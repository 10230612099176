/**
 * Created by Vladislav on 25.09.2024.
 */

var SoftMenuBarItem = {
    name: "CoinsItem",
    targets: "soft",
    plusButton: true,
    value: function () {
        return cleverapps.user.soft;
    },
    updater: function (f) {
        cleverapps.user.on("changeSoft", f, this);
    },
    attentionUpdater: function (f) {
        cleverapps.adsLimits.on("update", f, this);
    },
    attention: function () {
        return false;
    },
    onClickWindow: SoftCurrencyShopWindow,
    icon: bundles.menubar.frames.coin_png,
    force: Forces.MENU_BAR_SOFT_FORCE,
    availableOnScenes: function () {
        return [
            cleverapps.Environment.SCENE_MAIN,
            cleverapps.Environment.SCENE_GAME
        ];
    },
    hiddenByDefault: ["match3"].includes(cleverapps.config.type) ? [cleverapps.Environment.SCENE_GAME] : undefined,
    sparks: true,
    deltaSound: bundles.menubar.urls.coins_effect
};