/**
 * Created by andrey on 29.05.17.
 */
 
var ChooseColorView = cc.Node.extend({
    ctor: function (code) {
        this._super();

        this.code = code;

        this.colorSubview = createViewForChoose(code);
        this.colorSubview.setPosition(this.colorSubview.width / 2, this.colorSubview.height / 2);
        this.addChild(this.colorSubview);

        this.goalSubview = createViewForChoose(code.toUpperCase());
        this.goalSubview.setPosition(this.goalSubview.width / 2, this.goalSubview.height / 2);
        this.addChild(this.goalSubview);

        this.isActive = true;

        this.isGoal = false;
        this.goalSubview.visible = false;

        this.setCascadeOpacityEnabledRecursively(true);

        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize(this.colorSubview.getContentSize());
    },

    getCode: function () {
        if (this.isGoal) {
            return this.code.toUpperCase();
        }

        return this.code;
    },

    activate: function (isActive) {
        this.isActive = isActive;
        if (isActive) {
            this.setOpacity(255);
        } else {
            this.setOpacity(50);
        }
    },

    updateGoal: function (isGoal) {
        this.isGoal = isGoal;
        if (isGoal) {
            this.colorSubview.visible = false;
            this.goalSubview.visible = true;
        } else {
            this.colorSubview.visible = true;
            this.goalSubview.visible = false;
        }
    }
});

var ColorsBlock = ElementsChooser.extend({
    ctor: function () {
        var table = {};

        ColorComponent.CODES.forEach(function (code) {
            var view = new ChooseColorView(code);

            table[code] = view;
        });

        this._super({
            table: table,
            type: "cell"
        });
    },

    getData: function (editor) {
        return {
            type: this.type,
            selected: editor.getColorWithRegister(this.selected)
        };
    },

    colorsChanged: function (activeColors, goalColors) {
        for (var code in this.table) {
            var view = this.table[code];

            var isActive = activeColors.indexOf(code) >= 0 || activeColors.indexOf(code.toUpperCase()) >= 0;
            view.activate(isActive);

            view.updateGoal(goalColors.indexOf(code.toUpperCase()) >= 0);
        }
    }
});