/**
 * Created by andrey on 21.08.17.
 */

var DirtAnimation = BaseAnimation.extend({
    ctor: function () {
        this._super(bundles.dirt_decorator.jsons.dirt_spine_json, bundles.dirt_decorator.frames.cell_dirt);
    }
});

DirtAnimation.factory = function () {
    return cc.pool.hasObject(DirtAnimation) ? cc.pool.getFromPool(DirtAnimation) : new DirtAnimation();
};