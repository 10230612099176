/**
 * Created by iamso on 23.11.2020.
 */

var AdminLogView = cc.Node.extend({
    ctor: function () {
        this._super();

        this.log = cleverapps.administrator.log;
        this.views = [];

        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize2(cleverapps.styles.AdminLogView);

        this.createContent();
        this.addModeToggle();

        this.log.on("addLogEntry", this.addEntry.bind(this), this);
        this.log.loadLastEntries();

        cleverapps.UI.applyHover(this, {
            onMouseOver: function () {},
            onMouseOut: function () {}
        });
    },

    addEntry: function (entry) {
        var view = this.createEntry(entry);
        view.x = this.width / 2;

        this.content.addChild(view);
        this.updateContent();
    },

    updateContent: function () {
        this.content.reshape();
        this.scroll.updateInnerContent();
        if (!this.getInteractiveStates().mouseover) {
            this.scroll.scrollToPercent(100);
        }
    },

    addModeToggle: function () {
        var styles = cleverapps.styles.AdminLogView.mode;
        var button = new cleverapps.UI.Button({
            type: cleverapps.styles.UI.Button.Images.editor,
            text: this.log.targetEpisode !== undefined ? "EP." : "ALL",
            width: styles.width,
            height: styles.height,
            onClicked: function () {
                cleverapps.administrator.log.toggleMode();
                button.setString(this.log.targetEpisode !== undefined ? "EP." : "ALL");
            }.bind(this)
        });
        button.setTextVariant("strict_white");
        button.setPositionRound(styles);
        this.addChild(button, 1);
    },

    createContent: function () {
        var views = this.log.entries.map(function (entry) {
            return this.createEntry(entry);
        }, this);

        var content = this.content = new cleverapps.Layout(views, {
            direction: cleverapps.UI.VERTICAL,
            reversed: true
        });

        var scroll = this.scroll = new cleverapps.UI.ScrollView(content);
        scroll.setContentSize(this.getContentSize());
        this.addChild(scroll, -1);
        scroll.setPositionRound(this.width / 2, this.height / 2);
        scroll.scrollToPercent(100);
    },

    createEntry: function (entry) {
        var view = new AdminLogEntryView(entry, this);
        cleverapps.UI.wrapWithPadding(view, cleverapps.styles.AdminLogView.padding, true);
        view.x = this.width / 2;
        this.views.push(view);
        return view;
    }
});

cleverapps.styles.AdminLogView = {
    width: 880,
    height: 150,

    padding: {
        right: 40
    },

    mode: {
        x: { align: "right", dx: -32 },
        y: { align: "top", dy: -10 },
        width: 45,
        height: 45
    }
};