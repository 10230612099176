/**
 * Created by vladislav on 3/4/19
 */
var ClockCellComponent = function (view, cell, saveCallback) {
    MultiColorBombCellComponent.call(this, view, cell, saveCallback);
};

ClockCellComponent.prototype = Object.create(MultiColorBombCellComponent.prototype);
ClockCellComponent.constructor = ClockCellComponent;

ClockCellComponent.prototype.changeColor = function (itemIndex, color) {
    this.cell.colors[itemIndex] = color;
    this.view.items[itemIndex].color = color;
    this.view.items[itemIndex].view.animation.setSkin(ColorComponent.CODE_COLORS[color]);
    this.saveCallback();
};

ClockCellComponent.prototype.positionButton = function (item, index, button) {
    button.setPositionRound(this.view.calcPosition(item.view, cleverapps.styles.ClockCellComponent.pos.y));
};

ClockCellComponent.prototype.styles = function () {
    return cleverapps.styles.ClockCellComponent;
};

cleverapps.styles.ClockCellComponent = cleverapps.overrideStyles(cleverapps.styles.MultiColorBombCellComponent, {
    livesBlock: {
        y: -100
    },
    incDecBlock: {
        radius: 95
    },
    pos: {
        y: 80
    }
}, true);