/**
 * Created by andrey on 14.09.17.
 */

var StarChestWindow = CleverappsWindow.extend({
    onWindowLoaded: function () {
        this.reward = cleverapps.starChest.getReward();

        this.content = this.createContent();

        var buttons = this.createButtons();

        this._super({
            title: "StarChestWindow.title",
            name: "starchestwindow",
            content: this.content,
            buttons: buttons
        });

        if (this.shareCheckBox) {
            this.shareCheckBox.setCascadeOpacityEnabledRecursively(true);
            this.shareCheckBox.setOpacity(0);
        }

        if (!cleverapps.starChest.locked) {
            this.open();
        }
    },

    open: function () {
        this.openningChest = true;

        this.chest.setAnimation(0, "idle_ready", true);

        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.1),
            new cc.CallFunc(function () {
                if (this.visible) {
                    cleverapps.audio.playSound(bundles.main.urls.star_chest_reward_effect);
                }
            }.bind(this))
        ));

        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.7),
            new cc.CallFunc(function () {
                this.chest.setAnimationAndIdleAfter("open", "idle_open");
            }.bind(this)),
            new cc.DelayTime(1.0),
            new cc.CallFunc(function () {
                this.openningChest = false;

                this.close();

                cleverapps.starChest.open();
                cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STAR_CHEST_OPEN);
                RewardWindow.createStarChestWindow(this.reward);
            }.bind(this))
        ));
    },

    createOkButton: function () {
        var styles = cleverapps.styles.StarChestWindow;

        return new cleverapps.UI.Button({
            width: styles.button.width,
            height: styles.button.height,
            text: "OK",
            onClicked: this.close.bind(this)
        });
    },

    createAskButton: function () {
        var styles = cleverapps.styles.StarChestWindow;

        return new cleverapps.UI.Button({
            width: styles.button.width,
            height: styles.button.height,
            text: "StarChestWindow.askFriends",
            onClicked: function () {
                cleverapps.SocialManager.enforceLogin(function (code) {
                    if (code !== cleverapps.CODE_SUCCEED) {
                        return;
                    }

                    FriendsCache.loadFriends(function () {
                        new SelectFriendWindow("askStars", function (ids, onSuccess) {
                            cleverapps.requests.send(ids, "askStarRequest", function () {
                                ids.forEach(function (id) {
                                    if (cleverapps.friends.inApp(id)) {
                                        levels.friendRequests.sendServerRequest(id, levels.FriendRequest.TYPE_ASK_STAR);
                                    }
                                });

                                onSuccess();
                            });
                        });
                    });
                });
            }
        });
    },

    createButtons: function () {
        if (!cleverapps.starChest.locked) {
            return [];
        }

        return cleverapps.flags.requestsEnabled ? [this.createAskButton()] : [this.createOkButton()];
    },

    createContent: function () {
        var styles = cleverapps.styles.StarChestWindow;

        this.chestNode = this.createChest();

        this.bar = this.createBar();

        this.text = this.createText();

        return new cleverapps.Layout([this.chestNode, this.bar, this.text], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });
    },

    createChest: function () {
        var styles = cleverapps.styles.StarChestWindow;

        this.chest = new cleverapps.Spine(bundles.star_chest_window.jsons.chest_json);
        this.chest.setAnimation(0, "idle", true);

        var chestNode = new cc.Node();
        chestNode.setAnchorPoint(0.5, 0.5);
        chestNode.setContentSize2(styles.chest);
        chestNode.addChild(this.chest);
        this.chest.setPositionRound(styles.chest);

        return chestNode;
    },

    createText: function () {
        var styles = cleverapps.styles.StarChestWindow;

        var text = cleverapps.UI.generateOnlyText("StarChestWindow.text", cleverapps.styles.FONTS.WINDOW_TEXT);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        text.setDimensions(styles.text.width, 0);

        return text;
    },

    createBar: function () {
        var styles = cleverapps.styles.StarChestWindow;

        var bar = new ScaledProgressBar({
            progress: bundles.progress_bar.frames.bar_chest,
            background: bundles.progress_bar.frames.bg_chest,
            barText: {
                text: "%from%/%to%[[",
                font: cleverapps.styles.FONTS.STAR_CHEST_PROGRESS_TEXT,
                icons: {
                    "[[": bundles.star_chest_window.frames.star_png
                }
            }
        });
        bar.setLength(styles.bar.width);
        bar.setGoal(cleverapps.StarChest.GOAL_AMOUNT);
        bar.setPercentage(cleverapps.starChest.stars);

        return bar;
    },

    close: function () {
        if (!this.openningChest) {
            this._super();
        }
    }
});

StarChestWindow.prototype.listBundles = function () {
    return ["star_chest_window"];
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    STAR_CHEST_PROGRESS_TEXT: {
        name: "default",
        size: 44,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    }
});

cleverapps.styles.StarChestWindow = {
    margin: 50,

    button: {
        width: 250,
        height: 100
    },

    chest: {
        width: 300,
        height: 200,

        x: { align: "center" },
        y: { align: "center" }
    },

    bar: {
        width: 500
    },

    text: {
        width: 560
    }
};