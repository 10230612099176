/**
 * Created by mac on 9/25/20
 */

var AdminDetails = function () {
    cleverapps.EventEmitter.call(this);

    this.statistics = new LevelStatistics();
};

AdminDetails.prototype = Object.create(cleverapps.EventEmitter.prototype);
AdminDetails.prototype.constructor = AdminDetails;

AdminDetails.prototype.reset = function() {
    var adminLevel = cleverapps.administrator.adminLevels.getActive();

    this.statistics.reset(adminLevel);

    this.trigger('reset');
};