/**
 * Created by evgeny on 11.06.2024
 */

var HomefixOrangery = Object.create(MergeOrangery);

HomefixOrangery.init = function () {
    var tabs = [];

    this.addEditorTabs(tabs);

    tabs.forEach(function (tab) {
        tab.rows.unshift([{
            eraser: true,
            id: tab.id,
            itemIcon: new cc.Sprite(bundles.orangery.frames.eraser_icon),
            minimizedIcon: new cc.Sprite(bundles.orangery.frames.eraser_icon)
        }]);
    });

    this.tabs = tabs;
};

HomefixOrangery.addEditorTabs = function (tabs) {
    tabs.push({
        icon: bundles.orangery.frames.tab_icon_ground,
        rows: Object.keys(Map2d.currentMap.families).map(function (family) {
            return this.listUnitsByCode(family, false);
        }.bind(this))
    });
    tabs.push({
        icon: bundles.orangery.frames.tab_icon_fogs,
        rows: this.listFogs(),
        id: MergeOrangery.REGION_ID
    });
    tabs.push({
        icon: bundles.orangery.frames.tab_icon_ground,
        layer: Map2dEditor.LAYER_GROUND,
        rows: this.listTiles()
    });
    tabs.push({
        icon: bundles.orangery.frames.tab_icon_regions,
        rows: this.listRegions(),
        id: MergeOrangery.REGION_ID
    });
    tabs.push({
        icon: bundles.orangery.frames.tab_icon_decorators,
        rows: this.listDecorators(),
        layer: Map2dEditor.LAYER_DECORATORS,
        id: MergeOrangery.DECORATOR_ID
    });
    tabs.push({
        icon: bundles.orangery.frames.tab_icon_terrain,
        rows: this.listTerrains(),
        layer: Map2dEditor.LAYER_DECORATORS,
        id: MergeOrangery.DECORATOR_ID
    });
};
