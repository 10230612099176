/**
 * Created by anatoly on 28.08.2024
 */

var DangerAnimationView = cc.Node.extend({
    ctor: function (component) {
        this._super();

        this.component = component;
        this.component.getAnimationView = function () {
            return this;
        }.bind(this);

        this.addHero();
        this.addBoerAnimation();

        this.setupChildren();

        this.component.on("onStart", this.startAnimation.bind(this));
    },

    addBoerAnimation: function () {
        var styles = cleverapps.styles.BoerAnimation;

        this.boer = new BoerAnimation(this.component);

        this.boer.setPositionRound(styles);
        this.addChild(this.boer);
    },

    addHero: function () {
        var hero = this.hero = new cleverapps.Spine(bundles.danger_component.jsons.hero);
        hero.setAnimation(0, "idle", true);

        hero.scared = false;

        this.component.on("update", function () {
            if (this.component.getPercentOfCompletion() > 0.7 && !hero.scared) {
                hero.setAnimation(0, "scared", true);
                cleverapps.audio.playSound(bundles.danger_component.urls.tricky_vox_no);
                hero.scared = true;
            }
        }.bind(this));

        this.addChild(hero, -1);
    },

    startHeroAnimation: function () {
        this.hero.setAnimationAndIdleAfter("start", "idle");
        cleverapps.audio.playSound(bundles.danger_component.urls.tricky_help);
    },

    startAnimation: function () {
        this.startHeroAnimation();
        this.boer.startAnimation();
    },

    onWin: function () {
        this.hero.setAnimation(0, "joy", true);

        this.hero.runAction(new cc.RepeatForever(
            new cc.Sequence(
                new cc.PlaySound(bundles.danger_component.urls.tricky_applause),
                new cc.DelayTime(2)
            )
        ));
    },

    stopBoerAnimation: function () {
        this.boer.stop();
    },

    setupChildren: function () {
        var styles = cleverapps.styles.DangerAnimation;
        var rect = cleverapps.resolution.getBgRect();

        var partFromScreen = styles.SKIP_RESOLUTION.partFromScreen;

        var position = styles.positions[cleverapps.resolution.mode];

        if (cleverapps.resolution.mode === cleverapps.WideMode.HORIZONTAL) {
            var totalWidth = rect.width * partFromScreen;

            position.y.dy = rect.y / 2;
            this.setContentSize(totalWidth, rect.height);
        } else {
            var totalHeight = rect.height * partFromScreen;

            this.setContentSize(rect.width, totalHeight);
        }

        this.setPositionRound(position);

        this.hero.setScale(styles.scales[cleverapps.resolution.mode]);
        this.hero.setPositionRound(styles.hero.positions[cleverapps.resolution.mode]);

        this.boer.onAnimationResize();
    }
});

cleverapps.styles.DangerAnimation = {
    SKIP_RESOLUTION: {
        partFromScreen: 0.4
    },

    positions: [
        {
            x: { align: "center" },
            y: { align: "top" }
        },
        {
            x: { align: "center" },
            y: { align: "top" }
        },
        {
            x: { align: "left" },
            y: { align: "center" }
        }
    ],

    scales: [0.5, 0.5, 1],

    hero: {
        positions: [
            {
                x: { align: "left" },
                y: { align: "center", dy: -60 }
            },
            {
                x: { align: "left" },
                y: { align: "center", dy: -100 }
            },
            {
                x: { align: "left", dx: -30 },
                y: { align: "center", dy: -220 }
            }
        ]

    }
};