/**
 * Created by andrey on 24.04.2024
 */

var TransitionBackground = cc.Node.extend({
    ctor: function (type) {
        this._super();

        this.type = type;

        this.setAnchorPoint(0.5, 0.5);
        this.setPositionRound(cleverapps.styles.TransitionBackground);

        var json = type === Transition.TYPE_DEFAULT
            ? bundles.transition.jsons.transition_curtains_json
            : bundles.transition.jsons.transition_victory_curtains_json;

        var curtains = this.curtains = new cleverapps.Spine(json);
        this.addChild(curtains);

        var skins = curtains.listSkins();
        if (skins.length > 0) {
            var skin = skins[levels.user.getVirtualLevel() % skins.length];
            curtains.setSkin(skin);
        }

        this.setContentSize(curtains.getContentSize());
        curtains.setPosition(this.width / 2, this.height / 2);
    },

    show: function (f, silent) {
        if (silent) {
            this.curtains.setAnimation(0, "close", false);
            this.curtains.clearTrack(0);
            f();
            return;
        }

        var styles = cleverapps.styles.TransitionBackground[this.type] || {};

        this.runAction(new cc.Sequence(
            new cc.DelayTime(styles.delay || 0),
            new cc.CallFunc(function () {
                this.curtains.setAnimation(0, "close", false);
                this.curtains.setCompleteListener(f);
            }.bind(this))
        ));
    },

    hide: function (f, silent) {
        this.stopAllActions();

        if (silent) {
            f();
            return;
        }

        this.curtains.setAnimation(0, "open", false);
        this.curtains.setCompleteListener(f);
    }
});

cleverapps.styles.TransitionBackground = {
    x: { align: "center" },
    y: { align: "center" }
};

cleverapps.styles.TransitionBackground[Transition.TYPE_VICTORY] = {
    delay: 0.9
};
