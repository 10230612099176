/**
 * Created by Denis Kuzin on 19 july 2021
 */

var MiniMergeLogic = function (width, height, game) {
    this.fieldWidth = width || 11;
    this.fieldWidth = Math.max(this.fieldWidth, 5);
    this.fieldWidth = Math.min(this.fieldWidth, 12);

    this.fieldHeight = height || 11;
    this.fieldHeight = Math.max(this.fieldHeight, 5);
    this.fieldHeight = Math.min(this.fieldHeight, 12);

    this.game = game;

    var items = AdsPuzzle15.MERGE_TOOL.map(function (code) {
        var total = 1;
        while (bundles.puzzle_merge_tool.frames["unit_" + code + total]) {
            total++;
        }

        return { code: code, units: cleverapps.arrayFill(total - 1, {}), mergeToolFamily: true };
    });

    this.families = {};
    if (typeof Families === "object") {
        items = Object.values(Families).filter(function (family) {
            if (!family) {
                return false;
            }
            if (AdsPuzzle15.MERGE_TOOL.some(function (code) {
                return code === family.code;
            })) {
                return false;
            }

            return ["custom", "resource", "fruit", "hero", "chest"].indexOf(family.type) !== -1;
        }).concat(items);
    } else {
        var total = 1;
        while (bundles.puzzle15.frames["unit" + total]) {
            total++;
        }

        items.push({
            code: "fakeСode",
            units: cleverapps.arrayFill(total - 1, {})
        });
    }
    items.forEach(function (item) {
        if (item.units.length) {
            this.families[item.code] = item;
        }
    }.bind(this));
};

MiniMergeLogic.prototype.createUnits = function (options) {
    var map = this.game.map;
    var addUnit = function (code, stage, x, y) {
        var unit = new Puzzle15Unit(map, {
            code: code,
            stage: stage,
            stageCount: this.goal.stageCount,
            x: x,
            y: y,
            upgradableIcon: true
        });
        map.add(Map2d.LAYER_UNITS, x, y, unit);
    }.bind(this);

    if (options && options.mergeTool) {
        this.goal = {
            code: options.mergeTool,
            stageCount: 0,
            stageDone: 0
        };
        for (var i = 0; i < this.families[this.goal.code].units.length - 1; i++) {
            this.goal.stageCount++;
        }
    }

    var goalStage = -1;
    this.shuffleCells().forEach(function (cellPos) {
        if (this.goal && goalStage !== this.goal.stageCount) {
            addUnit(this.goal.code, goalStage < 0 ? 0 : goalStage, cellPos.x, cellPos.y);
            goalStage++;
        } else if (cleverapps.Random.nextDouble() < 0.3) {
            var code;
            // eslint-disable-next-line no-cond-assign
            while (code = cleverapps.Random.choose(Object.keys(this.families))) {
                if (code !== this.goal.code) {
                    break;
                }
            }

            var stage = cleverapps.Random.random((this.families[code].mergeToolFamily || code === "fakeСode") ? this.families[code].units.length : 1);

            addUnit(code, stage, cellPos.x, cellPos.y);
        }
    }.bind(this));
};

MiniMergeLogic.prototype.getPlayActions = function () {
    var actions = [];
    var map = this.game.map;

    var savedUnitsLayer = map.layers[Map2d.LAYER_UNITS].map(function (arr) {
        return arr.slice();
    });
    var stages = savedUnitsLayer.map(function (col) {
        var result = [];
        col.forEach(function (row) {
            result.push(row ? row.stage : undefined);
        });
        return result;
    });

    do {
        this.shuffleCells().forEach(function (cellPos) {
            if (this.goal.stageCount === this.goal.stageDone) {
                return;
            }

            var code = this.goal && this.goal.code;
            var startingUnit = map.getUnit(cellPos.x, cellPos.y);
            var lastPath = actions.length ? actions[actions.length - 1].path : undefined;
            var move = this.findMove(startingUnit, code, lastPath);
            if (!move) {
                return;
            }

            move.unit.move(move.path);
            this.upgrade(move);
            this.afterMove(map, move, true);

            actions.push(move);
        }.bind(this));
    } while (this.goal.stageCount !== this.goal.stageDone);

    // repair map
    map.layers[Map2d.LAYER_UNITS] = savedUnitsLayer;
    this.forEachCell(function (x, y) {
        var unit = map.getUnit(x, y);
        if (unit !== undefined) {
            unit.x = x;
            unit.y = y;
            unit.stage = stages[y][x];
        }
    });

    this.goal.stageDone = 0;

    return actions;
};

MiniMergeLogic.prototype.findMove = function (startingUnit, code, lastPath) {
    if (!startingUnit) {
        return;
    }

    var map = this.game.map;
    var movableUnits = [];
    this.forEachCell(function (x, y) {
        var unit = map.getUnit(x, y);
        if (unit) {
            movableUnits.push(unit);
        }
    });

    for (var i = 0; i < movableUnits.length; i++) {
        var unit = movableUnits[i];

        if (startingUnit.x === unit.x && startingUnit.y === unit.y) {
            continue;
        }

        if (code && startingUnit.code !== code || startingUnit.stage !== this.goal.stageDone) {
            continue;
        }

        if (lastPath) {
            var joint = lastPath.some(function (path) {
                return startingUnit.x === path.x && startingUnit.y === path.y || unit.x === path.x && unit.y === path.y;
            });

            if (!joint) {
                continue;
            }
        }

        if (unit && unit.isMergeable(startingUnit)) {
            return {
                unit: startingUnit,
                path: [cc.p(startingUnit.x, startingUnit.y), cc.p(unit.x, unit.y)]
            };
        }
    }
};

MiniMergeLogic.prototype.upgrade = function (move) {
    move.unit.stage++;
};

MiniMergeLogic.prototype.afterMove = function (map, move, silent, successCallback) {
    if (this.goal && move.unit.stage > this.goal.stageDone) {
        this.goal.stageDone = move.unit.stage;
    }

    if (!silent && successCallback) {
        successCallback(this.goal.stageDone / this.goal.stageCount, {
            lastMergedPos: { x: move.unit.x, y: move.unit.y }
        });
    }
};

MiniMergeLogic.prototype.shuffleCells = function () {
    var cellsPos = [];
    this.forEachCell(function (x, y) {
        cellsPos.push({ x: x, y: y });
    });
    return cleverapps.Random.shuffle(cellsPos);
};

MiniMergeLogic.prototype.forEachCell = function (callback) {
    for (var fieldCell = 0; fieldCell < this.fieldWidth * this.fieldHeight; fieldCell++) {
        var x = fieldCell % this.fieldWidth;
        var y = (fieldCell - x) / this.fieldWidth;

        callback(x, y);
    }
};