/**
 * Created by slava on 02.02.17.
 */

var BombCellView = BaseCellView.extend({
    ctor: function (bomb) {
        this._super();

        this.setContentSize(cleverapps.styles.BaseCellView.CELL_SIZE_PX, cleverapps.styles.BaseCellView.CELL_SIZE_PX);
        this.setAnchorPoint(0.5, 0.5);

        if (bomb.x === undefined) {
            var image = new cc.Sprite(bundles.bomb.frames.bomb_goal);
            this.addChild(image);
            image.setPositionRound(this.width / 2, this.height / 2);
            return;
        }

        this.container = new cc.Node();
        this.container.setAnchorPoint(0.5, 0.5);
        this.container.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(this.container);

        image = new cc.Sprite(this.getImage(bomb));
        this.image = image;
        this.image.setPositionRound(0, 0);
        this.container.addChild(image);

        this.animation = new cleverapps.Spine(bundles.bomb.jsons.bomb_spine_json);
        this.animation.setVisible(false);
        this.updateAnimationSkin(bomb);
        this.animation.setPositionRound(0, 0);
        this.container.addChild(this.animation);

        this.timerTxt = cleverapps.UI.generateImageText("", cleverapps.styles.FONTS.BOMB_TIMER_TEXT);
        this.timerTxt.setPositionRound(cleverapps.styles.BombCellView.timer);
        this.timerTxt.setAnchorPoint(0.5, 0.5);
        this.timerTxt.baseScale = this.timerTxt.scale;
        this.container.addChild(this.timerTxt);

        this.initialize(bomb);

        this.state = undefined;
        this.onUntilExplodeChanged();
        this.setCascadeOpacityEnabled(true);
        this.container.setCascadeOpacityEnabled(true);
    },

    getImage: function (bomb) {
        if (this.isDanger(bomb)) {
            return bundles.bomb.frames["bomb_danger_" + bomb.findComponent(ColorComponent).color];
        }
        return bundles.bomb.frames["bomb_" + bomb.findComponent(ColorComponent).color];
    },

    isDanger: function (bomb) {
        return bomb.untilExplode <= 3;
    },

    initialize: function (bomb) {
        this._super(bomb);

        bomb.onChangeColor = this.onColorChanged.bind(this);
        bomb.onChangeUntilExplode = this.onUntilExplodeChanged.bind(this);
        bomb.onBoom = this.boom.bind(this);

        this.setScale((Game.currentGame.started || cleverapps.environment.isAdministratorScene()) ? 1 : 0);
    },

    unuse: function () {
        this.cell.onChangeColor = function () {};
        this.cell.onChangeUntilExplode = function () {};
        this.cell.onBoom = function () {};

        this._super();
    },

    boom: function () {
        Game.currentGame.counter.setTimeout(function () {
            cleverapps.audio.playSound(bundles.bomb.urls.bomb_explosion);
        }, 100);
    },

    reuse: function (bomb) {
        this._super(bomb);

        this.state = undefined;
        this.onColorChanged();
    },

    onEnterTransitionDidFinish: function () {
        this._super();

        if (this.cell) {
            this.onUntilExplodeChanged();
        }
    },

    startExploding: function () {
        if (Game.currentGame.goals.hasType(this.cell.getGoalId())) {
            BaseCellView.prototype.startExplodingViaCollect.apply(this, arguments);
        } else {
            this._super.apply(this, arguments);
        }
        cleverapps.audio.playSound(bundles.game.urls.special_element_die);
    },

    updateImageTexture: function () {
        this.image.setSpriteFrame(this.getImage(this.cell));
    },

    updateAnimationSkin: function (bomb) {
        this.animation.setSkin("skin_" + bomb.findComponent(ColorComponent).color);
    },

    onColorChanged: function () {
        this.updateImageTexture();
        this.updateAnimationSkin(this.cell);
    },

    onUntilExplodeChanged: function () {
        if (this.isDanger(this.cell)) {
            if (this.state !== BombCellView.DANGER) {
                this.state = BombCellView.DANGER;
                this.updateImageTexture();
                this.timerTxt.setFont(cleverapps.styles.FONTS.BOMB_TIMER_DANGER_TEXT);

                var styles = cleverapps.styles.BombCellView;
                var duration = styles.danger.duration;
                this.container.runAction(new cc.RepeatForever(new cc.Sequence(new cc.ScaleTo(duration, styles.danger.scale), new cc.ScaleTo(duration, 1))));
            }
        } else if (this.state !== BombCellView.SIMPLE) {
            this.state = BombCellView.SIMPLE;
            this.updateImageTexture();
            this.timerTxt.setFont(cleverapps.styles.FONTS.BOMB_TIMER_TEXT);
            this.container.stopAllActions();
        }
        if (this.cell.untilExplode >= 10) {
            this.timerTxt.setScale(this.timerTxt.baseScale * 0.9);
        } else {
            this.timerTxt.setScale(this.timerTxt.baseScale);
        }
        this.timerTxt.setString("" + this.cell.untilExplode);
    },

    finishSmile: function () {
        if (this.smiling) {
            this.smiling = false;
            this.image.visible = true;
            this.animation.visible = false;
        }
    },

    smile: function () {
        if (this.smiling) {
            return;
        }

        this.smiling = true;
        this.animation.setStartVisibleListener(function () {
            this.image.visible = false;
        }.bind(this));
        if (this.isDanger(this.cell)) {
            this.animation.setAnimation(0, "animation", false);
        } else {
            this.animation.setAnimation(0, "stay", false);
        }
        this.animation.setCompleteListener(this.finishSmile.bind(this));
    }
});

cleverapps.overrideColors(cleverapps.styles.COLORS, {
    BOMB_TIMER_DANGER_COLOR: new cc.Color(255, 50, 50, 255)
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    BOMB_TIMER_TEXT: {
        name: "nostroke",
        color: cleverapps.styles.COLORS.RIDDLES_BROWN,
        size: 35
    },

    BOMB_TIMER_DANGER_TEXT: {
        name: "nostroke",
        size: 35,
        color: cleverapps.styles.COLORS.BOMB_TIMER_DANGER_COLOR
    }
});

cleverapps.styles.BombCellView = {
    timer: {
        x: 2,
        y: -24
    },

    danger: {
        scale: 1.2,
        duration: 0.6
    }
};

BombCellView.SIMPLE = 0;
BombCellView.DANGER = 1;