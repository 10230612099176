/**
 * Created by Andrey Popov on 13.04.2021.
 */

var DirectoryView = cleverapps.Layout.extend({
    ctor: function () {
        var styles = cleverapps.styles.DirectoryView;
        var hierarchy = cleverapps.wysiwyg.hierarchy;

        var controls = [new cleverapps.UI.Button({
            width: styles.button.width,
            height: styles.button.height,
            text: "Create directory",
            onClicked: hierarchy.createDirectory.bind(hierarchy, true)
        }), new cleverapps.UI.Button({
            width: styles.button.width,
            height: styles.button.height,
            text: "Create subdirectory",
            onClicked: hierarchy.createDirectory.bind(hierarchy)
        })];

        var controlsLayout = new cleverapps.Layout(controls, {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });
        controlsLayout.setAnchorPoint(0.5, 1);

        var folders = hierarchy.directories.filter(function (item) {
            return item !== "wysiwyg";
        }).map(function (item) {
            return this.createDirectory(item);
        }.bind(this));

        var foldersLayout = new cleverapps.Layout(folders, {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });
        foldersLayout.setAnchorPoint(0.5, 1);

        this._super([controlsLayout, foldersLayout], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });
    },

    createDirectory: function (item) {
        var styles = cleverapps.styles.DirectoryView;

        var button = new cleverapps.UI.Button({
            width: styles.button.width,
            height: styles.button.height,
            type: cleverapps.styles.UI.Button.Images.wysiwyg,
            text: item.replace("wysiwyg/", ""),
            textVariant: item === cleverapps.wysiwyg.directory ? "strict_black" : "strict",
            onClicked: function () {
                cleverapps.wysiwyg.hierarchy.selectDirectory(item);
            }
        });

        var removeAction = cleverapps.UI.createSprite(bundles.wysiwyg.frames.delete);
        cleverapps.UI.onClick(removeAction, function () {
            if (window.confirm("Remove '" + item.replace("wysiwyg/", "") + "' directory?")) {
                cleverapps.RestClient.post(
                    "/wysiwyg/removeDirectory",
                    {
                        directory: item
                    },
                    function () {
                        cleverapps.wysiwyg.hierarchy.selectDirectory("wysiwyg");
                    },
                    function (err) {
                        console.log(err);
                    }
                );
            }
        });

        return new cleverapps.Layout([button, removeAction], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });
    }
});

cleverapps.styles.DirectoryView = {
    margin: 10,

    button: {
        width: 400,
        height: 60
    }
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    SELECTED_DIRECTORY_ITEM_TEXT: {
        size: 26,
        color: cleverapps.styles.COLORS.BLACK,
        font: bundles.wysiwyg.urls.strict_font_ttf
    }
});
