/**
 * Created by slava on 02.02.17.
 */

var GrassTileView = cc.Sprite.extend(Object.assign({}, BaseTileView, {
    ctor: function (tile) {
        this._super(bundles.grass_tile.frames.tile_grass);

        this.initialize(tile);
        this.setLocalZOrder(4);
    }
}));