/**
 * Created by vladislav on 3/19/19
 */

var WallCell = function (x, y) {
    BaseCell.call(this, x, y);

    this.movable = false;
    this.hurtable = false;
};

WallCell.prototype = Object.create(BaseCell.prototype);
WallCell.prototype.constructor = WallCell;

WallCell.prototype.hurt = function () {};

WallCell.prototype.load = function () {};

WallCell.prototype.save = function () {
    return WallCell.CODES;
};

WallCell.prototype.getViewClass = function () {
    return WallCellView;
};

WallCell.prototype.bundleId = function () {
    return "wall";
};