/**
 * Created by slava on 02.02.17.
 */

var TulpanCellView = BaseCellView.extend({
    ctor: function (cell) {
        this._super();

        this.setContentSize(cleverapps.styles.BaseCellView.CELL_SIZE_PX, cleverapps.styles.BaseCellView.CELL_SIZE_PX);
        this.setAnchorPoint(0.5, 0.5);

        var image = new cc.Sprite(this.getImage());
        this.image = image;
        this.image.setPosition(this.width / 2, this.height / 2);
        this.image.setScale(cleverapps.skins.getSlot("tulpanScale"));
        this.addChild(image);

        this.initialize(cell);

        var previousOnFinishAppear = cell.onFinishAppear;
        cell.onFinishAppear = function () {
            previousOnFinishAppear.call(this);
            cleverapps.audio.playSound(cleverapps.styles.TulpanCellView.appearSound);
        };
    },

    getImage: function () {
        return cleverapps.skins.getSlot("missionIcon", {
            type: Mission.TYPE_BURN_NEARBY
        });
    },

    startExploding: function () {
        if (Game.currentGame && Game.currentGame.outcome === undefined) {
            var game = Game.currentGame;
            var scene = cleverapps.scenes.getRunningScene();
            var beginPos = scene.convertToNodeSpace(this.getParent().convertToWorldSpace(this.getPosition()));
            var target = cleverapps.aims.getTarget("mission_reward" + Game.currentGame.secondaryMission.type);
            var targetPos = scene.convertToNodeSpace(target.getParent().convertToWorldSpace(target.getPosition()));

            MissionAnimationView.show(game.getMissionType(), scene, {
                beginPos: beginPos,
                targetPos: targetPos,
                beginScale: this.image.getScale() * scene.fieldView.getScale(),
                finalScale: target.baseScale * target.getParent().getScale(),
                callback: function () {
                    game.addClover(Mission.TYPE_BURN_NEARBY, 1);
                }
            });
        }
        this.removeFromParent(false);
    }
});

cleverapps.styles.TulpanCellView = {
    scale: 1.2,
    appearSound: bundles.game.urls.tulpan_appear_effect
};