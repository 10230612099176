/**
 * Created by andrey on 28.03.2024.
 */

var InstantLogger = function () {
};

InstantLogger.prototype.logEvent = function (name, params) {
    if (!InstantLogger.EVENTS[name]) {
        return;
    }

    FBInstant.logEvent(name, params.value);
};

InstantLogger.isApplicable = function () {
    return connector.platform.oneOf(connector.INSTANT);
};

InstantLogger.EVENTS = ConversionManager.EVENTS;
