/**
 * Created by r4zi4l on 12.07.2021
 */

var Person = cleverapps.Spine.extend({
    avoidNode: "Person",

    ctor: function (options) {
        options = Person.ParseOptions(options);

        this.role = options.role;

        this.person = cleverapps.persons.getRole(this.role);

        if (!this.person) {
            throw "Person is undefined - " + this.role;
        }

        this._super(this.person.json);

        this.setOrientation(options.orientation);

        if (!options.skin && this.hasSkin("regular")) {
            options.skin = "regular";
        }
        if (options.skin && this.hasSkin(options.skin)) {
            this.setSkin(options.skin);
        }

        this.emotion = options.emotion;
        this.setAnimation(0, this.emotion, true);

        if (cleverapps.config.debugMode && cleverapps.gameModes.showPersonSilhouette) {
            this.addChild(new PersonSilhouette());
        }
    },

    setSpeaking: function (isSpeaking, interrupt) {
        if (!this.hasAnimation("talk")) {
            return;
        }

        if (this.isSpeaking === isSpeaking) {
            return;
        }

        this.isSpeaking = isSpeaking;

        if (this.isSpeaking) {
            this.setAnimation(0, "talk", false);
            this.setCompleteListener(function () {
                this.setAnimation(0, this.isSpeaking ? "talk" : this.emotion, false);
            }.bind(this));
        } else if (interrupt) {
            this.setAnimation(0, this.emotion, true);
            this.setCompleteListener();
        } else {
            this.setCompleteListenerOnce(function () {
                this.setAnimation(0, this.emotion, true);
            }.bind(this));
        }
    },

    setActive: function (isActive) {
        if (this.isActive === isActive) {
            return;
        }

        this.isActive = isActive;

        this.setLocalZOrder(this.isActive ? -1 : -2);
    },

    getRole: function () {
        return this.role;
    },

    getOrientation: function () {
        return this.orientation;
    },

    setOrientation: function (orientation) {
        this.orientation = orientation;

        if (this.orientation === Person.ORIENTATION_RIGHT) {
            this.setScaleX(-1);
        } else {
            this.setScaleX(1);
        }
    },

    showAnimation: function (silent) {
        this.setVisible(true);
        this.stopAllActions();

        if (silent) {
            this.setOpacity(255);
            this.setScale(1);
            this.clearTrack(1);
            return;
        }

        var fadeInAction;
        if (this.hasAnimation("fadeIn")) {
            fadeInAction = new cc.CallFunc(function () {
                this.setAnimation(1, "fadeIn");
            }.bind(this));
        } else {
            this.setOpacity(0);
            fadeInAction = new cc.FadeIn(0.15);
        }

        this.runAction(new cc.Spawn(
            fadeInAction,
            new cc.ScaleTo(0.15, 1).easing(cc.easeBackOut())
        ));
    },

    hideAnimation: function (silent) {
        this.stopAllActions();

        if (silent) {
            this.setVisible(false);
            this.clearTrack(1);
            return;
        }

        var fadeOutAction;
        if (this.hasAnimation("fadeOut")) {
            fadeOutAction = new cc.CallFunc(function () {
                this.setAnimation(1, "fadeOut");
            }.bind(this));
        } else {
            fadeOutAction = new cc.FadeOut(0.15);
        }

        this.runAction(new cc.Spawn(
            fadeOutAction,
            new cc.ScaleTo(0.15, 0.6).easing(cc.easeBackIn())
        ));
    }
});

Person.ParseOptions = function (options) {
    if (typeof options === "string") {
        options = {
            role: options
        };
    }

    options.emotion = options.emotion || "idle";
    options.orientation = options.orientation || Person.ORIENTATION_LEFT;

    return options;
};

Person.ORIENTATION_LEFT = "left";
Person.ORIENTATION_RIGHT = "right";
