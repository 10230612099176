/**
 * Created by andrey on 10.06.2024
 */

var BundleUtils = {
    createRegion: function (name, frame, page) {
        var region = new spine.TextureAtlasRegion();
        region.name = name;
        region.page = page;
        region.rotate = frame.rotated;

        region.u = frame.rect.x / page.width;
        region.v = frame.rect.y / page.height;

        if (region.rotate) {
            region.u2 = (frame.rect.x + frame.rect.height) / page.width;
            region.v2 = (frame.rect.y + frame.rect.width) / page.height;
        } else {
            region.u2 = (frame.rect.x + frame.rect.width) / page.width;
            region.v2 = (frame.rect.y + frame.rect.height) / page.height;
        }

        region.x = frame.rect.x;
        region.y = frame.rect.y;
        region.width = frame.rect.width;
        region.height = frame.rect.height;
        region.originalWidth = frame.size.width;
        region.originalHeight = frame.size.height;
        region.offsetX = frame.offset.x;
        region.offsetY = frame.offset.y;
        region.index = -1;
        region.texture = page.texture;

        return region;
    },

    createPage: function (atlas, texture) {
        var skeletonTexture = new sp.SkeletonTexture({ width: texture.getPixelsWide(), height: texture.getPixelsHigh() });
        skeletonTexture.setRealTexture(texture);

        var page = new spine.TextureAtlasPage();

        page.name = atlas.meta.image;
        page.width = atlas.meta.size.w;
        page.height = atlas.meta.size.h;
        page.minFilter = spine.Texture.filterFromString("Linear");
        page.magFilter = spine.Texture.filterFromString("Linear");
        page.uWrap = spine.TextureWrap.ClampToEdge;
        page.vWrap = spine.TextureWrap.ClampToEdge;

        page.texture = skeletonTexture;
        page.texture.setFilters(page.minFilter, page.magFilter);
        page.texture.setWraps(page.uWrap, page.vWrap);
        page.width = page.texture.getImage().width;
        page.height = page.texture.getImage().height;

        return page;
    },

    parseAtlas: function (minifiedAtlas) {
        var lines = minifiedAtlas.replace(/\r\n/g, "\n").split("\n");

        var json = {
            frames: {},
            animations: {},
            meta: {
                app: "https://www.codeandweb.com/texturepacker",
                version: "1.0"
            }
        };

        var format = lines[0].split(";");
        json.meta.image = format[0];
        json.meta.size = {
            w: +format[1].split(",")[0],
            h: +format[1].split(",")[1]
        };
        json.meta.format = format[2];

        for (var index = 1; index < lines.length; index++) {
            var line = lines[index].split(";");

            var frame = {};
            var key = line[0];

            frame.rect = cc.rect(
                +line[1].split(",")[0],
                +line[1].split(",")[1],
                +line[2].split(",")[0],
                +line[2].split(",")[1]
            );

            frame.rotated = Boolean(line[3]);

            frame.offset = cc.p(
                line[5] ? +line[5].split(",")[0] : 0,
                line[5] ? +line[5].split(",")[1] : 0
            );

            frame.size = cc.size(
                line[4] ? +line[4].split(",")[0] : frame.rect.width,
                line[4] ? +line[4].split(",")[1] : frame.rect.height
            );

            frame.trimmed = frame.size.width !== frame.rect.width || frame.size.height !== frame.rect.height;

            // @en The offset of the sprite frame center.
            // Sprite frame in an atlas texture could be trimmed for clipping the transparent pixels, so the trimmed rect is smaller than the original one,
            // the offset defines the distance from the original center to the trimmed center.
            // frame.size.width / 2; old center
            // frame.offset.x + frame.rect.width / 2; new center
            frame.spriteOffset = cc.p(
                (frame.offset.x + frame.rect.width / 2 - frame.size.width / 2),
                (frame.offset.y + frame.rect.height / 2 - frame.size.height / 2)
            );

            json.frames[key] = frame;
        }
        return json;
    }
};
