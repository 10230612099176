/**
 * Created by vladislav on 5/8/2024
 */

var ScoreTracker = function () {
    this.score = 0;
    this.lastReport = 0;

    this.load();

    this.withDialog = connector.platform.oneOf(connector.INSTANT, connector.WORTAL);

    if (this.withDialog && cleverapps.config.type === "merge") {
        ScoreTracker.REPORT_TIMEOUT = cleverapps.parseInterval("2 days");
    } else if (connector.platform.oneOf(connector.YOUTUBE, connector.GAMESNACK)) {
        ScoreTracker.REPORT_TIMEOUT = 0;
    }
};

ScoreTracker.prototype.load = function () {
    var data = cleverapps.dataLoader.load(DataLoader.TYPES.SCORE);
    if (!data) {
        // compatability
        data = cleverapps.dataLoader.load(DataLoader.TYPES.INSTANT_TOURNAMENT);
    }
    if (data) {
        this.score = data.score || 0;
        this.lastReport = data.lastReport || 0;
    }
};

ScoreTracker.prototype.save = function () {
    cleverapps.dataLoader.save(DataLoader.TYPES.SCORE, {
        score: this.score,
        lastReport: this.lastReport
    });
};

ScoreTracker.prototype.calcScore = function () {
    var level = cleverapps.user.getFloatLevel();

    return Math.round(level * 100);
};

ScoreTracker.prototype.setScore = function (score) {
    this.score = score;
    this.save();
};

ScoreTracker.prototype.needToReport = function () {
    if (Date.now() - this.lastReport < ScoreTracker.REPORT_TIMEOUT) {
        return false;
    }

    if (this.withDialog) {
        var level = cleverapps.user.getFloatLevel();
        if (level < 5) {
            return false;
        }

        if (cleverapps.timeStart === undefined || Date.now() - cleverapps.timeStart < 60000) {
            return false;
        }

        var score = this.calcScore();

        var per = 10;
        if (level < 9) {
            per = 50;
        }

        if (score < (Math.floor(this.score / per) + 1) * per) {
            return false;
        }
    }

    return true;
};

ScoreTracker.prototype.report = cleverapps.accumulate(1000, function () {
    if (!cleverapps.platformLogic.reportScore) {
        return;
    }
    
    if (!connector.platform.isConnected()) {
        return;
    }

    if (!this.needToReport()) {
        return;
    }

    var score = this.calcScore();

    cleverapps.platformLogic.reportScore(score, function (code) {
        if (code === cleverapps.CODE_SUCCEED) {
            this.lastReport = Date.now();
            this.setScore(score);
        }
    }.bind(this));
});

ScoreTracker.REPORT_TIMEOUT = cleverapps.parseInterval("10 minutes");
