/**
 * Created by Andrey Popov on 8/13/24.
 */

var Page = function (pageNo, comingSoon, offsetFromCenter) {
    this.pageNo = pageNo;
    this.comingSoon = comingSoon;
    this.offsetFromCenter = offsetFromCenter;

    this.onAnimateClose = function () {};
    this.onAnimateOpen = function () {};
    this.onScroll = function () {};
};

Page.prototype.isComingSoon = function () {
    return this.comingSoon;
};

Page.prototype.setOffsetFromCenter = function (offsetFromCenter) {
    this.offsetFromCenter = offsetFromCenter;
    this.onScroll();
};

Page.prototype.getResourcesBundleId = function () {
    return "page_" + this.pageNo + "_view_resources";
};