/**
 * Created by spepa on 12.01.2021
 */

var AdminConfig = {
    difficulty: {
        match3: [
            {
                bottom: 60,
                top: 95
            }
        ],

        solitaire: [
            {
                bottom: 60,
                top: 85
            },
            {
                episode: 10,
                bottom: 65,
                top: 92
            }
        ]
    }
};

AdminConfig.getConfig = function (key) {
    var config = AdminConfig[key][cleverapps.config.type];
    if (!config) return;

    var data;
    config.forEach(function (item) {
        if (!item.episode || item.episode <= cleverapps.administrator.adminEpisodes.getActive().episodeNo) {
            data = item;
        }
    });
    return data;
};