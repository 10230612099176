/**
 * Created by vladislav on 3/19/19
 */

var WallCellView = BaseCellView.extend({
    ctor: function (wallCell) {
        this._super();

        this.setContentSize(cleverapps.styles.BaseCellView.CELL_SIZE_PX, cleverapps.styles.BaseCellView.CELL_SIZE_PX);
        this.setAnchorPoint(0.5, 0.5);

        this.initialize(wallCell);

        this.image = new cc.Sprite(bundles.wall.frames.wall_tile);
        this.image.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(this.image);
    },

    refuse: function () {
    }
});