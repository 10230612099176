/**
 * Created by vtbelo on 01.03.18.
 */
var createViewForChoose = (function () {
    var loader = new Loader();

    return function (code) {
        if (Barriers && Barriers.EDITOR_CODES.indexOf(code) !== -1) {
            var image = new cc.Sprite(bundles.game.frames.barrier);
            if (code === Barriers.EDITOR_CODES[1]) {
                image.setRotation(-90);
            }
            return image;
        }

        var cell = loader.loadNext(-1, -1, code.split(""));
        var ViewClass = cell.getViewClass();
        var view = new ViewClass(cell);
        var specialShapeComponent = cell.findComponent && cell.findComponent(SpecialShapeComponent);
        if (specialShapeComponent) {
            specialShapeComponent.view.scaleViewContent();
        }
        return view;
    };
}());

var createViewForSkinChoose = (function () {
    var loader = new Loader();

    return function (code, skin) {
        if (code === Mark.CODES[0]) {
            var mark = new Mark();
            mark.getSkinSuffix = function () {
                return skin.replace(mark.getSkinBundles()[0], "");
            };
            return new MarkView({ mark: mark });
        }

        var cell = loader.loadNext(-1, -1, code.split(""));
        cell.getSkinSuffix = function () {
            return skin.replace(cell.getSkinBundles()[0], "");
        };
        var ViewClass = cell.getViewClass();
        var view = new ViewClass(cell);
        var specialShapeComponent = cell.findComponent && cell.findComponent(SpecialShapeComponent);
        if (specialShapeComponent) {
            specialShapeComponent.view.scaleViewContent();
        }
        return view;
    };
}());

var ElementsChooser = Chooser.extend({
    ctor: function (options) {
        var table = options.table || {};

        if (options.elements) {
            options.elements.forEach(function (ClassName) {
                var codes = ClassName.EDITOR_CODES || ClassName.CODES;
                var view = new cc.Node();
                var element = view.element = createViewForChoose(codes[0]);
                view.addChild(element);
                view.setContentSize2(element.getContentSize());
                view.setAnchorPoint(0.5, 0.5);
                element.setPosition(view.width / 2, view.height / 2);
                view.setPosition(view.width / 2, view.height / 2);
                if (codes.length > 1) {
                    table["subview_" + ClassName.EDITOR_CODES.join("#|#")] = view;
                } else {
                    table[codes[0]] = view;
                }
            });
        }

        this._super(table, {
            columns: 13
        });

        this.type = options.type;

        this.onFinalSelected = function () {};

        this.onSelect = this.onItemSelect.bind(this);
        this.savedSelected = {};
    },

    getData: function () {
        return {
            type: this.type,
            selected: this.savedSelected[this.selected] ? this.savedSelected[this.selected] : this.selected
        };
    },

    onItemSelect: function (code, view) {
        if (code.indexOf("subview_") === 0) {
            var subCodes = code.substr(8);
            subCodes = subCodes.split("#|#");
            var subTable = {};
            
            subCodes.forEach(function (subCode) {
                subTable[subCode] = createViewForChoose(subCode);
            });

            var subChooser = new Chooser(subTable, {
                bg: bundles.game.frames.tile_background_1,
                columns: 1
            });

            if (ElementsChooser.chooser) {
                ElementsChooser.chooser.removeFromParent();
            }
            ElementsChooser.chooser = subChooser;

            subChooser.setPosition(view.width / 2, subChooser.height / 2 + view.height);
            view.addChild(subChooser);

            var baseZorder = view.getLocalZOrder();
            view.setLocalZOrder(1);

            this.subCodes = subCodes;
            if (this.savedSelected[code] === undefined || (this.savedSelected[code] !== undefined && this.subCodes && this.subCodes.indexOf(this.savedSelected[code]) < 0)) {
                this.savedSelected[code] = subCodes[0];
            }
            this.subTable = subTable;
            this.subChooser = subChooser;

            subChooser.onBeforeSelect = function (subCode, subView) {
                ElementsChooser.chooser = undefined;
                subChooser.disabled = true;
                view.removeAllChildren();
                view.setLocalZOrder(baseZorder);

                subView.replaceParent(view);
                view.element = subView;
                subView.setPosition(view.width / 2, view.height / 2);

                this.savedSelected[code] = subCode;
                this.setSelected(code);
            }.bind(this);
        } else {
            this.savedSelected[code] = undefined;
        }

        this.showSkins(view, code);
    },

    showSkins: function (view, code) {
        if (!view.element) {
            return;
        }
        var cell = view.element.mark || view.element.cell || view.element.tile;
        if (!cell || !cell.getSkinBundles) {
            return;
        }

        var skins = cell.getSkinBundles();
        if (!skins || skins.length < 2) {
            return;
        }

        view.baseZorder = view.getLocalZOrder();
        view.setLocalZOrder(1);

        var elemCode = code;
        if (elemCode.indexOf("subview_") === 0) {
            elemCode = elemCode.substr(8);
            elemCode = elemCode.split("#|#")[0];
        }
        var skinTable = {};
        skins.forEach(function (skin) {
            skinTable[skin] = createViewForSkinChoose(elemCode, skin);
        });

        var skinChooser = new Chooser(skinTable, {
            bg: bundles.game.frames.tile_background_1,
            columns: skins.length,
            removeOnAsideClick: true
        });

        if (ElementsChooser.skinChooser) {
            ElementsChooser.skinChooser.removeFromParent();
        }
        ElementsChooser.skinChooser = skinChooser;
        addCleaner();
        skinChooser.addCleaner(function () {
            view.setLocalZOrder(view.baseZorder);
        });

        skinChooser.setPosition(view.width / 2, -view.height / 2);
        view.addChild(skinChooser);

        skinChooser.onBeforeSelect = function (subCode, subView) {
            ElementsChooser.skinChooser = undefined;
            skinChooser.removeFromParent();
            skinChooser.disabled = true;
            if (cell.bundleId() === subCode) {
                return;
            }

            Editor.currentEditor.setCellSkin(elemCode[0], subCode, cell);
            view.removeAllChildren();
            subView.replaceParent(view);
            view.element = subView;
            subView.setPosition(view.width / 2, view.height / 2);

            this.setSelected(code);
        }.bind(this);
    }
});