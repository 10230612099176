/**
 * Created by vladislav on 02.18.21.
 */

var AdsSpine = cc.Node.extend({
    ctor: function (properties) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);

        this.properties = properties || {};
        this.setContentSize(100, 100);

        if (cleverapps.wysiwyg.googleDriveProvider.hasAsset(this.properties.spine)) {
            if (cc.loader.getRes(cleverapps.wysiwyg.googleDriveProvider.getSpinePath(this.properties.spine) + ".png")) {
                this.showSpine();
            } else {
                cleverapps.RestClient.post(
                    "/wysiwyg/packSpines",
                    [this.properties.spine],
                    this.showSpine.bind(this),
                    function () {
                        cleverapps.notification.create("Pack spine " + this.properties.spine.name + " failed. Try to reload page to pack again.");
                    }.bind(this),
                    {
                        timeout: 30000,
                        ignoreNoRest: true
                    }
                );
            }
        }
    },

    hide: function () {
        this.setVisible(false);
    },

    show: function () {
        this.setVisible(true);

        if (this.properties.spine && this.properties.spine.idle && this.properties.spine.idle !== Wysiwyg.NOT_SELECTED) {
            this.spine.setAnimation(0, this.animationName, false);
            this.spine.addAnimation(0, this.properties.spine.idle, this.properties.spine.looped);
        } else if (this.animationName && this.animationName !== Wysiwyg.NOT_SELECTED) {
            this.spine.setAnimation(0, this.animationName, this.properties.spine.looped);
        }
    },

    adjustSpine: function () {
        var component = this.spine;
        if (!component) {
            return;
        }
        this.addChild(component);

        var scale = 1;
        var width = this.properties.width;
        var height = this.properties.height;

        if (width) {
            scale = width / component.width;
        }
        if (height) {
            scale = Math.min(scale, height / component.height);
        }
        component.setScale(scale);

        this.setContentSize((this.properties.contentWidth || component.getContentSize().width) * scale, (this.properties.contentHeight || component.height) * scale);
        component.setPositionRound(this.width / 2, this.height / 2);
        component.setScaleX(this.properties.flipX ? -1 : 1);
        component.setScaleY(this.properties.flipY ? -1 : 1);

        if (width && component.width) {
            var scaleX = width / component.width;
            component.setScaleX(this.properties.flipX ? -1 * scaleX : scaleX);
        }
        if (height && component.height) {
            var scaleY = height / component.height;
            component.setScaleY(this.properties.flipY ? -1 * scaleY : scaleY);
        }

        this.properties.rotation && component.setRotation(this.properties.rotation);

        this.parent && this.parent.onComponentSizeChanged();
    },

    showSpine: function () {
        var spinePath = cleverapps.wysiwyg.googleDriveProvider.getSpinePath(this.properties.spine);
        cc.loader.load([spinePath + ".json", spinePath + ".png", spinePath + ".atlas"], function () {
            this.spine = new cleverapps.Spine(spinePath + ".json", spinePath + ".atlas");
            var loadedSpine = cc.loader.getRes(cleverapps.wysiwyg.googleDriveProvider.getSpinePath(this.properties.spine) + ".json");

            this.animationName = this.spine.hasAnimation("animation") ? "animation" : this.spine.listAnimations()[0];

            if (this.properties.spine && this.properties.spine.animation) {
                if (loadedSpine.animations[this.properties.spine.animation]) {
                    this.animationName = this.properties.spine.animation;
                }

                if (this.properties.spine.animation.indexOf("%{lang}") !== -1) {
                    var localizedAnimationName = this.properties.spine.animation.replace("%{lang}", cleverapps.settings.language);
                    if (loadedSpine.animations[localizedAnimationName]) {
                        this.animationName = localizedAnimationName;
                    } else {
                        this.animationName = this.properties.spine.animation.replace("%{lang}", "en");
                    }
                }
            }

            var skinName = "default";
            if (this.properties.spine && this.properties.spine.skin) {
                skinName = this.properties.spine.skin;
            }

            var availableSkins = Object.keys(loadedSpine.skins);

            if (!this.properties.isPreview && this.properties.skinFormat) {
                var name = this.properties.skinFormat.replace("%{lang}", cleverapps.settings.language);
                var index = availableSkins.indexOf(name);
                if (index !== -1) {
                    skinName = availableSkins[index];
                }
            }

            if (skinName !== "default" && availableSkins.indexOf(skinName) !== -1) {
                this.spine.setSkin(skinName);
            }

            this.show();

            this.adjustSpine();

            this.showLoading(false);
        }.bind(this));
    },

    showLoading: function (visible) {
        if (this.loading) {
            this.loading.removeFromParent();
            delete this.loading;
        }

        if (visible && this.properties.preview) {
            this.loading = new cc.Sprite(bundles.wysiwyg.frames.loading);
            this.loading.runAction(new cc.RepeatForever(new cc.RotateBy(5, 360)));
            this.loading.setPositionRound(this.width / 2, this.height / 2);
            this.addChild(this.loading);
        }
    }
});

AdsSpine.PROPERTIES = [{
    name: "spine",
    type: "spine",
    folders: ["spines", "persons"],
    value: {
        folder: "spines",
        skin: "default",
        animation: "animation",
        looped: false,
        name: "cloud"
    },
    bubbleUp: true
}, {
    name: "flipX", type: "checkBox"
}, {
    name: "flipY", type: "checkBox"
}, {
    name: "contentWidth", type: "number"
}, {
    name: "contentHeight", type: "number"
}, {
    name: "skinFormat", type: "string" // , value: 'grass_%{lang}'
}, {
    name: "showOnEvent", type: "string", items: [Wysiwyg.NOT_SELECTED].concat(Object.values(Wysiwyg.EVENTS)), value: Wysiwyg.NOT_SELECTED
}];