/**
 * Created by slava on 22/8/19
 */

ColorComponent.CODES = [
    ColorComponent.COLOR_A,
    ColorComponent.COLOR_B,
    ColorComponent.COLOR_C,
    ColorComponent.COLOR_D,
    ColorComponent.COLOR_E,
    ColorComponent.COLOR_W
];

FlowerCell.CODES = ["f"];
ColorContainerCell.CODES = ["g"];
IceCreamMakerCell.CODES = ["i"];
GumDecoratorCell.CODES = ["k"];
MultiColorBombCell.CODES = ["l"];
CannonCell.CODES = ["m"];
MovesCell.CODES = ["n"];
DirtyHoneyDecoratorCell.CODES = ["o"];
DonutBoxCell.CODES = ["p"];
DirtMakerCell.CODES = ["q"];
BurstCell.CODES = ["r"];
SmallPetardCell.CODES = ["s"];
ColorBombCell.CODES = ["t"];
ClockCell.CODES = ["u"];
FireflyJarCell.CODES = ["v"];
SharkCell.CODES = ["x"];
DogCell.CODES = ["y"];

GoalCoefComponent.CODES = ["A", "B", "C", "D", "E", "W"];
FishCell.CODES = ["F"];
GingerHouseCell.CODES = ["H"];
MuffinComponent.CODES = ["I"];
CookieManCell.CODES = ["J"];
CakeCell.CODES = ["K"];
MuffinGoalCell.CODES = ["L"];
MouseCell.CODES = ["M"];
NutCell.CODES = ["N"];
LollipopCell.CODES = ["O"];
GingerHousePartCell.CODES = ["P"];
TruffleCell.CODES = ["Q"];
FireworkCell.CODES = ["R"];
WaffleCell.CODES = ["S"];
DragonCell.CODES = ["U"];
ColorCookieManCell.CODES = ["V"];
CandleCell.CODES = ["X"];
IceMakerCell.CODES = ["Y"];
BombCell.CODES = ["Z"];
CoinCell.CODES = ["+"];

BoxDecoratorCell.CODES = ["~"];
CrabMakerCell.CODES = ["$"];
DirtDecoratorCell.CODES = ["%"];
BagDecoratorCell.CODES = ["^"];
CrabDecoratorCell.CODES = ["&"];
MultiColorCell.CODES = ["*"];
SuperMultiColorCell.CODES = ["("];
ChainDecoratorCell.CODES = ["-"];
IceBlockDecoratorCell.CODES = [":"];

BlanketCell.CODES = ["п"];
FigureDragonCell.CODES = ["ш"];
WallCell.CODES = ["ц"];

HeroCell.CODES = ["ю"];
HeroCell.GOAL_CODES = ["я"];
ComboCell.CODES = ["ж"];

Loader.WILDCARD = ".";

BoosterCell.CODES = ["ф"];
TulpanCell.CODES = ["ы"];