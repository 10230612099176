/**
 * Created by slava on 01.08.17.
 */

var ViewHelper = {
    flyOut: function () {
        var grandParent = this.parent.parent.getParent();
        var position = this.parent.parent.getPosition();

        this.replaceParent(grandParent);

        this.setPosition(position);
    }
};