/**
 * Created by andrey on 03.05.17.
 */

var EventsLogger = function () {
    this.loggers = this.initLoggers();

    this.logPurchase = this.loggersApply.bind(this, "logPurchase");
    this.logAdRevenue = this.loggersApply.bind(this, "logAdRevenue");

    this.onUpdateUserId = this.loggersApply.bind(this, "onUpdateUserId");
    this.onUpdateScreen = this.loggersApply.bind(this, "onUpdateScreen");
};

EventsLogger.prototype.initLoggers = function () {
    var loggers = [];
    loggers.push(new RestLogger());

    if (AdjustLogger.isApplicable()) {
        loggers.push(new AdjustLogger());
    }

    if (InstantLogger.isApplicable()) {
        loggers.push(new InstantLogger());
    }

    if (SamsungLogger.isApplicable()) {
        loggers.push(new SamsungLogger());
    }

    if (GoogleAnalyticsLogger.isApplicable()) {
        loggers.push(new GoogleAnalyticsLogger());
    }

    if (FirebaseLogger.isApplicable()) {
        loggers.push(new FirebaseLogger());
    }

    if (ThinkingDataLogger.isApplicable()) {
        loggers.push(new ThinkingDataLogger());
    }

    if (GravityEngineLogger.isApplicable()) {
        loggers.push(new GravityEngineLogger());
    }

    if (YandexMetricaLogger.isApplicable()) {
        loggers.push(new YandexMetricaLogger());
    }

    if (FacebookPixelLogger.isApplicable()) {
        loggers.push(new FacebookPixelLogger());
    }

    return loggers;
};

EventsLogger.prototype.logEvent = function (name, params) {
    if (cleverapps.flags && cleverapps.flags.noLogEvents) {
        return;
    }

    this.loggersApply("logEvent", name, params || {});
};

EventsLogger.prototype.loggersApply = function () {
    var method = arguments[0];
    var args = Array.prototype.slice.call(arguments, 1);

    for (var index = 0; index < this.loggers.length; index++) {
        var logger = this.loggers[index];
        if (logger[method]) {
            logger[method].apply(logger, args);
        }
    }
};
