/**
 * Created by spepa on 20.12.2022
 */

var PromotionSaleWindow = CleverappsWindow.extend({
    ctor: function (offer) {
        this.logic = offer.logic;
        this.bundle = bundles[offer.bundle];

        this._super(offer);
    },

    onWindowLoaded: function () {
        this._super({
            name: "PromotionSaleWindow",
            noBackground: true,
            notCloseByTouchInShadow: true,
            closeButtonDelay: true,
            fireworks: true,
            content: this.createContent(),
            openSound: this.bundle.urls.bonussale_showup_effect
        });

        cleverapps.meta.hideAllControls();
        cleverapps.meta.showControlsWhileFocused(["MenuBarGoldItem", "MenuBarCoinsItem", "MenuBarLivesItem"]);

        this.logic.onUpdateLots = this.createListener(this.onLotBought.bind(this));
    },

    onShow: function () {
        this._super();

        this.runAction(new cc.Sequence(
            new cc.DelayTime(1),
            new cc.CallFunc(this.logic.onWindowShowUpFinished.bind(this.logic))
        ));
    },

    createContent: function () {
        var styles = cleverapps.styles.PromotionSaleWindow[this.logic.offer.type];

        var content = this.content = new cleverapps.Spine(this.bundle.jsons.bonussale_bg);
        content.setAnimationAndIdleAfter("open", "idle");

        var lots = this.lots = this.logic.getLots().map(function (lot, ind) {
            var lotNode = new PromotionSaleLot(lot, this.logic);
            lotNode.setLocalZOrder(2 - ind);
            return lotNode;
        }.bind(this));

        var lotsLayout = new cleverapps.Layout(lots, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.lots.marginX,
            padding: styles.lots.padding
        });
        lotsLayout.setPositionRound(styles.lots);
        this.content.addChild(lotsLayout);

        var description = cleverapps.UI.generateOnlyText("PromotionSaleWindow.description", cleverapps.styles.FONTS.BONUSBG_DESCRIPTION);
        description.setDimensions(styles.description.width, 0);
        description.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        description.fitTo(undefined, styles.description.height);
        description.setPositionRound(styles.description);
        this.content.addChild(description);

        this.createHeader();
        this.createTimer();
        return content;
    },

    createHeader: function () {
        var styles = cleverapps.styles.PromotionSaleWindow[this.logic.offer.type];

        if (this.bundle.jsons.bonussale_header) {
            var header = new cleverapps.Spine(this.bundle.jsons.bonussale_header);
            header.setAnimation(0, "animation", false);
            header.setPositionRound(styles.header);
            this.content.addChild(header);
        }

        var msg = Messages.has("PromotionSaleWindow." + this.logic.offer.name + ".title") || "PromotionSaleWindow.title";
        var title = cleverapps.UI.generateOnlyText(msg, styles.title.font || cleverapps.styles.FONTS.BONUSBG_TITLE);
        title.fitTo(styles.title.width, styles.title.height);
        title.setPositionRound(styles.title);
        this.content.addChild(title);
    },

    createTimer: function () {
        var styles = cleverapps.styles.PromotionSaleWindow[this.logic.offer.type].timer;
        if (!styles) {
            return;
        }

        var countDown = new cleverapps.CountDown(this.logic.getTimeLeft(), {
            onFinish: this.close.bind(this)
        });
        var timer = new cleverapps.CountDownView(countDown, {
            font: cleverapps.styles.FONTS.BONUSBG_TIMER
        });
        timer.setPositionRound(styles);
        this.content.addChild(timer);
    },

    onLotBought: function () {
        this.lots.forEach(function (lotView) {
            lotView.updateState();
        });

        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.5),
            new cc.CallFunc(function () {
                if (!this.closed) {
                    this.close();
                }
            }.bind(this))
        ));
    },

    listBundles: function (offer) {
        return [offer.bundle];
    },

    getPerson: function () {
        return cleverapps.styles.PromotionSaleWindow[this.logic.offer.type].person || cleverapps.persons.choose("jihn", "dwarf");
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    BONUSBG_TITLE: {
        size: 100,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE
    },

    BONUSBG_TIMER: {
        size: 50,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE
    },

    PR_BONUSBG_TITLE: {
        size: 100,
        color: cleverapps.styles.COLORS.COLOR_DARK_YELLOW,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE
    },

    BONUSBG_DESCRIPTION: {
        size: 60,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE
    }
});

cleverapps.styles.PromotionSaleWindow = {};
cleverapps.styles.PromotionSaleWindow[Offers.TYPES.PROMOTION_SALE] = {
    title: {
        x: { align: "center", dx: -10 },
        y: { align: "center", dy: 452 },

        width: 440,
        height: 110
    },

    description: {
        x: { align: "center", dx: -10 },
        y: { align: "bottom", dy: -20 },

        width: 1200,
        height: 70
    },

    header: {
        x: { align: "center" },
        y: { align: "top", dy: -40 }
    },

    lots: {
        x: { align: "center", dx: -8 },
        y: { align: "center", dy: -20 },

        marginX: 32,

        padding: {
            top: 140,
            bottom: 0
        }
    }
};
cleverapps.styles.PromotionSaleWindow[Offers.TYPES.CLPROMOTION_SALE] = cleverapps.styles.PromotionSaleWindow[Offers.TYPES.PROMOTION_SALE];

cleverapps.styles.PromotionSaleWindow[Offers.TYPES.PERIODIC_PROMOTION_SALE] = cleverapps.overrideStyles(cleverapps.styles.PromotionSaleWindow[Offers.TYPES.PROMOTION_SALE], {
    person: "bonussale_person",
    title: {
        font: cleverapps.styles.FONTS.PR_BONUSBG_TITLE,
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 421 }
    },

    lots: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 30 },
        marginX: 25
    },

    timer: {
        x: { align: "center", dx: 0 },
        y: { align: "top", dy: -145 }
    },

    description: {
        y: { align: "bottom", dy: -50 }
    }

}, true);