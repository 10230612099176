/**
 * Created by Andrey Popov on 2/19/21.
 */

var MergePuzzleLogic = function (width, height, game) {
    this.fieldWidth = width || 7;
    this.fieldWidth = Math.max(this.fieldWidth, 5);
    this.fieldWidth = Math.min(this.fieldWidth, 8);

    this.fieldHeight = height || 7;
    this.fieldHeight = Math.max(this.fieldHeight, 5);
    this.fieldHeight = Math.min(this.fieldHeight, 8);

    this.game = game;
    this.goalsCount = Math.floor((this.fieldWidth * this.fieldHeight - 1) / 3);
    this.goalsDone = 0;
    this.justMovedUnits = [];
};

MergePuzzleLogic.prototype.createUnits = function () {
    var chosenTypes = [];
    var map = this.game.map;
    var iterationsLimit = 10000;
    var hardcodedPositions = [
        [{ x: 0, y: 0 }, { x: 1, y: 0 }, { x: 3, y: 0 }],
        [{ x: 0, y: 1 }, { x: 2, y: 1 }, { x: 4, y: 0 }],
        [{ x: 3, y: 1 }, { x: 1, y: 2 }, { x: 1, y: 3 }],
        [{ x: 2, y: 2 }, { x: 4, y: 1 }, { x: 0, y: 2 }],
        [{ x: 1, y: 1 }, { x: 4, y: 2 }, { x: 3, y: 2 }]
    ];
    for (var i = 0; i < this.goalsCount; i++) {
        var unitType;
        do {
            unitType = cleverapps.Random.random(22);
        } while (chosenTypes.indexOf(unitType) !== -1);
        chosenTypes.push(unitType);

        [0, 1, 2].forEach(function (index) {
            var x, y;
            if (i < hardcodedPositions.length) {
                x = hardcodedPositions[i][index].x;
                y = hardcodedPositions[i][index].y; 
            } else {
                var isAvailable = false;
                do {
                    x = cleverapps.Random.random(map.width);
                    y = cleverapps.Random.random(map.height);
                    if (x === 2 && y === 0) {
                        continue;
                    }

                    var bfs = map.bfs(x, y, map.compareMergeable.bind(map, new Puzzle15Unit(undefined, { type: unitType }))).length;
                    isAvailable = map.getUnit(x, y) === undefined && bfs < 3;

                    if (iterationsLimit-- === 0) {
                        map.listAvailableGrounds().forEach(function (cell) {
                            map.remove(Map2d.LAYER_UNITS, cell.x, cell.y);
                        }, this);
                        this.createUnits(map);
                        return;
                    }
                } while (!isAvailable);
            }
            var unit = new Puzzle15Unit(map, { type: unitType, x: x, y: y });
            map.add(Map2d.LAYER_UNITS, x, y, unit);
        }.bind(this));
    }
};

MergePuzzleLogic.prototype.getPlayActions = function () {
    var actions = [];
    var map = this.game.map;
    var savedUnitsLayer = map.layers[Map2d.LAYER_UNITS].map(function (arr) {
        return arr.slice();
    });

    do {
        var move = this.findMove(this.goalsCount === this.goalsDone + 1);
        move.unit.move(move.path);
        this.afterMove(map, move, true);

        actions.push(move);
    } while (this.goalsCount !== this.goalsDone);

    // repair map
    map.layers[Map2d.LAYER_UNITS] = savedUnitsLayer;
    for (var x = 0; x < map.width; x++) {
        for (var y = 0; y < map.height; y++) {
            var unit = map.getUnit(x, y);
            if (unit !== undefined) {
                unit.x = x;
                unit.y = y;
            }
        }
    }
    this.goalsDone = 0;

    return actions;
};

MergePuzzleLogic.prototype.findMove = function (lastMove) {
    var maxEstimate = 0, chosenUnit = undefined, path = undefined;
    this.game.map.listAvailableUnits().filter(function (candidateUnit) {
        return candidateUnit.canMove() && (this.justMovedUnits.indexOf(candidateUnit) === -1 || lastMove);
    }.bind(this)).forEach(function (movableUnit) {
        var test = this.game.logic.estimateMoves(this.game.map, movableUnit, 0);
        if (test.estimate > maxEstimate || (test.estimate === maxEstimate && path && test.path.length < path.length)) {
            maxEstimate = test.estimate;
            chosenUnit = movableUnit;
            path = test.path;
        }
    }.bind(this));

    this.justMovedUnits.push(chosenUnit);
    if (this.justMovedUnits.length > 3) {
        this.justMovedUnits.shift();
    }

    return {
        unit: chosenUnit,
        path: path
    };
};

MergePuzzleLogic.prototype.estimateMoves = function (map, unit, depth) {
    var maxAffected = -1, cellToMove, minPath = undefined;
    for (var fieldCell = 0; fieldCell < this.fieldWidth * this.fieldHeight; fieldCell++) {
        var x = fieldCell % this.fieldWidth;
        var y = (fieldCell - x) / this.fieldWidth;
        if (map.getUnit(x, y) !== undefined && map.getUnit(x, y).isMergeable(unit)) {
            map.remove(Map2d.LAYER_UNITS, unit.x, unit.y);
            var affected = map.bfs(x, y, map.compareMergeable.bind(map, unit)).length;
            map.add(Map2d.LAYER_UNITS, unit.x, unit.y, unit);

            for (var i = 0; i < ISO_NEIGHBORS.length; i++) {
                var tx = x + ISO_NEIGHBORS[i].x;
                var ty = y + ISO_NEIGHBORS[i].y;
                if (tx < 0 || ty < 0 || tx >= map.width || ty >= map.height) {
                    continue;
                }
                if (map.getUnit(tx, ty) === undefined) {
                    var path = map.findPath(unit, { x: tx, y: ty });
                    if (path && (affected > maxAffected || (affected === maxAffected && path.length < minPath.length))) {
                        maxAffected = affected;
                        cellToMove = { x: tx, y: ty };
                        minPath = path;
                    }
                }
            }
        }
    }

    var accumulatedEstimate = (3 - depth) * maxAffected * (maxAffected === 3 ? 3 : 1);
    if (depth < 2 && maxAffected !== 3 && cellToMove) {
        var savedX = unit.x;
        var savedY = unit.y;
        map.remove(Map2d.LAYER_UNITS, unit.x, unit.y);
        map.add(Map2d.LAYER_UNITS, cellToMove.x, cellToMove.y, unit);
        unit.x = cellToMove.x;
        unit.y = cellToMove.y;

        depth++;
        var nextStepMaxEstimate = 0;
        map.listAvailableUnits().filter(function (candidateUnit) {
            return candidateUnit.canMove();
        }).forEach(function (unitToMove) {
            var nextStepEstimate = this.estimateMoves(map, unitToMove, depth).estimate;
            if (nextStepEstimate > nextStepMaxEstimate) {
                nextStepMaxEstimate = nextStepEstimate;
            }
        }.bind(this));

        accumulatedEstimate += nextStepMaxEstimate;
        map.remove(Map2d.LAYER_UNITS, cellToMove.x, cellToMove.y);
        map.add(Map2d.LAYER_UNITS, savedX, savedY, unit);
        unit.x = savedX;
        unit.y = savedY;
    }

    return {
        estimate: accumulatedEstimate,
        path: minPath
    };
};

MergePuzzleLogic.prototype.afterMove = function (map, move, silent, successCallback) {
    var affected = map.bfs(move.unit.x, move.unit.y, map.compareMergeable.bind(map, move.unit));
    if (affected.length > 2) {
        affected.forEach(function (unitToDelete) {
            map.getUnit(unitToDelete.x, unitToDelete.y).delete(silent);
        });

        this.goalsDone++;

        if (!silent && successCallback) {
            successCallback(this.goalsDone / this.goalsCount);
        }
    }
};