/**
 * Created by vladimir on 29.08.17.
 */

var FlowerAnimationView = BaseAnimation.extend({
    ctor: function () {
        this._super(bundles.flower_cell.jsons.flower_spine_json);
    }
});

FlowerAnimationView.animations = {
    2: "animation_3_2",
    1: "animation_2_1",
    0: "animation_1_0"
};
