/**
 * Created by Andrey Popov on 3/17/21.
 */

var DialoguePersonTitleView = cc.Scale9Sprite.extend({
    ctor: function (person) {
        var styles = cleverapps.styles.DialoguePersonTitleView;

        this._super(styles.image, cleverapps.UI.getScale9Rect(styles.image, cleverapps.UI.Scale9Rect.TwoPixelXY));

        this.setAnchorPoint(0.5, 0.5);
        this.setCascadeOpacityEnabled(true);
        this.setVisible(false);
        this.isSnown = false;

        var originalSize = this.getContentSize();

        var title = Messages.has("Dialogue.person." + person.role) && Messages.get("Dialogue.person." + person.role);
        if (!title) {
            if (cleverapps.config.debugMode) {
                console.error("person has no name! Dialogue.person." + person.role + " is missing in localization");
            }

            title = Messages.get("Dialogue.person.noName");
        }

        var titleText = cleverapps.UI.generateOnlyText(title, cleverapps.styles.FONTS.DIALOGUE_PERSON_TITLE_TEXT);
        titleText.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        this.addChild(titleText);

        this.setContentSize2(
            Math.max(titleText.width + styles.text.padding.x * 2, originalSize.width),
            Math.max(titleText.height + styles.text.padding.y * 2, originalSize.height)
        );
        this.updateSize();

        titleText.setPositionRound(styles.text);
    },

    updateSize: function () {
        this.setScale(cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? 0.6 : 1);
    },

    show: function () {
        if (this.isShown) {
            return;
        }

        this.stopAllActions();
        this.isSnown = true;

        this.setOpacity(0);
        this.setVisible(true);

        this.runAction(new cc.FadeIn(0.2));
    },

    hide: function () {
        if (!this.isSnown) {
            return;
        }

        this.stopAllActions();
        this.isSnown = false;

        this.runAction(new cc.Sequence(
            new cc.FadeOut(0.2),
            new cc.Hide()
        ));
    }
});

cleverapps.styles.DialoguePersonTitleView = {
    image: bundles.dialogues.frames.dialogue_name,

    text: {
        x: { align: "center" },
        y: { align: "center", dy: -3 },
        padding: {
            x: 5,
            y: 0
        }
    }
};
