/**
 * Created by spepa on 13.05.2024
 */

cleverapps.personDemo = function () {
    var params = cleverapps.getRequestParameters();
    if (params.personDemo === undefined || !cleverapps.config.debugMode) {
        return;
    }

    var startDialogue = function (role) {
        cleverapps.meta.displayWhenFreeFocus({
            focus: "LevelTutorial",
            action: function (f) {
                var steps = [
                    {
                        text: "Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.",
                        person: role,
                        person2: {
                            role: role,
                            orientation: "right"
                        }
                    },
                    {
                        text: "Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.",
                        person: {
                            role: role,
                            orientation: "right"
                        },
                        person2: role
                    }
                ];

                var dialogue = new Dialogue(steps, {
                    showUp: true,
                    autoClose: true,
                    autoScroll: false,
                    bundles: [params.personDemo]
                });

                dialogue.on("afterClose", f);
                new DialogueView(dialogue);
            }
        });
    };

    cleverapps.whenAllInitialized(function () {
        for (var role in cleverapps.persons.data) {
            if (cleverapps.persons.data[role].name === params.personDemo) {
                startDialogue(role);
            }
        }
    });
};
