/**
 * Created by Andrey Popov on 15.10.20
 */

var AdminLevelFlagsPanel = cleverapps.Layout.AsyncChild.extend({
    ctor: function (adminLevel, maxi) {
        this._super();

        this.adminLevel = adminLevel;
        this.maxi = maxi;

        if (!maxi) {
            this.setScale(0.8);
        }

        this.updateFlags();

        adminLevel.on("update", this.updateFlags.bind(this), this);
        if (AdminConfig.getConfig("difficulty")) {
            adminLevel.on("changeDifficulty", this.updateFlags.bind(this), this);
        }
    },

    updateFlags: function () {
        this.removeAllChildren();

        if (this.adminLevel.dragging) {
            return;
        }

        var events = this.listBoosters().concat(cleverapps.administrator.simpleMethaDataProvider.getEvents(parseInt(this.adminLevel.episodeNo), this.adminLevel.levelNo));

        var flags = events.filter(function (event) {
            return event.icon;
        }).map(function (event) {
            var yellowBanner = new cc.Sprite(bundles.admin.frames.yellow_flag);

            var icon = new cc.Sprite(event.icon);
            icon.setPositionRound(yellowBanner.width / 2, yellowBanner.height / 2);
            yellowBanner.addChild(icon);

            cleverapps.UI.fitToBox(icon, {
                width: yellowBanner.width * 0.8,
                height: yellowBanner.height * 0.8
            });

            if (event.tooltip) {
                cleverapps.tooltipManager.create(yellowBanner, {
                    text: event.tooltip,
                    position: cleverapps.UI.Tooltip.LOCATION_BELOW,
                    size: cleverapps.styles.UI.Tooltip.SIZE.short
                });
            }

            return yellowBanner;
        });

        var config = AdminConfig.getConfig("difficulty");
        if (config && (this.adminLevel.difficulty < config.bottom || this.adminLevel.difficulty > config.top)) {
            flags.unshift(new cc.Sprite(bundles.admin.frames.attention_icon));
        }

        var styles = cleverapps.styles.AdminLevelFlagsPanel;

        var layout = new cleverapps.Layout(flags, {
            direction: this.maxi ? cleverapps.UI.HORIZONTAL : cleverapps.UI.VERTICAL,
            margin: styles.margin
        });
        this.reshape(layout);
    },

    listBoosters: function () {
        var allBoosters = cleverapps.boosters.listAll();
        return allBoosters.filter(function (booster) {
            return this.isDiscoveredHere(booster.available);
        }.bind(this)).map(function (booster) {
            return Object.assign({}, cleverapps.Boosters.CONFIG[booster.id], {
                icon: this.getBoosterIcon(booster.id)
            });
        }, this);
    },

    isDiscoveredHere: function (available) {
        if (available.level === undefined) {
            available.level = 0;
        }
        if (available.episode === undefined) {
            available.episode = 0;
        }

        return parseInt(this.adminLevel.episodeNo) === available.episode && this.adminLevel.levelNo === available.level;
    },

    getBoosterIcon: function (booster) {
        var icons = {};
        icons[cleverapps.Boosters.TYPE_CELL] = bundles.admin.frames.cellbooster;
        icons[cleverapps.Boosters.TYPE_LINE] = bundles.admin.frames.linebooster;
        icons[cleverapps.Boosters.TYPE_COLOR] = bundles.admin.frames.colorbooster;
        icons[cleverapps.Boosters.TYPE_HINT] = bundles.admin.frames.hint_icon_png;
        icons[cleverapps.Boosters.TYPE_WILDCARD] = bundles.admin.frames.wildcard_png;
        icons[cleverapps.Boosters.TYPE_SHUFFLE] = bundles.admin.frames.shuffle_png;
        icons[cleverapps.Boosters.TYPE_BRUSH] = bundles.admin.frames.pot;
        icons[cleverapps.Boosters.TYPE_DISCOVER] = bundles.admin.frames.hat;
        if (typeof match3 !== "undefined") {
            icons[cleverapps.Boosters.TYPE_COMBO] = bundles.admin.frames.heroes_png;
        }
        icons[cleverapps.Boosters.TYPE_MOVES] = bundles.admin.frames.moves_png;
        icons[cleverapps.Boosters.TYPE_MULTICOLOR] = bundles.admin.frames.multicolor_png;
        icons[cleverapps.Boosters.TYPE_MAGNET] = bundles.admin.frames.magnet_png;
        icons[cleverapps.Boosters.TYPE_DICE] = bundles.admin.frames.dice_png;
        icons[cleverapps.Boosters.TYPE_JOKERS] = bundles.admin.frames.joker_png;
        icons[cleverapps.Boosters.TYPE_HIGHLIGHT] = bundles.admin.frames.highlight;
        icons[cleverapps.Boosters.TYPE_EYE] = bundles.admin.frames.eye;
        icons[cleverapps.Boosters.TYPE_MAGNIFIER] = bundles.admin.frames.hint;
        return icons[booster];
    }
});

cleverapps.styles.AdminLevelFlagsPanel = {
    margin: 10
};