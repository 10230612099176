/**
 * Created by vladislav on 15/03/2023
 */

var DebugStats = function () {
    this.setEnabled(cleverapps.config.showFPS);

    cc.eventManager.addCustomListener(cc.game.EVENT_SHOW, function () {
        cc.director.setDisplayStats(this.enabled);
    }.bind(this));

    this.addCustomProfiler();
};

DebugStats.prototype.addCustomProfiler = function () {
    if (connector.platform.oneOf(connector.WECHAT)) {
        return;
    }

    var position = {
        left: "0px",
        bottom: "90px"
    };
    if (engine === "creator") {
        position = {
            left: "0px",
            bottom: "0px"
        };
    }

    var container = this.profiler = document.createElement("div");
    container.style.position = "absolute";
    container.style.padding = "3px";
    container.style.backgroundColor = "rgb(0, 0, 34)";
    container.style.bottom = position.bottom;
    container.style.left = position.left;
    container.style.color = "rgb(0, 255, 255)";
    container.style.font = "bold 12px Helvetica, Arial";

    if (cleverapps.config.buildTime) {
        var buildTime = this.profilerBuildTime = document.createElement("div");
        buildTime.style.color = "rgb(255, 255, 255)";
        container.appendChild(buildTime);
    }

    this.updateTimeShift();

    if (container.children.length) {
        cc.container.appendChild(container);
    }

    this.profilerListener = cc.eventManager.addCustomListener(cc.Director.EVENT_AFTER_VISIT, this.updateProfiler.bind(this));
};

DebugStats.prototype.toggle = function () {
    this.setEnabled(!this.enabled);
};

DebugStats.prototype.setEnabled = function (enabled) {
    this.enabled = enabled;

    if (this.profiler) {
        this.profiler.style.visibility = this.enabled ? "visible" : "hidden";
    }
    cc.director.setDisplayStats(this.enabled);
};

DebugStats.prototype.updateProfiler = function () {
    if (this.profilerBuildTime) {
        this.profilerBuildTime.innerText = DebugStats.formatDate(
            DateShift.realDate() - DateShift.realDate(cleverapps.config.buildTime),
            true
        );
    }

    this.updateTimeShift();
};

DebugStats.prototype.updateTimeShift = function () {
    if (DateShift.getDelta()) {
        if (!this.profilerTimeShift) {
            this.profilerTimeShift = document.createElement("div");
            this.profiler.appendChild(this.profilerTimeShift);
        }

        this.profilerTimeShift.innerText = DebugStats.formatDate(new Date());
    } else if (this.profilerTimeShift) {
        this.profilerTimeShift.remove();
        this.profilerTimeShift = undefined;
    }
};

DebugStats.formatDate = function (date, diffOnly) {
    date = DateShift.realDate(date);

    var format = function (value) {
        switch (String(value).length) {
            case 0: return "00";
            case 1: return "0" + value;
            default: return value;
        }
    };

    if (diffOnly) {
        return format(date.getUTCHours()) + ":" + format(date.getUTCMinutes()) + ":" + format(date.getUTCSeconds());
    }

    return format(date.getDate()) + "." + format(date.getMonth() + 1) + "." + date.getFullYear()
        + " " + format(date.getHours()) + ":" + format(date.getMinutes()) + ":" + format(date.getSeconds());
};

if (engine === "creator") {
    cc.profiler.generateCanvas = cleverapps.extendFunc(cc.profiler.generateCanvas, function () {
        this._super.apply(this, arguments);

        cc.profiler._ctx.fillStyle = "black";
        cc.profiler._ctx.fillRect(0, 0, 500, 500);
        cc.profiler._ctx.fillStyle = "#00EFFF";
    });
}
