/**
 * Created by andrey on 24.05.17.
 */
var ToolFolder = function (listItems) {
    this.listItems = listItems;
};

ToolFolder.create = function (listItems) {
    if (!listItems) {
        return undefined;
    }
    return new ToolFolder(listItems);
};

var displayToolPrompt = function (callback, text, defaultVal) {
    if (connector.info.isNative || connector.platform.oneOf(connector.INSTANT, connector.WECHAT, connector.YOUTUBE, connector.GAMESNACK)) {
        cleverapps.meta.display({
            focus: "Prompt",
            action: function (f) {
                new PromptWindow({ callback: callback, text: text, defaultVal: defaultVal });
                cleverapps.meta.onceNoWindowsListener = f;
            }
        });
    } else {
        var value = window.prompt(text, defaultVal);
        if (value !== null && value !== undefined) {
            callback(value);
        }
    }
};

var displayToolWindow = function (func) {
    return function () {
        cleverapps.meta.display({
            stack: true,
            focus: "ToolWindow",
            action: function (f) {
                func();
                cleverapps.meta.onceNoWindowsListener = f;
            }
        });
    };
};

var splitEntriesInParts = function f(items, actionFunc) {
    var res = {};
    var batch = {};

    items.forEach(function (item, ind) {
        batch[item.name] = actionFunc.bind(undefined, item, ind);

        if (Object.keys(batch).length === 30) {
            res["part" + Object.keys(res).length] = batch;
            batch = {};
        }
    });

    if (Object.keys(batch).length) {
        res["part" + Object.keys(res).length] = batch;
    }
    return res;
};

// eslint-disable-next-line no-unused-vars
var dialogues = function () {
    var showDialogue = function (steps, options) {
        options = options || {};

        cleverapps.meta.display({
            focus: "ToolDialogue",
            action: function (f) {
                var dialogue = new Dialogue(steps, {
                    showUp: true,
                    autoClose: true,
                    autoScroll: false,
                    bundles: [options.bundle]
                });

                dialogue.on("afterClose", f);
                new DialogueView(dialogue);
            }
        });
    };

    var mapToMenu = function (menu) {
        var res = {};

        Object.keys(menu).forEach(function (title) {
            res[title] = function () {
                var item = menu[title];
                if (item.options) {
                    showDialogue(item.steps, item.options);
                } else {
                    showDialogue(item);
                }
            };
        });

        return res;
    };

    var listPersons = function () {
        var res = {};

        var allPersons = cleverapps.persons.data;
        for (var role in allPersons) {
            var personName = allPersons[role].name;

            res[role + ":" + personName] = {
                steps: [
                    {
                        // eslint-disable-next-line max-len
                        text: "Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.",
                        person: role,
                        person2: {
                            role: role,
                            orientation: "right"
                        }
                    },
                    {
                        text: "Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.",
                        person: {
                            role: role,
                            orientation: "right"
                        },
                        person2: role
                    },
                    {
                        text: "Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.",
                        person: role,
                        person2: {
                            role: role,
                            orientation: "right"
                        }
                    }
                ],
                options: { bundle: personName }
            };
        }

        return res;
    };

    var listMinimals = function () {
        var minimals = {};

        Object.keys(cleverapps.config.personsMinimal).forEach(function (role) {
            var personName = cleverapps.config.personsMinimal[role];

            var showMinimal = function () {
                cleverapps.meta.display({
                    focus: "testMinimal",
                    action: function (f) {
                        cleverapps.forces.create(ForceView.NO_ELEMENT, {
                            text: "Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.",
                            person: role,
                            delay: 0.5,
                            position: Dialogue.POSITIONS.CENTER
                        });
                        cleverapps.forces.onceForceClosed = f;
                    }
                });
            };

            minimals[role + ":" + personName] = function () {
                showMinimal();
            };
        });

        return minimals;
    };

    var listPlot = function () {
        var steps = {};
        for (var article in cleverapps.styles.dialogues) {
            var step = cleverapps.styles.dialogues[article].dialogue;
            steps[article] = step;
        }

        return steps;
    };

    var res = {
        persons: mapToMenu(listPersons()),
        minimals: listMinimals(),
        plot: mapToMenu(listPlot())
    };

    if (cleverapps.styles.comicses) {
        res.comics = (function () {
            var comicses = {};
            Object.keys(cleverapps.styles.comicses).forEach(function (key) {
                comicses[ColorComponent.CODE_COLORS[key] ? ColorComponent.CODE_COLORS[key] : key] = function () {
                    cleverapps.meta.display({
                        focus: "ToolComics",
                        actions: [
                            function (f) {
                                ComicsScene.showComics(f, cleverapps.styles.comicses[key]);
                            },
                            function (f) {
                                cleverapps.travelBook.gotoMainScene(f);
                            }]
                    });
                };
            });

            return comicses;
        }());
    }

    return res;
};

cleverapps.Tool = {
    snapshots: ToolFolder.create(function () {
        var res = {
            resetDate: function () {
                cleverapps.snapshots.extractData(function (snapshot) {
                    var delta = DateShift.getDelta();

                    var expandTime = cleverapps.expandTime;
                    cleverapps.expandTime = function (time, options) {
                        return expandTime.call(this, time, options) - delta;
                    };

                    if (cleverapps.config.type === "merge") {
                        var expandSeconds = UnitPacker.expandSeconds;
                        UnitPacker.expandSeconds = function (time, key) {
                            return expandSeconds.call(this, time) - (key === "limit" ? 0 : delta);
                        };
                    }

                    snapshot = JSON.parse(JSON.stringify(snapshot));

                    delete snapshot.date;

                    var processDate = function (obj) {
                        for (var name in obj) {
                            if (["forces", "fogs"].some(function (str) {
                                return name.indexOf(str) === 0;
                            })) {
                                return;
                            }

                            if (typeof obj[name] === "number") {
                                if (obj[name] > cleverapps.ZERO_DATE) {
                                    obj[name] -= delta;
                                }
                            } else if (typeof obj[name] === "object") {
                                processDate(obj[name]);
                            }
                        }
                    };
                    processDate(snapshot);

                    cleverapps.synchronizer.overrideProgress(snapshot);

                    cleverapps.meta.whenFreeFocus(function () {
                        cleverapps.expandTime = expandTime;
                        if (cleverapps.config.type === "merge") {
                            UnitPacker.expandSeconds = expandSeconds;
                        }
                    });
                });
            }
        };

        Object.keys(bundles.snapshots.jsons).sort(function (a, b) {
            var aParts = a.split("_");
            var bParts = b.split("_");
            for (var i = 0; i < Math.max(aParts.length, bParts.length); i++) {
                var aInt = parseInt(aParts[i]);
                var bInt = parseInt(bParts[i]);

                if (isNaN(aInt) && isNaN(bInt)) {
                    return a.level - b.level;
                }
                if (!isNaN(aInt) && isNaN(bInt)) {
                    return 1;
                }
                if (isNaN(aInt) && !isNaN(bInt)) {
                    return -1;
                }
                if (!isNaN(aInt) && !isNaN(bInt)) {
                    return aInt - bInt;
                }
            }
            return 0;
        }).forEach(function (name) {
            res[name] = function () {
                cleverapps.bundleLoader.loadBundle("snapshots", {
                    onSuccess: function () {
                        var snapshot = cc.loader.getRes(bundles.snapshots.jsons[name]);
                        cleverapps.synchronizer.overrideProgress(snapshot);
                    }
                });
            };
        });

        if (cleverapps.config.importMode) {
            return Object.assign({
                download: function () {
                    cleverapps.snapshots.exportToJson(cleverapps.loadedSnapshot);
                },
                createScene: function () {
                    cleverapps.wysiwyg = cleverapps.wysiwyg || new Wysiwyg("wysiwyg/tmp", true);
                    cleverapps.wysiwyg.hierarchy.createFromTemplate();
                }
            }, res);
        }

        return Object.assign({
            import: function () {
                cleverapps.snapshots.importFromUrl(function (snapshot) {
                    if (!snapshot.abtest) {
                        cleverapps.dataLoader.remove(DataLoader.TYPES.ABTEST);
                        cleverapps.abTest.updateGroups(snapshot.users.id);
                        snapshot.abtest = cleverapps.abTest.groups;
                    }

                    cleverapps.synchronizer.overrideProgress(snapshot);
                });
            },

            importJson: function () {
                var input = document.createElement("input");
                input.type = "file";

                input.onchange = function (event) {
                    var reader = new FileReader();
                    reader.onload = function (event) {
                        cleverapps.synchronizer.overrideProgress(JSON.parse(event.target.result.toString()));
                    };
                    reader.readAsText(event.target.files[0]);
                };

                input.click();
            },

            download: function () {
                cleverapps.snapshots.importFromUrl(function (snapshot) {
                    cleverapps.snapshots.exportToJson(snapshot);
                });
            }
        }, res);
    }),

    wysiwyg: function () {
        if (!cleverapps.wysiwyg && typeof Wysiwyg !== "undefined") {
            cleverapps.wysiwyg = new Wysiwyg();
        }
        cleverapps.toolModel.hide();

        OfferManager.prototype.isOfferAvailable = function () {
            return false;
        };
        cleverapps.offerManager.refreshAll();

        var openWysiwyg = function (f) {
            cleverapps.config.wysiwygMode = true;
            cleverapps.setUrlHash({ "wysiwyg": true });
            cleverapps.scenes.replaceScene(new WysiwygScene(cleverapps.wysiwyg), f);
        };

        if (cleverapps.meta.isFocused()) {
            cleverapps.meta.distract({
                focus: "wysiwyg",
                action: openWysiwyg
            });
        } else {
            cleverapps.meta.display({
                focus: "wysiwyg",
                action: openWysiwyg
            });
        }
    },

    "Meta homefix": (cleverapps.config.meta === "homefix") ? {
        unlockLocation: function () {
            var home = cleverapps.home;
            if (!home) {
                return;
            }

            displayToolPrompt(function (value) {
                home.unlockLocation(parseInt(value));
            }, "Location Index", 1);
        },

        fromStart: function () {
            var home = cleverapps.home;
            if (!home) {
                return;
            }

            HomeTool.set(home.firstFurnitureId(), 0, false, function () {
                cleverapps.refreshScene();
            });
        },

        gotoFurniture: function () {
            var home = cleverapps.home;
            if (!home) {
                return [];
            }

            var actionFunc = function (building) {
                HomeTool.set(building.id, 0, false, function () {
                    cleverapps.refreshScene();
                });
            };

            return splitEntriesInParts(home.readConfig().items, actionFunc);
        },

        nextFurniture: function () {
            var home = cleverapps.home;

            if (home.isCompleted()) {
                return;
            }

            HomeTool.set(home.getNextId(), 0, true, function () {
                cleverapps.refreshScene();
            });
        },

        nextStage: function () {
            var home = cleverapps.home;

            if (home.isCompleted()) {
                return;
            }

            var nextProgress = HomeTool.calcNextProgress(home.getMaxFurniture());
            HomeTool.set(nextProgress.furnitureId, nextProgress.stage, true, function () {
                cleverapps.refreshScene();
            });
        },

        passAll: function () {
            var furniture = cleverapps.home.getFurniture();
            HomeTool.set(furniture[furniture.length - 1].id, 0, false, function () {
                cleverapps.refreshScene();
            });
        },

        lastLevel: function () {
            var furniture = cleverapps.home.getFurniture();
            HomeTool.set(furniture[furniture.length - 1].id, 0, true, function () {
                cleverapps.refreshScene();
            });
        },

        add20Stars: function () {
            cleverapps.home.earnStars(20);
        },

        travelMapWindowMoveNext: function () {
            cleverapps.meta.display({
                focus: "TravelMapMoveNext",
                actions: [
                    function (f) {
                        cleverapps.home.currentLocationId++;
                        cleverapps.home.trigger("moveNext", f);
                    }
                ]
            });
        }
    } : undefined,

    Navigation: (cleverapps.config.type !== "merge") ? {
        goto_episode: function () {
            cleverapps.Tool.Navigation.goto_level("goto_episode");
        },

        goto_level: function (type) {
            var callback = function (level) {
                if (type === "goto_episode" && parseInt(level) >= 0) {
                    level = parseInt(level) * 15 + 1;
                }
                if (level) {
                    level = parseInt(level) - 1;

                    levels.user.level = level % Level.LEVELS_PER_EPISODE;
                    levels.user.episode = Math.floor(level / Level.LEVELS_PER_EPISODE);
                    levels.user.progress = 0;

                    var total = levels.user.getEpisodesAmount();
                    if (levels.user.episode >= total) {
                        levels.user.episode = total;
                        levels.user.level = 0;
                        levels.user.progress = level - levels.user.getHumanReadableNumber() + 1;
                    }

                    cleverapps.user.save();
                    cleverapps.dataLoader.processSaveQueue();

                    if (cleverapps.hose) {
                        cleverapps.hose.reset();
                        cleverapps.hose.location = levels.user.episode;
                        cleverapps.hose.progress = levels.user.level;
                        cleverapps.hose.save();
                    }

                    if (cleverapps.playButton) {
                        cleverapps.playButton.update();
                    }

                    if (cleverapps.isKnockoutGame()) {
                        cleverapps.meta.getMainObject().knockoutGame.setLevel(level);
                        cleverapps.meta.getMainObject().save();
                    }

                    if (typeof match3 !== "undefined" && match3 && match3.heroes) {
                        match3.heroes.checkAvailableToOpen();
                    }

                    cleverapps.sideBar.refreshAllIcons();
                    cleverapps.refreshScene();
                }
            };

            displayToolPrompt(callback, "Level", 15);
        }
    } : undefined,

    "Meta hose": (cleverapps.config.meta === "hose") ? {
        pass_level: function () {
            if (cleverapps.hose.isPassedAll()) {
                return;
            }

            levels.user.incLevel();

            cleverapps.hose.progress++;

            if (cleverapps.hose.canMoveNext()) {
                cleverapps.hose.location++;
                cleverapps.hose.progress = 0;
            }

            cleverapps.hose.save();

            cleverapps.refreshScene();
        },

        pass_episode: function () {
            if (cleverapps.hose.isPassedAll()) {
                return;
            }

            while (!cleverapps.hose.canMoveNext()) {
                levels.user.incLevel();

                cleverapps.hose.progress++;
            }

            cleverapps.hose.location++;
            cleverapps.hose.progress = 0;
            cleverapps.hose.save();

            cleverapps.refreshScene();
        },

        pass_all: function () {
            cleverapps.meta.display({
                focus: "ToolPassAll",
                action: function () {
                    while (!cleverapps.hose.isPassedAll()) {
                        cleverapps.hose.location++;
                        cleverapps.hose.progress = 0;
                    }
                    cleverapps.hose.save();

                    cleverapps.refreshScene();
                }
            });
        }
    } : undefined,

    "Meta farm": (cleverapps.config.meta === "farm") ? {
        gotoBuilding: ToolFolder.create(function () {
            var farm = cleverapps.farm;
            if (!farm) {
                return [];
            }

            var actionFunc = function (building, ind) {
                Farm.Reset();
                var newData = Farm.GetBuildingProgress(ind, 0);
                farm.load(newData);
                farm.save();
                cleverapps.refreshScene();
            };

            return splitEntriesInParts(farm.config.items, actionFunc);
        }),

        nextBuilding: function () {
            var farm = cleverapps.farm;

            if (farm.isCompleted()) {
                return;
            }

            FarmTool.set(farm.currentBuildingId + 1, 0, true);

            cleverapps.meta.display({
                focus: "ToolMoveNext",
                action: function (f) {
                    farm.moveNext(f);
                }
            });
        },

        nextQuest: function () {
            var farm = cleverapps.farm;

            if (farm.isCompleted()) {
                return;
            }

            var nextProgress = FarmTool.calcNextProgress(farm.getBuilding());
            FarmTool.set(nextProgress.buildingId, nextProgress.questId, true);

            cleverapps.meta.display({
                focus: "ToolMoveNext",
                action: function (f) {
                    farm.moveNext(f);
                }
            });
        },

        passAll: function () {
            var farm = cleverapps.farm;

            FarmTool.set(farm.buildings.length, 0);

            cleverapps.refreshScene();
        },

        listQuestItems: ToolFolder.create(function () {
            var farm = cleverapps.farm;

            if (!farm || !farm.getBuilding() || !farm.getBuilding().getQuest()) {
                return;
            }

            var res = {};
            var quest = farm.getBuilding().getQuest();
            var keys = farm.keys;
            quest.items.forEach(function (item) {
                res[item.name] = function () {
                    var currentAmount = keys[item.name] || 0;
                    var amount = parseInt(window.prompt(item.name + " " + currentAmount + "/" + item.amount, currentAmount));
                    if (!isNaN(amount)) {
                        keys[item.name] = amount;
                        farm.save();
                    }
                };
            });
            return res;
        }),

        setItems: function () {
            var farm = cleverapps.farm;
            var input = window.prompt("Enter required items \n e.g. coke=2,mentos=5");
            if (input) {
                input.replace(/ /g, "").split(",").forEach(function (item) {
                    var index = item.indexOf("=");
                    var key = item.substr(0, index);
                    var val = parseInt(item.substr(index + 1));
                    farm.keys[key] = val;
                    farm.save();
                });
            }
        },

        addCurrentQuestItems: function (isLastLevel) {
            var farm = cleverapps.farm;
            var quest = farm.getBuilding().getQuest();
            if (quest) {
                var keys = farm.keys;
                quest.items.forEach(function (item, index) {
                    if (!keys[item.name]) {
                        keys[item.name] = 0;
                    }

                    if (keys[item.name] < item.amount) {
                        keys[item.name] = item.amount;
                    }
                    if (isLastLevel === true && index === 0) {
                        keys[item.name]--;
                    }
                });
            }
            farm.save();
        },

        addAllItems: function () {
            var keys = {};
            cleverapps.farm.buildings.forEach(function (building) {
                if (keys[building.provides]) {
                    return;
                }
                keys[building.provides] = true;
                for (var i = 0; i < 1000; i++) {
                    cleverapps.farm.keys[building.provides] = 1000;
                }
            });

            cleverapps.farm.save();
        },

        lastLevel: function () {
            var farm = cleverapps.farm;

            FarmTool.set(farm.buildings.length, 0, true);

            cleverapps.refreshScene();
        },

        inspectByBuildings: ToolFolder.create(function () {
            var actionFunc = function (building) {
                var provides = [];

                for (var level = 1; level <= 100; level++) {
                    var levelProvide = [0, 0, 0];

                    if (building.id === 0 && level === 1) {
                        levelProvide = [3, 0, 0];
                    } else {
                        cleverapps.Random.seed(building.id * 10000 + level);
                        for (var i = 0; i < 3; i++) {
                            var t = cleverapps.Random.nextDouble();
                            if (t < cleverapps.farm.probabilities[0]) {
                                levelProvide[0]++;
                            } else if (t < cleverapps.farm.probabilities[1]) {
                                levelProvide[1]++;
                            } else {
                                levelProvide[2]++;
                            }
                        }
                    }
                    provides.push(levelProvide);
                }
                console.log(building.name, provides);
            };

            return splitEntriesInParts(cleverapps.farm.buildings, actionFunc);
        }),

        inspect: function () {
            var data = [];
            var fakeKeys = {};
            var fakeBuildingLevels = {};
            var skippedItems = [];

            var farm = cleverapps.farm;
            if (!farm) {
                return;
            }

            var totalLlvs = 0;

            farm.buildings.forEach(function (building) {
                var ind = data.push({}) - 1;
                data[ind][building.name] = {};
                fakeBuildingLevels[building.name] === undefined && (fakeBuildingLevels[building.name] = 0);

                building.quests.forEach(function (quest) {
                    var levelsOnQuest = 0;
                    var totalQuestProvides = {};
                    data[ind][building.name][Messages.get(quest.title)] = {
                        perItems: {}
                    };

                    quest.items.forEach(function (item) {
                        if (fakeKeys[item.name] === undefined) {
                            fakeKeys[item.name] = 0;
                        }
                        var totalItemProvides = {};
                        var levelsOnItem = 0;

                        while (fakeKeys[item.name] < item.amount) {
                            levelsOnItem++;
                            levelsOnQuest++;
                            var targetBuilding = farm.findProperBuilding(item);
                            fakeBuildingLevels[targetBuilding.name] === undefined && (fakeBuildingLevels[targetBuilding.name] = 0);
                            fakeBuildingLevels[targetBuilding.name]++;

                            var itemName = targetBuilding.provides;
                            fakeKeys[itemName] === undefined ? fakeKeys[itemName] = 1 : fakeKeys[itemName]++;
                            totalItemProvides[itemName] === undefined ? totalItemProvides[itemName] = 1 : totalItemProvides[itemName]++;
                            totalQuestProvides[itemName] === undefined ? totalQuestProvides[itemName] = 1 : totalQuestProvides[itemName]++;
                        }

                        if (!levelsOnItem) {
                            skippedItems.push(item.name + " - " + Messages.get(quest.title) + " (" + building.name + ")");
                        }

                        fakeKeys[item.name] -= item.amount;
                        data[ind][building.name][Messages.get(quest.title)].perItems[item.name] = {
                            earnedOnItem: cleverapps.clone(totalItemProvides),
                            levelsOnItem: levelsOnItem
                        };
                    });

                    data[ind][building.name][Messages.get(quest.title)].earnedOnQuest = cleverapps.clone(totalQuestProvides);
                    data[ind][building.name][Messages.get(quest.title)].levelsOnQuest = levelsOnQuest;
                    totalLlvs += levelsOnQuest;
                    data[ind][building.name][Messages.get(quest.title)].total = cleverapps.clone(fakeKeys);
                });
            });

            data.push({
                total: cleverapps.clone(fakeKeys),
                skippedItems: skippedItems,
                totalLvls: totalLlvs
            });

            data.forEach(function (item) {
                console.log(item);
            });

            console.log("Print clock to get json data");
            clock = JSON.stringify(data);
        }
    } : undefined,

    "Meta simple": (cleverapps.config.meta === "simple") ? {
        reset: function () {
            cleverapps.simple.stars = 0;
            cleverapps.simple.current = 0;

            cleverapps.simple.save();

            cleverapps.refreshScene();
        },

        moveNext: function () {
            cleverapps.user.incLevel();

            cleverapps.simple.earnStars(cleverapps.simple.getRequiredStars() - cleverapps.simple.stars - 3, true);
            cleverapps.simple.earnStars(3);

            cleverapps.meta.onFocusLostListenerPlacements();
        },

        moveTo: ToolFolder.create(function () {
            var entries = [];

            var moveTo = function (item, index) {
                cleverapps.simple.current = index;
                cleverapps.simple.stars = 0;
                cleverapps.simple.save();
                cleverapps.refreshScene();
            };

            for (var i = 0; i < cleverapps.simple.bgsAmount; i++) {
                entries.push({
                    name: Messages.has("background_name_" + i) ? Messages.get("background_name_" + i) : "UNNAMED_" + i
                });
            }

            return splitEntriesInParts(entries, moveTo);
        }),

        passAll: function () {
            cleverapps.simple.current = cleverapps.simple.bgsAmount - 1;
            cleverapps.simple.save();

            cleverapps.refreshScene();
        },

        add1: function () {
            cleverapps.simple.earnStars(1, false);
            cleverapps.simple.updateProgress();
        },

        add10: function () {
            cleverapps.simple.earnStars(10, false);
            cleverapps.simple.updateProgress();
        },

        addStars: function () {
            var amount = parseInt(window.prompt("Enter amount", 10));
            cleverapps.simple.earnStars(amount, false);
            cleverapps.simple.updateProgress();
        }
    } : undefined,

    UI: {
        dialogues: ToolFolder.create(dialogues),

        windowPersons: ToolFolder.create(function () {
            var res = {};

            Object.keys(cleverapps.persons.data).forEach(function (role) {
                var personName = cleverapps.persons.data[role].name;
                var bundleName = cleverapps.persons.data[role].bundle;

                if (!bundles[bundleName]) {
                    bundleName = role;
                }

                res[role + ":" + personName] = function () {
                    cleverapps.meta.display({
                        focus: "ToolWindowPersons",
                        action: function (f) {
                            console.log(role, bundleName);
                            cleverapps.bundleLoader.loadBundle(bundleName, {
                                onSuccess: function () {
                                    var window = new SettingsWindow();

                                    var persons = new WindowPersons({
                                        role: role,
                                        emotion: "idle"
                                    }, window);
                                    window.persons = persons;
                                    window.persons.positionPersons();
                                    window.persons.showUp();

                                    var leftPerson = persons.leftPerson;

                                    var animations = Object.keys(leftPerson.data.animations);

                                    var buttons = animations.map(function (animation) {
                                        return new cleverapps.UI.Button({
                                            text: animation,
                                            width: 250,
                                            height: 70,
                                            onClicked: function () {
                                                leftPerson.setAnimation(0, animation, true);
                                                leftPerson.setCompleteListener();
                                            }
                                        });
                                    });

                                    var layout = new cleverapps.Layout(buttons, {
                                        margin: 10,
                                        direction: cleverapps.UI.VERTICAL
                                    });
                                    cleverapps.UI.fitToBox(layout, {
                                        height: 700
                                    });
                                    window.addChild(layout);
                                    layout.setPositionRound({ x: { align: "left", dx: 50 }, y: { align: "top", dy: -50 } });

                                    cleverapps.meta.onceNoWindowsListener = function () {
                                        cleverapps.bundleLoader.deleteBundle(bundleName);
                                        f();
                                    };
                                }
                            });
                        }
                    });
                };
            });

            return res;
        }),

        skins: ToolFolder.create(function () {
            var res = {};
            Object.keys(cleverapps.SkinManager.SKINS).forEach(function (skin) {
                res[skin] = function () {
                    cleverapps.eventManager.events = cleverapps.eventManager.events.filter(function (event) {
                        return !event.features.some(function (feature) {
                            return cleverapps.SkinManager.SKINS[feature];
                        });
                    });
                    if (skin !== "default") {
                        cleverapps.eventManager.setCustomFeature(skin, cleverapps.parseInterval("1 day"));
                    } else {
                        cleverapps.eventManager.setCurrentFeatures();
                    }
                };
            });
            return res;
        }),

        windows: {
            restartWindow: displayToolWindow(function () {
                new RestartWindow();
            }),

            chooseSaveWindow: ToolFolder.create(function () {
                return {
                    open: displayToolWindow(function () {
                        new ChooseSaveWindow();
                    }),

                    forceError: function () {
                        var window = cleverapps.windows.findWindow(ChooseSaveWindow);
                        if (window) {
                            window._forceError = true;
                        }
                    }

                };
            }),

            tilesUnlockWindow: cleverapps.config.name === "zenmatch"
                ? displayToolWindow(function () {
                    new TilesUnlockWindow({
                        random: true
                    });
                }) : undefined,

            tellWindow: displayToolWindow(function () {
                new TellWindow({
                    text: "TreasureSearchWindow.Help.Text",
                    title: "TreasureSearchWindow.Help.Title"
                });
            }),

            selectFriendWindow: displayToolWindow(function () {
                new SelectFriendWindow("askLives", function () {

                });
            }),

            newLeague: displayToolWindow(function () {
                new NewLeagueWindow();
            }),

            rateWindow: displayToolWindow(function () {
                new RateWindow();
            }),

            victoryWindow: displayToolWindow(function () {
                if (!Game.currentGame) {
                    return;
                }

                new VictoryWindow(Game.currentGame);
            }),

            startEpisodeWindow: displayToolWindow(function () {
                new StartEpisodeWindow(levels.user.episode);
            }),

            finishEpisodeWindow: (cleverapps.config.meta === "hose") ? displayToolWindow(function () {
                new FinishEpisodeWindow(cleverapps.user.episode);
            }) : undefined,

            finisherWindow: displayToolWindow(function () {
                new FinisherWindow();
            }),

            signInWindow: displayToolWindow(function () {
                new SignInWindow({
                    errorMessage: "SignInWindow.TryAgain",
                    onSuccess: function () {},
                    onFailure: function () {}
                });
            }),

            restoreProgress: displayToolWindow(function () {
                new SignInWindow({
                    restoreProgress: true,
                    onSuccess: function () {},
                    onFailure: function () {}
                });
            }),

            loseWindow: displayToolWindow(function () {
                new LoseWindow(Game.currentGame);
            }),

            tacticalExtendWindow: displayToolWindow(function () {
                new TacticalExtendWindow();
            }),

            waitWindow: displayToolWindow(function () {
                new WaitWindow();
            }),

            crossPromoWindow: function () {
                var promotion = cleverapps.crossPromo.getFirstAvailable();

                if (!promotion) {
                    cleverapps.notification.create("No promo available for " + connector.info.source);
                } else {
                    displayToolWindow(function () {
                        new CrossPromoWindow(promotion);
                    })();
                }
            },

            reloadWindow: displayToolWindow(function () {
                new ReloadWindow();
            }),

            newVersionWindow: displayToolWindow(function () {
                new NewVersionWindow();
            }),

            helpByFiveAttemptsWindow: displayToolWindow(function () {
                new HelpByFiveAttemptsWindow();
            }),

            unlimitedLivesFinish: displayToolWindow(function () {
                new ExtendUnlimitedLivesWindow();
            }),

            allDone: displayToolWindow(function () {
                new AllDoneWindow();
            }),

            addBombMovesWindow: displayToolWindow(function () {
                new AddBombMovesWindow(100, "a", function () {}, function () {}, function () {});
            }),

            prolongationWindow: displayToolWindow(function () {
                new ProlongationWindow({
                    onGiveUp: function () {
                    }
                });
            }),

            confirmExitWindow: displayToolWindow(function () {
                new ConfirmExitWindow();
            }),

            vipRewardedWindow: displayToolWindow(function () {
                new VIPRewardedWindow(function () {}, function () {});
            }),

            passLastChance: displayToolWindow(function () {
                new PassLastChanceWindow(cleverapps.missionManager.findLocalPass());
            }),

            dailyLevelWindow: displayToolWindow(function () {
                new DailyLevelWindow();
            }),

            emailWindow: displayToolWindow(function () {
                new EmailWindow();
            }),

            noAdsWindow: displayToolWindow(function () {
                new NoAdsWindow();
            }),

            replaceOldMissionWindow: displayToolWindow(function () {
                new ReplaceOldMissionWindow(Mission.TYPE_DRAGONIA_EXPEDITION);
            }),

            tradeWindow: displayToolWindow(function () {
                new TradeWindow();
            }),

            adBlockWindow: displayToolWindow(function () {
                new AdBlockWindow();
            })
        },

        rewardWindows: {
            StarChestWindow: displayToolWindow(function () {
                new StarChestWindow();
            }),

            DailyTaskRewardWindow: displayToolWindow(function () {
                cleverapps.dailyTasks.whenStart = -1;
                cleverapps.dailyTasks.update();

                var tasks = cleverapps.dailyTasks.getActiveTasks();
                var task = tasks[Math.floor(Math.random() * tasks.length)];
                if (task) {
                    RewardWindow.createDailyTaskWindow(task);
                }
            }),

            RewardWindow: displayToolWindow(function () {
                var reward = {
                    hard: 100
                };

                if (cleverapps.lives) {
                    reward.unlimitedLives = "1 day";
                }

                if (cleverapps.config.type === "merge") {
                    delete reward.unlimitedLives;
                    reward.wands = 10;
                    reward.soft = 100;
                    reward.unit = [{
                        code: "king",
                        stage: 0,
                        amount: 1
                    }, {
                        code: "magicplant",
                        stage: 5,
                        amount: 1
                    }];
                }

                var boosters = cleverapps.boosters.listBoostersBefore();
                if (boosters.length > 0) {
                    reward.boosters = {};
                    for (var i = 0; i < boosters.length; i++) {
                        reward.boosters[boosters[i].id] = 1;
                    }
                }

                new RewardWindow(reward, {
                    shareId: "reward",
                    event: cleverapps.EVENTS.EARN.OTHER
                });
            }),

            HeroLevelRewardWindow: displayToolWindow(function () {
                new RewardWindow({
                    herolevels: [
                        "a3",
                        "b5"
                    ]
                });
            }),

            ExpeditionRewardWindow: displayToolWindow(function () {
                RewardWindow.createMissionWindow({
                    soft: 600,
                    unit: { code: "crystal", stage: 3, amount: 5 }
                }, Missions[Mission.TYPE_DRAGONIA_EXPEDITION]);
            }),

            stickersRewardWindow: cleverapps.config.features.includes("stickers_book") ? displayToolWindow(function () {
                var stickers = [];
                if (StickersBook.isPuzzle()) {
                    stickers.push(cleverapps.stickersBook.collections[0].stickers[5]);
                    stickers.push(cleverapps.stickersBook.collections[0].stickers[8]);
                    stickers.push(cleverapps.stickersBook.collections[0].stickers[6]);
                } else {
                    for (var index = 2; index < 5; index++) {
                        var sticker = cleverapps.stickersBook.collections[0].stickers[index];
                        stickers.push(sticker);
                    }
                }

                stickers.forEach(function (sticker) {
                    sticker.state = Sticker.STATE_OPENED;
                });

                stickers[0].attention = true;
                stickers[1].duplicate = true;
                stickers[2].attention = true;

                new StickersRewardWindow(stickers);
            }) : undefined,

            stickersCompletedWindow: cleverapps.config.features.includes("stickers_book") ? displayToolWindow(function () {
                cleverapps.stickersBook.collections[6].stickers.forEach(function (sticker) {
                    sticker.open();
                });
                new StickersCompletedWindow(6);
            }) : undefined
        },

        offerWindows: ToolFolder.create(function () {
            var res = {};

            Object.values(Offers.TYPES).filter(function (type) {
                return Offers[type] !== undefined && type !== Offers.TYPES.PACK;
            }).forEach(function (type) {
                res[Offers[type].name] = displayToolWindow(function () {
                    var WindowCtor = Offers[type].view;
                    var offerManager = cleverapps.offerManager;
                    if (!offerManager.offers[type]) {
                        offerManager.addOffer(type);
                        offerManager.shownOfferTypes.push(type);
                    }
                    new WindowCtor(offerManager.offers[type]);
                });
            });

            var type = Offers.TYPES.PACK;
            res.starterPack0 = function () {
                cleverapps.offerManager.addOffer(type);
                cleverapps.offerManager.offers[type].stage = 0;
                cleverapps.offerManager.showOfferWindow(cleverapps.offerManager.offers[type]);
            };
            res.starterPack = function () {
                cleverapps.offerManager.addOffer(type);
                cleverapps.offerManager.offers[type].stage = 1;
                cleverapps.offerManager.showOfferWindow(cleverapps.offerManager.offers[type]);
            };
            res.starterPack2 = function () {
                cleverapps.offerManager.addOffer(type);
                cleverapps.offerManager.offers[type].stage = 2;
                cleverapps.offerManager.showOfferWindow(cleverapps.offerManager.offers[type]);
            };

            return res;
        })
    },

    ADS: ToolFolder.create(function () {
        var playRewarded = function () {
            cleverapps.rewardedAdsManager.playRewarded(undefined, function () {
                console.log("Rewarded success");
            }, function (code) {
                console.log("Rewarded fail", code);
            });
        };

        var playInterstitial = function () {
            cleverapps.rewardedAdsManager.showInterstitial(function (code) {
                console.log("Interstitial result", code);
            });
        };

        var res = {
            playRewarded: playRewarded,

            playRewardedOverlay: function () {
                connector.ads.getPlugin().setLoaderState("rewarded", "ready");
                connector.ads.getPlugin()._testNoCallback = true;

                playRewarded();
            },

            playInterstitial: playInterstitial,

            playInterstitialOverlay: function () {
                connector.ads.getPlugin().setLoaderState("interstitial", "ready");
                connector.ads.getPlugin()._testNoCallback = true;

                playInterstitial();
            },

            fake_rewarded_off: function () {
                VideoAds.prototype.isAvailable = function () {
                    return false;
                };
                VKAds.prototype.isAvailable = function () {
                    return false;
                };
            },

            disableAds: function () {
                cleverapps.rewardedAdsManager.isEnabledOld = cleverapps.rewardedAdsManager.isEnabled;
                cleverapps.rewardedAdsManager.isEnabled = function () {
                    return false;
                };

                cleverapps.toolbar.updateItems(true);
            },

            enableAds: function () {
                cleverapps.rewardedAdsManager.isEnabled = cleverapps.rewardedAdsManager.isEnabledOld;
            },

            connect: function () {
                connector.ads.getPlugin().setStatus(connector.STATUS_CONNECTED);
                cleverapps.flags.disableReconnect = false;
            },

            disconnect: function () {
                connector.ads.getPlugin().setStatus(connector.STATUS_DISCONNECTED);
                cleverapps.flags.disableReconnect = true;
            },

            disable: function () {
                connector.ads.getPlugin().setStatus(connector.STATUS_DISABLED);
                cleverapps.flags.disableReconnect = true;
            },

            toggleNoAdsProduct: function () {
                if (cleverapps.noAds.state === NoAds.STATE_ENABLED) {
                    cleverapps.noAds.reset();
                } else {
                    cleverapps.noAds.enable();
                }
            }
        };

        if (connector.platform.oneOf(connector.ANDROID, connector.IOS)) {
            ["AdMobAds", "YandexAppAds", "AppLovinAds"].forEach(function (pluginName) {
                res[pluginName] = function () {
                    var PluginClass = window[pluginName];

                    connector.ads._installPlugin(new PluginClass());

                    cleverapps.toolModel.refresh("ADS");
                };
            });
        }

        if (connector.ads.oneOf(connector.APP_LOVIN)) {
            res.showMediationDebugger = function () {
                connector.platform.callNative("AppLovinPlugin.showMediationDebugger");
            };
        }

        if (connector.ads.oneOf(connector.YANDEX_APP_ADS)) {
            res.showDebugPanel = function () {
                connector.platform.callNative("YandexAdsPlugin.showDebugPanel");
            };
        }

        return res;
    }),

    discord: {
        platformSafePadding: function () {
            var safePadding = connector.platform.getSafePadding();
            cleverapps.notification.create(JSON.stringify(safePadding));
        },
        calculatedSafePadding: function () {
            var safePadding = cleverapps.resolution.getSafePadding();
            cleverapps.notification.create(JSON.stringify(safePadding));
        },
        isRotated: function () {
            cleverapps.notification.create(cleverapps.resolution.isRotated);
        }
    },

    Debug: {
        resolution: ToolFolder.create(function () {
            var sorted = Object.keys(resolutions).sort(function (a, b) {
                return resolutions[a].scale - resolutions[b].scale;
            });

            var res = {};

            var currName = cleverapps.resolution.resolutionName;

            sorted.forEach(function (name) {
                if (name !== currName) {
                    res[name] = function () {
                        cleverapps.settings.setManualResolution(name);
                    };
                } else {
                    res[name + " [CURRENT]"] = function () {};
                }
            });

            return res;
        }),

        AtlasAnalyzer: function () {
            cleverapps.atlasAnalyzer = new AtlasAnalyzer();
            AtlasAnalyzerScene.open(cleverapps.atlasAnalyzer);
        },

        sounds: ToolFolder.create(function () {
            var modes = ["normal", "wait_all", "important_only"];
            var res = {};

            var active = cleverapps.dataLoader.load(DataLoader.TYPES.TEST_SOUNDS);
            active = modes.indexOf(active) !== -1 ? active : "normal";

            ["normal", "wait_all", "important_only"].forEach(function (mode) {
                res[active === mode ? ToolMenuItem.ACTIVE_ITEM + mode : mode] = function () {
                    cleverapps.dataLoader.save(DataLoader.TYPES.TEST_SOUNDS, mode);
                    cleverapps.dataLoader.processSaveQueue();
                    cleverapps.toolModel.refresh("sounds");
                };
            });

            return res;
        }),

        browserLanguage: function () {
            alert("System: " + connector.info.language + " navigator: " + navigator.language);
        },

        clearLocalStorage: function () {
            connector.dataLoader.cleanAll();
            cleverapps.dataLoader.cleanAll();

            cleverapps.meta.display({
                name: "waitReload",
                action: function () {
                    new WaitWindow();
                }
            });
        },

        printAllLetters: function () {
            var letters = {};
            Object.values(Messages.dictionary).forEach(function (message) {
                // console.log(message);
                if (!Array.isArray(message)) {
                    message = [message];
                }
                message.forEach(function (m) {
                    m.split("").forEach(function (ch) {
                        letters[ch.toLowerCase()] = true;
                    });
                });
            });

            letters = Object.keys(letters).sort().join("");
            console.log(letters.length);
            console.log(letters);
        },

        reset: function () {
            cleverapps.meta.display({
                stack: true,
                name: "waitReload",
                action: function () {
                    new WaitWindow();
                }
            });

            cleverapps.Tool.Debug.clearLocalStorage();
            connector.dataLoader.setEnabled(false);
            cleverapps.dataLoader.setEnabled(false);
            cleverapps.snapshots.reset();

            levels.user.level = 0;
            levels.user.episode = 0;
            levels.user.initializeNewPlayerGold();
            levels.user.progressCompare = 0;
            levels.user.progress = 0;
            levels.user.save(true);
            var id = connector.platform.getUserID();

            if (cleverapps.farm) {
                cleverapps.farm.reset();
            }

            if (cleverapps.home) {
                cleverapps.home.reset();
            }

            if (cleverapps.simple) {
                cleverapps.simple.reset();
            }

            if (cleverapps.shortmeta) {
                cleverapps.shortmeta.reset();
            }

            var callback1 = function () {
                cleverapps.settings = new cleverapps.Settings(true);

                if (cleverapps.miniGame) {
                    cleverapps.miniGame = new cleverapps.MiniGame(true);
                    cleverapps.miniGame.save(true);
                }

                levels.user = new levels.User(true);
                connector.platform.setUserID(id);
                cleverapps.user = levels.user;

                if (cleverapps.boosters) {
                    cleverapps.boosters = new cleverapps.Boosters(true);
                    cleverapps.boosters.save(true);
                }

                if (cleverapps.subscription && cleverapps.subscription.isActive()) {
                    cleverapps.subscription.active = false;
                    cleverapps.subscription.save();
                }

                if (cleverapps.crossPromo) {
                    cleverapps.crossPromo.reset();
                }

                if (cleverapps.starChest) {
                    cleverapps.starChest = new cleverapps.StarChest(true);
                    cleverapps.starChest.save(true);
                }

                if (Game.currentGame && Game.currentGame.storeSave) {
                    Game.currentGame.storeSave = function () {};
                }

                cleverapps.forces.reset();
                cleverapps.GameSaver && cleverapps.GameSaver.reset();

                cleverapps.missionManager.reset();
                cleverapps.missionManager.save();

                cleverapps.friends.reset();

                if (cleverapps.hose) {
                    cleverapps.hose.reset();
                }

                if (cleverapps.serverFlags) {
                    cleverapps.serverFlags.reset();
                }

                if (cleverapps.unlimitedLives) {
                    cleverapps.unlimitedLives.reset();
                }

                if (cleverapps.lives) {
                    cleverapps.lives.reset();
                }

                if (cleverapps.noAds) {
                    cleverapps.noAds.reset();
                }

                if (cleverapps.growthFund) {
                    cleverapps.Tool.growthFund.reset();
                }

                cleverapps.unitsLibrary && cleverapps.unitsLibrary.reset();
                typeof Quests !== "undefined" && Quests.reset();
                typeof Workers !== "undefined" && Workers.reset();
                typeof Harvested !== "undefined" && Harvested.reset();
                typeof Pocket !== "undefined" && Pocket.reset();

                if (cleverapps.unitSavers) {
                    cleverapps.unitSavers.reset();
                }

                if (cleverapps.fogsSavers) {
                    cleverapps.fogsSavers.reset();
                }

                connector.social.logout();
            };

            var callback2 = function () {
                window.location.reload();
            };

            if (cleverapps.isLocalhost() && !cleverapps.synchronizer.synced) {
                callback1();
                callback2();
                return;
            }

            var iosWebView = cc.sys.OS_IOS === connector.info.os && connector.info.isNative;
            setTimeout(callback2, iosWebView ? 500 : 40000);

            new ActionPlayer([
                function (f) {
                    cleverapps.synchronizer._syncOut.getAccumulateTimeout = function () {
                        return 1;
                    };
                    cleverapps.synchronizer._syncOut.onTasksEmptyListener = cleverapps.once(f);
                    cleverapps.synchronizer._syncOut._sendUpdateInner();
                },

                function (f) {
                    cleverapps.synchronizer.syncWhenReady();
                    cleverapps.synchronizer.whenSyncCompleted(f);
                },

                function (f) {
                    callback1();
                    cleverapps.synchronizer._syncOut.onTasksEmptyListener = cleverapps.once(f);
                },

                function (f) {
                    callback2();
                    f();
                }

            ]).play();
        },

        adminAccess: function () {
            cleverapps.meta.display({
                focus: "AdminAccessWindow",
                action: function (f) {
                    new AdminAccessWindow();
                    cleverapps.meta.onceNoWindowsListener = f;
                }
            });
        },

        forces: {
            shownForces: ToolFolder.create(function () {
                var ids = Object.keys(Forces).filter(function (key) {
                    return typeof Forces[key] === "object" && cleverapps.forces.isShown(Forces[key].id);
                });

                var res = {};
                ids.forEach(function (id) {
                    res["X__" + id] = function () {
                        cleverapps.forces.clearForce(Forces[id].id);

                        cleverapps.toolModel.refresh();
                    };
                });

                return res;
            }),

            markAllShown: function () {
                if (cleverapps.forces.isRunningForce()) {
                    cleverapps.forces.closeRunningForce();
                }

                Object.keys(Forces).forEach(function (key) {
                    if (typeof Forces[key] === "object" && Forces[key].id !== undefined) {
                        cleverapps.forces.saveForce(Forces[key].id);
                    }
                });
            },

            clearAll: function () {
                Object.keys(Forces).forEach(function (key) {
                    if (typeof Forces[key] === "object" && Forces[key].id !== undefined) {
                        cleverapps.forces.clearForce(Forces[key].id);
                    }
                });
            }
        },

        resetWindows: {
            crossPromoWindow: function () {
                cleverapps.crossPromo.reset();
            },
            resetPlacements: function () {
                cleverapps.placements.resetAllPlacements();
            }
        },

        resetDailyLevel: function () {
            cleverapps.dailyLevel.reset();
            cleverapps.refreshScene();
        },

        throwError: function () {
            throw "Tool Error";
        },

        instantApi: ToolFolder.create(function () {
            if (!connector.platform.oneOf(connector.INSTANT)) {
                return;
            }

            var checkApi = function (api) {
                var apis = FBInstant.getSupportedAPIs();
                console.log("Searching " + api, apis);
                cleverapps.notification.create(api + " - " + apis.includes(api));
            };

            return {
                purchaseAsync: checkApi.bind(this, "payments.purchaseAsync"),
                purchaseSubscriptionAsync: checkApi.bind(this, "payments.purchaseSubscriptionAsync"),
                getRewardedVideoAsync: checkApi.bind(this, "getRewardedVideoAsync"),
                getInterstitialAdAsync: checkApi.bind(this, "getInterstitialAdAsync")
            };
        }),

        screenshots: function () {
            cleverapps.debugStats.setEnabled(false);

            if (!connector.info.isNative) {
                var body = window.document.body;
                var containers = body.getElementsByClassName("container");
                for (var i = 0; i < containers.length; i++) {
                    console.log(containers.item(i));
                    body.removeChild(containers.item(i));
                }

                var expand = body.getElementsByClassName("expand");
                for (i = 0; i < expand.length; i++) {
                    console.log(expand.item(i));
                    body.removeChild(expand.item(i));
                }

                var commits = window.document.getElementById("commits");
                if (commits) {
                    commits.parentNode.removeChild(commits);
                }
            }

            cleverapps.refreshScene();
        },

        toggleStats: {
            flag: "toggleStatsMode"
        },

        diffMaps: ToolFolder.create(function () {
            if (cleverapps.config.name !== "differences") {
                return;
            }

            var openLink = function (ep, lvl) {
                var url = "http://differences.labsystech.ru/private/levels/diffmaps/" + ep + "/" + lvl + ".png";
                connector.platform.openUrl(url);
            };

            var res = {};

            for (var ep = 0; ep < levels.user.getEpisodesAmount(); ep++) {
                var links = {};
                for (var lvl = 0; lvl < 15; lvl++) {
                    links[lvl] = openLink.bind(undefined, ep, lvl);
                }
                res[ep] = links;
            }

            return res;
        }),

        pushes: ToolFolder.create(function () {
            var res = {};
            Object.keys(cleverapps.LocalPushes.TYPES).forEach(function (type) {
                res[type] = function () {
                    if (connector.info.isNative) {
                        var timeout = cleverapps.LocalPushes.TIMEOUT_THRESHOLD + cleverapps.parseInterval("10 seconds");
                        cleverapps.localPushes.sendPush(cleverapps.LocalPushes.TYPES[type], timeout);
                    } else {
                        var actualValue = cleverapps.LocalPushes.TIMEOUT_THRESHOLD;
                        timeout = cleverapps.parseInterval("13 seconds");
                        cleverapps.localPushes.sendPush(cleverapps.LocalPushes.TYPES[type], timeout);
                        cleverapps.LocalPushes.TIMEOUT_THRESHOLD = actualValue;
                    }
                };
            });

            return res;
        }),

        debugSlots: function () {
            cleverapps.Spine.debugSlots = true;
            cleverapps.refreshScene();
        },

        debugBones: function () {
            cleverapps.Spine.debugBones = true;
            cleverapps.refreshScene();
        },

        browserName: function () {
            cleverapps.notification.create(new UAParser().getBrowser().name);
        },

        userAgent: function () {
            cleverapps.notification.create(window.navigator.userAgent.toLowerCase());

            cleverapps.copyToClipboard(window.navigator.userAgent.toLowerCase());
        },

        browserType: function () {
            cleverapps.notification.create(cc.sys.browserType);
        },

        connectorInfo: function () {
            cleverapps.notification.create(JSON.stringify(connector.info));
        },

        connectorState: function () {
            var text = "";
            ["platform", "ads", "payments", "social"].forEach(function (name) {
                var plugin = connector[name].getPlugin();
                var pluginName = plugin && plugin.pluginName || "none";

                text += name + " " + pluginName + ": " + connector[name].getStatus() + "\n";
            });
            cleverapps.notification.create(text);
        },

        debugInstantBanner: function () {
            cleverapps.notification.create("canShow: " + cleverapps.bannerAd.canShow() + " isStickyAvailable: " + connector.ads.isStickyAvailable
                + " limit: " + cleverapps.adsLimits.state(AdsLimits.TYPES.BANNER));
        },

        checkBannerAd: function () {
            cleverapps.bannerAd.check();
        },

        hideBannerAd: function () {
            connector.ads.closeStickyCallback();
        },

        islandsInfo: function () {
            cleverapps.flags.islandsInfoEnabled = true;
            cleverapps.refreshScene();
        },

        debugFont: function () {
            var createFont = function (size) {
                return {
                    name: "default",
                    fnt: true,
                    size: size,
                    color: cleverapps.styles.COLORS.WHITE,
                    stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
                };
            };

            var testString = "x0123456789/2+3-4";

            var createText = function (size) {
                return cleverapps.UI.generateOnlyText(testString, createFont(size));
            };

            var createImageText = function (size) {
                return cleverapps.UI.generateImageText(testString, createFont(size));
            };

            var sizes = [65, 60, 55, 50, 45, 40, 35, 30, 25, 20];

            var pairs = sizes.map(function (size) {
                var text = createText(size);
                var imageText = createImageText(size);
                return new cleverapps.Layout([imageText, text], {
                    direction: cleverapps.UI.HORIZONTAL,
                    margin: 20
                });
            });

            var layout = new cleverapps.Layout(pairs, {
                direction: cleverapps.UI.VERTICAL,
                margin: 20
            });

            var scene = cleverapps.scenes.getRunningScene();
            layout.setPositionRound({ align: "center" }, { align: "center" });
            layout.setLocalZOrder(10000);
            scene.addChild(layout);
        },

        debugButtons: function () {
            var createLayoutForSize = function (size) {
                var items = Object.keys(TextWithIcon.ICONS).filter(function (key) {
                    var icon = TextWithIcon.ICONS[key][0];
                    return bundles[icon.bundle].frames[icon.name];
                }).map(function (key) {
                    return new cleverapps.UI.Button({
                        text: key + "Test text",
                        width: size.width,
                        height: size.height
                    });
                });

                return new cleverapps.Layout(items, {
                    margin: 5,
                    direction: cleverapps.UI.VERTICAL
                });
            };

            var sizes = [
                {
                    width: 140,
                    height: 60
                },
                {
                    width: 170,
                    height: 70
                },
                {
                    width: 210,
                    height: 80
                },
                {
                    width: 250,
                    height: 90
                }
            ];

            var layouts = sizes.map(function (size) {
                return createLayoutForSize(size);
            });

            var layout = new cleverapps.Layout(layouts, {
                margin: 10,
                direction: cleverapps.UI.HORIZONTAL
            });

            var scene = cleverapps.scenes.getRunningScene();

            scene.addChild(layout);
            layout.setLocalZOrder(100);
            layout.setPositionRound(scene.width / 2, scene.height / 2);
        },

        printProductsForCrm: function () {
            var packs = {};
            for (var i = 0; i < RewardsConfig.Packs.length; i++) {
                var packCode = "starterPack0";
                var packName = "Starter pack";
                if (i === 1) {
                    packCode = "starterPack";
                    packName = "Super pack";
                } else if (i === 2) {
                    packCode = "starterPack2";
                    packName = "Mega pack";
                }

                packs[packCode] = {
                    name: packName
                };
                Object.assign(packs[packCode], RewardsConfig.Packs[i]);
            }

            connector.payments.products.filter(function (product) {
                if (cleverapps.config.type === "merge" && ["gold500", "gold1800", "gold3800", "gold6000", "gold25000"].indexOf(product.itemId) !== -1) {
                    return true;
                }

                if (!cleverapps.lives && product.itemId.indexOf("ives") !== -1) {
                    return false;
                }

                return packs[product.itemId] === undefined && (product.itemId.indexOf("Pack") !== -1 || product.restoreReward !== undefined);
            }).forEach(function (product) {
                var title = product.title.replace("", "");
                if (!Messages.has(title)) {
                    return;
                }

                packs[product.itemId] = {
                    name: Messages.get(title)
                };
                Object.assign(packs[product.itemId], product.restoreReward || product.reward);
            });

            Object.values(packs).forEach(function (pack) {
                if (pack.boosters) {
                    Object.keys(pack.boosters).forEach(function (boosterCode) {
                        pack["booster" + boosterCode] = pack.boosters[boosterCode];
                    });
                    pack.boosters = undefined;
                }

                if (pack.worker) {
                    pack.worker = cleverapps.parseInterval(pack.worker) / 3600000;
                }

                if (pack.unlimitedLives) {
                    pack.unlimitedLives = cleverapps.parseInterval(pack.unlimitedLives) / 3600000;
                }

                if (pack.energy) {
                    pack.lives = pack.energy;
                    pack.energy = undefined;
                }
            });

            console.log(JSON.stringify(packs, undefined, 4));
        },

        bannerPadding: ToolFolder.create(function () {
            var res = {};

            var plugin = connector.ads.getPlugin();

            res.none = function () {
                plugin.setBannerProbability(0);
                cleverapps.bannerAd.canShow = function () {
                    return false;
                };
                cleverapps.bannerAd.check(true);
            };

            if (!connector.platform.oneOf(connector.TEST)) {
                return res;
            }

            ["320x50", "468x60", "728x90"].forEach(function (bannerSize) {
                res[bannerSize] = function () {
                    res.none();

                    var size = cc.size(+(bannerSize.split("x")[0]), +(bannerSize.split("x")[1]));

                    plugin.setBannerProbability(1);
                    plugin.setBannerSize(size);

                    cleverapps.bannerAd.canShow = function () {
                        return !cleverapps.environment.isSceneWithPreview();
                    };
                    cleverapps.bannerAd.check();
                };
            });

            return res;
        }),

        toggleTimeScale: function () {
            if (cleverapps.debugSlider.isActive()) {
                cleverapps.debugSlider.remove();
            } else {
                cleverapps.debugSlider.createTimeScaleSlider();
            }
        },

        toggleFireworksTuning: function () {
            if (cleverapps.debugSlider.isActive()) {
                cleverapps.debugSlider.remove();
            } else {
                cleverapps.debugSlider.createFireworksSlider();
            }
        },

        toggleZoomScale: function () {
            if (cleverapps.config.type !== "merge") {
                return;
            }

            if (cleverapps.debugSlider.isActive()) {
                cleverapps.debugSlider.remove();
            } else {
                var toggleAction = function (value) {
                    var scene = cleverapps.scenes.getRunningScene();
                    if (!scene || !scene.map || !scene.map.scroll || cleverapps.meta.isFocused()) {
                        return;
                    }
                    if (scene.animateZoom) {
                        scene.animateZoom(value, 0);
                    }
                };

                cleverapps.debugSlider.create({
                    action: toggleAction,
                    minValue: 0.3,
                    maxValue: 4,
                    step: 0.1,
                    baseValue: 1,
                    silent: 1
                });
            }
        },

        videoProducts: ToolFolder.create(function () {
            var shopWindow = cleverapps.windows.list[0];
            if (!shopWindow || !(shopWindow instanceof ShopWindow)) {
                return {};
            }

            var tiles = shopWindow.tiles;

            var res = {
                reset: function () {
                    tiles.forEach(function (tile) {
                        cleverapps.dataLoader.save(DataLoader.TYPES.PRODUCT_VIDEO_PROGRESS + tile.tileModel.product.itemId, 0);
                        tile.tileModel.updateModelState();
                    });
                }
            };

            tiles.forEach(function (tile) {
                if (tile.tileModel.product.videoProduct) {
                    res[tile.tileModel.product.itemId] = function () {
                        var amount = parseInt(window.prompt("Enter amount", tile.tileModel.product.videoPrice - tile.tileModel.currentVideoProgress() - 1));
                        if (!isNaN(amount)) {
                            var i = 0;
                            while (i < amount) {
                                tile.tileModel.incVideoProduct(function () {});
                                i++;
                            }
                        }
                    };
                }
            });

            return res;
        }),

        payments: ToolFolder.create(function () {
            var res = {};

            res.connect = function () {
                connector.payments.getPlugin().setStatus(connector.STATUS_CONNECTED);

                cleverapps.flags.disableReconnect = false;
            };

            res.disconnect = function () {
                connector.payments.getPlugin().setStatus(connector.STATUS_DISCONNECTED);

                cleverapps.flags.disableReconnect = true;
            };

            res.disable = function () {
                connector.payments.getPlugin().setStatus(connector.STATUS_DISABLED);

                cleverapps.flags.disableReconnect = true;
            };

            res.resetEmail = function () {
                cleverapps.info.setKeyValue("email", "");
            };

            res.paymentsCountry = function () {
                cleverapps.notification.create("Country: " + connector._countryManager.country + " Currency: " + connector.payments.getPlugin().currency);
            };

            res.showOverlay = function () {
                setTimeout(function () {
                    cleverapps.scenes.getRunningScene().addChild(new PaymentOverlay());
                }, 5000);
            };

            res[(cleverapps.config.testRestore ? ToolMenuItem.ACTIVE_ITEM : "") + "restoreCheck"] = function () {
                cleverapps.config.testRestore = !cleverapps.config.testRestore;
                cleverapps.toolModel.refresh("payments");
            };

            if (connector.platform.oneOf(connector.WECHAT)) {
                res.wechat = {
                    present1000: function () {
                        var path = "/wechat/present/" + encodeURIComponent(connector.platform.getUserID());
                        cleverapps.RestClient.post(path, { amount: 1000 }, function (response) {
                            console.log("present success - " + JSON.stringify(response));
                            cleverapps.notification.create("Present - " + JSON.stringify(response));
                        }, function (response) {
                            console.log("present failure", response);
                            cleverapps.notification.create("Present Failure");
                        });
                    },
                    balance: function () {
                        connector.payments.getPlugin().getBalance(function (balance) {
                            cleverapps.notification.create(JSON.stringify(balance));
                        }, function () {
                            cleverapps.notification.create("Balance Failure");
                        });
                    }
                };
            }

            return res;
        }),

        abTests: ToolFolder.create(function () {
            var res = {};

            Object.keys(cleverapps.ABTest.Tests || {}).forEach(function (id) {
                var test = cleverapps.ABTest.Tests[id];

                if (!cleverapps.user.checkAvailable(test.available)) {
                    return;
                }

                var group = cleverapps.abTest.getGroup(test);
                var name = "";
                for (var prop in cleverapps.ABTest) {
                    if (cleverapps.ABTest[prop] === group) {
                        name = test.id + ":" + prop;
                        break;
                    }
                }
                res[name] = function () {
                    group = cleverapps.abTest.getGroup(test);
                    var groups = test.groups;
                    var index = groups.indexOf(group);
                    var nextGroup = groups[(index + 1) % groups.length];
                    cleverapps.abTest.forceGroup(
                        test,
                        nextGroup
                    );

                    cleverapps.toolModel.refresh();
                };
            }, this);

            return res;
        }),

        adLimits: ToolFolder.create(function () {
            var res = {};

            var map = {};

            for (var type1 in AdsLimits.TYPES) {
                map[AdsLimits.TYPES[type1]] = type1;
            }

            function millisecondsToTimeString(milliseconds) {
                if (!milliseconds) {
                    return "";
                }
                var seconds = Math.floor(milliseconds / 1000);
                var twoDigitHours = String(Math.floor(seconds / 3600));
                var twoDigitMinutes = String(Math.floor((seconds % 3600) / 60));
                var twoDigitSeconds = String((seconds % 60));

                if (twoDigitHours.length < 2) {
                    twoDigitHours = "0" + twoDigitHours;
                }

                if (twoDigitMinutes.length < 2) {
                    twoDigitMinutes = "0" + twoDigitMinutes;
                }

                if (twoDigitSeconds.length < 2) {
                    twoDigitSeconds = "0" + twoDigitSeconds;
                }

                return " " + twoDigitHours + ":" + twoDigitMinutes + ":" + twoDigitSeconds;
            }

            Object.keys(AdsLimits.LIMITS).forEach(function (type) {
                var limit = cleverapps.adsLimits.getLimit(type);

                var name;
                if (cleverapps.adsLimits.state(type) === AdsLimits.STATE_DISABLED) {
                    name = map[type] + " (Disabled)";
                } else {
                    var timeout = cleverapps.adsLimits.getLimitLeftTime(type) || cleverapps.adsLimits.getLimitTimeout(type);
                    name = map[type] + " (" + cleverapps.adsLimits.getLimitLeftCount(type) + "/" + limit.limit + millisecondsToTimeString(timeout) + ")";
                }

                if (cleverapps.user.checkAvailable(limit.available)) {
                    res[name] = function () {
                        if (cleverapps.adsLimits.state(type) === AdsLimits.STATE_READY) {
                            cleverapps.adsLimits.watch(type);

                            cleverapps.toolModel.refresh();
                        }
                    };
                }
            });
            res.setVolume1 = function () {
                for (var type in cleverapps.adsLimits.volumeControl) {
                    cleverapps.adsLimits.volumeControl[type] = 1;
                }
                cleverapps.toolModel.refresh();
            };

            res.setVolume10 = function () {
                for (var type in cleverapps.adsLimits.volumeControl) {
                    cleverapps.adsLimits.volumeControl[type] = 10;
                }
                cleverapps.toolModel.refresh();
            };

            res.setVolume60 = function () {
                for (var type in cleverapps.adsLimits.volumeControl) {
                    cleverapps.adsLimits.volumeControl[type] = 60;
                }
                cleverapps.toolModel.refresh();
            };

            res.resetAll = function () {
                Object.keys(AdsLimits.LIMITS).forEach(function (type) {
                    cleverapps.adsLimits.reset(type);
                });
                cleverapps.toolModel.refresh();
            };

            return res;
        }),

        clans: {
            addFakePlayers: function () {
                return ClansTool.addFakePlayers();
            },

            sendMessage: function () {
                return ClansTool.sendMessage();
            },

            sendRequest: function () {
                return ClansTool.sendRequest();
            },

            fillHelp: function () {
                return ClansTool.fillHelp();
            }
        },

        toggleDebugMinimal: function () {
            cleverapps.flags.debugMinimalDialogue = !cleverapps.flags.debugMinimalDialogue;

            cleverapps.notification.create((cleverapps.flags.debugMinimalDialogue ? "Enabled" : "Disabled") + " debug minimal");
        },

        sceneSize: function () {
            var size = cleverapps.resolution.getSceneSize();
            cleverapps.notification.create("width: " + size.width + " height: " + size.height);
        },

        throwTestError: function () {
            cleverapps.throwAsync("test error");
        },

        throwTestErrorOnLoad: function () {
            cleverapps.dataLoader.save(DataLoader.TYPES.THROWERROR, true);
            setTimeout(window.location.reload.bind(window.location), 1000);
        },

        isIOSHighPerformanceMode: function () {
            cleverapps.notification.create("isIOSHighPerformanceMode: " + GameGlobal.isIOSHighPerformanceMode);
        },

        returnBonus: {
            addActiveGift: function () {
                cleverapps.returnBonus.hasReward = true;
            }
        },

        lazyLoad: function () {
            displayToolPrompt(function (value) {
                value = parseFloat(value.replace(",", "."));
                if (isNaN(value)) {
                    return;
                }
                if (!LazyAssets.prototype._loadBundle) {
                    LazyAssets.prototype._loadBundle = LazyAssets.prototype.loadBundle;
                }

                if (value === 0) {
                    LazyAssets.prototype.loadBundle = LazyAssets.prototype._loadBundle;
                    delete LazyAssets.prototype._loadBundle;
                    return;
                }

                LazyAssets.prototype.loadBundle = function () {
                    var args = arguments;
                    var delayed = LazyAssets.prototype._loadBundle;
                    var scene = cc.director.getRunningScene();
                    setTimeout(function () {
                        if (scene.isRunning()) {
                            delayed.apply(cleverapps.lazyAssets, args);
                        }
                    }, value * 1000);
                };
            }, "Seconds delay", 1.5);
        },

        safePadding: {
            set: function () {
                displayToolPrompt(function (str) {
                    var padding = str.split(" ");
                    if (padding.length !== 4) {
                        return;
                    }

                    padding = padding.map(function (p) {
                        return parseInt(p);
                    });

                    cleverapps.resolution.setSafePaddingDebug(cc.padding.apply(null, padding));
                }, "Enter padding in format [top right bottom left]", "255 0 0 0");
            },
            reset: function () {
                cleverapps.resolution.setSafePaddingDebug();
            },
            showSafeArea: {
                flag: "showSafeArea"
            }
        },

        testSyncReload: function () {
            cleverapps.bundleLoader.loadBundle("snapshots", {
                onSuccess: function () {
                    var keys = Object.keys(bundles.snapshots.jsons);
                    var snapshot = cc.loader.getRes(bundles.snapshots.jsons[keys[Math.floor(Date.now() % keys.length)]]);

                    connector.dataLoader.setEnabled(false);
                    cleverapps.flags.norest = true;

                    cleverapps.synchronizer._loadedSync = cleverapps.clone(snapshot);
                    cleverapps.synchronizer._syncIn.process();
                }
            });
        },
        
        reskinUnits: ToolFolder.create(function () {
            var skins = {};

            Map2d.AVAILABLE_UNITS.forEach(function (unitSkin) {
                var skin = unitSkin.replace("units_", "");
                if (cleverapps.skins.getSlot("skinName") === skin) {
                    skins[ToolMenuItem.ACTIVE_ITEM + skin] = function () {};
                } else {
                    skins[skin] = function () {
                        var oldSkin = cleverapps.skins.getSlot("skinName");
                        bundles["location_" + cleverapps.travelBook.getCurrentPage().id].meta.units = skin;
                        cleverapps.toolModel.refresh();

                        cleverapps.bundleLoader.loadBundle("units_" + skin, {
                            onSuccess: function () {
                                Map2d.currentMap.listAvailableUnits().forEach(function (unit) {
                                    unit.onRemove();
                                    Map2d.currentMap.onAddUnit(unit.x, unit.y, unit);
                                });

                                cleverapps.bundleLoader.deleteBundle("units_" + oldSkin);
                                cleverapps.bundleLoader.clearUnusedResources();
                            },
                            blocked: true
                        });
                    };
                }
            });

            return skins;
        })
    },

    SOCIAL: {
        share: ToolFolder.create(function () {
            var windows = {};

            if (cleverapps.config.type === "merge") {
                windows.achievement = function () {
                    var window = new FinishQuestWindow(Game.currentGame.quests.quests[0]);
                    window.needsShare = function () {
                        return true;
                    };
                };

                windows.bonus_world = function () {
                    RewardWindow.createMissionWindow({
                        hard: 100
                    }, Missions[Mission.TYPE_DRAGONIA_EXPEDITION]);
                };

                windows.reward = function () {
                    new RewardWindow({ worker: Workers.ADS_BONUS_WORKER_PERIOD }, { shareId: "reward" });
                };
            }

            if (cleverapps.config.type === "match3") {
                var scene = cleverapps.scenes.getRunningScene();
                windows.victory = function () {
                    new VictoryWindow(scene.game);
                };
            }

            windows.tournament = function () {
                if (cleverapps.isKnockoutGame()) {
                    new KnockoutVictoryWindow();
                } else {
                    new RewardWindow({ hard: 100 }, { shareId: "tournament", event: cleverapps.EVENTS.EARN.OTHER });
                }
            };

            var res = {};
            Object.keys(windows).forEach(function (shareId) {
                res[shareId] = function () {
                    Share.isAvailable = function () {
                        return true;
                    };

                    cleverapps.meta.display({
                        focus: "ToolShare",
                        action: function (f) {
                            windows[shareId]();
                            cleverapps.meta.onceNoWindowsListener = f;
                        }
                    });
                };
            });

            return res;
        }),

        inviteFriends: function () {
            cleverapps.invites.invite(function (success) {
                cleverapps.notification.create("Invite result: " + success);
            });
        },

        listFriends: function () {
            connector.social.listFriends(function (code, list) {
                if (code === cleverapps.CODE_SUCCEED) {
                    list.forEach(function (friend) {
                        console.log(JSON.stringify(friend));
                    });
                }
            });
        },

        testSchedule: function () {
            console.log("sendPush: " + cleverapps.LocalPushes.TIMEOUT_THRESHOLD * 1.1);

            cleverapps.localPushes.sendPush(cleverapps.LocalPushes.TYPES.LIVES, cleverapps.LocalPushes.TIMEOUT_THRESHOLD * 1.1);
        },

        testCancel: function () {
            console.log("cancelPush");

            cleverapps.localPushes.cancelPush(cleverapps.LocalPushes.TYPES.LIVES);
        },

        createShortcut: function () {
            connector.platform.createShortcutCallback(function (code) {
                cleverapps.notification.create(code);
            });
        }
    },

    currency: ToolFolder.create(function () {
        var data = {
            hard_add1000: function () {
                cleverapps.user.earnHard(cleverapps.EVENTS.EARN.OTHER, 1000);
            },
            hard_add: function () {
                displayToolPrompt(function (value) {
                    cleverapps.user.earnHard(cleverapps.EVENTS.EARN.OTHER, parseInt(value || "0"));
                }, "Enter amount");
            },
            hard_reset: function () {
                cleverapps.user.gold = 0;
                cleverapps.user.onChangeGold();
            }
        };

        if (cleverapps.config.soft) {
            data.soft_add1000 = function () {
                cleverapps.user.setSoft(cleverapps.user.soft + 1000, false, { event: cleverapps.EVENTS.EARN.OTHER });
            };
            data.soft_add = function () {
                displayToolPrompt(function (value) {
                    cleverapps.user.setSoft(cleverapps.user.soft + parseInt(value || "0"), false, { event: cleverapps.EVENTS.EARN.OTHER });
                }, "Enter amount", 0);
            };
            data.soft_reset = function () {
                cleverapps.user.setSoft(0);
            };
        }

        if (cleverapps.config.type === "merge" || cleverapps.config.type === "match3") {
            data.exp_add1000 = function () {
                cleverapps.exp.setExp(cleverapps.exp.getExp() + 1000);
            };
            data.exp_add = function () {
                displayToolPrompt(function (value) {
                    cleverapps.exp.setExp(cleverapps.exp.getExp() + parseInt(value || "0"));
                }, "Enter amount", 0);
            };
            data.exp_reset = function () {
                cleverapps.exp.setExp(0);
            };
        }

        if (cleverapps.config.type === "merge") {
            data.wands_add1000 = function () {
                Game.currentGame.setWands(Game.currentGame.wands + 1000);
            };
            data.wands_add = function () {
                displayToolPrompt(function (value) {
                    Game.currentGame.setWands(Game.currentGame.wands + parseInt(value || "0"));
                }, "Enter amount", 0);
            };
            data.wands_reset = function () {
                Game.currentGame.setWands(0);
            };
            data.crops_reset = function () {
                var harvested = Game.currentGame.harvested;
                for (var code in harvested.items) {
                    var value = harvested.get(code);
                    harvested.take(code, value);
                }
            };
        }

        if (cleverapps.lives) {
            data.lives_setFull = function () {
                cleverapps.lives.setAmount(cleverapps.lives.getMaxLives());
            };
            data.lives_add1 = function () {
                cleverapps.lives.setAmount(cleverapps.lives.amount + 1);
            };
            data.lives_add = function () {
                displayToolPrompt(function (value) {
                    cleverapps.lives.setAmount(cleverapps.lives.amount + parseInt(value || "0"));
                }, "Enter amount", 0);
            };
            data.lives_reset = function () {
                if (cleverapps.unlimitedLives) {
                    cleverapps.unlimitedLives.reset();
                }
                cleverapps.lives.setAmount(0);
            };

            if (cleverapps.unlimitedLives) {
                data.lives_addUnlimited = function () {
                    displayToolPrompt(function (time) {
                        cleverapps.unlimitedLives.buy(time);
                        cleverapps.lives.onBuyUnlimitedLivesListener();
                    }, "Time", "5 seconds");
                };

                data.lives_extendUnlimitedLivesWindow = displayToolWindow(function () {
                    new ExtendUnlimitedLivesWindow();
                });
            }
        }

        return data;
    }),

    boosters: ToolFolder.create(function () {
        var boosters = cleverapps.boosters.listAll();

        var res = {};

        var addAmount = function (id, amount) {
            cleverapps.boosters.add(id, amount);
        };

        var boostersTitle = {};
        boostersTitle[cleverapps.Boosters.TYPE_BRUSH] = "Brush";
        boostersTitle[cleverapps.Boosters.TYPE_DISCOVER] = "Discover";
        boostersTitle[cleverapps.Boosters.TYPE_MAGNET] = "Magnet";
        boostersTitle[cleverapps.Boosters.TYPE_DICE] = "Dice";
        boostersTitle[cleverapps.Boosters.TYPE_JOKERS] = "Two jokers";
        boostersTitle[cleverapps.Boosters.TYPE_HIGHLIGHT] = "Flashlight";
        boostersTitle[cleverapps.Boosters.TYPE_EYE] = "Glasses";
        boostersTitle[cleverapps.Boosters.TYPE_MAGNIFIER] = "Magnifier";

        cleverapps.boosters.listAll().forEach(function (booster) {
            res[(boostersTitle[booster.id] || "Booster title") + " (id:" + booster.id + ")"] = addAmount.bind(null, booster.id, 1);
        });

        res.reset = function () {
            boosters.forEach(function (booster) {
                cleverapps.boosters.setAmount(booster.id, 0);
            });
            cleverapps.boosters.save();
        };

        return res;
    }),

    help: {
        hotKeys: ToolFolder.create(Shortcuts.GetMemo)
    },

    stickersBook: ToolFolder.create(function () {
        if (!cleverapps.stickersBook) {
            return;
        }
        var res = {
            addJokers: function () {
                var amount = parseInt(window.prompt("Enter amount", 10));
                cleverapps.stickersBook.addJokers(amount);
            },

            addPoints: function () {
                var amount = parseInt(window.prompt("Enter amount", 10));
                cleverapps.stickersBook.incPoints(amount);
            },

            openAll: function () {
                cleverapps.stickersBook.collections.forEach(function (collection) {
                    collection.stickers.forEach(function (sticker) {
                        sticker.open();
                    });
                });
            },

            clearAll: function () {
                cleverapps.windows.closeAllWindows();
                cleverapps.stickersBook.collections.forEach(function (collection) {
                    collection.stickers.forEach(function (sticker) {
                        sticker.state = Sticker.STATE_CLOSED;
                        sticker.setAttention(false);
                    });

                    collection.resetAttention();
                    collection.isPrizeCollected = false;
                    collection.isCompleted = false;
                });
                cleverapps.stickersBook.save();
            }
        };

        return res;
    }),

    dailyTasks: ToolFolder.create(function () {
        return !cleverapps.dailyTasks ? undefined : {
            regenerate: function () {
                this.dailyTasksIteration = this.dailyTasksIteration || 0;
                this.dailyTasksIteration++;
                cleverapps.dailyTasks._generatedTasks = undefined;
                cleverapps.dailyTasks.startNewTasks();
                cleverapps.dailyTasks.onChangeStateListener();

                if (cleverapps.windows.list.length > 0
                    && cleverapps.windows.list[cleverapps.windows.list.length - 1] instanceof DailyTasksWindow) {
                    var window = cleverapps.windows.list[cleverapps.windows.list.length - 1];
                    window.close();
                }

                cleverapps.meta.display({
                    focus: "DailyTasksWindow",
                    action: function (f) {
                        new DailyTasksWindow();
                        cleverapps.meta.onceNoWindowsListener = f;
                    }
                });
            },

            choose: ToolFolder.create(function () {
                var menu = {};

                [DailyTask.EASY, DailyTask.MEDIUM, DailyTask.HARD].forEach(function (difficulty) {
                    var configs = cleverapps.dailyTasks.listTaskByDifficulty(difficulty);

                    configs.forEach(function (config) {
                        var name = DailyTasks.GuessName(config.type);
                        if (name === undefined) {
                            console.log(config, difficulty);
                        }

                        menu[name] = function () {
                            var slot = [0, 2, 4][config.difficulty];
                            cleverapps.dailyTasks.swapOutTask(cleverapps.dailyTasks.tasks[slot], config);
                        };
                    });
                });

                return menu;
            }),

            finish: function () {
                var tasks = cleverapps.dailyTasks.getActiveTasks();
                if (tasks.length > 0) {
                    var task = tasks[0];
                    cleverapps.eventBus.trigger("taskEvent", task.type, task.goal, task.data);
                }
            },

            add_1: function () {
                var tasks = cleverapps.dailyTasks.getActiveTasks();
                if (tasks.length > 0) {
                    var task = tasks[0];
                    cleverapps.eventBus.trigger("taskEvent", task.type, 1, task.data);
                }
            }
        };
    }),

    growthFund: {
        setRewardsLvl: function (lvl) {
            if (!cleverapps.growthFund) {
                return;
            }

            var callback = function (lvl) {
                if (isNaN(lvl)) {
                    return;
                }
                lvl = Math.max(0, Math.min(lvl, cleverapps.user.level));

                var received = cleverapps.growthFund.isBought() ? 0 : -1;
                cleverapps.growthFund.levels.forEach(function (level) {
                    if (level.levelNo <= lvl) {
                        received = level.levelNo;
                    }
                });

                cleverapps.growthFund.received = received;
                cleverapps.growthFund.calcLevelsState();
                cleverapps.growthFund.trigger("updateState");
                cleverapps.growthFund.save();

                var win = cleverapps.windows.currentWindow();
                if (win && win instanceof GrowthFundWindow) {
                    win.close();
                }
            };

            if (lvl === undefined) {
                displayToolPrompt(callback, "Level", 1);
            } else {
                callback(lvl);
            }
        },

        reset: function () {
            if (!cleverapps.growthFund) {
                return;
            }
            cleverapps.growthFund.received = -1;
            cleverapps.growthFund.calcLevelsState();
            cleverapps.growthFund.trigger("updateState");
            cleverapps.growthFund.save();
        }
    },

    subscriptions: ToolFolder.create(function () {
        var res = {};
        if (!Subscription.IsAvailable()) {
            return res;
        }

        var getServerInfo = function (callback) {
            return function () {
                var params = {
                    source: connector.info.source,
                    tokens: cleverapps.subscription.tokens
                };
                cleverapps.RestClient.get("/subscriptions/getinfo/" + connector.platform.getUserID(), params, function (subscription) {
                    subscription = subscription || { data: cleverapps.subscription.getInfo() };
                    console.log("subscription - " + JSON.stringify(subscription));
                    callback(subscription);
                });
            };
        };

        res.serverInfo = getServerInfo(function (subscription) {
            displayToolWindow(function () {
                new ToolSubscriptionWindow(subscription);
            })();
        });

        if (cleverapps.isLocalhost() || connector.platform.oneOf(connector.CLEVERAPPS) && cleverapps.config.debugMode) {
            res.cancel = function () {
                if (cleverapps.subscription.isActive()) {
                    cleverapps.subscription.active = false;
                    cleverapps.subscription.save();
                    cleverapps.subscription.trigger("update");
                }
            };
        }
        res.setRewardTime = function () {
            var interval = window.prompt("Enter time", "5 seconds");
            if (!interval) {
                return;
            }
            var time = cleverapps.parseInterval(interval);
            cleverapps.subscription.rewardTime = Date.now() - cleverapps.parseInterval(Subscription.REWARD_INTERVAL) + time;
            cleverapps.subscription.save();
        };
        return res;
    }),

    missions: ToolFolder.create(function () {
        return MissionsTool.getToolItems(this);
    }),

    paymentsHistory: {
        add500: function () {
            cleverapps.paymentsHistory.addPayment(Product.CreateById("gold500"));
        },
        add1800: function () {
            cleverapps.paymentsHistory.addPayment(Product.CreateById("gold1800"));
        },
        add6000: function () {
            cleverapps.paymentsHistory.addPayment(Product.CreateById("gold6000"));
        },
        add25000: function () {
            cleverapps.paymentsHistory.addPayment(Product.CreateById("gold25000"));
        },
        resetPaymentsHistory: function () {
            cleverapps.dataLoader.remove(DataLoader.TYPES.PAYMENTS_HISTORY);
            cleverapps.paymentsHistory.ids = [];
            cleverapps.paymentsHistory.time = [];
            cleverapps.paymentsHistory.price = [];
            cleverapps.subscription.reset();
        },
        checkClassify: function () {
            var postfix = cleverapps.paymentsHistory.isVIP() ? " (VIP)" : " (no VIP)";
            var groupNames = Object.keys(cleverapps.PaymentsHistory);
            var group = cleverapps.paymentsHistory.classify();
            var name;
            for (var i = 0; i < groupNames.length; i++) {
                if (cleverapps.PaymentsHistory[groupNames[i]] === group) {
                    name = groupNames[i];
                    break;
                }
            }
            console.log(cleverapps.paymentsHistory.getInfo());
            cleverapps.notification.create("Payer classification = " + name + postfix);
        },
        checkVIPStatus: function () {
            var postfix = cleverapps.paymentsHistory.isVIP() ? " (VIP)" : " (no VIP)";

            var groupNames = Object.keys(cleverapps.PaymentsHistory).filter(function (key) {
                return key.startsWith("BRACKET_");
            });

            var status = cleverapps.paymentsHistory.classify();
            var name;
            for (var i = 0; i < groupNames.length; i++) {
                if (cleverapps.PaymentsHistory[groupNames[i]] === status) {
                    name = groupNames[i];
                    break;
                }
            }

            cleverapps.notification.create("Payer vip status = " + name + postfix);
        },

        vipRewardedWindow: displayToolWindow(function () {
            new VIPRewardedWindow(function () {}, function () {});
        })
    },

    audio: {
        logState: function () {
            var context = cc.Audio._context;

            console.log(
                "audio state",
                cleverapps.audio.disk,
                cleverapps.audio.muted,
                cleverapps.audio.volume,
                cleverapps.audio.currentFade,
                context && context.state,
                context && context.currentTime,
                context && context.baseLatency
            );
        },

        resume: function () {
            cc.Audio._context.resume();
        },

        currentSceneDelay: function () {
            var scene = cleverapps.scenes.getRunningScene();
            var styles = scene.getAudioStyles();
            if (styles) {
                console.log("### Current scene delay: " + styles.delay);
            } else {
                console.log("### No audio styles for scene");
            }
        },

        setSceneDelay: function () {
            var delay = parseFloat(window.prompt("Delay", 1));
            if (isNaN(delay)) {
                console.log("### Wrong value");
                return;
            }

            var scene = cleverapps.scenes.getRunningScene();
            var styles = scene.getAudioStyles();
            if (!styles) {
                console.log("#### No audio styles for current scene");
                return;
            }

            if (scene instanceof GameSceneBase) {
                cleverapps.styles.GameScene.sound.delay = delay;
                console.log("#### GameSceneBase set to " + delay);
            } else if (scene instanceof cleverapps.FixedWidthScene) {
                cleverapps.styles.FixedWidthScene.sound.delay = delay;
                console.log("#### FixedWidthScene set to " + delay);
            }
        }
    },

    events: ToolFolder.create(function () {
        var res = {};
        var constructEventName = function (eventData, isActive) {
            var event = new cleverapps.Event(eventData);
            var name = event.features.join(", ");
            var networks = eventData.networks || (eventData.trueData && eventData.trueData.networks);
            if (networks) {
                var networksPrefix = "[" + networks[0];
                networksPrefix += networks[1] ? ", " + networks[1] : "";
                networksPrefix += networks[2] ? "..." : "";
                networksPrefix += "] ";
                name = networksPrefix + name;
            }
            name = isActive ? ToolMenuItem.ACTIVE_ITEM + name + " (" + new Date(eventData.finish).toLocaleDateString("ru", {
                day: "numeric", month: "numeric", hour: "numeric", minute: "numeric"
            }) + ")" : name;
            return name;
        };

        var noDublicateEvents = [];
        cleverapps.eventManager.events.forEach(function (event) {
            noDublicateEvents = noDublicateEvents.filter(function (filteredEvent) {
                return JSON.stringify(event.features.slice(0).sort()) !== JSON.stringify(filteredEvent.features.slice(0).sort());
            });
            noDublicateEvents.push(event);
        });

        noDublicateEvents.forEach(function (eventData) {
            var event = new cleverapps.Event(eventData);
            var isApplicable = cleverapps.eventManager.isEventApplicable(event);

            res[constructEventName(eventData, isApplicable)] = function () {
                eventData.toolApplicable = !isApplicable;

                if (eventData.toolApplicable) {
                    eventData.trueData = {
                        start: eventData.start,
                        finish: eventData.finish,
                        networks: eventData.networks
                    };
                    var date = new Date();
                    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
                    date.setSeconds(date.getSeconds() - 1);
                    eventData.start = date.toISOString();
                    date.setSeconds(date.getSeconds() + 60 * 60);
                    eventData.finish = date.toISOString();
                    delete eventData.networks;
                } else if (eventData.trueData) {
                    eventData.start = eventData.trueData.start;
                    eventData.finish = eventData.trueData.finish;
                    eventData.networks = eventData.trueData.networks;
                    delete eventData.trueData;
                }

                cleverapps.refreshScene();
            };
        });
        return res;
    }),

    friendRequests: {
        create: function () {
            levels.friendRequests.listAvailableTypes().forEach(function (type) {
                var from = levels.FriendRequest.SYSTEM_ID;
                var friend = cleverapps.friends.listFriends()[0];
                if (friend && friend.id) {
                    from = friend.id;
                }

                var request = {
                    id: "__" + Math.floor(Math.random() * 1000000000000000),
                    from: from,
                    type: type
                };

                levels.friendRequests.notProcessed.push(levels.FriendRequest.createFriendRequest(request));
                levels.friendRequests.save();
                levels.friendRequests.onNewListener();
            });
        },
        clear: function () {
            levels.friendRequests.notProcessed = [];
            levels.friendRequests.save();
        }
    },

    cups: {
        daily: {
            addStars: function (cup) {
                cup = cup || cleverapps.dailyCup;

                var amount = parseInt(window.prompt("Enter stars", "10"));
                var reward = new Reward("cup", {
                    type: cup.type,
                    amount: amount
                });
                reward.receiveReward();
            },

            addPlayers: function (cup) {
                cup = cup || cleverapps.dailyCup;
                cleverapps.RestClient.get("/dailycup/addDebugPlayers/" + cup.type + "/" + encodeURIComponent(connector.platform.getUserID()), {}, function () {
                    setTimeout(function () {
                        cup.reload();
                    }, 500);
                });
            }
        },

        weekly: {
            addStars: function () {
                cleverapps.Tool.cups.daily.addStars(cleverapps.weeklyCup);
            },

            addPlayers: function () {
                cleverapps.Tool.cups.daily.addPlayers(cleverapps.weeklyCup);
            }
        },

        clan: {
            addStars: function () {
                cleverapps.Tool.cups.daily.addStars(cleverapps.clanCup);
            },

            addPlayers: function () {
                cleverapps.Tool.cups.daily.addPlayers(cleverapps.clanCup);
            }
        }
    },

    bonusWorld: {
        gotoLevel: function () {
            var mission = cleverapps.missionManager.findRunningMission(Mission.TYPE_BONUS_WORLD);
            if (mission) {
                var levelNo = parseInt(window.prompt("level number", 15)) - 1;
                if (!isNaN(levelNo)) {
                    var receivedRewards = mission.details.receivedRewards || {};

                    for (var i = 0; i < RewardsConfig.BonusWorld.length; i++) {
                        if (RewardsConfig.BonusWorld[i].level >= levelNo) {
                            delete receivedRewards[RewardsConfig.BonusWorld[i].level];
                        }
                    }

                    var details = mission.details;
                    details.receivedRewards = receivedRewards;

                    mission.update(levelNo - mission.result, details);

                    cleverapps.refreshScene();
                }
            }
        }
    },

    slotMachine: ToolFolder.create(function () {
        return {
            setPayline: function () {
                var codes = {
                    c: "coin",
                    cs: "coins",
                    b1: "booster_1",
                    b2: "booster_2",
                    b3: "booster_3"
                };

                var msg = "Enter 3 elements, separated by comma";
                for (var code in codes) {
                    msg = msg + "\n " + code + ": " + codes[code];
                }

                SlotMachine.PREDEFINED_PAYLINE = window.prompt(msg, "c,c,c")
                    .replace(/ /g, "")
                    .split(",")
                    .map(function (code2) {
                        return codes[code2];
                    });
            },

            resetPayline: function () {
                delete SlotMachine.PREDEFINED_PAYLINE;
            },

            addTicket: function () {
                var reward = new Reward("mission", { missionType: Mission.TYPE_SLOT_MACHINE, amount: 1 });
                reward.receiveReward();
                reward.onAnimationFinished();
            },

            resetTickets: function () {
                var mission = cleverapps.missionManager.findRunningMission(Mission.TYPE_SLOT_MACHINE);

                var reward = new Reward("mission", { missionType: Mission.TYPE_SLOT_MACHINE, amount: -mission.logic.getTickets() });
                reward.receiveReward();
                reward.onAnimationFinished();
            },

            resetForces: function () {
                cleverapps.forces.clearForce(Forces.SLOT_MACHINE.id);
                cleverapps.forces.clearForce(Forces.SLOT_MACHINE_ICON.id);
                cleverapps.forces.clearForce(Forces.SLOT_MACHINE_HELP.id);
            }
        };
    }),

    personWindows: {
        ConfirmSpeedUpWindow: displayToolWindow(function () {
            new ConfirmSpeedUpWindow({
                timeLeft: cleverapps.parseInterval("1 minute"),
                totalDuration: cleverapps.parseInterval("1 minute"),
                eventName: cleverapps.EVENTS.SPENT.ORDER,
                callback: function () {}
            });
        }),

        SpeedUpWindow: displayToolWindow(function () {
            new SpeedUpWindow({
                getTimeLeft: function () {
                    return cleverapps.parseInterval("1 minute");
                },
                getFullJobTime: function () {
                    return cleverapps.parseInterval("1 minute");
                },
                isSpeedUpBonusActive: function () {
                    return false;
                }
            });
        }),

        LevelUpWindow: displayToolWindow(function () {
            new LevelUpWindow();
        }),

        StartQuestWindow: displayToolWindow(function () {
            new StartQuestWindow(Game.currentGame.quests.quests.slice(0, 2));
        }),

        FinishQuestWindow: displayToolWindow(function () {
            new FinishQuestWindow(Game.currentGame.quests.quests[0]);
        })
    },

    game: {
        win: function () {
            if (Game.currentGame) {
                Game.currentGame.win();
            }
        },

        lose: function () {
            if (Game.currentGame) {
                Game.currentGame.lose();
            }
        },

        modes: ToolFolder.create(function () {
            var res = {
            };

            cleverapps.gameModes.listModes().forEach(function (flagName) {
                res[(cleverapps.gameModes[flagName] ? ToolMenuItem.ACTIVE_ITEM : "") + flagName] = function () {
                    cleverapps.gameModes.toggle(flagName);
                    cleverapps.toolModel.refresh();
                };
            });

            return res;
        }),

        exclamations: ToolFolder.create(function () {
            var res = {};
            res.random = function () {
                var msg = cleverapps.Random.mathChoose(cleverapps.exclamation.getCongratulations());
                cleverapps.exclamation.show(msg);
            };
            return res;
        })
    },

    promocodes: ToolFolder.create(function () {
        var res = {};
        for (var code in PromoCodes.CODES) {
            if (cleverapps.promoCodes.isUsedCode(code)) {
                res[code] = function () {
                    var used = cleverapps.dataLoader.load(DataLoader.TYPES.PROMOCODES);
                    used = used.filter(function (usedCode) {
                        return usedCode !== code;
                    });
                    cleverapps.dataLoader.save(DataLoader.TYPES.PROMOCODES, used);
                    cleverapps.toolModel.refresh();
                };
            }
        }
        return res;
    }),

    SAMSUNG: ToolFolder.create(function () {
        if (!connector.platform.oneOf(connector.SAMSUNG)) {
            return;
        }

        return {
            showUserId: function () {
                cleverapps.notification.create(connector.platform.getUserID());
            },
            loginUser: function () {
                cleverapps.SocialManager.login(function (success) {
                    if (!success) {
                        cleverapps.SocialManager.onFailedLogin();
                    }
                });
            },
            restart: function () {
                GSInstant.restart();
            },
            canCreateShortcut: function () {
                console.log(GSInstant.canCreateShortCut());
                cleverapps.notification.create(GSInstant.canCreateShortCut());
            },
            getLoginStatus: function () {
                var retObj = GSInstant.getLoginStatus();
                cleverapps.notification.create(retObj.result || retObj.err);
            },
            getUserIdAsync: function () {
                GSInstant.player.getPlayerIdAsync()
                    .then(function (playerId) {
                        cleverapps.notification.create(playerId);
                    })
                    .catch(function (err) {
                        cleverapps.notification.create(err);
                    });
            },
            paymentsIsReady: function () {
                cleverapps.notification.create(connector.payments.isConnected());
            },
            productInfo: function () {
                var product = connector.payments.getProduct("gold500");

                cleverapps.notification.create("textPrice: " + product.textPrice + ", price: " + product.price);
            },
            getProducts: function () {
                try {
                    GSInstantIAP.getProductListAsync().then(function (products) {
                        console.log(
                            "getProducts success",
                            products && products[0] && products[0].mItemId,
                            products && products[0] && products[0].mItemPriceString
                        );
                    }).catch(function (err) {
                        console.log("getProducts catch" + err);
                    });
                } catch (err) {
                    console.log("getProducts error " + err);
                }
            },
            getProductListAsync: function () {
                GSInstantIAP.getProductListAsync();
            }
        };
    })
};
