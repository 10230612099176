/**
 * Created by slava on 9/8/17
 */

var CellBoosterView = FieldBoosterView.extend({
    setAnimationZOrder: function (animation) {
        animation.setLocalZOrder(100);
    },

    onHurtCellsAnimation: function (cells) {
        var cell = cells[0];
        var position = BaseCellView.alignInTheGrid(cell.x, cell.y);
        var fieldView = Game.currentGame.field.onGetView();

        var animation = new cleverapps.Spine(bundles.game.jsons.cellbooster_spine_json);
        this.setAnimationZOrder(animation);
        animation.setPosition(position);
        fieldView.addChild(animation);

        animation.setAnimation(0, "animation", false);
        animation.setCompleteListener(function () {
            animation.runAction(new cc.Sequence(
                new cc.CallFunc(function () {
                    if (Game.currentGame) {
                        Game.currentGame.bigExplode();
                    }
                }),
                new cc.RemoveSelf()
            ));
        });

        cleverapps.audio.playSound(bundles.game.urls.spoon_use_effect);
    }
});