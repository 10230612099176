/**
 * Created by Andrey Popov on 20.01.2021.
 */

var WysiwygWindowBase = cc.Node.extend({
    ctor: function (tabs, width, height, noBg) {
        this._super();

        this.setContentSize2(width, height);
        this.setAnchorPoint(0.5, 0.5);
        this.setLocalZOrder(10);

        var styles = cleverapps.styles.WysiwygWindowBase;

        if (!Array.isArray(tabs)) {
            tabs = [tabs];
        }

        this.tabSprites = [];
        var tabsLayout = new cleverapps.Layout(tabs.map(function (tab, index) {
            var tabSprite = cleverapps.UI.createScale9Sprite(bundles.wysiwyg.frames.window_bg);
            tabSprite.setAnchorPoint(0.5, 0.5);
            tabSprite.setOpacity(index === 0 ? 255 : 140);
            tabSprite.setContentSize(styles.header);

            var text = cleverapps.UI.generateOnlyText(tab, cleverapps.styles.FONTS.WYSIWYG_HEADER_TEXT);
            text.setDimensions(styles.header.width, 0);
            text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
            text.setPositionRound(styles.text);
            tabSprite.addChild(text);
            
            cleverapps.UI.onClick(tabSprite, this.onTabClick.bind(this, tab));
            tabSprite.tab = tab;
            this.tabSprites.push(tabSprite);
            
            return tabSprite;
        }.bind(this)), { direction: cleverapps.UI.HORIZONTAL });

        tabsLayout.setPositionRound(styles.header);
        if (!noBg) {
            this.addChild(tabsLayout, -1);

            var bg = cleverapps.UI.createScale9Sprite(bundles.wysiwyg.frames.window_bg);
            bg.setAnchorPoint(0.5, 0.5);
            bg.setContentSize(width - styles.bg.padding, height - styles.bg.heightDiff);
            bg.setPositionRound(this.width / 2, (this.height - styles.bg.offset) / 2);
            this.addChild(bg, -1);
        }
    },

    createScroll: function (content) {
        if (this.scroll) {
            this.scroll.removeFromParent(true);
        }

        var scroll = this.scroll = new cleverapps.UI.ScrollView(undefined, {
            childrenVisibility: cleverapps.UI.ScrollView.CHILDREN_VISIBILITY_FULLCHECK
        });

        var styles = cleverapps.styles.WysiwygWindowBase;
        var availableHeight = this.height - styles.topOffset;

        scroll.setInnerContent(content);
        scroll.setContentSize2(this.width, availableHeight);

        if (content.height < availableHeight) {
            scroll.centerNode.setPositionRound(scroll.width / 2, availableHeight - content.height / 2);
        }

        scroll.setAnchorPoint(0, 0);
        scroll.setPositionRound(0, 0);

        this.addChild(this.scroll);

        this.scroll.scrollToDefault();
    },

    onTabClick: function (tab) {
        this.tabSprites.forEach(function (tabSprite) {
            tabSprite.setOpacity(tabSprite.tab === tab ? 255 : 140);
        });
    }
});

cleverapps.styles.WysiwygWindowBase = {
    header: {
        width: 150,
        height: 38,
        x: { align: "left", dx: 4 },
        y: { align: "top", dy: -4 }
    },
    text: {
        x: { align: "center" },
        y: { align: "center", dy: 4 }
    },
    bg: {
        heightDiff: 30,
        offset: 30,
        padding: 8
    },

    topOffset: 45
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    WYSIWYG_HEADER_TEXT: {
        size: 20,
        color: cleverapps.styles.COLORS.COLOR_DARK_GRAY,
        font: bundles.wysiwyg.urls.strict_font_ttf
    }
});