/**
 * Created by slava on 24.03.17.
 */

var FinishEpisodeWindow = CleverappsWindow.extend({
    onWindowLoaded: function (episodeNo) {
        this.episodeNo = episodeNo;

        var styles = cleverapps.styles.FinishEpisodeWindow;

        var text = this.createText();

        var items = [text];

        var image = this.createImage();
        if (image) {
            items.push(image);
        }

        var content = new cleverapps.Layout(items, {
            margin: styles.margin,
            direction: cleverapps.UI.VERTICAL
        });
        
        this._super({
            title: "FinishEpisodeWindow.Title",
            name: "finishepisodewindow",
            content: content,
            closeButton: true,
            button: {
                width: styles.button.width,
                height: styles.button.height,
                text: "OK",
                onPressed: this.close.bind(this)
            },
            shareId: "achievement",
            showSound: bundles.finish_episode_window && bundles.finish_episode_window.urls.finish_episode_effect || bundles.main.urls.congratulate_sfx
        });
    },

    createImage: function () {
        if (bundles.finish_episode_window) {
            var node = new cc.Node();
            node.setAnchorPoint(0.5, 0.5);
            node.setContentSize2(cleverapps.styles.FinishEpisodeWindow.animation);

            var animation = new cleverapps.Spine(bundles.finish_episode_window.jsons.episode_completed_json);
            animation.setAnimation(0, "animation", true);
            node.addChild(animation);
            animation.setPositionRound(node.width / 2, node.height / 2);

            return node;
        }

        var bundle = this.findProperBundle(this.episodeNo);

        if (bundle) {
            return new cc.Sprite(bundles[bundle].urls.episode_finish_image);
        }
    },

    createText: function () {
        var styles = cleverapps.styles.FinishEpisodeWindow;

        var message = "FinishEpisodeWindow.Text" + this.episodeNo;
        if (!Messages.has(message)) {
            message = "FinishEpisodeWindow.Text";
        }

        var text = cleverapps.UI.generateOnlyText(message, cleverapps.styles.FONTS.FINISH_EPISODE_TEXT || cleverapps.styles.FONTS.WINDOW_TEXT);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        text.setDimensions(styles.text.width, 0);

        return text;
    },

    onShow: function () {
        this._super();
    },

    findProperBundle: function (episodeNo) {
        var availableBundles = [];
        for (var i = 0; i < 2; i++) {
            var bundle = "finish_episode_" + i;
            var languageBundle = bundle + "_" + cleverapps.settings.language;

            if (bundles[languageBundle]) {
                availableBundles.push(languageBundle);
            } else if (bundles[bundle]) {
                availableBundles.push(bundle);
            } else {
                break;
            }
        }

        return availableBundles[episodeNo % availableBundles.length];
    },

    listBundles: function (episodeNo) {
        var need = ["victory_window"];

        if (bundles.finish_episode_window) {
            need.push("finish_episode_window");
        }

        var proper = this.findProperBundle(episodeNo);
        if (proper) {
            need.push(proper);
        }

        return need;
    }
});

cleverapps.styles.FinishEpisodeWindow = {
    text: {
        width: 600
    },

    margin: 40,

    animation: {
        width: 300,
        height: 300
    },

    button: {
        width: 280,
        height: 110
    }
};