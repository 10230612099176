/**
 * Created by vladislav on 3/25/19
 */

var SpecialShapeComponentView = cc.Node.extend({
    ctor: function (component, cellView) {
        this._super();

        this.component = component;
        this.cellView = cellView;

        var size = component.getSize();
        var cellSize = cleverapps.styles.BaseCellView.CELL_SIZE_PX;
        var styles = this.getStyles();

        this.cellView.setAnchorPoint(0.5, 0.5);

        if (this.component.cell.isForEditor || this.component.cell.isForGoal) {
            this.cellView.setContentSize2(styles.goal.width, styles.goal.width);
        } else {
            this.cellView.setContentSize2(cellSize * size.cols, cellSize * size.rows);
            this.cellView.setPositionRound(this.calcPositionInTheGrid());
        }
    },

    calcPositionInTheGrid: function () {
        var size = this.component.getSize();

        return BaseCellView.alignInTheGrid(this.component.cell.topLeftX + size.cols / 2 - 0.5, this.component.cell.topLeftY + size.rows / 2 - 0.5);
    },

    scaleViewContent: function () {
        var styles = this.getStyles();
        var cellSize = cleverapps.styles.BaseCellView.CELL_SIZE_PX;
        var size = this.component.getSize();

        var scaleX = styles.goal.width / (size.rows * cellSize);
        var scaleY = styles.goal.height / (size.cols * cellSize);
        this.cellView.getChildren().forEach(function (child) {
            child.setScale(Math.min(scaleX, scaleY));
        });
    },

    getStyles: function () {
        return cleverapps.styles.SpecialShapeComponentView;
    }
});

SpecialShapeComponentView.ROTATION_BY_ID = [0, 90, 180, -90];

cleverapps.styles.SpecialShapeComponentView = {
    goal: {
        width: 120,
        height: 120
    }
};