/**
 * Created by slava on 24.03.17.
 */

var WaitWindow = CleverappsWindow.extend({
    onWindowLoaded: function () {
        var content = new LoadingAnimation();

        content.setCascadeOpacityEnabled(true);
        content.setOpacity(0);
        content.setVisible(false);

        var styles = cleverapps.styles.WaitWindow;

        this._super({
            name: "waitwindow",
            noBackground: true,
            content: content,
            closeButton: false,
            styles: styles
        });

        content.runAction(
            new cc.Sequence(
                new cc.DelayTime(styles.delay),
                new cc.Show(),
                new cc.FadeTo(0.3, 255)
            )
        );
    },

    popUpAnimation: function () {

    },

    closeAnimation: function (callback) {
        callback();
    }
});

WaitWindow.show = function () {
    if (this.instance) {
        this.counter += 1;
        return;
    }

    cleverapps.meta.display({
        stack: true,
        focus: "WaitWindow",
        action: function (f) {
            this.instance = new WaitWindow();
            this.counter = 1;

            this.onceWaitWindowClosed = f;
        }.bind(this)
    });
};

WaitWindow.hide = function () {
    this.counter -= 1;

    if (this.counter === 0) {
        if (this.instance) {
            this.instance.close();
            this.instance = undefined;
        }

        if (this.onceWaitWindowClosed) {
            this.onceWaitWindowClosed();
            this.onceWaitWindowClosed = undefined;
        }
    }
};

cleverapps.styles.WaitWindow = {
    delay: 0.2
};