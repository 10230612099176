/**
 * Created by andrey on 24.09.2024
 */

var FirebaseRemoteConfig = function (name) {
    cleverapps.EventEmitter.call(this);

    this.name = name;

    var firebaseKey = cleverapps.config.firebase[name];

    var remoteConfig = this.remoteConfig = firebase.initializeApp(firebaseKey, name).remoteConfig();
    remoteConfig.defaultConfig = {};

    if (cleverapps.config.debugMode) {
        remoteConfig.settings.minimumFetchIntervalMillis = 1000;
    }

    this.initializeThrottle = cleverapps.throttle(10000, cleverapps.accumulate(0, this.initialize.bind(this)));
    this.fetchAndActivateThrottle = cleverapps.throttle(10000, cleverapps.accumulate(0, this.fetchAndActivate.bind(this)));

    cc.eventManager.addCustomListener(cc.game.EVENT_SHOW, function () {
        this.fetchAndActivateThrottle();
    }.bind(this));
};

FirebaseRemoteConfig.prototype = Object.create(cleverapps.EventEmitter.prototype);
FirebaseRemoteConfig.prototype.constructor = FirebaseRemoteConfig;

FirebaseRemoteConfig.prototype.setDefaultValue = function (key, value) {
    if (typeof value === "object") {
        value = JSON.stringify(value);
    }

    this.remoteConfig.defaultConfig[key] = value;
};

FirebaseRemoteConfig.prototype.initialize = function () {
    if (this.initialized) {
        return;
    }

    var name = this.name;
    // var start = Date.now();

    this.remoteConfig.ensureInitialized()
        .then(function () {
            // console.log("RemoteConfig " + name + " initialize success - " + (Date.now() - start) + "ms");
            this.initialized = true;
            this.trigger("initialize");
            this.trigger("update");
        }.bind(this))
        .catch(function (e) {
            console.log("RemoteConfig " + name + " initialize error", e);
        });
};

FirebaseRemoteConfig.prototype.fetchAndActivate = function () {
    var name = this.name;

    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.CONFIG.FETCH_START + name);

    // var start = Date.now();

    this.remoteConfig.fetchAndActivate()
        .then(function () {
            // console.log("RemoteConfig " + name + " fetch success - " + (Date.now() - start) + "ms");
            this.trigger("update");
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.CONFIG.FETCH_SUCCESS + name);
        }.bind(this))
        .catch(function (e) {
            console.log("RemoteConfig " + name + "fetch error", e);
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.CONFIG.FETCH_FAILURE + name);
        });
};

FirebaseRemoteConfig.prototype.getValue = function (key) {
    var value = this.remoteConfig.getValue(key);
    value = value && value.asString();

    if (value && key.endsWith("_json")) {
        value = JSON.parse(value);
    }

    return value;
};