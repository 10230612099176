/**
 * Created by Andrey Popov on 1/27/21.
 */

var PropertyEditor = cleverapps.Layout.extend({
    ctor: function (propertyDescription, delegate) {
        var styles = cleverapps.styles.PropertyEditor;

        this.propertyDescription = propertyDescription;

        var hasLink = propertyDescription.link && propertyDescription.getter();

        var text = cleverapps.UI.generateOnlyText(propertyDescription.name, hasLink
            ? cleverapps.styles.FONTS.LINK_PROPERTY_EDITOR_TEXT : cleverapps.styles.FONTS.PROPERTY_EDITOR_TEXT);
        text.setDimensions(styles.text.width, 0);
        text.setLineHeight(styles.editBox.height);
        text.setHorizontalAlignment(propertyDescription.isBlock ? cc.TEXT_ALIGNMENT_CENTER : cc.TEXT_ALIGNMENT_LEFT);
        text.setAnchorPoint(0.5, 0.5);

        if (hasLink) {
            this.underline = cleverapps.UI.drawUnderline(text);
            this.addChild(this.underline);
            cleverapps.UI.onClick(text, function () {
                cleverapps.snapshots.openInNewTab(cleverapps.config.deployment + "/" + propertyDescription.getter());
            });
            cleverapps.UI.applyHover(text);
        }

        this.widgets = [];
        this.addCleaner(this.cleanup.bind(this));

        var layoutItems = [text];
        var editBoxesItems = [];
        var direction = propertyDescription.direction || cleverapps.UI.HORIZONTAL;

        if (propertyDescription.actions) {
            var buttons = propertyDescription.actions.map(function (action) {
                var actionName = Object.keys(action)[0];
                var button = new cleverapps.UI.Button({
                    type: cleverapps.styles.UI.Button.Images.small_button_green,
                    textVariant: "strict_black",
                    width: styles.button.width,
                    text: actionName,
                    onClicked: action[actionName].bind(delegate)
                });

                return button;
            });

            layoutItems = [new cleverapps.Layout([text].concat(buttons), {
                direction: cleverapps.UI.HORIZONTAL,
                margin: styles.margin
            })];
        }

        var editBoxesCount = 0;
        if (propertyDescription.getter) {
            editBoxesCount = 1;
        } else if (propertyDescription.getters) {
            editBoxesCount = propertyDescription.getters.length;
        }

        if (propertyDescription.name === "snapshot" && propertyDescription.getter()) {
            cleverapps.snapshots.getSnapshot(propertyDescription.getter(), function (snapshot) {
                cleverapps.loadedSnapshot = snapshot;
            });
        }

        for (var i = 0; i < editBoxesCount; i++) {
            var editBoxWidth;
            var editBoxLayoutItems = [];

            if (direction === cleverapps.UI.VERTICAL) {
                editBoxWidth = styles.editBox.width;
            } else {
                editBoxWidth = styles.editBox.width / editBoxesCount;
            }
            editBoxWidth -= styles.margin;

            if (propertyDescription.subNames) {
                var subNameText = cleverapps.UI.generateOnlyText(propertyDescription.subNames[i], cleverapps.styles.FONTS.PROPERTY_EDITOR_TEXT);
                editBoxWidth -= (subNameText.width + styles.margin);
                editBoxLayoutItems.push(subNameText);
            }

            var editBoxSize = cc.size(editBoxWidth, (propertyDescription.lines || 1) * styles.editBox.height);
            var editBoxValue = propertyDescription.getter ? propertyDescription.getter() : propertyDescription.getters[i]();

            var editBox = undefined;
            var withBg = true;
            if (propertyDescription.type === "color") {
                editBox = new ColorPicker({
                    color: editBoxValue,
                    size: editBoxSize
                });
                withBg = false;
            } else if (propertyDescription.items) {
                var savedZOrder = this.getLocalZOrder();
                editBox = new DropDown({
                    size: editBoxSize,
                    value: editBoxValue,
                    availableItems: Array.isArray(propertyDescription.items) ? propertyDescription.items : propertyDescription.items(),
                    bundlesToLoad: propertyDescription.bundles || [],
                    multiselect: propertyDescription.multiselect,
                    previewGenerator: propertyDescription.getPreview,
                    onToggleShow: function (visible) {
                        this.setLocalZOrder(visible ? 10000 : savedZOrder);
                    }.bind(this)
                });
            } else if (propertyDescription.type === "checkBox") {
                editBox = new cc.Node();
                editBox.setContentSize(editBoxSize);

                var checkBox = new cleverapps.UI.CheckBox({
                    isSelected: editBoxValue,
                    checkImage: bundles.editor_controls.frames.checkbox_check,
                    bgImage: bundles.editor_controls.frames.checkbox_bg,
                    onChange: function (state) {
                        this.state = state;
                        this.delegate && this.delegate.editBoxEditingDidEnd(editBox);
                    },
                    label: ""
                });
                checkBox.setPositionRound({
                    x: { align: "left" },
                    y: { align: "center" }
                });

                editBox.setDelegate = function (delegate) {
                    checkBox.delegate = delegate;
                };
                editBox.getString = function () {
                    return checkBox.state;
                };

                editBox.addChild(checkBox);
                withBg = false;
            } else {
                editBox = new cc.EditBox(editBoxSize);
                editBox.setMaxLength(propertyDescription.type === "number" ? 6 : 12000);
                editBox.stayOnTop(true);
                editBox.setString(editBoxValue === undefined || editBoxValue === null ? "" : editBoxValue.toString());
                editBox.setTouchEnabled();
                editBox.setFontColor(new cc.Color(cleverapps.styles.COLORS.COLOR_BROWN));
                editBox.setFontSize(20 * resolutionScale);

                if (propertyDescription.placeHolderText) {
                    editBox.setPlaceHolder(propertyDescription.placeHolderText);
                    editBox.setPlaceholderFontSize(20 * resolutionScale);
                }
            }

            editBox.setDelegate(delegate);
            editBox.customSetter = propertyDescription.setter ? propertyDescription.setter : propertyDescription.setters[i];
            editBox.language = propertyDescription.language;

            if (withBg) {
                var editBoxBg = cleverapps.UI.createScale9Sprite(bundles.editor_controls.frames.component);
                editBoxBg.addChild(editBox);
                editBoxBg.setContentSize(editBoxWidth, (propertyDescription.lines || 1) * styles.editBox.height);
                editBox.setPositionRound(editBoxBg.width / 2 + styles.text.offsetX, editBoxBg.height / 2);

                editBoxLayoutItems.push(editBoxBg);

                var removeIcon = cleverapps.UI.createSprite(bundles.wysiwyg.frames.delete_small);
                removeIcon.setColor(cc.color.RED);
                removeIcon.setVisible(false);
                cleverapps.UI.onClick(removeIcon, function () {
                    editBox.setString("");
                    editBox._delegate.editBoxEditingDidEnd(editBox);
                });
                editBox.removeIcon = removeIcon;
                editBox.addChild(removeIcon);
                removeIcon.setPositionRound(editBox.width + 3 * styles.margin, editBox.height / 2);
            } else {
                editBoxLayoutItems.push(editBox);
            }

            this.widgets.push(editBox);

            var editBoxItem = new cleverapps.Layout(editBoxLayoutItems, {
                direction: cleverapps.UI.HORIZONTAL,
                margin: styles.margin
            });
            editBoxItem.setLocalZOrder(1000 - i);

            editBoxesItems.push(editBoxItem);
        }

        this.updateOverrides();

        var editBoxesLayout = new cleverapps.Layout(editBoxesItems, {
            direction: direction,
            margin: styles.margin
        });

        this._super(layoutItems.concat(editBoxesLayout), {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });
    },

    updateOverrides: function () {
        if (!this.propertyDescription.allowOverride) {
            return;
        }

        for (var i = 0; i < this.widgets.length; i++) {
            var editBox = this.widgets[i];

            var isGrey = false;
            if (i < this.widgets.length - 1 && this.propertyDescription.getters[i + 1]() !== undefined) {
                isGrey = true;
            }

            var propValue = this.propertyDescription.getters[i]();
            if (i > 0 && (propValue === undefined || propValue === "")) {
                isGrey = true;
            }

            if (isGrey) {
                editBox.getParent().setColor(cleverapps.styles.COLORS.GRAY_SCALE_COLOR);
            } else {
                editBox.getParent().setColor(cleverapps.styles.COLORS.WHITE);
            }

            editBox.removeIcon.setVisible(i > 0 && propValue !== undefined && propValue !== "");
        }
    },

    setVisible: function (visible) {
        cc.Node.prototype.setVisible.call(this, visible);
        this.widgets.forEach(function (widget) {
            widget.setVisible(visible);
        });
    },

    cleanup: function () {
        this.widgets.forEach(function (widget) {
            if (widget.cleanup) {
                widget.cleanup();
            }
        });
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    PROPERTY_EDITOR_TEXT: {
        size: 22,
        color: cleverapps.styles.COLORS.BLACK,
        font: bundles.wysiwyg.urls.strict_font_ttf
    },

    PROPERTY_EDITOR_GREY_TEXT: {
        size: 22,
        color: cleverapps.styles.COLORS.COLOR_VERY_DARK_GRAY,
        font: bundles.wysiwyg.urls.strict_font_ttf
    },

    LINK_PROPERTY_EDITOR_TEXT: {
        size: 22,
        color: cleverapps.styles.COLORS.COLOR_BLUE,
        font: bundles.wysiwyg.urls.strict_font_ttf
    }
});

cleverapps.styles.PropertyEditor = {
    text: {
        width: 150,
        offsetX: 4
    },

    button: {
        width: 90
    },

    editBox: {
        width: 290,
        height: 24
    },

    margin: 5
};