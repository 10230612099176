/**
 * Created by vladislav on 1/18/2024
 */

var CrazyLogic = function () {
    PlatformLogic.call(this);
};

CrazyLogic.prototype = Object.create(PlatformLogic.prototype);
CrazyLogic.prototype.constructor = CrazyLogic;

CrazyLogic.prototype.loadOldUserId = function (callback) {
    cleverapps.getFrameUserId(cleverapps.config.staticUrl, {
        key: cleverapps.config.name + "_plinga",
        callback: callback
    });
};