/**
 * Created by Andrey Popov on 15.06.2021.
 */

var ScriptView = cleverapps.Layout.extend({
    ctor: function (scriptFileName, width) {
        this.scriptFileName = scriptFileName;

        var styles = cleverapps.styles.ScriptView;

        var editAreas = [{
            caption: "Script name",
            name: "scriptName"
        }, {
            caption: "Parameters",
            name: "parameters"
        }, {
            caption: "Source code in JS",
            name: "text"
        }].map(function (editArea) {
            var areaCaption = cleverapps.UI.generateOnlyText(editArea.caption, cleverapps.styles.FONTS.AREA_CAPTION_TEXT);

            var areaBg = cleverapps.UI.createScale9Sprite(bundles.editor_controls.frames.component_frame);
            areaBg.setContentSize(width - styles.textPadding, styles[editArea.name].height);

            var editBox = this[editArea.name] = new cc.EditBox(cc.size(areaBg.width - styles.textPadding, styles[editArea.name].height));
            editBox.setDelegate(this);
            editBox.stayOnTop(true);
            editBox.setTouchEnabled();
            editBox.setMaxLength(10000);
            editBox.setPositionRound(areaBg.width / 2, areaBg.height / 2);
            areaBg.addChild(editBox);

            return new cleverapps.Layout([areaCaption, areaBg], {
                direction: cleverapps.UI.VERTICAL
            });
        }.bind(this));

        this.showScriptInfo();

        this._super(editAreas.reverse(), {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });
    },

    showScriptInfo: function () {
        var scriptInfo = cleverapps.wysiwyg.assets.scripts.filter(function (script) {
            return script.fileName === this.scriptFileName;
        }.bind(this))[0];

        if (!scriptInfo) {
            return;
        }

        this.scriptName.setString(scriptInfo.name);
        this.parameters.setString(scriptInfo.parameters);
        this.text.setString(scriptInfo.exec);

        this.scriptName.setFontColor(cleverapps.styles.FONTS.SCRIPT_BODY_TEXT.color);
        this.scriptName.setFontSize(cleverapps.styles.FONTS.SCRIPT_BODY_TEXT.size);
        this.parameters.setFontColor(cleverapps.styles.FONTS.SCRIPT_BODY_TEXT.color);
        this.parameters.setFontSize(cleverapps.styles.FONTS.SCRIPT_BODY_TEXT.size);
        this.text.setFontColor(cleverapps.styles.FONTS.SCRIPT_BODY_TEXT.color);
        this.text.setFontSize(cleverapps.styles.FONTS.SCRIPT_BODY_TEXT.size);
    },

    editBoxTextChanged: function () {
    },

    editBoxEditingDidEnd: function () {
    }
});

cleverapps.styles.ScriptView = {
    margin: 20,

    buttons: {
        x: { align: "center" },
        y: { align: "center" }
    },

    textPadding: 20,

    "scriptName": {
        height: 30
    },

    "parameters": {
        height: 160
    },

    "text": {
        height: 600
    }
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    SCRIPT_BODY_TEXT: {
        size: 22,
        color: cleverapps.styles.COLORS.BLACK,
        font: bundles.wysiwyg.urls.strict_font_ttf
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    AREA_CAPTION_TEXT: {
        size: 25,
        color: cleverapps.styles.COLORS.COLOR_VERY_DARK_GRAY,
        font: bundles.wysiwyg.urls.strict_font_ttf
    }
});
