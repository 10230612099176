/**
 * Created by slava on 02.02.17.
 */

var BoosterCellView = BaseCellView.extend({
    ctor: function (boosterCell) {
        this._super();

        this.setContentSize(cleverapps.styles.BaseCellView.CELL_SIZE_PX, cleverapps.styles.BaseCellView.CELL_SIZE_PX);
        this.setAnchorPoint(0.5, 0.5);

        this.image = this.getImage(boosterCell);
        this.image.setPosition(this.width / 2, this.height / 2);
        if (cleverapps.styles.BoosterCellView.scale) {
            this.image.setScale(cleverapps.styles.BoosterCellView.scale);
        }
        this.addChild(this.image);

        this.initialize(boosterCell);
    },

    getImage: function (boosterCell) {
        return new cc.Sprite(cleverapps.styles.BoosterCellView.images[boosterCell.type]);
    },

    getCollectFlyPoints: function () {
        var target = cleverapps.styles.BoosterCellView.target;
        var targetPosition = cc.p(this.x + (this.cell.x < Field.SIZE / 2 ? target.dx : -target.dx), target.dy);
        var bezierFirstPoint, bezierSecondPoint;

        bezierSecondPoint = cc.p(targetPosition.x, targetPosition.y + 300);
        bezierFirstPoint = cc.p((this.x + bezierSecondPoint.x) / 2, (this.y + bezierSecondPoint.y) / 2 + 300);

        return [bezierFirstPoint, bezierSecondPoint, targetPosition];
    },

    startExploding: function () {
        cleverapps.audio.playSound(bundles.game.urls.cell_collect_effect, {
            throttle: 200
        });

        if (!Game.currentGame) {
            return;
        }

        this.stopAllActions();
        var parent = cleverapps.scenes.getRunningScene();
        this.replaceParentSamePlace(parent);
        this.setLocalZOrder(50);

        var fieldScale = this.getParent().getScale();
        this.setScale(fieldScale);

        var styles = cleverapps.styles.BoosterCellView;

        this.runAction(new cc.Sequence(
            new cc.Spawn(
                new cc.MoveBy(0.7, styles.collect.x, styles.collect.y).easing(cc.easeBackOut()),
                new cc.ScaleTo(0.7, 2)
            ),
            new cc.RotateBy(0.4, 360 * 2).easing(cc.easeOut(1.5)),
            new cc.Spawn(
                new cc.BezierTo(0.6, this.getCollectFlyPoints()).easing(cc.easeIn(1))
            ),
            new cc.RemoveSelf()
        ));
    }
});

cleverapps.styles.BoosterCellView = {
    scale: 0.9,
    images: [bundles.game.frames.cellbooster_png, bundles.game.frames.linebooster_png, bundles.game.frames.colorbooster_png],

    collect: {
        x: 0,
        y: 300
    },

    target: {
        dx: 250,
        dy: -100
    }
};