/**
 * Created by andrey on 06.09.19.
 */

var CloudsAnimation = cc.Node.extend({
    ctor: function () { 
        this._super();

        this.setLocalZOrder(101);

        var animation = this.animation = new cleverapps.Spine(bundles.main.jsons.episode_clouds_spine_json);
        this.addChild(animation);

        this.inUse = true;
    },

    startLoadAnimation: function (episodeView) {
        this.animation.setAnimation(0, "idle", true);

        this.setVisible(false);
        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.1),
            new cc.CallFunc(function () {
                this.visible = episodeView.isRunning() && episodeView.visible;
            }.bind(this))
        ));
    },

    finishLoadAnimation: function (onFinish) {
        this.animation.setAnimation(0, "open", false);
        this.animation.setCompleteListener(function () {
            this.finishAnimation(onFinish);
        }.bind(this));
    },

    finishAnimation: function (onFinish) {
        if (this.inUse) {
            this.inUse = false;
            cc.pool.putInPool(this);

            if (typeof onFinish === "function") {
                onFinish();
            }
        }
    },

    unuse: function () {
        this.animation.visible = false;
        this.animation.setCompleteListener();
        this.animation.setStartVisibleListener(function () {});
        this.removeFromParent(); // if this.removeFromParent(false); then stable crashes in ios - with cleanup ok
    },

    reuse: function () {
        this.inUse = true;
    }
});

CloudsAnimation.factory = function () {
    return cc.pool.hasObject(CloudsAnimation) ? cc.pool.getFromPool(CloudsAnimation) : new CloudsAnimation();
};
