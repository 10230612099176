/**
 * Created by razial on 09.04.2024.
 */

cleverapps.EventEmitter = function () {
    this.bindings = {};
};

cleverapps.EventEmitter.prototype.once = function (eventName, callback, target) {
    var handler = this.on(eventName, function () {
        handler.clear();
        callback.apply(this, arguments);
    }.bind(this), target);
};

cleverapps.EventEmitter.prototype.on = function (eventName, callback, target) {
    if (!this.bindings[eventName]) {
        this.bindings[eventName] = [];
    }
    this.bindings[eventName].push(callback);
    var handler = {
        clear: function () {
            if (this.bindings[eventName]) {
                var index = this.bindings[eventName].indexOf(callback);
                if (index >= 0) {
                    this.bindings[eventName].splice(index, 1);
                }
            }
        }.bind(this)
    };

    addCleaner(target, handler.clear);

    return handler;
};

cleverapps.EventEmitter.prototype.off = function (eventName) {
    this.bindings[eventName] = undefined;
};

cleverapps.EventEmitter.prototype.purge = function () {
    this.bindings = {};
};

cleverapps.EventEmitter.prototype.trigger = function () {
    var args = [];
    for (var i = 0; i < arguments.length; i++) {
        args.push(arguments[i]);
    }

    var eventName = args.shift();
    if (this.bindings[eventName]) {
        this.bindings[eventName].slice().forEach(function (callback) {
            callback.apply(this, args);
        });
    }
};

var addCleaner = function (target, cleaner) {
    if (typeof target === "object") {
        if (!target._cleaners) {
            target._cleaners = [];
        }
        target._cleaners.push(cleaner);
    }
};

var runCleaners = function (target) {
    if (target._cleaners) {
        target._cleaners.forEach(cleverapps.callFunc);
        delete target._cleaners;
    }
};
