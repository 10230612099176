/**
 * Created by Andrey Popov on 1/22/21.
 */

var HierarchyItem = function (options) {
    Object.assign(this, options);

    if (!this.properties) {
        this.properties = {};
    }

    this.children = [];

    HierarchyItem.processProperties(this.properties);
};

HierarchyItem.prototype.toJSON = function () {
    var properties = Object.assign({}, this.properties);
    delete properties.isPreview;
    delete properties.mtime;

    return {
        id: this.id,
        type: this.type,
        parent: this.parentItem ? this.parentItem.id : undefined,
        assetName: this.assetName,
        properties: properties
    };
};

HierarchyItem.createClip = function (caption) {
    return new HierarchyItem({
        type: HierarchyItem.TYPE.CLIP,
        id: caption,
        properties: {
            caption: caption,
            languages: ["en"],
            resolutions: [Object.keys(Wysiwyg.VIDEO_SIZES)[0]]
        }
    });
};

HierarchyItem.createScene = function (caption, clip) {
    return new HierarchyItem({
        type: HierarchyItem.TYPE.SCENE,
        parentItem: clip,
        id: clip.id + "_" + caption,
        properties: {
            caption: caption,
            duration: 3000
        }
    });
};

HierarchyItem.createScript = function (name, component, properties) {
    return new HierarchyItem({
        type: HierarchyItem.TYPE.SCRIPT,
        parentItem: component,
        id: component.id + "_" + name,
        properties: properties
    });
};

HierarchyItem.createDirectory = function (name) {
    return new HierarchyItem({
        type: HierarchyItem.TYPE.DIRECTORY,
        id: name,
        properties: {
            caption: "cleverapps/res/" + cleverapps.config.name + "/" + name
        }
    });
};

HierarchyItem.prototype.onClick = function () {
    if (this.type === HierarchyItem.TYPE.DIRECTORY) {
        cleverapps.wysiwyg.hierarchy.selectDirectory(this.id);
    } else if (this.type === HierarchyItem.TYPE.CLIP) {
        cleverapps.wysiwyg.hierarchy.loadClip(this.id);
    } else {
        cleverapps.wysiwyg.hierarchy.selectItem(this);
    }
};

HierarchyItem.prototype.onIconClick = function () {
    if (HierarchyItem.TYPE.COMPONENT === this.type) {
        cleverapps.wysiwyg.hierarchy.changeItemProperty(this, "visible", !this.properties.visible);
    }
};

HierarchyItem.prototype.isSelected = function () {
    return cleverapps.wysiwyg.hierarchy.selectedItem === this;
};

HierarchyItem.prototype.clone = function (parent) {
    var copy = new HierarchyItem(this.toJSON());

    switch (copy.type) {
        case HierarchyItem.TYPE.CLIP:
            copy.id = "clip_" + cleverapps.wysiwyg.hierarchy.getNewClipIndex();
            break;
        case HierarchyItem.TYPE.SCENE:
            var newSceneIndex = parent.children.length + 1;
            copy.id = parent.id + "_scene_" + newSceneIndex;
            copy.parentItem = parent;
            break;
        case HierarchyItem.TYPE.SCRIPT:
            copy.id = parent.id + "_script_" + copy.name;
            copy.parentItem = parent;
            break;
        case HierarchyItem.TYPE.COMPONENT:
            copy.id = SceneComponent.ID++;
            copy.parentItem = parent;
            break;
    }

    return copy;
};

HierarchyItem.prototype.getLevel = function () {
    if (this.type === HierarchyItem.TYPE.DIRECTORY) {
        return 0;
    }

    var level = 1;
    var parent = this.parentItem;

    while (parent) {
        level++;
        parent = parent.parentItem;
    }
    return level;
};

HierarchyItem.prototype.getCaption = function () {
    var caption = this.properties.caption;
    if (this.type === HierarchyItem.TYPE.CLIP) {
        caption = caption + " (" + this.id.replace("clip_", "#") + ")";
    } else if (this.type === HierarchyItem.TYPE.COMPONENT && this.assetName) {
        caption = this.assetName.replace("Ads", "");

        if (this.properties.spine && this.properties.spine.name) {
            caption = this.properties.spine.name;
        } else if (this.properties.image && this.properties.image.name) {
            caption = this.properties.image.name;
        } else if (this.properties.sound && this.properties.sound.name) {
            caption = this.properties.sound.name;
        } else if (this.properties.LocalizedText) {
            var text = this.properties.LocalizedText.en || "Text";
            if (this.properties.LocalizedText.general && this.properties.LocalizedText.general.en) {
                text = this.properties.LocalizedText.general.en;
            }

            if (text.length > 12) {
                caption = text.substring(0, 12) + "...";
            } else if (text.length > 0) {
                caption = text;
            }
        }
    }

    return caption;
};

HierarchyItem.processProperties = function (properties) {
    if (!properties || typeof properties !== "object") {
        return;
    }

    delete properties.isPreview;

    if (properties.color && properties.color._val) {
        properties.color = new cc.Color(
            (properties.color._val & 0xff000000) >>> 24,
            (properties.color._val & 0x00ff0000) >> 16,
            (properties.color._val & 0x0000ff00) >> 8,
            properties.color._val & 0x000000ff
        );
    }

    Object.keys(properties).forEach(function (key) {
        HierarchyItem.processProperties(properties[key]);
    });
};

HierarchyItem.TYPE = {
    DIRECTORY: "directory",
    CLIP: "clip",
    SCENE: "scene",
    COMPONENT: "component",
    SCRIPT: "script"
};
