/**
 * Created by Andrey Popov on 11/21/23.
 */

var HidingNode = cc.Node.extend({
    ctor: function (target, direction) {
        this._super();

        this.target = target;

        if (target.avoidNode) {
            this.avoidNode = target.avoidNode;
            target.avoidNode = undefined;
        }

        this.direction = direction !== undefined ? direction : HidingNode.DIR.AUTO;

        this.alignment = target.alignment;
        delete target.alignment;
        this.setPosition(target.getPosition());
        this.setLocalZOrder(target.getLocalZOrder());
        this.setAnchorPoint(target.getAnchorPoint());

        this.updateSize();

        target.getParent().addChild(this);
        target.replaceParent(this);
        target.setPosition(this.getOriginalPosition());

        this.target.setVisible(false);
        this.isShown = false;
    },

    updateSize: function () {
        var contentSize = this.target.getContentSize();
        this.setContentSize2(contentSize.width * Math.abs(this.target.getScaleX()), contentSize.height * Math.abs(this.target.getScaleY()));
    },

    setupChildren: function () {
        this.target.setPosition(this.isShown ? this.getOriginalPosition() : this.calcHidePosition());
        this.target.setVisible(this.isShown);
    },

    completeAnimationOnResize: function () {
        if (this.animating && !this.animating.isDone()) {
            this.target.stopAction(this.animating);
        }
    },

    calcHidePosition: function () {
        var direction = this.direction;

        if (direction === HidingNode.DIR.NONE) {
            return this.getOriginalPosition();
        }

        var pos = this.getParent().convertToWorldSpace(this.getPosition());
        var winSize = cleverapps.resolution.getBgSize();

        if (direction === HidingNode.DIR.AUTO) {
            var box = this.getBoundingBox();

            var deltaX = Math.max(0, Math.min(box.x, winSize.width - box.x - box.width));
            var deltaY = Math.max(0, Math.min(box.y, winSize.height - box.y - box.height));
            direction = deltaX > deltaY ? cleverapps.UI.VERTICAL : cleverapps.UI.HORIZONTAL;
        }

        var width = this.width * this.scaleX;
        var height = this.height * this.scaleY;
        var middle = cc.p(pos.x + width * (0.5 - this.anchorX), pos.y + height * (0.5 - this.anchorY));

        var hideMiddle, d;
        if (direction === cleverapps.UI.VERTICAL) {
            d = (pos.y > winSize.height / 2) ? (winSize.height - middle.y) : -middle.y;
            hideMiddle = cc.p(middle.x, middle.y + 2 * d);
        } else {
            d = (pos.x > winSize.width / 2) ? (winSize.width - middle.x) : -middle.x;
            hideMiddle = cc.p(middle.x + 2 * d, middle.y);
        }

        return this.convertToNodeSpace(cc.p(
            hideMiddle.x + width * (this.anchorX - 0.5),
            hideMiddle.y + height * (this.anchorY - 0.5)
        ));
    },

    getOriginalPosition: function () {
        return cc.p(this.width / 2, this.height / 2);
    },

    toggle: function (visible, silent) {
        if (visible) {
            this.show(silent);
        } else {
            this.hide(silent);
        }
    },

    show: function (silent) {
        if (this.isShown === true) {
            return;
        }

        this.isShown = true;

        if (this.target.onShow) {
            this.target.onShow(silent);
        }

        cleverapps.scenes.onAvoidNodeVisibilityChanged();

        if (this.target.showAnimation) {
            this.target.showAnimation(silent);
            return;
        }

        if (this.animating && !this.animating.isDone()) {
            this.target.stopAction(this.animating);
        }

        if (silent) {
            this.target.setPosition(this.getOriginalPosition());
            this.target.setVisible(true);
            return;
        }

        this.target.setPosition(this.calcHidePosition());
        this.animating = this.target.runAction(
            new cc.Sequence(
                new cc.Show(),
                new cc.MoveTo(0.4, this.getOriginalPosition()).easing(cc.easeBackOut(2))
            )
        );
    },

    hide: function (silent) {
        if (this.isShown === false) {
            return;
        }

        this.isShown = false;

        if (this.target.onHide) {
            this.target.onHide(silent);
        }

        cleverapps.scenes.onAvoidNodeVisibilityChanged();

        if (this.target.hideAnimation) {
            this.target.hideAnimation(silent);
            return;
        }

        if (this.animating && !this.animating.isDone()) {
            this.target.stopAction(this.animating);
        }

        if (silent) {
            this.target.setPosition(this.calcHidePosition());
            this.target.setVisible(false);
            return;
        }

        this.animating = this.target.runAction(
            new cc.Sequence(
                new cc.MoveTo(0.15, this.calcHidePosition()).easing(cc.easeBackIn(2)),
                new cc.Hide()
            )
        );
    }
});

HidingNode.DIR = {
    AUTO: 2,
    NONE: 3
};
