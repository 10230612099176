/**
 * Created by andrey on 29.05.17.
 */
var EditorMovesView = cc.Node.extend({
    ctor: function (editor) {
        this._super();

        var movesView = new MovesView({ moves: editor.moves });

        if (!editor.loaded) {
            editor.onLoadSetMoves = function () {
                movesView.moves._setValue(editor.moves);
            };
        }
        if (movesView.text) {
            movesView.text.setVisible(false);
        }
        movesView.moves = new IncDecBlock({
            radius: cleverapps.styles.EditorMovesView.radius,
            value: editor.moves,
            range: [1, Infinity],
            onChange: function (value) {
                editor.moves = value;
                editor.save();
            }
        });

        movesView.addChild(movesView.moves);
        this.addChild(movesView);

        this.setPositionRound(cleverapps.styles.EditorMovesView.position);
    }
});

cleverapps.styles.EditorMovesView = {
    radius: 50,

    position: {
        x: { align: "center", dx: -480 },
        y: { align: "top", dy: -110 }
    }
};