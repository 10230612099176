/**
 * Created by ivan on 06.02.18.
 */

var FireworkCell = function (x, y, color) {
    BaseCell.call(this, x, y);

    this.addComponent(ColorComponent, { color: color });
    this.addComponent(SpecialColorComponent);

    this.createType = undefined;
    this.totalDuration = 0;

    this.onOutburstListener = function () {};
    
    this.putInPoolAvailable = true;
};

FireworkCell.prototype = Object.create(BaseCell.prototype);
FireworkCell.prototype.constructor = FireworkCell;

FireworkCell.prototype.editorComponents = function () {
    return [BaseCellComponent, SwitchModeComponent];
};

FireworkCell.prototype.getViewClass = function () {
    return FireworkCellView;
};

FireworkCell.prototype.explode = function (params) {
    if (this.createType === CreateTypes.CREATE_AGAIN_TYPE) {
        Game.currentGame.field.addNextCell(FireworkCell.CODES.concat([CreateTypes.CREATE_AGAIN_TYPE, "."]));
    }
    BaseCell.prototype._explode.call(this, params);
};

FireworkCell.prototype.hurt = function (params) {
    if (this.lives < 1 || !this.alive) {
        return;
    }

    var options = CombosLibrary.helpers.classifyTargets();

    var chosen;

    if (options.simple.length > 0 && options.special.length > 0) {
        chosen = [cleverapps.Random.choose(options.simple), cleverapps.Random.choose(options.special)];
    } else {
        var nonEmpty = options.simple;
        if (nonEmpty.length === 0) {
            nonEmpty = options.special;
        }

        nonEmpty = cleverapps.Random.shuffle(nonEmpty);
        chosen = nonEmpty.slice(0, 2);
    }

    chosen.forEach(function (cell, index) {
        cell.prepareForBurn();
        var flyDuration = this.getFlyDuration(cell);
        var duration = FireworkCell.HURT_DELAY + (index + 1) * FireworkCell.ANIMATION_DURATION + flyDuration;
        this.totalDuration = Math.max(this.totalDuration, duration);
        Game.currentGame.counter.setTimeout(function () {
            cell.hurt({ id: index, groupSize: chosen.length });
            RugTile.hurtViaCombo(this, cell);
        }.bind(this), duration * 1000);
    }.bind(this));
    this.onOutburstListener(chosen);
    this.hurtedCells = chosen.length;

    BaseCell.prototype.hurt.call(this, params);
};

FireworkCell.prototype.getFlyDuration = function (cell) {
    var dist = Math.abs(cell.x - this.x) + Math.abs(cell.y - this.y);
    var duration = dist / FireworkCell.FLY_SPEED;
    duration = Math.max(duration, FireworkCell.MIN_FLY_DURATION);
    duration = Math.min(duration, FireworkCell.MAX_FLY_DURATION);
    return duration;
};

FireworkCell.prototype.load = function (data) {
    this.createType = data[1];

    var color = data[2];
    var colorComponent = this.findComponent(ColorComponent);
    colorComponent.setColor(color === "." ? this.getRandomColor() : color);
};

FireworkCell.prototype.save = function () {
    return FireworkCell.CODES.concat([this.createType, this.findComponent(ColorComponent).color]);
};

FireworkCell.prototype.hurtDuration = function () {
    if (this.hurtedCells === 0) {
        return 0;
    }
    return FireworkCell.HURT_DELAY + this.hurtedCells * FireworkCell.ANIMATION_DURATION;
};

FireworkCell.prototype.explodeDuration = function () {
    return this.totalDuration - this.hurtDuration();
};

FireworkCell.prototype.getGoalId = function () {
    return FireworkCell.GOAL_ID;
};

FireworkCell.prototype.bundleId = function () {
    return "firework_cell";
};

FireworkCell.GOAL_ID = "firework";

FireworkCell.HURT_DELAY = 0.3;
FireworkCell.ANIMATION_DURATION = 0.4;
FireworkCell.FLY_SPEED = 10;
FireworkCell.MIN_FLY_DURATION = 0.8;
FireworkCell.MAX_FLY_DURATION = 2.5;