/**
 * Created by vladislav on 07.09.18.
 */

var ColorBombCell = function (x, y) {
    BaseCell.call(this, x, y);

    this.addComponent(ColorComponent);
    this.addComponent(SpecialColorComponent);
    this.addComponent(MarkComponent);

    this.createType = undefined;
    this.onChangeLivesListener = function () {
    };
    
    this.putInPoolAvailable = true;
};

ColorBombCell.prototype = Object.create(BaseCell.prototype);
ColorBombCell.prototype.constructor = ColorBombCell;

ColorBombCell.prototype.useCustomView = function () {
    return true;
};

ColorBombCell.prototype.bundleId = function () {
    return "color_bomb";
};

ColorBombCell.prototype.getViewClass = function () {
    return ColorBombCellView;
};

ColorBombCell.prototype.editorComponents = function () {
    return [BaseCellComponent, SwitchModeComponent, FireflyJarComponent];
};

ColorBombCell.prototype.boomNeighbors = function () {
};

ColorBombCell.prototype.hurt = function (explodeParams) {
    if (this.lives < 1 || !this.alive) {
        return;
    }

    this.animate(BaseCell.ANIMATION_HURT);
    this.lives--;

    if (this.lives <= 0) {
        this.explode(explodeParams);
    } else {
        Game.currentGame.counter.setTimeout(function () {
            this.onChangeLivesListener();
        }.bind(this), this.hurtDuration() * 1000);
    }
};

ColorBombCell.prototype.hurtDuration = function () {
    return 0.5;
};

ColorBombCell.prototype.explodeDuration = function () {
    return 1.0;
};

ColorBombCell.prototype.getMaxLives = function () {
    return 3;
};

ColorBombCell.prototype.explode = function (params) {
    Game.currentGame.counter.setTimeout(function () {
        var markComponent = this.findComponent(MarkComponent);
        if (markComponent) {
            markComponent.explode();
        }
        if (this.createType === CreateTypes.CREATE_AGAIN_TYPE) {
            Game.currentGame.field.addNextCell(ColorBombCell.CODES.concat([this.maxLives, ".", CreateTypes.CREATE_AGAIN_TYPE]));
        }
        BaseCell.prototype._explode.call(this, params);
    }.bind(this), this.explodeDuration() * 1000);

    Game.currentGame.counter.setTimeout(function () {
        var field = Game.currentGame.field;
        var cellsToHurt = [];
        var rugCellsToHurt = [];
        for (var row = this.y - 1; row <= this.y + 1; row++) {
            for (var col = this.x - 1; col <= this.x + 1; col++) {
                if (row === this.y && col === this.x) {
                    continue;
                }
                if (row >= 0 && row < Field.SIZE && col >= 0 && col < Field.SIZE) {
                    var cell = field.cells[row][col];
                    if (cell && this.isCellAllowed(cell) && field.isCellInField(row, col) && cell.alive && cell.lives !== 0 && cell.hurtable) {
                        cellsToHurt.push(cell);
                    }
                        
                    if (cell) {
                        rugCellsToHurt.push(cell);
                    } else {
                        rugCellsToHurt.push(new BaseCell(col, row));
                    }
                }
            }
        }
        cellsToHurt.forEach(function (cell, index) {
            cell.hurt({ id: index, groupSize: cellsToHurt.length });
        });
        rugCellsToHurt.forEach(function (cell) {
            RugTile.hurtViaCombo(this, cell);
        }.bind(this));
    }.bind(this), 800);
};

ColorBombCell.prototype.isCellAllowed = function (cell) {
    var type = cell.constructor;
    return (type !== MouseCell && type !== DogCell && type !== BlanketCell);
};

ColorBombCell.prototype.load = function (data) {
    this.maxLives = this.lives = parseInt(data[1]);

    var color = data[2];
    var colorComponent = this.findComponent(ColorComponent);
    colorComponent.setColor(color === "." ? this.getRandomColor() : color);

    this.createType = data[3];

    this.findComponent(MarkComponent).load(data);
};

ColorBombCell.prototype.save = function () {
    var colorComponent = this.findComponent(ColorComponent);
    var codes = ColorBombCell.CODES.concat([this.lives, colorComponent.color, this.createType]);

    var markCode = this.findComponent(MarkComponent).save();
    if (markCode) {
        codes.push(markCode);
    }

    return codes;
};