/**
 * Created by andrey on 29.05.17.
 */

var DecoratorsBlock = ElementsChooser.extend({
    ctor: function () {
        this._super({
            elements: EditorView.Decorators,
            type: "decor"
        });
    }
}); 

EditorView.Decorators = [ChainDecoratorCell, DirtDecoratorCell, CrabDecoratorCell, BoxDecoratorCell, BagDecoratorCell, CrabMakerCell, DirtMakerCell, IceBlockDecoratorCell, GumDecoratorCell];

EditorView.RiddlesDecorators = [BlanketCell];
EditorView.HeroesDecorators = [BlanketCell];

if (cleverapps.config.name === "riddles") {
    EditorView.Decorators = EditorView.Decorators.concat(EditorView.RiddlesDecorators);
}

if (cleverapps.config.name === "heroes") {
    EditorView.Decorators = EditorView.Decorators.concat(EditorView.HeroesDecorators);
}

ChainDecoratorCell.EDITOR_CODES = ["-", "-2"];
CrabDecoratorCell.EDITOR_CODES = ["&", "&2"];
BoxDecoratorCell.EDITOR_CODES = ["~", "~2", "~3", "~4"];
BagDecoratorCell.EDITOR_CODES = ["^.", "^a", "^b", "^c", "^d", "^e", "^w"];
IceBlockDecoratorCell.EDITOR_CODES = [":1", ":2"];
DirtMakerCell.EDITOR_CODES = ["q0", "q1", "q2", "q3"];
CrabMakerCell.EDITOR_CODES = ["$0", "$1", "$2"];
GumDecoratorCell.EDITOR_CODES = ["k", "k2", "k3"];