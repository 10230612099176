/**
 * https://doc.gravity-engine.com/turbo-integrated/mini-program/wechat/mp-sdk.html
 * https://doc.gravity-engine.com/turbo-integrated/before_deploy.html
 *
 * Created by andrey on 28.03.2024.
 */

var GravityEngineLogger = function () {
    this.connected = false;

    this.queue = [];

    this.connect();

    cc.eventManager.addCustomListener(cc.game.EVENT_SHOW, this.connect.bind(this));
    cleverapps.timeouts.setInterval(this.connect.bind(this), cleverapps.parseInterval("2 minutes"));
};

GravityEngineLogger.prototype.onUpdateUserId = function () {
    this.connected = false;

    this.connect();
};

GravityEngineLogger.prototype.connect = function () {
    if (connector.platform.withTmpID() || this.connected || this.connecting) {
        return;
    }

    this.connecting = true;

    var config = {
        accessToken: cleverapps.config.wechat.gravityEngine.accessToken,
        clientId: connector.platform.getUserID(),
        autoTrack: {
            appLaunch: true,
            appShow: true,
            appHide: true
        },
        name: "ge",
        sendTimeout: 20000,
        debugMode: cleverapps.config.debugMode && "debug"
    };

    this.gravityEngine = new GravityEngineAPI(config);
    this.gravityEngine.setupAndStart();

    this.gravityEngine.initialize({
        name: connector.platform.getUserID(),
        version: GravityEngineLogger.VERSION,
        openid: connector.platform.getUserID()
    })
        .then(function (res) {
            this.connecting = false;
            this.connected = true;

            console.log("GravityEngineLogger.connect success " + JSON.stringify(res));

            if (this.connectionError) {
                cleverapps.eventLogger.logEvent(cleverapps.EVENTS.DEBUG.GRAVITY_RECONNECT);
            }

            this.gravityEngine.registerEvent();

            for (var i = 0; i < this.queue.length; i++) {
                this.send(this.queue[i].method, this.queue[i].properties);
            }
            this.queue = [];
        }.bind(this))
        .catch(function (err) {
            this.connecting = false;

            if (!this.connectionError) {
                this.connectionError = true;

                cleverapps.eventLogger.logEvent(cleverapps.EVENTS.DEBUG.GRAVITY_INIT_ERROR);
            }

            console.log("GravityEngine.initialize failed " + JSON.stringify(err));

            if (cleverapps.config.debugMode) {
                cleverapps.notification.create("GravityEngine.initialize failed" + JSON.stringify(err));
            }
        }.bind(this));
};

GravityEngineLogger.prototype.logPurchase = function (product, purchase) {
    this.send("payEvent", { product: product, purchase: purchase });
};

GravityEngineLogger.prototype.logAdRevenue = function (name) {
    this.send("adShowEvent", { name: name });
};

GravityEngineLogger.prototype.send = function (method, properties) {
    if (!this.connected) {
        this.queue.push({ method: method, properties: properties });
        if (this.queue.length > GravityEngineLogger.QUEUE_LIMIT) {
            this.queue.shift();
        }
        return;
    }

    switch (method) {
        case "payEvent":
            var product = properties.product || {};
            var purchase = properties.purchase || {};

            console.log("GravityEngine.payEvent", product.price * 100, "CNY", purchase.paymentId, "" + product.itemId);
            this.gravityEngine.payEvent(product.price * 100, "CNY", purchase.paymentId, "" + product.itemId, "WeChat");
            break;
        case "adShowEvent":
            console.log("GravityEngine.adShowEvent", properties.name, cleverapps.config.wechat.rewarded);
            this.gravityEngine.adShowEvent(properties.name, cleverapps.config.wechat.rewarded);
            break;
    }
};

GravityEngineLogger.isApplicable = function () {
    return connector.platform.oneOf(connector.WECHAT) && cleverapps.config.wechat && cleverapps.config.wechat.gravityEngine;
};

GravityEngineLogger.QUEUE_LIMIT = 100;

GravityEngineLogger.VERSION = 475;