/**
 * Created by Ivan on 16.04.2024
 */

var StickersBook = function () {
    cleverapps.EventEmitter.call(this);

    this.load();

    if (this.isAvailable()) {
        cleverapps.playSession.set(cleverapps.EVENTS.STICKERS_COLLECTIONS, true);
    }
};

StickersBook.prototype = Object.create(cleverapps.EventEmitter.prototype);
StickersBook.prototype.constructor = StickersCollection;

StickersBook.prototype.load = function (stored) {
    var save = stored || cleverapps.dataLoader.load(DataLoader.TYPES.STICKERS_BOOK_INFO) || {};
    var saveAttention = cleverapps.dataLoader.load(DataLoader.TYPES.STICKERS_BOOK_ATTENTION) || [];

    this.createCollections(save, saveAttention);

    this.points = save.points || 0;
    this.jokers = save.jokers || 0;
};

StickersBook.prototype.createCollections = function (save, saveAttention) {
    this.collections = [];

    this.collectionsAmount = StickersBook.calcCollectionsAmount();

    var info = save.collections || [];

    for (var i = 0; i < this.collectionsAmount; i++) {
        var collectionInfo = this.binaryToArray(info[i] || 0, StickersCollection.STICKERS_IN_COLLECTION + 1);
        var isPrizeCollected = Boolean(collectionInfo.pop());
        var collection = new StickersCollection({
            stickers: collectionInfo,
            isPrizeCollected: isPrizeCollected,
            index: i,
            attention: this.binaryToArray(i < saveAttention.length ? saveAttention[i] : 0, StickersCollection.STICKERS_IN_COLLECTION)
        });

        collection.on("update", function () {
            this.trigger("update");
        }.bind(this));

        this.collections.push(collection);
    }
};

StickersBook.prototype.updateInfo = function (serverData) {
    if (!serverData) {
        return;
    }

    this.load(serverData);
    this.save(true);
};

StickersBook.prototype.updateAttention = function (data) {
    this.collections.forEach(function (collection, index) {
        var info = this.binaryToArray(index < data.length ? data[index] : 0, StickersCollection.STICKERS_IN_COLLECTION);
        collection.updateAttention(info);
    }.bind(this));
};

StickersBook.prototype.getAvailableCollections = function () {
    var availableCollections = [];
    for (var i = 0; i < this.collections.length; i++) {
        if (this.collections[i].isAvailable()) {
            availableCollections.push(this.collections[i]);
        } else {
            break;
        }
    }

    return availableCollections;
};

StickersBook.prototype.getInfo = function () {
    var collectionsInfo = this.getAvailableCollections().map(function (collection) {
        return collection.getInfo();
    });

    return {
        collections: collectionsInfo.map(function (info) {
            info.stickers.push(info.isPrizeCollected ? 1 : 0);
            return this.arrayToBinary(info.stickers);
        }.bind(this)),
        points: this.points,
        jokers: this.jokers
    };
};

StickersBook.prototype.getAttentionInfo = function () {
    return this.collections.map(function (collection) {
        return this.arrayToBinary(collection.getAttentionInfo());
    }.bind(this));
};

StickersBook.prototype.arrayToBinary = function (array) {
    return array.reduce(function (acc, curr) {
        return (acc << 1) | curr;
    }, 0);
};

StickersBook.prototype.binaryToArray = function (binary, length) {
    var array = [];
    for (var i = 0; i < length; i++) {
        array.unshift(binary & 1);
        binary >>= 1;
    }
    return array;
};

StickersBook.prototype.givePrize = function (amount, sourceEvent) {
    var regular = [];
    var rare = [];
    var stickers = [];
    this.getAvailableCollections().forEach(function (collection) {
        collection.stickers.forEach(function (sticker) {
            if (sticker.rarity === Sticker.RARITY_RARE) {
                rare.push(sticker);
            } else {
                regular.push(sticker);
            }
        });
    });

    var disableRepeats = regular.length + rare.length >= amount;

    for (var i = 0; i < amount; i++) {
        var luck = Math.random();
        var sticker;
        if (luck <= 0.1) {
            sticker = cleverapps.Random.choose(rare);
            disableRepeats && rare.splice(rare.indexOf(sticker), 1);
        } else {
            sticker = cleverapps.Random.choose(regular);
            disableRepeats && regular.splice(regular.indexOf(sticker), 1);
        }
        if (sticker.getState() === Sticker.STATE_OPENED) {
            this.incPoints(sticker.rarity === Sticker.RARITY_RARE ? StickersCollection.RARE_POINTS : StickersCollection.REGULAR_POINTS);
            sticker.duplicate = true;
        } else {
            sticker.open(sourceEvent);
        }
        stickers.push(sticker);
    }

    return stickers.sort(function (a, b) {
        return b.isRare() - a.isRare();
    });
};

StickersBook.prototype.save = function (fromServer) {
    cleverapps.dataLoader.save(DataLoader.TYPES.STICKERS_BOOK_INFO, this.getInfo());
    if (!fromServer) {
        cleverapps.synchronizer.addUpdateTask("stickersbook");
    }

    cleverapps.dataLoader.save(DataLoader.TYPES.STICKERS_BOOK_ATTENTION, this.getAttentionInfo());
};

StickersBook.prototype.getOpenedStickersAmount = function () {
    var amount = 0;
    this.getAvailableCollections().forEach(function (collection) {
        amount += collection.getOpenedStickersAmount();
    });
    return amount;
};

StickersBook.prototype.getCompletedCollectionsAmount = function () {
    return this.collections.filter(function (collection) {
        return collection.isCompleted;
    }).length;
};

StickersBook.prototype.isCompleted = function () {
    return this.collections.filter(function (collection) {
        return collection.isCollected();
    }).length === this.collectionsAmount;
};

StickersBook.prototype.exchangePointsToJokers = function () {
    var jokers = Math.floor(this.points / StickersCollection.GOAL_POINTS);
    this.points -= jokers * StickersCollection.GOAL_POINTS;
    this.trigger("updatePoints");

    this.addJokers(jokers);
    return jokers;
};

StickersBook.prototype.addJokers = function (amount) {
    amount = amount || 1;
    this.jokers += amount;
    this.trigger("updateJokersAmount", this.jokers);
    this.save();
};

StickersBook.prototype.isFullPoints = function () {
    return this.points >= StickersCollection.GOAL_POINTS;
};

StickersBook.prototype.isAvailable = function () {
    return levels.user.checkAvailable(cleverapps.Availables.STICKERS_BOOK) && cleverapps.ABTest.STICKERS_BOOK();
};

StickersBook.prototype.incPoints = function (amount) {
    this.points += amount;
    this.trigger("updatePoints");
    this.save();
};

StickersBook.prototype.useJoker = function () {
    this.jokers--;
    this.trigger("updateJokersAmount", this.jokers);
    this.save();
};

StickersBook.prototype.hasAttention = function () {
    return this.collections.filter(function (collection) {
        return collection.hasAttention();
    }).length;
};

StickersBook.isPuzzle = function () {
    return ["wooden"].includes(cleverapps.config.ui);
};

StickersBook.isStickersShopAvailable = function () {
    return cleverapps.config.debugMode || (["tripeaks", "zenmatch", "heroes"].indexOf(cleverapps.config.name) > -1);
};

StickersBook.getCollectionReward = function (collectionIndex) {
    return RewardsConfig.StickersBook[collectionIndex % RewardsConfig.StickersBook.length];
};

StickersBook.calcCollectionsAmount = function () {
    var collectionsAmount = 0;
    while (bundles["collection_" + collectionsAmount]) {
        collectionsAmount++;
    }

    return collectionsAmount;
};

StickersBook.prototype.getLevelReward = function () {
    return Game.currentGame.getNormalizedLevelReward(RewardsConfig.StickersBookLevelRewards, "match3");
};

StickersBook.PRODUCTS = ["stickers2", "stickers1", "stickers0"];

cleverapps.InitByFeature["stickers_book"] = function () {
    cleverapps.stickersBook = new StickersBook();
};