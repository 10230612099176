/**
 * Created by slava on 3/6/19
 */

CellHintAlgorithm = function (field) {
    HintAlgorithm.call(this, field);

    this.ideas = [this.removeBombIdea, this.moveToBottomIdea, this.collectMarkIdea, this.completeGoalIdea];
};

CellHintAlgorithm.prototype = Object.create(HintAlgorithm.prototype);
CellHintAlgorithm.constructor = CellHintAlgorithm;

CellHintAlgorithm.prototype.getId = function () {
    return cleverapps.Boosters.TYPE_CELL;
};

CellHintAlgorithm.prototype.getResult = function () {
    this.totalMarks = 0;

    for (var row = 0; row < Field.SIZE; row++) {
        for (var col = 0; col < Field.SIZE; col++) {
            var markComponent = this.field.cells[row][col] && this.field.cells[row][col].findComponent(MarkComponent);
            if (markComponent && markComponent.mark) {
                this.totalMarks++;
            }
        }
    }

    return HintAlgorithm.prototype.getResult.call(this);
};

CellHintAlgorithm.prototype.removeBombIdea = function (cell) {
    if (cell.constructor.name !== "BombCell") {
        return;
    }

    var untilExplode = cell.untilExplode;

    if (untilExplode <= 3 && untilExplode > 0) {
        return true;
    }
};

CellHintAlgorithm.prototype.completeGoalIdea = function (cell) {
    if (!this.isActiveGoal(cell) || this.countSameGoals(cell) > 3) {
        return;
    }

    if (cell.constructor.name === "CandleCell") {
        return cell.state === CandleCell.STATE_OFF && this.countSameGoals(cell) === 1;
    }

    return cell.hurtable && cell.lives === 1;
};

CellHintAlgorithm.prototype.collectMarkIdea = function (cell) {
    var markComponent = cell.findComponent(MarkComponent);
    if (!markComponent || !markComponent.mark) {
        return;
    }

    return this.totalMarks <= 2;
};

CellHintAlgorithm.prototype.moveToBottomIdea = function (cell) {
    var upperCell = cell.y > 0 && this.field.cells[cell.y - 1][cell.x];
    if (!upperCell || !this.isBottomRowElement(upperCell)) {
        return;
    }

    var bottomCell = undefined;
    for (var i = cell.y; i < Field.SIZE; i++) {
        if (this.field.cells[i][cell.x]) {
            if (bottomCell) {
                return false;
            }

            bottomCell = this.field.cells[i][cell.x];
        }
    }

    if (!bottomCell || !this.isRemovable(bottomCell)) {
        return;
    }

    return true;
};