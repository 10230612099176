/**
 * Created by Aleksey Ovsyanitskiy on 04.10.2023
 */

var TextBgPropertyEditor = function (property, initialValue, onChangeValueCallback) {
    this.property = property;
    this.onChangeValueCallback = onChangeValueCallback;
    var resolution = cleverapps.wysiwyg.wysiwygPreview.selectedResolution;

    var defaultValue = {
        image: {
            name: "",
            folder: ""
        },
        margins: {}
    };
    var defaultPadsValues = {
        general: {
            marginTop: 0,
            marginBottom: 0,
            marginLeft: 0,
            marginRight: 0
        }
    };
    defaultPadsValues[resolution] = {};

    if (initialValue) {
        this.value = cleverapps.override(defaultValue, initialValue);
        this.value.margins = cleverapps.override(defaultPadsValues, initialValue.margins);
    } else {
        this.value = defaultValue;
        this.value.margins = defaultPadsValues;
    }

    var scale9Names = cleverapps.wysiwyg.googleDriveProvider.getAssetsInFolders(this.property.folders);
    scale9Names[Wysiwyg.NOT_SELECTED] = true;

    this.propertyEditors = [{
        name: property.name,
        fullOnly: true,
        isBlock: true
    }, {
        name: "Scale9",
        items: Object.keys(scale9Names),
        direction: cleverapps.UI.VERTICAL,
        getter: function () {
            return this.value.image ? this.value.image.name : "";
        }.bind(this),
        setter: function (value) {
            if (value === Wysiwyg.NOT_SELECTED) {
                this.value.image = undefined;
            } else {
                this.value.image = {
                    name: value,
                    folder: scale9Names[value]
                };
            }
        }.bind(this)
    }, {
        name: "Margins",
        fullOnly: true,
        isBlock: true
    }, {
        name: "Top",
        allowOverride: true,
        getters: [function () {
            return this.value.margins.general.marginTop;
        }.bind(this), function () {
            return this.value.margins[resolution].marginTop;
        }.bind(this)],
        setters: [function (value) {
            this.value.margins.general.marginTop = value;
        }.bind(this), function (value, editBox) {
            if ((value || value === 0) && value !== this.value.margins.general.marginTop) {
                this.value.margins[resolution].marginTop = value;
            } else {
                editBox.setString("");
                delete this.value.margins[resolution].marginTop;
            }
        }.bind(this)]
    }, {
        name: "Bottom",
        allowOverride: true,
        getters: [function () {
            return this.value.margins.general.marginBottom;
        }.bind(this), function () {
            return this.value.margins[resolution].marginBottom;
        }.bind(this)],
        setters: [function (value) {
            this.value.margins.general.marginBottom = value;
        }.bind(this), function (value, editBox) {
            if ((value || value === 0) && value !== this.value.margins.general.marginBottom) {
                this.value.margins[resolution].marginBottom = value;
            } else {
                editBox.setString("");
                delete this.value.margins[resolution].marginBottom;
            }
        }.bind(this)]
    }, {
        name: "Left",
        allowOverride: true,
        getters: [function () {
            return this.value.margins.general.marginLeft;
        }.bind(this), function () {
            return this.value.margins[resolution].marginLeft;
        }.bind(this)],
        setters: [function (value) {
            this.value.margins.general.marginLeft = value;
        }.bind(this), function (value, editBox) {
            if ((value || value === 0) && value !== this.value.margins.general.marginLeft) {
                this.value.margins[resolution].marginLeft = value;
            } else {
                editBox.setString("");
                delete this.value.margins[resolution].marginLeft;
            }
        }.bind(this)]
    }, {
        name: "Right",
        allowOverride: true,
        getters: [function () {
            return this.value.margins.general.marginRight;
        }.bind(this), function () {
            return this.value.margins[resolution].marginRight;
        }.bind(this)],
        setters: [function (value) {
            this.value.margins.general.marginRight = value;
        }.bind(this), function (value, editBox) {
            if ((value || value === 0) && value !== this.value.margins.general.marginRight) {
                this.value.margins[resolution].marginRight = value;
            } else {
                editBox.setString("");
                delete this.value.margins[resolution].marginRight;
            }
        }.bind(this)]
    }];

    this.propertyEditors = this.propertyEditors.map(function (propertyDescription) {
        return new PropertyEditor(propertyDescription, this);
    }.bind(this));
};

TextBgPropertyEditor.prototype.editBoxEditingDidEnd = function (sender) {
    var value = sender.getString();
    if (sender instanceof cc.EditBox) {
        value = parseFloat(value);
    }
    sender.customSetter(value, sender);
    this.onChangeValueCallback(this.property.name, this.value);
    this.propertyEditors.forEach(function (propertyEditor) {
        propertyEditor.updateOverrides();
    });
};