/**
 * Created by Andrey Popov on 21.01.2021.
 */

var AssetsView = WysiwygWindowBase.extend({
    ctor: function (assets, options) {
        this._super(Object.values(Assets.MODES), options.width, cleverapps.styles.AssetsView.height);

        this.assets = assets;
        this.showAssets();

        this.setAnchorPoint(0, 0);
        this.setPositionRound(0, 0);
        this.setLocalZOrder(3);

        this.assets.on("showAssets", this.showAssets.bind(this), this);
    },

    showAssets: function () {
        if (this.scroll) {
            this.scroll.removeFromParent();
        }

        var styles = cleverapps.styles.AssetsView;

        var assetViews = this.assets.selectedTab === Assets.MODES.COMPONENTS ? this.assets.components.map(function (asset) {
            return new AssetComponentView(asset);
        }) : this.assets.scripts.map(function (script) {
            return new ScriptComponentView(script);
        });
        var layout = new cleverapps.Layout(assetViews, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.components.margin
        });

        var scroll = this.scroll = new cleverapps.UI.ScrollView(undefined, {
            direction: cleverapps.UI.ScrollView.DIR_HORIZONTAL,
            childrenVisibility: cleverapps.UI.ScrollView.CHILDREN_VISIBILITY_LINEAR
        });

        scroll.setInnerContent(layout);
        scroll.setContentSize2(this.getContentSize().width - styles.offset, this.getContentSize().height - cleverapps.styles.WysiwygWindowBase.header.height);
        scroll.setAnchorPoint(0, 0);
        scroll.setBarPadding(styles.scroll);
        scroll.setPositionRound(styles.components);

        this.addChild(scroll);

        scroll.scrollToDefault();
    },

    onTabClick: function (tab) {
        this._super(tab);
        this.assets.onTabChanged(tab);
    }
});

cleverapps.styles.AssetsView = {
    height: 170,
    offset: 20,

    components: {
        margin: 10,
        x: { align: "left", dx: 10 },
        y: { align: "bottom", dy: 10 }
    },

    scroll: {
        cornerPadding: 10,
        sidePadding: 10
    }
};