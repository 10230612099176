/**
 * Created by slava on 02.02.17.
 */

var TulpanCell = function (x, y) {
    BaseCell.call(this, x, y);
    this.movable = true;
    this.putInPoolAvailable = true;
};

TulpanCell.prototype = Object.create(BaseCell.prototype);
TulpanCell.prototype.constructor = TulpanCell;

TulpanCell.prototype.getViewClass = function () {
    return TulpanCellView;
};

TulpanCell.prototype.save = function () {
    return TulpanCell.CODES;
};

TulpanCell.prototype.load = function () {
};

TulpanCell.prototype.boom = function () {
    this.hurt();
};

TulpanCell.isNeedToAdd = function (moves, levelMoves, missionType, cellClass, nextCell) {
    if (!missionType) {
        missionType = Mission.TYPE_BURN_NEARBY;
    }
    if (!cellClass) {
        cellClass = TulpanCell;
    }
    if (!nextCell) {
        nextCell = TulpanCell.CODES[0];
    }

    if (Game.currentGame && levelMoves - moves > 2) {
        if (Game.currentGame.getMissionType() === missionType) {
            var amount = 0, i, j;

            for (i = 0; i < Field.SIZE; i++) {
                for (j = 0; j < Field.SIZE; j++) {
                    var cell = Game.currentGame.field.cells[i][j];
                    if (cell && cell.constructor === cellClass) {
                        amount++;
                    }
                }
            }

            for (i = 0; i < Game.currentGame.field.nextCells.length; i++) {
                if (Game.currentGame.field.nextCells[i] === nextCell) {
                    amount++;
                }
            }

            return Math.random() < 0.3 - 0.1 * amount;
        }
    }
    return false;
};