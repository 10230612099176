/**
 * Created by vladislav on 02.06.2022
 */

var ClansTool = {
    fakePlayersAmount: 50,

    addFakePlayers: function () {
        var amount = parseInt(window.prompt("Enter amount", 10));

        if (isNaN(amount)) {
            return;
        }

        var lastFakeIndex = -1;

        cleverapps.userClan.players.forEach(function (player) {
            if (this.isFake(player.id)) {
                var index = parseInt(player.id.replaceAll("_", "")) - 1000;

                if (index > lastFakeIndex) {
                    lastFakeIndex = index;
                }
            }
        }, this);

        amount = Math.min(this.fakePlayersAmount - lastFakeIndex - 1, amount);

        var callback = cleverapps.wait(amount, function () {
            this.closeAllWindows();

            new MyClanWindow();
        }.bind(this));
        for (var i = 0; i < amount; i++) {
            cleverapps.clans.addUserToClan("__" + (1000 + lastFakeIndex + 1 + i), cleverapps.userClan.id, function (data) {
                WaitWindow.hide();

                if (data.error) {
                    cleverapps.notification.create(data.error);
                } else {
                    cleverapps.userClan.update(data);

                    callback();
                }
            }, function (err) {
                WaitWindow.hide();
                new NoConnectionWindow();
                console.log(err);
            });
        }
    },

    closeAllWindows: function () {
        cleverapps.windows.closeBottomWindows();
        if (cleverapps.windows.currentWindow()) {
            cleverapps.windows.currentWindow().close();
        }
    },

    isFake: function (id) {
        return id.match(/^__10[0-9]{2}$/);
    },

    listFakePlayer: function () {
        return cleverapps.userClan.players.filter(function (player) {
            return this.isFake(player.id);
        }, this);
    },

    sendMessage: function () {
        if (cleverapps.userClan.id === undefined) {
            return {};
        }

        var res = {};

        this.listFakePlayer().forEach(function (player) {
            res[player.name || player.id] = function () {
                var messageIds = Object.keys(ClansConfig.MESSAGES).filter(function (id) {
                    return +id !== ClansConfig.MESSAGE_TYPE_ENERGY;
                });

                cleverapps.clanMessages.postMessage({
                    type: cleverapps.Random.mathChoose(messageIds),
                    playerId: player.id,
                    playerName: player.name
                });

                this.closeAllWindows();
                new MyClanWindow();
            }.bind(this);
        }.bind(this));

        return res;
    },

    sendRequest: function () {
        if (cleverapps.userClan.id === undefined) {
            return {};
        }

        var res = {};

        this.listFakePlayer().forEach(function (player) {
            res[player.name || player.id] = function () {
                cleverapps.clanMessages.postMessage({
                    type: ClansConfig.MESSAGE_TYPE_ENERGY,
                    playerId: player.id,
                    playerName: player.name
                });

                this.closeAllWindows();
                new MyClanWindow();
            }.bind(this);
        }.bind(this));

        return res;
    },

    fillHelp: function () {
        var allPlayers = cleverapps.userClan.players.filter(function (player) {
            return player.id !== connector.platform.getUserID(); 
        });

        for (var i = cleverapps.clanMessages.messages.length - 1; i >= 0; --i) {
            var message = cleverapps.clanMessages.messages[i];
            if (ClansConfig.MESSAGES[message.type].help) {
                var messageHelp = message.listHelp();
                var players = allPlayers.filter(function (player) {
                    return messageHelp.every(function (help) {
                        return help.senderId !== player.id;
                    });
                });
                var amount = ClansConfig.MESSAGES[message.type].help.times - messageHelp.length;

                if (players.length && amount > 0) {
                    for (var j = 0; j < players.length && j < amount; ++j) {
                        cleverapps.RestClient.post("/clans/message/sendhelp", {
                            messageId: message.messageId,
                            userId: players[j].id,
                            version: cleverapps.config.version
                        });
                    }
                    break;
                }
            }
        }
    }
};
