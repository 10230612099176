/**
 * Created by slava on 8/8/18
 */

cleverapps.TableView = cc.Node.extend({
    setupRowPosition: function (row, place, callback) {
        var styles = cleverapps.styles.TableView;

        var cb = function () {
            if (row.view.currentPlace === row.data.place && row.data.place > this.amountRows) {
                row.view.removeFromParent();
                delete this.rows[row.data.id];
            } else {
                this.setRowVisibility(row.view);
            }
            if (callback) {
                callback();
            }
        }.bind(this);

        place = this.amountRows - place;

        var pos = cc.p(this.innerPadding, this.innerPadding + (styles.margin + row.view.height) * place + row.view.height / 2);
        row.view.setLocalZOrder(place);
        if (!callback || !this.isVisiblePosition(row.view.y, row.view.height) && !this.isVisiblePosition(pos.y, row.view.height)) {
            row.view.setPositionRound(pos);
            cb();
        } else {
            row.view.runAction(new cc.Sequence(
                new cc.MoveTo(0.2, pos),
                new cc.CallFunc(cb)
            ));
        }

        return pos;
    },

    isVisiblePosition: function (position, height) {
        position -= this.container.height / 2;
        return -this.container.y - this.scroll.height / 2 - height / 2 <= position && position <= -this.container.y + this.scroll.height / 2 + height / 2;
    },

    finishAnimation: function () {
        this.inSwapAnimation--;
        this.swapIfNeed();
    },

    startAnimation: function () {
        this.inSwapAnimation += 1;
    },

    swapPairIfNeed: function (row1, row2) {
        var place1 = row1.view.currentPlace;
        var place2 = row2.view.currentPlace;

        this.startAnimation();
        this.startAnimation();

        row1.view.updatePlace(place2);
        row2.view.updatePlace(place1);

        var row1Position = this.setupRowPosition(row1, place2, this.finishAnimation.bind(this));
        var row2Position = this.setupRowPosition(row2, place1, this.finishAnimation.bind(this));

        var scrollTo;
        if (row1.data.player) {
            scrollTo = row1Position;
        } else if (row2.data.player) {
            scrollTo = row2Position;
        }

        if (scrollTo) {
            this.scroll.scrollTo(scrollTo, 0.2, {
                easing: cc.easeInOut(1)
            });
        }
    },

    swapIfNeed: function () {
        if (this.inSwapAnimation !== 0) {
            return;
        }

        var best = this.amountRows + 2, bestRow;

        Object.values(this.rows).forEach(function (row) {
            if (row.view.currentPlace > row.data.place && row.data.place < best) {
                best = row.data.place;
                bestRow = row;
            }
        });

        if (bestRow) {
            var rowWithPlace = this.findRowWithPlace(bestRow.view.currentPlace - 1);
            if (rowWithPlace) {
                this.swapPairIfNeed(rowWithPlace, bestRow);
            }
        }

        Object.values(this.rows).forEach(function (row) {
            row.view.updateAmount(row.data.amount);
        });
    },

    setRowVisibility: function (rowView) {
        rowView.setVisible(this.isVisiblePosition(rowView.y, rowView.height));
    },

    setRowsVisibility: function () {
        Object.values(this.rows).forEach(function (row) {
            this.setRowVisibility(row.view);
        }.bind(this));
    },

    cloneResults: function (results) {
        return results.map(function (result) {
            return cleverapps.clone(result);
        });
    },

    updateResults: function (newResults) {
        if (this.amountRows !== newResults.length) {
            return false;
        }

        var data = this.cloneResults(newResults);
        var notInTablePlace = this.amountRows + 1;

        Object.values(this.rows).forEach(function (row) {
            row.data.place = notInTablePlace;
        });

        for (var i = 0; i < data.length; i++) {
            var rowData = data[i];
            var row = this.rows[rowData.id];

            if (row) {
                row.data = rowData;
                row.view.updateParams(rowData);
            } else {
                if (this.dataIcon) {
                    rowData.dataIcon = this.dataIcon;
                }

                var viewData = cleverapps.clone(rowData);
                viewData.place = notInTablePlace;

                row = this.rows[rowData.id] = {
                    view: new this.RowConstructor(viewData, this.rowOptions),
                    data: rowData
                };
                row.view.setAnchorPoint(0, 0.5);
                this.container.addChild(row.view);
                this.setupRowPosition(row, viewData.place);
            }
        }

        this.swapIfNeed();

        return true;
    },

    findRowWithPlace: function (place) {
        return Object.values(this.rows).find(function (row) {
            return row.view.currentPlace === place;
        });
    },

    findPlayerRow: function () {
        return Object.values(this.rows).find(function (row) {
            return row.data.player;
        });
    },
    
    ctor: function (data, RowConstructor, dataIcon, options) {
        this._super();
        
        this.setAnchorPoint(0.5, 0.5);

        var styles = cleverapps.styles.TableView;

        this.options = options || {};
        this.rows = {};
        this.amountRows = 0;
        this.inSwapAnimation = 0;

        this.startAnimation();

        data = this.cloneResults(data);

        this.RowConstructor = RowConstructor || cleverapps.Row;
        this.dataIcon = dataIcon;
        this.rowOptions = this.options.rowOptions;

        this.innerPadding = this.options.innerPadding || styles.innerPadding;

        var container = this.container = new cc.Node();
        container.setAnchorPoint(0.5, 0.5);

        var width = styles.width;
        var height = 0;

        for (var i = 0; i < data.length; i++) {
            var rowData = data[i];

            if (this.dataIcon) {
                rowData.dataIcon = this.dataIcon;
            }
            rowData.clickableArea = this;

            var rowView = new this.RowConstructor(rowData, this.rowOptions);
            rowView.setAnchorPoint(0, 0.5);
            container.addChild(rowView);

            width = rowView.width;
            height += rowView.height;

            if (i > 0) {
                height += styles.margin;
            }

            this.rows[rowData.id] = {
                view: rowView,
                data: rowData
            };
            this.amountRows++;
        }

        container.setContentSize2(width + 2 * this.innerPadding, height + 2 * this.innerPadding);

        this.scroll = new cleverapps.UI.ScrollView(container, {
            childrenVisibility: cleverapps.UI.ScrollView.CHILDREN_VISIBILITY_NONE,
            containerMovedListener: this.createListener(this.setRowsVisibility.bind(this))
        });
        this.scroll.setBarPadding(styles.barPadding);
        this.scroll.setAnchorPoint(0, 0);
        this.addChild(this.scroll);

        if (options.bg !== false) {
            this.bg = new cc.Scale9Sprite(bundles.table.frames.table_bg_png);
            this.bg.setLocalZOrder(-1);
            this.addChild(this.bg);
        }

        Object.values(this.rows).forEach(function (row) {
            this.setupRowPosition(row, row.data.place);
        }.bind(this));

        this.updateSize();
        this.setRowsVisibility();

        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.7),
            new cc.CallFunc(this.finishAnimation.bind(this))
        ));
    },

    updateSize: function () {
        var styles = cleverapps.styles.TableView;
        var height = this.options.height && this.options.height[cleverapps.resolution.mode] && this.options.height[cleverapps.resolution.mode].height || this.options.height || styles.height;

        this.setContentSize2(this.container.width + 2 * styles.padding, height + 2 * styles.padding);

        var scrollHeight = Math.min(height, this.container.height + 1);

        this.scroll.setContentSize2(this.container.width, scrollHeight);
        this.scroll.setPosition(styles.padding, styles.padding + height - scrollHeight);

        if (this.bg) {
            this.bg.setContentSize2(this.width, this.height);
            this.bg.setPositionRound(this.width / 2, this.height / 2);
        }

        if (this.options.scroll !== undefined) {
            this.scroll.scrollTo(this.options.scroll);
        } else if (this.container.height > scrollHeight) {
            var row = this.findPlayerRow();
            if (row) {
                var target = this.scroll.targetToPoint(row.view);
                if (isNaN(this.container.x) || isNaN(target.x) || isNaN(target.y)) {
                    var msg = row.view.x + "_" + row.view.y;
                    msg += " s " + this.scroll.width + "_" + this.scroll.height + " ic " + this.container.width + "_" + this.container.height;
                    msg += " tp " + target.x + "_" + target.y + " m " + this.scroll.min.x + "_" + this.scroll.min.y + " " + this.scroll.max.x + "_" + this.scroll.max.y;
                    msg += " pl " + row.data.place + " cpl " + row.view.currentPlace + " tpl " + this.amountRows;

                    try {
                        msg += " " + JSON.stringify({
                            innerContentSize: this.scroll.innerContent && cc.p(this.scroll.innerContent.width, this.scroll.innerContent.height),
                            rowViewType: typeof row.view,
                            rowViewPosition: row.view && row.view.getPosition(),
                            rowViewWorldPos: row.view && row.view.getParent() && row.view.getParent().convertToWorldSpace(row.view.getPosition()),
                            innerContentPosition: this.scroll.innerContent && this.scroll.innerContent.getPosition(),
                            targetNodePosition: this.scroll.innerContent && row.view && row.view.getParent() 
                                && this.scroll.innerContent.convertToNodeSpace(row.view.getParent().convertToWorldSpace(row.view.getPosition()))
                        });
                    } catch (e) {
                        console.error(e);
                    }

                    try {
                        msg += " " + JSON.stringify({
                            innerContentWorldPosition: this.scroll.innerContent && this.scroll.innerContent.getParent()
                                && this.scroll.innerContent.getParent() && this.scroll.innerContent.getParent().convertToWorldSpace(this.scroll.innerContent.getPosition())
                        });
                    } catch (e) {
                        console.error(e);
                    }

                    cleverapps.throwAsync(msg);
                } else {
                    this.scroll.scrollTo(row.view);
                }
            }
        }
    }
});

cleverapps.styles.TableView = {
    width: 500,
    height: 732,

    margin: 4,
    padding: 6,
    innerPadding: 6,
    barPadding: {
        cornerPadding: 20,
        sidePadding: 5
    }
};