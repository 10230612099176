/**
 * Created by andrey on 16.07.18.
 */

var BagDecoratorCell = function (x, y, options) {
    BaseCell.call(this, x, y, options);

    this.hideInnerCell = true;
};

BagDecoratorCell.prototype = Object.create(BaseCell.prototype);
BagDecoratorCell.prototype.constructor = BagDecoratorCell;
Skinify(BagDecoratorCell, "bag");

BagDecoratorCell.prototype.getViewClass = function () {
    return BagDecoratorCellView;
};

BagDecoratorCell.prototype.smile = function () {
    if (this.alive) {
        this.animate(BaseCell.ANIMATION_SMILE);
    }
};

BagDecoratorCell.prototype.load = function (data, loader) {
    data.shift();
    this.color = data[0];
    data.shift();
    if (this.color === ".") {
        this.color = BaseCell.prototype.getRandomColor();
    }
    
    this.setInnerCell(loader.loadNext(this.y, this.x, data));
};

BagDecoratorCell.prototype.save = function () {
    var code = BagDecoratorCell.CODES.slice();
    code.push(this.color);
    return code.concat(this.innerCell.save());
};

BagDecoratorCell.prototype.boom = function (coef, cell) {
    var decoratorComponent = this.findComponent(DecoratorComponent);
    var colorComponent = cell && cell.findComponent(ColorComponent);
    if (colorComponent && colorComponent.color === this.color) {
        decoratorComponent.boomHurt();
    }
};

BagDecoratorCell.prototype.explodeDuration = function () {
    return 0; // inner cell must be visible immediately
};

BagDecoratorCell.prototype.getGoalId = function () {
    return BagDecoratorCell.GOAL_ID;
};

BagDecoratorCell.GOAL_ID = "bag_decorator";
