/**
 * Created by vladislav on 3/15/19
 */

var IceBlockMakerTileView = cc.Node.extend(Object.assign({}, BaseTileView, {
    ctor: function (tile) {
        this._super(tile);

        this.setAnchorPoint(0.5, 0.5);

        this.initialize(tile);

        var animation = this.animation = new cleverapps.Spine(bundles.ice_block_maker.jsons.ice_block_maker_json);
        this.addChild(animation);
        animation.setPositionRound(this.width / 2, this.height / 2);
        animation.setAnimation(0, "idle_2", true);

        tile.onStateChanged = this.changeState.bind(this);
        tile.onCellFreezed = this.onCellFreezed.bind(this);
    },

    changeState: function (state) {
        var idleName = state ? "idle_1" : "idle_2";
        var transitionName = state ? "transition_1" : "transition_2";
        this.animation.setAnimation(0, transitionName, false);
        this.animation.setCompleteListener(function () {
            this.animation.setCompleteListener();
            this.animation.setAnimation(0, idleName, true);
        }.bind(this));
    },
    
    onCellFreezed: function () {
        cleverapps.audio.playSound(cleverapps.styles.IceBlockMakerTileView.growUpSound);
    }
}));

cleverapps.styles.IceBlockMakerTileView = {
    growUpSound: bundles.game.frames.special_element_grow_up
};