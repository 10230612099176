/**
 * Created by slava on 01.08.17.
 */

var ExplosionView = cc.Node.extend({
    ctor: function () {
        this._super();

        var sprite = new cc.Sprite(bundles.explosion.frames.boom_frames_1);
        this.addChild(sprite);
        sprite.setScale(1.2);
        this.sprite = sprite;

        this.frames = [];
        for (var i = 2; bundles.explosion.frames["boom_frames_" + i]; i++) {
            this.frames.push(bundles.explosion.frames["boom_frames_" + i]);
        }
    },

    animate: function (color) {
        if (this.spine) {
            this.spine.setColor(color);
            this.spine.setAnimation(0, "animation", false);
            this.spine.setCompleteListener(function () {
                cc.pool.putInPool(this);
            }.bind(this));
        } else {
            this.sprite.setColor(color);
            this.sprite.runAction(new cc.Sequence(
                new cc.Animate(new cc.Animation(this.frames, cleverapps.styles.ExplosionView.frame.duration / (this.frames.length + 1))),
                new cc.CallFunc(function () {
                    cc.pool.putInPool(this);
                }.bind(this))
            ));
        }
    },

    reuse: function () {
        this.visible = true;
        this.setScale(1);
    },

    unuse: function () {
        this.visible = false;
        this.removeFromParent(false);
    }
});

ExplosionView.fillPool = function () {
    for (var i = 0; i < 6; i++) {
        cc.pool.putInPool(new ExplosionView());
    }
};

ExplosionView.factory = function () {
    return cc.pool.hasObject(ExplosionView) ? cc.pool.getFromPool(ExplosionView) : undefined;
};

cleverapps.styles.ExplosionView = {
    frame: {
        duration: 0.15
    }
};