/**
 * Created by vladislav on 28.09.2020
 */

var MagnifierBooster = function () {
    BaseBoosterBefore.call(this, cleverapps.Boosters.TYPE_MAGNIFIER);
};

MagnifierBooster.prototype = Object.create(BaseBoosterBefore.prototype);
MagnifierBooster.prototype.constructor = MagnifierBooster;

MagnifierBooster.prototype.getActionBefore = function () {
    return {
        type: "magnifier",
        source: this,
        amount: 5,
        duration: cleverapps.config.name === "scramble" ? 0 : 0.3
    };
};