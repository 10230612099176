/**
 * Created by vladislav on 1/26/2024
 */

var SPMobageLogic = function () {
    PlatformLogic.call(this);

    TacticalExtendWindow.AMOUNT = 5;

    this.loginScreenShown = false;

    connector.social.on("login", this.closeLoginScreen.bind(this));
};

SPMobageLogic.prototype = Object.create(PlatformLogic.prototype);
SPMobageLogic.prototype.constructor = SPMobageLogic;

SPMobageLogic.prototype.showLoginScreen = function (callback) {
    if (this.loginScreenShown) {
        callback();
        return;
    }

    this.loginScreenShown = true;

    if (connector.social.isLoggedIn()) {
        callback();
        return;
    }

    this.callback = callback;

    var waitingList = ResourceProcessor.calcAlwaysNeed();

    var interval = cleverapps.timeouts.setInterval(function () {
        if (!cleverapps.bundleLoader.isLoaded(waitingList)) {
            return;
        }

        cleverapps.timeouts.clearInterval(interval);

        this.createLoginScreen();
    }.bind(this), 300);
};

SPMobageLogic.prototype.closeLoginScreen = function () {
    if (this.overlay) {
        this.overlay.removeFromParent();
        delete this.overlay;
    }

    if (this.callback) {
        this.callback();
        delete this.callback;
    }
};

SPMobageLogic.prototype.createLoginScreen = function () {
    var size = cleverapps.resolution.getSceneSize();
    this.overlay = new cc.LayerColor(cleverapps.styles.COLORS.WHITE, size.width, size.height);
    this.overlay.updateSize = function () {
        var newSize = cleverapps.resolution.getSceneSize();
        this.overlay.setContentSize2(newSize);
        this.overlay.buttonNode.setPositionRound(newSize.width / 2, newSize.height / 2);
    }.bind(this);
    cleverapps.scenes.getRunningScene().addChild(this.overlay, 9999);

    var buttonNode = this.overlay.buttonNode = new SpPlatformButtons(size.width, function (options) {
        if (connector.social.isConnected()) {
            connector.social.loginCallback(function (success) {
                if (success) {
                    this.closeLoginScreen();
                } else {
                    cleverapps.SocialManager.onFailedLogin();
                }
            }.bind(this), options);
        } else {
            cleverapps.notification.create("SocialManager.Initialization");
        }
    }.bind(this));

    this.overlay.addChild(buttonNode);
    buttonNode.setPositionRound(size.width / 2, size.height / 2);
};