/**
 * Created by vladislav on 1/26/2024
 */
var MobageLogic = function () {
    PlatformLogic.call(this);

    TacticalExtendWindow.AMOUNT = 5;
};

MobageLogic.prototype = Object.create(PlatformLogic.prototype);
MobageLogic.prototype.constructor = MobageLogic;

MobageLogic.HEIGHT = 860;