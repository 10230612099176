/**
 * Created by slava on 3/6/19
 */

LineHintAlgorithm = function (field) {
    HintAlgorithm.call(this, field);

    this.ideas = [this.completeGoalIdea, this.bottomRowElementsIdea];
};

LineHintAlgorithm.prototype = Object.create(HintAlgorithm.prototype);
LineHintAlgorithm.constructor = LineHintAlgorithm;

LineHintAlgorithm.prototype.getId = function () {
    return cleverapps.Boosters.TYPE_LINE;
};

LineHintAlgorithm.prototype.getResult = function () {
    this.columnsProcessed = {};

    return HintAlgorithm.prototype.getResult.call(this);
};

LineHintAlgorithm.prototype.completeGoalIdea = function (cell) {
    if (this.columnsProcessed[cell.col]) {
        return;
    }

    if (!this.isActiveGoal(cell) || !cell.hurtable) {
        return;
    }

    var goalId = cell.getGoalId();
    var remaining = this.countSameGoals(cell);

    var col = cell.col;
    var columnTotal = 0;
    for (var i = 0; i < Field.SIZE; i++) {
        cell = this.field.cells[i][col];
        if (cell && cell.getGoalId() === goalId) {
            columnTotal++;
        }
    }

    return columnTotal >= remaining;
};

LineHintAlgorithm.prototype.bottomRowElementsIdea = function (cell) {
    return this.isBottomRowElement(cell);
};