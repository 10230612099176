/**
 * https://doc.thinkingdata.cn/ta-manual/latest/en/installation/installation/client_sdk/game_engine_sdk_installation/cocoscreator_sdk_installation/cocoscreator_sdk_installation.html#_1-sdk-integration
 *
 * Created by andrey on 28.03.2024.
 */

var ThinkingDataLogger = function () {
    window.thinkingdata = new ThinkingAnalyticsAPI({
        appId: cleverapps.config.wechat.thinkingData.appId,
        serverUrl: cleverapps.config.wechat.thinkingData.serverUrl,
        autoTrack: {
            appShow: true,
            appHide: true
        }
    });

    this.queue = [];

    thinkingdata.init();

    this.totalPriceAmount = 0;
    cleverapps.whenAllInitialized(this.initTotalAmount.bind(this));

    this.onUpdateUserId();
};

ThinkingDataLogger.prototype.initTotalAmount = function () {
    this.totalPriceAmount = cleverapps.paymentsHistory.price.reduce(function (sum, price) {
        var convertedPrice = connector.payments.convertPrice(price);

        return sum + convertedPrice;
    }, 0);
};

ThinkingDataLogger.prototype.onUpdateUserId = function () {
    if (connector.platform.withTmpID()) {
        return;
    }

    if (!cleverapps.allInitialized) {
        cleverapps.whenAllInitialized(this.onUpdateUserId.bind(this));
        return;
    }

    this.initialized = true;

    thinkingdata.login(connector.platform.getUserID());

    this.send("userSetOnce", { register_time_v1: new Date(cleverapps.user.registered) });

    if (cleverapps.user.channel) {
        this.send("userSetOnce", { channel: cleverapps.user.channel });
    }

    this.send("userSet", {
        last_login_time: new Date(),
        current_coin: cleverapps.user.soft,
        current_diamond: cleverapps.user.gold,
        current_level: Math.round(cleverapps.user.getFloatLevel()),
        current_energy: cleverapps.lives && cleverapps.lives.amount,
        current_hero: typeof UnitsLibrary !== "undefined" && UnitsLibrary.GetInstance("main", 0).listOpenedOfType("hero").length
    });

    for (var i = 0; i < this.queue.length; i++) {
        this.send(this.queue[i].method, this.queue[i].properties);
    }
    this.queue = [];
};

ThinkingDataLogger.prototype.logEvent = function (name, params) {
    if (name.startsWith("hard-")) {
        name = cleverapps.EVENTS.SPENT.HARD;
    } else if (name.startsWith(cleverapps.EVENTS.STATS.SHOP_OPEN)) {
        name = cleverapps.EVENTS.STATS.SHOP_OPEN;
    } else if (name.startsWith(cleverapps.EVENTS.STATS.TUTORIAL_STEP)) {
        params.new_guide_id = +(name.replace(cleverapps.EVENTS.STATS.TUTORIAL_STEP, ""));
        name = cleverapps.EVENTS.STATS.TUTORIAL_STEP;
    }

    if (!ThinkingDataLogger.EVENTS[name]) {
        return;
    }

    name = ThinkingDataLogger.RENAME[name] || name;

    this.track(name, params);
};

ThinkingDataLogger.prototype.track = function (eventName, properties) {
    properties = Object.assign({}, properties || {}, {
        cur_level: Math.round(cleverapps.user.getFloatLevel()),
        cur_diamond: cleverapps.user.gold,
        cur_coin: cleverapps.user.soft,
        cur_energy: cleverapps.lives && cleverapps.lives.amount,
        total_days: cleverapps.user.getDaysSinceRegistration(),
        total_purchase_amount: this.totalPriceAmount
    });

    if (properties.store_type) {
        properties.store_type = properties.store_type.replace("ShopWindow.title.", "");
    }

    properties.eventName = eventName;

    this.send("track", properties);
};

ThinkingDataLogger.prototype.logPurchase = function (product) {
    this.totalPriceAmount += product.price;

    this.send("userSetOnce", { first_pay_time: new Date() });
    this.send("userSet", { last_pay_time: new Date() });

    this.send("userAdd", {
        total_pay_amount: product.price,
        total_pay_count: 1
    });
};

ThinkingDataLogger.prototype.logAdRevenue = function () {
    this.send("userAdd", { total_ad_num: 1 });
};

ThinkingDataLogger.prototype.send = function (method, properties) {
    if (!this.initialized) {
        this.queue.push({ method: method, properties: properties });

        if (this.queue.length > ThinkingDataLogger.QUEUE_LIMIT) {
            this.queue.shift();
        }

        return;
    }

    switch (method) {
        case "userSetOnce":
        case "userSet":
        case "userAdd":
            thinkingdata[method](properties);
            break;
        case "track":
            var eventName = properties.eventName;
            delete properties.eventName;
            thinkingdata.track(eventName, properties);
            break;
    }
};

ThinkingDataLogger.isApplicable = function () {
    return connector.platform.oneOf(connector.WECHAT) && cleverapps.config.wechat && cleverapps.config.wechat.thinkingData;
};

ThinkingDataLogger.EVENTS = cleverapps.createSet([].concat(
    Object.values(cleverapps.EVENTS.STATS),
    Object.values(cleverapps.EVENTS.WECHAT),
    cleverapps.EVENTS.SPENT.HARD,
    cleverapps.EVENTS.EARN.HARD,
    Object.keys(ConversionManager.EVENTS)
));

ThinkingDataLogger.RENAME = {};
ThinkingDataLogger.RENAME[cleverapps.EVENTS.STATS.SHOP_OPEN] = "shop_enter";
ThinkingDataLogger.RENAME[cleverapps.EVENTS.STATS.SHOP_CLOSE] = "shop_stay";
ThinkingDataLogger.RENAME[cleverapps.EVENTS.STATS.TUTORIAL_STEP] = "new_guide";

ThinkingDataLogger.QUEUE_LIMIT = 100;
