/**
 * Created by slava on 16/4/19
 */

var ControlButton = cleverapps.UI.Button.extend({
    ctor: function (options) {
        options = options || {};

        this._super({
            type: cleverapps.styles.ControlButton[options.type],
            onClicked: options.logic.onClicked.bind(this),
            content: new cc.Sprite(options.icon)
        });

        SceneDecors.add(this, cleverapps.skins.getSlot("controlButton", { target: this }));

        if (options.logic.updateAttention) {
            options.logic.onUpdateAttention = this.createListener(this.setAttention.bind(this));
            options.logic.updateAttention();
        }
    }
});

cleverapps.styles.ControlButton = {
    "default": {
        button_png: bundles.controlbuttons.frames.control_button_png,
        button_on_png: bundles.controlbuttons.frames.control_button_on_png,
        mark: {
            x: { align: "right", dx: 5 },
            y: { align: "top", dy: -5 }
        }
    },
    "alternate": {
        button_png: bundles.controlbuttons.frames.back_button_png,
        button_on_png: bundles.controlbuttons.frames.back_button_on_png,
        mark: {
            x: { align: "right", dx: 5 },
            y: { align: "top", dy: -5 }
        }
    }
};
