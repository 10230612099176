/**
 * Created by Vladislav on 5/17/2024.
 */

var Country = function () {
    cleverapps.EventEmitter.call(this);

    this.country = undefined;

    this.load();

    this.update();
};

Country.prototype = Object.create(cleverapps.EventEmitter.prototype);
Country.prototype.constructor = Country;

Country.prototype.update = function () {
    cleverapps.RestClient.get("/country/getbyip", {}, function (response) {
        var countryCode = response && response.countryCode;

        console.log("countryCode", countryCode);

        if (countryCode) {
            this.setCountry(countryCode);
        }
    }.bind(this));
};

Country.prototype.whenReady = function (callback) {
    if (this.country) {
        callback(this.country);
    } else {
        this.once("ready", callback);
    }
};

Country.prototype.getCountry = function () {
    return this.country;
};

Country.prototype.setCountry = function (country) {
    if (this.country !== country) {
        this.country = country;

        this.save();

        this.trigger("ready", country);
    }
};

Country.prototype.load = function () {
    var info = cleverapps.dataLoader.load(DataLoader.TYPES.COUNTRY);
    if (info && info.country) {
        this.country = info.country;
    }
};

Country.prototype.save = function () {
    cleverapps.dataLoader.save(DataLoader.TYPES.COUNTRY, {
        country: this.country
    });
};