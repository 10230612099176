/**
 * Created by ivan on 10.05.18.
 */

var LollipopCellView = BaseCellView.extend({
    ctor: function (lollipopCell) {
        this._super();

        this.setContentSize(cleverapps.styles.BaseCellView.CELL_SIZE_PX, cleverapps.styles.BaseCellView.CELL_SIZE_PX);
        this.setAnchorPoint(0.5, 0.5);

        this.initialize(lollipopCell);

        this.setCascadeOpacityEnabled(true);
    },

    initialize: function (lollipopCell) {
        this._super(lollipopCell);

        lollipopCell.onChangeColorListener = this.changeColorAnimation.bind(this);
    },

    changeColorAnimation: function (cell, oldColor, newColor) {
        this.finishSmile();
        var animation = LollipopAnimation.factory(oldColor, this.cell.bundleId());
        animation.setPosition(this.width / 2, this.height / 2);
        animation.setLocalZOrder(1);
        this.addChild(animation);
        this.changingColor = true;

        animation.runAction(new cc.Sequence(
            new cc.DelayTime(0.3),
            new cc.CallFunc(function () {
                animation.setSkin(LollipopColorsMap[newColor]);
            })
        ));

        var colorComponent = this.cell.findComponent(ColorComponent);
        animation.runAnimate("animation", function () {
            colorComponent.view.updateImage(cell);
            colorComponent.view.show();

            this.changingColor = false;
            animation.setSkin(LollipopColorsMap[oldColor]);
        }.bind(this), colorComponent.view.image);

        cleverapps.audio.playSound(bundles[cell.bundleId()].urls.lollipop_change_color_effect);
    },

    finishSmile: function () {
        if (this.smiling) {
            this.smiling = false;

            var colorComponent = this.cell.findComponent(ColorComponent);
            colorComponent.view.show();

            this.animation.finishAnimation();
        }
    },

    smile: function () {
        if (!cleverapps.styles.LollipopCellView.smile || !cleverapps.Spine.hasAnimation("smile", bundles[this.cell.bundleId()].jsons.lollipop_json)) {
            return;
        }

        if (this.smiling || this.changingColor) {
            return;
        }

        this.animation = LollipopAnimation.factory(this.cell.findComponent(ColorComponent).color, this.cell.bundleId());
        this.addChild(this.animation);
        this.animation.setPositionRound(this.width / 2, this.height / 2);

        var colorComponent = this.cell.findComponent(ColorComponent);
        this.smiling = true;
        this.animation.runAnimate("smile", this.finishSmile.bind(this), colorComponent.view.image);

        cleverapps.audio.playSound(bundles[this.cell.bundleId()].urls.lollipop_smile_effect);
    },

    unuse: function () {
        this.cell.onChangeColorListener = function () {};

        this._super();
    },

    startExploding: function (goal, delta) {
        cleverapps.audio.playSound(bundles[this.cell.bundleId()].urls.lollipop_collect_effect);
        this.startExplodingViaCollect(goal, delta);
    }
});

cleverapps.styles.LollipopCellView = {
    smile: false
};