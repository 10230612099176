/**
 * Created by Andrey Popov on 10.02.2021.
 */

var Puzzle15Unit = function (map, options) {
    this.map = map;

    this.type = options.type;
    this.x = options.x;
    this.y = options.y;

    this.code = options.code;
    this.stage = options.stage;
    if (options.stageCount) {
        this.isLastStage = options.stageCount === options.stage;
    }
    this.upgradableIcon = options.upgradableIcon;

    this.hideableIcon = options.hideableIcon;
    this.static = options.static;

    cleverapps.EventEmitter.call(this);
};

Puzzle15Unit.prototype = Object.create(cleverapps.EventEmitter.prototype);
Puzzle15Unit.prototype.constructor = Puzzle15Unit;

Puzzle15Unit.prototype.isMergeable = function (unit) {
    if (this.code) {
        return unit.code === this.code && unit.stage === this.stage && !this.isLastStage;
    }

    return unit.type === this.type;
};

Puzzle15Unit.prototype.isPlaceMatchType = function () {
    return this.y * this.map.width + this.x === this.type;
};

Puzzle15Unit.prototype.canMove = function () {
    for (var i = 0; i < ISO_NEIGHBORS.length; i ++) {
        var tx = this.x + ISO_NEIGHBORS[i].x;
        var ty = this.y + ISO_NEIGHBORS[i].y;
        if (tx < 0 || ty < 0 || tx >= this.map.width || ty >= this.map.height) {
            continue;
        }
        if (this.map.getUnit(tx, ty) === undefined) {
            return true;
        }
    }

    return false;
};

Puzzle15Unit.prototype.move = function (path, slots, onUnitOccupiedSlot, callback) {
    var position = path[path.length - 1];

    var unit = this.map.getUnit(position.x, position.y);
    if (unit && slots) {
        this.trigger('unitMoved', path, slots, function () {
            this.map.remove(Map2d.LAYER_UNITS, unit.x, unit.y);
            unit.trigger('unitDeleted', true);

            this.map.remove(Map2d.LAYER_UNITS, this.x, this.y);
            this.map.add(Map2d.LAYER_UNITS, position.x, position.y, this);

            this.x = position.x;
            this.y = position.y;

            onUnitOccupiedSlot();
        }.bind(this), callback);

        return;
    }

    this.map.remove(Map2d.LAYER_UNITS, this.x, this.y);
    this.map.add(Map2d.LAYER_UNITS, position.x, position.y, this);

    if (slots) {
        this.trigger('unitMoved', path, slots, onUnitOccupiedSlot, callback);
    }

    this.x = position.x;
    this.y = position.y;
};

Puzzle15Unit.prototype.delete = function (silent) {
    this.map.remove(Map2d.LAYER_UNITS, this.x, this.y);
    if (!silent) {
        this.trigger('unitDeleted');
    }
};