/**
 * Created by slava on 02.02.17.
 */

var FishCell = function (x, y) {
    BaseCell.call(this, x, y);
    this.movable = true;
    this.hurtable = false;
    this.putInPoolAvailable = true;
};

FishCell.prototype = Object.create(BaseCell.prototype);
FishCell.prototype.constructor = FishCell;
Skinify(FishCell, "fish_cell");

FishCell.prototype.onTouchEnd = function () {
    if (this.movable) {
        this.animate(BaseCell.ANIMATION_ONCLICK);
        cleverapps.notification.create("Fish.MoveToDown");
    }
};

FishCell.prototype.smile = function () {
    if (this.alive) {
        this.animate(BaseCell.ANIMATION_SMILE);
    }
};

FishCell.prototype.load = function () {
    // do nothing
};

FishCell.prototype.save = function () {
    return FishCell.CODES;
};

FishCell.prototype.getViewClass = function () {
    return FishCellView;
};

FishCell.prototype.onBurnStage = function () {
    if (Game.currentGame) {
        for (var i = this.y + 1; i < Field.SIZE; i++) {
            if (Game.currentGame.field.inField(i, this.x)) {
                return;
            }
        }
        this.explodeViaCollect();
    }
};

FishCell.prototype.getGoalId = function () {
    return FishCell.GOAL_ID;
};

FishCell.GOAL_ID = "cell_fish";