/**
 * Created by r4zi4l on 05.04.2024
 */

RewardTypes.lives = {
    controls: "MenuBarLivesItem",
    flyingAnimation: Reward.JUMP_COLLECT_ANIMATION,

    getIcon: function () {
        return bundles.reward_icons.frames.life_png;
    },

    getSmallIcon: function () {
        return bundles.reward_icons.frames.life_small_png;
    },

    handler: function (value, options) {
        cleverapps.lives.give(value, options.event, true);
        return function () {
            cleverapps.lives.processRegenerateState(true);
            cleverapps.lives.runListeners();
        };
    }
};

RewardTypes.unlimitedLives = {
    controls: "MenuBarLivesItem",

    getIcon: function () {
        return bundles.reward_icons.frames.life_unlim;
    },

    getSmallIcon: function () {
        return bundles.reward_icons.frames.life_unlim_small;
    },

    handler: function (value) {
        cleverapps.unlimitedLives.buy(value.time);
        return function () {
            cleverapps.lives.onBuyUnlimitedLivesListener();
        };
    }
};
