/**
 * Created by vlad on 12.12.18.
 */

var DirtyHoneyDecoratorCell = function (x, y, options) {
    BaseCell.call(this, x, y, options);

    this.putInPoolAvailable = true;

    this.onCreateNew = function () {};
};

DirtyHoneyDecoratorCell.prototype = Object.create(BaseCell.prototype);
DirtyHoneyDecoratorCell.prototype.constructor = DirtyHoneyDecoratorCell;

DirtyHoneyDecoratorCell.prototype.getViewClass = function () {
    return DirtyHoneyDecoratorCellView;
};

DirtyHoneyDecoratorCell.prototype.save = function () {
    return DirtyHoneyDecoratorCell.CODES.concat(this.innerCell.save());
};

DirtyHoneyDecoratorCell.prototype.getColor = function () {
    return this.innerCell.getColor();
};

DirtyHoneyDecoratorCell.prototype.showUpDuration = function () {
    return 0.5;
};

DirtyHoneyDecoratorCell.prototype.explodeDuration = function () {
    return 0.5;
};

DirtyHoneyDecoratorCell.prototype.bundleId = function () {
    return "dirty_honey";
};

DirtyHoneyDecoratorCell.prototype.onExplode = function () {
    var decoratorComponent = this.findComponent(DecoratorComponent);
    decoratorComponent.onExplode();

    Game.currentGame.dirtyHoneyTileCanProcess = false;
};