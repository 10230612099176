/**
 * Created by spepa on 05.04.2023
 */

var InstantTournament = function () {
    this.id = undefined;

    this.load();

    if (connector.platform.isConnected()) {
        this.updateId();
    }

    connector.platform.on("changeStatus", function (status) {
        if (status === connector.STATUS_CONNECTED) {
            this.updateId();
        }
    }.bind(this));
};

InstantTournament.prototype.updateId = function () {
    if (!this.isAvailable()) {
        return;
    }

    cleverapps.platformLogic.getCurrentTournamentId(function (code, id) {
        if (code !== cleverapps.CODE_SUCCEED) {
            return;
        }

        if (id !== this.id) {
            this.id = id;
            this.save();
        }
    }.bind(this));
};

InstantTournament.prototype.load = function () {
    var data = cleverapps.dataLoader.load(DataLoader.TYPES.INSTANT_TOURNAMENT);
    if (data) {
        this.id = data.id;
    }
};

InstantTournament.prototype.save = function () {
    cleverapps.dataLoader.save(DataLoader.TYPES.INSTANT_TOURNAMENT, {
        id: this.id
    });
};

InstantTournament.prototype.isInTournament = function () {
    return this.id !== undefined;
};

InstantTournament.prototype.isAvailable = function () {
    return cleverapps.platformLogic.getCurrentTournamentId;
};

InstantTournament.prototype.reportScore = cleverapps.accumulate(1000, function () {
    if (!cleverapps.scoreTracker.needToReport()) {
        return;
    }

    if (!connector.platform.isConnected()) {
        return;
    }

    cleverapps.platformLogic.getCurrentTournamentId(function (code, id) {
        if (code !== cleverapps.CODE_SUCCEED) {
            return;
        }

        if (this.id !== id) {
            this.id = id;
            this.save();
        }

        if (this.id) {
            cleverapps.scoreTracker.report();
        }
    }.bind(this));
});

InstantTournament.REPORT_TIMEOUT = 600000;

if (cleverapps.config.type === "merge") {
    InstantTournament.REPORT_TIMEOUT = cleverapps.parseInterval("2 days");
}