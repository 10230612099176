/**
 * Created by vladislav on 3/4/19
 */

var ClockCellView = BaseCellView.extend({
    ctor: function (clockCell) {
        this._super();

        if (clockCell.isForGoal) {
            var styles = this.getStyles();
            this.setAnchorPoint(0.5, 0.5);
            this.setContentSize2(styles.goal.width, styles.goal.height);
            var goal = new cc.Sprite(bundles.clock.frames.clock_goal);
            this.addChild(goal);
            goal.setPositionRound(this.width / 2, this.height / 2);
            return;
        }

        var bigComponent = clockCell.findComponent(BigComponent);
        if (!bigComponent.isHead) {
            return;
        }

        this.initialize(clockCell);

        this.createBg();
        this.createItems(clockCell);
    },

    createBg: function () {
        var bg = this.bg = new cleverapps.Spine(bundles.clock.jsons.clock_json);
        bg.setAnimation(0, "animation", true);
        bg.setLocalZOrder(10);
        this.addChild(bg);
        bg.setPositionRound(this.width / 2, this.height / 2);
    },

    createItems: function (clockCell) {
        if (this.items) {
            this.items.forEach(function (item) {
                item.view.removeFromParent(true);
            });
        }
        this.items = [];

        clockCell.colors.forEach(function (color, index) {
            var item = this.createItem(color);
            this.addChild(item);

            item.setAnchorPoint(0, 0.5);
            item.setPositionRound(this.width / 2, this.height / 2);
            item.setRotation(this.calcRotation(index));
            item.setScaleY(this.calcScale());
            item.setLocalZOrder(clockCell.colors.length - index);

            if (index === 0) {
                this.addCurrentAnimation(item);
                item.setScaleY(item.getScaleY() * 1.2);
            }

            this.items.push({ view: item });
        }.bind(this));
    },

    removeCurrentAnimation: function (item) {
        if (item.currAnimation) {
            item.stopAction(item.currAnimation);
            delete item.currAnimation;
        }
    },

    addCurrentAnimation: function (item) {
        var baseScaleX = item.getScaleX();
        var baseScaleY = item.getScaleY();
        item.currAnimation = item.runAction(new cc.RepeatForever(
            new cc.Sequence(
                new cc.ScaleTo(0.4, baseScaleX * 1.1, baseScaleY * 1.1),
                new cc.ScaleTo(0.4, baseScaleX, baseScaleY)
            )
        ));
    },

    createItem: function (color) {
        var animation = new cleverapps.Spine(bundles.clock.jsons.petal_json);
        animation.setSkin(ColorComponent.CODE_COLORS[color]);
        animation.setAnimation(0, "idle_1", false);

        var item = new cc.Node();
        item.setAnchorPoint(0.5, 0.5);
        item.setContentSize2(this.getStyles().animation);
        item.addChild(animation);
        item.animation = animation;
        animation.setPositionRound(animation.width / 2, animation.height / 2);

        return item;
    },

    calcScale: function () {
        var minScale = 0.55, maxScale = 1.4;
        var step = (maxScale - minScale) / this.cell.getMaxLives();
        return minScale + step * (this.cell.getMaxLives() - this.cell.colors.length);
    },

    calcRotation: function (index) {
        var INITIAL_ROTATION = -90;
        return 360 / this.cell.colors.length * index + INITIAL_ROTATION;
    },

    initialize: function (clockCell) {
        this._super(clockCell);

        clockCell.onChangeLivesListener = this.changeLives.bind(this);
        this.setPositionRound(BaseCellView.alignInTheGrid(clockCell.x + 0.5, clockCell.y + 0.5));
    },

    calcPosition: function (item, dist) {
        var unitVector = { x: Math.cos(-item.rotation * Math.PI / 180), y: Math.sin(-item.rotation * Math.PI / 180) };
        return cc.p(item.x + unitVector.x * dist, item.y + unitVector.y * dist);
    },

    changeLives: function () {
        var styles = this.getStyles();

        var goalItem = this.items[0].view;
        goalItem.replaceParentSamePlace(this.parent);

        this.items.shift();

        var movePosition = this.calcPosition(goalItem, styles.moveBy.distance);

        this.setLocalZOrder(9);
        this.removeCurrentAnimation(goalItem);

        goalItem.runAction(new cc.Sequence(
            new cc.MoveTo(0.2, movePosition),
            new cc.CallFunc(function () {
                if (!Game.currentGame) {
                    return;
                }

                var goal = Game.currentGame.goals.findTargetFor(this.cell.getGoalId());
                goal.collect(1, goalItem);
                goalItem.runAction(new cc.RotateTo(0.3, -90));
            }.bind(this))
        ));

        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.6),
            new cc.CallFunc(function () {
                this.setLocalZOrder(this.baseZOrder);
                this.rotateAll();
            }.bind(this))
        ));
    },

    rotateAll: function () {
        this.items.forEach(function (item, index) {
            item.view.runAction(new cc.Sequence(
                new cc.Spawn(
                    new cc.ScaleTo(0.3, 1, this.calcScale()),
                    new cc.RotateTo(0.3, this.calcRotation(index))
                ),
                new cc.CallFunc(function () {
                    if (index === 0) {
                        item.view.runAction(new cc.Sequence(
                            new cc.ScaleTo(0.3, item.view.getScaleX(), item.view.getScaleY() * 1.2),
                            new cc.CallFunc(function () {
                                this.addCurrentAnimation(item.view);
                            }.bind(this))
                        ));
                    }
                }.bind(this))
            ));
        }.bind(this));
    },

    unuse: function () {
        this.cell.onChangeLivesListener = function () {};

        this._super();
    },

    getStyles: function () {
        return cleverapps.styles.ClockCellView;
    }
});

cleverapps.styles.ClockCellView = {
    radius: 75,

    hover: {
        y: 10
    },

    goal: {
        width: 120,
        height: 120
    },

    animation: {
        width: 100,
        height: 100
    },

    moveBy: {
        distance: 70
    }
};