/**
 * Created by vladislav on 25.01.2021
 */

var BonusWorldRewardsView = cc.Node.extend({
    ctor: function (bonusWorld) {
        this._super();

        this.bonusWorld = bonusWorld;

        this.setAnchorPoint(0.5, 0.5);

        this.styles = cleverapps.styles.BonusWorldRewardsView[this.bonusWorld.type];

        this.rewards = RewardsConfig.BonusWorld.map(function (reward, index) {
            var isOpen = this.bonusWorld.isRewardReceived(reward);

            var container = new cc.Node();
            this.addChild(container);
            container.setAnchorPoint(0.5, 0.5);
            container.setContentSize2(this.styles.prize);

            if (!isOpen) {
                var text = Messages.get(this.bonusWorld.config.localizationPrefix + ".BonusWorld.RewardTooltip", {
                    rewardLevel: reward.level
                });
                cleverapps.tooltipManager.create(container, {
                    rewards: reward.reward,
                    text: text,
                    location: index < 1 ? cleverapps.UI.Tooltip.LOCATION_ABOVE : cleverapps.UI.Tooltip.LOCATION_BELOW
                });

                container.hoverHandler = cleverapps.UI.applyHover(container);
            }

            var animation = new cleverapps.Spine(this.bonusWorld.bundle.jsons["chest_" + index + "_json"]);
            container.addChild(animation);
            animation.setAnimation(0, isOpen ? "idle_open" : "idle", true);
            animation.setPositionRound(container.width / 2, container.height / 2);
            container.animation = animation;

            if (index === RewardsConfig.BonusWorld.length - 1) {
                cleverapps.aims.registerTarget("finalPrizeTarget" + this.bonusWorld.type, container);
            }

            return container;
        }, this);

        this.bonusWorld.on("giveReward", this.giveReward.bind(this));
    },

    adjustPosition: function (bgScale) {
        this.rewards.forEach(function (reward, index) {
            var dx = this.styles.prizes[index].x * bgScale;
            var dy = this.styles.prizes[index].y * bgScale;

            var bgSize = cleverapps.resolution.getBgSize();
            if (dy < -bgSize.height / 2 + reward.height) {
                dy = -bgSize.height / 2 + reward.height;
                dx -= reward.width;
            }
            if (dy > bgSize.height / 2 - reward.height) {
                dy = bgSize.height / 2 - reward.height;
                dx += reward.width;
            }

            dx = Math.max(dx, -(bgSize.width / 2 - reward.width));
            dx = Math.min(dx, bgSize.width / 2 - reward.width);

            reward.setPosition(this.parent.convertToNodeSpace(cc.p(bgSize.width / 2 + dx, bgSize.height / 2 + dy)));
        }.bind(this));
    },

    giveReward: function (reward, f) {
        var container = this.rewards[RewardsConfig.BonusWorld.indexOf(reward)];

        if (container.hoverHandler) {
            container.hoverHandler.remove();
            container.hoverHandler = undefined;
        }

        cleverapps.tooltipManager.remove(container);
        cleverapps.audio.playSound(this.bonusWorld.bundle.urls.prize_sfx);

        var animation = container.animation;
        animation.setAnimation(0, "open", false);
        animation.setCompleteListener(function () {
            animation.setAnimation(0, "idle_open", true);
            animation.setCompleteListener();

            f();
        });
    }
});

cleverapps.styles.BonusWorldRewardsView = {};

cleverapps.styles.BonusWorldRewardsView[BonusWorld.TYPE_VALENTINES] = {
    prize: {
        width: 150,
        height: 150
    },

    prizes: [
        {
            x: 220,
            y: -430
        },
        {
            x: -71,
            y: -60
        },
        {
            x: 19,
            y: 441
        }
    ]
};

cleverapps.styles.BonusWorldRewardsView[BonusWorld.TYPE_EASTER] = {
    prize: {
        width: 150,
        height: 150
    },

    prizes: [
        {
            x: 245,
            y: -349
        },
        {
            x: -245,
            y: 85
        },
        {
            x: 19,
            y: 476
        }
    ]
};

cleverapps.styles.BonusWorldRewardsView[BonusWorld.TYPE_SUMMER] = {
    prize: {
        width: 150,
        height: 150
    },

    prizes: [
        {
            x: 144,
            y: -422
        },
        {
            x: 203,
            y: 33
        },
        {
            x: 22,
            y: 422
        }
    ]
};

cleverapps.styles.BonusWorldRewardsView[BonusWorld.TYPE_XMAS] = {
    prize: {
        width: 90,
        height: 90
    },

    prizes: [
        {
            x: -281,
            y: -423
        },
        {
            x: 26,
            y: 15
        },
        {
            x: -40,
            y: 373
        }
    ]
};

if (cleverapps.config.ui === "riddles") {
    cleverapps.styles.BonusWorldRewardsView[BonusWorld.TYPE_SUMMER] = {
        prize: {
            width: 90,
            height: 90
        },

        prizes: [
            {
                x: -251,
                y: -467
            },
            {
                x: 140,
                y: -91
            },
            {
                x: -40,
                y: 373
            }
        ]
    };
}

if (cleverapps.config.ui === "tropical") {
    cleverapps.styles.BonusWorldRewardsView[BonusWorld.TYPE_SUMMER] = {
        prize: {
            width: 110,
            height: 110
        },

        prizes: [
            {
                x: 180,
                y: -241
            },
            {
                x: 98,
                y: -41
            },
            {
                x: -10,
                y: 384
            }
        ]
    };
}