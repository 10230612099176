/**
 * Created by slava on 02.02.17.
 */

var ChainDecoratorCellView = BaseCellView.extend({
    ctor: function (decoratedCell) {
        this._super();

        var image = this.image = new cc.Sprite(decoratedCell.lives === 1 ? bundles.chain_decorator.frames.chain_decorator_cell : bundles.chain_decorator.frames.chain_decorator_cell2);
        this.setContentSize2(image.getContentSize());
        this.setAnchorPoint(0.5, 0.5);
        image.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(image);

        this.setCascadeOpacityEnabled(true);

        this.initialize(decoratedCell);
    },

    initialize: function (decoratedCell) {
        this._super(decoratedCell);

        this.image.setSpriteFrame(decoratedCell.lives === 1 ? bundles.chain_decorator.frames.chain_decorator_cell : bundles.chain_decorator.frames.chain_decorator_cell2);
        this.image.setVisible(true);
        this.cell.onChangeLivesListener = this.changeLives.bind(this);
    },

    changeLives: function () {
        if (this.cell.lives !== 1) {
            return;
        }

        var animation = BaseAnimation.factoryLimited(bundles.chain_decorator.jsons.chain2_animation_json);
        animation.setPosition(this.getPosition());

        if (!animation.parent) {
            this.parent.addChild(animation);
        }
        cleverapps.audio.playSound(bundles.chain_decorator.urls.chain_decorator_stage_decrease_effect);

        animation.runAnimate("animation", function () {
            if (this.image.isRunning()) {
                this.image.setSpriteFrame(bundles.chain_decorator.frames.chain_decorator_cell);
                this.image.visible = true;
            }
        }.bind(this), this.image);
    },

    animateExplode: function (callback) {
        var animation = BaseAnimation.factoryLimited(bundles.chain_decorator.jsons.chain_animation_json);
        animation.setPosition(this.getPosition());

        if (!animation.parent) {
            this.parent.addChild(animation);
        }
        cleverapps.audio.playSound(bundles.chain_decorator.urls.chain_decorator_die_effect);

        animation.runAnimate("animation", false, new cc.FadeOut(0.5), this.image);
        callback();
    }
});