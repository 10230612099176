/**
 * Created by mac on 10/8/20
 */

var GitButtonsView = cc.Node.extend({
    ctor: function (options) {
        options = this.options = options || {};

        this._super();
        this.setAnchorPoint(0.5, 0.5);

        var styles = cleverapps.styles.GitButtonsView;

        this.pushButton = this.createButton(bundles.git.frames.push_icon, "push");
        this.revertButton = this.createButton(bundles.git.frames.revert_icon, "revert");

        var layout = new cleverapps.Layout([this.pushButton, this.revertButton], {
            direction: options.direction || cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });

        this.addChild(layout);
        this.setContentSize2(layout.getContentSize());
        layout.setPositionRound(this.width / 2, this.height / 2);

        this.drawStatus();

        this.setPositionRound(styles.position);

        this.pushButton.setCascadeOpacityEnabled(true);
        this.pushButton.setCascadeColorEnabled(true);

        this.revertButton.setCascadeOpacityEnabled(true);
        this.revertButton.setCascadeColorEnabled(true);

        cleverapps.git.on("changed", this.drawStatus.bind(this), this);
    },

    createButton: function (icon, action) {
        var styles = cleverapps.styles.GitButtonsView;

        return new cleverapps.UI.Button({
            type: cleverapps.styles.UI.Button.Images.git,
            content: new cc.Sprite(icon),
            width: styles.button.width,
            height: styles.button.height,
            onClicked: function () {
                cleverapps.git.displayWindow(action);
            }
        });
    },

    clearButtonState: function (button) {
        button.enable();
        button.setColor(new cc.Color(255, 255, 255, 255));
        button.setOpacity(255);
        button.setVisible(true);
    },

    setButtonState: function (button, state) {
        switch (state) {
            case GitButtonsView.ENABLE_BUTTON:
                break;
            case GitButtonsView.DISABLE_BUTTON:
                button.disable();
                button.setOpacity(100);
                break;
            case GitButtonsView.ERROR_BUTTON:
                button.disable();
                button.setColor(new cc.Color(255, 0, 0, 255));
                break;
            case GitButtonsView.HIDDEN_BUTTON:
                button.disable();
                button.setVisible(false);
        }
    },

    createLoading: function () {
        this.loading = new cc.Sprite(bundles.git.frames.loading);
        this.loading.runAction(new cc.RepeatForever(new cc.RotateBy(5, 360)));
        this.loading.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(this.loading);
    },

    removeLoading: function () {
        if (this.loading) {
            this.loading.removeFromParent();
            this.loading = undefined;
        }
    },

    drawStatus: function () {
        this.setVisible(true);
        this.clearButtonState(this.pushButton);
        this.clearButtonState(this.revertButton);
        this.removeLoading();

        var status = cleverapps.git.getStatus();

        if (status === GitManager.STATUS_PROCESSING) {
            this.setButtonState(this.pushButton, GitButtonsView.HIDDEN_BUTTON);
            this.setButtonState(this.revertButton, GitButtonsView.HIDDEN_BUTTON);
            this.createLoading();
        } else if (status === GitManager.STATUS_ERROR) {
            var error = cleverapps.git.getError();

            if (error === GitManager.PUSH) {
                this.setButtonState(this.pushButton, GitButtonsView.ERROR_BUTTON);
                this.setButtonState(this.revertButton, GitButtonsView.ENABLE_BUTTON);
            } else if (error === GitManager.REVERT) {
                this.setButtonState(this.pushButton, GitButtonsView.ERROR_BUTTON);
                this.setButtonState(this.revertButton, GitButtonsView.ERROR_BUTTON);
            }
        } else if (status === GitManager.STATUS_DETACHED) {
            this.setButtonState(this.pushButton, GitButtonsView.ERROR_BUTTON);
            this.setButtonState(this.revertButton, GitButtonsView.ERROR_BUTTON);
        } else if (status === GitManager.STATUS_REDY) {
            this.setButtonState(this.pushButton, GitButtonsView.ENABLE_BUTTON);
            this.setButtonState(this.revertButton, GitButtonsView.ENABLE_BUTTON);
        } else if (status === GitManager.STATUS_EMPTY) {
            this.setButtonState(this.pushButton, this.options.hiddenByDefault ? GitButtonsView.HIDDEN_BUTTON : GitButtonsView.DISABLE_BUTTON);
            this.setButtonState(this.revertButton, this.options.hiddenByDefault ? GitButtonsView.HIDDEN_BUTTON : GitButtonsView.DISABLE_BUTTON);
        } else {
            this.setVisible(false);
        }
    }
});

GitButtonsView.ENABLE_BUTTON = 0;
GitButtonsView.DISABLE_BUTTON = 1;
GitButtonsView.ERROR_BUTTON = 2;
GitButtonsView.HIDDEN_BUTTON = 3;

cleverapps.styles.GitButtonsView = {
    button: {
        width: 60,
        height: 60
    },
    margin: 10,
    position: {
        x: { align: "right", dx: -50 },
        y: { align: "top", dy: -150 }
    }
};
