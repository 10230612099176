/**
 * Created by Aleksandr on 28.12.2022
 */

var BlocksOrangery = {
    init: function () {
        this.tabs = [{
            node: this.convertCellToIcon(new Cell({ color: 0 })),
            rows: this.getFormRows()
        }, {
            node: this.convertCellToIcon(new Cell({ color: 0, mark: "goal_blue" })),
            rows: this.getMarksRows()
        }, {
            node: this.convertCellToIcon(new Cell({
                color: 0,
                components: [{
                    "name": "ice",
                    "stage": 0
                }] 
            })),
            rows: this.getComponentsRows()
        }
        ];
    },

    convertCellToIcon: function (cell) {
        var view = new CellView(cell);
        view.setAnchorPoint(0.5, 0.5);
        view.setScale(0.5);
        return view;
    },
    
    getMarksRows: function () {
        var markRows = [[{
            eraser: true,
            itemIcon: new cc.Sprite(bundles.orangery.frames.eraser_icon),
            minimizedIcon: new cc.Sprite(bundles.orangery.frames.eraser_icon)
        }]];

        markRows.push(Object.keys(BlocksGame.MARKS).map(function (markName) {
            var cell = new Cell({ color: 0, mark: markName });
            return {
                itemIcon: this.convertCellToIcon(cell),
                minimizedIcon: this.convertCellToIcon(cell),
                form: [
                    "x"
                ],
                color: 0,
                mark: {
                    type: markName
                }
            };
        }.bind(this)));
        return markRows;
    },

    getComponentsRows: function () {
        var componentsRows = [[{
            eraser: true,
            itemIcon: new cc.Sprite(bundles.orangery.frames.eraser_icon),
            minimizedIcon: new cc.Sprite(bundles.orangery.frames.eraser_icon)
        }]];

        componentsRows.push([{
            name: "ice",
            constructor: IceOnCell
        }].map(function (component) {
            var cell = new Cell({
                color: 0,
                components: [{
                    name: component.name
                }]
            });

            return {
                itemIcon: this.convertCellToIcon(cell),
                minimizedIcon: this.convertCellToIcon(cell),
                form: [
                    "x"
                ],
                color: 0,
                components: [{ name: component.name }]
            };
        }.bind(this)));

        return componentsRows;
    },

    getFormRows: function () {
        var formRows = [[{
            eraser: true,
            itemIcon: new cc.Sprite(bundles.orangery.frames.eraser_icon),
            minimizedIcon: new cc.Sprite(bundles.orangery.frames.eraser_icon)
        }], []];
        var formPerRow = 7;

        for (var c = 0; c < BlocksGame.COLORS.length; c++) {
            formRows[1].push({
                color: c,
                itemIcon: this.createTextureByColor(BlocksGame.COLORS[c].rgb),
                minimizedIcon: this.createTextureByColor(BlocksGame.COLORS[c].rgb)
            });
        }

        for (var i = 0; i < Forms.ALL_POSSIBLE_VARIANTS.length; i++) {
            if (i % formPerRow === 0) {
                formRows.push([]);
            }

            var form = Forms.ALL_POSSIBLE_VARIANTS[i];
            var color = i % BlocksGame.COLORS.length;
            var piece = new Piece(undefined, {
                form: form, color: color, fromOrangery: true
            });

            formRows[Math.floor(i / formPerRow) + 2].push({
                form: form,
                color: color,
                itemIcon: new PieceView(piece, true),
                minimizedIcon: new PieceView(piece, true)
            });
        }
        return formRows;
    },

    createTextureByColor: function (color) {
        var colorSprite = new cc.Sprite();
        var w = 60;
        var h = 60;
        
        var texture = new cc.Texture2D();
        var len = w * h * 3;
        var data = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            var colorPart;
            if (i % 3 === 0) {
                colorPart = color.r;
            } else if (i % 3 === 1) {
                colorPart = color.g;
            } else if (i % 3 === 2) {
                colorPart = color.b;
            }

            data[i] = colorPart;
        }
        texture.initWithData(data, cc.Texture2D.PIXEL_FORMAT_RGB888, w, h, w * h);

        colorSprite.initWithTexture(texture, cc.rect(0, 0, w, h));
        return colorSprite;
    },

    getItemCode: function (item) {
        if (item.form) {
            return Forms.ToKey(item.form);
        }
        if (item.color !== undefined) {
            return "Color " + item.color;
        }
        return "Eraser";
    }
};
