/**
 * Created by decipaliz on 20.12.2023
 */

var EditorGoalsView = cleverapps.Layout.extend({
    ctor: function (goals) {
        GoalViewFactory.init();
        var level = LevelManager.getInstance().level;
        var views = goals.map(function (goal) {
            var goalView = new EditorGoalView(goal);

            goalView.baseScale = 0.8;
            goalView.setScale(goalView.baseScale);

            if (level.isHard()) {
                goalView.setColor(cleverapps.styles.COLORS.HARD);
            }
            return goalView;
        }, this);

        this._super(views, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: cleverapps.styles.EditorGoalsView.margin
        });
        this.setPositionRound(cleverapps.styles.EditorGoalsView);
    }
});

cleverapps.styles.EditorGoalsView = {
    x: { align: "center", dx: 40 },
    y: { align: "top", dy: -70 },
    margin: 20
};
