/**
 * Created by Denis Kuzin on 17 july 2023
 */

var PointerView = cleverapps.Spine.extend({
    avoidNode: "PointerView",
    ctor: function (options) {
        this._super(bundles.finger.jsons.arrow_json);

        this.options = options || {};
        this.target = this.options.target;
        this.targetOffset = this.options.targetOffset || 0;

        this.setLocalZOrder(14);

        var scene = cleverapps.scenes.getRunningScene();
        scene.addChild(this);

        this.afterResize();

        cleverapps.windows.on("openWindow", this.createListener(this.setVisible.bind(this, false)), this);
        cleverapps.windows.on("closeWindows", this.createListener(this.setVisible.bind(this, true)), this);
    },

    afterResize: function () {
        var targretRect = this.target.getSceneBoundingBox();
        var pointerPosition = cc.rectGetCenter(targretRect);

        var direction = this.guessDirection(cc.rectGetCenter(pointerPosition));

        if (this.direction !== direction) {
            this.direction = direction;
            this.setAnimation(0, this.direction, true);
        }

        var step = [PointerView.DIRECTIONS.DOWN, PointerView.DIRECTIONS.LEFT].includes(this.direction) ? 1 : -1;

        if ([PointerView.DIRECTIONS.UP, PointerView.DIRECTIONS.DOWN].includes(this.direction)) {
            pointerPosition.y += ((targretRect.height + this.height) / 2 + this.targetOffset) * step;
        } else {
            pointerPosition.x += ((targretRect.width + this.width) / 2 + this.targetOffset) * step;
        }
        
        this.setPosition(pointerPosition);
    },

    guessDirection: function (targetPosition) {
        if (this.options.direction) {
            return this.options.direction;
        }

        if (this.target instanceof MenuBarItemView) {
            return PointerView.DIRECTIONS.RIGHT;
        }
        if (typeof CardView !== "undefined" && this.target instanceof CardView) {
            return PointerView.DIRECTIONS.DOWN;
        }
        if (!this.target.getParent()) {
            return PointerView.DIRECTIONS.UP;
        }

        var sceneSize = cleverapps.resolution.getSceneSize();

        if (targetPosition.y > sceneSize.height * 4 / 5) {
            return PointerView.DIRECTIONS.UP;
        }

        if (targetPosition.x < sceneSize.width / 5) {
            return PointerView.DIRECTIONS.LEFT;
        }

        if (targetPosition.x > sceneSize.width * 4 / 5) {
            return PointerView.DIRECTIONS.RIGHT;
        }

        return PointerView.DIRECTIONS.DOWN;
    }

});

PointerView.create = function (options) {
    options = options || {};

    var pointer = new PointerView(options);

    PointerView.remove(PointerView.currentPointer);
    PointerView.currentPointer = pointer;

    addCleaner(pointer, function () {
        PointerView.remove(pointer);
    });

    return pointer;
};

PointerView.remove = function (pointer) {
    if (!pointer || PointerView.currentPointer !== pointer) {
        return;
    }

    PointerView.currentPointer = undefined;
    pointer.removeFromParent();
};

PointerView.DIRECTIONS = {
    UP: "up",
    DOWN: "down",
    LEFT: "left",
    RIGHT: "right"
};