/**
 * Created by Andrey Popov on 10.02.2021.
 */

var AdsPuzzle15 = cc.Node.extend({
    ctor: function (properties, eventBus) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);

        this.properties = properties;
        this.eventBus = eventBus;
        this.generatesEvents = properties.generatesEvents || [];
        this.listensEvents = properties.listensEvents || [];
        this.skinVariationPostfix = "_" + (properties.skinVariation || 1);

        if (properties.seed) {
            cleverapps.Random.seed(properties.seed);
        }

        if (typeof Unit === "undefined") {
            Map2d.prototype.listAvailableUnits = function () {
                var units = [];

                for (var y = 0; y < this.getHeight(); y++) {
                    for (var x = 0; x < this.getWidth(); x++) {
                        var unit = this.getUnit(x, y);
                        if (unit) {
                            units.push(unit);
                        }
                    }
                }

                return units;
            };
        }

        var LogicClass = properties.type ? AdsPuzzle15.TYPES[properties.type] : MergePuzzleLogic;
        this.logic = new LogicClass(properties.fieldWidth, properties.fieldHeight, this);
        this.map = new Map2d(Array(this.logic.fieldHeight).fill("2".repeat(this.logic.fieldWidth)));

        var styles = this.getStyles();
        var width = this.map.width * styles.cell.width + (this.map.width - 1) * styles.margin + 2 * styles.padding;
        var height = this.map.height * styles.cell.height + (this.map.height - 1) * styles.margin + 2 * styles.padding;
        this.setContentSize(width, height);
    },

    init: function (callback) {
        this.logic.createUnits({ mergeTool: this.properties.mergeTool });
        this.showUp();

        if (!this.properties.isPreview) {
            this.actions = this.logic.getPlayActions().concat({ type: "finish" });

            this.movingNode = new cc.Node();
            this.movingNode.setLocalZOrder(10000);
            this.addChild(this.movingNode);

            if (this.properties.delay) {
                this.play();
            } else if (this.listensEvents.indexOf(Wysiwyg.EVENTS.SCENE_STARTED) !== -1) {
                this.eventBus.on(Wysiwyg.EVENTS.SCENE_STARTED, this.play.bind(this), this);
            }
        }

        callback();
    },

    showUp: function () {
        var styles = this.getStyles();
        styles = cleverapps.overrideStyles(styles, {
            moveSound: this.properties.moveSound ? bundles[this.properties.moveSound.bundle].urls[this.properties.moveSound.sound] : bundles.puzzle15.urls.cubes_effect,
            moveSpeed: this.properties.fingerSpeed
        });

        var bg = cleverapps.UI.createScale9Sprite(styles.bg, cleverapps.UI.Scale9Rect.TwoPixelXY);
        bg.setContentSize(this.width + styles.bgPadding, this.height + (styles.bgPaddingY || styles.bgPadding));
        bg.setPosition(this.width / 2, this.height / 2);
        this.addChild(bg, -1);

        this.slots = {};
        for (var y = 0; y < this.map.height; y++) {
            for (var x = 0; x < this.map.width; x++) {
                var cellSlot = new cc.Node();
                this.slots[x + y * this.map.width] = cellSlot;
                cellSlot.setContentSize(styles.cell);
                var unit = this.map.getUnit(x, y);
                if (unit) {
                    var unitView = cellSlot.unitView = new Puzzle15UnitView(unit, styles);
                    unitView.setPositionRound(cellSlot.width / 2, cellSlot.height / 2);
                    cellSlot.addChild(unitView);
                }

                cellSlot.setPositionRound(x * styles.cell.width + styles.margin * x + styles.cell.offset.x, this.height - (y + 1) * styles.cell.height - styles.margin * y + styles.cell.offset.y);
                this.addChild(cellSlot);

                if (AdsPuzzle15.TYPES[this.properties.type] === MiniMergeLogic) {
                    var tileView = cleverapps.UI.createScale9Sprite(bundles.puzzle15.frames[(y + x) % 2 ? "mm_cell_120" : "mm_cell2_120"]);
                    tileView.setPositionRound(cellSlot.x + cellSlot.width / 2, cellSlot.y + cellSlot.height / 2);
                    tileView.setContentSize2(styles.cell.width + styles.margin, styles.cell.height + styles.margin);
                    this.addChild(tileView, -1);
                }
            }
        }

        if (this.generatesEvents.indexOf(Wysiwyg.EVENTS.PUZZLE_SHOW_UP) !== -1) {
            this.eventBus.trigger(Wysiwyg.EVENTS.PUZZLE_SHOW_UP);
        }
    },

    play: function () {
        setTimeout(function () {
            var clipProps = cleverapps.wysiwyg.hierarchy.getSelectedClip().properties;
            var options = {
                finger: {
                    image: cleverapps.wysiwyg.googleDriveProvider.getLink(clipProps.finger),
                    moveSpeed: this.properties.fingerSpeed,
                    noCircle: this.properties.fingerNoCircle
                },
                gameType: this.properties.type,
                currentGame: this,
                stepNoForZigzag: []
            };
            if (this.properties.clickSound) {
                options.clickSound = bundles[this.properties.clickSound.bundle].urls[this.properties.clickSound.sound];
            }
            if (this.properties.stepNoForZigzag) {
                options.stepNoForZigzag = this.properties.stepNoForZigzag.split(",").map(function (n) {
                    return parseInt(n);
                }).filter(function (num) {
                    return num > 0;
                });
            }

            new Puzzle15Player(options).play({
                actions: this.actions,
                delay: 100,
                onStartPlay: function () {
                    if (this.generatesEvents.indexOf(Wysiwyg.EVENTS.PUZZLE_STARTED) !== -1) {
                        this.eventBus.trigger(Wysiwyg.EVENTS.PUZZLE_STARTED);
                    }
                }.bind(this)
            }, function () {
                if (this.generatesEvents.indexOf(Wysiwyg.EVENTS.PUZZLE_FINISHED) !== -1) {
                    this.eventBus.trigger(Wysiwyg.EVENTS.PUZZLE_FINISHED);
                }

                if (this.properties.winSound) {
                    cleverapps.audio.playSound(bundles[this.properties.winSound.bundle].urls[this.properties.winSound.sound]);
                }

                setTimeout(function () {
                    if (this.generatesEvents.indexOf(Wysiwyg.EVENTS.SCENE_COMPLETED) !== -1) {
                        this.eventBus.trigger(Wysiwyg.EVENTS.SCENE_COMPLETED);
                    }
                }.bind(this), this.properties.clipCompletedDelay || 0);
            }.bind(this));
        }.bind(this), 1000);
    },

    afterSuccessMove: function (progress, options) {
        if (["PICTURE", "MINI_MERGE"].indexOf(this.properties.type) === -1) {
            cleverapps.audio.playSound(bundles.puzzle15.urls.open_cubes_effect);
        }

        if (this.generatesEvents.indexOf(Wysiwyg.EVENTS.PROGRESS_CHANGED) !== -1) {
            this.eventBus.trigger(Wysiwyg.EVENTS.PROGRESS_CHANGED, progress, {
                actualValue: this.logic.stepsCounter
            });
        }

        var styles, cellSlot;
        if (progress === 1) {
            if (this.properties.type === "PICTURE") {
                styles = this.getStyles();

                var type = this.map.width * this.map.height - 1;
                cellSlot = this.slots[type];
                var unitView = new Puzzle15UnitView(new Puzzle15Unit(
                    this.map,
                    {
                        type: type, x: this.map.width - 1, y: this.map.height - 1, hideableIcon: true 
                    }
                ), styles);
                unitView.setPositionRound(cellSlot.width / 2, cellSlot.height / 2);
                unitView.setScale(0.1);
                cellSlot.addChild(unitView);
                unitView.runAction(new cc.ScaleTo(0.3, 1));

                setTimeout(function () {
                    var fullView = new cc.Sprite(this.map.width === 4 ? bundles.puzzle15.frames.picture16_Full : (bundles.puzzle15.frames["pictureFull" + this.skinVariationPostfix] || bundles.puzzle15.frames.pictureFull));
                    fullView.setPositionRound(this.width / 2 + styles.cell.offset.x + styles.openPicturePart.offset.x, this.height / 2 + styles.cell.offset.y + styles.openPicturePart.offset.y);
                    fullView.setScale(styles.pictureFull.scale);
                    fullView.setOpacity(0);
                    fullView.runAction(new cc.FadeIn(0.5));
                    this.addChild(fullView);
                }.bind(this), 500);
            } else if (this.properties.type === "MINI_MERGE") {
                styles = this.getStyles();
                cellSlot = this.slots[options.lastMergedPos.x + options.lastMergedPos.y * this.map.width];

                var node = new cc.Node();
                node.setContentSize2(styles.cell.width, styles.cell.height);
                node.setAnchorPoint(0.5, 0.5);

                var backLight = new cleverapps.Spine(bundles.ads_spines.jsons.cell_light);
                backLight.setScale(0.35);
                backLight.setAnimation(0, "animation", true);

                node.addChild(backLight);
                backLight.setPositionRound(node.width / 2, node.height / 2);

                cellSlot.unitView.unSelect();
                cellSlot.unitView.addChild(node);
                cellSlot.unitView.icon.replaceParentSamePlace(node, { keepScale: true });

                node.replaceParentSamePlace(this, { keepScale: true });

                var scaleX = this.width / node.width;
                var scaleY = this.height / node.width;
                var scale = Math.min(scaleX, scaleY) * 0.8;

                cleverapps.audio.playSound(bundles.ads_sfx_common.urls.notepad_scene_1);

                node.runAction(new cc.Sequence(
                    new cc.Spawn(
                        new cc.ScaleTo(0.6, scale),
                        new cc.MoveTo(0.6, this.width / 2, this.height / 2)
                    ).easing(cc.easeInOut(3)),
                    new cc.CallFunc(function () {
                        if (!this.script || !this.script.whereToFly) {
                            return;
                        }

                        node.replaceParentSamePlace(this.parent, { keepScale: true });

                        this.runAction(new cc.Sequence(
                            new cc.DelayTime(1.0),
                            new cc.ScaleTo(0.5, 0).easing(cc.easeBackIn()),
                            new cc.CallFunc(function () {
                                node.stopAllActions();
                                var target = this.script.whereToFly();
                                var pos = node.getRelativeCoordinates(target);
                                node.runAction(new cc.Sequence(
                                    new cc.Spawn(
                                        new cc.MoveTo(0.5, pos),
                                        new cc.ScaleTo(0.5, 0.4)
                                    ).easing(cc.easeBackIn()),
                                    new cc.RemoveSelf(),
                                    new cc.CallFunc(function () {
                                        this.script.onFlyCompleted && this.script.onFlyCompleted();
                                    }.bind(this))
                                ));
                            }.bind(this))
                        ));
                    }.bind(this))
                ));
            }
        }
    },

    getStyles: function () {
        var styles = cleverapps.styles.AdsPuzzle15[this.properties.type] || cleverapps.styles.AdsPuzzle15.MERGE;
        if (styles["skin" + this.skinVariationPostfix]) {
            styles = cleverapps.overrideStyles(styles, styles["skin" + this.skinVariationPostfix], true);
        }
        if (this.properties.bgPaddingX !== null && this.properties.bgPaddingY !== null
            && !isNaN(this.properties.bgPaddingX) && !isNaN(this.properties.bgPaddingY)) {
            styles = cleverapps.overrideStyles(styles, {
                bgPadding: this.properties.bgPaddingX,
                bgPaddingY: this.properties.bgPaddingY
            }, true);
        }
        styles.skinVariationPostfix = this.skinVariationPostfix;
        return styles;
    }
});

cleverapps.styles.AdsPuzzle15 = {
    MERGE: {
        margin: 10,
        cell: {
            width: 70,
            height: 70,
            offset: {
                x: 20,
                y: -14
            }
        },
        icon: {
            offset: {
                x: -6,
                y: 6
            }
        },
        padding: 20,
        bg: bundles.puzzle15.frames.table_frame,
        bgPadding: 20
    },

    PICTURE: {
        margin: 0,
        cell: {
            width: 206,
            height: 206,
            offset: { x: 0, y: 6 }
        },
        icon: {
            offset: { x: 0, y: 2 }
        },
        openPicturePart: {
            offset: { x: 0, y: 0 },
            scale: 1.0
        },
        pictureFull: {
            scale: 1.1
        },
        padding: 0,
        bg: bundles.puzzle15.frames.picture_frame_1,
        bgPadding: 64
    },

    MINI_MERGE: {
        margin: 10,
        cell: {
            width: 70,
            height: 70,
            offset: {
                x: 0,
                y: 0
            }
        },
        icon: {
            offset: {
                x: 0,
                y: 0
            },
            scale: 0.75
        },
        selectedCell: {
            image: bundles.puzzle15.frames.mm_cell_target,
            "not selected": true
        },

        padding: 0,
        bg: bundles.puzzle15.frames.mm_bg,
        bgPadding: 75
    }
};

if (cleverapps.config.name === "adventure") {
    cleverapps.overrideStyles(cleverapps.styles.AdsPuzzle15, {
        MERGE: {
            icon: {
                offset: {
                    x: -2,
                    y: 0
                }
            }
        }
    });
} else if (cleverapps.config.name === "runes") {
    cleverapps.overrideStyles(cleverapps.styles.AdsPuzzle15, {
        MERGE: {
            cell: {
                width: 74,
                height: 74,
                offset: {
                    x: 24,
                    y: -18
                }
            },
            padding: 24,
            bgPadding: 38
        },

        PICTURE: {
            cell: {
                width: 188,
                height: 188
            },
            icon: {
                offset: { x: -8, y: 8 }
            },
            openPicturePart: {
                offset: { x: -7, y: 9 },
                scale: 0.94
            },
            pictureFull: {
                scale: 0.98
            }
        }
    });
} else if (cleverapps.config.name === "wondermerge") {
    cleverapps.overrideStyles(cleverapps.styles.AdsPuzzle15, {
        PICTURE: {
            margin: 3,
            cell: {
                width: 201,
                height: 201,
                offset: { x: 0, y: 1 }
            },
            bgPadding: 84,
            openPicturePart: {
                offset: { x: 0, y: 4 },
                scale: 1
            },
            pictureFull: {
                scale: 1.12
            }
        }
    });
} else if (cleverapps.config.name === "mergecraft") {
    cleverapps.overrideStyles(cleverapps.styles.AdsPuzzle15, {
        MERGE: {
            skin_2: {
                cell: {
                    offset: {
                        x: 20,
                        y: -19
                    }
                },
                bg: bundles.puzzle15.frames.table_frame_2,
                bgPadding: 296,
                bgPaddingY: 80
            }
        },
        PICTURE: {
            skin_2: {
                cell: {
                    offset: { x: 0, y: 0 }
                },
                bg: bundles.puzzle15.frames.picture_frame_2
            },
            skin_3: {
                cell: {
                    offset: { x: 0, y: 0 }
                },
                bg: bundles.puzzle15.frames.picture_frame_3
            }
        }
    });
}

AdsPuzzle15.TYPES = {
    MERGE: MergePuzzleLogic,
    PICTURE: PicturePuzzleLogic,
    STATIC: StaticPuzzleLogic,
    MINI_MERGE: MiniMergeLogic
};

AdsPuzzle15.BUNDLES = ["puzzle15", "puzzle_merge_tool", "finger"];

AdsPuzzle15.MERGE_TOOL = (function () {
    var list = [];
    if (cleverapps.config.name === "mergecraft") {
        list = list.concat(["barrel", "dwarf", "king", "mallet", "rope", "saw"]);
    } else if (cleverapps.config.name === "riddles") {
        list = list.concat(["cone", "donut"]);
    } else {
        list = list.concat(["barrel"]);
    }
    return list;
}());

AdsPuzzle15.PROPERTIES = [{
    name: "seed", type: "number"
}, {
    name: "fieldWidth", type: "number", bubbleUp: true, dirtyOnChange: true
}, {
    name: "fieldHeight", type: "number", bubbleUp: true, dirtyOnChange: true
}, {
    name: "type", type: "string", items: Object.keys(AdsPuzzle15.TYPES), bubbleUp: true, dirtyOnChange: true
}, {
    name: "skinVariation", type: "string", items: ["1", "2", "3"], bubbleUp: true, dirtyOnChange: true
}, {
    name: "bgPaddingX", type: "number", placeHolderText: "gap between bg and field"
}, {
    name: "bgPaddingY", type: "number", placeHolderText: "gap between bg and field"
}, {
    name: "isGame", value: true
}, {
    name: "generatesEvents", type: "string", items: Object.values(Wysiwyg.EVENTS), multiselect: true, value: [Wysiwyg.EVENTS.PROGRESS_CHANGED, Wysiwyg.EVENTS.SCENE_COMPLETED]
}, {
    name: "listensEvents", type: "string", items: Object.values(Wysiwyg.EVENTS), multiselect: true, value: [Wysiwyg.EVENTS.SCENE_STARTED]
}, {
    name: "clipCompletedDelay", type: "number", value: 0
}, {
    name: "winSound", type: "sound", folders: ["sounds"]
}, {
    name: "clickSound", type: "sound", folders: ["sounds"], value: { bundle: "puzzle15", sound: "puzzle_click_effect" }
}, {
    name: "moveSound", type: "sound", folders: ["sounds"], value: { bundle: "puzzle15", sound: "cubes_effect" }
}, {
    name: "fingerSpeed", type: "number"
}, {
    name: "stepNoForZigzag", type: "string", placeHolderText: "e.g. 3,5,12"
}, {
    name: "fingerNoCircle", type: "checkBox", value: false
}, {
    name: "mergeTool", type: "string", items: AdsPuzzle15.MERGE_TOOL, value: AdsPuzzle15.MERGE_TOOL[0]
}];