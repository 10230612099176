/**
 * Created by slava on 3/6/19
 */

ColorHintAlgorithm = function (field) {
    HintAlgorithm.call(this, field);

    this.ideas = [this.completeGoalIdea, this.feedEaterIdea];
};

ColorHintAlgorithm.prototype = Object.create(HintAlgorithm.prototype);
ColorHintAlgorithm.constructor = ColorHintAlgorithm;

ColorHintAlgorithm.prototype.getId = function () {
    return cleverapps.Boosters.TYPE_COLOR;
};

ColorHintAlgorithm.prototype.completeGoalIdea = function (cell) {
    if (!this.isActiveGoal(cell)) {
        return;
    }

    var color = cell.getColor();
    if (!color) {
        return;
    }

    var goalId = cell.getGoalId();
    var remaining = this.countSameGoals(cell);

    var colorTotal = 0;
    for (var i = 0; i < Field.SIZE; i++) {
        for (var j = 0; j < Field.SIZE; j++) {
            cell = this.field.cells[i][j];
            if (cell && cell.getColor() === color && cell.getGoalId() === goalId) {
                colorTotal++;
                var goalCoefComponent = cell.findComponent(GoalCoefComponent);
                if (goalCoefComponent && goalCoefComponent.coef) {
                    colorTotal += goalCoefComponent.coef;
                }
            }
        }
    }

    return colorTotal >= remaining;
};

ColorHintAlgorithm.prototype.feedEaterIdea = function (cell) {
    if (!Game.currentGame.EATERS || !cell.findComponent(ColorComponent)) {
        return;
    }

    var eater = this.getEater(cell.getColor());
    if (!eater) {
        return;
    }

    return eater.lives <= 10;
};