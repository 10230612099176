/**
 * Created by razial on 21.06.2024.
 */

var Products = {
    buyExp10: {
        boughtMessage: "Shop.BuyExp10.BoughtMessage",
        reward: {
            exp: 10
        }
    },
    buyExp150: {
        boughtMessage: "Shop.BuyExp150.BoughtMessage",
        reward: {
            exp: 150
        }
    },

    coins350: {
        reward: {
            soft: 350
        },
        icon: "shop_coins_very_small_png",
        boughtMessage: "Product.SoftCurrencyBought"
    },
    coins1100: {
        reward: {
            soft: 1100
        },
        icon: "shop_coins_small_png",
        boughtMessage: "Product.SoftCurrencyBought"
    },
    coins3000: {
        reward: {
            soft: 3000
        },
        icon: "shop_coins_medium_png",
        boughtMessage: "Product.SoftCurrencyBought"
    },
    coins7000: {
        reward: {
            soft: 7000
        },
        icon: "shop_coins_large_png",
        boughtMessage: "Product.SoftCurrencyBought"
    },

    expeditionPack: {
        boughtMessage: "Shop.expeditionPack.boughtMessage",
        restoreReward: {
            hard: 1200
        }
    },

    growthFund: {
        boughtMessage: "Shop.growthFund.boughtMessage",
        value: "800%",
        icon: "growth_fund_icon",
        mainSceneOnly: true,
        clanGift: true,
        restoreReward: {
            hard: 2800
        },
        TileModelClass: typeof GrowthFundTileModel !== "undefined" && GrowthFundTileModel,
        ViewClassName: typeof GrowthFundTile !== "undefined" && GrowthFundTile
    },

    unlimitedLives: {
        boughtMessage: "UnlimitedLivesBought",
        reward: {
            unlimitedLives: "3 days"
        },
        icon: "unlimitedLives_png",
        animation: "animation",
        getViewClassName: function () {
            if (typeof LivesProductTile !== "undefined") {
                return LivesProductTile;
            }
        },
        getTileModelClass: function () {
            if (typeof BuyLivesTileModel !== "undefined") {
                return BuyLivesTileModel;
            }
        }
    },

    cheapProduct: {
        reward: {
            hard: 1
        },
        rewardsIcon: "shop_gold_png",
        icon: "shop_gold_very_small_png"
    },
    gold500: {
        reward: {
            hard: 500
        },
        rewardsIcon: "shop_gold_png",
        icon: "shop_gold_very_small_png"
    },
    gold1800: {
        oldPrice: 1.99,
        label: 1800,
        reward: {
            hard: 1800
        },
        rewardsIcon: "shop_gold_png",
        icon: "shop_gold_small_png"
    },
    gold3800: {
        reward: {
            hard: 3800
        },
        rewardsIcon: "shop_gold_png",
        icon: "shop_gold_medium_png"
    },
    gold6000: {
        clanGift: true,
        oldPrice: 5.99,
        label: 6000,
        reward: {
            hard: 6000
        },
        rewardsIcon: "shop_gold_png",
        icon: "shop_gold_large_png"
    },
    gold25000: {
        clanGift: true,
        reward: {
            hard: 25000
        },
        rewardsIcon: "shop_gold_png",
        icon: "shop_gold_huge_png"
    },

    starterPack0: {
        boughtMessage: "StarterPack.Bought",
        boughtMessageParams: {
            title: "StarterPack0.Title"
        },
        reward: RewardsConfig.Packs[0],
        stage: 0,
        mainSceneOnly: true
    },
    starterPack: {
        boughtMessage: "StarterPack.Bought",
        boughtMessageParams: {
            title: "StarterPack1.Title"
        },
        reward: RewardsConfig.Packs[1],
        stage: 1,
        mainSceneOnly: true
    },
    starterPack2: {
        boughtMessage: "StarterPack.Bought",
        boughtMessageParams: {
            title: "StarterPack2.Title"
        },
        reward: RewardsConfig.Packs[2],
        stage: 2,
        mainSceneOnly: true
    },

    piggybank500: {},
    piggybank1800: {},
    piggybank6000: {},
    piggybank25000: {},

    subsWeek: {
        boughtMessage: "Shop.SubsWeek.BoughtMessage",
        icon: "prem_tile_icon",
        clanGift: true
    },
    subsMonth: {
        boughtMessage: "Shop.SubsMonth.BoughtMessage",
        icon: "prem_tile_icon",
        clanGift: true,
        TileModelClass: typeof SubscriptionTileModel !== "undefined" && SubscriptionTileModel,
        ViewClassName: typeof SubscriptionTile !== "undefined" && SubscriptionTile
    },

    subsWeekTest: {
        boughtMessage: "Shop.SubsWeek.BoughtMessage",
        icon: "prem_tile_icon"
    },
    subsMonthTest: {
        boughtMessage: "Shop.SubsMonth.BoughtMessage",
        icon: "prem_tile_icon"
    },

    // OLD PRODUCTS COMPATIBILITY
    sweetPack: {
        boughtMessage: "Shop.sweetPack.boughtMessage",
        reward: {
            hard: 500,
            boosters: {
                0: 1,
                1: 1
            }
        },
        icon: "pack500_png",
        ViewClassName: typeof PackProductTile !== "undefined" && PackProductTile
    },
    jamPack: {
        boughtMessage: "Shop.jamPack.boughtMessage",
        reward: {
            hard: 1800,
            boosters: {
                0: 1,
                1: 1,
                2: 1
            }
        },
        icon: "pack1800_png",
        ViewClassName: typeof PackProductTile !== "undefined" && PackProductTile
    },
    tastyPack: {
        boughtMessage: "Shop.tastyPack.boughtMessage",
        reward: {
            hard: 3800,
            boosters: {
                0: 3,
                1: 3,
                2: 3
            }
        },
        icon: "pack3800_png",
        ViewClassName: typeof PackProductTile !== "undefined" && PackProductTile
    },
    honeyPack: {
        boughtMessage: "Shop.honeyPack.boughtMessage",
        reward: {
            hard: 6000,
            boosters: {
                0: 5,
                1: 5,
                2: 5
            }
        },
        icon: "pack6000_png",
        ViewClassName: typeof PackProductTile !== "undefined" && PackProductTile
    },
    epicPack: {
        boughtMessage: "Shop.epicPack.boughtMessage",
        reward: {
            hard: 25000,
            boosters: {
                0: 20,
                1: 20,
                2: 20
            }
        },
        icon: "pack25000_png",
        ViewClassName: typeof PackProductTile !== "undefined" && PackProductTile
    },

    noAds: {
        boughtMessage: "NoAds.Bought",
        reward: {
            hard: 150,
            noAds: 1
        }
    },

    kit1: {
        boughtMessage: "Kit1.Bought",
        restoreReward: {
            hard: 100
        }
    },
    kit3: {
        boughtMessage: "Kit3.Bought",
        restoreReward: {
            hard: 300
        }
    },
    kit10: {
        boughtMessage: "Kit10.Bought",
        restoreReward: {
            hard: 1000
        },
        oldPrice: 35.99
    },
    kit50: {
        boughtMessage: "Kit50.Bought",
        restoreReward: {
            hard: 5000
        }
    },

    island1: {
        boughtMessage: "Island1.Bought",
        restoreReward: {
            hard: 100
        }
    },
    island2: {
        boughtMessage: "Island2.Bought",
        restoreReward: {
            hard: 200
        }
    },
    island5: {
        boughtMessage: "Island5.Bought",
        restoreReward: {
            hard: 500
        }
    },
    island10: {
        boughtMessage: "Island10.Bought",
        restoreReward: {
            hard: 1000
        }
    },
    island15: {
        boughtMessage: "Island15.Bought",
        restoreReward: {
            hard: 1500
        }
    },
    island20: {
        boughtMessage: "Island20.Bought",
        restoreReward: {
            hard: 2000
        }
    },
    island30: {
        boughtMessage: "Island30.Bought",
        restoreReward: {
            hard: 3000
        }
    },
    island45: {
        boughtMessage: "Island45.Bought",
        restoreReward: {
            hard: 4500
        }
    }
};

if (["merge", "tile3", "blocks"].includes(cleverapps.config.type)) {
    Products.gold500.reward = { hard: 200 };
    Products.gold1800.reward = { hard: 900 };
    Products.gold3800.reward = { hard: 2100 };
    Products.gold6000.reward = { hard: 3500 };
    Products.gold25000.reward = { hard: 14000 };
}

if (cleverapps.config.type === "solitaire" && cleverapps.config.name !== "spades") {
    Products.sweetPack.reward.boosters = { 10: 2, 11: 2, 12: 2 };
    Products.jamPack.reward.boosters = { 10: 3, 11: 3, 12: 3 };
    Products.tastyPack.reward.boosters = { 10: 5, 11: 5, 12: 5 };
    Products.honeyPack.reward.boosters = { 10: 7, 11: 7, 12: 7 };
    Products.epicPack.reward.boosters = { 10: 30, 11: 30, 12: 30 };
}

if (cleverapps.config.type === "match3") {
    Products.sweetPack.reward.boosters = { 5: 1, 6: 1, 7: 1 };
    Products.jamPack.reward.boosters = { 5: 2, 6: 2, 7: 2 };
    Products.tastyPack.reward.boosters = {
        0: 3, 1: 3, 5: 3, 6: 3, 7: 3
    };
    Products.honeyPack.reward.boosters = {
        0: 5, 1: 5, 5: 5, 6: 5, 7: 5
    };
    Products.epicPack.reward.boosters = {
        0: 18, 1: 18, 5: 18, 6: 18, 7: 18
    };
    Products.noAds.reward.hard = 350;
}

if (cleverapps.config.name === "scramble") {
    Products.sweetPack.reward.boosters = { 13: 2, 14: 2, 15: 2 };
    Products.jamPack.reward.boosters = { 13: 3, 14: 3, 15: 3 };
    Products.tastyPack.reward.boosters = { 13: 5, 14: 5, 15: 5 };
    Products.honeyPack.reward.boosters = { 13: 7, 14: 7, 15: 7 };
    Products.epicPack.reward.boosters = { 13: 30, 14: 30, 15: 30 };
}

if (cleverapps.config.type === "match3") {
    Products.kit3.restoreReward = { hard: 900 };
    Products.kit10.restoreReward = { hard: 3000 };
    Products.kit50.restoreReward = { hard: 11000 };
}
