/**
 * Created by denis on 6 august 2020
 */

var MagnetBooster = function () {
    BaseBoosterBefore.call(this, cleverapps.Boosters.TYPE_MAGNET);
};

MagnetBooster.prototype = Object.create(BaseBoosterBefore.prototype);
MagnetBooster.prototype.constructor = MagnetBooster;

MagnetBooster.prototype.getActionBefore = function () {
    return {
        type: "magnet",
        source: this,
        duration: 2.2
    };
};