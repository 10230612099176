/**
 * Created by slava on 24.03.17.
 */

var Hose = function () {
    this.location = 0;
    this.progress = 0;

    this.updateLocationsAmount();

    this.onReset = function () {};
    this.onGotoNextLocation = function () {};
    this.onAnimateOpen = function () {};
    this.onAnimateClose = function () {};

    this.reset();

    this.load();
};

Hose.prototype.calcLocations = function () {
    var amount = 0;

    var exist = function () {
        var bundleName = "episode_" + amount;

        if (cleverapps.config.type === "board" && cleverapps.settings.language !== "en") {
            bundleName += "_" + cleverapps.settings.language;
        }

        return bundles[bundleName] && !bundles[bundleName].meta.debug;
    };

    while (exist()) {
        amount++;
    }

    return amount;
};

Hose.prototype.updateLocationsAmount = function () {
    this.totalLocations = this.calcLocations();
};

Hose.prototype.gamePlayed = function (outcome) {
    if (outcome === GameBase.OUTCOME_VICTORY) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.METHA_LEVEL);
        cleverapps.playSession.set(cleverapps.EVENTS.METHA_LEVEL, true);

        this.progress++;
        this.save();
    }
};

Hose.prototype.reset = function () {
    this.onReset();
};

Hose.prototype.getBackgroundBundle = function () {
    if (!bundles.main_background_vertical || !bundles.main_background_horizontal) {
        return;
    }
    return cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? "main_background_vertical" : "main_background_horizontal";
};

Hose.prototype.isPassedAll = function () {
    return this.location >= this.totalLocations;
};

Hose.prototype.getLocation = function () {
    return this.location;
};

Hose.prototype.getCurrentPage = function () {
    return this.location + this.progress / Hose.LEVELS_PER_LOCATION;
};

Hose.prototype.canMoveNext = function () {
    return this.progress >= Hose.LEVELS_PER_LOCATION && this.location < this.totalLocations;
};

Hose.prototype.gotoNextLocation = function (callback) {
    if (this.canMoveNext()) {
        this.location++;
        this.progress = 0;
        this.save();

        this.onGotoNextLocation(callback, this.location);
    } else {
        callback();
    }
};

Hose.prototype.animateOpen = function (f, page) {
    this.onAnimateOpen(f, page);
};

Hose.prototype.animateClose = function (f, page) {
    this.onAnimateClose(f, page);
};

Hose.prototype.getTotalPages = function () {
    return this.totalLocations;
};

Hose.prototype.getInfo = function () {
    return {
        location: this.location,
        progress: this.progress
    };
};

Hose.prototype.updateData = function (data) {
    data = data || {};

    this.location = data.location || 0;
    this.progress = data.progress || 0;
};

Hose.prototype.save = function (fromServer) {
    cleverapps.dataLoader.save(DataLoader.TYPES.HOSE, this.getInfo());
    if (!fromServer) {
        cleverapps.synchronizer.addUpdateTask("metha");
    }
};

Hose.prototype.updateInfo = function (serverData) {
    this.updateData(serverData);
    this.save(true);
};

Hose.prototype.load = function () {
    var data = cleverapps.dataLoader.load(DataLoader.TYPES.HOSE);

    // migration
    if (!data) {
        data = {
            location: levels.user.episode,
            progress: levels.user.level
        };
    }

    this.updateData(data);
};

Hose.LEVELS_PER_LOCATION = 15;