/**
 * Created by vlad on 12.12.18.
 */

var DirtyHoneyDecoratorCellView = BaseCellView.extend({
    ctor: function (cell) {
        this._super();

        this.setContentSize2(cleverapps.styles.BaseCellView.CELL_SIZE_PX, cleverapps.styles.BaseCellView.CELL_SIZE_PX);
        this.setAnchorPoint(0.5, 0.5);

        this.initialize(cell);

        if (cell.isForGoal) {
            var styles = cleverapps.styles.DirtyHoneyDecoratorCellView;
            this.setContentSize2(styles.width, styles.height);
        }

        var image = this.image = new cc.Sprite(bundles.dirty_honey.frames.dirty_honey_decorator);
        image.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(image);
    },

    initialize: function (cell) {
        this._super(cell);

        cell.onCreateNew = this.animateCreateNew.bind(this);
    },

    animateCreateNew: function () {
        this.image.visible = false;
        BaseAnimation.runAnimate(this, bundles.dirty_honey.jsons.dirty_honey_json, "top_on", function () {
            this.image.visible = true;
        }.bind(this), this.image);
        cleverapps.audio.playSound(cleverapps.styles.DirtyHoneyDecoratorCellView.growUpSound);
    },

    animateExplode: function () {
        this.setLocalZOrder(11);
        BaseAnimation.runAnimate(this, bundles.dirty_honey.jsons.dirty_honey_json, "top_off", this.image);
        cleverapps.audio.playSound(cleverapps.styles.DirtyHoneyDecoratorCellView.dieSound);
    }
});

cleverapps.styles.DirtyHoneyDecoratorCellView = {
    width: 120,
    height: 120,
    growUpSound: bundles.dirty_honey.urls.dirty_honey_create_effect,
    dieSound: bundles.game.urls.special_element_die
};