/**
 * Created by vladislav on 1/26/2024
 */

var TonLogic = function () {
    PlatformLogic.call(this);
};

TonLogic.prototype = Object.create(PlatformLogic.prototype);
TonLogic.prototype.constructor = TonLogic;

TonLogic.prototype.reportScore = function (score, callback) {
    connector.platform.callNative("setScore", { value: score, isForce: false }, callback);
};
