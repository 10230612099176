/**
 * Created by vladislav on 30.12.2019
 */

cleverapps.SkinManager = function () {
    this.active = [];

    this.defaultSlots = cleverapps.SkinManager.SKINS.default.slots;
};

cleverapps.SkinManager.prototype.getLink = function (link) {
    if (!this.active.length) {
        return link;
    }

    for (var i = 0; i < this.active.length; i++) {
        var active = this.active[i].slots;
        var skin = active && active.skinName && active.skinName();
        var url = skin && this.overrides[skin] && this.overrides[skin][link];
        if (url) {
            return url;
        }
    }

    return link;
};

cleverapps.SkinManager.prototype.getSlot = function (slotName, options) {
    for (var i = 0; i < this.active.length; i++) {
        if (this.active[i] && this.active[i].slots[slotName]) {
            var slot = this.active[i].slots[slotName];
            var skinned = typeof slot === "function" ? slot(options) : slot;

            if (skinned !== undefined) {
                return skinned;
            }
        }
    }

    return this.defaultSlots[slotName] && this.defaultSlots[slotName](options);
};

cleverapps.SkinManager.prototype.update = function () {
    this.active = [];

    if (cleverapps.config.type === "merge") {
        var expedition = cleverapps.travelBook.getCurrentExpedition();

        if (expedition && cleverapps.SkinManager.SKINS[expedition.id + "_expedition"]) {
            this.active.push(cleverapps.SkinManager.SKINS[expedition.id + "_expedition"]);
        }
        if (cleverapps.eventManager && cleverapps.eventManager.isActive("xmas_skin")) {
            this.active.push(cleverapps.SkinManager.SKINS.xmas);
        }
        if (cleverapps.eventManager && cleverapps.eventManager.isActive("dragonia")) {
            this.active.push(cleverapps.SkinManager.SKINS.dragonia);
        }
        if (cleverapps.eventManager && cleverapps.eventManager.isActive("dragonia2")) {
            this.active.push(cleverapps.SkinManager.SKINS.dragonia2);
        }
        if (cleverapps.eventManager && cleverapps.eventManager.isActive("undersea")) {
            this.active.push(cleverapps.SkinManager.SKINS.undersea);
        }
        if (cleverapps.eventManager && cleverapps.eventManager.isActive("undersea2")) {
            this.active.push(cleverapps.SkinManager.SKINS.undersea2);
        }

        return;
    }

    if (!cleverapps.eventManager) {
        return;
    }

    cleverapps.eventManager.listCurrentFeatures().forEach(function (feature) {
        if (cleverapps.SkinManager.SKINS[feature]) {
            this.active.push(cleverapps.SkinManager.SKINS[feature]);
        }
    }, this);
};

cleverapps.SkinManager.prototype.getBundles = function () {
    var bundles = [];
    this.active.forEach(function (skin) {
        if (skin && skin.bundle) {
            bundles.push(skin.bundle);
        }
    });

    return bundles.length > 0 ? bundles : undefined;
};

cleverapps.SkinManager.SKINS = {
    default: {
        slots: {
            skinName: function () {
                if (!cleverapps.travelBook.isExpedition()) {
                    return undefined;
                }
                return bundles["location_" + cleverapps.travelBook.getCurrentPage().id].meta.units;
            },
            windowDecors: function (options) {
                return options.decors;
            },
            windowBg: function (options) {
                return options.image;
            },
            spine: function (options) {
                return options.json;
            },
            menuBarItem: function () {},
            controlButton: function () {},
            gameAudio: function () {},
            missionIcon: function (options) {
                var icon = Missions[options.type].collectIcon;
                return typeof icon === "function" ? icon() : icon;
            },
            missionWindowHero: function () {
                return cleverapps.styles.MissionWindow.heroTitleAnimation;
            },
            tulpanScale: function () {
                return cleverapps.styles.TulpanCellView.scale;
            },
            removeMissions: function () {
            },
            toolbarDecors: function () {
                return [];
            },
            localization: function (code) {
                return cleverapps.SkinManager.SKINS.default.localization[code];
            },
            windowTitle: function (options) {
                return options.image;
            },
            missionMarkIcon: function (options) {
                return Missions[options.type].collectIcon;
            },
            missionWindowTitleFont: function () {
                return cleverapps.styles.FONTS.WINDOW_TITLE_TEXT;
            },
            cardMarkSpine: function (options) {
                return options.json;
            },
            water: function () {
                var tilesTexture = Map2d.currentMap && Map2d.currentMap.tilesTexture;
                return bundles[tilesTexture] && bundles[tilesTexture].frames.water;
            },
            unitIcon: function (unit) {
                return bundles.unit_icons.frames["small_icon_castle_" + unit.code];
            },
            fog: function () {
                return {};
            },
            outOfBoundaryScale: function () {
                return 0.2;
            },
            whirlwind: function () {
                return bundles.fog_animations.jsons.whirlwind;
            },
            hasChessLayer: function () {
                return cleverapps.config.name === "hustlemerge";
            },
            getPoiAnimation: function () {
                return undefined;
            },
            pixelsBundle: function () {
                return "pixels";
            },
            sellerPerson: function () {
                return "seller";
            }
        }
    }
};