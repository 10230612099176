/**
 * Created by iamso on 09.08.19.
 */

var PassLevelReward = cleverapps.Layout.extend({
    ctor: function (parent, scene) {
        this._super([], {});
        this.game = Game.currentGame;
        this.occupiedSlots = 0;
        this.scene = scene;
        this.createSlots();

        parent.addChild(this);
        this.updateSize();
        this.setItems(this.slots, true);
        this.setupChildren();
        this.setLocalZOrder(15);
    },

    setupChildren: function () {
        var overlap = this.checkGameFieldOverlap(this.getParent());
        var position = cleverapps.styles.PassLevelReward.positions[overlap ? cleverapps.WideMode.SQUARE : cleverapps.resolution.mode];
        this.currentPosition = position;
        this.setPositionRound(position);
    },

    updateSize: function () {
        var overlap = this.checkGameFieldOverlap(this.getParent());
        var direction = cleverapps.UI.HORIZONTAL;
        if (cleverapps.resolution.mode === cleverapps.WideMode.SQUARE || overlap) {
            direction = cleverapps.UI.VERTICAL;
        }
        this.slots.forEach(function (slot) {
            slot.setPositionRound(0, 0);
        });
        this.setOptions({ direction: direction });
    },

    checkGameFieldOverlap: function (parent) {
        if (cleverapps.resolution.mode !== cleverapps.WideMode.VERTICAL) {
            return false;
        }

        var dummy = new cc.Node();
        dummy.setAnchorPoint(0.5, 0.5);
        var slotSize = cleverapps.styles.PassLevelReward.slot;
        dummy.setContentSize2(slotSize.width * PassLevelReward.SLOTS, slotSize.height);

        var position = cleverapps.styles.PassLevelReward.positions[cleverapps.WideMode.VERTICAL];
        dummy.setPositionRound(position);
        parent.addChild(dummy);

        return this.overlapsWithField(this.game.field.onGetView(), dummy);
    },

    overlapsWithField: function (fieldView, node) {
        node.replaceParentSamePlace(fieldView.parent);
        return cc.rectIntersectsRect(fieldView.getBoundingBox(), node.getBoundingBox());
    },

    createSlots: function () {
        var styles = cleverapps.styles.PassLevelReward;
        this.slots = [];
        for (var i = 0; i < PassLevelReward.SLOTS; i++) {
            var slot = new cc.Node();
            slot.setAnchorPoint(0.5, 0.5);
            slot.setContentSize2(styles.slot);
            this.slots.push(slot);
        }
    },

    createReward: function (type) {
        var styles = cleverapps.styles.PassLevelReward;

        var icon = this.getIcon(type);

        var reward = new cc.Node();
        reward.setAnchorPoint(0.5, 0.5);
        reward.setContentSize2(icon.width * icon.scale, icon.height * icon.scale);
        reward.addChild(icon);
        icon.setPosition(reward.width / 2, reward.height / 2);

        var amount = cleverapps.UI.generateImageText(this.getAmount(type), cleverapps.styles.FONTS.PASS_LEVEL_REWARD);
        amount.setVisible(false);
        amount.baseScale = amount.getScale();
        reward.addChild(amount);
        amount.setPositionRound(styles.prize.text);

        reward.icon = icon;
        reward.amount = amount;

        return reward;
    },

    addPrize: function (type) {
        var styles = cleverapps.styles.PassLevelReward;
        if (this.game && this.occupiedSlots < this.slots.length) {
            var reward = this.createReward(type);
            reward.icon.setScale(styles.prize.icon.scale[type]);
            reward.setScale(0);

            var slot = this.slots[this.occupiedSlots];
            slot.type = type;
            this.occupiedSlots++;
            slot.reward = reward;
            slot.addChild(reward);
            reward.setPositionRound({ x: { align: "center" }, y: { align: "center" } });

            cleverapps.tooltipManager.create(slot, {
                text: Messages.get("PassLevelRewardTooltip"),
                location: this.currentPosition.y.align === "top" ? cleverapps.UI.Tooltip.LOCATION_BELOW : cleverapps.UI.Tooltip.LOCATION_ABOVE
            });

            var delay = styles.delay[type];
            reward.runAction(new cc.Sequence(
                new cc.DelayTime(delay),
                new cc.Spawn(
                    new cc.ScaleTo(0.4, 1).easing(cc.easeBackOut()),
                    new cc.Sequence(
                        new cc.RotateTo(0.1, 10),
                        new cc.RotateTo(0.1, -20),
                        new cc.RotateTo(0.1, 10),
                        new cc.RotateTo(0.1, 0)
                    )
                )
            ));

            reward.amount.runAction(new cc.Sequence(
                new cc.DelayTime(delay + 0.3),
                new cc.CallFunc(function () {
                    reward.amount.setVisible(true);
                }),
                new cc.ScaleTo(0.2, reward.amount.baseScale)
            ));
        }
    },

    getIcon: function (type) {
        var image;
        var scale = 1;
        switch (type) {
            case PassLevelReward.BOOSTER:
                image = [bundles.reward_icons.frames.cellbooster, bundles.reward_icons.frames.linebooster, bundles.reward_icons.frames.colorbooster][Object.keys(this.game.rewards.boosters)[0]];
                break;
        }
        var icon = new cc.Sprite(image);
        icon.setScale(scale);
        return icon;
    },

    getAmount: function (type) {
        var amount;
        switch (type) {
            case PassLevelReward.BOOSTER:
                amount = 1;
                break;
        }
        return amount > 1 ? "" + amount : "";
    }
});

PassLevelReward.BOOSTER = "b";

PassLevelReward.SLOTS = 3;

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    PASS_LEVEL_REWARD: {
        name: "default",
        size: 36
    }
});

cleverapps.styles.PassLevelReward = {
    enabled: true,
    width: 100,
    height: 300,
    delay: {},

    positions: [
        { x: { align: "right", dx: -20 }, y: { align: "bottom", dy: -70 } },
        { x: { align: "right", dx: 100 }, y: { align: "top", dy: -30 } },
        { x: { align: "center", dx: 10 }, y: { align: "bottom", dy: -75 } }
    ],

    slot: {
        width: 65,
        height: 80
    },

    prize: {
        margin: 0,

        icon: {
            scale: {}
        },

        text: {
            x: { align: "right", dx: 0 },
            y: { align: "bottom", dy: 5 }
        }
    }
};

cleverapps.styles.PassLevelReward.delay[PassLevelReward.BOOSTER] = 1.7;
cleverapps.styles.PassLevelReward.prize.icon.scale[PassLevelReward.BOOSTER] = 0.45;
