/**
 * Created by ivan on 11.01.18.
 */

var GingerHouseCellView = BaseCellView.extend({
    ctor: function (gingerHouseCell) {
        this._super();

        var bigComponent = gingerHouseCell.findComponent(BigComponent);
        if (!bigComponent.isHead) {
            return;
        }

        gingerHouseCell.onCollectPartListener = this.collectPart.bind(this);

        var styles = cleverapps.styles.GingerHouseCellView;
        this.setContentSize2(styles.width, styles.height);
        this.setAnchorPoint(0.5, 0.5);

        if (!gingerHouseCell.isForGoal) {
            this.image = new cc.Sprite(bundles.gingerbread_house.frames.ginger_house_slot_png);
            this.image.setPosition(this.width / 2, this.height / 2);
            this.addChild(this.image);

            this.partsImages = {};
            this.partsImages[GingerHousePartCell.TYPES.WALL] = new cc.Sprite(bundles.gingerbread_house.frames.ginger_house_wall_png);
            this.partsImages[GingerHousePartCell.TYPES.DOOR] = new cc.Sprite(bundles.gingerbread_house.frames.ginger_house_door_png);
            this.partsImages[GingerHousePartCell.TYPES.WINDOW] = new cc.Sprite(bundles.gingerbread_house.frames.ginger_house_window_png);
            this.partsImages[GingerHousePartCell.TYPES.ROOF] = new cc.Sprite(bundles.gingerbread_house.frames.ginger_house_roof_png);

            for (var key in this.partsImages) {
                var partImage = this.partsImages[key];
                var offset = styles.imageOffsets && styles.imageOffsets[key] || { x: 0, y: 0 };
                partImage.setVisible(gingerHouseCell.collectedParts.indexOf(parseInt(key)) !== -1);
                partImage.setPosition(this.width / 2 + offset.x, this.height / 2 + offset.y);
                this.addChild(partImage);
            }
        } else {
            this.setContentSize(styles.goal.width, styles.goal.height);

            this.image = new cc.Sprite(bundles.gingerbread_house.frames.ginger_house_goal_png);
            this.image.setScale(0.8);
            this.image.setPosition(this.width / 2, this.height / 2);
            this.addChild(this.image);
        }

        this.initialize(gingerHouseCell);
        this.setPosition(BaseCellView.alignInTheGrid(gingerHouseCell.x + 0.5, gingerHouseCell.y + 0.5));
    },

    startExploding: function () {
        BaseCellView.prototype.startExplodingViaCollect.apply(this, arguments);
    },

    unuse: function () {
        this.cell.onCollectPartListener = function () {};

        this._super();
    },

    getPartStyles: function (partType) {
        switch (partType) {
            case GingerHousePartCell.TYPES.DOOR:
                return cleverapps.styles.GingerHouseCellView.door;
            case GingerHousePartCell.TYPES.ROOF:
                return cleverapps.styles.GingerHouseCellView.roof;
            case GingerHousePartCell.TYPES.WINDOW:
                return cleverapps.styles.GingerHouseCellView.window;
            case GingerHousePartCell.TYPES.WALL:
                return cleverapps.styles.GingerHouseCellView.wall;
        }

        return undefined;
    },

    getCollectFlyPoints: function (partView) {
        var targetPosition = this.getPosition();

        var partStyles = this.getPartStyles(partView.cell.type);
        targetPosition.x += partStyles.x;
        targetPosition.y += partStyles.y;

        var bezierFirstPoint, bezierSecondPoint;
        var styles = cleverapps.styles.GingerHouseCellView;

        bezierSecondPoint = cc.p(targetPosition.x, targetPosition.y + styles.collect.y);
        bezierFirstPoint = cc.p((partView.x + bezierSecondPoint.x) / 2, (partView.y + bezierSecondPoint.y) / 2);

        return [bezierFirstPoint, bezierSecondPoint, targetPosition];
    },

    collectPart: function (partCell, flyDuration) {
        var partView = partCell.view;

        partView.stopAllActions();
        partView.setLocalZOrder(10);

        cleverapps.audio.playSound(bundles.game.urls.cell_collect_effect, {
            throttle: 200
        });

        var bezierPoints = this.getCollectFlyPoints(partView);
        var styles = cleverapps.styles.GingerHouseCellView;

        var partStyles = this.getPartStyles(partCell.type);
        var targetScaleX = partStyles.scaleX;
        var targetScaleY = partStyles.scaleY;

        flyDuration = flyDuration - Goals.UP_DURATION - Goals.WAIT_DURATION;
        partView.runAction(new cc.Sequence(
            new cc.MoveBy(Goals.UP_DURATION, 0, styles.collect.up),
            new cc.DelayTime(Goals.WAIT_DURATION),
            new cc.Spawn(
                new cc.BezierTo(flyDuration, bezierPoints).easing(cc.easeIn(1)),
                new cc.ScaleTo(flyDuration, targetScaleX, targetScaleY)
            ),
            new cc.CallFunc(function () {
                cleverapps.audio.playSound(bundles.game.urls.goal_inc_effect, {
                    throttle: 100
                });
                this.partsImages[partCell.type].setVisible(true);
                if (partStyles.zOrder !== undefined) {
                    this.partsImages[partCell.type].setLocalZOrder(partStyles.zOrder);
                }

                partView.delete();
            }.bind(this))
        ));
    }
});

cleverapps.styles.GingerHouseCellView = {
    width: 240,
    height: 240,

    goal: {
        width: 120,
        height: 120
    },

    smile: {
        active: true
    },

    collect: {
        y: 300,
        up: 30
    },

    door: {
        x: -42,
        y: -68,
        scaleX: 0.95,
        scaleY: 0.95
    },

    window: {
        x: 46,
        y: -58,
        scaleX: 0.85,
        scaleY: 0.85
    },

    roof: {
        x: -1,
        y: 36,
        scaleX: 2.1,
        scaleY: 1.7
    },

    wall: {
        x: 0,
        y: -40,
        scaleX: 1.9,
        scaleY: 1.5
    },

    imageOffsets: {}
};