/**
 * Created by Denis "dalorveen" Kuzin on 02 november 2020
 */

var AdminQuickNavigation = {
    episodeNo: undefined,
    levelNo: undefined,
    suggestions: [],

    go: function (f) {
        var ep = AdminQuickNavigation.episodeNo;
        var lvl = AdminQuickNavigation.levelNo;
        AdminQuickNavigation.episodeNo = undefined;
        AdminQuickNavigation.levelNo = undefined;

        if (isNaN(ep) || isNaN(lvl)) {
            f();
            return;
        }

        var episode = cleverapps.administrator.adminEpisodes.episodes.filter(function (episode) {
            return episode.episodeNo === ep;
        })[0];

        if (!episode) {
            f();
            return;
        }

        cleverapps.administrator.adminEpisodes.setActive(episode, function () {
            var level = cleverapps.administrator.adminLevels.levels[lvl];
            cleverapps.administrator.adminLevels.setActive(level);
            cleverapps.administrator.adminEpisodes.scrollToEp(ep);
            f();
        });
    },

    isMostlyDigits: function (text) {
        if (text.length === 0) {
            return false;
        }

        var numDigits = 0;
        for (var i = 0; i < text.length; i++) {
            if (text[i] >= "0" && text[i] <= "9") {
                numDigits++;
            }
        }

        return numDigits > 0 && numDigits >= text.length - 1;
    },

    handle: function (text, callback) {
        AdminQuickNavigation.suggestions = [];

        if (!this.isMostlyDigits(text) && cleverapps.config.type === "board") {
            this.handleText(text, callback);
        } else {
            callback(this.handleNumber(text));
        }
    },

    handleText: function (text, callback) {
        cleverapps.RestClient.get("/admin/search/levelword/" + text, {}, function (data) {
            AdminQuickNavigation.suggestions = data || [];
            callback(data.length > 0);
        }, function (err) {
            cleverapps.notification.create("Search level error");
            console.log("Search error", err);
            callback(false);
        });
    },

    handleNumber: function (text) {
        var levelNo = parseInt(text);
        var levelNoAsText = "" + levelNo;

        if (text === levelNoAsText) {
            this.setHumanReadableNumber(levelNo);
        } else {
            var remaining = text.replace(levelNoAsText, "");
            this.set(levelNoAsText, parseInt(remaining) || 0);
        }
        return true;
    },

    setHumanReadableNumber: function (levelNo) {
        this.set(Math.floor((levelNo - 1) / AdminEpisode.LEVELS_PER_EPISODE), (levelNo - 1) % AdminEpisode.LEVELS_PER_EPISODE);
    },

    set: function (ep, lvl) {
        AdminQuickNavigation.episodeNo = this.checkEp(ep);
        AdminQuickNavigation.levelNo = this.checkLvl(lvl);
    },

    checkLvl: function (lvl) {
        return Math.max(0, Math.min(lvl, cleverapps.administrator.adminLevels.levels.length - 2));
    },

    checkEp: function (ep) {
        return Math.max(0, Math.min(ep, cleverapps.administrator.adminEpisodes.total - 1));
    }
};