/**
 * Created by vlad on 11.12.18.
 */

var DirtyHoneyTileView = cc.Node.extend(Object.assign({}, BaseTileView, {
    ctor: function (tile) {
        this._super();

        this.setAnchorPoint(0.5, 0.5);
        this.initialize(tile);

        var image = this.image = new cc.Sprite(bundles.dirty_honey.frames.dirty_honey_tile);
        this.addChild(image);
        image.setPositionRound(this.width / 2, this.height / 2);
        if (tile.x < 0) {
            image.setScale(0.8);
        }
    },
    initialize: function (tile) {
        BaseTileView.initialize.call(this, tile);

        this.tile.onCreateNew = this.animateCreateNew.bind(this);
    },
    animateCreateNew: function () {
        this.setScale(1);
        this.image.visible = false;

        BaseAnimation.runAnimate(this, bundles.dirty_honey.jsons.dirty_honey_json, "under_on", function () {
            this.image.visible = true;
        }.bind(this), this.image);

        cleverapps.audio.playSound(cleverapps.styles.DirtyHoneyTileView.growUpSound);
    },
    startExploding: function () {
        this.stopAllActions();
        BaseAnimation.runAnimate(this, bundles.dirty_honey.jsons.dirty_honey_json, "under_off", this.image);
        cleverapps.audio.playSound(cleverapps.styles.DirtyHoneyTileView.dieSound);
    }
}));

cleverapps.styles.DirtyHoneyTileView = {
    growUpSound: bundles.dirty_honey.urls.dirty_honey_create_effect,
    dieSound: bundles.game.urls.special_element_die
};