/**
 * Created by razial on 15.04.2024.
 */

Mission.TYPE_TREASURE_SEARCH = 7;

Missions.FEATURES[Mission.TYPE_TREASURE_SEARCH] = function () {
    Missions[Mission.TYPE_TREASURE_SEARCH] = {
        available: {
            level: 4.93
        },
        abTest: cleverapps.ABTest.TSEARCH,
        name: "tsearch",
        view: TreasureSearchWindow,
        minigame: {
            price: 1
        },
        teaser: true,
        startWindow: {
            name: "tsearchGuideWindow",
            bundle: bundles.tsearchguidewindow
        },
        nudgeWindow: TreasureSearchWindow,
        duration: "3 days",
        semaphore: Missions.SEMAPHORE_PRIMARY,
        bundle: "treasuresearch",
        sideBarJson: bundles.sidebar.jsons.treasure_search_icon_json,
        logic: TreasureSearchMissionLogic,
        startCurrency: 3
    };
};
