/**
 * Created by Oleg on 05.02.19.
 */

var Barriers = function () {
    // It is not saved in field.floor, it is saved in field.barriers,
    // because of necessity of putting ice, etc. on such cells too, not only barriers
};

Barriers.prototype.clear = function () {
    if (this.isDownBarrier) {
        for (var i = 0; i < this.isDownBarrier.length; i++) {
            for (var j = 0; j < this.isDownBarrier[i].length; j++) {
                this.isDownBarrier[i][j] = 0;
            }
        }
    }
    if (this.isRightBarrier) {
        for (i = 0; i < this.isRightBarrier.length; i++) {
            for (j = 0; j < this.isRightBarrier[i].length; j++) {
                this.isRightBarrier[i][j] = 0;
            }
        }
    }
};

// ### For EDITOR only ###
Barriers.prototype.empty = function () {
    // returns true if no barriers on field

    if (!this.isDownBarrier || !this.isRightBarrier) {
        return true;
    }

    for (var i = 0; i < this.isDownBarrier.length; i++) {
        for (var j = 0; j < this.isDownBarrier[i].length; j++) {
            if (this.isDownBarrier[i][j]) {
                return false;
            }
        }
    }
    for (i = 0; i < this.isRightBarrier.length; i++) {
        for (j = 0; j < this.isRightBarrier[i].length; j++) {
            if (this.isRightBarrier[i][j]) {
                return false;
            }
        }
    }
    return true;
};

Barriers.prototype.save = function () {
    var saveData = [[], []];
    if (this.isDownBarrier) {
        for (var i = 0; i < this.isDownBarrier.length; i++) {
            for (var j = 0; j < this.isDownBarrier[i].length; j++) {
                if (this.isDownBarrier[i][j]) {
                    saveData[0].push([i, j]);
                }
            }
        }
    }
    if (this.isRightBarrier) {
        for (i = 0; i < this.isRightBarrier.length; i++) {
            for (j = 0; j < this.isRightBarrier[i].length; j++) {
                if (this.isRightBarrier[i][j]) {
                    saveData[1].push([i, j]);
                }
            }
        }
    }
    return saveData;
};

Barriers.prototype.load = function (data) {
    this.isDownBarrier = [];
    this.isRightBarrier = [];
    for (var i = 0; i < Field.SIZE - 1; i++) {
        var row = [];
        for (var j = 0; j < Field.SIZE - 1; j++) {
            row.push(0);
        }
        this.isRightBarrier.push(row.slice());
        row.push(0);
        this.isDownBarrier.push(row);
    }
    this.isRightBarrier.push(this.isRightBarrier[0].slice());

    if (!data) { // to work on levels without barriers
        return;
    }

    // verify data format
    if (!Array.isArray(data)) { // wrong data format (for compatibility with empty old barriers array)
        return;
    }
    if (data.length !== 2) { // wrong data format (for compatibility with empty old barriers array)
        return;
    }
    if ((!Array.isArray(data[0])) || (!Array.isArray(data[1]))) { // wrong data format (for compatibility with empty old barriers array)
        return;
    }

    for (i = 0; i < data[0].length; i++) {
        if (data[0][i][0] >= 0 && data[0][i][0] < this.isDownBarrier.length
            && data[0][i][1] >= 0 && data[0][i][1] < this.isDownBarrier[data[0][i][0]].length) {
            this.isDownBarrier[data[0][i][0]][data[0][i][1]] = 1;
        }
    }
    for (i = 0; i < data[1].length; i++) {
        if (data[1][i][0] >= 0 && data[1][i][0] < this.isRightBarrier.length
            && data[1][i][1] >= 0 && data[1][i][1] < this.isRightBarrier[data[1][i][0]].length) {
            this.isRightBarrier[data[1][i][0]][data[1][i][1]] = 1;
        }
    }
};

Barriers.prototype.canMove = function (row, col, newRow, newCol) {
    if (!this.isDownBarrier || !this.isRightBarrier) {
        return true;
    }

    if (row !== newRow) { // Move up/down
        var x = row, y = col;
        if (newRow < row) { // Move up (change the check point)
            x = newRow;
        }
        if (x < 0 || x >= this.isDownBarrier.length || y < 0 || y >= this.isDownBarrier[0].length) { // Move out from field check
            return 0;
        }
        return !this.isDownBarrier[x][y];
    }
    // Move left/right
    x = row;
    y = col;
    if (newCol < col) { // Move left (change the check point)
        y = newCol;
    }
    if (x < 0 || x >= this.isRightBarrier.length || y < 0 || y >= this.isRightBarrier[0].length) { // Move out from field check
        return 0;
    }
    return !this.isRightBarrier[x][y];
};
