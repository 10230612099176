/**
 * Created by Andrey Popov on 20.01.2021.
 */

var Prefab = cc.Node.extend({
    ctor: function (prefabConfig, options) {
        this._super();

        this.options = options;

        this.prefabConfig = this.movingNode = prefabConfig;
        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize(options.size);

        this.setLocalZOrder(BaseWindow.WINDOWS_ZORDER + 100);

        this.eventBus = new cleverapps.EventEmitter();

        this.componentViews = {};

        var underGameLayer = this.underGameLayer = new cc.Node();
        underGameLayer.setAnchorPoint(0.5, 0.5);
        underGameLayer.setContentSize(this.getContentSize());

        var snapshotComponent = this.prefabConfig.components.filter(function (component) {
            return component.properties.isGame;
        })[0];
        var otherComponents = this.prefabConfig.components.filter(function (component) {
            return !component.properties.isGame;
        });
        
        if (snapshotComponent) {
            this.createComponents([snapshotComponent], cleverapps.scenes.getRunningScene());
            this.snapshotComponent.init();
        } else {
            cleverapps.scenes.getRunningScene().removeAllChildren();
        }

        this.addBackground();
        this.createComponents(otherComponents, this);

        if (!this.options.preview) {
            this.eventBus.trigger(Wysiwyg.EVENTS.SCENE_STARTED, this.options.size);
        } else {
            this.eventBus.trigger(Wysiwyg.EVENTS.PREVIEW, this.options.size);
        }

        if (this.options.onInit) {
            this.options.onInit();
        }

        if (options.onComplete) {
            this.eventBus.on(Wysiwyg.EVENTS.SCENE_COMPLETED, options.onComplete, this);
        }

        if (!this.snapshotComponent && prefabConfig.properties.duration) {
            setTimeout(options.onComplete, prefabConfig.properties.duration);
        }

        var el = document.getElementById("safeAreaInsets");
        if (el) {
            el.style.display = "none";
        }
    },

    createComponents: function (components, parent) {
        components.forEach(function (component) {
            if (component.properties.showOnEvent && component.properties.showOnEvent !== Wysiwyg.NOT_SELECTED && !this.options.preview) {
                this.eventBus.on(component.properties.showOnEvent, function () {
                    setTimeout(this.addNestedComponent.bind(this, component, parent), component.properties.delay);
                }.bind(this));
            } else if (component.properties.delay && !this.options.preview) {
                setTimeout(this.addNestedComponent.bind(this, component, parent), component.properties.delay);
            } else {
                this.addNestedComponent(component, parent);
            }
        }.bind(this));
    },

    addNestedComponent: function (component, parent) {
        component.properties.isPreview = this.options.preview;
        var componentView = new SceneComponentView(component, this.eventBus, parent);
        if (component.properties.isGame) {
            this.snapshotComponent = componentView.componentInstance;
        }

        this.componentViews[component.id] = componentView;
        this.createComponents(component.components, componentView);
        parent.addChild(componentView);

        if (parent === this && this.shouldBeUnderGameLayer(component)) {
            componentView.replaceParentSamePlace(this.underGameLayer, { keepScale: true });
        }
        if (parent === this.underGameLayer && !this.shouldBeUnderGameLayer(component)) {
            componentView.replaceParentSamePlace(this, { keepScale: true });
        }

        componentView.onComponentSizeChanged();

        component.scripts.forEach(function (script) {
            if (component.properties.isPreview && !script.activeOnPreview) {
                return;
            }
            Prefab.executeComponentScript(script, componentView.componentInstance, this.eventBus);
        }.bind(this));

        if (parent.componentInstance) {
            parent.componentInstance.nestedComponents.push(componentView.componentInstance);
        }

        return componentView;
    },

    shouldBeUnderGameLayer: function (component) {
        if (!this.snapshotComponent || this.snapshotComponent.properties.zOrder === undefined || component.properties.zOrder === undefined) {
            return false;
        }

        return component.properties.zOrder < this.snapshotComponent.properties.zOrder;
    },

    addBackground: function () {
        var bgProperties = Wysiwyg.getValueForResolution(this.prefabConfig.properties.bg);
        if (bgProperties && bgProperties.name && bgProperties.folder && cleverapps.wysiwyg.googleDriveProvider.hasAsset(bgProperties)) {
            var imagePath = cleverapps.wysiwyg.googleDriveProvider.getLink(bgProperties);
            var bg = this.bg = new cc.Sprite(imagePath);
            bg.setTag("clipBg");

            if (bg.width === 0 || bg.height === 0) {
                bg.addLoadedEventListener(this.setBackgroundScale.bind(this));
            } else {
                this.setBackgroundScale();
            }

            if (this.prefabConfig.properties.bgPosition) {
                bg.setPositionRound(Wysiwyg.getValueForResolution(this.prefabConfig.properties.bgPosition));
            } else {
                bg.setPositionRound(this.underGameLayer.width / 2, this.underGameLayer.height / 2);
            }
            this.underGameLayer.addChild(bg);
        }
    },

    setBackgroundScale: function () {
        if (!this.bg) {
            return;
        }

        this.bg.setScale(Math.max(this.width / this.bg.width, this.height / this.bg.height, 1));
    },

    redrawComponent: function (item) {
        if (!this.componentViews[item.id]) {
            return;
        }

        var parent = this.componentViews[item.id].getParent();
        this.componentViews[item.id].removeFromParent();

        var searchComponent = function (componentToSearch, componentId) {
            var searchResult = componentToSearch.components.filter(function (component) {
                return component.id === componentId;
            });

            if (searchResult.length > 0) {
                return searchResult[0];
            }
            for (var i = 0; i < componentToSearch.components.length; i++) {
                searchResult = searchComponent(componentToSearch.components[i], componentId);
                if (searchResult) {
                    return searchResult;
                }
            }

            return undefined;
        };

        var component = searchComponent(this.prefabConfig, item.id);
        HierarchyItem.processProperties(item.properties);

        component.properties = item.properties;
        this.addNestedComponent(component, parent);

        if (component.properties.isGame) {
            this.snapshotComponent.init();
        }

        this.componentViews[item.id] && this.componentViews[item.id].onComponentSizeChanged();
    },

    attachToScene: function () {
        var scene = cleverapps.scenes.getRunningScene();

        this.setPositionRound(scene.width / 2 - scene.x / scene.scale, scene.height / 2 - scene.y / scene.scale);
        this.underGameLayer.setPositionRound(scene.width / 2 - scene.x / scene.scale, scene.height / 2 - scene.y / scene.scale);

        this.setScale(scene.width / (this.width * scene.scale));
        this.underGameLayer.setScale(scene.width / (this.width * scene.scale));
        this.setBackgroundScale();

        scene.addChild(this);
        scene.addChild(this.underGameLayer, -10);

        Object.keys(this.componentViews).forEach(function (key) {
            this.componentViews[key].onComponentSizeChanged();
        }.bind(this));

        scene.setupChildren();
        scene.onResize();
    },

    destroy: function () {
        Object.keys(this.componentViews).forEach(function (key) {
            this.componentViews[key].removeFromParent();
        }.bind(this));
        this.componentViews = {};

        if (this.underGameLayer) {
            this.underGameLayer.removeFromParent();
            delete this.underGameLayer;
        }

        this.eventBus.purge();
    }
});

Prefab.executeComponentScript = function (script, component, eventBus) {
    var parameters = {};
    script.scriptParameters.split("\n").forEach(function (parameter) {
        var parameterName = parameter.split(":")[0];
        parameters[parameterName] = script[parameterName];
    });
    // eslint-disable-next-line no-new-func
    var scriptFunction = Function("component", "parameters", "eventBus", script.scriptBody);
    scriptFunction(component, parameters, eventBus);
};

cleverapps.styles.Prefab = {
    scroll: {
        padding: 5
    }
};