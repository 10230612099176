/**
 * Created by Vladislav on 25.09.2024.
 */

Placements.ENTRIES["unlimitedLivesWindow"] = {
    type: Placements.FREE_FOCUS,
    priority: 0,

    filter: function () {
        return cleverapps.environment.hasScene([cleverapps.Environment.SCENE_MAIN, cleverapps.Environment.SCENE_DAILY_CUP]);
    },

    action: function () {
        cleverapps.unlimitedLives.displayPromotionOnFinish();
    }
};