/**
 * Created by Andrey Popov on 10.11.2021
 */
var AtlasAnalyzer = function (tab) {
    this.bundlesExplorer = new BundlesExplorer(this, tab);
    this.textureImage = new TextureImage(this);
    this.imageSpy = new ImageSpy(this);
};

AtlasAnalyzer.prototype.showUp = function (tab, bundleName, frame) {
    this.bundlesExplorer.trigger("rebuildItems");
    if (tab || bundleName) {
        this.bundlesExplorer.selectItem(tab, bundleName, frame);
    }
};