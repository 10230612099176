/**
 * Created by slava on 02.02.17.
 */

var CrabDecoratorCellView = BaseCellView.extend({
    ctor: function (decoratedCell) {
        this._super();

        this.setContentSize2(cleverapps.styles.BaseCellView.CELL_SIZE_PX, cleverapps.styles.BaseCellView.CELL_SIZE_PX);
        this.setAnchorPoint(0.5, 0.5);

        var bundle = decoratedCell.bundleId();
        var imageRes = bundles[bundle].frames[bundle + decoratedCell.lives];
        if (decoratedCell.isForGoal && bundles[decoratedCell.bundleId()].frames[bundle + "_goal"]) {
            imageRes = bundles[bundle].frames[bundle + "_goal"];
        }
    
        this.image = new cc.Sprite(imageRes);

        var styles = cleverapps.styles.CrabDecoratorCellView.goalImage;
        var dy = 0;
        if (decoratedCell.isForGoal && styles) {
            this.image.setScale(styles.scale || 1);
            dy = styles.dy || 0;
        }
    
        this.image.setPosition(this.width / 2, this.height / 2 + dy);
        this.addChild(this.image);

        this.initialize(decoratedCell);
    },

    initialize: function (decoratedCell) {
        this._super(decoratedCell);

        if (!decoratedCell.isForGoal) {
            this.cell.onCrabMove = this.createListener(this.animateMove.bind(this));
            this.cell.onCrabFinishMove = this.createListener(this.animateFinishMove.bind(this));
            this.cell.onChangeLivesListener = this.createListener(this.changeLives.bind(this));

            if (!cleverapps.Random.random(2) || decoratedCell.createdByCrabMaker) {
                this.image.setScaleX(-1);
            }
        }
    },

    unuse: function () {
        this.cell.onCrabMove = function () {};
        this.cell.onCrabFinishMove = function () {};
        this.cell.onChangeLivesListener = function () {};

        this._super();
    },

    finishSmile: function () {
        if (this.animation) {
            this.animation.finishAnimation();
            this.animation = false;
            this.image.setVisible(true);
        }
    },

    smile: function () {
        if (this.cell.lives < 1 || !this.cell.alive) {
            return;
        }

        if (!this.animation) {
            this.animate("animation" + this.cell.lives);
        }
    },

    animate: function (animate, callback, spriteAction) {
        if (this.cell.isForGoal) {
            return;
        }

        this.finishSmile();
        this.animation = CrabAnimation.factory(this.cell.bundleId(), this.cell.lives);
        this.animation.setPosition(this.width / 2, this.height / 2);
        this.animation.setScaleX(this.image.getScaleX());
        this.addChild(this.animation);

        this.animation.runAnimate(animate, function () {
            delete this.animation;
            this.image.visible = true;
            if (callback) {
                callback();
            }
        }.bind(this), spriteAction, this.image);
    },

    animateExplode: function (callback) {
        this.setLocalZOrder(0);
        this.animate("death", callback, new cc.Spawn(new cc.ScaleTo(0.5, 0), new cc.RotateBy(0.5, 360)));
        var bundle = this.cell.bundleId();
        cleverapps.audio.playSound(bundles[bundle].urls[bundle + "_die_effect"]);
    },

    animateFinishMove: function (moveCell) {
        this.stopAllActions();
        this.setPosition(BaseCellView.alignInTheGrid(moveCell.x, moveCell.y));
    },

    animateMove: function (moveCell, moveTime, dx) {
        if (dx !== 0) {
            this.image.setScaleX(dx);
        }

        this.setLocalZOrder(0);
        this.runAction(new cc.MoveTo(moveTime, BaseCellView.alignInTheGrid(moveCell.x, moveCell.y)));

        this.animate("walk" + this.cell.lives);
        var bundle = this.cell.bundleId();
        cleverapps.audio.playSound(bundles[bundle].urls[bundle + "_move_effect"], { throttle: 200 });
    },

    changeLives: function () {
        if (this.cell.lives !== 1) {
            return;
        }

        this.finishSmile();
        var bundle = this.cell.bundleId();
        var img = bundles[bundle].frames[bundle + "1"];

        this.animate("beat", function () {
            if (this.image.isRunning()) {
                this.image.setSpriteFrame(img);
                this.image.visible = true;
            }
        }.bind(this), this.image);
    }
});

cleverapps.styles.CrabDecoratorCellView = {
};