/**
 * Created by Andrey Popov on 1/21/21.
 */

var AssetComponentView = AssetView.extend({
    ctor: function (assetComponent) {
        this._super(assetComponent);

        var styles = cleverapps.styles.AssetComponentView;

        if (this.asset.preview.icon) {
            var preview = new cc.Sprite(this.asset.preview.icon);
            preview.setPositionRound(styles.preview);
            this.addChild(preview);
        }
    },

    createPreview: function () {
        var framePreview;
        if (bundles.wysiwyg.frames[this.asset.name.toLowerCase() + "_drag"]) {
            framePreview = new cc.Sprite(bundles.wysiwyg.frames[this.asset.name.toLowerCase() + "_drag"]);
        } else {
            var ComponentClass = this.asset.ctor();
            framePreview = new ComponentClass(
                {
                    width: 100, height: 100, isPreview: true, font: cleverapps.styles.FONTS.BUTTON_TEXT
                },
                new cleverapps.EventEmitter(),
                this,
                cleverapps.resolution.getSceneSize()
            );
        }
        framePreview.setPositionRound(this.componentFrame.width / 2, this.componentFrame.height / 2);

        cleverapps.UI.fitToBox(framePreview, {
            width: 100,
            height: 100,
            maxScale: 2
        });

        var shadow = cleverapps.UI.createScale9Sprite(bundles.wysiwyg.frames.window_bg);
        shadow.setAnchorPoint(0.5, 0.5);
        shadow.setColor(cleverapps.styles.COLORS.GRAY_SCALE_COLOR);
        shadow.setOpacity(120);
        shadow.setContentSize(100, 100);
        this.componentFrame.addChild(shadow);

        this.componentFrame.addChild(framePreview);
        this.previewCreated = true;
    }
});

cleverapps.styles.AssetComponentView = {
    preview: {
        x: { align: "center" },
        y: { align: "center", dy: -10 }
    }
};