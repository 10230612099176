/**
 * Created by vladislav on 3/4/19
 */
var MultiColorBombCellComponent = function (view, cell, saveCallback) {
    this.view = view;
    this.cell = cell;
    this.saveCallback = saveCallback;

    view.activeButton = undefined;

    this.createButtons();

    this.createIncDecBlock();
};

MultiColorBombCellComponent.prototype.styles = function () {
    return cleverapps.styles.MultiColorBombCellComponent;
};

MultiColorBombCellComponent.prototype.closeWindow = function () {
    this.view.window.removeFromParent(true);
    this.view.window = undefined;
};

MultiColorBombCellComponent.prototype.createIncDecBlock = function () {
    var incDecBlock = new IncDecBlock({
        value: this.cell.lives,
        range: [1, this.cell.getMaxLives()],
        onDec: function () {
            if (this.cell.lives > 1) {
                this.cell.colors.pop();
                this.cell.lives = this.cell.colors.length;
                this.view.createItems(this.cell);
                this.createButtons();
                this.saveCallback();
            }
        }.bind(this),

        onInc: function () {
            if (this.cell.lives < this.cell.getMaxLives()) {
                var colors = ["a", "b", "c", "d", "e", "w"];
                var chosenColor = colors[0];
                for (var i = 0; i < colors.length; i++) {
                    if (this.cell.colors.indexOf(colors[i]) === -1) {
                        chosenColor = colors[i];
                        break;
                    }
                }
                this.cell.colors.push(chosenColor);
                this.cell.lives = this.cell.colors.length;
                this.view.createItems(this.cell);
                this.createButtons();
                this.saveCallback();
            }
        }.bind(this),

        radius: this.styles().incDecBlock.radius
    });

    incDecBlock.setPosition(this.view.width / 2, this.styles().livesBlock.y);
    incDecBlock.setLocalZOrder(15);
    this.view.addChild(incDecBlock);
};

MultiColorBombCellComponent.prototype.createButtons = function () {
    var styles = this.styles();

    if (this.view.buttons) {
        this.view.buttons.forEach(function (button) {
            button.removeFromParent(true);
        });
    }

    this.view.buttons = [];
    this.view.items.forEach(function (item, index) {
        var button = new cleverapps.UI.Button({
            type: cleverapps.styles.UI.Button.Images.editor,
            text: "*",
            width: styles.colorButtons.width,
            height: styles.colorButtons.height,
            onClicked: function () {
                if (this.view.window) {
                    if (this.view.activeButton === button) {
                        this.closeWindow();
                    } else {
                        this.closeWindow();
                        this.openWindow();
                        this.view.activeButton = button;
                    }
                } else {
                    this.view.activeButton = button;
                    this.openWindow();
                }
            }.bind(this)
        });
        button.setLocalZOrder(11);
        this.view.addChild(button);
        this.positionButton(item, index, button);
        button.itemIndex = index;
        this.view.buttons.push(button);
    }.bind(this));
};

MultiColorBombCellComponent.prototype.positionButton = function (item, index, button) {
    button.setPositionRound(item.view.x, item.view.y + item.view.height / 2 - cleverapps.styles.MultiColorBombCellComponent.margin);
};

MultiColorBombCellComponent.prototype.changeColor = function (itemIndex, color) {
    this.cell.colors[itemIndex] = color;
    this.view.items[itemIndex].color = color;
    this.view.items[itemIndex].view.setSkin(this.view.getSkin(color));
    this.saveCallback();
};

MultiColorBombCellComponent.prototype.openWindow = function () {
    var node = this.view.window = new cc.Node();
    node.setAnchorPoint(0.5, 0.5);
    var bg = this.bg = new cc.Scale9Sprite(new cc.Sprite(bundles.request_center.frames.friend_message_bg_png).getSpriteFrame());
    node.addChild(bg);
    node.setLocalZOrder(11);

    var colors = ["a", "b", "c", "d", "e", "w"];
    var height;
    this.choices = [];
    colors.forEach(function (color) {
        var button = new cleverapps.UI.Button({
            width: cleverapps.styles.MultiColorBombCellComponent.button.width,
            height: cleverapps.styles.MultiColorBombCellComponent.button.height,
            onClicked: function () {
                this.changeColor(this.view.activeButton.itemIndex, color);
                this.closeWindow();
            }.bind(this),
            content: new cc.Sprite(bundles.petards.frames["mclrbmb_" + color])
        });

        this.choices.push({ image: new cc.Sprite(bundles.petards.frames["mclrbmb_" + color]), color: color });
        height = button.height;
        button.setPosition(bg.width / 2, 0);
        bg.addChild(button);
    }.bind(this));

    bg.setContentSize2(bg.width, height * colors.length + (colors.length - 1) * cleverapps.styles.MultiColorBombCellComponent.margin);
    node.setContentSize2(bg.getContentSize());
    bg.setPositionRound(node.width / 2, node.height / 2);

    cleverapps.UI.arrangeWithMargins(bg.children, {
        direction: cleverapps.UI.VERTICAL,
        margin: cleverapps.styles.MultiColorBombCellComponent.margin
    });

    cleverapps.scenes.getRunningScene().addChild(node);
    var globalViewPos = this.view.getParent().convertToWorldSpace(this.view.getPosition());
    node.setPositionRound(globalViewPos.x + this.view.width + node.width, globalViewPos.y);
};

cleverapps.styles.MultiColorBombCellComponent = {
    livesBlock: {
        y: -80
    },
    colorButtons: {
        width: 50,
        height: 50
    },
    incDecBlock: {
        radius: 70
    },
    button: {
        width: 50,
        height: 100
    },
    margin: 10
};