/**
 * Created by Denis Kuzin on 01 august 2024
 */

var EaterComponentView = cc.Node.extend({
    ctor: function (component, cellView) {
        this._super();

        this.component = component;
        this.cellView = cellView;

        if (!component.bigComponent.isHead) {
            return;
        }

        this.setContentSize(cellView.getContentSize());

        this.cellWithoutLive = new cc.Node();
        this.addChild(this.cellWithoutLive);
        this.cellWithoutLive.setContentSize(cellView.getContentSize());

        if (!component.cell.isForGoal) {
            this.createEaterSpineContent(component.cell);
        } else {
            var styles = this.getStyles();
            var eaterGoalPng = bundles.game.frames[component.cell.bundleId() + "_eater_goal_png"];

            if (eaterGoalPng === undefined) {
                this.createEaterSpineContent(component.cell);
                if (styles.goal.animation && styles.goal.animation.scale) {
                    this.animation.setScale(styles.goal.animation.scale);
                }
            } else {
                this.image = new cc.Sprite(eaterGoalPng);
                this.image.setAnchorPoint(0, 0);
                this.setPosition(this.width / 2, this.height / 2);
                this.cellWithoutLive.addChild(this.image);
                if (styles.goal.image && styles.goal.image.scale) {
                    this.image.setScale(styles.goal.image.scale);
                }
            }
        }

        this.cellWithoutLive.setAnchorPoint(0.5, 0.5);
        this.cellWithoutLive.setPositionRound(this.width / 2, this.height / 2);
    },

    getStyles: function () {
        if (this.cellView.getStyles) {
            return this.cellView.getStyles();
        }
    },

    createEaterSpineContent: function (cell) {
        if (this.cellView.createEaterSpineContent) {
            this.cellView.createEaterSpineContent(cell);
            return;
        }

        this.animation = new cleverapps.Spine(bundles.game.jsons[cell.bundleId() + "_eater_spine_json"]);
        this.cellWithoutLive.addChild(this.animation);
        this.goIdle();
        this.animation.setPosition(this.width / 2, this.height / 2);
    },

    goIdle: function () {
        this.inHurt = false;
        if (this.getStyles().idleAnimation) {
            this.animation.setAnimation(0, "idle", true);
        }
    },

    hurtAnimation: function () {
        if (!this.inHurt) {
            this.inHurt = true;
            this.animation.setAnimation(0, "collect", false);
            this.animation.setCompleteListener(this.goIdle.bind(this));

            if (cleverapps.config.name === "heroes" && this.component.cell instanceof DogCell) {
                cleverapps.audio.playSound(bundles.dog.urls.dog_hurt_effect);
            }
        }

        if (cleverapps.config.name !== "heroes") {
            cleverapps.audio.playSound(bundles.game.urls.flower_open_effect);
        }
    },

    getCollectFlyPoints: function (object) {
        var targetPosition = object.getRelativeCoordinates(this);
        var bezierFirstPoint, bezierSecondPoint;
        var styles = this.getStyles();

        targetPosition.y += styles.collect.target.y;

        bezierSecondPoint = cc.p(targetPosition.x, targetPosition.y + styles.collect.y);
        bezierFirstPoint = cc.p((object.x + bezierSecondPoint.x) / 2, (object.y + bezierSecondPoint.y) / 2);

        return [bezierFirstPoint, bezierSecondPoint, targetPosition];
    },

    collect: function (object, goal, idInGroup, onFinish) {
        cleverapps.audio.playSound(bundles.game.urls.cell_collect_effect, {
            throttle: 200
        });

        object.stopAllActions();
        object.setLocalZOrder(10);

        var bezierPoints = this.getCollectFlyPoints(object, idInGroup);
        var styles = this.getStyles();

        object.runAction(new cc.Sequence(
            new cc.Spawn(
                new cc.MoveBy(Goals.UP_DURATION, 0, styles.collect.up)
            ),
            new cc.DelayTime(goal.waitDuration),
            new cc.Spawn(
                new cc.BezierTo(goal.duration - Goals.UP_DURATION - goal.waitDuration, bezierPoints).easing(cc.easeIn(1)),
                new cc.ScaleTo(goal.duration - Goals.UP_DURATION - goal.waitDuration, styles.collect.scale)
            ),
            new cc.CallFunc(function () {
                cleverapps.audio.playSound(bundles.game.urls.goal_inc_effect, {
                    throttle: 100
                });
                onFinish();
            })
        ));
    },

    startExploding: function () {
        if (cleverapps.config.name === "heroes" && this.component.cell instanceof DogCell) {
            this.animation.setAnimation(0, "fly_to_goal", false);
            cleverapps.audio.playSound(bundles.dog.urls.dog_collect_effect);
        }
    },

    refuse: function () {
        if (this.cellWithoutLive.getRotation() !== 0) {
            return;
        }
        var NOD_DURATION = 0.1;
        var ANGLE = 3;
        this.cellWithoutLive.runAction(new cc.Sequence(
            new cc.RotateBy(NOD_DURATION, -ANGLE).easing(cc.easeIn(1)),
            new cc.RotateBy(2 * NOD_DURATION, 2 * ANGLE).easing(cc.easeIn(1)),
            new cc.RotateTo(NOD_DURATION, 0).easing(cc.easeIn(1))
        ));
    }
});
