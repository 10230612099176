/**
 * Created by razial on 09.04.2024.
 */

cleverapps.Cache = function (capacity) {
    this.capacity = capacity || 100;
    this.data = {};
    this.queue = [];
};

cleverapps.Cache.prototype.set = function (key, value) {
    if (!this.data[key]) {
        var index = this.queue.indexOf(key);
        if (index !== -1) {
            this.queue.splice(index, 1);
        }
    }

    this.data[key] = value;
    this.queue.push(key);

    if (this.queue.length > this.capacity) {
        key = this.queue.shift();
        delete this.data[key];
    }
};

cleverapps.Cache.prototype.get = function (key) {
    return this.data[key];
};
