/**
 * Created by mac on 9/30/20
 */

var AdminEpisode = function (options) {
    cleverapps.EventEmitter.call(this);

    Object.assign(this, options);
};

AdminEpisode.prototype = Object.create(cleverapps.EventEmitter.prototype);
AdminEpisode.prototype.constructor = AdminEpisode;

AdminEpisode.createAddMoreEpisode = function () {
    return new AdminEpisode({
        extra: true,
        episodeNo: "#addMoreEpisode"
    });
};

AdminEpisode.create = function (index, name) {
    var episode = new AdminEpisode({
        index: index
    });
    episode.setEpisodeNo(name);
    return episode;
};

AdminEpisode.prototype.setEpisodeNo = function (name) {
    var episodeNo = parseInt(name);
    Object.assign(this, {
        name: name,
        episodeNo: name,
        start: episodeNo * AdminEpisode.LEVELS_PER_EPISODE,
        end: (episodeNo + 1) * AdminEpisode.LEVELS_PER_EPISODE - 1
    });
    this.onUpdate();
};

AdminEpisode.prototype.isAddMoreEpisode = function () {
    return this.extra !== undefined;
};

AdminEpisode.prototype.isNumeric = function () {
    var code = parseInt(this.episodeNo);
    return code === this.episodeNo;
};

AdminEpisode.prototype.isCurrent = function () {
    return this.episodeNo === cleverapps.user.episode;
};

AdminEpisode.prototype.isDebug = function () {
    var bundle = this.getBundle();
    return bundle && bundle.meta.debug;
};

AdminEpisode.prototype.isApproved = function () {
    return this.approved;
};

AdminEpisode.prototype.setApproved = function (approved) {
    this.approved = approved;
};

AdminEpisode.prototype.onClick = function (f) {
    if (this.isAddMoreEpisode()) {
        cleverapps.administrator.adminEpisodes.createNew(f);
    } else {
        cleverapps.administrator.adminEpisodes.setActive(this, f);
    }
};

AdminEpisode.prototype.inProgress = function () {
    return (this.language && this.language !== cleverapps.settings.language) ? false : AdminEpisode.InProgress(this.episodeNo);
};

AdminEpisode.prototype.bundleId = function () {
    return AdminEpisode.BundleId(this.episodeNo);
};

AdminEpisode.prototype.getBundle = function () {
    return bundles[this.bundleId()];
};

AdminEpisode.InProgress = function (episodeNo, language) {
    if (!episodeNo || /[^0-9]/.test(episodeNo)) {
        return;
    }
    var bundleName = AdminEpisode.BundleId(episodeNo, language);
    return bundles[bundleName].meta.episode.levelsAmount < 15;
};

AdminEpisode.prototype.select = function () {
    this.selected = true;
    this.trigger("select");
};

AdminEpisode.prototype.unselect = function () {
    this.selected = false;
    this.trigger("unselect");

    this.destructor();
};

AdminEpisode.prototype.hover = function () {
    if (this.isAddMoreEpisode()) {
        return;
    }

    cleverapps.administrator.adminEpisodes.hover(this);
};

AdminEpisode.prototype.unhover = function () {
    cleverapps.administrator.adminEpisodes.unhover(this);
};

AdminEpisode.prototype.onUpdate = function () {
    this.trigger("update");
};

AdminEpisode.prototype.onUpdateCurrent = function () {
    this.trigger("updateCurrent");
};

AdminEpisode.prototype.loadData = function (callback) {
    if (cleverapps.bundleLoader.isLoaded(this.bundleId())) {
        callback && callback();
        return;
    }

    cleverapps.bundleLoader.loadBundles([this.bundleId()], {
        onSuccess: callback
    });
};

AdminEpisode.prototype.destructor = function () {
    cleverapps.bundleLoader.deleteBundles([this.bundleId()]);
};

AdminEpisode.prototype.getEpisodeLevelsContent = function () {
    var bundle = bundles[this.bundleId()];
    return bundle.meta.location ? [cc.loader.getRes(bundle.jsons.map_json)] : cc.loader.getRes(bundle.jsons.levels);
};

AdminEpisode.prototype.getEpisodeLevels = function () {
    var content = this.getEpisodeLevelsContent();
    return Array.isArray(content) ? content : content.levels;
};

AdminEpisode.BundleId = function (episodeNo, language) {
    language = language || cleverapps.settings.language;
    var name = "episode_" + episodeNo;

    if (cleverapps.config.type === "board" && language !== "en") {
        name += "_" + language;
    }
    return name;
};

AdminEpisode.LEVELS_PER_EPISODE = 15;