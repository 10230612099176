/**
 * Created by andrey on 19.04.2024
 */

var PersonSilhouette = cc.Node.extend({
    ctor: function () {
        this._super();

        this.setAnchorPoint(0.5, 0);

        cleverapps.bundleLoader.loadBundle("person_silhouette", {
            onSuccess: this.createContent.bind(this)
        });
    },

    createContent: function () {
        var styles = cleverapps.styles.PersonSilhouette;

        var image = this.image = new cc.Sprite(bundles.person_silhouette.frames.silhouette);
        image.setAnchorPoint(0.5, 0);
        image.setPosition(image.width / 2, -styles.offsetY);
        this.addChild(image);

        this.setContentSize(image.width, image.height - styles.offsetY);
        this.setPositionRound(styles);
    }
});

cleverapps.styles.PersonSilhouette = {
    x: { align: "center" },
    y: { align: "bottom" },

    offsetY: 80
};