/**
 * Created by slava on 28/3/19
 */

var CurrentEpisodeLabel = cc.Node.extend({
    ctor: function (pageNo) {
        this._super();
        this.pageNo = pageNo;

        this.createContent();

        this.setAnchorPoint(0.5, 0.5);
        this.setPositionRound({ x: { align: "center" }, y: { align: "center" } });
        this.setLocalZOrder(1);
    },

    createContent: function () {
        this.bg = this.createBg();

        var label = this.label = new cc.Node();
        label.setAnchorPoint(0.5, 0.5);
        label.setContentSize2(this.bg.getContentSize());
        label.addChild(this.bg);
        label.setPositionRound(cleverapps.styles.CurrentEpisodeLabel.label.alignment);
        this.addLabelText();

        this.setContentSize2(label.getContentSize());
        this.addChild(this.label);
    },

    createBg: function () {
        var styles = cleverapps.styles.CurrentEpisodeLabel.background;
        var bg;
        if (styles.json) {
            bg = new cc.Node();
            bg.setAnchorPoint(0.5, 0.5);
            bg.setContentSize2(styles);

            var animation = this.bgAnimation = new cleverapps.Spine(styles.json);
            animation.setAnimation(0, "idle", false);
            animation.setPosition(bg.width / 2, bg.height / 2);
            bg.addChild(animation);
        } else {
            bg = cleverapps.UI.createScale9Sprite(styles.image, cleverapps.UI.Scale9Rect.TwoPixelXY);
            if (styles.width) {
                bg.setContentSize2(styles);
            }
        }
        bg.setPosition(bg.width / 2, bg.height / 2);
        return bg;
    },

    addLabelText: function () {
        var styles = cleverapps.styles.CurrentEpisodeLabel.label.islandText;
        var labelText = this.labelText = cleverapps.UI.generateTTFText("StartEpisodeWindow.Title" + this.pageNo, styles.font);
        labelText.fitTo(this.bg.width + styles.padding);
        labelText.setPositionRound(styles.alignment);
        this.label.addChild(labelText);
    }
});

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    ISLAND_LABEL_TEXT: {
        size: 34,
        color: cleverapps.styles.COLORS.WHITE
    },

    ISLAND_LABEL_LEVELS: {
        size: 30,
        color: cleverapps.styles.COLORS.WHITE
    }
});