/**
 * Created by slava on 22/2/18
 */

var EditorControlsView = cleverapps.Layout.extend({
    ctor: function (editor) {
        var styles = cleverapps.styles.EditorControlsView;
        this.buttons = editor.controls.map(function (control) {
            if (control.comboKeys) {
                cleverapps.keyboardController.bindKeys(control.hint, control.comboKeys, this.createListener(function () {
                    control.action();
                }));
            }

            return new cleverapps.UI.Button({
                content: new cc.Sprite(control.icon),
                type: cleverapps.styles.UI.Button.Images.editor,
                onClicked: control.action.bind(this),
                width: styles.button.width,
                height: styles.button.height,
                hint: control.hint,
                selectOnClick: control.selectOnClick
            });
        }, this);

        this.bindHistory(editor);
        this.bindCopyPaste(editor);

        this._super(this.buttons, {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });

        this.setLocalZOrder(25);
        this.setupChildren();
    },

    setupChildren: function () {
        this.setPositionRound(cleverapps.styles.EditorControlsView);
    },

    findButton: function (hint) {
        return this.buttons.filter(function (button) {
            return button.settings.hint === hint;
        })[0];
    },

    bindHistory: function (editor) {
        var undoButton = this.findButton("undo");
        if (!editor.history.hasUndo()) {
            undoButton.disable();
        }
        var redoButton = this.findButton("redo");
        if (!editor.history.hasRedo()) {
            redoButton.disable();
        }

        var table = Game.currentGame.table;
        var levelRotateButton = this.findButton("rotate level");
        if (levelRotateButton && !table.needAdaptToCanvas()) {
            levelRotateButton.disable();
        }

        editor.history.onChangeListener = this.createListener(function (hasUndo, hasRedo) {
            hasUndo ? undoButton.enable() : undoButton.disable();
            hasRedo ? redoButton.enable() : redoButton.disable();

            if (levelRotateButton) {
                table.needAdaptToCanvas() ? levelRotateButton.enable() : levelRotateButton.disable();
            }
        });
    },

    bindCopyPaste: function (editor) {
        var copy = this.findButton("copy");
        copy.disable();
        var paste = this.findButton("paste");
        paste.disable();

        editor.copyPaste.onChangeListener = this.createListener(function (hasCopy, hasPaste) {
            hasCopy ? copy.enable() : copy.disable();
            hasPaste ? paste.enable() : paste.disable();
        });
    }
});

cleverapps.styles.EditorControlsView = {
    x: { align: "right", dx: -10 },
    y: { align: "center" },

    margin: 10,
    button: {
        width: 70,
        height: 70
    }
};