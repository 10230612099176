/**
 * Created by mac on 9/25/20
 */

var EpisodeStatistics = function () {
    DataSource.call(this);
};

EpisodeStatistics.prototype = Object.create(DataSource.prototype);
EpisodeStatistics.prototype.constructor = EpisodeStatistics;

EpisodeStatistics.prototype.calculateData = function (adminEpisode, f) {
    var episodeLevels = adminEpisode.getEpisodeLevels();

    var hashes = {};
    episodeLevels.forEach(function (level, levelNo) {
        Object.assign(hashes, cleverapps.administrator.adminLevels.levels[levelNo].getLevelHashes());
    });

    if (Object.keys(hashes).length === 0) {
        this.data = [];
        f();
        return;
    }

    var hashesIds = Object.keys(hashes).join(",");

    if (cleverapps.config.type === "merge") {
        return;
    }

    cleverapps.RestClient.get("/admin/basic/" + hashesIds, {}, function (data) {
        this.data = this.processData(episodeLevels, hashes, data);

        // console.log("Response episode: ", this.data);
        f();
    }.bind(this), function () {
        console.log("Error getting statistics for episode", adminEpisode);
    });
};

EpisodeStatistics.prototype.baseParams = function (hashes, data) {
    var result = {};
    data.forEach(function (line) {
        Object.assign(line, hashes[line.hash]);
        result[line.levelNo + "_" + line.version] = line;
    });
    return result;
};

EpisodeStatistics.prototype.getLevelData = function (levelNo, data) {
    var level = cleverapps.administrator.adminLevels.levels[levelNo];

    var versions = Object.keys(level.getLevelVersions()).map(function (version) {
        return parseInt(version);
    }).sort(function (a, b) {
        return a - b;
    }).reverse();

    var result = versions.map(function (version) {
        return data[levelNo + "_" + version];
    }).find(function (line) {
        return line;
    });

    if (!result) {
        result = {
            levelNo: levelNo,
            version: -1,
            difficulty: 0,
            hints: 0,
            minutes: 0
        };
    }

    if (result.difficulty) {
        result.difficulty = Math.round(result.difficulty);
    }
    if (result.hints) {
        result.hints = Math.round(result.hints);
    }

    result.currentVersion = level.getMaxVersion(level.getLevelVersions());

    return result;
};

EpisodeStatistics.prototype.processData = function (episodeLevels, hashes, data) {
    data = this.baseParams(hashes, data);

    return episodeLevels.map(function (level, levelNo) {
        return this.getLevelData(levelNo, data);
    }.bind(this));
};
