/**
 * Created by vladislav on 29.10.2021
 */

var Shortcuts = function () {
    Shortcuts.List.filter(function (item) {
        return item.action;
    }).forEach(function (item) {
        var combo = item.keys.map(function (key) {
            return cc.KEY[key];
        });

        cleverapps.keyboardController.bindKeys(item.name, combo, item.action);
    });
};

Shortcuts.GetMemo = function () {
    var memo = {};

    Shortcuts.List.forEach(function (item) {
        if (!item.filter()) {
            return;
        }

        var key = item.keys.map(function (key) {
            return key.toUpperCase();
        }).join("+");
        memo[item.name + " - " + key] = item.action || function () {};
    }, this);

    return memo;
};

Shortcuts.selectedNodeFilter = function () {
    return cleverapps.sceneDebugger.selectedNode !== undefined;
};
Shortcuts.noSelectedNodeFilter = function () {
    return cleverapps.sceneDebugger.selectedNode === undefined;
};
Shortcuts.mergeTypeFilter = function () {
    return cleverapps.config.type === "merge" && cleverapps.sceneDebugger.selectedNode === undefined;
};
Shortcuts.ttfFilter = function () {
    return cleverapps.sceneDebugger.selectedNode instanceof cc.LabelTTF;
};

Shortcuts.List = [
    {
        name: "drawCalls",
        keys: ["ctrl", "q"],
        action: function () {
            cleverapps.drawCallsDebugger.debugDrawCalls();
        },
        filter: Shortcuts.noSelectedNodeFilter
    },
    {
        name: "toggleTexts",
        keys: ["ctrl", "y"],
        action: function () {
            cleverapps.sceneDebugger.toggleTexts();
        },
        filter: Shortcuts.noSelectedNodeFilter
    },
    {
        name: "makeSnapshot",
        keys: ["alt", "s"],
        action: function () {
            cleverapps.snapshots.createSnapshot();
        },
        filter: Shortcuts.noSelectedNodeFilter
    },
    {
        name: "recordActions",
        keys: ["alt", "r"],
        action: function () {
            cleverapps.snapshotBar.toggleRecord();
        },
        filter: Shortcuts.noSelectedNodeFilter
    },
    {
        name: "selectNode",
        keys: ["ctrl", "l🖱"],
        filter: Shortcuts.noSelectedNodeFilter
    },
    {
        name: "deleteNode",
        keys: ["Delete"],
        filter: Shortcuts.selectedNodeFilter
    },
    {
        name: "toggleVisible",
        keys: ["v"],
        action: function () {
            cleverapps.sceneDebugger.toggleMatchedNodes();
        },
        filter: Shortcuts.selectedNodeFilter
    },
    {
        name: "moveNode [10px]",
        keys: ["✥[+shift]"],
        filter: Shortcuts.selectedNodeFilter
    },
    {
        name: "scale",
        keys: ["s", "✥"],
        filter: Shortcuts.selectedNodeFilter
    },
    {
        name: "mirror",
        keys: ["m", "✥"],
        filter: Shortcuts.selectedNodeFilter
    },
    {
        name: "rotate",
        keys: ["r", "✥"],
        filter: Shortcuts.selectedNodeFilter
    },
    {
        name: "changeWidth",
        keys: ["w", "⇅"],
        filter: Shortcuts.selectedNodeFilter
    },
    {
        name: "changeHeight",
        keys: ["h", "⇅"],
        filter: Shortcuts.selectedNodeFilter
    },
    {
        name: "changeFontSize",
        keys: ["f", "✥"],
        filter: Shortcuts.ttfFilter
    },
    {
        name: "changeTTFDimensions",
        keys: ["d", "✥"],
        filter: Shortcuts.ttfFilter
    },
    {
        name: "copyUnit",
        keys: ["c", "l🖱"],
        filter: Shortcuts.mergeTypeFilter
    },
    {
        name: "deleteUnit [all]",
        keys: ["r🖱[+shift]"],
        filter: Shortcuts.mergeTypeFilter
    },
    {
        name: "demoUnit",
        keys: ["x", "l🖱"],
        filter: Shortcuts.mergeTypeFilter
    },
    {
        name: "toggleUnits",
        keys: ["u"],
        filter: Shortcuts.mergeTypeFilter,
        action: function () {
            Map2d.setUnitsVisible(!Map2d.unitsVisible);
        }
    },
    {
        name: "toggleChessLayer",
        keys: ["i"],
        filter: Shortcuts.mergeTypeFilter,
        action: function () {
            Map2d.setChessLayerVisible(!Map2d.chessLayerVisible);
        }
    },
    {
        name: "toggleBlockedCells",
        keys: ["o"],
        filter: Shortcuts.mergeTypeFilter,
        action: function () {
            Map2d.setStarredChessLayerVisible(!Map2d.starredChessLayerVisible);
        }
    },
    {
        name: "toggleUnitLock",
        keys: ["l", "l🖱"],
        filter: Shortcuts.mergeTypeFilter
    },
    {
        name: "toggleAnchorsMode",
        keys: ["a"],
        filter: Shortcuts.mergeTypeFilter,
        action: function () {
            cleverapps.flags.toggle("keyboardAnchorsMode");
            cleverapps.notification.create("Edit anchors mode " + (cleverapps.flags.keyboardAnchorsMode ? "activated" : "switched off"));
        }
    }
];
