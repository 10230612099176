/**
 * Created by mac on 4/6/24
 */

var LoadingAnimation = cleverapps.Spine.extend({
    ctor: function () {
        this._super(bundles.main.jsons.wait_json);

        this.setAnimation(0, "idle", true);

        var styles = cleverapps.styles.LoadingAnimation;
        if (styles.text) {
            var loadingText;
            if (Messages.get("Loading")) {
                loadingText = cleverapps.UI.generateOnlyText("Loading", cleverapps.styles.FONTS.LOADING_ANIMATION_TEXT);
            } else {
                loadingText = cleverapps.UI.__generateNotLocalizedText("Loading", cleverapps.styles.FONTS.LOADING_ANIMATION_TEXT);
            }
            loadingText.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
            loadingText.setDimensions(styles.text.width, 0);
            loadingText.fitTo(undefined, styles.text.height);
            loadingText.setPositionRound(styles.text);
            this.addChild(loadingText, 1);
        }
    },

    subscribeToWindows: function () {
        cleverapps.windows.on("hideLoading", this.hideLoading.bind(this), this);
        cleverapps.windows.on("showLoading", this.showLoading.bind(this), this);
    },

    hideLoading: function () {
        this.stopAllActions();
        this.setVisible(false);
    },

    showLoading: function () {
        this.stopAllActions();
        this.runAction(
            new cc.Sequence(
                new cc.DelayTime(0.1),
                new cc.Show()
            )
        );
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    LOADING_ANIMATION_TEXT: {
        size: 45,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.WINDOW_WHITE_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.WHITE_TEXT_SHADOW
    }
});

cleverapps.styles.LoadingAnimation = {
    text: {
        x: { align: "center" },
        y: { align: "center" },

        width: 400,
        height: 70
    }
};