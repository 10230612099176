/**
 * Created by andrey on 29.05.17.
 */
var EditorGoalView = cc.Sprite.extend({
    ctor: function (goal) {
        this._super();

        var bg = cleverapps.UI.createScale9Sprite(bundles.game.frames.goal_common_bg);
        bg.setContentSize(cleverapps.styles.EditorGoalView.bg.width, cleverapps.styles.EditorGoalView.bg.height);
        this.addChild(bg);
        this.setContentSize(bg.getContentSize());
        bg.setPositionRound(this.width / 2, this.height / 2);
        this.bg = bg;

        this.goal = goal;

        this.target = new IncDecBlock({
            radius: cleverapps.styles.EditorGoalView.radius,
            value: goal.target,
            color: cleverapps.styles.COLORS.WHITE,
            onChange: function (value) {
                goal.setTarget(value);
            }
        });

        this.target.setPosition(this.width / 2, cleverapps.styles.EditorGoalView.target.y);
        this.addChild(this.target);

        cleverapps.UI.onClick(this, this.showChooser.bind(this));

        goal.onChangeGoal = this.goalChanged.bind(this);

        this.closeButton = new cleverapps.UI.Button({
            type: {
                button_png: bundles.buttons_main.frames.close_small,
                button_on_png: bundles.buttons_main.frames.close_small_on
            },
            onClicked: this.reset.bind(this)
        });
        this.closeButton.setPosition(this.width + cleverapps.styles.EditorGoalView.close.x, this.height + cleverapps.styles.EditorGoalView.close.y);
        this.addChild(this.closeButton);
    },

    createViewByType: function (type) {
        var icon = GoalViewFactory.createView({
            type: type,
            on: function () {}
        }).icon;

        icon.removeFromParent();
        return icon;
    },

    showChooser: function () {
        var table = {};

        for (var type in GoalViewFactory.creators) {
            table[type] = this.createViewByType(type);
        }

        var chooser = new Chooser(table, {
            bg: bundles.game.frames.tile_background_1,
            removeOnAsideClick: true
        });

        if (EditorView.chooser) {
            EditorView.chooser.removeFromParent();
        }
        EditorView.chooser = chooser;

        chooser.setPositionRound(cleverapps.styles.EditorGoalView.chooser);
        this.parent.addChild(chooser);

        chooser.onSelect = function (key) {
            EditorView.chooser = undefined;
            this.goal.setType(key);
            chooser.removeFromParent();
        }.bind(this);
    },

    setIcon: function (icon) {
        if (this.icon) {
            this.icon.removeFromParent();
        }

        this.icon = icon;
        this.addChild(icon);
        this.icon.setPositionRound({ align: "center" }, { align: "center", dy: cleverapps.styles.EditorGoalView.icon.dy });

        cleverapps.UI.onClick(this.icon, function () {
            var newValue = window.prompt("", this.target.number.getString());
            if (newValue) {
                this.goal.setTarget(parseInt(newValue));
            }
        }.bind(this));
    },

    reset: function () {
        this.goal.setType(false);
    },

    goalChanged: function () {
        if (!this.goal.type) {
            this.target.visible = false;
            if (this.icon) {
                this.icon.removeFromParent();
            }

            this.closeButton.visible = false;
            this.bg.setColor(EditorGoalView.NO_COLOR);
            cleverapps.UI.convertToGrayScale(this);
            return;
        }

        cleverapps.UI.convertToGrayScale(this, true);
        this.closeButton.visible = true;
        this.target.visible = true;

        this.target.setAuto(this.goal.auto);
        this.target._setValue(this.goal.target, true);

        this.setIcon(this.createViewByType(this.goal.type));
        this.setState();
    },

    setState: function () {
        var provided = this.goal.isProvidedGoal();
        var fieldAmount = this.goal.inFieldAmount;
        var target = this.goal.target;

        this.bg.setColor(EditorGoalView.NO_COLOR);
        this.target.setRange([-Infinity, Infinity]);

        if (provided) {
            if (fieldAmount > target) {
                this.bg.setColor(EditorGoalView.ERROR_COLOR);
                this.target.setRange([fieldAmount, Infinity]);
            } else if (fieldAmount < target) {
                if (target - fieldAmount <= 2) {
                    this.bg.setColor(EditorGoalView.ERROR_COLOR);
                } else {
                    this.bg.setColor(EditorGoalView.WARNING_COLOR);
                }
            } else {
                this.target.setRange([target, Infinity]);
            }
        } else if (fieldAmount < target) {
            this.bg.setColor(EditorGoalView.ERROR_COLOR);
            this.target.setRange([-Infinity, target]);
        } else if (fieldAmount > target) {
            this.bg.setColor(EditorGoalView.WARNING_COLOR);
        } else {
            this.target.setRange([-Infinity, target]);
        }
    }
});

EditorGoalView.ERROR_COLOR = cc.color(250, 128, 114);
EditorGoalView.WARNING_COLOR = cc.color(250, 218, 94);
EditorGoalView.NO_COLOR = cc.color(255, 255, 255);

cleverapps.styles.EditorGoalView = {
    radius: 70,

    bg: {
        width: 180,
        height: 180
    },

    target: {
        y: 30
    },

    close: {
        x: -20,
        y: -20
    },

    chooser: {
        x: { align: "center", dx: 0 },
        y: { align: "top", dy: -90 }
    },

    icon: {
        dy: 20
    }
};