/**
 * Created by iamso on 30.10.18.
 */

var RugTileView = cc.Node.extend(Object.assign({}, BaseTileView, {
    ctor: function (tile) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);

        this.initialize(tile);
        this.setImage();
        this.setLocalZOrder(4);

        this.tile.onCreateNew = this.animateCreateNew.bind(this);
    },

    setImage: function () {
        var imageName = this.tile.x < 0 ? "rug_goal" : "rug_tile";
        var image = new cc.Sprite(bundles.rug.frames[imageName]);
        image.setPosition(this.width / 2, this.height / 2);
        this.image = image;
        this.addChild(image);
    },

    animateCreateNew: function () {
        this.image.setScale(0);
        this.image.runAction(
            new cc.Sequence(
                new cc.DelayTime(0.5),
                new cc.ScaleTo(0.1, 0.25).easing(cc.easeBackOut()),
                new cc.ScaleTo(0.1, 0.15).easing(cc.easeBackOut()),
                new cc.ScaleTo(0.3, 1.05).easing(cc.easeBackOut()),
                new cc.ScaleTo(0.1, 1).easing(cc.easeBackOut())

            )
        );
    }
}));