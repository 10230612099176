/**
 * Created by spepa on 03.09.2024
 */

var PassPackBadge = cc.Node.extend({
    ctor: function (offer) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);

        var badge = new cc.Sprite(bundles.passheader.frames.badge_png);
        this.setContentSize(badge.getContentSize());
        this.addChild(badge);
        badge.setPositionRound(this.width / 2, this.height / 2);

        var styles = cleverapps.styles.PassPackBadge[offer.type] || cleverapps.styles.PassPackBadge;
        var price = new TextWithIcon(offer.logic.getPriceText(), { font: cleverapps.styles.FONTS.BUTTON_BROWN_TEXT || cleverapps.styles.FONTS.BUTTON_TEXT });
        badge.addChild(price);
        price.fitTo(styles.text.width);
        price.setRotation(styles.text.rotation);
        price.setPositionRound(styles.text);
    }
});

cleverapps.styles.PassPackBadgeTemplate = {
    text: {
        x: { align: "center", dx: 37 },
        y: { align: "center", dy: 13 },
        rotation: -17,
        width: 130
    }
};

cleverapps.styles.PassPackBadge = cleverapps.overrideStyles(cleverapps.styles.PassPackBadgeTemplate, {}, true);

cleverapps.styles.PassPackBadge[Offers.TYPES.CLPASS] = cleverapps.overrideStyles(cleverapps.styles.PassPackBadgeTemplate, {
    text: {
        x: { align: "center", dx: 16 },
        y: { align: "center", dy: 4 },
        rotation: 34
    }
}, true);