/**
 * Created by Denis Kuzin on 03 june 2024
 */

var SpecialColorComponent = function (cell) {
    this.cell = cell;
};

SpecialColorComponent.prototype.repaint = function () {
    var colorComponent = this.cell.findComponent(ColorComponent);
    colorComponent.setColor(this.cell.getRandomColor());
    return this.cell;
};

SpecialColorComponent.prototype.canRepaint = function (inShuffle) {
    return !inShuffle;
};

SpecialColorComponent.prototype.onDoubleTouch = function () {
};