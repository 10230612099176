/**
 * Created by slava on 01.08.17.
 */

var MissionMarkView = cc.Sprite.extend({
    ctor: function () {
        this.icon = this.getIcon();
        this._super(this.icon);
        this.setAnchorPoint(0.5, 0.5);
        var styles = cleverapps.styles.MissionMarkView;
        this.setScale(styles.scale);
        this.setPosition(cleverapps.styles.BaseCellView.CELL_SIZE_PX / 2 + styles.x, cleverapps.styles.BaseCellView.CELL_SIZE_PX / 2 + styles.y);
    },

    getIcon: function () {
        return cleverapps.skins.getSlot("missionMarkIcon", {
            type: Game.currentGame.secondaryMission.type
        });
    },

    unuse: function () {
        if (Game.currentGame && Game.currentGame.outcome === GameBase.OUTCOME_UNKNOWN) {
            var game = Game.currentGame;
            var scene = cleverapps.scenes.getRunningScene();
            var beginPos = scene.convertToNodeSpace(this.getParent().convertToWorldSpace(this.getPosition()));
            var target = cleverapps.aims.getTarget("mission_reward" + Game.currentGame.secondaryMission.type);
            var targetPos = scene.convertToNodeSpace(target.getParent().convertToWorldSpace(target.getPosition()));

            MissionAnimationView.show(game.getMissionType(), scene, {
                beginPos: beginPos,
                targetPos: targetPos,
                beginScale: this.getScale() * scene.fieldView.getScale(),
                targetScale: 1,
                icon: this.icon,
                callback: function () {
                    game.addClover(Mission.TYPE_COLLECT_MARK, 1);
                }
            });
        }
        this.removeFromParent(false);
    },

    reuse: function () {
        this.icon = this.getIcon();
        this.setSpriteFrame(this.icon);
    }
});

MissionMarkView.factory = function () {
    return cc.pool.hasObject(MissionMarkView) ? cc.pool.getFromPool(MissionMarkView) : new MissionMarkView();
};

cleverapps.styles.MissionMarkView = {
    scale: 0.5,
    x: -30,
    y: 30
};