/**
 * Created by vladislav on 3/5/19
 */

var SmallPetardCell = function (x, y) {
    BaseCell.call(this, x, y);

    Game.currentGame.counter.registerStage(301, SmallPetardCell.process);

    this.onChangeLivesListener = function () {
    };

    this.onBlowUpListener = function () {
    };

    this.onShowUpListener = function () {
    };
};

SmallPetardCell.prototype = Object.create(BaseCell.prototype);
SmallPetardCell.prototype.constructor = SmallPetardCell;

SmallPetardCell.prototype.bundleId = function () {
    return "small_petard";
};

SmallPetardCell.prototype.getViewClass = function () {
    return SmallPetardCellView;
};

SmallPetardCell.prototype.editorComponents = function () {
    return [BaseCellComponent, LiveLineEditorComponent];
};

SmallPetardCell.prototype.boom = function (coef, cell) {
    this.hurt(undefined, cell);
};

SmallPetardCell.prototype.hurt = function (explodeParams, cell) {
    if (this.lives < 1 || !this.alive) {
        return;
    }

    var colorComponent = cell && cell.findComponent(ColorComponent);
    if (colorComponent && colorComponent.color !== this.color) {
        return;
    }

    if (!cell) {
        Game.currentGame.smallPetardCellCanProcess = true;
    }

    this.lives--;

    Game.currentGame.counter.setTimeout(function () {
        this.onChangeLivesListener();

        if (this.lives < 1 && this.alive) {
            Game.currentGame.smallPetardCellCanProcess = true;
            this.alive = false;
            this.blowUp();
        }
    }.bind(this), this.hurtDuration() * 1000);
};

SmallPetardCell.prototype.hurtDuration = function () {
    return 0.65;
};

SmallPetardCell.prototype.getMaxLives = function () {
    return 9;
};

SmallPetardCell.prototype.blowUp = function () {
    BaseCell.neighbors.forEach(function (dir) {
        var cellsToHurt = [];
        
        this.iterateLineOfFire(this.x + dir.x, this.y + dir.y, dir, function (cell) {
            cellsToHurt.push(cell);
        });
    
        var rugCellsToHurt = [];
        this.iterateLineOfFire(this.x + dir.x, this.y + dir.y, dir, function (cell) {
            rugCellsToHurt.push(cell);
        }, true);

        Game.currentGame.counter.setTimeout(function () {
            cellsToHurt.forEach(function (cell, index) {
                cell.hover(false);
                cell.hurt({ id: index, groupSize: cellsToHurt.length });
            });

            rugCellsToHurt.forEach(function (cell) {
                RugTile.hurtViaCombo(this, cell);
            }.bind(this));
        }.bind(this), 500);

        this.onBlowUpListener(cellsToHurt.length, dir);
    }.bind(this));

    Game.currentGame.counter.setTimeout(function () {
    }, 1500);
};

SmallPetardCell.prototype.process = function () {
    if (this.lives < 1) {
        this.lives = this.maxLives;
        this.alive = true;
        this.onChangeLivesListener();
        this.onShowUpListener();
    }
};

SmallPetardCell.process = function () {
    if (!Game.currentGame.smallPetardCellCanProcess) {
        return;
    }

    Game.currentGame.smallPetardCellCanProcess = false;

    for (var i = 0; i < Field.SIZE; i++) {
        for (var j = 0; j < Field.SIZE; j++) {
            var cell = Game.currentGame.field.cells[i][j];
            if (cell && cell instanceof SmallPetardCell) {
                cell.process();
            }
        }
    }
};

SmallPetardCell.prototype.load = function (data) {
    this.lives = this.maxLives = parseInt(data[1]);
    this.color = data[2];
    if (this.color === ".") {
        this.color = BaseCell.prototype.getRandomColor.call(this);
    }
};

SmallPetardCell.prototype.save = function () {
    return SmallPetardCell.CODES.concat([this.lives, this.color]);
};