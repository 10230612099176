/**
 * Created by slava on 02.02.17.
 */

var FishCellView = BaseCellView.extend({
    ctor: function (fishCell) {
        this._super();

        this.setContentSize(cleverapps.styles.BaseCellView.CELL_SIZE_PX, cleverapps.styles.BaseCellView.CELL_SIZE_PX);
        this.setAnchorPoint(0.5, 0.5);

        var image = new cc.Sprite(this.getImage(fishCell));
        this.image = image;
        this.image.setPosition(this.width / 2, this.height / 2);
        this.addChild(image);

        this.setCascadeOpacityEnabled(true);

        this.initialize(fishCell);
    },

    getImage: function (cell) {
        var bundle = cell.bundleId();
        return bundles[bundle].frames[bundle];
    },

    initialize: function (fishCell) {
        this._super(fishCell);

        fishCell.onBeforeExplodeListener = this.startExplodingViaCollect.bind(this);

        this.image.setVisible(true);
        this.smiling = false;
    },

    finishSmile: function () {
        if (this.smiling) {
            this.smiling = false;
            this.animation.finishAnimation();

            this.image.visible = true;
        }
    },

    runAnimate: function (name) {
        var bundle = this.cell.bundleId();
        this.animation = BaseAnimation.factory(bundles[bundle].jsons[bundle + "_spine_json"]);
        this.animation.setPosition(this.width / 2, this.height / 2);
        this.animation.runAnimate(name, this.finishSmile.bind(this), this.image);
        this.addChild(this.animation);
    },

    smile: function () {
        if (this.smiling) {
            return;
        }

        this.smiling = true;

        this.runAnimate("animation");
    },

    onclick: function () {
        this.finishSmile();

        this.smiling = true;

        this.runAnimate(cleverapps.styles.FishCellView.onclickAnimation);
    }
});

cleverapps.styles.FishCellView = {
    onclickAnimation: "smile"
};