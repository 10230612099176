/**
 * Created by olga on 08.05.2024
 */

var StickersBookJokerView = cc.Node.extend({
    ctor: function (bundleName) {
        this._super();

        this.bundleName = bundleName;

        var jokerView = new cc.Sprite(bundles[bundleName].frames.double_joker_png);
        this.addChild(jokerView);
        this.setContentSize(jokerView.width, jokerView.height);
        jokerView.setPositionRound(this.width / 2, this.height / 2);

        if (cleverapps.styles.StickersBookJokerView.withBillet) {
            this.createAmountWithBillet();
        } else {
            this.createAmountWithCircle();
        }

        cleverapps.stickersBook.on("updateJokersAmount", this.createListener(this.updateAmount.bind(this)), this);
    },

    createAmountWithBillet: function () {
        var styles = cleverapps.styles.StickersBookJokerView;

        var text = this.amount = cleverapps.UI.generateImageText(cleverapps.stickersBook.jokers, cleverapps.styles.FONTS.STICKERS_PROGRESS_BAR_TEXT);
        var textBg = new cc.Scale9Sprite(bundles[this.bundleName].frames.number_bg_png);
        textBg.setContentSize2(styles.bg);
        textBg.setLocalZOrder(-1);
        textBg.addChild(text);

        text.setPositionRound(styles.text);
        textBg.setPositionRound(styles.bg);

        this.addChild(textBg);
    },

    createAmountWithCircle: function () {
        var styles = cleverapps.styles.StickersBookJokerView.amount;
        var amount = this.amount = cleverapps.UI.generateImageText(cleverapps.stickersBook.jokers, cleverapps.styles.FONTS.STICKERS_PROGRESS_BAR_TEXT);
        amount.fitTo(styles.width);

        var amountBg = new cc.Sprite(bundles[this.bundleName].frames.joker_bg_png);
        amountBg.addChild(amount);
        amount.setPosition(amountBg.width / 2, amountBg.height / 2);
        amountBg.setPositionRound(styles);

        this.addChild(amountBg);
    },

    updateAmount: function (amount) {
        this.amount.setString(amount.toString());
    },

    animateCollect: function () {
        cleverapps.audio.playSound(bundles[this.bundleName].urls.joker_shine_effect);

        this.runAction(new cc.Sequence(
            new cc.ScaleTo(0.2, 1.15).easing(cc.easeOut(2)),
            new cc.ScaleTo(0.3, 1).easing(cc.easeInOut(2))
        ));

        var shining = new cleverapps.Spine(bundles[this.bundleName].jsons.joker_completed_json);
        shining.setPosition(this.width / 2, this.height / 2);
        shining.setAnimation(0, "animation", false);
        shining.setCompleteListenerRemove();
        this.addChild(shining, -1);
    }
});

cleverapps.styles.StickersBookJokerView = {
    amount: {
        x: { align: "right", dx: 15 },
        y: { align: "bottom", dy: -10 },
        width: 80
    },
    bg: {
        width: 135,
        height: 67,
        x: { align: "right", dx: -40 },
        y: { align: "center" }
    },
    text: {
        x: { align: "center", dx: -10 },
        y: { align: "center" }
    },
    withBillet: false
};