/**
 * Created by anatoly on 22.04.2024
 */

var TapToSkip = TapToContinue.extend({
    ctor: function () {
        this._super.apply(this, arguments);

        this.clickHandler = cleverapps.UI.onClick(this, function () {
        }, {
            interactiveScale: false,
            onOuterTouch: cleverapps.once(function () {
                Game.currentGame.skipBonusAnimations = true;
                Game.currentGame.trigger("onSkip");

                this.hide();
            }.bind(this))
        });
    },

    hide: function () {
        this.stopAllActions();

        this.runAction(new cc.Sequence(
            new cc.FadeOut(0.1),
            new cc.CallFunc(this.clickHandler.remove),
            new cc.RemoveSelf()
        ));
    }
});

cleverapps.styles.TapToSkip = {
    x: { align: "center" },
    y: { align: "bottom", dy: 50 }
};