/**
 * Created by Andrey Popov on 27.01.2021
 */

var AdsText = cc.Node.extend({
    ctor: function (properties, eventBus, parent) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);

        this.synth = window.speechSynthesis;
        properties = this.properties = properties || {};

        this.eventBus = eventBus;
        this.parentSize = cc.size(parent.width, parent.height);

        this.background = properties.background || undefined;
        if (this.background) {
            this.backgroundMargins = Wysiwyg.getValueForResolution(this.background.margins);
            this.background.image ? this.bgImage = cleverapps.wysiwyg.googleDriveProvider.getLink(this.background.image) : this.bgImage = undefined;
        }

        var text = properties.LocalizedText && Wysiwyg.getValueForResolution(properties.LocalizedText) ? Wysiwyg.getValueForResolution(properties.LocalizedText)[cleverapps.settings.language || "en"] : properties.text;
        text = this.text = text || "Sample text";
        var font = cleverapps.clone(properties.font ? Wysiwyg.getValueForResolution(properties.font) : cleverapps.styles.FONTS.BUTTON_TEXT, true);

        if (font.font) {
            font.font = bundles.wysiwyg_fonts.urls[font.font + "_font_ttf"];
        }

        var title = this.title = cleverapps.UI.generateOnlyText(text, font);
        if (properties.width && properties.height) {
            do {
                title = this.title = cleverapps.UI.generateOnlyText(text, font);
                title.setDimensions(properties.width, 0);
                title.fitTo(undefined, properties.height);

                var wordToCheck = this.getLongestWord(text);
                var testText;
                if (wordToCheck) {
                    testText = cleverapps.UI.generateOnlyText(wordToCheck, font);
                }
                font.size--;
            } while (wordToCheck && 1.01 * testText.width > title.width);
        }
        title.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        this.showBgImage();
        this.addChild(title);

        this.setContentSize(properties.width || 100, properties.height || 100);
        title.setPositionRound(this.width / 2, this.height / 2);

        eventBus.on(Wysiwyg.EVENTS.SCENE_STARTED, function () {
            if (this.visible) {
                this.showBgImage();
                this.showText();
                this.speak();
            }
        }.bind(this));
    },

    setVisible: function (visible) {
        this._super(visible);

        if (visible) {
            this.showBgImage();
            this.showText();
            this.speak();
        }
    },

    getLongestWord: function (text) {
        text = text.replaceAll("\n", " ");
        var words = [text];
        if (text.indexOf(" ") !== -1) {
            words = text.split(" ");
        }

        var longestWord = words[0];
        words.forEach(function (word) {
            if (word.length > longestWord.length) {
                longestWord = word;
            }
        });

        return longestWord;
    },

    showBgImage: function () {
        if (!this.bgImage) {
            return;
        }

        var marginTop = this.parentSize.height * parseFloat(this.backgroundMargins.marginTop) / 100;
        var marginBottom = this.parentSize.height * parseFloat(this.backgroundMargins.marginBottom) / 100;
        var marginLeft = this.parentSize.width * parseFloat(this.backgroundMargins.marginLeft) / 100;
        var marginRight = this.parentSize.width * parseFloat(this.backgroundMargins.marginRight) / 100;

        cc.loader.load(this.bgImage, function () {
            var scale9 = cleverapps.UI.createScale9Sprite(new cc.Sprite(this.bgImage).getSpriteFrame(), cleverapps.UI.Scale9Rect.TwoPixelXY);

            scale9.setContentSize(this.properties.width + marginLeft + marginRight || 100, this.properties.height + marginTop + marginBottom || 100);
            this.addChild(scale9);

            scale9.setLocalZOrder(-1);
            scale9.setPositionRound((this.properties.width / 2 + (marginRight - marginLeft) / 2) || (this.width / 2), (this.properties.height / 2 + (marginTop - marginBottom) / 2) || (this.height / 2));

            setTimeout(function () {
                this.parent && this.parent.onComponentSizeChanged();
            }.bind(this), 0);
        }.bind(this));
    },

    showText: function () {
        if (this.properties.appear === "byLetter") {
            this.title.byLetterAnimation({
                speed: this.properties.typingSpeed || 0.02,
                callback: function () {
                    setTimeout(function () {
                        if (!this.properties.isPreview) {
                            this.eventBus.trigger(Wysiwyg.EVENTS.SCENE_COMPLETED);
                            this.removeFromParent(true);
                        }
                    }.bind(this), this.properties.beforeCompleteDelay === undefined ? 1700 : this.properties.beforeCompleteDelay);
                }.bind(this)
            });
        } else if (this.properties.appear === "writing") {
            this.title.byLetterAnimation({ speed: this.properties.typingSpeed || 0.15 });
        }
    },

    speak: function () {
        if (this.properties.speechSynthesis && !this.properties.isPreview) {
            var utterThis = new SpeechSynthesisUtterance(this.text);
            if (this.properties.voice === "Male") {
                utterThis.voice = this.synth.getVoices().filter(function (voice) {
                    return voice.lang.indexOf(cleverapps.settings.language) !== -1 && voice.name.indexOf("Male") !== -1;
                })[0];

                if (!utterThis.voice && cleverapps.settings.language === "en") {
                    cleverapps.notification.create("male(" + cleverapps.settings.language + ") voice not found!!!\n" + this.synth.getVoices().map(function (voice) {
                        return voice.lang + " " + voice.name;
                    }).join("\n"));
                }
            }
            utterThis.lang = cleverapps.settings.language;
            this.synth.speak(utterThis);
        }
    }
});

AdsText.PROPERTIES = [{
    name: "LocalizedText", type: "localizedText", bubbleUp: true
}, {
    name: "font", type: "font", bubbleUp: true
}, {
    name: "height", type: "number", value: 10, allowOverride: true
}, {
    name: "width", type: "number", value: 20, allowOverride: true
}, {
    name: "appear", type: "string", items: ["instant", "byLetter", "writing"], value: "instant"
}, {
    name: "beforeCompleteDelay", type: "number", value: 1700
}, {
    name: "typingSpeed", type: "number", placeHolderText: "in seconds e.g.: 0.02"
}, {
    name: "speechSynthesis", type: "checkBox"
}, {
    name: "voice", type: "string", items: ["Female", "Male"], value: "Female"
}, {
    name: "background", type: "textBg", folders: ["scale9"]
}, {
    name: "scale", type: "number", disabled: true
}];