/**
 * Created by vladislav on 3/25/19
 */

var FigureDragonCell = function (x, y) {
    BaseCell.call(this, x, y);

    this.specialShapeComponent = this.addComponent(SpecialShapeComponent, { cellClass: FigureDragonCell });

    this.isForGoal = x === undefined;
    this.isForEditor = x === -1;

    this.hurtable = true;
};

FigureDragonCell.prototype = Object.create(BaseCell.prototype);
FigureDragonCell.prototype.constructor = FigureDragonCell;

FigureDragonCell.prototype.getGoalId = function () {
    return FigureDragonCell.GOAL_ID;
};

FigureDragonCell.prototype.editorComponents = function () {
    if (this.specialShapeComponent.isHead) {
        return [BaseCellComponent, SpecialShapeCellComponent, LiveLineEditorComponent];
    }
};

FigureDragonCell.prototype.boom = function () {
    this.hurt();
};

FigureDragonCell.prototype.bundleId = function () {
    return "figure_dragon";
};

FigureDragonCell.prototype.getConfig = function () {
    return FigureDragonCell.CONFIGS[this.configId];
};

FigureDragonCell.prototype.getViewClass = function () {
    return FigureDragonCellView;
};

FigureDragonCell.prototype.explodeDuration = function () {
    return 1;
};

FigureDragonCell.prototype.smile = function () {
    var specialShapeComponent = this.findComponent(SpecialShapeComponent);
    if (!this.alive || !specialShapeComponent || !specialShapeComponent.isHead) {
        return;
    }
    this.animate(BaseCell.ANIMATION_SMILE);
};

FigureDragonCell.GOAL_ID = "figure_dragon";

FigureDragonCell.TYPE_0 = 0;
FigureDragonCell.TYPE_1 = 1;
FigureDragonCell.TYPE_2 = 2;
FigureDragonCell.TYPE_3 = 3;

FigureDragonCell.CONFIGS = {};

FigureDragonCell.CONFIGS[FigureDragonCell.TYPE_0] = [
    [1, 1],
    [1, 1]
];

FigureDragonCell.CONFIGS[FigureDragonCell.TYPE_1] = [
    [1, 0],
    [1, 1],
    [0, 1]
];

FigureDragonCell.CONFIGS[FigureDragonCell.TYPE_2] = [
    [0, 1],
    [0, 1],
    [1, 1]
];

FigureDragonCell.CONFIGS[FigureDragonCell.TYPE_3] = [
    [1],
    [1],
    [1],
    [1]
];