/**
 * Created by slava on 01.08.17.
 */

var ShineView = cc.Node.extend({
    ctor: function () {
        this._super();

        this.shine = new cleverapps.Spine(bundles.shine.jsons.target_json);
        this.addChild(this.shine);

        this.setPosition(cleverapps.styles.BaseCellView.CELL_SIZE_PX / 2, cleverapps.styles.BaseCellView.CELL_SIZE_PX / 2);

        this.prepareForCell();
    },

    prepareForCell: function () {
        this.shine.setAnimation(0, "target", true);

        if (cleverapps.styles.ShineView.zIndex) {
            this.setLocalZOrder(cleverapps.styles.ShineView.zIndex);
        }
    },

    reuse: function () {
        this.prepareForCell();
    },

    unuse: function () {
        this.shine.clearTrack(0);
        this.removeFromParent(false);
    }
});

ShineView.factory = function () {
    // if (cc.pool.hasObject(MarkView)) {
    //     console.log("FROM POOL");
    // }
    return cc.pool.hasObject(ShineView) ? cc.pool.getFromPool(ShineView) : new ShineView();
};

cleverapps.styles.ShineView = {
    zIndex: 0
};