// https://cocos2d-x.org/filecenter/jsbuilder?ver=v3.13
var CocosTransitions = {
    init: function () {
        cc.NodeGrid = cc.Node.extend({
            grid: null,
            _target: null,
            _gridRect: null,
            ctor: function (rect) {
                cc.Node.prototype.ctor.call(this);
                if (rect === undefined) {
                    rect = cc.rect();
                }
                this._gridRect = rect;
            },
            getGrid: function () {
                return this.grid;
            },
            setGrid: function (grid) {
                this.grid = grid;
            },
            setGridRect: function (rect) {
                this._gridRect = rect;
            },
            getGridRect: function () {
                return this._gridRect;
            },
            setTarget: function (target) {
                this._target = target;
            },
            _createRenderCmd: function () {
                if (cc._renderType === cc.game.RENDER_TYPE_WEBGL) {
                    return new cc.NodeGrid.WebGLRenderCmd(this);
                }
                return new cc.Node.CanvasRenderCmd(this);
            }
        });
        var _p = cc.NodeGrid.prototype;
        _p.grid;
        _p.target;
        cc.defineGetterSetter(_p, "target", null, _p.setTarget);
        cc.NodeGrid.create = function () {
            return new cc.NodeGrid();
        };
        (function () {
            cc.NodeGrid.WebGLRenderCmd = function (renderable) {
                cc.Node.WebGLRenderCmd.call(this, renderable);
                this._needDraw = false;
                this._gridBeginCommand = new cc.CustomRenderCmd(this, this.onGridBeginDraw);
                this._gridEndCommand = new cc.CustomRenderCmd(this, this.onGridEndDraw);
            };
            var proto = cc.NodeGrid.WebGLRenderCmd.prototype = Object.create(cc.Node.WebGLRenderCmd.prototype);
            proto.constructor = cc.NodeGrid.WebGLRenderCmd;
            proto.visit = function (parentCmd) {
                var node = this._node;
                if (!node._visible) {
                    return;
                }
                parentCmd = parentCmd || this.getParentRenderCmd();
                if (node._parent && node._parent._renderCmd) {
                    this._curLevel = node._parent._renderCmd._curLevel + 1;
                }
                var currentStack = cc.current_stack;
                currentStack.stack.push(currentStack.top);
                this._syncStatus(parentCmd);
                currentStack.top = this._stackMatrix;
                cc.renderer.pushRenderCommand(this._gridBeginCommand);
                if (node._target) {
                    node._target.visit();
                }
                var locChildren = node._children;
                if (locChildren && locChildren.length > 0) {
                    var childLen = locChildren.length;
                    node.sortAllChildren();
                    for (var i = 0; i < childLen; i++) {
                        var child = locChildren[i];
                        child && child.visit();
                    }
                }
                cc.renderer.pushRenderCommand(this._gridEndCommand);
                this._dirtyFlag = 0;
                currentStack.top = currentStack.stack.pop();
            };
            proto.onGridBeginDraw = function () {
                var locGrid = this._node.grid;
                if (locGrid && locGrid._active) {
                    locGrid.beforeDraw();
                }
            };
            proto.onGridEndDraw = function () {
                var locGrid = this._node.grid;
                if (locGrid && locGrid._active) {
                    locGrid.afterDraw(this._node);
                }
            };
        }());
        cc.GridBase = cc.Class.extend({
            _active: false,
            _reuseGrid: 0,
            _gridSize: null,
            _gridRect: null,
            _texture: null,
            _step: null,
            _grabber: null,
            _isTextureFlipped: false,
            _shaderProgram: null,
            _directorProjection: 0,
            _dirty: false,
            ctor: function (gridSize, texture, flipped, rect) {
                cc.sys._checkWebGLRenderMode();
                this._active = false;
                this._reuseGrid = 0;
                this._gridSize = null;
                this._gridRect = new cc.rect();
                this._texture = null;
                this._step = cc.p(0, 0);
                this._grabber = null;
                this._isTextureFlipped = false;
                this._shaderProgram = null;
                this._directorProjection = 0;
                this._dirty = false;
                if (gridSize !== undefined) {
                    this.initWithSize(gridSize, texture, flipped, rect);
                }
            },
            isActive: function () {
                return this._active;
            },
            setActive: function (active) {
                this._active = active;
                if (!active) {
                    var director = cc.director;
                    var proj = director.getProjection();
                    director.setProjection(proj);
                }
            },
            getReuseGrid: function () {
                return this._reuseGrid;
            },
            setReuseGrid: function (reuseGrid) {
                this._reuseGrid = reuseGrid;
            },
            getGridSize: function () {
                return cc.size(this._gridSize.width, this._gridSize.height);
            },
            setGridSize: function (gridSize) {
                this._gridSize.width = parseInt(gridSize.width);
                this._gridSize.height = parseInt(gridSize.height);
            },
            setGridRect: function (rect) {
                this._gridRect = rect;
            },
            getGridRect: function () {
                return this._gridRect;
            },
            getStep: function () {
                return cc.p(this._step.x, this._step.y);
            },
            setStep: function (step) {
                this._step.x = step.x;
                this._step.y = step.y;
            },
            isTextureFlipped: function () {
                return this._isTextureFlipped;
            },
            setTextureFlipped: function (flipped) {
                if (this._isTextureFlipped !== flipped) {
                    this._isTextureFlipped = flipped;
                    this.calculateVertexPoints();
                }
            },
            initWithSize: function (gridSize, texture, flipped, rect) {
                if (!texture) {
                    var director = cc.director;
                    var winSize = director.getWinSizeInPixels();
                    var POTWide = cc.NextPOT(winSize.width);
                    var POTHigh = cc.NextPOT(winSize.height);
                    var data = new Uint8Array(POTWide * POTHigh * 4);
                    if (!data) {
                        cc.log("cocos2d: CCGrid: not enough memory.");
                        return false;
                    }
                    texture = new cc.Texture2D();
                    texture.initWithData(data, cc.Texture2D.PIXEL_FORMAT_RGBA8888, POTWide, POTHigh, winSize);
                    if (!texture) {
                        cc.log("cocos2d: CCGrid: error creating texture");
                        return false;
                    }
                }
                flipped = flipped || false;
                this._active = false;
                this._reuseGrid = 0;
                this._gridSize = gridSize;
                this._texture = texture;
                this._isTextureFlipped = flipped;
                if (rect === undefined || cc._rectEqualToZero(rect)) {
                    var size = this._texture.getContentSize();
                    rect = new cc.rect(0, 0, size.width, size.height);
                }
                this._gridRect = rect;
                this._step.x = this._gridRect.width / gridSize.width;
                this._step.y = this._gridRect.height / gridSize.height;
                this._grabber = new cc.Grabber();
                if (!this._grabber) {
                    return false;
                }
                this._grabber.grab(this._texture);
                this._shaderProgram = cc.shaderCache.programForKey(cc.SHADER_POSITION_TEXTURE);
                this.calculateVertexPoints();
                return true;
            },
            beforeDraw: function () {
                this._directorProjection = cc.director.getProjection();
                var size = cc.director.getWinSizeInPixels();
                gl.viewport(0, 0, size.width, size.height);
                this._grabber.beforeRender(this._texture);
            },
            afterDraw: function (target) {
                this._grabber.afterRender(this._texture);
                cc.director.setViewport();
                cc.glBindTexture2D(this._texture);
                this.beforeBlit();
                this.blit(target);
                this.afterBlit();
            },
            beforeBlit: function () {
            },
            afterBlit: function () {
            },
            blit: function () {
                cc.log("cc.GridBase.blit(): Shall be overridden in subclass.");
            },
            reuse: function () {
                cc.log("cc.GridBase.reuse(): Shall be overridden in subclass.");
            },
            calculateVertexPoints: function () {
                cc.log("cc.GridBase.calculateVertexPoints(): Shall be overridden in subclass.");
            },
            set2DProjection: function () {
                var winSize = cc.director.getWinSizeInPixels();
                var gl = cc._renderContext;
                gl.viewport(0, 0, winSize.width, winSize.height);
                cc.kmGLMatrixMode(cc.KM_GL_PROJECTION);
                cc.kmGLLoadIdentity();
                var orthoMatrix = cc.math.Matrix4.createOrthographicProjection(0, winSize.width, 0, winSize.height, -1, 1);
                cc.kmGLMultMatrix(orthoMatrix);
                cc.kmGLMatrixMode(cc.KM_GL_MODELVIEW);
                cc.kmGLLoadIdentity();
                cc.setProjectionMatrixDirty();
            }
        });
        cc.GridBase.create = function (gridSize, texture, flipped, rect) {
            return new cc.GridBase(gridSize, texture, flipped, rect);
        };
        cc.Grid3D = cc.GridBase.extend({
            _texCoordinates: null,
            _vertices: null,
            _originalVertices: null,
            _indices: null,
            _texCoordinateBuffer: null,
            _verticesBuffer: null,
            _indicesBuffer: null,
            _needDepthTestForBlit: false,
            _oldDepthTestValue: false,
            _oldDepthWriteValue: false,
            ctor: function (gridSize, texture, flipped, rect) {
                cc.GridBase.prototype.ctor.call(this);
                this._texCoordinates = null;
                this._vertices = null;
                this._originalVertices = null;
                this._indices = null;
                this._texCoordinateBuffer = null;
                this._verticesBuffer = null;
                this._indicesBuffer = null;
                this._matrix = new cc.math.Matrix4();
                this._matrix.identity();
                if (gridSize !== undefined) {
                    this.initWithSize(gridSize, texture, flipped, rect);
                }
            },
            vertex: function (pos) {
                return this.getVertex(pos);
            },
            getVertex: function (pos) {
                if (pos.x !== (0 | pos.x) || pos.y !== (0 | pos.y)) {
                    cc.log("cc.Grid3D.vertex() : Numbers must be integers");
                }
                var index = 0 | ((pos.x * (this._gridSize.height + 1) + pos.y) * 3);
                var locVertices = this._vertices;
                return new cc.Vertex3F(locVertices[index], locVertices[index + 1], locVertices[index + 2]);
            },
            originalVertex: function (pos) {
                return this.getOriginalVertex(pos);
            },
            getOriginalVertex: function (pos) {
                if (pos.x !== (0 | pos.x) || pos.y !== (0 | pos.y)) {
                    cc.log("cc.Grid3D.originalVertex() : Numbers must be integers");
                }
                var index = 0 | ((pos.x * (this._gridSize.height + 1) + pos.y) * 3);
                var locOriginalVertices = this._originalVertices;
                return new cc.Vertex3F(locOriginalVertices[index], locOriginalVertices[index + 1], locOriginalVertices[index + 2]);
            },
            setVertex: function (pos, vertex) {
                if (pos.x !== (0 | pos.x) || pos.y !== (0 | pos.y)) {
                    cc.log("cc.Grid3D.setVertex() : Numbers must be integers");
                }
                var index = 0 | ((pos.x * (this._gridSize.height + 1) + pos.y) * 3);
                var vertArray = this._vertices;
                vertArray[index] = vertex.x;
                vertArray[index + 1] = vertex.y;
                vertArray[index + 2] = vertex.z;
                this._dirty = true;
            },
            beforeBlit: function () {
                if (this._needDepthTestForBlit) {
                    var gl = cc._renderContext;
                    this._oldDepthTestValue = gl.isEnabled(gl.DEPTH_TEST);
                    this._oldDepthWriteValue = gl.getParameter(gl.DEPTH_WRITEMASK);
                    gl.enable(gl.DEPTH_TEST);
                    gl.depthMask(true);
                }
            },
            afterBlit: function () {
                if (this._needDepthTestForBlit) {
                    var gl = cc._renderContext;
                    if (this._oldDepthTestValue) {
                        gl.enable(gl.DEPTH_TEST);
                    } else {
                        gl.disable(gl.DEPTH_TEST);
                    }
                    gl.depthMask(this._oldDepthWriteValue);
                }
            },
            blit: function (target) {
                var n = this._gridSize.width * this._gridSize.height;
                var wt = target._renderCmd._worldTransform;
                this._matrix.mat[0] = wt.a;
                this._matrix.mat[4] = wt.c;
                this._matrix.mat[12] = wt.tx;
                this._matrix.mat[1] = wt.b;
                this._matrix.mat[5] = wt.d;
                this._matrix.mat[13] = wt.ty;
                this._shaderProgram.use();
                this._shaderProgram._setUniformForMVPMatrixWithMat4(this._matrix);
                var gl = cc._renderContext, locDirty = this._dirty;
                gl.enableVertexAttribArray(cc.VERTEX_ATTRIB_POSITION);
                gl.enableVertexAttribArray(cc.VERTEX_ATTRIB_TEX_COORDS);
                gl.bindBuffer(gl.ARRAY_BUFFER, this._verticesBuffer);
                if (locDirty) {
                    gl.bufferData(gl.ARRAY_BUFFER, this._vertices, gl.DYNAMIC_DRAW);
                }
                gl.vertexAttribPointer(cc.VERTEX_ATTRIB_POSITION, 3, gl.FLOAT, false, 0, 0);
                gl.bindBuffer(gl.ARRAY_BUFFER, this._texCoordinateBuffer);
                if (locDirty) {
                    gl.bufferData(gl.ARRAY_BUFFER, this._texCoordinates, gl.DYNAMIC_DRAW);
                }
                gl.vertexAttribPointer(cc.VERTEX_ATTRIB_TEX_COORDS, 2, gl.FLOAT, false, 0, 0);
                gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, this._indicesBuffer);
                if (locDirty) {
                    gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, this._indices, gl.STATIC_DRAW);
                }
                gl.drawElements(gl.TRIANGLES, n * 6, gl.UNSIGNED_SHORT, 0);
                if (locDirty) {
                    this._dirty = false;
                }
                cc.incrementGLDraws(1);
            },
            reuse: function () {
                if (this._reuseGrid > 0) {
                    var locOriginalVertices = this._originalVertices, locVertices = this._vertices;
                    for (var i = 0, len = this._vertices.length; i < len; i++) {
                        locOriginalVertices[i] = locVertices[i];
                    }
                    --this._reuseGrid;
                }
            },
            calculateVertexPoints: function () {
                var gl = cc._renderContext;
                var width = this._texture.pixelsWidth;
                var height = this._texture.pixelsHeight;
                var imageH = this._texture.getContentSizeInPixels().height;
                var locGridSize = this._gridSize;
                var numOfPoints = (locGridSize.width + 1) * (locGridSize.height + 1);
                this._vertices = new Float32Array(numOfPoints * 3);
                this._texCoordinates = new Float32Array(numOfPoints * 2);
                this._indices = new Uint16Array(locGridSize.width * locGridSize.height * 6);
                if (this._verticesBuffer) {
                    gl.deleteBuffer(this._verticesBuffer);
                }
                this._verticesBuffer = gl.createBuffer();
                if (this._texCoordinateBuffer) {
                    gl.deleteBuffer(this._texCoordinateBuffer);
                }
                this._texCoordinateBuffer = gl.createBuffer();
                if (this._indicesBuffer) {
                    gl.deleteBuffer(this._indicesBuffer);
                }
                this._indicesBuffer = gl.createBuffer();
                var x, y, i, locIndices = this._indices, locTexCoordinates = this._texCoordinates;
                var locIsTextureFlipped = this._isTextureFlipped, locVertices = this._vertices;
                for (x = 0; x < locGridSize.width; ++x) {
                    for (y = 0; y < locGridSize.height; ++y) {
                        var idx = (y * locGridSize.width) + x;
                        var x1 = x * this._step.x + this._gridRect.x;
                        var x2 = x1 + this._step.x;
                        var y1 = y * this._step.y + this._gridRect.y;
                        var y2 = y1 + this._step.y;
                        var a = (x * (locGridSize.height + 1) + y);
                        var b = ((x + 1) * (locGridSize.height + 1) + y);
                        var c = ((x + 1) * (locGridSize.height + 1) + (y + 1));
                        var d = (x * (locGridSize.height + 1) + (y + 1));
                        locIndices[idx * 6] = a;
                        locIndices[idx * 6 + 1] = b;
                        locIndices[idx * 6 + 2] = d;
                        locIndices[idx * 6 + 3] = b;
                        locIndices[idx * 6 + 4] = c;
                        locIndices[idx * 6 + 5] = d;
                        var l1 = [a * 3, b * 3, c * 3, d * 3];
                        var e = { x: x1, y: y1, z: 0 };
                        var f = { x: x2, y: y1, z: 0 };
                        var g = { x: x2, y: y2, z: 0 };
                        var h = { x: x1, y: y2, z: 0 };
                        var l2 = [e, f, g, h];
                        var tex1 = [a * 2, b * 2, c * 2, d * 2];
                        var tex2 = [cc.p(x1, y1), cc.p(x2, y1), cc.p(x2, y2), cc.p(x1, y2)];
                        for (i = 0; i < 4; ++i) {
                            locVertices[l1[i]] = l2[i].x;
                            locVertices[l1[i] + 1] = l2[i].y;
                            locVertices[l1[i] + 2] = l2[i].z;
                            locTexCoordinates[tex1[i]] = tex2[i].x / width;
                            if (locIsTextureFlipped) {
                                locTexCoordinates[tex1[i] + 1] = (imageH - tex2[i].y) / height;
                            } else {
                                locTexCoordinates[tex1[i] + 1] = tex2[i].y / height;
                            }
                        }
                    }
                }
                this._originalVertices = new Float32Array(this._vertices);
                gl.bindBuffer(gl.ARRAY_BUFFER, this._verticesBuffer);
                gl.bufferData(gl.ARRAY_BUFFER, this._vertices, gl.DYNAMIC_DRAW);
                gl.bindBuffer(gl.ARRAY_BUFFER, this._texCoordinateBuffer);
                gl.bufferData(gl.ARRAY_BUFFER, this._texCoordinates, gl.DYNAMIC_DRAW);
                gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, this._indicesBuffer);
                gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, this._indices, gl.STATIC_DRAW);
                this._dirty = true;
            },
            setNeedDepthTestForBlit: function (needDepthTest) {
                this._needDepthTestForBlit = needDepthTest;
            },
            getNeedDepthTestForBlit: function () {
                return this._needDepthTestForBlit;
            }
        });
        cc.Grid3D.create = function (gridSize, texture, flipped) {
            return new cc.Grid3D(gridSize, texture, flipped);
        };
        cc.TiledGrid3D = cc.GridBase.extend({
            _texCoordinates: null,
            _vertices: null,
            _originalVertices: null,
            _indices: null,
            _texCoordinateBuffer: null,
            _verticesBuffer: null,
            _indicesBuffer: null,
            ctor: function (gridSize, texture, flipped, rect) {
                cc.GridBase.prototype.ctor.call(this);
                this._texCoordinates = null;
                this._vertices = null;
                this._originalVertices = null;
                this._indices = null;
                this._texCoordinateBuffer = null;
                this._verticesBuffer = null;
                this._indicesBuffer = null;
                this._matrix = new cc.math.Matrix4();
                this._matrix.identity();
                if (gridSize !== undefined) {
                    this.initWithSize(gridSize, texture, flipped, rect);
                }
            },
            tile: function (pos) {
                return this.getTile(pos);
            },
            getTile: function (pos) {
                if (pos.x !== (0 | pos.x) || pos.y !== (0 | pos.y)) {
                    cc.log("cc.TiledGrid3D.tile() : Numbers must be integers");
                }
                var idx = (this._gridSize.height * pos.x + pos.y) * 4 * 3;
                var locVertices = this._vertices;
                return new cc.Quad3(
                    new cc.Vertex3F(locVertices[idx], locVertices[idx + 1], locVertices[idx + 2]),
                    new cc.Vertex3F(locVertices[idx + 3], locVertices[idx + 4], locVertices[idx + 5]),
                    new cc.Vertex3F(locVertices[idx + 6], locVertices[idx + 7], locVertices[idx + 8]),
                    new cc.Vertex3F(locVertices[idx + 9], locVertices[idx + 10], locVertices[idx + 11])
                );
            },
            getOriginalTile: function (pos) {
                if (pos.x !== (0 | pos.x) || pos.y !== (0 | pos.y)) {
                    cc.log("cc.TiledGrid3D.originalTile() : Numbers must be integers");
                }
                var idx = (this._gridSize.height * pos.x + pos.y) * 4 * 3;
                var locOriginalVertices = this._originalVertices;
                return new cc.Quad3(
                    new cc.Vertex3F(locOriginalVertices[idx], locOriginalVertices[idx + 1], locOriginalVertices[idx + 2]),
                    new cc.Vertex3F(locOriginalVertices[idx + 3], locOriginalVertices[idx + 4], locOriginalVertices[idx + 5]),
                    new cc.Vertex3F(locOriginalVertices[idx + 6], locOriginalVertices[idx + 7], locOriginalVertices[idx + 8]),
                    new cc.Vertex3F(locOriginalVertices[idx + 9], locOriginalVertices[idx + 10], locOriginalVertices[idx + 11])
                );
            },
            originalTile: function (pos) {
                return this.getOriginalTile(pos);
            },
            setTile: function (pos, coords) {
                if (pos.x !== (0 | pos.x) || pos.y !== (0 | pos.y)) {
                    cc.log("cc.TiledGrid3D.setTile() : Numbers must be integers");
                }
                var idx = (this._gridSize.height * pos.x + pos.y) * 12;
                var locVertices = this._vertices;
                locVertices[idx] = coords.bl.x;
                locVertices[idx + 1] = coords.bl.y;
                locVertices[idx + 2] = coords.bl.z;
                locVertices[idx + 3] = coords.br.x;
                locVertices[idx + 4] = coords.br.y;
                locVertices[idx + 5] = coords.br.z;
                locVertices[idx + 6] = coords.tl.x;
                locVertices[idx + 7] = coords.tl.y;
                locVertices[idx + 8] = coords.tl.z;
                locVertices[idx + 9] = coords.tr.x;
                locVertices[idx + 10] = coords.tr.y;
                locVertices[idx + 11] = coords.tr.z;
                this._dirty = true;
            },
            blit: function (target) {
                var n = this._gridSize.width * this._gridSize.height;
                var wt = target._renderCmd._worldTransform;
                this._matrix.mat[0] = wt.a;
                this._matrix.mat[4] = wt.c;
                this._matrix.mat[12] = wt.tx;
                this._matrix.mat[1] = wt.b;
                this._matrix.mat[5] = wt.d;
                this._matrix.mat[13] = wt.ty;
                this._shaderProgram.use();
                this._shaderProgram._setUniformForMVPMatrixWithMat4(this._matrix);
                var gl = cc._renderContext, locDirty = this._dirty;
                gl.enableVertexAttribArray(cc.VERTEX_ATTRIB_POSITION);
                gl.enableVertexAttribArray(cc.VERTEX_ATTRIB_TEX_COORDS);
                gl.bindBuffer(gl.ARRAY_BUFFER, this._verticesBuffer);
                if (locDirty) {
                    gl.bufferData(gl.ARRAY_BUFFER, this._vertices, gl.DYNAMIC_DRAW);
                }
                gl.vertexAttribPointer(cc.VERTEX_ATTRIB_POSITION, 3, gl.FLOAT, false, 0, this._vertices);
                gl.bindBuffer(gl.ARRAY_BUFFER, this._texCoordinateBuffer);
                if (locDirty) {
                    gl.bufferData(gl.ARRAY_BUFFER, this._texCoordinates, gl.DYNAMIC_DRAW);
                }
                gl.vertexAttribPointer(cc.VERTEX_ATTRIB_TEX_COORDS, 2, gl.FLOAT, false, 0, this._texCoordinates);
                gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, this._indicesBuffer);
                if (locDirty) {
                    gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, this._indices, gl.STATIC_DRAW);
                }
                gl.drawElements(gl.TRIANGLES, n * 6, gl.UNSIGNED_SHORT, 0);
                if (locDirty) {
                    this._dirty = false;
                }
                cc.incrementGLDraws(1);
            },
            reuse: function () {
                if (this._reuseGrid > 0) {
                    var locVertices = this._vertices, locOriginalVertices = this._originalVertices;
                    for (var i = 0; i < locVertices.length; i++) {
                        locOriginalVertices[i] = locVertices[i];
                    }
                    --this._reuseGrid;
                }
            },
            calculateVertexPoints: function () {
                var width = this._texture.pixelsWidth;
                var height = this._texture.pixelsHeight;
                var imageH = this._texture.getContentSizeInPixels().height;
                var locGridSize = this._gridSize;
                var numQuads = locGridSize.width * locGridSize.height;
                this._vertices = new Float32Array(numQuads * 12);
                this._texCoordinates = new Float32Array(numQuads * 8);
                this._indices = new Uint16Array(numQuads * 6);
                var gl = cc._renderContext;
                if (this._verticesBuffer) {
                    gl.deleteBuffer(this._verticesBuffer);
                }
                this._verticesBuffer = gl.createBuffer();
                if (this._texCoordinateBuffer) {
                    gl.deleteBuffer(this._texCoordinateBuffer);
                }
                this._texCoordinateBuffer = gl.createBuffer();
                if (this._indicesBuffer) {
                    gl.deleteBuffer(this._indicesBuffer);
                }
                this._indicesBuffer = gl.createBuffer();
                var x, y, i = 0;
                var locStep = this._step, locVertices = this._vertices, locTexCoords = this._texCoordinates,
                    locIsTextureFlipped = this._isTextureFlipped;
                for (x = 0; x < locGridSize.width; x++) {
                    for (y = 0; y < locGridSize.height; y++) {
                        var x1 = x * locStep.x;
                        var x2 = x1 + locStep.x;
                        var y1 = y * locStep.y;
                        var y2 = y1 + locStep.y;
                        locVertices[i * 12] = x1;
                        locVertices[i * 12 + 1] = y1;
                        locVertices[i * 12 + 2] = 0;
                        locVertices[i * 12 + 3] = x2;
                        locVertices[i * 12 + 4] = y1;
                        locVertices[i * 12 + 5] = 0;
                        locVertices[i * 12 + 6] = x1;
                        locVertices[i * 12 + 7] = y2;
                        locVertices[i * 12 + 8] = 0;
                        locVertices[i * 12 + 9] = x2;
                        locVertices[i * 12 + 10] = y2;
                        locVertices[i * 12 + 11] = 0;
                        var newY1 = y1;
                        var newY2 = y2;
                        if (locIsTextureFlipped) {
                            newY1 = imageH - y1;
                            newY2 = imageH - y2;
                        }
                        locTexCoords[i * 8] = x1 / width;
                        locTexCoords[i * 8 + 1] = newY1 / height;
                        locTexCoords[i * 8 + 2] = x2 / width;
                        locTexCoords[i * 8 + 3] = newY1 / height;
                        locTexCoords[i * 8 + 4] = x1 / width;
                        locTexCoords[i * 8 + 5] = newY2 / height;
                        locTexCoords[i * 8 + 6] = x2 / width;
                        locTexCoords[i * 8 + 7] = newY2 / height;
                        i++;
                    }
                }
                var locIndices = this._indices;
                for (x = 0; x < numQuads; x++) {
                    locIndices[x * 6 + 0] = (x * 4 + 0);
                    locIndices[x * 6 + 1] = (x * 4 + 1);
                    locIndices[x * 6 + 2] = (x * 4 + 2);
                    locIndices[x * 6 + 3] = (x * 4 + 1);
                    locIndices[x * 6 + 4] = (x * 4 + 2);
                    locIndices[x * 6 + 5] = (x * 4 + 3);
                }
                this._originalVertices = new Float32Array(this._vertices);
                gl.bindBuffer(gl.ARRAY_BUFFER, this._verticesBuffer);
                gl.bufferData(gl.ARRAY_BUFFER, this._vertices, gl.DYNAMIC_DRAW);
                gl.bindBuffer(gl.ARRAY_BUFFER, this._texCoordinateBuffer);
                gl.bufferData(gl.ARRAY_BUFFER, this._texCoordinates, gl.DYNAMIC_DRAW);
                gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, this._indicesBuffer);
                gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, this._indices, gl.DYNAMIC_DRAW);
                this._dirty = true;
            }
        });
        cc.TiledGrid3D.create = function (gridSize, texture, flipped) {
            return new cc.TiledGrid3D(gridSize, texture, flipped);
        };
        cc.Grabber = cc.Class.extend({
            _FBO: null,
            _oldFBO: null,
            _oldClearColor: null,
            _gl: null,
            ctor: function () {
                cc.sys._checkWebGLRenderMode();
                this._gl = cc._renderContext;
                this._oldClearColor = [0, 0, 0, 0];
                this._oldFBO = null;
                this._FBO = this._gl.createFramebuffer();
            },
            grab: function (texture) {
                var locGL = this._gl;
                this._oldFBO = locGL.getParameter(locGL.FRAMEBUFFER_BINDING);
                locGL.bindFramebuffer(locGL.FRAMEBUFFER, this._FBO);
                locGL.framebufferTexture2D(locGL.FRAMEBUFFER, locGL.COLOR_ATTACHMENT0, locGL.TEXTURE_2D, texture._webTextureObj, 0);
                var status = locGL.checkFramebufferStatus(locGL.FRAMEBUFFER);
                if (status !== locGL.FRAMEBUFFER_COMPLETE) {
                    cc.log("Frame Grabber: could not attach texture to frmaebuffer");
                }
                locGL.bindFramebuffer(locGL.FRAMEBUFFER, this._oldFBO);
            },
            beforeRender: function (texture) {
                var locGL = this._gl;
                this._oldFBO = locGL.getParameter(locGL.FRAMEBUFFER_BINDING);
                locGL.bindFramebuffer(locGL.FRAMEBUFFER, this._FBO);
                this._oldClearColor = locGL.getParameter(locGL.COLOR_CLEAR_VALUE);
                locGL.clearColor(0, 0, 0, 0);
                locGL.clear(locGL.COLOR_BUFFER_BIT | locGL.DEPTH_BUFFER_BIT);
            },
            afterRender: function (texture) {
                var locGL = this._gl;
                locGL.bindFramebuffer(locGL.FRAMEBUFFER, this._oldFBO);
                locGL.colorMask(true, true, true, true);
            },
            destroy: function () {
                this._gl.deleteFramebuffer(this._FBO);
            }
        });
        cc.GridAction = cc.ActionInterval.extend({
            _gridSize: null,
            _gridNodeTarget: null,
            ctor: function (duration, gridSize) {
                cc.sys._checkWebGLRenderMode();
                cc.ActionInterval.prototype.ctor.call(this);
                this._gridSize = cc.size(0, 0);
                gridSize && this.initWithDuration(duration, gridSize);
            },
            _cacheTargetAsGridNode: function (target) {
                this._gridNodeTarget = target;
            },
            clone: function () {
                var action = new cc.GridAction();
                var locGridSize = this._gridSize;
                action.initWithDuration(this._duration, cc.size(locGridSize.width, locGridSize.height));
                return action;
            },
            startWithTarget: function (target) {
                cc.ActionInterval.prototype.startWithTarget.call(this, target);
                cc.renderer.childrenOrderDirty = true;
                this._cacheTargetAsGridNode(target);
                var newGrid = this.getGrid();
                var targetGrid = this._gridNodeTarget.getGrid();
                if (targetGrid && targetGrid.getReuseGrid() > 0) {
                    var locGridSize = targetGrid.getGridSize();
                    if (targetGrid.isActive() && (locGridSize.width === this._gridSize.width) && (locGridSize.height === this._gridSize.height)) {
                        targetGrid.reuse();
                    }
                } else {
                    if (targetGrid && targetGrid.isActive()) {
                        targetGrid.setActive(false);
                    }
                    this._gridNodeTarget.setGrid(newGrid);
                    this._gridNodeTarget.getGrid().setActive(true);
                }
            },
            reverse: function () {
                return new cc.ReverseTime(this);
            },
            initWithDuration: function (duration, gridSize) {
                if (cc.ActionInterval.prototype.initWithDuration.call(this, duration)) {
                    this._gridSize.width = gridSize.width;
                    this._gridSize.height = gridSize.height;
                    return true;
                }
                return false;
            },
            getGrid: function () {
                cc.log("cc.GridAction.getGrid(): it should be overridden in subclass.");
            }
        });
        cc.gridAction = function (duration, gridSize) {
            return new cc.GridAction(duration, gridSize);
        };
        cc.GridAction.create = cc.gridAction;
        cc.Grid3DAction = cc.GridAction.extend({
            getGrid: function () {
                return new cc.Grid3D(this._gridSize, undefined, undefined, this._gridNodeTarget.getGridRect());
            },
            getGridRect: function () {
                return this._gridNodeTarget.getGridRect();
            },
            vertex: function (position) {
                return this.getVertex(position);
            },
            getVertex: function (position) {
                return this.target.grid.getVertex(position);
            },
            originalVertex: function (position) {
                return this.getOriginalVertex(position);
            },
            getOriginalVertex: function (position) {
                return this.target.grid.originalVertex(position);
            },
            setVertex: function (position, vertex) {
                this.target.grid.setVertex(position, vertex);
            }
        });
        cc.grid3DAction = function (duration, gridSize) {
            return new cc.Grid3DAction(duration, gridSize);
        };
        cc.Grid3DAction.create = cc.grid3DAction;
        cc.TiledGrid3DAction = cc.GridAction.extend({
            tile: function (position) {
                return this.getTile(position);
            },
            getTile: function (position) {
                return this.target.grid.tile(position);
            },
            originalTile: function (position) {
                return this.getOriginalTile(position);
            },
            getOriginalTile: function (position) {
                return this.target.grid.originalTile(position);
            },
            setTile: function (position, coords) {
                this.target.grid.setTile(position, coords);
            },
            getGrid: function () {
                return new cc.TiledGrid3D(this._gridSize, undefined, undefined, this._gridNodeTarget.getGridRect());
            }
        });
        cc.tiledGrid3DAction = function (duration, gridSize) {
            return new cc.TiledGrid3DAction(duration, gridSize);
        };
        cc.TiledGrid3DAction.create = cc.tiledGrid3DAction;
        cc.StopGrid = cc.ActionInstant.extend({
            startWithTarget: function (target) {
                cc.ActionInstant.prototype.startWithTarget.call(this, target);
                cc.renderer.childrenOrderDirty = true;
                var grid = this.target.grid;
                if (grid && grid.isActive()) {
                    grid.setActive(false);
                }
            }
        });
        cc.stopGrid = function () {
            return new cc.StopGrid();
        };
        cc.StopGrid.create = cc.stopGrid;
        cc.ReuseGrid = cc.ActionInstant.extend({
            _times: null,
            ctor: function (times) {
                cc.ActionInstant.prototype.ctor.call(this);
                times !== undefined && this.initWithTimes(times);
            },
            initWithTimes: function (times) {
                this._times = times;
                return true;
            },
            startWithTarget: function (target) {
                cc.ActionInstant.prototype.startWithTarget.call(this, target);
                cc.renderer.childrenOrderDirty = true;
                if (this.target.grid && this.target.grid.isActive()) {
                    this.target.grid.setReuseGrid(this.target.grid.getReuseGrid() + this._times);
                }
            }
        });
        cc.reuseGrid = function (times) {
            return new cc.ReuseGrid(times);
        };
        cc.ReuseGrid.create = cc.reuseGrid;
        cc.Waves3D = cc.Grid3DAction.extend({
            _waves: 0,
            _amplitude: 0,
            _amplitudeRate: 0,
            ctor: function (duration, gridSize, waves, amplitude) {
                cc.GridAction.prototype.ctor.call(this);
                amplitude !== undefined && this.initWithDuration(duration, gridSize, waves, amplitude);
            },
            getAmplitude: function () {
                return this._amplitude;
            },
            setAmplitude: function (amplitude) {
                this._amplitude = amplitude;
            },
            getAmplitudeRate: function () {
                return this._amplitudeRate;
            },
            setAmplitudeRate: function (amplitudeRate) {
                this._amplitudeRate = amplitudeRate;
            },
            initWithDuration: function (duration, gridSize, waves, amplitude) {
                if (cc.Grid3DAction.prototype.initWithDuration.call(this, duration, gridSize)) {
                    this._waves = waves;
                    this._amplitude = amplitude;
                    this._amplitudeRate = 1.0;
                    return true;
                }
                return false;
            },
            update: function (dt) {
                var locGridSize = this._gridSize;
                var locAmplitude = this._amplitude, locPos = cc.p(0, 0);
                var locAmplitudeRate = this._amplitudeRate, locWaves = this._waves;
                for (var i = 0; i < locGridSize.width + 1; ++i) {
                    for (var j = 0; j < locGridSize.height + 1; ++j) {
                        locPos.x = i;
                        locPos.y = j;
                        var v = this.originalVertex(locPos);
                        v.z += (Math.sin(Math.PI * dt * locWaves * 2 + (v.y + v.x) * 0.01) * locAmplitude * locAmplitudeRate);
                        this.setVertex(locPos, v);
                    }
                }
            }
        });
        cc.waves3D = function (duration, gridSize, waves, amplitude) {
            return new cc.Waves3D(duration, gridSize, waves, amplitude);
        };
        cc.Waves3D.create = cc.waves3D;
        cc.FlipX3D = cc.Grid3DAction.extend({
            ctor: function (duration) {
                if (duration !== undefined) {
                    cc.GridAction.prototype.ctor.call(this, duration, cc.size(1, 1));
                } else {
                    cc.GridAction.prototype.ctor.call(this);
                }
            },
            initWithDuration: function (duration) {
                return cc.Grid3DAction.prototype.initWithDuration.call(this, duration, cc.size(1, 1));
            },
            initWithSize: function (gridSize, duration) {
                if (gridSize.width !== 1 || gridSize.height !== 1) {
                    cc.log("Grid size must be (1,1)");
                    return false;
                }
                return cc.Grid3DAction.prototype.initWithDuration.call(this, duration, gridSize);
            },
            update: function (dt) {
                var angle = Math.PI * dt;
                var mz = Math.sin(angle);
                angle /= 2.0;
                var mx = Math.cos(angle);
                var diff = new cc.Vertex3F();
                var tempVer = cc.p(0, 0);
                tempVer.x = tempVer.y = 1;
                var v0 = this.originalVertex(tempVer);
                tempVer.x = tempVer.y = 0;
                var v1 = this.originalVertex(tempVer);
                var x0 = v0.x;
                var x1 = v1.x;
                var x;
                var a, b, c, d;
                if (x0 > x1) {
                    a = cc.p(0, 0);
                    b = cc.p(0, 1);
                    c = cc.p(1, 0);
                    d = cc.p(1, 1);
                    x = x0;
                } else {
                    c = cc.p(0, 0);
                    d = cc.p(0, 1);
                    a = cc.p(1, 0);
                    b = cc.p(1, 1);
                    x = x1;
                }
                diff.x = (x - x * mx);
                diff.z = Math.abs(parseFloat((x * mz) / 4.0));
                var v = this.originalVertex(a);
                v.x = diff.x;
                v.z += diff.z;
                this.setVertex(a, v);
                v = this.originalVertex(b);
                v.x = diff.x;
                v.z += diff.z;
                this.setVertex(b, v);
                v = this.originalVertex(c);
                v.x -= diff.x;
                v.z -= diff.z;
                this.setVertex(c, v);
                v = this.originalVertex(d);
                v.x -= diff.x;
                v.z -= diff.z;
                this.setVertex(d, v);
            }
        });
        cc.flipX3D = function (duration) {
            return new cc.FlipX3D(duration);
        };
        cc.FlipX3D.create = cc.flipX3D;
        cc.FlipY3D = cc.FlipX3D.extend({
            ctor: function (duration) {
                if (duration !== undefined) {
                    cc.GridAction.prototype.ctor.call(this, duration, cc.size(1, 1));
                } else {
                    cc.GridAction.prototype.ctor.call(this);
                }
            },
            update: function (dt) {
                var angle = Math.PI * dt;
                var mz = Math.sin(angle);
                angle /= 2.0;
                var my = Math.cos(angle);
                var diff = new cc.Vertex3F();
                var tempP = cc.p(0, 0);
                tempP.x = tempP.y = 1;
                var v0 = this.originalVertex(tempP);
                tempP.x = tempP.y = 0;
                var v1 = this.originalVertex(tempP);
                var y0 = v0.y;
                var y1 = v1.y;
                var y;
                var a, b, c, d;
                if (y0 > y1) {
                    a = cc.p(0, 0);
                    b = cc.p(0, 1);
                    c = cc.p(1, 0);
                    d = cc.p(1, 1);
                    y = y0;
                } else {
                    b = cc.p(0, 0);
                    a = cc.p(0, 1);
                    d = cc.p(1, 0);
                    c = cc.p(1, 1);
                    y = y1;
                }
                diff.y = y - y * my;
                diff.z = Math.abs(parseFloat(y * mz) / 4.0);
                var v = this.originalVertex(a);
                v.y = diff.y;
                v.z += diff.z;
                this.setVertex(a, v);
                v = this.originalVertex(b);
                v.y -= diff.y;
                v.z -= diff.z;
                this.setVertex(b, v);
                v = this.originalVertex(c);
                v.y = diff.y;
                v.z += diff.z;
                this.setVertex(c, v);
                v = this.originalVertex(d);
                v.y -= diff.y;
                v.z -= diff.z;
                this.setVertex(d, v);
            }
        });
        cc.flipY3D = function (duration) {
            return new cc.FlipY3D(duration);
        };
        cc.FlipY3D.create = cc.flipY3D;
        cc.Lens3D = cc.Grid3DAction.extend({
            _position: null,
            _radius: 0,
            _lensEffect: 0,
            _concave: false,
            _dirty: false,
            ctor: function (duration, gridSize, position, radius) {
                cc.GridAction.prototype.ctor.call(this);
                this._position = cc.p(0, 0);
                radius !== undefined && this.initWithDuration(duration, gridSize, position, radius);
            },
            getLensEffect: function () {
                return this._lensEffect;
            },
            setLensEffect: function (lensEffect) {
                this._lensEffect = lensEffect;
            },
            setConcave: function (concave) {
                this._concave = concave;
            },
            getPosition: function () {
                return this._position;
            },
            setPosition: function (position) {
                if (!cc.pointEqualToPoint(position, this._position)) {
                    this._position.x = position.x;
                    this._position.y = position.y;
                    this._dirty = true;
                }
            },
            initWithDuration: function (duration, gridSize, position, radius) {
                if (cc.Grid3DAction.prototype.initWithDuration.call(this, duration, gridSize)) {
                    this.setPosition(position);
                    this._radius = radius;
                    this._lensEffect = 0.7;
                    this._dirty = true;
                    return true;
                }
                return false;
            },
            update: function (dt) {
                if (this._dirty) {
                    var locGridSizeWidth = this._gridSize.width, locGridSizeHeight = this._gridSize.height;
                    var locRadius = this._radius, locLensEffect = this._lensEffect;
                    var locPos = cc.p(0, 0);
                    var vect = cc.p(0, 0);
                    var v, r, l, new_r, pre_log;
                    for (var i = 0; i < locGridSizeWidth + 1; ++i) {
                        for (var j = 0; j < locGridSizeHeight + 1; ++j) {
                            locPos.x = i;
                            locPos.y = j;
                            v = this.originalVertex(locPos);
                            vect.x = this._position.x - v.x;
                            vect.y = this._position.y - v.y;
                            r = cc.pLength(vect);
                            if (r < locRadius) {
                                r = locRadius - r;
                                pre_log = r / locRadius;
                                if (pre_log === 0) {
                                    pre_log = 0.001;
                                }
                                l = Math.log(pre_log) * locLensEffect;
                                new_r = Math.exp(l) * locRadius;
                                r = cc.pLength(vect);
                                if (r > 0) {
                                    vect.x /= r;
                                    vect.y /= r;
                                    vect.x *= new_r;
                                    vect.y *= new_r;
                                    v.z += cc.pLength(vect) * locLensEffect;
                                }
                            }
                            this.setVertex(locPos, v);
                        }
                    }
                    this._dirty = false;
                }
            }
        });
        cc.lens3D = function (duration, gridSize, position, radius) {
            return new cc.Lens3D(duration, gridSize, position, radius);
        };
        cc.Lens3D.create = cc.lens3D;
        cc.Ripple3D = cc.Grid3DAction.extend({
            _position: null,
            _radius: 0,
            _waves: 0,
            _amplitude: 0,
            _amplitudeRate: 0,
            ctor: function (duration, gridSize, position, radius, waves, amplitude) {
                cc.GridAction.prototype.ctor.call(this);
                this._position = cc.p(0, 0);
                amplitude !== undefined && this.initWithDuration(duration, gridSize, position, radius, waves, amplitude);
            },
            getPosition: function () {
                return this._position;
            },
            setPosition: function (position) {
                this._position.x = position.x;
                this._position.y = position.y;
            },
            getAmplitude: function () {
                return this._amplitude;
            },
            setAmplitude: function (amplitude) {
                this._amplitude = amplitude;
            },
            getAmplitudeRate: function () {
                return this._amplitudeRate;
            },
            setAmplitudeRate: function (amplitudeRate) {
                this._amplitudeRate = amplitudeRate;
            },
            initWithDuration: function (duration, gridSize, position, radius, waves, amplitude) {
                if (cc.Grid3DAction.prototype.initWithDuration.call(this, duration, gridSize)) {
                    this.setPosition(position);
                    this._radius = radius;
                    this._waves = waves;
                    this._amplitude = amplitude;
                    this._amplitudeRate = 1.0;
                    return true;
                }
                return false;
            },
            update: function (dt) {
                var locGridSizeWidth = this._gridSize.width, locGridSizeHeight = this._gridSize.height;
                var locPos = cc.p(0, 0), locRadius = this._radius;
                var locWaves = this._waves, locAmplitude = this._amplitude, locAmplitudeRate = this._amplitudeRate;
                var v, r, tempPos = cc.p(0, 0);
                for (var i = 0; i < (locGridSizeWidth + 1); ++i) {
                    for (var j = 0; j < (locGridSizeHeight + 1); ++j) {
                        locPos.x = i;
                        locPos.y = j;
                        v = this.originalVertex(locPos);
                        tempPos.x = this._position.x - v.x;
                        tempPos.y = this._position.y - v.y;
                        r = cc.pLength(tempPos);
                        if (r < locRadius) {
                            r = locRadius - r;
                            var rate = Math.pow(r / locRadius, 2);
                            v.z += (Math.sin(dt * Math.PI * locWaves * 2 + r * 0.1) * locAmplitude * locAmplitudeRate * rate);
                        }
                        this.setVertex(locPos, v);
                    }
                }
            }
        });
        cc.ripple3D = function (duration, gridSize, position, radius, waves, amplitude) {
            return new cc.Ripple3D(duration, gridSize, position, radius, waves, amplitude);
        };
        cc.Ripple3D.create = cc.ripple3D;
        cc.Shaky3D = cc.Grid3DAction.extend({
            _randRange: 0,
            _shakeZ: false,
            ctor: function (duration, gridSize, range, shakeZ) {
                cc.GridAction.prototype.ctor.call(this);
                shakeZ !== undefined && this.initWithDuration(duration, gridSize, range, shakeZ);
            },
            initWithDuration: function (duration, gridSize, range, shakeZ) {
                if (cc.Grid3DAction.prototype.initWithDuration.call(this, duration, gridSize)) {
                    this._randRange = range;
                    this._shakeZ = shakeZ;
                    return true;
                }
                return false;
            },
            update: function (dt) {
                var locGridSizeWidth = this._gridSize.width, locGridSizeHeight = this._gridSize.height;
                var locRandRange = this._randRange, locShakeZ = this._shakeZ, locP = cc.p(0, 0);
                var v;
                for (var i = 0; i < (locGridSizeWidth + 1); ++i) {
                    for (var j = 0; j < (locGridSizeHeight + 1); ++j) {
                        locP.x = i;
                        locP.y = j;
                        v = this.originalVertex(locP);
                        v.x += (cc.rand() % (locRandRange * 2)) - locRandRange;
                        v.y += (cc.rand() % (locRandRange * 2)) - locRandRange;
                        if (locShakeZ) {
                            v.z += (cc.rand() % (locRandRange * 2)) - locRandRange;
                        }
                        this.setVertex(locP, v);
                    }
                }
            }
        });
        cc.shaky3D = function (duration, gridSize, range, shakeZ) {
            return new cc.Shaky3D(duration, gridSize, range, shakeZ);
        };
        cc.Shaky3D.create = cc.shaky3D;
        cc.Liquid = cc.Grid3DAction.extend({
            _waves: 0,
            _amplitude: 0,
            _amplitudeRate: 0,
            ctor: function (duration, gridSize, waves, amplitude) {
                cc.GridAction.prototype.ctor.call(this);
                amplitude !== undefined && this.initWithDuration(duration, gridSize, waves, amplitude);
            },
            getAmplitude: function () {
                return this._amplitude;
            },
            setAmplitude: function (amplitude) {
                this._amplitude = amplitude;
            },
            getAmplitudeRate: function () {
                return this._amplitudeRate;
            },
            setAmplitudeRate: function (amplitudeRate) {
                this._amplitudeRate = amplitudeRate;
            },
            initWithDuration: function (duration, gridSize, waves, amplitude) {
                if (cc.Grid3DAction.prototype.initWithDuration.call(this, duration, gridSize)) {
                    this._waves = waves;
                    this._amplitude = amplitude;
                    this._amplitudeRate = 1.0;
                    return true;
                }
                return false;
            },
            update: function (dt) {
                var locSizeWidth = this._gridSize.width, locSizeHeight = this._gridSize.height, locPos = cc.p(0, 0);
                var locWaves = this._waves, locAmplitude = this._amplitude, locAmplitudeRate = this._amplitudeRate;
                var v;
                for (var i = 1; i < locSizeWidth; ++i) {
                    for (var j = 1; j < locSizeHeight; ++j) {
                        locPos.x = i;
                        locPos.y = j;
                        v = this.originalVertex(locPos);
                        v.x += (Math.sin(dt * Math.PI * locWaves * 2 + v.x * 0.01) * locAmplitude * locAmplitudeRate);
                        v.y += (Math.sin(dt * Math.PI * locWaves * 2 + v.y * 0.01) * locAmplitude * locAmplitudeRate);
                        this.setVertex(locPos, v);
                    }
                }
            }
        });
        cc.liquid = function (duration, gridSize, waves, amplitude) {
            return new cc.Liquid(duration, gridSize, waves, amplitude);
        };
        cc.Liquid.create = cc.liquid;
        cc.Waves = cc.Grid3DAction.extend({
            _waves: 0,
            _amplitude: 0,
            _amplitudeRate: 0,
            _vertical: false,
            _horizontal: false,
            ctor: function (duration, gridSize, waves, amplitude, horizontal, vertical) {
                cc.GridAction.prototype.ctor.call(this);
                vertical !== undefined && this.initWithDuration(duration, gridSize, waves, amplitude, horizontal, vertical);
            },
            getAmplitude: function () {
                return this._amplitude;
            },
            setAmplitude: function (amplitude) {
                this._amplitude = amplitude;
            },
            getAmplitudeRate: function () {
                return this._amplitudeRate;
            },
            setAmplitudeRate: function (amplitudeRate) {
                this._amplitudeRate = amplitudeRate;
            },
            initWithDuration: function (duration, gridSize, waves, amplitude, horizontal, vertical) {
                if (cc.Grid3DAction.prototype.initWithDuration.call(this, duration, gridSize)) {
                    this._waves = waves;
                    this._amplitude = amplitude;
                    this._amplitudeRate = 1.0;
                    this._horizontal = horizontal;
                    this._vertical = vertical;
                    return true;
                }
                return false;
            },
            update: function (dt) {
                var locSizeWidth = this._gridSize.width, locSizeHeight = this._gridSize.height, locPos = cc.p(0, 0);
                var locVertical = this._vertical, locHorizontal = this._horizontal;
                var locWaves = this._waves, locAmplitude = this._amplitude, locAmplitudeRate = this._amplitudeRate;
                var v;
                for (var i = 0; i < locSizeWidth + 1; ++i) {
                    for (var j = 0; j < locSizeHeight + 1; ++j) {
                        locPos.x = i;
                        locPos.y = j;
                        v = this.originalVertex(locPos);
                        if (locVertical) {
                            v.x += (Math.sin(dt * Math.PI * locWaves * 2 + v.y * 0.01) * locAmplitude * locAmplitudeRate);
                        }
                        if (locHorizontal) {
                            v.y += (Math.sin(dt * Math.PI * locWaves * 2 + v.x * 0.01) * locAmplitude * locAmplitudeRate);
                        }
                        this.setVertex(locPos, v);
                    }
                }
            }
        });
        cc.waves = function (duration, gridSize, waves, amplitude, horizontal, vertical) {
            return new cc.Waves(duration, gridSize, waves, amplitude, horizontal, vertical);
        };
        cc.Waves.create = cc.waves;
        cc.Twirl = cc.Grid3DAction.extend({
            _position: null,
            _twirls: 0,
            _amplitude: 0,
            _amplitudeRate: 0,
            ctor: function (duration, gridSize, position, twirls, amplitude) {
                cc.GridAction.prototype.ctor.call(this);
                this._position = cc.p(0, 0);
                amplitude !== undefined && this.initWithDuration(duration, gridSize, position, twirls, amplitude);
            },
            getPosition: function () {
                return this._position;
            },
            setPosition: function (position) {
                this._position.x = position.x;
                this._position.y = position.y;
            },
            getAmplitude: function () {
                return this._amplitude;
            },
            setAmplitude: function (amplitude) {
                this._amplitude = amplitude;
            },
            getAmplitudeRate: function () {
                return this._amplitudeRate;
            },
            setAmplitudeRate: function (amplitudeRate) {
                this._amplitudeRate = amplitudeRate;
            },
            initWithDuration: function (duration, gridSize, position, twirls, amplitude) {
                if (cc.Grid3DAction.prototype.initWithDuration.call(this, duration, gridSize)) {
                    this.setPosition(position);
                    this._twirls = twirls;
                    this._amplitude = amplitude;
                    this._amplitudeRate = 1.0;
                    return true;
                }
                return false;
            },
            update: function (dt) {
                var c = this._position;
                var locSizeWidth = this._gridSize.width, locSizeHeight = this._gridSize.height, locPos = cc.p(0, 0);
                var amp = 0.1 * this._amplitude * this._amplitudeRate;
                var locTwirls = this._twirls;
                var v, a, dX, dY, avg = cc.p(0, 0);
                for (var i = 0; i < (locSizeWidth + 1); ++i) {
                    for (var j = 0; j < (locSizeHeight + 1); ++j) {
                        locPos.x = i;
                        locPos.y = j;
                        v = this.originalVertex(locPos);
                        avg.x = i - (locSizeWidth / 2.0);
                        avg.y = j - (locSizeHeight / 2.0);
                        a = cc.pLength(avg) * Math.cos(Math.PI / 2.0 + dt * Math.PI * locTwirls * 2) * amp;
                        dX = Math.sin(a) * (v.y - c.y) + Math.cos(a) * (v.x - c.x);
                        dY = Math.cos(a) * (v.y - c.y) - Math.sin(a) * (v.x - c.x);
                        v.x = c.x + dX;
                        v.y = c.y + dY;
                        this.setVertex(locPos, v);
                    }
                }
            }
        });
        cc.twirl = function (duration, gridSize, position, twirls, amplitude) {
            return new cc.Twirl(duration, gridSize, position, twirls, amplitude);
        };
        cc.Twirl.create = cc.twirl;
        cc.ShakyTiles3D = cc.TiledGrid3DAction.extend({
            _randRange: 0,
            _shakeZ: false,
            ctor: function (duration, gridSize, range, shakeZ) {
                cc.GridAction.prototype.ctor.call(this);
                shakeZ !== undefined && this.initWithDuration(duration, gridSize, range, shakeZ);
            },
            initWithDuration: function (duration, gridSize, range, shakeZ) {
                if (cc.TiledGrid3DAction.prototype.initWithDuration.call(this, duration, gridSize)) {
                    this._randRange = range;
                    this._shakeZ = shakeZ;
                    return true;
                }
                return false;
            },
            update: function (dt) {
                var locGridSize = this._gridSize, locRandRange = this._randRange;
                var locPos = cc.p(0, 0);
                for (var i = 0; i < locGridSize.width; ++i) {
                    for (var j = 0; j < locGridSize.height; ++j) {
                        locPos.x = i;
                        locPos.y = j;
                        var coords = this.originalTile(locPos);
                        coords.bl.x += (cc.rand() % (locRandRange * 2)) - locRandRange;
                        coords.br.x += (cc.rand() % (locRandRange * 2)) - locRandRange;
                        coords.tl.x += (cc.rand() % (locRandRange * 2)) - locRandRange;
                        coords.tr.x += (cc.rand() % (locRandRange * 2)) - locRandRange;
                        coords.bl.y += (cc.rand() % (locRandRange * 2)) - locRandRange;
                        coords.br.y += (cc.rand() % (locRandRange * 2)) - locRandRange;
                        coords.tl.y += (cc.rand() % (locRandRange * 2)) - locRandRange;
                        coords.tr.y += (cc.rand() % (locRandRange * 2)) - locRandRange;
                        if (this._shakeZ) {
                            coords.bl.z += (cc.rand() % (locRandRange * 2)) - locRandRange;
                            coords.br.z += (cc.rand() % (locRandRange * 2)) - locRandRange;
                            coords.tl.z += (cc.rand() % (locRandRange * 2)) - locRandRange;
                            coords.tr.z += (cc.rand() % (locRandRange * 2)) - locRandRange;
                        }
                        this.setTile(locPos, coords);
                    }
                }
            }
        });
        cc.shakyTiles3D = function (duration, gridSize, range, shakeZ) {
            return new cc.ShakyTiles3D(duration, gridSize, range, shakeZ);
        };
        cc.ShakyTiles3D.create = cc.shakyTiles3D;
        cc.ShatteredTiles3D = cc.TiledGrid3DAction.extend({
            _randRange: 0,
            _once: false,
            _shatterZ: false,
            ctor: function (duration, gridSize, range, shatterZ) {
                cc.GridAction.prototype.ctor.call(this);
                shatterZ !== undefined && this.initWithDuration(duration, gridSize, range, shatterZ);
            },
            initWithDuration: function (duration, gridSize, range, shatterZ) {
                if (cc.TiledGrid3DAction.prototype.initWithDuration.call(this, duration, gridSize)) {
                    this._once = false;
                    this._randRange = range;
                    this._shatterZ = shatterZ;
                    return true;
                }
                return false;
            },
            update: function (dt) {
                if (this._once === false) {
                    var locGridSize = this._gridSize, locRandRange = this._randRange;
                    var coords, locPos = cc.p(0, 0);
                    for (var i = 0; i < locGridSize.width; ++i) {
                        for (var j = 0; j < locGridSize.height; ++j) {
                            locPos.x = i;
                            locPos.y = j;
                            coords = this.originalTile(locPos);
                            coords.bl.x += (cc.rand() % (locRandRange * 2)) - locRandRange;
                            coords.br.x += (cc.rand() % (locRandRange * 2)) - locRandRange;
                            coords.tl.x += (cc.rand() % (locRandRange * 2)) - locRandRange;
                            coords.tr.x += (cc.rand() % (locRandRange * 2)) - locRandRange;
                            coords.bl.y += (cc.rand() % (locRandRange * 2)) - locRandRange;
                            coords.br.y += (cc.rand() % (locRandRange * 2)) - locRandRange;
                            coords.tl.y += (cc.rand() % (locRandRange * 2)) - locRandRange;
                            coords.tr.y += (cc.rand() % (locRandRange * 2)) - locRandRange;
                            if (this._shatterZ) {
                                coords.bl.z += (cc.rand() % (locRandRange * 2)) - locRandRange;
                                coords.br.z += (cc.rand() % (locRandRange * 2)) - locRandRange;
                                coords.tl.z += (cc.rand() % (locRandRange * 2)) - locRandRange;
                                coords.tr.z += (cc.rand() % (locRandRange * 2)) - locRandRange;
                            }
                            this.setTile(locPos, coords);
                        }
                    }
                    this._once = true;
                }
            }
        });
        cc.shatteredTiles3D = function (duration, gridSize, range, shatterZ) {
            return new cc.ShatteredTiles3D(duration, gridSize, range, shatterZ);
        };
        cc.ShatteredTiles3D.create = cc.shatteredTiles3D;
        cc.Tile = function (position, startPosition, delta) {
            this.position = position || cc.p(0, 0);
            this.startPosition = startPosition || cc.p(0, 0);
            this.delta = delta || cc.p(0, 0);
        };
        cc.ShuffleTiles = cc.TiledGrid3DAction.extend({
            _seed: 0,
            _tilesCount: 0,
            _tilesOrder: null,
            _tiles: null,
            ctor: function (duration, gridSize, seed) {
                cc.GridAction.prototype.ctor.call(this);
                this._tilesOrder = [];
                this._tiles = [];
                seed !== undefined && this.initWithDuration(duration, gridSize, seed);
            },
            initWithDuration: function (duration, gridSize, seed) {
                if (cc.TiledGrid3DAction.prototype.initWithDuration.call(this, duration, gridSize)) {
                    this._seed = seed;
                    this._tilesOrder.length = 0;
                    this._tiles.length = 0;
                    return true;
                }
                return false;
            },
            shuffle: function (array, len) {
                for (var i = len - 1; i >= 0; i--) {
                    var j = 0 | (cc.rand() % (i + 1));
                    var v = array[i];
                    array[i] = array[j];
                    array[j] = v;
                }
            },
            getDelta: function (pos) {
                var locGridSize = this._gridSize;
                var idx = pos.width * locGridSize.height + pos.height;
                return cc.size(
                    ((this._tilesOrder[idx] / locGridSize.height) - pos.width),
                    ((this._tilesOrder[idx] % locGridSize.height) - pos.height)
                );
            },
            placeTile: function (pos, tile) {
                var coords = this.originalTile(pos);
                var step = this.target.grid.getStep();
                var locPosition = tile.position;
                coords.bl.x += (locPosition.x * step.x);
                coords.bl.y += (locPosition.y * step.y);
                coords.br.x += (locPosition.x * step.x);
                coords.br.y += (locPosition.y * step.y);
                coords.tl.x += (locPosition.x * step.x);
                coords.tl.y += (locPosition.y * step.y);
                coords.tr.x += (locPosition.x * step.x);
                coords.tr.y += (locPosition.y * step.y);
                this.setTile(pos, coords);
            },
            startWithTarget: function (target) {
                cc.TiledGrid3DAction.prototype.startWithTarget.call(this, target);
                var locGridSize = this._gridSize;
                this._tilesCount = locGridSize.width * locGridSize.height;
                var locTilesOrder = this._tilesOrder;
                locTilesOrder.length = 0;
                for (var k = 0; k < this._tilesCount; ++k) {
                    locTilesOrder[k] = k;
                }
                this.shuffle(locTilesOrder, this._tilesCount);
                var locTiles = this._tiles;
                locTiles.length = 0;
                var tileIndex = 0, tempSize = cc.size(0, 0);
                for (var i = 0; i < locGridSize.width; ++i) {
                    for (var j = 0; j < locGridSize.height; ++j) {
                        locTiles[tileIndex] = new cc.Tile();
                        locTiles[tileIndex].position = cc.p(i, j);
                        locTiles[tileIndex].startPosition = cc.p(i, j);
                        tempSize.width = i;
                        tempSize.height = j;
                        locTiles[tileIndex].delta = this.getDelta(tempSize);
                        ++tileIndex;
                    }
                }
            },
            update: function (dt) {
                var tileIndex = 0, locGridSize = this._gridSize, locTiles = this._tiles;
                var selTile, locPos = cc.p(0, 0);
                for (var i = 0; i < locGridSize.width; ++i) {
                    for (var j = 0; j < locGridSize.height; ++j) {
                        locPos.x = i;
                        locPos.y = j;
                        selTile = locTiles[tileIndex];
                        selTile.position.x = selTile.delta.width * dt;
                        selTile.position.y = selTile.delta.height * dt;
                        this.placeTile(locPos, selTile);
                        ++tileIndex;
                    }
                }
            }
        });
        cc.shuffleTiles = function (duration, gridSize, seed) {
            return new cc.ShuffleTiles(duration, gridSize, seed);
        };
        cc.ShuffleTiles.create = cc.shuffleTiles;
        cc.FadeOutTRTiles = cc.TiledGrid3DAction.extend({
            testFunc: function (pos, time) {
                var locX = this._gridSize.width * time;
                var locY = this._gridSize.height * time;
                if (locX === this._gridSize.width && locY === this._gridSize.height) {
                    return 0.0;
                }
                if ((locX + locY) === 0.0) {
                    return 1.0;
                }
                return Math.pow((pos.x + pos.y) / (locX + locY), 6);
            },
            turnOnTile: function (pos) {
                this.setTile(pos, this.originalTile(pos));
            },
            turnOffTile: function (pos) {
                this.setTile(pos, new cc.Quad3());
            },
            transformTile: function (pos, distance) {
                var coords = this.originalTile(pos);
                var step = this.target.grid.getStep();
                coords.bl.x += (step.x / 2) * (1.0 - distance);
                coords.bl.y += (step.y / 2) * (1.0 - distance);
                coords.br.x -= (step.x / 2) * (1.0 - distance);
                coords.br.y += (step.y / 2) * (1.0 - distance);
                coords.tl.x += (step.x / 2) * (1.0 - distance);
                coords.tl.y -= (step.y / 2) * (1.0 - distance);
                coords.tr.x -= (step.x / 2) * (1.0 - distance);
                coords.tr.y -= (step.y / 2) * (1.0 - distance);
                this.setTile(pos, coords);
            },
            update: function (dt) {
                var locGridSize = this._gridSize;
                var locPos = cc.p(0, 0), distance;
                for (var i = 0; i < locGridSize.width; ++i) {
                    for (var j = 0; j < locGridSize.height; ++j) {
                        locPos.x = i;
                        locPos.y = j;
                        distance = this.testFunc(locPos, dt);
                        if (distance === 0) {
                            this.turnOffTile(locPos);
                        } else if (distance < 1) {
                            this.transformTile(locPos, distance);
                        } else {
                            this.turnOnTile(locPos);
                        }
                    }
                }
            }
        });
        cc.fadeOutTRTiles = function (duration, gridSize) {
            return new cc.FadeOutTRTiles(duration, gridSize);
        };
        cc.FadeOutTRTiles.create = cc.fadeOutTRTiles;
        cc.FadeOutBLTiles = cc.FadeOutTRTiles.extend({
            testFunc: function (pos, time) {
                var locX = this._gridSize.width * (1.0 - time);
                var locY = this._gridSize.height * (1.0 - time);
                if ((locX + locY) === 0) {
                    return 0.0;
                }
                if ((pos.x + pos.y) === 0) {
                    return 1.0;
                }
                return Math.pow((locX + locY) / (pos.x + pos.y), 6);
            }
        });
        cc.fadeOutBLTiles = function (duration, gridSize) {
            return new cc.FadeOutBLTiles(duration, gridSize);
        };
        cc.FadeOutBLTiles.create = cc.fadeOutBLTiles;
        cc.FadeOutUpTiles = cc.FadeOutTRTiles.extend({
            testFunc: function (pos, time) {
                var locY = this._gridSize.height * time;
                if (locY === this._gridSize.height) {
                    return 0.0;
                }
                if (locY === 0.0) {
                    return 1.0;
                }
                return Math.pow(pos.y / locY, 6);
            },
            transformTile: function (pos, distance) {
                var coords = this.originalTile(pos);
                var step = this.target.grid.getStep();
                coords.bl.y += (step.y / 2) * (1.0 - distance);
                coords.br.y += (step.y / 2) * (1.0 - distance);
                coords.tl.y -= (step.y / 2) * (1.0 - distance);
                coords.tr.y -= (step.y / 2) * (1.0 - distance);
                this.setTile(pos, coords);
            }
        });
        cc.fadeOutUpTiles = function (duration, gridSize) {
            return new cc.FadeOutUpTiles(duration, gridSize);
        };
        cc.FadeOutUpTiles.create = cc.fadeOutUpTiles;
        cc.FadeOutDownTiles = cc.FadeOutUpTiles.extend({
            testFunc: function (pos, time) {
                var locY = this._gridSize.height * (1.0 - time);
                if (locY === 0.0) {
                    return 0.0;
                }
                if (pos.y === 0) {
                    return 1.0;
                }
                return Math.pow(locY / pos.y, 6);
            }
        });
        cc.fadeOutDownTiles = function (duration, gridSize) {
            return new cc.FadeOutDownTiles(duration, gridSize);
        };
        cc.FadeOutDownTiles.create = cc.fadeOutDownTiles;
        cc.TurnOffTiles = cc.TiledGrid3DAction.extend({
            _seed: null,
            _tilesCount: 0,
            _tilesOrder: null,
            ctor: function (duration, gridSize, seed) {
                cc.GridAction.prototype.ctor.call(this);
                this._tilesOrder = [];
                gridSize !== undefined && this.initWithDuration(duration, gridSize, seed);
            },
            initWithDuration: function (duration, gridSize, seed) {
                if (cc.TiledGrid3DAction.prototype.initWithDuration.call(this, duration, gridSize)) {
                    this._seed = seed || 0;
                    this._tilesOrder.length = 0;
                    return true;
                }
                return false;
            },
            shuffle: function (array, len) {
                for (var i = len - 1; i >= 0; i--) {
                    var j = 0 | (cc.rand() % (i + 1));
                    var v = array[i];
                    array[i] = array[j];
                    array[j] = v;
                }
            },
            turnOnTile: function (pos) {
                this.setTile(pos, this.originalTile(pos));
            },
            turnOffTile: function (pos) {
                this.setTile(pos, new cc.Quad3());
            },
            startWithTarget: function (target) {
                cc.TiledGrid3DAction.prototype.startWithTarget.call(this, target);
                this._tilesCount = this._gridSize.width * this._gridSize.height;
                var locTilesOrder = this._tilesOrder;
                locTilesOrder.length = 0;
                for (var i = 0; i < this._tilesCount; ++i) {
                    locTilesOrder[i] = i;
                }
                this.shuffle(locTilesOrder, this._tilesCount);
            },
            update: function (dt) {
                var l = 0 | (dt * this._tilesCount), locGridSize = this._gridSize;
                var t, tilePos = cc.p(0, 0), locTilesOrder = this._tilesOrder;
                for (var i = 0; i < this._tilesCount; i++) {
                    t = locTilesOrder[i];
                    tilePos.x = 0 | (t / locGridSize.height);
                    tilePos.y = t % (0 | locGridSize.height);
                    if (i < l) {
                        this.turnOffTile(tilePos);
                    } else {
                        this.turnOnTile(tilePos);
                    }
                }
            }
        });
        cc.turnOffTiles = function (duration, gridSize, seed) {
            return new cc.TurnOffTiles(duration, gridSize, seed);
        };
        cc.TurnOffTiles.create = cc.turnOffTiles;
        cc.WavesTiles3D = cc.TiledGrid3DAction.extend({
            _waves: 0,
            _amplitude: 0,
            _amplitudeRate: 0,
            ctor: function (duration, gridSize, waves, amplitude) {
                cc.GridAction.prototype.ctor.call(this);
                amplitude !== undefined && this.initWithDuration(duration, gridSize, waves, amplitude);
            },
            getAmplitude: function () {
                return this._amplitude;
            },
            setAmplitude: function (amplitude) {
                this._amplitude = amplitude;
            },
            getAmplitudeRate: function () {
                return this._amplitudeRate;
            },
            setAmplitudeRate: function (amplitudeRate) {
                this._amplitudeRate = amplitudeRate;
            },
            initWithDuration: function (duration, gridSize, waves, amplitude) {
                if (cc.TiledGrid3DAction.prototype.initWithDuration.call(this, duration, gridSize)) {
                    this._waves = waves;
                    this._amplitude = amplitude;
                    this._amplitudeRate = 1.0;
                    return true;
                }
                return false;
            },
            update: function (dt) {
                var locGridSize = this._gridSize, locWaves = this._waves, locAmplitude = this._amplitude,
                    locAmplitudeRate = this._amplitudeRate;
                var locPos = cc.p(0, 0), coords;
                for (var i = 0; i < locGridSize.width; i++) {
                    for (var j = 0; j < locGridSize.height; j++) {
                        locPos.x = i;
                        locPos.y = j;
                        coords = this.originalTile(locPos);
                        coords.bl.z = (Math.sin(dt * Math.PI * locWaves * 2
                            + (coords.bl.y + coords.bl.x) * 0.01) * locAmplitude * locAmplitudeRate);
                        coords.br.z = coords.bl.z;
                        coords.tl.z = coords.bl.z;
                        coords.tr.z = coords.bl.z;
                        this.setTile(locPos, coords);
                    }
                }
            }
        });
        cc.wavesTiles3D = function (duration, gridSize, waves, amplitude) {
            return new cc.WavesTiles3D(duration, gridSize, waves, amplitude);
        };
        cc.WavesTiles3D.create = cc.wavesTiles3D;
        cc.JumpTiles3D = cc.TiledGrid3DAction.extend({
            _jumps: 0,
            _amplitude: 0,
            _amplitudeRate: 0,
            ctor: function (duration, gridSize, numberOfJumps, amplitude) {
                cc.GridAction.prototype.ctor.call(this);
                amplitude !== undefined && this.initWithDuration(duration, gridSize, numberOfJumps, amplitude);
            },
            getAmplitude: function () {
                return this._amplitude;
            },
            setAmplitude: function (amplitude) {
                this._amplitude = amplitude;
            },
            getAmplitudeRate: function () {
                return this._amplitudeRate;
            },
            setAmplitudeRate: function (amplitudeRate) {
                this._amplitudeRate = amplitudeRate;
            },
            initWithDuration: function (duration, gridSize, numberOfJumps, amplitude) {
                if (cc.TiledGrid3DAction.prototype.initWithDuration.call(this, duration, gridSize)) {
                    this._jumps = numberOfJumps;
                    this._amplitude = amplitude;
                    this._amplitudeRate = 1.0;
                    return true;
                }
                return false;
            },
            update: function (dt) {
                var sinz = (Math.sin(Math.PI * dt * this._jumps * 2) * this._amplitude * this._amplitudeRate);
                var sinz2 = (Math.sin(Math.PI * (dt * this._jumps * 2 + 1)) * this._amplitude * this._amplitudeRate);
                var locGridSize = this._gridSize;
                var locGrid = this.target.grid;
                var coords, locPos = cc.p(0, 0);
                for (var i = 0; i < locGridSize.width; i++) {
                    for (var j = 0; j < locGridSize.height; j++) {
                        locPos.x = i;
                        locPos.y = j;
                        coords = locGrid.originalTile(locPos);
                        if (((i + j) % 2) === 0) {
                            coords.bl.z += sinz;
                            coords.br.z += sinz;
                            coords.tl.z += sinz;
                            coords.tr.z += sinz;
                        } else {
                            coords.bl.z += sinz2;
                            coords.br.z += sinz2;
                            coords.tl.z += sinz2;
                            coords.tr.z += sinz2;
                        }
                        locGrid.setTile(locPos, coords);
                    }
                }
            }
        });
        cc.jumpTiles3D = function (duration, gridSize, numberOfJumps, amplitude) {
            return new cc.JumpTiles3D(duration, gridSize, numberOfJumps, amplitude);
        };
        cc.JumpTiles3D.create = cc.jumpTiles3D;
        cc.SplitRows = cc.TiledGrid3DAction.extend({
            _rows: 0,
            _winSize: null,
            ctor: function (duration, rows) {
                cc.GridAction.prototype.ctor.call(this);
                rows !== undefined && this.initWithDuration(duration, rows);
            },
            initWithDuration: function (duration, rows) {
                this._rows = rows;
                return cc.TiledGrid3DAction.prototype.initWithDuration.call(this, duration, cc.size(1, rows));
            },
            update: function (dt) {
                var locGridSize = this._gridSize, locWinSizeWidth = this._winSize.width;
                var coords, direction, locPos = cc.p(0, 0);
                for (var j = 0; j < locGridSize.height; ++j) {
                    locPos.y = j;
                    coords = this.originalTile(locPos);
                    direction = 1;
                    if ((j % 2) === 0) {
                        direction = -1;
                    }
                    coords.bl.x += direction * locWinSizeWidth * dt;
                    coords.br.x += direction * locWinSizeWidth * dt;
                    coords.tl.x += direction * locWinSizeWidth * dt;
                    coords.tr.x += direction * locWinSizeWidth * dt;
                    this.setTile(locPos, coords);
                }
            },
            startWithTarget: function (target) {
                cc.TiledGrid3DAction.prototype.startWithTarget.call(this, target);
                this._winSize = cc.director.getWinSizeInPixels();
            }
        });
        cc.splitRows = function (duration, rows) {
            return new cc.SplitRows(duration, rows);
        };
        cc.SplitRows.create = cc.splitRows;
        cc.SplitCols = cc.TiledGrid3DAction.extend({
            _cols: 0,
            _winSize: null,
            ctor: function (duration, cols) {
                cc.GridAction.prototype.ctor.call(this);
                cols !== undefined && this.initWithDuration(duration, cols);
            },
            initWithDuration: function (duration, cols) {
                this._cols = cols;
                return cc.TiledGrid3DAction.prototype.initWithDuration.call(this, duration, cc.size(cols, 1));
            },
            update: function (dt) {
                var locGridSizeWidth = this._gridSize.width, locWinSizeHeight = this._winSize.height;
                var coords, direction, locPos = cc.p(0, 0);
                for (var i = 0; i < locGridSizeWidth; ++i) {
                    locPos.x = i;
                    coords = this.originalTile(locPos);
                    direction = 1;
                    if ((i % 2) === 0) {
                        direction = -1;
                    }
                    coords.bl.y += direction * locWinSizeHeight * dt;
                    coords.br.y += direction * locWinSizeHeight * dt;
                    coords.tl.y += direction * locWinSizeHeight * dt;
                    coords.tr.y += direction * locWinSizeHeight * dt;
                    this.setTile(locPos, coords);
                }
                cc.renderer.childrenOrderDirty = true;
            },
            startWithTarget: function (target) {
                cc.TiledGrid3DAction.prototype.startWithTarget.call(this, target);
                this._winSize = cc.director.getWinSizeInPixels();
            }
        });
        cc.splitCols = function (duration, cols) {
            return new cc.SplitCols(duration, cols);
        };
        cc.SplitCols.create = cc.splitCols;
        cc.PageTurn3D = cc.Grid3DAction.extend({
            getGrid: function () {
                var result = new cc.Grid3D(this._gridSize, undefined, undefined, this._gridNodeTarget.getGridRect());
                result.setNeedDepthTestForBlit(true);
                return result;
            },
            clone: function () {
                var ret = new cc.PageTurn3D();
                ret.initWithDuration(this._duration, this._gridSize);
                return ret;
            },
            update: function (time) {
                var tt = Math.max(0, time - 0.25);
                var deltaAy = (tt * tt * 500);
                var ay = -100 - deltaAy;
                var deltaTheta = Math.sqrt(time);
                var theta = deltaTheta > 0.5 ? Math.PI / 2 * deltaTheta : Math.PI / 2 * (1 - deltaTheta);
                var rotateByYAxis = (2 - time) * Math.PI;
                var sinTheta = Math.sin(theta);
                var cosTheta = Math.cos(theta);
                var locGridSize = this._gridSize;
                var locVer = cc.p(0, 0);
                for (var i = 0; i <= locGridSize.width; ++i) {
                    for (var j = 0; j <= locGridSize.height; ++j) {
                        locVer.x = i;
                        locVer.y = j;
                        var p = this.getOriginalVertex(locVer);
                        p.x -= this.getGridRect().x;
                        var R = Math.sqrt((p.x * p.x) + ((p.y - ay) * (p.y - ay)));
                        var r = R * sinTheta;
                        var alpha = Math.asin(p.x / R);
                        var beta = alpha / sinTheta;
                        var cosBeta = Math.cos(beta);
                        if (beta <= Math.PI) {
                            p.x = (r * Math.sin(beta));
                        } else {
                            p.x = 0;
                        }
                        p.y = (R + ay - (r * (1 - cosBeta) * sinTheta));
                        p.z = (r * (1 - cosBeta) * cosTheta);// "100" didn't work for
                        p.x = p.z * Math.sin(rotateByYAxis) + p.x * Math.cos(rotateByYAxis);
                        p.z = p.z * Math.cos(rotateByYAxis) - p.x * Math.cos(rotateByYAxis);
                        p.z /= 7;
                        if (p.z < 0.5) {
                            p.z = 0.5;
                        }
                        p.x += this.getGridRect().x;
                        this.setVertex(locVer, p);
                    }
                }
            }
        });
        cc.pageTurn3D = function (duration, gridSize) {
            return new cc.PageTurn3D(duration, gridSize);
        };
        cc.PageTurn3D.create = cc.pageTurn3D;
        cc.ProgressTimer = cc.Node.extend({
            _type: null,
            _percentage: 0.0,
            _sprite: null,
            _midPoint: null,
            _barChangeRate: null,
            _reverseDirection: false,
            _className: "ProgressTimer",
            ctor: function (sprite) {
                cc.Node.prototype.ctor.call(this);
                this._type = cc.ProgressTimer.TYPE_RADIAL;
                this._percentage = 0.0;
                this._midPoint = cc.p(0, 0);
                this._barChangeRate = cc.p(0, 0);
                this._reverseDirection = false;
                this._sprite = null;
                sprite && this.initWithSprite(sprite);
            },
            onEnter: function () {
                this._super();
                if (cc._renderType === cc.game.RENDER_TYPE_WEBGL) {
                    this._renderCmd.initCmd();
                    this._renderCmd._updateProgress();
                }
            },
            cleanup: function () {
                if (cc._renderType === cc.game.RENDER_TYPE_WEBGL) {
                    this._renderCmd.releaseData();
                }
                this._super();
            },
            getMidpoint: function () {
                return cc.p(this._midPoint.x, this._midPoint.y);
            },
            setMidpoint: function (mpoint) {
                this._midPoint = cc.pClamp(mpoint, cc.p(0, 0), cc.p(1, 1));
            },
            getBarChangeRate: function () {
                return cc.p(this._barChangeRate.x, this._barChangeRate.y);
            },
            setBarChangeRate: function (barChangeRate) {
                this._barChangeRate = cc.pClamp(barChangeRate, cc.p(0, 0), cc.p(1, 1));
            },
            getType: function () {
                return this._type;
            },
            getPercentage: function () {
                return this._percentage;
            },
            getSprite: function () {
                return this._sprite;
            },
            setPercentage: function (percentage) {
                if (this._percentage !== percentage) {
                    this._percentage = cc.clampf(percentage, 0, 100);
                    this._renderCmd._updateProgress();
                }
            },
            setOpacityModifyRGB: function (bValue) {
            },
            isOpacityModifyRGB: function () {
                return false;
            },
            isReverseDirection: function () {
                return this._reverseDirection;
            },
            setColor: function (color) {
                this._sprite.color = color;
                this._renderCmd.setDirtyFlag(cc.Node._dirtyFlags.colorDirty);
            },
            setOpacity: function (opacity) {
                this._sprite.opacity = opacity;
                this._renderCmd.setDirtyFlag(cc.Node._dirtyFlags.opacityDirty);
            },
            getColor: function () {
                return this._sprite.color;
            },
            getOpacity: function () {
                return this._sprite.opacity;
            },
            setReverseProgress: function (reverse) {
                if (this._reverseDirection !== reverse) {
                    this._reverseDirection = reverse;
                    this._renderCmd.resetVertexData();
                }
            },
            setSprite: function (sprite) {
                if (this._sprite !== sprite) {
                    this._sprite = sprite;
                    if (sprite) {
                        this.setContentSize(sprite.width, sprite.height);
                        sprite.ignoreAnchorPointForPosition(true);
                    } else {
                        this.setContentSize(0, 0);
                    }
                    this._renderCmd.resetVertexData();
                }
            },
            setType: function (type) {
                if (type !== this._type) {
                    this._type = type;
                    this._renderCmd.resetVertexData();
                }
            },
            setReverseDirection: function (reverse) {
                if (this._reverseDirection !== reverse) {
                    this._reverseDirection = reverse;
                    this._renderCmd.resetVertexData();
                }
            },
            initWithSprite: function (sprite) {
                this.percentage = 0;
                this.setAnchorPoint(0.5, 0.5);
                this._type = cc.ProgressTimer.TYPE_RADIAL;
                this._reverseDirection = false;
                this.midPoint = cc.p(0.5, 0.5);
                this.barChangeRate = cc.p(1, 1);
                this.setSprite(sprite);
                this._renderCmd.resetVertexData();
                return true;
            },
            _createRenderCmd: function () {
                if (cc._renderType === cc.game.RENDER_TYPE_CANVAS) {
                    return new cc.ProgressTimer.CanvasRenderCmd(this);
                }
                return new cc.ProgressTimer.WebGLRenderCmd(this);
            }
        });
        var _p = cc.ProgressTimer.prototype;
        _p.midPoint;
        cc.defineGetterSetter(_p, "midPoint", _p.getMidpoint, _p.setMidpoint);
        _p.barChangeRate;
        cc.defineGetterSetter(_p, "barChangeRate", _p.getBarChangeRate, _p.setBarChangeRate);
        _p.type;
        cc.defineGetterSetter(_p, "type", _p.getType, _p.setType);
        _p.percentage;
        cc.defineGetterSetter(_p, "percentage", _p.getPercentage, _p.setPercentage);
        _p.sprite;
        cc.defineGetterSetter(_p, "sprite", _p.getSprite, _p.setSprite);
        _p.reverseDir;
        cc.defineGetterSetter(_p, "reverseDir", _p.isReverseDirection, _p.setReverseDirection);
        cc.ProgressTimer.create = function (sprite) {
            return new cc.ProgressTimer(sprite);
        };
        cc.ProgressTimer.TEXTURE_COORDS_COUNT = 4;
        cc.ProgressTimer.TEXTURE_COORDS = 0x4b;
        cc.ProgressTimer.TYPE_RADIAL = 0;
        cc.ProgressTimer.TYPE_BAR = 1;
        (function () {
            cc.ProgressTimer.CanvasRenderCmd = function (renderableObject) {
                cc.Node.CanvasRenderCmd.call(this, renderableObject);
                this._needDraw = true;
                this._PI180 = Math.PI / 180;
                this._barRect = cc.rect(0, 0, 0, 0);
                this._origin = cc.p(0, 0);
                this._radius = 0;
                this._startAngle = 270;
                this._endAngle = 270;
                this._counterClockWise = false;
                this._canUseDirtyRegion = true;
            };
            var proto = cc.ProgressTimer.CanvasRenderCmd.prototype = Object.create(cc.Node.CanvasRenderCmd.prototype);
            proto.constructor = cc.ProgressTimer.CanvasRenderCmd;
            proto.rendering = function (ctx, scaleX, scaleY) {
                var wrapper = ctx || cc._renderContext, context = wrapper.getContext(), node = this._node,
                    locSprite = node._sprite;
                var locTextureCoord = locSprite._renderCmd._textureCoord,
                    alpha = locSprite._renderCmd._displayedOpacity / 255;
                if (locTextureCoord.width === 0 || locTextureCoord.height === 0) {
                    return;
                }
                if (!locSprite._texture || !locTextureCoord.validRect || alpha === 0) {
                    return;
                }
                wrapper.setTransform(this._worldTransform, scaleX, scaleY);
                wrapper.setCompositeOperation(locSprite._blendFuncStr);
                wrapper.setGlobalAlpha(alpha);
                var locRect = locSprite._rect, locOffsetPosition = locSprite._offsetPosition;
                var locX = locOffsetPosition.x,
                    locY = -locOffsetPosition.y - locRect.height,
                    locWidth = locRect.width,
                    locHeight = locRect.height;
                wrapper.save();
                if (locSprite._flippedX) {
                    locX = -locX - locWidth;
                    context.scale(-1, 1);
                }
                if (locSprite._flippedY) {
                    locY = locOffsetPosition.y;
                    context.scale(1, -1);
                }
                if (node._type === cc.ProgressTimer.TYPE_BAR) {
                    var locBarRect = this._barRect;
                    context.beginPath();
                    context.rect(locBarRect.x, locBarRect.y, locBarRect.width, locBarRect.height);
                    context.clip();
                    context.closePath();
                } else if (node._type === cc.ProgressTimer.TYPE_RADIAL) {
                    var locOriginX = this._origin.x;
                    var locOriginY = this._origin.y;
                    context.beginPath();
                    context.arc(locOriginX, locOriginY, this._radius, this._PI180 * this._startAngle, this._PI180 * this._endAngle, this._counterClockWise);
                    context.lineTo(locOriginX, locOriginY);
                    context.clip();
                    context.closePath();
                }
                var texture = locSprite._renderCmd._textureToRender || locSprite._texture;
                var image = texture.getHtmlElementObj();
                if (locSprite._renderCmd._colorized) {
                    context.drawImage(
                        image,
                        0,
                        0,
                        locTextureCoord.width,
                        locTextureCoord.height,
                        locX,
                        locY,
                        locWidth,
                        locHeight
                    );
                } else {
                    context.drawImage(
                        image,
                        locTextureCoord.renderX,
                        locTextureCoord.renderY,
                        locTextureCoord.width,
                        locTextureCoord.height,
                        locX,
                        locY,
                        locWidth,
                        locHeight
                    );
                }
                wrapper.restore();
                cc.g_NumberOfDraws++;
            };
            proto.releaseData = function () {
            };
            proto.resetVertexData = function () {
            };
            proto._updateProgress = function () {
                this.setDirtyFlag(cc.Node._dirtyFlags.contentDirty);
                var node = this._node;
                var locSprite = node._sprite;
                var sw = locSprite.width, sh = locSprite.height;
                var locMidPoint = node._midPoint;
                if (node._type === cc.ProgressTimer.TYPE_RADIAL) {
                    this._radius = Math.round(Math.sqrt(sw * sw + sh * sh));
                    var locStartAngle, locEndAngle, locCounterClockWise = false, locOrigin = this._origin;
                    locOrigin.x = sw * locMidPoint.x;
                    locOrigin.y = -sh * locMidPoint.y;
                    if (node._reverseDirection) {
                        locEndAngle = 270;
                        locStartAngle = 270 - 3.6 * node._percentage;
                    } else {
                        locStartAngle = -90;
                        locEndAngle = -90 + 3.6 * node._percentage;
                    }
                    if (locSprite._flippedX) {
                        locOrigin.x -= sw * (node._midPoint.x * 2);
                        locStartAngle = -locStartAngle;
                        locEndAngle = -locEndAngle;
                        locStartAngle -= 180;
                        locEndAngle -= 180;
                        locCounterClockWise = !locCounterClockWise;
                    }
                    if (locSprite._flippedY) {
                        locOrigin.y += sh * (node._midPoint.y * 2);
                        locCounterClockWise = !locCounterClockWise;
                        locStartAngle = -locStartAngle;
                        locEndAngle = -locEndAngle;
                    }
                    this._startAngle = locStartAngle;
                    this._endAngle = locEndAngle;
                    this._counterClockWise = locCounterClockWise;
                } else {
                    var locBarChangeRate = node._barChangeRate;
                    var percentageF = node._percentage / 100;
                    var locBarRect = this._barRect;
                    var drewSize = cc.size((sw * (1 - locBarChangeRate.x)), (sh * (1 - locBarChangeRate.y)));
                    var drawingSize = cc.size((sw - drewSize.width) * percentageF, (sh - drewSize.height) * percentageF);
                    var currentDrawSize = cc.size(drewSize.width + drawingSize.width, drewSize.height + drawingSize.height);
                    var startPoint = cc.p(sw * locMidPoint.x, sh * locMidPoint.y);
                    var needToLeft = startPoint.x - currentDrawSize.width / 2;
                    if ((locMidPoint.x > 0.5) && (currentDrawSize.width / 2 >= sw - startPoint.x)) {
                        needToLeft = sw - currentDrawSize.width;
                    }
                    var needToTop = startPoint.y - currentDrawSize.height / 2;
                    if ((locMidPoint.y > 0.5) && (currentDrawSize.height / 2 >= sh - startPoint.y)) {
                        needToTop = sh - currentDrawSize.height;
                    }
                    locBarRect.x = 0;
                    var flipXNeed = 1;
                    if (locSprite._flippedX) {
                        locBarRect.x -= currentDrawSize.width;
                        flipXNeed = -1;
                    }
                    if (needToLeft > 0) {
                        locBarRect.x += needToLeft * flipXNeed;
                    }
                    locBarRect.y = 0;
                    var flipYNeed = 1;
                    if (locSprite._flippedY) {
                        locBarRect.y += currentDrawSize.height;
                        flipYNeed = -1;
                    }
                    if (needToTop > 0) {
                        locBarRect.y -= needToTop * flipYNeed;
                    }
                    locBarRect.width = currentDrawSize.width;
                    locBarRect.height = -currentDrawSize.height;
                }
            };
            proto._syncStatus = function (parentCmd) {
                var node = this._node;
                if (!node._sprite) {
                    return;
                }
                var flags = cc.Node._dirtyFlags, locFlag = this._dirtyFlag;
                var parentNode = parentCmd ? parentCmd._node : null;
                if (parentNode && parentNode._cascadeColorEnabled && (parentCmd._dirtyFlag & flags.colorDirty)) {
                    locFlag |= flags.colorDirty;
                }
                if (parentNode && parentNode._cascadeOpacityEnabled && (parentCmd._dirtyFlag & flags.opacityDirty)) {
                    locFlag |= flags.opacityDirty;
                }
                if (parentCmd && (parentCmd._dirtyFlag & flags.transformDirty)) {
                    locFlag |= flags.transformDirty;
                }
                this._dirtyFlag = locFlag;
                var spriteCmd = node._sprite._renderCmd;
                var spriteFlag = spriteCmd._dirtyFlag;
                var colorDirty = spriteFlag & flags.colorDirty,
                    opacityDirty = spriteFlag & flags.opacityDirty;
                if (colorDirty) {
                    spriteCmd._syncDisplayColor();
                    spriteCmd._dirtyFlag = spriteCmd._dirtyFlag & flags.colorDirty ^ spriteCmd._dirtyFlag;
                    this._dirtyFlag = this._dirtyFlag & flags.colorDirty ^ this._dirtyFlag;
                }
                if (opacityDirty) {
                    spriteCmd._syncDisplayOpacity();
                    spriteCmd._dirtyFlag = spriteCmd._dirtyFlag & flags.opacityDirty ^ spriteCmd._dirtyFlag;
                    this._dirtyFlag = this._dirtyFlag & flags.opacityDirty ^ this._dirtyFlag;
                }
                if (colorDirty || opacityDirty) {
                    spriteCmd._updateColor();
                }
                if (locFlag & flags.transformDirty) {
                    this.transform(parentCmd);
                }
                if (locFlag & flags.orderDirty) {
                    this._dirtyFlag = this._dirtyFlag & flags.orderDirty ^ this._dirtyFlag;
                }
            };
            proto.updateStatus = function () {
                var node = this._node;
                if (!node._sprite) {
                    return;
                }
                var flags = cc.Node._dirtyFlags, locFlag = this._dirtyFlag;
                var spriteCmd = node._sprite._renderCmd;
                var spriteFlag = spriteCmd._dirtyFlag;
                var colorDirty = spriteFlag & flags.colorDirty,
                    opacityDirty = spriteFlag & flags.opacityDirty;
                if (colorDirty) {
                    spriteCmd._updateDisplayColor();
                    spriteCmd._dirtyFlag = spriteCmd._dirtyFlag & flags.colorDirty ^ spriteCmd._dirtyFlag;
                    this._dirtyFlag = this._dirtyFlag & flags.colorDirty ^ this._dirtyFlag;
                }
                if (opacityDirty) {
                    spriteCmd._updateDisplayOpacity();
                    spriteCmd._dirtyFlag = spriteCmd._dirtyFlag & flags.opacityDirty ^ spriteCmd._dirtyFlag;
                    this._dirtyFlag = this._dirtyFlag & flags.opacityDirty ^ this._dirtyFlag;
                }
                if (colorDirty || opacityDirty) {
                    spriteCmd._updateColor();
                }
                if (locFlag & flags.transformDirty) {
                    this.transform(this.getParentRenderCmd(), true);
                }
                if (locFlag & flags.contentDirty) {
                    this._notifyRegionStatus && this._notifyRegionStatus(cc.Node.CanvasRenderCmd.RegionStatus.Dirty);
                }
                this._dirtyFlag = 0;
            };
        }());
        cc.ProgressTo = cc.ActionInterval.extend({
            _to: 0,
            _from: 0,
            ctor: function (duration, percent) {
                cc.ActionInterval.prototype.ctor.call(this);
                this._to = 0;
                this._from = 0;
                percent !== undefined && this.initWithDuration(duration, percent);
            },
            initWithDuration: function (duration, percent) {
                if (cc.ActionInterval.prototype.initWithDuration.call(this, duration)) {
                    this._to = percent;
                    return true;
                }
                return false;
            },
            clone: function () {
                var action = new cc.ProgressTo();
                action.initWithDuration(this._duration, this._to);
                return action;
            },
            reverse: function () {
                cc.log("cc.ProgressTo.reverse(): reverse hasn't been supported.");
                return null;
            },
            startWithTarget: function (target) {
                cc.ActionInterval.prototype.startWithTarget.call(this, target);
                this._from = target.percentage;
            },
            update: function (time) {
                if (this.target instanceof cc.ProgressTimer) {
                    this.target.percentage = this._from + (this._to - this._from) * time;
                }
            }
        });
        cc.progressTo = function (duration, percent) {
            return new cc.ProgressTo(duration, percent);
        };
        cc.ProgressTo.create = cc.progressTo;
        cc.ProgressFromTo = cc.ActionInterval.extend({
            _to: 0,
            _from: 0,
            ctor: function (duration, fromPercentage, toPercentage) {
                cc.ActionInterval.prototype.ctor.call(this);
                this._to = 0;
                this._from = 0;
                toPercentage !== undefined && this.initWithDuration(duration, fromPercentage, toPercentage);
            },
            initWithDuration: function (duration, fromPercentage, toPercentage) {
                if (cc.ActionInterval.prototype.initWithDuration.call(this, duration)) {
                    this._to = toPercentage;
                    this._from = fromPercentage;
                    return true;
                }
                return false;
            },
            clone: function () {
                var action = new cc.ProgressFromTo();
                action.initWithDuration(this._duration, this._from, this._to);
                return action;
            },
            reverse: function () {
                return cc.progressFromTo(this._duration, this._to, this._from);
            },
            startWithTarget: function (target) {
                cc.ActionInterval.prototype.startWithTarget.call(this, target);
            },
            update: function (time) {
                if (this.target instanceof cc.ProgressTimer) {
                    this.target.percentage = this._from + (this._to - this._from) * time;
                }
            }
        });
        cc.progressFromTo = function (duration, fromPercentage, toPercentage) {
            return new cc.ProgressFromTo(duration, fromPercentage, toPercentage);
        };
        cc.ProgressFromTo.create = cc.progressFromTo;
        (function () {
            var MAX_VERTEX_COUNT = 8;
            cc.ProgressTimer.WebGLRenderCmd = function (renderableObject) {
                cc.Node.WebGLRenderCmd.call(this, renderableObject);
                this._needDraw = true;
                this._progressDirty = true;
                this._bl = cc.p();
                this._tr = cc.p();
                this.initCmd();
            };
            var proto = cc.ProgressTimer.WebGLRenderCmd.prototype = Object.create(cc.Node.WebGLRenderCmd.prototype);
            proto.constructor = cc.ProgressTimer.WebGLRenderCmd;
            proto.transform = function (parentCmd, recursive) {
                this.originTransform(parentCmd, recursive);
                var sp = this._node._sprite;
                sp._renderCmd.transform(this, recursive);
                var lx = sp._offsetPosition.x, rx = lx + sp._rect.width,
                    by = sp._offsetPosition.y, ty = by + sp._rect.height,
                    wt = this._worldTransform;
                this._bl.x = lx * wt.a + by * wt.c + wt.tx;
                this._bl.y = lx * wt.b + by * wt.d + wt.ty;
                this._tr.x = rx * wt.a + ty * wt.c + wt.tx;
                this._tr.y = rx * wt.b + ty * wt.d + wt.ty;
                this._updateProgressData();
            };
            proto.rendering = function (ctx) {
                var node = this._node;
                var context = ctx || cc._renderContext;
                if (this._vertexDataCount === 0 || !node._sprite) {
                    return;
                }
                this._shaderProgram.use();
                this._shaderProgram._updateProjectionUniform();
                var blendFunc = node._sprite._blendFunc;
                cc.glBlendFunc(blendFunc.src, blendFunc.dst);
                cc.glBindTexture2D(node._sprite.texture);
                context.bindBuffer(context.ARRAY_BUFFER, this._vertexWebGLBuffer);
                context.enableVertexAttribArray(cc.VERTEX_ATTRIB_POSITION);
                context.enableVertexAttribArray(cc.VERTEX_ATTRIB_COLOR);
                context.enableVertexAttribArray(cc.VERTEX_ATTRIB_TEX_COORDS);
                if (this._vertexDataDirty) {
                    context.bufferSubData(context.ARRAY_BUFFER, 0, this._float32View);
                    this._vertexDataDirty = false;
                }
                var locVertexDataLen = cc.V3F_C4B_T2F.BYTES_PER_ELEMENT;
                context.vertexAttribPointer(cc.VERTEX_ATTRIB_POSITION, 3, context.FLOAT, false, locVertexDataLen, 0);
                context.vertexAttribPointer(cc.VERTEX_ATTRIB_COLOR, 4, context.UNSIGNED_BYTE, true, locVertexDataLen, 12);
                context.vertexAttribPointer(cc.VERTEX_ATTRIB_TEX_COORDS, 2, context.FLOAT, false, locVertexDataLen, 16);
                if (node._type === cc.ProgressTimer.TYPE_RADIAL) {
                    context.drawArrays(context.TRIANGLE_FAN, 0, this._vertexDataCount);
                } else if (node._type === cc.ProgressTimer.TYPE_BAR) {
                    if (!node._reverseDirection) {
                        context.drawArrays(context.TRIANGLE_STRIP, 0, this._vertexDataCount);
                    } else {
                        context.drawArrays(context.TRIANGLE_STRIP, 0, this._vertexDataCount / 2);
                        context.drawArrays(context.TRIANGLE_STRIP, 4, this._vertexDataCount / 2);
                        cc.g_NumberOfDraws++;
                    }
                }
                cc.g_NumberOfDraws++;
            };
            proto._syncStatus = function (parentCmd) {
                var node = this._node;
                if (!node._sprite) {
                    return;
                }
                var flags = cc.Node._dirtyFlags, locFlag = this._dirtyFlag;
                var parentNode = parentCmd ? parentCmd._node : null;
                if (parentNode && parentNode._cascadeColorEnabled && (parentCmd._dirtyFlag & flags.colorDirty)) {
                    locFlag |= flags.colorDirty;
                }
                if (parentNode && parentNode._cascadeOpacityEnabled && (parentCmd._dirtyFlag & flags.opacityDirty)) {
                    locFlag |= flags.opacityDirty;
                }
                if (parentCmd && (parentCmd._dirtyFlag & flags.transformDirty)) {
                    locFlag |= flags.transformDirty;
                }
                this._dirtyFlag = locFlag;
                var spriteCmd = node._sprite._renderCmd;
                var spriteFlag = spriteCmd._dirtyFlag;
                var colorDirty = (locFlag | spriteFlag) & flags.colorDirty,
                    opacityDirty = (locFlag | spriteFlag) & flags.opacityDirty;
                if (colorDirty) {
                    spriteCmd._syncDisplayColor();
                    spriteCmd._dirtyFlag = spriteCmd._dirtyFlag & flags.colorDirty ^ spriteCmd._dirtyFlag;
                    this._dirtyFlag = this._dirtyFlag & flags.colorDirty ^ this._dirtyFlag;
                }
                if (opacityDirty) {
                    spriteCmd._syncDisplayOpacity();
                    spriteCmd._dirtyFlag = spriteCmd._dirtyFlag & flags.opacityDirty ^ spriteCmd._dirtyFlag;
                    this._dirtyFlag = this._dirtyFlag & flags.opacityDirty ^ this._dirtyFlag;
                }
                if (colorDirty || opacityDirty) {
                    this._updateColor();
                }
                if (locFlag & flags.transformDirty) {
                    this.transform(parentCmd);
                }
                if (locFlag & flags.textureDirty) {
                    this._updateProgressData();
                    this._dirtyFlag = this._dirtyFlag & flags.textureDirty ^ this._dirtyFlag;
                }
                spriteCmd._dirtyFlag = 0;
            };
            proto.updateStatus = function () {
                var node = this._node;
                if (!node._sprite) {
                    return;
                }
                var flags = cc.Node._dirtyFlags, locFlag = this._dirtyFlag;
                var spriteCmd = node._sprite._renderCmd;
                var spriteFlag = spriteCmd._dirtyFlag;
                var colorDirty = (locFlag | spriteFlag) & flags.colorDirty,
                    opacityDirty = (locFlag | spriteFlag) & flags.opacityDirty;
                if (colorDirty) {
                    spriteCmd._updateDisplayColor();
                    spriteCmd._dirtyFlag = spriteCmd._dirtyFlag & flags.colorDirty ^ spriteCmd._dirtyFlag;
                    this._dirtyFlag = this._dirtyFlag & flags.colorDirty ^ this._dirtyFlag;
                }
                if (opacityDirty) {
                    spriteCmd._updateDisplayOpacity();
                    spriteCmd._dirtyFlag = spriteCmd._dirtyFlag & flags.opacityDirty ^ spriteCmd._dirtyFlag;
                    this._dirtyFlag = this._dirtyFlag & flags.opacityDirty ^ this._dirtyFlag;
                }
                if (colorDirty || opacityDirty) {
                    this._updateColor();
                }
                if (locFlag & flags.transformDirty) {
                    this.transform(this.getParentRenderCmd(), true);
                }
                if (locFlag & flags.orderDirty) {
                    this._dirtyFlag = this._dirtyFlag & flags.orderDirty ^ this._dirtyFlag;
                }
                if (locFlag & flags.textureDirty) {
                    this._updateProgressData();
                    this._dirtyFlag = this._dirtyFlag & flags.textureDirty ^ this._dirtyFlag;
                }
            };
            proto.releaseData = function () {
                if (this._vertexData) {
                    var webglBuffer = this._vertexWebGLBuffer;
                    setTimeout(function () {
                        cc._renderContext.deleteBuffer(webglBuffer);
                    }, 0.1);
                    this._vertexWebGLBuffer = null;
                    this._vertexData = null;
                    this._float32View = null;
                    this._vertexArrayBuffer = null;
                    this._vertexDataCount = 0;
                }
            };
            proto.initCmd = function () {
                if (!this._vertexData) {
                    this._vertexWebGLBuffer = cc._renderContext.createBuffer();
                    var vertexDataLen = cc.V3F_C4B_T2F.BYTES_PER_ELEMENT;
                    this._vertexArrayBuffer = new ArrayBuffer(MAX_VERTEX_COUNT * vertexDataLen);
                    this._float32View = new Float32Array(this._vertexArrayBuffer);
                    this._vertexData = [];
                    for (var i = 0; i < MAX_VERTEX_COUNT; i++) {
                        this._vertexData[i] = new cc.V3F_C4B_T2F(null, null, null, this._vertexArrayBuffer, i * vertexDataLen);
                    }
                    gl.bindBuffer(gl.ARRAY_BUFFER, this._vertexWebGLBuffer);
                    gl.bufferData(gl.ARRAY_BUFFER, this._float32View, gl.DYNAMIC_DRAW);
                    this._vertexDataCount = 0;
                    this._vertexDataDirty = true;
                    this._shaderProgram = cc.shaderCache.programForKey(cc.SHADER_SPRITE_POSITION_TEXTURECOLOR);
                }
            };
            proto.resetVertexData = function () {
                this._vertexDataCount = 0;
            };
            proto._updateProgressData = function () {
                var node = this._node;
                var locType = node._type;
                if (locType === cc.ProgressTimer.TYPE_RADIAL) {
                    this._updateRadial();
                } else if (locType === cc.ProgressTimer.TYPE_BAR) {
                    this._updateBar();
                }
                this._vertexDataDirty = true;
            };
            proto._updateProgress = function () {
                this.setDirtyFlag(cc.Node._dirtyFlags.textureDirty);
            };
            proto._updateBar = function () {
                var node = this._node;
                if (!node._sprite) {
                    return;
                }
                var i, alpha = node._percentage / 100.0;
                var locBarChangeRate = node._barChangeRate;
                var alphaOffset = cc.pMult(cc.p(
                    (1.0 - locBarChangeRate.x) + alpha * locBarChangeRate.x,
                    (1.0 - locBarChangeRate.y) + alpha * locBarChangeRate.y
                ), 0.5);
                var min = cc.pSub(node._midPoint, alphaOffset), max = cc.pAdd(node._midPoint, alphaOffset);
                if (min.x < 0) {
                    max.x += -min.x;
                    min.x = 0;
                }
                if (max.x > 1) {
                    min.x -= max.x - 1;
                    max.x = 1;
                }
                if (min.y < 0) {
                    max.y += -min.y;
                    min.y = 0;
                }
                if (max.y > 1) {
                    min.y -= max.y - 1;
                    max.y = 1;
                }
                var locVertexData;
                if (!this._reverseDirection) {
                    if (!this._vertexDataCount) {
                        this._vertexDataCount = 4;
                    }
                    locVertexData = this._vertexData;
                    this._textureCoordFromAlphaPoint(locVertexData[0].texCoords, min.x, max.y);
                    this._vertexFromAlphaPoint(locVertexData[0].vertices, min.x, max.y);
                    this._textureCoordFromAlphaPoint(locVertexData[1].texCoords, min.x, min.y);
                    this._vertexFromAlphaPoint(locVertexData[1].vertices, min.x, min.y);
                    this._textureCoordFromAlphaPoint(locVertexData[2].texCoords, max.x, max.y);
                    this._vertexFromAlphaPoint(locVertexData[2].vertices, max.x, max.y);
                    this._textureCoordFromAlphaPoint(locVertexData[3].texCoords, max.x, min.y);
                    this._vertexFromAlphaPoint(locVertexData[3].vertices, max.x, min.y);
                } else {
                    locVertexData = this._vertexData;
                    if (!this._vertexDataCount) {
                        this._vertexDataCount = 8;
                        this._textureCoordFromAlphaPoint(locVertexData[0].texCoords, 0, 1);
                        this._vertexFromAlphaPoint(locVertexData[0].vertices, 0, 1);
                        this._textureCoordFromAlphaPoint(locVertexData[1].texCoords, 0, 0);
                        this._vertexFromAlphaPoint(locVertexData[1].vertices, 0, 0);
                        this._textureCoordFromAlphaPoint(locVertexData[6].texCoords, 1, 1);
                        this._vertexFromAlphaPoint(locVertexData[6].vertices, 1, 1);
                        this._textureCoordFromAlphaPoint(locVertexData[7].texCoords, 1, 0);
                        this._vertexFromAlphaPoint(locVertexData[7].vertices, 1, 0);
                    }
                    this._textureCoordFromAlphaPoint(locVertexData[2].texCoords, min.x, max.y);
                    this._vertexFromAlphaPoint(locVertexData[2].vertices, min.x, max.y);
                    this._textureCoordFromAlphaPoint(locVertexData[3].texCoords, min.x, min.y);
                    this._vertexFromAlphaPoint(locVertexData[3].vertices, min.x, min.y);
                    this._textureCoordFromAlphaPoint(locVertexData[4].texCoords, max.x, max.y);
                    this._vertexFromAlphaPoint(locVertexData[4].vertices, max.x, max.y);
                    this._textureCoordFromAlphaPoint(locVertexData[5].texCoords, max.x, min.y);
                    this._vertexFromAlphaPoint(locVertexData[5].vertices, max.x, min.y);
                }
                this._updateColor();
            };
            proto._updateRadial = function () {
                var node = this._node;
                if (!node._sprite) {
                    return;
                }
                var i, locMidPoint = node._midPoint;
                var alpha = node._percentage / 100;
                var angle = 2 * (cc.PI) * (node._reverseDirection ? alpha : 1.0 - alpha);
                var topMid = cc.p(locMidPoint.x, 1);
                var percentagePt = cc.pRotateByAngle(topMid, locMidPoint, angle);
                var index = 0;
                var hit;
                if (alpha === 0) {
                    hit = topMid;
                    index = 0;
                } else if (alpha === 1) {
                    hit = topMid;
                    index = 4;
                } else {
                    var min_t = cc.FLT_MAX;
                    var locProTextCoordsCount = cc.ProgressTimer.TEXTURE_COORDS_COUNT;
                    for (i = 0; i <= locProTextCoordsCount; ++i) {
                        var pIndex = (i + (locProTextCoordsCount - 1)) % locProTextCoordsCount;
                        var edgePtA = this._boundaryTexCoord(i % locProTextCoordsCount);
                        var edgePtB = this._boundaryTexCoord(pIndex);
                        if (i === 0) {
                            edgePtB = cc.pLerp(edgePtA, edgePtB, 1 - locMidPoint.x);
                        } else if (i === 4) {
                            edgePtA = cc.pLerp(edgePtA, edgePtB, 1 - locMidPoint.x);
                        }
                        var retPoint = cc.p(0, 0);
                        if (cc.pLineIntersect(edgePtA, edgePtB, locMidPoint, percentagePt, retPoint)) {
                            if ((i === 0 || i === 4)) {
                                if (!(retPoint.x >= 0 && retPoint.x <= 1)) {
                                    continue;
                                }
                            }
                            if (retPoint.y >= 0) {
                                if (retPoint.y < min_t) {
                                    min_t = retPoint.y;
                                    index = i;
                                }
                            }
                        }
                    }
                    hit = cc.pAdd(locMidPoint, cc.pMult(cc.pSub(percentagePt, locMidPoint), min_t));
                }
                var sameIndexCount = true;
                if (this._vertexDataCount !== index + 3) {
                    sameIndexCount = false;
                    this._vertexDataCount = index + 3;
                }
                this._updateColor();
                var locVertexData = this._vertexData;
                if (!sameIndexCount) {
                    this._textureCoordFromAlphaPoint(locVertexData[0].texCoords, locMidPoint.x, locMidPoint.y);
                    this._vertexFromAlphaPoint(locVertexData[0].vertices, locMidPoint.x, locMidPoint.y);
                    this._textureCoordFromAlphaPoint(locVertexData[1].texCoords, topMid.x, topMid.y);
                    this._vertexFromAlphaPoint(locVertexData[1].vertices, topMid.x, topMid.y);
                    for (i = 0; i < index; i++) {
                        var alphaPoint = this._boundaryTexCoord(i);
                        this._textureCoordFromAlphaPoint(locVertexData[i + 2].texCoords, alphaPoint.x, alphaPoint.y);
                        this._vertexFromAlphaPoint(locVertexData[i + 2].vertices, alphaPoint.x, alphaPoint.y);
                    }
                }
                this._textureCoordFromAlphaPoint(locVertexData[this._vertexDataCount - 1].texCoords, hit.x, hit.y);
                this._vertexFromAlphaPoint(locVertexData[this._vertexDataCount - 1].vertices, hit.x, hit.y);
            };
            proto._boundaryTexCoord = function (index) {
                if (index < cc.ProgressTimer.TEXTURE_COORDS_COUNT) {
                    var locProTextCoords = cc.ProgressTimer.TEXTURE_COORDS;
                    if (this._node._reverseDirection) {
                        return cc.p((locProTextCoords >> (7 - (index << 1))) & 1, (locProTextCoords >> (7 - ((index << 1) + 1))) & 1);
                    }
                    return cc.p((locProTextCoords >> ((index << 1) + 1)) & 1, (locProTextCoords >> (index << 1)) & 1);
                }
                return cc.p(0, 0);
            };
            proto._textureCoordFromAlphaPoint = function (coords, ax, ay) {
                var locSprite = this._node._sprite;
                if (!locSprite) {
                    coords.u = 0;
                    coords.v = 0;
                    return;
                }
                var uvs = locSprite._renderCmd._vertices,
                    bl = uvs[1],
                    tr = uvs[2];
                var min = cc.p(bl.u, bl.v);
                var max = cc.p(tr.u, tr.v);
                if (locSprite.textureRectRotated) {
                    var temp = ax;
                    ax = ay;
                    ay = temp;
                }
                coords.u = min.x * (1 - ax) + max.x * ax;
                coords.v = min.y * (1 - ay) + max.y * ay;
            };
            proto._vertexFromAlphaPoint = function (vertex, ax, ay) {
                vertex.x = this._bl.x * (1 - ax) + this._tr.x * ax;
                vertex.y = this._bl.y * (1 - ay) + this._tr.y * ay;
                vertex.z = this._node._vertexZ;
            };
            proto._updateColor = function () {
                var sp = this._node._sprite;
                if (!this._vertexDataCount || !sp) {
                    return;
                }
                var color = this._displayedColor;
                var spColor = sp._renderCmd._displayedColor;
                var r = spColor.r;
                var g = spColor.g;
                var b = spColor.b;
                var a = sp._renderCmd._displayedOpacity / 255;
                if (sp._opacityModifyRGB) {
                    r *= a;
                    g *= a;
                    b *= a;
                }
                color.r = r;
                color.g = g;
                color.b = b;
                color.a = sp._renderCmd._displayedOpacity;
                var locVertexData = this._vertexData;
                for (var i = 0, len = this._vertexDataCount; i < len; ++i) {
                    locVertexData[i].colors = color;
                }
                this._vertexDataDirty = true;
            };
        }());
        cc.SCENE_FADE = 4208917214;
        cc.TRANSITION_ORIENTATION_LEFT_OVER = 0;
        cc.TRANSITION_ORIENTATION_RIGHT_OVER = 1;
        cc.TRANSITION_ORIENTATION_UP_OVER = 0;
        cc.TRANSITION_ORIENTATION_DOWN_OVER = 1;
        cc.TransitionSceneOriented = cc.TransitionScene.extend({
            _orientation: 0,
            ctor: function (t, scene, orientation) {
                cc.TransitionScene.prototype.ctor.call(this);
                orientation != undefined && this.initWithDuration(t, scene, orientation);
            },
            initWithDuration: function (t, scene, orientation) {
                if (cc.TransitionScene.prototype.initWithDuration.call(this, t, scene)) {
                    this._orientation = orientation;
                }
                return true;
            }
        });
        cc.TransitionSceneOriented.create = function (t, scene, orientation) {
            return new cc.TransitionSceneOriented(t, scene, orientation);
        };
        cc.TransitionRotoZoom = cc.TransitionScene.extend({
            ctor: function (t, scene) {
                cc.TransitionScene.prototype.ctor.call(this);
                scene && this.initWithDuration(t, scene);
            },
            onEnter: function () {
                cc.TransitionScene.prototype.onEnter.call(this);
                this._inScene.attr({
                    scale: 0.001,
                    anchorX: 0.5,
                    anchorY: 0.5
                });
                this._outScene.attr({
                    scale: 1.0,
                    anchorX: 0.5,
                    anchorY: 0.5
                });
                var rotoZoom = cc.sequence(
                    cc.spawn(
                        cc.scaleBy(this._duration / 2, 0.001),
                        cc.rotateBy(this._duration / 2, 360 * 2)
                    ),
                    cc.delayTime(this._duration / 2)
                );
                this._outScene.runAction(rotoZoom);
                this._inScene.runAction(
                    cc.sequence(
                        rotoZoom.reverse(),
                        cc.callFunc(this.finish, this)
                    )
                );
            }
        });
        cc.TransitionRotoZoom.create = function (t, scene) {
            return new cc.TransitionRotoZoom(t, scene);
        };
        cc.TransitionJumpZoom = cc.TransitionScene.extend({
            ctor: function (t, scene) {
                cc.TransitionScene.prototype.ctor.call(this);
                scene && this.initWithDuration(t, scene);
            },
            onEnter: function () {
                cc.TransitionScene.prototype.onEnter.call(this);
                var winSize = cleverapps.resolution.getSceneSize();
                this._inScene.attr({
                    scale: 0.5,
                    x: winSize.width,
                    y: 0,
                    anchorX: 0.5,
                    anchorY: 0.5
                });
                this._outScene.anchorX = 0.5;
                this._outScene.anchorY = 0.5;
                var jump = cc.jumpBy(this._duration / 4, cc.p(-winSize.width, 0), winSize.width / 4, 2);
                var scaleIn = cc.scaleTo(this._duration / 4, 1.0);
                var scaleOut = cc.scaleTo(this._duration / 4, 0.5);
                var jumpZoomOut = cc.sequence(scaleOut, jump);
                var jumpZoomIn = cc.sequence(jump, scaleIn);
                var delay = cc.delayTime(this._duration / 2);
                this._outScene.runAction(jumpZoomOut);
                this._inScene.runAction(cc.sequence(delay, jumpZoomIn, cc.callFunc(this.finish, this)));
            }
        });
        cc.TransitionJumpZoom.create = function (t, scene) {
            return new cc.TransitionJumpZoom(t, scene);
        };
        cc.TransitionMoveInL = cc.TransitionScene.extend({
            ctor: function (t, scene) {
                cc.TransitionScene.prototype.ctor.call(this);
                scene && this.initWithDuration(t, scene);
            },
            onEnter: function () {
                cc.TransitionScene.prototype.onEnter.call(this);
                this.initScenes();
                var action = this.action();
                this._inScene.runAction(
                    cc.sequence(this.easeActionWithAction(action), cc.callFunc(this.finish, this))
                );
            },
            initScenes: function () {
                this._inScene.setPosition(-cleverapps.resolution.getSceneSize().width, 0);
            },
            action: function () {
                return cc.moveTo(this._duration, cc.p(0, 0));
            },
            easeActionWithAction: function (action) {
                return new cc.EaseOut(action, 2.0);
            }
        });
        cc.TransitionMoveInL.create = function (t, scene) {
            return new cc.TransitionMoveInL(t, scene);
        };
        cc.TransitionMoveInR = cc.TransitionMoveInL.extend({
            ctor: function (t, scene) {
                cc.TransitionMoveInL.prototype.ctor.call(this);
                scene && this.initWithDuration(t, scene);
            },
            initScenes: function () {
                this._inScene.setPosition(cleverapps.resolution.getSceneSize().width, 0);
            }
        });
        cc.TransitionMoveInR.create = function (t, scene) {
            return new cc.TransitionMoveInR(t, scene);
        };
        cc.TransitionMoveInT = cc.TransitionMoveInL.extend({
            ctor: function (t, scene) {
                cc.TransitionMoveInL.prototype.ctor.call(this);
                scene && this.initWithDuration(t, scene);
            },
            initScenes: function () {
                this._inScene.setPosition(0, cleverapps.resolution.getSceneSize().height);
            }
        });
        cc.TransitionMoveInT.create = function (t, scene) {
            return new cc.TransitionMoveInT(t, scene);
        };
        cc.TransitionMoveInB = cc.TransitionMoveInL.extend({
            ctor: function (t, scene) {
                cc.TransitionMoveInL.prototype.ctor.call(this);
                scene && this.initWithDuration(t, scene);
            },
            initScenes: function () {
                this._inScene.setPosition(0, -cleverapps.resolution.getSceneSize().height);
            }
        });
        cc.TransitionMoveInB.create = function (t, scene) {
            return new cc.TransitionMoveInB(t, scene);
        };
        cc.ADJUST_FACTOR = 0.5;
        cc.TransitionSlideInL = cc.TransitionScene.extend({
            ctor: function (t, scene) {
                cc.TransitionScene.prototype.ctor.call(this);
                scene && this.initWithDuration(t, scene);
            },
            _sceneOrder: function () {
                this._isInSceneOnTop = false;
            },
            onEnter: function () {
                cc.TransitionScene.prototype.onEnter.call(this);
                this.initScenes();
                var inA = this.action();
                var outA = this.action();
                var inAction = cc.sequence(this.easeActionWithAction(inA), cc.callFunc(this.finish, this));
                var outAction = this.easeActionWithAction(outA);
                this._inScene.runAction(inAction);
                this._outScene.runAction(outAction);
            },
            initScenes: function () {
                this._inScene.setPosition(-cleverapps.resolution.getSceneSize().width + cc.ADJUST_FACTOR, 0);
            },
            action: function () {
                return cc.moveBy(this._duration, cc.p(cleverapps.resolution.getSceneSize().width - cc.ADJUST_FACTOR, 0));
            },
            easeActionWithAction: function (action) {
                return new cc.EaseInOut(action, 2.0);
            }
        });
        cc.TransitionSlideInL.create = function (t, scene) {
            return new cc.TransitionSlideInL(t, scene);
        };
        cc.TransitionSlideInR = cc.TransitionSlideInL.extend({
            ctor: function (t, scene) {
                cc.TransitionSlideInL.prototype.ctor.call(this);
                scene && this.initWithDuration(t, scene);
            },
            _sceneOrder: function () {
                this._isInSceneOnTop = true;
            },
            initScenes: function () {
                this._inScene.setPosition(cleverapps.resolution.getSceneSize().width - cc.ADJUST_FACTOR, 0);
            },
            action: function () {
                return cc.moveBy(this._duration, cc.p(-(cleverapps.resolution.getSceneSize().width - cc.ADJUST_FACTOR), 0));
            }
        });
        cc.TransitionSlideInR.create = function (t, scene) {
            return new cc.TransitionSlideInR(t, scene);
        };
        // cc.TransitionSlideInB = cc.TransitionSlideInL.extend({
        //     ctor: function (t, scene) {
        //         cc.TransitionSlideInL.prototype.ctor.call(this);
        //         scene && this.initWithDuration(t, scene);
        //     },
        //     _sceneOrder: function () {
        //         this._isInSceneOnTop = false;
        //     },
        //     initScenes: function () {
        //         this._inScene.setPosition(0, -(cleverapps.resolution.getSceneSize().height - cc.ADJUST_FACTOR));
        //     },
        //     action: function () {
        //         return cc.moveBy(this._duration, cc.p(0, cleverapps.resolution.getSceneSize().height - cc.ADJUST_FACTOR));
        //     }
        // });
        // cc.TransitionSlideInB.create = function (t, scene) {
        //     return new cc.TransitionSlideInB(t, scene);
        // };
        // cc.TransitionSlideInT = cc.TransitionSlideInL.extend({
        //     ctor: function (t, scene) {
        //         cc.TransitionSlideInL.prototype.ctor.call(this);
        //         scene && this.initWithDuration(t, scene);
        //     },
        //     _sceneOrder: function () {
        //         this._isInSceneOnTop = true;
        //     },
        //     initScenes: function () {
        //         this._inScene.setPosition(0, cleverapps.resolution.getSceneSize().height - cc.ADJUST_FACTOR);
        //     },
        //     action: function () {
        //         return cc.moveBy(this._duration, cc.p(0, -(cleverapps.resolution.getSceneSize().height - cc.ADJUST_FACTOR)));
        //     }
        // });
        // cc.TransitionSlideInT.create = function (t, scene) {
        //     return new cc.TransitionSlideInT(t, scene);
        // };
        cc.TransitionShrinkGrow = cc.TransitionScene.extend({
            ctor: function (t, scene) {
                cc.TransitionScene.prototype.ctor.call(this);
                scene && this.initWithDuration(t, scene);
            },
            onEnter: function () {
                cc.TransitionScene.prototype.onEnter.call(this);
                this._inScene.attr({
                    scale: 0.001,
                    anchorX: 2 / 3.0,
                    anchorY: 0.5
                });
                this._outScene.attr({
                    scale: 1.0,
                    anchorX: 1 / 3.0,
                    anchorY: 0.5
                });
                var scaleOut = cc.scaleTo(this._duration, 0.01);
                var scaleIn = cc.scaleTo(this._duration, 1.0);
                this._inScene.runAction(cc.sequence(this.easeActionWithAction(scaleIn), cc.callFunc(this.finish, this)));
                this._outScene.runAction(this.easeActionWithAction(scaleOut));
            },
            easeActionWithAction: function (action) {
                return new cc.EaseOut(action, 2.0);
            }
        });
        cc.TransitionShrinkGrow.create = function (t, scene) {
            return new cc.TransitionShrinkGrow(t, scene);
        };
        cc.TransitionFade = cc.TransitionScene.extend({
            _color: null,
            ctor: function (t, scene, color) {
                cc.TransitionScene.prototype.ctor.call(this);
                this._color = cc.color();
                scene && this.initWithDuration(t, scene, color);
            },
            onEnter: function () {
                cc.TransitionScene.prototype.onEnter.call(this);
                var l = new cc.LayerColor(this._color);
                this._inScene.visible = false;
                this.addChild(l, 2, cc.SCENE_FADE);
                var f = this.getChildByTag(cc.SCENE_FADE);
                var a = cc.sequence(
                    cc.fadeIn(this._duration / 2),
                    cc.callFunc(this.hideOutShowIn, this),
                    cc.fadeOut(this._duration / 2),
                    cc.callFunc(this.finish, this)
                );
                f.runAction(a);
            },
            onExit: function () {
                cc.TransitionScene.prototype.onExit.call(this);
                this.removeChildByTag(cc.SCENE_FADE, false);
            },
            initWithDuration: function (t, scene, color) {
                color = color || cc.color.BLACK;
                if (cc.TransitionScene.prototype.initWithDuration.call(this, t, scene)) {
                    this._color.r = color.r;
                    this._color.g = color.g;
                    this._color.b = color.b;
                    this._color.a = 0;
                }
                return true;
            }
        });
        cc.TransitionFade.create = function (t, scene, color) {
            return new cc.TransitionFade(t, scene, color);
        };
        cc.TransitionCrossFade = cc.TransitionScene.extend({
            ctor: function (t, scene) {
                cc.TransitionScene.prototype.ctor.call(this);
                scene && this.initWithDuration(t, scene);
            },
            onEnter: function () {
                cc.TransitionScene.prototype.onEnter.call(this);
                var color = cc.color(0, 0, 0, 0);
                var winSize = cleverapps.resolution.getSceneSize();
                var layer = new cc.LayerColor(color);
                var inTexture = new cc.RenderTexture(winSize.width, winSize.height);
                inTexture.sprite.anchorX = 0.5;
                inTexture.sprite.anchorY = 0.5;
                inTexture.attr({
                    x: winSize.width / 2,
                    y: winSize.height / 2,
                    anchorX: 0.5,
                    anchorY: 0.5
                });
                inTexture.begin();
                this._inScene.visit();
                inTexture.end();
                var outTexture = new cc.RenderTexture(winSize.width, winSize.height);
                outTexture.setPosition(winSize.width / 2, winSize.height / 2);
                outTexture.sprite.anchorX = outTexture.anchorX = 0.5;
                outTexture.sprite.anchorY = outTexture.anchorY = 0.5;
                outTexture.begin();
                this._outScene.visit();
                outTexture.end();
                inTexture.sprite.setBlendFunc(cc.ONE, cc.ONE);
                outTexture.sprite.setBlendFunc(cc.SRC_ALPHA, cc.ONE_MINUS_SRC_ALPHA);
                layer.addChild(inTexture);
                layer.addChild(outTexture);
                inTexture.sprite.opacity = 255;
                outTexture.sprite.opacity = 255;
                var layerAction = cc.sequence(
                    cc.fadeTo(this._duration, 0), 
                    cc.callFunc(this.hideOutShowIn, this),
                    cc.callFunc(this.finish, this)
                );
                outTexture.sprite.runAction(layerAction);
                this.addChild(layer, 2, cc.SCENE_FADE);
            },
            onExit: function () {
                this.removeChildByTag(cc.SCENE_FADE, false);
                cc.TransitionScene.prototype.onExit.call(this);
            },
            visit: function () {
                cc.Node.prototype.visit.call(this);
            },
            draw: function () {
            }
        });
        cc.TransitionCrossFade.create = function (t, scene) {
            return new cc.TransitionCrossFade(t, scene);
        };
        cc.TransitionTurnOffTiles = cc.TransitionScene.extend({
            _gridProxy: null,
            ctor: function (t, scene) {
                cc.TransitionScene.prototype.ctor.call(this);
                this._gridProxy = new cc.NodeGrid();
                scene && this.initWithDuration(t, scene);
            },
            _sceneOrder: function () {
                this._isInSceneOnTop = false;
            },
            onEnter: function () {
                cc.TransitionScene.prototype.onEnter.call(this);
                this._gridProxy.setTarget(this._outScene);
                this._gridProxy.onEnter();
                var winSize = cleverapps.resolution.getSceneSize();
                var aspect = winSize.width / winSize.height;
                var x = 0 | (12 * aspect);
                var y = 12;
                var toff = cc.turnOffTiles(this._duration, cc.size(x, y));
                var action = this.easeActionWithAction(toff);
                this._gridProxy.runAction(cc.sequence(action, cc.callFunc(this.finish, this), cc.stopGrid()));
            },
            visit: function () {
                this._inScene.visit();
                this._gridProxy.visit();
            },
            easeActionWithAction: function (action) {
                return action;
            }
        });
        cc.TransitionTurnOffTiles.create = function (t, scene) {
            return new cc.TransitionTurnOffTiles(t, scene);
        };
        cc.TransitionSplitCols = cc.TransitionScene.extend({
            _gridProxy: null,
            _switchTargetToInscene: function () {
                this._gridProxy.setTarget(this._inScene);
            },
            ctor: function (t, scene) {
                cc.TransitionScene.prototype.ctor.call(this);
                this._gridProxy = new cc.NodeGrid();
                scene && this.initWithDuration(t, scene);
            },
            onEnter: function () {
                cc.TransitionScene.prototype.onEnter.call(this);
                this._gridProxy.setTarget(this._outScene);
                this._gridProxy.onEnter();
                var split = this.action();
                var seq = cc.sequence(split, cc.callFunc(this._switchTargetToInscene, this), split.reverse());
                this._gridProxy.runAction(
                    cc.sequence(this.easeActionWithAction(seq), cc.callFunc(this.finish, this), cc.stopGrid())
                );
            },
            onExit: function () {
                this._gridProxy.setTarget(null);
                this._gridProxy.onExit();
                cc.TransitionScene.prototype.onExit.call(this);
            },
            visit: function () {
                this._gridProxy.visit();
            },
            easeActionWithAction: function (action) {
                return new cc.EaseInOut(action, 3.0);
            },
            action: function () {
                return cc.splitCols(this._duration / 2.0, 3);
            }
        });
        cc.TransitionSplitCols.create = function (t, scene) {
            return new cc.TransitionSplitCols(t, scene);
        };
        cc.TransitionSplitRows = cc.TransitionSplitCols.extend({
            ctor: function (t, scene) {
                cc.TransitionSplitCols.prototype.ctor.call(this);
                scene && this.initWithDuration(t, scene);
            },
            action: function () {
                return cc.splitRows(this._duration / 2.0, 3);
            }
        });
        cc.TransitionSplitRows.create = function (t, scene) {
            return new cc.TransitionSplitRows(t, scene);
        };
        cc.TransitionFadeTR = cc.TransitionScene.extend({
            _gridProxy: null,
            ctor: function (t, scene) {
                cc.TransitionScene.prototype.ctor.call(this);
                this._gridProxy = new cc.NodeGrid();
                scene && this.initWithDuration(t, scene);
            },
            _sceneOrder: function () {
                this._isInSceneOnTop = false;
            },
            onEnter: function () {
                cc.TransitionScene.prototype.onEnter.call(this);
                this._gridProxy.setTarget(this._outScene);
                this._gridProxy.onEnter();
                var winSize = cleverapps.resolution.getSceneSize();
                var aspect = winSize.width / winSize.height;
                var x = 0 | (12 * aspect);
                var y = 12;
                var action = this.actionWithSize(cc.size(x, y));
                this._gridProxy.runAction(
                    cc.sequence(this.easeActionWithAction(action), cc.callFunc(this.finish, this), cc.stopGrid())
                );
            },
            visit: function () {
                this._inScene.visit();
                this._gridProxy.visit();
            },
            easeActionWithAction: function (action) {
                return action;
            },
            actionWithSize: function (size) {
                return cc.fadeOutTRTiles(this._duration, size);
            }
        });
        cc.TransitionFadeTR.create = function (t, scene) {
            return new cc.TransitionFadeTR(t, scene);
        };
        cc.TransitionFadeBL = cc.TransitionFadeTR.extend({
            ctor: function (t, scene) {
                cc.TransitionFadeTR.prototype.ctor.call(this);
                scene && this.initWithDuration(t, scene);
            },
            actionWithSize: function (size) {
                return cc.fadeOutBLTiles(this._duration, size);
            }
        });
        cc.TransitionFadeBL.create = function (t, scene) {
            return new cc.TransitionFadeBL(t, scene);
        };
        cc.TransitionFadeUp = cc.TransitionFadeTR.extend({
            ctor: function (t, scene) {
                cc.TransitionFadeTR.prototype.ctor.call(this);
                scene && this.initWithDuration(t, scene);
            },
            actionWithSize: function (size) {
                return new cc.FadeOutUpTiles(this._duration, size);
            }
        });
        cc.TransitionFadeUp.create = function (t, scene) {
            return new cc.TransitionFadeUp(t, scene);
        };
        cc.TransitionFadeDown = cc.TransitionFadeTR.extend({
            ctor: function (t, scene) {
                cc.TransitionFadeTR.prototype.ctor.call(this);
                scene && this.initWithDuration(t, scene);
            },
            actionWithSize: function (size) {
                return cc.fadeOutDownTiles(this._duration, size);
            }
        });
        cc.TransitionFadeDown.create = function (t, scene) {
            return new cc.TransitionFadeDown(t, scene);
        };
        cc.SCENE_RADIAL = 0xc001;
        cc.TransitionProgress = cc.TransitionScene.extend({
            _to: 0,
            _from: 0,
            _sceneToBeModified: null,
            _className: "TransitionProgress",
            ctor: function (t, scene) {
                cc.TransitionScene.prototype.ctor.call(this);
                scene && this.initWithDuration(t, scene);
            },
            _setAttrs: function (node, x, y) {
                node.attr({
                    x: x,
                    y: y,
                    anchorX: 0.5,
                    anchorY: 0.5
                });
            },
            onEnter: function () {
                cc.TransitionScene.prototype.onEnter.call(this);
                this._setupTransition();
                var winSize = cleverapps.resolution.getSceneSize();
                var texture = new cc.RenderTexture(winSize.width, winSize.height);
                texture.sprite.anchorX = 0.5;
                texture.sprite.anchorY = 0.5;
                this._setAttrs(texture, winSize.width / 2, winSize.height / 2);
                texture.clear(0, 0, 0, 1);
                texture.begin();
                this._sceneToBeModified.visit();
                texture.end();
                if (this._sceneToBeModified === this._outScene) {
                    this.hideOutShowIn();
                }
                var pNode = this._progressTimerNodeWithRenderTexture(texture);
                var layerAction = cc.sequence(
                    cc.progressFromTo(this._duration, this._from, this._to),
                    cc.callFunc(this.finish, this)
                );
                pNode.runAction(layerAction);
                this.addChild(pNode, 2, cc.SCENE_RADIAL);
            },
            onExit: function () {
                this.removeChildByTag(cc.SCENE_RADIAL, true);
                cc.TransitionScene.prototype.onExit.call(this);
            },
            _setupTransition: function () {
                this._sceneToBeModified = this._outScene;
                this._from = 100;
                this._to = 0;
            },
            _progressTimerNodeWithRenderTexture: function (texture) {
                cc.log("cc.TransitionProgress._progressTimerNodeWithRenderTexture(): should be overridden in subclass");
                return null;
            },
            _sceneOrder: function () {
                this._isInSceneOnTop = false;
            }
        });
        cc.TransitionProgress.create = function (t, scene) {
            return new cc.TransitionProgress(t, scene);
        };
        cc.TransitionProgressRadialCCW = cc.TransitionProgress.extend({
            ctor: function (t, scene) {
                cc.TransitionProgress.prototype.ctor.call(this);
                scene && this.initWithDuration(t, scene);
            },
            _progressTimerNodeWithRenderTexture: function (texture) {
                var size = cleverapps.resolution.getSceneSize();
                var pNode = new cc.ProgressTimer(texture.sprite);
                if (cc._renderType === cc.game.RENDER_TYPE_WEBGL) {
                    pNode.sprite.flippedY = true;
                }
                pNode.type = cc.ProgressTimer.TYPE_RADIAL;
                pNode.reverseDir = false;
                pNode.percentage = 100;
                this._setAttrs(pNode, size.width / 2, size.height / 2);
                return pNode;
            }
        });
        cc.TransitionProgressRadialCCW.create = function (t, scene) {
            return new cc.TransitionProgressRadialCCW(t, scene);
        };
        cc.TransitionProgressRadialCW = cc.TransitionProgress.extend({
            ctor: function (t, scene) {
                cc.TransitionProgress.prototype.ctor.call(this);
                scene && this.initWithDuration(t, scene);
            },
            _progressTimerNodeWithRenderTexture: function (texture) {
                var size = cleverapps.resolution.getSceneSize();
                var pNode = new cc.ProgressTimer(texture.sprite);
                if (cc._renderType === cc.game.RENDER_TYPE_WEBGL) {
                    pNode.sprite.flippedY = true;
                }
                pNode.type = cc.ProgressTimer.TYPE_RADIAL;
                pNode.reverseDir = true;
                pNode.percentage = 100;
                this._setAttrs(pNode, size.width / 2, size.height / 2);
                return pNode;
            }
        });
        cc.TransitionProgressRadialCW.create = function (t, scene) {
            var tempScene = new cc.TransitionProgressRadialCW();
            if ((tempScene !== null) && (tempScene.initWithDuration(t, scene))) {
                return tempScene;
            }
            return new cc.TransitionProgressRadialCW(t, scene);
        };
        cc.TransitionProgressHorizontal = cc.TransitionProgress.extend({
            ctor: function (t, scene) {
                cc.TransitionProgress.prototype.ctor.call(this);
                scene && this.initWithDuration(t, scene);
            },
            _progressTimerNodeWithRenderTexture: function (texture) {
                var size = cleverapps.resolution.getSceneSize();
                var pNode = new cc.ProgressTimer(texture.sprite);
                if (cc._renderType === cc.game.RENDER_TYPE_WEBGL) {
                    pNode.sprite.flippedY = true;
                }
                pNode.type = cc.ProgressTimer.TYPE_BAR;
                pNode.midPoint = cc.p(1, 0);
                pNode.barChangeRate = cc.p(1, 0);
                pNode.percentage = 100;
                this._setAttrs(pNode, size.width / 2, size.height / 2);
                return pNode;
            }
        });
        cc.TransitionProgressHorizontal.create = function (t, scene) {
            return new cc.TransitionProgressHorizontal(t, scene);
        };
        cc.TransitionProgressVertical = cc.TransitionProgress.extend({
            ctor: function (t, scene) {
                cc.TransitionProgress.prototype.ctor.call(this);
                scene && this.initWithDuration(t, scene);
            },
            _progressTimerNodeWithRenderTexture: function (texture) {
                var size = cleverapps.resolution.getSceneSize();
                var pNode = new cc.ProgressTimer(texture.sprite);
                if (cc._renderType === cc.game.RENDER_TYPE_WEBGL) {
                    pNode.sprite.flippedY = true;
                }
                pNode.type = cc.ProgressTimer.TYPE_BAR;
                pNode.midPoint = cc.p(0, 0);
                pNode.barChangeRate = cc.p(0, 1);
                pNode.percentage = 100;
                this._setAttrs(pNode, size.width / 2, size.height / 2);
                return pNode;
            }
        });
        cc.TransitionProgressVertical.create = function (t, scene) {
            return new cc.TransitionProgressVertical(t, scene);
        };
        cc.TransitionProgressInOut = cc.TransitionProgress.extend({
            ctor: function (t, scene) {
                cc.TransitionProgress.prototype.ctor.call(this);
                scene && this.initWithDuration(t, scene);
            },
            _progressTimerNodeWithRenderTexture: function (texture) {
                var size = cleverapps.resolution.getSceneSize();
                var pNode = new cc.ProgressTimer(texture.sprite);
                if (cc._renderType === cc.game.RENDER_TYPE_WEBGL) {
                    pNode.sprite.flippedY = true;
                }
                pNode.type = cc.ProgressTimer.TYPE_BAR;
                pNode.midPoint = cc.p(0.5, 0.5);
                pNode.barChangeRate = cc.p(1, 1);
                pNode.percentage = 0;
                this._setAttrs(pNode, size.width / 2, size.height / 2);
                return pNode;
            },
            _sceneOrder: function () {
                this._isInSceneOnTop = false;
            },
            _setupTransition: function () {
                this._sceneToBeModified = this._inScene;
                this._from = 0;
                this._to = 100;
            }
        });
        cc.TransitionProgressInOut.create = function (t, scene) {
            return new cc.TransitionProgressInOut(t, scene);
        };
        cc.TransitionProgressOutIn = cc.TransitionProgress.extend({
            ctor: function (t, scene) {
                cc.TransitionProgress.prototype.ctor.call(this);
                scene && this.initWithDuration(t, scene);
            },
            _progressTimerNodeWithRenderTexture: function (texture) {
                var size = cleverapps.resolution.getSceneSize();
                var pNode = new cc.ProgressTimer(texture.sprite);
                if (cc._renderType === cc.game.RENDER_TYPE_WEBGL) {
                    pNode.sprite.flippedY = true;
                }
                pNode.type = cc.ProgressTimer.TYPE_BAR;
                pNode.midPoint = cc.p(0.5, 0.5);
                pNode.barChangeRate = cc.p(1, 1);
                pNode.percentage = 100;
                this._setAttrs(pNode, size.width / 2, size.height / 2);
                return pNode;
            }
        });
        cc.TransitionProgressOutIn.create = function (t, scene) {
            return new cc.TransitionProgressOutIn(t, scene);
        };
        cc.TransitionPageTurn = cc.TransitionScene.extend({
            ctor: function (t, scene, backwards) {
                cc.TransitionScene.prototype.ctor.call(this);
                this._gridProxy = new cc.NodeGrid();
                this.initWithDuration(t, scene, backwards);
            },
            _back: true,
            _gridProxy: null,
            _className: "TransitionPageTurn",
            initWithDuration: function (t, scene, backwards) {
                this._back = backwards;
                if (cc.TransitionScene.prototype.initWithDuration.call(this, t, scene)) {
                }
                return true;
            },
            actionWithSize: function (vector) {
                if (this._back) {
                    return cc.reverseTime(cc.pageTurn3D(this._duration, vector));
                }
                return cc.pageTurn3D(this._duration, vector);
            },
            onEnter: function () {
                cc.TransitionScene.prototype.onEnter.call(this);
                var winSize = cleverapps.resolution.getSceneSize();
                var x, y;
                if (winSize.width > winSize.height) {
                    x = 16;
                    y = 12;
                } else {
                    x = 12;
                    y = 16;
                }
                var action = this.actionWithSize(cc.size(x, y)), gridProxy = this._gridProxy;
                if (!this._back) {
                    gridProxy.setTarget(this._outScene);
                    gridProxy.onEnter();
                    gridProxy.runAction(cc.sequence(action, cc.callFunc(this.finish, this), cc.stopGrid()));
                } else {
                    gridProxy.setTarget(this._inScene);
                    gridProxy.onEnter();
                    this._inScene.visible = false;
                    gridProxy.runAction(
                        cc.sequence(action, cc.callFunc(this.finish, this), cc.stopGrid())
                    );
                    this._inScene.runAction(cc.show());
                }
            },
            visit: function () {
                if (this._back) {
                    this._outScene.visit();
                } else {
                    this._inScene.visit();
                }
                this._gridProxy.visit();
            },
            _sceneOrder: function () {
                this._isInSceneOnTop = this._back;
            }
        });
        cc.TransitionPageTurn.create = function (t, scene, backwards) {
            return new cc.TransitionPageTurn(t, scene, backwards);
        };
    }
};