/**
 * Created by olga on 26.10.2022
 */

var TextHintContent = function (options) {
    this.message = options.message;
    this.toReplace = options.toReplace;
};

TextHintContent.isApplicable = function (options) {
    return options.message !== undefined;
};

TextHintContent.prototype.hasSameContent = function (options) {
    if (!options.message) {
        return false;
    }
    return this.message === options.message && cleverapps.cmp(this.toReplace, options.toReplace);
};

TextHintContent.prototype.getMessages = function () {
    if (Array.isArray(this.message)) {
        return this.message;
    }
    return [
        { text: this.message, toReplace: this.toReplace, align: true }
    ];
};