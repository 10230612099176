/**
 * Created by Andrey Popov on 4/14/21.
 */

var ActionsPropertyEditor = function (property, initialValue, onChangeValueCallback) {
    this.property = property;
    this.onChangeValueCallback = onChangeValueCallback;
    this.value = initialValue || [];

    this.propertyEditors = [
        new PropertyEditor({
            name: property.name,
            actions: [{
                import: function () {
                    this.onChangeValueCallback(this.property.name, cleverapps.loadedSnapshot ? cleverapps.loadedSnapshot.actions : []);
                    cleverapps.wysiwyg.inspector.showItemProperties();
                }.bind(this)
            }, {
                clear: function () {
                    this.value = [];
                    this.onChangeValueCallback(this.property.name, []);
                    cleverapps.wysiwyg.inspector.showItemProperties();
                }.bind(this)
            }]
        }, this),
        this.getActionsSummary({
            getter: function () {
                if (cleverapps.wysiwyg.inspector.selectedActionIndex === undefined || this.value.length < cleverapps.wysiwyg.inspector.selectedActionIndex) {
                    return "";
                }

                this.value.forEach(function (action) {
                    action.timestamp = undefined;
                });
                return JSON.stringify(this.value[cleverapps.wysiwyg.inspector.selectedActionIndex], undefined, 4);
            }.bind(this),
            setter: function (value) {
                value.timestamp = undefined;
                this.value[cleverapps.wysiwyg.inspector.selectedActionIndex] = value;
            }.bind(this)
        })
    ];
};

ActionsPropertyEditor.prototype.editBoxEditingDidEnd = function (sender) {
    try {
        var value = JSON.parse(sender.getString());
        sender.customSetter(value);
        this.onChangeValueCallback(this.property.name, this.value);
        cleverapps.wysiwyg.inspector.showItemProperties();
    } catch (e) {
        console.log(e);
    }
};

ActionsPropertyEditor.prototype.getActionsSummary = function (propertyDescription) {
    var items = this.value.filter(function (action) {
        return action.type !== undefined;
    }).map(this.getActionView.bind(this));

    var selection = cleverapps.wysiwyg.inspector.selectedActionIndex;
    if (selection !== undefined && this.value[selection]) {
        propertyDescription.lines = Math.min(JSON.stringify(this.value[selection], undefined, 4).split("\n").length, 15);
        var textArea = new PropertyEditor(propertyDescription, this);
        if (selection === items.length - 1) {
            items.push(textArea);
        } else {
            items.splice(selection + 1, 0, textArea);
        }
    }

    return new cleverapps.Layout(items, {
        direction: cleverapps.UI.VERTICAL
    });
};

ActionsPropertyEditor.prototype.getActionView = function (action, actionIndex) {
    var styles = cleverapps.styles.ActionsPropertyEditor;

    var toggleEnabled = cleverapps.UI.createSprite(action.disabled ? bundles.editor_controls.frames.component_item_hidden : bundles.editor_controls.frames.component_item);
    cleverapps.UI.applyHover(toggleEnabled);
    cleverapps.UI.onClick(toggleEnabled, function () {
        if (action.disabled) {
            delete action.disabled;
        } else {
            action.disabled = true;
        }

        this.onChangeValueCallback(this.property.name, this.value);
        cleverapps.wysiwyg.inspector.showItemProperties();
    }.bind(this));

    var decTime = cleverapps.UI.createSprite(bundles.editor_controls.frames.dropdown);
    decTime.setRotation(90);
    cleverapps.UI.applyHover(decTime);
    cleverapps.UI.onClick(decTime, function () {
        this.updateDelay(action, -0.1);
    }.bind(this));

    var beforeStartDelayStr = cleverapps.UI.generateOnlyText(
        (action.beforeStartDelay || 0).toLocaleString("en", { maximumFractionDigits: 1 }),
        cleverapps.styles.FONTS.PROPERTY_EDITOR_TEXT
    );
    beforeStartDelayStr.setDimensions(styles.timestamp);
    beforeStartDelayStr.setHorizontalAlignment(cc.TEXT_ALIGNMENT_RIGHT);

    var incTime = cleverapps.UI.createSprite(bundles.editor_controls.frames.dropdown);
    incTime.setRotation(-90);
    cleverapps.UI.applyHover(incTime);
    cleverapps.UI.onClick(incTime, function () {
        this.updateDelay(action, 0.1);
    }.bind(this));

    var actionName = action.type;
    if (actionName === "click_uielement") {
        actionName = "click " + (action.id || "").substring(0, 14) + "...";
    }
    var actionNameString = cleverapps.UI.generateOnlyText(actionName, cleverapps.styles.FONTS.PROPERTY_EDITOR_TEXT);
    actionNameString.setDimensions(styles.actionName.width, 0);
    cleverapps.UI.applyHover(actionNameString);
    cleverapps.UI.onClick(actionNameString, function () {
        if (cleverapps.wysiwyg.inspector.selectedActionIndex === actionIndex) {
            cleverapps.wysiwyg.inspector.selectedActionIndex = undefined;
        } else {
            cleverapps.wysiwyg.inspector.selectedActionIndex = actionIndex;
        }
        cleverapps.wysiwyg.inspector.showItemProperties();
    });

    var removeAction = cleverapps.UI.createSprite(bundles.wysiwyg.frames.delete_small);
    removeAction.setColor(cc.color.RED);
    removeAction.setVisible(false);

    cleverapps.UI.onClick(removeAction, function () {
        if (window.confirm("Remove '" + (action.type || action) + "' action?")) {
            this.value.splice(actionIndex, 1);
            this.onChangeValueCallback(this.property.name, this.value);
            cleverapps.wysiwyg.inspector.showItemProperties();
        }
    }.bind(this));

    var actionRow = new cleverapps.Layout([toggleEnabled, decTime, beforeStartDelayStr, incTime, actionNameString, removeAction], {
        direction: cleverapps.UI.HORIZONTAL,
        margin: styles.margin
    });
    cleverapps.UI.applyHover(actionRow, {
        onMouseOver: function () {
            removeAction.setVisible(true);
            removeAction.setScale(0.5);
        },
        onMouseOut: function () {
            removeAction.setVisible(false);
            removeAction.setScale(0.5);
        }
    });

    return actionRow;
};

ActionsPropertyEditor.prototype.updateDelay = function (action, diff) {
    action.beforeStartDelay = Number(((action.beforeStartDelay || 0) + diff).toFixed(2));
    this.onChangeValueCallback(this.property.name, this.value);
    cleverapps.wysiwyg.inspector.showItemProperties();
};

cleverapps.styles.ActionsPropertyEditor = {
    timestamp: {
        width: 54,
        height: 24
    },

    margin: 10,

    actionName: {
        width: 260
    }
};