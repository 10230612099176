/**
 * Created by Andrey Popov on 9/23/24.
 */

var FallSaleIcon = cc.Sprite.extend({
    ctor: function (position) {
        this._super(bundles.main.frames.fallsale_badge);
        this.setLocalZOrder(3);

        this.setPositionRound(cleverapps.styles.FallSaleIcon.positions[position || FallSaleIcon.POSITION_BUTTON]);
    }
});

var FallSaleTitleIcon = cc.Scale9Sprite.extend({
    ctor: function () {
        this._super(bundles.main.frames.fallsale_title, cleverapps.UI.getScale9Rect(bundles.main.frames.fallsale_title, cleverapps.UI.Scale9Rect.TwoPixelXY));

        this.setAnchorPoint(0.5, 0.5);

        var styles = cleverapps.styles.FallSaleIcon.positions.title;

        var text = cleverapps.UI.generateOnlyText("FallSaleWindow.shoptitle", cleverapps.styles.FONTS.FALL_SALE_BIG_TITLE);
        text.fitTo(styles.description.width, styles.description.height);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        text.setPositionRound(styles.description);
        this.addChild(text);

        this.setLocalZOrder(-1);

        this.setPositionRound(styles);
        this.setContentSize2(styles);

        this.setCascadeOpacityEnabledRecursively(true);
    }
});

FallSaleIcon.POSITION_SIDEBAR = "sidebar";
FallSaleIcon.POSITION_BUTTON = "button";
FallSaleIcon.POSITION_MENUBAR = "menubar";
FallSaleIcon.POSITION_TILE = "title";

cleverapps.overrideStyles(cleverapps.styles.DECORATORS, {
    FALL_SALE_BIG_TITLE_SHADOW: {
        color: new cc.Color(255, 188, 95),
        direction: cc.size(2, -3)
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    FALL_SALE_BIG_TITLE: {
        size: 44,
        color: new cc.Color(137, 64, 45, 255),
        shadow: cleverapps.styles.DECORATORS.FALL_SALE_BIG_TITLE_SHADOW
    }
});

cleverapps.styles.FallSaleIcon = {
    positions: {
        sidebar: {
            x: { align: "right", dx: 18 },
            y: { align: "top", dy: 4 }
        },
        menubar: {
            x: { align: "center", dx: 130 },
            y: { align: "bottom", dy: -50 }
        },
        button: {
            x: { align: "center", dx: 116 },
            y: { align: "bottom", dy: -38 }
        },
        title: {
            x: { align: "center" },
            y: { align: "bottom", dy: -100 },

            width: 500,
            height: 123,

            description: {
                height: 80,
                width: 340,

                x: { align: "center" },
                y: { align: "center", dy: -2 }
            }
        }
    }
};