/**
 * Created by Denis Kuzin on 11 june 2021
 */

var AdsContainer = ccui.Layout.extend({
    ctor: function (properties, eventBus) {
        this._super();
        this.properties = properties;

        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize(properties.width, properties.height);

        if (properties.isPreview) {
            setTimeout(function () {
                this.nestedComponents.forEach(function (component) {
                    component.replaceParentSamePlace(this);
                }.bind(this));

                this.setClippingEnabled(true);
            }.bind(this));
        }

        eventBus.on(Wysiwyg.EVENTS.SCENE_STARTED, this.init.bind(this), this);
    },

    init: function () {
        this.nestedComponents.forEach(function (component) {
            component.replaceParentSamePlace(this);
        }.bind(this));

        this.setClippingEnabled(true);
    }
});

AdsContainer.PROPERTIES = [{
    name: "width", type: "number", value: 600
}, {
    name: "height", type: "number", value: 400
}];