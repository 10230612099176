/**
 * Created by iamso on 18.10.18.
 */

var TransporterTileView = cc.Node.extend(Object.assign({}, BaseTileView, {
    ctor: function (tile) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);

        this.initialize(tile);
        this.setAnimation();
        this.setImage();

        this.tile.onSetImage = this.setImage.bind(this);
        this.tile.onAnimate = this.animate.bind(this);
        this.tile.onProcessListener = this.animateProcess.bind(this);
    },

    getEditorSprite: function () {
        var sprite = new cc.Sprite(bundles.editor.frames.transporterTile);

        switch (this.tile.direction) {
            case TransporterTile.UP:
                sprite.setRotation(-90);
                break;
            case TransporterTile.DOWN:
                sprite.setRotation(90);
                break;
            case TransporterTile.LEFT:
                sprite.setRotation(180);
                break;
            case TransporterTile.RIGHT:
                break;
        }

        return sprite;
    },

    getFieldSprite: function () {
        var prevDir = this.tile.getPrevDir();
        var image;
        if (this.tile.direction === prevDir) {
            image = new cc.Sprite(bundles.transporter.frames.transporter_straight_tile);

            var angles = [-90, 0, 90, 180];
            image.setRotation(angles[this.tile.direction]);
            return image;
        }

        var transform = this.getTransform();
        image = new cc.Sprite(bundles.transporter.frames.transporter_corner_tile);
        image.setRotation(transform.angle ? transform.angle : 0);
        image.setScale(transform.scaleX, transform.scaleY);
        return image;
    },

    getTransform: function () {
        var code = TransporterTileView.CODES[this.tile.getPrevDir()] + TransporterTileView.CODES[this.tile.direction];
        return TransporterTileView.TRANSFORMS[code];
    },

    animateProcess: function () {
        if (this.animation && cleverapps.styles.TransporterTileView.animateOnProcess) {
            var animation = this.tile.getPrevDir() === this.tile.direction ? "straight" : "corner";
            this.animation.setAnimation(0, animation, false);
            this.animation.setVisible(true);
            this.image.setVisible(false);
            this.animation.setCompleteListener(function () {
                this.animation.setVisible(false);
                this.image.setVisible(true);
            }.bind(this));

            if (this.tile.getPrevDir() !== this.tile.direction) {
                var transform = this.getTransform();
                this.animation.setRotation(transform.angle ? transform.angle : 0);
                this.animation.setScale(transform.scaleX, transform.scaleY);
            }
            this.animationDir();

            cleverapps.audio.playSound(bundles.transporter.urls.transporter_effect);
        }
    },

    animationDir: function () {
        if (cleverapps.styles.TransporterTileView.animateOnProcess && this.tile.getPrevDir() !== this.tile.direction) {
            return;
        }
        var code = TransporterTileView.CODES[this.tile.direction];
        var angles = {
            "L": 0,
            "R": 180,
            "D": -90,
            "U": 90
        };
        this.animation.setRotation(angles[code]);
    },
    
    setImage: function () {
        var sprite = (cleverapps.environment.isEditorScene()) ? this.getEditorSprite() : this.getFieldSprite();

        sprite.setPosition(this.width / 2, this.height / 2);
        if (this.image !== undefined) {
            this.image.removeFromParent();
        }
        this.image = sprite;
        this.addChild(sprite);
    },

    setAnimation: function () {
        this.animation = new cleverapps.Spine(bundles.transporter.jsons.transporter_json);
        this.animation.setPosition(this.width / 2, this.height / 2);
        this.animation.setLocalZOrder(10);
        this.animationDir();
        this.addChild(this.animation);
    },

    animate: function () {
        if (this.animation && !cleverapps.styles.TransporterTileView.animateOnProcess) {
            this.animation.setAnimation(0, "idle", true);
        }
    }
}));

TransporterTileView.CODES = ["U", "R", "D", "L"];

TransporterTileView.TRANSFORMS = {
    "UR": { scaleX: 1, scaleY: 1 },
    "UL": { scaleX: -1, scaleY: 1 },

    "DR": { scaleX: 1, scaleY: -1 },
    "DL": { scaleX: -1, scaleY: -1 },

    "RU": { scaleX: -1, scaleY: -1 },
    "RD": { scaleX: -1, scaleY: 1 },

    "LU": { scaleX: 1, scaleY: -1 },
    "LD": { scaleX: 1, scaleY: 1 }
};

cleverapps.styles.TransporterTileView = {
    animateOnProcess: false
};
