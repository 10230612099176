/**
 * Created by vladislav on 3/18/19
 */

var CannonCell = function (x, y) {
    BaseCell.call(this, x, y);

    Game.currentGame.counter.registerStage(302, CannonCell.process);

    this.onChangeLivesListener = function () {};
    this.onTurnListener = function () {};
    this.onFireListener = function () {};
};

CannonCell.prototype = Object.create(BaseCell.prototype);
CannonCell.prototype.constructor = CannonCell;

CannonCell.prototype.bundleId = function () {
    return "cannon";
};

CannonCell.prototype.editorComponents = function () {
    return [BaseCellComponent, LiveLineEditorComponent, CannonCellComponent];
};

CannonCell.prototype.load = function (data) {
    this.lives = this.maxLives = parseInt(data[1]);
    this.currDir = parseInt(data[2]);
    this.clockwise = !!parseInt(data[3]);

    var dirData = parseInt(data.splice(4).join(""));
    this.dirs = [];

    for (var i = 0; i < Object.keys(BaseCell.DIRECTIONS).length; i++) {
        if (dirData & (1 << i)) {
            this.dirs.push(i);
        }
    }
};

CannonCell.prototype.save = function () {
    var data = CannonCell.CODES.concat([this.lives, this.currDir, this.clockwise ? 1 : 0]);

    var dirData = 0;
    for (var i = 0; i < this.dirs.length; i++) {
        dirData |= (1 << this.dirs[i]);
    }
    return data.concat(dirData.toString().split(""));
};

CannonCell.prototype.getViewClass = function () {
    return CannonCellView;
};

CannonCell.prototype.boom = function (coef, cell) {
    this.hurt(undefined, cell);
};

CannonCell.prototype.hurt = function () {
    if (this.lives < 1 || !this.alive) {
        return;
    }

    this.lives--;
    this.onChangeLivesListener();

    Game.currentGame.counter.setTimeout(function () {
        if (this.lives === 0) {
            this.fire();
        }
    }.bind(this), this.hurtDuration() * 1000);
};

CannonCell.prototype.fire = function () {
    if (this.hasFired) {
        return;
    }

    var cellsToDestroy = [];
    this.iterateLineOfFire(
        this.x + BaseCell.DIRECTIONS[this.currDir].x,
        this.y + BaseCell.DIRECTIONS[this.currDir].y,
        BaseCell.DIRECTIONS[this.currDir],
        function (cell) {
            cellsToDestroy.push(cell);
        },
        true
    );
    cellsToDestroy = cellsToDestroy
        .slice(0, cellsToDestroy.map(function (obj) {
            return obj.constructor === BaseCell && !obj.components.length;
        }) // empty cell is basecell
            .lastIndexOf(false) + 1);

    this.hasFired = true;
    
    cellsToDestroy.forEach(function (cell, index) {
        Game.currentGame.counter.setTimeout(function () {
            if (cell && cell.alive && cell.hurtable) {
                cell.hurt({ id: index, groupSize: 1 });
            }
            RugTile.hurtViaCombo(this, cell);
        }.bind(this), index * CannonCell.timeFlyOneCellSec * 1000);
    }.bind(this));

    Game.currentGame.counter.setTimeout(function () {
    }, (cellsToDestroy.length * CannonCell.timeFlyOneCellSec + this.fireDuration()) * 1000);

    this.onFireListener(cellsToDestroy.length);
};

CannonCell.prototype.fireDuration = function () {
    return 0.5;
};

CannonCell.prototype.hurtDuration = function () {
    return 0.5;
};

CannonCell.prototype.chooseDirection = function () {
    var initialDir = this.currDir; // save to restore later before return
    do {
        var newDir = this.getNextDir(this.clockwise);
        if (this.isViableDirection(newDir)) {
            this.currDir = initialDir;
            return newDir;
        }
        this.currDir = newDir;
    } while (newDir !== initialDir);
    
    this.currDir = initialDir;
    return initialDir; // can't rotate
};

CannonCell.prototype.turn = function () {
    var newDir = this.chooseDirection();
    if (newDir !== undefined) {
        this.currDir = newDir;
        this.onTurnListener();

        Game.currentGame.counter.setTimeout(function () {
        }, 500);
    }

    this.lives = this.maxLives;
    this.onChangeLivesListener();
};

CannonCell.prototype.getNextDir = function (clockwise) {
    var len = this.dirs.length;
    var index = this.dirs.indexOf(this.currDir);
    return this.dirs[(index + (clockwise ? 1 : -1) + len) % len];
};

CannonCell.prototype.isViableDirection = function (dir) {
    var isViable = false;
    dir = BaseCell.DIRECTIONS[dir];
    this.iterateLineOfFire(this.x + dir.x, this.y + dir.y, dir, function (cell) {
        if (cell && cell.alive && cell.hurtable) {
            isViable = true;
        }
    });
    return isViable;
};

CannonCell.prototype.process = function () {
    if (!this.hasFired) {
        return;
    }

    this.hasFired = false;

    this.turn();
};

CannonCell.process = function () {
    if (!Game.currentGame.cannonCellCanProcess) {
        return;
    }

    Game.currentGame.cannonCellCanProcess = false;

    for (var x = 0; x < Field.SIZE; x++) {
        for (var y = 0; y < Field.SIZE; y++) {
            var cell = Game.currentGame.field.cells[y][x];
            if (cell && cell instanceof CannonCell) {
                cell.process();
            }
        }
    }
};

CannonCell.prototype.getMaxLives = function () {
    return 9;
};

CannonCell.timeFlyOneCellSec = 0.2;