/**
 * Created by ivan on 16.04.18.
 */

var BlastAnimation = BaseAnimation.extend({
    ctor: function (skin) {
        this._super(bundles.burst.jsons.burst_blast_json);
        if (this.animation) {
            this.animation.setSkin(skin);
        }
    }
});

BlastAnimation.factory = function (skin) {
    return cc.pool.hasObject(BlastAnimations[skin]) ? cc.pool.getFromPool(BlastAnimations[skin]) : new BlastAnimations[skin]();
};

var BlastAnimations = {
    purple: BlastAnimation.extend({
        ctor: function () {
            this._super("purple"); 
        } 
    }),
    yellow: BlastAnimation.extend({
        ctor: function () {
            this._super("yellow"); 
        } 
    }),
    red: BlastAnimation.extend({
        ctor: function () {
            this._super("red"); 
        } 
    }),
    green: BlastAnimation.extend({
        ctor: function () {
            this._super("green"); 
        } 
    }),
    blue: BlastAnimation.extend({
        ctor: function () {
            this._super("blue"); 
        } 
    })
};