/**
 * Created by andrey on 21.08.17.
 */

var IceBlockAnimation = BaseAnimation.extend({
    ctor: function () {
        this._super(bundles.ice_block.jsons.ice_block_spine_json, bundles.ice_block.frames.ice_block_1_stable);
    }
});

IceBlockAnimation.factory = function () {
    return cc.pool.hasObject(IceBlockAnimation) ? cc.pool.getFromPool(IceBlockAnimation) : new IceBlockAnimation();
};