/**
 * Created by vladislav on 21.07.2021
 */

cc.spriteFrameCache = {
    getSpriteFrame: function (name) {
        var message = "spriteFrameCache use - " + name;
        if (cleverapps.config.debugMode) {
            throw new Error(message);
        } else {
            cleverapps.throwAsync(message);
        }
    }
};