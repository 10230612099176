/**
 * Created by vladislav on 1/18/2024
 */

var CoolMathGamesLogic = function () {
    PlatformLogic.call(this);

    cleverapps.Availables.LEADER_BOARD = { debugMode: true };
    delete cleverapps.config.leagues;

    ["scramble", "crocword", "riddles", "heroes", "adventure", "runes"].forEach(function (project) {
        CupsConfig.TYPES[CupsConfig.TYPE_DAILY].available.projectName.splice(CupsConfig.TYPES[CupsConfig.TYPE_DAILY].available.projectName.indexOf(project), 1);
    });
};

CoolMathGamesLogic.prototype = Object.create(PlatformLogic.prototype);
CoolMathGamesLogic.prototype.constructor = CoolMathGamesLogic;