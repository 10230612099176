/**
 * Created by andrey on 29.05.17.
 */
 
var CellsBlock = ElementsChooser.extend({
    ctor: function () {
        var table = {};
        table[" "] = new cc.Sprite(bundles.editor.frames.empty);
        this._super({
            elements: EditorView.Cells,
            type: "cell",
            table: table
        });
    }
});

EditorView.Cells = [EditorRandomCell, MultiColorCell, BombCell, IceCreamMakerCell, DonutBoxCell, FireworkCell, BurstCell, ColorBombCell,
    MultiColorBombCell, SharkCell, MovesCell, SmallPetardCell, CannonCell, WallCell];
EditorView.RiddlesCells = [];

if (cleverapps.config.name === "riddles") {
    EditorView.Cells = EditorView.Cells.concat(EditorView.RiddlesCells);
}

BombCell.EDITOR_CODES = ["Zx.5", "Zxa5", "Zxb5", "Zxc5", "Zxd5", "Zxe5", "Zxw5"];
IceCreamMakerCell.EDITOR_CODES = ["i1", "i2", "i3", "i4"];
DonutBoxCell.EDITOR_CODES = ["p1", "p2", "p3", "p4"];
FireworkCell.EDITOR_CODES = ["Rx.", "Rxa", "Rxb", "Rxc", "Rxd", "Rxe", "Rxw"];
BurstCell.EDITOR_CODES = ["r1", "r2", "r3", "r4"];
ColorBombCell.EDITOR_CODES = ["t3.x", "t3ax", "t3bx", "t3cx", "t3dx", "t3ex", "t3wx"];
MultiColorBombCell.EDITOR_CODES = ["labc"];
MovesCell.EDITOR_CODES = ["n1"];
SmallPetardCell.EDITOR_CODES = ["s3.", "s3a", "s3b", "s3c", "s3d", "s3e", "s3w"];
CannonCell.EDITOR_CODES = ["m3011"];
