/**
 * Created by andrey on 16.03.18.
 */

var MuffinGoalCellView = BaseCellView.extend({
    ctor: function (muffinGoalCell) {
        this._super();

        this.setContentSize(cleverapps.styles.BaseCellView.CELL_SIZE_PX, cleverapps.styles.BaseCellView.CELL_SIZE_PX);
        this.setAnchorPoint(0.5, 0.5);

        this.initialize(muffinGoalCell);

        if (this.cell.isForGoal) {
            this.createGoalImage();
        }
    },

    getIdleAnimationName: function () {
        return "muffin_goal_" + this.cell.lives;
    },

    getTransitionAnimationName: function () {
        return "muffin_goal_" + this.cell.lives + "_transition";
    },

    getSmileAnimationName: function () {
        return "muffin_goal_" + this.cell.lives + "_smile";
    },

    createGoalImage: function () {
        if (this.goalImage) {
            return;
        }
        this.goalImage = new cc.Sprite(bundles.muffin.frames.muffin_cherry);
        this.addChild(this.goalImage);
        this.goalImage.setPositionRound(this.width / 2, this.height / 2);
    },
  
    startExploding: function (goal, delta) {
        this.finishSmile();

        var callback = function () {
            if (!Game.currentGame) {
                return;
            }

            var muffinComponent = this.cell.findComponent(MuffinComponent);
            muffinComponent.view.hide();

            this.createGoalImage();
            this.goalImage.setVisible(true);

            this.startExplodingViaCollect(goal, delta);
        }.bind(this);

        this.setLocalZOrder(3);
        this.stopAllActions();
        this.runAction(new cc.Sequence(
            new cc.ScaleTo(0.3, 1.5),
            new cc.CallFunc(function () {
                this.boomAnimation(0.5);
                this.runAction(new cc.MoveBy(0.3, 0, this.getStyles().explode.dy));
                callback();
            }.bind(this))
        ));
    },

    getStyles: function () {
        return cleverapps.styles.MuffinGoalCellView;
    },

    finishSmile: function () {
        var muffinComponent = this.cell.findComponent(MuffinComponent);
        muffinComponent.view.finishSmile();
    },

    smile: function () {
        var muffinComponent = this.cell.findComponent(MuffinComponent);
        muffinComponent.view.smile();
    }
});

cleverapps.styles.MuffinGoalCellView = {
    explode: {
        dy: 34
    }
};