/**
 * Created by Denis "dalorveen" Kuzin on 18 october 2020
 */

var AdminLanguageView = cc.Sprite.extend({
    ctor: function (adminLanguage, options) {
        this.adminLanguage = adminLanguage;
        this._super(bundles.admin.frames["admin_" + adminLanguage.language]);

        var frame = cleverapps.UI.createScale9Sprite(bundles.admin.frames.selected_button);
        frame.setContentSize(this.getContentSize());
        this.addChild(frame, -1);
        frame.setPositionRound(this.width / 2, this.height / 2);

        if (options.width && this.width > options.width) {
            cleverapps.UI.fitToBox(this, { width: options.width, height: this.height });
        }

        this.baseScale = this.scale;

        this.createIndicator();

        adminLanguage.on("select", this.select.bind(this), this);
        adminLanguage.on("unselect", this.unselect.bind(this), this);

        cleverapps.UI.onClick(this, adminLanguage.onClick.bind(adminLanguage));
        cleverapps.UI.applyHover(this);

        this.setOpacity(adminLanguage.selected ? 255 : AdminLanguageView.OPACITY);
    },

    createIndicator: function () {
        var indicator = this.indicator = new cc.Sprite(bundles.admin.frames.in_process);
        indicator.setVisible(this.adminLanguage.inProgress());
        indicator.setPositionRound(cleverapps.styles.AdminLanguageView.indicator);
        this.addChild(indicator);
    },

    select: function () {
        this.setOpacity(255);
    },

    unselect: function () {
        this.setOpacity(AdminLanguageView.OPACITY);
    }
});

cleverapps.styles.AdminLanguageView = {
    indicator: {
        x: { align: "right", dx: -5 },
        y: { align: "center", dy: 0 }
    }
};

AdminLanguageView.OPACITY = 40;