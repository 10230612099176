/**
 * Created by Denis Kuzin on 06 june 2024
 */

var EaterComponent = function (cell, cellClass, rows, cols) {
    this.cell = cell;

    var bigComponent = this.bigComponent = cell.addComponent(BigComponent, { cellClass: cellClass, rows: rows, cols: cols });

    if (this.cell.isForGoal) {
        return;
    }

    this.cell.lives = 10;

    if (bigComponent.isHead) {
        if (!Game.currentGame.EATERS) {
            Game.currentGame.EATERS = [];
        }
        Game.currentGame.EATERS.push(this.cell);
    }

    EaterComponent.COLOR_EATERS = {};
};

EaterComponent.prototype.setDepth = function () {
    if (this.cell.depth > 0) { // remove this eater from the list (was added to the list on load, even before appear on the field)
        if (!Game.currentGame.EATERS) {
            Game.currentGame.EATERS = [];
        }
        Game.currentGame.EATERS = Game.currentGame.EATERS.filter(function (element) {
            return !(element.findComponent(EaterComponent) && (element.constructor === this.cell.constructor)
                && (element.x === this.cell.x) && (element.y === this.cell.y)); // could not be 2 eater cells in the same place except under blanket,
            // so should not filter EATERS separately for each class
        }.bind(this));
    } else { // add this eater to the list
        if (!Game.currentGame.EATERS) {
            Game.currentGame.EATERS = [];
        }
        if (this.bigComponent.isHead) {
            Game.currentGame.EATERS.push(this.cell);
        }
    }
};

EaterComponent.prototype.checkHeadFlag = function () {
    var bigComponent = this.cell.findComponent(BigComponent);
    bigComponent.checkHeadFlag();

    if (!this.bigComponent.isHead && Game.currentGame.EATERS) { // blanket case, 4 eaters added for 2x2 eater cell under blanket because on Load isHead === true for each part of 2x2 eater
        Game.currentGame.EATERS = Game.currentGame.EATERS.filter(function (element) {
            return !(element.findComponent(EaterComponent) && (element.x === this.cell.x) && (element.y === this.cell.y)); // could not be 2 eater cells in the same place except under blanket,
            // so should not filter EATERS separately for each class
        }.bind(this));
    }
};

EaterComponent.prototype.editorComponents = function () {
    if (this.bigComponent.isHead) {
        return [BaseCellComponent, BigCellComponent, LiveLineEditorComponent];
    }
};

EaterComponent.find = function (params, color) {
    if (!Game.currentGame || !Game.currentGame.EATERS) {
        return;
    }

    var eaters = Game.currentGame.EATERS.filter(function (eater) {
        return eater.getAcceptedColors().indexOf(color) !== -1;
    });

    if (eaters.length === 0) {
        return;
    }

    var id = params ? params.id : 0;
    if (id === 0 || EaterComponent.COLOR_EATERS[color] === undefined) {
        if (EaterComponent.COLOR_EATERS[color] === undefined) {
            EaterComponent.COLOR_EATERS[color] = 0;
        } else {
            EaterComponent.COLOR_EATERS[color] = (EaterComponent.COLOR_EATERS[color] + 1) % eaters.length;
        }
    }

    return eaters[EaterComponent.COLOR_EATERS[color]];
};

EaterComponent.prototype.getFlyDuration = function (object) {
    var ver = object.y - (this.cell.y + 0.5);
    var hor = object.x - (this.cell.x + 0.5);
    var flyDuration = Math.sqrt(ver * ver + hor * hor + 2) / 8;
    return flyDuration;
};

EaterComponent.prototype.check = function (object, idInGroup, groupSize) {
    var flyDuration = this.getFlyDuration(object);
    var beginDuration = Goals.UP_DURATION + Goals.WAIT_DURATION;
    var d = Goals.DEFAULT_NEXT_CELL_DURATION;
    if (d * groupSize > Goals.MAX_NEXT_CELL_DURATION) {
        d = Goals.MAX_NEXT_CELL_DURATION / groupSize;
    }
    d *= idInGroup;
    beginDuration += d;
    var fullDuration = flyDuration + beginDuration;

    Game.currentGame.counter.setTimeout(function () {
        if (!this.bigComponent.isHead) {
            this.realHurt(this.bigComponent.head);
        } else {
            this.realHurt(this.cell);
        }
    }.bind(this), fullDuration * 1000);

    return {
        duration: fullDuration,
        waitDuration: beginDuration - Goals.UP_DURATION
    };
};

EaterComponent.prototype.realHurt = function (cell) {
    if (cell.lives > 0) {
        cell.hurtable = true;
        cell.hurt();
        if (cell.lives === 0) {
            for (var i = 0; i < Game.currentGame.EATERS.length; i++) {
                if (Game.currentGame.EATERS[i] === cell) {
                    Game.currentGame.EATERS.splice(i, 1);
                    break;
                }
            }
        }
        cell.hurtable = false;
    }
};

EaterComponent.prototype.hurtDuration = function () {
    return 0.4;
};