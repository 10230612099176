/**
 * Created by Denis Kuzin on 16 october 2023
 */

var AdminLevelTag = cc.Node.extend({
    ctor: function (adminLevel, maxi) {
        this._super();

        this.setContentSize(cleverapps.styles.AdminLevelTag);
        this.setAnchorPoint(0.5, 0.5);

        if (!maxi) {
            this.setScale(0.6);
        }

        this.adminLevel = adminLevel;

        this.updateTag();

        adminLevel.on("change:tag", this.updateTag.bind(this), this);
    },

    updateTag: function () {
        if (this.tagBg) {
            this.tagBg.removeFromParent();
            this.tagBg = undefined;
        }

        if (this.adminLevel.dragging) {
            return;
        }

        var tag = this.adminLevel.getTag();
        if (tag) {
            var tagBg = this.tagBg = cleverapps.UI.createScale9Sprite(bundles.admin.frames["button_tag_" + tag], cleverapps.UI.Scale9Rect.TwoPixelXY);
            tagBg.setContentSize(this.getContentSize());

            var text = cleverapps.UI.generateOnlyText(tag, cleverapps.styles.FONTS.ADMIN_LEVEL_TAG_TEXT);
            text.setPositionRound(tagBg.width / 2, tagBg.height / 2 + cleverapps.styles.AdminLevelTag.textOffsetY);
            text.fitTo(tagBg.width, tagBg.height);
            tagBg.addChild(text);

            this.addChild(tagBg);
            tagBg.setPositionRound(this.width / 2, this.height / 2);
        }
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    ADMIN_LEVEL_TAG_TEXT: {
        size: 35,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    }
});

cleverapps.styles.AdminLevelTag = {
    width: 125,
    height: 45,
    textOffsetY: 1
};