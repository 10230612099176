/**
 * Created by slava on 02.02.17.
 */

var DragonCellView = BaseCellView.extend({
    ctor: function (dragonCell) {
        this._super();

        var bigComponent = dragonCell.findComponent(BigComponent);
        if (!bigComponent.isHead) {
            return;
        }

        var styles = this.getStyles();
        this.setContentSize2(styles.width, styles.height);
        this.setAnchorPoint(0.5, 0.5);

        this.cellWithoutLive = new cc.Node();
        this.addChild(this.cellWithoutLive);
        this.cellWithoutLive.setContentSize2(styles.width, styles.height);

        this.animation = new cleverapps.Spine(bundles.dragon.jsons.dragon_spine_json);
        this.cellWithoutLive.addChild(this.animation);
        this.lastSmileTime = 0;

        if (!dragonCell.isForGoal) {
            this.image = new cc.Sprite(bundles.dragon.frames.dragon_stable_png);
            this.cellWithoutLive.addChild(this.image);
            this.image.setPosition(this.width / 2, this.height / 2);

            this.animation.setPosition(this.width / 2, this.height / 2);
            this.goalType = false;
        } else {
            this.setContentSize(styles.goal.width, styles.goal.height);
            this.cellWithoutLive.setContentSize2(styles.goal.width, styles.goal.height);

            this.image = new cc.Sprite(bundles.dragon.frames.dragon_stable_goal_png);
            this.image.setScale(styles.goal.scale);
            this.cellWithoutLive.addChild(this.image);
            this.image.setPosition(this.width / 2, this.height / 2);

            this.animation.setPosition(this.width / 2, this.height / 2);
            this.animation.setScale(styles.goal.scale);
            this.goalType = true;
        }

        this.animation.setStartVisibleListener(function () {
            this.image.visible = false;
        }.bind(this));

        this.cellWithoutLive.setAnchorPoint(0.5, 0.5);
        this.cellWithoutLive.setPositionRound(this.width / 2, this.height / 2);

        this.initialize(dragonCell);
        this.setPosition(BaseCellView.alignInTheGrid(dragonCell.x + 0.5, dragonCell.y + 0.5));
    },

    getStyles: function () {
        return cleverapps.styles.DragonCellView;
    },

    finishAnimating: function () {
        if (this.animating) {
            this.animating = false;
            this.image.visible = true;
            this.animation.visible = false;
        }
    },

    finishSmile: function () {
        if (cleverapps.styles.DragonCellView.smile.active) {
            this.finishAnimating();
        }
    },

    smile: function () {
        if (this.animating) {
            return;
        }
        if (this.lastSmileTime > Date.now() - 5000) {
            return;
        }
        if (!cleverapps.styles.DragonCellView.smile.active) {
            return;
        }

        this.lastSmileTime = Date.now();
        this.animating = true;
        if (this.goalType) {
            this.animation.setAnimation(0, "smile_0", false);
        } else {
            this.animation.setAnimation(0, "smile_1", false);
        }
        this.animation.setCompleteListener(this.finishSmile.bind(this));
    },

    initialize: function (dragonCell) {
        this._super(dragonCell);

        dragonCell.onOpenAnimation = this.openAnimation.bind(this);
    },

    unuse: function () {
        this.cell.onOpenAnimation = function () {};

        this._super();
    },

    hurtAnimation: function () {
        if (this.cell.lives > 0) {
            this.animating = true;
            this.animation.setAnimation(0, "hit", false);
            this.animation.setCompleteListener(this.finishAnimating.bind(this));

            cleverapps.audio.playSound(bundles.dragon.urls.dragon_hit_effect || bundles.game.urls.nut_effect);
        }
    },

    openAnimation: function () {
        this.animating = true;
        this.animation.setAnimation(0, "open", false);
        this.animation.setCompleteListener(function () {
            if (cleverapps.styles.DragonCellView.flyToGoalAnimation.active) {
                this.animation.setAnimation(0, "fly_to_goal", true);
            } else {
                this.finishAnimating();
            }
        }.bind(this));
        this.image.setSpriteFrame(bundles.dragon.frames.dragon_stable_goal_png);
        this.goalType = true;

        cleverapps.audio.playSound(bundles.dragon.urls.dragon_collect_effect || bundles.game.urls.nut_effect);
    }
});

cleverapps.styles.DragonCellView = {
    width: 240,
    height: 240,

    lives: {
        scale: 1.3,

        circleGap: 4
    },

    lifeBg: {
        dy: 30,
        mobileDy: 40
    },
    
    circle: {
        scale: 1.12,
        mobileScale: 1.5
    },

    goal: {
        width: 120,
        height: 120,
        scale: 0.7
    },

    hover: {
        y: 10
    },

    smile: {
        active: true
    },

    flyToGoalAnimation: {
        active: false
    },

    bar: {
        width: 110,
        height: 10
    }
};