/**
 * Created by Andrey Popov on 21.04.2023
 */

var UpdateScoreAction = function (f) {
    if (cleverapps.instantTournament.isAvailable()) {
        cleverapps.instantTournament.reportScore();
    } else {
        cleverapps.scoreTracker.report();
    }

    f();
};