/**
 * Created by Vladislav on 22.05.2024.
 */

var UserPermissions = function () {
    this.queue = [];
    this.busy = false;
};

UserPermissions.prototype.queuePermission = function (callback) {
    this.queue.push(callback);

    this.process();
};

UserPermissions.prototype.process = function () {
    if (this.busy) {
        return;
    }

    var callback = this.queue.shift();
    if (callback) {
        this.busy = true;

        callback(function () {
            this.busy = false;

            this.process();
        }.bind(this));
    }
};