/**
 * Created by iamso on 02.11.2020.
 */

var AdminLevelsChart = cc.Sprite.extend({
    ctor: function (data, wrapper) {
        this.load();
        this.prepareData(data);

        this.wrapper = wrapper;
        this._super(this.createChart());
        this.chart.clear();

        if (this.points.length) {
            cleverapps.administrator.adminLevels.on("chartUpdate", function () {
                if (cleverapps.administrator.adminLevels.ready) {
                    this.setCurrentLvl();
                }
            }.bind(this), this);
            this.setControls();
        }

        this.addCleaner(this.removeChart.bind(this));
    },

    prepareData: function (data) {
        var maxLevel = 0;
        var existingLevels = {};
        data.forEach(function (row) {
            existingLevels[row.levelNo] = true;
            if (row.levelNo > maxLevel) {
                maxLevel = row.levelNo;
            }
        });

        for (var i = 0; i < maxLevel; i++) {
            if (!existingLevels[i]) {
                data.push({
                    level: i,
                    difficulty: 0,
                    hints: 0
                });
            }
        }

        data.sort(function (rowA, rowB) {
            return rowA.levelNo - rowB.levelNo;
        });

        this.labels = data.map(function (row) {
            return row.levelNo;
        });

        this.prepareDataSets(data);
    },

    prepareDataSets: function (data) {
        this.dataSets = {};

        this.dataSets.difficulty = {
            label: "Difficulty",
            fromZero: true,
            data: data.map(function (row) {
                return row.difficulty;
            })
        };

        this.dataSets.hints = {
            label: "Hints",
            data: data.map(function (row) {
                return row.hints || 0;
            })
        };

        if (["board", "solitaire"].indexOf(cleverapps.config.type) !== -1) {
            this.dataSets.minutes = {
                label: "Minutes",
                fromZero: true,
                data: data.map(function (row) {
                    return row.minutes || 0;
                })
            };
        }

        Object.keys(this.dataSets).forEach(function (name) {
            this.dataSets[name].active = this.activeSets.indexOf(name) !== -1;
        }, this);
    },

    removeChart: function () {
        if (this.chart) {
            this.chart.destroy();
            delete this.chart;
        }

        var element = document.getElementById("adminlevelschart");
        if (element) {
            element.parentNode.removeChild(element);
        }
    },

    createChart: function () {
        this.removeChart();

        var canvas = document.getElementById("adminlevelschart");
        if (!canvas) {
            canvas = document.createElement("canvas");
            document.body.appendChild(canvas);
        }

        canvas.id = "adminlevelschart";
        canvas.height = this.wrapper.height;
        canvas.width = this.wrapper.width;

        this.chart = new Chart(canvas.getContext("2d"), {
            type: "line",
            data: {
                labels: this.labels,
                datasets: this.getDataSets()
            },
            options: {
                scales: {
                    yAxes: this.getYAxes()
                },
                responsive: false,
                animation: {
                    duration: 0
                },
                hover: {
                    animationDuration: 0
                },
                responsiveAnimationDuration: 0,
                tooltips: {
                    position: "custom",
                    displayColors: false,
                    callbacks: this.getToolTipCallbacks(),
                    bodyFontStyle: "bold"
                },
                annotation: this.getAnnotations()
            }
        });

        var dpr = this.chart.currentDevicePixelRatio;
        this.points = this.chart.getDatasetMeta(0).data.map(function (point) {
            var pos = point.getCenterPoint();
            return cc.p(pos.x * dpr, pos.y * dpr);
        });

        this.legentBoxes = this.chart.legend.legendHitBoxes.map(function (box) {
            return cc.rect(box.left * dpr, (this.chart.height - box.top - box.height) * dpr, box.width * dpr, box.height * dpr);
        }, this);

        return canvas;
    },

    setCurrentLvl: function () {
        this.setSpriteFrame(new cc.Sprite(this.createChart()).getSpriteFrame());
        this.chart.clear();
        this.save();
    },

    getAnnotations: function () {
        var config = AdminConfig.getConfig("difficulty");
        if (!config || this.activeSets.indexOf("difficulty") === -1) {
            return;
        }

        return {
            annotations: [
                {
                    type: "line",
                    mode: "horizontal",
                    scaleID: "y-axis-0",
                    value: config.top,
                    borderColor: "tomato",
                    borderWidth: 1
                },
                {
                    type: "line",
                    mode: "horizontal",
                    scaleID: "y-axis-0",
                    value: config.bottom,
                    borderColor: "tomato",
                    borderWidth: 1
                }
            ]
        };
    },

    getDataSets: function () {
        var radius = [];
        this.labels.forEach(function (lvlNo) {
            radius.push(cleverapps.administrator.adminLevels.active && lvlNo === cleverapps.administrator.adminLevels.active.levelNo ? 10 : 3);
        });

        var createSet = function (data, ind) {
            return {
                label: data.label,
                fill: false,
                hidden: !data.active,
                backgroundColor: this.getColor(ind),
                borderColor: this.getColor(ind),
                data: data.data,
                yAxisID: "y-axis-" + ind,
                radius: radius
            };
        }.bind(this);

        var sets = [], ind = 0;
        for (var key in this.dataSets) {
            sets.push(createSet(this.dataSets[key], ind++));
        }

        return sets;
    },

    getYAxes: function () {
        var drawGrid = true, isLeft = true;

        var createAxis = function (ind, dataSet) {
            var options = {
                display: dataSet.active,
                position: isLeft ? "left" : "right",
                id: "y-axis-" + ind,
                gridLines: {
                    drawOnChartArea: drawGrid
                },
                ticks: {
                    beginAtZero: dataSet.fromZero
                }
            };

            if (dataSet.active) {
                drawGrid = false;
                isLeft = !isLeft;
            }
            return options;
        };

        var axes = [], ind = 0;
        for (var key in this.dataSets) {
            axes.push(createAxis(ind++, this.dataSets[key]));
        }

        return axes;
    },

    getColor: function (ind) {
        var colors = [
            "rgb(255, 99, 132)",
            "rgb(54, 162, 235)",
            "rgb(125, 32, 223)",
            "rgb(249, 228, 6)",
            "rgb(130, 223, 32)"
        ];
        return colors[ind % colors.length];
    },

    getToolTipCallbacks: function () {
        var createCallback = function (ind) {
            return function (tooltipItem, data) {
                var lvl = Array.isArray(tooltipItem) ? tooltipItem[0].xLabel : tooltipItem.xLabel;
                return data.datasets[ind].label + ": " + data.datasets[ind].data[lvl];
            };
        };

        var possibleCallbacks = ["beforeBody", "beforeLabel", "afterLabel", "afterBody",
            "beforeFooter", "footer", "afterFooter"];

        var callbacks = {}, cnt = 0;
        Object.values(this.dataSets).forEach(function (dataSet, ind) {
            var callbackName = possibleCallbacks[cnt];
            if (dataSet.active && callbackName) {
                callbacks[callbackName] = createCallback(ind);
                cnt++;
            }
        });

        callbacks.label = function () {};
        return callbacks;
    },

    toggleActiveData: function (ind) {
        var set = Object.values(this.dataSets)[ind];
        set.active = !set.active;

        this.activeSets = [];
        var cnt = 0;
        for (var name in this.dataSets) {
            if (ind === cnt++) {
                this.dataSets[name].active = !this.dataSets[name].active;
            }

            if (this.dataSets[name].active) {
                this.activeSets.push(name);
            }
        }

        this.save();
        this.setCurrentLvl();
    },

    setControls: function () {
        var findClosest = function (pos) {
            var lvlNo = 0;
            var closest = 9999;

            for (var i = 0; i < this.points.length; i++) {
                var dx = Math.abs(this.points[i].x - pos.x);
                if (dx < closest) {
                    closest = dx;
                    lvlNo = i;
                }
            }
            return lvlNo;
        }.bind(this);

        var isInArea = function (pos) {
            var area = this.chart.chartArea;
            var dpr = this.chart.currentDevicePixelRatio;
            return cc.rectContainsPoint(cc.rect(area.left * dpr, 0, area.right * dpr, area.bottom * dpr), pos);
        }.bind(this);

        var checkLegendItems = function (pos) {
            for (var ind in this.legentBoxes) {
                if (cc.rectContainsPoint(this.legentBoxes[ind], pos)) {
                    this.toggleActiveData(ind);
                    return true;
                }
            }
        }.bind(this);

        cleverapps.UI.onPressed(this, function (touch) {
            var pos = this.convertTouchToNodeSpace(touch);
            if (isInArea(pos)) {
                var lvl = cleverapps.administrator.adminLevels.levels[findClosest(pos)];
                if (lvl && !lvl.isAddMoreLevel()) {
                    cleverapps.administrator.adminLevels.setActive(lvl);
                }
                return true;
            }

            return checkLegendItems(pos);
        }.bind(this), {
            interactiveScale: false
        });
    },

    save: function () {
        cleverapps.dataLoader.save(DataLoader.TYPES.ADMIN_CHART, this.activeSets);
    },

    load: function () {
        this.activeSets = cleverapps.dataLoader.load(DataLoader.TYPES.ADMIN_CHART) || ["difficulty", "hints"];
    }
});

AdminLevelsChart.PrepareChart = function () {
    Chart.plugins.register({
        beforeDraw: function (chartInstance) {
            var ctx = chartInstance.chart.ctx;
            ctx.fillStyle = "white";
            ctx.fillRect(0, 0, chartInstance.chart.width, chartInstance.chart.height);
        }
    });

    Chart.pluginService.register({
        beforeRender: function (chart) {
            if (!chart.getDatasetMeta(0).data.length || !cleverapps.administrator.adminLevels.active) {
                return;
            }
            var data = chart.getDatasetMeta(0).data[cleverapps.administrator.adminLevels.active.levelNo];
            if (!data) {
                return;
            }

            chart.pluginTooltip = new Chart.Tooltip({
                _chart: chart.chart,
                _chartInstance: chart,
                _data: chart.data,
                _options: chart.options.tooltips,
                _active: [data]
            });
            chart.options.tooltips.enabled = false;
        },
        afterDraw: function (chart) {
            if (!chart.pluginTooltip) {
                return;
            }
            chart.options.tooltips.enabled = true;
            chart.pluginTooltip.initialize();
            chart.pluginTooltip.update();
            chart.pluginTooltip.pivot();
            chart.pluginTooltip.draw();
            chart.options.tooltips.enabled = false;
        }
    });

    Chart.Tooltip.positioners.custom = function (elements) {
        if (!elements.length || !elements[0] || !cleverapps.administrator.adminLevels.active) {
            return false;
        }

        var points = [];
        for (var i = 0; i < elements[0]._chart.data.datasets.length; i++) {
            var pos = elements[0]._chart.getDatasetMeta(i).data[cleverapps.administrator.adminLevels.active.levelNo].getCenterPoint();
            if (!elements[0]._chart.data.datasets[i].hidden) {
                points.push(cc.p(pos.x, pos.y));
            }
        }

        if (points.length) {
            points.sort(function (a, b) {
                return a.y > b.y ? 1 : -1;
            });
            return points[0];
        }

        return { x: -1000, y: 0 };
    };
};