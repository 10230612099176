/**
 * Created by Ivan on 16.04.2024
 */

var StickersCollection = function (options) {
    cleverapps.EventEmitter.call(this);

    this.isPrizeCollected = options.isPrizeCollected;
    this.isCompleted = false;

    this.index = options.index;

    this.createStickers(options);

    this.title = "Collection." + this.index + ".Title";
    this.icon = bundles.stickers_book_window.frames["collection" + this.index + "_png"];
};

StickersCollection.prototype = Object.create(cleverapps.EventEmitter.prototype);
StickersCollection.prototype.constructor = StickersCollection;

StickersCollection.prototype.createStickers = function (options) {
    this.stickers = [];

    options.stickers.forEach(function (state, i) {
        var sticker = new Sticker({
            collection: this,
            state: state,
            image: bundles["collection_" + this.index].frames["sticker_" + i + "_png"],
            rarity: i < (
                StickersCollection.getCollectionRarity(this.index)
            ) ? Sticker.RARITY_RARE : Sticker.RARITY_REGULAR,
            title: "Sticker." + this.index + "." + i + ".Title",
            attention: i < options.attention.length ? options.attention[i] : false
        });
        sticker.on("update", this.onStickerUpdate.bind(this));
        this.stickers.push(sticker);
    }.bind(this));

    this.onStickerUpdate();
};

StickersCollection.prototype.getInfo = function () {
    return {
        isPrizeCollected: this.isPrizeCollected,
        stickers: this.stickers.map(function (sticker) {
            return sticker.getState();
        })
    };
};

StickersCollection.prototype.onPrizeCollect = function () {
    this.isPrizeCollected = true;
    cleverapps.stickersBook.save();
    this.trigger("update");
};

StickersCollection.prototype.onStickerUpdate = function () {
    var isCompleted = true;

    for (var i = 0; i < this.stickers.length; i++) {
        if (StickersBook.isPuzzle() && this.stickers[i].hasAttention()) {
            isCompleted = false;
        }

        if (this.stickers[i].getState() === Sticker.STATE_CLOSED) {
            isCompleted = false;
        }
    }

    if (isCompleted) {
        this.setCompleted();
    }

    this.trigger("update");
};

StickersCollection.prototype.setCompleted = function () {
    this.isCompleted = true;
    this.trigger("complete");
};

StickersCollection.prototype.getOpenedStickersAmount = function (withoutAttention) {
    return this.stickers.filter(function (sticker) {
        if (StickersBook.isPuzzle() && !withoutAttention) {
            return sticker.getState() === Sticker.STATE_OPENED && !sticker.hasAttention();
        } 
        return sticker.getState() === Sticker.STATE_OPENED;
    }).length;
};

StickersCollection.prototype.getAvailableLevel = function () {
    var availableLevel = cleverapps.Availables.STICKERS_BOOK.level;
    for (var i = 0; i < this.index; i++) {
        if (availableLevel < 13.2) {
            availableLevel += 2.666;
        } else {
            availableLevel += 5.333;
        }
    }

    return cleverapps.humanReadableNumber({ floatLevel: availableLevel });
};

StickersCollection.prototype.isAvailable = function () {
    var currentLevel = levels.user.getVirtualLevel();
    var availableLevel = this.getAvailableLevel();
    return currentLevel >= availableLevel;
};

StickersCollection.prototype.isCollected = function () {
    return this.getOpenedStickersAmount(true) === StickersCollection.STICKERS_IN_COLLECTION;
};

StickersCollection.prototype.hasAttention = function () {
    return this.stickers.filter(function (sticker) {
        return sticker.hasAttention();
    }).length;
};

StickersCollection.prototype.getAttentionInfo = function () {
    return this.stickers.map(function (sticker) {
        return sticker.hasAttention();
    });
};

StickersCollection.prototype.updateAttention = function (data) {
    this.stickers.forEach(function (sticker, index) {
        sticker.setAttention(data[index]);
    });
};

StickersCollection.prototype.resetAttention = function () {
    this.stickers.forEach(function (sticker) {
        sticker.setAttention(false);
    });
    this.trigger("hideAttention");
};

StickersCollection.getCollectionRarity = function (collectionIndex) {
    return StickersCollection.RARE_STICKERS_CONFIG[collectionIndex % StickersCollection.RARE_STICKERS_CONFIG.length];
};

StickersCollection.STICKERS_IN_COLLECTION = 9;

StickersCollection.REGULAR_POINTS = 5;
StickersCollection.RARE_POINTS = 25;
StickersCollection.GOAL_POINTS = 200;

StickersCollection.RARE_STICKERS_CONFIG = [
    3,
    4,
    4,
    3,
    4,
    3,
    5,
    4,
    5,
    5
];