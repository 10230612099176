/**
 * Created by Andrey Popov on 10.11.2021
 */

var TextureImageView = AnalyzerWindowBase.extend({
    ctor: function (textureImage) {
        this._super("Texture");

        this.textureImage = textureImage;
 
        this.textureImage.on("clearComponents", this.clearComponents.bind(this), this);

        this.textureImage.on("showLoading", function () {
            this.clearComponents();
            this.loading.setVisible(true);
            this.changeCursor(true);
        }.bind(this), this);
        this.textureImage.on("hideLoading", function () {
            this.loading.setVisible(false);
            this.changeCursor(false);
        }.bind(this), this);

        this.textureImage.on("showTexture", this.showTexture.bind(this), this);
        this.textureImage.on("selectTextureFrame", this.selectTextureFrame.bind(this), this);

        this.loading = new cc.Sprite(bundles.wysiwyg.frames.loading);
        this.loading.runAction(new cc.RepeatForever(new cc.RotateBy(5, 360)));
        this.loading.setPositionRound({ align: "center" }, { align: "center" });
        this.loading.setVisible(false);
        this.loading.setScale(2);
        this.addChild(this.loading);

        this.updateSize();
        this.setupChildren();
    },

    updateSize: function () {
        var sceneSize = cleverapps.resolution.getSceneSize();

        var width = sceneSize.width - cleverapps.styles.BundlesExplorerView.width - cleverapps.styles.ImageSpyView.width;
        var height = sceneSize.height - cleverapps.styles.AtlasAnalyzerScene.offsetTop;

        this.setContentSize(width, height);

        var styles = cleverapps.styles.TextureImageView;

        if (this.canvas) {
            var size = this.canvas.getContentSize();
            var scaleX = Math.min((this.width - styles.padding.x) / size.width, (this.width - styles.padding.x) / this.width);
            var scaleY = Math.min((this.height - styles.padding.y) / size.height, (this.height - styles.padding.y) / this.height);
            this.canvas.setContentSize2(size);
            this.canvas.setScale(Math.min(scaleX, scaleY));
            this.canvas.setPositionRound(styles);
        }

        if (this.textureBg) {
            this.textureBg.setContentSize(this.canvas.getContentSize());
        }
    },

    clearComponents: function () {
        if (this.canvas) {
            this.canvas.removeFromParent();
        }
    },

    showTexture: function (texture) {
        if (this.canvas) {
            this.canvas.removeFromParent();
        }

        if (!texture) {
            this.loading.setVisible(false);
            this.changeCursor(false);
            return;
        }

        this.canvas = new cc.Sprite(texture);
        this.canvas.setAnchorPoint(0.5, 0.5);
        this.createScroll(this.canvas, {
            zoom: true,
            hideScrollBar: true
        });

        if (this.textureFrameSelection) {
            this.textureFrameSelection.removeFromParent();
            delete this.textureFrameSelection;
        }

        this.loading.setVisible(false);
        setTimeout(function () {
            this.changeCursor(false);
        }.bind(this), 200);

        this.textureFrameSelection = cleverapps.UI.createScale9Sprite(bundles.wysiwyg.frames.attach_frame);
        this.textureFrameSelection.setAnchorPoint(0, 1);
        this.textureFrameSelection.setLocalZOrder(10000);
        this.textureFrameSelection.setVisible(false);
        this.canvas.addChild(this.textureFrameSelection);

        var textureBg = this.textureBg = cleverapps.UI.createScale9Sprite(bundles.editor_controls.frames.component);
        textureBg.setAnchorPoint(0.5, 0.5);
        textureBg.setPositionRound({ align: "center" }, { align: "center" });
        this.canvas.addChild(textureBg, -1);

        var startPosition;
        cleverapps.UI.onDrag(this.canvas, {
            onClick: function (touch) {
                var clickPoint = this.canvas.convertTouchToNodeSpace(touch);
                this.textureImage.handleTextureClick(clickPoint.x, clickPoint.y);
            }.bind(this),
            onDragStart: function () {
                startPosition = this.canvas.getPosition();
                return true;
            }.bind(this),
            onDragMove: function (touch) {
                var displacement = this.canvas.parent.convertTouchToNodeSpaceDisplacement(touch);
                this.canvas.setPosition(Math.round(startPosition.x + displacement.x), Math.round(startPosition.y + displacement.y));
            }.bind(this)
        });

        this.updateSize();
    },

    selectTextureFrame: function (frame, animate) {
        var border = cleverapps.styles.TextureImageView.border;
        this.textureFrameSelection.setContentSize2(frame.size.width + border, frame.size.height + border);
        this.textureFrameSelection.setVisible(true);

        if (animate) {
            var scale = 3;
            this.textureFrameSelection.setPositionRound(frame.position.x - frame.size.width - scale * border / 2, frame.position.y + frame.size.height + scale * border / 2);
            this.textureFrameSelection.setScale(scale);

            this.textureFrameSelection.runAction(
                new cc.Spawn(
                    new cc.ScaleTo(0.4, 1),
                    new cc.MoveTo(0.4, frame.position.x - border / 2, frame.position.y + border / 2)
                )
            );
        } else {
            this.textureFrameSelection.setPositionRound(frame.position.x - border / 2, frame.position.y + border / 2);
        }
    },

    changeCursor: function (waiting) {
        if (!connector.info.isNative && "mouse" in cc.sys.capabilities) {
            cc._canvas.style.cursor = waiting ? "wait" : "default";
        }
    }
});

cleverapps.styles.TextureImageView = {
    padding: {
        x: 20,
        y: 40
    },

    x: { align: "center" },
    y: { align: "center", dy: -16 },

    border: 40
};