/**
 * Created by slava on 02.02.17.
 */

var IceCreamMakerCellView = BaseCellView.extend({
    ctor: function (IceCreamMaker) {
        this._super();

        this.setContentSize(cleverapps.styles.BaseCellView.CELL_SIZE_PX, cleverapps.styles.BaseCellView.CELL_SIZE_PX);
        this.setAnchorPoint(0.5, 0.5);

        var image = new cc.Sprite(this.getImage(IceCreamMaker.lives));
        this.image = image;
        this.image.setPosition(this.width / 2, this.height / 2);
        this.addChild(image);

        this.animation = new cleverapps.Spine(bundles.ice_cream_maker.jsons.iceCreamMaker_spine_json);
        this.animation.setPosition(this.width / 2, this.height / 2);
        this.animation.visible = false;
        this.addChild(this.animation);

        this.initialize(IceCreamMaker);

        this.updateImage();
    },

    getImage: function (lives) {
        return bundles.ice_cream_maker.frames["iceCreamMaker_" + (4 - lives)] || bundles.ice_cream_maker.frames.iceCreamMaker_3;
    },

    initialize: function (IceCreamMaker) {
        this._super(IceCreamMaker);

        this.image.setVisible(true);
        this.animation.setVisible(false);

        IceCreamMaker.onChangeLivesListener = this.changeLives.bind(this);
    },

    updateImage: function () {
        if (this.cell.lives > 0) {
            this.image.setSpriteFrame(this.getImage(this.cell.lives));
        } else {
            this.image.setVisible(false);
            this.animation.setVisible(true);
            this.animation.setAnimation(0, "idle_4", true);
        }
    },

    changeLives: function () {
        this.updateImage();
        cleverapps.audio.playSound(bundles.ice_cream_maker.urls.icecream_maker_fill_effect);
    },

    unuse: function () {
        this.cell.onChangeLivesListener = function () {};

        this._super();
    },

    openAnimation: function () {
        var realZOrder = this.getLocalZOrder();
        this.setLocalZOrder(2);
        this.animation.setAnimation(0, "open", false);

        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.4),
            new cc.CallFunc(function () {
                cleverapps.audio.playSound(bundles.game.urls.goal_completed_effect);
            })
        ));

        this.runAction(new cc.Sequence(
            new cc.DelayTime(2.0),
            new cc.CallFunc(function () {
                this.setLocalZOrder(realZOrder);
                this.image.setVisible(true);
                this.animation.setVisible(false);
            }.bind(this))
        ));
    },

    hurtAnimation: function () {
    }
});