/**
 * Created by ivan on 10.05.18.
 */

var WaffleCell = function (x, y, color) {
    BaseCell.call(this, x, y);

    this.addComponent(ColorComponent, { color: color });
    this.addComponent(SpecialColorComponent);
    this.addComponent(MarkComponent);

    this.putInPoolAvailable = true;
};

WaffleCell.prototype = Object.create(BaseCell.prototype);
WaffleCell.prototype.constructor = WaffleCell;

WaffleCell.prototype.getViewClass = function () {
    return WaffleCellView;
};

WaffleCell.prototype.getGoalId = function () {
    return WaffleCell.GOAL_ID;
};

WaffleCell.prototype.bundleId = function () {
    return "waffle";
};

WaffleCell.prototype.explode = function (params) {
    var markComponent = this.findComponent(MarkComponent);
    if (markComponent) {
        markComponent.explode();
    }

    this.explodeViaCollect(params);
};

WaffleCell.prototype.load = function (data) {
    var color = data[1];
    var colorComponent = this.findComponent(ColorComponent);
    colorComponent.setColor(color === "." ? this.getRandomColor() : color);

    this.findComponent(MarkComponent).load(data);
};

WaffleCell.prototype.save = function () {
    var colorComponent = this.findComponent(ColorComponent);
    var code = WaffleCell.CODES.slice();
    code.push(colorComponent.color);

    var markCode = this.findComponent(MarkComponent).save();
    if (markCode) {
        code.push(markCode);
    }

    return code;
};

WaffleCell.GOAL_ID = "waffle";