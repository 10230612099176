/**
 * Created by ivan on 10.04.18.
 */

var Toolbar = function () {
    this.items = [];
    this.freezed = false;

    this.onUpdateItemsListener = function () {};
    this.onAddItemListener = function () {};
    this.onEnableListener = function () {};
    this.onGetView = function () {};

    if (cleverapps.config.type === "merge") {
        if (typeof OrdersToolbarItem !== "undefined") {
            this.addItem(new OrdersToolbarItem());
        }
        if (typeof UnitsLibraryToolbarItem !== "undefined") {
            this.addItem(new UnitsLibraryToolbarItem());
        }
        if (typeof UnitShopToolbarItem !== "undefined") {
            this.addItem(new UnitShopToolbarItem());
        }
        if (typeof TravelBookToolbarItem !== "undefined") {
            this.addItem(new TravelBookToolbarItem());
        }
        this.addItem(new DailyTasksToolbarItem());
        if (typeof ClansToolbarItem !== "undefined") {
            this.addItem(new ClansToolbarItem());
        }
        if (typeof CustomersToolbarItem !== "undefined") {
            this.addItem(new CustomersToolbarItem());
        }
        if (typeof MagnetToolbarItem !== "undefined") {
            this.addItem(new MagnetToolbarItem());
        }
        if (typeof DynamiteToolbarItem !== "undefined") {
            this.addItem(new DynamiteToolbarItem());
        }
    } else if (cleverapps.config.type === "battlefield") {
        if (typeof ArmyLibraryToolbarItem !== "undefined") {
            this.addItem(new ArmyLibraryToolbarItem());
        }
    } else if (["match3", "differences", "solitaire", "board", "tile3"].includes(cleverapps.config.type)) {
        this.addItem(new FriendRequestsToolbarItem());
        if (typeof MiniGameToolbarItem !== "undefined") {
            this.addItem(new MiniGameToolbarItem());
        }

        if (cleverapps.dailyTasks) {
            this.addItem(new DailyTasksToolbarItem());
        }

        this.addItem(new LeaderBoardToolbarItem());
        this.addItem(new RewardedVideoToolbarItem());
    } else if (["blocks"].includes(cleverapps.config.type)) {
        if (typeof MiniGameToolbarItem !== "undefined") {
            this.addItem(new MiniGameToolbarItem());
        }
        this.addItem(new RewardedVideoToolbarItem());
        this.addItem(new LeaderBoardToolbarItem());
        this.addItem(new FriendRequestsToolbarItem());
        if (cleverapps.dailyTasks) {
            this.addItem(new DailyTasksToolbarItem());
        }
    }

    if (cleverapps.config.debugMode && cleverapps.config.meta === Metha.HOMEFIX && typeof TravelMapToolbarItem !== "undefined") {
        this.addItem(new TravelMapToolbarItem());
    }

    if (typeof StickersBookToolbarItem !== "undefined") {
        this.addItem(new StickersBookToolbarItem());
    }
};

Toolbar.prototype.getSceneItems = function () {
    return this.items.filter(function (item) {
        return item.isAvailableOnScene();
    });
};

Toolbar.prototype.anyItemWithForce = function () {
    for (var i = 0; i < this.items.length; i++) {
        var force = this.items[i].getForce();
        if (force && this.items[i].isVisible() && !cleverapps.forces.isShown(force.id)) {
            return this.items[i];
        }
    }

    return undefined;
};

Toolbar.prototype.showAnyForce = function () {
    var item = this.anyItemWithForce();

    cleverapps.meta.display({
        focus: "toolbarItemForce",
        control: "toolbar",
        actions: [
            function (f) {
                this.updateItems();
                cleverapps.timeouts.setTimeout(f, 500);
            }.bind(this),

            function (f) {
                cleverapps.meta.showControlsWhileFocused("toolbar");
                item.showForce();
                cleverapps.forces.onceForceClosed = f;
            }
        ]
    });
};

Toolbar.prototype.addItem = function (item) {
    if (this.getItem(item.type) !== undefined) {
        return;
    }
    this.items.push({});
    var index = this.items.length - 1;
    while (index > 0 && this.items[index - 1].type > item.type) {
        this.items[index] = this.items[index - 1];
        index--;
    }

    this.items[index] = item;

    this.onAddItemListener(item, index);
};

Toolbar.prototype.hasVisibleItems = function () {
    return this.items.filter(function (item) {
        return item.isVisible();
    }).length > 0;
};

Toolbar.prototype.resetByType = function (itemType) {
    var item = this.getItem(itemType.type);
    if (item) {
        item.resetState();
        this.updateItems();
    }
};

Toolbar.prototype.resetItemsState = function () {
    this.items.forEach(function (item) {
        item.resetState && item.resetState();
    });
};

Toolbar.prototype.getItem = function (type) {
    for (var i = 0; i < this.items.length; ++i) {
        if (this.items[i].type === type) {
            return this.items[i];
        }
    }
    return undefined;
};

Toolbar.prototype.updateItems = function (silent) {
    if (this.freezed) {
        return;
    }

    this.onUpdateItemsListener(silent);
};

Toolbar.prototype.freezeUpdates = function () {
    this.freezed = true;
};

Toolbar.prototype.unfreezeUpdates = function () {
    this.freezed = false;
    this.updateItems();
};

Toolbar.prototype.setDragItem = function (item) {
    if (this.dragged === item) {
        return;
    }
    if (this.dragged) {
        this.dragged.freeFocus();
    }

    this.dragged = item;
    if (item) {
        cleverapps.meta.display({
            focus: "toolbarItemDrag",
            control: item.dragControls,
            action: function (f) {
                item.freeFocus = f;
            }
        });
    }
};