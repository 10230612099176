/**
 * Created by slava on 3/31/17.
 */

var NotificationView = cc.Scale9Sprite.extend({
    ctor: function (model, message, options) {
        var frame = bundles.notification.frames.notification_bg;

        this._super(frame, cleverapps.UI.getScale9Rect(frame, cleverapps.UI.Scale9Rect.TwoPixelXY));

        this.model = model;

        var styles = cleverapps.styles.NotificationView;

        this.setLocalZOrder(UpMenuContainer.ZORDER + 11);

        var text = cleverapps.UI.generateOnlyText(message, cleverapps.styles.FONTS.NOTIFICATION_TEXT, options.toReplace);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);

        var maxWidth = Math.min(cleverapps.resolution.getSceneSize().width * 0.85, styles.maxWidth);
        var textHeight = styles.text.height;

        if (options.progress) {
            this.bar = this.createBar(options.progress);
            textHeight -= this.bar.height * this.bar.scale + styles.progress.margin;
        }

        if (options.image) {
            var image = options.image instanceof cc.Node ? options.image : new cc.Sprite(options.image);
            cleverapps.UI.fitToBox(image, styles.image);
            maxWidth -= image.width * image.scale + styles.image.margin;
        }

        text.setDimensions(maxWidth, 0);
        text.fitTo(undefined, textHeight);

        this.content = text;

        if (this.bar) {
            this.content = new cleverapps.Layout([text, this.bar], {
                margin: styles.progress.margin,
                direction: cleverapps.UI.VERTICAL
            });
        }

        if (image) {
            this.content = new cleverapps.Layout([image, this.content], {
                margin: styles.image.margin,
                direction: cleverapps.UI.HORIZONTAL
            });
        }

        this.addChild(this.content);

        this.updateSize();
        this.setPositionRound(this.getHidePosition());

        if (!options.noSound) {
            cleverapps.audio.playSound(bundles.main.urls.notification_effect);
        }

        this.onShowListener = function () {
            if (!this.isRunning()) {
                return;
            }

            if (options.progress && options.progress.newAmount) {
                this.updateProgress(options.progress.newAmount, options.progress.total, 0.5);
            } else {
                this.delayAndHide();
            }
        }.bind(this);

        this.model.onRemove = this.createListener(function () {
            this.removeFromParent();
        }.bind(this));
        this.model.onUpdateProgress = this.createListener(this.updateProgress.bind(this));

        if (options.debugInfo) {
            this.setLocalZOrder(10000);
            this.model.onRemove = function () {};

            this.alwaysOn = true;

            cleverapps.UI.onPressed(this, function () {
                cleverapps.meta.display({
                    stack: true,
                    focus: "ErrorWindow",
                    action: function (f) {
                        new ErrorWindow(options);
                        cleverapps.meta.onceNoWindowsListener = f;
                    }
                });
            });
        }
    },

    createBar: function (progress) {
        var styles = cleverapps.styles.NotificationView.progress;
        var bar = new ScaledProgressBar({
            type: ScaledProgressBar.Types[styles.type],
            barText: {
                font: cleverapps.styles.FONTS.NOTIFICATION_PROGRESS_FONT
            }
        });
        bar.setLength(styles.width);
        bar.setGoal(progress.total);
        bar.setPercentage(progress.amount);
        bar.setAnchorPoint(0.5, 0.5);
        cleverapps.UI.fitToBox(bar, { height: styles.height });
        return bar;
    },

    updateSize: function () {
        var styles = cleverapps.styles.NotificationView;
        var maxContentWidth = cleverapps.resolution.getSceneSize().width * 0.85;
        var scale = Math.min(1, maxContentWidth / this.content.width);

        this.setContentSize(
            Math.max(styles.minWidth, this.content.width * scale + 2 * styles.padding.x),
            Math.max(styles.minHeight, 2 * (this.content.height * scale + styles.padding.y))
        );

        this.content.setScale(scale);
        this.content.setPositionRound({ x: { align: "center" }, y: { align: "bottom", dy: styles.padding.y } });
    },

    getHidePosition: function () {
        return { x: { align: "center" }, y: { align: "top", dy: this.height } };
    },

    getShownPosition: function () {
        var styles = cleverapps.styles.NotificationView;
        return { x: { align: "center" }, y: { align: "top", dy: -styles.offsetY + this.height / 2 } };
    },

    completeAnimationOnResize: function () {
        this.stopAllActions();
        this.delayAndHide();
    },

    show: function () {
        if (this.shown || !this.isRunning()) {
            return;
        }
        this.shown = true;

        var shownPosition = this.getShownPosition();
        this.runAction(new cc.Sequence(
            new cc.MoveTo(0.2, this.calculatePositionRound(shownPosition)),
            new cc.CallFunc(function () {
                this.onShowListener();
            }.bind(this))
        )).setFinalize(function () {
            this.setPositionRound(shownPosition);
        }.bind(this));
    },

    hide: function () {
        if (!this.shown || !this.isRunning()) {
            return;
        }
        this.shown = false;

        var hiddenPosition = this.getHidePosition();
        this.runAction(new cc.Sequence(
            new cc.MoveTo(0.5, this.calculatePositionRound(hiddenPosition)),
            new cc.CallFunc(function () {
                this.model.reset();
                this.model.processQueue();
            }.bind(this))
        )).setFinalize(function () {
            this.setPositionRound(hiddenPosition);
        }.bind(this));
    },

    delayAndHide: function () {
        if (!this.isRunning()) {
            return;
        }

        this.delayAndHideAction = this.runAction(new cc.Sequence(
            new cc.DelayTime(3),
            new cc.CallFunc(this.hide.bind(this))
        ));
    },

    updateProgress: function (progress, total, delay) {
        if (!this.bar || !this.shown) {
            return;
        }

        this.stopAction(this.delayAndHideAction);
        this.stopAction(this.updateProgressAction);

        if (!this.isRunning()) {
            return;
        }

        delay = delay || 0;
        this.updateProgressAction = this.runAction(new cc.Sequence(
            new cc.DelayTime(delay),
            new cc.CallFunc(function () {
                this.delayAndHide();
                this.bar.setGoal(total);
                this.bar.setPercentage(progress, {
                    animated: true
                });

                if (progress >= total) {
                    this.bar.updateBarText(Messages.get("done"));
                    cleverapps.audio.playSound(bundles.notification.urls.task_done_sfx);

                    if (bundles.notification.jsons.bar_flash_json) {
                        var animation = new cleverapps.Spine(bundles.notification.jsons.bar_flash_json);
                        this.bar.addChild(animation);
                        animation.setPositionRound(cleverapps.styles.NotificationView.progress.animation);
                        animation.setAnimation(0, "animation", false);
                        animation.setCompleteListenerRemove();
                    }
                }
            }.bind(this))
        )).setFinalize(function () {
            this.bar.setGoal(total);
            this.bar.setPercentage(progress);
            if (progress >= total) {
                this.bar.updateBarText(Messages.get("done"));
            }
        }.bind(this));
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    NOTIFICATION_TEXT: {
        name: "nostroke",
        size: 50,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    }
});

cleverapps.styles.NotificationView = {
    offsetY: 10,

    text: {
        height: 100
    },

    image: {
        margin: 20,
        width: 200,
        height: 100,
        maxScale: 1.5
    },

    minWidth: 300,
    minHeight: 200,
    maxWidth: 750,

    padding: {
        x: 30,
        y: 40
    },

    progress: {
        margin: 10,
        width: 350,
        height: 30,
        type: "green",
        animation: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: -2 }
        }
    }
};

NotificationView.ZORDER = BaseWindow.WINDOWS_ZORDER + 2;

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    NOTIFICATION_PROGRESS_FONT: {
        name: "default",
        size: 26
    }
});
