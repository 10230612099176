/**
 * Created by Andrey Popov on 19.09.2022
 */

var PointerHandlerExtensions = {};

PointerHandlerExtensions.in = function () {
    PointerHandler.prototype.__handleTouchBegan = PointerHandler.prototype.handleTouchBegan;
    PointerHandler.prototype.handleTouchBegan = function (touch, event) {
        if (!this.needProcessTouch(touch)) {
            return false;
        }
        if (!this.target.skipCapture) {
            var currentNode = PointerHandlerExtensions.bubbleToInterestingNode(this.target);
            cleverapps.actionsRecording.pushAction({
                type: "touch",
                location: PointerHandlerExtensions.saveLocation(currentNode, touch._point),
                debugId: currentNode.debugId
            });
        }
        return PointerHandler.prototype.__handleTouchBegan.call(this, touch, event);
    };

    if (cleverapps.config.type === "board" && cleverapps.config.name !== "scramble") {
        PointerHandler.prototype.__handleTouchMoved = PointerHandler.prototype.handleTouchMoved;
        PointerHandler.prototype.handleTouchMoved = function (touch, event) {
            if (this.touches[touch.__instanceId].state === PointerHandler.STATE_DRAG_MOVE) {
                var currentNode = PointerHandlerExtensions.bubbleToInterestingNode(this.target);
                var touchAction = cleverapps.actionsRecording.lastAction();
                if (!touchAction.path) {
                    touchAction.path = [];
                }

                var point = PointerHandlerExtensions.saveLocation(currentNode, touch._point);
                if (touchAction.path.length === 0 || cc.pDistance(touchAction.path[touchAction.path.length - 1], point) > 0.04) {
                    touchAction.path.push(point);
                }
            }

            PointerHandler.prototype.__handleTouchMoved.call(this, touch, event);
        };
    }

    PointerHandler.prototype.__handleTouchEnded = PointerHandler.prototype.handleTouchEnded;
    PointerHandler.prototype.handleTouchEnded = function (touch, event) {
        var currentNode = PointerHandlerExtensions.bubbleToInterestingNode(this.target);
        if (currentNode.dragTarget) {
            currentNode = currentNode.dragTarget();
        }

        if (this.touches[touch.__instanceId].state === PointerHandler.STATE_DRAG_MOVE) {
            var touchAction = cleverapps.actionsRecording.popAction();
            cleverapps.actionsRecording.pushAction({
                type: "drag",
                startPoint: touchAction.location,
                startDebugId: touchAction.debugId,
                path: touchAction.path,
                endPoint: PointerHandlerExtensions.saveLocation(currentNode, touch._point),
                endDebugId: currentNode.debugId,
                beforeStartDelay: touchAction.beforeStartDelay,
                beforeActionSeed: touchAction.beforeActionSeed
            });
        }

        PointerHandler.prototype.__handleTouchEnded.call(this, touch, event);
    };

    PointerHandler.prototype.__handleMouseScroll = PointerHandler.prototype.handleMouseScroll;
    PointerHandler.prototype.handleMouseScroll = function (event) {
        cleverapps.actionsRecording.pushAction({
            type: "zoom",
            scrollY: event.getScrollY(),
            debugId: PointerHandlerExtensions.bubbleToInterestingNode(this.target).debugId
        });

        PointerHandler.prototype.__handleMouseScroll.call(this, event);
    };
};

PointerHandlerExtensions.out = function () {
    if (PointerHandler.prototype.__handleTouchBegan) {
        PointerHandler.prototype.handleTouchBegan = PointerHandler.prototype.__handleTouchBegan;
    }

    if (PointerHandler.prototype.__handleTouchMoved) {
        PointerHandler.prototype.handleTouchMoved = PointerHandler.prototype.__handleTouchMoved;
    }

    if (PointerHandler.prototype.__handleTouchEnded) {
        PointerHandler.prototype.handleTouchEnded = PointerHandler.prototype.__handleTouchEnded;
    }

    if (PointerHandler.prototype.__handleMouseScroll) {
        PointerHandler.prototype.handleMouseScroll = PointerHandler.prototype.__handleMouseScroll;
    }
};

PointerHandlerExtensions.bubbleToInterestingNode = function (node) {
    var currentNode = node;
    while (!currentNode.debugId) {
        currentNode = currentNode.getParent();
    }
    return currentNode;
};

PointerHandlerExtensions.saveLocation = function (node, point) {
    var position = node.convertToNodeSpace(point);
    return cc.p(Math.round(10000 * position.x / node.width) / 10000, Math.round(10000 * position.y / node.height) / 10000);
};
