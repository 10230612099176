/**
 * Created by slava on 01.08.17.
 */

var FlowerCell = function (x, y) {
    BaseCell.call(this, x, y);
    this.movable = false;
};

FlowerCell.prototype = Object.create(BaseCell.prototype);
FlowerCell.prototype.constructor = FlowerCell;
Skinify(FlowerCell, "flower_cell");

FlowerCell.prototype.load = function (data) {
    this.lives = parseInt(data[1]);
};

FlowerCell.prototype.save = function () {
    return FlowerCell.CODES.concat([this.lives]);
};

FlowerCell.prototype.getViewClass = function () {
    return FlowerCellView;
};

FlowerCell.prototype.getGoalId = function () {
    return FlowerCell.GOAL_ID;
};

FlowerCell.prototype.boom = function () {
    this.hurt();
};

FlowerCell.prototype.hurtDuration = function () {
    return this.lives === 0 ? 0.4 : 0.0;
};

FlowerCell.prototype.explode = function () {
    if (Game.currentGame.goals.hasType(this.getGoalId())) {
        BaseCell.prototype.explodeViaCollect.call(this);
    } else {
        BaseCell.prototype.explode.call(this);
    }
};

FlowerCell.prototype.smile = function () {
    if (this.lives) {
        return;
    }
    this.animate(BaseCell.ANIMATION_SMILE);
};

FlowerCell.GOAL_ID = "flower_cell";