/**
 * Created by andrey on 11.07.2023
 */

cc.Sprite.prototype.unuse = function () {
    this.removeTemporarily();
};

cc.Sprite.prototype.reuse = function () {
    this.setScale(1);
    this.setRotation(0);
    this.setAnchorPoint(0.5, 0.5);
    this.setPosition(0, 0);
    this.setOpacity(255);
    this.setColor(cc.Base.EMPTY_COLOR);
    this.setCascadeColorEnabled(false);
    this.setCascadeOpacityEnabled(false);
    this.setVisible(true, true);
};

cc.Sprite.prototype.visitTextureBundle = function () {
    var textureBundle = this.getTextureBundle();
    var virtualBundle = this.virtualResource && bundles[this.virtualResource.bundleName];
    var preferredBundles = this.getPreferredBundles();

    var needUpdate = (cc.rendererBundle && virtualBundle
        && cc.rendererBundle !== textureBundle
        && virtualBundle.isInjectTo(cc.rendererBundle))
        || (textureBundle && !cleverapps.bundleLoader.isLoaded(textureBundle))
        || (virtualBundle && preferredBundles && !preferredBundles.includes(textureBundle) && virtualBundle.isInjectTo(preferredBundles));

    if (needUpdate) {
        this.setSpriteFrame(this.virtualResource);
        textureBundle = this.getTextureBundle();
    }

    if (textureBundle) {
        cc.rendererBundle = textureBundle;
    }

    return needUpdate;
};

cc.Scale9Sprite.prototype.visitTextureBundle = cc.Sprite.prototype.visitTextureBundle;
