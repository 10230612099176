/**
 * Created by slava on 22/2/18
 */

EditorControlsView.prototype.listControlsOld = EditorControlsView.prototype.listControls;

EditorControlsView.prototype.listControls = function (editor) {
    var res = EditorControlsView.prototype.listControlsOld.call(this, editor);

    res.toggle = function () {
        if (this.hidden) {
            editor.showCells();
            this.hidden = false;
        } else {
            editor.hideCells();
            this.hidden = true;
        }
    };

    return res;
};