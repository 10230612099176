/**
 * Created by Andrey Popov on 5/15/23.
 */

var AdsLogo = cc.Node.extend({
    ctor: function (properties, eventBus, parent, sceneSize) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);

        this.sceneSize = sceneSize;
        this.properties = cleverapps.clone(properties || {}, true);

        if (properties.asset && properties.asset.indexOf(".png") !== -1) {
            this.content = new AdsSprite({
                image: {
                    folder: "logo",
                    name: properties.asset
                }
            });
        } else {
            this.content = new AdsSpine({
                spine: {
                    folder: "logo",
                    name: properties.asset,
                    animation: properties.animation,
                    looped: false
                }
            });
        }
        if (this.content) {
            this.addChild(this.content);
        }
        this.onComponentSizeChanged();

        var effect = this.properties.effect;
        if (effect && effect.name !== Wysiwyg.NOT_SELECTED && effect.name !== SoundPropertyEditor.WITHOUT_MUSIC) {
            this.sound = cleverapps.audio.playSound(cleverapps.wysiwyg.googleDriveProvider.getSoundLink(effect));
        }
    },

    hide: function () {
        this.setVisible(false);

        if (this.sound) {
            cleverapps.audio.stopSound(this.sound);
        }
    },

    onComponentSizeChanged: function () {
        if (!this.content) {
            return;
        }
        
        this.setContentSize(this.content.getContentSize());

        this.content.setPositionRound(this.width / 2, this.height / 2);

        this.parent && this.parent.onComponentSizeChanged();
    }
});

AdsLogo.PROPERTIES = [{
    name: "asset",
    type: "string",
    items: function () {
        return Object.keys(cleverapps.wysiwyg.googleDriveProvider.getAssetsInFolders(["logo"]));
    },
    bubbleUp: true
}, {
    name: "animation",
    type: "string",
    items: function () {
        return ["animation_%{lang}", "animation_en", "animation_en_idle", "animation_ru", "animation_ru_idle"];
    }
}, {
    name: "effect",
    type: "sound",
    folders: ["sounds"],
    bubbleUp: true
}];