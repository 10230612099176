/**
 * Created by Andrey Popov on 16.11.20
 */

var Slider = ccui.Slider.extend({
    ctor: function () {
        this._super();

        this.setTouchEnabled(true);
        this.setScale9Enabled(true);

        this.loadBarTexture(bundles.slider.frames.editor_slider, ccui.Widget.PLIST_TEXTURE);
        this.loadSlidBallTextures(bundles.slider.frames.editor_slider_pointer, bundles.slider.frames.editor_slider_pointer, "", ccui.Widget.PLIST_TEXTURE);
        this.loadProgressBarTexture(bundles.slider.frames.editor_slider, ccui.Widget.PLIST_TEXTURE);

        this.percentChangeCallback = undefined;
        this.incPercentCallback = undefined;
        this.decPercentCallback = undefined;

        this.addEventListener(function (sender, type) {
            if (type === ccui.Slider.EVENT_PERCENT_CHANGED && this.percentChangeCallback) {
                this.percentChangeCallback(sender);
            }
        }, this);
    },

    onTouchBegan: function (touch, event) {
        var pass = ccui.Slider.prototype.onTouchBegan.call(this, touch, event);
        var point = this.convertTouchToNodeSpace(touch);
        var clickInSliderArea = this.visible && cc.rectContainsPoint(cc.rect(0, 0, this.width, this.height), point);

        if (!this._hit && this._touchBeganPosition && clickInSliderArea) {
            var clickPosition = this._slidBallNormalRenderer.convertToNodeSpace(this._touchBeganPosition);
            if (clickPosition.x > 0 && this.incPercentCallback) {
                this.incPercentCallback();
            }

            if (clickPosition.x < 0 && this.decPercentCallback) {
                this.decPercentCallback();
            }
        }
        return pass;
    }
});