/**
 * Created by ivan on 22.02.18.
 */

var CakeCellView = BaseCellView.extend({
    ctor: function (cakeCell) {
        this._super();

        if (cakeCell.isForGoal) {
            this.initGoal(cakeCell);
            return;
        }

        var bigComponent = cakeCell.findComponent(BigComponent);
        if (bigComponent.isHead) {
            this.initHead(cakeCell);
        }
        this.initialize(cakeCell);
    },

    initGoal: function (cakeCell) {
        var styles = cleverapps.styles.CakeCellView;
        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize(styles.goal.width, styles.goal.height);
        var bundle = cakeCell.bundleId();
        var goalImage = new cc.Sprite(bundles[bundle].frames[bundle + "_goal_png"]);
        goalImage.setPositionRound(this.width / 2, this.height / 2);

        this.addChild(goalImage);
        this.cell = cakeCell;

        if (!cakeCell.isDartsSkin()) {
            goalImage.setScaleX(-1);
        }
    },

    initHead: function (cakeCell) {
        var styles = cleverapps.styles.CakeCellView;
        this.setContentSize2(styles.width, styles.height);
        this.setAnchorPoint(0.5, 0.5);

        var bundle = cakeCell.bundleId();
        var plate = this.plate = new cc.Sprite(bundles[bundle].frames[bundle + "_plate_png"]);
        plate.setAnchorPoint(0.5, 0.5);
        plate.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(plate);

        if (!cakeCell.isDartsSkin()) {
            var anchors = {
                1: { "x": 1, "y": 0 },
                2: { "x": 0, "y": 0 },
                3: { "x": 1, "y": 1 },
                4: { "x": 0, "y": 1 }
            };
            var position = {
                1: { "x": this.width / 4, "y": this.height * 3 / 4 },
                2: { "x": this.width * 3 / 4, "y": this.height * 3 / 4 },
                3: { "x": this.width / 4, "y": this.height / 4 },
                4: { "x": this.width * 3 / 4, "y": this.height / 4 }
            };
            this.images = {};
            for (var part = 1; part <= 4; ++part) {
                var image = new cc.Sprite(bundles[bundle].frames[bundle + "_" + part + "_3_png"]);
                image.setAnchorPoint(anchors[part]);
                this.addChild(image);
                var x = position[part].x + (anchors[part].x - 0.5) * image.width;
                var y = position[part].y + (anchors[part].y - 0.5) * image.height;
                image.setPositionRound(x, y);
                this.images[part] = image;
            }
        }

        var bigComponent = cakeCell.findComponent(BigComponent);
        for (var dx = 0; dx < Field.SIZE; dx++) {
            for (var dy = 0; dy < Field.SIZE; dy++) {
                var cell = Game.currentGame.field.cells[dy][dx];
                if (cell === undefined) {
                    continue;
                }
                if (cell instanceof CakeCell && cell.findComponent(BigComponent).head === bigComponent.head) {
                    if (cakeCell.isDartsSkin()) {
                        for (var i = 0; i < 3 - cell.lives; i++) {
                            this.dartsChangeLives(cell, {
                                silent: true,
                                lives: 2 - i
                            });
                        }
                    } else if (cell.lives > 0) {
                        this.images[cell.part].setSpriteFrame(this.getImage(cell));
                    } else {
                        this.images[cell.part].visible = false;
                    }
                }
            }
        }
    },

    getStyles: function () {
        return cleverapps.styles.CakeCellView;
    },

    initialize: function (cakeCell) {
        this._super(cakeCell);

        cakeCell.onChangeLivesListener = this.changeLives.bind(this);
        this.setPositionRound(BaseCellView.alignInTheGrid(cakeCell.x + 0.5, cakeCell.y + 0.5));
    },

    unuse: function () {
        this.cell.onChangeLivesListener = function () {};

        this._super();
    },

    getImage: function (cell) {
        cell = cell || this.cell;
        var bundle = cell.bundleId();
        return bundles[bundle].frames[bundle + "_" + cell.part + "_" + cell.lives + "_png"];
    },

    changeLives: function (cell) {
        var bigComponent = this.cell.findComponent(BigComponent);
        if (!bigComponent.isHead) {
            return;
        }

        if (cell.isDartsSkin()) {
            this.dartsChangeLives(cell);
            return;
        }

        if (cell.lives > 0) {
            this.images[cell.part].setSpriteFrame(this.getImage(cell));
        } else {
            this.images[cell.part].visible = false;
        }

        var animation = CakeAnimation.factory(this.cell.bundleId());
        animation.setPosition(BaseCellView.alignInTheGrid(cell.x, cell.y));
        if (!animation.parent) {
            this.parent.addChild(animation);
        }
        animation.runAnimate("animation");

        var bundle = cell.bundleId();
        var goalImage = new cc.Sprite(bundles[bundle].frames[bundle + "_fly_png"] || bundles[bundle].frames[bundle + "_goal_png"]);
        goalImage.setPosition(BaseCellView.alignInTheGrid(cell.x, cell.y));
        this.parent.addChild(goalImage);
        goalImage.setScale(0.5);

        var goal = Game.currentGame.goals.findTargetFor(cell.getGoalId());
        goal.collect(1, goalImage);
    },

    dartsChangeLives: function (cell, options) {
        options = options || {};
        var lives = (options.lives !== undefined) ? options.lives : cell.lives;
        var styles = cleverapps.styles.CakeCellView;

        var partRot = {
            1: -90,
            2: 0,
            3: 180,
            4: 90
        };
        if (lives >= 0) {
            var baseRot = -90;
            var pos = styles.positions[cell.part][2 - lives];
            var targetPos = cc.p(this.plate.x + pos.x, this.plate.y + pos.y);
            var dist = Math.sqrt(pos.x * pos.x + pos.y * pos.y);
            var k = styles.startDistance / dist;
            var startPos = cc.p(this.plate.x + pos.x * k, this.plate.y + pos.y * k);
            var bundle = cell.bundleId();
            var dart = new cc.Sprite(dist <= styles.centerRadius ? bundles[bundle].frames[bundle + "_dart_middle_png"] : bundles[bundle].frames[bundle + "_dart_png"]);
            this.addChild(dart);
            dart.setPositionRound(options.silent ? targetPos : startPos);
            dart.setRotation(baseRot + partRot[cell.part] + (2 - lives) * 30 + 15);

            if (!options.silent) {
                dart.setScale(1.5);
                this.setLocalZOrder(11);
                dart.runAction(
                    new cc.Sequence(
                        new cc.Spawn(
                            new cc.FadeIn(0.2),
                            new cc.MoveTo(0.2, targetPos).easing(cc.easeIn(5)),
                            new cc.ScaleTo(0.2, 1.0)
                        ),
                        new cc.CallFunc(function () {
                            this.setLocalZOrder(this.baseZOrder);
                        }.bind(this))
                    )
                );
            }
        }
    },

    startExploding: function () {
        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.3),
            new cc.ScaleTo(0.3, 0),
            new cc.RemoveSelf()
        ));
    },

    hurtAnimation: function () {
        var bigComponent = this.cell.findComponent(BigComponent);
        if (!bigComponent.isHead) {
            return;
        }

        var DURATION = 0.2;
        this.runAction(new cc.Sequence(
            new cc.ScaleTo(DURATION, 0.9),
            new cc.ScaleTo(DURATION, 1)
        ));
    }
});

cleverapps.styles.CakeCellView = {
    width: 240,
    height: 240,

    goal: {
        width: 120,
        height: 120
    },

    hover: {
        y: 10
    }
};