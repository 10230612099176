/**
 * Created by slava on 02.02.17.
 */

var BombByLeftMovesView = cc.Node.extend({
    ctor: function (bomb) {
        this._super();
        this.setLocalZOrder(20);

        this.setCascadeOpacityEnabled(true);

        this.addImage();
        this.initialize(bomb);
    }
});

BombByLeftMovesView.prototype.addImage = function () {
    var image = new cc.Sprite(bundles.game.frames.bomb_png);
    this.addChild(image);
    this.image = image;
};

BombByLeftMovesView.prototype.initialize = function (bomb) {
    this.setVisible(true);
    this.bomb = bomb;
    if (this.image) {
        this.image.visible = true;
        this.image.opacity = 255;
    }
    this.setScale(0);
    this.opacity = 255;

    this.bomb.onExplodeListener = this.onExplode.bind(this);
    this.bomb.onAnimateListener = this.animate.bind(this);

    this.animation = BombByLeftMovesAnimation.factory();
    if (this.animation) {
        this.addChild(this.animation);
    }
};

BombByLeftMovesView.prototype.animate = function () {
    var scene = cleverapps.scenes.getRunningScene();
    var movesView = scene.movesView;

    var fieldView = Game.currentGame.field.onGetView();

    var start = this.getRelativeCoordinates(movesView);
    var target = BaseCellView.alignInTheGrid(this.bomb.x, this.bomb.y);
    target = this.parent.convertToNodeSpace(fieldView.convertToWorldSpace(target));
    var second = cc.p((start.x + 2 * target.x) / 3, start.y + cleverapps.styles.BombByLeftMovesView.bezier.y);
    var first = cc.p((start.x + second.x) / 2, (start.y + second.y) / 2);

    this.setPosition(start);
    this.setScale(0.4);
    this.setOpacity(0);
    this.runAction(new cc.Sequence(
        new cc.Spawn(
            new cc.FadeIn(0.2),
            new cc.BezierTo(0.7, [first, second, target]).easing(cc.easeIn(1)),
            new cc.ScaleTo(0.7, 0.8).easing(cc.easeIn(1)),
            new cc.RotateBy(0.7, cleverapps.Random.nextDouble() < 0.5 ? 360 : -360)
        ),
        new cc.ScaleTo(0.2, 1.2),

        new cc.Sequence(new cc.CallFunc(function () {
            if (this.animation) {
                this.scale = 1;
                this.image.visible = false;
                this.animation.animate();
            } else {
                this.runAction(new cc.Sequence(
                    new cc.Spawn(
                        new cc.ScaleTo(0.2, 1.5),
                        new cc.FadeOut(0.2)
                    )
                ));
            }

            cleverapps.audio.playSound(bundles.game.urls.bomb_by_left_moves_explosion_effect, {
                throttle: 200
            });
        }.bind(this)))
    ));
};

BombByLeftMovesView.prototype.onExplode = function () {
    this.stopAllActions();

    cc.pool.putInPool(this);
};

BombByLeftMovesView.prototype.reuse = function (bomb) {
    this.initialize(bomb);
};

BombByLeftMovesView.prototype.unuse = function () {
    this.visible = false;

    this.bomb.onExplodeListener = function () {
    };
    this.bomb.onAnimateListener = function () {
    };
    delete this.bomb;

    if (this.animation) {
        cc.pool.putInPool(this.animation);
    }
};

BombByLeftMovesView.factory = function (bomb) {
    return cc.pool.hasObject(BombByLeftMovesView) ? cc.pool.getFromPool(BombByLeftMovesView, bomb) : new BombByLeftMovesView(bomb);
};

cleverapps.styles.BombByLeftMovesView = {
    bezier: {
        y: 300
    },
    parentScene: true
};

var BombByLeftMovesAnimation = cc.Node.extend({
    ctor: function () {
        this._super();

        var animation = new cleverapps.Spine(bundles.game.jsons.bomb_animation_json);
        this.addChild(animation);
        this.animation = animation;

        this.setCascadeOpacityEnabled(true);
    },

    animate: function () {
        this.animation.setAnimation(0, "animation", false);
    },

    unuse: function () {
        this.animation.visible = false;
        this.removeFromParent(false);
    }
});

BombByLeftMovesAnimation.fillPool = function () {
    for (var i = 0; i < BombByLeftMovesAnimation.POOL_SIZE; i++) {
        cc.pool.putInPool(new BombByLeftMovesAnimation());
    }
};

BombByLeftMovesAnimation.factory = function () {
    return cc.pool.hasObject(BombByLeftMovesAnimation) ? cc.pool.getFromPool(BombByLeftMovesAnimation) : undefined;
};

BombByLeftMovesAnimation.POOL_SIZE = 5;