/**
 * Created by slava on 23.03.17.
 */

var MenuBarView = cc.Node.extend({
    ctor: function () {
        this._super();

        var styles = cleverapps.styles.MenuBar;
        var views = cleverapps.menuBar.getSceneItems().filter(function (item) {
            if (item.name === "CoinsItem" && cleverapps.gameModes.hideMenuBarCoinsItem) {
                return false;
            }

            return true;
        }).map(function (item) {
            var ViewClass = item.getViewClass();
            return new ViewClass(item);
        });
        var layout = this.layout = new cleverapps.Layout(views, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });
        layout.setAnchorPoint(0, 0);

        this.addChild(layout);

        this.views = views.map(function (view) {
            var controlView = new HidingNode(view, cleverapps.UI.VERTICAL);
            var hiddenByDefault = view.item.hiddenByDefault;
            if (view.item.name === "GoldItem" && cleverapps.gameModes.showMenuBarGoldItem) {
                hiddenByDefault = undefined;
            }
            cleverapps.meta.registerControl(view.item.getControlName(), controlView, hiddenByDefault);
            return controlView;
        });

        this.updateSize();
        this.updateContentSize();
    },

    updateSize: function () {
        if (connector.info.isMobile) {
            this.layout.setScale(cleverapps.styles.MenuBar.mobileScale[cleverapps.resolution.mode]);
        }
    },

    reshape: function (maxWidth, centerX) {
        var layout = this.layout;
        var centerItem = this.views[1];
        maxWidth /= layout.scale;
        centerX /= layout.scale;

        layout.setOptions({ margin: cleverapps.styles.MenuBar.margin });
        if (layout.width < maxWidth && centerItem && centerItem.target && centerItem.target.centerAlign
            && centerItem.x < centerX && centerX + centerItem.width / 2 <= maxWidth) {
            centerItem.setPosition(centerX, centerItem.y);
            layout.width = centerItem.x + centerItem.width / 2;
        }
        this.updateContentSize();
    },

    updateContentSize: function () {
        this.setContentSize2(this.layout.width * this.layout.scale, this.layout.height * this.layout.scale);
    }
});

cleverapps.styles.MenuBar = {
    mobileScale: [0.75, 0.8, 0.8],
    margin: 0
};
