/**
 * Created by Vladislav on 25.09.2024.
 */

var ExpMenuBarItem = {
    name: "ExpItem",
    targets: "exp",
    plusButton: true,
    lottery: true,
    isClickable: function () {
        return cleverapps.flags.monetization === cleverapps.Flags.MONETIZATION_REGULAR;
    },
    clickableUpdater: function (f) {
        cleverapps.flags.on("change:monetization", f, this);
    },
    value: function () {
        return cleverapps.exp.getExp();
    },
    updater: function (f) {
        cleverapps.exp.on("changeExp", f, this);
    },
    onClickWindow: ExpInfoWindow,
    icon: bundles.menubar.frames.exp_png,
    availableOnScenes: [cleverapps.Environment.SCENE_HEROES]
};