/**
 * Created by Denis "dalorveen" Kuzin on 18 october 2020
 */

var AdminLanguage = function (administrator, language, selected) {
    cleverapps.EventEmitter.call(this);

    this.language = language;
    this.selected = selected;

    administrator.adminEpisodes.onUpdateEpisodeListener = this.onUpdateEpisode.bind(this);
};

AdminLanguage.prototype = Object.create(cleverapps.EventEmitter.prototype);
AdminLanguage.prototype.constructor = AdminLanguage;

AdminLanguage.prototype.onClick = function () {
    if (!this.selected) {
        cleverapps.administrator.setLanguage(this.language);
    }
};

AdminLanguage.prototype.select = function () {
    this.selected = true;
    this.trigger("select");
};

AdminLanguage.prototype.unselect = function () {
    this.selected = false;
    this.trigger("unselect");
};

AdminLanguage.prototype.inProgress = function () {
    if (typeof this.inProgressCache !== "undefined") {
        return this.inProgressCache;
    }
    this.inProgressCache = AdminEpisodes.GetEpisodeNames(this.language).find(function (episodeNo) {
        return AdminEpisode.InProgress(episodeNo, this.language);
    }.bind(this));
    return this.inProgressCache;
};

AdminLanguage.prototype.onUpdateEpisode = function () {
    if (cleverapps.settings.language === this.language) {
        delete this.inProgressCache;
    }
};

AdminLanguage.IsAvailable = function () {
    return cleverapps.config.type === "board";
};
