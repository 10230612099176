/**
 * Created by ivan on 11.01.18.
 */

var GingerHousePartCell = function (x, y) {
    BaseCell.call(this, x, y);

    this.movable = true;
    this.hurtable = false;
};

GingerHousePartCell.prototype = Object.create(BaseCell.prototype);
GingerHousePartCell.prototype.constructor = GingerHousePartCell;

GingerHousePartCell.prototype.load = function (data) {
    switch (data[1]) {
        case "^":
            this.type = GingerHousePartCell.TYPES.ROOF;
            break;
        case "&":
            this.type = GingerHousePartCell.TYPES.DOOR;
            break;
        case "*":
            this.type = GingerHousePartCell.TYPES.WINDOW;
            break;
        case "#":
            this.type = GingerHousePartCell.TYPES.WALL;
            break;
    }
};

GingerHousePartCell.getCodeByType = function (partType) {
    var letter = "";
    switch (partType) {
        case GingerHousePartCell.TYPES.ROOF:
            letter = "^";
            break;
        case GingerHousePartCell.TYPES.DOOR:
            letter = "&";
            break;
        case GingerHousePartCell.TYPES.WINDOW:
            letter = "*";
            break;
        case GingerHousePartCell.TYPES.WALL:
            letter = "#";
            break;
    }
    return GingerHousePartCell.CODES.concat(letter);
};

GingerHousePartCell.prototype.save = function () {
    return GingerHousePartCell.getCodeByType(this.type);
};

GingerHousePartCell.prototype.getViewClass = function () {
    return GingerHousePartCellView;
};

GingerHousePartCell.prototype.onBurnStage = function () {
    for (var i = this.y + 1; i < Field.SIZE; i++) {
        if (Game.currentGame.field.inField(i, this.x)) {
            return;
        }
    }
    this.explode();
};

GingerHousePartCell.prototype.explode = function () {
    if (!this.alive) {
        return;
    }
    this.alive = false;
    var house = GingerHouseCell.find(this.type);
    house.collectPart(this);

    var EXPLODE_INTERVAL = this.explodeDuration();
    this.onBeforeExplodeListener(EXPLODE_INTERVAL);
    Game.currentGame.counter.setTimeout(this.afterExplode.bind(this), EXPLODE_INTERVAL * 1000);
};

GingerHousePartCell.EXPLODE_VIA_COLLECT_TIMEOUT = 300;

GingerHousePartCell.TYPES = {
    WALL: 0,
    DOOR: 1,
    WINDOW: 2,
    ROOF: 3
};