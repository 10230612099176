/**
 * Created by Reda on 21.08.2024
 */

var WindowOffer = function (parentNode, options) {
    this.options = options || {};
    this.parentNode = parentNode;
    this.positioningStartOffset = options.positioningStartOffset || 0;

    if (PackOfferComponent.checkBundles(options.bundle)) {
        var packView = this.packView = new PackOfferComponent(options);
        parentNode.addChild(packView, 2);
    }
};

WindowOffer.prototype.createOfferButton = function () {
    this.offerButton = new OfferButtonComponent(this.options);
    return this.offerButton;
};

WindowOffer.prototype.removeOfferButton = function () {
    this.offerButton && this.offerButton.removeFromParent();
    this.offerButton = undefined;
};

WindowOffer.prototype.addOfferButton = function () {
    if (!this.offerButton) {
        this.offerButton = this.createOfferButton();
        this.parentNode.addChild(this.offerButton, 2);
        this.offerButton.runAction(new cc.Sequence(
            new cc.ScaleTo(0, 0.8),
            new cc.Show(),
            new cc.ScaleTo(0.3, 1).easing(cc.easeBackOut())
        ));
    }
};

WindowOffer.prototype.updatePackPosition = function () {
    var packStyles = this.packView.getStyles();
    var sceneSize = cleverapps.resolution.getSceneSize();

    var dx = sceneSize.width / 2 + this.positioningStartOffset;
    this.packView.setPositionRound({
        x: { align: "left", dx: dx + packStyles.offsetX },
        y: { align: "bottom", dy: packStyles.offsetY - cleverapps.resolution.getSafePadding().bottom }
    });
};

WindowOffer.prototype.isPackVisible = function () {
    if (!this.packView) {
        return false;
    }
    var packStyles = this.packView.getStyles();
    var sceneSize = cleverapps.resolution.getSceneSize();

    var isVisible = this.packView.x < sceneSize.width;
    return isVisible && packStyles.visibleResolutionModes.indexOf(cleverapps.resolution.mode) !== -1;
};

WindowOffer.prototype.update = function (animate) {
    this.packView && this.updatePackPosition();

    if (!this.shown) {
        return;
    }

    if (this.isPackVisible()) {
        this.packView.show(animate);
        this.removeOfferButton();
    } else {
        this.packView && this.packView.hide(animate);
        this.addOfferButton();
    }
};

WindowOffer.prototype.show = function () {
    this.shown = true;
    this.update(true);
};

WindowOffer.prototype.getOverlapNode = function () {
    return this.offerButton || (this.packView && this.packView.getOverlapNode());
};

WindowOffer.prototype.expireOffer = function () {
    this.removeOfferButton();
    this.packView && this.packView.removeFromParent();
};
