/**
 * Created by Andrey Popov on 9/23/24.
 */

var FallSaleWindow = CleverappsWindow.extend({
    onWindowLoaded: function () {
        this._super({
            name: "FallSaleWindow",
            noBackground: true,
            notCloseByTouchInShadow: true,
            closeButtonDelay: true,
            fireworks: true,
            content: this.createContent(),
            openSound: bundles.fallsale.urls.sale_showup_effect,
            button: {
                width: cleverapps.styles.FallSaleWindow.button.width,
                height: cleverapps.styles.FallSaleWindow.button.height,

                text: "FallSaleWindow.Button.text",
                onPressed: function () {
                    new HardCurrencyShopWindow();
                    this.close();
                }.bind(this)
            }
        });
    },

    createContent: function () {
        var styles = cleverapps.styles.FallSaleWindow;

        var content = this.content = new cleverapps.Spine(bundles.fallsale.jsons.sale_bg);
        content.setAnimationAndIdleAfter("open", "idle");

        var description = cleverapps.UI.generateOnlyText("FallSaleWindow.description", cleverapps.styles.FONTS.FALL_SALE_BG_DESCRIPTION);
        description.setDimensions(styles.description.width, 0);
        description.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        description.fitTo(undefined, styles.description.height);
        description.setPositionRound(styles.description);
        this.content.addChild(description);

        this.createHeader();
        return content;
    },

    createHeader: function () {
        var styles = cleverapps.styles.FallSaleWindow;

        var msg = "FallSaleWindow.title";
        var title = cleverapps.UI.generateOnlyText(msg, cleverapps.styles.FONTS.FALL_SALE_BG_TITLE);
        title.fitTo(styles.title.width, styles.title.height);
        title.setPositionRound(styles.title);
        this.content.addChild(title);
    },

    listBundles: function () {
        return ["fallsale"];
    },

    getPerson: function () {
        return cleverapps.persons.choose("seller", "hero");
    }
});

cleverapps.overrideStyles(cleverapps.styles.DECORATORS, {
    FALL_SALE_BG_TITLE_SHADOW: {
        color: new cc.Color(201, 71, 0, 255),
        direction: cc.size(4, -6)
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    FALL_SALE_BG_TITLE: {
        size: 200,
        color: new cc.Color(255, 240, 0, 255),
        shadow: cleverapps.styles.DECORATORS.FALL_SALE_BG_TITLE_SHADOW
    },

    FALL_SALE_BG_DESCRIPTION: {
        size: 24,
        color: new cc.Color(255, 253, 81, 255)
    }
});

cleverapps.styles.FallSaleWindow = {
    title: {
        x: { align: "center", dx: -10 },
        y: { align: "center", dy: 394 },

        width: 800,
        height: 200
    },

    description: {
        x: { align: "center", dx: -10 },
        y: { align: "center", dy: -330 },

        width: 1000,
        height: 200
    },

    header: {
        x: { align: "center" },
        y: { align: "top", dy: -40 }
    },

    button: {
        width: 460,
        height: 100
    }
};