/**
 * Created by spepa on 11.04.2024
 */

var ToolbarDragIcon = cc.Node.extend({
    ctor: function (icon, itemModel) {
        this._super();
        this.icon = icon;
        this.itemModel = itemModel;

        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize(icon.getContentSize());
        icon.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(icon);

        itemModel.onUpdateDragState = this.createListener(this.updateState.bind(this));
    },

    updateState: function (params) {
        params = params || {};
        if (params.animation && params.animation !== this.icon.getCurrentAnimationName(0)) {
            this.icon.setAnimation(0, params.animation, true);
        }
    },

    onDragMove: function (touch) {
        this.setPositionRound(this.parent.convertTouchToNodeSpace(touch));
        this.itemModel.onFollowPointer(touch);
    },

    onDragEnd: function (touch, callback) {
        if (this.itemModel.dragAction(touch)) {
            this.icon.setAnimation(0, "use", false);
            this.icon.setCompleteListenerOnce(function () {
                this.icon.setAnimation(0, "idle", true);
                callback();
            }.bind(this));
        } else {
            callback();
        }
    }
});