/**
 * Created by Aleksandr on 08.05.2024
 */

var CustomRewardView = cc.Node.extend({
    ctor: function (reward, index) {
        this._super();

        this.reward = reward;
        this.index = index;
        this.hasAnimation = RewardTypes[reward.type].getCustomRewardIcon() instanceof VirtualJson;

        this.createIcon();
        this.createTooltip();
        this.createAnimation();
        this.createProgress();

        this.setVisible(false);

        this.updateSize();
    },

    setUpIconSpine: function () {
        var rewardType = RewardTypes[this.reward.type];
        var icon = new cleverapps.Spine(rewardType.getCustomRewardIcon());
        var animation = rewardType.getCustomRewardIconAnimation ? rewardType.getCustomRewardIconAnimation(this.reward.options).before : "animation";
        icon.setAnimation(0, animation, true);
        return icon;
    },

    setUpIconSprite: function () {
        var rewardType = RewardTypes[this.reward.type];
        return new cc.Sprite(rewardType.getCustomRewardIcon());
    },

    createIcon: function () {
        var styles = cleverapps.styles.CustomRewardView.icon;

        var image = cleverapps.skins.getSlot("sidebarBg") || bundles.sidebar.frames.icon_bg;
        var bg = this.bg = cleverapps.UI.createScale9Sprite(image);
        this.addChild(bg);
        bg.setPositionRound(bg.width / 2, bg.height / 2);
        this.setContentSize(bg.getContentSize());

        var icon = this.icon = this.hasAnimation ? this.setUpIconSpine() : this.setUpIconSprite();
        icon.setPositionRound(styles.position);
        bg.addChild(icon);
    },

    createTooltip: function (text) {
        if (!RewardTypes[this.reward.type].getCustomRewardText) {
            return;
        }
        var styles = cleverapps.styles.CustomRewardView.tooltip;

        var tooltip = this.tooltip = new cc.Scale9Sprite(bundles.custom_reward.frames.tooltip_bubble);
        tooltip.setContentSize2(styles);
        tooltip.setPositionRound(styles);
        tooltip.setVisible(false);
        this.addChild(tooltip);

        var tooltipArrow = new cc.Sprite(bundles.custom_reward.frames.tooltip_arrow);
        tooltipArrow.setPositionRound(styles.arrow);
        tooltip.addChild(tooltipArrow);

        var tooltipText = this.tooltipText = cleverapps.UI.generateOnlyText(text, cleverapps.styles.FONTS.CUSTOM_REWARD_TOOLTIP_TEXT);
        tooltipText.fitTo(tooltip.width - styles.offset);
        tooltipText.setPositionRound(tooltip.width / 2, tooltip.height / 2);
        tooltip.addChild(tooltipText);
    },

    createAnimation: function () {
        if (!RewardTypes[this.reward.type].getCustomRewardAnimation) {
            return;
        }
        var styles = cleverapps.styles.CustomRewardView;

        var animation = this.rewardAnimation = new cleverapps.Spine(RewardTypes[this.reward.type].getCustomRewardAnimation());
        animation.setPositionRound(styles.animation);
        animation.setVisible(false);
        this.addChild(animation);
    },

    createProgress: function () {
        var rewardType = RewardTypes[this.reward.type];
        if (!rewardType.getCustomRewardProgress || !rewardType.getCustomRewardProgress()) {
            return;
        }
        var styles = cleverapps.styles.CustomRewardView;

        var animation = rewardType.getCustomRewardProgressAnimation ? rewardType.getCustomRewardProgressAnimation(this.reward.options).before : "animation";

        var progress = this.progress = new cleverapps.Spine(rewardType.getCustomRewardProgress());
        progress.setAnimation(0, animation, true);
        progress.setPositionRound(styles.progress);
        this.bg.addChild(progress);
    },

    collectAnimate: function () {
        var styles = cleverapps.styles.CustomRewardView;
        var rewardType = RewardTypes[this.reward.type];
        var animationDelay = this.index * this.getAnimationDuration();

        this.runAction(new cc.Sequence(
            new cc.DelayTime(animationDelay + 0.1),
            new cc.Show(),
            new cc.DelayTime(1.4),
            new cc.CallFunc(function () {
                if (rewardType.getCustomRewardCloseSound) {
                    cleverapps.audio.playSound(rewardType.getCustomRewardCloseSound());
                }
            }),
            new cc.MoveBy(0.5, styles.hideOffset, 0).easing(cc.easeInOut(1)),
            new cc.RemoveSelf()
        ));

        this.bg.runAction(new cc.Spawn(
            new cc.Sequence(
                new cc.DelayTime(animationDelay),
                new cc.MoveBy(0, styles.hideOffset, 0),
                new cc.DelayTime(0.2),
                new cc.CallFunc(function () {
                    if (rewardType.getCustomRewardOpenSound) {
                        cleverapps.audio.playSound(rewardType.getCustomRewardOpenSound());
                    }
                }),
                new cc.MoveBy(0.5, -styles.hideOffset, 0).easing(cc.easeBackOut(1))
            ),
            new cc.Sequence(
                new cc.DelayTime(animationDelay + 0.1),
                new cc.CallFunc(function () {
                    if (this.rewardAnimation) {
                        this.rewardAnimation.setVisible(true);
                        this.rewardAnimation.setAnimation(0, "animation", false);
                        this.rewardAnimation.setCompleteListenerRemove();
                    }
                    if (rewardType.getCustomRewardIconAnimation && this.icon) {
                        var iconAnimation = rewardType.getCustomRewardIconAnimation(this.reward.options);
                        this.icon.setAnimationAndIdleAfter(iconAnimation.during, iconAnimation.after);
                    }
                    if (rewardType.getCustomRewardIconAnimation && this.progress) {
                        var progressAnimation = rewardType.getCustomRewardProgressAnimation(this.reward.options);
                        this.progress.setAnimationAndIdleAfter(progressAnimation.during, progressAnimation.after);
                    }
                }.bind(this))
            )
        ));

        if (this.tooltip) {
            this.tooltip.setScale(0.2);
            this.tooltip.runAction(new cc.Sequence(
                new cc.DelayTime(animationDelay + 0.1),
                new cc.Show(),
                new cc.RotateBy(0, styles.tooltip.showUpRotation),
                new cc.Spawn(
                    new cc.Sequence(
                        new cc.MoveBy(0, styles.tooltip.xOffset, -styles.tooltip.yOffset),
                        new cc.MoveBy(0.3, -styles.tooltip.xOffset, styles.tooltip.yOffset)
                    ),
                    new cc.Sequence(
                        new cc.ScaleTo(0.4, 1.2),
                        new cc.ScaleTo(0.2, 1)
                    ),
                    new cc.Sequence(
                        new cc.RotateBy(0.3, -styles.tooltip.showUpRotation).easing(cc.easeOut(2)),
                        new cc.RotateBy(0.1, -styles.tooltip.afterShowUpRotation),
                        new cc.RotateBy(0.1, styles.tooltip.afterShowUpRotation)
                    )
                )
            ));
        }

        if (this.tooltipText) {
            this.tooltipText.setString(rewardType.getCustomRewardText(this.reward.getValue(), true));

            var scale = this.tooltipText.getScale();
            this.tooltipText.runAction(new cc.Sequence(
                new cc.DelayTime(1),
                new cc.CallFunc(function () {
                    if (rewardType.getCustomRewardSound) {
                        cleverapps.audio.playSound(rewardType.getCustomRewardSound());
                    }
                }),
                new cc.ScaleTo(0.15, scale * 1.3),
                new cc.CallFunc(function () {
                    this.tooltipText.setString(rewardType.getCustomRewardText(this.reward.getValue()));
                    this.tooltipText.fitTo(this.tooltip.width - styles.tooltip.offset);
                }.bind(this)),
                new cc.CallFunc(function () {
                    if (rewardType.getCustomRewardAfterUpdateSound) {
                        cleverapps.audio.playSound(rewardType.getCustomRewardAfterUpdateSound());
                    }
                }),
                new cc.ScaleTo(0.15, scale)
            ));
        }
    },

    getAnimationDuration: function () {
        return 1.5;
    },

    updateSize: function () {
        var styles = cleverapps.styles.CustomRewardView;

        this.setScale(cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? 0.8 : 1);

        var sceneSize = cleverapps.resolution.getSceneSize();
        this.setPositionRound(sceneSize.width - this.width - styles.padding, styles.y);
    }
});

cleverapps.styles.COLORS.CUSTOM_REWARD_TOOLTIP_COLOR = new cc.Color(148, 49, 19, 255);

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    CUSTOM_REWARD_TOOLTIP_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.CUSTOM_REWARD_TOOLTIP_COLOR
    }
});

cleverapps.styles.CustomRewardView = {
    y: 120,

    hideOffset: 500,
    padding: 10,

    tooltip: {
        arrow: {
            x: { align: "right", dx: 8 },
            y: { align: "bottom", dy: -8 }
        },

        x: { align: "left", dx: -80 },
        y: { align: "top", dy: 45 },

        width: 125,
        height: 75,
        offset: 20,
        showUpRotation: 150,
        afterShowUpRotation: 10,
        xOffset: 200,
        yOffset: 100
    },
    animation: {
        x: { align: "center" },
        y: { align: "center" }
    },
    progress: {
        x: { align: "left", dx: 0 },
        y: { align: "bottom", dy: -40 }
    },
    icon: {
        position: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 0 }
        }
    },
    text: {
        position: {
            x: { align: "center", dx: 0 },
            y: { align: "bottom", dy: -60 }
        }
    }
};