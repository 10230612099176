/**
 * Created by Vladislav on 25.09.2024.
 */

Placements.ENTRIES["canUpgradeHeroes"] = {
    type: Placements.FREE_FOCUS_MAIN,
    priority: -1,

    filter: function () {
        return match3.heroes.available() && cleverapps.forces.isShown(Forces.HEROES_AVAILABLE.id) && match3.heroes.canUpgradeSomebody();
    },

    action: function () {
        cleverapps.meta.display({
            focus: "CanUpgradeHeroWindow",
            action: function (f) {
                new CanUpgradeHeroWindow();
                cleverapps.meta.onceNoWindowsListener = f;
            }
        });
    },

    interval: "10 minutes",
    delayFromStart: "10 seconds"
};

Placements.ENTRIES["heroesTutorial"] = {
    type: Placements.FREE_FOCUS_OTHER,

    filter: function () {
        return cleverapps.environment.isHeroesScene()
            && (match3.heroes.getUnlockTutorialHero() || match3.heroes.getUpgradeTutorialHero());
    },

    action: function () {
        match3.heroes.displayHeroForce(match3.heroes.getUnlockTutorialHero() || match3.heroes.getUpgradeTutorialHero());
    }
};