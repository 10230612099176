/**
 * Created by Andrey Popov on 10.02.2021.
 */

var Puzzle15UnitView = cc.Sprite.extend({
    ctor: function (unit, styles) {
        this.unit = unit;
        this.styles = styles;

        if (unit.code) {
            this._super();
        } else {
            var pictureTile = bundles.puzzle15.frames["pictureTile" + styles.skinVariationPostfix] || bundles.puzzle15.frames.pictureTile;
            if (bundles.puzzle15.frames.pictureTile1 && bundles.puzzle15.frames.pictureTile2) {
                pictureTile = (+unit.type) % 2 === 0 ? bundles.puzzle15.frames.pictureTile1 : bundles.puzzle15.frames.pictureTile2;
            }

            var tile = bundles.puzzle15.frames["tile" + styles.skinVariationPostfix] || bundles.puzzle15.frames.tile;
            this._super(this.unit.hideableIcon ? pictureTile : tile);
        }

        var icon = this.icon = unit.code ? this.getIconImage(unit) : new cc.Sprite(bundles.puzzle15.frames["unit" + (unit.type + 1) + styles.skinVariationPostfix] || bundles.puzzle15.frames["unit" + (unit.type + 1)]);

        if (unit.code) {
            var scaleX = styles.cell.width / icon.width;
            var scaleY = styles.cell.height / icon.height;
            icon.setScale(Math.min(scaleX, scaleY));
        }

        this.addChild(icon);
        icon.setPositionRound(this.width / 2 + styles.icon.offset.x, this.height / 2 + styles.icon.offset.y);

        this.unit.on("unitMoved", this.move.bind(this), this);
        this.unit.on("unitDeleted", this.delete.bind(this), this);

        if (this.unit.hideableIcon) {
            this.updateIcon();
        }
    },

    move: function (path, slots, onUnitOccupiedSlot, callback) {
        this.replaceParentSamePlace(cleverapps.scenes.getMovingNode(this));
        var initialScale = this.getScale();
        this.setScale(0.8 * initialScale);
        var actions = [];
        setTimeout(function () {
            cleverapps.audio.playSound(this.styles.moveSound);
        }.bind(this), 50);
        path.forEach(function (step, index) {
            var curX = index === 0 ? this.unit.x : path[index - 1].x;
            var curY = index === 0 ? this.unit.y : path[index - 1].y;

            var duration = 0.15;
            if (index !== 0 && this.styles.moveSpeed > 0) {
                duration = cc.pDistance(path[index - 1], path[index]) / this.styles.moveSpeed * 50;
            }

            actions.push(new cc.MoveBy(duration, (step.x - curX) * (this.styles.cell.width + this.styles.margin), -(step.y - curY) * (this.styles.cell.height + this.styles.margin)));
        }.bind(this));

        actions.push(new cc.CallFunc(function () {
            var slot = slots[path[path.length - 1].x + path[path.length - 1].y * this.unit.map.width];
            if (slot.unitView) {
                slot.unitView = this;
            }
            this.replaceParentSamePlace(slot);
            if (onUnitOccupiedSlot) {
                onUnitOccupiedSlot();
            }

            if (this.unit.hideableIcon) {
                this.updateIcon(initialScale);
            }
        }.bind(this)));

        if (this.unit.upgradableIcon) {
            actions.push(new cc.Sequence(
                new cc.ScaleTo(0.1, 0),
                new cc.CallFunc(function () {
                    cleverapps.audio.playSound(bundles.ads_sfx_common.urls.character_selection2);
                    this.updateIcon();
                }.bind(this)),
                new cc.ScaleTo(0.5, initialScale).easing(cc.easeBackOut())
            ));
        } else {
            actions.push(new cc.Sequence(new cc.ScaleTo(0.1, initialScale * 1.05), new cc.ScaleTo(0.1, initialScale).easing(cc.easeBackOut())));
        }

        actions.push(new cc.CallFunc(function () {
            callback && callback();
        }));

        this.runAction(new cc.Sequence(actions));
    },

    select: function () {
        if (this.selection) {
            return;
        }

        this.replaceParentSamePlace(cleverapps.scenes.getMovingNode(this));

        var selectedCell = this.styles.selectedCell || {};

        var selection = this.selection = new cc.Sprite(selectedCell.image || bundles.puzzle15.frames.detach_square);
        this.addChild(selection);
        selection.setPositionRound(this.width / 2 + this.styles.icon.offset.x, this.height / 2 + this.styles.icon.offset.y);
        if (this.styles.icon.scale) {
            selection.setScale(this.styles.icon.scale);
        }

        setTimeout(function () {
            if (!selectedCell[Wysiwyg.NOT_SELECTED]) {
                cleverapps.audio.playSound(bundles.puzzle15.urls.cubes_effect);
            }
        }, 50);
    },

    unSelect: function () {
        if (this.selection) {
            this.selection.removeFromParent(true);
            this.selection = undefined;
        }
    },

    delete: function (silent) {
        if (silent) {
            this.removeFromParent();
            return;
        }

        this.runAction(new cc.Sequence(
            new cc.CallFunc(function () {
                if (this.unit.static) {
                    var star = new cc.Sprite(bundles.puzzle15.frames.unit_star);
                    star.setPosition(this.width / 2, this.height / 2);
                    star.setScale(0);
                    this.addChild(star);
                    star.replaceParentSamePlace(this.getParent());
                    star.runAction(new cc.Sequence(
                        new cc.ScaleTo(0.3, 1),
                        new cc.DelayTime(0.3),
                        new cc.ScaleTo(0.2, 0),
                        new cc.RemoveSelf()
                    ));
                } else {
                    var animation = new cleverapps.Spine(bundles.puzzle15.jsons.sparkle_json);
                    animation.setAnimation(0, "animation", false);
                    animation.setTimeScale(2);
                    animation.setPosition(this.width / 2, this.height / 2);
                    animation.setCompleteListenerRemove();
                    this.getParent().addChild(animation);
                }
            }.bind(this)),
            new cc.ScaleTo(0.2, 0),
            new cc.RemoveSelf()
        ));
    },

    updateIcon: function () {
        if (this.unit.code) {
            this.icon.removeFromParent();

            this.icon = this.getIconImage(this.unit);
            var scaleX = this.styles.cell.width / this.icon.width;
            var scaleY = this.styles.cell.height / this.icon.height;
            this.icon.setScale(Math.min(scaleX, scaleY));

            this.addChild(this.icon);
            this.icon.setPositionRound(this.width / 2 + this.styles.icon.offset.x, this.height / 2 + this.styles.icon.offset.y);
        } else {
            var postfix = this.unit.map.width === 4 ? "16_" : "";
            var frame = (this.unit.isPlaceMatchType() ? ("picture" + postfix) : "digit") + (this.unit.type + 1);
            this.icon.setSpriteFrame(bundles.puzzle15.frames[frame + this.styles.skinVariationPostfix] || bundles.puzzle15.frames[frame]);

            if (this.unit.isPlaceMatchType()) {
                this.icon.setPositionRound(this.width / 2 + this.styles.openPicturePart.offset.x, this.height / 2 + this.styles.openPicturePart.offset.y);
                this.icon.setScale(this.styles.openPicturePart.scale);
            }
        }
    },

    getIconImage: function (unit) {
        if (unit.code === "fakeСode") {
            return new cc.Sprite(bundles.puzzle15.frames["unit" + (unit.stage + 1)]);
        }

        var frame = "unit_" + unit.code + (unit.stage + 1);
        if (bundles.puzzle_merge_tool.frames[frame]) {
            return new cc.Sprite(bundles.puzzle_merge_tool.frames[frame]);
        } 
        return UnitView.getUnitImage(this.unit);
    }
});