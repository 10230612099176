/**
 * Created by slava on 14/8/17
 */

var AvailableMoveView = cc.Node.extend({
    ctor: function (availableMove) {
        this._super();

        availableMove.on("changeVisible", this.setVisible.bind(this), this);
    },

    setVisible: function (visible, move) {
        if (visible) {
            cleverapps.audio.playSound(bundles.game.urls.available_move_effect);
        }

        var cellA, cellB;

        if (!Game.currentGame || !Game.currentGame.field || !Game.currentGame.field.cells) {
            return;
        }

        if (cleverapps.styles.AvailableMoveView.animateShape) {
            var cells = [];
            if (move.shape) {
                cells = move.shape.cells;
            } else {
                cellA = move.start;
                cellB = Game.currentGame.field.cells[cellA.y + move.dir.row] && Game.currentGame.field.cells[cellA.y + move.dir.row][cellA.x + move.dir.col];
                if (!cellA || !cellB) {
                    return;
                }
                cells.push(cellA);
                cells.push(cellB);
            }

            if (visible) {
                cells.forEach(function (cell) {
                    (cell.innerCell || cell).onAvailableMoveListener(null, move);
                });
            } else {
                cells.forEach(function (cell) {
                    (cell.innerCell || cell).onBeforeMoveListener({ x: cell.x, y: cell.y }, { moveInterval: 0.1, easing: cc.easeOut(1) });
                });
            }
        } else {
            cellA = move.start;
            cellB = Game.currentGame.field.cells[cellA.y + move.dir.row] && Game.currentGame.field.cells[cellA.y + move.dir.row][cellA.x + move.dir.col];

            if (!cellA || !cellB) {
                return;
            }

            if (visible) {
                cellA.onAvailableMoveListener(move.dir, move);
                cellB.onAvailableMoveListener({ col: move.dir.col * (-1), row: move.dir.row * (-1) }, move);
            } else {
                cellA.onBeforeMoveListener({ x: cellA.x, y: cellA.y }, { moveInterval: 0.1, easing: cc.easeOut(1) });
                cellB.onBeforeMoveListener({ x: cellB.x, y: cellB.y }, { moveInterval: 0.1, easing: cc.easeOut(1) });
            }
        }
    }
});

var AVAILABLE_MOVE_ANIMATIONS = {
    default: function (dir) {
        var baseScale = this.scale;
        var baseX = this.x;
        var baseY = this.y;
        var deltaX = -dir.col * cleverapps.styles.BaseCellView.CELL_SIZE_PX * 0.16;
        var deltaY = dir.row * cleverapps.styles.BaseCellView.CELL_SIZE_PX * 0.16;

        if (dir.row === 0) {
            deltaY -= cleverapps.styles.BaseCellView.CELL_SIZE_PX * 0.075;
        }

        this.runAction(new cc.RepeatForever(
            new cc.Sequence(
                new cc.Repeat(new cc.Sequence(
                    new cc.Spawn(
                        new cc.ScaleTo(0.35, baseScale * 1.1),
                        new cc.MoveTo(0.35, baseX - deltaX, baseY - deltaY).easing(cc.easeIn(3.5))
                    ),
                    new cc.Spawn(
                        new cc.ScaleTo(0.35, baseScale),
                        new cc.MoveTo(0.35, baseX, baseY).easing(cc.easeOut(3.5))
                    )
                ), 3),
                new cc.DelayTime(3)
            )
        ));
    },

    homescapes: function (dir, move) {
        var baseScaleX = this.getScaleX();
        var baseScaleY = this.getScaleY();
        var baseX = this.x;
        var baseY = this.y;
        var baseRot = this.getRotation();
        if (this.cell.x === move.start.x && this.cell.y === move.start.y) {
            var targetX = move.dir.col * cleverapps.styles.BaseCellView.CELL_SIZE_PX * 0.2;
            var targetY = -move.dir.row * cleverapps.styles.BaseCellView.CELL_SIZE_PX * 0.2;
            this.setLocalZOrder(10);
            this.runAction(new cc.RepeatForever(
                new cc.Sequence(
                    new cc.Spawn(
                        new cc.Sequence(
                            new cc.MoveBy(0.45, targetX, targetY),
                            new cc.MoveBy(0.2, -0.3 * targetX, -0.3 * targetY),
                            new cc.MoveBy(0.2, 0.3 * targetX, 0.3 * targetY),
                            new cc.MoveTo(0.4, baseX, baseY).easing(cc.easeOut(1))
                        ),
                        new cc.Sequence(
                            new cc.ScaleTo(0.45, baseScaleX * 1.1, baseScaleY * 1.1),
                            new cc.DelayTime(0.4),
                            new cc.ScaleTo(0.4, baseScaleX, baseScaleY).easing(cc.easeOut(1))
                        )
                    ),
                    new cc.DelayTime(0.5)
                )
            ));
        } else {
            this.runAction(new cc.RepeatForever(
                new cc.Sequence(
                    new cc.DelayTime(0.1),
                    new cc.Spawn(
                        new cc.Sequence(
                            new cc.ScaleTo(0.35, baseScaleX * 1.1, baseScaleY * 1.1),
                            new cc.DelayTime(0.35),
                            new cc.ScaleTo(0.35, baseScaleX, baseScaleY)
                        ),
                        new cc.Sequence(
                            new cc.RotateTo(0.35, baseRot - 5).easing(cc.easeIn(2)),
                            new cc.RotateTo(0.175, baseRot + 5),
                            new cc.RotateTo(0.175, baseRot - 5),
                            new cc.RotateTo(0.35, baseRot)
                        )
                    ),
                    new cc.DelayTime(0.6)
                )
            ));
        }
    }
};

cleverapps.styles.AvailableMoveView = {
    animation: AVAILABLE_MOVE_ANIMATIONS.homescapes,
    animateShape: true
};