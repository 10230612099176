/**
 * Created by vlad on 21.11.18.
 */

var GoalCellsBlock = ElementsChooser.extend({
    ctor: function () {
        this._super({
            elements: EditorView.GoalCells,
            type: "goal_cell"
        });
    }
}); 

EditorView.GoalCells = [FishCell, FlowerCell, NutCell, CookieManCell, DragonCell, GingerHouseCell, MuffinGoalCell, CakeCell, CandleCell, WaffleCell, DogCell, LollipopCell,
    TruffleCell, ColorCookieManCell, FireflyJarCell, IceMakerCell, MouseCell, ColorContainerCell, ClockCell, FigureDragonCell, CoinCell];

EditorView.RiddlesGoalCells = [];

if (cleverapps.config.name === "riddles") {
    EditorView.GoalCells = EditorView.GoalCells.concat(EditorView.RiddlesGoalCells);
}

FlowerCell.EDITOR_CODES = ["f1", "f2", "f3"];
NutCell.EDITOR_CODES = ["N1"];
GingerHouseCell.EDITOR_CODES = ["H", "P^", "P&", "P*", "P#"];
MuffinGoalCell.EDITOR_CODES = ["I2", "I1", "L2", "L1"];
WaffleCell.EDITOR_CODES = ["S.", "Sa", "Sb", "Sc", "Sd", "Se", "Sw"];
TruffleCell.EDITOR_CODES = ["Q.", "Qa", "Qb", "Qc", "Qd", "Qe", "Qw"];
CandleCell.EDITOR_CODES = ["X-", "X+"];
LollipopCell.EDITOR_CODES = ["O.", "Oa", "Ob", "Oc", "Od", "Oe", "Ow"];
FireflyJarCell.EDITOR_CODES = ["v3"];
ColorCookieManCell.EDITOR_CODES = ["V.", "Va", "Vb", "Vc", "Vd", "Ve", "Vw"];
ColorContainerCell.EDITOR_CODES = ["gy"];
ClockCell.EDITOR_CODES = ["uabc"];
FigureDragonCell.EDITOR_CODES = ["ш0020", "ш1120", "ш1020", "ш2120", "ш2020", "ш3020", "ш3120"];