/**
 * Created by slava on 02.02.17.
 */

var GoalColorCellView = BaseCellView.extend({
    ctor: function (colorCell) {
        this._super();

        this.setContentSize(cleverapps.styles.BaseCellView.CELL_SIZE_PX, cleverapps.styles.BaseCellView.CELL_SIZE_PX);
        this.setAnchorPoint(0.5, 0.5);

        this.initialize(colorCell);

        this.setCascadeOpacityEnabled(true);
    },

    shakeAnimation: function () {
        if (!this.cell.findComponent(GoalCoefComponent) || this.inShakeNow || this.getScaleX() !== 1 || this.getScaleY() !== 1) {
            return;
        }

        this.inShakeNow = true;
        this.runAction(new cc.Sequence(
            new cc.ScaleTo(0.2, 1.25),
            new cc.ScaleTo(0.1, 1),
            new cc.CallFunc(function () {
                this.inShakeNow = false;
            }.bind(this))
        ));
    },

    initialize: function (colorCell) {
        this._super(colorCell);

        colorCell.onBeforeExplodeMouseListener = this.startExplodingViaMouse.bind(this);
        colorCell.onGameFinishListener = this.onGameFinish.bind(this);
    },

    smile: function () {
        if (this.cell.useCustomView() || !cleverapps.styles.GoalColorCellView.animation) {
            return;
        }

        if (this.smiling) {
            return;
        }

        this.smiling = true;

        var colorComponent = this.cell.findComponent(ColorComponent);
        var color = colorComponent.color.toUpperCase();
        this.animation = BaseAnimation.factory(bundles.color_cells.jsons[color + "_spine_json"]);
        this.animation.setPosition(this.width / 2, this.height / 2);
        this.addChild(this.animation, -1);
        this.animation.runAnimate("animation", this.stopSmile.bind(this), colorComponent.view.image);
    },

    startMoving: function (duration, destination, easing) {
        this.stopSmile();

        this._super.call(this, duration, destination, easing);
    },

    stopSmile: function () {
        if (this.smiling) {
            this.smiling = false;

            var colorComponent = this.cell.findComponent(ColorComponent);
            colorComponent.view.show();
            this.animation.finishAnimation();
        }
    },

    unuse: function () {
        this.cell.onBeforeExplodeMouseListener = function () {};
        this.cell.onGameFinishListener = function () {};

        this.stopSmile();

        this._super();
    },

    startExploding: function (goal, delta) {
        this.startExplodingViaCollect(goal, delta);
    }
});

cleverapps.styles.GoalColorCellView = {
    animation: true
};

// one color - one pool
// eslint-disable-next-line camelcase
var GoalColorCellView_A = GoalColorCellView.extend({});
// eslint-disable-next-line camelcase
var GoalColorCellView_B = GoalColorCellView.extend({});
// eslint-disable-next-line camelcase
var GoalColorCellView_C = GoalColorCellView.extend({});
// eslint-disable-next-line camelcase
var GoalColorCellView_D = GoalColorCellView.extend({});
// eslint-disable-next-line camelcase
var GoalColorCellView_E = GoalColorCellView.extend({});
// eslint-disable-next-line camelcase
var GoalColorCellView_W = GoalColorCellView.extend({});
