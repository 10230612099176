/**
 * https://firebase.google.com/docs/remote-config/get-started?platform=web
 *
 * Created by andrey on 12.08.2024
 */

var RemoteConfig = function (json) {
    cleverapps.EventEmitter.call(this);

    this.json = json;

    var remote = json.remote.replace("firebase://", "").split("/");
    var name = this.name = remote[0];
    var key = this.key = remote[1];

    if (!RemoteConfig.isAvailable(name)) {
        return;
    }

    var firebase = RemoteConfig.FirebaseConfigs[name];
    if (!firebase) {
        firebase = RemoteConfig.FirebaseConfigs[name] = new FirebaseRemoteConfig(name);
    }

    firebase.setDefaultValue(key, json);
    firebase.initializeThrottle();
    firebase.fetchAndActivateThrottle();

    this.firebase = firebase;

    firebase.on("update", this.update.bind(this));
    this.update();
};

RemoteConfig.prototype = Object.create(cleverapps.EventEmitter.prototype);
RemoteConfig.prototype.constructor = RemoteConfig;

RemoteConfig.prototype.getJson = function () {
    return this.json;
};

RemoteConfig.prototype.update = function () {
    var json = this.firebase.getValue(this.key);

    if (JSON.stringify(this.json) !== JSON.stringify(json)) {
        console.log("update " + this.key, json);

        this.json = json;

        this.trigger("update");
    }
};

RemoteConfig.prototype.initialize = function (callback) {
    if (!this.firebase || this.firebase.initialized) {
        callback();
    } else {
        this.firebase.initializeThrottle();
        this.firebase.once("initialize", callback);
    }
};

RemoteConfig.isAvailable = function (name) {
    var result = typeof firebase !== "undefined" && !cleverapps.flags.norest;

    var firebaseKey = cleverapps.config.firebase && cleverapps.config.firebase[name];
    if (result && !firebaseKey) {
        cleverapps.throwAsync("Firebase key missing - " + name);
        return;
    }

    return result;
};

RemoteConfig.FirebaseConfigs = {};
