/**
 * Created by spepa on 28.02.2024
 */

var RedeemCodeWindow = CleverappsWindow.extend({
    onWindowLoaded: function () {
        this._super({
            title: "RedeemCodeWindow.title",
            name: "redeemcodewindow",
            content: this.createContent(),
            styles: cleverapps.styles.RedeemCodeWindow.window,
            button: {
                text: "RedeemCodeWindow.button",
                width: cleverapps.styles.RedeemCodeWindow.button.width,
                onPressed: this.processInput.bind(this)
            }
        });
    },

    createContent: function () {
        var text = cleverapps.UI.generateOnlyText("RedeemCodeWindow.cta", cleverapps.styles.FONTS.WINDOW_TEXT);
        var input = this.createInput();

        return new cleverapps.Layout([text, input], {
            direction: cleverapps.UI.VERTICAL,
            margin: cleverapps.styles.RedeemCodeWindow.margin,
            padding: cleverapps.styles.RedeemCodeWindow.padding
        });
    },

    createInput: function () {
        var styles = cleverapps.styles.RedeemCodeWindow.input;
        var input = this.inputBox = new cc.EditBox(cc.size(styles), new cc.Scale9Sprite(bundles.windows.frames.editbox_bg_png), true);

        input.setInputMode(cc.EDITBOX_INPUT_MODE_SINGLELINE);
        input.setMaxLength(10);
        input.stayOnTop(true);
        input.setTouchEnabled();
        input.setFontColor(cleverapps.styles.FONTS.PROMO_INPUT.color);
        input.setFont(cleverapps.UI.getFontName(), cleverapps.styles.FONTS.PROMO_INPUT.size);

        return input;
    },

    processInput: function () {
        var code = this.inputBox.getString().toUpperCase().trim();
        var rewards = cleverapps.promoCodes.useCode(code);
        if (!rewards) {
            cleverapps.notification.create("RedeemCodeWindow.nocode");
            this.close();
            return;
        }

        new RewardWindow(rewards, { fireworks: true });
        this.close();
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    PROMO_INPUT: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE
    }
});

cleverapps.styles.RedeemCodeWindow = {
    margin: 20,

    padding: {
        x: 30,
        top: 0,
        bottom: 15
    },

    button: {
        width: 250
    },

    input: {
        width: 350,
        height: 70,
        x: { align: "center" },
        y: { align: "center" }
    },

    window: {
        padding: {
            bottom: 50
        }
    }
};