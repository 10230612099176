/**
 * Created by Andrey Popov on 2/08/21.
 */

var SoundPropertyEditor = function (property, initialValue, onChangeValueCallback) {
    this.property = property;
    this.onChangeValueCallback = onChangeValueCallback;
    this.value = cleverapps.override({
        folder: "",
        name: ""
    }, initialValue);

    var nameWithFolders = cleverapps.wysiwyg.googleDriveProvider.getAssetsInFolders(this.property.folders);
    nameWithFolders[Wysiwyg.NOT_SELECTED] = true;

    this.propertyEditors = [{
        name: property.name,
        items: Object.keys(nameWithFolders).concat([SoundPropertyEditor.WITHOUT_MUSIC]),
        getter: function () {
            return this.value.name;
        }.bind(this),
        setter: function (value) {
            this.value = {};
            if (value === Wysiwyg.NOT_SELECTED) {
                this.value.name = Wysiwyg.NOT_SELECTED;
            } else if (value === SoundPropertyEditor.WITHOUT_MUSIC) {
                this.value.name = SoundPropertyEditor.WITHOUT_MUSIC;
            } else {
                this.value = {
                    name: value,
                    folder: nameWithFolders[value]
                };
            }
        }.bind(this),
        getPreview: function (value) {
            if (value === Wysiwyg.NOT_SELECTED) {
                return;
            }

            var icon = new cc.Sprite(bundles.editor_controls.frames.sound_icon);

            cleverapps.UI.onClick(icon, function () {
                cleverapps.audio.unmute();

                cleverapps.audio.eject();
                cc.audioEngine.stopAllEffects();

                var url = cleverapps.wysiwyg.googleDriveProvider.path + nameWithFolders[value] + "/" + value;
                cc.loader.load(url, function () {
                    cleverapps.audio.playSound(url);
                });
            });
            cleverapps.UI.applyHover(icon);

            return icon;
        }
    }].map(function (propertyDescription) {
        return new PropertyEditor(propertyDescription, this);
    }.bind(this));
};

SoundPropertyEditor.prototype.editBoxEditingDidEnd = function (sender) {
    sender.customSetter(sender.getString());
    this.onChangeValueCallback(this.property.name, this.value);
};

SoundPropertyEditor.WITHOUT_MUSIC = "without_music";