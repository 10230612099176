/**
 * Created by iamso on 12.05.21
 */

var ScreenshotsPropertyEditor = function (property, initialValue, onChangeValueCallback) {
    this.property = property;
    this.onChangeValueCallback = onChangeValueCallback;
    this.value = cleverapps.override({
        frequency: 1,
        amount: 1,
        video: 1
    }, initialValue);

    this.propertyEditors = [{
        name: property.name,
        isBlock: true
    }, {
        name: "params",
        getters: [function () {
            return this.value.frequency;
        }.bind(this), function () {
            return this.value.amount;
        }.bind(this)],
        setters: [function (value) {
            this.value.frequency = parseFloat(value) || 1;
        }.bind(this), function (value) {
            this.value.amount = parseInt(value) || 1;
        }.bind(this)],
        subNames: ["freq", "amount"]
    }, {
        name: "filenamePrefix",
        type: "string",
        getter: function () {
            return this.value.filenamePrefix;
        }.bind(this),
        setter: function (value) {
            this.value.filenamePrefix = value;
        }.bind(this)
    }, {
        name: "video",
        items: ["only frames", "save"],
        getter: function () {
            return ["only frames", "save"][this.value.video];
        }.bind(this),
        setter: function (value) {
            this.value.video = ["only frames", "save"].indexOf(value);
        }.bind(this)
    }].map(function (propertyDescription) {
        return new PropertyEditor(propertyDescription, this);
    }.bind(this));
};

ScreenshotsPropertyEditor.prototype.editBoxEditingDidEnd = function (sender) {
    sender.customSetter(sender.getString());
    this.onChangeValueCallback(this.property.name, this.value);
};