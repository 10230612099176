/*
 * Created by Andrey Popov on 16.11.2023
 */

var SceneFilmEffect = cc.Node.extend({
    ctor: function (options) {
        this._super();

        options.scaleTimeout = options.scaleTimeout || 1;

        this.options = options;
        this.onShow = cleverapps.once(options.onShow);

        this.setLocalZOrder(BaseWindow.WINDOWS_ZORDER - 1);

        this.setContentSize2(cleverapps.resolution.getSceneSize());
        this.setAnchorPoint(0.5, 0.5);
        this.setPositionRound({ align: "center" }, { align: "center" });

        this.topLayer = new FilmEffectLayer(Object.assign({ top: true }, options));
        this.addChild(this.topLayer);

        this.bottomLayer = new FilmEffectLayer(options);
        this.addChild(this.bottomLayer);

        this.addControls();
        this.showAnimation();

        this.scheduleUpdate();
    },

    update: function (dt) {
        this._super(dt);

        var scene = cleverapps.scenes.getRunningScene();
        this.setScale(1 / scene.getScale());
        this.setPositionRound(this.parent.convertToNodeSpace(cc.p(scene.width / 2, scene.height / 2)));
    },
    
    addControls: function () {
        cc.eventManager.addListener({
            event: cc.EventListener.TOUCH_ONE_BY_ONE,
            swallowTouches: true,
            onTouchBegan: function () {
                return true;
            },

            onTouchEnded: function () {
                if (this.options.onClick) {
                    this.options.onClick();
                }
                return true;
            }.bind(this)
        }, this);

        cc.eventManager.addListener({
            event: cc.EventListener.MOUSE,
            onMouseScroll: function (event) {
                if (event && event.stopPropagation) {
                    event.stopPropagation();
                }
                return true;
            },
            onMouseMove: function (event) {
                if (event && event.stopPropagation) {
                    event.stopPropagation();
                }
                return true;
            }
        }, this);
    },

    showAnimation: function () {
        var scene = cleverapps.scenes.getRunningScene();

        var moveByX = this.options.moveBy && this.options.moveBy.x || 0;
        var moveByY = this.options.moveBy && this.options.moveBy.y || 0;

        var layerHeight = moveByY > 0 ? this.bottomLayer.height : this.topLayer.height;
        var maxMoveByY = layerHeight + scene.height * (this.options.scale - 1) / 2;

        var targetPosition = scene.getPosition();

        if (Math.abs(moveByY) > maxMoveByY) {
            moveByY = cleverapps.sign(moveByY) * maxMoveByY;
        }

        targetPosition.x += moveByX;
        targetPosition.y += moveByY;

        this.topLayer.showAnimation();
        this.bottomLayer.showAnimation();

        this.stopCurrentAction();
        this.currentAction = scene.runAction(new cc.Sequence(
            new cc.DelayTime(this.options.layerTimeout),
            new cc.Spawn(
                new cc.MoveTo(this.options.scaleTimeout, targetPosition),
                new cc.ScaleTo(this.options.scaleTimeout, this.options.scale)
            ),
            new cc.CallFunc(this.onShow.bind(this))
        ));
    },

    hideAnimation: function (timeout, callback) {
        timeout = timeout || 0;
        callback = callback || function () {};

        var scene = cleverapps.scenes.getRunningScene();
        this.stopCurrentAction();
        var targetY = cleverapps.resolution.getBgSize().height - scene.height;

        if (!timeout) {
            scene.setPositionRound(0, targetY);
            scene.setScale(1);
            callback();
            if (this.pendingCallback) {
                this.pendingCallback();
            }
            return;
        }

        this.pendingCallback = callback;

        this.runAction(new cc.Sequence(
            new cc.DelayTime(timeout),
            new cc.CallFunc(callback),
            new cc.RemoveSelf()
        ));

        this.currentAction = scene.runAction(new cc.Spawn(
            new cc.MoveTo(timeout, cc.p(0, targetY)),
            new cc.ScaleTo(timeout, 1)
        ));

        this.topLayer.hideAnimation(timeout);
        this.bottomLayer.hideAnimation(timeout);
    },

    stopCurrentAction: function () {
        if (this.currentAction && !this.currentAction.isDone()) {
            cleverapps.scenes.getRunningScene().stopAction(this.currentAction);
            delete this.currentAction;
        }
    },

    completeAnimationOnResize: function () {
        this.onShow();
        this.removeFromParent();
    }
});