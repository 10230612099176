/**
 * Created by ivan on 06.02.18.
 */

var FireworkCellView = BaseCellView.extend({
    ctor: function (fireworkCell) {
        this._super();

        this.setContentSize(cleverapps.styles.BaseCellView.CELL_SIZE_PX, cleverapps.styles.BaseCellView.CELL_SIZE_PX);
        this.setAnchorPoint(0.5, 0.5);

        this.initialize(fireworkCell);

        this.setCascadeOpacityEnabled(true);
    },

    initialize: function (fireworkCell) {
        this._super(fireworkCell);

        if (fireworkCell.x === undefined) {
            return;
        }
        fireworkCell.onOutburstListener = this.outburst.bind(this);

        if (!this.animation) {
            this.animation = new cleverapps.Spine(bundles.firework_cell.jsons.firework_animation_json);
            this.animation.setPosition(this.width / 2, this.height / 2);
            this.addChild(this.animation);
        }

        var colorComponent = fireworkCell.findComponent(ColorComponent);
        colorComponent.view.show();

        this.animation.visible = false;
        this.animation.setSkin(this.getSkin(fireworkCell));
        this.animation.setStartVisibleListener(function () {
            colorComponent.view.hide();
        });

        this.originalZOrder = this.getLocalZOrder();
    },

    unuse: function () {
        this.cell.onOutburstListener = function () {};

        this._super();
    },

    getSkin: function (fireworkCell) {
        var colors = {
            "a": "blue",
            "b": "green",
            "c": "purple",
            "d": "red",
            "e": "yellow",
            "w": "white"
        };

        return "bombcup_" + colors[fireworkCell.findComponent(ColorComponent).color];
    },

    collectToGoal: function () {
        if (!Game.currentGame) {
            return;
        }

        var fireworkCell = this.cell;
        var goal = Game.currentGame.goals.findTargetFor(fireworkCell.getGoalId());
        if (goal) {
            var colorComponent = fireworkCell.findComponent(ColorComponent);
            var goalImage = new cc.Sprite(colorComponent.view.getImage(fireworkCell));
            Game.currentGame.field.onGetView().addChild(goalImage);
            goalImage.setPositionRound(BaseCellView.alignInTheGrid(fireworkCell.x, fireworkCell.y));
            goalImage.setScale(0);

            goalImage.runAction(new cc.Sequence(
                new cc.DelayTime(1.0),
                new cc.ScaleTo(0.3, 1.2),
                new cc.CallFunc(function () {
                    goal.collect(1, goalImage);
                })
            ));
        }
    },
    
    outburst: function (cells) {
        this.collectToGoal();
        if (cells.length === 0) {
            return;
        }

        var styles = cleverapps.styles.FireworkCellView;
        cells.forEach(function (cell, index) {
            var start = this.getPosition();
            var target = BaseCellView.alignInTheGrid(cell.x, cell.y);
            var first = cc.p(start.x, Math.max(start.y, target.y) + styles.bomb.flyUp);
            var second = cc.p(target.x, first.y);

            var bomb = new cc.Sprite(bundles.game.frames.bomb_png);
            bomb.opacity = 0;
            this.parent.addChild(bomb);
    
            var additionalDX = 0;
            if (styles.bomb.startDX) {
                additionalDX += styles.bomb.startDX;
            }
            var additionalDY = 0;
            if (styles.bomb.startDY) {
                additionalDY += styles.bomb.startDY;
            }
            
            bomb.setPosition(start.x + additionalDX, start.y + additionalDY);

            var bombAnimation = new cleverapps.Spine(bundles.game.jsons.bomb_animation_json);
            bombAnimation.setPosition(bomb.width / 2, bomb.height / 2);
            bomb.addChild(bombAnimation);
            bomb.setLocalZOrder(21);

            this.setLocalZOrder(1);

            this.runAction(new cc.Sequence(
                new cc.DelayTime(FireworkCell.HURT_DELAY + index * FireworkCell.ANIMATION_DURATION),
                new cc.CallFunc(function () {
                    this.animation.setAnimation(0, "animation", false);
                    if (index === cells.length - 1) {
                        this.animation.setCompleteListener(function () {
                            this.setLocalZOrder(this.originalZOrder);
                        }.bind(this));
                    }

                    var flyDuration = this.cell.getFlyDuration(cell);
                    var rotateAngle = cell.x < this.cell.x ? -180 : 180;

                    cleverapps.audio.playSound(bundles.firework_cell.urls.firework_explode);

                    bomb.runAction(new cc.Sequence(
                        new cc.DelayTime(FireworkCell.ANIMATION_DURATION),
                        new cc.FadeIn(0),
                        new cc.Spawn(
                            new cc.BezierTo(flyDuration, [first, second, target]).easing(cc.easeIn(1)),
                            new cc.RotateBy(flyDuration, rotateAngle)
                        ),
                        new cc.FadeOut(0)
                    ));

                    bombAnimation.runAction(new cc.Sequence(
                        new cc.DelayTime(FireworkCell.ANIMATION_DURATION + flyDuration),
                        new cc.CallFunc(function () {
                            cleverapps.audio.playSound(bundles.game.urls.bomb_by_left_moves_explosion_effect);
                            bombAnimation.setAnimation(0, "animation", false);
                            bombAnimation.setCompleteListener(function () {
                                bomb.runAction(new cc.RemoveSelf());
                            });
                        })
                    ));
                }.bind(this))
            ));
        }.bind(this));
    },

    createAnimation: function (params) {
        params = params || {};
        if (!params.silent) {
            this.setScale(0);
            this.runAction(new cc.ScaleTo(0.4, 1).easing(cc.easeBackOut()));
        }

        var light = new cleverapps.Spine(bundles.game.jsons.story_light_animation_json);
        light.setScale(2);
        light.setAnimation(0, "animation", true);
        light.setPositionRound(this.width / 2 + cleverapps.styles.FireworkCellView.light.x, this.height / 2 + cleverapps.styles.FireworkCellView.light.y);
        light.setLocalZOrder(-1);
        this.addChild(light);

        var realZOrder = this.getLocalZOrder();
        if (!realZOrder) {
            realZOrder = 0;
        }
        this.setLocalZOrder(3);
        light.runAction(new cc.Sequence(
            new cc.DelayTime(1.5),
            new cc.ScaleTo(0.2, 0),
            new cc.CallFunc(function () {
                this.setLocalZOrder(realZOrder);
            }.bind(this)),
            new cc.RemoveSelf()
        ));
    }
});

cleverapps.styles.FireworkCellView = {
    bomb: {
        flyUp: 150
    },

    light: {
        x: -6,
        y: -14
    }
};