/**
 * Created by Aleksandr on 28.12.2022
 */

var SolitaireOrangery = {
    init: function () {
        var regularCards = Card.SUITS.map(function (suit) {
            return Card.VALUES.map(function (value) {
                return {
                    cardOptions: {
                        value: value + suit
                    }
                };
            });
        });
        regularCards.unshift([{
            cardOptions: {
                random: true
            }
        }]);

        var fillHandCards = [];
        for (var index = 1; index <= 9; index++) {
            fillHandCards.push({
                CardCtor: FillHandCard,
                cardOptions: {
                    feature: "fillhand",
                    amount: index
                }
            });
        }

        this.tabs = [
            {
                iconCard: new Card({
                    value: "qs"
                }),
                rows: regularCards
            }, {
                iconCard: new Wildcard(),
                rows: [[{
                    CardCtor: Wildcard
                }, {
                    CardCtor: MouseCard
                }, {
                    CardCtor: CheeseCard
                }, {
                    CardCtor: StreakCard
                }, {
                    CardCtor: SafeCard,
                    cardOptions: {
                        requiredValue: 0,
                        requiredSuit: Card.SUITS[0]
                    }
                }, {
                    CardCtor: SharkCard
                }, {
                    CardCtor: CurtainCard,
                    cardOptions: {
                        sleep: 5,
                        active: 2,
                        magicCurtain: true,
                        sleeping: true
                    }
                }, {
                    CardCtor: IncDecCard,
                    cardOptions: {
                        dir: 1
                    }
                }, {
                    CardCtor: IncDecCard,
                    cardOptions: {
                        dir: -1
                    }
                }], fillHandCards,
                Card.VALUES.concat([""]).map(function (value) {
                    return {
                        CardCtor: WeakJokerCard,
                        cardOptions: {
                            value: value || undefined
                        }
                    };
                }),
                ["j", "q", "k"].map(function (value) {
                    return {
                        CardCtor: DoubleCard,
                        cardOptions: {
                            value: value || undefined
                        }
                    };
                }).concat(Card.SUITS.map(function (value) {
                    return {
                        CardCtor: SuitCard,
                        cardOptions: {
                            value: "x" + value
                        }
                    };
                }))
                ].map(function (row) {
                    return row.map(function (card) {
                        card.cardType = true;
                        return card;
                    });
                })
            }, {
                iconCard: new Card({
                    components: [{
                        name: "ice"
                    }]
                }),
                rows: [[{
                    cardOptions: {
                        marks: ["magnet"]
                    },
                    component: true
                }, {
                    cardOptions: {
                        marks: ["key"]
                    },
                    component: true
                }], [{
                    cardOptions: {
                        components: [{
                            name: "flags"
                        }],
                        componentCtor: FlagsOnCard
                    },
                    component: true
                }, {
                    cardOptions: {
                        components: [{
                            name: "flags",
                            lives: 2
                        }],
                        componentCtor: FlagsOnCard
                    },
                    component: true
                }, {
                    cardOptions: {
                        components: [{
                            name: "chain"
                        }],
                        componentCtor: ChainOnCard
                    },
                    component: true
                }, {
                    cardOptions: {
                        components: [{
                            name: "ice"
                        }],
                        componentCtor: IceOnCard
                    },
                    component: true
                }, {
                    cardOptions: {
                        components: [{
                            name: "bomb",
                            counter: 5
                        }],
                        componentCtor: BombOnCard
                    },
                    component: true
                }

                ]]
            }
        ].map(function (info) {
            var card = info.iconCard;
            card.disableEditorControls = true;
            card.open && !(card instanceof FillHandCard) && card.open();

            var view = CardView.createCardView(card);
            view.setScale(0.4);
            info.rows.forEach(function (row) {
                row.forEach(function (rowCard) {
                    var options = rowCard.cardOptions || {};
                    var card = new (rowCard.CardCtor || Card)(options);
                    card.disableEditorControls = true;
                    if (options.random) {
                        card.value = undefined;
                    }
                    card.open();

                    var cardView = CardView.createCardView(card);
                    cardView.children.forEach(function (child) {
                        if (child.animation) {
                            child.animation.setScale(0.6);
                        } else if (child.spine) {
                            child.spine.setScale(0.6);
                        } else {
                            child.setScale(0.6);
                        }
                    });

                    rowCard.card = card;
                    rowCard.itemIcon = cardView;
                    rowCard.minimizedIcon = CardView.createCardView(card);
                });
            });

            return {
                node: view,
                rows: info.rows
            };
        });

        this.maximized = true;
    },

    getItemCode: function (item) {
        return item.card.feature || item.card.getSkin();
    }
};
