/**
 * Created by slava on 02.02.17.
 */

var LiveLineComponentView = cc.Node.extend({
    ctor: function (component, cellView) {
        this._super();

        this.component = component;
        this.cellView = cellView;

        this.setContentSize(cellView.getContentSize());

        if (!this.component.cell.isForGoal) {
            this.reverseLive = true;

            this.generateLive(this.component.cell);

            this.component.cell.onChangeLivesListener = this.changeLives.bind(this);
        }
    },

    generateLive: function (cell) {
        var styles = cleverapps.styles.LiveLineComponentView;
        this.startLives = cell.lives;

        var progressBar = this.bar = new ScaledProgressBar({
            background: bundles.progress_bar.frames.bg_celllife,
            progress: bundles.progress_bar.frames.bar_celllife
        });
        progressBar.setLength(styles.bar.width);

        var lifeBgCircle = new cc.Sprite(bundles.live_line_cell.frames.cell_life_bg);
        lifeBgCircle.setAnchorPoint(0.5, 0.5);
        lifeBgCircle.setLocalZOrder(3);
        var scale = connector.info.isMobile ? styles.circle.mobileScale : styles.circle.scale;
        if (scale) {
            lifeBgCircle.setScale(styles.circle.scale);
        }

        this.livesTxt = new cleverapps.UI.ImageFont("" + this.startLives, cleverapps.styles.FONTS.CELL_LIVES);
        this.livesTxt.baseScale = this.livesTxt.scale;
        this.livesTxt.setPosition(lifeBgCircle.width / 2, lifeBgCircle.height / 2);
        lifeBgCircle.addChild(this.livesTxt);
        this.updateLivesTextSize(cell);

        var x = this.width / 2;
        var y = connector.info.isMobile ? styles.lifeBg.mobileDy : styles.lifeBg.dy;

        if (this.cellView instanceof BlanketCellView) {
            var liveLineStyles = connector.info.isMobile ? styles.BlanketCellView.mobile : styles.BlanketCellView;
            x = this.width / 2 + liveLineStyles.dx;
            y = this.height / 2 - this.cellView.item.height / 1.2 + liveLineStyles.dy;
        }

        this.liveLine = new cleverapps.Layout([lifeBgCircle, progressBar], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: connector.info.isMobile ? styles.lives.circleGap : 0
        });
        this.liveLine.setPositionRound(x, y);
        if (styles.lives.scale) {
            this.liveLine.setScale(styles.lives.scale);
        }
        this.addChild(this.liveLine);
    },

    updateLivesTextSize: function (cell) {
        if (cell.lives < 10) {
            this.livesTxt.setScale(this.livesTxt.baseScale * 0.7);
        } else {
            this.livesTxt.setScale(this.livesTxt.baseScale * 0.55);
        }
    },

    changeLives: function () {
        var lives = this.reverseLive ? (this.startLives - this.component.cell.lives) : this.component.cell.lives;
        this.bar.setGoal(this.startLives);
        this.bar.setPercentage(lives);

        this.livesTxt.setString("" + this.component.cell.lives);
        this.updateLivesTextSize(this.component.cell);
    },

    onDestruction: function () {
        this.component.cell.onChangeLivesListener = function () {};
    },

    startExploding: function () {
        if (this.liveLine) {
            this.liveLine.removeFromParent(true);
            this.liveLine = undefined;
        }

        var callback = Game.currentGame.goals.hasType(this.component.cell.getGoalId())
            ? BaseCellView.prototype.startExplodingViaCollect : BaseCellView.prototype.startExploding;
        callback.apply(this.cellView, arguments);
        return true;
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    CELL_LIVES: {
        size: 40,
        name: "nostroke",
        color: cleverapps.styles.COLORS.COLOR_WINDOW_TEXT
    }
});

cleverapps.styles.LiveLineComponentView = {
    lives: {
        scale: 1.3,
        circleGap: 4
    },

    lifeBg: {
        dy: 30,
        mobileDy: 40
    },

    circle: {
        dx: -17,
        dy: 2,
        scale: 1.12,
        mobileScale: 1.5
    },

    bar: {
        width: 110,
        height: 10
    },

    BlanketCellView: {
        dx: 2,
        dy: 16,

        mobile: {
            dx: 10,
            dy: 20
        }
    }
};