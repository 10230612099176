/**
 * Created by andrey on 29.05.17.
 */
var Editor = function () {
    EditorBase.call(this);

    this.loader = new Loader();
    this.marks = 0;

    this.goals = [];

    for (var i = 0; i < Editor.MAX_GOALS; i++) {
        var goal = new EditorGoal(this);
        goal.goalChanged = function () {
            this.processColorGoals();

            this.save();
        }.bind(this);
        this.goals.push(goal);
    }

    this.onBackgroundAdded = function () {};
    this.onTileChanged = function () {};
    this.onCellChanged = function () {};
    this.onChangeColors = function () {};
    this.onChangeMarkCount = function () {};
    this.onLoadSetMoves = function () {};
    this.onLoadDangerDuration = function () {};
};

Editor.prototype = Object.create(EditorBase.prototype);
Editor.prototype.constructor = Editor;

Editor.BUNDLES = [];

Editor.prototype.getSaveData = function () {
    var data = EditorBase.prototype.getSaveData.call(this);

    data.dangerDuration = cleverapps.clone(this.dangerDuration, true);
    data.moves = cleverapps.clone(this.moves, true);
    data.colors = cleverapps.clone(this.colors, true);
    data.colors.sort(function (a, b) {
        if (a.toLowerCase() < b.toLowerCase()) {
            return -1;
        }
        return 1;
    });

    if (Object.keys(this.cellSkins).length !== 0) {
        data.cellSkins = cleverapps.clone(this.cellSkins, true);
    }

    data.field = this.loader.save(this.cells);
    data.floor = this.loader.save(this.floor);

    delete data.barriers;
    if (this.barriers && !this.barriers.empty()) {
        data.barriers = this.barriers.save();
    }

    data.goals = [];
    for (var i = 0; i < Editor.MAX_GOALS; i++) {
        if (this.goals[i].type) {
            this.goals[i].setAutoTarget();
            data.goals.push(this.goals[i].toJson(this.cells));
        }
    }

    if (this.firstCells) {
        data.firstCells = this.firstCells;
    }
    if (this.tutorial) {
        data.tutorial = this.tutorial;
    }

    return data;
};

Editor.prototype._load = function (levelContent) {
    this.setDangerDuration(levelContent.dangerDuration || 0);
    this.setMoves(levelContent.moves);

    this.cellSkins = levelContent.cellSkins || {};
    if (levelContent.tutorial) {
        this.tutorial = levelContent.tutorial;
    }
    if (levelContent.firstCells) {
        this.firstCells = levelContent.firstCells;
    }

    this.floor = [];
    this.cells = [];
    Field.SIZE = levelContent.field.length;
    this.onChangeFieldSize(Field.SIZE);

    for (var i = 0; i < Field.SIZE; i++) {
        this.addRow();
    }
    Game.currentGame.field.floor = this.floor;
    Game.currentGame.field.cells = this.cells;

    this.loader.load(levelContent.field, function (cell) {
        this.changeCell(cell);
    }.bind(this));

    this.loader.load(levelContent.floor, function (tile) {
        this.changeTile(tile);
    }.bind(this));

    this.barriers = new Barriers();
    this.barriers.load(levelContent.barriers);
    this.onUpdateBarriersView();

    var tag = levelContent.tag;
    this.validCountGoals = Editor.VALID_COUNT_GOALS[tag] || Editor.VALID_COUNT_GOALS["default"];

    for (i = 0; i < Editor.MAX_GOALS; i++) {
        if (i < levelContent.goals.length) {
            this.goals[i].load(levelContent.goals[i]);
        } else {
            this.goals[i].onChangeGoal();
        }
        this.goals[i].setAutoTarget(this.cells, this.floor);
    }

    this.setColors(levelContent.colors);

    this.countMarkCells();
    this.onLoadSetMoves();
    this.onLoadDangerDuration();
};

Editor.prototype.getGoalsCount = function () {
    return this.goals.filter(function (goal) {
        return goal.type;
    }).length;
};

Editor.prototype.checkValidGoalsCount = function () {
    return this.validCountGoals > this.getGoalsCount();
};

Editor.prototype.existGoal = function (type) {
    return this.goals.map(function (goal) {
        return goal.type;
    }).indexOf(type) !== -1;
};

Editor.prototype.processColorGoals = function () {
    for (var i = 0; i < Field.SIZE; i++) {
        for (var j = 0; j < Field.SIZE; j++) {
            if (this.cells[i][j]) {
                var newCell = undefined, colorComponent = undefined, markComponent = undefined;
                var oldColorComponent = this.cells[i][j].findComponent(ColorComponent);
                var oldMarkComponent = this.cells[i][j].findComponent(MarkComponent);

                if (this.cells[i][j].constructor === BaseCell && this.cells[i][j].findComponent(GoalCoefComponent) && !this.existGoal(oldColorComponent.color)) {
                    newCell = new BaseCell(j, i, { color: "" });
                    newCell.addComponent(MarkComponent);
                }
                if (this.cells[i][j].constructor === BaseCell && oldColorComponent && this.existGoal(oldColorComponent.color)) {
                    newCell = new BaseCell(j, i, { color: "", coef: true });
                    newCell.addComponent(MarkComponent);
                }
                if (newCell) {
                    colorComponent = newCell.findComponent(ColorComponent);
                    colorComponent.setColor(oldColorComponent.color);

                    markComponent = newCell.findComponent(MarkComponent);
                    markComponent.mark = oldMarkComponent.mark;

                    this.changeCell(newCell);
                }

                var decorator;
                for (var it = this.cells[i][j]; it; it = it.innerCell) {
                    if (it.innerCell) {
                        decorator = it;
                    }
                }

                if (decorator) {
                    newCell = undefined;
                    oldColorComponent = decorator.innerCell.findComponent(ColorComponent);
                    oldMarkComponent = decorator.innerCell.findComponent(MarkComponent);

                    if (decorator.innerCell.constructor === BaseCell && decorator.innerCell.findComponent(GoalCoefComponent) && !this.existGoal(oldColorComponent.color)) {
                        newCell = new BaseCell(j, i, { color: "" });
                        newCell.addComponent(MarkComponent);
                    }
                    if (decorator.innerCell.constructor === BaseCell && decorator.innerCell.findComponent(ColorComponent) && this.existGoal(oldColorComponent.color)) {
                        newCell = new BaseCell(j, i, { color: "", coef: true });
                        newCell.addComponent(MarkComponent);
                    }
                    if (newCell) {
                        colorComponent = newCell.findComponent(ColorComponent);
                        colorComponent.setColor(oldColorComponent.color);

                        markComponent = newCell.findComponent(MarkComponent);
                        markComponent.mark = oldMarkComponent.mark;

                        decorator.setInnerCell(newCell);
                        if (!this.cells[i][j].hideInnerCell) {
                            this.changeCell(this.cells[i][j]);
                        }
                    }
                }
            }
        }
    }

    for (i = 0; i < this.colors.length; i++) {
        var color = this.colors[i];
        if (this.colors.indexOf(color.toUpperCase()) >= 0) {
            if (!this.existGoal(color.toLowerCase())) {
                this.colors.splice(i, 1);
                this.colors.push(color.toLowerCase());
                i--;
            }
        } else if (this.existGoal(color.toLowerCase())) {
            this.colors.splice(i, 1);
            this.colors.push(color.toUpperCase());
            i--;
        }
    }

    this.onChangeColors(this.colors);
};

Editor.prototype.getColorWithRegister = function (color) {
    if (this.existGoal(color)) {
        return color.toUpperCase();
    }
    return color;
};

Editor.prototype.clear = function () {
    if (!this.prepareEdit()) {
        return;
    }
    Game.currentGame.HEADS = undefined;

    for (var i = 0; i < Field.SIZE; i++) {
        for (var j = 0; j < Field.SIZE; j++) {
            this.deleteCell(j, i);
            var cell = this.loader.loadNext(i, j, [Loader.WILDCARD]);
            this.changeCell(cell);
        }
    }
    for (i = 0; i < Editor.MAX_GOALS; i++) {
        this.goals[i].setTarget(10);
        this.goals[i].setType(false);
    }

    this.barriers = new Barriers();
    this.barriers.load();
    this.onUpdateBarriersView();

    this.save();
};

Editor.prototype.addRow = function () {
    this.floor.push([]);
    this.cells.push([]);
    for (var j = 0; j < Field.SIZE; j++) {
        this.floor[this.floor.length - 1].push(undefined);
        this.cells[this.cells.length - 1].push(undefined);
    }
};

Editor.prototype.changeFieldSize = function (delta) {
    if (!this.prepareEdit()) {
        return;
    }
    if (Field.SIZE + delta < 3 || Field.SIZE + delta > 9) {
        return;
    }
    if (delta === 1) {
        Field.SIZE++;
        this.addRow();
        for (var i = 0; i < Field.SIZE; i++) {
            var cell = this.loader.loadNext(i, Field.SIZE - 1, [Loader.WILDCARD]);
            this.changeCell(cell);
            cell = this.loader.loadNext(Field.SIZE - 1, i, [Loader.WILDCARD]);
            this.changeCell(cell);
        }
    } else {
        var isBlanketCellDeleted = false;
        for (i = 0; i < Field.SIZE; i++) {
            if ((this.cells[i][Field.SIZE - 1] instanceof BlanketCell)
                || (this.cells[Field.SIZE - 1][i] instanceof BlanketCell)) {
                isBlanketCellDeleted = true;
                break;
            }
        }
        if (isBlanketCellDeleted) {
            return;
        }
        
        for (i = 0; i < Field.SIZE; i++) {
            this.deleteCell(i, Field.SIZE - 1);
            this.deleteCell(Field.SIZE - 1, i);
            this.cells[i].pop();
            this.floor[i].pop();
        }
        this.cells.pop();
        this.floor.pop();
        Field.SIZE--;
    }

    if (this.barriers) {
        var savedBarriers = this.barriers.save();
        this.barriers = new Barriers();

        if (this.barriers.isDownBarrier) {
            savedBarriers[0] = savedBarriers[0].filter(function (coords) { // process "down barriers" array
                return coords[0] < this.barriers.isDownBarrier.length
                       && coords[1] < this.barriers.isDownBarrier[0].length; // new sizes are used there
            }.bind(this));
        }
        if (this.barriers.isRightBarrier) {
            savedBarriers[1] = savedBarriers[1].filter(function (coords) { // process "right barriers" array
                return coords[0] < this.barriers.isRightBarrier.length
                       && coords[1] < this.barriers.isRightBarrier[0].length; // new sizes are used there
            }.bind(this));
        }

        this.barriers.load(savedBarriers);
        this.onUpdateBarriersView();
    }

    this.onChangeFieldSize(Field.SIZE);
    this.save();
};

Editor.prototype.setDangerDuration = function (duration) {
    this.dangerDuration = duration;

    this.save();
};

Editor.prototype.setMoves = function (moves) {
    this.moves = moves;

    this.save();
};

Editor.prototype.setColors = function (colors) {
    this.colors = colors;
    console.log("SET colors: ", colors);
    this.onChangeColors(colors);
    this.save();
};

Editor.prototype.setMarks = function (marks) {
    if (marks < 0 || marks > 81) {
        return;
    }
    this.marks = marks;
    this.onChangeMarkCount(marks);
};

Editor.prototype.setCellSkin = function (code, skin, updatedCell) {
    if (updatedCell.getSkinBundles()[0] === skin) {
        delete this.cellSkins[code];
    } else {
        this.cellSkins[code] = skin;
    }
    this.onCellSkinChanged();

    var needUpdate = function (cell) {
        if (cell && cell.visible) {
            var markComponent = cell.findComponent(MarkComponent);
            if ((updatedCell instanceof cell.constructor || cell instanceof updatedCell.constructor) || (markComponent && markComponent.mark && markComponent.mark.constructor === updatedCell.constructor)) {
                return true;
            }
            if (cell.innerCell) {
                return needUpdate(cell.innerCell);
            }
        }
        return false;
    };

    this.cells.forEach(function (row) {
        row.forEach(function (cell) {
            if (needUpdate(cell)) {
                this.onDeleteCell(cell);
                this.onCellChanged(cell);
            }
        }, this);
    }, this);

    this.floor.forEach(function (row) {
        row.forEach(function (tile) {
            if (tile && (updatedCell instanceof tile.constructor || tile instanceof updatedCell.constructor)) {
                this.onDeleteTile(tile);
                this.onTileChanged(tile);
            }
        }, this);
    }, this);

    this.save();
};

Editor.prototype.cellCanBeSetUp = function (x, y, cell) {
    var bigComponent = cell.findComponent(BigComponent);

    if (cell instanceof BlanketCell) {
        for (var dy = 0; dy < bigComponent.rows && y + dy < Field.SIZE; dy++) {
            for (var dx = 0; dx < bigComponent.cols && x + dx < Field.SIZE; dx++) {
                if (this.cells[y + dy][x + dx] === undefined || (this.floor && this.floor[y + dy][x + dx] instanceof TransporterTile)) {
                    return false;
                }
            }
        } // can't put undefined cells and transporter tile under blanket
        
        // forbid barriers strictly inside the blanket
        if (this.barriers && this.barriers.isDownBarrier) {
            for (dy = 0; dy + 1 < bigComponent.rows && y + dy + 1 < Field.SIZE; dy++) {
                for (dx = 0; dx < bigComponent.cols && x + dx < Field.SIZE; dx++) {
                    if (this.barriers.isDownBarrier[y + dy][x + dx]) {
                        return false;
                    }
                }
            }
        }
        if (this.barriers && this.barriers.isRightBarrier) {
            for (dy = 0; dy < bigComponent.rows && y + dy < Field.SIZE; dy++) {
                for (dx = 0; dx + 1 < bigComponent.cols && x + dx + 1 < Field.SIZE; dx++) {
                    if (this.barriers.isRightBarrier[y + dy][x + dx]) {
                        return false;
                    }
                }
            }
        }
    }

    var specialShapeComponent = cell.findComponent(SpecialShapeComponent);
    if (specialShapeComponent) {
        var size = specialShapeComponent.getSize();
        if (x < 0 || x + size.cols > Field.SIZE || y < 0 || y + size.rows > Field.SIZE) {
            return false;
        }
    }

    if (bigComponent) { // when try to put new BigCell on field
        if (x + bigComponent.cols <= Field.SIZE && y + bigComponent.rows <= Field.SIZE) { // BigCell area must be inside the field
            for (dy = 0; dy < bigComponent.rows; dy++) {
                for (dx = 0; dx < bigComponent.cols; dx++) {
                    if (this.cells[y + dy][x + dx] && this.cells[y + dy][x + dx] instanceof BlanketCell) {
                        return false;
                    } // different big cell objects should not intersect with blanket cell when added, but
                    // blanket cell can be on big cell
                    if (this.cells[y + dy][x + dx] && this.cells[y + dy][x + dx].findComponent(BigComponent) && !(cell instanceof BlanketCell)) {
                        return false;
                    } // different big cell (non-blanket) objects should not intersect with each other
                }
            }
        } else {
            return false; // not fit in field
        }
    }

    bigComponent = this.cells[cell.y][cell.x] && this.cells[cell.y][cell.x].findComponent(BigComponent);
    if (bigComponent) {
        if ([EditorRandomCell, BlanketCell].indexOf(cell.constructor) !== -1 && bigComponent.isHead) {
            return true;
        } // allow to reset BigCell with EditorRandomCell put on head or with put of BlanketCell on head
        return false; // to prevent putting new non-big cells on the BigCell area and BigCells intersection
    }
    
    return true;
};

Editor.prototype.clickField = function (x, y, code, type) {
    if (x < 0 || y < 0 || x >= Field.SIZE || y >= Field.SIZE) {
        return false;
    }

    if (!this.prepareEdit()) {
        return;
    }

    this.history.reset();

    if (!code) {
        var cell = this.cells[y][x];
        if (!cell) {
            return;
        }
        var component = cell.findComponent([BigComponent, SpecialShapeComponent]);
        if (cell.changeLivesOnClick) {
            cell.changeLivesOnClick();
        } else if (component && component.head && component.head.changeLivesOnClick) {
            component.head.changeLivesOnClick();
        }
        return;
    }

    if (code === DirtyHoneyDecoratorCell.CODES[0]) {
        type = "decor";
    }

    var colorComponent, nextColorComponent;

    if (type === "cell") {
        if (code === " ") {
            this.deleteCell(x, y);
            this.countMarkCells();
            return true;
        }
        cell = this.loader.loadNext(y, x, code.split(""));
        colorComponent = this.cells[y][x] && this.cells[y][x].findComponent(ColorComponent);
        nextColorComponent = cell.findComponent(ColorComponent);
        if (this.cells[y][x] && this.cells[y][x].constructor === cell.constructor && this.cells[y][x].lives === cell.lives
            && (colorComponent && nextColorComponent && colorComponent.color === nextColorComponent.color || (cell.wildcard && this.cells[y][x].wildcard))) {
            cell = this.loader.loadNext(y, x, [Loader.WILDCARD]);
        }
    
        if (this.cellCanBeSetUp(x, y, cell)) {
            if (cell instanceof MultiColorBombCell) {
                this.changeMultiColorBombCell(cell);
            }
    
            if (cell instanceof WallCell) {
                this.changeWallCell(cell);
            }
    
            this.changeCell(cell);
        }
    }

    if (type === "goal_cell") {
        cell = this.loader.loadNext(y, x, code.split(""));
        colorComponent = this.cells[y][x] && this.cells[y][x].findComponent(ColorComponent);
        nextColorComponent = cell.findComponent(ColorComponent);
        if (this.cells[y][x] && this.cells[y][x].constructor === cell.constructor && this.cells[y][x].lives === cell.lives
            && (colorComponent && nextColorComponent && colorComponent.color === nextColorComponent.color
            || (cell.wildcard && this.cells[y][x].wildcard))
            && !(cell.findComponent(SpecialShapeComponent))) {
            cell = this.loader.loadNext(y, x, [Loader.WILDCARD]);
        }

        var specialShapeComponent = cell.findComponent(SpecialShapeComponent);
        if (this.cellCanBeSetUp(x, y, cell)) {
            if (cell instanceof MouseCell) {
                this.changeMouseCell(cell);
            }
            if (cell instanceof DogCell) {
                this.changeDogCell(cell);
            }
            if (cell instanceof DragonCell) {
                this.changeDragonCell(cell);
            }
            if (cell instanceof GingerHouseCell) {
                this.changeGingerHouseCell(cell);
            }

            if (specialShapeComponent) {
                this.changeSpecialShapeCell(cell);
            } else {
                this.changeCell(cell);
            }

            if (cell instanceof CakeCell) {
                this.changeCakeCell(cell);
            }
            if (cell instanceof ClockCell) {
                this.changeClockCell(cell);
            }
        } else if (specialShapeComponent) {
            Game.currentGame.HEADS.splice(Game.currentGame.HEADS.indexOf(specialShapeComponent.findHead(cell.x, cell.y)), 1);
        }
    }

    if (type === "tile") {
        if (Barriers.EDITOR_CODES.indexOf(code) !== -1) {
            if (code === Barriers.EDITOR_CODES[0]) {
                if (this.barriers && this.barriers.isDownBarrier && y < this.barriers.isDownBarrier.length) {
                    if (this.cells[y][x] instanceof BlanketCell && y + 1 < Field.SIZE && this.cells[y + 1][x] instanceof BlanketCell) {
                        return; // forbid to put barriers strictly inside the blanket
                    }
                    this.barriers.isDownBarrier[y][x] ^= 1; // swap the state of barrier (from "off" to "on" and vice versa)
                }
            } else if (this.barriers && this.barriers.isRightBarrier && this.barriers.isRightBarrier.length > 0 && x < this.barriers.isRightBarrier[0].length) {
                if (this.cells[y][x] instanceof BlanketCell && x + 1 < Field.SIZE && this.cells[y][x + 1] instanceof BlanketCell) {
                    return; // forbid to put barriers strictly inside the blanket
                }
                this.barriers.isRightBarrier[y][x] ^= 1; // swap the state of barrier (from "off" to "on" and vice versa)
            }
            this.save();
            this.onUpdateBarriersView();
        } else {
            if (!this.cells[y][x]) {
                cell = this.loader.loadNext(y, x, [Loader.WILDCARD]);
                this.changeCell(cell);
            } else if (this.cells[y][x] instanceof BlanketCell) {
                return; // forbid to put tiles on blanket area when the blanket is already placed
            }
            var tile = this.loader.loadNext(y, x, code.split(""));
            if (tile instanceof RabbitTile) {
                var doublePut = false;
                if (tile.isRoot && (this.floor[tile.y][tile.x] instanceof RabbitTile) && this.floor[tile.y][tile.x].isRoot && this.floor[tile.y][tile.x].size === tile.size) {
                    doublePut = true;
                }
    
                var coveredByBigRabbit = false;
                for (var row = 0; row < Field.SIZE; row++) {
                    for (var col = 0; col < Field.SIZE; col++) {
                        if ((this.floor[row][col] instanceof RabbitTile) && this.floor[row][col].isRoot && this.floor[row][col].size && this.floor[row][col].size > 1
                            && row <= tile.y && tile.y < row + this.floor[row][col].size && col <= tile.x && tile.x < col + this.floor[row][col].size) {
                            coveredByBigRabbit = true; // to prevent removing separate cells from big rabbits by putting small on their area
                        }
                    }
                }
                if (!tile.isRoot && (this.floor[tile.y][tile.x] instanceof RabbitTile) && !this.floor[tile.y][tile.x].isRoot && !coveredByBigRabbit) {
                    doublePut = true;
                }
                    
                if (doublePut) { // remove by "double put"
                    this.deleteRabbitTile(this.floor[tile.y][tile.x]);
                    this.deleteTile(tile.x, tile.y);
                } else if (!coveredByBigRabbit) {
                    var size = 1;
                    if (tile.size) {
                        size = tile.size;
                    }
                    if (tile.x + size <= Field.SIZE && tile.y + size <= Field.SIZE) { // must fit into the field
                        if (tile.isRoot && tile.size > 1) {
                            var intersectWithBigRabbit = false;
                            for (row = 0; row < Field.SIZE; row++) {
                                for (col = 0; col < Field.SIZE; col++) {
                                    if ((this.floor[row][col] instanceof RabbitTile) && this.floor[row][col].isRoot && this.floor[row][col].size && this.floor[row][col].size > 1) {
                                        var minX = Math.max(tile.x, col);
                                        var maxX = Math.min(tile.x + tile.size - 1, col + this.floor[row][col].size - 1);
                                        var minY = Math.max(tile.y, row);
                                        var maxY = Math.min(tile.y + tile.size - 1, row + this.floor[row][col].size - 1);
                                        if (minX <= maxX && minY <= maxY) {
                                            intersectWithBigRabbit = true;
                                        }
                                    }
                                }
                            }
                            if (!intersectWithBigRabbit) {
                                this.deleteTile(tile.x, tile.y);
                                this.deleteRabbitTile(tile); // to remove other small rabbits inside, need to have ability to put big on small
                                
                                this.changeTile(tile);
                                this.changeRabbitTile(tile);
                            }
                        } else {
                            this.changeTile(tile);
                            this.changeRabbitTile(tile);
                        }
                    }
                }
            } else {
                this.changeTile(tile);
            }
        }
    }

    if (type === "mark") {
        if (this.cells[y][x]) {
            cell = this.cells[y][x];
            if (cell.innerCell) {
                cell = cell.innerCell;
            }
            var markComponent = this.cells[y][x].findComponent(MarkComponent);
            if (markComponent) {
                if (markComponent.mark) {
                    markComponent.removeMark();
                } else {
                    markComponent.addMark();
                }
                this.changeCell(this.cells[y][x]);
            }
        }
    }

    if (type === "decor") {
        var decor = this.loader.loadNext(y, x, code.split(""));
        if (decor instanceof BlanketCell) { // always isHead === true, because we put new cell by click
            if (this.cellCanBeSetUp(x, y, decor)) {
                if (this.cells[y][x]) {
                    decor.setInnerCell(this.cells[y][x]);
                } else {
                    decor.setInnerCell(new EditorRandomCell(x, y));
                }

                this.changeCell(decor);
                this.changeBlanketCell(decor);
            } else if (this.cells[y][x] instanceof BlanketCell && this.cells[y][x].findComponent(BigComponent).isHead) { // clear previous blanket on "double put"
                this.clearBlanketCell(this.cells[y][x]);
                this.save();
            }
        } else {
            if (!this.cells[y][x]) {
                decor.setInnerCell(this.loader.loadNext(y, x, [Loader.WILDCARD]));
            } else {
                if (this.cells[y][x].findComponent(BigComponent)) {
                    return false; // not put decorators 1x1 on BigCells (except when BlanketCell is put)
                }
                
                if (this.cells[y][x].constructor === decor.constructor) {
                    decor = this.cells[y][x].innerCell || this.loader.loadNext(y, x, [Loader.WILDCARD]);
                } else {
                    var cannotPutIceBlock = !this.cells[y][x].movable && decor.constructor === IceBlockDecoratorCell;
                    if (cannotPutIceBlock) {
                        return false;
                    }
                    
                    if (this.cells[y][x].innerCell && [BoxDecoratorCell, BagDecoratorCell, EditorBagDecoratorRandomCell, GumDecoratorCell, DirtDecoratorCell].indexOf(decor.constructor) === -1) {
                        if (decor.constructor === this.cells[y][x].innerCell.constructor) {
                            return false; // should not put 2 same decorators on each other
                        }
                        decor.setInnerCell(this.cells[y][x].innerCell);
                    } else {
                        if ([BagDecoratorCell, EditorBagDecoratorRandomCell].indexOf(decor.constructor) !== -1 && [BagDecoratorCell, EditorBagDecoratorRandomCell].indexOf(this.cells[y][x].constructor) !== -1) {
                            return false; // should not put different types of Bags on each other (i.e. random and color bags)
                        }
                        decor.setInnerCell(this.cells[y][x]);
                    }
                }
            }
            
            this.changeCell(decor);
        }
    }

    this.checkIfGoalSetted(x, y);
    this.countMarkCells();
    return true;
};

Editor.prototype.checkIfGoalSetted = function (x, y) {
    var vacantGoalIndex = undefined;
    var currentGoals = this.goals.reduce(function (filtered, goal, curIndex) {
        if (goal.type) {
            filtered.push(goal.type);
        } else if (vacantGoalIndex === undefined) {
            vacantGoalIndex = curIndex;
        }
        return filtered;
    }, []);

    if (vacantGoalIndex !== undefined) {
        [this.cells, this.floor].forEach(function (elements) {
            var element = elements[y][x];
            if (element && element.getGoalId && element.getGoalId() && EditorView.GoalCells.indexOf(element.constructor) !== -1 && currentGoals.indexOf(element.getGoalId()) === -1) {
                this.goals[vacantGoalIndex].setType(element.getGoalId());
            }
        }, this);
    }
};

Editor.prototype.countMarkCells = function () {
    var result = 0;
    for (var i = 0; i < Field.SIZE; i++) {
        for (var j = 0; j < Field.SIZE; j++) {
            var cell = this.cells[i][j];
            if (cell === undefined) {
                continue;
            }
            var markComponent = cell.findComponent(MarkComponent);
            var innerMarkComponent = cell.innerCell && cell.innerCell.findComponent(MarkComponent);
            if (markComponent && markComponent.mark !== undefined
                || innerMarkComponent && innerMarkComponent.mark !== undefined) {
                result++;
            }
        }
    }
    this.setMarks(result);
};

Editor.prototype.isColorActive = function (color) {
    color = this.getColorWithRegister(color);
    return this.colors.indexOf(color) >= 0;
};

Editor.prototype.swapColor = function (color) {
    color = this.getColorWithRegister(color);

    var index = this.colors.indexOf(color);

    if (index === -1) {
        this.colors.push(color);
    } else if (this.colors.length > 1) {
        this.colors.splice(index, 1);
    }

    this.setColors(this.colors);
};

Editor.prototype.addBackground = function (x, y) {
    this.floor[y][x] = undefined;
    this.onBackgroundAdded(x, y);
    this.save();
};

Editor.prototype.changeTile = function (tile) {
    this.saveTileToHistory(tile.x, tile.y);
    if (this.floor[tile.y][tile.x] !== undefined) {
        if (this.floor[tile.y][tile.x].constructor === tile.constructor) {
            this.deleteTile(tile.x, tile.y);
            return;
        } this.onDeleteTile(this.floor[tile.y][tile.x]);
    }

    this.floor[tile.y][tile.x] = tile;
    this.onTileChanged(tile);
    this.save();

    if (tile instanceof SyrupTile) {
        this.changeSyrupTile(tile);
    }
};

Editor.prototype.deleteTile = function (x, y) {
    this.saveTileToHistory(x, y);
    if (this.floor[y][x] !== undefined) {
        this.onDeleteTile(this.floor[y][x]);
        this.floor[y][x] = undefined;
    }
    this.save();
};

Editor.prototype.deleteCell = function (x, y) {
    if (this.cells[y][x] !== undefined) {
        var bigComponent = this.cells[y][x].findComponent(BigComponent);
        if (bigComponent) {
            var head = bigComponent.head;
            var headBigComponent = head.findComponent(BigComponent);
            for (var dx = 0; dx < headBigComponent.cols; dx++) {
                for (var dy = 0; dy < headBigComponent.rows; dy++) {
                    if (this.cells[head.y + dy][head.x + dx]) {
                        this.saveCellToHistory(head.x + dx, head.y + dy);
                        this.deleteTile(head.x + dx, head.y + dy);
                        this.onDeleteCell(this.cells[head.y + dy][head.x + dx]);
                        this.onDeleteBackground(head.x + dx, head.y + dy);
                        this.cells[head.y + dy][head.x + dx] = undefined;
                    }
                }
            }
        } else {
            this.saveCellToHistory(x, y);
            this.deleteTile(x, y);
            this.onDeleteCell(this.cells[y][x]);
            this.onDeleteBackground(x, y);
            this.cells[y][x] = undefined;
        }
    }
    this.save();
};

Editor.prototype.deleteRabbitTile = function (tile) {
    if (!tile.isRoot) {
        return;
    }
    for (var dx = 0; dx < tile.size; dx++) {
        for (var dy = 0; dy < tile.size; dy++) {
            if (dx + dy > 0) {
                this.saveTileToHistory(tile.x + dx, tile.y + dy);
                this.deleteTile(tile.x + dx, tile.y + dy);
            }
        }
    }
    this.save();
};

Editor.prototype.clear2x2Cell = function (cell) {
    var bigComponent = cell.findComponent(BigComponent);
    if (!bigComponent.isHead) {
        return;
    }
    for (var dy = 0; dy <= 1; dy++) {
        for (var dx = 0; dx <= 1; dx++) {
            if (dx + dy > 0 && this.cells[cell.y + dy][cell.x + dx]) {
                this.onDeleteCell(this.cells[cell.y + dy][cell.x + dx]);
                this.cells[cell.y + dy][cell.x + dx] = new EditorRandomCell(cell.x + dx, cell.y + dy);
                this.onCellChanged(this.cells[cell.y + dy][cell.x + dx]);
            }
        }
    }
};

Editor.prototype.changeBlanketCell = function (cell) {
    var bigComponent = cell.findComponent(BigComponent);
    for (var dx = 0; dx < bigComponent.cols; dx++) {
        for (var dy = 0; dy < bigComponent.rows; dy++) {
            if (dx + dy > 0) {
                var blanketBody = new BlanketCell(cell.x + dx, cell.y + dy, 2, 2);
                var blanketBodyBigComponent = blanketBody.findComponent(BigComponent);
                blanketBodyBigComponent.isHead = false;
                blanketBodyBigComponent.head = cell;
    
                if (this.cells[cell.y + dy][cell.x + dx]) {
                    blanketBody.setInnerCell(this.cells[cell.y + dy][cell.x + dx]);
                } else {
                    blanketBody.setInnerCell(new EditorRandomCell(cell.x + dx, cell.y + dy));
                }
                    
                this.changeCell(blanketBody);
            }
        }
    }
};

Editor.prototype.change2x2Cell = function (cell, constructor, paramsSetter) {
    for (var dx = 0; dx <= 1; dx++) {
        for (var dy = 0; dy <= 1; dy++) {
            if (dx + dy > 0) {
                var bodyCell = new constructor(cell.x + dx, cell.y + dy);
                if (paramsSetter) {
                    paramsSetter(bodyCell);
                }
                this.changeCell(bodyCell);
            }
        }
    }
};

Editor.prototype.changeMouseCell = function (cell) {
    this.change2x2Cell(cell, MouseCell, function (bodyCell) {
        var bigComponent = bodyCell.findComponent(BigComponent);
        bigComponent.isHead = false;
        bigComponent.head = cell;
    });
};

Editor.prototype.changeDogCell = function (cell) {
    this.change2x2Cell(cell, DogCell, function (bodyCell) {
        var bigComponent = bodyCell.findComponent(BigComponent);
        bigComponent.isHead = false;
        bigComponent.head = cell;
    });
};

Editor.prototype.changeDragonCell = function (cell) {
    this.change2x2Cell(cell, DragonCell, function (bodyCell) {
        var bigComponent = bodyCell.findComponent(BigComponent);
        bigComponent.isHead = false;
        bigComponent.head = cell;
    });
};

Editor.prototype.changeGingerHouseCell = function (cell) {
    this.change2x2Cell(cell, GingerHouseCell, function (bodyCell) {
        var bigComponent = bodyCell.findComponent(BigComponent);
        bigComponent.isHead = false;
    });
};

Editor.prototype.changeCakeCell = function (cell) {
    this.change2x2Cell(cell, CakeCell);
};

Editor.prototype.changeMultiColorBombCell = function (cell) {
    this.change2x2Cell(cell, MultiColorBombCell, function (bodyCell) {
        var bigComponent = bodyCell.findComponent(BigComponent);
        bigComponent.isHead = false;
    });
};

Editor.prototype.changeClockCell = function (cell) {
    this.change2x2Cell(cell, ClockCell, function (bodyCell) {
        var bigComponent = bodyCell.findComponent(BigComponent);
        bigComponent.isHead = false;
    });
};

Editor.prototype.changeSpecialShapeCell = function (head) {
    if (Game.currentGame.field.cells[head.y][head.x].findComponent(SpecialShapeComponent)) {
        this.deleteSpecialShapeCell(Game.currentGame.field.cells[head.y][head.x]);
        Game.currentGame.HEADS.splice(Game.currentGame.HEADS.indexOf(head), 1);
    } else {
        head.topLeftX = head.x;
        head.topLeftY = head.y;
        var headFound = false;
        var specialShapeComponent = head.findComponent(SpecialShapeComponent);
        var size = specialShapeComponent.getSize();

        for (var y = 0; y < size.rows; y++) {
            for (var x = 0; x < size.cols; x++) {
                if (specialShapeComponent.config[y][x] === 1) {
                    if (!headFound) {
                        head.x = head.topLeftX + x;
                        head.y = head.topLeftY + y;
                        headFound = true;
                        this.changeCell(head);
                    } else {
                        var part = new head.constructor(head.topLeftX + x, head.topLeftY + y);
                        part.findComponent(SpecialShapeComponent).isHead = false;
                        this.changeCell(part);
                    }
                }
            }
        }
    }
};

Editor.prototype.changeRabbitTile = function (tile) {
    if (!tile.isRoot) {
        return;
    }
    for (var dx = 0; dx < tile.size; dx++) {
        for (var dy = 0; dy < tile.size; dy++) {
            if (dx + dy > 0) {
                var rabbitTile = new RabbitTile(tile.x + dx, tile.y + dy);
                rabbitTile.root = tile;
                rabbitTile.lives = tile.lives;
                this.changeTile(rabbitTile);
            }
        }
    }
};

Editor.prototype.changeWallCell = function (cell) {
    this.deleteTile(cell.x, cell.y);
};

Editor.prototype.changeSyrupTile = function (tile) {
    for (var x = 0; x < Field.SIZE; x++) {
        for (var y = 0; y < Field.SIZE; y++) {
            var currTile = this.floor[y][x];
            if (currTile instanceof SyrupTile && currTile.dir !== tile.dir) {
                this.deleteTile(currTile.x, currTile.y);
            }
        }
    }
};

Editor.prototype.changeCell = function (cell) {
    this.saveCellToHistory(cell.x, cell.y);
    if (this.cells[cell.y][cell.x] === undefined) {
        this.addBackground(cell.x, cell.y);
    } else {
        this.onDeleteCell(this.cells[cell.y][cell.x]);
    }
    if (this.cells[cell.y][cell.x] && this.cells[cell.y][cell.x].findComponent(SpecialShapeComponent)) {
        this.deleteSpecialShapeCell(this.cells[cell.y][cell.x], true);
    }
    
    if (this.cells[cell.y][cell.x] instanceof BlanketCell) {
        this.clearBlanketCell(this.cells[cell.y][cell.x]); // put (blanket or editorRandomCell) on blanket, other cases would not lead here,
        // clear by double-put or by random cell
        this.save();
        return;
    }
    
    // all big cells should clear entire area, because put of EditorRandomCell on its area is now prohibited by Editor.prototype.cellCanBeSetUp
    if (this.cells[cell.y][cell.x] && [MouseCell, DogCell, CakeCell, ClockCell, MultiColorBombCell, GingerHouseCell, DragonCell].indexOf(this.cells[cell.y][cell.x].constructor) !== -1
        && !(cell instanceof BlanketCell)) {
        this.clear2x2Cell(this.cells[cell.y][cell.x]);
    }
    
    this.cells[cell.y][cell.x] = cell;
    this.onCellChanged(cell);
    this.save();
};

Editor.prototype.clearBlanketCell = function (cell) {
    var bigComponent = cell.findComponent(BigComponent);

    if (!bigComponent.isHead) {
        return;
    }

    for (var dy = 0; dy < bigComponent.rows; dy++) {
        for (var dx = 0; dx < bigComponent.cols; dx++) {
            if (this.cells[cell.y + dy][cell.x + dx]) {
                this.onDeleteCell(this.cells[cell.y + dy][cell.x + dx]);
                
                if (this.cells[cell.y + dy][cell.x + dx].innerCell) {
                    this.cells[cell.y + dy][cell.x + dx] = this.cells[cell.y + dy][cell.x + dx].innerCell;
                    if (this.cells[cell.y + dy][cell.x + dx].findComponent(BigComponent)) { // when BigCell is only loaded via load() the head flag is always "true", there are no such cells on field at that moment
                        this.cells[cell.y + dy][cell.x + dx].checkHeadFlag();
                    }
                } else {
                    this.cells[cell.y + dy][cell.x + dx] = new EditorRandomCell(cell.x + dx, cell.y + dy);
                }

                this.onCellChanged(this.cells[cell.y + dy][cell.x + dx]);
            }
        }
    }
};

Editor.prototype.deleteSpecialShapeCell = function (cell, excludeCell) {
    var head = cell.findComponent(SpecialShapeComponent).findHead(cell.x, cell.y);
    head.findComponent(SpecialShapeComponent).iterateSelf(function (x, y) {
        var currCell = Game.currentGame.field.cells[y][x];
        if (excludeCell && cell === currCell) {
            return;
        }
        var newCell = this.loader.loadNext(currCell.y, currCell.x, [Loader.WILDCARD]);
        this.onDeleteCell(this.cells[newCell.y][newCell.x]);
        this.cells[newCell.y][newCell.x] = newCell;
        this.onCellChanged(newCell);
    }.bind(this));

    Game.currentGame.HEADS.splice(Game.currentGame.HEADS.indexOf(head), 1);
};

Editor.prototype.saveCellToHistory = function (x, y) {
    if (this.cells[y][x] === undefined) {
        this.history.add({
            redo: function () {},
            undo: function () {
                this.deleteCell(x, y);
            }.bind(this)
        });
    } else {
        var prevCell = this.cells[y][x];
        this.history.add({
            redo: function () {},
            undo: function () {
                this.changeCell(prevCell);
            }.bind(this)
        });
    }
};

Editor.prototype.saveTileToHistory = function (x, y) {
    if (this.floor[y][x] === undefined) {
        this.history.add({
            redo: function () {},
            undo: function () {
                this.deleteTile(x, y);
            }.bind(this)
        });
    } else {
        var prevTile = this.floor[y][x];
        this.history.add({
            redo: function () {},
            undo: function () {
                this.changeTile(prevTile);
            }.bind(this)
        });
    }
};

Editor.prototype.hideCells = function () {
    for (var y = 0; y < this.cells.length; ++y) {
        for (var x = 0; x < this.cells[y].length; ++x) {
            var cell = this.cells[y][x];
            if (cell && cell.currentView) {
                cell.currentView.originalZOrder = cell.currentView.getLocalZOrder();
                cell.currentView.setLocalZOrder(-1);
            }
        }
    }
};

Editor.prototype.showCells = function () {
    for (var y = 0; y < this.cells.length; ++y) {
        for (var x = 0; x < this.cells[y].length; ++x) {
            var cell = this.cells[y][x];
            if (cell && cell.currentView) {
                cell.currentView.setLocalZOrder(cell.currentView.originalZOrder);
            }
        }
    }
};

Editor.VALID_COUNT_GOALS = {
    default: 3,
    tricky: 1
};

Editor.MAX_GOALS = 3;

cleverapps.styles.EditorView = {
    fieldPosition: {
        x: 300,
        y: 100
    },

    incDec: {
        x: 400
    },

    mouseCell: {
        y: 60
    },

    dogCell: {
        y: 60
    },
    
    blanketCell: {
        sizeButton: {
            width: 90,
            height: 60
        },
        
        colorButton: {
            width: 90,
            height: 60
        },
        
        colorItem: {
            width: 90,
            height: 90,
            margin: 12
        }
    },
    
    bombCell: {
        radius: {
            width: 45
        },
        y: 40
    },

    colorBombCell: {
        radius: {
            width: 33
        },
        y: 70
    },

    fireflyJarCell: {
        radius: {
            width: 50
        },
        y: 60
    },

    cannonCell: {
        dirButton: {
            x: 0,
            y: -40,
            width: 50,
            height: 50
        },

        currDirCheckBox: {
            x: 30,
            y: -30
        },

        clockwiseCheckBoxMargin: 15,
        rowsMargin: 10,
        itemsMargin: 3
    },

    specialShapeCell: {
        rotationButton: {
            x: 0,
            y: -50,
            width: 50,
            height: 50
        }
    },

    switchMode: {
        width: 120,
        height: 40
    }
};