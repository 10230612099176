/**
 * Created by iamso on 30.10.18.
 */

var RugTile = function (x, y) {
    BaseTile.call(this, x, y);
    this.putInPoolAvailable = true;

    this.onCreateNew = function () {};
};

RugTile.prototype = Object.create(BaseTile.prototype);
RugTile.prototype.constructor = RugTile;

RugTile.prototype.getViewClass = function () {
    return RugTileView;
};

RugTile.prototype.save = function () {
    return RugTile.CODES;
};

RugTile.prototype.bundleId = function () {
    return "rug";
};

RugTile.prototype.getGoalId = function () {
    return RugTile.GOAL_ID;
};

RugTile.hurtViaCombo = function (combo, cell) {
    if (!Game.currentGame) {
        return;
    }

    var comboTile = Game.currentGame.field.floor[combo.y][combo.x];
    var cellTile = Game.currentGame.field.floor[cell.y][cell.x];

    if (comboTile && comboTile.constructor === RugTile) {
        if (cellTile === undefined && !RugTile.isDecorator(cell)) { // cellTile === null should not be processed, no cell on initial field
            RugTile.processTo({ x: cell.x, y: cell.y });
        }
    }
};

RugTile.process = function (cells) {
    if (!Game.currentGame || !RugTile.checkIfTriggered(cells)) {
        return;
    }

    var tilesToInvade = [];
    cells.forEach(function (cell) {
        if (Game.currentGame.field.floor[cell.y][cell.x] === undefined) {
            if (!RugTile.isDecorator(cell)) {
                tilesToInvade.push({ x: cell.x, y: cell.y });
            }
        }
    });
    tilesToInvade.forEach(function (tile) {
        RugTile.processTo(tile);
    });
};

RugTile.checkIfTriggered = function (cells) {
    var rugTriggered = false;
    cells.forEach(function (cell) {
        if (Game.currentGame.field.floor[cell.y][cell.x] && Game.currentGame.field.floor[cell.y][cell.x].constructor === RugTile) {
            if (!RugTile.isDecorator(cell)) {
                rugTriggered = true;
            }
        }
    });
    return rugTriggered;
};

RugTile.processTo = function (tile) {
    if (!Game.currentGame) {
        return;
    }
    var cell = Game.currentGame.field.cells[tile.y][tile.x];
    if (cell && cell.findComponent(MuffinComponent) && cell.lives > 0) { // not grow rug under muffins with ">= 2 lives"
        return;
    }
    var rugTile = new RugTile(tile.x, tile.y);
    Game.currentGame.field.addTile(rugTile);
    rugTile.onCreateNew();
    Game.currentGame.counter.setTimeout(function () {
        if (bundles.rug.urls.rug_create_effect) {
            cleverapps.audio.playSound(bundles.rug.urls.rug_create_effect);
        }
    }, 600);

    Game.currentGame.goalCounter.setTimeout(function () {
        if (Game.currentGame && Game.currentGame.goals.hasType(RugTile.GOAL_ID)) {
            Game.currentGame.goals.incGoal(RugTile.GOAL_ID, 1);
        }
    }, 600);
};

RugTile.prototype.hurtViaBooster = function (boosterId) {
    if (!this.shouldTrigger()) {
        return;
    }

    var amountByBooster = {
        0: 1,
        1: 2,
        2: 4
    };

    var amount = amountByBooster[boosterId];

    var neighbors = [
        { x: this.x - 1, y: this.y },
        { x: this.x, y: this.y + 1 },
        { x: this.x + 1, y: this.y },
        { x: this.x, y: this.y - 1 }
    ];

    var chosen = [];
    for (var i = 0; i < neighbors.length; i++) {
        if (chosen.length === amount) {
            break;
        }

        if (this.isAllowed(neighbors[i].x, neighbors[i].y)) {
            chosen.push(neighbors[i]);
        }
    }

    chosen.forEach(function (tile) {
        RugTile.processTo(tile);
    });
};

RugTile.prototype.shouldTrigger = function () {
    var cell = Game.currentGame.field.cells[this.y][this.x];
    if (!cell) {
        return false;
    }

    if (RugTile.isDecorator(cell)) {
        return false;
    }

    if (cell.getColor() !== undefined && cell.hurtable) {
        return true;
    }

    return false;
};

RugTile.prototype.isAllowed = function (x, y) {
    if (!(x >= 0 && y >= 0 && x < Field.SIZE && y < Field.SIZE)) {
        return false;
    }

    var cell = Game.currentGame.field.cells[y][x];
    var tile = Game.currentGame.field.floor[y][x];
    return tile === undefined && cell && !RugTile.isDecorator(cell);
};

RugTile.isDecorator = function (cell) {
    return (cell && cell.innerCell) || ([CrabMakerCell, DirtMakerCell].indexOf(cell.constructor) !== -1);
};

RugTile.prototype.explode = function () {};
RugTile.CODES = ["|"];
RugTile.GOAL_ID = "|";
