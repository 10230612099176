/**
 * Created by mac on 10/14/20
 */

var AddLevelView = AdminLevelView.extend({
    ctor: function (adminLevel) {
        cc.Node.prototype.ctor.call(this);

        this.adminLevel = adminLevel;

        this.setContentSize2(cleverapps.styles.AddLevelView);
        this.setAnchorPoint(0.5, 0.5);

        var bg = cleverapps.UI.createScale9Sprite(bundles.admin.frames.selected_button, cleverapps.UI.Scale9Rect.TwoPixelXY);
        bg.setContentSize2(this.getContentSize());
        bg.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(bg);

        var plus = new cc.Sprite(bundles.admin.frames.plus_button);
        plus.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(plus);

        cleverapps.UI.onClick(this, this.onClick.bind(this));
        cleverapps.UI.applyHover(this, {
            onMouseOver: function () {
                this.setScale(1.05);
            }.bind(this),

            onMouseOut: function () {
                this.setScale(1);
            }.bind(this)
        });
    }
});

cleverapps.styles.AddLevelView = {
    width: 146,
    height: 200
};
