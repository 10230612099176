/**
 * Created by razial on 07.12.2022
 */

var TreasureBagIcon = cc.Node.extend({
    ctor: function () {
        this._super();

        var styles = cleverapps.styles.TreasureBag;

        if (styles.background) {
            var background = new cc.Sprite(bundles.treasurebag.frames.treasurebag_bg_png);
            background.setPositionRound(background.width / 2, background.height / 2);
            this.addChild(background);
        }
        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize2(styles);

        var animation = this.animation = new cleverapps.Spine(bundles.treasurebag.jsons.treasurebag_json);
        animation.setPositionRound(styles.animation);
        this.addChild(animation);

        cleverapps.aims.registerTarget("default", this, {
            withoutDelta: true,
            controls: ["treasureBag"]
        });
        this.setLocalZOrder(TreasureBagIcon.ZORDER);

        this.updateSize();
        this.setupChildren();
    },

    setupChildren: function () {
        var styles = cleverapps.styles.TreasureBag;
        this.setPositionRound(styles.position);
    },

    updateSize: function () {
        var styles = cleverapps.styles.TreasureBag;
        this.setScale(connector.info.isMobile ? styles.mobileScale[cleverapps.resolution.mode] : styles.scale[cleverapps.resolution.mode]);
        this.baseScale = this.scale;
    },

    onShow: function () {
        this.animation.setAnimation(0, "animation", false);
    }
});

TreasureBagIcon.ZORDER = BaseWindow.WINDOWS_ZORDER + 1;

cleverapps.styles.TreasureBag = {
    width: 210,
    height: 210,
    scale: [0.8, 1, 1],
    mobileScale: [0.65, 0.8, 0.8],

    animation: {
        x: { align: "center", dx: -2 },
        y: { align: "center", dy: -8 }
    },

    position: {
        x: { align: "right", dx: -10 },
        y: { align: "bottom", dy: 200 }
    },

    hideVertical: false,

    offsetY: 60,

    background: true
};