/**
 * Created by slava on 02.02.17.
 */
var EditorRandomCellView = ColorCellView.extend({
    ctor: function (cell) {
        this._super(cell);

        this.setOpacity(255);
    },

    getImage: function () {
        return bundles.editor.frames.randomCell;
    },

    onColorChanged: function () {
        this.image.setTexture(this.getImage(this.cell));
    }
});

var EditorSmallPetardRandomCellView = SmallPetardCellView.extend({
    ctor: function (cell) {
        this._super(cell);

        this.setOpacity(255);
    },

    getImage: function () {
        return bundles.editor.frames.randomSmallPetardCell;
    }
});

var EditorBombRandomCellView = BombCellView.extend({
    ctor: function (cell) {
        this._super(cell);

        this.setOpacity(255);
        this.setScale(1);
    },

    getImage: function () {
        return bundles.editor.frames.randomBombCell;
    },

    updateImageTexture: function () {
        this.image.setTexture(this.getImage());
    }
});

var EditorColorBombRandomCellView = ColorBombCellView.extend({
    ctor: function (cell) {
        this._super(cell);

        this.setOpacity(255);
    },

    initialize: function (cell) {
        this.cell = cell;

        var markComponent = cell.findComponent(MarkComponent);
        if (markComponent && markComponent.mark) {
            markComponent.addMark();
        }

        var image = this.image = new cc.Sprite(this.getImage(cell));
        image.setPosition(this.width / 2, this.height / 2);
        this.addChild(image);

        cell.onChangeLivesListener = this.changeLives.bind(this);
        cell.onAnimationListener = this.showAnimation.bind(this);
        
        this.initializeZOrder();
    },

    changeLives: function () {
        this.image.setTexture(this.getImage(this.cell));
    },

    getImage: function (colorBombCell) {
        return bundles.editor.frames["color_bomb_random_" + colorBombCell.lives];
    }
});

var EditorFireworkRandomCellView = FireworkCellView.extend({
    ctor: function (cell) {
        this._super(cell);

        this.setOpacity(255);
    },

    updateImage: function () {
        this.image.setTexture(this.getImage());
    },

    getImage: function () {
        return bundles.editor.frames.randomFireworkCell;
    }
});

var EditorWaffleRandomCellView = WaffleCellView.extend({
    ctor: function (cell) {
        this._super(cell);

        this.setOpacity(255);
    },

    getImage: function () {
        return bundles.editor.frames.randomWaffleCell;
    },

    updateImage: function () {
        this.image.setTexture(this.getImage());
    }
});

var EditorTruffleRandomCellView = TruffleCellView.extend({
    updateImages: function () {
        if (!this.images) {
            return;
        }

        this.images.forEach(function (image, index) {
            var resource = bundles.editor.frames.randomTruffleCell;
            if (index === 0 && !this.cell.wildcard) {
                resource = bundles.truffle.frames["truffle_cell_" + this.cell.findComponent(ColorComponent).color];
            }
            image.setSpriteFrame(new cc.Sprite(resource).getSpriteFrame());
        }, this);
    }
});

var EditorLollipopRandomCellView = LollipopCellView.extend({
    ctor: function (cell) {
        this._super(cell);

        this.setOpacity(255);
    },

    getImage: function (cell) {
        return bundles.editor.frames[cell.bundleId() === "lollipop_reskin" ? "randomLollipopCellReskin" : "randomLollipopCell"];
    },

    updateImage: function () {
        this.image.setTexture(this.getImage(this.cell));
    }
});

var EditorColorCookieManRandomCellView = ColorCookieManCellView.extend({
    ctor: function (cell) {
        this._super(cell);

        this.setOpacity(255);
    },

    getImage: function () {
        return bundles.editor.frames.randomColorCookieManCell;
    },

    updateImage: function () {
        this.image.setTexture(this.getImage());
    }
});

var EditorBagDecoratorRandomCellView = BagDecoratorCellView.extend({
    ctor: function (cell) {
        this._super(cell);

        this.setOpacity(255);
    },

    getImage: function (cell) {
        return bundles.editor.frames[cell.bundleId() === "bag_reskin" ? "randomBagDecoratorCellReskin" : "randomBagDecoratorCell"];
    },

    updateImage: function () {
        this.image.setTexture(this.getImage(this.cell));
    }
});
