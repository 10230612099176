/**
 * Created by Andrey Popov on 14.06.2024
 */

var OfferProductTile = BaseProductTile.extend({
    ctor: function (tileModel, options) {
        var styles = OfferProductTile.styles = OfferProductTile.styles || cleverapps.overrideStyles(cleverapps.styles.BaseProductTile, cleverapps.styles.OfferProductTile, true);

        options = options || {};

        this._super(tileModel, styles, options);
    },

    wrapIconWithBg: function (icon) {
        if (this.styles.icon.bg) {
            return this._super(icon);
        }

        var wrapper = new cc.Node();
        wrapper.setContentSize2(icon.width, icon.height);
        wrapper.setAnchorPoint(0.5, 0.5);
        
        wrapper.addChild(icon);
        icon.setPositionRound(wrapper.width / 2, wrapper.height / 2 + this.styles.icon.offsetY);

        return wrapper;
    },

    createDescription: function () {
        var styles = this.styles.description;

        var node = new cc.Node();
        node.setAnchorPoint(0.5, 0.5);
        node.setContentSize2(styles);

        var bg = cleverapps.UI.createScale9Sprite(bundles.tile_shop.frames.offer_description_bg, cleverapps.UI.Scale9Rect.TwoPixelY);
        bg.setContentSize2(styles.bg);
        node.addChild(bg);
        bg.setPositionRound(styles.bg);
        
        var content = new TileRewardsBlock(this.tileModel, {
            size: cc.size(this.styles.description),
            font: cleverapps.styles.FONTS.OFFER_TILE_REWARDS_TEXT,
            icons: {},
            paddingY: this.styles.description.paddingY
        });

        content.setPositionRound(node.width / 2, node.height / 2);
        node.addChild(content);

        return node;
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    OFFER_TILE_REWARDS_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE
    }
});

cleverapps.styles.OfferProductTile = {
    width: 460,
    height: 747,

    icon: {
        offsetY: -40,
        bg: undefined
    },

    content: {
        y: { align: "bottom", dy: 75 },
        margin: 0
    },

    description: {
        width: 370,
        y: 20,
        paddingY: -30,
        bg: {
            width: 370,
            height: 174,
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 0 }
        }
    },

    title: {
        y: 20,

        text: {
            y: { align: "center", dy: 6 }
        },

        size: {
            width: 480
        }
    }
};
