/**
 * Created by vlad on 31/10/18.
 */

var HardLevelIcon = cc.Node.extend({
    ctor: function (parent) {
        this._super();

        var styles = this.styles = cleverapps.styles.HardLevelIcon;

        this.setAnchorPoint(0.5, 0.5);
        this.setLocalZOrder(16);

        var image = this.image = new cc.Sprite(bundles.hard_level_icon.frames.hard_level_icon);
        this.addChild(image);
        this.setContentSize2(image.getContentSize());
        image.setRotation(styles.rotation);
        image.setPositionRound(this.width / 2, this.height / 2);

        this.updateSize();
        this.setupChildren();

        parent.addChild(this);
        this.runAnimation();
    },

    runAnimation: function () {
        var baseScale = this.image.scale;
        var baseRot = this.image.rotation;
        this.image.setScale(baseScale * 0.1);
        this.image.setVisible(false);
        this.image.runAction(new cc.Sequence(
            new cc.DelayTime(1.0),
            new cc.CallFunc(function () {
                this.image.setVisible(true);
            }.bind(this)),
            new cc.ScaleTo(0.5, baseScale).easing(cc.easeBackOut()),
            new cc.CallFunc(function () {
                this.image.runAction(
                    new cc.RepeatForever(
                        new cc.Sequence(
                            new cc.DelayTime(6.0),
                            new cc.RotateTo(0.15, baseRot + 15).easing(cc.easeIn(1)),
                            new cc.RotateTo(0.15, baseRot - 15).easing(cc.easeIn(1)),
                            new cc.RotateTo(0.15, baseRot + 10).easing(cc.easeIn(1)),
                            new cc.RotateTo(0.15, baseRot).easing(cc.easeIn(1))
                        )
                    )
                );
            }.bind(this))
        ));
    },

    updateSize: function () {
        if (this.image) {
            this.image.setScale(this.styles.scale[cleverapps.resolution.mode]);
        }
    },

    setupChildren: function () {
        this.setPositionRound(this.styles.alignment[cleverapps.resolution.mode]);
    },
    
    hide: function () {
        this.setVisible(false);
    }
});

cleverapps.styles.HardLevelIcon = {
    scale: [0.4, 0.7, 0.7],
    rotation: -10,
    alignment: [
        { x: { align: "center", dx: -285 }, y: { align: "top", dy: -87 } },
        { x: { align: "center", dx: 400 }, y: { align: "top", dy: -7 } },
        { x: { align: "center", dx: 400 }, y: { align: "top", dy: -7 } }
    ],
    enabled: false,
    parentSceneScene: false
};