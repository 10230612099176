/**
 * Created by denis on 4 august 2020
 */

var TwoJokersBooster = function () {
    BaseBoosterBefore.call(this, cleverapps.Boosters.TYPE_JOKERS);
};

TwoJokersBooster.prototype = Object.create(BaseBoosterBefore.prototype);
TwoJokersBooster.prototype.constructor = TwoJokersBooster;

TwoJokersBooster.prototype.getActionBefore = function () {
    return {
        type: "jokers",
        source: this,
        duration: 1.6
    };
};