/**
 * Created by slava on 02.02.17.
 */

var IceTileView = cc.Node.extend(Object.assign({}, BaseTileView, {
    ctor: function (tile) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);

        this.initialize(tile);

        var bundle = tile.bundleId();
        if (tile.x < 0) {
            this.image = new cc.Sprite(bundles[bundle].frames[bundle + "_goal"]);
            this.image.setScaleX(cleverapps.styles.IceTileView.goal.scaleX);
        } else {
            this.image = new cc.Sprite(bundles[bundle].frames[bundle + "_tile"]);
            this.setLocalZOrder(3);
        }

        this.image.setPosition(this.width / 2, this.height / 2);
        this.addChild(this.image);

        this.tile.onCreateNew = this.animateCreateNew.bind(this);
    },

    initialize: function (tile) {
        if (this.image) {
            this.image.setScale(1);
        }
        BaseTileView.initialize.call(this, tile);
    },

    startExploding: function () {
        this.stopAllActions();
        if (!Game.currentGame.goals.hasType(this.tile.getGoalId())) {
            this.image.runAction(new cc.Sequence(
                new cc.ScaleTo(0.3, 0).easing(cc.easeBackIn()),
                new cc.CallFunc(function () {
                    this.delete();
                }.bind(this))
            ));
            return;
        }
        var fieldView = Game.currentGame.field.onGetView();
        var collectAnimation = BaseAnimation.factoryLimited(bundles[this.tile.bundleId()].jsons.ice_spine_json);
        var pos = this.getPosition();
        var object = this.tile;
        collectAnimation.setPosition(pos);
        fieldView.addChild(collectAnimation);

        Game.currentGame.goalCounter.setTimeout(function () {
        }, 700);

        var id = 0;
        if (IceTileView.lastExplodeTime > Date.now() - 100) {
            id = IceTileView.groupSize;
        } else {
            IceTileView.groupSize = 0;
            IceTileView.lastExplodeTime = Date.now();
        }
        IceTileView.groupSize++;
        var groupSize = IceTileView.groupSize;

        var styles = cleverapps.styles.IceTileView;
        if (styles.collectAnimation && this.onCollectAnimation) {
            this.onCollectAnimation(object, id, groupSize);
        }

        var bundle = this.tile.bundleId();
        collectAnimation.runAnimate("two", styles.collectAnimation ? undefined : function () {
            if (Game.currentGame) {
                var icon = new cc.Sprite(bundles[bundle].frames.ice_goal);
                icon.setLocalZOrder(5);
                icon.setPosition(pos.x + cleverapps.styles.IceTileView.potMargin.x, pos.y + cleverapps.styles.IceTileView.potMargin.y);
                fieldView.addChild(icon);

                var goal = Game.currentGame.goals.findTargetFor(object.getGoalId());
                goal.collect(1, icon);
            }
        }, new cc.Sequence(new cc.CallFunc(function (object) {
            object.setScale(0.1);
        }), new cc.Spawn(new cc.MoveBy(0.3, cleverapps.styles.IceTileView.potMargin.x, cleverapps.styles.IceTileView.potMargin.y), new cc.ScaleTo(0.5, 1).easing(cc.easeBackOut()))));

        this.delete();
    },

    animateCreateNew: function () {
        this.image.setScale(0);
        this.image.runAction(
            new cc.Sequence(
                new cc.ScaleTo(0.1, 0.25).easing(cc.easeBackOut()),
                new cc.ScaleTo(0.1, 0.15).easing(cc.easeBackOut()),
                new cc.ScaleTo(0.3, 1.05).easing(cc.easeBackOut()),
                new cc.ScaleTo(0.1, 1).easing(cc.easeBackOut())
            )
        );
    }
}));

IceTileView.lastExplodeTime = 0;
IceTileView.groupSize = 0;

cleverapps.styles.IceTileView = {
    potMargin: {
        x: -53,
        y: -32
    },
    goal: {
        scaleX: -1
    }
};
