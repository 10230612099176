/**
 * Created by Ivan on 16.04.2024
 */

var StickersCollectionWindow = CleverappsWindow.extend({
    ctor: function (collection) {
        this.collection = collection;

        this._super();
    },

    onWindowLoaded: function () {
        this._super({
            title: this.collection.title,
            name: "StickersCollectionWindow",
            content: this.getContent(),
            styles: cleverapps.styles.StickersCollectionWindow.window
        });

        cleverapps.UI.onClick(this.window, function () {
            this.onClick();
        }.bind(this), {
            interactiveScale: false
        });
    },

    getContent: function () {
        var styles = cleverapps.styles.StickersCollectionWindow;

        var content = this.content = new cc.Node();
        content.setAnchorPoint(0.5, 0.5);
        content.setContentSize2(styles.size);
        content.setPositionRound(styles);

        if (!(this.collection.isPrizeCollected && styles.jokers.hideOnComplete)) {
            var jokerAmount = new StickersBookJokerView("stickers_collection_window");
            jokerAmount.setPositionRound(styles.jokers.position);
            content.addChild(jokerAmount);
            cleverapps.UI.onClick(jokerAmount, function () {
                this.collection.stickers.forEach(function (sticker, index) {
                    if (sticker.getState() === Sticker.STATE_CLOSED) {
                        this.highlightSticker(index);
                    }
                }.bind(this));
            }.bind(this));
        }

        var progress = this.createProgress();

        content.addChild(progress);
        progress.setPositionRound(styles.prize.position);

        this.collection.on("update", this.createListener(function () {
            progress.updateProgress && progress.updateProgress(this.collection.getOpenedStickersAmount(), StickersCollection.STICKERS_IN_COLLECTION, {
                animated: true
            });
        }.bind(this)), this);

        this.addComponent();

        if (this.collection.isPrizeCollected && styles.stamp) {
            var stamp = new cc.Sprite(bundles.stickers_collection_window.frames.stamp_png);
            this.content.addChild(stamp);
            stamp.setPositionRound(styles.stamp);
        }

        return content;
    },

    createProgress: function () {
        var styles = cleverapps.styles.StickersCollectionWindow;

        var currentValue = this.collection.getOpenedStickersAmount();
        var goalValue = StickersCollection.STICKERS_IN_COLLECTION;

        if (this.collection.isPrizeCollected) {
            var completedText = cleverapps.UI.generateOnlyText("StickersCollectionWindow.CompletedText", cleverapps.styles.FONTS.WINDOW_TEXT);
            var checkmark = new cc.Sprite(bundles.stickers_collection_window.frames.checkmarks_png);
            var text = styles.checkmark ? new cleverapps.Layout([checkmark, completedText], {
                direction: cleverapps.UI.HORIZONTAL,
                margin: styles.checkmark.margin
            }) : completedText;
            return text;
        }

        var prizeBar = new PrizeBarComponent({
            onCollect: this.createListener(function () {
                this.collection.onPrizeCollect();
                this.close();
                new StickersCompletedWindow(this.collection.index);
            }.bind(this)),
            reward: StickersBook.getCollectionReward(this.collection.index),
            progressBar: {
                progressImage: bundles.stickers_collection_window.frames.progress_bar_png,
                progressBgImage: bundles.stickers_collection_window.frames.progress_bar_bg_png,
                width: styles.prize.width,
                barText: {
                    dy: styles.text.dy
                }
            }
        });
        prizeBar.updateProgress(currentValue, goalValue);
        if (this.collection.isPrizeCollected) {
            prizeBar.setCollected();
        }
        return prizeBar;
    },

    addTooltipHandler: function (stickerView) {
        if (!stickerView.sticker.isOpen()) {
            stickerView.clickHandler = cleverapps.UI.onClick(stickerView, function () {
                this.onClick(stickerView);
            }.bind(this), {
                interactiveScale: false,
                onDoubleClick: cleverapps.config.debugMode && this.onDoubleClick.bind(this, stickerView)
            });
        }
    },

    highlightSticker: function (index) {
        var sticker = this.stickers[index];

        if (sticker.light) {
            return;
        }

        var light = sticker.light = new cleverapps.Spine(bundles.stickers_collection_window.jsons.highlight_sticker_json);

        if (light.hasSkin(index)) {
            light.setSkin(index);
        }

        sticker.addChild(light, -1);
        light.setPositionRound(cleverapps.styles.StickersCollectionWindow.highlight);
        light.setAnimation(0, "animation", false);
        light.setCompleteListener(function () {
            sticker.light.removeFromParent();
            sticker.light = undefined;
        });
    },

    createStickerTooltip: function (target) {
        if (target.sticker.getState() !== Sticker.STATE_CLOSED) {
            return;
        }
        if (this.stickerTooltip && this.stickerTooltip.target === target) {
            return;
        }

        var styles = cleverapps.styles.StickersCollectionWindow.tooltip;
        var bg = new cc.Scale9Sprite(bundles.stickers_collection_window.frames.tooltip_bg_png);
        bg.setAnchorPoint(0.5, 1);

        var text = new TextWithIcon("!! WildCard.Button", {
            icons: {
                "!!": bundles.stickers_collection_window.frames.double_joker_png
            },
            font: cleverapps.styles.FONTS.STICKER_JOKER_BUTTON_TEXT
        });

        var button = new cleverapps.UI.Button({
            type: cleverapps.styles.UI.Button.Images.wildcard_button_green,
            width: styles.button.width,
            height: styles.button.height,
            content: text,
            disabled: cleverapps.stickersBook.jokers === 0,
            onClicked: function () {
                this.removeStickerTooltip();

                target.clickHandler.remove();
                target.clickHandler = undefined;

                this.collection.jokerAnimationActive = true;
                target.sticker.open(Sticker.SOURCES.joker);
                cleverapps.stickersBook.useJoker();

                this.close();

                cleverapps.meta.display({
                    stack: true,
                    focus: "UseJoker",
                    action: function (f) {
                        cleverapps.windows.currentWindow().useJokerAnimation(target.sticker, f);
                    }
                });
            }.bind(this)
        });

        bg.addChild(button);
        button.setPositionRound(styles.button);

        target.addChild(bg);
        bg.setPositionRound(styles);
        bg.replaceParentSamePlace(this.content, 100);

        bg.setScale(0.2);
        bg.setOpacity(0);
        bg.runAction(new cc.Sequence(
            new cc.Show(),
            new cc.Spawn(
                new cc.FadeIn(0.1),
                new cc.ScaleTo(0.1, 1)
            ).easing(cc.easeInOut(2))
        ));

        this.stickerTooltip = bg;
        this.stickerTooltip.target = target;
    },

    removeStickerTooltip: function () {
        if (!this.stickerTooltip) {
            return;
        }
        var stickerTooltip = this.stickerTooltip;
        this.stickerTooltip = undefined;

        stickerTooltip.stopAllActions();
        stickerTooltip.runAction(
            new cc.Sequence(
                new cc.Spawn(
                    new cc.ScaleTo(0.2, 0.3),
                    new cc.FadeOut(0.2)
                ).easing(cc.easeInOut(2)),
                new cc.CallFunc(function () {
                    stickerTooltip.removeFromParent(true);
                })
            )
        );
    },

    onClick: function (target) {
        if (target && this.stickerTooltip && target === this.stickerTooltip.target) {
            return;
        }

        this.removeStickerTooltip();

        if (target) {
            this.createStickerTooltip(target);
        }
    },

    addComponent: function () {
        if (this.component) {
            this.component.removeFromParent();
            this.component = undefined;
        }

        var ComponentClass = StickersBook.isPuzzle() ? PuzzlesComponent : StickersComponent;
        this.component = new ComponentClass(this.collection);

        this.stickers = this.component.stickers;
        this.stickers.forEach(function (stickerView) {
            this.addTooltipHandler(stickerView);
        }.bind(this));

        this.content.addChild(this.component);
        this.content.setLocalZOrder(5);
    },

    onDoubleClick: function (stickerView) {
        this.removeStickerTooltip();
        stickerView.sticker.open();
        this.addComponent();
    },

    onClose: function () {
        this.removeStickerTooltip();
        this.component.onClose();
    },

    listBundles: function () {
        return ["collection_" + this.collection.index, "stickers_collection_window"];
    }
});

cleverapps.styles.StickersCollectionWindow = {
    x: { align: "center" },
    y: { align: "center" },

    size: [
        { width: 1000, height: 1300 },
        { width: 1000, height: 1300 },
        { width: 1570, height: 1025 }
    ],

    window: {
        padding: {
            left: 24,
            right: 27,
            top: 30,
            bottom: 30
        }
    },

    prize: {
        position: [
            { x: { align: "center" }, y: { align: "top", dy: -120 } },
            { x: { align: "center" }, y: { align: "top", dy: -120 } },
            { x: { align: "center" }, y: { align: "top", dy: -140 } }
        ],
        width: 400
    },

    textBg: {
        x: {
            align: "center"
        },
        y: {
            align: "bottom",
            dy: 15
        },
        width: 100
    },

    tooltip: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: -185 },
        button: {
            width: 260,
            height: 140,
            x: { align: "center" },
            y: { align: "center", dy: -15 }
        },
        arrow: {
            x: { align: "center", dx: 0 },
            y: { align: "top", dy: 18 },
            rotation: 180
        },
        text: {
            dimensionWidth: 160,
            maxWidth: 170,
            maxHeight: 90
        }
    },

    jokers: {
        position: [
            { x: { align: "right", dx: -64 }, y: { align: "top", dy: -104 } },
            { x: { align: "right", dx: -64 }, y: { align: "top", dy: -104 } },
            { x: { align: "right", dx: -60 }, y: { align: "top", dy: -124 } }
        ]
    },

    checkmark: {
        margin: 20
    },

    highlight: {
        x: { align: "center" },
        y: { align: "center" }
    },

    text: {
        dy: 4
    }
};
