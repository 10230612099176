/**
 * Created by r4zi4l on 03.08.2021
 */

// update config from here: https://docs.google.com/spreadsheets/d/1V--Y1ZfuxNuNk-LWxsWaSEN7E64n66g7c84q8r6GYrU/edit?gid=686562638#gid=686562638

var AdsLimits = function () {
    cleverapps.EventEmitter.call(this);

    AdsLimits.initialize();

    this.load();

    this.dirty = false;

    this.volumeControl = {
        general: 1
    };

    this.updateVolume();
    this.update();

    cleverapps.rewardedAdsManager.onRewardedRefresh(this.notifyUpdated.bind(this));
    new cleverapps.Interval(this.update.bind(this), cleverapps.parseInterval(AdsLimits.CHECK_INTERVAL));

    cleverapps.flags.on("change:highMonetization", this.updateVolume.bind(this));
    cleverapps.flags.on("change:lowMonetization", this.updateVolume.bind(this));
    connector.platform.on("changeStatus", this.updateVolume.bind(this));
};

AdsLimits.prototype = Object.create(cleverapps.EventEmitter.prototype);
AdsLimits.prototype.constructor = AdsLimits;

AdsLimits.prototype.save = function () {
    cleverapps.dataLoader.save(DataLoader.TYPES.ADS_LIMITS_DATA, this.data);
};

AdsLimits.prototype.load = function () {
    this.data = cleverapps.dataLoader.load(DataLoader.TYPES.ADS_LIMITS_DATA) || {};

    // cleanup: remove obsolete data
    var types = Object.values(AdsLimits.TYPES);
    Object.keys(this.data).forEach(function (type) {
        if (types.indexOf(type) === -1) {
            delete this.data[type];
        }
    }, this);
};

AdsLimits.prototype._updateType = function (type) {
    var data = this.data[type];
    var limit = this.getLimit(type);

    if (limit.disabled || data && data.watchCount > 0 && this.getLimitLeftTime(type) === 0) {
        delete this.data[type];

        this.save();
        this.dirty = true;
    }
};

AdsLimits.prototype.setVolume = function (value, type) {
    if (!type) {
        this.volumeControl.general = value;
    } else if (this.volumeControl[type] !== undefined) {
        this.volumeControl[type] *= value;
    } else {
        this.volumeControl[type] = value;
    }
};

AdsLimits.prototype.getVolume = function (type) {
    if (this.volumeControl[type] !== undefined) {
        return this.volumeControl[type];
    }
    return this.volumeControl.general;
};

AdsLimits.prototype.getLimitTimeout = function (type) {
    var limit = this.getLimit(type);
    if (limit && limit.timeout) {
        return cleverapps.parseInterval(limit.timeout) / this.getVolume(type);
    }
};

AdsLimits.prototype.notifyUpdated = function () {
    this.trigger("update");
};

AdsLimits.prototype.updateVolume = function () {
    this.volumeControl = {
        general: 1
    };

    if (cleverapps.config.debugMode) {
        this.setVolume(10);
    }

    if (connector.platform.oneOf(connector.YANDEX)) {
        this.setVolume(2.665, AdsLimits.TYPES.INTERSTITIAL);
    }
    if (connector.platform.oneOf(connector.OK, connector.VK, connector.TEST)) {
        this.setVolume(2, AdsLimits.TYPES.INTERSTITIAL);
    }

    if (connector.platform.oneOf(connector.CRAZY) && cleverapps.flags.highMonetization) {
        this.setVolume(0, AdsLimits.TYPES.INTERSTITIAL);
        this.setVolume(0, AdsLimits.TYPES.BANNER);
    }

    if (connector.platform.oneOf(connector.INSTANT) && !connector.info.isMobile && cleverapps.flags.highMonetization) {
        this.setVolume(0, AdsLimits.TYPES.INTERSTITIAL);
    }

    if (cleverapps.config.type === "blocks") {
        this.setVolume(2, AdsLimits.TYPES.INTERSTITIAL);
    }

    if (connector.ads.oneOf(connector.YANDEX_WEB_ADS, connector.VK)) {
        this.setVolume(0, AdsLimits.TYPES.PREROLL);
    }

    if (cleverapps.flags.lowMonetization) {
        this.setVolume(4, AdsLimits.TYPES.INTERSTITIAL);
    }
};

AdsLimits.prototype.update = function () {
    for (var key in AdsLimits.TYPES) {
        this._updateType(AdsLimits.TYPES[key]);
    }
    if (this.dirty) {
        this.dirty = false;
        this.notifyUpdated();
    }
};

AdsLimits.prototype.state = function (type) {
    this._updateType(type);
    var limit = this.getLimit(type);

    if (limit.disabled) {
        return AdsLimits.STATE_DISABLED;
    }

    if (cleverapps.flags.lowMonetization && type === AdsLimits.TYPES.INTERSTITIAL) {
        if (!cleverapps.user.checkAvailable({ level: 0.13 })) {
            return AdsLimits.STATE_UNAVAILABLE;
        }
    } else if (!cleverapps.user.checkAvailable(limit.available) || this.getVolume(type) === 0) {
        return AdsLimits.STATE_UNAVAILABLE;
    }

    if (this.getLimitLeftCount(type) === 0) {
        return AdsLimits.STATE_LIMITED;
    }

    if (type === AdsLimits.TYPES.BANNER) {
        if (!connector.ads.isStickyAvailable) {
            return AdsLimits.STATE_NOADS;
        }
    } else if ([AdsLimits.TYPES.INTERSTITIAL, AdsLimits.TYPES.PREROLL].indexOf(type) !== -1) {
        if (!connector.ads.isInterstitialAvailable) {
            return AdsLimits.STATE_NOADS;
        }
    } else if (!cleverapps.rewardedAdsManager.isRewardedAvailable()) {
        return AdsLimits.STATE_NOADS;
    }

    return AdsLimits.STATE_READY;
};

AdsLimits.prototype.getLimit = function (type) {
    var data = AdsLimits.LIMITS[type];

    if (!data) {
        if (cleverapps.config.debugMode) {
            console.error("No ads limit for type: " + type);
        }
        data = { disabled: true };
    }

    if (data && data.nonpayer) {
        var status = cleverapps.paymentsHistory.classify();

        if (status === cleverapps.PaymentsHistory.BRACKET_NONE) {
            return data.nonpayer;
        }
        if (status === cleverapps.PaymentsHistory.BRACKET_UNDECIDED) {
            return data.undecided;
        }

        return data.payer;
    }

    return data;
};

AdsLimits.prototype.getLimitLeftTime = function (type) {
    var data = this.data[type] || {
        lastRefresh: 0
    };

    return Math.max(0, data.lastRefresh + this.getLimitTimeout(type) - Date.now());
};

AdsLimits.prototype.getWatchCount = function (type) {
    return this.data[type] && this.data[type].watchCount || 0;
};

AdsLimits.prototype.getLimitLeftCount = function (type) {
    var limit = this.getLimit(type);

    if (limit.limit === AdsLimits.UNLIMITED) {
        return 1;
    }

    var data = this.data[type] || {
        watchCount: 0
    };
    return Math.max(0, limit.limit - data.watchCount);
};

AdsLimits.prototype.getLimitLevel = function (type) {
    var limit = this.getLimit(type);

    return cleverapps.humanReadableNumber({ floatLevel: limit.available.level });
};

AdsLimits.prototype.watch = function (type) {
    if (this.data[type]) {
        this.data[type].watchCount++;
    } else {
        this.data[type] = {
            watchCount: 1,
            lastRefresh: Date.now()
        };
    }

    this.save();
    cleverapps.timeouts.setTimeout(this.update.bind(this), this.getLimitTimeout(type));

    this.notifyUpdated();
};

AdsLimits.prototype.reset = function (type) {
    delete this.data[type];
    this.save();
};

AdsLimits.STATE_UNAVAILABLE = 0;
AdsLimits.STATE_LIMITED = 1;
AdsLimits.STATE_NOADS = 2;
AdsLimits.STATE_READY = 3;
AdsLimits.STATE_DISABLED = 4;

AdsLimits.CHECK_INTERVAL = "1 minute";

AdsLimits.TYPES = {
    HARD: "hard",
    WORKER: "worker",
    LIVES: "lives",
    ENERGY_SHORT: "energy_short",
    BARREL: "barrel",
    SPEEDUP: "speedup",
    THIRD_ELEMENT: "third_element",
    PIXEL: "pixel",
    OPEN_CHEST: "chest",
    BOOSTERS_BEFORE: "booster_before",
    FLYING_HINT: "flying_hint",
    FLYING_STAR: "flying_star",
    FLYING_BOOSTER: "flying_booster",
    FLYING_DISCOVER: "flying_discover",
    FLYING_MOVES: "flying_moves",
    FLYING_HARD_SOFT: "flying_hard_soft",
    TOOLBAR: "toolbar",
    POINTS_THIRD_ELEMENT: "third_element_points",
    ANIMALS_THIRD_ELEMENT: "third_element_animals",
    BANK: "bank",
    FREEX2: "freex2",
    PRODUCT: "product",
    CINEMA: "cinema",
    PROLONGATION: "extend",
    CHOICE: "choice",
    ENERGY: "energy",
    ENERGY_2: "energy_2",
    ENERGY_2_SHORT: "energy_short_2",
    ENERGY_1: "energy_1",
    ENERGY_1_SHORT: "energy_short_1",
    ENERGY_3: "energy_3",
    ENERGY_3_SHORT: "energy_short_3",
    ENERGY_4: "energy_4",
    ENERGY_4_SHORT: "energy_short_4",
    BOOSTER_1: "booster_1",
    BOOSTER_2: "booster_2",
    BOOSTER_3: "booster_3",
    BOOSTER_ADS: "booster_ads",
    INTERSTITIAL: "interstitial",
    PREROLL: "preroll",
    SHOVEL: "shovel",
    BANNER: "banner",
    CHAIN: "chain",
    PRIZE_CALENDAR: "prize_calendar",
    PINBALL_CHIPS: "pinball_chips"
};

AdsLimits.UNLIMITED = "unlimited";

AdsLimits.LIMITS = {};

AdsLimits.initialize = function () {
    var res = bundles.ads_limits_data.jsons.ads_limits_json.getJson();
    AdsLimits.LIMITS = res["adslimits"];
};
