/**
 * Created by andrey on 29.05.17.
 */
var EditorController = function (editor, scene, editorView) {
    editorView.fieldSizeBlock.onSetFieldSize = function (delta) {
        editor.changeFieldSize(delta);
    };

    editor.onChangeFieldSize = function (fieldSize) {
        editorView.field.drawFieldRect(fieldSize);
    };

    editorView.field.onClick = function (x, y) {
        if (!editorView.tab) {
            return false;
        }

        var active = editorView.tab.getCurrentView();
        if (!active) {
            return false;
        }

        var data = active.getData(editor);

        return editor.clickField(x, y, data.selected, data.type);
    };

    editor.onBackgroundAdded = function (x, y) {
        editorView.addBackground(x, y);
    };

    editor.onDeleteBackground = function (x, y) {
        editorView.deleteBackground(x, y);
    };

    editor.onCellChanged = function (cell) {
        editorView.cellChanged(cell);
    };

    editor.onDeleteCell = function (cell) {
        editorView.cellDeleted(cell);
    };

    editor.onTileChanged = function (tile) {
        editorView.tileChanged(tile);
    };

    editor.onDeleteTile = function (tile) {
        editorView.tileDeleted(tile);
    };

    editor.onChangeMarkCount = function (marks) {
        editorView.marksBlock.displayMarksAmount(marks);
    };

    editor.onCellSkinChanged = function () {
        scene.saveCellSkin();
    };

    editor.onUpdateBarriersView = function () {
        if (editorView.barriersView) {
            editorView.barriersView.removeFromParent();
        }
        editorView.barriersView = new BarriersView(editor, EditorView.alignInTheGrid);
        editorView.barriersView.scale = editorView.field.scale;
        editorView.barriersView.setContentSize(editorView.field.getContentSize());
        editorView.barriersView.setAnchorPoint(0.5, 0.5);
        editorView.barriersView.setPosition(editorView.field.getPosition());
        editorView.addChild(editorView.barriersView);
    };

    editorView.setMarks = function (marks) {
        editor.setMarks(marks);
    };

    editorView.needSave = function () {
        editor.save();
    };

    editorView.colorsBlock.onBeforeSelect = function (code) {
        if (!editor.isColorActive(code) || this.selected === code) {
            editor.swapColor(code);
        }
    };

    editorView.colorsBlock.onSelect = function (code) {
        if (!editor.isColorActive(code)) {
            this.setSelected(undefined);
        }
    };

    editor.onChangeColors = function (colors) {
        var goalColors = [];
        ColorComponent.CODES.forEach(function (code) {
            if (editor.existGoal(code)) {
                goalColors.push(code.toUpperCase());
            }
        });
        editorView.colorsBlock.colorsChanged(colors, goalColors);
    };

    Game.currentGame.field.on("addCell", function (cell) {
        editorView.cellChanged(cell);
    }, editorView);

    Game.currentGame.goals.on("showUpAnimationStart", function (f) {
        f();
    });

    Game.currentGame.field.on("showUpFloor", function (f) {
        f();
    });
};
