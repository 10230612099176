/**
 * Created by andrey on 14.09.2022
 */

var PaymentOverlay = cc.Scale9Sprite.extend({
    ctor: function () {
        this._super(bundles.pixel.frames.pixel_png);

        this.alwaysOn = true;

        this.setColor(cleverapps.styles.COLORS.BLACK);
        this.setLocalZOrder(Transition.ZORDER + 1);

        cleverapps.UI.onClick(this, function () {
            if (this.closeText.isVisible()) {
                this.startCancel();
            }
        }.bind(this), {
            interactiveScale: false,
            swallowEvents: true
        });

        this.createText();
        this.createCloseText();
        this.showLoading();

        this.setupChildren();

        this.setOpacity(0);
        this.runAction(new cc.Sequence(
            new cc.DelayTime(PaymentOverlay.SHOW_TIMEOUT / 1000),
            new cc.CallFunc(function () {
                this.hideLoading();
            }.bind(this)),
            new cc.FadeTo(0.3, 80)
        ));
    },

    showLoading: function () {
        var loading = this.loading = new LoadingAnimation();
        loading.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(loading);

        loading.setVisible(false);
        loading.runAction(new cc.Sequence(
            new cc.DelayTime(0.2),
            new cc.Show()
        ));
    },

    hideLoading: function () {
        if (this.loading) {
            this.loading.removeFromParent();
            delete this.loading;
        }
    },

    setupChildren: function () {
        var styles = cleverapps.styles.PaymentOverlay;

        var bgSize = cleverapps.resolution.getBgSize();
        var safePadding = cleverapps.resolution.getSafePadding();
        this.setContentSize2(bgSize.width, bgSize.height);
        this.setAnchorPoint(0.5, 0.5);
        this.setPositionRound(bgSize.width / 2 - safePadding.left, bgSize.height / 2 - safePadding.bottom);

        if (this.loading) {
            this.loading.setPositionRound(this.width / 2, this.height / 2);
        }

        if (this.text) {
            this.text.setDimensions(this.width, 0);
            this.text.fitTo(this.width);
            this.text.setPositionRound(styles.text);
        }

        if (this.closeText) {
            this.closeText.setDimensions(this.width, 0);
            this.closeText.fitTo(this.width);
            this.closeText.setPositionRound(styles.closeText);
        }
    },

    createText: function () {
        var text = this.text = cleverapps.UI.generateOnlyText("ProcessingInProgress", cleverapps.styles.FONTS.REWARDED_AD_OVERLAY_TEXT);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        this.addChild(text);

        text.setOpacity(0);
        text.runAction(new cc.Sequence(
            new cc.DelayTime(PaymentOverlay.SHOW_TIMEOUT / 1000),
            new cc.FadeIn(0.3)
        ));
    },

    createCloseText: function () {
        var closeText = this.closeText = cleverapps.UI.generateOnlyText("Window.TapToContinue", cleverapps.styles.FONTS.PAYMENT_OVERLAY_CLOSE_TEXT);
        closeText.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        this.addChild(closeText);

        closeText.setOpacity(0);
        closeText.setVisible(false);
        closeText.runAction(new cc.Sequence(
            new cc.DelayTime(PaymentOverlay.CLOSE_AVAILABLE / 1000),
            new cc.Show(),
            new cc.FadeIn(0.3)
        ));
    },

    startCancel: function () {
        this.text.setVisible(false);
        this.closeText.setVisible(false);

        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.PAYMENTS_TERMINATED);

        this.showLoading();

        var delay = PaymentOverlay.CANCEL_DURATION / 1000;
        if (connector.payments.getPurchaseState() === "validate") {
            delay += 5;
        }

        this.runAction(new cc.Sequence(
            new cc.DelayTime(delay),
            new cc.CallFunc(function () {
                this.hideLoading();

                connector.payments.stopCurrentPurchase();
            }.bind(this))
        ));
    },

    onEnter: function () {
        this._super();

        console.log("PaymentsManager overlay created");
    },

    onExit: function () {
        this._super();

        console.log("PaymentsManager overlay closed");
    }
});

PaymentOverlay.initialize = function () {
    var showTimeout = "4 seconds";
    var closeAvailable = "15 seconds";
    var cancelDuration = "10 seconds";

    if (connector.payments.oneOf(connector.MM) && connector.info.os === connector.OS_IOS) {
        closeAvailable = "5 seconds";
    }
    if (connector.platform.oneOf(connector.CLEVERAPPS, connector.MSSTART)) {
        closeAvailable = "30 seconds";
    }
    if (connector.platform.oneOf(connector.IOS, connector.MACOS)) {
        closeAvailable = "30 seconds";
        cancelDuration = "20 seconds";
    }
    if (connector.payments.oneOf(connector.YOOKASSA)) {
        closeAvailable = "5 seconds";
        cancelDuration = "2 seconds";
    }
    if (connector.platform.oneOf(connector.DRAUGIEM)) {
        closeAvailable = showTimeout;
        cancelDuration = "5 seconds";
    }

    PaymentOverlay.SHOW_TIMEOUT = cleverapps.parseInterval(showTimeout);
    PaymentOverlay.CLOSE_AVAILABLE = cleverapps.parseInterval(closeAvailable);
    PaymentOverlay.CANCEL_DURATION = cleverapps.parseInterval(cancelDuration);
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    PAYMENT_OVERLAY_TEXT: {
        size: 60,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },

    PAYMENT_OVERLAY_CLOSE_TEXT: {
        size: 30,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    }
});

cleverapps.styles.PaymentOverlay = {
    text: {
        x: { align: "center" },
        y: { align: "center" }
    },

    closeText: {
        x: { align: "center" },
        y: { align: "center", dy: -100 }
    }
};
