/**
 * Created by Aleksandr on 15.09.2022.
 */

var ClipPreviewBar = function (prefabPlayer) {
    this.prefabPlayer = prefabPlayer;

    this.clipProps = this.prefabPlayer.options.clipConfig.properties;

    this.onHide = function () {};
    this.onShow = function () {};
    this.onResetTimer = function () {};
    this.onStopTimer = function () {};
};

ClipPreviewBar.prototype.replay = function () {
    this.prefabPlayer.reloadSnapshot();
};

ClipPreviewBar.prototype.download = function (downloadActions) {
    var player = new ActionPlayer();

    player.add(function (f) {
        cleverapps.loadSdk("//cdnjs.cloudflare.com/ajax/libs/jszip/3.10.1/jszip.min.js", {
            onSuccess: f
        });
    });

    downloadActions.forEach(function (action, index) {
        player.add(function (f) {
            var needReload = !this.prefabPlayer.screenshotParameters || index === 0;

            if (this.prefabPlayer.options.language !== action.language) {
                needReload = true;
                cleverapps.settings.setLanguage(action.language);
                cleverapps.settings.updateDependents();
            }

            cleverapps.setUrlHash({ res: action.resolution, lang: action.language });
            this.prefabPlayer.options.download = true;
            this.prefabPlayer.options.language = action.language;
            this.prefabPlayer.options.resolutionName = action.resolution;
            this.prefabPlayer.options.onFinish = f;

            if (needReload) {
                this.prefabPlayer.reloadSnapshot();
            } else {
                this.prefabPlayer.run();
            }
        }.bind(this));
    }.bind(this));

    player.add(function (f) {
        this.prefabPlayer.completeDownload(function () {
            f();
            window.location.reload();
        });
    }.bind(this));

    player.play();
};

ClipPreviewBar.prototype.edit = function () {
    cleverapps.setUrlHash({ wysiwyg: true, snapshot: undefined });
    this.prefabPlayer.options.snapshot = undefined;
    window.location.reload();
};

ClipPreviewBar.prototype.changeLanguage = function (language) {
    cleverapps.setUrlHash({ lang: language });
    this.prefabPlayer.options.language = language;
    cleverapps.settings.setLanguage(language);
    cleverapps.settings.updateDependents();
    this.prefabPlayer.reloadSnapshot();
};

ClipPreviewBar.prototype.changeResolution = function (resolution) {
    cleverapps.setUrlHash({ res: resolution });
    this.prefabPlayer.options.resolutionName = resolution;

    if (this.prefabPlayer.screenshotParameters) {
        this.prefabPlayer.run();
    } else {
        this.prefabPlayer.reloadSnapshot();
    }
};

ClipPreviewBar.prototype.resetTimer = function () {
    this.onResetTimer();
};

ClipPreviewBar.prototype.stopTimer = function () {
    this.onStopTimer();
};