/**
 * Created by andrey on 18.06.2024
 */

var VirtualFrame = function (bundleName, key, name) {
    VirtualResource.call(this, bundleName, key);

    if (name) {
        this.name = name;
    }
};

VirtualFrame.prototype = Object.create(VirtualResource.prototype);
VirtualFrame.prototype.constructor = VirtualFrame;

cc.VirtualFrame = VirtualFrame;

VirtualFrame.prototype.setFrameData = function (data) {
    var spriteFrame;
    var texture = cc.textureCache.getTextureForKey(bundles[this.bundleName].urls.png);

    if (engine === "cocos2d") {
        spriteFrame = new cc.SpriteFrame(texture, cc.rect(data.rect), data.rotated, data.offset, data.size);
    } else {
        spriteFrame = new cc.SpriteFrame();
        spriteFrame.texture = texture;
        spriteFrame.rect = data.rect;
        spriteFrame.originalSize = data.size;
        spriteFrame.offset = data.offset;

        if (data.rotated) {
            spriteFrame.rotated = true;
            spriteFrame.flipUVX = true;
            spriteFrame.flipUVY = true;
        }
    }

    spriteFrame.bundleName = this.bundleName;

    this.spriteFrame = spriteFrame;
};

VirtualFrame.prototype.onUnload = function () {
    delete this.spriteFrame;
};

VirtualFrame.prototype.getSpriteFrame = function () {
    return this.spriteFrame;
};

VirtualFrame.prototype.save = function () {
    return this.name;
};