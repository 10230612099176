/**
 * Created by Andrey Popov on 1/28/21.
 */

var PositionPropertyEditor = function (property, initialValue, onChangeValueCallback) {
    this.property = property;
    this.onChangeValueCallback = onChangeValueCallback;

    if (initialValue && !initialValue.general) {
        initialValue = {
            general: initialValue
        };
    }

    this.value = cleverapps.override({
        general: {
            x: {
                align: "center", dx: 0
            },
            y: {
                align: "center", dy: 0
            }
        }
    }, initialValue);

    var resolution = cleverapps.wysiwyg.wysiwygPreview.selectedResolution;
    if (!this.value[resolution]) {
        this.value[resolution] = {};
    }

    this.propertyEditors = [{
        name: property.name + " align",
        items: ["left", "center", "right"],
        getter: function () {
            return this.value.general.x.align;
        }.bind(this),
        setter: function (value) {
            this.value.general.x.align = value;
        }.bind(this)
    }, {
        name: property.name + " dx",
        type: "number",
        allowOverride: true,
        getters: [function () {
            return this.value.general.x.dx;
        }.bind(this), function () {
            return this.value[resolution].x ? this.value[resolution].x.dx : undefined;
        }.bind(this)],
        setters: [function (value) {
            this.value.general.x.dx = parseFloat(value) || 0;
        }.bind(this), function (value) {
            if (value !== undefined && !isNaN(parseFloat(value))) {
                if (!this.value[resolution].x) {
                    this.value[resolution].x = {};
                }

                this.value[resolution].x.dx = parseFloat(value) || 0;
            } else {
                delete this.value[resolution].x;
            }
        }.bind(this)]
    }, {
        name: property.name + " align",
        items: ["top", "center", "bottom"],
        getter: function () {
            return this.value.general.y.align;
        }.bind(this),
        setter: function (value) {
            this.value.general.y.align = value;
        }.bind(this)
    }, {
        name: property.name + " dy",
        allowOverride: true,
        type: "number",
        getters: [function () {
            return this.value.general.y.dy;
        }.bind(this), function () {
            return this.value[resolution].y ? this.value[resolution].y.dy : undefined;
        }.bind(this)],
        setters: [function (value) {
            this.value.general.y.dy = parseFloat(value) || 0;
        }.bind(this), function (value) {
            if (value !== undefined && !isNaN(parseFloat(value))) {
                if (!this.value[resolution].y) {
                    this.value[resolution].y = {};
                }
                this.value[resolution].y.dy = parseFloat(value) || 0;
            } else {
                delete this.value[resolution].y;
            }
        }.bind(this)]
    }].map(function (propertyDescription) {
        return new PropertyEditor(propertyDescription, this);
    }.bind(this));
};

PositionPropertyEditor.prototype.editBoxEditingDidEnd = function (sender) {
    sender.customSetter(sender.getString());
    this.onChangeValueCallback(this.property.name, this.value);

    this.propertyEditors.forEach(function (propertyEditor) {
        propertyEditor.updateOverrides();
    });
};