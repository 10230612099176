/**
 * Created by Denis Kuzin on 05 october 2023
 */

var CoinCell = function (x, y) {
    BaseCell.call(this, x, y);
    this.movable = true;
    this.putInPoolAvailable = true;
};

CoinCell.prototype = Object.create(BaseCell.prototype);
CoinCell.prototype.constructor = CoinCell;

CoinCell.prototype.bundleId = function () {
    return "coin_cell";
};

CoinCell.prototype.load = function () {
};

CoinCell.prototype.save = function () {
    return CoinCell.CODES;
};

CoinCell.prototype.getViewClass = function () {
    return CoinCellView;
};

CoinCell.prototype.getGoalId = function () {
    return CoinCell.GOAL_ID;
};

CoinCell.prototype.boom = function () {
    this.hurt();
};

CoinCell.prototype.explode = function () {
    if (Game.currentGame.goals.hasType(this.getGoalId())) {
        BaseCell.prototype.explodeViaCollect.call(this);
    } else {
        BaseCell.prototype.explode.call(this);
    }
};

CoinCell.GOAL_ID = "coin_cell";