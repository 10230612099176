/**
 * Created by andrey on 21.08.17.
 */

var CrabAnimation = BaseAnimation.extend({
    ctor: function (bundle, lives) {
        this._super(bundles[bundle].jsons[bundle + "_spine_json"], bundles[bundle].frames["cell_crab" + lives]);
    }
});

CrabAnimation.factory = function (bundle, lives) {
    return cc.pool.hasObject(CrabAnimation) ? cc.pool.getFromPool(CrabAnimation) : new CrabAnimation(bundle, lives);
};