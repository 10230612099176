/**
 * Created by spepa on 22.05.2024
 */

var YoutubeLogic = function () {
    PlatformLogic.call(this);
};

YoutubeLogic.prototype = Object.create(PlatformLogic.prototype);
YoutubeLogic.prototype.constructor = YoutubeLogic;

YoutubeLogic.prototype.reportScore = function (score, callback) {
    if (cleverapps.config.type === "merge") {
        return;
    }

    ytgame.engagement.sendScore({ value: score }).then(function () {
        callback(cleverapps.CODE_SUCCEED);
    }).catch(function () {
        callback(cleverapps.CODE_FAILED);
    });
};

YoutubeLogic.prototype.onStart = function () {
    cleverapps.flags.set("largeTextHotfix", true);
};