/**
 * Created by Andrey Popov on 11/29/23.
 */

var IFramePreview = cc.Node.extend({
    ctor: function (url, size) {
        this._super();

        this.setContentSize(size);
        this.setLocalZOrder(100);

        var iFrame = this.iFrameElement = document.createElement("iframe");
        iFrame.id = "WysiwygPreviewIFrame";

        iFrame.style.position = "absolute";
        iFrame.style.zIndex = "-1";
        iFrame.style.border = "none";
        iFrame.src = url;
        iFrame.style.outline = "none";

        document.getElementById("Cocos2dGameContainer").appendChild(this.iFrameElement);

        window.addEventListener("message", this.handleMessageFromPreview.bind(this));

        this.componentBoxes = {};
    },

    hideIFrame: function () {
        this.iFrameElement.style.display = "none";
        this.setVisible(false);
    },

    showIFrame: function () {
        var boundingBox = this.getGlobalBoundingBox();

        var scale = cc.view.getScaleY() / cc.view._devicePixelRatio;

        this.iFrameElement.height = boundingBox.height * scale + "px";
        this.iFrameElement.width = boundingBox.width * scale + "px";
        this.iFrameElement.style.left = boundingBox.x * scale + "px";
        this.iFrameElement.style.bottom = boundingBox.y * scale + "px";

        this.iFrameElement.style.display = "block";
        this.setVisible(true);
        this.showLoading(true);
        setTimeout(this.showLoading.bind(this, false), 5000);
    },

    sendMessage: function (data) {
        this.iFrameElement.contentWindow.postMessage(JSON.stringify(data));
    },

    selectComponent: function (item) {
        if (this.selectedComponentBox) {
            this.selectedComponentBox.updateItemTools(false);
            delete this.selectedComponentBox;
        }

        if (item) {
            this.selectedComponentBox = this.componentBoxes[item.id];

            if (this.selectedComponentBox) {
                this.selectedComponentBox.updateItemTools(true);
            }
        }
    },

    handleMessageFromPreview: function (event) {
        var data = JSON.parse(event.data);
        var hierarchy = cleverapps.wysiwyg.hierarchy;

        switch (data.type) {
            case "componentBoxChanged":
                var componentBox = this.componentBoxes[data.id];
                var hierarchyItem = hierarchy.items.filter(function (item) {
                    return item.id === data.id;
                })[0];

                if (!componentBox && hierarchyItem) {
                    var parentNode = this;
                    if (hierarchyItem.parentItem && hierarchyItem.parentItem.type === HierarchyItem.TYPE.COMPONENT && this.componentBoxes[hierarchyItem.parentItem.id]) {
                        parentNode = this.componentBoxes[hierarchyItem.parentItem.id];
                    }

                    componentBox = new ComponentBoxView(hierarchyItem, parentNode);

                    this.componentBoxes[data.id] = componentBox;
                } else if (this.componentBoxes[data.id]) {
                    this.componentBoxes[data.id].updateHierarchyItem(hierarchyItem);
                }

                var scale = this.getGlobalBoundingBox().height / (cleverapps.resolution.getSceneSize().height * cc.Base.GetHierarchyScale(this).y);
                componentBox.updateBox(data.boundingBox, scale);
                break;
            case "sceneLoaded":
                this.showLoading(false);
                this.selectComponent(cleverapps.wysiwyg.hierarchy.selectedItem);
                break;
            case "newAdHocItem":
                var propValue = hierarchy.selectedItem.properties.adHoc || [];
                if (propValue.filter(function (override) {
                    return override.bundle === data.bundle && (override.image && override.image === data.image || override.spine && override.spine === data.spine);
                }).length === 0) {
                    propValue.push({
                        bundle: data.bundle,
                        image: data.image,
                        spine: data.spine
                    });
                    hierarchy.changeItemProperty(hierarchy.selectedItem, "adHoc", propValue);
                    cleverapps.wysiwyg.inspector.showItemProperties();
                }

                break;
        }
    },

    showLoading: function (visible) {
        if (this.loading) {
            this.loading.removeFromParent();
            delete this.loading;
        }

        if (visible) {
            this.loading = new cc.Sprite(bundles.wysiwyg.frames.loading);
            this.loading.setPositionRound(this.width / 2, this.height / 2);
            this.loading.runAction(new cc.RepeatForever(new cc.RotateBy(5, 360)));
            this.addChild(this.loading);
        }
    }
});