/**
 * Created by Reda on 12.07.2024
 */

var StickersShopItemView = cc.Node.extend({
    ctor: function (product, shopWindow) {
        this._super();

        this.product = product;
        this.shopWindow = shopWindow;

        this.createBg();
        this.createImage();
        this.createLabel();
        this.createReward();
        this.createButton();
    },

    createBg: function () {
        var styles = cleverapps.styles.StickersShopItemView;
        var bg = cleverapps.UI.createScale9Sprite(bundles.stickers_shop.frames.tile_bg_png, cleverapps.UI.Scale9Rect.TwoPixelXY);
        bg.setContentSize2(styles.size);
        this.setContentSize2(bg.getContentSize());
        bg.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(bg);

        if (styles.inner_bg) {
            var innerBg = new cc.Scale9Sprite(bundles.stickers_shop.frames.inner_bg_png || bundles.stickers_book_common.frames.foreground_png);
            innerBg.setContentSize2(styles.inner_bg.size);
            innerBg.setPositionRound(styles.inner_bg.position);
            bg.addChild(innerBg);
        }
    },

    createImage: function () {
        var styles = cleverapps.styles.StickersShopItemView.image;
        var image = this.image = new cc.Sprite(this.product.icon);
        image.setPositionRound(styles.position);
        this.addChild(image);
    },

    createLabel: function () {
        var styles = cleverapps.styles.StickersShopItemView;
        var text = this.title = cleverapps.UI.generateOnlyText(
            this.product.title,
            cleverapps.styles.FONTS.STICKERS_SHOP_LABEL
        );
        text.setPositionRound(styles.text);
        text.fitTo(styles.text.maxWidth);

        if (styles.ribbon) {
            var background = this.ribbon = cleverapps.UI.createScale9Sprite(bundles.stickers_shop.frames.ribbon_png);
            background.setPositionRound(styles.ribbon.position);
            background.setContentSize2(styles.ribbon.size);
            background.addChild(text);
            this.addChild(background);
        } else {
            this.addChild(text);
        }
    },

    createReward: function () {
        var styles = cleverapps.styles.StickersShopItemView.rewards;
        var rewardComponent = new RewardsListComponent(this.product.reward, {
            columns: 2,
            fitToBox: styles.box[cleverapps.resolution.mode],
            font: cleverapps.styles.FONTS.STICKERS_SHOP_REWARD,
            textDirection: cleverapps.UI.HORIZONTAL,
            margin: styles.margin[cleverapps.resolution.mode],
            reverseRows: true,
            reverseColums: true
        });

        rewardComponent.setPositionRound(styles.position);
        this.addChild(rewardComponent);
    },

    buyProduct: function () {
        this.product.buy(function () {
            this.shopWindow.close();
        }.bind(this));
    },

    createButton: function () {
        var styles = cleverapps.styles.StickersShopItemView.button;

        var button = this.button = new cleverapps.UI.Button({
            text: this.product.getCurrentPrice(),
            onClicked: this.buyProduct.bind(this),
            width: styles.size[cleverapps.resolution.mode].width,
            height: styles.size[cleverapps.resolution.mode].height
        });

        button.setPositionRound(styles.position);
        this.addChild(button);
    },

    disable: function () {
        [this.image, this.ribbon, this.title].filter(Boolean).forEach(
            function (item) {
                item.setColor(new cc.Color(200, 200, 200, 180));
            }
        );
        this.button.disable();
        this.button.setString(Messages.get("TileShop.tile.level", {
            level: cleverapps.stickersBook.collections[this.product.availableAfterCollection].getAvailableLevel()
        }));
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    STICKERS_SHOP_REWARD: {
        name: "nostroke",
        size: 45,
        color: cleverapps.styles.COLORS.COLOR_WINDOW_TEXT
    },

    STICKERS_SHOP_LABEL: {
        name: "default",
        size: 45,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: {
            color: cleverapps.styles.COLORS.LIGHT_TEXT_STROKE_COLOR,
            size: 2
        },
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    }
});

cleverapps.styles.StickersShopItemView = {
    size: [
        { width: 830, height: 400 },
        { width: 500, height: 830 },
        { width: 500, height: 830 }
    ],

    image: {
        position: [
            {
                x: { align: "left", dx: 60 },
                y: { align: "center", dy: 0 }
            },
            {
                x: { align: "center", dx: 45 },
                y: { align: "center", dy: 250 }
            },
            {
                x: { align: "center", dx: 45 },
                y: { align: "center", dy: 250 }
            }
        ]
    },
    ribbon: {
        size: [
            { width: 550, height: 110 },
            { width: 550, height: 100 },
            { width: 550, height: 100 }
        ],
        position: [
            {
                x: { align: "left", dx: -10 },
                y: { align: "top", dy: 35 }
            },
            {
                x: { align: "center", dx: 12 },
                y: { align: "top", dy: -290 }
            },
            {
                x: { align: "center", dx: 12 },
                y: { align: "top", dy: -290 }
            }
        ]
    },

    text: {
        x: { align: "center" },
        y: { align: "center", dy: 7 },
        maxWidth: 400
    },

    rewards: {
        position: [{
            x: { align: "right", dx: -10 },
            y: { align: "center", dy: 15 }
        }, {
            x: { align: "center", dx: 0 },
            y: { align: "bottom", dy: 150 }
        },
        {
            x: { align: "center", dx: 0 },
            y: { align: "bottom", dy: 150 }
        }
        ],
        box: [{
            width: 400,
            height: 230
        }, {
            width: 380,
            height: 320
        }, {
            width: 380,
            height: 320
        }
        ],
        margin: [{
            x: 60,
            y: 10
        }, {
            x: 30,
            y: 20
        }, {
            x: 30,
            y: 20
        }
        ]
    },

    button: {
        size: [
            {
                width: 250,
                height: 72
            },
            {
                width: 300,
                height: 80
            },
            {
                width: 300,
                height: 80
            }],
        position: [
            {
                x: { align: "center" },
                y: { align: "bottom", dy: -1 }
            },
            {
                x: { align: "center" },
                y: { align: "bottom", dy: 70 }
            },
            {
                x: { align: "center" },
                y: { align: "bottom", dy: 70 }
            }
        ] 
    }
};