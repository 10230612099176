/**
 * Created by andrey on 05.09.17.
 */

cleverapps.StarChest = function (isNewUser) {
    cleverapps.Chest.call(this, {
        storage: DataLoader.TYPES.STAR_CHEST,
        serverTaskName: "starchests",
        isNewUser: isNewUser
    });
};

cleverapps.StarChest.prototype = Object.create(cleverapps.Chest.prototype);
cleverapps.StarChest.prototype.constructor = cleverapps.StarChest;

cleverapps.StarChest.prototype.fromJSON = function (data) {
    this.locked = true;
    this.setStars(data.stars || 0);
};

cleverapps.StarChest.prototype.toJSON = function () {
    return {
        stars: this.stars || 0
    };
};

cleverapps.StarChest.prototype.initializeForNewUser = function () {
    this.locked = true;
    this.setStars(0);
};

cleverapps.StarChest.prototype.addStars = function (stars, silent) {
    if (stars === 0) {
        return;
    }

    if (!stars) {
        cleverapps.throwAsync("Error addStars - " + stars);
        return;
    }

    this.setStars(this.stars + stars, silent);

    this.save();

    if (!silent) {
        cleverapps.sideBar.resetByClassName(StarChestIcon);
    }
};

cleverapps.StarChest.prototype.setStars = function (stars, silent) {
    if (stars < 0 || !stars) {
        stars = 0;
    }

    this.stars = stars;

    if (!silent) {
        this.onChangeStars();
    }
};

cleverapps.StarChest.prototype.onChangeStars = function () {
    if (this.locked && this.stars >= cleverapps.StarChest.GOAL_AMOUNT) {
        this.unlock();
    }
};

cleverapps.StarChest.prototype.updateInfo = function (serverData, fromServer) {
    this.locked = true;
    this.setStars(serverData.stars);
    this.save(!fromServer);
    this.changeLockedListener();
};

cleverapps.StarChest.prototype.open = function () {
    this.locked = true;
    this.addStars(-cleverapps.StarChest.GOAL_AMOUNT);
    this.changeLockedListener();
    cleverapps.eventBus.trigger("taskEvent", DailyTasks.OPEN_STAR_CHEST);
};

cleverapps.StarChest.prototype.getReward = function () {
    var reward = levels.user.isBeginner() ? cleverapps.StarChest.BEGINNER_PRIZE : cleverapps.Random.choose(cleverapps.StarChest.PRZIES);

    return cleverapps.user.prepareRewardByRich(reward);
};

cleverapps.StarChest.GOAL_AMOUNT = 20;

cleverapps.StarChest.BEGINNER_PRIZE = {
    hard: 50
};

cleverapps.StarChest.PRZIES = [
    {
        boosters: {
            5: 1
        },
        hard: 100
    },
    {
        boosters: {
            6: 1
        },
        hard: 100
    },
    {
        boosters: {
            7: 1
        },
        hard: 100
    },
    {
        boosters: {
            5: 2
        },
        hard: 100
    },
    {
        boosters: {
            6: 2
        },
        hard: 100
    },
    {
        boosters: {
            7: 2
        },
        hard: 100
    }
];