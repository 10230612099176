/**
 * Created by andrey on 28.03.2024.
 */

var SamsungLogger = function () {
};

SamsungLogger.prototype.logEvent = function (name, params) {
    if (!SamsungLogger.EVENTS[name]) {
        return;
    }

    GSInstant.logEvent(name, "string", JSON.stringify(params));
};

SamsungLogger.isApplicable = function () {
    return connector.platform.oneOf(connector.SAMSUNG);
};

SamsungLogger.EVENTS = ConversionManager.EVENTS;
