/**
 * Created by vladislav on 1/26/2024
 */

var MSStartLogic = function () {
    PlatformLogic.call(this);

    connector.ads.on("error:details:msstart:cache", this.onAdCacheError.bind(this));
    connector.ads.on("error:details:msstart:play", this.onAdPlayError.bind(this));
};

MSStartLogic.prototype = Object.create(PlatformLogic.prototype);
MSStartLogic.prototype.constructor = MSStartLogic;

MSStartLogic.prototype.onAdCacheError = function (errorCode, originalError) {
    switch (errorCode) {
        case "FAILURE0": // VAST Parser didn't answer until timeout
        case "FAILURE100": // Vast Parser error (100)
        case "FAILURE101": // andrey ru error message - Vast Parser error (101)
        case "FAILURE303": // Vast Parser error (303)
        case "FAILURE403": // Unable to select rendition
        case "FAILURE900": // General error reported from HTML5 video player
        case "FAILURE903": // Heavy Ad Intervention by browser detected.
        case "FAILURE10000": // No ad loader found.
        case "API_ACCESS_DENIED": // Access denied to this API.
        case "NO_AD_LOADER_FOUND":
        case "AD_ALREADY_LOADED":
        case "AD_IS_BEING_LOADED":
        case "AD_REQUIRES_ACTIVE_TAB":
        case "REQUEST_THROTTLED":
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.MSSTART.ADS_LOAD + errorCode);
            cleverapps.country.whenReady(function (country) {
                cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.MSSTART.ADS_COUNTRY_LOAD + country + "-" + errorCode);
            });
            break;
        default:
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.MSSTART.ADS_LOAD + "OTHER");
            cleverapps.throwAsync(cleverapps.EVENTS.STATS.MSSTART.ADS_LOAD + originalError.code + " message: " + JSON.stringify(originalError));
    }
};

MSStartLogic.prototype.onAdPlayError = function (errorCode, originalError) {
    switch (errorCode) {
        case "COMPLETED_FAILURE402": // VPAID AdError reported from JS VPAID player
        case "COMPLETED_FAILURE900": // General error reported from HTML5 video player
        case "COMPLETED_FAILURE901": // VPAID AdError reported from JS VPAID player | VAST AdError reported from JS VPAID player
        case "COMPLETED_FAILURE903": // Heavy Ad Intervention by browser detected.  Browser Message: Ad was removed because its network usage exceeded the limit.
        case "NO_LOADED_ADS_WITH_ID":
        case "REQUEST_THROTTLED":
        case "FAILED_BY_TIMEOUT":
        case "USER_CANCELED":
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.MSSTART.ADS_PLAY + errorCode);
            cleverapps.country.whenReady(function (country) {
                cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.MSSTART.ADS_COUNTRY_PLAY + country + "-" + errorCode);
            });
            break;
        default:
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.MSSTART.ADS_PLAY + "OTHER");
            cleverapps.throwAsync(cleverapps.EVENTS.STATS.MSSTART.ADS_PLAY + originalError.code + " message: " + JSON.stringify(originalError));
    }
};

MSStartLogic.prototype.logPushes = function () {
    var params = cleverapps.getRequestParameters();
    var pushCode = params && params.notificationPayload && JSON.parse(params.notificationPayload).push_code;

    if (pushCode) {
        cleverapps.localPushes.logClickEvent(pushCode);
    }
};

MSStartLogic.prototype.reportScore = function (score, callback) {
    if (!connector.social.isLoggedIn()) {
        callback(cleverapps.CODE_FAILED);
        return;
    }

    $msstart.submitGameResultsAsync(score).then(function (result) {
        callback(result ? cleverapps.CODE_SUCCEED : cleverapps.CODE_FAILED);
    }).catch(function () {
        callback(cleverapps.CODE_FAILED);
    });
};