/**
 * Created by mac on 7/22/17.
 */

var Field = function (loader, savedField) {
    cleverapps.EventEmitter.call(this);

    this.savedField = savedField || {};

    this.loader = loader;

    this.onGetView = function () {};
    this.onGetFloorBackgroundView = function () {};
    this.onGetBarriersViews = function () {};

    this.onMoveListener = function () {};

    this.onTouchStarted = function () {};
    this.onTouchMoved = function () {};
    this.onTouchEnded = function () {};
    this.onTouchSwiped = function () {};

    this.availableMove = new AvailableMove();
    if (BoosterAdvice.isEnabled()) {
        this.boosterAdvice = new BoosterAdvice(this);
    }

    this.shufflesInRow = 0;

    this.barriers = new Barriers();
};

Field.prototype = Object.create(cleverapps.EventEmitter.prototype);
Field.prototype.constructor = Field;

Field.prototype.getInfo = function () {
    var field = this.loader.save(this.cells);
    var floor = this.loader.save(this.floor);
    return {
        field: field,
        floor: floor
    };
};

Field.prototype.calcEmptyColumns = function (level) {
    var amount = 1000;
    for (var i = 0; i < level.field.length; i++) {
        var amountEmpty = 0;
        for (var j = level.field[i].length - 1; j >= 0; j--) {
            if (level.field[i][j] !== " ") {
                break;
            }
            amountEmpty++;
        }
        if (amountEmpty < amount) {
            amount = amountEmpty;
        }
    }

    return amount;
};

Field.prototype.calcEmptyRows = function (level) {
    var amount = 0;
    for (var i = level.field.length - 1; i >= 0; i--) {
        var empty = true;
        for (var j = 0; j < level.field[i].length; j++) {
            if (level.field[i][j] !== " ") {
                empty = false;
                break;
            }
        }
        if (!empty) {
            break;
        }

        amount++;
    }

    return amount;
};

Field.prototype.smile = function () {
    if (!Game.currentGame || Game.currentGame.field !== this || !Game.currentGame.started || cc.game.isPaused()) {
        return;
    }

    var available = [];
    for (var i = 0; i < Field.SIZE; i++) {
        for (var j = 0; j < Field.SIZE; j++) {
            var cell = this.cells[i] && this.cells[i][j] && this.cells[i][j];
            var component = cell && cell.findComponent([MuffinComponent, GoalCoefComponent]);
            if (cell && cell.smile && cell.y >= 0) {
                available.push(cell);
            } else if (component && component.smile && cell.y >= 0) {
                available.push(component);
            }
        }
    }

    if (available.length === 0) {
        return;
    }

    var random = available[Math.floor(Math.random() * available.length)];
    random.smile();
};

Field.prototype.showUpCells = function (f, silent) {
    for (var i = 0; i < Field.SIZE; i++) {
        for (var j = 0; j < Field.SIZE; j++) {
            if (this.cells[i][j]) {
                this.cells[i][j].animate(BaseCell.ANIMATION_SHOWUP, {
                    silent: silent
                });
            }
            if (this.floor[i][j]) {
                this.floor[i][j].animate(BaseCell.ANIMATION_SHOWUP, {
                    silent: silent
                });
            }
        }
    }

    cleverapps.timeouts.setTimeout(f, silent ? 0 : Field.SHOW_UP_ANIMATION_DURATION);
};

Field.prototype.hideCells = function (f) {
    for (var i = 0; i < Field.SIZE; i++) {
        for (var j = 0; j < Field.SIZE; j++) {
            if (this.cells[i][j]) {
                this.cells[i][j].animate(BaseCell.ANIMATION_HIDE);
            }
            if (this.floor[i][j]) {
                this.floor[i][j].animate(BaseCell.ANIMATION_HIDE);
            }
        }
    }

    cleverapps.timeouts.setTimeout(f, Field.HIDE_ANIMATION_DURATION);
};

Field.prototype.hideAnimation = function (f) {
    this.hideCells(function () {
        this.trigger("hide", f);
    }.bind(this));
};

Field.SHOW_UP_ANIMATION_SOUND_DELAY = 1200;
Field.SHOW_UP_ANIMATION_DURATION = 1300;
Field.HIDE_ANIMATION_DURATION = 2200;

if (cleverapps.config.name === "heroes") {
    Field.SHOW_UP_ANIMATION_SOUND_DELAY = 4300;
} else if (cleverapps.config.name === "runes") {
    Field.SHOW_UP_ANIMATION_SOUND_DELAY = 3300;
} else if (cleverapps.config.name === "adventure") {
    Field.SHOW_UP_ANIMATION_SOUND_DELAY = 3300;
}

Field.prototype.startSmile = function () {
    this.smileInterval = cleverapps.timeouts.setInterval(function () {
        if (Math.random() < 0.1) {
            this.smile();
        }
    }.bind(this), 200);
};

Field.prototype.stopSmile = function () {
    if (this.smileInterval !== undefined) {
        cleverapps.timeouts.clearInterval(this.smileInterval);
        delete this.smileInterval;
    }
};

Field.prototype.isSelected = function (cell) {
    return this.selected && this.selected.y === cell.y && this.selected.x === cell.x;
};

Field.prototype.select = function (selected) {
    if (!selected) {
        return;
    }

    if (!selected.movable || selected.controlDisabled) {
        cleverapps.audio.playSound(bundles.game.urls.click_blocked_effect);
        selected.animate(BaseCell.ANIMATION_MISTAKE);
        return;
    }

    if (this.selected) {
        if (this.isSelected(selected)) {
            selected.animate(BaseCell.ANIMATION_SELECT);
            this.removeSelection();
            return;
        }

        this.removeSelection();
    }

    this.selected = selected;
    selected.animate(BaseCell.ANIMATION_SELECT);
    this.trigger("changeSelected", this.selected);
};

Field.prototype.resetCoefs = function () {
    if (this.needResetCoef) {
        this.needResetCoef = false;
        for (var i = 0; i < Field.SIZE; i++) {
            for (var j = 0; j < Field.SIZE; j++) {
                var cell = this.cells[i][j];

                if (cell) {
                    var goalCoefComponent = cell.findComponent(GoalCoefComponent) || cell.innerCell && cell.innerCell.findComponent(GoalCoefComponent);
                    if (goalCoefComponent) {
                        goalCoefComponent.resetCoef();
                    }
                }
            }
        }
    }
};

Field.prototype.prepareResetCoefs = function () {
    this.needResetCoef = true;
    for (var i = 0; i < Field.SIZE; i++) {
        for (var j = 0; j < Field.SIZE; j++) {
            var cell = this.cells[i][j];

            if (cell) {
                var goalCoefComponent = cell.findComponent(GoalCoefComponent) || cell.innerCell && cell.innerCell.findComponent(GoalCoefComponent);
                if (goalCoefComponent) {
                    goalCoefComponent.prepareResetCoef();
                }
            }
        }
    }
};

Field.prototype.removeSelection = function () {
    if (this.selected && this.selected.onRemoveSelection) {
        this.selected.onRemoveSelection();
    }
    this.selected = undefined;
    this.trigger("changeSelected", this.selected);
};

Field.prototype.cellEvents = function (cell) {
    this.trigger("addCell", cell);
    cell.onAfterExplodeListener = this.removeCell.bind(this);
};

Field.prototype.getRandomMulticolorTo = function () {
    var variants = [], i, j;
    for (i = 0; i < Field.SIZE; i++) {
        for (j = 0; j < Field.SIZE; j++) {
            if (this.cells[i][j]) {
                var currentCell = this.cells[i][j];
                if (currentCell.canDelete()) {
                    variants.push(currentCell);
                }
            }
        }
    }
    if (variants.length > 0) {
        return variants[cleverapps.Random.random(variants.length)];
    }
    return false;
};

Field.prototype.addRandomMulticolor = function (amount, options) {
    options = options || {};
    var actionBefore = options.actionBefore;
    var fromLantern = options.fromLantern;

    var createMulticolor = function () {
        var cell = this.getRandomMulticolorTo();
        if (!cell) {
            return;
        }
        var multiColor = new MultiColorCell(cell.x, cell.y);
        Game.currentGame.field.addCell(multiColor);

        if (actionBefore && fromLantern) {
            cell.delete();
            multiColor.animate(BaseCell.ANIMATION_CREATE_FROM_LANTERN, { scale: 0.6 });
        } else if (actionBefore) {
            cell.delete();
            multiColor.animate(BaseCell.ANIMATION_CREATE_FROM_BOOSTER_BEFORE);
        } else {
            multiColor.animate(BaseCell.ANIMATION_CREATE_FROM_CENTER, { scale: 0.3 });
        }

        Game.currentGame.counter.setTimeout(function () {
            if (!actionBefore) {
                cell.delete();
                multiColor.animate(BaseCell.ANIMATION_CREATE, { silent: true });
            }
        }, BaseCellView.CREATE_FROM_CENTER_DURATION);
        return multiColor;
    }.bind(this);

    amount = amount || 1;
    var created = [];
    for (var i = 0; i < amount; i++) {
        var multicolor = createMulticolor();
        if (multicolor) {
            created.push(multicolor);
        }
    }
    return created.length;
};

Field.prototype.listRandomComboOptions = function () {
    var options = [];
    for (var i = 0; i < Field.SIZE; i++) {
        for (var j = 0; j < Field.SIZE; j++) {
            if (this.cells[i][j]) {
                var currentCell = this.cells[i][j];
                if (currentCell.canDelete()) {
                    options.push(currentCell);
                }
            }
        }
    }
    return options;
};

Field.prototype.showLantern = function (lanternStreak) {
    this.trigger("showLantern", lanternStreak);
};

Field.prototype.addRandomCombo = function (amount, options) {
    options = options || {};
    var actionBefore = options.actionBefore;
    var timeouts = options.timeouts || [];
    var fromLantern = options.fromLantern;

    var cells = cleverapps.Random.shuffle(this.listRandomComboOptions());

    var addCombo = function (cell, combo, timeout) {
        Game.currentGame.counter.setTimeout(function () {
            cell.delete();
            this.addCell(combo);
            if (actionBefore && fromLantern) {
                combo.animate(BaseCell.ANIMATION_CREATE_FROM_LANTERN, { scale: 0.6 });
            } else if (actionBefore) {
                combo.animate(BaseCell.ANIMATION_CREATE_FROM_BOOSTER_BEFORE);
            } else {
                combo.animate(BaseCell.ANIMATION_CREATE_FROM_CENTER, { scale: 0.3 });
                Game.currentGame.counter.setTimeout(function () {
                    combo.animate(BaseCell.ANIMATION_CREATE);
                }, BaseCellView.CREATE_FROM_CENTER_DURATION);
            }
        }.bind(this), timeout);
    }.bind(this);

    var settled = [];
    for (var i = 0; i < cells.length && settled.length < amount; i++) {
        var cell = cells[i];

        var combo = ComboComponent.Create(cell, {
            random: true
        });

        if (combo) {
            var markComponent = cell.findComponent(MarkComponent);
            if (markComponent && markComponent.missionMark) {
                markComponent.setMissionMark(true);
            }

            var timeout = settled.length < timeouts.length ? timeouts[settled.length] : 0;
            settled.push(combo);
            cell.toDelete = true;
            addCombo(cell, combo, timeout);
        }
    }

    return settled.length;
};

Field.prototype.load = function (level) {
    this.floor = [];
    this.cells = [];
    this.selected = undefined;
    this.nextCells = [];

    this.blockedCells = [];

    var i;
    if (level.firstCells) {
        for (i = 0; i < level.firstCells.length; i++) {
            this.pushNextCell(level.firstCells[i]);
        }
    }

    Field.SIZE = level.field.length;
    Field.SIZE_X = Field.SIZE - this.calcEmptyColumns(level);
    Field.SIZE_Y = Field.SIZE - this.calcEmptyRows(level);

    for (i = 0; i < Field.SIZE; i++) {
        this.floor.push([]);
        this.cells.push([]);
        this.blockedCells.push([]);
        for (var j = 0; j < Field.SIZE; j++) {
            this.floor[i].push(null);
            this.cells[i].push(undefined);
            this.blockedCells[i].push(false);
        }
    }

    var loader = new Loader();

    loader.load(level.field, function (cell) {
        this.floor[cell.y][cell.x] = undefined;
    }.bind(this), true);

    loader.load(this.savedField.field || level.field, function (cell) {
        this.cells[cell.y][cell.x] = cell;
        this.cellEvents(cell);
    }.bind(this));

    loader.load(this.savedField.floor || level.floor, function (tile) {
        this.addTile(tile);
    }.bind(this));

    this.barriers.load(level.barriers);

    if (!cleverapps.environment.isEditorScene()) {
        this._paint();
    }
    if (cleverapps.loadedSnapshot && Game.currentGame.slot !== undefined) {
        Game.currentGame.eraseSave();
    }

    this.calculateFallDownPortals();
};

Field.prototype.calculateFallDownPortals = function () {
    if (!Match3Rules.SlideFallDown) {
        return;
    }

    this.portals = {};

    var j;
    if (Match3Rules.ClockMakerPortals) { // first non-empty cell in each column
        for (j = 0; j < Field.SIZE; j++) {
            for (var i = 0; i < Field.SIZE; i++) {
                if (this.floor[i][j] !== null) {
                    this.portals[j] = i - 1;
                    break;
                }
            }
        }
    } else { // HomeScapes style portals, only 0 row
        for (j = 0; j < Field.SIZE; j++) {
            if (this.floor[0][j] !== null) {
                this.portals[j] = -1;
            }
        }
    }
};

Field.prototype.markNotWillFall = function () {
    for (var i = 0; i < Field.SIZE; i++) {
        for (var j = 0; j < Field.SIZE; j++) {
            if (this.cells[i][j]) {
                this.cells[i][j].willFall = false;
            }
        }
    }
};

Field.prototype.animateDidFallNotWillFall = function () {
    for (var i = 0; i < Field.SIZE; i++) {
        for (var j = 0; j < Field.SIZE; j++) {
            if (this.cells[i][j]) {
                if (this.cells[i][j].didFall === true && this.cells[i][j].willFall === false) {
                    this.cells[i][j].animate(BaseCell.ANIMATION_DOWN);
                }

                this.cells[i][j].didFall = this.cells[i][j].willFall;
            }
        }
    }
};

Field.prototype.createRandomCell = function (x, y) {
    if (this.nextCells.length) {
        return this.loader.loadNext(y, x, this.nextCells.shift());
    }
    return this.loader.loadNext(y, x, [Loader.WILDCARD]);
};

Field.prototype.pushNextCell = function (cell) {
    if (cell.CODES) {
        cell = cell.CODES[0];
    }

    this.nextCells.push(Array.isArray(cell) ? cell : [cell]);
};

Field.prototype.addNextCell = function (cellData, randomInterval) {
    if (cellData[0] === ColorCookieManCell.CODES[0]) {
        ColorCookieManCell.exitToNextMove++;
        return;
    }
    var interval = randomInterval || { start: 7, end: 15 };
    var randomDelay = cleverapps.Random.random(interval.start, interval.end);
    for (var i = 0; i < randomDelay; i++) {
        this.pushNextCell(Loader.WILDCARD);
    }
    this.pushNextCell(cellData);
};

Field.prototype.addTile = function (tile) {
    this.trigger("addTile", tile);
    tile.onAfterExplodeListener = this.removeTile.bind(this);
    this.floor[tile.y][tile.x] = tile;
};

Field.prototype.addCell = function (cell, x, y) {
    if (x === undefined) {
        x = cell.x;
    }
    if (y === undefined) {
        y = cell.y;
    }
    this.cellEvents(cell);
    if (!this.cells[y]) {
        throw new Error("Row is undefined in addCell y:" + y + " x:" + x + " rowsAmount:" + this.cells.length + " Field.SIZE_Y:" + Field.SIZE_Y);
    }
    this.cells[y][x] = cell;
};

Field.prototype.replenishGaps = function (factory) {
    if (Match3Rules.SlideFallDown) {
        return this.slideFallDown(factory);
    }
    return this.simpleFallDown(factory);
};

Field.prototype.isBlockedAbove = function (i, j) {
    if (this.barriers.canMove(i - 1, j, i, j) === false) {
        return true;
    }

    if (this.portals[j] === i - 1) {
        return false;
    }

    if (this.floor[i - 1] === undefined || this.floor[i - 1][j] === null) {
        return true;
    }

    return this.cells[i - 1][j] !== undefined && (!this.cells[i - 1][j].movable || this.cells[i - 1][j].sticky);
};

Field.prototype.canFall = function (i, j, dir) {
    if (!this.isEmpty(i + 1, j + dir)) {
        return false;
    }

    if (this.barriers.canMove(i, j, i + 1, j) === false) {
        return false;
    }

    var CENTER = 0;
    if (this.portals[j] === i) {
        return dir === CENTER;
    }

    if (dir !== CENTER) {
        if (!this.blockedCells[i + 1][j + dir]) {
            return false;
        }
    }

    return !(this.cells[i] === undefined || this.cells[i][j] === undefined || !this.cells[i][j].movable || this.cells[i][j].sticky);
};

Field.prototype.doFall = function (si, sj, dir, factory) {
    if (this.portals[sj] === si) {
        var cell = factory(sj, si);
        this.addCell(cell, sj + dir, si + 1);
        cell.onAppear();
        cell.move({ x: sj + dir, y: si + 1 }, { moveInterval: 0.1 }, cell.onFinishAppear);

        cell.willFall = true;
    } else {
        this.cells[si][sj].move({ y: si + 1, x: sj + dir }, { moveInterval: 0.1 });
        this.cells[si + 1][sj + dir] = this.cells[si][sj];
        this.cells[si][sj].willFall = true;
        this.cells[si][sj] = undefined;
    }
};

Field.prototype.slideFallDown = function (factory) {
    var LEFT = -1;
    var CENTER = 0;
    var RIGHT = 1;

    var i, j;
    for (j = 0; j < Field.SIZE; j++) {
        for (i = 0; i < Field.SIZE; i++) {
            if (this.isEmpty(i, j)) {
                if (i > 0 && this.blockedCells[i - 1][j] === true) {
                    this.blockedCells[i][j] = true;
                } else if (this.isBlockedAbove(i, j)) {
                    this.blockedCells[i][j] = true;
                }
            } else {
                this.blockedCells[i][j] = false;
            }
        }
    }

    var didFall = false;

    var leftThenRight = LEFT;

    var traverseDir = (Math.random() < 0.5) ? 1 : 0;

    for (i = Field.SIZE - 2; i >= -1; i--) {
        traverseDir = 1 - traverseDir;

        for (var pj = 0; pj < Field.SIZE; pj++) {
            j = pj;
            if ((traverseDir + i) % 2 === 0) {
                j = Field.SIZE - 1 - pj;
            }
            if (i === -1 && this.portals[j] === -1 || i !== -1 && !this.isEmpty(i, j)) {
                var dir = 10;

                if (this.canFall(i, j, CENTER)) {
                    dir = CENTER;
                } else {
                    var isLeft = this.canFall(i, j, LEFT);
                    var isRight = this.canFall(i, j, RIGHT);

                    if (isLeft && isRight) {
                        dir = leftThenRight = -leftThenRight;
                    } else if (isLeft) {
                        dir = LEFT;
                    } else if (isRight) {
                        dir = RIGHT;
                    }
                }

                if (dir !== 10) {
                    didFall = true;
                    this.doFall(i, j, dir, factory);

                    if (dir !== CENTER) {
                        for (var ti = i; ti >= 0; ti--) {
                            if (!this.blockedCells[ti][j + dir]) {
                                break;
                            }

                            this.blockedCells[ti][j + dir] = false;
                            if (this.isBlockedAbove(ti, j + dir)) {
                                break;
                            }
                        }
                    }

                    if (i > 0 && this.blockedCells[i - 1][j] === true || i >= 0 && this.isBlockedAbove(i, j)) {
                        this.blockedCells[i][j] = true;
                    }
                }
            }
        }
    }

    return didFall;
};

Field.prototype.simpleFallDown = function (factory) {
    var STEP = Field.REPLENISH_GAPS_DELAY_STEP;

    for (var j = 0; j < Field.SIZE; j++) {
        var gaps = [];
        var filled = 0;
        var delay = 0;
        for (var i = Field.SIZE - 1; i >= 0; i--) {
            if (this.barriers.canMove(i, j, i + 1, j) === false) {
                filled = gaps.length;
            }

            if (this.cells[i][j] !== undefined && this.cells[i][j].fallThroughBarrier) {
                if (gaps.length && gaps.length === filled && !this.cells[i + 1][j]) {
                    filled--;
                }
            }

            if (this.isEmpty(i, j)) {
                gaps.push({
                    y: i,
                    x: j
                });
            } else if (this.cells[i][j] !== undefined && this.cells[i][j].movable && !this.cells[i][j].sticky && gaps.length > filled) {
                var upperGap = gaps[filled++];
                this.cells[i][j].move(upperGap, { delay: delay, squeeze: true });
                delay += STEP * cleverapps.Random.nextDouble();
                this.cells[upperGap.y][upperGap.x] = this.cells[i][j];
                this.cells[i][j] = undefined;
                gaps.push({
                    y: i,
                    x: j
                });
            }
        }

        var emptyCells = 0;

        gaps.forEach(function (gap) {
            if (this.cells[gap.y][gap.x] === undefined) {
                emptyCells++;
            }
        }.bind(this));

        var prevY = undefined;
        for (var id = 0; id < gaps.length; id++) {
            var gap = gaps[id];

            if (this.cells[gap.y][gap.x] !== undefined) {
                continue;
            }

            if (prevY !== undefined) {
                if (gap.y < prevY - 1) {
                    delay += 0.1 * (prevY - 1 - gap.y);
                }
            }

            prevY = gap.y;

            var cell = factory(gap.x, -(id + 1) + (gaps.length - emptyCells));
            this.addCell(cell, gap.x, gap.y);
            cell.onAppear();
            cell.move(gap, { delay: delay, squeeze: true }, cell.onFinishAppear);
            delay += STEP * cleverapps.Random.nextDouble();

            if (cleverapps.config.wysiwygMode && this.onAdsStartFall) {
                this.onAdsStartFall();
            }
        }
    }
};

Field.prototype.isEmpty = function (row, col) {
    return this.floor[row][col] !== null && this.cells[row][col] === undefined;
};

Field.prototype.removeTile = function (x, y) {
    this.floor[y][x] = undefined;
};

Field.prototype.removeCell = function (x, y) {
    this.cells[y][x] = undefined;
};

Field.prototype.findAllShapes = function (targetCell) {
    var shapes = [];

    var found = [];
    for (var fi = 0; fi < Field.SIZE; fi++) {
        found.push({});
    }

    var addToShape = function (shape, row, col, color) {
        if (this.isThreeInRow(row, col) && !found[row][col]) {
            if (this.cells[row][col].getColor() === color) {
                found[row][col] = true;
                shape.add(this.cells[row][col]);
            }
        }
    }.bind(this);

    var createShape = function (i, j) {
        if (!found[i][j] && this.isThreeInRow(i, j)) {
            var color = this.cells[i][j].getColor();

            var si = 0;
            var shape = new Shape();

            addToShape(shape, i, j, color);

            while (si < shape.cells.length) {
                var cell = shape.cells[si++];

                addToShape(shape, cell.y + 1, cell.x, color);
                addToShape(shape, cell.y - 1, cell.x, color);
                addToShape(shape, cell.y, cell.x + 1, color);
                addToShape(shape, cell.y, cell.x - 1, color);
            }

            shapes.push(shape);
        }
    }.bind(this);

    if (targetCell) {
        createShape(targetCell.y, targetCell.x);
    } else {
        for (var i = 0; i < Field.SIZE; i++) {
            for (var j = 0; j < Field.SIZE; j++) {
                createShape(i, j);

                if (this.isCellInField(i, j) && this.cells[i][j].mustHurtNextBurn) {
                    delete this.cells[i][j].mustHurtNextBurn;
                    var shape = new Shape();
                    shape.add(this.cells[i][j]);
                    shapes.push(shape);
                }
            }
        }
    }

    return shapes;
};

Field.prototype.findRandomMove = function () {
    var move = cleverapps.Random.choose(this.findValidMove(true));

    if (move) {
        var cellToSwap = this.cells[move.start.y + move.dir.row][move.start.x + move.dir.col];
        this.swap(move.start, cellToSwap, true);
        move.shape = this.findAllShapes(move.start)[0];
        this.swap(move.start, cellToSwap, true);
    }

    return move;
};

Field.prototype._paint = function (inShuffle) {
    for (var attempt = 0; attempt < 1000; attempt++) {
        var repainted = false;

        if (!inShuffle) {
            for (var i = 0; i < Field.SIZE; i++) {
                for (var j = 0; j < Field.SIZE; j++) {
                    if (this.isThreeInRow(i, j)) {
                        this.cells[i][j] = this.cells[i][j].repaint();
                        this.cellEvents(this.cells[i][j]);
                        repainted = true;
                    }
                }
            }
        }

        if (!repainted && this.findValidMove()) {
            if (inShuffle) {
                Game.currentGame.counter.setTimeout(function () {}, 0);
            }
            return true;
        }

        var canChangeColor = [];
        for (var r = 0; r < Field.SIZE; r++) {
            for (var c = 0; c < Field.SIZE; c++) {
                if (this.cells[r][c] && this.cells[r][c].canRepaint(inShuffle)) {
                    canChangeColor.push([r, c]);
                }
            }
        }

        if (canChangeColor.length) {
            var pos = cleverapps.Random.choose(canChangeColor);
            var oldCell = this.cells[pos[0]][pos[1]];
            this.cells[pos[0]][pos[1]] = oldCell.repaint();
            if (inShuffle) {
                oldCell.delete();
                this.addCell(this.cells[pos[0]][pos[1]]);
            } else {
                this.cellEvents(this.cells[pos[0]][pos[1]]);
            }
        }
    }

    if (!inShuffle) {
        cleverapps.throwAsync("Cant repaint by 1000 attemts on level: " + (cleverapps.environment.episodeNo * 15 + cleverapps.environment.levelNo + 1));
    }

    return false;
};

Field.prototype.move = function (start, finish) {
    this.onMoveListener();
    this.trigger("moveAnimation", start, finish, true);
    cleverapps.audio.playSound(bundles.game.urls.move_effect);
    this.removeSelection();

    this.lastMove = [
        {
            x: start.x,
            y: start.y
        },
        {
            x: finish.x,
            y: finish.y
        }
    ];

    start.move(finish.getPosition(), { squeeze: true });
    finish.move(start.getPosition(), { squeeze: true });

    Game.currentGame.counter.onceZero = function () {
        this.swapBack(start, finish);

        CrazyCombos(start, finish);
    }.bind(this);

    Game.currentGame.counter.afterRunStages = function () {
        start.actionAfterRunStages(finish);
        finish.actionAfterRunStages(start);
    };
};

Field.prototype.invalidMove = function (start, finish) {
    this.removeSelection();
    this.trigger("moveAnimation", start, finish, false);
    start.onWrongMoveListener(finish);
    cleverapps.audio.playSound(bundles.game.urls.wrong_move_effect);
};

Field.prototype.wrongMove = function (start, finish) {
    this.removeSelection();
    this.trigger("moveAnimation", start, finish, false);
    start.onWrongMoveListener(finish);
    finish.onWrongMoveListener(start);
    cleverapps.audio.playSound(bundles.game.urls.wrong_move_effect);
};

Field.prototype.swap = function (start, finish, swapCoordinates) {
    this.cells[start.y][start.x] = finish;
    this.cells[finish.y][finish.x] = start;
    if (swapCoordinates) {
        var finishX = finish.x;
        var finishY = finish.y;
        finish.x = start.x;
        finish.y = start.y;
        start.x = finishX;
        start.y = finishY;
    }
};

Field.prototype.swapBack = function (start, finish) {
    this.cells[start.y][start.x] = start;
    this.cells[finish.y][finish.x] = finish;
};

Field.prototype.canMove = function (cellA, cellB) {
    return (Math.abs(cellA.x - cellB.x) + Math.abs(cellA.y - cellB.y)) === 1;
};

Field.prototype.isValidMove = function (cellA, dir) {
    if (!cellA) {
        return false;
    }

    if (!this.isWithinBounds(cellA.y + dir.row, cellA.x + dir.col)) {
        return false;
    }

    var cellB = this.cells[cellA.y + dir.row][cellA.x + dir.col];
    if (!cellB) {
        return false;
    }

    if (!cellA.movable || !cellB.movable || cellA.controlDisabled || cellB.controlDisabled) {
        return false;
    }

    if (this.barriers.canMove(cellA.y, cellA.x, cellB.y, cellB.x) === false) {
        return false;
    }

    if (cellA.canMoveWith(cellB)) {
        return true;
    }

    if (cellB.canMoveWith(cellA)) {
        return true;
    }

    this.swap(cellA, cellB);
    var existThreeInRow = this.isThreeInRow(cellA.y, cellA.x) || this.isThreeInRow(cellB.y, cellB.x);
    this.swapBack(cellA, cellB);

    if (existThreeInRow) {
        return true;
    }

    return undefined;
};

Field.prototype.onGameFinish = function () {
    for (var i = 0; i < Field.SIZE; i++) {
        for (var j = 0; j < Field.SIZE; j++) {
            if (this.cells[i][j]) {
                this.cells[i][j].onGameFinish();
            }
        }
    }
};

Field.prototype.shuffle = function (force, cellsForShuffle) {
    if (this.findValidMove() && !force) {
        this.shufflesInRow = 0;
        return false;
    }

    this.shufflesInRow++;
    if (this.shufflesInRow > 1) {
        if (this.shufflesInRow > 10) {
            cleverapps.throwAsync("Endless shuffle");
            if (Game.currentGame.stillNoPenalty()) {
                Game.currentGame.giveUp();
            } else {
                Game.currentGame.lose();
            }
        }
        if (this._paint(true)) {
            this.shufflesInRow = 0;
            return false;
        }
    }

    var i;
    if (!cellsForShuffle) {
        cleverapps.exclamation.show("message.Shuffle");
        cellsForShuffle = [];
        for (i = 0; i < Field.SIZE; i++) {
            for (var j = 0; j < Field.SIZE; j++) {
                if (this.cells[i][j] && this.cells[i][j].findComponent(ColorComponent) && this.cells[i][j].movable && !this.cells[i][j].sticky && !this.cells[i][j].controlDisabled) {
                    cellsForShuffle.push(this.cells[i][j]);
                }
            }
        }
    }

    var positionsForShuffle;
    var beginPositions;

    for (var times = 0; times < 50; times++) {
        cellsForShuffle.sort(function () {
            return cleverapps.Random.nextDouble() - 0.5;
        });

        beginPositions = [];
        positionsForShuffle = [];
        for (i = 0; i < cellsForShuffle.length; i++) {
            beginPositions.push({
                x: cellsForShuffle[i].x,
                y: cellsForShuffle[i].y
            });
            positionsForShuffle.push({
                x: cellsForShuffle[(i + 1) % cellsForShuffle.length].x,
                y: cellsForShuffle[(i + 1) % cellsForShuffle.length].y
            });
        }

        for (i = 0; i < cellsForShuffle.length; i++) {
            cellsForShuffle[i].x = positionsForShuffle[i].x;
            cellsForShuffle[i].y = positionsForShuffle[i].y;
            this.cells[cellsForShuffle[i].y][cellsForShuffle[i].x] = cellsForShuffle[i];
        }

        var good = false;
        if (this.findValidMove() || this.findAllShapes().length > 0) {
            good = true;
        }

        for (i = 0; i < cellsForShuffle.length; i++) {
            cellsForShuffle[i].x = beginPositions[i].x;
            cellsForShuffle[i].y = beginPositions[i].y;
            this.cells[cellsForShuffle[i].y][cellsForShuffle[i].x] = cellsForShuffle[i];
        }

        if (good) {
            this.shufflesInRow = 0;
            break;
        }
    }

    Game.currentGame.counter.setTimeout(function () {
        for (var t = 0; t < cellsForShuffle.length; t++) {
            cellsForShuffle[t].move(positionsForShuffle[t], {
                moveInterval: Field.SHUFFLE_MOVE_INTERVAL,
                shuffle: true
            });
        }

        Game.currentGame.counter.onceZero = function () {
            for (var z = 0; z < cellsForShuffle.length; z++) {
                this.cells[positionsForShuffle[z].y][positionsForShuffle[z].x] = cellsForShuffle[z];
            }
        }.bind(this);
    }.bind(this), Field.SHUFFLE_MOVE_INTERVAL * 1000);

    return true;
};

Field.prototype.findValidMove = function (returnAll) {
    var result = [];
    var adjustMove = function (move) {
        var cellToSwap = this.cells[move.start.y + move.dir.row][move.start.x + move.dir.col];
        this.swap(move.start, cellToSwap, true);
        if (this.isThreeInRow(cellToSwap.y, cellToSwap.x)) {
            move.dir.row = -move.dir.row;
            move.dir.col = -move.dir.col;
            var temp = move.start;
            move.start = cellToSwap;
            cellToSwap = temp;
        }
        this.swap(move.start, cellToSwap, true);
    }.bind(this);
    for (var i = 0; i < Field.SIZE; i++) {
        for (var j = 0; j < Field.SIZE; j++) {
            var cell = this.cells[i][j];
            if (cell) {
                if (i < Field.SIZE - 1) {
                    var moveLeft = {
                        start: cell,
                        dir: { row: 1, col: 0 }
                    };
                    if (this.isValidMove(moveLeft.start, moveLeft.dir)) {
                        adjustMove(moveLeft);
                        if (returnAll) {
                            result.push(moveLeft);
                        } else {
                            return moveLeft;
                        }
                    }
                }

                if (j < Field.SIZE - 1) {
                    var moveDown = {
                        start: cell,
                        dir: { row: 0, col: 1 }
                    };
                    if (this.isValidMove(moveDown.start, moveDown.dir)) {
                        adjustMove(moveDown);
                        if (returnAll) {
                            result.push(moveDown);
                        } else {
                            return moveDown;
                        }
                    }
                }
            }
        }
    }

    if (returnAll) {
        return result;
    }
    return false;
};

Field.prototype.isThreeInRow = function (row, col) {
    if (!this.isCellInField(row, col)) {
        return false;
    }

    if (this.cells[row][col].getColor() === undefined) {
        return false;
    }

    if (Match3Rules.Features.Plane) {
        if (this.haveSquare(row, col, 1, 1) || this.haveSquare(row, col, 1, -1) || this.haveSquare(row, col, -1, 1) || this.haveSquare(row, col, -1, -1)) {
            return true;
        }
    }

    return this.have3SameColor(row, col, 1, 0) || this.have3SameColor(row, col, 0, 1);
};

Field.prototype.haveSquare = function (row, col, dr, dc) {
    var c = this.cells[row][col].getColor();

    return this.isCellInField(row + dr, col + dc) && (this.cells[row + dr][col + dc].getColor() === c)
        && this.isCellInField(row, col + dc) && (this.cells[row][col + dc].getColor() === c)
        && this.isCellInField(row + dr, col) && (this.cells[row + dr][col].getColor() === c);
};

Field.prototype.have3SameColor = function (row, col, dr, dc) {
    var c = this.cells[row][col].getColor();

    var right = this.isCellInField(row + dr, col + dc) && (this.cells[row + dr][col + dc].getColor() === c);
    var left = this.isCellInField(row - dr, col - dc) && (this.cells[row - dr][col - dc].getColor() === c);

    if (left && right) {
        return true;
    }

    dr += dr;
    dc += dc;

    if (right && this.isCellInField(row + dr, col + dc) && this.cells[row + dr][col + dc].getColor() === c) {
        return true;
    }

    if (left && this.isCellInField(row - dr, col - dc) && this.cells[row - dr][col - dc].getColor() === c) {
        return true;
    }

    return false;
};

Field.prototype.isCellInField = function (row, col) {
    return this.isWithinBounds(row, col) && this.cells[row][col];
};

Field.prototype.inField = function (row, col) {
    return this.isWithinBounds(row, col) && this.floor[row][col] !== null;
};

Field.prototype.isWithinBounds = function (row, col) {
    return row >= 0 && col >= 0 && row < Field.SIZE && col < Field.SIZE;
};

Field.prototype.debugHighlightCells = function (cell) {
    if (cleverapps.config.debugMode) {
        var cells = [];

        if (cell instanceof HeroCell) {
            var algorithm = new HintAlgorithm(this);
            var smartTargets = algorithm.smartChoice();

            var fillCells = function () {
                var targets = cell.algo.findTargets(cell, true).filter(function (choice) {
                    return choice !== undefined;
                }).map(function (choice) {
                    var res = { x: choice.x, y: choice.y };

                    smartTargets.forEach(function (target) {
                        if (target.x === choice.x && target.y === choice.y) {
                            res.goalId = target.goalId;
                        }
                    });

                    return res;
                });

                cells = cleverapps.unique(cells.concat(targets), function (item) {
                    return item.x + "_" + item.y;
                });
            };

            if (cell.algo.beforeEffect) {
                cell.algo.beforeEffect(cell, function () {
                    fillCells();
                });
            } else {
                fillCells();
            }
        }

        this.trigger("debugHighlightCells", cells);
    }
};

Field.prototype.iterate = function (targets, iterator) {
    for (var i = 0; i < Field.SIZE; i++) {
        for (var j = 0; j < Field.SIZE; j++) {
            var target = targets[i] && targets[i][j];
            iterator(target, i, j);
        }
    }
};

Field.prototype.iterateFloor = function (iterator) {
    this.iterate(this.floor, function (floor, i, j) {
        if (floor !== null) {
            iterator(i, j);
        }
    });
};

Field.prototype.iterateCells = function (iterator) {
    this.iterate(this.cells, function (cell) {
        if (cell) {
            iterator(cell);
        }
    });
};

Field.prototype.iterateTiles = function (iterator) {
    this.iterate(this.floor, function (tile) {
        if (tile) {
            iterator(tile);
        }
    });
};

Field.prototype.bombByLeftMoves = function (moves, f) {
    var positions = BombByLeftMoves.ChooseLocations(this, moves);
    this.trigger("bombByLeftMoves");
    var INTERVAL = 100;

    var maxDelay = 0;
    positions.forEach(function (position, id) {
        maxDelay = Math.max(id * INTERVAL + Field.BONUS_BY_MOVES_DELAY, maxDelay);
        cleverapps.timeouts.setTimeout(function () {
            this.bombByLeftMovesCreate(position);
        }.bind(this), id * INTERVAL + Field.BONUS_BY_MOVES_DELAY);
    }.bind(this));

    var duration = (Field.SIZE + 1) / BombByLeftMovesView.SPEED * 1000 + BombByLeftMovesView.DELAY + 1000;
    cleverapps.timeouts.setTimeout(f, maxDelay + duration);

    Game.currentGame.setMoves(Game.currentGame.moves - moves);
};

Field.prototype.bombByLeftMovesCreate = function (position) {
    var bomb = new BombByLeftMoves(position.col, position.row);
    this.trigger("bombByLeftMovesCreate", bomb);
    bomb.go();
};

Field.prototype.getCell = function (x, y) {
    return this.cells[y][x];
};

Field.prototype.getFloorTile = function (x, y) {
    return this.floor[y][x];
};

Field.MAX_ONE_TIME_LEFT_MOVES_EXPLODE_CELLS = 10;
Field.REPLENISH_GAPS_DELAY_STEP = 0;
Field.SHUFFLE_MOVE_INTERVAL = 0.5;
Field.BONUS_BY_MOVES_DELAY = 1000;