/**
 * Created by Denis Kuzin on 30 july 2024
 */

var BigComponentView = cc.Node.extend({
    ctor: function (component, cellView) {
        this._super();

        this.component = component;
        this.cellView = cellView;
    },

    refuse: function () {
        var view = this.cellView.cellWithoutLive || this.cellView;

        if (view.getRotation() !== 0) {
            return;
        }
        var NOD_DURATION = 0.1;
        var ANGLE = 3;
        view.runAction(new cc.Sequence(
            new cc.RotateBy(NOD_DURATION, -ANGLE).easing(cc.easeIn(1)),
            new cc.RotateBy(2 * NOD_DURATION, 2 * ANGLE).easing(cc.easeIn(1)),
            new cc.RotateTo(NOD_DURATION, 0).easing(cc.easeIn(1))
        ));
    }
});

BigComponentView.showHover = function (state) {
    if (state) {
        var styles = this.getStyles();
        this.setLocalZOrder(1);
        this.shine = ShineView.factory();
        this.shine.setPosition(this.width / 2, this.height / 2);
        this.shine.setScale(2);
        this.addChild(this.shine);
        this.runAction(new cc.MoveBy(0.2, 0, styles.hover.y).easing(cc.easeIn(0.5)));
    } else {
        this.stopAllActions();
        var specialShapeComponent = this.cell.findComponent(SpecialShapeComponent);
        var pos = specialShapeComponent && specialShapeComponent.view.calcPositionInTheGrid
            ? specialShapeComponent.view.calcPositionInTheGrid()
            : BaseCellView.alignInTheGrid(this.cell.x + 0.5, this.cell.y + 0.5);
        this.runAction(new cc.MoveTo(0.2, pos).easing(cc.easeIn(0.5)));

        if (this.shine) {
            this.shine.runAction(
                new cc.Sequence(
                    new cc.DelayTime(0.1),
                    new cc.CallFunc(function () {
                        this.setPosition(cleverapps.styles.BaseCellView.CELL_SIZE_PX / 2, cleverapps.styles.BaseCellView.CELL_SIZE_PX / 2);
                        this.setScale(1);
                        cc.pool.putInPool(this);
                    }.bind(this.shine))
                )
            );
            this.shine = undefined;
        }
        this.initializeZOrder();
    }
};
