/**
 * Created by vladislav on 11/10/2023
 */

var PrivacyWindow = CleverappsWindow.extend({
    onWindowLoaded: function (title, text) {
        this._super({
            title: title,
            name: "privacywindow",
            content: this.createContent(this.splitTextIntoChunks(text)),
            foreground: bundles.windows.frames.window_foreground_png,
            noPadding: true
        });
    },

    splitTextIntoChunks: function (text) {
        var maxLength = 1000;
        var lines = text.split("\n");

        var chunks = [];
        var currentChunk = "";
        for (var i = 0; i < lines.length; i++) {
            currentChunk += lines[i];
            if (currentChunk.length >= maxLength) {
                chunks.push(currentChunk);
                currentChunk = "";
            } else {
                currentChunk += "\n";
            }
        }

        if (currentChunk) {
            chunks.push(currentChunk);
        }

        return chunks;
    },

    createContent: function (textChunks) {
        var styles = cleverapps.styles.PrivacyWindow;

        var size = styles.size[cleverapps.resolution.mode];

        var chunks = textChunks.map(function (textChunk) {
            var text = cleverapps.UI.__generateNotLocalizedText(textChunk, cleverapps.styles.FONTS.WINDOW_SMALL_TEXT);
            text.setDimensions(size.width - styles.text.padding * 2, 0);
            text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_LEFT);
            return text;
        });

        var layout = new cleverapps.Layout(chunks, {
            margin: 0,
            padding: styles.scroll.padding,
            direction: cleverapps.Layout.VERTICAL
        });

        var scroll = new cleverapps.UI.ScrollView(layout, {
            direction: cleverapps.UI.ScrollView.DIR_VERTICAL
        });
        scroll.setContentSize2(styles.size);
        scroll.setBarPadding(styles.scroll.barPadding);
        scroll.scrollTo(cleverapps.UI.ScrollView.SCROLLS.UPPER_LEFT);

        return cleverapps.UI.wrapWithPadding(scroll, cc.padding(styles.contentPadding));
    }
});

cleverapps.styles.PrivacyWindow = {
    contentPadding: { x: 0, y: 20 },
    size: [
        { width: 1000, height: 1500 },
        { width: 2000, height: 1000 },
        { width: 2000, height: 1000 }
    ],

    text: {
        padding: 50
    },

    scroll: {
        padding: {
            x: 20,
            y: 50
        },
        barPadding: {
            cornerPadding: 40,
            sidePadding: 40
        }
    }
};