/**
 * Created by andrey on 30.08.17.
 */

var ColorFulAnimationView = cc.Node.extend({
    ctor: function () {
        this._super();

        this.setLocalZOrder(15);

        this.animation = new cleverapps.Spine(bundles.colorful.jsons.colorful_json);
        this.addChild(this.animation);
    },

    animate: function (callback) {
        this.animation.setAnimation(0, "animation", false);
        this.animation.setCompleteListener(function () {
            cc.pool.putInPool(this);
            callback();
        }.bind(this));
    },

    unuse: function () {
        this.removeFromParent(false);
    }
});

ColorFulAnimationView.fillPool = function () {
    if (cleverapps.styles.ColorFulAnimationView.active) {
        for (var i = 0; i < cleverapps.styles.ColorFulAnimationView.animations.number; i++) {
            cc.pool.putInPool(new ColorFulAnimationView());
        }
    }
};

ColorFulAnimationView.factory = function () {
    return cc.pool.hasObject(ColorFulAnimationView) ? cc.pool.getFromPool(ColorFulAnimationView) : undefined;
};

cleverapps.styles.ColorFulAnimationView = {
    active: true,
    animations: {
        number: 3
    }
};
