/**
 * Created by anatoly on 28.08.2024
 */

var DangerComponent = function (duration) {
    cleverapps.EventEmitter.call(this);

    this.duration = duration;
    this.timer = Game.currentGame.timer;

    this.started = false;

    this.timer.on("onSecond", this.onSecond.bind(this));

    this.getAnimationView = function () {};
};

DangerComponent.prototype = Object.create(cleverapps.EventEmitter.prototype);
DangerComponent.prototype.constructor = DangerComponent;

DangerComponent.prototype.getTimeLeft = function () {
    return Math.max(this.duration - (this.timer.getInGameTime() * 1000), 0);
};

DangerComponent.prototype.isFinished = function () {
    return this.getTimeLeft() === 0;
};

DangerComponent.prototype.getPercentOfCompletion = function () {
    if (!this.duration) {
        throw new Error("Not danger duration!");
    }

    return (this.duration - this.getTimeLeft()) / this.duration;
};

DangerComponent.prototype.onSecond = function () {
    if (!this.started) {
        this.trigger("onStart");
        this.started = true;
    }

    if (this.isFinished()) {
        this.onFinish();
    }

    this.trigger("update");
};

DangerComponent.prototype.onFinish = function () {
    this.stop();
    Game.currentGame.counter.trigger();
};

DangerComponent.prototype.stop = function () {
    this.getAnimationView().stopBoerAnimation();
};

DangerComponent.prototype.onWin = function () {
    this.stop();
    this.getAnimationView().onWin();
};