/**
 * Created by Andrey Popov on 20.01.2021.
 */

var WysiwygPreviewView = WysiwygWindowBase.extend({
    ctor: function (wysiwygPreview, options) {
        this._super("Preview", options.width, options.height, true);

        this.wysiwygPreview = wysiwygPreview;

        var canvas = this.canvas = new cc.Node();

        canvas.setAnchorPoint(0.5, 0.5);
        this.addChild(canvas, 1);

        this.setLocalZOrder(1);
        this.setPositionRound(cleverapps.styles.WysiwygPreviewView);

        this.wysiwygPreview.on("clearComponents", this.clearComponents.bind(this), this);
        this.wysiwygPreview.on("showComponents", this.showComponents.bind(this), this);
        this.wysiwygPreview.on("showDirectory", this.showDirectory.bind(this), this);
        this.wysiwygPreview.on("showScript", this.showScript.bind(this), this);
        this.wysiwygPreview.on("updateComponent", this.updateComponent.bind(this), this);
        this.wysiwygPreview.on("selectComponent", this.selectComponent.bind(this), this);

        cleverapps.UI.onClick(this, function () {
            this.iFrames.forEach(function (iFrame) {
                iFrame.selectComponent();
            }); 
        }.bind(this), {
            interactiveScale: false
        });

        this.iFrames = [];
    },

    clearComponents: function () {
        if (this.directoryView) {
            this.directoryView.removeFromParent();
            this.directoryView = undefined;
        }
        if (this.scriptView) {
            this.scriptView.removeFromParent();
            this.scriptView = undefined;
        }
        if (this.featuresSelector) {
            this.featuresSelector.removeFromParent();
            this.featuresSelector = undefined;
        }

        this.canvas.removeAllChildren();
        this.iFrames.forEach(function (iFrame) {
            iFrame.hideIFrame();
        });
    },

    showDirectory: function (directoryItems) {
        this.createScroll(new DirectoryView(directoryItems));
        this.directoryView = this.scroll;
        this.scroll.scrollToPercent(100);
    },

    showScript: function (scriptFileName) {
        this.createScroll(new ScriptView(scriptFileName, this.width));
        this.scriptView = this.scroll;
    },

    showFeaturesSelector: function () {
        var styles = cleverapps.styles.WysiwygPreviewView.featuresSelector;
        var selectedClip = cleverapps.wysiwyg.hierarchy.getSelectedClip();

        var features = [new SelectorView(
            selectedClip && selectedClip.properties.resolutions ? selectedClip.properties.resolutions.filter(function (item) {
                return Wysiwyg.VIDEO_SIZES[item];
            }) : [],
            this.wysiwygPreview.selectedResolution,
            this.wysiwygPreview.selectResolution.bind(this.wysiwygPreview)
        )];

        if (selectedClip && selectedClip.children.length !== 1) {
            features.unshift(new PreviewSliderView(this.wysiwygPreview.wysiwyg.previewSlider));
        }

        this.featuresLayout = new cleverapps.Layout(features, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin,
            padding: styles.padding
        });

        if (this.featuresSelector) {
            this.featuresSelector.removeFromParent();
            delete this.featuresSelector;
        }

        this.featuresSelector = new cleverapps.UI.ScrollView(this.featuresLayout, {
            childrenVisibility: cleverapps.UI.ScrollView.CHILDREN_VISIBILITY_NONE,
            scrollBarEnabled: false,
            direction: cleverapps.UI.ScrollView.DIR_HORIZONTAL,
            outOfBoundaryScale: 0
        });
        this.featuresSelector.setContentSize2(this.width - styles.offset, styles.height);
        this.featuresSelector.setPositionRound(styles);
        this.featuresSelector.scrollToDefault();
        this.featuresSelector.setLocalZOrder(10000);
        this.addChild(this.featuresSelector);
    },

    showComponents: function (scenesToShow, hardReload) {
        var styles = cleverapps.styles.WysiwygPreviewView;

        var size = this.wysiwygPreview.canvasSize;
        var scale = Math.min((this.width - styles.padding.x) / size.width, (this.height - styles.padding.y) / size.height, 1);

        this.canvas.setContentSize2(size.width * scale, size.height * scale);
        this.canvas.setPositionRound(styles.canvas);

        var clipRows = [];
        var rowIndex = -1;

        this.iFrames.forEach(function (iFrame) {
            iFrame.hideIFrame();
            iFrame.removeFromParent();
        });

        var params = cleverapps.getRequestParameters(location.hash);
        var scenesInRow = scenesToShow.length % 3 === 0 && size.height > size.width ? 3 : 2;
        var requiredWidth = 0;
        var requiredHeight = 0;

        this.iFrames = scenesToShow.map(function (sceneToShow, index) {
            if (index % scenesInRow === 0) {
                rowIndex++;
                clipRows.push([]);
            }

            var iFramePreview;
            if (this.iFrames[index]) {
                iFramePreview = this.iFrames[index];
                iFramePreview.setContentSize(this.canvas.getContentSize());

                var hierarchy = cleverapps.wysiwyg.hierarchy;
                iFramePreview.sendMessage({
                    type: "reload",
                    resolution: this.wysiwygPreview.selectedResolution,
                    scene: sceneToShow.index,
                    snapshot: sceneToShow.snapshot || "wysiwygdummyclip",
                    clipId: params.clipId,
                    hardReload: hardReload,
                    hierarchyItems: hierarchy.getClipContent(hierarchy.getSelectedClip())
                });
            } else {
                var url = window.location.origin + window.location.pathname
                    + "#preview=true&directory=" + params.directory + "&clipId=" + params.clipId
                    + "&res=" + this.wysiwygPreview.selectedResolution
                    + "&scene=" + sceneToShow.index
                    + "&snapshot=" + (sceneToShow.snapshot || "wysiwygdummyclip");
                iFramePreview = new IFramePreview(url, this.canvas.getContentSize());
            }

            clipRows[rowIndex].push(iFramePreview);

            if (rowIndex === 0) {
                requiredWidth += iFramePreview.width;
            }
            if (index % scenesInRow === 0) {
                requiredHeight += iFramePreview.height;
            }

            return iFramePreview;
        }.bind(this));

        var scenesLayout = new cleverapps.Layout(clipRows.map(function (row) {
            return new cleverapps.Layout(row, { direction: cleverapps.UI.HORIZONTAL });
        }), { direction: cleverapps.UI.VERTICAL });

        scenesLayout.setScale(Math.min(Math.max(this.canvas.width, this.width) / requiredWidth, Math.max(this.canvas.height, this.height) / requiredHeight));
        scenesLayout.setPositionRound(this.canvas.width / 2, this.canvas.height / 2);

        this.canvas.addChild(scenesLayout);

        this.iFrames.forEach(function (iFrame, index) {
            if (index < scenesToShow.length) {
                iFrame.showIFrame();
            }
        });

        this.showFeaturesSelector();
    },

    updateComponent: function (item) {
        this.sendMessageToIFrame({
            type: "redrawComponent",
            item: item
        });
    },

    selectComponent: function (item) {
        this.iFrames.forEach(function (iFrame) {
            iFrame.selectComponent(item);
        });
    },

    sendMessageToIFrame: function (data) {
        this.iFrames.forEach(function (iFrame) {
            iFrame.sendMessage(data);
        });
    }
});

cleverapps.styles.WysiwygPreviewView = {
    x: { align: "center", dx: -50 },
    y: { align: "top", dy: -100 },

    padding: {
        x: 20,
        y: 40
    },

    featuresSelector: {
        x: { align: "center" },
        y: { align: "top", dy: 54 },

        offset: 170,
        height: 50,
        margin: 15,
        padding: {
            left: 20,
            right: 20
        }
    },

    canvas: {
        x: { align: "center" },
        y: { align: "center" }
    }
};