/**
 * Created by Andrey Popov on 10.11.20
 */

var LayersSwitcherView = cc.Node.extend({
    ctor: function (layers) {
        this._super();

        if (Editor.currentEditor.layerManager) {
            Editor.currentEditor.layerManager.onRebuild = this.createListener(this.rebuild.bind(this));
        }

        Editor.currentEditor.onChangeLayer = this.onChangeLayer.bind(this);

        var styles = cleverapps.styles.LayersSwitcherView;

        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize(styles);

        var title = cleverapps.UI.generateTTFText("Layers", cleverapps.styles.FONTS.EDITOR_BLUE_TEXT);
        title.setPositionRound(styles.title);
        this.addChild(title);

        var slider = this.slider = new Slider();
        slider.setContentSize(styles.slider);
        slider.setPositionRound(styles.slider);
        slider.setRotation(-90);

        slider.percentChangeCallback = function (sender) {
            this._setLayer(Math.round(sender.getPercent() * (this.countOfLayers - 1) / 100));
        }.bind(this);
        slider.incPercentCallback = function () {
            this._setLayer(this.current + 1);
        }.bind(this);
        slider.decPercentCallback = function () {
            this._setLayer(this.current - 1);
        }.bind(this);

        this.addChild(slider);

        var bg = new cc.Scale9Sprite(bundles.editor.frames.editor_layers_white);
        bg.setContentSize(this.getContentSize());
        bg.setPositionRound(this.width / 2, this.height / 2);
        bg.setAnchorPoint(0.5, 0.5);
        this.addChild(bg, -1);

        this.labels = [];

        this.rebuild(layers);
        this.onChangeLayer(this.countOfLayers - 1);
    },

    rebuild: function (layers) {
        var styles = cleverapps.styles.LayersSwitcherView;

        this.labels.forEach(function (label) {
            label.removeFromParent();
        });

        var layersCount = layers;
        if (Array.isArray(layers)) {
            layersCount = layers.length;

            layers.forEach(function (labelText, index) {
                var label = cleverapps.UI.generateOnlyText(labelText, cleverapps.styles.FONTS.EDITOR_BLUE_TEXT);
                this.addChild(label);
                label.setAnchorPoint(0, 0.5);
                var percent = index / (layersCount - 1);
                label.setPositionRound(styles.label.x, this.slider.y - this.slider.width / 2 + percent * this.slider.width);
                this.labels.push(label);
            }, this);
        }

        this.countOfLayers = layersCount;
    },

    onChangeLayer: function (layer) {
        this.current = layer;

        var percent = this.countOfLayers > 1 ? this.current * 100 / (this.countOfLayers - 1) : 100;
        this.slider.setPercent(percent);
    },

    _setLayer: function (layer) {
        layer = cleverapps.clamp(layer, 0, this.countOfLayers - 1);

        Editor.currentEditor.setLayer(layer);
    }
});

cleverapps.styles.LayersSwitcherView = {
    width: 60,
    height: 160,

    title: {
        x: { align: "center" },
        y: { align: "top", dy: 35 }
    },

    label: {
        x: 70
    },

    slider: {
        height: 9,
        width: 120,

        x: { align: "center" },
        y: { align: "center" }
    }
};