/**
 * Created by vladislav on 1/9/19
 */

var IceMakerCellView = BaseCellView.extend({
    ctor: function (iceMakerCell) {
        this._super();

        this.setContentSize(cleverapps.styles.BaseCellView.CELL_SIZE_PX, cleverapps.styles.BaseCellView.CELL_SIZE_PX);
        this.setAnchorPoint(0.5, 0.5);

        var animation = this.animation = new cleverapps.Spine(bundles.ice_maker.jsons.ice_maker_json);
        var styles = cleverapps.styles.IceMakerCellView.animations;
        animation.setAnimation(0, iceMakerCell.isForGoal && styles.goal ? "goal" : "idle", true);
        this.addChild(animation);
        animation.setPositionRound(this.width / 2, this.height / 2);

        if (!iceMakerCell.isForGoal) {
            this.livesText = cleverapps.UI.generateImageText(iceMakerCell.lives, cleverapps.styles.FONTS.INGAME_COMPACT_TEXT);
            this.addChild(this.livesText);
        }

        this.initialize(iceMakerCell);
        this.updateLivesText();
    },

    initialize: function (iceMakerCell) {
        this._super(iceMakerCell);

        this.cell.onChangeLivesListener = this.changeLives.bind(this);
    
        if (this.livesText) {
            this.livesText.setVisible(true);
            this.updateLivesText();
        }
        if (this.animation) {
            this.animation.setAnimation(0, iceMakerCell.isForGoal && cleverapps.styles.IceMakerCellView.animations.goal ? "goal" : "idle", true);
        }
    },

    changeLives: function () {
        this.updateLivesText();

        this.squeeze(-1);
    },

    updateLivesText: function () {
        if (!this.livesText) {
            return;
        }

        var styles = cleverapps.styles.IceMakerCellView.lives;
        if (this.cell.lives < 10) {
            this.livesText.setScale(styles.scale[0]);
        } else {
            this.livesText.setScale(styles.scale[1]);
        }

        this.livesText.setString("" + this.cell.lives);
        if (this.animation.getScaleX() < 0) {
            this.livesText.setPositionRound(cleverapps.styles.BaseCellView.CELL_SIZE_PX - styles.x, styles.y);
        } else {
            this.livesText.setPositionRound(styles);
        }
    },

    moveAnimation: function (params) {
        var animation = this.animation;
        if (params.dir.x !== 0 && params.dir.x === this.animation.getScaleX()) {
            animation.setScaleX(-animation.getScaleX());
            this.updateLivesText();
        }

        var styles = cleverapps.styles.IceMakerCellView.animations;
        var animName = params.dir.x !== 0 && styles.horizontal ? "move_horizontal" : "move_vertical";
        var callback = function () {
            this.setLocalZOrder(10);
            animation.setAnimation(0, animName, false);
            animation.setCompleteListener(function () {
                animation.setAnimation(0, "idle", true);
                this.setLocalZOrder(this.baseZOrder);
                animation.setCompleteListener();
                params.callback();
            }.bind(this));
            cleverapps.audio.playSound(bundles.ice_maker.urls.ice_maker_fly_effect);
        }.bind(this);

        if (params.iceAnimation) {
            animation.setAnimation(0, "honey", false);
            animation.setCompleteListener(callback);
        } else {
            callback();
        }
    },

    startExplodingViaCollect: function () {
        var styles = cleverapps.styles.IceMakerCellView.animations;
        this.animation.setAnimation(0, styles.goal ? "goal" : "move_vertical", true);

        this._super.apply(this, arguments);
    },

    startExploding: function () {
        if (this.livesText) {
            this.livesText.setVisible(false);
        }

        if (Game.currentGame.goals.hasType(this.cell.getGoalId())) {
            this.startExplodingViaCollect.apply(this, arguments);
        } else {
            this.stopAllActions();
            this.animation.runAction(new cc.Spawn(
                new cc.ScaleTo(0.5, 0),
                new cc.RotateBy(0.5, 360)
            ));
        }
    },

    getStyles: function () {
        return cleverapps.styles.IceMakerCellView;
    }
});

cleverapps.styles.IceMakerCellView = {
    lives: {
        x: 52,
        y: 15,
        scale: [0.7, 0.6]
    },

    animations: {
        horizontal: true
    }
};