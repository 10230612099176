/**
 * Created by slava on 4/24/17.
 */

var ToolView = cc.Node.extend({
    ctor: function () {
        this._super();

        if (cleverapps.config.wysiwygMode) {
            this.setVisible(false);
        }

        this.setLocalZOrder(100);
        this._setGlobalZOrder(true);
        this.updateSize();
        this.addSpotlight();
        this.addMenu();
        this.updateMenu();
        this.hide(true);

        cleverapps.toolModel.on("change", this.createListener(this.updateMenu.bind(this)));
        cleverapps.toolModel.on("show", this.createListener(this.show.bind(this)));
        cleverapps.toolModel.on("hide", this.createListener(this.hide.bind(this)));
        cleverapps.toolModel.on("hideSpotlight", this.createListener(this.hideSpotlight.bind(this)));
    },

    updateSize: function () {
        var styles = cleverapps.styles.ToolMenu;
        this.setContentSize(styles.width, cleverapps.resolution.getSceneSize().height - styles.topOffset);
    },

    addSpotlight: function () {
        this.spotlight = new SpotlightView();
        this.spotlight.setupChildren = function () {
            this.spotlight.setPositionRound(cleverapps.styles.ToolMenu.spotlight);
        }.bind(this);
        this.spotlight.setupChildren();
        this.addChild(this.spotlight);
    },

    addMenu: function () {
        var styles = cleverapps.styles.ToolMenu.menu;
        var menu = this.menu = new cc.Scale9Sprite(bundles.tool.frames.tool_bg);
        if (connector.info.isMobile) {
            menu.setScale(1.5);
        }
        menu.setAnchorPoint(0, 0.5);
        menu.setupChildren = function () {
            menu.setPositionRound(styles.x, (this.spotlight.y / 2));
        }.bind(this);
        menu.setupChildren();
        this.addChild(menu);
    },

    updateMenu: function (search) {
        var styles = cleverapps.styles.ToolMenu.menu;
        var menu = this.menu;
        if (menu.list) {
            menu.list.removeFromParent();
        }
        var items = cleverapps.toolModel.listMenuItems().map(this.createMenuItem.bind(this));

        var list = new cleverapps.Layout(items, {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });

        cleverapps.UI.onClick(list, function () {
            cleverapps.toolModel.hide();
        }, {
            interactiveScale: false
        });

        cleverapps.UI.wrapWithPadding(list, styles.padding, true);

        var scroll = new cleverapps.UI.ScrollView(list, {
            childrenVisibility: cleverapps.UI.ScrollView.CHILDREN_VISIBILITY_NONE,
            direction: cleverapps.UI.ScrollView.DIR_VERTICAL
        });

        scroll.setAnchorPoint(0, 0);

        menu.list = scroll;
        menu.addChild(scroll);

        menu.updateSize = function () {
            var winSize = cleverapps.resolution.getSceneSize();
            if (connector.info.isMobile) {
                winSize.height /= 1.5;
            }
            scroll.setContentSize(list.width, Math.min(list.height, winSize.height + styles.dHeight));
            menu.setContentSize(scroll.getContentSize());
        };

        menu.updateSize();

        scroll.scrollToDefault();

        this.spotlight.onModelChange(search || "");
    },

    getMenuItemText: function (item) {
        var text = item.key;

        if (item.isFlag() && cleverapps.flags.get(item.entry.flag)) {
            text = "^^" + text;
        }

        var parent = item.parent;
        while (parent && !parent.isRoot()) {
            text = ToolMenuItem.SUB_ITEM + text;
            parent = parent.parent;
        }

        return text;
    },

    createMenuItem: function (item) {
        var itemText = this.getMenuItemText(item);
        var font = cleverapps.styles.FONTS.TOOL_MENU_TEXT;

        if (itemText.indexOf(ToolMenuItem.ACTIVE_ITEM) !== -1) {
            font = cleverapps.styles.FONTS.TOOL_MENU_ACTIVE_TEXT;
        }

        var text = new TextWithIcon(itemText, {
            font: font,
            notLocalized: true,
            icons: {
                "^^": bundles.checkbox.frames.check_mark_png
            }
        });

        if (item.isFlag()) {
            cleverapps.flags.on("change:" + item.entry.flag, function () {
                cleverapps.toolModel.refresh();
            }, text);
        }

        cleverapps.UI.onClick(text, function () {
            item.clickHandler();
        });
        cleverapps.UI.applyHover(text);
        text.setAnchorPoint(0, 0.5);
        return text;
    },

    addOpener: function () {
        var opener = this.opener = new cc.Node();
        opener.setLocalZOrder(99);
        opener._setGlobalZOrder(true);
        var styles = cleverapps.styles.ToolMenu.opener;
        opener.setAnchorPoint(0, 0.5);
        opener.setContentSize2(styles.width, styles.height);
        cleverapps.UI.onClick(opener, function () {
            cleverapps.toolModel.show();
        });
        opener.setPosition(styles.x, cleverapps.resolution.getSceneSize().height / 2);
        this.getParent().addChild(opener);
    },

    show: function (silent) {
        var styles = cleverapps.styles.ToolMenu;

        this.spotlight.show();
        if (!connector.info.isMobile) {
            this.spotlight.setFocus();
        }

        this.setVisible(true);
        this.spotlight.setVisible(true);

        if (silent) {
            this.setPositionRound(styles);
            return;
        }

        this.runAction(
            new cc.MoveTo(0.1, this.calculatePositionRound(styles))
        ).setFinalize(function () {
            this.setPositionRound(styles);
        }.bind(this));
    },

    hide: function (silent) {
        this.spotlight.setBlur();
        this.spotlight.hide();
        var pos = cc.p(-this.menu.width, 0);

        if (silent) {
            this.setPositionRound(pos);
            this.setVisible(false);
            return;
        }

        this.runAction(
            new cc.Sequence(
                new cc.MoveTo(0.1, this.calculatePositionRound(pos)),
                new cc.Hide()
            )
        ).setFinalize(function () {
            this.setPositionRound(pos);
        }.bind(this));
    },

    hideSpotlight: function () {
        this.spotlight.setBlur();
        this.spotlight.hide();
        this.spotlight.setVisible(false);
    },

    setupChildren: function () {
        this.stopAllActions();
    }
});

cleverapps.styles.DECORATORS = cleverapps.overrideStyles(cleverapps.styles.DECORATORS, {
    TOOL_MENU_TEXT_STROKE: {
        color: cleverapps.styles.COLORS.BLACK,
        size: 1
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    TOOL_MENU_TEXT: {
        size: 30,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.TOOL_MENU_TEXT_STROKE
    },
    TOOL_MENU_ACTIVE_TEXT: {
        size: 30,
        color: cleverapps.styles.COLORS.YELLOW,
        stroke: cleverapps.styles.DECORATORS.TOOL_MENU_TEXT_STROKE
    }
});

cleverapps.styles.ToolMenu = {
    x: 0,
    y: 0,
    width: 300,
    topOffset: 80,
    menu: {
        x: 0,
        topOffset: 200,
        bottomOffset: 50,
        dHeight: -230,
        margin: -4,
        padding: {
            top: 20,
            right: 20,
            left: 30,
            bottom: 20
        }
    },
    spotlight: {
        x: { align: "left", dx: 10 },
        y: { align: "top", dy: -20 }
    },
    opener: {
        x: 0,
        width: 50,
        height: 400
    }
};
