/**
 * Created by slava on 9/8/17
 */

var LineBoosterView = FieldBoosterView.extend({
    onHurtCellsAnimation: function (cells) {
        var fieldView = Game.currentGame.field.onGetView();

        var start = cells[0];
        var startP = BaseCellView.alignInTheGrid(start.x, start.y);

        var animation = new cleverapps.Spine(bundles.game.jsons.linebooster_spine_json);
        animation.setLocalZOrder(100);
        fieldView.addChild(animation);

        var styles = cleverapps.styles.LineBoosterView;
        animation.setPosition(startP.x, startP.y + styles.animation.y);

        animation.setAnimation(0, "animation", false);
        animation.setTimeScale(styles.timeScale.scale);
        animation.setCompleteListener(function () {
            animation.runAction(new cc.Sequence(
                new cc.CallFunc(function () {
                    if (Game.currentGame) {
                        Game.currentGame.bigExplode();
                    }
                }),
                new cc.RemoveSelf()
            ));
        });

        cleverapps.audio.playSound(bundles.game.urls.rolling_pin_use_effect);
    }
});

cleverapps.styles.LineBoosterView = {
    animation: {
        y: -450
    },

    timeScale: {
        scale: 1.4
    }
};