/**
 * Created by Vladislav on 19.07.2024.
 */

var WechatShutdownWindow = CleverappsWindow.extend({
    onWindowLoaded: function () {
        this._super({
            content: this.getContent(),
            name: "wechatshutdownwindow",
            title: "《萌猪消消乐面包版》游戏即将关服通知",
            button: {
                text: "OK",
                onPressed: this.close.bind(this)
            }
        });
    },

    getContent: function () {
        var styles = cleverapps.styles.WechatShutdownWindow;

        var message = "尊敬的玩家：\n"
            + "感谢您对《萌猪消消乐面包版》游戏的支持。由于运营策略调整，我们遗憾地通知您，《萌猪消消乐面包版》游戏将于2024年8月1日00:00正式关服。\n"
            + "请您在关服前处理好游戏内的相关事宜，并关注我们的后续公告以了解可能的补偿措施。\n"
            + "如有任何疑问，请随时联系我们的客服。\n"
            + "感谢您的理解与支持！\n"
            + "《萌猪消消乐面包版》游戏运营团队";

        var text = cleverapps.UI.generateOnlyText(message, cleverapps.styles.FONTS.WINDOW_TEXT);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        text.setDimensions(styles.text.width, 0);
        text.fitTo(undefined, styles.text.height);

        return text;
    }
});

cleverapps.styles.WechatShutdownWindow = {
    text: {
        width: 650,
        height: 400
    }
};
