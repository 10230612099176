/**
 * Created by Ivan on 05.06.2024
 */

var NewPuzzlesSlotsView = cc.Node.extend({
    ctor: function (puzzleComponent) {
        this._super();

        var styles = cleverapps.styles.NewPuzzlesSlotsView;
        this.setAnchorPoint(0.5, 0.5);

        this.puzzleComponent = puzzleComponent;
        this.collection = puzzleComponent.collection;
        this.slots = [];

        this.layout = new cleverapps.Layout([], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin,
            padding: styles.padding
        });

        this.createFg();
        this.createScroll();

        this.update();
    },

    update: function (animate) {
        if (animate) {
            var movingNode = cleverapps.scenes.getMovingNode();
            this.puzzleComponent.newStickers.forEach(function (stickerView) {
                stickerView.replaceParentSamePlace(movingNode, {
                    keepScale: true
                });
            });
        }

        this.updateLayout();
        this.updateScroll();
        this.updateText(animate);

        if (animate) {
            this.puzzleComponent.newStickers.forEach(function (stickerView) {
                stickerView.replaceParentSamePlace(stickerView.slot, {
                    keepScale: true
                });
                var position = stickerView.calculatePositionRound(cleverapps.styles.PuzzleStickerView.position);
                stickerView.runAction(new cc.MoveTo(0.4, position).easing(cc.easeBackOut()));
            });
        }
    },

    updateLayout: function () {
        var slotsLength = Math.max(3, this.puzzleComponent.newStickers.length);
        while (this.slots.length < slotsLength) {
            var slot = new cc.Node();
            slot.setAnchorPoint(0.5, 0.5);
            slot.setContentSize2(cleverapps.styles.PuzzlesComponent.placeholder);
            slot.setScale(0.6);
            this.slots.push(slot);
        }
        this.layout.setItems(this.slots);

        this.puzzleComponent.newStickers.forEach(function (stickerView, index) {
            if (!stickerView.slot) {
                stickerView.slot = this.slots[index];
                this.slots[index].addChild(stickerView);
                stickerView.setPositionRound(cleverapps.styles.PuzzleStickerView.position);
            }
        }.bind(this));
    },

    takeStickerFromSlot: function (stickerView) {
        this.slots.splice(this.slots.indexOf(stickerView.slot), 1);
        stickerView.slot = undefined;
        this.update(true);
    },

    createFg: function () {
        var styles = cleverapps.styles.NewPuzzlesSlotsView;

        var fg = new cc.Scale9Sprite(bundles.stickers_collection_window.frames.foreground_png);
        fg.setContentSize2(styles.fg);
        this.setContentSize2(fg.getContentSize());

        this.addChild(fg);
        fg.setPositionRound(this.width / 2, this.height / 2);
    },

    createScroll: function () {
        var styles = cleverapps.styles.NewPuzzlesSlotsView;

        var scroll = this.scroll = new cleverapps.UI.ScrollView(this.layout, {
            childrenVisibility: cleverapps.UI.ScrollView.CHILDREN_VISIBILITY_NONE,
            direction: cleverapps.UI.ScrollView.DIR_HORIZONTAL,
            outOfBoundaryScale: false,
            padding: styles.scroll.padding
        });

        scroll.setContentSize2(styles.scroll);
        scroll.setPositionRound(this.width / 2, this.height / 2);
        scroll.scrollToPercent(0);

        this.addChild(scroll);
    },

    setDisableScroll: function (isDisabled) {
        if (this.scroll) {
            this.scroll.stopAutoScroll();
            this.scroll.touchScrollDisabled = isDisabled;
        }
    },

    updateScroll: function () {
        this.scroll.updateInnerContent();
        this.scroll.scrollToPercent(0);
        this.scroll.barHandler.reset();
    },

    getText: function () {
        var message;

        if (this.collection.isCompleted) {
            message = "StickersCollectionWindow.Open.CompletedText";
        }
        if (this.collection.getOpenedStickersAmount(true) === 0) {
            message = "StickersCollectionWindow.Open.PlayLevelsText";
        }

        return message;
    },

    createText: function (message, animate) {
        if (this.text) {
            this.text.removeFromParent();
            this.text = undefined;
        }

        var text = this.text = cleverapps.UI.generateOnlyText(message, cleverapps.styles.FONTS.STICKERS_OPEN_TEXT);
        this.addChild(this.text);
        if (animate) {
            text.setCascadeOpacityEnabledRecursively(true);
            text.setOpacity(0);
            text.runAction(new cc.FadeIn(1));
        }

        var styles = cleverapps.styles.NewPuzzlesSlotsView.text;
        this.text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        this.text.setDimensions(styles.width, 0);
        this.text.fitTo(undefined, styles.height);
        this.text.setPositionRound(this.width / 2, this.height / 2);
    },

    updateText: function (animate) {
        var message = this.getText();
        if (!message) {
            this.text && this.text.removeFromParent();
            return;
        }

        this.createText(message, animate);
    }
});

cleverapps.styles.NewPuzzlesSlotsView = {
    margin: 90,

    fg: {
        width: 722,
        height: 260
    },

    padding: {
        left: 40,
        right: 40
    },

    scroll: {
        width: 710,
        height: 240
    },

    text: {
        width: 500,
        height: 150
    }
};
