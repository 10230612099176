/**
 * Created by slava on 3/7/19
 */

match3 = {};

var CleverAppsHeroesRules = {
    ClockMakerPortals: true,

    SlideFallDown: false,

    Features: {
        Plane: false
    },

    Plus: true,

    Heroes: true,

    ExplodeDuration: 0.5
};

var NewRules = {
    ClockMakerPortals: true,

    SlideFallDown: true,

    Features: {
        Plane: true
    },

    Plus: false,

    Heroes: false,

    ExplodeDuration: 0.3
};

var Match3Rules = CleverAppsHeroesRules;