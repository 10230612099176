/**
 * Created by Andrey Popov on 10.11.2021
 */

var ExplorerItemView = cc.Node.extend({
    ctor: function (explorerItem, highlight) {
        this._super();

        this.explorerItem = explorerItem;

        var styles = cleverapps.styles.ExplorerItemView;
        var width = styles.width;

        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize2(width, styles.height);

        if (highlight) {
            var icon = this.icon = new cc.Sprite(bundles.wysiwyg.frames.scenario_item);
            icon.setAnchorPoint(0.5, 0.5);
            icon.setPositionRound(styles.icon);
            icon.setRotation(90);
            this.addChild(icon);
        }

        var text = this.text = cleverapps.UI.generateOnlyText(this.explorerItem, cleverapps.styles.FONTS.EXPLORER_ITEM_TEXT);
        text.fitTo(width, styles.height);
        this.underline = cleverapps.UI.drawUnderline(text, styles.underline);
        this.addChild(this.underline);
        this.underline.setVisible(false);
        cleverapps.UI.applyHover(this, {
            onMouseOver: this.onMouseOver.bind(this),
            onMouseOut: this.onMouseOut.bind(this)
        });
        text.setDimensions(width, 0);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_LEFT);

        text.setAnchorPoint(0.5, 0.5);
        text.setPositionRound(styles.text);
        this.addChild(text);

        cleverapps.UI.onClick(this.text, cleverapps.atlasAnalyzer.bundlesExplorer.selectItem.bind(cleverapps.atlasAnalyzer.bundlesExplorer, undefined, this.explorerItem));
    },

    setSelected: function (selected) {
        this.text.setFontFillColor(selected ? cleverapps.styles.COLORS.BLACK : cleverapps.styles.COLORS.COLOR_VERY_DARK_GRAY);
        this.text._setUpdateTextureDirty();
    },

    onMouseOver: function () {
        if (this.underline && this.underline.visible === false) {
            this.underline.setVisible(true);
            this.underline.setScale(0);
            this.underline.runAction(new cc.ScaleTo(0.3, 1));
        }
    },

    onMouseOut: function () {
        if (this.underline && this.underline.visible === true) {
            this.underline.runAction(new cc.Sequence(
                new cc.ScaleTo(0.3, 0),
                new cc.CallFunc(function () {
                    this.underline.setVisible(false);
                }.bind(this))
            ));
        }
    }
});

cleverapps.styles.ExplorerItemView = {
    width: 380,
    height: 26,

    text: {
        x: { align: "right", dx: 12 },
        y: { align: "center" }
    },

    icon: {
        x: { align: "left", dx: -2 },
        y: { align: "center", dy: 2 }
    },

    underline: {
        x: { align: "left", dx: 6 },
        y: { align: "bottom", dy: -2 }
    }
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    EXPLORER_ITEM_TEXT: {
        size: 24,
        color: cleverapps.styles.COLORS.COLOR_VERY_DARK_GRAY,
        font: bundles.wysiwyg.urls.strict_font_ttf
    },

    SELECTED_EXPLORER_ITEM_TEXT: {
        size: 24,
        color: cleverapps.styles.COLORS.BLACK,
        font: bundles.wysiwyg.urls.strict_font_ttf
    }
});
