/**
 * Created by vladislav on 1/26/2024
 */

var KongregateLogic = function () {
    PlatformLogic.call(this);

    // achievements are rewarded only after platform creates their badges; resend to assure awarding when badge is ready
    connector.platform.whenConnected(this.resendAchievements.bind(this));
};

KongregateLogic.prototype = Object.create(PlatformLogic.prototype);
KongregateLogic.prototype.constructor = KongregateLogic;

KongregateLogic.prototype.resendAchievements = function () {
    if (cleverapps.achievements) {
        cleverapps.achievements.listCompleted().forEach(function (achievement) {
            connector.social.markAchievement(achievement.getConfig());
        });
    }
};