var ColorCookieManCellView = BaseCellView.extend({
    ctor: function (colorcookiemancell) {
        this._super();

        this.setContentSize(cleverapps.styles.BaseCellView.CELL_SIZE_PX, cleverapps.styles.BaseCellView.CELL_SIZE_PX);
        this.setAnchorPoint(0.5, 0.5);

        if (colorcookiemancell.findComponent(ColorComponent).color !== undefined) {
            this.animation = new cleverapps.Spine(bundles.colorcookieman.jsons.color_cookieman_spine_json);
            this.animation.setPosition(this.width / 2, this.height / 2);
            this.addChild(this.animation);
        }

        this.setCascadeOpacityEnabled(true);

        this.initialize(colorcookiemancell);
    },

    getSkin: function (colorcookiemancell) {
        var colors = {
            "a": "blue",
            "b": "green",
            "c": "purple",
            "d": "red",
            "e": "yellow",
            "w": "white"
        };

        return colors[colorcookiemancell.findComponent(ColorComponent).color];
    },

    initialize: function (colorcookiemancell) {
        this._super(colorcookiemancell);

        colorcookiemancell.onBeforeExplodeListener = this.startExplodingViaCollect.bind(this);
        colorcookiemancell.onFinishMoveListener = this.cookieManFinishMoving.bind(this);

        var colorComponent = colorcookiemancell.findComponent(ColorComponent);
        colorComponent.view.show();

        if (this.animation) {
            this.animation.setSkin(this.getSkin(colorcookiemancell));
            this.animation.setVisible(false);
        }

        this.smiling = false;
        this.setVisible(colorcookiemancell.visible);
    },

    cookieManFinishMoving: function (destination, options, onFinishCallback) {
        this.finishMoving(destination, options, onFinishCallback);
        this.finishSmile();
    },

    swapImageToAnimation: function () {
        this.runAction(new cc.CallFunc(function () {
            var colorComponent = this.cell.findComponent(ColorComponent);
            colorComponent.view.hide();

            this.animation.visible = true;
        }.bind(this)));
    },

    finishSmile: function () {
        if (this.smiling) {
            this.smiling = false;

            var colorComponent = this.cell.findComponent(ColorComponent);
            colorComponent.view.show();

            this.animation.visible = false;
        }
    },

    smile: function () {
        if (!this.animation) {
            return;
        }

        if (this.smiling) {
            return;
        }
        this.smiling = true;
        this.animation.setAnimation(0, "animation", false);
        this.animation.setCompleteListener(this.finishSmile.bind(this));
        this.swapImageToAnimation();
        if (bundles.colorcookieman.urls.clown_smile_effect) {
            cleverapps.audio.playSound(bundles.colorcookieman.urls.clown_smile_effect);
        }
    },

    startExplodingViaCollect: function () {
        if (bundles.colorcookieman.urls.clown_collect_effect) {
            cleverapps.audio.playSound(bundles.colorcookieman.urls.clown_collect_effect);
        }

        this._super.apply(this, arguments);
    },

    cookieUp: function (callback) {
        callback = callback || function () {};
        var initialZOrder = this.getLocalZOrder();
        if (this.animation) {
            this.setLocalZOrder(3);
            this.smiling = true;
            this.animation.setAnimation(0, "up", false);
            this.animation.setCompleteListener(function () {
                callback();
                this.finishSmile();
                this.setLocalZOrder(initialZOrder);
            }.bind(this));
            this.swapImageToAnimation();
        } else {
            this.setLocalZOrder(3);
            var position = BaseCellView.alignInTheGrid(this.cell.x, this.cell.y);
            this.runAction(new cc.Sequence(
                new cc.DelayTime(0.3),
                new cc.MoveTo(0.5, position),
                new cc.CallFunc(function () {
                    this.setLocalZOrder(initialZOrder);
                    callback();
                }.bind(this))
            ));
        }
        if (bundles.colorcookieman.urls.clown_jump_effect) {
            cleverapps.audio.playSound(bundles.colorcookieman.urls.clown_jump_effect);
        }
    },

    cookieIn: function (callback) {
        callback = callback || function () {};
        cleverapps.audio.playSound(bundles.colorcookieman.urls.clown_in_effect || bundles.game.urls.bomb_by_left_moves_explosion_effect);
        if (this.animation) {
            this.smiling = true;
            this.animation.setAnimation(0, "in", false);
            this.animation.setCompleteListener(function () {
                callback();
                this.finishSmile();
            }.bind(this));
            this.swapImageToAnimation();
        } else {
            this.setScale(0);
            this.runAction(new cc.Sequence(
                new cc.Spawn(new cc.RotateBy(1, 360 * 2), new cc.ScaleTo(1, 1)),
                new cc.CallFunc(callback)
            ));
        }
    },

    cookieOut: function (callback) {
        callback = callback || function () {};
        cleverapps.audio.playSound(bundles.colorcookieman.urls.clown_out_effect || bundles.game.urls.bomb_by_left_moves_explosion_effect);
        if (this.animation) {
            this.smiling = true;
            this.animation.setAnimation(0, "out", false);
            this.animation.setCompleteListener(function () {
                callback();
                this.finishSmile();
                this.setVisible(false);
            }.bind(this));
            this.swapImageToAnimation();
        } else {
            this.runAction(new cc.Sequence(
                new cc.Spawn(new cc.RotateBy(1, -360 * 2), new cc.ScaleTo(1, 0)),
                new cc.CallFunc(callback)
            ));
        }
    }
});