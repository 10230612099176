/**
 * Created by vladislav on 29.10.2021
 */

var DrawCallsDebugger = function () {
    this.nodes = {};

    if (engine === "creator") {
        this.drawCalls = 0;
        this.currentFrame = -1;
        creator.director.onBatchBroken = this.onBatchBroken.bind(this);
    }
};

DrawCallsDebugger.prototype.onBatchBroken = function (info) {
    if (this.currentFrame !== cc.director.getTotalFrames()) {
        this.currentFrame = cc.director.getTotalFrames();
        this.drawCalls = 1;
    } else {
        this.drawCalls++;
    }

    if (info.comp.node.ccObject) {
        info.comp.node.ccObject.showDrawCall(info);
    }
};

DrawCallsDebugger.prototype.addNode = function (node) {
    this.nodes[node.__instanceId] = node;
};

DrawCallsDebugger.prototype.analyzeDrawCalls = function () {
    Object.values(this.nodes).sort(function (a, b) {
        return a.numberOfDraw - b.numberOfDraw;
    }).forEach(function (node, index) {
        node.debugBorder({
            labelText: node.numberOfDraw,
            noResLinks: true
        });
        var info = index + (node.numberOfDraw !== index ? " (" + node.numberOfDraw + ") " : "") + " " + node.debugGetName();
        if (node instanceof cc.LabelTTF) {
            info += " " + node.getString();
        }
        var virtualResource = node.virtualResource;
        if (virtualResource) {
            info += " " + virtualResource.toString();
        }
        var textureBundle = node.getTextureBundle && node.getTextureBundle();
        if (textureBundle) {
            info += " " + textureBundle;
        }

        if (typeof node.jsonName === "string") {
            info += " " + node.jsonName;
        }
        if (node.debugGetName() === "SkeletonAnimation") {
            info += " stack - " + node.drawStack;
        }
        console.log(info);
        if (node.drawInfo) {
            console.log(node.drawInfo);
        }
    });
    console.log(this.getNumberOfDraw() + " draw calls");
    console.log(this.getNumberOfVertices() + " vertexes");
};

DrawCallsDebugger.prototype.debugDrawCalls = function () {
    if (this.running) {
        return;
    }

    var nodes = Object.values(this.nodes);
    if (nodes.length) {
        nodes.forEach(function (node) {
            node.cleanupBorder();
        });

        this.nodes = {};

        return;
    }

    this.running = true;

    setTimeout(function () {
        this.analyzeDrawCalls();

        this.running = false;
    }.bind(this), 100);
};

DrawCallsDebugger.prototype.getNumberOfDraw = function () {
    if (engine === "cocos2d") {
        return cc.g_NumberOfDraws;
    }

    return this.drawCalls;
    // return cc.gfx.deviceManager.gfxDevice.numDrawCalls;
};

DrawCallsDebugger.prototype.getNumberOfVertices = function () {
    if (engine === "cocos2d") {
        return cc.g_NumberOfVertexes;
    } 
    return cc.gfx.deviceManager.gfxDevice.numTris / 3;
};

cc.Node.prototype.showDrawCall = function (info) {
    if (cleverapps.drawCallsDebugger && cleverapps.drawCallsDebugger.running) {
        cleverapps.drawCallsDebugger.addNode(this);
        this.numberOfDraw = cleverapps.drawCallsDebugger.getNumberOfDraw();
        this.drawStack = new Error().stack;
        this.drawInfo = info;
    }
};