/**
 * Created by slava on 01.08.17.
 */

var DirtDecoratorCell = function (x, y, options) {
    BaseCell.call(this, x, y, options);

    this.putInPoolAvailable = true;

    this.onCreateNew = function () {};

    Game.currentGame.counter.registerStage(202, DirtDecoratorCell.process);
};

DirtDecoratorCell.prototype = Object.create(BaseCell.prototype);
DirtDecoratorCell.prototype.constructor = DirtDecoratorCell;

DirtDecoratorCell.prototype.getViewClass = function () {
    return DirtDecoratorCellView;
};

DirtDecoratorCell.prototype.save = function () {
    return DirtDecoratorCell.CODES.concat(this.innerCell.save());
};

DirtDecoratorCell.prototype.boom = function () {
    var decoratorComponent = this.findComponent(DecoratorComponent);
    decoratorComponent.boomHurt();
};

DirtDecoratorCell.prototype.onExplode = function () {
    Game.currentGame.dirtDecoratorCellCanProcess = false;

    var decoratorComponent = this.findComponent(DecoratorComponent);
    decoratorComponent.onExplode();
};

DirtDecoratorCell.processTo = function (data) {
    var dirt = new DirtDecoratorCell(data.cell.x, data.cell.y, { components: [DecoratorComponent] });
    dirt.setInnerCell(data.cell);
    dirt.onCreate = true;
    Game.currentGame.field.addCell(dirt);
    dirt.onCreateNew(data.dir);
    Game.currentGame.goals.incTarget(DirtDecoratorCell.GOAL_ID, 1);
    Game.currentGame.counter.setTimeout(function () {
    }, 900);
};

DirtDecoratorCell.canProcessTo = function (i, j) {
    if (Game.currentGame.field.isCellInField(i, j)) {
        var cell = Game.currentGame.field.cells[i][j];
        var type = cell.constructor;
        var targets = [ColorBombCell, WaffleCell, FireworkCell, TruffleCell,
            ColorCookieManCell, LollipopCell, BombCell, NutCell];
        return targets.indexOf(type) !== -1 || type === BaseCell && (cell.findComponent(ColorComponent) || cell.findComponent(GoalCoefComponent));
    }
    return false;
};

DirtDecoratorCell.process = function () {
    if (!Game.currentGame || !Game.currentGame.dirtDecoratorCellCanProcess) {
        return;
    }
    
    var DI = [-1, 1, 0, 0];
    var DJ = [0, 0, -1, 1];
    var DNAME = ["down", "up", "right", "left"];
    var toProcess = [];
    for (var i = 0; i < Field.SIZE; i++) {
        for (var j = 0; j < Field.SIZE; j++) {
            if (Game.currentGame.field.cells[i][j] && Game.currentGame.field.cells[i][j].constructor === DirtDecoratorCell) {
                for (var dir = 0; dir < 4; dir++) {
                    if (DirtDecoratorCell.canProcessTo(i + DI[dir], j + DJ[dir])
                        && (Game.currentGame.field.barriers.canMove(i, j, i + DI[dir], j + DJ[dir]) === true)) {
                        toProcess.push({ cell: Game.currentGame.field.cells[i + DI[dir]][j + DJ[dir]], dir: DNAME[dir] });
                    }
                }
            }
        }
    }

    if (toProcess.length > 0) {
        Game.currentGame.dirtDecoratorCellCanProcess = false;
        DirtDecoratorCell.processTo(cleverapps.Random.choose(toProcess));
    }
};

DirtDecoratorCell.prototype.getGoalId = function () {
    return DirtDecoratorCell.GOAL_ID;
};

DirtDecoratorCell.prototype.bundleId = function () {
    return "dirt_decorator";
};

DirtDecoratorCell.GOAL_ID = "dirt_decorator";