/**
 * Created by slava on 02.02.17.
 */

var DirtDecoratorCellView = BaseCellView.extend({
    ctor: function (decoratedCell) {
        this._super();

        this.setContentSize2(cleverapps.styles.BaseCellView.CELL_SIZE_PX, cleverapps.styles.BaseCellView.CELL_SIZE_PX);
        this.setAnchorPoint(0.5, 0.5);

        var imageRes = bundles.dirt_decorator.frames.cell_dirt;
        if (decoratedCell.isForGoal && bundles.dirt_decorator.frames.cell_dirt_goal) {
            imageRes = bundles.dirt_decorator.frames.cell_dirt_goal;
        }
        
        this.image = new cc.Sprite(imageRes);
        this.image.setPosition(this.width / 2, this.height / 2);
        this.addChild(this.image);

        if (!decoratedCell.isForGoal) {
            this.initialize(decoratedCell);
        }
    },

    initialize: function (decoratedCell) {
        this._super(decoratedCell);

        if (decoratedCell.onCreate) {
            this.image.visible = false;
        }

        decoratedCell.onCreateNew = this.animateCreateNew.bind(this);
    },

    unuse: function () {
        this.cell.onCreateNew = function () {};

        this._super();
    },

    finishSmile: function () {
        if (this.animation) {
            this.animation.finishAnimation();
            this.animation = false;
            this.image.setVisible(true);
        }
    },

    animate: function (animate, callback, spriteAction) {
        this.finishSmile();
        this.animation = DirtAnimation.factory();
        this.animation.setPosition(this.width / 2, this.height / 2);
        this.addChild(this.animation);
        this.animation.runAnimate(animate, function () {
            this.animation = false;
            this.image.visible = true;
            if (callback) {
                callback();
            }
        }.bind(this), spriteAction, this.image);
    },

    animateCreateNew: function (dir) {
        cleverapps.audio.playSound(bundles.dirt_decorator.urls.dirt_decorator_grow_up_effect);
        var spriteAction = new cc.Sequence(
            new cc.CallFunc(function (obj) {
                obj.setOpacity(0);
            }),
            new cc.FadeIn(0.5)
        );
        this.animate(dir, false, spriteAction, this.image);
    },

    animateExplode: function (callback) {
        this.setLocalZOrder(0);
        this.animate("out", callback, new cc.FadeOut(0.7));
        cleverapps.audio.playSound(bundles.dirt_decorator.urls.dirt_decorator_die_effect);
    }
});