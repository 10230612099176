/**
 * Created by ivan on 02.02.18.
 */

var CrabMakerCellView = BaseCellView.extend({
    ctor: function (crabMakerCell) {
        this._super();

        this.setContentSize(cleverapps.styles.BaseCellView.CELL_SIZE_PX, cleverapps.styles.BaseCellView.CELL_SIZE_PX);
        this.setAnchorPoint(0.5, 0.5);

        var image = new cc.Sprite(this.getImage(crabMakerCell.stage));
        image.setAnchorPoint(0.5, 0.5);
        image.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(image);
        this.image = image;

        this.addAnimation();

        this.initialize(crabMakerCell);
    },

    initialize: function (crabMakerCell) {
        this._super(crabMakerCell);

        crabMakerCell.onChangeStage = this.changeStage.bind(this);
    },

    unuse: function () {
        this.cell.onChangeStage = function () {};

        this._super();
    },

    addAnimation: function () {
        var animation = new cleverapps.Spine(bundles.crab_maker.jsons.crab_maker_animation_json);
        animation.setPosition(this.width / 2, this.height / 2);
        animation.setStartVisibleListener(function () {
            this.image.visible = false;
        }.bind(this));
        this.addChild(animation);
        this.animation = animation;
    },

    getImage: function (stage) {
        return bundles.crab_maker.frames["crab_maker_stable_" + stage];
    },

    changeStage: function (callback) {
        if (this.cell.stage === 0) {
            return;
        }

        if (!this.animation) {
            this.addAnimation();
        }
        this.animation.setAnimation(0, "" + this.cell.stage, false);
        this.animation.setCompleteListener(function () {
            this.image.setSpriteFrame(this.getImage(this.cell.stage));
            this.image.visible = true;
            this.animation.visible = false;
            if (callback) {
                callback();
            }
        }.bind(this));

        if (bundles.crab_maker.urls.crab_fly_effect) {
            cleverapps.audio.playSound(bundles.crab_maker.urls.crab_fly_effect);
        }
    },

    hurtAnimation: function () {

    }
});