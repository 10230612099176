/**
 * Created by iamso on 04.06.19.
 */

var TransitionContent = cc.Node.extend({
    ctor: function (type) {
        this._super();

        var styles = cleverapps.styles.TransitionContent;

        this.type = type;

        this.setAnchorPoint(0.5, 0.5);

        var json = type === Transition.TYPE_DEFAULT
            ? bundles.transition.jsons.transition_person_json
            : bundles.transition.jsons.transition_victory_person_json;

        var person = this.person = new cleverapps.Spine(json);
        person.setScale(styles.person[type].scale && styles.person[type].scale[cleverapps.resolution.mode] || 1);
        person.setPositionRound(styles.person[type]);
        this.addChild(person);

        this.updateSize();
        if (type === Transition.TYPE_VICTORY) {
            var message = this.message = cleverapps.UI.generateTTFText("message.YouWin", cleverapps.styles.FONTS.WIN_TEXT);
            message.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
            message.setPositionRound(styles.message.position);
            message.fitTo(cleverapps.resolution.getBgSize().width);
            this.addChild(message);
        }
    },

    updateSize: function () {
        if (this.type === Transition.TYPE_VICTORY) {
            var safePadding = cleverapps.resolution.getSafePadding();

            this.setContentSize(cleverapps.resolution.getSceneSize());
            this.setPositionRound({ align: "center", dx: -safePadding.left }, { align: "center", dy: -safePadding.bottom });
        } else {
            this.setContentSize(cleverapps.resolution.getBgSize());
            this.setPositionRound({ align: "center" }, { align: "center" });
        }
    },

    show: function (silent) {
        this.person.setAnimation(0, "close", false);

        if (silent) {
            this.person.clearTrack(0);
            return;
        }

        if (this.type === Transition.TYPE_VICTORY) {
            this.message.setOpacity(0);
            this.message.runAction(new cc.Sequence(
                new cc.DelayTime(0.2),
                new cc.FadeIn(0.2),
                new cc.DelayTime(0.5),
                new cc.PlaySound(bundles.transition.urls.transition_victory_sound),
                new cc.DelayTime(0.6),
                new cc.FadeOut(0.2),
                new cc.Hide()
            ));
        }
    },

    hide: function () {
        if (this.person.hasAnimation("open")) {
            this.person.setAnimation(0, "open", false);
        }

        if (this.message) {
            this.message.stopAllActions();
            this.message.setVisible(false);
        }
    }
});

cleverapps.styles.TransitionContent = {
    person: {

    },

    message: {
        position: [{
            x: { align: "center" },
            y: { align: "center", dy: 200 }
        }, {
            x: { align: "center" },
            y: { align: "center", dy: 350 }
        }, {
            x: { align: "center" },
            y: { align: "center", dy: 350 }
        }]
    }
};

cleverapps.styles.TransitionContent.person[Transition.TYPE_DEFAULT] = {
    x: { align: "center" },
    y: { align: "center" }
};

cleverapps.styles.TransitionContent[Transition.TYPE_VICTORY] = {
    x: { align: "center" },
    y: { align: "bottom", dy: 160 },

    scale: [
        0.89,
        1.3,
        1.3
    ]
};