/**
 * Created by evgenijsenkevic on 27.08.2024
 * */

var AdminLocationsView = cc.Node.extend({
    ctor: function (model) {
        this._super();
        this.model = model;

        var size = cleverapps.resolution.getSceneSize();
        var styles = cleverapps.styles.AdminLocationsView;
        this.setContentSize(styles.width, size.height - styles.paddingY);

        this.createBg();
        this.createContent();
        this.setPositionRound(styles.position);
    },

    createBg: function () {
        var bg = cleverapps.UI.createScale9Sprite(bundles.admin.frames.common_bg, cleverapps.UI.Scale9Rect.TwoPixelXY);
        bg.setAnchorPoint(0.5, 0.5);
        bg.setContentSize2(this.getContentSize());
        bg.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(bg);
    },

    createContent: function () {
        var label = cleverapps.UI.generateOnlyText("Locations", cleverapps.styles.FONTS.ADMIN_LEVEL_TEXT);

        var contentViews = [label];
        this.model.adminLocations.forEach(function (location) {
            contentViews.push(new AdminLocationView(location));
        });

        var styles = cleverapps.styles.AdminLocationsView;

        var layout = new cleverapps.Layout(contentViews, {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });

        layout.setPositionRound(styles.content);
        this.addChild(layout);
    }
});

cleverapps.styles.AdminLocationsView = {
    width: 220,
    paddingY: 100,

    margin: 10,

    position: {
        x: { align: "left" },
        y: { align: "center" }
    },

    content: {
        x: { align: "center" },
        y: { align: "top", dy: -40 }
    }
};