/**
 * Created by Aleksandr on 28.12.2022
 */

var Tile3Orangery = {
    init: function () {
        var random = [{
            itemIcon: new cc.Sprite(bundles.card.frames.skin_random),
            minimizedIcon: new cc.Sprite(bundles.card.frames.skin_random),
            preSelected: true
        }];

        var tileRows = [];
        var tilesPerRow = 8;

        for (var i = 0; bundles.card.frames["skin_" + i]; ++i) {
            if (i % tilesPerRow === 0) {
                tileRows.push([]);
            }
            tileRows[Math.floor(i / tilesPerRow)].push({
                value: i,
                itemIcon: new cc.Sprite(bundles.card.frames["skin_" + i]),
                minimizedIcon: new cc.Sprite(bundles.card.frames["skin_" + i])
            });
        }

        var rows = [random].concat(tileRows);

        this.tabs = [{
            node: this.convertCardToIcon(new Card({
                value: "0"
            })),
            rows: rows
        }, {
            node: this.convertCardToIcon(new Card({
                components: [{
                    name: "plate"
                }]
            })),
            rows: this.listCardsComponents()
        }, {
            node: this.convertCardToIcon(new CurtainCard({
                sleep: 5,
                active: 2,
                sleeping: true 
            })),
            rows: this.listCardTypes()
        }
        ];

        this.maximized = true;
    },

    getItemCode: function (item) {
        return "skin_" + item.value;
    },

    listCardsComponents: function () {
        return [[{
            name: "plate",
            constructor: PlateOnCard
        }, {
            name: "ice",
            constructor: IceOnCard
        }, {
            name: "bomb",
            constructor: BombOnCard
        }]].map(function (rows) {
            return rows.map(function (component) {
                var card = new Card({
                    components: [{
                        name: component.name
                    }]
                });

                return {
                    itemIcon: this.convertCardToIcon(card),
                    minimizedIcon: this.convertCardToIcon(card),
                    cardOptions: {
                        components: [{
                            name: component.name
                        }],
                        componentCtor: component.constructor
                    },
                    component: true
                };
            }.bind(this));
        }.bind(this));
    },

    listCardTypes: function () {
        return [[{
            CardCtor: CurtainCard,
            cardOptions: {
                sleep: 5,
                active: 2,
                sleeping: true
            }
        }]].map(function (rows) {
            return rows.map(function (item) {
                var card = new (item.CardCtor)(item.cardOptions);
                return {
                    CardCtor: item.CardCtor,
                    itemIcon: this.convertCardToIcon(card),
                    minimizedIcon: this.convertCardToIcon(card),
                    cardOptions: item.cardOptions,
                    cardType: true
                };
            }.bind(this));
        }.bind(this));
    },

    convertCardToIcon: function (card) {
        card.disableEditorControls = true;
        card.open && card.open();

        var view = CardView.createCardView(card);
        view.setScale(0.4);
        return view;
    }
};
