/**
 * Created by slava on 14.11.18.
 */

var FireflyJarCellView = BaseCellView.extend({
    ctor: function (fireflyCell) {
        this._super();

        this.setContentSize(cleverapps.styles.BaseCellView.CELL_SIZE_PX, cleverapps.styles.BaseCellView.CELL_SIZE_PX);
        this.setAnchorPoint(0.5, 0.5);

        if (fireflyCell.isForGoal) {
            this.initSetGoalSprite();
            return;
        }

        this.initialize(fireflyCell);
    },

    initialize: function (fireflyCell) {
        this._super(fireflyCell);

        var animation = this.animation = new cleverapps.Spine(bundles.firefly_jar.jsons.firefly_jar_json);
        this.updateAnimation();
        this.addChild(animation);
        animation.setPositionRound(this.width / 2, this.height / 2);

        fireflyCell.onFlyToGoalListener = this.createListener(this.flyToGoal.bind(this));
    },

    initSetGoalSprite: function () {
        var jar = this.jar = new cc.Sprite(bundles.firefly_jar.frames.firefly_goal_png);
        jar.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(jar);
    },

    updateAnimation: function () {
        if (this.cell.lives < 1 || !this.cell.alive) {
            return;
        }
        this.animation.setAnimation(0, this.cell.lives > 4 ? "idle_5" : "idle_" + this.cell.lives, true);
        this.animation.setCompleteListener(function () {
        });
    },

    changeLives: function () {
        this.updateAnimation();
    },

    hurtAnimation: function () {
        var animation = "open_" + (this.cell.lives + 1);
        if (this.cell.lives + 1 === 5) {
            animation = "open_5";
        }
        if (this.cell.lives + 1 > 5) {
            animation = "open_5_1";
        }
        this.setLocalZOrder(9);
        this.animation.setAnimation(0, animation, false);
        this.animation.setCompleteListener(function () {
            this.updateAnimation();
            this.setLocalZOrder(this.baseZOrder);
        }.bind(this));
    },

    flyToGoal: function () {
        var goalAnimation = new cleverapps.Spine(bundles.firefly_jar.jsons.firefly_goal_json);
        goalAnimation.setPositionRound(BaseCellView.alignInTheGrid(this.cell.x, this.cell.y));
        this.parent.addChild(goalAnimation);

        goalAnimation.runAction(new cc.Sequence(
            new cc.DelayTime(FireflyJarCellView.FLY_TO_GOAL_DURATION),
            new cc.CallFunc(function () {
                goalAnimation.setAnimation(0, "animation", true);
                cleverapps.audio.playSound(bundles.firefly_jar.urls.firefly_effect);

                if (Game.currentGame) {
                    goalAnimation.visible = true;
                    var goal = Game.currentGame.goals.findTargetFor(this.cell.getGoalId());
                    goal.collect(1, goalAnimation);
                }
            }.bind(this))
        ));
    },

    unuse: function () {
        this.cell.onChangeLivesListener = function () {};
        this.cell.onFlyToGoalListener = function () {};

        this._super();
    },

    startExploding: function () {
        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.3),
            new cc.ScaleTo(0.3, 0),
            new cc.RemoveSelf()
        ));
    }
});

FireflyJarCellView.FLY_TO_GOAL_DURATION = 1.0;