/**
 * Created by mac on 7/22/17.
 */

Score.prototype.updatePoints = function (collectAllStars) {
    if (!this.required) {
        this.required = this.calcRequired();
    }

    if (this.stars === undefined) {
        this.stars = 0;
    }

    var percent = collectAllStars ? 100 : Math.min(this.calcCurrentGoals() / this.calcTargetGoals() * 100, 100);
    var points = Math.ceil(percent / 100 * this.required);

    if (points > this.points) {
        this.points = points;

        this.trigger("onChange", { percent: percent });

        var newStars = this.calcStars();

        for (var i = this.stars + 1; i <= newStars; i++) {
            this.trigger("onChange", { star: i });
        }

        this.stars = newStars;
    }
};

Score.prototype.calcStars = function () {
    for (var i = 0; i < Score.COEFS.length; i++) {
        if (this.points < Score.COEFS[i] * this.required) {
            return i;
        }
    }

    return Score.COEFS.length;
};

Score.prototype.calcTargetGoals = function () {
    var game = Game.currentGame;
    var total = 0;
    for (var type in game.goals.elements) {
        total += game.goals.elements[type].target;
    }

    return total;
};

Score.prototype.calcCurrentGoals = function () {
    var game = Game.currentGame;
    var total = 0;
    for (var type in game.goals.elements) {
        total += Math.min(game.goals.elements[type].amount, game.goals.elements[type].target);
    }

    return total;
};

Score.prototype.calcRequired = function () {
    var game = Game.currentGame;
    var targetGoals = this.calcTargetGoals();

    var required = targetGoals * 10;

    var cellsByMoves = game.beginMoves * 5;
    if (cellsByMoves > targetGoals && game.beginMoves > 0) {
        required += (cellsByMoves - targetGoals) * 3;
    }

    return required;
};

Score.COEFS = [0.5, 0.75, 1.0];