/**
 * Created by Aleksandr on 30.11.2022
 */

var OrangeryItem = cc.Node.extend({
    ctor: function (orangery, item, tabId, odd) {
        this._super();

        this.orangery = orangery;
        this.item = item;
        this.tabId = tabId;
        this.odd = odd;

        this.setContentSize2(cleverapps.styles.OrangeryItem);
        this.createBackground(false, item.bg);
        this.addIcon();

        cleverapps.UI.applyHover(this, {
            onMouseOver: this.onMouseOver.bind(this),
            onMouseOut: this.onMouseOut.bind(this)
        });

        cleverapps.UI.onClick(this, this.onClick.bind(this));

        item.onSelectionChanged = this.createListener(this.select.bind(this));

        if (item.preSelected) {
            this.orangery.select(item, tabId);
        }
    },

    addIcon: function () {
        var styles = cleverapps.styles.OrangeryItem;
        var icon = this.icon = this.item.itemIcon;
        this.addChild(icon);
        icon.setPosition(this.width / 2, this.height / 2);
        cleverapps.UI.fitToBox(icon, {
            width: styles.width,
            height: styles.height
        });
    },

    createBackground: function (selected, bg) {
        if (this.bg) {
            this.bg.removeFromParent();
        }

        var background;
        if (selected) {
            background = bundles.orangery.frames.item_bg_selected;
        } else {
            background = bg || (this.odd ? bundles.orangery.frames.item_bg_dark : bundles.orangery.frames.item_bg_light);
        }

        this.bg = cleverapps.UI.createScale9Sprite(background, cleverapps.UI.Scale9Rect.TwoPixelY);
        this.bg.setContentSize(this.getContentSize());
        this.bg.setPosition(this.width / 2, this.height / 2);
        this.addChild(this.bg, -1);
    },

    select: function (selected) {
        if (this.selection) {
            this.selection.removeFromParent();
            delete this.selection;
            this.createBackground(false, this.item.bg);
        }

        if (selected) {
            var selection = this.selection = cleverapps.UI.createScale9Sprite(bundles.orangery.frames.item_selected, cleverapps.UI.Scale9Rect.TwoPixelY);
            selection.setAnchorPoint(0, 0);
            selection.setContentSize(this.getContentSize());
            selection.setColor(new cc.Color(0, 255, 0, 255));
            this.addChild(selection);
            this.createBackground(true);
        }
    },

    showHint: function () {
        this.stopAllActions();

        this.runAction(new cc.Sequence(
            new cc.DelayTime(1),
            new cc.CallFunc(function () {
                if (this.isDisplayed() && !this.hint) {
                    this.hint = new cleverapps.UI.Tooltip(this, {
                        text: this.orangery.getItemCode(this.item)
                    });
                    cleverapps.scenes.getRunningScene().movingNode.addChild(this.hint);
                    this.hint.setLocalZOrder(BaseWindow.WINDOWS_ZORDER - 3);
                }
            }.bind(this))
        ));
    },

    hideHint: function () {
        this.stopAllActions();

        if (this.hint) {
            this.hint.removeFromParent();
            this.hint = undefined;
        }
    },

    onMouseOver: function () {
        this.icon.setScale(this.icon.getScale() * 1.1);

        this.showHint();
    },

    onMouseOut: function () {
        cleverapps.UI.fitToBox(this.icon, {
            width: cleverapps.styles.OrangeryItem.width,
            height: cleverapps.styles.OrangeryItem.height
        });

        this.hideHint();
    },

    isSelected: function () {
        return this.orangery.selected === this.item;
    },

    onClick: function () {
        if (cleverapps.config.type === "merge" && cleverapps.keyboardController.isPressed(cc.KEY.x)) {
            var url = cleverapps.RestClient.standsWeb("/?unitsDemo=" + cleverapps.travelBook.currentPage.id + "&code=" + this.item.code);
            connector.platform.openUrl(url);
            return;
        }

        if (this.isSelected()) {
            this.orangery.unselect(this.item);
        } else {
            this.orangery.select(this.item, this.tabId);
        }
        this.hideHint();

        cleverapps.audio.playSound(bundles.main.urls.click_effect);
    }
});

cleverapps.styles.OrangeryItem = {
    width: 80,
    height: 80
};