/**
 * Created by slava on 03.08.17.
 */

var GrassTile = function (x, y) {
    BaseTile.call(this, x, y);
};

GrassTile.prototype = Object.create(BaseTile.prototype);
GrassTile.prototype.constructor = GrassTile;

GrassTile.CODES = ["G"];

GrassTile.prototype.getViewClass = function () {
    return GrassTileView;
};

GrassTile.prototype.hurt = function () {
};

GrassTile.prototype.save = function () {
    return GrassTile.CODES;
};

GrassTile.prototype.bundleId = function () {
    return "grass_tile";
};