/**
 * Created by Andrey Popov on 1/25/21.
 */

var SceneComponentView = cc.Node.extend({
    ctor: function (sceneComponent, eventBus, parent) {
        this._super();

        this.sceneComponent = sceneComponent;

        var properties = cleverapps.clone(sceneComponent.properties, true);

        ["width", "height", "scale", "position"].forEach(function (propName) {
            if (properties[propName]) {
                properties[propName] = Wysiwyg.getValueForResolution(properties[propName]);
            }
        });

        this.setAnchorPoint(0.5, 0.5);

        var ComponentClass = sceneComponent.ctor();
        if (!properties.isGame) {
            if (properties.width) {
                properties.width = parent.width * parseFloat(properties.width) / 100;
            }
            if (properties.height) {
                properties.height = parent.height * parseFloat(properties.height) / 100;
            }
        }

        var componentInstance = this.componentInstance = new ComponentClass(properties, eventBus, parent);
        this.setAnchorPoint(0.5, 0.5);

        if (properties.position && properties.position.x) {
            properties.position.x.dx = parent.width * parseFloat(properties.position.x.dx) / 100;
        }
        if (properties.position && properties.position.y) {
            properties.position.y.dy = parent.height * parseFloat(properties.position.y.dy) / 100;
        }
        this.setPositionRound(properties.position);

        if (!properties.isGame) {
            if (properties.height && !properties.width) {
                this.setContentSize(componentInstance.width, properties.height);
            } else if (!properties.height && properties.width) {
                this.setContentSize(properties.width, componentInstance.height);
            } else {
                this.setContentSize(componentInstance.getContentSize());
            }
        }

        if (properties.scale) {
            componentInstance.setScale(properties.scale);
        }

        if (properties.rotation) {
            this.setRotation(properties.rotation);
        }

        if (properties.zOrder !== undefined) {
            this.setLocalZOrder(properties.zOrder);
        }

        if (properties.visible === false) {
            this.setVisible(false);
        }

        componentInstance.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(componentInstance);

        componentInstance.nestedComponents = [];

        sceneComponent.onGetView = this.createListener(function () {
            return componentInstance;
        });
    },

    onComponentSizeChanged: function () {
        if (!this.sceneComponent.properties.width && !this.sceneComponent.properties.height) {
            this.setContentSize(this.componentInstance.getContentSize());
            this.componentInstance.setPositionRound(this.width / 2, this.height / 2);
        }

        var componentBox;
        if (this.sceneComponent.properties.isGame) {
            componentBox = cleverapps.scenes.getRunningScene().getBoundingBox();
        } else {
            componentBox = this.componentInstance.getBoundingBoxToWorld();
        }

        this.sceneComponent.onComponentBoxChanged(componentBox);
    }
});