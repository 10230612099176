/**
 * Created by decipaliz on 06.12.2023
 */

var FilmEffectLayer = cc.Scale9Sprite.extend({
    ctor: function (options) {
        this._super(bundles.pixel.frames.pixel_png);
        this.options = options || {};

        this.top = this.options.top;

        this.setAnchorPoint(0.5, this.top ? 1 : 0);
        this.setColor(cc.color.BLACK);

        var bgSize = cleverapps.resolution.getBgSize();
        this.setContentSize2(bgSize.width + 2, bgSize.height * 0.1 + 3);
        this.setPositionRound(
            { align: "center" },
            this.top ? { align: "top", dy: 1 + bgSize.height - cleverapps.resolution.getSceneSize().height } : { align: "bottom", dy: -1 }
        );
    },

    showAnimation: function () {
        this.setOpacity(0);
        this.runAction(new cc.FadeTo(this.options.layerTimeout + 0.01, 255));
    },

    hideAnimation: function (timeout) {
        var sceneSize = cleverapps.resolution.getBgSize();

        this.stopAllActions();

        var targetY = this.top ? this.height + sceneSize.height : -this.height;

        this.runAction(new cc.Spawn(
            new cc.MoveTo(timeout, this.x, targetY),
            new cc.ScaleTo(timeout, 1)
        ));
    }
});