/**
 * Created by mac on 5/23/20
 */

var Recorder = function (forVideo) {
    cleverapps.screenshotsZip = new JSZip();

    if (forVideo) {
        var canvasStream = this.canvasStream = cc.game.canvas.captureStream(60);
        var soundStream = this.soundStream = this.createSoundStream();

        var mixedStream = new MediaStream([
            canvasStream.getVideoTracks()[0],
            soundStream.getAudioTracks()[0]
        ]);

        this.videoRecorder = new MediaRecorder(mixedStream, {
            mimeType: Recorder.MIME_TYPE,
            audioBitsPerSecond: 128000,
            videoBitsPerSecond: 8400000
        });

        this.recordedBlobs = [];
        this.videoRecorder.ondataavailable = function (event) {
            this.recordedBlobs.push(event.data);
        }.bind(this);
    }
};

Recorder.prototype.startCapture = function (options) {
    this.options = options;

    if (this.options.screenshotParameters) {
        setTimeout(function () {
            var cnt = 0;
            var capture = function () {
                cnt++;

                if (cnt < (this.options.screenshotParameters.amount || 1)) {
                    setTimeout(capture, this.options.screenshotParameters.frequency);
                }

                var content = cc.game.canvas.toDataURL("image/jpeg", Recorder.IMAGE_QUALITY);
                var fileName = this.options.screenshotParameters.name;
                if (this.options.screenshotParameters.amount > 1) {
                    fileName += ("_" + cnt);
                }

                cleverapps.screenshotsZip.folder(this.options.folder).file(fileName + ".jpg", content.split(",")[1], { base64: true });
            }.bind(this);

            capture();
        }.bind(this), this.options.screenshotParameters.frequency);
    } else {
        this.recordedBlobs = [];
        this.videoRecorder.start(10);
    }
};

Recorder.prototype.stopCapture = function (name, f) {
    this.videoRecorder.stop();

    var blob = new Blob(this.recordedBlobs, {
        type: Recorder.MIME_TYPE
    });

    var reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = function (event) {
        if (this.data && this.data.data) {
            this.data.data.push(event.target.result);
        } else {
            this.data = { data: [event.target.result] };
        }
        this.ext = ".webm";

        cleverapps.screenshotsZip.folder(this.options.folder).file(name + ".webm", blob, { base64: true });
        f();
    }.bind(this);
};

Recorder.prototype.saveZip = function (name, callback) {
    cleverapps.screenshotsZip.generateAsync({ type: "blob" }).then(function (content) {
        var link = document.createElement("a");
        link.href = URL.createObjectURL(content);
        link.download = name;
        link.click();
        link.remove();

        delete cleverapps.screenshotsZip;

        this.canvasStream && this.canvasStream.getTracks().forEach(function (track) {
            track.stop();
        });
        this.soundStream && this.soundStream.getTracks().forEach(function (track) {
            track.stop();
        });

        callback();
    }.bind(this));
};

Recorder.prototype.createSoundStream = function () {
    var mediaStreamDestination = cc.Audio._context.createMediaStreamDestination();
    var masterGainNode = cc.Audio._context.createGain();
    masterGainNode.connect(mediaStreamDestination);

    cc.Audio.WebAudio.prototype.play = function (offset) {
        // If repeat play, you need to stop before an audio
        if (this._currentSource && !this.paused) {
            this._currentSource.stop(0);
            this.playedLength = 0;
        }
        this._volume.connect(masterGainNode);

        var audio = this.context.createBufferSource();
        audio.buffer = this.buffer;
        audio.connect(this._volume);
        audio.loop = this._loop;

        this._startTime = this.context.currentTime;
        offset = offset || this.playedLength;

        var duration = this.buffer.duration;
        if (!this._loop) {
            if (audio.start) {
                audio.start(0, offset, duration - offset);
            } else if (audio.notoGrainOn) {
                audio.noteGrainOn(0, offset, duration - offset);
            } else {
                audio.noteOn(0, offset, duration - offset);
            }
        } else if (audio.start) {
            audio.start(0);
        } else if (audio.notoGrainOn) {
            audio.noteGrainOn(0);
        } else {
            audio.noteOn(0);
        }

        this._currentSource = audio;

        // If the current audio context time stamp is 0
        // There may be a need to touch events before you can actually start playing audio
        // So here to add a timer to determine whether the real start playing audio, if not, then the incoming touchPlay queue
        if (this.context.currentTime === 0) {
            var self = this;
            clearTimeout(this._currextTimer);
            this._currextTimer = setTimeout(function () {
                if (self.context.currentTime === 0) {
                    cc.Audio.touchPlayList.push({
                        offset: offset,
                        audio: self
                    });
                }
            }, 10);
        }
    };

    return mediaStreamDestination.stream;
};

Recorder.MIME_TYPE = "video/webm";
Recorder.IMAGE_QUALITY = 0.9;