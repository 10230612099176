/**
 * Created by andrey on 10.10.17.
 */

var Mark = function (cell) {
    this.code = Mark.CODES[0];
    this.cell = cell;

    this.onExplodeListener = function () {};
    this.onSmileListener = function () {};
};

Skinify(Mark, "game_mark");

Mark.prototype.explode = function () {
    var isGoal = Game.currentGame.goals.hasType(this.getGoalId());

    var startExplodeDuration = cleverapps.styles.MarkView.animation.start.duration;

    Game.currentGame.goalCounter.setTimeout(function () {
    }, startExplodeDuration * 1000);

    this.onExplodeListener(isGoal);
};

Mark.prototype.smile = function () {
    this.onSmileListener();
};

Mark.prototype.getGoalId = function () {
    return Mark.GOAL_ID;
};

Mark.prototype.isDefaultSkin = function () {
    return this.getSkinSuffix() === "";
};

Mark.CODES = ["!"];
Mark.GOAL_ID = Mark.CODES[0];