/**
 * Created by slava on 3/5/19
 */

var StartEpisodeWindow = CleverappsWindow.extend({
    onWindowLoaded: function (episodeNo) {
        this.episodeNo = episodeNo;

        this._super({
            title: ("StartEpisodeWindow.Title" + this.episodeNo),
            name: "startepisodewindow",
            content: this.getContent(),
            closeButton: false,
            button: {
                width: cleverapps.styles.StartEpisodeWindow.button.width,
                height: cleverapps.styles.StartEpisodeWindow.button.height,
                text: "OK",
                onPressed: this.close.bind(this)
            }
        });
    },

    getPerson: function () {
        return "hero";
    },

    getContent: function () {
        var styles = cleverapps.styles.StartEpisodeWindow;

        var text = cleverapps.UI.generateTTFText("StartEpisodeWindow.Text" + this.episodeNo, cleverapps.styles.FONTS.WINDOW_TEXT);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        text.setDimensions(styles.text.width, 0);

        return text;
    }
});

cleverapps.styles.StartEpisodeWindow = {
    text: {
        width: 600
    },

    button: {
        width: 250,
        height: 100
    }
};
