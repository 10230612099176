/**
 * Created by Denis Kuzin on 08 august 2024
 */

var MarkComponentView = cc.Node.extend({
    ctor: function (component, cellView) {
        this._super();

        this.setContentSize(cellView.getContentSize());
        this.setLocalZOrder(11);

        if (component.mark) {
            var markView = MarkView.factory(component.cell);
            markView.initialize(component.cell);
            this.addChild(markView);
        }

        if (component.missionMark) {
            this.missionMarkView = MissionMarkView.factory();
            this.addChild(this.missionMarkView);
        }
    },

    removeMissionMark: function () {
        if (this.missionMarkView) {
            cc.pool.putInPool(this.missionMarkView);
            this.missionMarkView = undefined;
        }
    }
});
