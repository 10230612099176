/**
 * Created by Andrey Popov on 1/24/21.
 */

var WysiwygPreview = function (wysiwyg) {
    this.wysiwyg = wysiwyg;

    cleverapps.EventEmitter.call(this);

    this.componentsByIds = {};

    this.wysiwyg.hierarchy.on("itemPropertyChanged", this.itemPropertyChanged.bind(this));
};

WysiwygPreview.prototype = Object.create(cleverapps.EventEmitter.prototype);
WysiwygPreview.prototype.constructor = WysiwygPreview;

WysiwygPreview.prototype.registerComponent = function (component) {
    this.componentsByIds[component.id] = component;
};

WysiwygPreview.prototype.clear = function () {
    this.componentsByIds = {};
};

WysiwygPreview.prototype.showComponents = function (selectedItem, forceUpdate) {
    if (!selectedItem) {
        return;
    }

    if (selectedItem.type === HierarchyItem.TYPE.DIRECTORY) {
        this.clear();

        this.trigger("showDirectory", this.wysiwyg.hierarchy.items.filter(function (item) {
            return item.type === HierarchyItem.TYPE.CLIP;
        }));
        
        this.selectedResolution = undefined;
        this.wysiwyg.inspector.showItemProperties();
    } else {
        var selectedClip = this.wysiwyg.hierarchy.getSelectedClip();
        var props = selectedClip.properties;

        if (!this.selectedResolution || (props.resolutions && props.resolutions.indexOf(this.selectedResolution) === -1)) {
            this.selectedResolution = props.resolutions.filter(function (res) {
                return Wysiwyg.VIDEO_SIZES[res];
            })[0] || Wysiwyg.VIDEO_SIZES.portrait_1080x1920;
        }

        this.canvasSize = Wysiwyg.VIDEO_SIZES[this.selectedResolution];

        if (selectedItem.type === HierarchyItem.TYPE.SCRIPT) {
            this.clear();
            this.selectedResolution = undefined;
            this.forceUpdate = true;
            this.trigger("showScript", selectedItem.properties.scriptFileName);
        } else {
            if (forceUpdate) {
                this.forceUpdate = true;
            }

            if (!this.forceUpdate) {
                this.selectComponent(selectedItem);
            } else {
                this.clear();

                this.trigger("showComponents", this.getScenesToShow(selectedItem), this.hardReload);
            }
            this.forceUpdate = false;
            this.hardReload = false;
        }
    }
};

WysiwygPreview.prototype.itemPropertyChanged = function (component) {
    if (component.type === HierarchyItem.TYPE.COMPONENT) {
        if (this.forceUpdate && ["AdsGame", "AdsMap2d"].indexOf(component.assetName) !== -1) {
            this.showComponents(cleverapps.wysiwyg.hierarchy.selectedItem, true);
        } else {
            this.trigger("updateComponent", component);
        }
    } else {
        this.showComponents(cleverapps.wysiwyg.hierarchy.selectedItem);
    }
};

WysiwygPreview.prototype.getScenesToShow = function (selectedItem) {
    var scenesToShow = [];
    if (selectedItem.type === HierarchyItem.TYPE.CLIP) {
        scenesToShow = selectedItem.children.map(function (scene, index) {
            return {
                id: scene.id,
                index: index,
                snapshot: this.wysiwyg.hierarchy.getSceneSnapshot(scene)
            };
        }.bind(this));
    } else {
        var sceneToShow = this.wysiwyg.hierarchy.getItemScene(selectedItem);
        scenesToShow.push({
            id: sceneToShow.id,
            index: sceneToShow.parentItem.children.indexOf(sceneToShow),
            snapshot: this.wysiwyg.hierarchy.getSceneSnapshot(sceneToShow)
        });
    }

    return scenesToShow;
};

WysiwygPreview.prototype.selectComponent = function (component) {
    this.trigger("selectComponent", component);
};

WysiwygPreview.prototype.clear = function () {
    this.trigger("clearComponents");
};

WysiwygPreview.prototype.selectResolution = function (selectedResolution) {
    this.selectedResolution = selectedResolution;
    this.canvasSize = Wysiwyg.VIDEO_SIZES[this.selectedResolution];

    var selectedItem = cleverapps.wysiwyg.hierarchy.selectedItem;
    this.trigger("showComponents", this.getScenesToShow(selectedItem));
    cleverapps.wysiwyg.inspector.showItemProperties();
};