/**
 * Created by vladislav on 16.02.2022
 */

var TreasureSearchCellView = cc.Node.extend({
    ctor: function (treasureSearch, row, col, parentView) {
        this._super();

        this.treasureSearch = treasureSearch;
        this.row = row;
        this.col = col;
        this.parentView = parentView;

        var styles = cleverapps.styles.TreasureSearchCellView;

        this.setContentSize(styles);

        var item = this.item = new cleverapps.Spine(bundles.treasuresearch.jsons.clouds);
        item.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(item, 1);

        this.createCover();
    },

    onClick: function () {
        if (this.treasureSearch.running && !this.treasureSearch.paused) {
            this.treasureSearch.open(this.col, this.row);
        }
    },

    createCoverAnimation: function () {
        this.cover = new cleverapps.Spine(bundles.treasuresearch.jsons.cover_json);
        this.cover.setAnimation(0, "idle", true);
        this.cover.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(this.cover);
    },

    createCover: function () {
        if (!this.treasureSearch.isOpened(this.col, this.row)) {
            this.createCoverAnimation();

            cleverapps.UI.onClick(this.cover, this.onClick.bind(this), {
                interactiveScale: false
            });

            var options = {};
            if (this.treasureSearch.type === TreasureSearch.TYPE_FISHING) {
                options.onMouseOver = function () {
                    if (!this.bubbles && !this.treasureSearch.paused) {
                        var bubbles = this.bubbles = new cleverapps.Spine(bundles.treasuresearch.jsons.clouds);
                        bubbles.setAnimation(0, "hover", true);
                        bubbles.setPositionRound(this.width / 2, this.height / 2);
                        this.addChild(bubbles, 100);
                        bubbles.replaceParentSamePlace(this.parent);
                    }
                }.bind(this);

                options.onMouseOut = function () {
                    if (this.bubbles) {
                        this.bubbles.removeFromParent();
                        delete this.bubbles;
                    }
                }.bind(this);
            }
            this.hoverHandler = cleverapps.UI.applyHover(this.cover, options);
        } else if (this.treasureSearch.type === TreasureSearch.TYPE_FISHING) {
            this.createCoverAnimation();

            var goalType = this.treasureSearch.getGoalType(this.col, this.row);
            var isGoalOpened = this.treasureSearch.isGoalOpened(goalType);
            if (goalType && !isGoalOpened) {
                this.item.setAnimation(0, "idle_high", true);
            } else {
                this.item.setAnimation(0, "idle_lily", true);
            }
        }
    },

    animateOpen: function (delay, callback) {
        if (!this.cover) {
            return;
        }

        if (this.hoverHandler) {
            if (this.bubbles) {
                this.bubbles.removeFromParent();
                delete this.bubbles;
            }

            this.hoverHandler.remove();
            this.hoverHandler = undefined;
        }

        this.item.replaceParentSamePlace(this.parentView, { keepScale: true });

        if (this.treasureSearch.type === TreasureSearch.TYPE_FISHING) {
            var goalType = this.treasureSearch.getGoalType(this.col, this.row);

            this.runAction(new cc.Sequence(
                new cc.DelayTime(delay),
                new cc.CallFunc(function () {
                    this.item.replaceParentSamePlace(this, { keepScale: true });

                    this.item.setAnimation(0, "animation_" + (goalType ? "high" : "low"), false);
                    this.item.setCompleteListener(function () {
                        this.item.setCompleteListener();

                        this.item.setStartListener(function () {
                            if (this.item.getCurrentAnimationName(0) === "fadein_lily") {
                                cleverapps.audio.playSound(bundles.treasuresearch.urls.lily_effect);
                            }
                        }.bind(this));

                        if (goalType) {
                            this.item.setAnimation(0, "idle_high", true);
                        } else {
                            cleverapps.audio.playSound(bundles.treasuresearch.urls.float_effect);
                            this.item.setAnimation(0, "fadeout", false);
                            this.item.addAnimation(0, "fadein_lily", false);
                            this.item.addAnimation(0, "idle_lily", true);
                        }

                        callback();
                    }.bind(this));
                }.bind(this))
            ));
            return;
        }

        this.item.setAnimation(0, "animation", false);
        this.item.setCompleteListenerRemove();
        cleverapps.audio.playSound(bundles.treasuresearch.urls.open_effect);

        this.cover.setAnimation(0, "animation", false);
        this.cover.setCompleteListenerRemove(callback);
        this.cover = undefined;
    },

    animateFinish: function (callback) {
        if (this.treasureSearch.type !== TreasureSearch.TYPE_FISHING) {
            callback();
            return;
        }

        cleverapps.audio.playSound(bundles.treasuresearch.urls.float_effect);
        this.item.setAnimation(0, "fadeout", false);
        this.item.setCompleteListenerOnce(callback);

        this.runAction(new cc.Sequence(
            new cc.DelayTime(1.5),
            new cc.CallFunc(function () {
                cleverapps.audio.playSound(bundles.treasuresearch.urls.lily_effect);
                this.item.setAnimationAndIdleAfter("fadein_lily", "idle_lily");
            }.bind(this))
        ));
    }
});

cleverapps.styles.TreasureSearchCellView = {
    width: 140,
    height: 140
};
