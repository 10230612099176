/**
 * Created by Ivan on 20.07.2023
 */

var VideoPropertyEditor = function (property, initialValue, onChangeValueCallback) {
    this.property = property;
    this.onChangeValueCallback = onChangeValueCallback;
    this.value = cleverapps.override({
        bundle: "",
        video: ""
    }, initialValue);

    var urlsWithBundles = {};
    urlsWithBundles[Wysiwyg.NOT_SELECTED] = true;

    this.property.bundles.forEach(function (bundleString) {
        Object.keys(bundles[bundleString].urls).forEach(function (url) {
            urlsWithBundles[url] = bundleString;
        });
    });

    this.propertyEditors = [{
        name: property.name,
        items: Object.keys(urlsWithBundles),
        bundles: this.property.bundles,
        getter: function () {
            return this.value.video;
        }.bind(this),
        setter: function (value) {
            if (value === Wysiwyg.NOT_SELECTED) {
                this.value = undefined;
            } else {
                this.value = {
                    video: value,
                    bundle: urlsWithBundles[value]
                };
            }
        }.bind(this)
    }].map(function (propertyDescription) {
        return new PropertyEditor(propertyDescription, this);
    }.bind(this));
};

VideoPropertyEditor.prototype.editBoxEditingDidEnd = function (sender) {
    sender.customSetter(sender.getString());
    this.onChangeValueCallback(this.property.name, this.value);
};