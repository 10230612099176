/**
 * Created by andrey on 16.07.18.
 */

var BagDecoratorCellView = BaseCellView.extend({
    ctor: function (decoratedCell) {
        this._super();

        this.setContentSize2(cleverapps.styles.BaseCellView.CELL_SIZE_PX, cleverapps.styles.BaseCellView.CELL_SIZE_PX);
        this.setAnchorPoint(0.5, 0.5);

        this.image = new cc.Sprite(this.getImage(decoratedCell));
        this.setContentSize(this.image.getContentSize());
        this.image.setPosition(this.width / 2, this.height / 2);
        this.addChild(this.image);

        this.initialize(decoratedCell);
    },
    
    initialize: function (decoratedCell) {
        this._super(decoratedCell);

        this.image.setVisible(true);
    },
    
    getImage: function (bagCell) {
        var bundleId = bagCell.bundleId();
        if (bagCell.x === undefined) {
            return bundles[bundleId].frames[bundleId + "_goal"];
        }
        return bundles[bundleId].frames[bundleId + "_" + bagCell.color];
    },

    createAnimation: function () {
        var bundleId = this.cell.bundleId();
        var animation = BaseAnimation.factoryLimited(bundles[bundleId].jsons[bundleId + "_spine_json"]);
        animation.setPosition(this.getPosition());
        animation.setVisible(false);
        animation.setSkin(ColorComponent.CODE_COLORS[this.cell.color]);
        if (!animation.parent) {
            this.parent.addChild(animation);
        }
        return animation;
    },

    finishSmile: function () {

    },

    smile: function () {

    },

    animateExplode: function (callback) {
        var animation = this.createAnimation();
        cleverapps.audio.playSound(bundles.game.urls.bag_decorator_die_effect);

        animation.runAnimate("animation", false, new cc.FadeOut(0.5));
        animation.runAction(new cc.CallFunc(function () {
            animation.setVisible(true);
            this.image.setVisible(false);
            callback();
        }.bind(this)));
    }
});