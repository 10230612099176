/**
 * Created by vladislav on 03.10.18.
 */

var BoosterAdviceView = cc.Node.extend({
    ctor: function (boosterAdvice, fieldView) {
        this._super();

        this.fieldView = fieldView;

        this.setLocalZOrder(10);

        boosterAdvice.onShowAdvice = this.createListener(this.showAdvice.bind(this));
        boosterAdvice.onStopAdvice = this.createListener(this.removeFinger.bind(this));
    },

    showAdvice: function (data) {
        if (Game.levelTutorialView && Game.levelTutorialView.tutorial && !Game.levelTutorialView.tutorial.finished) {
            return;
        }

        var clicks = [
            cleverapps.boosters.getBoosterById(data.boosterId).onGetView(),
            data.target
        ].filter(Boolean);

        this.finger = FingerView.hintClick(clicks);
    },

    removeFinger: function () {
        FingerView.remove(this.finger);
        this.finger = undefined;
    }
});
