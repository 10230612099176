/**
 * Created by olga on 19.08.2022
 */

var ToolSubscriptionWindow = CleverappsWindow.extend({
    onWindowLoaded: function (subscription) {
        this._super({
            content: this.getContent(subscription)
        });
    },
    getContent: function (subscription) {
        var text = "";

        var parseObj = function (data, curDepth) {
            for (var key in data) {
                var padding = Array(curDepth).join("—");

                if (typeof data[key] === "object") {
                    text += padding + key + ":\n";
                    parseObj(data[key], curDepth + 1);
                } else {
                    text += padding + key + ": " + data[key] + "\n";
                }
            }
        };

        if (subscription.data) {
            parseObj(subscription.data, 1);
        }

        if (subscription.network) {
            text += "\n※Network data:\n";
            parseObj(subscription.network, 1);
        }

        return cleverapps.UI.generateOnlyText(text, cleverapps.styles.FONTS.WINDOW_TEXT);
    }
});