/**
 * Created by Vladislav on 25.09.2024.
 */

Placements.ENTRIES["bonusWorldFinished"] = {
    type: Placements.FREE_FOCUS,

    filter: function () {
        return cleverapps.environment.isBonusWorldScene() && cleverapps.scenes.getRunningScene().bonusWorld.isFinished();
    },

    action: function () {
        var bonusWorld = cleverapps.scenes.getRunningScene().bonusWorld;

        cleverapps.meta.display({
            focus: "bonusWorldFinished",
            action: function (f) {
                bonusWorld.finish(f);
            }
        });
    }
};

Placements.ENTRIES["bonusWorldReward"] = {
    type: Placements.FREE_FOCUS,
    priority: 2,

    filter: function () {
        return cleverapps.environment.isBonusWorldScene() && cleverapps.scenes.getRunningScene().bonusWorld.getAvailableReward();
    },

    action: function () {
        var bonusWorld = cleverapps.scenes.getRunningScene().bonusWorld;

        cleverapps.meta.display({
            focus: "bonusWorldReward",
            actions: [
                function (f) {
                    if (bonusWorld.levelPassed) {
                        bonusWorld.animateChangeProgress();

                        setTimeout(f, 2000);
                    } else {
                        f();
                    }
                },
                function (f) {
                    bonusWorld.giveReward(f);
                }
            ]
        });
    }
};

Placements.ENTRIES["bonusWorldProgress"] = {
    type: Placements.FREE_FOCUS,
    priority: 1,

    filter: function () {
        return cleverapps.environment.isBonusWorldScene() && cleverapps.scenes.getRunningScene().bonusWorld.levelPassed;
    },

    action: function () {
        var bonusWorld = cleverapps.scenes.getRunningScene().bonusWorld;

        bonusWorld.animateChangeProgress();
    }
};