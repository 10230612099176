/**
 * Created by olga on 10.06.2024
 */

var Goals = function (level, savedGoals) {
    cleverapps.EventEmitter.call(this);
    this.elements = {};

    this.isDone = false;
    this.required = level.goals.length;

    level.goals.forEach(function (element) {
        var type = element.type;
        var GoalClass = Goal;
        if (cleverapps.config.type === "match3") {
            GoalClass = Match3Goal;
        }
        var goal = new GoalClass(element, savedGoals && savedGoals[type]);
        this.elements[type] = goal;

        goal.on("complete", this.onGoalComplete.bind(this), this);
        goal.on("incomplete", this.onGoalInComplete.bind(this), this);
        goal.checkCompleted();
    }, this);
};

Goals.prototype = Object.create(cleverapps.EventEmitter.prototype);
Goals.prototype.constructor = Goals;

Goals.prototype.getInfo = function () {
    var info = {};

    for (var key in this.elements) {
        info[key] = this.elements[key].getInfo();
    }

    return info;
};

Goals.prototype.onGoalComplete = function () {
    this.required--;
    if (this.required === 0) {
        this.isDone = true;
    }
};

Goals.prototype.onGoalInComplete = function () {
    this.required++;
    if (this.required > 0) {
        this.isDone = false;
    }
};

Goals.prototype.findTargetFor = function (type) {
    return this.elements[type];
};

Goals.prototype.hasType = function (type) {
    return this.elements[type] !== undefined;
};

Goals.prototype.incGoal = function (type, amount) {
    var goal = this.findTargetFor(type);
    if (goal) {
        goal.inc(amount);
    }
};

Goals.prototype.incTarget = function (type, target) {
    var goal = this.findTargetFor(type);
    if (goal) {
        goal.incTarget(target);
    }
};

Goals.prototype.whenCollectsFinished = function (callback) {
    var n = 0;
    for (var key in this.elements) {
        if (this.elements[key].onCollectFinished) {
            n++;
        }
    }

    if (n === 0) {
        callback();
        return;
    }

    var waiter = cleverapps.wait(n, callback);
    for (key in this.elements) {
        if (this.elements[key].onCollectFinished) {
            this.elements[key].onCollectFinished = waiter;
        }
    }
};