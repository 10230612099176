/**
 * Created by Andrey Popov on 1/22/21.
 */

var HierarchyItemView = cc.Node.extend({
    ctor: function (hierarchyItem) {
        this._super();

        this.hierarchyItem = hierarchyItem;

        var styles = cleverapps.styles.HierarchyItemView;
        var width = styles.width - styles.levelIndent * this.hierarchyItem.getLevel();

        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize2(width, styles.height);

        var icon = this.icon = new cc.Sprite();
        icon.setAnchorPoint(0.5, 0.5);
        icon.setPositionRound(styles.icon);
        this.addChild(icon);
        this.updateIcon();

        var hasTypeIcon = hierarchyItem.assetName && bundles.wysiwyg.frames["item_" + hierarchyItem.assetName.toLowerCase()];
        var iconName = undefined;
        if (hierarchyItem.type === HierarchyItem.TYPE.SCRIPT) {
            hasTypeIcon = true;
            iconName = "adsscript";
        }

        if (hierarchyItem.type === HierarchyItem.TYPE.CLIP) {
            hasTypeIcon = true;
            iconName = hierarchyItem.properties.frameCapture && !hierarchyItem.properties.frameCapture.video ? "image" : "video";
        }

        if (hasTypeIcon) {
            hasTypeIcon = true;
            var typeIcon = new cc.Sprite(bundles.wysiwyg.frames["item_" + (iconName || hierarchyItem.assetName.toLowerCase())]);
            typeIcon.setAnchorPoint(0.5, 0.5);
            typeIcon.setPositionRound(styles.typeIcon);
            cleverapps.UI.fitToBox(typeIcon, styles.typeIcon);
            this.addChild(typeIcon);
        }

        var text = this.text = cleverapps.UI.generateOnlyText(this.hierarchyItem.getCaption(), cleverapps.styles.FONTS.HIERARCHY_ITEM_TEXT);
        text.fitTo(width - styles.icon.width, styles.height);

        this.underline = cleverapps.UI.drawUnderline(text, hasTypeIcon ? styles.lineWithType : styles.line);
        this.underline.setVisible(false);
        this.addChild(this.underline);
        cleverapps.UI.applyHover(this, {
            onMouseOver: this.onMouseOver.bind(this),
            onMouseOut: this.onMouseOut.bind(this)
        });

        text.setDimensions(width - styles.icon.width, 0);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_LEFT);

        text.setAnchorPoint(0.5, 0.5);
        text.setPositionRound(hasTypeIcon ? styles.textWithType : styles.text);
        this.addChild(text);

        this.setSelected(hierarchyItem.isSelected());

        this.clickHandler = cleverapps.UI.onDrag(this, {
            onClick: function () {
                if (!hierarchyItem.isSelected() && !cleverapps.wysiwyg.hierarchy.selectionBlocked) {
                    hierarchyItem.onClick();
                }
            },

            onDragStart: function () {
                return hierarchyItem.type === HierarchyItem.TYPE.COMPONENT;
            },
            onDragMove: function (touch) {
                var dragTarget = this.findDragTarget(touch);
                if (dragTarget) {
                    this.changeCursor("grab");
                    dragTarget.onMouseOver();
                } else {
                    this.changeCursor("not-allowed");
                }
            }.bind(this),
            onDragEnd: function (touch) {
                this.changeCursor();
                var dragTarget = this.findDragTarget(touch);
                if (dragTarget) {
                    var children = hierarchyItem.parentItem.children;
                    var targetIndex = -1;
                    if (dragTarget !== hierarchyItem.parentItem) {
                        targetIndex = children.indexOf(dragTarget.hierarchyItem);
                    }
                    var currentIndex = children.indexOf(hierarchyItem);
                    cleverapps.wysiwyg.hierarchy.reorder(hierarchyItem, targetIndex < currentIndex ? targetIndex - currentIndex + 1 : targetIndex - currentIndex);
                }
            }.bind(this)
        });

        cleverapps.UI.onClick(this.icon, this.hierarchyItem.onIconClick.bind(this.hierarchyItem), {
            filter: function () {
                return !cleverapps.wysiwyg.hierarchy.selectionBlocked;
            }
        });
    },

    changeCursor: function (name) {
        if (!connector.info.isNative && "mouse" in cc.sys.capabilities) {
            cc._canvas.style.cursor = name || "default";
        }
    },

    setSelected: function (selected) {
        this.text.setFontFillColor(selected ? cleverapps.styles.COLORS.BLACK : cleverapps.styles.COLORS.COLOR_VERY_DARK_GRAY);
        this.text._setUpdateTextureDirty();
    },

    onMouseOver: function () {
        if (this.underline && this.underline.visible === false && !cleverapps.wysiwyg.hierarchy.selectionBlocked) {
            this.underline.setVisible(true);
            this.underline.setScale(0);
            this.underline.runAction(new cc.ScaleTo(0.3, 1));
        }
    },

    findDragTarget: function (touch) {
        var targetItem = undefined;
        cleverapps.scenes.getRunningScene().hierarchyView.hierarchyItemViews.forEach(function (hierarchyItemView) {
            var itemPoint = hierarchyItemView.convertTouchToNodeSpace(touch);
            if (cc.rectContainsPoint(cc.rect(0, 0, hierarchyItemView.width, hierarchyItemView.height), itemPoint)
                    && [HierarchyItem.TYPE.COMPONENT, HierarchyItem.TYPE.SCENE].indexOf(hierarchyItemView.hierarchyItem.type) !== -1 && hierarchyItemView !== this) {
                targetItem = hierarchyItemView;
            }
            if (targetItem !== hierarchyItemView) {
                hierarchyItemView.onMouseOut();
            }
        });

        return targetItem;
    },

    onMouseOut: function () {
        if (this.underline && this.underline.visible === true) {
            this.underline.runAction(new cc.Sequence(
                new cc.ScaleTo(0.3, 0),
                new cc.CallFunc(function () {
                    this.underline.setVisible(false);
                }.bind(this))
            ));
        }
    },

    updateIcon: function () {
        var image;
        var rotation = 0;
        switch (this.hierarchyItem.type) {
            case HierarchyItem.TYPE.CLIP:
                image = bundles.wysiwyg.frames.scenario_item;
                rotation = this.hierarchyItem.properties.visible === true ? 90 : 0;
                break;
            case HierarchyItem.TYPE.DIRECTORY:
                image = bundles.wysiwyg.frames.directory_item;
                break;
            case HierarchyItem.TYPE.SCRIPT:
                image = bundles.wysiwyg.frames.item_adsscript;
                this.icon.setVisible(false);
                break;
            case HierarchyItem.TYPE.SCENE:
                image = bundles.wysiwyg.frames.clip_item;
                break;
            case HierarchyItem.TYPE.COMPONENT:
                image = this.hierarchyItem.properties.visible === false
                    ? bundles.editor_controls.frames.component_item_hidden
                    : bundles.editor_controls.frames.component_item;
                if (this.hierarchyItem.assetName === "AdsGame") {
                    image = bundles.wysiwyg.frames.clip_item;
                }
                break;
        }

        this.icon.setSpriteFrame(image);
        this.icon.setRotation(rotation);
        cleverapps.UI.fitToBox(this.icon, cleverapps.styles.HierarchyItemView.icon);
    }
});

cleverapps.styles.HierarchyItemView = {
    width: 380,
    height: 32,

    icon: {
        x: { align: "left", dx: 4 },
        y: { align: "center", dy: 3 },
        width: 28,
        height: 28
    },

    typeIcon: {
        x: { align: "left", dx: 14 },
        y: { align: "center", dy: 2 },
        width: 32,
        height: 32
    },

    text: {
        x: { align: "right", dx: -8 },
        y: { align: "center", dy: 1 }
    },

    textWithType: {
        x: { align: "right", dx: 20 },
        y: { align: "center", dy: 1 }
    },

    line: {
        x: { align: "left", dx: 18 },
        y: { align: "bottom" },
        height: 1
    },

    lineWithType: {
        x: { align: "left", dx: 50 },
        y: { align: "bottom", dy: -1 },
        height: 1
    },

    levelIndent: 40
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    HIERARCHY_ITEM_TEXT: {
        size: 20,
        color: cleverapps.styles.COLORS.COLOR_VERY_DARK_GRAY,
        font: bundles.wysiwyg.urls.strict_font_ttf
    },

    SELECTED_HIERARCHY_ITEM_TEXT: {
        size: 20,
        color: cleverapps.styles.COLORS.BLACK,
        font: bundles.wysiwyg.urls.strict_font_ttf
    }
});
