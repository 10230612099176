/**
 * Created by mac on 7/24/17.
 */

var CloversView = cc.Node.extend({
    avoidView: "CloversView",

    ctor: function () {
        this._super();

        this.mission = Game.currentGame.secondaryMission;
        this.setLocalZOrder(10);

        this.withinInfoPanel = cleverapps.config.type === "match3" && ["riddles", "heroes"].indexOf(cleverapps.config.name) !== -1;

        this.setAnchorPoint(0.5, 0.5);

        this.background = this.createBackground();

        this.updateSize();

        this.setPositionRound(cleverapps.styles.CloversView.position);

        this.icon = this.createIcon();
        this.text = this.createText();

        Game.currentGame.on("rewardClover", this.createListener(this.runCollectAnimation.bind(this)));
        Game.currentGame.on("updateCloversTarget", this.createListener(function () {
            this.text.setString(this.getTextString());
        }.bind(this)));

        cleverapps.tooltipManager.create(this, {
            text: "CloversTooltip",
            location: cleverapps.config.name === "runes" ? cleverapps.UI.Tooltip.LOCATION_ABOVE : cleverapps.UI.Tooltip.LOCATION_BELOW,
            control: "cloversViewTooltip"
        });

        cleverapps.aims.registerTarget("mission_reward" + this.mission.type, this.icon, {
            flyingUnderShadow: true,
            controls: ["rewardElementClover"]
        });

        var styles = cleverapps.styles.CloversView;
        this.icon.setPositionRound(styles.icon);
        this.text.setPositionRound(styles.text);
    },

    completeAnimationOnResize: function () {
        this.stopAllActions();
    },

    updateSize: function () {
        var styles = cleverapps.styles.CloversView;
        this.setContentSize2(styles);
        this.background.setContentSize2(styles);
    },

    createBackground: function () {
        var background = cleverapps.UI.createScale9Sprite(bundles.reward_element.frames.reward_element_bg_png, cleverapps.UI.Scale9Rect.TwoPixelXY);
        background.setAnchorPoint(0, 0);
        this.addChild(background);
        return background;
    },

    createIcon: function () {
        var styles = cleverapps.styles.CloversView;

        var icon = new cc.Sprite(this.mission.getIcon());
        this.addChild(icon);
        cleverapps.UI.fitToBox(icon, styles.icon);
        icon.baseScale = icon.scale;

        return icon;
    },

    createText: function () {
        var styles = cleverapps.styles.CloversView;

        var text = this.text = cleverapps.UI.generateImageText(
            this.getTextString(),
            cleverapps.styles.FONTS.CLOVERS_TEXT
        );
        this.addChild(text);
        text.setPositionRound(styles.text);

        return text;
    },

    getTextString: function () {
        var amount = Game.currentGame.rewards[GameBase.REWARD_SECONDARY];

        if (["differences"].includes(cleverapps.config.type) && Game.currentGame.clovers.length > 0) {
            return amount + "/" + Game.currentGame.clovers.length;
        }

        if (["tile3"].includes(cleverapps.config.type) && Game.currentGame.clovers > 0) {
            return amount + "/" + Game.currentGame.clovers;
        }

        return amount;
    },

    getSparksColor: function () {
        if (cleverapps.config.type === "blocks") {
            return "pink";
        }
        return "yellow";
    },

    runCollectAnimation: function () {
        this.stopAllActions();

        if (this.mission.type === Mission.TYPE_RAINBOW && cleverapps.config.name !== "heroes") {
            this.rotateAnimation();
            return;
        }

        var styles = cleverapps.styles.CloversView;

        this.icon.runAction(new cc.Sequence(
            new cc.CallFunc(function () {
                if (!["board", "blocks"].includes(cleverapps.config.type)) {
                    return;
                }

                var sparks = new cleverapps.Spine(bundles.menubar.jsons.sparks_json);
                sparks.setSkin(this.getSparksColor());
                sparks.setAnimation(0, "single", false);
                sparks.setCompleteListenerRemove();

                this.addChild(sparks, 0);
                sparks.setPositionRound(this.icon.getPositionX() + styles.sparks.x, this.icon.getPositionY() + styles.sparks.y);
            }.bind(this)),
            new cc.PlaySound(bundles.game.urls.tournament_element_collected),
            new cc.ScaleTo(0.15, this.icon.baseScale * 1.1),
            new cc.CallFunc(function () {
                this.text.setString(this.getTextString());
            }.bind(this)),
            new cc.ScaleTo(0.15, this.icon.baseScale)
        ));
    },

    rotateAnimation: function () {
        this.text.setString(this.getTextString());
        if (this.icon.getRotation() === 0) {
            this.icon.setScale(this.icon.baseScale);
            this.icon.runAction(new cc.Sequence(
                new cc.Spawn(
                    new cc.Sequence(
                        new cc.ScaleTo(0.2, 1.2 * this.icon.baseScale),
                        new cc.ScaleTo(0.2, this.icon.baseScale)
                    ),
                    new cc.RotateBy(0.4, 360)
                ),
                new cc.CallFunc(function () {
                    this.icon.setRotation(0);
                }, this)
            ));
        }
        this.text.stopAllActions();
        this.text.runAction(new cc.Sequence(
            new cc.PlaySound(bundles.main.urls.tournament_element_collected),
            new cc.ScaleTo(0.2, 1.5),
            new cc.ScaleTo(0.2, 1)
        ));
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    CLOVERS_TEXT: {
        name: "default",
        size: 40
    }
});

cleverapps.styles.CloversView = {
    width: 150,
    height: 61,

    position: [
        {
            x: { align: "left", dx: 40 },
            y: { align: "top", dy: -150 }
        },
        {
            x: { align: "left", dx: 236 },
            y: { align: "top", dy: -150 }
        },
        {
            x: { align: "left", dx: 250 },
            y: { align: "top", dy: -150 }
        }
    ],

    icon: {
        x: { align: "center" },
        y: { align: "center" },

        width: 100,
        height: 100
    },

    text: {
        x: { align: "center", dx: 20 },
        y: { align: "center" }
    },

    sparks: {
        x: 0,
        y: 0
    }
};
