/**
 * Created by Andrey Popov on 6/10/21.
 */

var ScriptComponentView = AssetView.extend({
    ctor: function (scriptComponent) {
        this._super(scriptComponent);

        var styles = cleverapps.styles.ScriptComponentView;

        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize(styles);

        var caption = cleverapps.UI.generateOnlyText(this.asset.name, cleverapps.styles.FONTS.STRICT_BLACK_TEXT);
        caption.setPositionRound(styles.text);
        caption.setDimensions(styles.text.width, 0);
        this.addChild(caption);

        var editButton = new cleverapps.UI.Button({
            width: styles.edit.width,
            height: styles.edit.height,
            content: new cc.Sprite(bundles.wysiwyg.frames.edit),
            onClicked: this.asset.editCallback.bind(this)
        });
        editButton.setPositionRound(styles.edit);
        this.addChild(editButton);

        var bg = cleverapps.UI.createScale9Sprite(bundles.wysiwyg.frames.button_on);
        bg.setContentSize(this.width, this.height);
        bg.setPosition(this.width / 2, this.height / 2);
        this.addChild(bg, -1);

        var captionForMove = cleverapps.UI.generateOnlyText(this.asset.name, cleverapps.styles.FONTS.STRICT_BLACK_TEXT);
        captionForMove.setPositionRound(this.componentFrame.width / 2, this.componentFrame.height / 2);

        this.componentFrame.addChild(captionForMove);
        this.previewCreated = true;
        var frameBg = cleverapps.UI.createScale9Sprite(bundles.wysiwyg.frames.button_on);
        frameBg.setContentSize(captionForMove.width, this.height);
        frameBg.setPosition(this.componentFrame.width / 2, this.componentFrame.height / 2);
        this.componentFrame.addChild(frameBg, -1);
    }
});

cleverapps.styles.ScriptComponentView = {
    width: 160,
    height: 95,

    text: {
        width: 150,
        x: { align: "center" },
        y: { align: "center", dy: 12 }
    },

    edit: {
        width: 40,
        height: 40,
        x: { align: "center", dx: -30 },
        y: { align: "center", dy: -25 }
    }
};