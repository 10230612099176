/**
 * Created by Ivan on 16.04.2024
 */

var StickersBookWindow = CleverappsWindow.extend({
    onWindowLoaded: function (stickersBook) {
        this.stickersBook = stickersBook;
        this._super({
            title: "StickersBookWindow.Title",
            name: "StickersBookWindow",
            content: this.getContent(),
            help: function () {
                new GuideWindow({
                    name: "StickersBookGuideWindow",
                    bundle: bundles.stickers_book_guide_window
                });
            },
            styles: cleverapps.styles.StickersBookWindow.window,
            offerWindowButton: this.getNextWindowButtonOptions(),
            foreground: bundles.windows.frames.window_foreground_png
        });
        stickersBook.on("update", this.onCollectionsUpdate.bind(this));
        this.onCollectionsUpdate();

        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STICKERS_BOOK_WINDOW_OPEN);
    },

    getNextWindowButtonOptions: function () {
        return StickersBook.isStickersShopAvailable() ? {
            text: "StickersShopWindow.Title",
            onClicked: this.onOfferClicked.bind(this),
            offerType: Offers.TYPES.STICKERS_PACK,
            bundle: "stickers_book_window",
            badgeText: "SALE"
        } : undefined;
    },

    onOfferClicked: function () {
        new StickersShopWindow();
    },

    getContent: function () {
        var styles = cleverapps.styles.StickersBookWindow;

        var content = new cc.Node();
        content.setAnchorPoint(0.5, 0.5);
        content.setContentSize2(styles.size);

        if (styles.fg) {
            content.addChild(this.createFg());
        }
        content.addChild(this.createCollections());

        content.addChild(this.createProgress());

        content.addChild(this.createCollectionsAmount());
        if (cleverapps.resolution.mode !== cleverapps.WideMode.VERTICAL) {
            content.addChild(this.createStickersAmount());
        }
        content.addChild(this.createJokers());

        return content;
    },

    createFg: function () {
        var styles = cleverapps.styles.StickersBookWindow.fg;

        var fg = new cc.Scale9Sprite(bundles.stickers_book_window.frames.foreground_png);
        fg.setContentSize2(styles.size);
        fg.setLocalZOrder(-2);
        fg.setPositionRound(styles);

        return fg;
    },

    reorderForHorizontalScreen: function (collectionsIcons) {
        var horizontalCollectionIcons = Array(collectionsIcons.length);
        var evenIndex = 0;
        var oddIndex = Math.ceil(collectionsIcons.length / 2);

        for (var i = 0; i < collectionsIcons.length; i++) {
            horizontalCollectionIcons[i % 2 ? oddIndex++ : evenIndex++] = collectionsIcons[i];
        }

        return horizontalCollectionIcons;
    },

    createCollections: function () {
        var styles = cleverapps.styles.StickersBookWindow;

        var collectionsIcons = this.icons = this.stickersBook.collections.map(function (collection) {
            return new StickersCollectionIcon(collection);
        });

        var layout = new cleverapps.GridLayout(
            cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? collectionsIcons : this.reorderForHorizontalScreen(collectionsIcons),
            {
                columns: cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? 3 : Math.ceil(this.stickersBook.collections.length / 2),
                margin: styles.grid.margin,
                padding: styles.grid.padding
            }
        );

        var scroll = this.scroll = new cleverapps.UI.ScrollView(layout, {
            childrenVisibility: cleverapps.UI.ScrollView.CHILDREN_VISIBILITY_NONE,
            direction: cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? cleverapps.UI.ScrollView.DIR_VERTICAL : cleverapps.UI.ScrollView.DIR_HORIZONTAL,
            outOfBoundaryScale: false
        });
        scroll.setContentSize2(styles.scroll.size);
        scroll.setPositionRound(styles.scroll.position);
        scroll.scrollToPercent(100);

        return scroll;
    },

    createProgress: function () {
        var styles = cleverapps.styles.StickersBookWindow.progressView;

        var progressView = this.progressView = new PrizeBarComponent({
            onCollect: this.close.bind(this),
            prize: false,
            progressBar: {
                progressImage: bundles.stickers_book_window.frames.progress_bar_png,
                progressBgImage: bundles.stickers_book_window.frames.progress_bar_bg_png,
                width: cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? styles.verticalWidth : styles.width,
                barText: {
                    dy: styles.text.dy
                }
            }
        });
        progressView.updateProgress(this.stickersBook.points, StickersCollection.GOAL_POINTS);
        progressView.setPositionRound(styles.position);

        this.stickersBook.on("updatePoints", this.createListener(function () {
            progressView.updateProgress(this.stickersBook.points, StickersCollection.GOAL_POINTS);
        }.bind(this)), this);

        if (cleverapps.config.debugMode) {
            cleverapps.UI.onClick(progressView, function () {
                this.stickersBook.points = StickersCollection.GOAL_POINTS;
                this.stickersBook.save();
                progressView.updateProgress(this.stickersBook.points, StickersCollection.GOAL_POINTS);
            }.bind(this));
        }

        var jokerIcon = new cc.Sprite(bundles.stickers_book_window.frames.joker_number_png);
        progressView.addChild(jokerIcon);
        jokerIcon.setPositionRound(styles.joker);

        return progressView;
    },

    createCollectionsAmount: function () {
        var styles = cleverapps.styles.StickersBookWindow.collectionsAmount;

        var node = new cc.Sprite(bundles.stickers_book_window.frames.collection_number_png);

        var text = this.collectionsAmount = cleverapps.UI.generateImageText("", cleverapps.styles.FONTS.STICKERS_PROGRESS_BAR_TEXT);
        var textBg = new cc.Scale9Sprite(bundles.stickers_book_window.frames.number_bg_png);
        textBg.setContentSize2(styles.bg);
        textBg.addChild(text);
        textBg.setLocalZOrder(-1);

        node.addChild(textBg);
        textBg.setPositionRound(styles.bg);
        node.setPositionRound(styles.position);

        return node;
    },

    onCollectionsUpdate: function () {
        if (this.stickersAmount) {
            var openedStickers = this.stickersBook.getOpenedStickersAmount();
            var totalStickers = this.stickersBook.collections.length * StickersCollection.STICKERS_IN_COLLECTION;
            this.stickersAmount.setString(openedStickers + "/" + totalStickers, cleverapps.styles.FONTS.STICKERS_PROGRESS_BAR_TEXT);
            this.stickersAmount.fitTo(cleverapps.styles.StickersBookWindow.stickersAmount.text.width);
            this.stickersAmount.setPositionRound(cleverapps.styles.StickersBookWindow.stickersAmount.text);
        }

        var completedCollections = this.stickersBook.getCompletedCollectionsAmount();
        var totalCollections = this.stickersBook.collections.length;
        this.collectionsAmount.setString(completedCollections + "/" + totalCollections, cleverapps.styles.FONTS.STICKERS_PROGRESS_BAR_TEXT);
        this.collectionsAmount.fitTo(cleverapps.styles.StickersBookWindow.collectionsAmount.text.width);
        this.collectionsAmount.setPositionRound(cleverapps.styles.StickersBookWindow.collectionsAmount.text);
    },

    createStickersAmount: function () {
        var styles = cleverapps.styles.StickersBookWindow.stickersAmount;

        var node = new cc.Sprite(bundles.stickers_book_window.frames.cards_number_png);

        var text = this.stickersAmount = cleverapps.UI.generateImageText("", cleverapps.styles.FONTS.STICKERS_PROGRESS_BAR_TEXT);
        var textBg = new cc.Scale9Sprite(bundles.stickers_book_window.frames.number_bg_png);
        textBg.setContentSize2(styles.bg);
        textBg.addChild(text);
        textBg.setLocalZOrder(-1);

        node.addChild(textBg);
        textBg.setPositionRound(styles.bg);
        node.setPositionRound(styles.position);

        return node;
    },

    createJokers: function () {
        var styles = cleverapps.styles.StickersBookWindow.jokers;

        var jokerAmount = this.jokerAmount = new StickersBookJokerView("stickers_book_window");
        cleverapps.UI.onClick(jokerAmount, function () {
            new GuideWindow({
                name: "JokerGuideWindow",
                bundle: bundles.joker_guide_window
            });
        });
        jokerAmount.setPositionRound(styles.position);

        return jokerAmount;
    },

    stickerAppearAnimation: function () {
        return new cc.Sequence(
            new cc.ScaleTo(0, 1.5),
            new cc.Show(),
            new cc.DelayTime(0.5),
            new cc.ScaleTo(0.5, 1).easing(cc.easeBackIn())
        );
    },

    runJokerAnimation: function (sticker, callback) {
        var jokerAnimation = new cleverapps.Spine(bundles.stickers_book_window.jsons.joker_animation_json);

        var ViewClass = sticker.getViewClass();
        var stickerView = new ViewClass(sticker);
        stickerView.setAnchorPoint(0.5, 0.5);
        stickerView.setVisible(false);

        this.content.addChild(stickerView);
        this.content.addChild(jokerAnimation);

        var sourcePosition = this.jokerAmount.getPosition();
        var finalPosition = jokerAnimation.calculatePositionRound(cleverapps.styles.StickersBookWindow.jokerAnimation.position);

        jokerAnimation.replaceParentSamePlace(cleverapps.scenes.getMovingNode(jokerAnimation));

        var startPoint = jokerAnimation.parent.convertToNodeSpace(this.content.convertToWorldSpace(sourcePosition));
        var middlePoint = jokerAnimation.parent.convertToNodeSpace(this.content.convertToWorldSpace(cc.p(sourcePosition.x, this.content.height)));
        var finalPoint = jokerAnimation.parent.convertToNodeSpace(this.content.convertToWorldSpace(finalPosition));
        var points = [startPoint, middlePoint, finalPoint];

        jokerAnimation.runAction(new cc.Sequence(
            new cc.DelayTime(0.3),
            new cc.CallFunc(function () {
                cleverapps.audio.playSound(bundles.stickers_book_window.urls.joker_sound_effect);
                jokerAnimation.setScale(0.2);
                jokerAnimation.setPositionRound(startPoint);
                jokerAnimation.setAnimation(0, "idle", true);
            }),
            new cc.Spawn(
                new cc.ScaleTo(0.5, 1),
                new cc.BezierTo(0.5, points)
            ),
            new cc.CallFunc(function () {
                jokerAnimation.setAnimation(0, "open", false);
                cleverapps.audio.playSound(bundles.stickers_book_window.urls.joker_open_effect);
            }),
            new cc.DelayTime(1)
        ));

        var collectionIcon = this.icons[sticker.collection.index];
        var collectionIconPosition = this.content.convertToNodeSpace(collectionIcon.parent.convertToWorldSpace(collectionIcon.getPosition()));

        var stickerPosition = stickerView.parent.convertToNodeSpace(this.content.convertToWorldSpace(cc.p(this.content.width / 2, this.content.height / 2)));
        stickerView.setPositionRound(stickerPosition);
        stickerView.runAction(new cc.Sequence(
            new cc.DelayTime(cleverapps.styles.StickersBookWindow.jokerAnimation.duration),
            this.stickerAppearAnimation(),
            new cc.Spawn(
                new cc.MoveTo(0.25, collectionIconPosition.x, collectionIconPosition.y).easing(cc.easeIn(2)),
                new cc.ScaleTo(0.25, 0.6),
                new cc.FadeTo(0.25, 150)
            ),
            new cc.RemoveSelf()
        ));

        collectionIcon.collect(callback);
    },

    useJokerAnimation: function (sticker, callback) {
        this.runAction(new cc.Sequence(
            new cc.CallFunc(function () {
                var collectionIcon = this.icons[sticker.collection.index];
                collectionIcon.createBadge();
            }.bind(this)),
            new cc.DelayTime(0.3),
            new cc.CallFunc(function () {
                this.runJokerAnimation(sticker, callback);
            }.bind(this))
        ));
    },

    onShow: function () {
        this._super();

        var target = this.icons.find(function (icon) {
            return icon.collection.hasAttention();
        });
        if (target) {
            this.scroll.scrollTo(target);
        }
        if (this.stickersBook.isFullPoints()) {
            this.progressView.updateProgress(StickersCollection.GOAL_POINTS - 50, StickersCollection.GOAL_POINTS);
            this.runAction(new cc.Sequence(
                new cc.DelayTime(0.1),
                new cc.CallFunc(function () {
                    cleverapps.audio.playSound(bundles.stickers_book_window.urls.full_progress_effect);
                })
            ));

            cleverapps.meta.display({
                stack: true,
                focus: "AddJoker",
                actions: [
                    function (f) {
                        this.progressView.updateProgress(this.stickersBook.points, StickersCollection.GOAL_POINTS, {
                            animated: true,
                            callback: f
                        });
                    }.bind(this),

                    function (f) {
                        var shining = new cleverapps.Spine(bundles.stickers_book_window.jsons.progressbar_light_json);
                        shining.setPositionRound(cleverapps.styles.StickersBookWindow.progressView.shine);
                        shining.setAnimation(0, "animation", false);
                        shining.setCompleteListenerRemove();
                        this.progressView.addChild(shining);
                        cleverapps.timeouts.setTimeout(f, 700);
                    }.bind(this),

                    function (f) {
                        new RewardWindow({ joker: this.stickersBook.exchangePointsToJokers() });
                        cleverapps.meta.onceNoWindowsListener = f;
                    }.bind(this),

                    function (f) {
                        cleverapps.timeouts.setTimeout(f, 300);
                    },

                    function (f) {
                        this.jokerAmount.animateCollect();
                        f();
                    }.bind(this)
                ]
            });
        }
    },

    listBundles: function () {
        return ["stickers_book_window"];
    }
});

cleverapps.styles.StickersBookWindow = {
    size: [
        { width: 920, height: 1600 },
        { width: 1560, height: 1100 },
        { width: 1560, height: 1100 }
    ],

    window: {
        padding: {
            left: 24,
            right: 27,
            top: 30,
            bottom: 30
        }
    },

    grid: {
        margin: {
            x: 25,
            y: 25
        },
        padding: {
            top: 15
        }
    },

    fg: {
        size: [
            { width: 860, height: 1322 },
            { width: 1460, height: 810 },
            { width: 1460, height: 810 }
        ],
        x: { align: "center" },
        y: { align: "center", dy: -110 }
    },

    scroll: {
        size: [
            { width: 860, height: 1322 },
            { width: 1450, height: 810 },
            { width: 1450, height: 810 }
        ],
        position: [
            { x: { align: "center" }, y: { align: "center", dy: -112 } },
            { x: { align: "center" }, y: { align: "center", dy: -112 } },
            { x: { align: "center" }, y: { align: "center", dy: -112 } }
        ],

        barPadding: {
            sidePadding: 20
        }
    },

    progressView: {
        width: 460,
        verticalWidth: 320,
        position: [
            { x: { align: "center", dx: 0 }, y: { align: "top", dy: -141 } },
            { x: { align: "center", dx: 0 }, y: { align: "top", dy: -141 } },
            { x: { align: "center", dx: 0 }, y: { align: "top", dy: -141 } }
        ],

        shine: {
            x: { align: "right", dx: 30 },
            y: { align: "center" }
        },

        joker: [
            { x: { align: "left", dx: -20 }, y: { align: "center" } },
            { x: { align: "left", dx: -20 }, y: { align: "center" } },
            { x: { align: "left", dx: -20 }, y: { align: "center" } }
        ],

        text: {
            dy: 4
        }
    },

    collectionsAmount: {
        position: [
            { x: { align: "left", dx: 30 }, y: { align: "top", dy: -131 } },
            { x: { align: "left", dx: 50 }, y: { align: "top", dy: -131 } },
            { x: { align: "left", dx: 50 }, y: { align: "top", dy: -131 } }
        ],

        text: {
            width: 100,
            x: { align: "center", dx: 12 },
            y: { align: "center" }
        },

        bg: {
            width: 160,
            height: 60,
            x: { align: "center", dx: 95 },
            y: { align: "center" }
        }
    },

    stickersAmount: {
        position: [
            { x: { align: "left", dx: 248 }, y: { align: "top", dy: -131 } },
            { x: { align: "left", dx: 280 }, y: { align: "top", dy: -131 } },
            { x: { align: "left", dx: 280 }, y: { align: "top", dy: -131 } }
        ],

        text: {
            width: 100,
            x: { align: "center", dx: 12 },
            y: { align: "center" }
        },

        bg: {
            width: 160,
            height: 60,
            x: { align: "center", dx: 95 },
            y: { align: "center" }
        }
    },

    jokers: {
        position: [
            { x: { align: "right", dx: -45 }, y: { align: "top", dy: -122 } },
            { x: { align: "right", dx: -62 }, y: { align: "top", dy: -122 } },
            { x: { align: "right", dx: -62 }, y: { align: "top", dy: -122 } }
        ]
    },

    jokerAnimation: {
        position: {
            x: { align: "center" },
            y: { align: "center" }
        },
        duration: 2.2
    }
};
