/**
 * Created by evgenijsenkevic on 04.09.2024
 */

var SwitchAdminButton = {
    onClicked: function () {
        var scene = cleverapps.scenes.getRunningScene();
        var newScene = scene instanceof AdministratorScene ? AdminLocationsScene : AdministratorScene;

        cleverapps.meta.display({
            focus: "SwitchAdminButton",
            action: function (f) {
                newScene.open(f);
            }
        });
    },

    isAvailableInEpisode: function () {
        var scene = cleverapps.scenes.getRunningScene();
        return cleverapps.administrator && cleverapps.administrator.adminLocations
            && cleverapps.oneOf(scene, AdminLocationsScene, AdministratorScene);
    }
};