/**
 * Created by razial on 23.03.2023
 */

var AdminLevelPreview = cc.Node.extend({
    ctor: function (bundles, callback) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);

        this.bundles = cleverapps.toArray(bundles || []);
        this.callback = callback || function () {};

        cleverapps.bundleLoader.loadBundles(this.bundles, {
            onSuccess: this.onPreviewLoaded.bind(this),
            onFailure: this.onPreviewLoadingFailed.bind(this)
        });
    },

    cleanup: function () {
        this._super();
        this.unload();
    },

    unload: function () {
        if (this.loaded) {
            this.loaded = false;
            cleverapps.bundleLoader.deleteBundles(this.bundles);
        }
    },

    onPreviewLoaded: function () {
        this.loaded = true;

        if (!this.isRunning()) {
            this.unload();
            return;
        }

        this.callback(this);
    },

    onPreviewLoadingFailed: function () {
        if (!this.isRunning()) {
            this.unload();
        }
    }
});