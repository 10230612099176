/**
 * Created by andrey on 21.05.2020
 */

var MovesBooster = function () {
    BaseBoosterBefore.call(this, cleverapps.Boosters.TYPE_MOVES);
};

MovesBooster.prototype = Object.create(BaseBoosterBefore.prototype);
MovesBooster.prototype.constructor = MovesBooster;

MovesBooster.prototype.getActionBefore = function () {
    return {
        type: "moves",
        source: this,
        amount: 5
    };
};