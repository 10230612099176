/**
 * Created by Andrey Popov on 16.02.2024
 */

var SupplyDayView = cc.Scale9Sprite.extend({
    ctor: function (options) {
        this._super(bundles.supplies_window.frames.tile_bg);

        this.isLast = options.isLast;
        this.day = options.day;
        this.onSelectDay = options.onClick;
        this.isSelected = options.isSelected;

        var styles = cleverapps.styles.SupplyDayView;
        if (this.isLast) {
            styles = styles.last;
        }
        this.used = options.stage > this.day;

        this.setContentSize2(styles);

        var image = this.image = new cleverapps.Spine(this.isLast ? bundles.supplies_window.jsons.last_box_json : bundles.supplies_window.jsons.box_json);
        if (image.hasSkin("day" + (this.day + 1))) {
            image.setSkin("day" + (this.day + 1));
        }
        image.setAnchorPoint(0.5, 0.5);
        image.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(image);

        if (this.used) {
            image.setAnimation(0, "bought", false);
        } else {
            image.setAnimation(0, this.isSelected ? "selected" : "idle", false);

            var text = cleverapps.config.ui === "riddles" ? "Supplies.N_day" : "Supplies.dayN";

            var dayTitle = this.dayTitle = cleverapps.UI.generateOnlyText(text, cleverapps.styles.FONTS.SUPPLIES_DAYS_TEXT, {
                separator: styles.title.lineBreak ? "\n" : " ",
                day: this.day + 1
            });

            if (styles.title.lineHeight) {
                dayTitle.setLineHeight(styles.title.lineHeight);
            }

            dayTitle.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
            dayTitle.setPositionRound(styles.title);
            this.addChild(dayTitle);
        }

        if (!this.used && !this.isSelected) {
            this.hoverHandler = cleverapps.UI.applyHover(this);
            this.clickHandler = cleverapps.UI.onClick(this, this.select.bind(this));
        }
    },

    select: function () {
        if (this.isSelected) {
            return;
        }

        this.isSelected = true;

        if (this.hoverHandler) {
            this.hoverHandler.remove();
            this.hoverHandler = undefined;
        }
        if (this.clickHandler) {
            this.clickHandler.remove();
            this.clickHandler = undefined;
        }

        this.onSelectDay(this.day);

        this.image.setAnimation(0, "selected", false);

        cleverapps.audio.playSound(bundles.supplies_window.urls.box_click_sfx);
    },

    unselect: function () {
        if (!this.isSelected) {
            return;
        }

        this.isSelected = false;

        if (!this.hoverHandler && !this.used) {
            this.hoverHandler = cleverapps.UI.applyHover(this);
        }
        if (!this.clickHandler && !this.used) {
            this.clickHandler = cleverapps.UI.onClick(this, this.select.bind(this));
        }

        this.image.setAnimation(0, this.used ? "bought" : "idle", false);
    },

    receiveRewards: function (f) {
        if (this.hoverHandler) {
            this.hoverHandler.remove();
            this.hoverHandler = undefined;
        }
        if (this.clickHandler) {
            this.clickHandler.remove();
            this.clickHandler = undefined;
        }

        this.dayTitle && this.dayTitle.removeFromParent();

        this.used = true;
        this.image.setAnimation(0, "open", false);
        this.image.setCompleteListener(f);

        cleverapps.audio.playSound(bundles.supplies_window.urls.box_open_sfx);
    }
});

cleverapps.styles.SupplyDayView = {
    width: 170,
    height: 176,

    title: {
        x: { align: "center" },
        y: { align: "top", dy: -8 }
    },

    last: {
        width: 240,
        height: 380,

        title: {
            x: { align: "center" },
            y: { align: "top", dy: -12 }
        }
    }
};