/**
 * Created by vladislav on 1/26/2024
 */

var WortalLogic = function () {
    PlatformLogic.call(this);
};

WortalLogic.prototype = Object.create(PlatformLogic.prototype);
WortalLogic.prototype.constructor = WortalLogic;

WortalLogic.prototype.getCurrentTournamentId = function (callback) {
    Wortal.tournament.getCurrentAsync().then(function (tournament) {
        var expired = new Date(tournament.endTime * 1000) < new Date();
        callback(cleverapps.CODE_SUCCEED, expired ? undefined : String(tournament.id));
    }).catch(function () {
        callback(cleverapps.CODE_FAILED);
    });
};

WortalLogic.prototype.reportScore = function (score, callback) {
    Wortal.tournament.postScoreAsync(score).then(function () {
        callback(cleverapps.CODE_SUCCEED);
    }).catch(function () {
        callback(cleverapps.CODE_FAILED);
    });
};
