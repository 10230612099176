/**
 * Created by Aleksandr on 29.11.2022
 */

var OrangeryView = cc.Node.extend({
    ctor: function (orangery) {
        this.orangery = orangery;
        this._super();
        this.setAnchorPoint(0.5, 0.5);
        this.setLocalZOrder(BaseWindow.WINDOWS_ZORDER - 3);

        this.updateSize();

        this.createMinimized();

        if (this.orangery.maximized) {
            this.createMaximized();
            this.minimized.setVisible(false);
            this.maximized.setVisible(true);
        }

        cleverapps.aims.registerTarget("orangery", this, {
            controls: this.orangery.getControlName()
        });

        this.orangery.onChangeButton = this.createListener(this.updateMinimizedContent.bind(this));
        this.orangery.onToggleButton = this.createListener(this.toggleVisibility.bind(this));
        this.orangery.onToggleView = this.createListener(function (state) {
            if (state === undefined) {
                state = !this.maximized || !this.maximized.isVisible();
            }
            this.orangery.maximized = state;
            if (state) {
                this.showView();
            } else {
                this.hideView();
            }
        }.bind(this));

        if (connector.info.isMobile) {
            this.setScale(1.5);
        }

        cleverapps.UI.onClick(this.minimized, this.onClick.bind(this));
    },

    updateSize: function () {
        var styles = cleverapps.styles.OrangeryView;

        var position = styles.showPosition[cleverapps.config.type] || styles.showPosition;

        this.setContentSize2(styles.minimized);
        this.setPositionRound(position);

        this.showPosition = position;
    },

    createDraggableTitle: function () {
        var draggable = new cc.Node();
        draggable.setLocalZOrder(2);

        cleverapps.UI.applyHover(draggable, {
            onMouseOver: function () {
                if (this.maximized.visible) {
                    document.body.style.cursor = "move";
                }
            }.bind(this),
            onMouseOut: function () {
                document.body.style.cursor = "";
            }
        });

        var startPosition;
        cleverapps.UI.onDrag(draggable, {
            onDragStart: function () {
                startPosition = this.maximized.getPosition();
                return true;
            }.bind(this),
            followPointer: function (touch) {
                var displacement = this.convertTouchToNodeSpaceDisplacement(touch);
                this.maximized.setPositionRound(startPosition.x + displacement.x, startPosition.y + displacement.y);
            }.bind(this),
            onDragEnd: function (touch) {
                var displacement = this.convertTouchToNodeSpaceDisplacement(touch);
                this.maximized.setPositionRound(startPosition.x + displacement.x, startPosition.y + displacement.y);
            }.bind(this)
        });
        return draggable;
    },

    createMinimized: function () {
        var styles = cleverapps.styles.OrangeryView.minimized;

        var minimized = this.minimized = new cc.Node();

        minimized.setContentSize2(styles);
        minimized.setPositionRound(styles.x / cc.view.getFrameSize().width * cleverapps.resolution.getSceneSize().width, styles.y);

        var bg = minimized.bg = new cc.Scale9Sprite(bundles.dev_buttons.frames.orangery_bg_dark);
        bg.setContentSize2(minimized.width, minimized.height);
        bg.setPositionRound(minimized.width / 2, minimized.height / 2);
        minimized.addChild(bg);

        var innerBg = new cc.Scale9Sprite(bundles.dev_buttons.frames.orangery_bg_light);
        innerBg.setContentSize(minimized.width - styles.margin * 2, minimized.height - styles.margin * 2);
        innerBg.setPositionRound(minimized.width / 2, minimized.height / 2);
        minimized.bg.addChild(innerBg);

        this.updateMinimizedContent();

        cleverapps.UI.applyHover(minimized.bg);

        this.addChild(minimized);
    },

    createMaximized: function () {
        this.orangery.init();

        var styles = cleverapps.styles.OrangeryView.maximized;

        var maximized = this.maximized = new cc.Node();
        maximized.setVisible(false);
        maximized.setPositionRound(styles);
        maximized.setLocalZOrder(3);

        var bg = maximized.bg = new cc.Scale9Sprite(bundles.dev_buttons.frames.orangery_bg_dark);
        maximized.addChild(bg);

        var content = maximized.content = new cc.Scale9Sprite(bundles.dev_buttons.frames.orangery_bg_light);
        maximized.addChild(content);

        var tabs = maximized.tabs = new Tabs(this.createTabs(), styles.tabs);
        tabs.setPositionRound(tabs.width / 2, tabs.height / 2);

        var draggable = maximized.title = this.createDraggableTitle();
        draggable.setContentSize(tabs.getContentSize());
        maximized.addChild(draggable);

        draggable.addChild(tabs);

        if (this.orangery.listTabs().length === 1) {
            tabs.setVisible(false);
        }

        var closeButton = maximized.closeButton = new CloseButton({
            onClicked: this.orangery.toggleView.bind(this.orangery, false)
        });
        maximized.addChild(closeButton);

        tabs.onTabChanged = this.createListener(this.onTabChanged.bind(this));

        tabs.activateTab(this.orangery.selectedTab || 0);

        maximized.setLocalZOrder(BaseWindow.WINDOWS_ZORDER - 2);

        this.addChild(maximized);

        cleverapps.UI.onClick(this.maximized, function () {}, {
            interactiveScale: false
        });
        cleverapps.UI.onClick(this, function () {}, {
            interactiveScale: false
        });
    },

    createTabs: function () {
        return this.orangery.listTabs().map(function (tab) {
            tab.generator = this.createListener(this.createTab.bind(this, tab));
            return tab;
        }.bind(this));
    },

    createTab: function (tab) {
        var minWidth = this.maximized.title.width + this.maximized.closeButton.width + cleverapps.styles.OrangeryView.maximized.margin;
        var content = new OrangeryTab(this.orangery, tab, minWidth);
        content.setAnchorPoint(0, 0);
        content.setPositionRound(0, 0);
        this.maximized.content.addChild(content);
        return content;
    },

    updateMinimizedContent: function () {
        var minimized = this.minimized;

        if (minimized.itemIcon) {
            minimized.itemIcon.removeTemporarily();
        }

        if (minimized.itemTitle) {
            minimized.itemTitle.removeFromParent();
        }

        if (minimized.trashIcon) {
            minimized.trashIcon.removeFromParent();
        }

        var styles = cleverapps.styles.OrangeryView.minimized;

        if (!this.orangery.selected) {
            var plus = minimized.itemIcon = new cc.Sprite(bundles.dev_buttons.frames.plus);
            plus.setPositionRound(minimized.width / 2, minimized.height / 2);
            minimized.bg.addChild(plus);
            return;
        }

        var icon = minimized.itemIcon = this.orangery.selected.minimizedIcon;
        minimized.bg.addChild(icon);
        icon.setPositionRound(minimized.width / 2, minimized.height / 2);
        cleverapps.UI.fitToBox(icon, {
            width: styles.width,
            height: styles.height
        });

        var title = minimized.itemTitle = cleverapps.UI.generateOnlyText(this.orangery.getItemCode(this.orangery.selected), cleverapps.styles.FONTS.SYSTEM);
        title.setPositionRound(styles.title);
        minimized.bg.addChild(title);

        var trashIcon = minimized.trashIcon = new cc.Sprite(bundles.buttons_main.frames.delete_icon);
        cleverapps.UI.fitToBox(trashIcon, {
            height: styles.trash.height
        });
        trashIcon.baseScale = trashIcon.scale;
        minimized.bg.addChild(trashIcon);
        trashIcon.setPositionRound(styles.trash);

        cleverapps.UI.applyHover(trashIcon);
        cleverapps.UI.onClick(trashIcon, function () {
            this.orangery.unselect(this.orangery.selected);
        }.bind(this));
    },

    onTabChanged: function (tabId) {
        this.updateMaximizedContent();

        if (cleverapps.config.type === "merge" && cleverapps.config.editorMode) {
            var layer = this.orangery.data.tabs[tabId].layer;
            if (layer === undefined) {
                layer = Map2dEditor.LAYER_UNITS;
            }

            Editor.currentEditor.setLayer(layer);
        }
    },

    updateMaximizedContent: function () {
        var maximized = this.maximized;

        var tabContent = maximized.tabs.activeTab();

        var styles = cleverapps.styles.OrangeryView.maximized;
        var width = tabContent.width;
        var height = tabContent.height;
        var titleHeight = Math.max(maximized.title.height, maximized.closeButton.height);

        maximized.setContentSize(width + styles.margin * 2, height + styles.margin * 2 + titleHeight);

        maximized.bg.setAnchorPoint(0.5, 0.5);
        maximized.bg.setContentSize2(maximized.width, maximized.height);
        maximized.bg.setPositionRound(maximized.width / 2, maximized.height / 2);

        maximized.content.setAnchorPoint(0, 0);
        maximized.content.setContentSize2(width, height);
        maximized.content.setPositionRound(styles.margin, styles.margin);

        maximized.title.setAnchorPoint(0, 0.5);
        maximized.title.setPositionRound(styles.margin, maximized.height - styles.margin - titleHeight / 2);

        maximized.closeButton.setAnchorPoint(1, 0.5);
        maximized.closeButton.setPositionRound(maximized.width - styles.margin, maximized.height - styles.margin - titleHeight / 2);
    },

    toggleVisibility: function (state) {
        if (state === undefined) {
            state = !this.isVisible();
        }
        this.toggle(state);
    },

    showView: function () {
        if (!this.maximized) {
            this.createMaximized();
        }

        if (this.maximized.isVisible()) {
            return;
        }

        var options = this.prepareAnimation(this.maximized, this.minimized);
        this.maximized.setPositionRound(options.to.x, options.to.y);
        this.maximized.setScale(options.scale.x, options.scale.y);
        this.maximized.setVisible(true);

        if (this.orangery.selectedTab) {
            this.maximized.tabs.activateTab(this.orangery.selectedTab);
        }

        cleverapps.debugStats.setEnabled(false);

        this.maximized.runAction(new cc.Sequence(
            new cc.Spawn(
                new cc.ScaleTo(0.2, 1, 1),
                new cc.MoveTo(0.2, options.from.x, options.from.y)
            ),
            new cc.CallFunc(function () {
                this.minimized.setVisible(false);
            }.bind(this))
        ));
    },

    hideView: function () {
        if (!this.maximized.isVisible()) {
            return;
        }

        var options = this.prepareAnimation(this.maximized, this.minimized);

        this.maximized.stopAllActions();
        this.maximized.runAction(new cc.Sequence(
            new cc.Spawn(
                new cc.ScaleTo(0.2, options.scale.x, options.scale.y),
                new cc.MoveTo(0.2, options.to.x, options.to.y)
            ),
            new cc.CallFunc(function () {
                cleverapps.debugStats.setEnabled(cleverapps.flags.toggleStatsMode ? false : cleverapps.flags.debugInfo !== false);
                this.maximized.setVisible(false);
                this.minimized.setVisible(true);
            }.bind(this)),
            new cc.MoveTo(0, options.from.x, options.from.y)
        ));
    },

    prepareAnimation: function (window, button) {
        var corners = [{ x: 0, y: 0 }, { x: 1, y: 0 }, { x: 1, y: 1 }, { x: 0, y: 1 }];

        var selectedDistance = 0, selectedCorner = 0;
        for (var i = 0; i < corners.length; ++i) {
            var corner = corners[i];
            var distance = 1 / cc.pDistanceSQ(window.convertToWorldSpace(cc.p(corner.x * window.width, corner.y * window.height)), button.convertToWorldSpace(cc.p(corner.x * button.width, corner.y * button.height)));

            if (distance > selectedDistance) {
                selectedDistance = distance;
                selectedCorner = corner;
            }
        }

        var fromPosition = cc.p(window);
        var fromCorner = button.convertToNodeSpace(window.convertToWorldSpace(cc.p(selectedCorner.x * window.width, selectedCorner.y * window.height)));
        var toCorner = button.convertToNodeSpace(button.convertToWorldSpace(cc.p(selectedCorner.x * button.width, selectedCorner.y * button.height)));

        var scale = {
            x: button.width / window.width,
            y: button.height / window.height
        };

        var toPosition = cc.pSub(fromPosition, fromCorner);
        toPosition.x = toPosition.x * scale.x + toCorner.x;
        toPosition.y = toPosition.y * scale.y + toCorner.y;

        return {
            from: fromPosition,
            to: toPosition,
            scale: scale
        };
    },

    onClick: function () {
        this.orangery.toggleView();
    }
});

cleverapps.styles.OrangeryView = {
    showPosition: {
        x: { align: "left", dx: 5 },
        y: { align: "bottom", dy: 10 },

        "battlefield": {
            x: { align: "left", dx: 300 },
            y: { align: "bottom", dy: 10 }
        }
    },

    maximized: {
        margin: 10,

        x: { align: "left", dx: 5 },
        y: { align: "bottom", dy: -5 },

        tabs: {
            x: { align: "left", dx: 5 },
            y: { align: "top", dy: 67 },
            type: {
                off_png: bundles.dev_buttons.frames.orangery_bg_dark,
                on_png: bundles.dev_buttons.frames.orangery_bg_light
            },
            direction: cleverapps.UI.HORIZONTAL,
            width: 90,
            height: 70
        },

        closeButton: {
            x: { align: "right", dx: -10 },
            y: { align: "top", dy: -10 }
        }
    },

    minimized: {
        width: 100,
        height: 100,

        x: 70,
        y: 0,

        margin: 10,

        trash: {
            height: 35,

            x: { align: "right" },
            y: { align: "top", dy: 10 }
        },

        title: {
            x: { align: "center" },
            y: { align: "top", dy: 25 }
        }
    }
};
