/**
 * Created by Oleg on 08.08.2019.
 */

var BlanketCellView = BaseCellView.extend({
    ctor: function (blanketCell) {
        this._super();

        this.blanketCell = blanketCell;
        this.blanketCell.deleteView = this.deleteView.bind(this);

        var bigComponent = blanketCell.findComponent(BigComponent);

        if (!bigComponent.isHead) {
            return;
        }

        if (!blanketCell.isForGoal) {
            this.setPositionRound(BaseCellView.alignInTheGrid(blanketCell.x + bigComponent.cols / 2 - 0.5, blanketCell.y + bigComponent.rows / 2 - 0.5));
            this.setContentSize(bigComponent.cols * cleverapps.styles.BaseCellView.CELL_SIZE_PX, bigComponent.rows * cleverapps.styles.BaseCellView.CELL_SIZE_PX);
        } else {
            var styles = this.getStyles();
            this.setContentSize2(styles.goal.width, styles.goal.height);
        }

        this.setAnchorPoint(0.5, 0.5);

        this.initialize(blanketCell);
    },

    getStyles: function () {
        return cleverapps.styles.BlanketCellView;
    },

    createEaterSpineContent: function (blanketCell) {
        var bigComponent = blanketCell.findComponent(BigComponent);

        var animationNode = new cc.Node();
        animationNode.setAnchorPoint(0, 0);
        this.allCells = [];
        for (var dy = 0; dy < bigComponent.rows; dy++) {
            var rowCells = [];
            for (var dx = 0; dx < bigComponent.cols; dx++) {
                var animation = new cleverapps.Spine(bundles.blanket.jsons.blanket_wrapper_spine1_json);
                animationNode.addChild(animation);
                animation.setAnimation(0, "idle", true);
                animation.setPositionRound((dx + 0.5) * cleverapps.styles.BaseCellView.CELL_SIZE_PX, (dy + 0.5) * cleverapps.styles.BaseCellView.CELL_SIZE_PX);
                rowCells.push(animation);
            }
            this.allCells.push(rowCells);
        }
        this.addChild(animationNode);

        var baseX = (bigComponent.cols) * cleverapps.styles.BaseCellView.CELL_SIZE_PX / 2;
        var baseY = (bigComponent.rows) * cleverapps.styles.BaseCellView.CELL_SIZE_PX / 2;

        var elementNode = this.elementNode = new cc.Node();
        elementNode.setPosition(baseX, baseY);

        var tartlet = this.tartlet = new cc.Sprite(bundles.blanket.frames.tartlet_png);
        tartlet.setAnchorPoint(0.5, 0.5);
        elementNode.addChild(tartlet);
        tartlet.setScale(this.getStyles().centerImageTartlet.scale);

        var colors = blanketCell.getAcceptedColors();
        if (!colors || (colors && colors.length <= 0)) {
            return;
        }
        var item = this.item = new cc.Sprite(bundles.color_cells.frames["color_cells_" + colors[0]]);
        item.setAnchorPoint(0.5, 0.5);
        elementNode.addChild(item);
        item.setPosition(0, this.getStyles().centerImageElement.dy);
        item.setScale(this.getStyles().centerImageElement.scale);

        var tartlet2 = this.tartlet2 = new cc.Sprite(bundles.blanket.frames.tartlet2_png);
        tartlet2.setAnchorPoint(0.5, 0.5);
        elementNode.addChild(tartlet2);
        tartlet2.setScale(this.getStyles().centerImageTartlet2.scale);

        this.addChild(elementNode);
    },

    hurtAnimation: function () {
        if (this.onHurtAnimating) {
            return;
        }
        this.onHurtAnimating = true;
        var DURATION = 0.05;
        this.elementNode.runAction(new cc.Sequence(
            (new cc.ScaleTo(DURATION, 1.2)).easing(cc.easeIn(1)),
            (new cc.ScaleTo(DURATION, 1)).easing(cc.easeIn(1)),
            new cc.CallFunc(function () {
                this.onHurtAnimating = false;
            }.bind(this))
        ));
        cleverapps.audio.playSound(bundles.game.urls.blanket_cell_stage_decrease_effect);
    },

    deleteView: function () {
        var bigComponent = this.blanketCell.findComponent(BigComponent);
        for (var dy = 0; dy < bigComponent.rows; dy++) {
            for (var dx = 0; dx < bigComponent.cols; dx++) {
                this.allCells[dy][dx].replaceParentSamePlace(this.parent);
                this.allCells[dy][dx].setAnimation(0, "animation", false);
                this.allCells[dy][dx].setLocalZOrder(100);
            }
        }

        this.runAction(new cc.Sequence(
            new cc.DelayTime(this.blanketCell.explodeDuration()),
            new cc.RemoveSelf()
        ));
        cleverapps.audio.playSound(bundles.game.urls.blanket_cell_die_effect);
    }
});

cleverapps.styles.BlanketCellView = cleverapps.overrideStyles(cleverapps.styles.EaterCellView, {
    width: 120,
    height: 120,

    idleAnimation: true,

    goal: {
        width: 120,
        height: 120,
        image: {
            scale: 0.8
        }
    },

    centerImageTartlet: {
        scale: 0.96
    },

    centerImageElement: {
        scale: 1.46,
        dy: 13
    },

    centerImageTartlet2: {
        scale: 0.96
    }
}, true);