/**
 * Created by evgenijsenkevic on 30.08.2024
 * */

var AdminActiveLocationView = cc.Node.extend({
    ctor: function (location) {
        this._super();

        this.location = location;
        this.locationBundles = location.getLocationBundles();

        var styles = cleverapps.styles.AdminActiveLocationView;
        this.setContentSize(styles.width, styles.height);
        this.setPositionRound(styles.position);

        this.createLoading();
        this.createButtons();

        cleverapps.bundleLoader.loadBundles(this.locationBundles, {
            onSuccess: this.onLocationLoaded.bind(this)
        });
    },

    unload: function () {
        cleverapps.bundleLoader.deleteBundles(this.locationBundles);
    },

    onLocationLoaded: function () {
        var location = bundles[this.location.getBundleName()].jsons.map_json;

        if (this.loading) {
            this.loading.removeFromParent();
            this.loading = undefined;
        }

        this.createBg();
        this.createContent(location.data);
    },

    createLoading: function () {
        var loading = this.loading = new LoadingAnimation();
        loading.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(loading);
    },

    createBg: function () {
        var bg = this.bg = cleverapps.UI.createScale9Sprite(bundles.admin.frames.lvl_selected_bg, cleverapps.UI.Scale9Rect.TwoPixelXY);
        bg.setContentSize2(this.getContentSize());
        bg.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(bg);
    },

    createContent: function (location) {
        var styles = cleverapps.styles.AdminActiveLocationView;

        var name = this.name = cleverapps.UI.generateOnlyText(location.locationName, cleverapps.styles.FONTS.ADMIN_LEVEL_TEXT_SELECTED);

        var preview = this.preview = AdminLevelMiniature.generate(location, styles.preview.map);
        preview.setContentSize2(styles.preview);
        preview.setAnchorPoint(0.5, 0.5);

        var dataView = new AdminLocationDataView(location, styles.dataview);

        var layout = new cleverapps.Layout([name, preview, dataView], {
            direction: cleverapps.UI.VERTICAL,
            margin: cleverapps.styles.AdminActiveLocationView.margin
        });

        layout.setAnchorPoint(0.5, 0.5);
        layout.setPositionRound(styles.layout);
        this.addChild(layout);
    },

    createButtons: function () {
        var styles = cleverapps.styles.AdminActiveLocationView.buttons;

        var playButton = new cleverapps.UI.Button({
            content: new cc.Sprite(bundles.admin.frames.play_icon),
            width: styles.width,
            height: styles.height,
            onClicked: function () {
                cleverapps.administrator.adminLocations.play();
            }
        });

        var editButton = new cleverapps.UI.Button({
            type: cleverapps.styles.UI.Button.Images.editor,
            content: new cc.Sprite(bundles.admin.frames.edit_icon),
            width: styles.width,
            height: styles.height,
            onClicked: function () {
                cleverapps.administrator.adminLocations.edit();
            }
        });

        var buttonsLayout = this.buttonsLayout = new cleverapps.Layout([playButton, editButton], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });

        buttonsLayout.setPositionRound(styles);
        this.addChild(buttonsLayout);
    }
});

cleverapps.styles.AdminActiveLocationView = {
    width: 520,
    height: 650,
    margin: 20,

    position: {
        x: { align: "center" },
        y: { align: "center" }
    },

    dataview: {
        width: 500,
        height: 100
    },

    preview: {
        width: 500,
        height: 400,

        map: {
            width: 450,
            height: 450
        },
        x: { align: "center" },
        y: { align: "bottom", dy: 10 }
    },

    name: {
        x: { align: "center" },
        y: { align: "top", dy: -10 }
    },

    buttons: {
        width: 45,
        height: 45,
        x: { align: "center" },
        y: { align: "bottom", dy: -50 },
        margin: 25
    },

    layout: {
        x: { align: "center" },
        y: { align: "center" }
    }
};