/**
 * Created by vladislav on 3/20/19
 */

var SyrupTile = function (x, y) {
    BaseTile.call(this, x, y);

    this.isForGoal = x === undefined;
    this.isForEditor = x === -1;
    this.putInPoolAvailable = true;

    this.onCreateNew = function () {};
    this.onRemoveSurface = function () {};
};

SyrupTile.prototype = Object.create(BaseTile.prototype);
SyrupTile.prototype.constructor = SyrupTile;

SyrupTile.prototype.getViewClass = function () {
    return SyrupTileView;
};

SyrupTile.prototype.hurt = function () {};

SyrupTile.prototype.explode = function () {};

SyrupTile.prototype.removeSurface = function () {
    this.onRemoveSurface();
};

SyrupTile.isSeparator = function (x, y) {
    var tile = Game.currentGame.field.floor[y][x];
    var cell = Game.currentGame.field.cells[y][x];

    return tile === null || cell && cell instanceof WallCell;
};

SyrupTile.iterateLine = function (cell, iterator) {
    var field = Game.currentGame.field;

    var x = cell.x, y = cell.y;
    var iterate = function (x, y, dir) {
        while (field.isWithinBounds(y, x) && !SyrupTile.isSeparator(x, y)) {
            iterator(x, y);

            if (SyrupTile.isVertical()) {
                x += dir;
            } else {
                y += dir;
            }
        }
    };

    iterate(x, y, 1);

    if (SyrupTile.isVertical()) {
        x--;
    } else {
        y--;
    }
    iterate(x, y, -1);
};

SyrupTile.isWithin = function (x, y) {
    var field = Game.currentGame.field;
    var floor = field.floor;

    var dir = BaseCell.DIRECTIONS[SyrupTile.dir];

    return field.isWithinBounds(y + dir.y, x + dir.x) && floor[y + dir.y][x + dir.x] && floor[y + dir.y][x + dir.x] instanceof SyrupTile;
};

SyrupTile.createLine = function (cell) {
    var field = Game.currentGame.field;
    var floor = field.floor;

    var createdTiles = 0;
    var dir = BaseCell.DIRECTIONS[SyrupTile.dir];
    SyrupTile.iterateLine(cell, function (x, y) {
        if (!floor[y][x]) {
            SyrupTile.createNew(x, y);
            createdTiles++;
        }
        if (field.isWithinBounds(y - dir.y, x - dir.x) && floor[y - dir.y][x - dir.x] && floor[y - dir.y][x - dir.x] instanceof SyrupTile) {
            floor[y - dir.y][x - dir.x].removeSurface();
        }
    });

    Game.currentGame.goalCounter.setTimeout(function () {
        if (Game.currentGame && createdTiles > 0) {
            Game.currentGame.goals.incGoal(SyrupTile.GOAL_ID, createdTiles);
        }
    }, 600);
};

SyrupTile.isVertical = function () {
    return SyrupTile.dir === BaseCell.UP || SyrupTile.dir === BaseCell.DOWN;
};

SyrupTile.createNew = function (x, y) {
    var field = Game.currentGame.field;

    var syrupTile = new SyrupTile(x, y);

    syrupTile.isNewWithin = SyrupTile.isWithin(x, y);

    field.addTile(syrupTile);
    syrupTile.onCreateNew();

    Game.currentGame.counter.setTimeout(function () {
    }, syrupTile.showUpDuration() * 1000);
};

SyrupTile.enableProcess = function () {
    Game.currentGame.syrupTileCanProcess = true;
};

SyrupTile.process = function (cells) {
    if (SyrupTile.dir === undefined || !Game.currentGame.syrupTileCanProcess) {
        return;
    }

    var floor = Game.currentGame.field.floor;
    var toCreate = [];
    for (var i = 0; i < cells.length - 1; i++) {
        for (var j = i + 1; j < cells.length; j++) {
            var dir = BaseCell.DIRECTIONS[SyrupTile.dir];
            var isValid = function (cell1, cell2) {
                var tile1 = floor[cell1.y][cell1.x];
                var tile2 = floor[cell2.y][cell2.x];
                return tile1 instanceof SyrupTile && !tile2 && cell1.x + dir.x === cell2.x && cell1.y + dir.y === cell2.y;
            };
            if (isValid(cells[i], cells[j])) {
                toCreate.push(cells[j]);
            } else if (isValid(cells[j], cells[i])) {
                toCreate.push(cells[i]);
            }
        }
    }

    toCreate.forEach(function (cell) {
        SyrupTile.createLine(cell);
    });

    if (toCreate.length > 0) {
        Game.currentGame.syrupTileCanProcess = false;
    }
};

SyrupTile.prototype.showUpDuration = function () {
    return 0.3;
};

SyrupTile.prototype.load = function (data) {
    this.dir = SyrupTile.dir = parseInt(data[1]);
};

SyrupTile.prototype.save = function () {
    return SyrupTile.CODES.concat([SyrupTile.dir]);
};

SyrupTile.prototype.bundleId = function () {
    return "syrup";
};

SyrupTile.prototype.getGoalId = function () {
    return SyrupTile.GOAL_ID;
};

SyrupTile.CODES = ["ё"];

SyrupTile.GOAL_ID = "syrup";
