/**
 * Created by Andrey Popov on 2/19/21.
 */

var PicturePuzzleLogic = function (width, height, game) {
    this.fieldWidth = width || 3;
    this.fieldWidth = Math.max(this.fieldWidth, 3);
    this.fieldWidth = Math.min(this.fieldWidth, 4);

    this.fieldHeight = this.fieldWidth;
    // this.fieldHeight = height || 3;
    // this.fieldHeight = Math.max(this.fieldHeight, 3);
    // this.fieldHeight = Math.min(this.fieldHeight, 5);

    this.game = game;
    this.goalsCount = this.fieldWidth * this.fieldHeight - 1;
    this.goalsDone = 0;
};

PicturePuzzleLogic.prototype.createUnits = function () {
    var map = this.game.map;
    var emptyCell = {
        x: this.fieldWidth - 1,
        y: this.fieldHeight - 1
    };
    map.remove(Map2d.LAYER_UNITS, emptyCell.x, emptyCell.y);
    for (var i = 0; i < this.fieldWidth * this.fieldHeight - 1; i++) {
        var x = i % this.fieldWidth;
        var y = (i - x) / this.fieldWidth;
        var unit = new Puzzle15Unit(map, {type: i, x: x, y: y, hideableIcon: true});
        map.add(Map2d.LAYER_UNITS, x, y, unit);
    }

    this.path = [];
    var justMovedUnit = undefined;
    do {
        var unit = cleverapps.Random.choose(map.listAvailableUnits().filter(function (candidateUnit) {
            return candidateUnit.canMove() && justMovedUnit !== candidateUnit;
        }));

        map.remove(Map2d.LAYER_UNITS, unit.x, unit.y);
        map.add(Map2d.LAYER_UNITS, emptyCell.x, emptyCell.y, unit);
        var tempX = unit.x;
        var tempY = unit.y;
        unit.x = emptyCell.x;
        unit.y = emptyCell.y;
        emptyCell.x = tempX;
        emptyCell.y = tempY;

        this.afterMove(map);
        this.path.push({
            unit: unit,
            path: [{
                x: emptyCell.x,
                y: emptyCell.y
            }]
        });

        justMovedUnit = unit;
        if (this.path.length > 50) {
            break;
        }

    } while (this.goalsDone !== 1 || (this.goalsDone < 3 && this.path.length > 30));

    if (this.path.length < 15 || this.path.length > 50) {
        this.createUnits();
    }
};

PicturePuzzleLogic.prototype.getPlayActions = function () {
    this.stepsCounter = this.path.length + cleverapps.Random.random(8, 15);
    this.remainingSteps = this.path.length;
    return this.path.reverse();
};

PicturePuzzleLogic.prototype.afterMove = function (map, move, silent, successCallback) {
    this.goalsDone = map.listAvailableUnits().filter(function (unit) {
        return unit.isPlaceMatchType();
    }.bind(this)).length;

    if (!silent && successCallback) {
        this.remainingSteps --;
        successCallback(this.goalsDone === this.goalsCount ? 1 : (this.path.length - this.remainingSteps) / this.path.length);
        this.stepsCounter --;
    }
};