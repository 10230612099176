/**
 * Created by mac on 7/23/17.
 */

var Loader = function () {
    this.map = {};

    this.map[" "] = undefined;

    this.doMap(FishCell);
    this.doMap(BaseCell, {
        codes: ColorComponent.CODES,
        components: [ColorComponent, MarkComponent]
    });
    this.doMap(BaseCell, {
        codes: GoalCoefComponent.CODES,
        components: [ColorComponent, GoalCoefComponent, MarkComponent]
    });
    this.doMap(MultiColorCell);
    this.doMap(SuperMultiColorCell);
    this.doMap(FlowerCell);
    this.doMap(ChainDecoratorCell, {
        components: [DecoratorComponent]
    });
    this.doMap(NutCell);
    this.doMap(IceCreamMakerCell);
    this.doMap(DonutBoxCell);
    this.doMap(GrassTile);
    this.doMap(IceTile);
    this.doMap(TransporterTile);
    this.doMap(MouseCell);
    this.doMap(BlanketCell);
    this.doMap(DogCell);
    this.doMap(BombCell);
    this.doMap(DirtDecoratorCell, {
        components: [DecoratorComponent]
    });
    this.doMap(CrabDecoratorCell, {
        components: [DecoratorComponent]
    });
    this.doMap(DragonCell);
    this.doMap(CookieManCell);
    this.doMap(BoxDecoratorCell, {
        components: [DecoratorComponent]
    });
    this.doMap(GingerHouseCell);
    this.doMap(GingerHousePartCell);
    this.doMap(CrabMakerCell);
    this.doMap(FireworkCell);
    this.doMap(CakeCell);
    this.doMap(BaseCell, {
        codes: MuffinComponent.CODES,
        components: [MuffinComponent]
    });
    this.doMap(MuffinGoalCell);
    this.doMap(BurstCell);
    this.doMap(DirtMakerCell);
    this.doMap(WaffleCell);
    this.doMap(TruffleCell);
    this.doMap(ColorCookieManCell);
    this.doMap(CandleCell);
    this.doMap(RabbitTile);
    this.doMap(LollipopCell);
    this.doMap(ColorBombCell);
    this.doMap(BagDecoratorCell, {
        components: [DecoratorComponent]
    });
    this.doMap(IceBlockDecoratorCell, {
        components: [DecoratorComponent]
    });
    this.doMap(RugTile);
    this.doMap(FireflyJarCell);
    this.doMap(MultiColorBombCell);
    this.doMap(DirtyHoneyTile);
    this.doMap(DirtyHoneyDecoratorCell, {
        components: [DecoratorComponent]
    });
    this.doMap(ColorContainerCell);
    this.doMap(IceMakerCell);
    this.doMap(SharkCell);
    this.doMap(MovesCell);
    this.doMap(GumDecoratorCell, {
        components: [DecoratorComponent]
    });
    this.doMap(ClockCell);
    this.doMap(SmallPetardCell);
    this.doMap(IceBlockMakerTile);
    this.doMap(CannonCell);
    this.doMap(WallCell);
    this.doMap(SyrupTile);
    this.doMap(FigureDragonCell);
    this.doMap(HeroCell);
    this.doMap(HeroCell, {
        codes: HeroCell.GOAL_CODES,
        components: [GoalCoefComponent]
    });
    this.doMap(ComboCell);
    this.doMap(CoinCell);
    this.doMap(BoosterCell);
    this.doMap(TulpanCell);
};

Loader.prototype.doMap = function (className, options) {
    options = options || {};
    (options.codes || className.CODES).forEach(function (letter) {
        if (this.map[letter] !== undefined) {
            console.log("Duplicate mapping for " + letter);
            console.log(className);
            throw 1;
        }
        this.map[letter] = {
            className: className,
            components: options.components
        };
    }, this);
};

Loader.prototype.loadNext = function (row, col, data, coordOnly) {
    if (data[0] === Loader.WILDCARD) {
        data[0] = cleverapps.Random.choose(Game.currentGame.levelContent.colors);

        if (cleverapps.gameModes.silentGoals) {
            data[0] = data[0].toLowerCase();
        }
    }

    var options = this.map[data[0]];
    var ClassName = options && options.className;

    if (ClassName !== undefined) {
        if (coordOnly) {
            return { x: col, y: row };
        }
        var cell = new ClassName(col, row, { components: options.components });
        cell.load(data, this);
        return cell;
    }

    return undefined;
};

Loader.prototype.load = function (array, callback, coordOnly) {
    var row = 0;
    array.forEach(function (line) {
        var letters = line.split("");
        letters.reverse();

        var col = 0;
        var i = letters.length - 1;
        while (i >= 0) {
            var char = letters[i];
            i--;

            var data = [];
            if (char >= "0" && char <= "9") {
                var length = char - "0";
                while (i >= 0 && letters[i] >= "0" && letters[i] <= "9") {
                    length = length * 10 + (letters[i] - "0");
                    i--;
                }
                for (var j = 0; j < length; j++) {
                    data.push(letters[i]);
                    i--;
                }
            } else {
                data.push(char);
            }

            var cell = this.loadNext(row, col, data, coordOnly);
            if (cell) {
                callback(cell);
            }

            col++;
        }

        row++;
    }, this);
};

Loader.prototype.save = function (array) {
    var data = [];
    for (var row = 0; row < Field.SIZE; row++) {
        var s = [];
        for (var col = 0; col < Field.SIZE; col++) {
            if (array[row][col] === undefined || array[row][col] === null) {
                s.push(" ");
            } else {
                var t = array[row][col].save();
                if (t.length > 1) {
                    t = t.length.toString().split("").concat(t);
                }
                s = s.concat(t);
            }
        }
        data.push(s.join(""));
    }
    return data;
};