/**
 * Created by Oleg on 06.03.2020.
 */

var ControlButtonsView = cleverapps.Layout.extend({
    ctor: function () {
        var styles = cleverapps.styles.ControlButtonsView;

        this.buttons = ControlButtonsView.controlButtons.filter(function (item) {
            return item.logic.isAvailableInEpisode();
        }).map(function (item) {
            return new ControlButton(item);
        });

        this._super(this.buttons, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });
    }
});

ControlButtonsView.controlButtons = [
    {
        type: "default",
        logic: SwitchAdminButton,
        icon: bundles.controlbuttons.frames.switch_icon_png
    },

    {
        type: "alternate",
        logic: ExitControlButton,
        icon: bundles.controlbuttons.frames.back_icon_png
    },

    {
        type: "default",
        logic: MenuControlButton,
        icon: bundles.controlbuttons.frames.menu_icon_png
    },

    {
        type: "default",
        logic: PauseControlButton,
        icon: bundles.controlbuttons.frames.pause_png
    }
];

cleverapps.styles.ControlButtonsView = {
    margin: 16
};
