/**
 * Created by Andrey Popov on 10.11.2021
 */

var AnalyzerWindowBase = cc.Node.extend({
    ctor: function (tabs) {
        this._super();

        this.setAnchorPoint(0.5, 0.5);
        this.setLocalZOrder(10);

        var styles = cleverapps.styles.AnalyzerWindowBase;

        if (!Array.isArray(tabs)) {
            tabs = [tabs];
        }

        this.tabSprites = [];
        var tabsLayout = new cleverapps.Layout(tabs.map(function (tab, index) {
            var tabSprite = cleverapps.UI.createScale9Sprite(bundles.wysiwyg.frames.window_bg);
            tabSprite.setAnchorPoint(0.5, 0.5);
            tabSprite.setOpacity(index === 0 ? 255 : 140);
            tabSprite.setContentSize(styles.header);

            var text = cleverapps.UI.generateOnlyText(tab, cleverapps.styles.FONTS.ANALYZER_HEADER_TEXT);
            text.setDimensions(styles.header.width, 0);
            text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
            text.setPositionRound(tabSprite.width / 2, tabSprite.height / 2 + styles.textOffset);
            tabSprite.addChild(text);

            cleverapps.UI.onClick(tabSprite, this.onTabClick.bind(this, tab));
            tabSprite.tab = tab;
            this.tabSprites.push(tabSprite);

            return tabSprite;
        }.bind(this)), {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.headerMargin
        });

        tabsLayout.setPositionRound(styles.header);
        this.addChild(tabsLayout, -1);

        var background = this.background = cleverapps.UI.createScale9Sprite(bundles.wysiwyg.frames.window_bg);
        background.setAnchorPoint(0.5, 0.5);
        this.addChild(background, -1);
    },

    setupChildren: function () {
        var styles = cleverapps.styles.AnalyzerWindowBase;

        this.background.setContentSize(this.width - styles.bg.padding, this.height - styles.bg.heightDiff);
        this.background.setPositionRound(this.width / 2, (this.height - styles.bg.offset) / 2);

        if (this.scroll) {
            var availableHeight = this.height - styles.topOffset;

            this.scroll.setContentSize2(this.width, availableHeight);
            this.scroll.setPositionRound(0, 0);

            if (this.scroll.innerContent.height < availableHeight) {
                this.scroll.centerNode.setPositionRound(this.scroll.width / 2, availableHeight - this.scroll.innerContent.height / 2);
            }
        }
    },

    createScroll: function (content, options) {
        if (this.scroll) {
            this.scroll.removeFromParent(true);
        }

        options = options || {};

        var scroll = this.scroll = new cleverapps.UI.ScrollView(undefined, {
            childrenVisibility: cleverapps.UI.ScrollView.CHILDREN_VISIBILITY_FULLCHECK,
            zoom: options.zoom,
            scrollBarEnabled: !options.hideScrollBar
        });

        scroll.setInnerContent(content);
        scroll.setAnchorPoint(0, 0);

        this.addChild(this.scroll);

        if (options && options.innerContentPos) {
            scroll.scrollBy(options.innerContentPos);
        }

        this.setupChildren();
    },

    onTabClick: function (tab) {
        this.tabSprites.forEach(function (tabSprite) {
            tabSprite.setOpacity(tabSprite.tab === tab ? 255 : 140);
        });
    }
});

cleverapps.styles.AnalyzerWindowBase = {
    header: {
        width: 120,
        height: 38,
        x: { align: "left", dx: 4 },
        y: { align: "top", dy: -4 }
    },
    bg: {
        heightDiff: 30,
        offset: 30,
        padding: 8
    },

    headerMargin: 3,

    textOffset: 2,

    topOffset: 45
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    ANALYZER_HEADER_TEXT: {
        size: 20,
        color: cleverapps.styles.COLORS.COLOR_DARK_GRAY,
        font: bundles.wysiwyg.urls.strict_font_ttf
    }
});
