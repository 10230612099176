/**
 * Created by slava on 3/5/19
 */

var Actor = cc.Node.extend({
    ctor: function (options) {
        this._super();
        this.options = options;
        this.setAnchorPoint(0.5, 0.5);
        this.updateSize();
        this.spine = new cleverapps.Spine(options.spine);
        this.currSpine = options.spine;
        this.currSpineId = options.spineId;
        this.currSfx = options.sfx;

        this.addChild(this.spine);

        if (options.skin) {
            this.spine.setSkin(options.skin);
            this.currSkin = options.skin;
        }

        this.spine.setAnimation(0, options.animation || Actor.ANIMATIONS.IDLE, true);

        this.alive = true;
        this.positionStyles = options.boostersAmountPosition ? options.boostersAmountPosition[Game.currentGame.boosters.amountAvailable()] : options;
        this.setupChildren();
    },

    updateSize: function () {
        this.setContentSize(this.options.size);

        this.baseScale = this.options.scale || 1;
        if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL && this.options.mobileScale) {
            this.baseScale = this.options.mobileScale;
        }
        this.setScale(this.baseScale);
    },

    setupChildren: function () {
        this.setPositionRound(this.getBasePosition());

        if (this.options.spinePosition) {
            this.spine.setPositionRound(this.options.spinePosition);
        } else {
            this.spine.setPositionRound(this.width / 2, 0);
        }
    },

    getBasePosition: function () {
        var pos = cleverapps.clone(this.positionStyles, true);
        if (pos.wideModePositions) {
            pos = pos.wideModePositions[cleverapps.resolution.mode];
        } else {
            pos = pos.position;
        }
        return pos;
    },

    shake: function () {
        this.stopAllActions();

        var scale = this.baseScale;
        if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL && this.options.mobileScale) {
            scale = this.options.mobileScale;
        }

        this.setScale(scale);
        this.runAction(new cc.Sequence(
            new cc.ScaleTo(0.1, scale * 1.07, scale * 0.93),
            new cc.ScaleTo(0.05, scale, scale)
        ));
    },

    moveAnimation: function () {
        this.stopAllActions();
        this.basePosition = this.getBasePosition();
        this.showUpAction = new cc.MoveTo(0.8, this.calculatePositionRound(this.basePosition)).setFinalize(function () {
            this.setPositionRound(this.basePosition);
            delete this.showUpAction;
        }.bind(this));
        this.runAction(this.showUpAction);
    },

    completeAnimationOnResize: function () {
        if (this.showUpAction) {
            this.stopAction(this.showUpAction);
        }
    },

    showUpAnimation: function () {},

    animate: function (animation, options) {
        options = options || {};

        if (!this.alive) {
            if (animation === Actor.ANIMATIONS.REVIVE) {
                this.alive = true;
            } else {
                return;
            }
        }

        if ([Actor.ANIMATIONS.DIE, Actor.ANIMATIONS.DIE_IDLE].indexOf(animation) !== -1) {
            this.alive = false;
        }

        if ([Actor.ANIMATIONS.HURT, Actor.ANIMATIONS.HURT2].indexOf(animation) !== -1) {
            if ([Actor.ANIMATIONS.ATTACK1, Actor.ANIMATIONS.ATTACK2, Actor.ANIMATIONS.ATTACK3, Actor.ANIMATIONS.ATTACK4].indexOf(this.animationRunning) !== -1) {
                this.animationRunning = false;
            }
            if ([Actor.ANIMATIONS.HURT, Actor.ANIMATIONS.HURT2].indexOf(this.animationRunning) !== -1 && this.animationStarted < Date.now() - 300) {
                this.animationRunning = false;
            }
        }

        if ([Actor.ANIMATIONS.DIE, Actor.ANIMATIONS.DIE_IDLE, Actor.ANIMATIONS.REVIVE, Actor.ANIMATIONS.HEAL, Actor.ANIMATIONS.ENEMY_ATTACK].indexOf(animation) !== -1) {
            this.animationRunning = false;
        }

        if (this.animationRunning) {
            return;
        }

        var sfx;
        if (this.currSfx && !options.noSFX) {
            sfx = this.currSfx[animation];
        }
        
        if (sfx) {
            this.runAction(new cc.Sequence(
                new cc.DelayTime(options.sfxDelay || cleverapps.styles.Actor.sfx.delay),
                new cc.CallFunc(function () {
                    cleverapps.audio.playSound(sfx);
                })
            ));
        }

        this.animationRunning = animation;
        this.animationStarted = Date.now();
        this.spine.setAnimation(0, animation, false);
        this.spine.setCompleteListener(function () {
            this.animationRunning = false;
            this.spine.setCompleteListener();

            var idleAnimation;
            if (options.idleAnimation) {
                idleAnimation = options.idleAnimation;
            } else if ([Actor.ANIMATIONS.DIE, Actor.ANIMATIONS.DIE_IDLE].indexOf(animation) !== -1) {
                idleAnimation = Actor.ANIMATIONS.DIE_IDLE;
            } else {
                idleAnimation = Actor.ANIMATIONS.IDLE;
            }

            this.spine.setAnimation(0, idleAnimation, true);

            options.callback && options.callback();
        }.bind(this));
    },

    getHidingPosition: function () {
        var scene = cleverapps.scenes.getRunningScene();
        var targetPosition = this.getPosition();

        switch (cleverapps.resolution.mode) {
            case cleverapps.WideMode.HORIZONTAL: case cleverapps.WideMode.SQUARE:
                targetPosition.x = scene.width + this.width + 2;
                break;
            case cleverapps.WideMode.VERTICAL:
                targetPosition.y = -this.width - 2;
                break;
        }

        return targetPosition;
    },

    hide: function () {
        this.runAction(new cc.Sequence(
            new cc.MoveTo(0.15, this.getHidingPosition()).easing(cc.easeIn(2)),
            new cc.CallFunc(function () {
                this.setVisible(false);
            }.bind(this))
        ));
    }
});

Actor.ANIMATIONS = {};
Actor.ANIMATIONS.ENEMY_ATTACK = "attack";
Actor.ANIMATIONS.ATTACK1 = "attack1";
Actor.ANIMATIONS.ATTACK2 = "attack2";
Actor.ANIMATIONS.ATTACK3 = "attack3";
Actor.ANIMATIONS.ATTACK4 = "attack4";
Actor.ANIMATIONS.IDLE = "idle";
Actor.ANIMATIONS.HAPPY = "happy";
Actor.ANIMATIONS.HURT = "hurt";
Actor.ANIMATIONS.HURT2 = "hurt2";
Actor.ANIMATIONS.DIE = "die";
Actor.ANIMATIONS.DIE_IDLE = "die_idle";
Actor.ANIMATIONS.HEAL = "heal";
Actor.ANIMATIONS.REVIVE = "revive";

cleverapps.styles.Actor = {
    sfx: {
        delay: 0.7
    },
    animation: {
        mobileOffsetX: 180,
        playerPositions: {
            x: 350,
            y: -20
        }
    },
    showDelay: undefined
};