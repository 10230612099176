/**
 * Created by slava on 11/9/18
 */

var PiggyBankWindow = CleverappsWindow.extend({
    onWindowLoaded: function (mission) {
        this.mission = mission;

        this.mission.logic.onShowBottomButtonPriceWithAnimation = this.createListener(this.showBottomButtonPriceWithAnimation.bind(this));
        this.mission.logic.onSetBalance = this.createListener(this.setBalance.bind(this));

        var leftTime = mission.getTimeLeft();
        var timer = this.createTimer(leftTime);
        if (leftTime <= 0 || !mission) {
            return;
        }

        var styles = cleverapps.styles.PiggyBankWindow;

        var animation = this.createAnimation(leftTime);

        var description = this.createDescription();

        var progress = this.progress = new PiggyBankProgressBar(mission);

        var padding = new cc.Node();
        padding.setContentSize(0, 30);

        var content = new cleverapps.Layout([animation, timer, description, progress, padding], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });

        this._super({
            name: "piggybankwindow",
            title: mission.logic.isFull() ? "PiggyBankWindow.Title.Full" : "PiggyBankWindow.Title",
            content: content,
            button: {
                width: styles.button.width,
                height: styles.button.height,
                onDisableHint: "PiggyBankWindow.bottom_button_hint",
                text: mission.logic.getCurrentPrice(),
                onPressed: function () {
                    mission.logic.buy(this.close.bind(this));
                }.bind(this)
            },
            help: function () {
                new GuideWindow({
                    name: "PiggyBankRulesWindow",
                    bundle: bundles.piggybankruleswindow
                });
            },
            styles: cleverapps.styles.PiggyBankWindow,
            openSound: bundles.main.urls.shop_buy_effect
        });

        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.PIGGY_BANK_WINDOW_OPENED);

        Lottery.addIcon(this.button);
        Lottery.addText(this);

        cleverapps.paymentsLogic.onChangeStatus(this.updateButton.bind(this), this);
        this.mission.setNeedReview(false);

        this.updateButton();
        this.mission.logic.updateProgress();
    },

    createWindowButtons: function () {
        this.params.button.onClicked = this.params.button.onPressed || this.close.bind(this);
        this.bottomButton = new BottomButton(this.params.button);

        return this.bottomButton;
    },

    updateButton: function () {
        if (connector.payments.isConnected() && this.mission.logic.checkAvailableBottomButton()) {
            this.bottomButton.enable();
        } else {
            this.bottomButton.disable();
        }
    },

    showBottomButtonPriceWithAnimation: function () {
        if (!this.bottomButton.disabled || !connector.payments.isConnected()) {
            return;
        }

        var light = new cleverapps.Spine(bundles.piggy_bank_window.jsons.button_light);
        light.setAnchorPoint(0.5, 0.5);
        light.setPositionRound({
            x: { align: "center" },
            y: { align: "center" }
        });

        light.setAnimation(0, "animation", false);
        this.bottomButton.addChild(light);
        light.setLocalZOrder(-1);

        this.bottomButton.runAction(new cc.Sequence(
            new cc.ScaleTo(0.5, 0.8),
            new cc.ScaleTo(0.3, 1),
            new cc.CallFunc(function () {
                this.bottomButton.enable();
            }.bind(this))
        ));
    },

    createAnimation: function () {
        var styles = cleverapps.styles.PiggyBankWindow;
        var pig = new cleverapps.Spine(bundles.piggy_bank.jsons.piggy_bank_json);
        pig.setAnchorPoint(0.5, 0.5);
        pig.setAnimation(0, this.mission.logic.isFull() ? "piggy_full" : "piggy_bank", true);
        if (this.mission.logic.isFull()) {
            this.runAction(new cc.RepeatForever(
                new cc.Sequence(
                    new cc.DelayTime(1.15),
                    new cc.PlaySound(bundles.piggy_bank.urls.jump_effect),
                    new cc.DelayTime(1.38)
                )
            ));
        }

        var balance = this.balance = this.createBalance();

        pig.addChild(balance);

        balance.setPositionRound(styles.balance.position);

        balance.setLocalZOrder(1);
        return pig;
    },

    setBalance: function () {
        this.balance.setScale(1);

        var delta = this.mission.result - this.mission.logic.getLastShowAmountProgress();
        var times = 1 + (delta / this.mission.logic.getMaxAmount()) * 50;

        var value = Math.floor((delta) / times);
        var duration = 0.6 / times;
        var currentBalance = this.mission.logic.getLastShowAmountProgress();

        this.balance.runAction(new cc.Sequence(
            new cc.ScaleTo(0.2, 1.2),
            new cc.DelayTime(0.2),
            new cc.PlaySound(bundles.piggy_bank.urls.piggy_load_coins),
            new cc.Repeat(new cc.Sequence(
                new cc.DelayTime(duration),
                new cc.CallFunc(function () {
                    currentBalance += value;
                    this.balance.setString(this.mission.logic.getGoal(currentBalance));
                }.bind(this))
            ), times - 1),
            new cc.CallFunc(function () {
                this.balance.setString(this.mission.logic.getGoal(this.mission.result));
            }.bind(this)),
            new cc.DelayTime(0.3),
            new cc.ScaleTo(0.4, 1)
        ).setFinalize(function () {
            this.mission.logic.setLastShowAmountProgress(this.mission.result);
        }.bind(this)));
    },

    createBalance: function () {
        return new TextWithIcon(this.mission.logic.getGoal(this.mission.logic.getLastShowAmountProgress()), {
            font: cleverapps.styles.FONTS.PIGGY_BANK_BALANCE_FONT,
            iconSize: 1.2
        });
    },

    createTimer: function (leftTime) {
        return new Timer(leftTime, this.close.bind(this), {
            timerBg: bundles.piggy_bank_window.frames.big_timer_bg_png,
            timerIcon: bundles.piggy_bank_window.frames.big_timer_png
        });
    },

    createDescription: function () {
        var styles = cleverapps.styles.PiggyBankWindow;

        var text = cleverapps.UI.generateOnlyText(this.mission.logic.getDescriptionText(), cleverapps.styles.FONTS.WINDOW_TEXT, {
            value: this.mission.logic.getBaseAmount()
        });

        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        text.setDimensions(styles.text.width, 0);
        text.fitTo(undefined, styles.text.height);
        return text;
    }
});

PiggyBankWindow.prototype.listBundles = function () {
    return ["piggy_bank_window"];
};

cleverapps.styles.FONTS.PIGGY_BANK_BALANCE_FONT = {
    size: 50,
    color: new cc.Color(0xFF, 0xE2, 0x40, 0xFF)
};

cleverapps.styles.PiggyBankWindow = {
    margin: 20,
    padding: {
        bottom: 100
    },
    amount: {
        width: 260,
        height: 64,

        margin: 10
    },

    text: {
        width: 640,
        height: 150
    },

    button: {
        width: 300,
        height: 100
    },

    pig: {
        position: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 0 }
        }
    },

    balance: {
        position: {
            x: { align: "center", dx: 0 },
            y: { align: "top", dy: -20 }
        }
    },

    timer: {
        position: {
            x: { align: "center", dx: 0 },
            y: { align: "bottom", dy: 0 }
        }
    },

    goal: {
        iconSize: 11
    },

    deltaAnimation: {
        dy: -40
    }
};
