/**
 * Created by decipaliz on 22.05.2024
 */

Mission.TYPE_PRIZE_CALENDAR = 10;

Missions.FEATURES[Mission.TYPE_PRIZE_CALENDAR] = function () {
    Missions[Mission.TYPE_PRIZE_CALENDAR] = {
        available: {
            level: 4.1,
            debugMode: true,
            youtube: {
                disabled: true
            }
        },
        abTest: cleverapps.ABTest.PRIZECAL,
        name: "prizecalendar",
        view: PrizeCalendarWindow,
        startWindow: PrizeCalendarWindow,
        nudgeWindow: PrizeCalendarWindow,
        iconForce: Forces.PRIZE_CALENDAR,
        logic: PrizeCalendarMissionLogic,
        semaphore: Missions.SEMAPHORE_CUSTOM_PRIZE_CALENDAR,
        sideBarJson: bundles.sidebar.jsons.prize_calendar_icon_json,
        duration: "180 minutes",
        bundle: "prizecalendar"
    };
};