/**
 * Created by vladislav on 28.09.2020
 */

var HighlightBooster = function () {
    BaseBoosterBefore.call(this, cleverapps.Boosters.TYPE_HIGHLIGHT);
};

HighlightBooster.prototype = Object.create(BaseBoosterBefore.prototype);
HighlightBooster.prototype.constructor = HighlightBooster;

HighlightBooster.prototype.getActionBefore = function () {
    return {
        type: "highlight",
        source: this,
        duration: cleverapps.config.name === "scramble" ? 0 : 0.3
    };
};