/**
 * Created by slava on 03.08.17.
 */

var IceTile = function (x, y) {
    BaseTile.call(this, x, y);
    this.putInPoolAvailable = true;

    this.onCreateNew = function () {};
};

IceTile.prototype = Object.create(BaseTile.prototype);
IceTile.prototype.constructor = IceTile;
Skinify(IceTile, "ice");

IceTile.CODES = ["@"];

IceTile.prototype.getViewClass = function () {
    return IceTileView;
};

IceTile.prototype.getGoalId = function () {
    return IceTile.GOAL_ID;
};

IceTile.prototype.showUpDuration = function () {
    return 0.6;
};

IceTile.prototype.getExplodeInterval = function () {
    return 0.3;
};

IceTile.GOAL_ID = "@";

IceTile.prototype.save = function () {
    return IceTile.CODES;
};