/**
 * Created by slava on 02.02.17.
 */

var HeroCell = function (x, y, options) {
    BaseCell.call(this, x, y);

    this.algo = options.algo;

    this.addComponent(ComboComponent);
    this.addComponent(ColorComponent, { color: options.color });
    this.addComponent(MarkComponent);

    if (options.components && options.components.includes(GoalCoefComponent)) {
        this.addComponent(GoalCoefComponent);
    }

    this.putInPoolAvailable = true;
};

HeroCell.prototype = Object.create(BaseCell.prototype);
HeroCell.prototype.constructor = HeroCell;

HeroCell.prototype.useCustomView = function () {
    return true;
};

HeroCell.prototype.simpleExplode = function (params) {
    var comboComponent = this.findComponent(ComboComponent);
    comboComponent.algoEffect(BaseCell.prototype.simpleExplode.bind(this, params));
};

HeroCell.prototype.explode = function (params) {
    var goalCoefComponent = this.findComponent(GoalCoefComponent);
    var colorComponent = this.findComponent(ColorComponent);

    if (goalCoefComponent) {
        goalCoefComponent.explode(params);
    } else if (colorComponent) {
        colorComponent.explode(params);
    }
};

HeroCell.prototype.explodeViaCollect = function (params) {
    var goalCoefComponent = this.findComponent(GoalCoefComponent);

    if (goalCoefComponent) {
        var comboComponent = this.findComponent(ComboComponent);
        comboComponent.algoEffect(BaseCell.prototype.explodeViaCollect.bind(this, params));
    }
};

HeroCell.prototype.onDoubleTouch = function () {
    if (Game.currentGame && (this.algo.activateByClick === cleverapps.HERO_ACTIVATE_BY_CLICK_MOVE || this.algo.activateByClick === cleverapps.HERO_ACTIVATE_BY_CLICK_NO_MOVE)) {
        if (this.algo.activateByClick === cleverapps.HERO_ACTIVATE_BY_CLICK_MOVE) {
            Game.currentGame.field.removeSelection();
            Game.currentGame.field.onMoveListener();
        }
        this.hurt();
    }
};

HeroCell.prototype.getRandomColor = function () {
    var lowerCaseAvailableColors = Game.currentGame.levelContent.colors.map(function (color) {
        return color.toLowerCase();
    });
    return cleverapps.Random.choose(match3.heroes.getListOfAvailableColors().filter(function (color) {
        return lowerCaseAvailableColors.indexOf(color) !== -1;
    })).toLowerCase();
};

HeroCell.prototype.getViewClass = function () {
    var colorComponent = this.findComponent(ColorComponent);

    if (colorComponent.color === "a") {
        // eslint-disable-next-line camelcase
        return HeroCellView_A;
    }
    if (colorComponent.color === "b") {
        // eslint-disable-next-line camelcase
        return HeroCellView_B;
    }
    if (colorComponent.color === "c") {
        // eslint-disable-next-line camelcase
        return HeroCellView_C;
    }
    if (colorComponent.color === "d") {
        // eslint-disable-next-line camelcase
        return HeroCellView_D;
    }
    if (colorComponent.color === "e") {
        // eslint-disable-next-line camelcase
        return HeroCellView_E;
    }
    if (colorComponent.color === "w") {
        // eslint-disable-next-line camelcase
        return HeroCellView_W;
    }
    throw "unknown color: " + colorComponent.color;
};

HeroCell.prototype.repaint = function () {
    return this.findComponent(ComboComponent).repaint();
};

HeroCell.prototype.canMoveWith = function (neighbor) {
    return (neighbor instanceof HeroCell);
};

HeroCell.prototype.save = function () {
    var colorComponent = this.findComponent(ColorComponent);
    var goalCoefComponent = this.findComponent(GoalCoefComponent);

    var data = (goalCoefComponent ? HeroCell.GOAL_CODES : HeroCell.CODES).concat([colorComponent.color]);

    var markCode = this.findComponent(MarkComponent).save();
    if (markCode) {
        data.push(markCode);
    }

    return data;
};

HeroCell.prototype.load = function (data) {
    var colorComponent = this.findComponent(ColorComponent);
    colorComponent.setColor(data[1]);

    this.findComponent(MarkComponent).load(data);

    this.algo = ComboComponent.CalcAlgo(this);
};
