/**
 * Created by slava on 19/2/20
 */

var ToolModel = function () {
    cleverapps.EventEmitter.call(this);

    this.root = new ToolMenuItem(cleverapps.Tool, null, "ROOT");

    this.path = this.root;
    this.search = "";

    ToolModel.AUTONAVIGATE = [
        {
            filter: function () {
                return this.path === this.root && cleverapps.scenes.getRunningScene() instanceof GameSceneBase;
            }.bind(this),

            path: ["game"]
        },

        {
            forces: true,
            path: ["Debug", "forces"]
        },
        {
            windows: [typeof ClansCupWindow !== "undefined" && ClansCupWindow],
            path: ["cups", "clan"]
        },
        {
            windows: [typeof ClansWindow !== "undefined" && ClansWindow, typeof MyClanWindow !== "undefined" && MyClanWindow],
            path: ["Debug", "clans"]
        },
        {
            windows: [DailyTasksWindow],
            path: ["dailyTasks"]
        },
        {
            filter: function () {
                var mission = cleverapps.missionManager.getRunningMissions().find(function (mission) {
                    return mission.view && cleverapps.windows.currentWindow() instanceof mission.view;
                });
                return mission && cleverapps.Tool.missions.listItems()[ToolMenuItem.ACTIVE_ITEM + mission.name];
            },
            path: function () {
                var mission = cleverapps.missionManager.getRunningMissions().find(function (mission) {
                    return mission.view && cleverapps.windows.currentWindow() instanceof mission.view;
                });
                return ["missions", ToolMenuItem.ACTIVE_ITEM + mission.name];
            }
        }
    ];

    if (cleverapps.config.type === "merge") {
        ToolModel.AUTONAVIGATE.push({
            windows: [CustomersWindow],
            path: ["Debug", "customers"]
        });
    }
};

ToolModel.prototype = Object.create(cleverapps.EventEmitter.prototype);
ToolModel.prototype.constructor = ToolModel;

ToolModel.prototype.show = function (silent) {
    this.opened = true;
    this.autoNavigate();
    this.trigger("show", silent);

    cleverapps.debugStats.setEnabled(false);
};

ToolModel.prototype.hide = function (silent) {
    this.opened = false;
    this.trigger("hide", silent);

    cleverapps.debugStats.setEnabled(cleverapps.flags.toggleStatsMode ? false : cleverapps.flags.debugInfo !== false);
};

ToolModel.prototype.toggleSearch = function (search) {
    if (search !== this.search) {
        this._navigate(this.root, search);
    } else if (!search) {
        this.hide();
    }
};

ToolModel.prototype._navigate = function (path, search) {
    this.path = path;
    this.search = search || "";
    this.trigger("change", this.search);
};

ToolModel.prototype.reset = function () {
    this.path = this.root;
    this.search = "";
    this.purge();
};

ToolModel.prototype.isOpened = function () {
    return this.opened;
};

ToolModel.prototype.listMenuItems = function () {
    if (this.search) {
        return this.find(this.search);
    }
    var list = this.path.children();
    if (this.path.parent) {
        list.unshift(new ToolMenuItem(this.path.parent.entry, this.path.parent.parent, "back"));
    }
    return list;
};

ToolModel.prototype.autoNavigate = function () {
    for (var i = 0; i < ToolModel.AUTONAVIGATE.length; i++) {
        var option = ToolModel.AUTONAVIGATE[i];
        var selected = false;

        if (option.forces && cleverapps.forces.isRunningForce()) {
            selected = true;
        } else if (option.windows && option.windows.some(function (targetWindow) {
            return targetWindow && cleverapps.windows.list.some(function (window) {
                return window instanceof targetWindow;
            });
        })) {
            selected = true;
        } else if (option.filter && option.filter()) {
            selected = true;
        }

        if (selected) {
            var path = this.root;
            var optionPath = option.path;
            if (typeof optionPath === "function") {
                optionPath = optionPath();
            }

            optionPath.forEach(function (key) {
                var activeChild = path.children().find(function (item) {
                    return item.key === key;
                });

                path = activeChild;
            });

            this._navigate(path, "");
            break;
        }
    }
};

ToolModel.prototype.refresh = function () {
    this.trigger("change");
};

ToolModel.prototype.execute = function (path) {
    var item = this.root;

    path = path.split("/");
    path.forEach(function (key) {
        item = item.children().find(function (item) {
            return item.key === key;
        });
    });

    item.clickHandler();
};

ToolModel.prototype.showPath = function (path) {
    this.show(true);
    this.backToRoot();
    this.execute(path);
    this.trigger("hideSpotlight");
};

ToolModel.prototype.backToRoot = function () {
    this.path = this.root;

    this.refresh();
};