/**
 * Created by Andrey Popov on 4/14/21.
 */

var AdHocPropertyEditor = function (property, initialValue, onChangeValueCallback) {
    this.property = property;
    this.onChangeValueCallback = onChangeValueCallback;
    this.value = initialValue || [];

    this.propertyEditors = [
        new PropertyEditor({
            name: "Ad hoc"
        }, this),
        new cleverapps.Layout(this.value.map(this.getAdHocView.bind(this)), {
            direction: cleverapps.UI.VERTICAL
        })
    ];
};

AdHocPropertyEditor.prototype.editBoxEditingDidEnd = function (sender) {
    try {
        var value = JSON.parse(sender.getString());
        sender.customSetter(value);
        this.onChangeValueCallback(this.property.name, this.value);
        cleverapps.wysiwyg.inspector.showItemProperties();
    } catch (e) {
        console.log(e);
    }
};

AdHocPropertyEditor.prototype.getAdHocView = function (adHoc, adHocIndex) {
    var styles = cleverapps.styles.AdHocPropertyEditor;

    var sourceString = cleverapps.UI.generateOnlyText(adHoc.bundle + " " + (adHoc.image || adHoc.spine), cleverapps.styles.FONTS.PROPERTY_EDITOR_TEXT);
    sourceString.setDimensions(styles.source.width, 0);

    var nameWithFolders = cleverapps.wysiwyg.googleDriveProvider.getAssetsInFolders(adHoc.image ? ["sprites"] : ["spines", "units"]);
    var dropdown = new DropDown({
        availableItems: Object.keys(nameWithFolders),
        value: this.value[adHocIndex] && this.value[adHocIndex].override ? this.value[adHocIndex].override.name : "click to set",
        size: cc.size(styles.target.width, sourceString.height),
        downOnly: true
    });
    dropdown.setDelegate({
        editBoxEditingDidEnd: function (dropdown) {
            this.value[adHocIndex].override = {
                name: dropdown.value,
                folder: nameWithFolders[dropdown.value]
            };
            this.onChangeValueCallback(this.property.name, this.value);
            cleverapps.wysiwyg.inspector.showItemProperties();
        }.bind(this)
    });

    var removeAction = cleverapps.UI.createSprite(bundles.wysiwyg.frames.delete_small);
    removeAction.setColor(cc.color.RED);
    removeAction.setVisible(false);

    cleverapps.UI.onClick(removeAction, function () {
        if (window.confirm("Remove '" + (adHoc.image || adHoc.spine) + "' override?")) {
            this.value.splice(adHocIndex, 1);
            this.onChangeValueCallback(this.property.name, this.value);
            cleverapps.wysiwyg.inspector.showItemProperties();
        }
    }.bind(this));

    var adHocRow = new cleverapps.Layout([sourceString, dropdown, removeAction], {
        direction: cleverapps.UI.HORIZONTAL
    });
    cleverapps.UI.applyHover(adHocRow, {
        onMouseOver: function () {
            removeAction.setVisible(true);
            removeAction.setScale(0.8);
        },
        onMouseOut: function () {
            removeAction.setVisible(false);
            removeAction.setScale(0.8);
        }
    });

    adHocRow.setLocalZOrder(this.value.length - adHocIndex);

    return adHocRow;
};

cleverapps.styles.AdHocPropertyEditor = {
    source: {
        width: 200
    },

    target: {
        width: 240
    }
};