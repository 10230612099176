/**
 * Created by anatoly on 25.09.2024
 */

var EditorDangerDurationView = cc.Node.extend({
    ctor: function (editor) {
        this._super();

        if (!editor.loaded) {
            editor.onLoadDangerDuration = function () {
                this.switcher._setValue(editor.dangerDuration);
            }.bind(this);
        }

        this.switcher = new IncDecBlock({
            radius: cleverapps.styles.EditorDangerDurationView.radius,
            timeBlock: true,
            value: editor.dangerDuration,
            range: [0, Infinity],
            onChange: function (value) {
                editor.dangerDuration = value;
                editor.save();
            }
        });

        this.addChild(this.switcher);

        this.setPositionRound(cleverapps.styles.EditorDangerDurationView);
    }
});

cleverapps.styles.EditorDangerDurationView = {
    x: { align: "center", dx: -480 },
    y: { align: "top", dy: -110 },

    radius: 80
};