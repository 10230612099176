/**
 * Created by iamso on 23.11.2020.
 */

var AdminLogEntry = function (data) {
    cleverapps.EventEmitter.call(this);

    this.log = cleverapps.administrator.log;

    this.loadData(data);
};

AdminLogEntry.prototype = Object.create(cleverapps.EventEmitter.prototype);
AdminLogEntry.prototype.constructor = AdminLogEntry;

AdminLogEntry.prototype.save = function (callback) {
    if (this.inSaveNow) {
        this.needSave = callback;
        return;
    }

    this.inSaveNow = true;
    this.needSave = undefined;

    var onResponse = function (response) {
        if (response.result) {
            if (response.result.insertId) {
                this.id = response.result.insertId;
            }
            if (callback) {
                callback();
            }
        } else {
            cleverapps.notification.create("Error saving to log");
            console.log(response, this.data);
        }

        this.inSaveNow = false;
        if (this.needSave) {
            this.save(this.needSave);
        }
    }.bind(this);

    cleverapps.RestClient.post("/admin/log/", this.getInfo(), onResponse, onResponse);
};

AdminLogEntry.prototype.loadData = function (data) {
    this.id = data.id;
    this.type = data.type;
    this.date = data.date || new Date();
    this.userName = data.userName || cleverapps.dataLoader.localStorage.getItem("adminAccess_username") || cleverapps.user.getPlayerInfo().name;

    var template = AdminLog.ACTIONS[data.type];
    Object.assign(this, template);

    this.params = {};
    for (var key in template.params) {
        if (data[key] === undefined) {
            console.error("Undefined param " + key + " in type " + this.type + " action");
        }
        this.params[key] = data[key];
    }
};

AdminLogEntry.prototype.getInfo = function () {
    return {
        id: this.id,
        type: this.type,
        date: this.date.getTime(),
        userName: this.userName,
        params: this.params
    };
};

AdminLogEntry.prototype.updateData = function (data, fromServer) {
    data = data || {};
    if (data.date && data.date.getTime() === this.date.getTime()) {
        return;
    }

    this.date = data.date || new Date();

    if (fromServer) {
        this.trigger("updateLogEntry");
    } else {
        this.save(function () {
            this.trigger("updateLogEntry");
        }.bind(this));
    }
};

AdminLogEntry.prototype.remove = function () {
    this.trigger("deleteLogEntry");
};
