/**
 * Created by Vladislav on 25.09.2024.
 */

Placements.ENTRIES["restoreLives"] = {
    type: Placements.FREE_FOCUS_MAIN,

    filter: function () {
        return cleverapps.flags.monetization === cleverapps.Flags.MONETIZATION_DISABLED && cleverapps.lives.isEmpty();
    },

    action: function () {
        cleverapps.meta.display({
            focus: "restoreLives",
            action: function (f) {
                new RewardWindow({ lives: cleverapps.lives.getMaxLives() });
                cleverapps.meta.onceNoWindowsListener = f;
            }
        });
    }
};