/**
 * Created by mac on 2019-03-22
 */

var Bundle = function (name, data) {
    this.name = name;

    this.parse(data);
};

Bundle.prototype.parse = function (data) {
    this.virtual = data.virtual;
    this.injectTo = data.injectTo;
    if (data.injectTo) {
        this.injectToSet = cleverapps.createSet(data.injectTo);
    }
    this.meta = data.meta;

    this.frames = data.frames;
    this.regions = {};
    this.jsons = data.jsons;
    this.urls = data.urls;

    if (data.important) {
        this.important = data.important;
    }

    if (data.lazy) {
        this.lazy = data.lazy;
    }

    if (data.originalPaths) {
        this.originalPaths = data.originalPaths;
    }

    for (var frameId in this.frames) {
        this.frames[frameId] = new VirtualFrame(this.name, frameId, this.frames[frameId]);
    }

    for (var jsonId in this.jsons) {
        this.jsons[jsonId] = new VirtualJson(this.name, jsonId, this.jsons[jsonId]);
    }

    for (var urlId in this.urls) {
        this.urls[urlId] = new VirtualUrl(this.name, urlId, this.urls[urlId]);
    }
};

Bundle.prototype.save = function () {
    var result = {
        virtual: this.virtual,
        injectTo: this.injectTo,
        meta: this.meta,
        originalPaths: this.originalPaths,

        urls: this.urls,
        important: this.important
    };

    var frames = {};
    for (var frameId in this.frames) {
        frames[frameId] = this.frames[frameId].save();
    }
    result.frames = frames;

    var jsons = {};
    for (var jsonId in this.jsons) {
        jsons[jsonId] = this.jsons[jsonId].save();
    }
    result.jsons = jsons;

    var urls = {};
    for (var urlId in this.urls) {
        urls[urlId] = this.urls[urlId].save();
    }
    result.urls = urls;

    return JSON.parse(JSON.stringify(result));
};

Bundle.prototype.onLoad = function (callback) {
    callback = callback || function () {};
    var key;

    if (this.urls.json) {
        var packedJson = cc.loader.getRes(this.urls.json);
        for (key in this.jsons) {
            var json = this.jsons[key];
            var jsonData = packedJson[json.name];

            if (cleverapps.config.debugMode && cleverapps.git) {
                jsonData = cleverapps.git.urls[json.originalPath()] || jsonData;
            }

            json.setJsonData(jsonData);
        }
    }

    var atlas, frame;

    if (this.jsons.atlas) {
        var minifiedAtlas = this.jsons.atlas.getJson();
        this.jsons.atlas.onUnload();

        try {
            atlas = BundleUtils.parseAtlas(minifiedAtlas);
        } catch (e) {
            console.error("cant parse parseMinifiedAtlas - " + this.name, minifiedAtlas);
            throw e;
        }
    }

    for (key in this.frames) {
        frame = this.frames[key];
        if (atlas.frames[frame.name] === undefined) {
            console.error(frame.name, minifiedAtlas);
            continue;
        }

        var frameData = atlas.frames[frame.name];
        frameData.spriteFrame = true;
        frame.setFrameData(frameData);
    }

    if (atlas) {
        this.atlas = {};

        if (engine === "cocos2d") {
            var page = BundleUtils.createPage(atlas, cc.textureCache.getTextureForKey(this.urls.png));
            for (var name in atlas.frames) {
                frame = atlas.frames[name];
                if (!frame.spriteFrame) {
                    this.regions[name] = BundleUtils.createRegion(name, frame, page);
                }
            }

            this.atlas.dispose = function () {
                if (page) {
                    page.texture.dispose();
                }
            };

            this.atlas.findRegion = function (name) {
                return this.regions[name];
            }.bind(this);
        } else {
            this.atlas.text = BundleUtils.getAtlasText(atlas);
            this.atlas.bundleName = this.name;
        }

        atlas = undefined;
    }

    cc.loader.release(this.urls.json);

    for (key in this.urls) {
        var texture = cc.textureCache.getTextureForKey(this.urls[key]);
        if (texture) {
            texture.bundleName = this.name;
        }
    }

    this.loaded = true;

    callback();
};

Bundle.prototype.onUnload = function () {
    this.loaded = false;

    delete this.atlas;

    for (var key in this.frames) {
        this.frames[key].onUnload();
    }

    for (var jsonId in this.jsons) {
        var virtualJson = this.jsons[jsonId];
        virtualJson.onUnload();
    }

    this.regions = {};
};

Bundle.prototype.listSoundUrls = function (includeLazy) {
    if (cc.sys.isWebViewAudio || cleverapps.environment.isLoaderScene() || this.virtual) {
        return [];
    }

    if (cleverapps.config.debugMode && cleverapps.dataLoader.load(DataLoader.TYPES.TEST_SOUNDS) === "important_only") {
        return [];
    }

    return Object.values(this.urls).filter(function (virtualUrl) {
        return virtualUrl.isSound() && virtualUrl.getUrl() && !virtualUrl.isImportant() && (includeLazy || !virtualUrl.isLazy());
    }).map(function (virtualUrl) {
        return virtualUrl.getUrl();
    });
};

Bundle.prototype.listUrls = function () {
    if (this.virtual) {
        return [];
    }

    return Object.values(this.urls).filter(function (virtualUrl) {
        if (connector.info.source === "playable") {
            if (typeof virtualUrl.getUrl() === "string" && (cc.loader.cache[virtualUrl.getUrl()] !== undefined || virtualUrl.getUrl().indexOf(".json") !== -1)) {
                return false;
            }
            if (virtualUrl.getUrl() && virtualUrl.getUrl().type === "font") {
                return false;
            }
        }

        if (virtualUrl.isSound() && (cc.sys.isWebViewAudio || !virtualUrl.isImportant() || virtualUrl.isLazy())) {
            return false;
        }

        return virtualUrl;
    }).map(function (virtualUrl) {
        return virtualUrl.getUrl();
    }).filter(Boolean);
};

Bundle.prototype.isInjectTo = function (name) {
    if (Array.isArray(name)) {
        return name.some(this.isInjectTo.bind(this));
    }
    return this.name === name || this.injectTo && this.injectTo.includes(name);
};

Bundle.prototype.getBundleName = function () {
    return this.name;
};