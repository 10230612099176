/**
 * Created by spepa on 18.07.2024
 */

var LazyAssets = function () {
    cleverapps.EventEmitter.call(this);

    this.bundles = {};
    this._loading = {};
};

LazyAssets.prototype = Object.create(cleverapps.EventEmitter.prototype);
LazyAssets.prototype.constructor = LazyAssets;

LazyAssets.prototype.keepBundles = function (bundles) {
    cleverapps.toArray(bundles).forEach(function (name) {
        if (!this.bundles[name]) {
            this.loadBundle(name);
        }
    }.bind(this));
};

LazyAssets.prototype.unload = function () {
    var bundles = Object.keys(this.bundles).concat(Object.keys(this._loading));
    cleverapps.bundleLoader.deleteBundles(bundles);
    this.bundles = {};
    this._loading = {};
};

LazyAssets.prototype.abortLoading = function () {
    this.off("onLoaded");
    this.trigger("onAbort");
};

LazyAssets.prototype.loadBundle = function (name) {
    this._loading = this._loading || {};
    if (this._loading[name]) {
        return;
    }
    this._loading[name] = true;

    cleverapps.bundleLoader.loadBundle(name, {
        onSuccess: function () {
            this.bundles[name] = true;
            delete this._loading[name];
            this.trigger("onLoaded", name, cleverapps.CODE_SUCCEED);
        }.bind(this),

        onFailure: function () {
            delete this._loading[name];
            this.trigger("onLoaded", name, cleverapps.CODE_FAILED);
        }.bind(this)
    });
};
