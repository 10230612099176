/**
 * Created by r4zi4l on 05.04.2024
 */

RewardTypes.herolevels = {
    getAnimation: function (value) {
        var json = value.color.toUpperCase() + "_hero_spine_json";
        return {
            json: cleverapps.skins.getSlot(json) || bundles.heroes.jsons[json],
            scale: 1.5,
            skin: match3.heroes.getCurrentSkin(),
            animation: "idle"
        };
    },

    parse: function (value, options) {
        return value.map(function (herolevel) {
            return new Reward("herolevels", { color: herolevel[0], level: herolevel[1] }, options);
        });
    },

    handler: function (value) {
        match3.heroes.setLevel(value.color, Math.max(0, Math.min(Heroes.HEROES_MAX_LEVEL, value.level)), true);
        return function () {
            match3.heroes.onHeroChangeLevel(value.color);
        };
    }
};
