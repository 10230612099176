/**
 * Created by andrey on 29.01.18.
 */

var ComingSoonLocationView = LocationView.extend({
    ctor: function (page) {
        this._super(page);

        this.setLocalZOrder(1);
    },

    customize: function () {
        this._super();

        var styles = cleverapps.styles.LocationView.comingSoon;
        if (styles.textOffset) {
            this.title.y += styles.textOffset;
            this.description.y += styles.textOffset;
        }
    },

    addBackground: function () {
        if (bundles.main.jsons.episode_clouds_spine_json) {
            var animation = new cleverapps.Spine(bundles.main.jsons.episode_clouds_spine_json);
            animation.setAnimation(0, "idle", true);
            animation.setLocalZOrder(-1);
            this.addChild(animation);
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.LocationView, {
    comingSoon: {}
});
