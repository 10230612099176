/**
 * Created by Andrey Popov on 1/25/21.
 */

var ComponentBoxView = cc.Node.extend({
    ctor: function (hierarchyItem, parentComponent) {
        this._super();

        this.hierarchyItem = hierarchyItem;
        this.parentComponent = parentComponent;
        var properties = this.properties = cleverapps.clone(hierarchyItem.properties, true);
        this.setAnchorPoint(0, 0);

        if (properties.rotation) {
            this.setRotation(properties.rotation);
        }

        if (this.properties.zOrder !== undefined) {
            this.setLocalZOrder(this.properties.zOrder);
        }
        if (this.properties.isGame) {
            this.setLocalZOrder(-1);
        }

        parentComponent.addChild(this);
    },

    updateBox: function (boundingBox, boxScale) {
        this.setScale(boxScale);
        this.setPositionRound(boundingBox.x * boxScale, boundingBox.y * boxScale);

        this.setContentSize(boundingBox);

        if (this.tools) {
            this.tools.setPositionRound(this.width / 2, this.height / 2);
            this.tools.setContentSize(this.getContentSize());
            this.updateControlPositions();
        }

        if (this.clickHandler) {
            this.clickHandler.remove();
            this.clickHandler = undefined;
        }

        this.clickHandler = cleverapps.UI.onClick(this, function (touch) {
            if (this.properties.isGame && cleverapps.keyboardController.isPressed(cc.KEY.ctrl)) {
                var position = this.parent.convertToNodeSpace(touch._point);
                cc.director.getRunningScene().wysiwygPreviewView.sendMessageToIFrame({
                    type: "debugClick",
                    point: cc.p(position.x / this.parent.width, position.y / this.parent.height)
                });
            } else {
                cleverapps.wysiwyg.hierarchy.selectItem(this.hierarchyItem);
            }
        }.bind(this), {
            interactiveScale: false
        });
    },

    updateHierarchyItem: function (hierarchyItem) {
        this.hierarchyItem = hierarchyItem;
        this.properties = cleverapps.clone(hierarchyItem.properties, true);
    },

    updateControlPositions: function () {
        var styles = cleverapps.styles.ComponentBoxView;

        this.changeWidthControl && this.changeWidthControl.setPositionRound(styles.changeWidth);
        this.changeWidthControlLeft && this.changeWidthControlLeft.setPositionRound(styles.changeWidthLeft);
        this.changeHeightControl && this.changeHeightControl.setPositionRound(styles.changeHeight);
        this.changeHeightControlTop && this.changeHeightControlTop.setPositionRound(styles.changeHeightTop);
        this.changeScaleControl && this.changeScaleControl.setPositionRound(styles.changeScale);
    },

    updateItemTools: function (visible) {
        if (visible) {
            this.setLocalZOrder(this.getLocalZOrder() + 100);
        } else {
            this.setLocalZOrder(this.properties.zOrder || 0);
            if (this.properties.isGame) {
                this.setLocalZOrder(-1);
            }
        }

        var tools = this.tools;
        if (!tools) {
            tools = this.tools = cleverapps.UI.createScale9Sprite(bundles.wysiwyg.frames.window_bg);
            tools.setAnchorPoint(0.5, 0.5);
            tools.setColor(cleverapps.styles.COLORS.GRAY_SCALE_COLOR);
            tools.setOpacity(120);
            this.addChild(tools);
        }

        this.tools.setPositionRound(this.width / 2, this.height / 2);
        this.tools.setContentSize(this.getContentSize());
        this.tools.setVisible(visible);

        if (this.dragListener) {
            this.dragListener.remove();
            this.dragListener = undefined;
        }

        if (this.changeWidthControl) {
            this.changeWidthControl.removeFromParent();
            this.changeWidthControlListener.remove();
        }
        if (this.changeWidthControlLeft) {
            this.changeWidthControlLeft.removeFromParent();
            this.changeWidthControlLeftListener.remove();
        }
        if (this.changeHeightControl) {
            this.changeHeightControl.removeFromParent();
            this.changeHeightControlListener.remove();
        }
        if (this.changeHeightControlTop) {
            this.changeHeightControlTop.removeFromParent();
            this.changeHeightControlTopListener.remove();
        }
        if (this.changeScaleControl) {
            this.changeScaleControl.removeFromParent();
            this.changeScaleControlListener.remove();
        }

        if (this.keyboardListener) {
            cc.eventManager.removeListener(this.keyboardListener);
            delete this.keyboardListener;
        }

        if (!visible) {
            return;
        }

        var startPosition;
        this.dragListener = cleverapps.UI.onDrag(this.tools, {
            onDragStart: function () {
                startPosition = this.getPosition();
                this.changeCursor("move");
                return true;
            }.bind(this),
            onDragMove: function (touch) {
                var displacement = this.parent.convertTouchToNodeSpaceDisplacement(touch);
                this.setPosition(startPosition.x + displacement.x, startPosition.y + displacement.y);
            }.bind(this),
            onDragEnd: function (touch) {
                var displacement = this.parent.convertTouchToNodeSpaceDisplacement(touch);
                this.shiftPosition(Math.round(displacement.x), Math.round(displacement.y));
                this.changeCursor();
            }.bind(this)
        });

        var position = Wysiwyg.getValueForResolution(this.properties.position);
        var startSize;
        var addChangeSizeListener = function (control, dimension, sign) {
            return cleverapps.UI.onDrag(control, {
                onDragStart: function () {
                    startSize = this.getContentSize();
                    var cursor = "nwse-resize";
                    if (dimension === "width") {
                        cursor = "ew-resize";
                    } else if (dimension === "height") {
                        cursor = "ns-resize";
                    }
                    this.changeCursor(cursor);
                    return true;
                }.bind(this),
                onDragMove: function (touch) {
                    var displacement = this.convertTouchToNodeSpaceDisplacement(touch);

                    if (dimension === "width") {
                        var alignCoefX = position.x.align === "center" ? 2 : 1;
                        this.tools.setContentSize(startSize.width + sign * displacement.x * alignCoefX, startSize.height);
                    } else if (dimension === "height") {
                        var alignCoefY = position.y.align === "center" ? 2 : 1;
                        this.tools.setContentSize(startSize.width, startSize.height + sign * displacement.y * alignCoefY);
                    } else {
                        this.tools.setContentSize(startSize.width + sign * displacement.x, startSize.height - sign * displacement.y);
                    }
                    this.updateControlPositions();
                }.bind(this),
                onDragEnd: function () {
                    var width = Wysiwyg.toPercents(this.tools.width * this.scale, this.parentComponent.width);
                    var height = Wysiwyg.toPercents(this.tools.height * this.scale, this.parentComponent.height);
                    if (dimension === "width") {
                        this.changeProperty("width", Wysiwyg.setValueForResolution(this.properties.width, width));
                    } else if (dimension === "height") {
                        this.changeProperty("height", Wysiwyg.setValueForResolution(this.properties.height, height));
                    } else {
                        this.changeProperty("scale", Wysiwyg.setValueForResolution(
                            this.properties.scale,
                            Wysiwyg.getValueForResolution(this.properties.scale) * this.tools.width / (startSize.width)
                        ));
                    }
                    this.updateControlPositions();
                    this.changeCursor();
                }.bind(this)
            });
        }.bind(this);

        if (["AdsSprite", "AdsSpine", "AdsGame", "AdsLogo"].indexOf(this.hierarchyItem.assetName) === -1) {
            if (position.x.align !== "right") {
                var changeWidthControl = this.changeWidthControl = new cc.Sprite(bundles.editor_controls.frames.preview_control);
                changeWidthControl.setRotation(-90);
                changeWidthControl.setAnchorPoint(0.5, 0.5);
                this.changeWidthControlListener = addChangeSizeListener(changeWidthControl, "width", 1);
                tools.addChild(changeWidthControl);
            }

            if (position.x.align !== "left") {
                var changeWidthControlLeft = this.changeWidthControlLeft = new cc.Sprite(bundles.editor_controls.frames.preview_control);
                changeWidthControlLeft.setRotation(90);
                changeWidthControlLeft.setAnchorPoint(0.5, 0.5);
                this.changeWidthControlLeftListener = addChangeSizeListener(changeWidthControlLeft, "width", -1);
                tools.addChild(changeWidthControlLeft);
            }

            if (position.y.align !== "bottom") {
                var changeHeightControl = this.changeHeightControl = new cc.Sprite(bundles.editor_controls.frames.preview_control);
                changeHeightControl.setAnchorPoint(0.5, 0.5);
                this.changeHeightControlListener = addChangeSizeListener(changeHeightControl, "height", -1);
                tools.addChild(changeHeightControl);
            }

            if (position.y.align !== "top") {
                var changeHeightControlTop = this.changeHeightControlTop = new cc.Sprite(bundles.editor_controls.frames.preview_control);
                changeHeightControlTop.setRotation(180);
                changeHeightControlTop.setAnchorPoint(0.5, 0.5);
                this.changeHeightControlTopListener = addChangeSizeListener(changeHeightControlTop, "height", 1);
                tools.addChild(changeHeightControlTop);
            }
        }

        if (["AdsText", "AdsScale9Sprite"].indexOf(this.hierarchyItem.assetName) === -1) {
            var changeSizeControl = this.changeScaleControl = new cc.Sprite(bundles.editor_controls.frames.preview_control);
            changeSizeControl.setRotation(-45);
            changeSizeControl.setAnchorPoint(0.5, 0.5);
            this.changeScaleControlListener = addChangeSizeListener(changeSizeControl, "both", 2);
            tools.addChild(changeSizeControl);
        }

        this.updateControlPositions();

        if ("keyboard" in cc.sys.capabilities) {
            this.keyboardListener = cc.eventManager.addListener({
                event: cc.EventListener.KEYBOARD,
                onKeyPressed: function (key) {
                    var keys = {};
                    keys[cc.KEY.up] = { x: 0, y: 1 };
                    keys[cc.KEY.down] = { x: 0, y: -1 };
                    keys[cc.KEY.left] = { x: -1, y: 0 };
                    keys[cc.KEY.right] = { x: 1, y: 0 };

                    if (keys[key]) {
                        this.shiftPosition(keys[key].x, keys[key].y);
                    }
                }.bind(this)
            }, this);
        }
    },

    shiftPosition: function (dx, dy) {
        var parentWidth = this.parentComponent.width;
        var parentHeight = this.parentComponent.height;

        var position = Wysiwyg.getValueForResolution(this.properties.position);
        var newDx = position.x.dx;
        if (newDx) {
            newDx = parentWidth * parseFloat(newDx) / 100;
        }
        newDx += dx;

        var newDy = position.y.dy;
        if (newDy) {
            newDy = parentHeight * parseFloat(newDy) / 100;
        }
        newDy += dy;

        this.changeProperty("position", Wysiwyg.setValueForResolution(this.properties.position, {
            x: {
                align: position.x.align,
                dx: parseFloat((newDx * 100 / parentWidth).toFixed(2))
            },
            y: {
                align: position.y.align,
                dy: parseFloat((newDy * 100 / parentHeight).toFixed(2))
            }
        }));
    },

    changeProperty: function (property, value) {
        this.properties[property] = value;
        var hierarchy = cleverapps.wysiwyg.hierarchy;
        hierarchy.changeItemProperty(hierarchy.selectedItem, property, value);
        cleverapps.wysiwyg.inspector.showItemProperties();
    },

    changeCursor: function (name) {
        if (!connector.info.isNative && "mouse" in cc.sys.capabilities) {
            cc._canvas.style.cursor = name || "default";
        }
    }
});

cleverapps.styles.ComponentBoxView = {
    changeWidth: {
        x: { align: "right", dx: 25 },
        y: { align: "center" }
    },

    changeWidthLeft: {
        x: { align: "left", dx: -25 },
        y: { align: "center" }
    },

    changeHeight: {
        x: { align: "center" },
        y: { align: "bottom", dy: -25 }
    },

    changeHeightTop: {
        x: { align: "center" },
        y: { align: "top", dy: 25 }
    },

    changeScale: {
        x: { align: "right", dx: 15 },
        y: { align: "bottom", dy: -15 }
    }
};