/**
 * Created by vladislav on 23.12.2020
 */

var AdsTransition = cc.Node.extend({
    ctor: function (properties, eventBus) {
        this.properties = properties;
        this.eventBus = eventBus;

        this._super();
        this.setAnchorPoint(0.5, 0.5);

        if (properties.isPreview || properties.listensEvent === Wysiwyg.NOT_SELECTED) {
            this.show();
            return;
        }

        eventBus.on(properties.listensEvent, function (options) {
            setTimeout(function () {
                if (properties.listensEvent === Wysiwyg.EVENTS.PROGRESS_CHANGED) {
                    if (options === 1) {
                        this.show();
                    }
                } else {
                    this.show();
                }
            }.bind(this), properties.delayAfterListenedEvent || 0);
        }.bind(this));
    },

    show: function () {
        if (!this.properties.spine || !this.properties.spine.name) {
            return;
        }
        var spinePath = cleverapps.wysiwyg.googleDriveProvider.getSpinePath(this.properties.spine);
        cleverapps.RestClient.post(
            "/wysiwyg/packSpines",
            [this.properties.spine],
            function () {
                cc.loader.load([spinePath + ".json", spinePath + ".png", spinePath + ".atlas"], function () {
                    var spine = new cleverapps.Spine(spinePath + ".json", 1, spinePath + ".atlas");
                    spine.setAnimation(0, this.properties.spine.animation, false);
                    if (this.properties.spine.idle) {
                        var loop = this.properties.spine.looped && this.properties.duration;
                        spine.addAnimation(0, this.properties.spine.idle, loop);
                    }
                    this.setContentSize2(spine.getContentSize());

                    this.addChild(spine);
                    spine.setPositionRound(this.width / 2, this.height / 2);

                    if (this.properties.sound && this.properties.sound.sound !== Wysiwyg.NOT_SELECTED) {
                        cleverapps.audio.playSound(cleverapps.wysiwyg.googleDriveProvider.getSoundLink(this.properties.sound));
                    }

                    var trigger = function () {
                        if (this.properties.isPreview) {
                            spine.setVisible(false);
                        }

                        var generatesEvents = this.properties.generatesEvents || [];
                        generatesEvents.forEach(function (event) {
                            this.eventBus.trigger(event);
                        }.bind(this));
                    }.bind(this);

                    if (this.properties.duration) {
                        setTimeout(function () {
                            trigger();
                        }, this.properties.duration);
                    } else {
                        var count = this.properties.spine.idle ? 0 : 1;
                        spine.setCompleteListener(function () {
                            if (count++) {
                                trigger();
                            }
                        });
                    }
                }.bind(this));
            }.bind(this),
            function () {
                cleverapps.notification.create("Pack spine " + this.properties.spine.name + " failed. Try to reload page to pack again.");
            }.bind(this),
            {
                timeout: 30000
            }
        );
    }
});

AdsTransition.PROPERTIES = [{
    name: "generatesEvents", type: "string", items: Object.values(Wysiwyg.EVENTS), multiselect: true, value: []
}, {
    name: "listensEvent", type: "string", items: Object.values(Wysiwyg.EVENTS).concat([Wysiwyg.NOT_SELECTED]), value: Wysiwyg.NOT_SELECTED
}, {
    name: "delayAfterListenedEvent", type: "number"
}, {
    name: "delay", type: "number", disabled: true
}, {
    name: "duration", type: "number", placeHolderText: "milliseconds"
}, {
    name: "spine",
    type: "spine",
    folders: ["transitions"],
    bubbleUp: true
}, {
    name: "sound", type: "sound", folders: ["sounds"]
}];