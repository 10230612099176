/**
 * Created by vladislav on 2/22/2024
 */

var PaymentsLogic = function () {
    connector.payments.on("productsLoaded", this.onProductsLoaded.bind(this));
    connector.payments.on("restore", this.onRestore.bind(this));
    connector.payments.on("changeStatus", this.updateFlags.bind(this));
    connector.payments.on("error:purchase", this.onPurchaseError.bind(this));
    connector.payments.on("error:unknownProducts", this.onUnknownProductsError.bind(this));
    connector.payments.on("error:unknownSubscriptions", this.onUnknownSubscriptionsError.bind(this));

    PaymentOverlay.initialize();
    connector.payments.on("pluginChanged", function () {
        if (this.purchaseToRestore) {
            delete this.purchaseToRestore;
        }

        PaymentOverlay.initialize();
    }.bind(this));

    cc.eventManager.addCustomListener(cc.game.EVENT_SHOW, function () {
        connector.payments.loadProductsThrottled();
    });
};

PaymentsLogic.prototype.updateFlags = function () {
    cleverapps.whenAllInitialized(function () {
        cleverapps.flags.update();
    });
};

PaymentsLogic.prototype.onProductsLoaded = function () {
    connector.payments.products.forEach(function (product) {
        if (product.currencyImage) {
            Product.RegisterCurrencyImage(product.currency, product.currencyImage);
        }
    });
};

PaymentsLogic.prototype.onChangeStatus = function (callback, target) {
    var listener = connector.payments.on("changeStatus", callback);

    if (target) {
        addCleaner(target, listener.clear);
    }
};

PaymentsLogic.prototype.purchase = function (product, callback, options) {
    if (this.purchasePlayer && this.purchasePlayer.isRunning()) {
        cleverapps.notification.create("PurchaseError.purchase_already_open");
        callback(false);
        return;
    }

    var purchase;
    var email;
    this.purchasePlayer = new ActionPlayer([
        function (f, stop) {
            if (connector.payments.isLoginRequired) {
                cleverapps.SocialManager.enforceLogin(function (code) {
                    if (code === cleverapps.CODE_SUCCEED) {
                        f();
                    } else {
                        stop("login_required");
                    }
                }, {
                    strict: true
                });
            } else {
                f();
            }
        },

        function (f, stop) {
            if (cleverapps.synchronizer._syncIn.needShowReloadWindow()) {
                stop();
            } else {
                f();
            }
        },

        function (f, stop) {
            if (connector.payments.isEmailRequired) {
                this.getEmail(function (receivedEmail) {
                    if (receivedEmail) {
                        email = receivedEmail;

                        f();
                    } else {
                        stop("email_required");
                    }
                });
            } else {
                f();
            }
        }.bind(this),

        function (f) {
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.PRODUCT.OPEN);

            this.createOverlay();

            f();
        }.bind(this),

        function (f, stop) {
            connector.payments.purchaseCallback({
                id: product.itemId,
                context: product.context,
                email: email
            }, function (result) {
                if (result) {
                    purchase = result.purchase;

                    f();
                } else {
                    stop();
                }
            });
        }
    ]);

    this.purchasePlayer.onComplete(function () {
        this.removeOverlay();
    }.bind(this));

    this.purchasePlayer.onFailure(function (code) {
        console.log("PaymentsManager purchase failure", product.productId, product.itemId);

        if (code) {
            this.onPurchaseError(code);
        }

        callback(false);
    }.bind(this));

    this.purchasePlayer.onSuccess(function () {
        console.log("PaymentsManager purchase success", product.productId, product.itemId);

        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.PRODUCT.SUCCESS);

        this.logPurchase(product, purchase);

        product.onBuyed(options);

        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.PRODUCT.CONSUME_START);

        cleverapps.conversionManager.processConversion(cleverapps.EVENTS.CONVERSIONS.PAYMENTS, product.priceUSD);

        connector.payments.consumeCallback(purchase, function (result) {
            cleverapps.eventLogger.logEvent(result
                ? cleverapps.EVENTS.STATS.PRODUCT.CONSUME_SUCCESS
                : cleverapps.EVENTS.STATS.PRODUCT.CONSUME_FAILURE);
        });

        callback(true);
    }.bind(this));

    this.purchasePlayer.play();
};

PaymentsLogic.prototype.onPurchaseError = function (code) {
    if (code === "purchase_canceled_by_user") {
        return;
    }

    if (code === "invalid_email") {
        cleverapps.info.setKeyValue("email", "");
    }

    cleverapps.notification.create("PurchaseError." + code);
};

PaymentsLogic.prototype.onUnknownProductsError = function (products) {
    if (new Date(cleverapps.config.buildTime).getTime() + PaymentsLogic.UNKNOWN_PRODUCTS_ERRORS_PERIOD > Date.now() && !this.unkonwnProductsLogged) {
        this.unkonwnProductsLogged = true;

        cleverapps.throwAsync("platform missed some products " + products);
    } else {
        console.error("platform missed some products " + products);
    }
};

PaymentsLogic.prototype.onUnknownSubscriptionsError = function (products) {
    if (new Date(cleverapps.config.buildTime).getTime() + PaymentsLogic.UNKNOWN_PRODUCTS_ERRORS_PERIOD > Date.now() && !this.unkonwnSubscriptionsLogged) {
        this.unkonwnSubscriptionsLogged = true;

        cleverapps.throwAsync("platform missed some products " + products);
    } else {
        console.error("platform missed some products " + products);
    }
};

PaymentsLogic.prototype.getEmail = function (callback) {
    var email = cleverapps.info.getValue("email");

    if (email && cleverapps.validateEmail(email)) {
        callback(email);
    } else {
        new EmailWindow(callback);
    }
};

PaymentsLogic.prototype.createOverlay = function () {
    cleverapps.meta.display({
        stack: true,
        focus: "PaymentOverlay",
        action: function (f) {
            this.overlay = new PaymentOverlay();
            cleverapps.scenes.getRunningScene().addChild(this.overlay);

            this.oncePaymentOverlayClosed = f;
        }.bind(this)
    });
};

PaymentsLogic.prototype.removeOverlay = function () {
    if (this.overlay) {
        this.overlay.removeFromParent();
        this.overlay = undefined;
    }

    if (this.oncePaymentOverlayClosed) {
        this.oncePaymentOverlayClosed();
        this.oncePaymentOverlayClosed = undefined;
    }
};

PaymentsLogic.prototype.onRestore = function (purchase) {
    this.purchaseToRestore = purchase;

    if (!cleverapps.meta.isFocused()) {
        this.showRestore();
    }
};

PaymentsLogic.prototype.consumeRestored = function () {
    var purchase = this.purchaseToRestore;
    if (!purchase) {
        return;
    }

    cleverapps.user.incProgressCompare(50);

    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.PRODUCT.CONSUME_START);

    delete this.purchaseToRestore;
    connector.payments.consumeCallback(purchase, function (code) {
        cleverapps.eventLogger.logEvent(code === cleverapps.CODE_SUCCEED
            ? cleverapps.EVENTS.STATS.PRODUCT.CONSUME_SUCCESS
            : cleverapps.EVENTS.STATS.PRODUCT.CONSUME_FAILURE);
    });
};

PaymentsLogic.prototype.showRestore = function () {
    var purchase = this.purchaseToRestore;
    if (!purchase) {
        return;
    }

    var data = purchase.productId && connector.payments.getProduct(purchase.productId);
    if (!data) {
        return;
    }

    if (!cleverapps.environment.isMainScene() || data.mainSceneOnly && cleverapps.travelBook.isExpedition()) {
        return;
    }

    var product = Product.CreateById(data.itemId);
    var context = purchase.context || {};

    var RestoreLogic = product.getRestoreLogic(context);

    var currentPage = cleverapps.travelBook.getCurrentPage();

    var expeditionId = RestoreLogic && RestoreLogic.RestoreExpeditionId && RestoreLogic.RestoreExpeditionId(context);
    var targetPage = cleverapps.travelBook.getPageById(expeditionId === undefined ? currentPage.id : expeditionId);

    if (targetPage && targetPage !== currentPage) {
        return;
    }

    var actions;
    if (targetPage && targetPage.isActive() && !targetPage.isCompleted() && RestoreLogic && RestoreLogic.RestoreActions) {
        actions = RestoreLogic.RestoreActions(context, function () {
            this.consumeRestored();

            this.logPurchase(product, purchase);
        }.bind(this));
    }

    cleverapps.meta.display({
        focus: "restorePurchase",
        action: function (f) {
            if (actions) {
                new ActionPlayer(actions).play(f);
            } else {
                this.consumeRestored();

                this.logPurchase(product, purchase);

                var reward = product.restoreReward || product.reward;
                if (reward) {
                    RewardWindow.createPurchaseWindow(reward);
                    cleverapps.meta.onceNoWindowsListener = f;
                } else {
                    f();
                }
            }
        }.bind(this)
    });
};

PaymentsLogic.prototype.logPurchase = function (product, purchase) {
    var info = {
        itemId: product.itemId,
        price: this.calcRealUSDPrice(product),
        textPrice: purchase.price + " " + purchase.currency,
        paymentId: purchase.paymentId
    };

    if (product.type === "subscription") {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.SUBSCRIPTION_BOUGHT, info);
        return;
    }

    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.PAYMENTS, info);

    var country = cleverapps.country.getCountry();
    var platform = connector.info.platform;

    var params = {
        value: info.price
    };

    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.PAYMENTS_COUNTRY + country, params);

    if (platform) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.PAYMENTS_PLATFORM + platform, params);

        if (["US"].includes(country)) {
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.PAYMENTS_COUNTRY_PLATFORM + country + "_" + platform, params);
        }
    }

    if (product.event) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.BUY + product.itemId + "-" + product.event, params);
    }

    if (connector.payments.oneOf(connector.YOOKASSA)) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.YOOKASSA.PAID_COUNT);
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.YOOKASSA.PAID_AMOUNT, params);
    }

    cleverapps.abTest.allLogEvent(cleverapps.EVENTS.STATS.PAYMENTS, params);

    if (cleverapps.travelBook.isExpedition()) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.EXPEDITIONS.SPEND + cleverapps.travelBook.getCurrentPage().id, {
            value: info.price * 100
        });

        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.EXPEDITIONS.TOTAL + cleverapps.travelBook.getCurrentPage().id, params);
    }

    if (Game.currentGame && Game.currentGame.level && Game.currentGame.level.isBonusWorldLevel() || cleverapps.environment.isBonusWorldScene()) {
        var mission = cleverapps.missionManager.findByType(Mission.TYPE_BONUS_WORLD);
        if (mission) {
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.BONUS_WORLD_PAYMENTS + mission.bonusWorld.type, params);
        }
    }

    cleverapps.paymentsHistory.addPayment(product);

    var price = purchase.price;
    var currency = purchase.currency;
    if (connector.payments.isVirtualCurrency()) {
        price = info.price;
        currency = "USD";
    }

    cleverapps.eventLogger.logPurchase(product, purchase, {
        transactionId: purchase.paymentId,
        price: price,
        currency: currency
    });
};

PaymentsLogic.prototype.calcRealUSDPrice = function (product) {
    var coef = this.getRealPriceCoef(product);

    if (coef === 1) {
        return product.priceUSD;
    }

    return Math.round(product.priceUSD * coef * 100) / 100;
};

PaymentsLogic.prototype.getRealPriceCoef = function (product) {
    var VIRTUAL_CURRENCY_USD_COEF = {
        OK: 20 / connector.EXCHANGE_RATES.RUB,
        VK_GOLOS: 21 / connector.EXCHANGE_RATES.RUB,
        MLK: 20 / connector.EXCHANGE_RATES.RUB,
        FM: 30 / connector.EXCHANGE_RATES.RUB,
        YAN: 20 / connector.EXCHANGE_RATES.RUB
    };

    if (connector.payments.isVirtualCurrency()) {
        return VIRTUAL_CURRENCY_USD_COEF[product.currency] || 1;
    }

    if (!connector.EXCHANGE_RATES[product.currency]) {
        return 1;
    }

    var currentRate = connector.payments.getExchangeRate();

    return currentRate / connector.EXCHANGE_RATES[product.currency];
};

PaymentsLogic.UNKNOWN_PRODUCTS_ERRORS_PERIOD = cleverapps.parseInterval("3 month");
