/**
 * Created by olga on 21.05.2024
 */

var PuzzleStickerView = cc.Node.extend({
    ctor: function (sticker, options) {
        options = options || {};
        this._super();

        this.sticker = sticker;
        this.placeholder = options.placeholder;

        this.image = new cc.Sprite(sticker.image);
        this.addChild(this.image);

        this.setAnchorPoint(0.5, 0);

        this.setContentSize2(cleverapps.styles.PuzzlesComponent.placeholder);

        this.image.setPositionRound(cleverapps.styles.PuzzleStickerView.position);

        this.setCascadeOpacityEnabledRecursively(true);

        if (this.placeholder) {
            this.addShadow();
        }

        this.update();

        if (options.withUpdate) {
            sticker.on("update", this.createListener(this.update.bind(this)));
        }
    },

    update: function () {
        this.image.setVisible(this.sticker.isOpen());
        this.shadow && this.shadow.setVisible(this.sticker.isOpen());
    },

    highlight: function () {},

    addShadow: function () {
        var shadow = this.shadow = new cleverapps.Spine(bundles.stickers_collection_window.jsons.puzzle_shadow_json);

        this.addChild(shadow);
        shadow.setSkin(this.sticker.getIndex());
        shadow.setPositionRound(cleverapps.styles.PuzzleStickerView.shadow);
        shadow.setLocalZOrder(-1);

        shadow.setAnimation(0, "idle_close", true);
    },

    calculateViewPosition: function () {
        return this.convertToWorldSpace(this.image.getPosition());
    },

    animateDragStart: function () {
        cleverapps.audio.playSound(bundles.stickers_collection_window.urls.puzzle_hand_effect);

        var dragOffset = cleverapps.styles.PuzzleStickerView.dragOffset;
        this.image.runAction(new cc.MoveBy(0.1, dragOffset.x, dragOffset.y).easing(cc.easeExponentialOut(2)));
        this.runAction(new cc.ScaleTo(0.1, 1).easing(cc.easeIn(2)));

        this.shadow.setAnimationAndIdleAfter("open", "idle_open");
    },

    animateEndDrag: function (options) {
        var actions = [];
        this.stopAllActions();

        if (options.animateWrong) {
            actions = actions.concat([
                new cc.CallFunc(function () {
                    this.animatePuzzle(false);
                    cleverapps.audio.playSound(bundles.stickers_collection_window.urls.puzzle_wrong_effect);
                }.bind(this)),
                new cc.RotateBy(0.1, 10),
                new cc.RotateBy(0.2, -20),
                new cc.RotateBy(0.1, 10)
            ]);
        }

        this.shadow.setAnimationAndIdleAfter("close", "idle_close");

        var slotScale = this.slot.getScale();
        var dragOffset = cleverapps.styles.PuzzleStickerView.dragOffset;
        actions = actions.concat([
            new cc.Spawn(
                new cc.ScaleTo(0.1, slotScale),
                new cc.MoveTo(0.1, options.targetPos.x, options.targetPos.y).easing(cc.easeOut(1)),
                new cc.TargetedAction(this.image, new cc.MoveBy(0.1, dragOffset.x, -dragOffset.y))
            ),
            new cc.ScaleTo(0.1, slotScale - 0.1).easing(cc.easeOut(1)),
            new cc.ScaleTo(0.1, slotScale).easing(cc.easeIn(1)),

            new cc.CallFunc(function () {
                cleverapps.audio.playSound(bundles.stickers_collection_window.urls.puzzle_sleeve_effect);
                options.callback();
            })
          
        ]);

        this.runAction(new cc.Sequence(actions));
    },

    animatePutUp: function () {
        this.stopAllActions();
        this.image.stopAllActions();

        this.image.setPositionRound(cleverapps.styles.PuzzleStickerView.position);

        this.animatePuzzle(true);
        cleverapps.audio.playSound(bundles.stickers_collection_window.urls.puzzle_put_effect);

        this.shadow.setAnimationAndIdleAfter("close", "idle_close");
    },

    animatePuzzle: function (isCorrect) {
        var spineName = isCorrect ? "correct_puzzle_json" : "wrong_puzzle_json";
        var animation = new cleverapps.Spine(bundles.stickers_collection_window.jsons[spineName]);
        this.addChild(animation);
        animation.setLocalZOrder(isCorrect ? -1 : 10);
        animation.setPositionRound(this.width / 2, this.height / 2);

        animation.setAnimation(0, "animation", false);
        animation.setCompleteListenerRemove();
    },

    createBadge: function (bundleName) {
        var styles = cleverapps.styles.StickerView.badge;

        var badge = this.badge = new cc.Sprite(bundles[bundleName].frames.badge_png);
        badge.setLocalZOrder(10);

        var text = cleverapps.UI.generateOnlyText("Attention.Text", cleverapps.styles.FONTS.COLLECTION_BADGE_TEXT);
        badge.addChild(text);
        text.setPositionRound(styles.text);
        text.fitTo(styles.text.maxWidth);
        text.setRotation(styles.text.rotation);

        this.addChild(badge);
        badge.setPositionRound(styles);
    }
    
});

cleverapps.styles.PuzzleStickerView = {
    shadow: {
        x: { align: "center", dx: 3 },
        y: { align: "center", dy: -11 }
    },
    dragOffset: {
        x: 0,
        y: 60
    },
    position: {
        x: { align: "center" },
        y: { align: "center" }
    }
};
