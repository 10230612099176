/**
 * Created by slava on 02.02.17.
 */

var DonutBoxCellView = BaseCellView.extend({
    ctor: function (donutBox) {
        this._super();

        this.setContentSize(cleverapps.styles.BaseCellView.CELL_SIZE_PX, cleverapps.styles.BaseCellView.CELL_SIZE_PX);
        this.setAnchorPoint(0.5, 0.5);
        var image = new cc.Sprite(this.getImage(donutBox.lives));
        this.image = image;
        this.image.setPosition(this.width / 2, this.height / 2);
        this.addChild(image);

        this.animation = new cleverapps.Spine(bundles.donut_box.jsons.donutBox_spine_json);
        this.animation.setPosition(this.width / 2, this.height / 2);
        this.animation.setStartVisibleListener(function () {
            this.image.visible = false;
        }.bind(this));
        this.addChild(this.animation);

        this.initialize(donutBox);
    },

    getLivesId: function (lives) {
        return 4 - lives;
    },

    getImage: function (lives) {
        return bundles.donut_box.frames["donutBox_" + this.getLivesId(lives)];
    },

    initialize: function (donutBox) {
        this._super(donutBox);

        this.image.setVisible(true);
        this.animation.setVisible(false);

        donutBox.onChangeLivesListener = this.changeLives.bind(this);
    },

    changeLives: function () {
        this.image.setSpriteFrame(this.getImage(this.cell.lives));
        var curLives = this.getLivesId(this.cell.lives);
        if (curLives > 0) {
            this.animation.setAnimation(0, (curLives - 1) + "_" + curLives, true);
            this.animation.setCompleteListener(function () {
                if (curLives === 4) {
                    this.animation.setCompleteListener();
                    this.animation.setAnimation(0, "idle_4", true);
                } else {
                    this.image.setVisible(true);
                    this.animation.setVisible(false);
                }
            }.bind(this));
        } else {
            this.image.setVisible(true);
            this.animation.setVisible(false);
        }
        cleverapps.audio.playSound(bundles.donut_box.urls.donutBox_maker_fill_effect);
    },

    unuse: function () {
        this.cell.onChangeLivesListener = function () {};

        this._super();
    },

    openAnimation: function () {
        var realZOrder = this.getLocalZOrder();
        this.setLocalZOrder(2);
        this.animation.setCompleteListener();
        this.animation.setAnimation(0, "open", false);

        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.1),
            new cc.CallFunc(function () {
                cleverapps.audio.playSound(bundles.donut_box.urls.donutBox_open_effect || bundles.game.urls.goal_completed_effect);
            })
        ));

        this.runAction(new cc.Sequence(
            new cc.DelayTime(1.7),
            new cc.CallFunc(function () {
                this.setLocalZOrder(realZOrder);
                this.image.setVisible(true);
                this.animation.setVisible(false);
            }.bind(this))
        ));
    },

    hurtAnimation: function () {
    }
});