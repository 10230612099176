/**
 * Created by ivan on 24.04.18.
 */

var DirtMakerCell = function (x, y) {
    BaseCell.call(this, x, y);
    this.movable = false;
    this.hurtable = false;
    this.stage = 0;
    Game.currentGame.counter.registerStage(207, DirtMakerCell.process);

    this.onChangeStage = function () {};
    this.onShoot = function () {};
};

DirtMakerCell.prototype = Object.create(BaseCell.prototype);
DirtMakerCell.prototype.constructor = DirtMakerCell;

DirtMakerCell.prototype.load = function (data) {
    this.stage = parseInt(data[1]);
};

DirtMakerCell.prototype.bundleId = function () {
    return ["dirt_maker"];
};

DirtMakerCell.prototype.save = function () {
    return DirtMakerCell.CODES.concat([this.stage]);
};

DirtMakerCell.prototype.getViewClass = function () {
    return DirtMakerCellView;
};

DirtMakerCell.prototype.makeDirt = function () {
    var variants = [], i, j;
    for (i = 0; i < Field.SIZE; i++) {
        for (j = 0; j < Field.SIZE; j++) {
            var cell = Game.currentGame.field.cells[i][j];
            if (cell && DirtMakerCell.changedCells.indexOf(cell) === -1
                && (cell.constructor === BaseCell && (cell.findComponent(ColorComponent) || cell.findComponent(GoalCoefComponent))
                    || cell.constructor === HeroCell || cell.constructor === WaffleCell || cell.constructor === LollipopCell)) {
                variants.push(cell);
            }
        }
    }

    if (variants.length > 0) {
        var vid = cleverapps.Random.random(variants.length);
        var selectedCell = variants[vid];
        DirtMakerCell.changedCells.push(selectedCell);
        var x = selectedCell.x;
        var y = selectedCell.y;
        this.stage = 0;
        this.onShoot(x, y);
        Game.currentGame.counter.setTimeout(function () {
            var dirtCell = new DirtDecoratorCell(x, y, { components: [DecoratorComponent] });
            dirtCell.setInnerCell(selectedCell);
            dirtCell.onCreate = true;
            Game.currentGame.field.addCell(dirtCell);
            dirtCell.onCreateNew("up");
            Game.currentGame.goals.incTarget(DirtDecoratorCell.GOAL_ID, 1);
        }, this.getMakeDirtDuration());
    }
};

DirtMakerCell.prototype.getMakeDirtDuration = function () {
    return 1950;
};

DirtMakerCell.prototype.boom = function () {
    this.skipProcess = true;
};

DirtMakerCell.prototype.process = function () {
    if (this.stage + 1 < DirtMakerCell.STAGES_COUNT) {
        this.stage++;
        this.onChangeStage();
    } else {
        this.makeDirt();
    }
};

DirtMakerCell.process = function () {
    if (!Game.currentGame.dirtMakerCellCanProcess) {
        return;
    }

    Game.currentGame.dirtDecoratorCellCanProcess = false;
    Game.currentGame.dirtMakerCellCanProcess = false;
    DirtMakerCell.changedCells = [];
    for (var i = 0; i < Field.SIZE; i++) {
        for (var j = 0; j < Field.SIZE; j++) {
            if (Game.currentGame.field.cells[i][j] && Game.currentGame.field.cells[i][j].constructor === DirtMakerCell) {
                if (Game.currentGame.field.cells[i][j].skipProcess) {
                    Game.currentGame.field.cells[i][j].skipProcess = false;
                } else {
                    Game.currentGame.field.cells[i][j].process();
                }
            }
        }
    }
};

DirtMakerCell.STAGES_COUNT = 4;