/**
 * Created by spepa on 03.02.2023
 */

var TileHandler = {
    listItems: function () {
        return Array.from(Game.currentGame.table.selected.set);
    },

    serialize: function (card) {
        var data = card.toJSON();
        data.x += EditorCardTable.COPY_MOVE_BY.x;
        data.y += EditorCardTable.COPY_MOVE_BY.y;
        return data;
    },

    deserialize: function (data, ind) {
        var command = Game.currentGame.table.createNewCardCommand(data);
        return {
            undo: command.undo,
            redo: function () {
                if (ind === 0) {
                    Game.currentGame.table.clearSelection();
                }
                var card = command.redo();
                Game.currentGame.table.select(card);
            }
        };
    }
};