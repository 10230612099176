/**
 * Created by spepa on 15.04.21.
 */

var Skinify = function (classObject, baseSkin) {
    classObject.prototype.bundleId = function () {
        return baseSkin + this.getSkinSuffix();
    };

    classObject.prototype.getSkinBundles = function () {
        return [baseSkin, baseSkin + "_reskin", baseSkin + "_xmas_reskin"].filter(function (bundle) {
            return bundles[bundle];
        });
    };

    classObject.prototype.getSkinSuffix = function () {
        var skinSuffix = "";
        if (Game.currentGame && Game.currentGame.levelContent.cellSkins) {
            var levelCellSkin = Game.currentGame.levelContent.cellSkins[classObject.CODES[0]];

            if (this.getSkinBundles().indexOf(levelCellSkin) !== -1) {
                skinSuffix = levelCellSkin.replace(baseSkin, "");
            }
        }

        return skinSuffix;
    };
};