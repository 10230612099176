/**
 * Created by Andrey Popov on 06/09/21.
 */

var ScriptComponent = function (fileName, options) {
    this.fileName = fileName;
    Object.assign(this, options);

    cleverapps.EventEmitter.call(this);
};

ScriptComponent.prototype = Object.create(cleverapps.EventEmitter.prototype);
ScriptComponent.prototype.constructor = ScriptComponent;

ScriptComponent.prototype.moveToScene = function (parentId) {
    this.trigger("scriptMovedToScene", parentId);
};