/**
 * Created by decipaliz on 04.07.2025
 */

GameBase.ActionsBefore = {
    combo: function (options) {
        var amount = options.amount;
        var timeouts = [0, 120, 250, 360, 500, 620, 750, 860, 1000, 1100].slice(0, amount);

        var combosCreated = Game.currentGame.field.addRandomCombo(amount, {
            actionBefore: true,
            timeouts: timeouts,
            fromLantern: options.lantern
        });
        if (combosCreated < amount) {
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.BOOSTER_COMBO_NO_PLACE, { value: (amount - combosCreated) });
        }
        return combosCreated > 0;
    },

    moves: function (options) {
        var amount = options.amount;
        if (Game.currentGame.moves === -1) {
            return false;
        }

        Game.currentGame.setMoves(Game.currentGame.moves + amount, {
            animate: true,
            actionBefore: true,
            fromLantern: options.lantern
        });
        Game.currentGame.beginMoves += amount;

        return true;
    },

    multicolor: function (options) {
        var amount = options.amount;
        var multicolorsCreated = Game.currentGame.field.addRandomMulticolor(amount, {
            actionBefore: true,
            fromLantern: options.lantern
        });
        if (multicolorsCreated < amount) {
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.BOOSTER_MULTICOLOR_NO_PLACE, { value: amount - multicolorsCreated });
        }
        return multicolorsCreated > 0;
    },

    lantern: function (options) {
        Game.currentGame.field.showLantern(options.lanternStreak);
    },

    magnet: function () {
        var targetCards = cleverapps.Random.shuffle(Game.currentGame.table.cardsThatCanBeReplacedWithBoosters());

        if (targetCards.length === 0) {
            return false;
        }

        var priorityCards = targetCards.filter(function (card) {
            return card.isOpen();
        });

        var targetCard = priorityCards.length > 0 ? priorityCards[0] : targetCards[0];
        var options = Object.assign(targetCard.toJSON(), {
            marks: ["magnet"]
        });

        var boosterCard = TileFactory.Create(options);
        boosterCard.setOwner(targetCard.owner);

        Game.currentGame.table.replaceCard(targetCard, boosterCard);
        Game.currentGame.table.trigger("replaceCardWithAnother", targetCard, boosterCard);

        if (targetCard.isOpen()) {
            boosterCard.flip();
        }

        var mark = boosterCard.marks[0];
        mark.hide();
        Game.currentGame.table.trigger("demonstrateBoosterBefore", boosterCard, function () {
            mark.show(true);
        });

        return true;
    },

    dice: function () {
        Game.currentGame.dice.roll();
        Game.currentGame.dice.reward();
        return true;
    },

    jokers: function () {
        var insertJoker = function (targetCard, isLeft) {
            if (!targetCard) {
                return;
            }

            var boosterCard = TileFactory.Create({
                feature: "wild",
                x: targetCard.x,
                y: targetCard.y,
                rotation: targetCard.getRotation()
            });
            boosterCard.setOwner(targetCard.owner);

            Game.currentGame.table.insertBeforeCard(targetCard, boosterCard);
            Game.currentGame.table.trigger("insertCard", boosterCard, isLeft ? -2 * cleverapps.styles.CardView.width : 2 * cleverapps.styles.CardView.width);
        };

        var candidates = Game.currentGame.table.cardsWithPossibilityOfPlacingUnderThem();
        insertJoker(candidates.filter(function (card) {
            return card.x < 0;
        }).pop(), true);

        insertJoker(candidates.filter(function (card) {
            return card.x >= 0;
        }).pop(), false);

        return true;
    },

    highlight: function () {
        Game.currentGame.currentHighlighter.enable();
        return true;
    },

    eye: function (options) {
        options.source.trigger("giveBonus");
        return true;
    },

    magnifier: function (options) {
        Game.currentGame.keypad.addMagnifiers(options.amount);
        Game.currentGame.keypad.magnifiersShowUp();
        return true;
    }
};