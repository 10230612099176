/**
 * Created by Andrey Popov on 1/25/21.
 */

var SceneComponent = function (options) {
    Object.assign(this, options);

    if (!SceneComponent.ID) {
        SceneComponent.ID = 0;
    }
    if (options.id && options.id >= SceneComponent.ID) {
        SceneComponent.ID = options.id + 1;
    }

    this.id = options.id || SceneComponent.ID++;

    this.onGetView = function () {};
};

SceneComponent.prototype.onComponentBoxChanged = function (boundingBox) {
    window.parent.postMessage(JSON.stringify({
        type: "componentBoxChanged",
        id: this.id,
        boundingBox: boundingBox
    }));
};