/**
 * Created by Andrey Popov on 07.06.24.
 */

var OfferTileModel = function (options) {
    this.offer = options.offer;
    this.shopIcon = options.shopIcon;
    this.title = options.title;
    this.reward = options.reward;

    TileModel.call(this, options.product);
};

OfferTileModel.prototype = Object.create(TileModel.prototype);
OfferTileModel.prototype.constructor = OfferTileModel;

OfferTileModel.prototype.getViewClassName = function () {
    return OfferProductTile;
};

OfferTileModel.prototype.getIcon = function () {
    return bundles.tile_shop.frames[this.shopIcon];
};

OfferTileModel.prototype.getTitle = function () {
    return this.title;
};

OfferTileModel.prototype.getReward = function () {
    return this.reward;
};

OfferTileModel.prototype.wantsScroll = function () {
    return true;
};

OfferTileModel.prototype.buy = function (shopCallback) {
    return this.product.buy(function (success) {
        if (success) {
            this.product.logBuyItem();

            cleverapps.eventLogger.logEvent(this.offer.name + "_" + cleverapps.EVENTS.OFFER_TILE_PURCHASE);

            new RewardWindow(this.getReward(), { event: cleverapps.EVENTS.EARN.PURCHASE });

            this.offer.incStage && this.offer.incStage();
            this.offer.complete();

            shopCallback();
        }
    }.bind(this), { noRewardWindow: true });
};
