/**
 * Created by decipaliz on 28.06.2023
 */

var ErrorWindow = CleverappsWindow.extend({
    onWindowLoaded: function (options) {
        this.options = options;

        this._super({
            content: this.createContent(),
            name: "ErrorWindow",
            title: "Error",
            buttons: this.getButtons()
        });
    },

    createContent: function () {
        var styles = cleverapps.styles.ErrorWindow;

        var icon = new cc.Sprite(bundles.dev_buttons.frames.icon_error);

        var errorLog = this.options.debugInfo.split("\n")
            .slice(0, 5)
            .map(function (line) {
                if (line.length > 102) {
                    return line.slice(0, 100) + "...";
                }
                return line;
            })
            .join("\n");
        var text = cleverapps.UI.generateOnlyText(errorLog, cleverapps.styles.FONTS.WINDOW_SMALL_TEXT, false);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_LEFT);
        text.setVerticalAlignment(cc.VERTICAL_TEXT_ALIGNMENT_CENTER);
        text.setWrapWidth(text.maxWidth());

        var textLayout = new cleverapps.Layout([text], { direction: cleverapps.UI.VERTICAL });

        var logScroll = new cleverapps.UI.ScrollView(textLayout, { direction: cleverapps.UI.ScrollView.DIR_HORIZONTAL, scrollBarEnabled: true });
        logScroll.setContentSize(styles.width, styles.height);
        logScroll.scrollToPercent(0);

        return new cleverapps.Layout([icon, logScroll], { direction: cleverapps.UI.VERTICAL });
    },

    getButtons: function () {
        var reportButton = new cleverapps.UI.Button({
            text: "%% Report",
            icons: {
                "%%": bundles.dev_buttons.frames.icon_report
            },
            width: cleverapps.styles.ErrorWindow.button.width,
            height: cleverapps.styles.ErrorWindow.button.height,
            onClicked: function () {
                cleverapps.copyToClipboard(this.options.debugInfo, function () {
                    cleverapps.notification.create("Copied to clipboard", { force: !cleverapps.allInitialized });
                });
            }.bind(this)
        });

        var snapshotButton = new cleverapps.UI.Button({
            text: "%% Snapshot",
            icons: {
                "%%": bundles.dev_buttons.frames.snapshot
            },
            width: cleverapps.styles.ErrorWindow.button.width,
            height: cleverapps.styles.ErrorWindow.button.height,
            onClicked: function () {
                cleverapps.snapshots.createSnapshot(undefined, function (snapshotUrl) {
                    cleverapps.copyToClipboard("snapshot: " + snapshotUrl + "\n" + this.options.debugInfo, function () {
                        cleverapps.notification.create("Copied snapshot & error to clipboard", { force: !cleverapps.allInitialized });
                    });
                }.bind(this));
            }.bind(this)
        });

        return [reportButton, snapshotButton];
    }
});

cleverapps.styles.ErrorWindow = {
    width: 1000,
    height: 560,
    button: {
        width: 280,
        height: 110
    }
};