/**
 * Created by vlad on 26.12.18.
 */

var ColorContainerCellView = BaseCellView.extend({
    ctor: function (containerCell) {
        this._super();

        this.setContentSize(cleverapps.styles.BaseCellView.CELL_SIZE_PX, cleverapps.styles.BaseCellView.CELL_SIZE_PX);
        this.setAnchorPoint(0.5, 0.5);

        var bundle = containerCell.bundleId();
        if (containerCell.isForGoal) {
            this.image = new cc.Sprite(bundles[bundle].frames[bundle + "_goal"]);
            this.addChild(this.image);
            this.image.setPositionRound(this.width / 2, this.height / 2);
        } else {
            this.animation = new cleverapps.Spine(bundles[bundle].jsons[bundle + "_json"]);
            this.addChild(this.animation);
            this.animation.setPositionRound(this.width / 2, this.height / 2);
            if (containerCell.boomColor) {
                this.setColor(containerCell.boomColor);
                this.animation.setAnimation(0, "bottle_" + containerCell.lives, true);
            } else {
                this.animation.setSkin("blue");
                this.animation.setAnimation(0, "bottle_2", true);
            }
        }

        this.initialize(containerCell);
    },

    initialize: function (containerCell) {
        this._super(containerCell);

        containerCell.onChangeLivesListener = this.changeLives.bind(this);
        containerCell.onSetColor = this.setColor.bind(this);
    },

    setColor: function (color) {
        this.animation.setSkin(ColorComponent.CODE_COLORS[color]);
    },

    changeLives: function () {
        this.animation.setAnimation(0, "bottle_" + this.cell.lives + "_up", false);
        this.animation.setCompleteListener(function () {
            this.animation.setAnimation(0, "bottle_" + this.cell.lives, false);
        }.bind(this));

        var bundle = this.cell.bundleId();
        cleverapps.audio.playSound(bundles[bundle].urls[bundle + "_fill_effect"]);
    },

    startExploding: function (goal, idInGroup, params) {
        var bundle = this.cell.bundleId();
        cleverapps.audio.playSound(bundles[bundle].urls[bundle + "_collect_effect"]);
        this.startExplodingViaCollect(goal, idInGroup, params);
    }
});