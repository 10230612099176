/**
 * Created by Andrey Popov on 27.01.2021
 */

var AdsSprite = cc.Node.extend({
    ctor: function (properties) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize(100, 100);

        properties = properties || {};

        if (cleverapps.wysiwyg.googleDriveProvider.hasAsset(properties.image)) {
            var imagePath = cleverapps.wysiwyg.googleDriveProvider.getLink(properties.image);
            cc.loader.load(imagePath, function () {
                var sprite = new cc.Sprite(imagePath);

                var scale = properties.scale || 1;
                sprite.setScale(scale);
                this.addChild(sprite);

                this.setContentSize(sprite.width * scale, sprite.height * scale);
                sprite.setPositionRound(this.width / 2, this.height / 2);

                if (properties.flipX) {
                    sprite.setScaleX(-1 * scale);
                }
                if (properties.flipY) {
                    sprite.setScaleY(-1 * scale);
                }

                properties.rotation && sprite.setRotation(properties.rotation);

                this.parent && this.parent.onComponentSizeChanged();
            }.bind(this));
        }
    }
});

AdsSprite.PROPERTIES = [{
    name: "image", type: "image", folders: ["sprites"], value: { folder: "sprites", name: "arrow.png" }, bubbleUp: true
}, {
    name: "flipX", type: "checkBox"
}, {
    name: "flipY", type: "checkBox"
}];