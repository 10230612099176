/**
 * Created by vladislav on 31.01.2022
 */

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
});

cleverapps.overrideStyles(cleverapps.styles.HeroCellView, {
    animation: {
        overshoot: false
    }
});

cleverapps.overrideStyles(cleverapps.styles.BombCellView, {
    timer: {
        y: -26
    }
});

cleverapps.styles.GingerHouseCellView.imageOffsets[GingerHousePartCell.TYPES.WALL] = { x: 0, y: -47 };
cleverapps.styles.GingerHouseCellView.imageOffsets[GingerHousePartCell.TYPES.DOOR] = { x: -42, y: -63 };
cleverapps.styles.GingerHouseCellView.imageOffsets[GingerHousePartCell.TYPES.WINDOW] = { x: 48, y: -56 };
cleverapps.styles.GingerHouseCellView.imageOffsets[GingerHousePartCell.TYPES.ROOF] = { x: 0, y: 47 };

cleverapps.overrideStyles(cleverapps.styles.FireworkCellView, {
    bomb: {
        startDY: 40
    }
});

cleverapps.overrideStyles(cleverapps.styles.FieldBoosterView, {
    amount: {
        x: -32,
        y: 26
    },

    background: [
        {
            scale9: cleverapps.UI.Scale9Rect.TwoPixelXY,
            width: 150,
            height: 150
        },
        {
            scale9: cleverapps.UI.Scale9Rect.TwoPixelXY,
            width: 150,
            height: 150
        },
        {
            scale9: cleverapps.UI.Scale9Rect.TwoPixelXY,
            width: 150,
            height: 150
        }
    ],
    locked: {
        x: { align: "center" },
        y: { align: "center" }
    }
});