/**
 * Created by spepa on 20.06.2024
 */

var ChooseSaveWindow = CleverappsWindow.extend({
    ctor: function () {
        if (cleverapps.environment.isSceneWithPreview()) {
            return;
        }
        this._super();
        this.setLocalZOrder(Transition.ZORDER + 1);
    },

    onWindowLoaded: function () {
        this._super({
            content: this.createContent(),
            name: "ChooseSaveWindow",
            title: "ChooseSaveWindow.Title"
            // closeButton: false
        });
    },

    getPerson: function () {
        return "seller";
    },

    isServerBetter: function (local, server) {
        var localLvl = cleverapps.humanReadableNumber(local.episode, local.level);
        var serverLvl = cleverapps.humanReadableNumber(server.episode, server.level);
        return serverLvl >= localLvl;
    },

    createContent: function () {
        var styles = cleverapps.styles.ChooseSaveWindow;

        var text = this.text = cleverapps.UI.generateOnlyText("ChooseSaveWindow.text", cleverapps.styles.FONTS.SAVE_WINDOW_TEXT);
        text.setDimensions(styles.textWidth, 0);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);

        var localData = Object.assign({ }, ChooseSaveWindow.DATA.local);
        var serverData = Object.assign({ server: true }, ChooseSaveWindow.DATA.server);

        var betterLocal = [];
        var betterServer = [];
        Object.keys(localData).forEach(function (key) {
            if (localData[key] > serverData[key]) {
                betterLocal.push(key);
            } else if (localData[key] < serverData[key]) {
                betterServer.push(key);
            }
        });

        localData.better = betterLocal;
        serverData.better = betterServer;

        if (this.isServerBetter(localData, serverData)) {
            serverData.badge = true;
        } else {
            localData.badge = true;
        }

        var local = this.localTile = this.createTile(localData);
        var server = this.serverTile = this.createTile(serverData);
        var tiles = new cleverapps.Layout([local, server], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.tilesMargin,
            padding: { x: styles.paddingX }
        });

        return new cleverapps.Layout([tiles, text], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });
    },

    createTile: function (data) {
        var styles = cleverapps.styles.ChooseSaveWindow.tiles;
        var bg = cleverapps.UI.createScale9Sprite(bundles.choosesave_window.frames.tile_bg);

        var save = this.createSaveData(data);
        save.setPositionRound(styles.save);
        bg.setContentSize(save.width + styles.paddingX, save.height + styles.paddingY);
        bg.addChild(save);

        var msg = Messages.get("ChooseSaveWindow.local");
        if (data.server) {
            msg = Messages.get("ChooseSaveWindow.server", { time: Product.FormatTimePeriod(Date.now() - data.visited).title });
        }

        var status = cleverapps.UI.generateOnlyText(msg, cleverapps.styles.FONTS.SAVE_STATUS);
        status.fitTo(save.width - styles.status.paddingX);
        status.setPositionRound(styles.status);
        bg.addChild(status);

        if (data.badge) {
            var badge = new TileBadge({
                rotation: styles.badge.rotation,
                icon: new cc.Sprite(bundles.choosesave_window.frames.badge_icon)
            });
            badge.setScale(styles.badge.scale);
            badge.setPositionRound(styles.badge);
            bg.addChild(badge);
        }

        var button = new cleverapps.UI.Button({
            width: styles.button.width,
            height: styles.button.height,
            text: data.server ? "ChooseSaveWindow.acceptServer" : "ChooseSaveWindow.acceptLocal",
            onClicked: this.onClick.bind(this, data.server)
        });
        button.setCascadeOpacityEnabled(true);

        var tile = new cleverapps.Layout([bg, button], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });
        tile.button = button;
        return tile;
    },

    createSaveData: function (data) {
        var styles = cleverapps.styles.ChooseSaveWindow.tiles.save;
        var saveBg = cleverapps.UI.createScale9Sprite(bundles.choosesave_window.frames.save_bg);
        saveBg.setContentSize(styles);

        var dataType = new cc.Sprite(data.server ? bundles.choosesave_window.frames.server : bundles.choosesave_window.frames.local);
        dataType.setPositionRound(styles.dataType);
        saveBg.addChild(dataType);

        var items = this.createSaveItems(data);
        items.setPositionRound(styles.items);
        saveBg.addChild(items);

        var levelBg = new cc.Sprite(bundles.choosesave_window.frames.level_bg);
        levelBg.setPositionRound(styles.level);
        saveBg.addChild(levelBg);

        var levelLabel = cleverapps.UI.generateOnlyText("ChooseSaveWindow.level", cleverapps.styles.FONTS.SAVE_LEVEL);
        var levelValue = cleverapps.UI.generateOnlyText(cleverapps.humanReadableNumber(data.episode, data.level), data.better.includes("level") ? cleverapps.styles.FONTS.SAVE_LEVEL_BETTER : cleverapps.styles.FONTS.SAVE_LEVEL);
        var level = new cleverapps.Layout([levelLabel, levelValue], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.level.margin
        });
        level.setPositionRound(styles.level.text);
        levelBg.addChild(level);

        return saveBg;
    },
    
    createSaveItems: function (data) {
        var styles = cleverapps.styles.ChooseSaveWindow.tiles.save;

        var maxValueWidth = 0;
        var maxIconWidth = 0;
        var items = this.getSafeFields().filter(function (type) {
            return data[type] !== undefined;
        }).map(function (type) {
            var reward = new Reward(type === "gold" ? "hard" : type, data[type]);
            var icon = reward.getSmallIcon();
            var value = cleverapps.UI.generateImageText(reward.getAmount(), data.better.includes(type) ? cleverapps.styles.FONTS.SAVE_ITEMS_BETTER : cleverapps.styles.FONTS.SAVE_ITEMS);
            maxValueWidth = value.width > maxValueWidth ? value.width : maxValueWidth;
            maxIconWidth = icon.width > maxIconWidth ? icon.width : maxIconWidth;
            return { icon: icon, value: value };
        }).map(function (item) {
            var iconPadding = Math.max(0, (maxIconWidth - item.icon.width) / 2);
            item.icon = cleverapps.UI.wrapWithPadding(item.icon, { left: iconPadding, right: iconPadding });
            item.icon.setContentSize(maxIconWidth, item.icon.height);

            var layout = new cleverapps.Layout([item.icon, item.value], {
                direction: cleverapps.UI.HORIZONTAL,
                margin: styles.margin
            });
            layout.setContentSize(maxIconWidth + maxValueWidth, layout.height);
            return layout;
        });

        return new cleverapps.Layout(items, {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.itemsMargin
        });
    },

    onClick: function (server) {
        [this.localTile, this.serverTile].forEach(function (tile) {
            tile.button.runAction(new cc.Sequence(
                new cc.ReplaceParent(this.content),
                new cc.DelayTime(0.3),
                new cc.FadeOut(0.2, 0)
            ));
            tile.runAction(new cc.Sequence(
                new cc.ScaleTo(0.3, 0.2),
                new cc.Hide()
            ));
        }.bind(this));

        this.text.runAction(new cc.Sequence(
            new cc.DelayTime(0.3),
            new cc.FadeOut(0.2, 0)
        ));

        var progress = new cleverapps.Spine(bundles.choosesave_window.jsons.progress);
        this.content.addChild(progress);
        progress.setPositionRound(cleverapps.styles.ChooseSaveWindow.progress);
        if (this._forceError) {
            progress.setAnimationAndIdleAfter("to_server", "locked");
        } else {
            progress.setAnimation(0, server ? "to_device" : "to_server", false);
        }
    },

    getSafeFields: function () {
        switch (cleverapps.config.type) {
            case "merge":
                return ["exp", "wands", "soft", "gold"];
            case "match3":
                return ["exp", "soft", "gold"];
            default:
                return ["soft", "gold"];
        }
    },

    listBundles: function () {
        return ["choosesave_window"];
    }
});

ChooseSaveWindow.SAVE_FIELDS = ["level", "exp", "soft", "gold", "wands"];

ChooseSaveWindow.DATA = {
    local: {
        visited: 1716218439339,
        gold: 1505,
        soft: 10,
        wands: 55,
        level: 3,
        episode: 0,
        exp: 4000

    },
    server: {
        visited: 1716207239339,
        gold: 15005,
        soft: 56100,
        wands: 5,
        exp: 200,
        level: 6,
        episode: 0
    }
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    SAVE_WINDOW_TEXT: {
        name: "nostroke",
        size: 40,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    SAVE_LEVEL: {
        size: 45,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    SAVE_LEVEL_BETTER: {
        size: 45,
        color: cleverapps.styles.COLORS.GREEN,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    SAVE_ITEMS: {
        name: "nostroke",
        size: 50,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    SAVE_ITEMS_BETTER: {
        name: "nostroke",
        size: 50,
        color: cleverapps.styles.COLORS.GREEN
    },

    SAVE_STATUS: {
        size: 35,
        color: cleverapps.styles.COLORS.COLOR_WINDOW_TEXT
    }
});

cleverapps.styles.ChooseSaveWindow = {
    tilesMargin: 100,
    margin: 30,
    textWidth: 900,
    paddingX: 50,

    progress: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 200 }
    },

    tiles: {
        paddingX: 40,
        paddingY: 105,
        margin: 20,

        button: {
            width: 350,
            height: 100
        },

        badge: {
            x: { align: "right", dx: 45 },
            y: { align: "top", dy: 45 },

            rotation: 0,
            scale: 0.7
        },

        save: {
            width: 390,
            height: 540,
            itemsMargin: 5,
            margin: 5,
            x: { align: "center" },
            y: { align: "top", dy: -5 },

            level: {
                x: { align: "center", dx: 8 },
                y: { align: "top", dy: -140 },

                margin: 10,

                text: {
                    x: { align: "center", dx: -15 },
                    y: { align: "center", dy: 10 }
                }
            },

            items: {
                x: { align: "center" },
                y: { align: "center", dy: -100 }
            },

            dataType: {
                x: { align: "center" },
                y: { align: "top", dy: -30 }
            }
        },

        status: {
            paddingX: 40,
            x: { align: "center" },
            y: { align: "bottom", dy: 35 }
        }
    }
};