var ColorBombCellView = BaseCellView.extend({
    ctor: function (colorBombCell) {
        this._super();

        this.setContentSize(cleverapps.styles.BaseCellView.CELL_SIZE_PX, cleverapps.styles.BaseCellView.CELL_SIZE_PX);
        this.setAnchorPoint(0.5, 0.5);

        this.initialize(colorBombCell);
    },

    initialize: function (colorBombCell) {
        this._super(colorBombCell);

        if (!this.animation) {
            this.animation = new cleverapps.Spine(bundles.color_bomb.jsons.color_bomb_json);
            this.animation.setPositionRound(this.width / 2, this.height / 2);
            this.animation.setAnimation(0, "stage_1_0", true);
            this.addChild(this.animation);
        }
        this.animation.setVisible(true);
        this.updateAnimationSkin(colorBombCell);
        this.updateAnimation();

        colorBombCell.onChangeLivesListener = this.changeLives.bind(this);
    },

    changeLives: function () {
        if (this.cell.lives > 0) {
            this.updateAnimation();
        }
    },

    startExploding: function () {
        this.removeMissionMarkView();

        if (this.finishSmile) {
            this.finishSmile();
        }

        this.delete();
    },

    hurtAnimation: function () {
        var explodeNow = false;
        if (this.cell.lives === 1) {
            explodeNow = true;
        }

        if (explodeNow) {
            this.setLocalZOrder(11);
            this.runAction(new cc.Sequence(new cc.DelayTime(0.5), new cc.CallFunc(function () {
                if (Game.currentGame) {
                    Game.currentGame.shakeField();
                }
                cleverapps.audio.playSound(bundles.color_bomb.urls.bomb_explosion, { throttle: 20 });
            })));
        }

        var animation = "stage_" + (3 - this.cell.lives + 1).toString() + "_1";
        if (this.animation.hasAnimation(animation)) {
            this.animation.setAnimation(0, animation, false);
        }
        cleverapps.audio.playSound(bundles.color_bomb.urls.bomb_hurt);
    },

    updateAnimation: function () {
        this.animation.setAnimation(0, "stage_" + (3 - this.cell.lives + 1).toString() + "_0", true);
    },

    updateAnimationSkin: function (colorBombCell) {
        this.animation.setSkin(this.getSkin(colorBombCell));
    },

    getSkin: function (colorBombCell) {
        var colors = {
            "a": "blue",
            "b": "green",
            "c": "purple",
            "d": "red",
            "e": "yellow",
            "w": "white"
        };

        return colors[colorBombCell.findComponent(ColorComponent).color];
    }
});
