/**
 * Created by evgeny on 15.05.2024
 */

var PiggyBankGoalView = cc.Node.extend({
    ctor: function (goal) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);
        var styles = cleverapps.styles.PiggyBankGoalView;

        var parts = this.parseText(goal);
        this.createGoalIcon(parts[0]);
        cleverapps.UI.wrap(this);

        var amount = cleverapps.UI.generateImageText(parts[1], cleverapps.styles.FONTS.PIGGY_BANK_GOAL_FONT);
        this.addChild(amount);
        amount.setPositionRound(styles.amount);
        this.amount = Number.parseInt(parts[1]);
    },

    createGoalIcon: function (key) {
        var icon = this.createIcon(key);

        this.light = new cleverapps.Spine(bundles.piggy_bank_window.jsons.coin_light);
        this.light.setSkin("yellow");
        this.addChild(this.light);

        this.addChild(icon);
    },

    parseText: function (string) {
        string.trim();

        var parts = [];
        parts.push(string.substring(0, 2));
        parts.push(string.substring(2, string.length));
        return parts;
    },

    showLight: function () {
        this.light && this.light.setAnimation(0, "multiple", false);
        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.1),
            new cc.PlaySound(bundles.piggy_bank.urls.coins_light_effect)
        ));
    },

    createIcon: function (key) {
        var image = this.chooseImage(key);

        var icon = new cc.Sprite(image);

        icon.setScale(PiggyBankGoalView.ICONS[key].scale);

        return icon;
    },

    chooseImage: function (key) {
        var selected = PiggyBankGoalView.ICONS[key];
        return bundles[selected.bundle].frames[selected.name];
    }
});

PiggyBankGoalView.ICONS = {
    "$$": {
        name: "gold_icon_png",
        bundle: "buttons_inlined_icons",
        scale: 1
    },

    "@@": {
        name: "coin_icon_png",
        bundle: "buttons_inlined_icons",
        scale: 1
    }
};

cleverapps.styles.FONTS.PIGGY_BANK_GOAL_FONT = {
    size: 35,
    color: new cc.Color(0xFF, 0xE2, 0x40, 0xFF)
};

cleverapps.styles.PiggyBankGoalView = {
    amount: {
        x: { align: "center" },
        y: { align: "center", dy: -50 }
    }
};