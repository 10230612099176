/**
 * Created by Andrey Popov on 6/19/23.
 */

var SpinePropertyEditor = function (property, initialValue, onChangeValueCallback) {
    this.property = property;
    this.onChangeValueCallback = onChangeValueCallback;
    this.value = cleverapps.override({
        name: "",
        folder: ""
    }, initialValue);

    var loadedSpine;
    if (this.value.name && this.value.name.length > 0 && cleverapps.wysiwyg.googleDriveProvider.hasAsset(this.value)) {
        var spinePath = cleverapps.wysiwyg.googleDriveProvider.getSpinePath(this.value) + ".json";
        loadedSpine = cc.loader.getRes(spinePath);
        if (!loadedSpine) {
            cc.loader.load(spinePath, function () {
                loadedSpine = cc.loader.getRes(spinePath);
                cleverapps.wysiwyg.inspector.showItemProperties();
            });
        }
    }

    var nameWithFolders = cleverapps.wysiwyg.googleDriveProvider.getAssetsInFolders(this.property.folders);
    nameWithFolders[Wysiwyg.NOT_SELECTED] = true;

    this.propertyEditors = [
        {
            name: "spine",
            items: Object.keys(nameWithFolders),
            getter: function () {
                return this.value.name;
            }.bind(this),
            setter: function (value) {
                if (value === Wysiwyg.NOT_SELECTED) {
                    this.value.name = undefined;
                } else {
                    this.value = {
                        name: value,
                        folder: nameWithFolders[value]
                    };
                }
            }.bind(this)
        }
    ];

    if (!property.disableParams) {
        this.propertyEditors = this.propertyEditors.concat([
            {
                name: "animation",
                items: function () {
                    if (!loadedSpine) {
                        return [];
                    }
                    return Object.keys((loadedSpine.animations));
                },
                getter: function () {
                    return this.value.animation;
                }.bind(this),
                setter: function (value) {
                    this.value.animation = value;
                }.bind(this)
            },
            {
                name: "skin",
                fullOnly: true,
                items: function () {
                    if (!loadedSpine) {
                        return [];
                    }
                    return Object.keys(loadedSpine.skins);
                },
                getter: function () {
                    return this.value.skin;
                }.bind(this),
                setter: function (value) {
                    this.value.skin = value;
                }.bind(this)
            },
            {
                name: "idle",
                fullOnly: true,
                items: function () {
                    if (!loadedSpine) {
                        return [];
                    }
                    return [Wysiwyg.NOT_SELECTED].concat(Object.keys((loadedSpine.animations)));
                },
                getter: function () {
                    return this.value.idle;
                }.bind(this),
                setter: function (value) {
                    this.value.idle = value;
                }.bind(this)
            }
        ]);
    }
    this.propertyEditors = this.propertyEditors.filter(Inspector.FilterForFullVersion).map(function (propertyDescription) {
        return new PropertyEditor(propertyDescription, this);
    }.bind(this));
};

SpinePropertyEditor.prototype.editBoxEditingDidEnd = function (sender) {
    sender.customSetter(sender.getString());
    this.onChangeValueCallback(this.property.name, this.value);
    cleverapps.wysiwyg.inspector.showItemProperties();
};