/**
 * Created by r4zi4l on 01.08.2022
 */

/* eslint-disable no-global-assign, no-proto, new-parens */

var DateShift = {
    Date: Date,

    setShift: function (delta) {
        this.delta = parseInt(delta) || 0;

        cc.director.setNextDeltaTimeZero(true);

        if (!DateShift.getDelta()) {
            Date = DateShift.Date;
        } else {
            Date = function () {
                var args = Array.prototype.concat.apply([undefined], arguments);
                var date = new (Function.prototype.bind.apply(DateShift.Date, args));
                return new DateShift.Date(arguments.length > 1 || arguments.length === 1 && typeof arguments[0] === "string" ? date.getTime() : date.getTime() + DateShift.getDelta());
            };

            Date.__proto__ = DateShift.Date;

            Date.now = function () {
                return DateShift.Date.now() + DateShift.getDelta();
            };
        }

        if (cleverapps.eventManager) {
            cleverapps.eventManager.setCurrentFeatures();
        }
    },

    setCurrent: function (date) {
        this.setShift(date - DateShift.Date.now());
    },

    getDelta: function () {
        return this.delta || 0;
    },

    realDate: function (date) {
        return date !== undefined ? new this.Date(date) : new this.Date();
    }
};
