/**
 * Created by andrey on 24.08.17.
 */

var NutCell = function (x, y) {
    BaseCell.call(this, x, y);
    this.movable = true;
};

NutCell.prototype = Object.create(BaseCell.prototype);
NutCell.prototype.constructor = NutCell;
Skinify(NutCell, "nut");

NutCell.prototype.load = function (data) {
    this.lives = parseInt(data[1]);
};

NutCell.prototype.save = function () {
    return NutCell.CODES.concat([this.lives]);
};

NutCell.prototype.getViewClass = function () {
    return NutCellView;
};

NutCell.prototype.getGoalId = function () {
    return NutCell.GOAL_ID;
};

NutCell.prototype.boom = function () {
    this.hurt();
};

NutCell.prototype.explode = function () {
    if (Game.currentGame.goals.hasType(this.getGoalId())) {
        BaseCell.prototype.explodeViaCollect.call(this);
    } else {
        BaseCell.prototype.explode.call(this);
    }
};

NutCell.prototype.smile = function () {
    if (!this.alive) {
        return;
    }
    this.animate(BaseCell.ANIMATION_SMILE);
};

NutCell.GOAL_ID = "nut_cell_0";