/**
 * Created by Andrey Popov on 06.06.2024
 */

var PackOfferLogic = function (offer) {
    this.offer = offer;
};

PackOfferLogic.prototype.getStage = function () {
    return this.offer.stage || 0;
};

PackOfferLogic.prototype.getProduct = function () {
    return Product.CreateById(PackOfferLogic.Products[this.getStage()]);
};

PackOfferLogic.prototype.getTimeLeft = function () {
    return undefined;
};

PackOfferLogic.prototype.isIconAvailable = function () {
    return true;
};

PackOfferLogic.prototype.hasIconAttention = function () {
    return false;
};

PackOfferLogic.prototype.processBought = function () {
    this.offer.incStage();
    this.offer.complete();
};

PackOfferLogic.Products = ["starterPack0", "starterPack", "starterPack2"];