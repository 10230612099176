/**
 * Created by decipaliz on 20.06.2024
 */

var AdBlockWindow = CleverappsWindow.extend({
    onWindowLoaded: function () {
        this._super({
            content: this.createContent(),
            name: "AdBlockWindow",
            title: "AdBlockWindow.Title",
            button: {
                text: "OK"
            }
        });

        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.ADS.AD_BLOCK_WINDOW);
    },

    getPerson: function () {
        return {
            role: "hero",
            emotion: "sad"
        };
    },

    createContent: function () {
        var styles = cleverapps.styles.AdBlockWindow;

        var adblockDetectedText = cleverapps.UI.generateOnlyText("AdBlockWindow.AdblockDetected", cleverapps.styles.FONTS.WINDOW_TEXT, false);

        var icon = new cc.Sprite(bundles.adblock_window.frames.adblock_icon_png);

        var turnOffAdblockText = cleverapps.UI.generateOnlyText("AdBlockWindow.TurnOffAdblock", cleverapps.styles.FONTS.WINDOW_TEXT, false);
        turnOffAdblockText.setDimensions(cleverapps.styles.AdBlockWindow.text.width, 0);
        turnOffAdblockText.fitTo(undefined, cleverapps.styles.AdBlockWindow.text.height);
        turnOffAdblockText.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        turnOffAdblockText.setVerticalAlignment(cc.VERTICAL_TEXT_ALIGNMENT_CENTER);

        return new cleverapps.Layout([adblockDetectedText, icon, turnOffAdblockText], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });
    },

    listBundles: function () {
        return ["adblock_window"];
    }
});

cleverapps.styles.AdBlockWindow = {
    margin: 40,
    text: {
        width: 600,
        height: 130
    }
};