/**
 * Created by andrey on 20.11.17.
 */

cc.Base = cc.Node;

cc.Base.prototype.replaceParent = function (newParent) {
    this.removeTemporarily(false);
    newParent.addChild(this);
};

cc.Base.GetHierarchyRotation = function (target) {
    var rotation = { x: 0, y: 0 };
    while (target) {
        rotation.x += target.getRotationX();
        rotation.y += target.getRotationY();
        target = target.parent;
    }
    return rotation;
};

cc.Base.prototype.adjustRotationTo = function (target) {
    var rotation = cc.Base.GetHierarchyRotation(target);
    rotation.x *= -1;
    rotation.y *= -1;

    var node = this;
    while (node) {
        rotation.x += node.getRotationX();
        rotation.y += node.getRotationY();
        node = node.parent;
    }
    return rotation;
};

cc.Base.GetHierarchyScale = function (target) {
    var scale = { x: 1, y: 1 };
    while (target) {
        scale.x *= target.scaleX;
        scale.y *= target.scaleY;
        target = target.parent;
    }
    return scale;
};

cc.Base.prototype.adjustScaleTo = function (target) {
    var scale = cc.Base.GetHierarchyScale(this);
    var targetScale = cc.Base.GetHierarchyScale(target);

    scale.x /= targetScale.x;
    scale.y /= targetScale.y;

    return scale;
};

cc.Base.prototype.replaceParentSamePlace = function (newParent, options) {
    if (options && options.keepScale) {
        var scale = this.adjustScaleTo(newParent);
        this.setScaleX(scale.x);
        this.setScaleY(scale.y);
    }
    if (options && options.keepRotation) {
        var rotation = this.adjustRotationTo(newParent);
        this.setRotationX(rotation.x);
        this.setRotationY(rotation.y);
    }
    var pos = newParent.convertToNodeSpace(this.parent.convertToWorldSpace(this.getPosition()));
    this.replaceParent(newParent);
    this.setPosition(pos);
};

cc.Base.prototype.createListener = function (listener) {
    var self = this;
    addCleaner(this, function () {
        self = undefined;
        listener = undefined;
    });
    return function () {
        if (listener && self && !self.stopListeners) {
            return listener.apply(self, arguments);
        }
    };
};

cc.Base.prototype.hasTouch = function (touch) {
    if (!this.visible) {
        return false;
    }
    var point = this.convertTouchToNodeSpace(touch);
    return cc.rectContainsPoint(cc.rect(0, 0, this.width, this.height), point);
};

cc.Base.prototype.createListener_onExitTransitionDidStart = cc.Base.prototype.onExitTransitionDidStart;
cc.Base.prototype.onExitTransitionDidStart = function () {
    this.stopListeners = true;
    delete this.preferredBundles;

    this.createListener_onExitTransitionDidStart();

    if (this.avoidNode || this.updateDynamicPosition) {
        var scene = cleverapps.scenes.getRunningScene();

        if (this.avoidNode && scene.removeAvoidNode) {
            scene.removeAvoidNode(this);
        }

        if (this.updateDynamicPosition && scene.removeDynamicNode) {
            scene.removeDynamicNode(this);
        }
    }
};

cc.Base.prototype.onEnterTransitionDidFinish = cleverapps.extendFunc(cc.Base.prototype.onEnterTransitionDidFinish, function () {
    this.stopListeners = false;
    delete this.preferredBundles;

    this._super.apply(this, arguments);

    if (this.avoidNode || this.updateDynamicPosition) {
        var scene = cleverapps.scenes.getRunningScene();

        if (this.avoidNode && scene.addAvoidNode) {
            scene.addAvoidNode(this);
        }

        if (this.updateDynamicPosition && scene.addDynamicNode) {
            scene.addDynamicNode(this);
        }
    }
});

cc.Base.prototype.cleanup = cleverapps.extendFunc(cc.Base.prototype.cleanup, function () {
    this.stopListeners = true;
    runCleaners(this);
    this._super();
});

cc.Base.prototype.runAction = cleverapps.extendFunc(cc.Base.prototype.runAction, function () {
    if (this.stopListeners && cleverapps.config.debugMode && !cleverapps.config.wysiwygMode) {
        cleverapps.throwAsync("runAction on stopped node");
    }

    if (this.saveStack) {
        this.actionStack = new Error().stack;
    }

    return this._super.apply(this, arguments);
});

cc.Base.prototype.addCleaner = function (callback) {
    addCleaner(this, callback);
};

cc.Base.prototype.addChild = cleverapps.extendFunc(cc.Base.prototype.addChild, function (node) {
    this._super.apply(this, arguments);

    if (node.alignment) {
        node.doAlignment();
    }
});

cc.Base.prototype.calculatePositionRound = function (x, y, options) {
    if (Array.isArray(x)) {
        x = x[cleverapps.resolution.mode];
    }

    if (options === undefined) {
        options = typeof x === "object" ? (x.options || x) : {};
    }

    if (y === undefined) {
        y = x.y;
        x = x.x;
    }

    if (typeof x === "object" || typeof y === "object") {
        var parentSize;
        var box = options.box;
        if (this.parent) {
            parentSize = this.parent.getContentSize();
        }

        if (box) {
            parentSize = box;
        }
        if (options.parentSize) {
            parentSize = options.parentSize;
        }

        if (!parentSize) {
            return { x: x, y: y, options: options };
        }

        if (typeof x === "object") {
            x = this.calculateCoordinate(x, "x", parentSize.width) + (box && box.x || 0);
        }

        if (typeof y === "object") {
            y = this.calculateCoordinate(y, "y", parentSize.height) + (box && box.y || 0);
        }
    }

    return cc.p(Math.floor(x), Math.floor(y));
};

cc.Base.prototype.setPositionRound = function (x, y, options) {
    this.alignment = y === undefined ? x : { x: x, y: y, options: options };

    if (this.parent) {
        this.doAlignment();
    }
};

cc.Base.prototype.doAlignment = function () {
    var position = this.calculatePositionRound(this.alignment);
    this.setPosition(position.x, position.y);
};

cc.Base.prototype.setPosition = cleverapps.extendFunc(cc.Base.prototype.setPosition, function (x, y) {
    this._super(x, y);

    if (isNaN(this.x) || isNaN(this.y)) {
        if (cleverapps.config.debugMode) {
            throw "In setPosition value is NaN or undefined: x = " + this.x + ", y = " + this.y;
        }
        cleverapps.throwAsync("In setPosition value is NaN or undefined: x = " + this.x + ", y = " + this.y);
    }
});

cc.Base.prototype.calculateCoordinate = function (styles, coordinate, parentSize) {
    var c = styles["d" + coordinate] || 0;

    var anchorPoint = coordinate === "x" ? this.getAnchorPoint().x : this.getAnchorPoint().y;
    var dimension = coordinate === "x" ? this.width * Math.abs(this.scaleX) : this.height * Math.abs(this.scaleY);
    if (styles.anchor === false) {
        dimension = 0;
    } else if (styles.anchor !== undefined) {
        if (coordinate === "x") {
            this.setAnchorPoint(styles.anchor, this.anchorY);
        } else {
            this.setAnchorPoint(this.anchorX, styles.anchor);
        }
    }

    var align = styles.align || "center";
    switch (align) {
        case "left":
        case "bottom":
            c += anchorPoint * dimension;
            break;
        case "center":
            c += parentSize / 2;
            c += (anchorPoint - 1 / 2) * dimension;
            break;
        case "right":
        case "top":
            c += parentSize;
            c += (anchorPoint - 1) * dimension;
            break;
    }

    return c;
};

cc.Base.prototype.getRelativeCoordinates = function (target) {
    return this.getParent().convertToNodeSpace(target.getParent().convertToWorldSpace(target.getPosition()));
};

cc.Node.prototype.getScale = function () {
    if (this.scaleX !== this.scaleY) {
        cleverapps.throwAsync("getScale with different scaleX=" + this.scaleX + " and scaleY=" + this.scaleY);
    }
    return this.scaleX;
};

cc.Base.prototype.setContentSize2 = function (width, height) {
    if (Array.isArray(width)) {
        width = width[cleverapps.resolution.mode];
    }

    if (Array.isArray(height)) {
        height = height[cleverapps.resolution.mode];
    }

    if (height === undefined) {
        height = width.height;
        width = width.width;
    }

    this.setContentSize(width, height);
};

cc.Base.prototype.setContentSize = cleverapps.extendFunc(cc.Base.prototype.setContentSize, function (size, height) {
    var oldWidth = this.width;
    var oldHeight = this.height;

    this._super(size, height);

    if (oldWidth !== this.width || oldHeight !== this.height) {
        this.children.forEach(function (child) {
            if (child.alignment) {
                child.doAlignment();
            }
        });
    }
});

cc.Base.prototype.setScale2 = function (scale) {
    if (Array.isArray(scale)) {
        scale = scale[cleverapps.resolution.mode];
    }

    this.setScale(scale);
};

cc.Base.prototype.isDisplayed = function () {
    var node = this;

    while (node.isVisible() && node.parent) {
        node = node.parent;
    }

    return node.isVisible() && node === cleverapps.scenes.getRunningScene();
};

cc.Base.prototype.startLoading = function () {
};

cc.Base.prototype.getCenterPos = function () {
    var boxPos = this.getBoundingBox();
    boxPos.x += boxPos.width / 2;
    boxPos.y += boxPos.height / 2;
    return { x: Math.round(boxPos.x), y: Math.round(boxPos.y) };
};

cc.Base.prototype.getBoundingBox = function () {
    var scaleX = this.scaleX;
    var scaleY = this.scaleY;
    var baseScaleX = this.baseScaleX || this.baseScale || scaleX;
    var baseScaleY = this.baseScaleY || this.baseScale || scaleY;
    var change = scaleX !== baseScaleX || scaleY !== baseScaleY;

    if (change) {
        this.setScale(baseScaleX, baseScaleY);
    }

    var rect = cc.rect(0, 0, this._contentSize.width, this._contentSize.height);
    var box = cc.rectApplyAffineTransform(rect, this.getNodeToParentTransform());

    if (change) {
        this.setScale(scaleX, scaleY);
    }

    return box;
};

cc.Base.prototype.getNodeToSceneTransform = function () {
    var t = this.getNodeToParentTransform();
    for (var p = this.parent; p !== null; p = p.parent) {
        if (p instanceof cleverapps.FixedWidthScene) {
            return t;
        }
        t = cc.affineTransformConcat(t, p.getNodeToParentTransform());
    }
};

cc.Base.prototype.getSceneBoundingBox = function () {
    var rect = cc.rect(0, 0, this._contentSize.width, this._contentSize.height);
    var trans = this.getNodeToSceneTransform();
    return cc.rectApplyAffineTransform(rect, trans);
};

cc.Base.prototype.getGlobalBoundingBox = function () {
    var rect = cc.rect(0, 0, this._contentSize.width, this._contentSize.height);
    var trans = this.getNodeToWorldTransform();
    return cc.rectApplyAffineTransform(rect, trans);
};

cc.Base.prototype.setCascadeOpacityEnabledRecursively = function (enabled) {
    this.performRecursive(function (node) {
        node.setCascadeOpacityEnabled(enabled);
    });
};

cc.Base.prototype.setRecCascadeColorEnabled = function () {
    this.performRecursive(function (node) {
        node.setCascadeColorEnabled(true);
    });
};

cc.Base.prototype.onResize = function () {
    this.children.forEach(function (child) {
        child.onResize();
    });

    this.completeAnimationOnResize && this.completeAnimationOnResize();

    this.updateSize && this.updateSize();
    this.setupChildren && this.setupChildren();
};

cc.Base.prototype.performRecursive = function (callback) {
    var q = [this];
    while (q.length > 0) {
        var t = q.pop();
        callback(t);
        Array.prototype.push.apply(q, t.children);

        if (t.iterateTiles) {
            t.iterateTiles(function (tile) {
                if (tile instanceof cc.Node) {
                    q.push(tile);
                }
            });
        }
    }
};

cc.Base.prototype.getPreferredBundles = function () {
    if (this.preferredBundles !== undefined) {
        return this.preferredBundles;
    }

    this.preferredBundles = false;

    if (this.bundles) {
        this.preferredBundles = this.bundles.reduce(function (result, name) {
            var bundle = bundles[name];
            return result.concat(bundle.injectTo || [name]);
        }, []); 
    } else if (this.parent) {
        this.preferredBundles = this.parent.getPreferredBundles();
    }

    return this.preferredBundles;
};

cc.Base.prototype.getCropContainer = function () {

};

cc.Base.prototype.setInteractiveState = function (cause, state) {
    if (!this.interactiveStates) {
        this.interactiveStates = {};
    }

    if (!state) {
        state = undefined;
    }

    if (this.interactiveStates[cause] === state) {
        return false;
    }

    if (!state) {
        delete this.interactiveStates[cause];
    } else {
        this.interactiveStates[cause] = state;
    }
    return true;
};

cc.Base.prototype.getInteractiveStates = function () {
    var states = {};

    if (!this.interactiveStates) {
        return states;
    }

    for (var cause in this.interactiveStates) {
        states[this.interactiveStates[cause]] = true;
    }

    return states;
};

cc.Base.prototype.removeInteractiveStates = function (pattern) {
    if (!this.interactiveStates) {
        return;
    }

    for (var cause in this.interactiveStates) {
        if (cause.indexOf(pattern) === 0) {
            delete this.interactiveStates[cause];
        }
    }
};

cc.Base.prototype.applyInteractiveScale = function () {
    if (this.interactiveScale === false) {
        return;
    }

    var scaleX = this.baseScaleX || this.baseScale || 1;
    var scaleY = this.baseScaleY || this.baseScale || 1;

    var states = this.getInteractiveStates();
    if (states.pressed) {
        this.setScale(scaleX * 0.97, scaleY * 0.97);
    } else if (states.mouseover) {
        this.setScale(scaleX * 1.03, scaleY * 1.03);
    } else {
        this.setScale(scaleX, scaleY);
    }
};

cc.Base.prototype.convertTouchToNodeSpaceDisplacement = function (touch) {
    var startPosition = this.convertToNodeSpace(touch.getStartLocation());
    var currentPosition = this.convertToNodeSpace(touch.getLocation());

    var angleRadians = -(Math.PI / 180) * this.getRotation();

    var mapPosition = function (pos) {
        return {
            x: pos.x * Math.cos(angleRadians) - pos.y * Math.sin(angleRadians),
            y: pos.x * Math.sin(angleRadians) + pos.y * Math.cos(angleRadians)
        };
    };

    startPosition = mapPosition(startPosition);
    currentPosition = mapPosition(currentPosition);

    return cc.p(currentPosition.x - startPosition.x, currentPosition.y - startPosition.y);
};

cc.Base.prototype.removeTemporarily = function () {
    if (engine === "creator") {
        this._temporarilyRemoved = true;
    }

    this.removeFromParent.apply(this, arguments);
};

cc.Base.CHILDREN_ORDER_FN = function (child1, child2) {
    return (child1._localZOrder - child2._localZOrder) || (child1.arrivalOrder - child2.arrivalOrder);
};

cc.Base.EMPTY_COLOR = cc.color(255, 255, 255, 255);
