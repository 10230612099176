/**
 * Created by Andrey Popov on 1/26/21.
 */

var LocalizedTextPropertyEditor = function (property, initialValue, onChangeValueCallback) {
    this.property = property;
    this.onChangeValueCallback = onChangeValueCallback;

    if (initialValue && initialValue.general === undefined) {
        initialValue = {
            general: initialValue
        };
    }

    this.value = initialValue || {
        general: {}
    };

    var resolution = cleverapps.wysiwyg.wysiwygPreview.selectedResolution;
    if (!this.value[resolution]) {
        this.value[resolution] = {};
    }

    var languages = cleverapps.wysiwyg.hierarchy.getSelectedClip().properties.languages;

    var propConfig = [{
        name: property.name,
        fullOnly: true,
        actions: [{
            translate: this.translate.bind(this, languages, true)
        }, {
            clear: function () {
                this.value.general = {};
                this.value[resolution] = {};
                this.onChangeValueCallback(this.property.name, this.value);
                cleverapps.wysiwyg.inspector.showItemProperties();
            }.bind(this)
        }]
    }];

    languages.forEach(function (language) {
        propConfig.push({
            name: language,
            fullOnly: language !== "en",
            language: language,
            allowOverride: true,
            getters: [function () {
                return this.value.general[language];
            }.bind(this), function () {
                var override = this.value[resolution][language];
                if (!override || override.length === 0) {
                    return undefined;
                }
                return override;
            }.bind(this)],
            setters: [function (value) {
                this.value.general[language] = value;
            }.bind(this), function (value) {
                if (value && value.length > 0) {
                    this.value[resolution][language] = value;
                } else {
                    delete this.value[resolution][language];
                }
            }.bind(this)]
        });
    }.bind(this));

    this.propertyEditors = propConfig.filter(Inspector.FilterForFullVersion).map(function (propertyDescription) {
        return new PropertyEditor(propertyDescription, this);
    }.bind(this));
};

LocalizedTextPropertyEditor.prototype.translate = function (languages, hard) {
    cleverapps.RestClient.post("/admin/translate", {
        text: this.value.general.en,
        languages: languages
    }, function (response) {
        if (hard) {
            this.onChangeValueCallback(this.property.name, response);
        } else {
            var newValue = this.value.general;
            var needToTranslate = false;
            Object.keys(response).forEach(function (lang) {
                if (!newValue[lang] || newValue[lang] === "") {
                    newValue[lang] = response[lang];
                } else if (lang !== "ru" && newValue[lang] !== response[lang]) {
                    needToTranslate = true;
                }
            });

            if (needToTranslate) {
                cleverapps.notification.create("Please run translate action!");
            }
            this.onChangeValueCallback(this.property.name, newValue);
        }

        cleverapps.wysiwyg.inspector.showItemProperties();
    }.bind(this));
};

LocalizedTextPropertyEditor.prototype.editBoxEditingDidEnd = function (sender) {
    sender.customSetter(sender.getString());
    this.onChangeValueCallback(this.property.name, this.value);
    if (sender.language && sender.language === "en") {
        this.translate(cleverapps.wysiwyg.hierarchy.getSelectedClip().properties.languages);
    }
};

LocalizedTextPropertyEditor.prototype.editBoxEditingDidBegin = function (sender) {
    if (sender.language) {
        var textComponents = cleverapps.wysiwyg.hierarchy.getChildrenByFilter(cleverapps.wysiwyg.hierarchy.selectedItem, function (item) {
            return item.assetName && item.assetName === "AdsText";
        });

        cc.director.getRunningScene().wysiwygPreviewView.sendMessageToIFrame({
            type: "changeLanguage",
            language: sender.language
        });

        textComponents.forEach(function (component) {
            cleverapps.scenes.getRunningScene().wysiwygPreviewView.updateComponent(component);
        });
        this.onChangeValueCallback(this.property.name, this.value);
    }
};