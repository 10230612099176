/**
 * https://yandex.ru/support/metrica/index.html
 *
 * Created by anatoly on 22/04/2024
 */

var YandexMetricaLogger = function () {
    console.log("YandexMetricaLogger init");

    cleverapps.loadSdk("//mc.yandex.ru/metrika/tag.js", {
        onSuccess: function () {
            console.log("YandexMetricaLogger init success");
        }
    });

    var ym = function () {
        window.ym.a.push(arguments);
    };

    ym.a = [];
    ym.l = 1 * new Date();

    window.ym = ym;

    var metricaID = cleverapps.config.yandexMetrica;

    if (typeof metricaID === "object") {
        metricaID = cleverapps.config.debugMode ? metricaID.standId : metricaID.releaseId;
    }

    this.metricaID = metricaID;

    ym(metricaID, "init", {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true
    });

    this.onUpdateUserId();
};

YandexMetricaLogger.prototype.logEvent = function (name, params) {
    if (!ConversionManager.EVENTS[name]) {
        return;
    }

    console.log("yandex metrica", name, params);

    ym(this.metricaID, "reachGoal", name, {
        order_price: params && params.value || 1
    });
};

YandexMetricaLogger.prototype.logPurchase = function (product, purchase, params) {
    ym(this.metricaID, "reachGoal", "purchase", {
        order_price: params.price,
        currency: params.currency
    });
};

YandexMetricaLogger.prototype.logAdRevenue = function (name, impressionCost) {
    ym(this.metricaID, "reachGoal", "ad", {
        order_price: impressionCost,
        currency: "USD"
    });
};

YandexMetricaLogger.prototype.onUpdateUserId = function () {
    ym(this.metricaID, "setUserID", connector.platform.getUserID());
};

YandexMetricaLogger.isApplicable = function () {
    return connector.platform.oneOf(connector.CLEVERAPPS) && cleverapps.config.yandexMetrica;
};