/**
 * Created by Vladislav on 25.09.2024.
 */

Placements.ENTRIES["flyingAd"] = {
    type: Placements.FREE_FOCUS_MAIN,

    filter: function () {
        return cleverapps.flyingAd.needUpdate();
    },

    action: function () {
        cleverapps.flyingAd.update();
    }
};