/**
 * Created by Ivan on 19.04.2024
 */

var StickerView = cc.Node.extend({
    ctor: function (sticker, options) {
        this._super();

        this.options = options || {};
        this.sticker = sticker;
        this.bundle = bundles["collection_" + sticker.collection.index];

        this.setAnchorPoint(0.5, 0.5);

        this.createImage();
        this.createFrame();
        this.createLabel();

        this.setCascadeOpacityEnabledRecursively(true);

        this.update();

        if (this.options.withUpdate) {
            sticker.on("update", this.createListener(this.update.bind(this)));
        }
    },

    createFrame: function () {
        var styles = cleverapps.styles.StickerView;

        var frame = this.frame = new cc.Sprite(this.bundle.frames.gray_frame_png);
        this.setContentSize(frame.getContentSize());
        frame.setPositionRound(this.width / 2, this.height / 2);

        if (this.options.tape) {
            var tape = new cc.Sprite(this.bundle.frames.tape_png);
            tape.setRotation(styles.tape.rotation[cleverapps.resolution.mode]);
            frame.addChild(tape);
            tape.setPositionRound(styles.tape);
        }

        this.addChild(frame);
    },

    createImage: function () {
        var styles = cleverapps.styles.StickerView.image;
        var image = this.image = new cc.Sprite();
        image.setPositionRound(styles);
        styles.zOrder && image.setLocalZOrder(styles.zOrder);

        this.addChild(image);
    },

    update: function () {
        var state = this.sticker.getState();
        if (state === Sticker.STATE_OPENED) {
            this.image.setSpriteFrame(this.sticker.image);
            this.image.setVisible(true);
            this.frame.setSpriteFrame(this.bundle.frames[this.sticker.isRare() ? "gold_frame_png" : "silver_frame_png"]);
            this.label && this.label.setSpriteFrame(this.bundle.frames.text_bg_blue_png);
            this.text.setVisible(true);
        } else {
            this.image.setSpriteFrame(this.bundle.frames.locked_image_png);
            this.frame.setSpriteFrame(this.bundle.frames.gray_frame_png);
            this.label && this.label.setSpriteFrame(this.bundle.frames.text_bg_gray_png);

            if (cleverapps.styles.StickerView.hideLocked) {
                this.text.setVisible(false);
                this.image.setVisible(false);
            }
        }

        if (this.options.badge && this.sticker.hasAttention()) {
            this.createBadge();
        } else {
            this.removeBadge();
        }
    },

    createLabel: function () {
        var styles = cleverapps.styles.StickerView.label;

        var text = this.text = cleverapps.UI.generateOnlyText(this.sticker.title, cleverapps.styles.FONTS.COLLECTION_WINDOW_TEXT);
        text.setPositionRound(styles.text);
        text.fitTo(styles.text.width);
        text.setLocalZOrder(12);

        if (styles.textBg) {
            var label = this.label = new cc.Sprite(this.bundle.frames.text_bg_gray_png);
            label.setPositionRound(styles.textBg);
            label.addChild(text);
            this.addChild(label);
        } else {
            this.addChild(text);
        }
    },

    createBadge: function () {
        if (this.badge) {
            return;
        }

        var styles = cleverapps.styles.StickerView.badge;

        var badge = this.badge = new cc.Sprite(this.bundle.frames.badge_png);
        badge.setLocalZOrder(11);

        var text = cleverapps.UI.generateOnlyText("Attention.Text", cleverapps.styles.FONTS.COLLECTION_BADGE_TEXT);
        badge.addChild(text);
        text.setPositionRound(styles.text);
        text.fitTo(styles.text.maxWidth);
        text.setRotation(styles.text.rotation);

        this.addChild(badge);
        badge.setPositionRound(styles);
    },

    removeBadge: function () {
        if (!this.badge) {
            return;
        }

        this.badge.removeFromParent();
    }
});

cleverapps.styles.StickerView = {
    image: {
        x: { align: "center" },
        y: { align: "center", dy: 35 }
    },

    label: {
        textBg: {
            x: {
                align: "center",
                dx: 0
            },
            y: {
                align: "bottom",
                dy: 16
            }
        },

        text: {
            x: { align: "center" },
            y: { align: "center", dy: 2 },
            width: 170
        }
    },

    badge: {
        x: { align: "right", dx: 30 },
        y: { align: "top", dy: 20 },
        text: {
            x: { align: "center" },
            y: { align: "center" },
            maxWidth: 70,
            rotation: 20
        }
    },

    points: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: -50 },
        margin: -7
    },

    text: {
        x: { align: "center", dx: 0 },
        y: { align: "top", dy: 98 }
    },

    effect: {
        x: { align: "center" },
        y: { align: "center" }
    },

    tape: false
};