/**
 * Created by Ivan on 28.07.2023
 */

var AdsMap2d = cc.Node.extend({
    ctor: function (properties, eventBus) {
        this._super();
        properties = properties || {};

        [Wysiwyg.EVENTS.PREVIEW, Wysiwyg.EVENTS.SCENE_STARTED].forEach(function (event) {
            eventBus.on(event, function () {
                if (properties.zoomScale) {
                    this.setZoom(Wysiwyg.getValueForResolution(properties.zoomScale));
                }
                if (properties.scrollDeltaX || properties.scrollDeltaY) {
                    this.setScroll(Wysiwyg.getValueForResolution(properties.scrollDeltaX), Wysiwyg.getValueForResolution(properties.scrollDeltaY));
                }
            }.bind(this), this);
        }.bind(this));
    },

    setZoom: function (zoom) {
        var scene = cleverapps.scenes.getRunningScene();
        var defaultZoom = scene.game ? scene.game.getBasicZoom() : 1;

        defaultZoom *= (zoom || 1);
        scene.animateZoom(defaultZoom, 0);
    },

    setScroll: function (scrollDeltaX, scrollDeltaY) {
        var scene = cleverapps.scenes.getRunningScene();
        var map = scene.game.map;

        var scrollCell = map.scrollCell || map.getMapCenterCell();
        scrollCell = map.getView().getCell(scrollCell.x, scrollCell.y);
        scrollCell = scrollCell ? scene.scroll.normalizePoint(scene.scroll.targetToPoint(scrollCell)) : scene.scroll.getContainerPosition();
        scrollCell = cc.pAdd(scrollCell, {
            x: scrollDeltaX || 0,
            y: scrollDeltaY || 0
        });

        scene.scroll.scrollToPoint(scrollCell);
    }
});

AdsMap2d.PROPERTIES = [{
    name: "scrollDeltaX", type: "number", value: 0, dirtyOnChange: true, allowOverride: true
}, {
    name: "scrollDeltaY", type: "number", value: 0, dirtyOnChange: true, allowOverride: true
}, {
    name: "zoomScale",
    type: "number",
    value: function () {
        var currentClip = cleverapps.wysiwyg.hierarchy.getSelectedClip();
        var zoomScaleValues = {
            general: 1
        };
        currentClip.properties.resolutions.forEach(function (resolution) {
            zoomScaleValues[resolution] = AdsMap2d.RESOLUTIONS_ZOOM_SCALE[resolution];
        });
        return zoomScaleValues;
    },
    dirtyOnChange: true,
    allowOverride: true
}];

AdsMap2d.RESOLUTIONS_ZOOM_SCALE = {
    general: 1,
    portrait_720x800: 0.8,
    portrait_240x400: 0.7,
    portrait_300x380: 0.7,
    portrait_800x1200: 0.8,
    portrait_768x1024: 0.8,
    portrait_1080x1350: 0.8,
    portrait_1080x1920: 0.8,
    portrait_1242x2208: 0.8,
    portrait_1284x2778: 0.8,
    portrait_1600x2560: 0.8,
    portrait_2048x2732: 0.8,
    square_600x600: 0.8,
    square_1024x1024: 0.9,
    square_1080x1080: 0.8,
    landscape_696x340: 1.4,
    landscape_500x200: 1.4,
    landscape_800x40: 4,
    landscape_1000x250: 2,
    landscape_1024x768: 1,
    landscape_1024x500: 1.2,
    landscape_1080x607: 1.2,
    landscape_1120x630: 1.2,
    landscape_1200x627: 1.2,
    landscape_1200x628: 1.2,
    landscape_1600x300: 2,
    landscape_1600x1200: 1,
    landscape_1848x682: 1.4,
    landscape_1920x886: 1.4,
    landscape_2880x650: 2.5,
    landscape_2560x1600: 1.2,
    landscape_2732x2048: 1,
    landscape_2208x1242: 1.2,
    landscape_2778x1284: 1.4,
    landscape_1920x1080: 1.2
};