/**
 * Created by vladislav on 25.02.2021
 */

var ColorPicker = cc.Node.extend({
    ctor: function (options) {
        this._super();

        this.setAnchorPoint(0.5, 0.5);

        this.options = options || {};
        this.color = this.options.color || cleverapps.styles.COLORS.WHITE;
        this.onChangeCallback = this.options.onChangeCallback || function () {};

        this.colorSprite = new cc.Sprite();
        var texture = this.createTexture();
        this.colorSprite.initWithTexture(texture, cc.rect(0, 0, this.options.size.width, this.options.size.height));
        this.addChild(this.colorSprite);
        this.setContentSize2(this.colorSprite.getContentSize());
        this.colorSprite.setPositionRound(this.width / 2, this.height / 2);

        cleverapps.UI.onClick(this, this.onClicked.bind(this));
    },

    setDelegate: function (delegate) {
        this.delegate = delegate;
    },

    getString: function () {
        return this.color;
    },

    onClicked: function () {
        if (this.window) {
            this.closeWindow();
            $(cc.EditBox).show();
            return;
        }

        $(cc.EditBox).hide();

        var styles = cleverapps.styles.ColorPicker;
        this.window = new ColorPickerWindow(this.color, this.onChange.bind(this));
        this.addChild(this.window);
        this.window.setPositionRound(-this.window.width / 2 - styles.windowOffsetX, this.height / 2);
        this.window.replaceParentSamePlace(cleverapps.scenes.getRunningScene());
        this.window.setLocalZOrder(10);
    },

    closeWindow: function () {
        if (this.window) {
            this.window.removeFromParent(true);
            delete this.window;
        }
    },

    onExit: function () {
        this._super();

        this.closeWindow();
    },

    onChange: function (color) {
        this.color = color;
        this.colorSprite.initWithTexture(this.createTexture(), cc.rect(0, 0, this.options.size.width, this.options.size.height));
        this.onChangeCallback(this.color);

        this.notifyDelegate();
    },

    notifyDelegate: cleverapps.throttle(100, function () {
        if (this.delegate) {
            this.delegate.editBoxEditingDidEnd(this);
        }
    }),

    createTexture: function () {
        var styles = cleverapps.styles.ColorPicker;

        return ColorPicker.CreateTextureByColor(styles.width, styles.height, this.color);
    }
});

ColorPicker.CreateTexture = function (w, h, iterator) {
    var tex = new cc.Texture2D();
    var len = w * h * 3;
    var data = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        data[i] = iterator(i);
    }
    tex.initWithData(data, cc.Texture2D.PIXEL_FORMAT_RGB888, w, h, w * h);
    return tex;
};

ColorPicker.CreateTextureByColor = function (w, h, color) {
    return ColorPicker.CreateTexture(w, h, function (index) {
        if (index % 3 === 0) {
            return color.r;
        } if (index % 3 === 1) {
            return color.g;
        } if (index % 3 === 2) {
            return color.b;
        }
    });
};

cleverapps.styles.ColorPicker = {
    width: 70,
    height: 70,
    windowOffsetX: 20
};

var ColorPickerWindow = cc.Node.extend({
    ctor: function (color, onChangeCallback) {
        this._super();

        this.setAnchorPoint(0.5, 0.5);

        this.onChangeCallback = onChangeCallback;

        this.mainPaletteColors = [
            { r: 255, g: 0, b: 0 },
            { r: 255, g: 0, b: 255 },
            { r: 0, g: 0, b: 255 },
            { r: 0, g: 255, b: 255 },
            { r: 0, g: 255, b: 0 },
            { r: 255, g: 255, b: 0 },
            { r: 255, g: 0, b: 0 }
        ];

        var styles = cleverapps.styles.ColorPickerWindow;

        this.createPalette();
        this.createPaletteSlider();
        this.createGradient();
        this.createGradientSlider();
        this.createRGBBoxes();

        var colorsLayout = new cleverapps.Layout([this.gradientContainer, this.paletteContainer], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });

        var layout = new cleverapps.Layout([colorsLayout, this.rgbBoxesLayout], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.editBox.margin
        });

        var bg = new cc.Scale9Sprite(bundles.editor_controls.frames.component);
        this.addChild(bg);
        bg.setContentSize2(layout.width + 2 * styles.bg.padding.x, layout.height + 2 * styles.bg.padding.y);
        this.setContentSize2(bg.getContentSize());
        bg.setPositionRound(this.width / 2, this.height / 2);
        bg.setLocalZOrder(-1);

        this.addChild(layout);
        layout.setPositionRound(this.width / 2, this.height / 2);

        this.setCurrentColor(color);

        this.createDragListener();
    },

    createDragListener: function () {
        var startPosition;
        cleverapps.UI.onDrag(this, {
            onDragStart: function () {
                startPosition = this.getPosition();
                return true;
            }.bind(this),
            onDragMove: function (touch) {
                var displacement = this.parent.convertTouchToNodeSpaceDisplacement(touch);
                this.setPositionRound(startPosition.x + displacement.x, startPosition.y + displacement.y);
            }.bind(this),
            onDragEnd: function (touch) {
                var displacement = this.parent.convertTouchToNodeSpaceDisplacement(touch);
                this.setPositionRound(startPosition.x + displacement.x, startPosition.y + displacement.y);
            }.bind(this)
        });
    },

    createRGBBoxLayout: function (label) {
        var styles = cleverapps.styles.ColorPickerWindow.editBox;
        var isHex = label === "#";
        var box = new cc.EditBox(cc.size(isHex ? 2 * styles.width : styles.width, styles.height));
        box.setDelegate(this);
        box.stayOnTop(true);
        box.setTouchEnabled();
        box.setMaxLength(15);
        box.setFontColor(cleverapps.styles.COLORS.BLACK);
        box.setString("0");
        box.setFontSize(20);
        box.maxLength = isHex ? 6 : 3;

        var labelText = cleverapps.UI.generateOnlyText(label.toUpperCase(), cleverapps.styles.FONTS.PROPERTY_EDITOR_TEXT);

        var boxBg = new cc.Scale9Sprite(bundles.editor_controls.frames.component_frame);
        boxBg.setContentSize2(box.width + (isHex ? 0 : 2 * styles.bg.padding.x), box.height + 2 * styles.bg.padding.y);
        boxBg.addChild(box);
        box.setPositionRound(boxBg.width / 2, boxBg.height / 2);

        var layout = new cleverapps.Layout([labelText, boxBg], {
            margin: styles.labelMargin,
            direction: cleverapps.UI.HORIZONTAL
        });

        layout.box = box;

        return layout;
    },

    createRGBBoxes: function () {
        var styles = cleverapps.styles.ColorPickerWindow.editBox;

        var boxBgR = this.createRGBBoxLayout("r");
        var boxBgG = this.createRGBBoxLayout("g");
        var boxBgB = this.createRGBBoxLayout("b");
        var boxBgHex = this.createRGBBoxLayout("#");
        this.rgbBoxesLayout = new cleverapps.Layout([boxBgR, boxBgG, boxBgB, boxBgHex], {
            margin: styles.margin,
            direction: cleverapps.UI.HORIZONTAL
        });

        this.editBoxR = boxBgR.box;
        this.editBoxG = boxBgG.box;
        this.editBoxB = boxBgB.box;
        this.editBoxHex = boxBgHex.box;
    },

    updateRGBBoxes: function () {
        this.editBoxR.setString(this.color.r.toString());
        this.editBoxG.setString(this.color.g.toString());
        this.editBoxB.setString(this.color.b.toString());

        var hexToStr = function (color) {
            var result = color.toString(16);
            if (result.length === 1 && result !== "0") {
                result = "0" + result;
            }
            return result;
        };
        this.editBoxHex.setString(hexToStr(this.color.r) + hexToStr(this.color.g) + hexToStr(this.color.b));
    },

    editBoxTextChanged: function (sender) {
        var color;
        var value = parseInt(sender.getString());
        if (!isNaN(value)) {
            value = this.clamp(value, 0, 255);
            if (sender === this.editBoxR) {
                color = new cc.Color(value, this.color.g, this.color.b, 255);
            } else if (sender === this.editBoxG) {
                color = new cc.Color(this.color.r, value, this.color.b, 255);
            } else if (sender === this.editBoxB) {
                color = new cc.Color(this.color.r, this.color.g, value, 255);
            }
        }

        if (color) {
            this.setCurrentColor(color);
            this.onChangeCallback(this.color);
        }
    },

    editBoxEditingDidEnd: function (sender) {
        if (sender === this.editBoxHex) {
            var color;
            var value = sender.getString();
            if (value.length === 3) {
                color = new cc.Color(parseInt(value[0], 16), parseInt(value[1], 16), parseInt(value[2], 16), 255);
            } if (value.length === 6) {
                color = new cc.Color(parseInt(value[0] + value[1], 16), parseInt(value[2] + value[3], 16), parseInt(value[4] + value[5], 16), 255);
            }

            if (color) {
                this.setCurrentColor(color);
                this.onChangeCallback(this.color);
            }
        }
    },

    setCurrentColor: function (color) {
        this.color = color;

        this.paletteColor = this.calcPaletteColor();

        this.updatePaletteSlider();
        this.updateGradient();
        this.updateGradientSlider();
        this.updateRGBBoxes();
    },

    calcPaletteColor: function () {
        for (var i = 0; i < this.mainPaletteColors.length - 1; i++) {
            var incR = cleverapps.sign(this.mainPaletteColors[i + 1].r - this.mainPaletteColors[i].r);
            var incG = cleverapps.sign(this.mainPaletteColors[i + 1].g - this.mainPaletteColors[i].g);
            var incB = cleverapps.sign(this.mainPaletteColors[i + 1].b - this.mainPaletteColors[i].b);

            var paletteColor = { r: this.mainPaletteColors[i].r, g: this.mainPaletteColors[i].g, b: this.mainPaletteColors[i].b };

            while (paletteColor.r !== this.mainPaletteColors[i + 1].r || paletteColor.g !== this.mainPaletteColors[i + 1].g || paletteColor.b !== this.mainPaletteColors[i + 1].b) {
                if (this.calcColorPositionInGradient(paletteColor)) {
                    return paletteColor;
                }

                paletteColor.r += incR;
                paletteColor.g += incG;
                paletteColor.b += incB;
            }
        }
    },

    calcColorPositionInGradient: function (paletteColor) {
        var calcPixelRange = function (line, paletteColorPart, colorPart) {
            var leftVal = 255 - line;
            var rightVal = Math.round(paletteColorPart - paletteColorPart * line / 255);

            if (colorPart < rightVal || colorPart > leftVal) {
                return;
            }

            var rangeLength = 256 / (leftVal - rightVal + 1);
            var start = (leftVal - colorPart) * rangeLength;

            return [this.clamp(Math.floor(start), 0, 255), this.clamp(Math.ceil(start + rangeLength - 1), 0, 255)];
        }.bind(this);

        var getPixel = function (ranges) {
            var maxStart = ranges[0][0], minEnd = ranges[0][1];
            for (var i = 0; i < ranges.length; i++) {
                if (ranges[i][0] > maxStart) {
                    maxStart = ranges[i][0];
                }

                if (ranges[i][1] < minEnd) {
                    minEnd = ranges[i][1];
                }
            }
            if (minEnd < maxStart) {
                return undefined;
            } 
            return Math.round((minEnd + maxStart) / 2);
        };

        for (var line = 0; line < 256; line++) {
            var pixelR = calcPixelRange(line, paletteColor.r, this.color.r);
            var pixelG = calcPixelRange(line, paletteColor.g, this.color.g);
            var pixelB = calcPixelRange(line, paletteColor.b, this.color.b);

            if (pixelR === undefined || pixelG === undefined || pixelB === undefined) {
                continue;
            }

            var pixel = getPixel([pixelR, pixelG, pixelB]);

            if (pixel !== undefined) {
                return {
                    line: line,
                    pixel: pixel
                };
            }
        }
    },

    createGradientSlider: function () {
        this.gradientSlider = new cc.Sprite(bundles.editor_controls.frames.component_item);
        this.gradientContainer.addChild(this.gradientSlider);
    },

    updateGradientSlider: function () {
        var position = this.calcColorPositionInGradient(this.paletteColor);

        var x = position.pixel / 255 * this.gradientContainer.width;
        var y = this.gradientContainer.height - position.line / 255 * this.gradientContainer.height;
        this.gradientSlider.setPositionRound(x, y);
    },

    createPaletteSlider: function () {
        this.paletteSlider = new cc.Node();
        this.paletteSlider.setAnchorPoint(0.5, 0.5);

        var sliderLeft = new cc.Sprite(bundles.editor_controls.frames.preview_control);
        sliderLeft.setRotation(-90);
        sliderLeft.setScale(0.5);

        var sliderRight = new cc.Sprite(bundles.editor_controls.frames.preview_control);
        sliderRight.setRotation(90);
        sliderRight.setScale(0.5);

        this.paletteSlider.setContentSize2(this.paletteContainer.width, sliderLeft.height);
        this.paletteSlider.addChild(sliderLeft);
        this.paletteSlider.addChild(sliderRight);
        sliderLeft.setPositionRound(sliderLeft.width / 2 * sliderLeft.scale, this.paletteSlider.height / 2);
        sliderRight.setPositionRound(this.paletteSlider.width - sliderLeft.width / 2 * sliderLeft.scale, this.paletteSlider.height / 2);

        this.paletteContainer.addChild(this.paletteSlider);
        this.paletteSlider.setPositionRound(this.paletteContainer.width / 2, 0);
    },

    calcPaletteSliderPosition: function () {
        var isInRange = function (val, val1, val2) {
            var min = val1 < val2 ? val1 : val2;
            var max = min === val1 ? val2 : val1;

            return val >= min && val <= max;
        };

        for (var i = 0; i < this.mainPaletteColors.length - 1; i++) {
            var color = this.mainPaletteColors[i];
            var nextColor = this.mainPaletteColors[i + 1];
            if (isInRange(this.paletteColor.r, color.r, nextColor.r)
                && isInRange(this.paletteColor.g, color.g, nextColor.g)
                && isInRange(this.paletteColor.b, color.b, nextColor.b)) {
                var line = i * 255 + (Math.abs(this.paletteColor.r - color.r) + Math.abs(this.paletteColor.g - color.g) + Math.abs(this.paletteColor.b - color.b));

                var percent = line / (255 * (this.mainPaletteColors.length - 1) + 1);
                return cc.p(this.paletteSlider.x, this.paletteContainer.height - percent * this.paletteContainer.height);
            }
        }
    },

    calcPaletteColorByLine: function (line) {
        var colorIndex = Math.trunc(line / 255);
        var color = this.mainPaletteColors[colorIndex];
        var colorNext = this.mainPaletteColors[colorIndex + 1];
        var offset = line % 255;

        if (!colorNext) {
            colorNext = color;
        }

        return {
            r: color.r + cleverapps.sign(colorNext.r - color.r) * offset,
            g: color.g + cleverapps.sign(colorNext.g - color.g) * offset,
            b: color.b + cleverapps.sign(colorNext.b - color.b) * offset
        };
    },

    createPalette: function () {
        var styles = cleverapps.styles.ColorPickerWindow.palette;

        this.paletteContainer = new cc.Node();
        this.paletteContainer.setAnchorPoint(0.5, 0.5);

        var height = 255 * (this.mainPaletteColors.length - 1) + 1;
        var texture = ColorPicker.CreateTexture(styles.width, height, function (index) {
            var line = Math.trunc(Math.trunc(index / 3) / styles.width);
            var color = this.calcPaletteColorByLine(line);

            if (index % 3 === 0) {
                return color.r;
            } if (index % 3 === 1) {
                return color.g;
            } if (index % 3 === 2) {
                return color.b;
            }
        }.bind(this));

        var palette = this.palette = new cc.Sprite();
        palette.initWithTexture(texture, cc.rect(0, 0, styles.width, height));
        palette.setScale(styles.height / palette.height);

        this.paletteContainer.setContentSize2(palette.width * palette.scale, palette.height * palette.scale);
        this.paletteContainer.addChild(palette);

        cleverapps.UI.onDrag(this.paletteContainer, {
            instantDrag: true,

            onDragStart: function (touch) {
                var point = this.paletteContainer.convertTouchToNodeSpace(touch);
                this.setPaletteColorByPoint(point);
                return true;
            }.bind(this),

            followPointer: function (touch) {
                this.setPaletteColorByPoint(this.paletteContainer.convertTouchToNodeSpace(touch));
            }.bind(this)
        });

        palette.setPositionRound(this.paletteContainer.width / 2, this.paletteContainer.height / 2);

        return this.paletteContainer;
    },

    setPaletteColorByPoint: function (point) {
        point.y = this.clamp(point.y, 0, this.paletteContainer.height);
        this.paletteSlider.setPositionY(point.y);
        var line = Math.trunc(this.paletteContainer.height - point.y) / this.paletteContainer.height * this.palette.height;

        this.paletteColor = this.calcPaletteColorByLine(line);

        this.updateGradientThrottled();
        this.setColorByPoint(this.gradientSlider.getPosition());
    },

    setColorByPoint: function (point) {
        point.x = this.clamp(point.x, 0, this.gradientContainer.width);
        point.y = this.clamp(point.y, 0, this.gradientContainer.height);
        this.gradientSlider.setPosition(point);

        this.color = this.calcColorByPoint(point);
        this.updateRGBBoxes();
        this.onChangeCallback(new cc.Color(this.color.r, this.color.g, this.color.b, 255));
    },

    calcGradientColorPart: function (line, pixel, part) {
        var leftVal = 255 - line;
        var rightVal = part - part * (line / 255);

        return Math.round(leftVal - (leftVal - rightVal) * (pixel / 255));
    },

    calcGradientColor: function (line, pixel) {
        return {
            r: this.calcGradientColorPart(line, pixel, this.paletteColor.r),
            g: this.calcGradientColorPart(line, pixel, this.paletteColor.g),
            b: this.calcGradientColorPart(line, pixel, this.paletteColor.b)
        };
    },

    updatePaletteSlider: function () {
        this.paletteSlider.setPositionRound(this.calcPaletteSliderPosition());
    },

    updateGradientThrottled: cleverapps.throttle(50, function () {
        this.updateGradient();
    }),

    updateGradient: function () {
        var texture = ColorPicker.CreateTexture(256, 256, function (index) {
            var pixel = Math.trunc(index / 3);
            var line = Math.trunc(pixel / 256);
            var pixelInLine = pixel % 256;

            if (index % 3 === 0) {
                return this.calcGradientColorPart(line, pixelInLine, this.paletteColor.r);
            } if (index % 3 === 1) {
                return this.calcGradientColorPart(line, pixelInLine, this.paletteColor.g);
            } if (index % 3 === 2) {
                return this.calcGradientColorPart(line, pixelInLine, this.paletteColor.b);
            }
        }.bind(this));
        this.gradient.initWithTexture(texture, cc.rect(0, 0, 256, 256));
        this.gradient.setScale(this.gradientContainer.width / this.gradient.width, this.gradientContainer.height / this.gradient.height);
    },

    clamp: function (value, min, max) {
        return Math.min(Math.max(min, value), max);
    },

    calcColorByPoint: function (point) {
        var pixel = Math.round(this.clamp(point.x, 0, this.gradient.width) / this.gradient.width * 255);
        var line = Math.round((this.gradient.height - this.clamp(point.y, 0, this.gradient.height)) / this.gradient.height * 255);

        return this.calcGradientColor(line, pixel);
    },

    createGradient: function () {
        var styles = cleverapps.styles.ColorPickerWindow.gradient;

        this.gradientContainer = new cc.Node();
        this.gradientContainer.setAnchorPoint(0.5, 0.5);
        this.gradientContainer.setContentSize2(styles.width, styles.height);

        this.gradient = new cc.Sprite();
        this.gradientContainer.addChild(this.gradient);
        this.gradient.setPositionRound(this.gradientContainer.width / 2, this.gradientContainer.height / 2);

        cleverapps.UI.onDrag(this.gradientContainer, {
            instantDrag: true,

            onDragStart: function (touch) {
                var point = this.gradientContainer.convertTouchToNodeSpace(touch);
                this.setColorByPoint(point);
                return true;
            }.bind(this),

            followPointer: function (touch) {
                var point = this.gradientContainer.convertTouchToNodeSpace(touch);
                this.setColorByPoint(point);
            }.bind(this)
        });

        return this.gradientContainer;
    }
});

cleverapps.styles.ColorPickerWindow = {
    margin: 20,

    gradient: {
        width: 300,
        height: 300
    },
    palette: {
        width: 200,
        height: 300
    },
    paletteSlider: {
        height: 3
    },
    editBox: {
        width: 45,
        height: 25,
        margin: 15,
        labelMargin: 4,
        bg: {
            padding: {
                x: 4,
                y: 4
            }
        }
    },
    bg: {
        padding: {
            x: 10,
            y: 10
        }
    }
};
