/**
 * Created by mac on 9/28/20
 */

var AdminEpisodesView = cc.Node.extend({
    ctor: function (adminEpisodes, mode) {
        this._super();

        this.adminEpisodes = adminEpisodes;

        this.mode = mode;

        var size = cleverapps.resolution.getSceneSize();

        this.setContentSize2(cleverapps.styles.AdminEpisodesView.width, size.height);
        this.setAnchorPoint(0.5, 0.5);

        var bg = this.bg = cleverapps.UI.createScale9Sprite(bundles.admin.frames.common_bg);
        var padding = cleverapps.styles.AdminEpisodesView.padding;
        bg.setContentSize(this.width - padding.left, this.height - padding.y);
        bg.setPositionRound(this.width / 2 + padding.left / 2, this.height / 2);
        this.addChild(bg);

        this.createQuickNavigation();

        this.adminEpisodes.on("reset", this.reset.bind(this), this);
        this.adminEpisodes.on("setActive", this.reshape.bind(this), this);
        this.adminEpisodes.on("insert", this.insert.bind(this), this);
        this.adminEpisodes.on("remove", this.remove.bind(this), this);
        this.adminEpisodes.on("rename", this.rename.bind(this), this);
        this.adminEpisodes.on("scrollToEp", this.scrollByEpisodeNo.bind(this), this);

        this.reset();

        this.scrollByEpisodeNo(cleverapps.user.episode);
    },

    reset: function () {
        var oldPos;
        if (this.scroll) {
            oldPos = this.scroll.innerContent.height / 2 + this.scroll.innerContent.getPosition().y;
            this.scroll.removeFromParent(true);
        }

        this.viewsByEpisodeNo = {};
        var views = this.adminEpisodes.episodes.map(function (episode) {
            var adminEpisode = new AdminEpisodeView(episode);
            this.viewsByEpisodeNo[episode.episodeNo] = adminEpisode;
            return adminEpisode;
        }, this);

        var container = this.container = new cleverapps.Layout(views, {
            direction: cleverapps.UI.VERTICAL,
            margin: cleverapps.styles.AdminEpisodesView.margin
        });

        var quickNavigationTop = this.quickNavigation.y - this.quickNavigation.height / 2 - cleverapps.styles.AdminEpisodesView.margin;

        var scroll = new cleverapps.UI.ScrollView(undefined, {
            childrenVisibility: cleverapps.UI.ScrollView.CHILDREN_VISIBILITY_LINEAR
        });

        scroll.setInnerContent(container);
        scroll.setContentSize2(this.width, quickNavigationTop);
        scroll.setBarPadding(cleverapps.styles.AdminEpisodesView.scroll.barPadding);

        this.bg.addChild(scroll);
        scroll.setAnchorPoint(0, 0);
        scroll.setPositionRound(cleverapps.styles.AdminEpisodesView.scroll);

        this.scroll = scroll;

        if (oldPos !== undefined) {
            this.scroll.scrollToPoint(cc.p(-this.scroll.innerContent.x, this.scroll.innerContent.height / 2 - oldPos));
        }
    },

    reshape: function () {
        if (this.container) {
            var oldPos = this.scroll.innerContent.height / 2 + this.scroll.innerContent.getPosition().y;
            this.container.reshape();
            if (this.scroll) {
                this.scroll.updateInnerContent();
                this.scroll.scrollToPoint(cc.p(-this.scroll.innerContent.x, this.scroll.innerContent.height / 2 - oldPos));
            }
        }
    },

    sortViews: function () {
        this.adminEpisodes.episodes.slice().forEach(function (episode) {
            this.container.reorderChild(this.viewsByEpisodeNo[episode.episodeNo]);
        }, this);
        this.container.sortAllChildren();

        this.reshape();
    },

    insert: function (episode) {
        var view = this.viewsByEpisodeNo[episode.episodeNo] = new AdminEpisodeView(episode);
        view.x = this.container.width / 2;
        this.container.addChild(view);

        this.sortViews();
    },

    remove: function (episode) {
        var view = this.viewsByEpisodeNo[episode.episodeNo];
        view.removeFromParent();
        delete this.viewsByEpisodeNo[episode.episodeNo];

        this.sortViews();
    },

    rename: function (episode, oldEpisodeNo) {
        var view = this.viewsByEpisodeNo[oldEpisodeNo];
        delete this.viewsByEpisodeNo[oldEpisodeNo];

        this.viewsByEpisodeNo[episode.episodeNo] = view;

        this.sortViews();
    },

    scrollByEpisodeNo: function (episodeNo) {
        var guessEpisode = this.viewsByEpisodeNo[episodeNo];
        if (guessEpisode) {
            this.scroll.scrollTo(guessEpisode);
        }
    },

    switchMode: function (mode) {
        this.reset(mode);
    },

    createQuickNavigation: function () {
        var quickNavigation = this.quickNavigation = new AdminQuickNavigationView();
        quickNavigation.setPositionRound(cleverapps.styles.AdminEpisodesView.quickNavigation);
        quickNavigation.setLocalZOrder(1);
        this.bg.addChild(quickNavigation);
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    ADMIN_TEXT: {
        size: 21,
        name: "formal"
    },

    ADMIN_TEXT_SELECTED: {
        size: 21,
        color: cleverapps.styles.COLORS.ADMIN_TEXT_COLOR,
        name: "formal"
    }
});

cleverapps.styles.AdminEpisodesView = {
    margin: 10,
    width: 280,
    padding: {
        left: 10,
        y: 30
    },

    quickNavigation: {
        x: { align: "center", dx: -10 },
        y: { align: "top", dy: -15 }
    },

    scroll: {
        x: -15,
        y: 3,

        barPadding: {
            sidePadding: 16
        }
    }
};
