/**
 * Created by ivan on 06.04.18.
 */

var BurstCell = function (x, y) {
    BaseCell.call(this, x, y);
    this.movable = false;
    Game.currentGame.counter.registerStage(206, BurstCell.process);

    this.onBurstListener = function () {};
};

BurstCell.prototype = Object.create(BaseCell.prototype);
BurstCell.prototype.constructor = BurstCell;

BurstCell.prototype.load = function (data) {
    this.lives = parseInt(data[1]);
};

BurstCell.prototype.bundleId = function () {
    return "burst";
};

BurstCell.prototype.save = function () {
    return BurstCell.CODES.concat([this.lives]);
};

BurstCell.prototype.getViewClass = function () {
    return BurstCellView;
};

BurstCell.prototype.hurt = function () {
    if (this.lives < 1) {
        return;
    }

    this.lives--;
    this.onChangeLivesListener();
};

BurstCell.prototype.boom = function () {
    this.hurt();
};

BurstCell.prototype.burstDuration = function (targetCell, id) {
    var dx = targetCell.x - this.x;
    var dy = targetCell.y - this.y;
    return BurstCell.BURST_INTERVAL * id + Math.sqrt(dx * dx + dy * dy) / BurstCell.BURST_SPEED;
};

BurstCell.prototype.processTo = function (cell, id) {
    BurstCell.changed[cell.y][cell.x] = true;
    this.onBurstListener(cell, id);
    Game.currentGame.counter.setTimeout(function () {
        cell.hurt();
    }, this.burstDuration(cell, id) * 1000);
};

BurstCell.prototype.process = function () {
    if (!Game.currentGame) {
        return;
    }

    var variants = [], i, j;
    for (i = 0; i < Field.SIZE; i++) {
        for (j = 0; j < Field.SIZE; j++) {
            if (!BurstCell.changed[i][j] && Game.currentGame.field.cells[i][j]) {
                var currentCell = Game.currentGame.field.cells[i][j];
                if (currentCell.hurtable && !(currentCell instanceof CandleCell && currentCell.state === CandleCell.STATE_ON)) {
                    variants.push(currentCell);
                }
            }
        }
    }
    if (variants.length > 0) {
        var maxBurstDuration = 0;
        for (i = 0; i < BurstCell.BURST_COUNT && variants.length > 0; i++) {
            var vid = cleverapps.Random.random(variants.length);
            this.processTo(variants[vid], i);
            maxBurstDuration = Math.max(maxBurstDuration, this.burstDuration(variants[vid]));
            variants.splice(vid, 1);
        }

        this.animate(BaseCell.ANIMATION_OPEN);

        Game.currentGame.counter.setTimeout(function () {
            this.lives = BurstCell.MAX_LIVES;
            this.onChangeLivesListener();
        }.bind(this), maxBurstDuration * 1000);
    }
};

BurstCell.process = function () {
    var toProcess = [], i, j;
    for (i = 0; i < Field.SIZE; i++) {
        for (j = 0; j < Field.SIZE; j++) {
            if (Game.currentGame.field.cells[i][j] && Game.currentGame.field.cells[i][j].constructor === BurstCell) {
                if (Game.currentGame.field.cells[i][j].lives === 0) {
                    toProcess.push(Game.currentGame.field.cells[i][j]);
                }
            }
        }
    }

    if (toProcess.length > 0) {
        BurstCell.changed = [];
        for (i = 0; i < Field.SIZE; i++) {
            BurstCell.changed.push([]);
            for (j = 0; j < Field.SIZE; j++) {
                BurstCell.changed.push(false);
            }
        }
        for (i = 0; i < toProcess.length; i++) {
            toProcess[i].process();
        }
    }
};

BurstCell.BURST_COUNT = 5;
BurstCell.MAX_LIVES = 4;
BurstCell.BURST_SPEED = 12;
BurstCell.BURST_INTERVAL = 0.1;
