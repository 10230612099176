/**
 * Created by slava on 01.08.17.
 */

var MouseCell = function (x, y) {
    BaseCell.call(this, x, y, MouseCell);

    this.addComponent(EaterComponent, { cellClass: MouseCell });
    this.addComponent(LiveLineComponent);
};

MouseCell.prototype = Object.create(BaseCell.prototype);
MouseCell.prototype.constructor = MouseCell;

MouseCell.prototype.bundleId = function () {
    return "mouse";
};

MouseCell.prototype.getViewClass = function () {
    return EaterCellView;
};

MouseCell.prototype.getGoalId = function () {
    return MouseCell.GOAL_ID;
};

MouseCell.prototype.getAcceptedColors = function () {
    return MouseCell.ACCEPTED_COLORS;
};

MouseCell.GOAL_ID = "mouse_cell";

MouseCell.ACCEPTED_COLORS = ["a"];