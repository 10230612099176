/**
 * Created by vladislav on 23.08.2019
 */

var PackProductTile = BaseProductTile.extend({
    ctor: function (tileModel, options) {
        var styles = PackProductTile.styles = PackProductTile.styles || cleverapps.overrideStyles(cleverapps.styles.BaseProductTile, cleverapps.styles.PackProductTile, true);

        options = options || {};

        this._super(tileModel, styles, options);
    },

    createDescription: function () {
        if (!this.styles.description) {
            return;
        }

        var styles = this.styles.description;

        var node = new cc.Node();
        node.setAnchorPoint(0.5, 0.5);
        node.setContentSize2(styles);

        if (bundles.tile_shop.frames.description_bg) {
            var bg = cleverapps.UI.createScale9Sprite(bundles.tile_shop.frames.description_bg, cleverapps.UI.Scale9Rect.TwoPixelY);
            bg.setContentSize2(styles.bg);
            node.addChild(bg);
            bg.setPositionRound(styles.bg);
        }
        
        var content = new TileRewardsBlock(this.tileModel, {
            size: cc.size(this.styles.description),
            font: cleverapps.styles.FONTS.PACK_TILE_REWARDS_TEXT,
            icons: {},
            paddingY: this.styles.description.paddingY
        });

        content.setPositionRound(node.width / 2, node.height / 2);
        node.addChild(content);

        return node;
    }
});

cleverapps.styles.PackProductTile = {

};
