/**
 * Created by ivan on 10.05.18.
 */

var LollipopCell = function (x, y, color) {
    BaseCell.call(this, x, y);

    this.addComponent(ColorComponent, { color: color });
    this.addComponent(SpecialColorComponent);
    this.addComponent(MarkComponent);

    Game.currentGame.counter.registerStage(208, LollipopCell.process);

    this.putInPoolAvailable = true;

    this.onChangeColorListener = function () {};
};

LollipopCell.prototype = Object.create(BaseCell.prototype);
LollipopCell.prototype.constructor = LollipopCell;
Skinify(LollipopCell, "lollipop");

LollipopCell.prototype.getGoalId = function () {
    return LollipopCell.GOAL_ID;
};

LollipopCell.prototype.getViewClass = function () {
    return LollipopCellView;
};

LollipopCell.prototype.explode = function (params) {
    var markComponent = this.findComponent(MarkComponent);
    if (markComponent) {
        markComponent.explode();
    }

    this.explodeViaCollect(params);
};

LollipopCell.prototype.smile = function () {
    if (this.alive && !this.isForGoal) {
        this.animate(BaseCell.ANIMATION_SMILE);
    }
};

LollipopCell.prototype.process = function () {
    var colorComponent = this.findComponent(ColorComponent);

    var index = -1;
    for (var i = 0; i < Game.currentGame.levelContent.colors.length; i++) {
        var color = Game.currentGame.levelContent.colors[i];
        if (color.toLowerCase() === colorComponent.color) {
            index = i;
            break;
        }
    }

    var oldColor = colorComponent.color;
    index = (index + 1) % Game.currentGame.levelContent.colors.length;
    colorComponent.setColor(Game.currentGame.levelContent.colors[index].toLowerCase());

    this.onChangeColorListener(this, oldColor, colorComponent.color);
    Game.currentGame.counter.setTimeout(function () {
    }, 900);

    return this;
};

LollipopCell.prototype.load = function (data) {
    var color = data[1];
    var colorComponent = this.findComponent(ColorComponent);
    colorComponent.setColor(color === "." ? this.getRandomColor() : color);

    this.findComponent(MarkComponent).load(data);
};

LollipopCell.prototype.save = function () {
    var colorComponent = this.findComponent(ColorComponent);
    var code = LollipopCell.CODES.slice();
    code.push(colorComponent.color);

    var markCode = this.findComponent(MarkComponent).save();
    if (markCode) {
        code.push(markCode);
    }

    return code;
};

LollipopCell.process = function () {
    if (!Game.currentGame.lollipopCellCanProcess) {
        return;
    }
    Game.currentGame.lollipopCellCanProcess = false;

    for (var i = 0; i < Field.SIZE; i++) {
        for (var j = 0; j < Field.SIZE; j++) {
            var cell = Game.currentGame.field.cells[i][j];
            if (cell && cell.constructor === LollipopCell) {
                cell.process();
            }
        }
    }
};

LollipopCell.GOAL_ID = "lollipop";