/**
 * Created by Andrey Popov on 20.01.2021
 */
var AdsFireworks = Fireworks.extend({
    ctor: function (properties) {
        properties = properties || {};

        this.fireworksSound = properties.sound ? cleverapps.wysiwyg.googleDriveProvider.getLink(properties.sound) : undefined;

        this._super({
            sound: this.fireworksSound,
            json: cleverapps.config.type === "merge" ? bundles.ads_fireworks.jsons.confetti_json : undefined
        });

        this.setAnchorPoint(0.5, 0.5);
    },

    hide: function () {
        this.setVisible(false);

        this.sound = undefined;
    },

    show: function () {
        this.setVisible(true);

        this.sound = this.fireworksSound;
    }
});

AdsFireworks.BUNDLES = ["ads_fireworks"];

AdsFireworks.PROPERTIES = [{
    name: "position", value: { x: { align: "center", dx: "-50%" }, y: { align: "center", dy: "-50%" } }
}, {
    name: "sound", type: "sound", folders: ["sounds"]
}, {
    name: "loop", type: "checkBox", value: true
}];
