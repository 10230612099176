/**
 * Created by slava on 02.08.17.
 */

var BoxDecoratorCell = function (x, y, options) {
    BaseCell.call(this, x, y, options);

    this.hideInnerCell = true;
};

BoxDecoratorCell.prototype = Object.create(BaseCell.prototype);
BoxDecoratorCell.prototype.constructor = BoxDecoratorCell;

BoxDecoratorCell.prototype.getViewClass = function () {
    return BoxDecoratorCellView;
};

BoxDecoratorCell.prototype.load = function (data, loader) {
    data.shift();
    if ("234".indexOf(data[0]) >= 0) {
        this.lives = parseInt(data[0]);
        data.shift();
    }
    this.setInnerCell(loader.loadNext(this.y, this.x, data));
};

BoxDecoratorCell.prototype.smile = function () {
    if (this.alive && this.lives > 0) {
        this.animate(BaseCell.ANIMATION_SMILE);
    }
};

BoxDecoratorCell.prototype.save = function () {
    var saveData = BoxDecoratorCell.CODES;
    if (this.lives > 1) {
        saveData = saveData.concat(this.lives);
    }
    return saveData.concat(this.innerCell.save());
};

BoxDecoratorCell.prototype.boom = function () {
    var decoratorComponent = this.findComponent(DecoratorComponent);
    decoratorComponent.boomHurt();
};

BoxDecoratorCell.prototype.onExplode = function () {
    var decoratorComponent = this.findComponent(DecoratorComponent);
    decoratorComponent.onExplode(); // inner cell must be visible immediately
};

BoxDecoratorCell.prototype.bundleId = function () {
    return "box";
};

BoxDecoratorCell.prototype.getGoalId = function () {
    return BoxDecoratorCell.GOAL_ID;
};

BoxDecoratorCell.GOAL_ID = "box_decorator";
