/**
 * Created by vladislav on 03.10.18.
 */

var BoosterAdvice = function (field) {
    this.field = field;

    this.boosterAlgorithms = [CellHintAlgorithm, LineHintAlgorithm, ColorHintAlgorithm].map(function (AlgorithmClass) {
        return new AlgorithmClass(field);
    });

    this.movesSinceShown = 0;

    this.onShowAdvice = function () {};
    this.onStopAdvice = function () {};
};

BoosterAdvice.prototype.showAdvice = function () {
    this.clearTimeout();
    this.movesSinceShown++;

    if (!Game.currentGame || Game.currentGame.stopped || !this.isReady()) {
        return;
    }

    var advice = this.getAdvice();
    if (!advice) {
        return;
    }

    this.showTimeout = setTimeout(function () {
        this.movesSinceShown = 0;
        this.onShowAdvice(advice);
    }.bind(this), 2000);
};

BoosterAdvice.prototype.isReady = function () {
    return this.movesSinceShown > 2 && !cleverapps.config.wysiwygMode;
};

BoosterAdvice.prototype.clearTimeout = function () {
    if (this.showTimeout) {
        clearTimeout(this.showTimeout);
        this.showTimeout = undefined;
    }
};

BoosterAdvice.prototype.disable = function () {
    this.disabled = true;
    this.clearTimeout();
    this.onStopAdvice();
};

BoosterAdvice.prototype.enable = function () {
    this.disabled = false;
    this.showAdvice();
};

BoosterAdvice.prototype.getAdvice = function () {
    var actives = this.boosterAlgorithms.map(function (algorithm) {
        if (Game.currentGame.boosters.list[algorithm.getId()].isDisabled()) {
            return;
        }

        var res = algorithm.getResult();
        return res ? {
            target: res,
            boosterId: algorithm.getId()
        } : undefined;
    }).filter(function (res) {
        return res !== undefined;
    });

    return cleverapps.Random.choose(actives);
};

BoosterAdvice.isEnabled = function () {
    return !cleverapps.config.wysiwygMode;
};
