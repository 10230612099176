/**
 * Created by decipaliz on 03.08.2024
 */

var LevelsView = cc.Node.extend({
    ctor: function (page) {
        this._super();
        this.page = page;
    },

    onLoad: function () {
        if (this.page.isComingSoon()) {
            return;
        }

        var levelViews = Level.CreateLevels(this.page.pageNo).map(function (level) {
            return new LevelView(level, this);
        }, this);

        levelViews.reverse().forEach(this.addChild.bind(this));
    }
});

cleverapps.styles.LevelsView = {
    levels_1: undefined,
    levels_2: undefined
};