/**
 * Created by anatoly on 02.10.2024
 */

var GoalsCounterView = cc.Node.extend({
    ctor: function (editor) {
        this._super();
        this.addText();

        cleverapps.UI.wrap(this);

        this.setPositionRound(cleverapps.styles.GoalsCounterView);
        this.onGoalsUpdate(editor.checkValidGoalsCount());

        editor.onGoalsChanged = this.onGoalsUpdate.bind(this);
    },

    addText: function () {
        this.text = cleverapps.UI.generateTTFText("Level contain too many goals!", cleverapps.styles.FONTS.EDITOR_BLUE_TEXT);
        this.addChild(this.text);
    },

    onGoalsUpdate: function (moreThanMax) {
        var text = this.text;

        if (moreThanMax) {
            text.setFontFillColor(cleverapps.styles.COLORS.DARK_RED);
            text._setUpdateTextureDirty();
            text.setString("Level contain too many goals!");
        } else {
            text.setFontFillColor(cleverapps.styles.COLORS.COLOR_BLUE);
            text._setUpdateTextureDirty();
            text.setString("Level contain valid count of goals!");
        }
    }
});

cleverapps.styles.GoalsCounterView = {
    x: { align: "center", dx: 650 },
    y: { align: "top", dy: -110 }
};