/**
 * Created by Vladislav on 20.09.2024.
 */

cleverapps.Tool.flyingAd = ToolFolder.create(function () {
    var res = {};
    Object.keys(FlyingAd.TYPES).forEach(function (type) {
        if (!FlyingAd.DATA[FlyingAd.TYPES[type]].filter({ tool: true })) {
            return;
        }
        res[type] = function () {
            cleverapps.flyingAd.setDelay(0);
            FlyingAd.DEBUG_TYPE = FlyingAd.TYPES[type];
            cleverapps.flyingAd.update();
        };
    });

    res.reset = function () {
        delete FlyingAd.DEBUG_TYPE;
        cleverapps.flyingAd.update();
    };

    return res;
});