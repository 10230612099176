/**
 * Created by vladislav on 4/12/19
 */
var EditorWindow = cc.Node.extend({
    ctor: function (content, cell, view, options) {
        this._super();

        this.options = options || {};
        this.content = content;
        this.onCloseListener = this.options.onClose || function () {};

        this.createBg();
        this.createCloseButton();

        cleverapps.UI.onClick(this, function () {});
    },

    createBg: function () {
        var styles = this.getStyles();
        var contentSize = this.content.getContentSize();

        this.setAnchorPoint(0.5, 0.5);

        var bg = this.bg = new cc.Scale9Sprite(new cc.Sprite(bundles.request_center.frames.friend_message_bg_png).getSpriteFrame());
        bg.setAnchorPoint(0.5, 0.5);
        bg.setContentSize2(contentSize.width + 2 * styles.padding.x, contentSize.height + 2 * styles.padding.y);
        this.setContentSize2(bg.getContentSize());

        bg.addChild(this.content);
        this.content.setPositionRound(this.width / 2, this.height / 2);

        this.addChild(bg);
        bg.setPositionRound(this.width / 2, this.height / 2);
    },

    createCloseButton: function () {
        var styles = this.getStyles();
        var closeButton = new cleverapps.UI.Button({
            type: {
                button_png: bundles.buttons_main.frames.close_small,
                button_on_png: bundles.buttons_main.frames.close_small_on
            },
            onClicked: this.close.bind(this)
        });
        this.bg.addChild(closeButton);
        closeButton.setPositionRound(styles.closeButton);
    },

    close: function () {
        this.removeFromParent(true);
        this.onCloseListener();
    },

    getStyles: function () {
        return cleverapps.styles.EditorWindow;
    }
});

EditorWindow.create = function (content, cell, view, options) {
    var window = new EditorWindow(content, cell, view, options);
    var scene = cleverapps.scenes.getRunningScene();
    scene.addChild(window);

    var sceneSize = cleverapps.resolution.getSceneSize();
    var pos = view.getParent().convertToWorldSpace(view.getPosition());

    var checkIfFits = function (winPos) {
        return winPos.x + window.width / 2 < sceneSize.width
            && winPos.x - window.width / 2 > 0
            && winPos.y + window.height / 2 < sceneSize.height
            && winPos.y - window.height / 2 > 0;
    };

    var positions = [];

    var offset = window.getStyles().elementOffset;

    positions.push({
        x: pos.x + view.width / 2 + window.width / 2 + offset,
        y: pos.y
    });

    positions.push({
        x: pos.x - view.width / 2 - window.width / 2 - offset,
        y: pos.y
    });

    positions.push({
        x: pos.x,
        y: pos.y + view.height / 2 + window.height / 2 + offset
    });

    positions.push({
        x: pos.x,
        y: pos.y - view.height / 2 - window.height / 2 - offset
    });

    var selectedPos = positions[0];
    for (var i = 0; i < positions.length; i++) {
        if (checkIfFits(positions[i])) {
            selectedPos = positions[i];
            break;
        }
    }

    window.setPositionRound(selectedPos);

    return window;
};

cleverapps.styles.EditorWindow = {
    padding: {
        x: 20,
        y: 20
    },
    closeButton: {
        x: { align: "right", dx: 10 },
        y: { align: "top", dy: 10 }
    },
    elementOffset: 20
};