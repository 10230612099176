/**
 * Created by Denis Kuzin on 04 june 2024
 */

var DecoratorComponent = function (cell) {
    this.cell = cell;

    this.onExplodeHurt = 0;
    this.onExplodeBoomCounter = 0;

    this.cell.innerCell = undefined;
};

DecoratorComponent.prototype.load = function (data, loader) {
    data.shift();
    this.cell.setInnerCell(loader.loadNext(this.cell.y, this.cell.x, data));
};

DecoratorComponent.prototype.boomHurt = function (explodeParams) {
    if (this.cell.lives < 1 || !this.cell.alive) {
        this.onExplodeBoomCounter++;
        return;
    }

    this.cell.hurt(explodeParams);
};

DecoratorComponent.prototype.hurt = function () {
    if (this.cell.lives < 1 || !this.cell.alive) {
        this.onExplodeHurt++;
        return;
    }

    return true;
};

DecoratorComponent.prototype.canRepaint = function (inShuffle) {
    if (inShuffle) {
        return false;
    }
    if (this.cell.getColor()) {
        return this.cell.innerCell.canRepaint();
    }
    return false;
};

DecoratorComponent.prototype.repaint = function () {
    if (this.cell.canRepaint()) {
        this.cell.setInnerCell(this.cell.innerCell.repaint());
    }
    return this.cell;
};

DecoratorComponent.prototype.onExplode = function () {
    this.cell.innerCell.viewExists = true;
    Game.currentGame.field.addCell(this.cell.innerCell); // need to put cell on field and register onAfterExplodeListener
    if (!this.cell.innerCell.visible) {
        this.cell.innerCell.changeVisible(true); // to push visible flag to inners
    }

    for (var i = 0; i < this.onExplodeHurt; i++) {
        this.cell.innerCell.hurt();
    }

    if (this.onExplodeBoomCounter > 0) {
        this.cell.innerCell.boom(this.onExplodeBoomCounter, this.cell);
    }

    this.cell.setInnerCell(undefined);
};

DecoratorComponent.prototype.explode = function (params) {
    if (this.cell.getGoalId() && Game.currentGame.goals.hasType(this.cell.getGoalId())) {
        this.cell.explodeViaCollect(params);
        return;
    }

    return true;
};

DecoratorComponent.prototype.animate = function (type, params) {
    if (this.cell.innerCell) {
        if ([BaseCell.ANIMATION_SHOWUP, BaseCell.ANIMATION_TRANSPORTER_LOOP_START, BaseCell.ANIMATION_TRANSPORTER_LOOP_FINISH].indexOf(type) !== -1) { // should not apply other animations to innerCell
            this.cell.innerCell.animate(type, params);
        }
    }
};

DecoratorComponent.prototype.move = function (destination, options, onFinishCallback) {
    if (this.cell.innerCell) {
        this.cell.innerCell.move(destination, options, onFinishCallback);
    }
};

DecoratorComponent.prototype.hover = function (state) {
    if (this.cell.innerCell) {
        this.cell.innerCell.hover(state);
    }

    return true;
};

DecoratorComponent.prototype.afterMove = function (destination) {
    var curCell = this.cell;
    while (curCell.innerCell) {
        var deeperCell = curCell.innerCell;
        deeperCell.x = destination.x;
        deeperCell.y = destination.y;

        curCell = deeperCell;
    }
};