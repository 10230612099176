/**
 * Created by ivan on 17.01.18.
 */

var CrabMakerCell = function (x, y) {
    BaseCell.call(this, x, y);
    this.movable = false;
    this.hurtable = false;
    this.stage = 0;
    Game.currentGame.counter.registerStage(217, CrabMakerCell.process);

    this.onChangeStage = function () {};
};

CrabMakerCell.prototype = Object.create(BaseCell.prototype);
CrabMakerCell.prototype.constructor = CrabMakerCell;

CrabMakerCell.prototype.load = function (data) {
    this.stage = parseInt(data[1]);
};

CrabMakerCell.prototype.bundleId = function () {
    return ["crab_maker"];
};

CrabMakerCell.prototype.save = function () {
    return CrabMakerCell.CODES.concat([this.stage]);
};

CrabMakerCell.prototype.getViewClass = function () {
    return CrabMakerCellView;
};

CrabMakerCell.prototype.makeCrab = function () {
    if (Game.currentGame === undefined) {
        return;
    }
    var crabCell = new CrabDecoratorCell(this.x, this.y, { components: [DecoratorComponent] });
    crabCell.setInnerCell(this);
    crabCell.createdByCrabMaker = true;
    Game.currentGame.field.addCell(crabCell);
    Game.currentGame.crabDecoratorCellCanProcess = false;
};

CrabMakerCell.prototype.boom = function () {
    this.skipProcess = true;
};

CrabMakerCell.prototype.process = function () {
    if (this.stage === CrabMakerCell.STAGES_COUNT) {
        this.stage = 0;
    } else {
        this.stage++;
    }
    this.onChangeStage(function () {
        if (this.stage === CrabMakerCell.STAGES_COUNT) {
            this.makeCrab();
        }
    }.bind(this));
};

CrabMakerCell.process = function () {
    if (!Game.currentGame.crabMakerCellCanProcess) {
        return;
    }

    Game.currentGame.crabMakerCellCanProcess = false;
    for (var i = 0; i < Field.SIZE; i++) {
        for (var j = 0; j < Field.SIZE; j++) {
            if (Game.currentGame.field.cells[i][j] && Game.currentGame.field.cells[i][j].constructor === CrabMakerCell) {
                if (Game.currentGame.field.cells[i][j].skipProcess) {
                    Game.currentGame.field.cells[i][j].skipProcess = false;
                } else {
                    Game.currentGame.field.cells[i][j].process();
                }
            }
        }
    }
};

CrabMakerCell.STAGES_COUNT = 3;