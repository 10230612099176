/**
 * Created by slava on 22/2/18
 */
var EditorField = cc.Node.extend({
    ctor: function () {
        this._super();

        this.setAnchorPoint(0.5, 0.5);

        this.fieldRect = new cc.DrawNode();
        this.fieldRect.setLocalZOrder(-1);
        this.fieldRect.setAnchorPoint(0.5, 0.5);
        this.addChild(this.fieldRect);

        this.setupChildren();
        this.updateSize();

        cleverapps.UI.onClick(this, function (touch) {
            var point = this.convertTouchToNodeSpace(touch);
            return this.onClick(Math.floor(point.x / cleverapps.styles.BaseCellView.CELL_SIZE_PX), Math.floor((9 * cleverapps.styles.BaseCellView.CELL_SIZE_PX - point.y) / cleverapps.styles.BaseCellView.CELL_SIZE_PX));
        }.bind(this), { interactiveScale: false });

        this.onClick = function () {};
    },

    drawFieldRect: function (size) {
        this.fieldRect.clear();
        this.fieldRect.drawRect(cc.p(0, 0), cc.p(cleverapps.styles.BaseCellView.CELL_SIZE_PX * size, -cleverapps.styles.BaseCellView.CELL_SIZE_PX * size), new cc.Color(255, 255, 255, 50), 2);
        this.setupChildren();
        this.updateSize();
    },

    updateSize: function () {
        var maxFieldSize = 9;
        if (Field.SIZE > maxFieldSize) {
            this.setScale(0.7 * maxFieldSize / Field.SIZE);
            this.setContentSize(cleverapps.styles.BaseCellView.CELL_SIZE_PX * Field.SIZE, cleverapps.styles.BaseCellView.CELL_SIZE_PX * Field.SIZE);
        } else {
            this.setScale(0.7);
            this.setContentSize(cleverapps.styles.BaseCellView.CELL_SIZE_PX * maxFieldSize, cleverapps.styles.BaseCellView.CELL_SIZE_PX * maxFieldSize);
        }
    },

    setupChildren: function () {
        this.setPositionRound(cleverapps.styles.EditorField);
        this.fieldRect.setPositionRound(cleverapps.styles.EditorField.fieldRect);
    }
});

cleverapps.styles.EditorField = {
    x: { align: "center" },
    y: { align: "top", dy: -230 },
    fieldRect: {
        x: { align: "left" },
        y: { align: "top" }
    }
};