/**
 * Created by andrey on 23.08.18.
 */

var CoefAnimation = BaseAnimation.extend({
    ctor: function () {
        this._super(bundles.coef.jsons.coef_animation_json);
    }
});

CoefAnimation.fillPool = function () {
    for (var i = 0; i < 6; i++) {
        cc.pool.putInPool(new CoefAnimation());
    }
};

CoefAnimation.factory = function () {
    return cc.pool.hasObject(CoefAnimation) ? cc.pool.getFromPool(CoefAnimation) : undefined;
};