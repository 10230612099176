/**
 * Created by andrey on 16.03.18.
 */

var MuffinCellView = BaseCellView.extend({
    ctor: function (muffinCell) {
        this._super();

        this.setContentSize(cleverapps.styles.BaseCellView.CELL_SIZE_PX, cleverapps.styles.BaseCellView.CELL_SIZE_PX);
        this.setAnchorPoint(0.5, 0.5);

        this.initialize(muffinCell);
    },

    finishSmile: function () {
        var muffinComponent = this.cell.findComponent(MuffinComponent);
        muffinComponent.view.finishSmile();
    },

    smile: function () {
        var muffinComponent = this.cell.findComponent(MuffinComponent);
        muffinComponent.view.smile();
    }
});
