/**
 * Created by vladislav on 1/26/2024
 */

var OKLogic = function () {
    PlatformLogic.call(this);

    connector.platform.on("pushPermission", function (isGranted) {
        cleverapps.info.setKeyValue("pushPermission", isGranted, true);
    });

    if (!connector.info.isMobile) {
        connector.platform.whenConnected(this.updateSize.bind(this));
    }
};

OKLogic.prototype = Object.create(PlatformLogic.prototype);
OKLogic.prototype.constructor = OKLogic;

OKLogic.prototype.updateSize = function () {
    connector.platform.getPlugin().listeners.getPageInfo = function (result, data) {
        var height;
        try {
            height = JSON.parse(data).innerHeight;
        } catch (e) {
            height = window && window.innerHeight || document && document.body && document.body.clientHeight || 800;
        }
        var h = height - 75;
        if (h < 600) {
            h = 600;
        }
        FAPI.UI.setWindowSize(760, h);
        cleverapps.resolution.setupDesignResolution(cc.size(cc.view.getFrameSize().width, h));
    };

    [0, 5, 10, 20, 60].forEach(function (timeout) {
        setTimeout(FAPI.UI.getPageInfo, timeout * 1000);
    });
};

OKLogic.prototype.reportStatus = function (event, status) {
    if (!connector.platform.isConnected()) {
        return;
    }

    console.log("setStatus", event, status);
    FAPI.Client.call({
        method: "sdk.setStatus",
        event: event,
        status: status
    }, function (code, data, error) {
        console.log("sdk.setStatus response", JSON.stringify(arguments));

        var logEvent = "set_status_ok";
        if (code !== "ok") {
            logEvent = "set_status_error";

            if (error && (error.error_msg || error.error_code)) {
                cleverapps.throwAsync("sdk.setStatus event: '" + event + "' status: '" + status + "' error: " + JSON.stringify(error));
            }
        }
        cleverapps.eventLogger.logEvent(logEvent);
    });
};

OKLogic.prototype.getPushCode = function () {
    var rParams = FAPI.Util.getRequestParameters();

    var pushCode = cleverapps.getRequestParameters("?" + rParams.custom_args).push_code;
    if (pushCode) {
        return pushCode;
    }
    var push = cleverapps.LocalPushes.GetTypeById(rParams.custom_args);
    if (push) {
        return push.code;
    }
};

OKLogic.prototype.logPushes = function () {
    var pushCode = this.getPushCode();
    if (pushCode) {
        cleverapps.localPushes.logClickEvent(pushCode);
    }
};
