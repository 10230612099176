/**
 * Created by Andrey Popov on 3/16/21.
 */

var DialogueView = CleverappsWindow.extend({
    onWindowLoaded: function (dialogue, options) {
        this.options = options || {};

        this.dialogue = dialogue;
        var styles = cleverapps.styles.DialogueView;

        this._super({
            name: "DialogueViewWindow",
            content: new cc.Node(),
            closeButton: false,
            closeByBackButton: false,
            noBackground: true,
            styles: cleverapps.overrideStyles(styles.Window, this.options.window, true),
            noScaleAdjustment: true,
            openSound: bundles.dialogues.urls.dialogue_effect
        });

        this.generateContent();

        dialogue.on("showUp", this.showUp.bind(this), this);
        dialogue.on("close", this.close.bind(this), this);

        dialogue.on("addPerson", this.addPerson.bind(this), this);
        dialogue.on("hidePerson", this.hidePerson.bind(this), this);
        dialogue.on("updateActive", this.updateActive.bind(this), this);
        dialogue.on("changeText", this.changeText.bind(this), this);
        dialogue.on("finishTyping", this.byLetterAnimationFinish.bind(this), this);
    },

    onShow: function () {
        if (!this.dialogue.isStarted()) {
            this.dialogue.run();
        }
    },

    setupChildren: function () {
        this._super();

        var sceneSize = cleverapps.resolution.getSceneSize();
        var styles = cleverapps.styles.DialogueView;

        this.contentWrap.setContentSize(sceneSize);

        var x = cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? 0 : styles.widthPadding;
        this.content.setContentSize2(sceneSize.width - 2 * x, styles.size[cleverapps.resolution.mode].height);
        this.dialogueContent.setContentSize2(sceneSize.width, this.content.height);
        var safePadding = cleverapps.resolution.getSafePadding();
        this.background.setContentSize2(this.content.width, this.content.height + safePadding.bottom);
        this.background.baseContentSize = this.background.getContentSize();

        var contentWrapPosition = styles.position;

        this.contentWrap.setPositionRound(contentWrapPosition);

        this.content.stopAllActions();
        this.content.setOpacity(255);
        this.content.setPositionRound(x, styles.offsetY);
        this.background.setPositionRound({ align: "left" }, { align: "top" });

        [this.leftPersonView, this.rightPersonView].filter(Boolean).forEach(function (person) {
            person.stopAllActions();
            person.setVisible(true);
            this.updatePersonSize(person);
            this.updatePersonPosition(person);
        }.bind(this));

        if (this.text) {
            this.text.setupChildren();
        }
    },

    updatePersonSize: function (person) {
        var styles = cleverapps.styles.DialogueView.persons;
        var scale = 1;
        if (cleverapps.resolution.mode !== cleverapps.WideMode.HORIZONTAL) {
            scale = cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? styles.mobileScale : styles.squareScale;
        }

        var scaleX = scale;
        if (person.getOrientation() !== "left") {
            scaleX = -scaleX;
        }
        person.setScale(scaleX, scale);
    },

    updatePersonPosition: function (person) {
        var styles = cleverapps.styles.DialogueView;

        var scale = Math.abs(person.getScaleX());
        var x = styles.persons[person.getOrientation()][cleverapps.resolution.mode].x;
        var y = this.content.height + styles.persons.offsetY * scale;

        person.setPositionRound(x, y);

        var center = cc.p(this.contentWrap.width / 2, y);
        var personBox = person.getBoundingBox();

        if (cc.rectContainsPoint(personBox, center)) {
            var dx = styles.persons.margin / 2 + person.width / 2 * scale;
            person.setPositionRound(
                person.getOrientation() === "left" ? center.x - dx : center.x + dx,
                y
            );
        }

        var position = person.getPosition();

        person.showPosition = position;
        person.hidePosition = cc.p(
            person.getOrientation() === "left" ? -person.width - this.content.x : this.content.x + this.content.width + person.width,
            position.y
        );
        var personTitle = person.title;
        personTitle.setPositionRound(person.x, cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? styles.title.mobileY : styles.title.y);

        var titleBox = personTitle.getBoundingBox();
        var offsetX = styles.title.offsetX;
        if (titleBox.x < offsetX || titleBox.x + titleBox.width > personTitle.parent.width - offsetX) {
            personTitle.setPositionRound(
                person.getOrientation() === "left" ? { align: "left", dx: offsetX } : { align: "right", dx: -offsetX },
                personTitle.y
            );
        }

        var personArrow = person.arrow;

        personArrow.setPositionRound(
            person.getOrientation() === "left"
                ? Math.max(personBox.x + personBox.width, titleBox.x + titleBox.width) + personArrow.width / 2
                : Math.min(personBox.x, titleBox.x) - personArrow.width / 2,
            styles.arrow.y
        );
    },

    generateContent: function () {
        var content = this.content = new cc.Node();
        content.setAnchorPoint(0, 0);
        content.setCascadeOpacityEnabled(true);

        var background = this.background = cleverapps.UI.createScale9Sprite(bundles.dialogues.frames.bg_png, cleverapps.UI.Scale9Rect.TwoPixelXY);
        background.setAnchorPoint(0, 0);
        background.setCascadeOpacityEnabled(true);
        content.addChild(background);

        var dialogueContent = this.dialogueContent = new cc.Node();
        dialogueContent.avoidNode = "DialogueView";
        dialogueContent.setVisible(false);
        dialogueContent.setAnchorPoint(0, 0);
        dialogueContent.addChild(content);

        var contentWrap = this.contentWrap = new cc.Node();
        contentWrap.setAnchorPoint(0, 0.5);
        contentWrap.addChild(dialogueContent);

        cleverapps.UI.onClick(contentWrap, function () {
            this.dialogue.screenClicked();
            return true;
        }.bind(this), {
            interactiveScale: false
        });
        contentWrap.setContentSize(cleverapps.resolution.getSceneSize());
        contentWrap.setLocalZOrder(1);
        this.addChild(contentWrap);
    },

    showUp: function (silent, onShow) {
        var animationStyle = cleverapps.styles.DialogueView.showAnimation;
        if (silent) {
            this.dialogueContent.setVisible(true);
            onShow();
            return;
        }

        if (animationStyle === "fromBelow") {
            this.showUpFromBelow(onShow);
        } else {
            this.showUpFromSides(onShow);
        }
    },

    showUpFromSides: function (onShow) {
        var baseContentSize = this.background.baseContentSize;
        this.background.setContentSize(baseContentSize.width * 0.6, baseContentSize.height);
        this.content.setOpacity(0);
        if (this.textBgArrow) {
            this.textBgArrow.setVisible(false);
        }

        [this.leftPersonView, this.rightPersonView].filter(Boolean).forEach(function (person) {
            var delay = person.isActive ? 0 : 0.25;

            person.setPosition(person.hidePosition);
            person.setVisible(false);

            person.runAction(new cc.Sequence(
                new cc.DelayTime(delay),
                new cc.Show(),
                new cc.MoveTo(0.35, person.showPosition).easing(cc.easeBackOut()),
                new cc.CallFunc(function () {
                    cleverapps.scenes.onAvoidNodeVisibilityChanged();
                })
            ));
        });

        this.background.runAction(new cc.Sequence(
            new cc.DelayTime(0.25),
            new Scale9SpriteResizeTo(0.25, baseContentSize)
        ));

        this.content.runAction(new cc.Sequence(
            new cc.DelayTime(0.25),
            new cc.FadeTo(0.25, 255)
        ));

        this.dialogueContent.runAction(new cc.Sequence(
            new cc.DelayTime(0.25),
            new cc.Show(),
            new cc.DelayTime(0.25),
            new cc.CallFunc(function () {
                onShow();
                if (this.textBgArrow) {
                    this.textBgArrow.setVisible(true);
                }
            }.bind(this))
        ));
    },

    showUpFromBelow: function (onShow) {
        var styles = cleverapps.styles.DialogueView;
        var basePosition = this.content.getPosition();
        var startPosition = cc.p(basePosition.x, -this.content.height);
        this.content.setPositionRound(startPosition);
        this.content.runAction(new cc.Sequence(
            new cc.DelayTime(this.options.delay || 0),
            new cc.MoveTo(0.2, basePosition.x, basePosition.y + styles.showUp.offset),
            new cc.MoveTo(0.1, basePosition)
        ));

        this.dialogueContent.runAction(new cc.Sequence(
            new cc.DelayTime(this.options.delay || 0),
            new cc.Show(),
            new cc.DelayTime(0.3),
            new cc.CallFunc(onShow)
        ));

        [this.leftPersonView, this.rightPersonView].filter(Boolean).forEach(function (person) {
            var showPersonPosition = person.showPosition;
            person.setVisible(false);
            person.setPosition(showPersonPosition.x, startPosition.y + this.content.height);
            person.runAction(new cc.Sequence(
                new cc.DelayTime(this.options.delay || 0),
                new cc.Show(),
                new cc.MoveTo(0.2, cc.p(showPersonPosition.x, showPersonPosition.y + styles.showUp.offset)),
                new cc.MoveTo(0.1, showPersonPosition.x, showPersonPosition.y),
                new cc.CallFunc(function () {
                    cleverapps.scenes.onAvoidNodeVisibilityChanged();
                })
            ));
        }.bind(this));
    },

    addPerson: function (person, silent) {
        var role = Person.ParseOptions(person).role;
        if (!cleverapps.persons.getRole(role)) {
            cleverapps.throwAsync("Person is undefined - " + role);
            return;
        }

        var personView = new Person(person);
        personView.setAnchorPoint(0.5, 0);
        personView.setActive(person.active);

        var personTitle = new DialoguePersonTitleView(person);
        personView.title = personTitle;

        var personArrow = new cc.Sprite(bundles.dialogues.frames.dialogue_arrow_png);
        personArrow.setVisible(false);
        personView.arrow = personArrow;

        if (personView.getOrientation() === "left") {
            this.leftPersonView = personView;
        } else {
            this.rightPersonView = personView;

            personArrow.setScaleX(-1);
        }

        this.content.addChild(personView);
        this.content.addChild(personArrow);
        this.content.addChild(personTitle);

        this.updatePersonSize(personView);
        this.updatePersonPosition(personView);

        addCleaner(personView, function () {
            personTitle.removeFromParent();
            personArrow.removeFromParent();
        });

        if (silent) {
            return;
        }

        personView.setPosition(personView.hidePosition);

        personView.setVisible(false);
        personView.runAction(new cc.Sequence(
            new cc.DelayTime(0.3),
            new cc.Show(),
            new cc.MoveTo(0.25, personView.showPosition).easing(cc.easeBackOut())
        ));
    },

    updateActive: function (orientation) {
        var isLeft = orientation === "left";
        var active = isLeft ? this.leftPersonView : this.rightPersonView;
        var inactive = isLeft ? this.rightPersonView : this.leftPersonView;

        if (active) {
            active.setActive(true);
            active.setSpeaking(true);
            active.title.show();
            active.arrow.setVisible(true);
        }

        if (inactive) {
            inactive.setActive(false);
            inactive.setSpeaking(false);
            inactive.title.hide();
            inactive.arrow.setVisible(false);
        }
    },

    hidePerson: function (position) {
        var personView = position === "right" ? this.rightPersonView : this.leftPersonView;

        if (personView) {
            personView.runAction(new cc.Sequence(
                new cc.MoveTo(0.25, personView.hidePosition).easing(cc.easeIn(5)),
                new cc.RemoveSelf()
            ));

            personView.title.hide();
            personView.arrow.setVisible(false);
        }

        if (position === "right") {
            this.rightPersonView = undefined;
        } else {
            this.leftPersonView = undefined;
        }
        cleverapps.scenes.onAvoidNodeVisibilityChanged();
    },

    changeText: function (text, isWallAtRight) {
        var styles = cleverapps.styles.DialogueView;

        if (this.text) {
            this.text.removeFromParent();
        }

        this.text = cleverapps.UI.generateOnlyText(text, cleverapps.styles.FONTS.DIALOGUE_MESSAGE_TEXT || cleverapps.styles.FONTS.FORCE_MESSAGE_TEXT);
        this.text.initTextSize = this.text.getFontSize();
        this.content.addChild(this.text, 1);
        this.text.setupChildren = function () {
            var textStyles = styles.text;
            var padding = textStyles.padding;
            var alignment = cc.TEXT_ALIGNMENT_LEFT;
            if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
                padding = textStyles.verticalPadding;
                alignment = cc.TEXT_ALIGNMENT_CENTER;
            }
            this.text.setHorizontalAlignment(alignment);
            var rightX = this.content.width - padding.fromWallX;
            var leftX = padding.x;
            if (isWallAtRight) {
                rightX = this.content.width - padding.x;
                leftX = padding.fromWallX;
            }
            var topY = this.content.height - padding.y;
            var bottomY = padding.y;

            var textScale = 1;
            var textWidth = rightX - leftX;
            var textHeight = topY - bottomY;

            if (textWidth > textStyles.optimization.width) {
                // text rendering optimization
                textScale = 1.5;
                textWidth /= textScale;
                textHeight /= textScale;
            }
            this.text.setFontSize(Math.round(this.text.initTextSize / textScale));

            this.text.setDimensions(textWidth, 0);
            this.text.fitTo(undefined, textHeight);
            this.text.setScale(textScale);
            this.text.setAnchorPoint(0.5, 1.0);
            this.text.setPositionRound((leftX + rightX) / 2, topY);
            if (this.text._byLetterAnimationRunning) {
                this.text._byLetterAnimationDone();
                this.text.setString(text);
            }
        }.bind(this);
        this.text.setupChildren();
        this.text.byLetterAnimation({ callback: this.dialogue.finishTyping.bind(this.dialogue) });
    },

    close: function (silent) {
        this.byLetterAnimationFinish();
        this.content.stopAllActions();
        var onClose = function () {
            this.dialogue.trigger("beforeClose");
            CleverappsWindow.prototype.close.apply(this, arguments);
            this.dialogue.finishClose();
        }.bind(this);
        if (silent) {
            onClose();
            return;
        }

        var animationStyle = cleverapps.styles.DialogueView.showAnimation;
        if (animationStyle === "fromBelow") {
            this.closeToBelow(onClose);
        } else {
            this.closeToSides(onClose);
        }
    },

    closeToSides: function (onClose) {
        if (this.text) {
            this.text.removeFromParent();
        }
        [this.leftPersonView, this.rightPersonView].filter(Boolean).forEach(function (person) {
            var delay = person.isActive ? 0.25 : 0;

            person.arrow.setVisible(false);
            person.title.setVisible(false);

            person.runAction(new cc.Sequence(
                new cc.DelayTime(delay),
                new cc.MoveTo(0.25, person.hidePosition)
            ));
        });

        this.background.runAction(new cc.Spawn(
            new cc.FadeTo(0.5, 0),
            new Scale9SpriteResizeTo(0.5, this.background.width * 0.6, this.background.height)
        ));

        this.dialogueContent.runAction(new cc.Sequence(
            new cc.DelayTime(0.5),
            new cc.CallFunc(onClose)
        ));
    },

    closeToBelow: function (onClose) {
        var styles = cleverapps.styles.DialogueView;
        var startPosition = cc.p(this.content.x, -this.content.height);
        this.content.runAction(new cc.Sequence(
            new cc.DelayTime(0.3),
            new cc.MoveTo(0.15, cc.p(this.content.x, this.content.y + styles.showUp.offset)),
            new cc.MoveTo(0.15, startPosition),
            new cc.CallFunc(onClose),
            new cc.RemoveSelf()
        ));
        [this.leftPersonView, this.rightPersonView].filter(Boolean).forEach(function (person) {
            person.runAction(new cc.Sequence(
                new cc.DelayTime(0.3),
                new cc.MoveTo(0.15, cc.p(person.x, person.y + styles.showUp.offset)),
                new cc.MoveTo(0.15, person.x, startPosition.y + this.content.height)
            ));
        }.bind(this));
    },

    byLetterAnimationFinish: function (skipped) {
        if (this.text) {
            this.text.byLetterAnimationFinish();
            this.text.setupChildren();
        }

        [this.leftPersonView, this.rightPersonView].forEach(function (personView) {
            if (personView) {
                personView.setSpeaking(false, skipped);
            }
        });
    },

    listBundles: function (dialogue) {
        return dialogue.bundlesToLoad.slice();
    }
});

cleverapps.styles.DialogueView = {
    position: {
        x: { align: "left", dx: 0 },
        y: { align: "center", dy: 0 }
    },
    size: [
        { height: 240 },
        { height: 280 },
        { height: 280 }
    ],
    widthPadding: 0,
    offsetY: 20,
    showAnimation: "fromSides",

    showUp: {
        offset: 75
    },

    arrow: {
        y: { align: "top", dy: 23 }
    },

    text: {
        padding: {
            fromWallX: 30,
            x: 30,
            y: 30
        },
        verticalPadding: {
            fromWallX: 50,
            x: 50,
            y: 40
        },

        optimization: {
            width: 1000
        }
    },

    persons: {
        mobileScale: 0.65,
        squareScale: 0.8,

        margin: 100,
        offsetY: -450
    },

    title: {
        offsetX: 0,
        y: { align: "top", anchor: false, dy: 0 },
        mobileY: { align: "top", anchor: false, dy: 0 }
    },

    Window: {
        windowShowUpAnimation: false
    }
};

cleverapps.styles.DialogueView.persons[Person.ORIENTATION_LEFT] = [
    { x: { align: "left", dx: 20 } },
    { x: { align: "left", dx: 75 } },
    { x: { align: "left", dx: 75 } }
];

cleverapps.styles.DialogueView.persons[Person.ORIENTATION_RIGHT] = [
    { x: { align: "right", dx: -20 } },
    { x: { align: "right", dx: -75 } },
    { x: { align: "right", dx: -75 } }
];