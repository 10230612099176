/**
 * Created by vladislav on 11/10/2023
 */

/* eslint-disable max-len */
// eslint-disable-next-line no-unused-vars
var privacyText = {
    wechat: "萌猪消消乐（面包版）游戏隐私协议\n"
        + "\n"
        + "萌猪消消乐面包版游戏隐私协议\n"
        + "发布时间：2023年7月1日\n"
        + "更新时间：2023年7月1日\n"
        + "生效时间：2023年7月1日\n"
        + "\n"
        + "《萌猪消消乐（面包版）》游戏（以下称“本游戏”）由广州道泉网络科技有限公司负责开发，授权深圳市彩苻科技有限公司负责游戏运营及相关推广活动。在您游玩《萌猪消消乐（面包版）》之前，请务必认真阅读和理解本《萌猪消消乐（面包版）游戏隐私协议》（以下简称“本协议”）。本协议为《道泉网络科技用户协议》之补充，您在阅读本协议的同时，应当同时阅读《道泉网络科技用户协议》，《道泉网络科技用户协议》与本协议不一致的，以本协议为准。如您同意《道泉网络科技用户协议》和本协议，您可游玩本游戏。如果您对本协议或道泉网络科技游戏服务有任何疑问或投诉、建议，可通过客服、道泉网络科技客服微信小程序（小程序版本）、道泉网络科技客服公众号（小程序版本）等方式联系我们，我们将尽快跟进处理，并在法律法规规定的期限内回复您的请求。您点击同意、接受或下一步，或您注册、游玩本游戏均视为您已阅读并同意签署本协议。\n"
        + "我们将严格依照相关法律法规规定，保护您的个人信息及隐私安全。本协议将帮助您直观、简明地了解我们是如何收集、处理和保存您的个人信息以及如何保护您的稳私安全。\n"
        + "\n"
        + "本协议将帮助您了解以下内容：\n"
        + "一，我们可能收集的个人信息\n"
        + "二，合作方可能收集的个人信息\n"
        + "三，我们可能如何使用您的个人信息\n"
        + "四，我们如何储存和保护您的个人信息\n"
        + "五，我们如何对外提供信息\n"
        + "六，本协议如何更新\n"
        + "七、联系方式\n"
        + "\n"
        + "一、我们可能收集的个人信息\n"
        + "您在使用本游戏时，我们可能需要收集和使用您的一些个人信息，我们收集和使用的个人信息包括两种：第一种：本游戏的核心功能所必要的信息。这类信息为本游戏正常运行的必备信息；第二种：本游戏附加业务功能可能收集的信息。这类信息用以支撑您在游戏中希望体验的附加功能，如您拒绝提供，将会导致特定附加功能无法实现或无法达到我们拟达到的效果，但并不会影响本游戏核心功能的正常使用。\n"
        + "我们需要特别提醒您的是：在本游戏中，为了向您提供更全面的服务，游戏中内嵌了第三方SDK或类似应用程序，因此，第三方SDK或类似应用程序可能也会收集您的个人信息。\n"
        + "除此之外，您理解并同意，我们希望提供给您的产品和服务是完善的，所以我们会不断改进我们的产品和服务，这意味着我们可能会经常推出新的业务功能，可能需要收集新的个人信息或变更个人信息使用目的或方式。如果某一需要收集您的个人信息的功能未能在本协议中予以说明的，我们会通过更新本协议、弹窗等方式另行向您说明该信息收集的目的、内容、使用方式和范围，您可自主选择是否同意，我们仅会在征得您明示同意后收集相关信息。\n"
        + "◆ 当您运行本游戏时，为满足相关法律法规政策及相关主管部门的需求，确保用户身份真实性，实现反欺诈等风险控制、保障系统和服务安全，您需进行实名认证。我们会在获得您的同意或您主动提供的情况下，收集您的实名身份信息（包括姓名、身份证号、手机号码）。您应知悉，实名身份信息属于敏感信息，请您谨慎考虑是否提供。我们亦会高度对待和谨慎处理您的实名身份信息（包括高强度加密保护）。拒绝提供实名身份信息可能会导致您无法使用本游戏。\n"
        + "◆ 当您运行本游戏时，我们需要收集您所使用的设备信息，包括：MAC地址、广告标识符、Serial、设备机型、设备名称、操作系统及版本、设备分辨率、设备设置、软件安装列表、软硬件特征信息、设备屏幕尺寸、移动网络信息、登录IP地址、日志信息、CPU信息、软件信息。用于识别账号异常状态，了解产品适配性，维护基础功能的正常运行，实现本游戏的问题排查。为了收集上述基本的个人设备信息，我们将会申请访问您的设备信息权限，我们收集这些信息是为了向您提供本游戏的核心游戏功能。\n"
        + "◆ 当您运行本游戏时，我们将需要获取您的存储权限，用于使应用程序在运行时对游戏资源进行必要的缓存和读取。存储权限的开启是为了向您提供本游戏核心功能。\n"
        + "◆ 当您使用本游戏的消费功能时，我们会收集您的充值记录和消费记录，以便您查询您的交易信息，同时尽最大程度保护您的虚拟物品安全。充值记录、消费记录属于敏感信息，收集上述信息为实现本游戏消费功能所必需的，否则您将无法完成交易。\n"
        + "◆ 我们致力于为你提供安全、可信的产品与使用环境，提供优质而可靠的服务与信息是我们的核心目标。为了维护相关产品或服务的正常稳定运行，保护你或其他用户或公众的安全及合法利益，我们会收集如下必要信息：\n"
        + "1.为了保障软件与服务的安全运行、运营的质量及效率，我们会收集你的硬件型号、操作系统版本号、网络设备硬件地址（MAC地址）、硬件序列号、IP 地址、WLAN接入点（如SSID，BSSID）、蓝牙（Bluetooth）、基站、软件版本号、网络接入方式、类型、状态、网络质量数据、操作、使用、服务日志、传感器信息等数据。\n"
        + "2.为了预防恶意程序、保障运营质量及效率，我们会收集安装的应用信息或正在运行的进程信息、应用程序的总体运行、使用情况与频率、应用崩溃情况、总体安装使用情况、性能数据、应用来源。\n"
        + "3.我们可能使用你的帐号信息、设备信息、服务日志信息，以及我们的关联方、合作方获得你授权或依法可以提供给我们的信息，用于判断帐号安全、交易安全、进行身份验证、识别违法违规情况、检测及防范安全事件，并依法采取必要的记录、分析、处置措施。\n"
        + "\n"
        + "◆ 当您使用游戏中的客服功能时，第三方客服SDK将会启动并可能收集您的设备信息，征求您的麦克风权限、相机权限、相册权限、或照片权限授权（详情请见附件一：《第三方SDK及类似应用程序说明》）。为了您的账号与系统安全，优化服务质量，我们可能从第三方客服SDK接触并间接收集您的个人信息，包括您的设备信息和您与客服的沟通内容（包括文本、图片、音视频、通话记录形式）。请您知悉并理解，如您与客服沟通中涉及到账号或个人敏感问题，我们可能会要求您提供个人敏感信息（包括手机号码、身份证号码），用于与该账号实名认证信息核对，判断您的真实身份；上述敏感信息仅做核对之用，客服不会予以留存。\n"
        + "◆ 根据相关法律法规及国家标准，以下情形中，我们可能会收集、使用您的相关个人信息且无需征求您的授权同意：\n"
        + "1. 与国家安全、国防安全等国家利益直接相关的；与公共安全、公共卫生、公共知情等重大公共利益直接相关的；\n"
        + "2. 与犯罪侦查、起诉、审判和判决执行等直接相关的；\n"
        + "3. 出于维护用户或其他个人的生命、财产、声誉等重大合法权益但又很难得到本人同意的；\n"
        + "4. 所收集的个人信息是用户自行向社会公众公开的；\n"
        + "5. 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；\n"
        + "6. 根据用户要求签订和履行合同所必需的；\n"
        + "7. 用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；\n"
        + "8. 为开展合法的新闻报道所必需的；\n"
        + "9. 出于公共利益开展统计或学术研究所必要，且其对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；\n"
        + "10. 法律法规规定的其他情形。\n"
        + "\n"
        + "\n"
        + "二、合作方可能收集的个人信息\n"
        + "◆合作平台第三方SDK：\n"
        + "QQSDK，腾讯SDK，移动应用SDK，字节跳动SDK，com.sigmob.sdk，kssdk-ad，com.yxcorp.kuaishou，热云TrackingIO，字节增长营销SDK，QuickSDK For Unity 3D，com.ss.android,com.reyun.tracking，yaq.pro.getVresult，bykvm_19do.bykvm_19do.bykvm_if122，穿山甲（头条）SDK，微信SDK，优量汇（广点通移动联盟系统）SDK\n"
        + "◆涉及个人信息：\n"
        + "设备标识符（Android如IMEI、AndroidID、OAID、IMSI、ICCID、MEID，iOS如IDFV、IDFA），运行中的进程，MAC地址，IP 地址，位置信息（如GPS、WLAN接入点、蓝牙和基站），网络类型，设备传感器，设备序列号，设备帐户数据，SD卡数据，本机号码，网络类型、崩溃信息、性能数据、广告数据，设备序列号（SN码），设备型号、设备品牌、设备操作系统版本\n"
        + "◆使用目的：\n"
        + "提供游戏基础功能、安全风控服务、游戏优化服务、游戏资产保护服务、预防恶意程序、保障运营质量及效率，广告投放合作、广告归因、反作弊服务，提供账号登录、⽀付、广告变现，广告效果分析，提供通过微信登录、分享至微信功能\n"
        + "◆使用场景：\n"
        + "在用户使用游戏时使用\n"
        + "◆合作方主体：\n"
        + "北京抖音信息服务有限公司，北京巨量引擎网络技术有限公司，北京创智汇聚科技有限公司，北京快手科技有限公司，腾讯科技（深圳）有限公司，北京热云科技有限公司，北京字节跳动科技有限公司，维沃移动通信有限公司，广东欢太科技有限公司\n"
        + "\n"
        + "\n"
        + "三、我们可能如何使用您的个人信息\n"
        + "我们将严格遵守法律法规的规定以及与您的约定，按照本协议及《道泉网络科技用户协议》使用收集的信息。\n"
        + "◆ 信息使用规则\n"
        + "我们会按照如下规则使用本协议第一条收集的信息：\n"
        + "1. 我们会根据收集的信息向您提供各项功能与服务，包括基础游戏功能、玩家互动功能、消费功能、执行软件验证、执行软件升级、网络同步等；\n"
        + "2. 我们会根据您使用公司产品的频率和情况、故障信息、性能信息等分析本游戏的运行情况，以确保服务的安全性，并优化我们的产品，提高我们的服务质量。我们不会将存储在分析软件中的信息与您提供的个人身份信息相结合。\n"
        + "◆信息使用规则的变动及再次同意\n"
        + "我们将会在本协议所涵盖的用途内使用收集到的信息。如我们拟使用的您的个人信息范围超出了本协议所述范围，我们将在收集您的个人信息前，修改本协议内容，并通过弹窗、推送通知等方式及时向您告知，并再次征得您的明示同意。\n"
        + "\n"
        + "四、我们如何储存和保护您的个人信息\n"
        + "◆个人信息的储存\n"
        + "1. 信息储存的方式和期限\n"
        + "我们会通过安全的方式存储您的信息，包括本地存储（例如利用APP进行数据缓存）、数据库和服务器日志。我们只会在为实现服务目的所必需的时间内或法律法规规定的条件下存储您的个人信息。\n"
        + "除非法律另有规定或您根据本协议提出请求，我们将在游戏运营期间内保存您的个人信息。\n"
        + "2. 信息储存的地域\n"
        + "我们会按照法律法规规定，将您的个人信息存储于中国境内。\n"
        + "3. 信息的删除\n"
        + "当本游戏停止运营或根据本协议我们不再保存您信息时，存储个人信息的电子档案将被永久删除，我们会采取合理措施确保个人信息不能被恢复。如根据法律规定我们应继续保存您的个人信息，这部分个人信息也将与其它类型信息隔离保存。\n"
        + "◆个人信息的保护\n"
        + "1. 信息安全\n"
        + "在现有设备及技术条件下，我们一贯积极地采取技术与管理等合理措施保障您账号与个人信息的安全；我们将善意使用收集的信息，采取各项有效且必要的措施以保护您的隐私安全，并使用商业上合理的安全技术措施来保护您的隐私不被未经授权的访问、使用或泄漏。目前，我们采用业界领先的技术保护措施。我们使用的技术手段包括但不限于防火墙、加密（例如SSL）、去标识化或匿名化处理、访问控制措施（堡垒机、二次验证等方式）等。\n"
        + "我们建立了保障个人信息安全专门的管理制度、流程和组织。我们仅允许有必要知晓这些信息的员工访问您的个人信息，并为此设置了严格的访问权限控制和监控机制。我们同时要求可能接触到您的信息的所有人员履行相应的保密义务。如果未能履行这些义务，我们会追究其法律责任。\n"
        + "2. 信息安全事件处置措施\n"
        + "若发生个人信息泄露等安全事件，我们会启动应急预案，阻止安全事件扩大。若发生信息安全事件，我们会以公告、推送通知或邮件等形式告知您安全事件的基本情况、我们即将或已经采取的处置措施和补救措施，以及我们对您的应对建议。如果难以实现逐一告知，我们将通过公告等方式发布警示。\n"
        + "\n"
        + "五、我们如何对外提供信息\n"
        + "除本协议以及《道泉网络科技用户协议》规定的情形外，我们不会主动共享、提供或转让您的个人信息至第三方。如存在其他共享、提供或转让您的个人信息或您要求我们将您的个人信息共享、提供或转让至我们外的第三方的情形时，我们会直接向您确认并取得您的明示同意。\n"
        + "根据相关法律法规及国家标准，在以下情形中，我们会在不事先征得您的授权同意的前提下，共享、转让、公开披露您的特定个人信息：\n"
        + "1. 经您本人(或未成年人的监护人)书面授权或应您本人（或未成年人的监护人）的书面要求而授权我们披露的；\n"
        + "2. 有关法律要求我们披露的；\n"
        + "3. 司法机关或行政机关基于法定程序要求我们提供的；\n"
        + "4. 出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；\n"
        + "5. 个人信息主体自行向社会公众公开个人信息的；\n"
        + "6. 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。\n"
        + "\n"
        + "六、本协议如何更新\n"
        + "如发生业务功能变更、个人信息出境情况变更、使用目的变更、个人信息保护联络方式变更等情形，我们将对本协议进行相应的修订，并通过弹窗、推送通知等方式及时向您告知，并重新获得您的同意，只有在您确认并接收变更后的协议后，我们才会依据变更后的协议收集、使用、处理和存储您的个人信息；如您不同意变更后的内容，您可以选择停止使用我们的服务。 \n"
        + "\n"
        + "七、联系方式\n"
        + "如您有关于网络信息安全的投诉和举报，或对本协议有任何问题、意见或建议，或对我们的隐私措施有任何疑问的，您可发送邮件至1121163660@qq.com\n"
        + "我们将尽快审核所涉问题，并在收到邮件的十五个工作日内回复。\n"
        + "\n"
        + "广州道泉网络科技有限公司",
    default: "PRIVACY POLICY\n"
        + "1. GENERAL\n"
        + "1.1. Clever Apps Pte. Ltd. (\"we\" or \"us\"), of 8 Marina Boulevard #11-00 Marina Bay Financial Centre Singapore is a data controller. We are committed to protecting and respecting your privacy. This policy (together with our terms of service) sets out the basis on which any personal information we collect from you, or you provide to us, will be processed by us. By using our games and applications (\"Games\"), you consent to the collection and use of your personal information as outlined in this Privacy Policy. The policy may be amended from time to time. You should visit this page periodically to review any changes to the Privacy Policy.\n"
        + "1.2. Since the Games are only served through social network websites or mobile application stores, it is your responsibility to read and accept their terms and conditions, as well as their privacy policy prior to continuing.\n"
        + "\n"
        + "2. WHAT DATA WE ARE COLLECTING\n"
        + "2.1. We record information regarding the use of the Games, e.g. access time.\n"
        + "2.2. Information relating to profile of users of the Games on social networking websites, e.g. player name.\n"
        + "2.3. Other information, like browser name and version and device information.\n"
        + "2.4. If you contact us, we may keep a record of that correspondence.\n"
        + "\n"
        + "3. THE PURPOSE WE ARE COLLECTING PERSONAL DATA\n"
        + "3.1. Provide and operate Games.\n"
        + "3.2. Improve our Games and keep them secure.\n"
        + "3.3. Personalize Games.\n"
        + "3.4. Promotional activities, including marketing and advertising.\n"
        + "3.5. Comply with Clever Apps Pte Ltd's legal obligations.\n"
        + "3.6. Prevent and/or detect fraud or fraudulent behavior. \n"
        + "\n"
        + "4. REQUEST DATA DELETION\n"
        + "4.1. Clever Apps Pte Ltd stores Personal Data of users of the Games for as long as users continue to use the Games. Clever Apps Pte Ltd will delete the Personal Data of a user if there is no ongoing legitimate business need to process data or after receiving the corresponding request from a user of the Games via the email address help.cleverapps+privacy@gmail.com within a reasonable time. \n"
        + "4.2. Alternatively, players could send their requests through our internal CRM system (located in every game via Settings -> Support). \n"
        + "\n"
        + "5. DISCLOSURE OF YOUR PERSONAL INFORMATION\n"
        + "5.1. We will not sell or give your personally identifiable information to others except as provided in this privacy policy.\n"
        + "5.2. We may disclose your personal information to any member of our group, which means our subsidiaries, our ultimate holding company and its subsidiaries.\n"
        + "5.3. We may disclose your personal information to third parties:\n"
        + "5.3.1. in the event that we sell or buy any business or assets, in which case we may disclose your personal data to the prospective seller or buyer of such business or assets;\n"
        + "5.3.2. if Clever Apps Pte. Ltd., any of its Gamess, or substantially all of its assets are acquired by a third party, in which case personal data held by it about its users or customers may be one of the assets transferred to that third party. In such circumstances we may retain the right to continue to provide marketing communications relating to other Gamess provided by us to you;\n"
        + "5.3.3. if we are under a duty to disclose or share your personal data in order to comply with any legal obligation, or in order to enforce or apply our Terms and other agreements; or to protect the rights, property, or safety of Clever Apps Pte. Ltd., members of the Clever Apps, our users, or others. This includes exchanging information with other companies and organisations for the purposes of fraud protection and credit risk reduction.\n"
        + "5.4. As the Games operates within social networking websites, the Games may share information about you with others in the same social networking site who are using the Games as may be necessary to operate the Games.\n"
        + "5.5. By continuing to use the Games, you expressly consent to this Privacy Policy, including, without limitation, your consent to use and disclose your personally information in the manner described in this Privacy Policy. If you object to your information being used in this way, you should not otherwise use the Games. This Privacy Policy is incorporated into and subject to the Terms..\n"
        + "\n"
        + "6. STORAGE OF YOUR PERSONAL DATA\n"
        + "6.1. The data that we collect from you may be transferred to, and stored at, a destination outside the European Economic Area (\"EEA\"). It may also be processed by staff operating outside the EEA who work for us or for one of our suppliers. Such staff maybe engaged in, among other things, the processing of your payment details and the provision of support Gamess. By submitting your personal data, you agree to this transfer, storing or processing. We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this privacy policy.\n"
        + "\n"
        + "7. COOKIES \n"
        + "7.1. We may store information about you using cookies (files which are sent by us to your computer or other access device) which we can access when you use our Games in the future. We do this to learn about repeat visitors and to find out if we are delivering quality products and Gamess therefore improving our Games further. During your use of the Gamess, third party advertisers or other Games providers may also place cookies on your computer, over which we have no control. Please check their privacy policies for details of how they use the data they collect in this way.\n"
        + "7.2. If you want to delete any cookies that are already on your computer, please refer to the instructions for your file management software to locate the file or directory that stores cookies.\n"
        + "7.3. Information on deleting or controlling cookies is available at www.AboutCookies.org . Please note that by deleting our cookies or disabling future cookies you may not be able to access certain areas or features of our Games.\n"
        + "\n"
        + "8. GOOGLE ANALYTICS ADVERTISING FEATURES\n"
        + "8.1. We have implemented the following Google Analytics Advertising Features: services that require Google Analytics to collect data via advertising cookies and identifiers.\n"
        + "8.2. We and third-party vendors, including Google, use first-party cookies (such as the Google Analytics cookie) and third-party cookies (such as the Google advertising cookies) or other third-party identifiers together to compile data regarding user interactions with ad impressions, and other ad service functions as they relate to our website.\n"
        + "8.3. You can opt-out of the Google Analytics Advertising Features we use by indicating your preference using the interest-based opt-out link here: https://tools.google.com/dlpage/gaoptout/. Additionally, you can opt-out through Ads Settings, Ad Settings for mobile apps, or any other available means.\n"
        + "8.4. For more information on how Google processes and collects your information and how you can opt-out, please see the following links: https://policies.google.com/privacy and https://policies.google.com/technologies/partner-sites.\n"
        + "\n"
        + "9. CONTACT\n"
        + "9.1. Questions, comments and requests regarding this privacy policy are welcomed and should be addressed to help.cleverapps+privacy@gmail.com."
};