/**
 * Created by Aleksandr on 27.04.2023
 */

var ExpeditionIcon = function (pageId) {
    this.pageId = pageId;

    SideBarIcon.call(this, {
        animation: cleverapps.travelBook.getPageById(this.pageId).getSidebarIcon(),
        priority: SideBar.PRIORITY_REDUNDANT
    });
    this.resetState();
};

ExpeditionIcon.prototype = Object.create(SideBarIcon.prototype);
ExpeditionIcon.prototype.constructor = ExpeditionIcon;

ExpeditionIcon.prototype.onPressed = function () {
    cleverapps.meta.display({
        focus: "ExpeditionIconClicked",
        action: function (f) {
            cleverapps.travelBook.getPageById(this.pageId).gotoExpedition(f);
        }.bind(this)
    });
};

ExpeditionIcon.prototype.resetState = function () {
    var priorityPage = cleverapps.travelBook.listAvailablePages().filter(function (page) {
        return !page.isMain() && page.isActive() && !page.isCurrent();
    }).sort(function (a, b) {
        return a.slot - b.slot;
    })[0];

    this.available = priorityPage && (priorityPage.id === this.pageId);
    if (this.available) {
        var page = cleverapps.travelBook.getPageById(this.pageId);
        this.setAttention(page.attention);
        if (page.isCompleted()) {
            this.setLeftTime(0);
            this.setTitle("Finished");
        } else {
            this.setLeftTime(page.getTimeLeft());
            this.setTitle(undefined);
        }
    }
};
