/**
 * Created by Denis Kuzin on 10 june 2021
 */

var CheckBoxPropertyEditor = function (property, initialValue, onChangeValueCallback) {
    this.property = property;
    this.onChangeValueCallback = onChangeValueCallback;
    initialValue = initialValue || false;

    this.value = initialValue;

    this.propertyEditors = [{
        name: property.name,
        type: "checkBox",
        getter: function () {
            return this.value;
        }.bind(this),
        setter: function (value) {
            this.value = value;
            return this.value;
        }.bind(this)
    }].map(function (propertyDescription) {
        return new PropertyEditor(propertyDescription, this);
    }.bind(this));
};

CheckBoxPropertyEditor.prototype.editBoxEditingDidEnd = function (sender) {
    sender.customSetter(sender.getString());
    this.onChangeValueCallback(this.property.name, this.value);
};