/**
 * Created by ivan on 22.02.18.
 */

var CakeCell = function (x, y) {
    BaseCell.call(this, x, y);

    var bigComponent = this.bigComponent = this.addComponent(BigComponent, { cellClass: CakeCell });

    if (this.isForGoal) {
        this.hurtable = true;
        return;
    }

    this.lives = 3;
    this.hurtable = true;

    var dx = x - bigComponent.head.x;
    var dy = y - bigComponent.head.y;
    this.part = dx + dy * 2 + 1;
};

CakeCell.prototype = Object.create(BaseCell.prototype);
CakeCell.prototype.constructor = CakeCell;
Skinify(CakeCell, "cake");

CakeCell.prototype.getViewClass = function () {
    return CakeCellView;
};

CakeCell.prototype.getGoalId = function () {
    return CakeCell.GOAL_ID;
};

CakeCell.prototype.load = function (data) {
    if (data[1]) {
        this.lives = parseInt(data[1]);
    }
};

CakeCell.prototype.save = function () {
    if (this.lives !== 3) {
        return CakeCell.CODES.concat(this.lives);
    }
    return CakeCell.CODES;
};

CakeCell.prototype.boom = function () {
    this.hurt();
};

CakeCell.prototype.hurt = function () {
    if (this.lives <= 0) {
        return;
    }

    if (this.isDartsSkin()) {
        this.dartsHurt();
    } else {
        this.lives--;
        this.bigComponent.head.onChangeLivesListener(this);
        this.bigComponent.head.animate(BaseCell.ANIMATION_HURT);
        this.bigComponent.head.checkAlive();
    }
};

CakeCell.prototype.dartsHurt = function () {
    this.lives--;
    Game.currentGame.goalCounter.setTimeout(function () {
        if (!cleverapps.config.rpg) {
            var goal = Game.currentGame.goals.findTargetFor(this.getGoalId());
            var goalView = goal.getView();
            var baseScale = goalView.baseScale || 1;
            goalView.runAction(new cc.Sequence(
                new cc.ScaleTo(0.2, baseScale * 0.9),
                new cc.ScaleTo(0.2, baseScale)
            ));
        }
        Game.currentGame.goals.incGoal(this.getGoalId(), 1);
    }.bind(this), this.hurtDuration() * 1000);
    this.bigComponent.head.onChangeLivesListener(this);
    this.bigComponent.head.animate(BaseCell.ANIMATION_HURT);
    this.bigComponent.head.checkAlive();
};

CakeCell.prototype.onBeforeHurtViaBooster = function (affectedCells) {
    if (this.lives > 0) {
        return;
    }

    var cells = [];
    for (var dx = 0; dx < 2; ++dx) {
        for (var dy = 0; dy < 2; ++dy) {
            if (dx !== this.dx && dy !== this.dy) {
                var currentCell = Game.currentGame.field.cells[this.bigComponent.head.y + dy][this.bigComponent.head.x + dx];
                if (currentCell.lives > 0) {
                    if (affectedCells.indexOf(currentCell) !== -1) {
                        return;
                    }
                    cells.push(currentCell);
                }
            }
        }
    }

    if (cells.length !== 0) {
        var minDist = 2;
        var chosenCell = cells[0];
        cells.forEach(function (cell) {
            var dist = Math.abs(this.x - cell.x) + Math.abs(this.y - cell.y);
            if (dist < minDist) {
                chosenCell = cell;
                minDist = dist;
            }
        }.bind(this));
        chosenCell.willBurn = true;
        affectedCells.push(chosenCell);
    }
};

CakeCell.prototype.checkAlive = function () {
    var alive = false;

    for (var dx = 0; dx < 2; ++dx) {
        for (var dy = 0; dy < 2; ++dy) {
            if (Game.currentGame.field.cells[this.y + dy][this.x + dx].lives > 0) {
                alive = true;
            }
        }
    }

    if (alive) {
        return;
    }

    this.bigComponent.head.explode();
};

CakeCell.prototype.onHeadExplode = function () {
    BaseCell.prototype._explode.call(this);
};

CakeCell.prototype.isDartsSkin = function () {
    return ["heroes", "runes"].indexOf(cleverapps.config.name) !== -1 && this.getSkinSuffix() === "";
};

CakeCell.prototype.hurtDuration = function () {
    if (this.isDartsSkin()) {
        return 0.2;
    }
    return 0;
};

CakeCell.prototype.editorComponents = function () {
    if (this.bigComponent.isHead) {
        return [BaseCellComponent, BigCellComponent];
    }
    return [BaseCellComponent];
};

CakeCell.GOAL_ID = "cake_cell";