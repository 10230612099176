/**
 * Created by vladislav on 28.12.2021
 */

var Share = {
    show: function (id, callback) {
        callback = cleverapps.once(callback || function () {});

        var shareData = this.getData(id);

        new ActionPlayer([
            function (f) {
                cleverapps.SocialManager.enforceLogin(function (code) {
                    if (code === cleverapps.CODE_SUCCEED) {
                        f();
                    } else {
                        callback();
                    }
                });
            },
            function (f) {
                var needsBase64 = connector.platform.oneOf(connector.INSTANT, connector.WORTAL, connector.TEST);
                if (needsBase64) {
                    cleverapps.loadBase64Image(shareData.image, function (base64Picture) {
                        if (base64Picture) {
                            shareData.image = base64Picture;

                            f();
                        } else {
                            callback();
                        }
                    });
                } else {
                    f();
                }
            },
            function () {
                this._show(id, shareData, callback);
            }.bind(this)
        ]).play();
    },

    _show: function (id, shareData, callback) {
        connector.social.shareCallback(shareData, function (success) {
            if (success) {
                cleverapps.eventLogger.logEvent(cleverapps.EVENTS.SHARE + id);
                this.save();
            } else {
                console.log(Messages.get("Share.ShareFailed"));
            }

            callback();
        }.bind(this));
    },

    getData: function (id) {
        var data = cc.loader.getRes(bundles.main.jsons.vk_json);
        var vkPictures = data[id] || data.default;

        var path = id + "_" + cleverapps.settings.language;
        if (!bundles.shares.urls[path + "_1"]) {
            path = id + "_en";
        }
        if (!bundles.shares.urls[path + "_1"]) {
            path = "default_en";
        }

        var pictures = [];
        for (var i = 1; i < 15; i++) {
            var picture = bundles.shares.urls[path + "_" + i];

            if (!picture) {
                break;
            }

            pictures.push(picture.getUrl());
        }

        var shareData = {
            title: Messages.get("Share." + id + ".Name"),
            text: Messages.get("Share." + id + ".Description"),
            image: cleverapps.Random.mathChoose(pictures)
        };

        if (connector.platform.oneOf(connector.FACEBOOK)) {
            shareData.text = Messages.get(cleverapps.config.title);
        }

        if (connector.platform.oneOf(connector.VK)) {
            shareData.image = cleverapps.Random.mathChoose(vkPictures);
        }

        if (connector.platform.oneOf(connector.PLINGA)) {
            shareData.linktext = Messages.get("PlayNow");
        }

        if (connector.platform.oneOf(connector.DRAUGIEM)) {
            shareData.subTitle = Messages.get("PlayNow");
        }

        return shareData;
    },

    isEnoughTimePassed: function () {
        var save = cleverapps.dataLoader.load(DataLoader.TYPES.LAST_SUCCESSFUL_SHARE) || {};
        return !save.date || Date.now() - save.date >= Share.INTERVAL;
    },

    isAvailable: function () {
        if (!connector.social.isSupportsNativeShare) {
            return false;
        }
        return this.isEnoughTimePassed() && connector.social.isLoggedIn() && cleverapps.user.checkAvailable(Share.AVAILABLE);
    },

    save: function () {
        cleverapps.dataLoader.save(DataLoader.TYPES.LAST_SUCCESSFUL_SHARE, {
            date: Date.now()
        });
    }
};

Share.INTERVAL = cleverapps.parseInterval(cleverapps.config.debugMode ? "3 minute" : "3 days");

Share.AVAILABLE = {
    level: 0.93
};

if (cleverapps.config.type === "merge") {
    Share.AVAILABLE = {
        level: 4
    };
}