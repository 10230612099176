/**
 * Created by decipaliz on 14.12.2023
 */

var GoalViewFactory = {};

GoalViewFactory.init = function () {
    if (GoalViewFactory.creators) {
        return;
    }
    GoalViewFactory.creators = {};
    GoalViewFactory.registerViewFactory("a", GoalViewFactory.baseGoalViewCreator);
    GoalViewFactory.registerViewFactory("b", GoalViewFactory.baseGoalViewCreator);
    GoalViewFactory.registerViewFactory("c", GoalViewFactory.baseGoalViewCreator);
    GoalViewFactory.registerViewFactory("d", GoalViewFactory.baseGoalViewCreator);
    GoalViewFactory.registerViewFactory("e", GoalViewFactory.baseGoalViewCreator);
    GoalViewFactory.registerViewFactory("w", GoalViewFactory.baseGoalViewCreator);
    GoalViewFactory.registerViewFactory(FishCell.GOAL_ID, GoalViewFactory.baseGoalViewCreator);
    GoalViewFactory.registerViewFactory(FlowerCell.GOAL_ID, GoalViewFactory.baseGoalViewCreator);
    GoalViewFactory.registerViewFactory(NutCell.GOAL_ID, GoalViewFactory.baseGoalViewCreator);
    GoalViewFactory.registerViewFactory(Mark.GOAL_ID, GoalViewFactory.baseGoalViewCreator);
    GoalViewFactory.registerViewFactory(MouseCell.GOAL_ID, GoalViewFactory.baseGoalViewCreator);
    GoalViewFactory.registerViewFactory(IceTile.GOAL_ID, GoalViewFactory.baseGoalViewCreator);
    GoalViewFactory.registerViewFactory(DragonCell.GOAL_ID, GoalViewFactory.baseGoalViewCreator);
    GoalViewFactory.registerViewFactory(CookieManCell.GOAL_ID, GoalViewFactory.baseGoalViewCreator);
    GoalViewFactory.registerViewFactory(GingerHouseCell.GOAL_ID, GoalViewFactory.baseGoalViewCreator);
    GoalViewFactory.registerViewFactory(CakeCell.GOAL_ID, GoalViewFactory.baseGoalViewCreator);
    GoalViewFactory.registerViewFactory(MuffinGoalCell.GOAL_ID, GoalViewFactory.baseGoalViewCreator);
    GoalViewFactory.registerViewFactory(WaffleCell.GOAL_ID, GoalViewFactory.baseGoalViewCreator);
    GoalViewFactory.registerViewFactory(CandleCell.GOAL_ID, GoalViewFactory.baseGoalViewCreator);
    GoalViewFactory.registerViewFactory(RabbitTile.GOAL_ID, GoalViewFactory.baseGoalViewCreator);
    GoalViewFactory.registerViewFactory(LollipopCell.GOAL_ID, GoalViewFactory.baseGoalViewCreator);
    GoalViewFactory.registerViewFactory(DogCell.GOAL_ID, GoalViewFactory.baseGoalViewCreator);
    GoalViewFactory.registerViewFactory(TruffleCell.GOAL_ID, GoalViewFactory.baseGoalViewCreator);
    GoalViewFactory.registerViewFactory(ColorCookieManCell.GOAL_ID, GoalViewFactory.baseGoalViewCreator);
    GoalViewFactory.registerViewFactory(FireflyJarCell.GOAL_ID, GoalViewFactory.baseGoalViewCreator);
    GoalViewFactory.registerViewFactory(IceMakerCell.GOAL_ID, GoalViewFactory.baseGoalViewCreator);
    GoalViewFactory.registerViewFactory(RugTile.GOAL_ID, GoalViewFactory.baseGoalViewCreator);
    GoalViewFactory.registerViewFactory(DirtyHoneyTile.GOAL_ID, GoalViewFactory.baseGoalViewCreator);
    GoalViewFactory.registerViewFactory(ColorContainerCell.GOAL_ID, GoalViewFactory.baseGoalViewCreator);
    GoalViewFactory.registerViewFactory(GumDecoratorCell.GOAL_ID, GoalViewFactory.baseGoalViewCreator);
    GoalViewFactory.registerViewFactory(ClockCell.GOAL_ID, GoalViewFactory.baseGoalViewCreator);
    GoalViewFactory.registerViewFactory(DirtDecoratorCell.GOAL_ID, GoalViewFactory.baseGoalViewCreator);
    GoalViewFactory.registerViewFactory(CrabDecoratorCell.GOAL_ID, GoalViewFactory.baseGoalViewCreator);
    GoalViewFactory.registerViewFactory(BoxDecoratorCell.GOAL_ID, GoalViewFactory.baseGoalViewCreator);
    GoalViewFactory.registerViewFactory(BagDecoratorCell.GOAL_ID, GoalViewFactory.baseGoalViewCreator);
    GoalViewFactory.registerViewFactory(BombCell.GOAL_ID, GoalViewFactory.baseGoalViewCreator);
    GoalViewFactory.registerViewFactory(FireworkCell.GOAL_ID, GoalViewFactory.baseGoalViewCreator);
    GoalViewFactory.registerViewFactory(SyrupTile.GOAL_ID, GoalViewFactory.baseGoalViewCreator);
    GoalViewFactory.registerViewFactory(FigureDragonCell.GOAL_ID, GoalViewFactory.baseGoalViewCreator);
    GoalViewFactory.registerViewFactory(CoinCell.GOAL_ID, GoalViewFactory.baseGoalViewCreator);
};

GoalViewFactory.baseGoalViewCreator = function (element, options) {
    switch (element.type) {
        case Mark.GOAL_ID:
            var mark = new Mark();
            return new GoalView(element, new cc.Sprite(bundles[mark.bundleId()].frames["mark_goal" + mark.getSkinSuffix()]));
    }

    var cell;
    switch (element.type) {
        case "a":
        case "b":
        case "c":
        case "d":
        case "e":
        case "w":
            cell = new BaseCell(undefined, undefined, {
                color: element.type,
                coef: true
            });
            break;

        case FlowerCell.GOAL_ID:
            cell = new FlowerCell(-1, -1);
            cell.lives = 0;
            break;

        case FishCell.GOAL_ID:
            cell = new FishCell();
            break;

        case CookieManCell.GOAL_ID:
            cell = new CookieManCell();
            break;

        case NutCell.GOAL_ID:
            cell = new NutCell();
            break;

        case MouseCell.GOAL_ID:
            cell = new MouseCell(-1, -1);
            break;

        case BlanketCell.GOAL_ID:
            cell = new BlanketCell(-1, -1, 2, 2);
            break;

        case DogCell.GOAL_ID:
            cell = new DogCell(-1, -1);
            break;

        case DragonCell.GOAL_ID:
            cell = new DragonCell(-1, -1);
            break;

        case IceTile.GOAL_ID:
            cell = new IceTile(-1, -1);
            break;

        case GingerHouseCell.GOAL_ID:
            cell = new GingerHouseCell(-1, -1);
            break;

        case CakeCell.GOAL_ID:
            cell = new CakeCell(-1, -1);
            break;

        case MuffinGoalCell.GOAL_ID:
            cell = new MuffinGoalCell();
            break;

        case WaffleCell.GOAL_ID:
            cell = new WaffleCell(-1, -1);
            break;

        case TruffleCell.GOAL_ID:
            cell = new TruffleCell(-1, -1);
            break;

        case LollipopCell.GOAL_ID:
            cell = new LollipopCell(-1, -1);
            break;

        case CandleCell.GOAL_ID:
            cell = new CandleCell();
            break;

        case RabbitTile.GOAL_ID:
            cell = new RabbitTile();
            break;

        case ColorCookieManCell.GOAL_ID:
            cell = new ColorCookieManCell(-1, -1);
            break;

        case ColorBombCell.GOAL_ID:
            cell = new ColorBombCell(-1, -1);
            break;

        case RugTile.GOAL_ID:
            cell = new RugTile(-1, -1);
            break;

        case FireflyJarCell.GOAL_ID:
            cell = new FireflyJarCell(-1, -1);
            break;

        case DirtyHoneyTile.GOAL_ID:
            cell = new DirtyHoneyTile(-1, -1);
            break;

        case ColorContainerCell.GOAL_ID:
            cell = new ColorContainerCell(-1, -1);
            break;

        case IceMakerCell.GOAL_ID:
            cell = new IceMakerCell(-1, -1);
            break;

        case GumDecoratorCell.GOAL_ID:
            cell = new GumDecoratorCell(-1, -1);
            break;

        case ClockCell.GOAL_ID:
            cell = new ClockCell(-1, -1);
            break;

        case SyrupTile.GOAL_ID:
            cell = new SyrupTile();
            break;

        case DirtDecoratorCell.GOAL_ID:
            cell = new DirtDecoratorCell(-1, -1);
            break;

        case CrabDecoratorCell.GOAL_ID:
            cell = new CrabDecoratorCell();
            break;

        case BoxDecoratorCell.GOAL_ID:
            cell = new BoxDecoratorCell(-1, -1);
            break;

        case BagDecoratorCell.GOAL_ID:
            cell = new BagDecoratorCell();
            break;

        case BombCell.GOAL_ID:
            cell = new BombCell();
            break;

        case FireworkCell.GOAL_ID:
            cell = new FireworkCell();
            break;

        case FigureDragonCell.GOAL_ID:
            cell = new FigureDragonCell();
            break;

        case CoinCell.GOAL_ID:
            cell = new CoinCell();
            break;
    }
    var ViewClass = cell.getViewClass();
    var icon = new ViewClass(cell);

    return new GoalView(element, icon, options);
};

GoalViewFactory.registerViewFactory = function (type, factoryFunction) {
    GoalViewFactory.creators[type] = factoryFunction;
};

GoalViewFactory.createView = function (element, options) {
    var FactoryFunction = GoalViewFactory.creators[element.type];
    return new FactoryFunction(element, options);
};