/**
 * Created by Andrey Popov on 16.02.2024
 */

var SupplyPaperView = cleverapps.Spine.extend({
    ctor: function (supplyStage, logic) {
        this._super(bundles.supplies_window.jsons.supplies_paper_json);
        this.logic = logic;

        this.vertical = cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL;
        var styles = cleverapps.styles.SupplyPaperView;

        this.setAnimationAndIdleAfter(this.vertical ? "open_vert" : "open", this.vertical ? "animation_vert" : "animation");

        if (this.vertical) {
            this.setContentSize(styles);
            this.spine.setPositionRound(this.width / 2, this.height / 2);
        }

        if (["wooden", "heroes"].indexOf(cleverapps.config.ui) !== -1) {
            this.createRibbon();
        }

        if (styles.decor) {
            var decor = new cc.Sprite(bundles.supplies_window.frames.decor);
            decor.setAnchorPoint(0.5, 0.5);
            decor.setLocalZOrder(2);
            decor.setPositionRound(styles.decor.positions);
            this.addChild(decor);
        }

        this.updateStage(supplyStage, true);
    },

    createRibbon: function () {
        var styles = cleverapps.styles.SupplyPaperView.title;
        var size = styles.size[cleverapps.resolution.mode];

        var ribbon = this.ribbon = cleverapps.UI.createScale9Sprite(bundles.supplies_window.frames.supplies_ribbon);
        ribbon.setPositionRound(styles.positions);
        ribbon.setContentSize2(size.width || ribbon.width, size.height || ribbon.height);

        this.addChild(ribbon);
    },

    createTitleText: function (supplyStage) {
        var styles = cleverapps.styles.SupplyPaperView.title;

        var text = ["riddles", "tropical"].indexOf(cleverapps.config.ui) !== -1 ? "Supplies.dayN" : "Supplies.dayNRewards";

        var paperTitle = this.paperTitle = cleverapps.UI.generateOnlyText(text, cleverapps.styles.FONTS.SUPPLIES_PAPER_TEXT, {
            day: supplyStage + 1,
            separator: " "
        });

        paperTitle.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        paperTitle.setDimensions(styles.text.width, 0);
        paperTitle.fitTo(undefined, styles.text.height);

        paperTitle.setPositionRound(styles.text.positions);

        paperTitle.setCascadeOpacityEnabledRecursively(true);
        paperTitle.setOpacity(0);
        paperTitle.runAction(new cc.Sequence(new cc.DelayTime(0.2), new cc.FadeIn(0.2)));

        this.ribbon ? this.ribbon.addChild(paperTitle) : this.addChild(paperTitle);
    },

    updateStage: function (supplyStage, isOpen, callback) {
        var styles = cleverapps.styles.SupplyPaperView;

        if (!isOpen) {
            if (this.oldPaperTitle) {
                this.oldPaperTitle.stopAllActions();
                this.oldPaperTitle.removeFromParent();
            }
            if (this.oldRewards) {
                this.oldRewards.innerContent.targets.forEach(function (target) {
                    target.stopAllActions();
                    target.removeFromParent();
                });
                this.oldRewards.removeFromParent();
            }

            var oldPaperTitle = this.oldPaperTitle = this.paperTitle;
            oldPaperTitle.stopAllActions();
            oldPaperTitle.runAction(new cc.Sequence(
                new cc.FadeOut(0.2),
                new cc.RemoveSelf(),
                new cc.CallFunc(function () {
                    delete this.oldPaperTitle;
                }.bind(this))
            ));

            var oldRewards = this.oldRewards = this.rewardsScroll;
            var hideCallback = cleverapps.wait(oldRewards.innerContent.targets.length, function () {
                if (this.oldRewards) {
                    this.oldRewards.removeFromParent();
                    delete this.oldRewards;
                }
            }.bind(this));
            oldRewards.innerContent.targets.forEach(function (target) {
                target.setCascadeOpacityEnabledRecursively(true);

                target.runAction(new cc.Sequence(
                    new cc.Spawn(
                        new cc.FadeOut(0.2),
                        new cc.MoveBy(0.2, -this.width, 0).easing(cc.easeIn(1))
                    ),
                    new cc.CallFunc(hideCallback)
                ));
            }.bind(this));
        }

        this.createTitleText(supplyStage);

        var rewardsList = new RewardsListComponent(this.logic.reward[supplyStage], {
            font: cleverapps.styles.FONTS.SUPPLY_REWARD_TEXT,
            columns: "auto",
            fitToBox: styles.rewards.size[cleverapps.resolution.mode],
            noPrefix: false,
            textDirection: cleverapps.UI.VERTICAL,
            textMargin: 0,
            margin: styles.rewards.margin[cleverapps.resolution.mode],
            event: cleverapps.EVENTS.EARN.PURCHASE
        });

        this.rewardsScroll = new cleverapps.UI.ScrollView(rewardsList, {
            childrenVisibility: cleverapps.UI.ScrollView.CHILDREN_VISIBILITY_NONE,
            direction: cleverapps.UI.ScrollView.DIR_NONE,
            scrollBarEnabled: false,
            mouseScroll: false
        });
        this.rewardsScroll.setContentSize2(styles.rewards.size);
        this.rewardsScroll.setPositionRound(styles.rewards.positions);

        var distance = isOpen ? 0 : this.width;
        rewardsList.targets.forEach(function (target) {
            target.setCascadeOpacityEnabledRecursively(true);
            target.setOpacity(0);
            target.x += distance;
            callback = callback || function () {};

            target.runAction(new cc.Sequence(
                new cc.DelayTime(0.15),
                new cc.Spawn(
                    new cc.FadeIn(0.2),
                    new cc.MoveBy(0.2, -distance, 0).easing(cc.easeOut(2))
                ),
                new cc.CallFunc(callback)
            ));
        });

        this.addChild(this.rewardsScroll);
    },

    receiveRewards: function (callback) {
        var rewardList = this.rewardsScroll.innerContent;

        rewardList.stopAllActions();
        rewardList.receiveRewards();
        rewardList.receiveRewardsAnimation({ callback: callback });
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    SUPPLY_REWARD_TEXT: {
        name: "nostroke",
        size: 36,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    }
});

cleverapps.styles.SupplyPaperView = {
    width: 700,
    height: 550,

    title: {
        size: [
            { width: 800 },
            { width: 440 },
            { width: 440 }
        ],

        positions: [
            { x: { align: "center", dx: 17 }, y: { align: "top", dy: 40 } },
            { x: { align: "center", dx: 5 }, y: { align: "top", dy: 70 } },
            { x: { align: "center", dx: 5 }, y: { align: "top", dy: 70 } }
        ],

        text: {
            width: 370,
            height: 70,

            positions: [
                { x: { align: "center", dx: -4 }, y: { align: "center", dy: 13 } },
                { x: { align: "center", dx: -4 }, y: { align: "center", dy: 13 } },
                { x: { align: "center", dx: -4 }, y: { align: "center", dy: 13 } }
            ]
        }
    },

    rewards: {
        positions: [
            { x: { align: "center", dx: 5 }, y: { align: "center", dy: -20 } },
            { x: { align: "center" }, y: { align: "center", dy: -10 } },
            { x: { align: "center" }, y: { align: "center", dy: -10 } }
        ],

        size: [
            { width: 720, height: 460 },
            { width: 370, height: 450 },
            { width: 370, height: 450 }
        ],

        margin: [{ x: 100, y: 40 }, undefined, undefined]
    },

    decor: {
        positions: [
            { x: { align: "left", dx: -80 }, y: { align: "bottom", dy: 20 } },
            { x: { align: "left", dx: -80 }, y: { align: "bottom", dy: -37 } },
            { x: { align: "left", dx: -80 }, y: { align: "bottom", dy: -37 } }
        ]
    }
};