/**
 * Created by ivan on 06.04.18.
 */

var BurstCellView = BaseCellView.extend({
    ctor: function (burstCell) {
        this._super();

        this.setContentSize(cleverapps.styles.BaseCellView.CELL_SIZE_PX, cleverapps.styles.BaseCellView.CELL_SIZE_PX);
        this.setAnchorPoint(0.5, 0.5);

        var image = new cc.Sprite(this.getImage(burstCell.lives));
        this.image = image;
        this.image.setPosition(this.width / 2, this.height / 2);
        this.addChild(image);

        this.animation = new cleverapps.Spine(bundles.burst.jsons.burst_json);
        this.animation.setPosition(this.width / 2, this.height / 2);
        this.animation.setStartVisibleListener(function () {
            this.image.visible = false;
        }.bind(this));
        this.addChild(this.animation);

        this.initialize(burstCell);
    },

    getImage: function (lives) {
        lives = lives || this.cell.lives;
        return bundles.burst.frames["burst_" + (4 - lives)];
    },

    updateImage: function () {
        var image = this.getImage();
        if (image === undefined) {
            return;
        }
        this.image.setSpriteFrame(image);
    },

    initialize: function (burstCell) {
        this._super(burstCell);

        this.image.setVisible(true);
        this.animation.setVisible(false);

        burstCell.onChangeLivesListener = this.changeLives.bind(this);
        burstCell.onBurstListener = this.burstAnimation.bind(this);
    },

    animate: function (name, cycle, onFinish) {
        this.animation.setAnimation(0, name, cycle);
        this.animation.setCompleteListener(onFinish);
    },

    finishAnimation: function () {
        this.updateImage();
        this.image.setVisible(true);
        this.animation.setVisible(false);
    },

    changeLives: function () {
        if (this.cell.lives > 0) {
            if (this.cell.lives < BurstCell.MAX_LIVES) {
                this.animate("" + (BurstCell.MAX_LIVES - this.cell.lives), false, this.finishAnimation.bind(this));
            } else {
                this.updateImage();
            }
        } else {
            this.animate("4_idle", true, function () {});
        }

        if (bundles.burst.urls.burst_fill_effect) {
            cleverapps.audio.playSound(bundles.burst.urls.burst_fill_effect);
        }
    },

    unuse: function () {
        this.cell.onChangeLivesListener = function () {};

        this._super();
    },

    openAnimation: function () {
        var realZOrder = this.getLocalZOrder();
        this.setLocalZOrder(2);
        this.animate("open", false, function () {
            this.setLocalZOrder(realZOrder);
        }.bind(this));

        var styles = cleverapps.styles.BurstCellView;
        this.runAction(new cc.Sequence(
            new cc.DelayTime(styles.openSound.delay),
            new cc.CallFunc(function () {
                cleverapps.audio.playSound(bundles.game.urls.burstcell_open_effect);
            })
        ));
    },

    burstAnimation: function (targetCell, id) {
        var colors = ["purple", "yellow", "green", "red", "blue"];
        var ball = new cc.Sprite(bundles.burst.frames["ball_" + colors[id]]);
        ball.setOpacity(0);
        this.parent.addChild(ball);
        ball.setAnchorPoint(0.5, 0.5);
        ball.setPosition(this.getPosition());
        ball.y -= cleverapps.styles.BaseCellView.CELL_SIZE_PX / 2;
        var FADE_TIME = 0.1;
        var delayTime = id * BurstCell.BURST_INTERVAL;
        var burstDuration = this.cell.burstDuration(targetCell, id);
        var targetPosition = BaseCellView.alignInTheGrid(targetCell.x, targetCell.y);
        ball.runAction(new cc.Sequence(
            new cc.DelayTime(delayTime),
            new cc.Spawn(
                new cc.FadeIn(FADE_TIME),
                new cc.MoveTo(burstDuration - delayTime, targetPosition).easing(cc.easeQuadraticActionIn()),
                new cc.CallFunc(function () {
                    cleverapps.audio.playSound(bundles.game.urls.bomb_by_left_moves_explosion_effect, { throttle: 0 });
                }),
                new cc.Sequence(
                    new cc.ScaleTo((burstDuration - delayTime) / 2, 1.8),
                    new cc.ScaleTo((burstDuration - delayTime) / 2, 1.3)
                ),
                new cc.Sequence(
                    new cc.DelayTime(burstDuration - delayTime - FADE_TIME),
                    new cc.FadeOut(FADE_TIME)
                )
            ),
            new cc.RemoveSelf()
        ));

        this.runAction(new cc.Sequence(
            new cc.DelayTime(burstDuration),
            new cc.CallFunc(function () {
                var blastAnimation = BlastAnimation.factory(colors[id]);
                blastAnimation.setPosition(targetPosition);
                blastAnimation.setLocalZOrder(10);
                this.parent.addChild(blastAnimation);
                blastAnimation.runAnimate("animation");
            }.bind(this))
        ));
    },

    hurtAnimation: function () {
    }
});

cleverapps.styles.BurstCellView = {
    openSound: {
        delay: 0.4
    }
};