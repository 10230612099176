/**
 * Created by vladislav on 03.03.2021
 */

var ColorPropertyEditor = function (property, initialValue, onChangeValueCallback) {
    this.property = property;
    this.onChangeValueCallback = onChangeValueCallback;
    initialValue = initialValue || {};

    this.value = initialValue || {
        color: cleverapps.styles.COLORS.WHITE
    };

    this.propertyEditors = [{
        name: property.name,
        type: "color",
        getter: function () {
            return this.value.color;
        }.bind(this),
        setter: function (value) {
            this.value.color = value;
            return this.value.color;
        }.bind(this)
    }].map(function (propertyDescription) {
        return new PropertyEditor(propertyDescription, this);
    }.bind(this));
};

ColorPropertyEditor.prototype.editBoxEditingDidEnd = function (sender) {
    sender.customSetter(sender.getString());
    this.onChangeValueCallback(this.property.name, this.value);
};