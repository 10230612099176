/**
 * Created by mac on 8/03/17.
 */

var FloorView = cc.Node.extend({
    ctor: function (field) {
        this._super();

        this.setCascadeOpacityEnabled(true);

        this.onTouchBeganListener = function () {};
        this.onTouchMovedListener = function () {};
        this.onTouchEndedListener = function () {};

        field.iterateFloor(function (row, col) {
            this.addBackground(col, row);
        }.bind(this));

        field.iterateTiles(function (tile) {
            this.addTile(tile);

            if (tile.constructor === TransporterTile) {
                tile.onSetImage();
                tile.onAnimate();
            }
        }.bind(this));

        field.onGetFloorBackgroundView = this.createListener(function (x, y) {
            return this.getChildByTag(x * Field.SIZE + y);
        }.bind(this));

        field.on("addTile", this.addTile.bind(this), this);

        this.calculateBorder(field);
    },

    addBackground: function (x, y) {
        var image = [bundles.game.frames.tile_background_1, bundles.game.frames.tile_background_2][(x + y) % 2];
        var background = cleverapps.UI.createScale9Sprite(image, cleverapps.UI.Scale9Rect.TwoPixelXY);
        background.setContentSize(cleverapps.styles.BaseCellView.CELL_SIZE_PX, cleverapps.styles.BaseCellView.CELL_SIZE_PX);
        background.setPosition(BaseCellView.alignInTheGrid(x, y));
        this.addChild(background, -1, x * Field.SIZE + y);
    },

    addTile: function (tile) {
        var ViewClass = tile.getViewClass();
        var view = cc.pool.hasObject(ViewClass) ? cc.pool.getFromPool(ViewClass, tile) : new ViewClass(tile);
        view.beforeShowUpAnimation();
        this.addChild(view);
    },

    calculateBorder: function (filed) {
        var floor = filed.floor;

        var DIRS = [{ x: 1, y: 0 }, { x: 0, y: 1 }, { x: -1, y: 0 }, { x: 0, y: -1 }];

        var LEFT = 0;
        var STRAIGHT = 1;
        var RIGHT = 2;

        var exist = function (row, col, d) {
            var x = col - d.x;
            var y = row - d.y;

            if (y >= 0 && y < Field.SIZE && x >= 0 && x < Field.SIZE) {
                return floor[y][x] !== null;
            }
        };

        var sum = function (d1, d2) {
            return {
                x: d1.x + d2.x,
                y: d1.y + d2.y
            };
        };

        filed.iterateFloor(function (row, col) {
            for (var k = 0; k < DIRS.length; k++) {
                var d = DIRS[k];
                if (!exist(row, col, d)) {
                    var nd = DIRS[(k + 1) % DIRS.length];
                    if (exist(row, col, sum(d, nd))) {
                        this.addBorder(row, col, k, LEFT);
                    } else if (exist(row, col, nd)) {
                        this.addBorder(row, col, k, STRAIGHT);
                    } else {
                        this.addBorder(row, col, k, RIGHT);
                    }
                }
            }
        }.bind(this));
    },

    addBorder: function (y, x, dir, type) {
        var resource = bundles.game.frames["border_" + FloorView.DIR_NAMES[dir] + FloorView.TURN_NAMES[type]];
        if (!resource) {
            return;
        }

        var image = new cc.Sprite(resource);
        image.setAnchorPoint(0.5, 0.5);
        image.setPosition(BaseCellView.alignInTheGrid(x, y));
        image.setLocalZOrder(1);
        this.addChild(image);

        if (cleverapps.gameModes.floorBorderOverCells) {
            image.replaceParentSamePlace(this.parent);
            image.setLocalZOrder(5);
        }
    }
});

FloorView.DIR_NAMES = ["left", "top", "right", "bottom"];
FloorView.TURN_NAMES = ["_left", "_straight", "_right"];
