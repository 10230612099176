/**
 * Created by vladislav on 2/28/2024
 */

var Activities = function () {
    this.lastActivitySent = 0;

    this.interval = Activities.INVERVALS[connector.info.source];
};

Activities.prototype.canSend = function (activityType) {
    if (this.interval && Date.now() - this.lastActivitySent < this.interval) {
        return false;
    }

    var config = Activities.CONFIG[activityType];

    return config.probability > Math.random();
};

Activities.prototype.send = function (activityType, data) {
    if (connector.platform.oneOf(connector.VK)) {
        this._sendVkActivity(activityType, data);
        return;
    }

    if (!this.canSend(activityType)) {
        return;
    }

    var config = Activities.CONFIG[activityType];
    var message = cleverapps.Random.mathChoose(config.messages);

    var params = {
        title: Messages.get(message.title),
        body: Messages.get(message.body, data),
        link: cleverapps.config.deployment
    };

    connector.social.sendActivity(params, function (code) {
        if (code === cleverapps.CODE_SUCCEED) {
            this.lastActivitySent = Date.now();
        }
    }.bind(this));
};

Activities.prototype._sendVkActivity = function (activityType, data) {
    if (activityType !== Activities.TYPES.LVL_PASSED) {
        return;
    }

    var activityData;
    if (data.score && Math.random() < 0.5) {
        activityData = {
            activityId: 2,
            value: data.score
        };
    } else {
        activityData = {
            activityId: 1,
            value: data.levelNo
        };
    }

    connector.social.sendActivity(activityData, function () {});
};

Activities.INVERVALS = {
    draugiem: cleverapps.parseInterval("1 day")
};

Activities.TYPES = {};
Activities.TYPES.LVL_PASSED = 0;
Activities.TYPES.DAILY_TASK = 1;

Activities.CONFIG = {};

Activities.CONFIG[Activities.TYPES.LVL_PASSED] = {
    probability: 0.25,
    messages: [{
        title: "Activity.PassLevel.title",
        body: "Activity.PassLevel.body1"
    }, {
        title: "Activity.PassLevel.title",
        body: "Activity.PassLevel.body2"
    }, {
        title: "Activity.PassLevel.title",
        body: "Activity.PassLevel.body3"
    }
    ]
};

Activities.CONFIG[Activities.TYPES.DAILY_TASK] = {
    probability: 0.5,
    messages: [{
        title: "Activity.DailyTask.title",
        body: "Activity.DailyTask.body1"
    }, {
        title: "Activity.DailyTask.title",
        body: "Activity.DailyTask.body2"
    }]
};