/**
 * Created by Denis Kuzin on 06 june 2024
 */

var SpecialShapeComponent = function (cell, cellClass) {
    this.cell = cell;
    this.cellClass = cellClass;

    if (!Game.currentGame.HEADS) {
        Game.currentGame.HEADS = [];
    }
};

SpecialShapeComponent.prototype.editorComponents = function () {
    if (this.isHead) {
        return [BaseCellComponent, SpecialShapeCellComponent];
    }
};

SpecialShapeComponent.prototype.load = function (data) {
    if (data.length > 1) {
        this.initConfig(data[1], parseInt(data[2]));
        var digits = data.splice(3).join("");
        this.cell.lives = parseInt(digits);

        this.head = this.cell;
        this.isHead = true;

        var configHeadX, configHeadY;
        var found = false;
        for (var row = 0; row < this.getSize().rows && !found; row++) {
            for (var col = 0; col < this.getSize().cols && !found; col++) {
                if (this.config[row][col] === 1) {
                    configHeadX = col;
                    configHeadY = row;
                    found = true;
                }
            }
        }
        this.head.topLeftX = this.head.x - configHeadX;
        this.head.topLeftY = this.head.y - configHeadY;

        if (!this.cell.isForGoal && !this.cell.isForEditor) {
            Game.currentGame.HEADS.push(this.cell);
        }
    } else {
        this.initHead();
    }
};

SpecialShapeComponent.prototype.initHead = function () {
    this.head = this.findHead(this.cell.x, this.cell.y);
    this.isHead = false;
};

SpecialShapeComponent.prototype.findHead = function (x, y) {
    for (var i = 0; i < Game.currentGame.HEADS.length; i++) {
        var head = Game.currentGame.HEADS[i];
        var found = false;
        head.findComponent(SpecialShapeComponent).iterateSelf(function (cellX, cellY) {
            if (x === cellX && y === cellY) {
                found = true;
            }
        });
        if (found) {
            return head;
        }
    }
};

SpecialShapeComponent.prototype.save = function () {
    if (this.isHead) {
        return this.getSaveData();
    }

    return this.cellClass.CODES;
};

SpecialShapeComponent.prototype.getSaveData = function () {
    var data = this.cellClass.CODES.concat([this.cell.configId, this.cell.rotation]);
    var digits = "" + this.cell.lives;
    return data.concat(digits.split(""));
};

SpecialShapeComponent.prototype.iterateSelf = function (iterator) {
    for (var row = 0; row < this.getSize().rows; row++) {
        for (var col = 0; col < this.getSize().cols; col++) {
            if (this.config[row][col] === 1) {
                iterator(this.head.topLeftX + col, this.head.topLeftY + row);
            }
        }
    }
};

SpecialShapeComponent.prototype.getSize = function () {
    return { rows: this.config.length, cols: this.config[0].length };
};

SpecialShapeComponent.prototype.rotateConfig = function (anticlockwise) {
    var size = this.getSize();
    var newConfig = [];
    for (var i = 0; i < size.cols; i++) {
        newConfig.push([]);
    }

    for (var row = 0; row < size.rows; row++) {
        for (var col = 0; col < size.cols; col++) {
            if (anticlockwise) {
                newConfig[size.cols - 1 - col][row] = this.config[row][col];
            } else {
                newConfig[col][size.rows - 1 - row] = this.config[row][col];
            }
        }
    }

    this.config = newConfig;
};

SpecialShapeComponent.prototype.getConfig = function () {
    if (this.cell.getConfig) {
        return this.cell.getConfig();
    }

    throw "Not implemented";
};

SpecialShapeComponent.prototype.initConfig = function (configId, rotation) {
    this.cell.configId = configId;

    this.config = this.getConfig();

    this.cell.rotation = rotation;
    while (rotation > 0) {
        this.rotateConfig();
        rotation--;
    }
};

SpecialShapeComponent.prototype.getLives = function () {
    if (!this.isHead) {
        return this.head.getLives();
    }
    return this.cell.lives;
};

SpecialShapeComponent.prototype.hurt = function () {
    if (!this.isHead) {
        this.head.hurt();
        return;
    }

    return true;
};

SpecialShapeComponent.prototype.hover = function (state) {
    if (!this.isHead) {
        this.head.hover(state);
        return;
    }

    return true;
};

SpecialShapeComponent.prototype.animate = function (type, params) {
    if (!this.isHead) {
        this.head.animate(type, params);
        return;
    }

    return true;
};

SpecialShapeComponent.prototype.onHeadExplode = function () {
    this.cell.explodeViaCollect();
};

SpecialShapeComponent.prototype.explode = function () {
    if (this.isHead) {
        if (!this.cell.findComponent(SpecialShapeComponent) || !Game.currentGame) {
            return;
        }

        this.iterateSelf(function (x, y) {
            var cell = Game.currentGame.field.cells[y][x];
            if (cell !== this.cell) {
                cell.explode();
            }
        }.bind(this));

        this.onHeadExplode();

        this.cell.removeComponent(SpecialShapeComponent);

        return;
    }

    return true;
};