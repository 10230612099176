/**
 * Created by slava on 02.02.17.
 */

var HeroCellView = GoalColorCellView.extend({
    ctor: function (cell) {
        this._super(cell);

        var color = cell.findComponent(ColorComponent).color.toUpperCase();
        this.animation = new cleverapps.Spine(cleverapps.skins.getSlot(color + "_hero_spine_json") || bundles.heroes.jsons[color + "_hero_spine_json"]);
        this.animation.setSkin(match3.heroes.getCurrentSkin());
        this.animation.setPosition(this.width / 2, this.height / 2);
        this.animation.setAnimation(0, "idle", true);
        this.addChild(this.animation, -1);
    },

    createAnimation: function (params) {
        cleverapps.audio.playSound(bundles.game.urls.booster_5_show_effect);

        params = params || {};
        if (!params.silent) {
            this.setScale(0);
            this.runAction(new cc.ScaleTo(0.4, 1).easing(cc.easeBackOut()));
        }

        var light = this.light = new cleverapps.Spine(bundles.game.jsons.story_light_animation_json);
        light.setScale(2);
        light.setPositionRound(this.width / 2 + cleverapps.styles.FireworkCellView.light.x, this.height / 2 + cleverapps.styles.FireworkCellView.light.y);
        light.setLocalZOrder(-2);
        this.addChild(light);

        var lightZOrder = this.getLocalZOrder();
        if (!lightZOrder) {
            lightZOrder = 0;
        }
        this.setLocalZOrder(3);
        light.setAnimation(0, "animation", true);
        light.runAction(new cc.Sequence(
            new cc.DelayTime(1.5),
            new cc.ScaleTo(0.2, 0),
            new cc.CallFunc(function () {
                this.setLocalZOrder(lightZOrder);
                this.removeLight();
            }.bind(this))
        ));

        if (this.cell.algo.createAnimation) {
            this.animation.setAnimationAndIdleAfter(this.cell.algo.createAnimation, "idle");
        } else {
            this.animation.setAnimation(0, "idle", true);
        }
    },

    removeLight: function () {
        if (this.light) {
            this.light.stopAllActions();
            this.light.runAction(new cc.RemoveSelf());
            this.light = undefined;
        }
    },

    animateEffect: function () {
        this.removeLight();
        this.inShakeNow = true;

        if (this.cell.algo.bigZOrder) {
            this.setLocalZOrder(20);
        } else {
            this.setLocalZOrder(3);
        }

        this.effectSound();

        var goalCoefComponent = this.cell.findComponent(GoalCoefComponent);
        var coefView = goalCoefComponent && goalCoefComponent.view;

        if (this.cell.actionDirection) {
            if (coefView) {
                coefView.setVisible(false);
            }
            this.setLocalZOrder(22);
            var overshootCoef = cleverapps.styles.HeroCellView.animation.overshoot ? 0.25 : 0;
            var x = this.cell.x + (this.cell.actionLength + overshootCoef) * this.cell.actionDirection.x;
            var y = this.cell.y + (this.cell.actionLength + overshootCoef) * this.cell.actionDirection.y;
            var targetPosition = BaseCellView.alignInTheGrid(x, y);

            this.runAction(new cc.Sequence(
                new cc.DelayTime(this.cell.algo.action_delay * 0.8 / 1000),
                new cc.MoveTo(this.cell.algo.direct_timeout / 1000, targetPosition),
                new cc.CallFunc(function () {
                    if (coefView) {
                        coefView.setVisible(true);
                    }
                })
            ));

            var animationName = cleverapps.styles.HeroCellView.animation.direction;

            if (this.cell.actionDirection.x > 0) {
                this.animation.setAnimation(0, animationName.right, false);
            } else if (this.cell.actionDirection.x < 0) {
                this.animation.setAnimation(0, animationName.left, false);
            } else if (this.cell.actionDirection.y > 0) {
                this.animation.setAnimation(0, animationName.down, false);
            } else {
                this.animation.setAnimation(0, animationName.up, false);
            }
        } else {
            this.animation.setAnimation(0, "out", false);
        }

        this.animation.setCompleteListener(function () {
            this.animation.setAnimation(0, "idle", true);
            this.inShakeNow = false;
        }.bind(this));
    },

    effectSound: function () {
        var colorComponent = this.cell.findComponent(ColorComponent);
        if (colorComponent.color && bundles.game.urls["hero_sound_" + colorComponent.color]) {
            cleverapps.audio.playSound(bundles.game.urls["hero_sound_" + colorComponent.color]);
        }
    },

    smile: function () {
    },

    stopSmile: function () {
    },

    initialize: function (cell) {
        GoalColorCellView.prototype.initialize.call(this, cell);

        cell.algoEffectAnimation = this.animateEffect.bind(this);

        var comboComponent = cell.findComponent(ComboComponent);
        comboComponent.view.afterInitialize(cell);
    },

    unuse: function () {
        this.animation.visible = false;

        this.cell.algoEffectAnimation = function () {};

        var comboComponent = this.cell.findComponent(ComboComponent);
        comboComponent.view.beforeUnuse();

        this.stopSmile();

        GoalColorCellView.prototype.unuse.call(this);
    },

    reuse: function (cell) {
        this._super(cell);

        this.animation.setAnimation(0, "idle", true);
    },

    startExploding: function (goal, idInGroup, params) {
        if (this.cell.findComponent(GoalCoefComponent) && goal.view) {
            GoalColorCellView.prototype.startExploding.call(this, goal, idInGroup, params);
        } else {
            ColorCellView.prototype.startExploding.call(this, goal, idInGroup, params);
        }
    },

    createFromCenterAnimation: function () {
        this.animation.setAnimation(0, "idle", true);

        this._super.apply(this, arguments);
    }
});

// one color - one pool
// eslint-disable-next-line camelcase
var HeroCellView_A = HeroCellView.extend({});
// eslint-disable-next-line camelcase
var HeroCellView_B = HeroCellView.extend({});
// eslint-disable-next-line camelcase
var HeroCellView_C = HeroCellView.extend({});
// eslint-disable-next-line camelcase
var HeroCellView_D = HeroCellView.extend({});
// eslint-disable-next-line camelcase
var HeroCellView_E = HeroCellView.extend({});
// eslint-disable-next-line camelcase
var HeroCellView_W = HeroCellView.extend({});

cleverapps.styles.HeroCellView = {
    animation: {
        overshoot: true,

        direction: {
            left: "out",
            right: "out2",
            up: "out",
            down: "out2"
        }
    }
};