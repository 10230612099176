/**
 * Created by ivan on 23.03.18.
 */

var CakeAnimation = BaseAnimation.extend({
    ctor: function (bundle) {
        this._super(bundles[bundle].jsons[bundle + "_splash_json"]);
    }
});

CakeAnimation.factory = function (bundle) {
    return cc.pool.hasObject(CakeAnimation) ? cc.pool.getFromPool(CakeAnimation) : new CakeAnimation(bundle);
};