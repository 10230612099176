/**
 * Created by Anastasiya on 01.03.2024
 */

// animations descriptions: https://docs.google.com/spreadsheets/d/1g24GcHLbiRM-3ALfqUD6R-hCTnh81b2gyKMal_FWNXo/edit?gid=1560161212#gid=1560161212

AnimationsLibrary = {};

AnimationsLibrary.click = function (sprite) {
    var position = sprite.getPosition();
    var dScale = sprite.height < cleverapps.styles.AnimationsLibrary.click.smallHeight ? 0.1 : 0.05;

    return new cc.Sequence(
        new cc.Spawn(
            new cc.ScaleTo(0.1, 1 + dScale, 1 - dScale),
            new cc.MoveBy(0.1, 0, -dScale / 2 * sprite.height)
        ),
        new cc.Spawn(
            new cc.ScaleTo(0.1, 1, 1),
            new cc.MoveTo(0.1, position.x, position.y)
        )
    );
};

AnimationsLibrary.fallDown = function (node, dustAnimation) {
    dustAnimation = dustAnimation || bundles.animations_library.jsons.fall_down_json;

    var mapView = Map2d.currentMap.getView();

    var pos = mapView.alignInIsometricGrid(node.unit.x, node.unit.y);
    if (node.unit.isMultiCell()) {
        pos = mapView.alignPositionInIsometricGrid(
            node.unit.x,
            node.unit.y,
            MultiCellView.calcCenterPosition(node.unit.findComponent(MultiCell).shape)
        );
    }

    return new cc.Spawn(
        new cc.MoveBy(0.25, pos.x - node.x, 0).easing(cc.easeOut(2)),
        new cc.MoveBy(0.15, 0, pos.y - node.y).easing(cc.easeIn(1)),
        new cc.CallFunc(function () {
            var dustSpine = new cleverapps.Spine(dustAnimation);
            dustSpine.setPositionRound({
                x: { align: "center" },
                y: { align: "center" }
            });
            dustSpine.setCompleteListenerRemove();
            dustSpine.setSafeToRemove();
            dustSpine.setLocalZOrder(-1);
            mapView.addTile(Map2d.LAYER_UNITS, node.unit.x, node.unit.y, dustSpine);
            dustSpine.setAnimation(0, node.unit.isMultiCell() ? "animation_multicell" : "animation", false);

            cleverapps.audio.playSound(node.unit.isMultiCell() ? bundles.merge.urls.unit_fall_multicell_effect : bundles.merge.urls.unit_fall_effect);
        })
    );
};

AnimationsLibrary.jump = function () {
    return new cc.Spawn(
        new cc.Sequence(
            new cc.DelayTime(0.4),
            new cc.MoveBy(0.2, 0, 20),
            new cc.MoveBy(0.2, 0, -20)
        ),
        new cc.Sequence(
            new cc.ScaleTo(0.4, 1.1, 0.90).easing(cc.easeCubicActionOut()),
            new cc.ScaleTo(0.2, 0.85, 1.15),
            new cc.ScaleTo(0.3, 1.05, 0.95),
            new cc.ScaleTo(0.1, 1, 1)
        )
    );
};

AnimationsLibrary.squeezeJump = function (source, options) {
    options = options || {};
    var jumpHeight = options.jumpHeight || cleverapps.styles.AnimationsLibrary.squeezeJump.jumpHeight;
    var baseScale = { x: 1, y: 1 };
    if (options.baseScale) {
        baseScale.x = options.baseScale.x || options.baseScale;
        baseScale.y = options.baseScale.y || options.baseScale;
    }
    var anchorPoint;
    return new cc.Sequence(
        new cc.CallFunc(function () {
            anchorPoint = source.getAnchorPoint();
            source.setAnchorPoint(anchorPoint.x, 0);
            source.setPosition(source.getPosition().x, source.getPosition().y - source.height * anchorPoint.y);
        }),
        new cc.ScaleTo(0.1, 1.25 * baseScale.x, 0.8 * baseScale.y),
        new cc.Spawn(
            new cc.Sequence(
                new cc.ScaleTo(0.1, 0.5 * baseScale.x, 1.3 * baseScale.y),
                new cc.ScaleTo(0.15, baseScale.x, baseScale.y).easing(cc.easeOut(2))
            ),
            new cc.Sequence(
                new cc.DelayTime(0.08),
                new cc.MoveBy(0.5, 0, jumpHeight).easing(cc.easeBackOut())
            )
        ),
        new cc.CallFunc(function () {
            source.setAnchorPoint(anchorPoint);
            source.setPosition(source.getPosition().x, source.getPosition().y + source.height * anchorPoint.y);
        })
    );
};

AnimationsLibrary.shiningLine = function (node, stripeAnimation) {
    stripeAnimation = stripeAnimation || bundles.animations_library.jsons.complete_json;

    return new cc.CallFunc(function () {
        var completeSpine = new cleverapps.Spine(stripeAnimation);
        completeSpine.setCompleteListenerRemove();
        completeSpine.setSafeToRemove();
        completeSpine.setPositionRound({
            x: { align: "center" },
            y: { align: "center" }
        });
        node.addChild(completeSpine);
        completeSpine.setScale(node.height / completeSpine.height);
        completeSpine.setAnimation(0, "animation", false);
    });
};

AnimationsLibrary.rays = function (node, options) {
    options = options || {};
    var skin = options.skin || "yellow";
    var size = options.size || node.height;
    var raysSpine = new cleverapps.Spine(bundles.animations_library.jsons.rays_json);
    raysSpine.setSkin(skin);
    raysSpine.setScale(size / raysSpine.height);
    node.addChild(raysSpine, -1);
    raysSpine.setPositionRound({
        x: { align: "center" },
        y: { align: "center" }
    });
    raysSpine.setAnimationAndIdleAfter("open", "idle");

    return raysSpine;
};

AnimationsLibrary.disappear = function (duration) {
    duration = duration === undefined ? 0.5 : duration;

    return new cc.Sequence(
        new cc.ScaleTo(duration, 0.2).easing(cc.easeBackIn()),
        new cc.Hide()
    );
};

AnimationsLibrary.appear = function (duration) {
    duration = duration === undefined ? 0.5 : duration;

    return new cc.Sequence(
        new cc.Hide(),
        new cc.ScaleTo(0.05, 0.2),
        new cc.Show(),
        new cc.ScaleTo(duration, 1).easing(cc.easeBackOut())
    );
};

AnimationsLibrary.upgrade = function (nodeOld, nodeNew, parent, options) {
    options = options || {};
    var duration = options.duration || 1;
    var individualAnimationDuration = duration / 2;
    return new cc.Sequence(
        new cc.CallFunc(function () {
            var action = nodeOld === nodeNew
                ? AnimationsLibrary.disappear(individualAnimationDuration)
                : new cc.Sequence(AnimationsLibrary.disappear(individualAnimationDuration), new cc.RemoveSelf());
            nodeOld.runAction(action);
        }),
        new cc.DelayTime(individualAnimationDuration),
        new cc.CallFunc(function () {
            nodeNew.runAction(
                AnimationsLibrary.appear(individualAnimationDuration)
            );
        }),
        new cc.DelayTime(individualAnimationDuration)
    );
};

AnimationsLibrary.shake = function (node, options) {
    options = options || {};
    var delay = options.delay || 0;
    var power = options.power || 1;
    var moveTime = options.moveTime || 0;
    var delayTime = options.delayTimeAfterMove !== undefined ? options.delayTimeAfterMove : 0.05;

    var strength = options.strength;
    if (!strength) {
        strength = cleverapps.styles.AnimationsLibrary.shake;
    }

    var points = options.points || [
        { x: 2, y: 1, r: 0 },
        { x: -1, y: -2, r: -1 },
        { x: -3, y: 0, r: 1 },
        { x: 0, y: 2, r: 0 },
        { x: 1, y: -1, r: 1 },
        { x: 0, y: 0, r: 0 }
    ];

    var base = options.base;

    if (!base) {
        base = {
            x: node.x,
            y: node.y,
            r: node.rotation
        };
    }

    var shiftActions = points.map(function (point) {
        return new cc.Spawn(
            new cc.MoveTo(moveTime, base.x + point.x * strength.dx * power, base.y + point.y * strength.dy * power),
            new cc.RotateTo(moveTime, base.r + point.r)
        );
    });

    var actions = [new cc.DelayTime(delay / 1000)];

    shiftActions.forEach(function (action) {
        actions.push(action);
        actions.push(new cc.DelayTime(delayTime));
    });

    if (options.afterShakeAction) {
        actions.push(options.afterShakeAction);
    }

    return new cc.Sequence(actions);
};

AnimationsLibrary.animateDelta = function (delta, options) {
    var duration = 1;
    if (typeof delta !== "string") {
        delta = delta > 0 ? "+" + delta : "" + delta;
    }

    options.font = options.font || cleverapps.styles.FONTS.WHITE_TEXT || cleverapps.styles.FONTS.TEXT;

    var content = new TextWithIcon(delta, options);
    content.setCascadeOpacityEnabledRecursively(true);

    options.target.addChild(content);

    if (options.x !== undefined && options.y !== undefined) {
        content.setPositionRound(options);
    } else {
        content.setPositionRound(options.target.width * 0.5, options.target.height * 0.75);
    }

    var dy = content.height * (options.moveRate || 0.3);
    content.opacity = 0;
    content.visible = false;

    return new cc.CallFunc(function () {
        content.replaceParentSamePlace(cleverapps.scenes.getMovingNode(content));

        content.runAction(new cc.Sequence(
            new cc.Show(),
            new cc.FadeIn(),
            new cc.Spawn(
                new cc.MoveBy(duration, cc.p(0, dy)).easing(cc.easeIn(0.7)),
                new cc.FadeTo(duration, 0)
            ),
            new cc.RemoveSelf()
        ));
    });
};

AnimationsLibrary.pulse = function (node, options) {
    var SCALE_RATE = 1.3;
    var FPS = 1;
    var duration = options.duration || 0.5;
    var steps = Math.ceil(FPS * duration);
    var stepDuration = duration / steps;
    var actions = [];
    var baseScale = node.baseScale || node.getScale();
    for (var i = 0; i < steps; i++) {
        actions.push(new cc.Sequence(
            new cc.ScaleTo(stepDuration / 2, baseScale * SCALE_RATE),
            new cc.ScaleTo(stepDuration / 2, baseScale)
        ));
    }
    return new cc.Sequence(actions);
};

AnimationsLibrary.countTo = function (node, newValue, options) {
    var duration = options.duration || 1;

    var currentText = node.getString();

    var parts = currentText.split("/");

    var slash = "/";
    if (parts[0][parts[0].length - 1] === " ") {
        slash = " / ";
    }

    var currentValue = +parts[0].trim();
    var total = parts[1] && +parts[1].trim();

    var FPS = 30;
    var delta = newValue - currentValue;
    var steps = Math.min(Math.ceil(FPS * duration), Math.abs(delta));
    var increment = delta / steps;

    var updateValueAction = function (value) {
        return new cc.Sequence(
            new cc.CallFunc(function () {
                node.setString(total !== undefined ? value + slash + total : value);
            }),
            new cc.DelayTime(duration / steps)
        );
    };

    var actions = [];
    for (var i = 0; i < steps; i++) {
        currentValue += increment;
        actions.push(updateValueAction(Math.round(currentValue)));
    }

    return new cc.Sequence(actions);
};

AnimationsLibrary.jumpCollect = function (source, target, options) {
    options = options || {};
    target = cleverapps.aims.getTarget(target);

    var styles = cleverapps.styles.AnimationsLibrary.jumpCollect;
    var jumpMovingNode = cleverapps.scenes.getMovingNode(source);
    var collectMovingNode = options.collectMovingNode || cleverapps.scenes.getMovingNode(target);
    var duration = options.duration || cc.CollectAnimation.calcDuration({ targetNode: source, to: target, jump: true });
    var jumpScale = options.jumpScale || source.scale * 1.2;
    var collectScale = options.collectScale || source.scale;

    source.replaceParentSamePlace(jumpMovingNode, {
        keepScale: true
    });

    styles = options.longJump ? styles.longJump : styles.jump;
    var jumpStyles = styles.both;

    if (options.jumpSide) {
        if (options.jumpSide === "left") {
            jumpStyles = styles.left;
        } else {
            jumpStyles = styles.right;
        }
    }
    if (options.longJump) {
        jumpStyles = styles.right;
    }

    var jumpOffset = options.jumpOffset || 0;
    var jumpPosition = cc.p(
        jumpStyles.x1 + (jumpStyles.x2 - jumpStyles.x1) * jumpOffset,
        jumpStyles.y1 + Math.random() * (jumpStyles.y2 - jumpStyles.y1)
    );

    var actions = [
        new cc.CallFunc(function () {
            cleverapps.aims.showTarget(target);
        })
    ];

    actions.push(new cc.Spawn(
        options.longJump
            ? new cc.JumpTo(0.8, source.x + jumpPosition.x, source.y + jumpPosition.y, styles.height * (0.9 + 0.2 * Math.random()), 2).easing(cc.easeInOut(1.2))
            : new cc.JumpAnimation(0.4, source, jumpPosition, styles.height),
        new cc.ScaleTo(0.4, jumpScale),
        new cc.Sequence(
            new cc.DelayTime(0.3),
            new cc.PlaySound(bundles.main.urls.coins_drop_effect)
        )
    ));

    if (options.longJump) {
        actions.push(new cc.CallFunc(function () {
            cleverapps.aims.showTarget(target);
        }));
        actions.push(new cc.DelayTime((options.collectDelay || 0) + 0.5 * (1 - jumpOffset)));
    }

    actions.push(new cc.ReplaceParent(collectMovingNode));

    actions.push(new cc.Spawn(
        new cc.CollectAnimation(duration, target, {
            jump: true
        }),
        new cc.ScaleTo(duration, collectScale)
    ));

    actions.push(new cc.PlaySound(options.collectSound));

    if (options.collectEffect) {
        actions.push(new cc.CollectEffect(target));
    }

    return new cc.Sequence(actions);
};

AnimationsLibrary.coinCloud = function (node, options) {
    options = options || {};
    options.cloudSize = options.cloudSize || 0;
    var target = options.target || cleverapps.aims.getTarget(["rewardHard", "hard"]);
    var styles = cleverapps.styles.AnimationsLibrary.coinCloud;
    var coinStyle = styles.coins[options.cloudSize];
    var coins = [];
    var actions = [];

    actions.push(
        new cc.CallFunc(function () {
            var sparkAnimation = new cleverapps.Spine(bundles.animations_library.jsons.coins_reward_json);
            sparkAnimation.setPositionRound(styles.sparks.position);
            node.addChild(sparkAnimation);
            sparkAnimation.setAnimation(0, "animation", false);
            sparkAnimation.setCompleteListenerRemove();
        })
    );

    options.reward && actions.push(
        new cc.CallFunc(function () {
            var text = cleverapps.UI.generateTTFText("+" + options.reward, cleverapps.styles.FONTS.COINS_REWARD_TEXT);
            text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
            text.setScale(styles.text.scale / 2);
            text.setLocalZOrder(1);

            text.setPositionRound(styles.text.position);
            node.addChild(text);
            text.replaceParentSamePlace(cleverapps.scenes.getMovingNode());
            text.runAction(new cc.Sequence(
                new cc.FadeIn(0.2),
                new cc.ScaleTo(styles.showTextTime[options.cloudSize], styles.text.scale),
                new cc.FadeOut(0.2),
                new cc.RemoveSelf()
            ));
        })
    );
    actions.push(new cc.CallFunc(function () {
        for (var i = 0; i < coinStyle.length; i++) {
            for (var j = 0; j < coinStyle[i].count; j++) {
                var coin = new cc.Sprite(bundles.animations_library.frames.animations_coin);

                coin.setRotation((Math.random() - 0.5) * 120);
                coin.setScale(0);
                node.addChild(coin);

                var angle = 2 * Math.PI * j / coinStyle[i].count;

                var baseAngle = (coinStyle[i].baseAngle || 0) * Math.PI / 180;
                var posX = coinStyle[i].radiusX * Math.cos(angle + baseAngle);
                var posY = coinStyle[i].radiusY * Math.sin(angle + baseAngle);

                posX += (Math.random() - 0.5) * 0.5 * posX;
                posY += (Math.random() - 0.5) * 0.5 * posY;

                coin.setPosition(node.width / 2 + posX, node.height / 2 + posY);
                coin.replaceParentSamePlace(cleverapps.scenes.getMovingNode());
                coin.runAction(new cc.Spawn(
                    new cc.MoveBy(CoinCloud.APPEAR_TIME, cc.p(
                        posX * 0.2,
                        posY * 0.2
                    )),
                    new cc.ScaleTo(CoinCloud.APPEAR_TIME, coinStyle[i].scale)
                ));
                coins.push(coin);
            }
        }
        coins.sort(function (a, b) {
            return a.getPositionX() - b.getPositionX();
        }).forEach(function (coin, index) {
            coin.runAction(new cc.Sequence(
                new cc.DelayTime(CoinCloud.APPEAR_TIME + index * 0.01),
                new cc.CallFunc(function () {
                    cleverapps.aims.showTarget(target);
                }),
                new cc.Spawn(
                    new cc.CollectAnimation(CoinCloud.COLLECT_COIN_TIME, target, {
                        jump: true
                    }),
                    new cc.RotateBy(CoinCloud.COLLECT_COIN_TIME, 180)
                ),
                new cc.CallFunc(function () {
                    if (index === 0) {
                        target.aim && target.aim.animate && target.aim.animate(options.reward);
                    }
                }),
                new cc.RemoveSelf()
            ));
        });
    }));
    return new cc.Sequence(actions);
};

AnimationsLibrary.shift = function () {
    var styles = cleverapps.styles.AnimationsLibrary.shift;
    return new cc.Sequence(
        new cc.Spawn(
            new cc.MoveBy(0.3, 0, styles.shift).easing(cc.easeOut(2)),
            new cc.ScaleTo(0.3, 0.9)
        ),
        new cc.Spawn(
            new cc.MoveBy(0.2, 0, -styles.shift).easing(cc.easeIn(2)),
            new cc.ScaleTo(0.2, 1)
        )
    );
};

AnimationsLibrary.showUp = function (node, showUpAnimation) {
    var styles = cleverapps.styles.AnimationsLibrary.showUp;
    showUpAnimation = showUpAnimation || bundles.animations_library.jsons.show_up_json;

    return new cc.Sequence(
        new cc.CallFunc(function () {
            var showUpSpine = new cleverapps.Spine(showUpAnimation);
            node.addChild(showUpSpine);
            showUpSpine.setPositionRound(styles);
            showUpSpine.setAnimation(0, "animation", false);
            showUpSpine.setCompleteListenerRemove();
        }),
        new cc.DelayTime(0.3),
        AnimationsLibrary.shift()
    );
};

AnimationsLibrary.hovering = function (node, options) {
    options = options || {};
    var styles = cleverapps.styles.AnimationsLibrary.hovering;

    var animationTime = options.animationTime || 7;
    var leftAngle = options.leftAngle || 6;
    var rightAngle = options.rightAngle || 3;
    var offsetY = styles.offsetY;
    var delayTime = options.delayTime || 0;

    var particlesSpine = node.particles = new cleverapps.Spine(bundles.animations_library.jsons.hovering_json);
    particlesSpine.setScale(node.height / particlesSpine.height);
    node.addChild(particlesSpine, -1);
    particlesSpine.setPositionRound(styles.particles);

    return new cc.Sequence(
        new cc.CallFunc(function () {
            particlesSpine.setAnimation(0, "animation", true);
        }),
        new cc.DelayTime(delayTime),
        new cc.CallFunc(function () {
            node.runAction(new cc.RepeatForever(
                new cc.Spawn(
                    new cc.Sequence(
                        new cc.RotateBy(3 * animationTime / 10, leftAngle).easing(cc.easeInOut(2)),
                        new cc.RotateBy(4 * animationTime / 10, -(leftAngle + rightAngle)).easing(cc.easeInOut(2)),
                        new cc.RotateBy(3 * animationTime / 10, rightAngle)
                    ),
                    new cc.Sequence(
                        new cc.MoveBy(animationTime / 2, 0, -offsetY).easing(cc.easeInOut(2)),
                        new cc.MoveBy(animationTime / 2, 0, offsetY).easing(cc.easeInOut(2))
                    )
                )
            ));
        })
    );
};

AnimationsLibrary.animateCollect = function (source, target, options) {
    options = options || {};
    target = cleverapps.aims.getTarget(target);

    var movingNode = cleverapps.scenes.getMovingNode(target);

    var duration = options.duration || cc.CollectAnimation.calcDuration({ targetNode: source, to: target });
    var baseScaleX = source.baseScaleX || source.baseScale || 1;
    var baseScaleY = source.baseScaleY || source.baseScale || 1;
    var targetScale = options.adjustToTarget ? Math.min(target.width / source.width, target.height / source.height, 1) : options.scale;

    var actions = [new cc.ReplaceParent(movingNode)];

    var path;
    if (options.path) {
        path = new cleverapps.Particles(bundles.particle.jsons.particles_collect_json, bundles.particle.urls.particle_texture);
        actions.push(new cc.CallFunc(function () {
            movingNode.addChild(path, -1);
            path.setPositionRound(movingNode.convertToNodeSpace(source.convertToWorldSpace(cc.p(source.width / 2, source.height / 2))));
            path.followTarget(source);
        }));
    }

    actions.push(new cc.CallFunc(function () {
        cleverapps.aims.showTarget(target);
    }));

    var collectAnimation = new cc.CollectAnimation(duration, target, options);

    if (options.easing) {
        collectAnimation = collectAnimation.easing(options.easing);
    }

    actions.push(new cc.Spawn(
        collectAnimation,
        new cc.Sequence(
            new cc.ScaleTo(duration * 0.4, baseScaleX * 1.3, baseScaleY * 1.3),
            new cc.ScaleTo(duration * 0.6, targetScale || baseScaleX, targetScale || baseScaleY)
        )
    ));

    if (options.sound) {
        actions.push(new cc.PlaySound(options.sound, { throttle: 0 }));
    }
    if (options.collectEffect) {
        actions.push(new cc.CollectEffect(target));
    }

    if (path) {
        actions.push(new cc.CallFunc(function () {
            path.stop();
        }));
    }

    return new cc.Sequence(actions);
};

AnimationsLibrary.spawn = function (node, options) {
    options = options || {};
    var delay = options.delay || 0;
    var callback = options.callback || function () {};
    node.setVisible(false);
    var baseScale = node.scale;
    node.setScale(0.3 * baseScale);
    node.setOpacity(0.3);
    node.setCascadeOpacityEnabledRecursively(true);
    return new cc.Sequence(
        new cc.DelayTime(delay),
        new cc.Show(),
        new cc.Spawn(
            new cc.ScaleTo(0.3, baseScale).easing(cc.easeBackOut()),
            new cc.FadeIn(0.15)
        ),
        new cc.CallFunc(function () {
            callback();
        })
    );
};
AnimationsLibrary.deSpawn = function (node, options) {
    options = options || {};
    var delay = options.delay || 0;
    var callback = options.callback || function () {};
    node.setCascadeOpacityEnabledRecursively(true);
    return new cc.Sequence(
        new cc.DelayTime(delay),
        new cc.ScaleTo(0.3, 0.3 * node.scale).easing(cc.easeBackIn(10)),
        new cc.FadeOut(),
        new cc.Hide(),
        new cc.CallFunc(function () {
            callback();
        })
    );
};

AnimationsLibrary.blinkShader = function (options) {
    options = options || {};
    var duration = options.duration || 2;
    var brightness = options.brightness === undefined ? 0.15 : options.brightness;
    var color = [brightness, brightness, brightness];
    return new cc.Sequence(
        new cc.Flicker(
            duration / 2,
            color
        ).easing(cc.easeInOut(2)),
        new cc.Flicker(
            duration / 2,
            color,
            true
        ).easing(cc.easeInOut(2))
    );
};

AnimationsLibrary.clean = function (node, options) {
    options = options || {};
    var callback = options.callback || function () {};

    var oldAnchor = node.getAnchorPoint();
    var nodeSize = node.getContentSize();
    var oldPosition = node.getPosition();
    var newAnchor = cc.p(oldAnchor.x, 0);
    node.setAnchorPoint(newAnchor);
    var deltaX = (newAnchor.x - oldAnchor.x) * nodeSize.width;
    var deltaY = (newAnchor.y - oldAnchor.y) * nodeSize.height;
    var newPosition = cc.p(oldPosition.x + deltaX, oldPosition.y + deltaY);
    node.setPosition(newPosition);

    var dust = new cleverapps.Spine(bundles.animations_library.jsons.clean_json);
    dust.setPositionRound({ x: { align: "center" }, y: { align: "bottom" } });
    node.addChild(dust);
    dust.replaceParentSamePlace(cleverapps.scenes.getMovingNode(dust));
    dust.setScale(node.width / dust.width);

    dust.setCompleteListenerRemove(callback);

    return new cc.Spawn(
        new cc.Sequence(
            new cc.DelayTime(0.15),
            new cc.CallFunc(function () {
                dust.setAnimation(0, "animation", false);
            }),
            new cc.DelayTime(0.1),

            new cc.MoveBy(0.15, 0, 60).easing(cc.easeOut(2)),
            new cc.MoveBy(0.1, 0, -50).easing(cc.easeIn(2)),
            new cc.Hide()
        ),

        new cc.Sequence(
            new cc.ScaleTo(0.15, 1, 0.95).easing(cc.easeCubicActionOut()),
            new cc.ScaleTo(0.1, 1, 1.05).easing(cc.easeCubicActionOut()),
            new cc.ScaleTo(0.15, 1, 1).easing(cc.easeCubicActionIn())
        )
    );
};

cleverapps.styles.AnimationsLibrary = {
    squeezeJump: {
        jumpHeight: 180
    },
    shake: {
        dx: 1,
        dy: 1
    },
    shift: {
        shift: 25
    },
    showUp: {
        x: { align: "center" },
        y: { align: "center" }
    },
    hovering: {
        offsetY: 25,
        particles: {
            x: { align: "center" },
            y: { align: "center" }
        }
    },
    jumpCollect: {
        jump: {
            height: 200,

            right: {
                x1: 50,
                x2: 150,
                y1: -50,
                y2: -100
            },

            left: {
                x1: -50,
                x2: -150,
                y1: -50,
                y2: -100
            },

            both: {
                x1: -50,
                x2: 50,
                y1: -50,
                y2: -100
            }
        },

        longJump: {
            height: 100,

            right: {
                x1: 550,
                x2: 650,
                y1: -50,
                y2: -100
            }
        }
    },
    click: {
        smallHeight: 100
    },
    coinCloud: {
        text: {
            scale: 1,
            position: {
                x: {
                    align: "center",
                    dx: 0
                },
                y: {
                    align: "center",
                    dy: 0
                }
            }
        },
        sparks: {
            position: {
                x: {
                    align: "center",
                    dx: 0
                },
                y: {
                    align: "center",
                    dy: 0
                }
            }
        },
        coins: [
            [
                {
                    radiusX: 80,
                    radiusY: 80,
                    count: 6,
                    scale: 1
                }
            ],
            [
                {
                    radiusX: 40,
                    radiusY: 40,
                    count: 8,
                    scale: 0.8
                },
                {
                    radiusX: 15,
                    radiusY: 15,
                    count: 3,
                    scale: 1
                }
            ],
            [
                {
                    radiusX: 100,
                    radiusY: 140,
                    count: 16,
                    scale: 0.8
                },
                {
                    radiusX: 70,
                    radiusY: 98,
                    count: 12,
                    scale: 1
                },
                {
                    radiusX: 40,
                    radiusY: 55,
                    count: 6,
                    scale: 1.3
                }
            ]
        ],
        showTextTime: [0.6, 1, 1]
    }
};

cleverapps.overrideStyles(cleverapps.styles.DECORATORS, {
    COINS_TEXT_STROKE: {
        size: 3,
        color: cleverapps.styles.COLORS.COINS_REWARD_STROKE_COLOR
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    COINS_REWARD_TEXT: {
        size: 120,
        color: cleverapps.styles.COLORS.COINS_REWARD_YELLOW,
        stroke: cleverapps.styles.DECORATORS.COINS_TEXT_STROKE
    }
});

var CoinCloud = {};
CoinCloud.SMALL = 0;
CoinCloud.MEDIUM = 1;
CoinCloud.BIG = 2;
CoinCloud.COLLECT_COIN_TIME = 0.5;
CoinCloud.APPEAR_TIME = 0.4;