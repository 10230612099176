/**
 * Created by vladislav on 3/20/19
 */

var SyrupTileView = cc.Node.extend(Object.assign({}, BaseTileView, {
    ctor: function (tile) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);

        this.initialize(tile);

        this.createAnimation();

        if (tile.isForEditor) {
            this.addEditorText();
        }
    },

    initialize: function (tile) {
        BaseTileView.initialize.call(this, tile);

        this.setLocalZOrder(0);

        tile.onCreateNew = this.createNew.bind(this);
        tile.onRemoveSurface = this.removeSurface.bind(this);
    },

    addEditorText: function () {
        var text;
        if (SyrupTile.dir === BaseCell.UP) {
            text = "up";
        }
        if (SyrupTile.dir === BaseCell.DOWN) {
            text = "down";
        }
        if (SyrupTile.dir === BaseCell.LEFT) {
            text = "left";
        }
        if (SyrupTile.dir === BaseCell.RIGHT) {
            text = "right";
        }

        text = cleverapps.UI.generateTTFText(text, cleverapps.styles.FONTS.WHITE_TEXT);
        this.addChild(text);
        text.setPositionRound(this.width / 2, this.height / 2);
    },

    createAnimation: function () {
        var json = this.tile.isForGoal ? bundles.syrup.jsons.syrup_goal_json : bundles.syrup.jsons.syrup_json;
        this.animation = new cleverapps.Spine(json);

        var needSurface = !this.tile.isForGoal && SyrupTile.dir !== undefined && !SyrupTile.isWithin(this.tile.x, this.tile.y);

        this.animation.setAnimation(0, needSurface ? "idle_" + SyrupTile.dir : "idle", true);

        this.addChild(this.animation);
        this.animation.setPositionRound(this.width / 2, this.height / 2);
    },

    getStyles: function () {
        return cleverapps.styles.SyrupTileView;
    },

    createNew: function () {
        this.animation.setAnimation(0, "transition_" + SyrupTile.dir, false);
        if (this.tile.isNewWithin) {
            this.runAction(new cc.Sequence(
                new cc.DelayTime(0.7),
                new cc.CallFunc(function () {
                    this.animation.setAnimation(0, "idle", true);
                    this.animation.setCompleteListener();
                }.bind(this))
            ));
        } else {
            this.animation.setCompleteListener(function () {
                this.animation.setAnimation(0, "idle_" + SyrupTile.dir, true);
                this.animation.setCompleteListener();
            }.bind(this));
        }
    },

    removeSurface: function () {
        this.animation.setCompleteListener();
        this.animation.setAnimation(0, "idle", true);
    }
}));

cleverapps.styles.SyrupTileView = {
    surface: {
        dist: 50
    }
};
