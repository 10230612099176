AdminLog.MOVE_LEVEL = 0;
AdminLog.TOGGLE_HARD = 1;
AdminLog.RENAME_EPISODE = 2;
AdminLog.EDIT_LEVEL = 3;
AdminLog.CREATE_EPISODE = 4;
AdminLog.CREATE_LEVEL = 5;
AdminLog.COPY_EPISODE = 6;
AdminLog.COPY_LEVEL = 7;
AdminLog.ANNOTAION_CHAGE = 8;
AdminLog.DELETE_EPISODE = 9;
AdminLog.CREATE_LEVEL_VERSION = 10;
AdminLog.GIT_PUSH = 11;
AdminLog.GIT_REVERT = 12;
AdminLog.EPISODE_SET_DEBUG = 13;
AdminLog.DELETE_LEVEL = 14;
AdminLog.TOGGLE_TRICKY = 15;
AdminLog.PASTE_LEVEL = 16;
AdminLog.TOGGLE_TAG = 17;

AdminLog.ACTIONS = {};
AdminLog.ACTIONS[AdminLog.MOVE_LEVEL] = {
    params: {
        toEpisodeNo: undefined,
        toLevelNo: undefined,
        fromEpisodeNo: undefined,
        fromLevelNo: undefined
    },
    filter: function () {
        return this.params.toEpisodeNo !== this.params.fromEpisodeNo || this.params.toLevelNo !== this.params.fromLevelNo;
    },
    getMessage: function () {
        if (this.params.toEpisodeNo !== this.params.fromEpisodeNo) {
            return "Move episode " + this.params.fromEpisodeNo + " level " + this.params.fromLevelNo + " to episode " + this.params.toEpisodeNo;
        }
        return "Episode " + this.params.fromEpisodeNo + ", move level " + this.params.fromLevelNo + " to " + this.params.toLevelNo;
    }
};

AdminLog.ACTIONS[AdminLog.TOGGLE_HARD] = {
    params: {
        levelNo: undefined,
        episodeNo: undefined,
        madeHard: undefined
    },
    getMessage: function () {
        return "Episode " + this.params.episodeNo + ", level " + this.params.levelNo + (this.params.madeHard ? " make hard" : " remove hard");
    }
};

AdminLog.ACTIONS[AdminLog.TOGGLE_TRICKY] = {
    params: {
        levelNo: undefined,
        episodeNo: undefined,
        madeTricky: undefined
    },
    getMessage: function () {
        return "Episode " + this.params.episodeNo + ", level " + this.params.levelNo + (this.params.madeTricky ? " make tricky" : " remove tricky");
    }
};

AdminLog.ACTIONS[AdminLog.TOGGLE_TAG] = {
    params: {
        levelNo: undefined,
        episodeNo: undefined,
        removedTag: undefined,
        addedTag: undefined
    },
    getMessage: function () {
        var removedTag = this.params.removedTag === "" ? "" : " remove " + this.params.removedTag;
        var addedTag = this.params.addedTag === "" ? "" : " make " + this.params.addedTag;
        return "Episode " + this.params.episodeNo + ", level " + this.params.levelNo + removedTag + addedTag;
    }
};

AdminLog.ACTIONS[AdminLog.RENAME_EPISODE] = {
    params: {
        episodeNo: undefined,
        newEpisodeNo: undefined
    },
    filter: function () {
        return this.params.episodeNo !== this.params.newEpisodeNo;
    },
    getMessage: function () {
        return "Episode " + this.params.episodeNo + " rename to " + this.params.newEpisodeNo;
    }
};

AdminLog.ACTIONS[AdminLog.EDIT_LEVEL] = {
    updatedEntry: true,
    params: {
        episodeNo: undefined,
        levelNo: undefined
    },
    getMessage: function () {
        return "Edit episode " + this.params.episodeNo + " level " + this.params.levelNo;
    }
};

AdminLog.ACTIONS[AdminLog.CREATE_EPISODE] = {
    params: {
        episodeNo: undefined
    },
    getMessage: function () {
        return "Create episode " + this.params.episodeNo;
    }
};

AdminLog.ACTIONS[AdminLog.CREATE_LEVEL] = {
    params: {
        episodeNo: undefined,
        levelNo: undefined
    },
    getMessage: function () {
        return "Create episode " + this.params.episodeNo + " level " + this.params.levelNo;
    }
};

AdminLog.ACTIONS[AdminLog.COPY_EPISODE] = {
    params: {
        episodeNo: undefined,
        newEpisodeNo: undefined
    },
    getMessage: function () {
        return "Copy episode " + this.params.episodeNo + " to " + this.params.newEpisodeNo;
    }
};

AdminLog.ACTIONS[AdminLog.COPY_LEVEL] = {
    params: {
        episodeNo: undefined,
        levelNo: undefined
    },
    getMessage: function () {
        return "Copy episode " + this.params.episodeNo + " level " + this.params.levelNo;
    }
};

AdminLog.ACTIONS[AdminLog.ANNOTAION_CHAGE] = {
    params: {
        episodeNo: undefined,
        levelNo: undefined
    },
    getMessage: function () {
        return "Episode " + this.params.episodeNo + " level " + this.params.levelNo + " change annotation";
    }
};

AdminLog.ACTIONS[AdminLog.DELETE_EPISODE] = {
    params: {
        episodeNo: undefined
    },
    getMessage: function () {
        return "Delete episode " + this.params.episodeNo;
    }
};

AdminLog.ACTIONS[AdminLog.CREATE_LEVEL_VERSION] = {
    params: {
        episodeNo: undefined,
        levelNo: undefined,
        version: undefined
    },
    getMessage: function () {
        return "Episode " + this.params.episodeNo + " level " + this.params.levelNo + ", create version " + this.params.version;
    }
};

AdminLog.ACTIONS[AdminLog.GIT_PUSH] = {
    params: {
        data: undefined
    },
    getMessage: function () {
        if (typeof this.params.data === "string") {
            return "Push " + this.params.data;
        }
        return "Push " + this.params.data.newFiles.concat(this.params.data.changedFiles).join(", ");
    }
};

AdminLog.ACTIONS[AdminLog.GIT_REVERT] = {
    params: {
        data: undefined
    },
    getMessage: function () {
        if (typeof this.params.data === "string") {
            return "Revert " + this.params.data;
        }
        return "Revert " + this.params.data.newFiles.concat(this.params.data.changedFiles).join(", ");
    }
};

AdminLog.ACTIONS[AdminLog.EPISODE_SET_DEBUG] = {
    params: {
        episodeNo: undefined,
        debug: undefined
    },
    getMessage: function () {
        return "Episode " + this.params.episodeNo + " set debug: " + this.params.debug;
    }
};

AdminLog.ACTIONS[AdminLog.DELETE_LEVEL] = {
    params: {
        episodeNo: undefined,
        levelNo: undefined
    },
    getMessage: function () {
        return "Episode " + this.params.episodeNo + " delete level " + this.params.levelNo;
    }
};

AdminLog.ACTIONS[AdminLog.PASTE_LEVEL] = {
    params: {
        episodeNo: undefined,
        levelNo: undefined
    },
    getMessage: function () {
        return "Paste episode " + this.params.episodeNo + " level " + this.params.levelNo;
    }
};
