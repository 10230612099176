/**
 * Created by ivan on 10.05.18.
 */

var WaffleCellView = BaseCellView.extend({
    ctor: function (waffleCell) {
        this._super();

        this.setContentSize(cleverapps.styles.BaseCellView.CELL_SIZE_PX, cleverapps.styles.BaseCellView.CELL_SIZE_PX);
        this.setAnchorPoint(0.5, 0.5);

        this.initialize(waffleCell);

        this.setCascadeOpacityEnabled(true);
    },

    startExploding: function (goal, delta) {
        this.startExplodingViaCollect(goal, delta);
    }
});