/**
 * Created by Vladislav on 18.09.2024.
 */

cleverapps.InitByType["match3"] = function () {
    match3.heroes = new Heroes();

    cleverapps.starChest = new cleverapps.StarChest();

    levels.levelAttempts = new levels.LevelAttempts();
};