/**
 * Created by slava on 01.08.17.
 */

var DonutBoxCell = function (x, y) {
    BaseCell.call(this, x, y);
    this.movable = false;
    Game.currentGame.counter.registerStage(205, DonutBoxCell.process);
};

DonutBoxCell.prototype = Object.create(BaseCell.prototype);
DonutBoxCell.prototype.constructor = DonutBoxCell;

DonutBoxCell.prototype.load = function (data) {
    this.lives = parseInt(data[1]);
};

DonutBoxCell.prototype.bundleId = function () {
    return "donut_box";
};

DonutBoxCell.prototype.save = function () {
    return DonutBoxCell.CODES.concat([this.lives]);
};

DonutBoxCell.prototype.getViewClass = function () {
    return DonutBoxCellView;
};

DonutBoxCell.prototype.hurt = function () {
    if (this.lives < 1) {
        return;
    }

    this.lives--;
    this.onChangeLivesListener();
    this.animate(BaseCell.ANIMATION_HURT);
};

DonutBoxCell.prototype.boom = function () {
    this.hurt();
};

DonutBoxCell.prototype.processTo = function (cell) {
    DonutBoxCell.changed[cell.y][cell.x] = true;
    var donut;
    if (Game.currentGame.goals.hasType(DonutBoxCell.MAKE_COLOR)) {
        donut = new BaseCell(cell.x, cell.y, {
            color: DonutBoxCell.MAKE_COLOR,
            coef: true
        });
    } else {
        donut = new BaseCell(cell.x, cell.y, { color: DonutBoxCell.MAKE_COLOR });
    }
    Game.currentGame.field.addCell(donut);
    donut.animate(BaseCell.ANIMATION_CREATE_FROM_CELL, { x: this.x, y: this.y, delay: 0.1 });
    Game.currentGame.counter.setTimeout(function () {
        cell.delete();
    }, 600);
};

DonutBoxCell.prototype.process = function () {
    var variants = [], i, j;
    for (i = 0; i < Field.SIZE; i++) {
        for (j = 0; j < Field.SIZE; j++) {
            if (!DonutBoxCell.changed[i][j] && Game.currentGame.field.cells[i][j]) {
                var currentCell = Game.currentGame.field.cells[i][j];
                var colorComponent = currentCell.findComponent(ColorComponent);
                if (currentCell.canDelete() && colorComponent && currentCell.color !== DonutBoxCell.MAKE_COLOR) {
                    variants.push(currentCell);
                }
            }
        }
    }
    if (variants.length > 0) {
        for (i = 0; i < 4 && variants.length > 0; i++) {
            var vid = cleverapps.Random.random(variants.length);
            this.processTo(variants[vid]);
            variants.splice(vid, 1);
        }

        this.animate(BaseCell.ANIMATION_OPEN);

        Game.currentGame.counter.setTimeout(function () {
            this.lives = 4;
            this.onChangeLivesListener();
            this.animate(BaseCell.ANIMATION_HURT);
        }.bind(this), 900);
    }
};

DonutBoxCell.process = function () {
    var toProcess = [], i, j;
    for (i = 0; i < Field.SIZE; i++) {
        for (j = 0; j < Field.SIZE; j++) {
            if (Game.currentGame.field.cells[i][j] && Game.currentGame.field.cells[i][j].constructor === DonutBoxCell) {
                if (Game.currentGame.field.cells[i][j].lives === 0) {
                    toProcess.push(Game.currentGame.field.cells[i][j]);
                }
            }
        }
    }

    if (toProcess.length > 0) {
        DonutBoxCell.changed = [];
        for (i = 0; i < Field.SIZE; i++) {
            DonutBoxCell.changed.push([]);
            for (j = 0; j < Field.SIZE; j++) {
                DonutBoxCell.changed.push(false);
            }
        }
        for (i = 0; i < toProcess.length; i++) {
            toProcess[i].process();
        }
    }
};

DonutBoxCell.MAKE_COLOR = "a";