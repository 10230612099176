/**
 * Created by andrey on 21.07.2023
 */

var Map2dRemoveQueue = function (map2d) {
    this.map2d = map2d;

    this.queue = {};
    this.size = 0;
};

Map2dRemoveQueue.prototype.add = function (x, y) {
    if (!this.queue[y]) {
        this.queue[y] = {};
    }
    if (!this.queue[y][x]) {
        this.queue[y][x] = true;
        this.size++;
    }
};

Map2dRemoveQueue.prototype.remove = function (x, y) {
    if (this.queue[y] && this.queue[y][x]) {
        delete this.queue[y][x];
        this.size--;

        // eslint-disable-next-line no-unreachable-loop
        for (var i in this.queue[y]) {
            return;
        }

        delete this.queue[y];
    }
};

Map2dRemoveQueue.prototype.process = function (dt) {
    var map2d = this.map2d;

    if (map2d.lastVisitRectTime + Map2dRemoveQueue.PROCESS_SCROLL_DELAY > cleverapps.timeouts.time) {
        return;
    }

    var speed = this.getSpeed();
    var limit = speed.limit * dt;

    for (var y in this.queue) {
        for (var x in this.queue[y]) {
            map2d.removeTiles(x, y);
            this.remove(x, y);

            limit--;
            if (limit <= 0) {
                return;
            }
        }
    }
};

Map2dRemoveQueue.prototype.getSpeed = function () {
    var i = 1;
    while (i < Map2dRemoveQueue.SPEED.length && this.size >= Map2dRemoveQueue.SPEED[i].threshold) {
        i++;
    }
    return Map2dRemoveQueue.SPEED[i - 1];
};

Map2dRemoveQueue.PROCESS_SCROLL_DELAY = 500;

// one screen ~ 1000 cells
Map2dRemoveQueue.SPEED = [
    {
        threshold: 0,
        limit: 250
    },
    {
        threshold: 1000,
        limit: 500
    },
    {
        threshold: 2000,
        limit: 1000
    }
];
