/**
 * Created by vladislav on 3/5/19
 */

var SmallPetardCellView = BaseCellView.extend({
    ctor: function (smallPetardCell) {
        this._super();

        this.setContentSize(cleverapps.styles.BaseCellView.CELL_SIZE_PX, cleverapps.styles.BaseCellView.CELL_SIZE_PX);
        this.setAnchorPoint(0.5, 0.5);

        var styles = this.getStyles();

        var animation = this.animation = new cleverapps.Spine(bundles.small_petard.jsons.small_petard_json);
        animation.setSkin(ColorComponent.CODE_COLORS[smallPetardCell.color]);
        animation.setAnimation(0, "idle", true);
        this.addChild(animation);
        animation.setPositionRound(this.width / 2, this.height / 2);

        var livesBg = this.livesBg = new cc.Sprite(bundles.small_petard.frames.small_petard_lives_bg);
        this.addChild(livesBg);
        livesBg.setPositionRound(this.width / 2 + styles.livesBg.x, styles.livesBg.y);

        var livesText = this.livesText = cleverapps.UI.generateImageText(smallPetardCell.lives, cleverapps.styles.FONTS.PETARD_LIVES);
        livesText.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        livesBg.addChild(livesText);
        livesText.setPositionRound(livesBg.width / 2 + styles.livesText.x, livesBg.height / 2 + styles.livesText.y);
        livesText.baseScale = livesText.scale;
        livesText.setScale(smallPetardCell.lives > 9 ? livesText.baseScale * styles.livesText.scale : livesText.baseScale);

        this.initialize(smallPetardCell);
    },

    initialize: function (smallPetardCell) {
        this._super(smallPetardCell);

        smallPetardCell.onChangeLivesListener = this.changeLives.bind(this);
        smallPetardCell.onBlowUpListener = this.blowUp.bind(this);
        smallPetardCell.onShowUpListener = this.showUp.bind(this);
    },

    changeLives: function () {
        var styles = this.getStyles();

        this.livesText.setString(this.cell.lives);
        this.livesText.setScale(this.cell.lives > 9 ? this.livesText.baseScale * styles.livesText.scale : this.livesText.baseScale);
    },

    showUp: function () {
        this.setScale(0);

        this.animation.setAnimation(0, "idle", true);
        this.animation.setCompleteListener();

        this.livesBg.setVisible(true);

        this.stopAllActions();
        this.runAction(
            new cc.ScaleTo(0.3, 1).easing(cc.easeBackOut())
        );
    },

    blowUp: function (cellsToFlyCount, dir) {
        this.livesBg.setVisible(false);

        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.2),
            new cc.CallFunc(function () {
                SmallPetardCellView.explosionWavesAnimation.call(this, {
                    image: bundles.small_petard.frames.small_petard_wave,
                    directions: [dir],
                    cellsToFlyCount: cellsToFlyCount
                });
            }.bind(this))
        ));

        this.setLocalZOrder(100);
        this.animation.setAnimation(0, "explode", false);
        this.animation.setCompleteListener(function () {
            this.setLocalZOrder(this.baseZOrder);
            this.animation.setAnimation(0, "explode_idle", true);
            this.animation.setCompleteListener();
        }.bind(this));
    },

    getStyles: function () {
        return cleverapps.styles.SmallPetardCellView;
    }
});

// This function was moved here from BaseCellView and is used also in CannonCellView and MultiColorBombCellView
SmallPetardCellView.explosionWavesAnimation = function (options) {
    options = options || {};
    if (Game.currentGame) {
        Game.currentGame.shakeField();
    }

    var useFieldDxDy = (options.fieldDX !== undefined) || (options.fieldDY !== undefined);
    var dx = 0, dy = 0;
    if (options.fieldDX) {
        dx = options.fieldDX;
    }
    if (options.fieldDY) {
        dy = options.fieldDY;
    }

    var pos = BaseCellView.alignInTheGrid(this.cell.x + dx, this.cell.y + dy);

    if (!useFieldDxDy && this.cell.findComponent(BigComponent)) {
        pos = BaseCellView.alignInTheGrid(this.cell.x + 0.5, this.cell.y + 0.5);
    }

    var parent = this.getParent();
    var directions = options.directions || BaseCell.DIRECTIONS;

    // Transform directions coordinates from Field to View (flipped Y axis)
    directions = directions.map(function (value) {
        return { x: value.x, y: value.y * (-1) };
    });

    Object.values(directions).forEach(function (dir) {
        var wave = new cc.Sprite(options.image);
        parent.addChild(wave);

        var rotation = Math.acos(dir.y / Math.sqrt(dir.x * dir.x + dir.y * dir.y)) / Math.PI * 180;
        if (dir.x < 0) {
            rotation *= -1;
        }

        var startPosCoef = 1;
        if (dir.x * dir.y !== 0) { // diagonal case
            startPosCoef /= Math.sqrt(2);
        }
        var start = cc.p(pos.x + dir.x * this.width * startPosCoef, pos.y + dir.y * this.height * startPosCoef);

        var dx = 10000;
        if (dir.x !== 0) {
            dx = dir.x === -1 ? start.x : (parent.width - start.x);
        }

        var dy = 10000;
        if (dir.y !== 0) {
            dy = dir.y === -1 ? start.y : (parent.height - start.y);
        }

        var delta = Math.min(dx, dy);
        var moveTo = cc.p(start.x + dir.x * delta, start.y + dir.y * delta);

        wave.setRotation(rotation);
        wave.setPositionRound(start);
        wave.setScale(options.scale || 1);
        wave.setLocalZOrder(30);

        if (options.bulletFly) {
            var timeFly = options.timeFlyOneCellSec * options.cellsToFlyCount;
            moveTo = cc.p(
                start.x + dir.x * options.cellsToFlyCount * cleverapps.styles.BaseCellView.CELL_SIZE_PX,
                start.y + dir.y * options.cellsToFlyCount * cleverapps.styles.BaseCellView.CELL_SIZE_PX
            );
            var scaleValue = 1.5;
            wave.setScale(scaleValue);
            wave.setScaleX(0.3);
            wave.runAction(new cc.ScaleTo(timeFly / 2, scaleValue, scaleValue));
            wave.runAction(new cc.Sequence(
                new cc.MoveTo(timeFly, moveTo),
                new cc.RemoveSelf()
            ));
        } else {
            if (options.cellsToFlyCount !== undefined) { // take walls into account, need to stop animation on them
                moveTo = cc.p(
                    start.x + dir.x * options.cellsToFlyCount * cleverapps.styles.BaseCellView.CELL_SIZE_PX,
                    start.y + dir.y * options.cellsToFlyCount * cleverapps.styles.BaseCellView.CELL_SIZE_PX
                );
            }
            var dist = Math.sqrt((start.x - moveTo.x) * (start.x - moveTo.x) + (start.y - moveTo.y) * (start.y - moveTo.y));
            var time = 1.1 * (dist / parent.width);
            wave.runAction(new cc.Sequence(
                new cc.Spawn(
                    new cc.MoveTo(time, moveTo).easing(cc.easeOut(2)),
                    new cc.Sequence(
                        new cc.DelayTime(time / 2),
                        new cc.FadeOut(time / 2).easing(cc.easeOut(2))
                    )
                ),
                new cc.RemoveSelf()
            ));
        }
    }.bind(this));
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    PETARD_LIVES: {
        size: 38,
        color: cleverapps.styles.COLORS.COLOR_WINDOW_TEXT,
        name: "default"
    }
});

cleverapps.styles.SmallPetardCellView = {
    livesBg: {
        y: 30,
        x: 1
    },
    livesText: {
        scale: 0.8,
        y: -2,
        x: -1
    },
    scale: 0.95
};