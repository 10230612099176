/**
 * Created by andrey on 21.05.2020
 */

var MultiColorBooster = function () {
    BaseBoosterBefore.call(this, cleverapps.Boosters.TYPE_MULTICOLOR);
};

MultiColorBooster.prototype = Object.create(BaseBoosterBefore.prototype);
MultiColorBooster.prototype.constructor = MultiColorBooster;

MultiColorBooster.prototype.getActionBefore = function () {
    return {
        type: "multicolor",
        source: this,
        amount: 1
    };
};