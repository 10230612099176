/**
 * Created by andrey on 18.06.2024
 */

var VirtualJson = function (bundleName, key, name) {
    VirtualResource.call(this, bundleName, key);

    if (name && name.inline) {
        this.inline = true;
        this.setJsonData(JSON.parse(name.data));
    } else {
        this.name = name;
    }
};

VirtualJson.prototype = Object.create(VirtualResource.prototype);
VirtualJson.prototype.constructor = VirtualJson;

cc.VirtualJson = VirtualJson;

VirtualJson.prototype.setJsonData = function (data) {
    this.data = data;
};

VirtualJson.prototype.getJson = function () {
    return this.data;
};

VirtualJson.prototype.onUnload = function () {
    if (this.inline) {
        return;
    }

    delete this.data;
    delete this.cachedData;
};

VirtualJson.prototype.getCachedData = function () {
    return this.cachedData;
};

VirtualJson.prototype.setCachedData = function (cachedData) {
    this.cachedData = cachedData;
};

VirtualJson.prototype.save = function () {
    if (this.inline) {
        return {
            inline: true,
            data: JSON.stringify(this.data)
        };
    }
    return this.name;
};