/**
 * Created by olga on 06.05.2024
 */

var FreeUnlimitedLivesWindow = CleverappsWindow.extend({
    onWindowLoaded: function (onSuccess) {
        this.onSuccess = onSuccess;

        var styles = cleverapps.styles.ExtendUnlimitedLivesWindow;

        var icon = this.createIcon();
        var text = this.createText();
        var content = new cleverapps.Layout([icon, text], {
            margin: styles.margin,
            direction: cleverapps.UI.VERTICAL
        });

        this._super({
            content: content,
            title: "FreeUnlimitedLivesWindow.title",
            name: "freeunlimitedliveswindow",
            button: {
                text: "FREE",
                width: styles.button.width,
                height: styles.button.height,
                onPressed: this.onBuy.bind(this)
            },
            closeButton: false
        });
    },

    createText: function () {
        var styles = cleverapps.styles.FreeUnlimitedLivesWindow.text;

        var text = cleverapps.UI.generateOnlyText("FreeUnlimitedLivesWindow.unlimitedLivesText", cleverapps.styles.FONTS.WINDOW_TEXT);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        text.setDimensions(styles.width, 0);
        text.fitTo(undefined, styles.height);

        return text;
    },

    createIcon: function () {
        var icon = new cc.Sprite(bundles.tile_shop.frames.unlimitedLives_png);

        var animation = bundles.tile_shop.jsons.shop_lives_animations_json;
        if (animation) {
            var spine = new cleverapps.Spine(animation);
            spine.setPosition(icon.width / 2, icon.height / 2);
            spine.setAnimation(0, "animation", true);
            icon.addChild(spine);
        }

        return icon;
    },

    onShow: function () {
        this._super();
        cleverapps.forces.create(this.buttons, Forces.FREE_UNLIMITED_LIVES_FORCE, {
            highlights: [this],
            importantNodes: [this.window]
        });
    },

    onBuy: function () {
        cleverapps.unlimitedLives.buy("3 hours");
        cleverapps.lives.onBuyUnlimitedLivesListener();
        cleverapps.forces.closeRunningForce();
        this.onSuccess();
        this.close();
    },

    listBundles: function () {
        return ["tile_shop"];
    }
});

cleverapps.styles.FreeUnlimitedLivesWindow = {
    margin: 75,

    text: {
        width: 700,
        height: 300
    },

    button: {
        width: 300,
        height: 100
    }
};