/**
 * Created by slava on 01.08.17.
 */

var MarkView = cc.Node.extend({
    ctor: function (cell) {
        this._super();

        this.cell = cell;
        this.mark = cell.mark || cell.findComponent(MarkComponent).mark;

        this.setContentSize(cleverapps.styles.BaseCellView.CELL_SIZE_PX, cleverapps.styles.BaseCellView.CELL_SIZE_PX);
        this.setAnchorPoint(0.5, 0.5);

        this.image = new cc.Sprite(this.getImage());
        this.image.setPosition(this.width / 2, this.height / 2);
        this.addChild(this.image);

        this.animation = new cleverapps.Spine(bundles[this.mark.bundleId()].jsons[this.getSkinnedRes("mark_spine_json")]);
        this.animation.setPosition(this.width / 2, this.height / 2);
        this.animation.setStartVisibleListener(function () {
            this.image.visible = false;
        }.bind(this));
        this.addChild(this.animation);
        this.animation.visible = false;
    },

    getSkinnedRes: function (name) {
        return name + this.mark.getSkinSuffix();
    },

    initialize: function () {
        this.image.visible = true;
        if (this.animation) {
            if (cleverapps.styles.MarkView.animation.skins) {
                this.animation.setSkin(this.getSkin());
            }
            this.animation.visible = false;
        }

        this.mark.onExplodeListener = this.animateCollect.bind(this);
        this.mark.onSmileListener = this.smile.bind(this);

        var position;
        var component = this.cell.findComponent(GoalCoefComponent) ? GoalCoefComponent : ColorComponent;
        if (cleverapps.styles.MarkView.positions[component.name]) {
            position = cleverapps.styles.MarkView.positions[component.name];
            var colorComponent = this.cell.findComponent(ColorComponent);
            if (colorComponent && cleverapps.styles.MarkView.positions[component.name][colorComponent.color]) {
                position = cleverapps.styles.MarkView.positions[component.name][colorComponent.color];
            } else if (colorComponent && colorComponent.color) {
                position = undefined; // to process missed colors from styles by default, but cells without colors as is
            }
        }

        this.setPositionRound(position || { x: { align: "center" }, y: { align: "center" } });
    },

    getImage: function () {
        if (this.cell instanceof ColorBombCell && this.mark.isDefaultSkin()) {
            return bundles.game_mark.frames.mark_on_colorcell;
        }

        return bundles[this.mark.bundleId()].frames[this.getSkinnedRes("mark_on_colorcell")];
    },

    getGoalImage: function () {
        return bundles[this.mark.bundleId()].frames[this.getSkinnedRes("mark_goal")];
    },

    getSkin: function () {},

    reuse: function (cell) {
        this.cell = cell;
        this.mark = cell.findComponent(MarkComponent).mark;
        this.image.setSpriteFrame(this.getImage());
        this.setScale(1);
    },

    unuse: function () {
        this.removeFromParent(false);
        if (this.animation) {
            this.animation.clearTrack(0);
        }
        this.mark.onExplodeListener = function () {};
    },

    animateFlyStart: function (callback) {
        if (!this.animation) {
            this.image.setSpriteFrame(this.getGoalImage());
            this.image.runAction(
                new cc.Sequence(
                    new cc.MoveBy(1, cc.p(cleverapps.styles.MarkViewLowModeUpFly.x, cleverapps.styles.MarkViewLowModeUpFly.y)),
                    new cc.CallFunc(callback)
                )
            );
            return;
        }

        this.animation.setAnimation(0, "fly_start", false);
        this.animation.setCompleteListener(callback);
    },

    animateFlyGoal: function (callback) {
        if (Game.currentGame) {
            if (this.animation) {
                this.animation.setAnimation(0, "fly_to_goal", true);
                this.animation.setCompleteListener();
            }

            var goal = Game.currentGame.goals.findTargetFor(this.mark.getGoalId());
            goal.collect(1, this, { callback: callback });
        }
    },

    delete: function () {
        if (this.mark && this.mark.cell && this.mark.cell.putInPoolAvailable && !cleverapps.gameModes.putInPoolDisabled) {
            cc.pool.putInPool(this);
        } else {
            this.removeFromParent();
        }
    },

    animateCollect: function (isGoal) {
        ViewHelper.flyOut.call(this);
        this.setLocalZOrder(100);

        var endCallback = function () {
            this.delete();
        }.bind(this);

        var callback = isGoal ? this.animateFlyGoal.bind(this, endCallback) : endCallback;

        this.animateFlyStart(callback);
    },

    smile: function () {
        if (!this.animation) {
            return;
        }

        if (this.smiling) {
            return;
        }

        this.smiling = true;

        var styles = cleverapps.styles.MarkView;
        var animationName = styles.smile.animationName;
        if (Array.isArray(animationName)) {
            animationName = cleverapps.Random.choose(animationName);
        }
        this.animation.setAnimation(0, animationName, false);
        this.animation.setCompleteListener(this.stopSmile.bind(this));
    },

    stopSmile: function () {
        if (this.smiling) {
            this.smiling = false;

            this.image.visible = true;
            this.animation.visible = false;
        }
    }
});

MarkView.factory = function (cell) {
    return cc.pool.hasObject(MarkView) ? cc.pool.getFromPool(MarkView, cell) : new MarkView(cell);
};

cleverapps.styles.MarkViewLowModeUpFly = {
    x: 0,
    y: 100
};

cleverapps.styles.MarkView = {
    positions: {
    },

    animation: {
        start: {
            duration: 1.0
        }
    },

    smile: {
        animationName: "idle"
    }
};