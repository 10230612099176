/**
 * Created by Andrey Popov on 20.01.2021.
 */

var HierarchyView = WysiwygWindowBase.extend({
    ctor: function (hierarchy, options) {
        this._super("Hierarchy", cleverapps.styles.HierarchyView.width, options.height);

        this.hierarchy = hierarchy;

        this.hierarchy.on("rebuildItems", this.rebuildItems.bind(this), this);
        this.hierarchy.on("showLoading", this.showLoading.bind(this), this);
        this.hierarchy.on("selectionChanged", this.selectHierarchyItem.bind(this), this);
        this.hierarchy.on("multipleSelectionChanged", this.selectMultipleHierarchyItems.bind(this), this);

        this.rebuildItems();

        this.setPositionRound(cleverapps.styles.HierarchyView);
    },

    rebuildItems: function () {
        var styles = cleverapps.styles.HierarchyView;

        var showClipItems = false;
        var itemsToShow = [];
        var selection = this.hierarchy.getSelectedClip();

        var sceneIndex = 1;
        this.hierarchy.items.forEach(function (hierarchyItem) {
            var showItem = false;
            switch (hierarchyItem.type) {
                case HierarchyItem.TYPE.DIRECTORY:
                    showItem = true;
                    break;
                case HierarchyItem.TYPE.CLIP:
                    if (!selection) {
                        showItem = true;
                    } else if (selection.id === hierarchyItem.id || hierarchyItem.properties.visible) {
                        showItem = true;
                    }

                    showClipItems = showItem ? selection && selection.type === HierarchyItem.TYPE.CLIP : false;
                    break;
                default:
                    if (hierarchyItem.type === HierarchyItem.TYPE.SCENE) {
                        hierarchyItem.properties.caption = "scene_" + sceneIndex;
                        sceneIndex++;
                    }
                    if (showClipItems) {
                        itemsToShow.push(hierarchyItem);
                    }
                    break;
            }

            if (showItem) {
                itemsToShow.push(hierarchyItem);
            }
        });

        if (itemsToShow.length) {
            var directories = [];
            var clips = [];

            itemsToShow.forEach(function (hierarchyItem) {
                if (hierarchyItem.type === HierarchyItem.TYPE.DIRECTORY) {
                    directories.push(hierarchyItem);
                } else if (hierarchyItem.type === HierarchyItem.TYPE.CLIP) {
                    clips.push({
                        root: hierarchyItem,
                        branch: []
                    });
                } else {
                    clips[clips.length - 1].branch.push(hierarchyItem);
                }
            });

            clips.sort(function (a, b) {
                var dateA = Date.parse(a.root.properties.mtime);
                var dateB = Date.parse(b.root.properties.mtime);
                var oldDate = Date.now() - 7 * 1000 * 60 * 60 * 24;

                if (dateA > oldDate || dateB > oldDate) {
                    return dateB - dateA;
                }

                if (a.root.properties.caption < b.root.properties.caption) {
                    return -1;
                }
                if (a.root.properties.caption > b.root.properties.caption) {
                    return 1;
                }
                return 0;
            });

            itemsToShow = directories;
            clips.forEach(function (clip) {
                itemsToShow.push(clip.root);
                clip.branch.forEach(function (leaf) {
                    itemsToShow.push(leaf);
                });
            });
        }

        this.hierarchyItemViews = [];
        var itemViews = itemsToShow.map(function (hierarchyItem) {
            var itemView = new HierarchyItemView(hierarchyItem);
            this.hierarchyItemViews.push(itemView);
            return itemView;
        }.bind(this));

        var oldInnerContentPos;
        if (this.scroll) {
            oldInnerContentPos = this.scroll.getContainerPosition();
        }

        this.createScroll(new cleverapps.Layout(itemViews, {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        }), {
            innerContentPos: oldInnerContentPos
        });

        this.scroll.scrollToDefault();
    },

    selectHierarchyItem: function (item) {
        this.hierarchyItemViews.forEach(function (itemView) {
            itemView.setSelected(itemView.hierarchyItem === item);
        });

        this.rebuildItems();
    },

    selectMultipleHierarchyItems: function (items) {
        this.hierarchyItemViews.forEach(function (itemView) {
            itemView.setSelected(items.some(function (item) {
                return item === itemView.hierarchyItem;
            }));
        });
    },

    showLoading: function (visible) {
        if (this.loading) {
            this.loading.removeFromParent();
            delete this.loading;
        }

        if (visible) {
            this.loading = new cc.Sprite(bundles.wysiwyg.frames.loading);
            this.loading.runAction(new cc.RepeatForever(new cc.RotateBy(5, 360)));
            this.loading.setPositionRound(this.width / 2, this.height / 2);
            this.addChild(this.loading);
        }
    }
});

cleverapps.styles.HierarchyView = {
    margin: 6,

    width: 400,

    x: { align: "left" },
    y: { align: "top", dy: -74 }
};