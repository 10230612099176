/**
 * Created by andrey on 28.08.2024
 */

cleverapps.whenAllInitialized(function () {
    if (!connector.platform.oneOf(connector.TEST)) {
        return;
    }

    // eslint-disable-next-line no-global-assign
    setInterval = cleverapps.extendFunc(setInterval, function (func, interval) {
        var creationStack = new Error().stack;
        var wrapper = function () {
            if (cc.game.isPaused()) {
                console.error(creationStack);
                cleverapps.throwAsync("background interval - " + creationStack);
                wrapper = func;
            }

            return func.apply(this, arguments);
        };

        return this._super(function () {
            return wrapper.apply(this, arguments);
        }, interval);
    });
});