/**
 * Created by slava on 24.03.17.
 */

var RestartWindow = CleverappsWindow.extend({
    ctor: function () {
        if (RestartWindow.showed || cleverapps.environment.isSceneWithPreview() || !cleverapps.scenes.getRunningScene()) {
            return;
        }
        RestartWindow.showed = true;
        
        this._super();

        this.setLocalZOrder(Transition.ZORDER + 1);
    },

    onWindowLoaded: function () {
        var title = "RestartWindow.Title";
        var message = "RestartWindow.AnErrorOccured";

        if (cleverapps.versionChecker.isNewClientVersionAvailable()) {
            title = "RestartWindow.NewVersionTitle";
            message = "RestartWindow.NewVersionAvailable";
        }
        
        title = Messages.get(title) || "Try again";
        message = Messages.get(message) || "An error occurred";

        this.text = cleverapps.UI.generateOnlyText(message, cleverapps.styles.FONTS.WINDOW_TEXT, false);
        this.text.setDimensions(cleverapps.styles.RestartWindow.width, cleverapps.styles.RestartWindow.height);
        this.text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        this.text.setVerticalAlignment(cc.VERTICAL_TEXT_ALIGNMENT_CENTER);

        this._super({
            content: this.text,
            name: "RestartWindow",
            title: title,
            button: {
                text: Messages.get("RestartWindow.ButtonMessage") || "Reload",
                width: cleverapps.styles.RestartWindow.button.width,
                height: cleverapps.styles.RestartWindow.button.height,
                onPressed: function () {
                    connector.platform.locationReload();
                }
            },
            closeButton: false
        });

        if (this.window && this.window.height === 0) {
            this.text.removeFromParent();
            this.windowTitle.removeFromParent();
        }
    }
});

cleverapps.styles.RestartWindow = {
    width: 590,
    height: 460,
    button: {
    }
};

if (cleverapps.config.orientation === "horizontal") {
    cleverapps.increaseStylesByKoef(cleverapps.styles.RestartWindow, 1.4);
}