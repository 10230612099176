/**
 * Created by Denis Kuzin on 12 august 2024
 */

var ComboComponent = function (cell) {
    this.cell = cell;
};

ComboComponent.prototype.repaint = function () {
    var newColor = this.cell.getRandomColor();
    var params = ComboComponent.CalcConstructor({ color: newColor });
    var Constructor = params.className;
    var newCell = new Constructor(this.cell.x, this.cell.y, {
        components: params.components,
        color: newColor
    });
    newCell.algo = ComboComponent.CalcAlgo(newCell, { random: true });

    var markComponent = newCell.findComponent(MarkComponent);
    if (markComponent) {
        newCell = markComponent.repaint(this.cell);
    }

    return newCell;
};

ComboComponent.prototype.getType = function () {
    return this.cell.algo.name;
};

ComboComponent.prototype.algoEffect = function (callback) {
    if (this.cell.algo.beforeEffect) {
        this.cell.algo.beforeEffect(this.cell);
    }

    var colorComponent = this.cell.findComponent(ColorComponent);
    var color = colorComponent && colorComponent.color || "";

    Game.currentGame.counter.setTimeout(function () {
        if (!Game.currentGame || !Game.currentGame.field) {
            return;
        }

        var choices = this.cell.algo.findTargets(this.cell).filter(function (choice) {
            return choice !== undefined;
        }).map(function (choice) {
            return { x: choice.x, y: choice.y };
        });

        for (var p = 0; p < choices.length; p++) {
            ComboComponent.addComboTarget(choices[p].x, choices[p].y, color);
        }

        if (this.cell.algo.actionToAllCells) {
            var cells = [];
            for (var i = 0; i < choices.length; i++) {
                ComboComponent.removeComboTarget(choices[i].x, choices[i].y, color);
                var cell = Game.currentGame.field.cells[choices[i].y][choices[i].x];
                if (CombosLibrary.checkFilters(this.cell, cell, true)) {
                    cells.push(cell);
                }
            }
            this.cell.algo.action(this.cell, cells);
        } else {
            var shotIndexTimeout = 0;
            choices.forEach(function (choice, order) {
                var flyDuration = this.cell.algo.shootFlyDuration(this.cell, choice);

                var shotFunc = function () {
                    this.cell.algoShotAnimation(choice, flyDuration, order);
                    Game.currentGame.counter.setTimeout(function () {
                        ComboComponent.removeComboTarget(choice.x, choice.y, color);
                        var cell = Game.currentGame.field.cells[choice.y][choice.x];
                        if (CombosLibrary.checkFilters(this.cell, cell, true)) {
                            this.cell.algo.action(this.cell, cell, order);
                        }
                    }.bind(this), flyDuration * 1000);
                }.bind(this);

                if (shotIndexTimeout > 0) {
                    Game.currentGame.counter.setTimeout(shotFunc, shotIndexTimeout);
                } else {
                    shotFunc();
                }

                shotIndexTimeout += this.cell.algo.between_shoot_timeout;
            }.bind(this));
        }
    }.bind(this), this.cell.algo.action_delay);

    this.cell.algoEffectAnimation();
    Game.currentGame.counter.setTimeout(function () {
        callback();
    }, this.cell.algo.action_delay + this.cell.algo.action_timeout);

    if (color) {
        cleverapps.eventBus.trigger("taskEvent", DailyTasks.COLLECT_HERO_CELLS, { color: color });
    }
};

ComboComponent.CalcAlgo = function (base, options) {
    if (Match3Rules.Heroes) {
        var hero = match3.heroes.getHero(base.findComponent(ColorComponent).color, options);
        if (hero.level) {
            return hero;
        }

        return;
    }

    if (options.shape) {
        return CombosLibrary.ByShape[options.shape.calculateForm()];
    } if (options.neighbor) {
        return options.neighbor.algo;
    } if (options.random) {
        return CombosLibrary.ByShape[Shape.PLANE];
    }
};

ComboComponent.CalcConstructor = function (base) {
    var colorComponent = base.findComponent && base.findComponent(ColorComponent);
    var color = base.color || colorComponent && colorComponent.color;

    if (Match3Rules.Heroes) {
        if (Game.currentGame && Game.currentGame.goals.hasType(color)) {
            return {
                className: HeroCell,
                components: [GoalCoefComponent]
            };
        }

        return {
            className: HeroCell,
            components: []
        };
    }

    return {
        className: ComboCell,
        components: []
    };
};

ComboComponent.Create = function (base, options) {
    if (options.shape && options.shape.calculateForm() === Shape.FIVE && MultiColorCell.IsAvailable()) {
        return new MultiColorCell(base.x, base.y);
    }

    if (options.shape && options.shape.calculateForm() === Shape.THREE) {
        return;
    }

    var algo = ComboComponent.CalcAlgo(base, options);

    if (!algo) {
        return;
    }

    var params = ComboComponent.CalcConstructor(base, options);
    var ConstructorClass = params.className;

    var baseColorComponent = base.findComponent(ColorComponent);
    var cell = new ConstructorClass(base.x, base.y, {
        components: params.components,
        color: baseColorComponent.color,
        algo: algo
    });

    var baseMarkComponent = base.findComponent(MarkComponent);
    if (baseMarkComponent && baseMarkComponent.mark && !options.shape) {
        var markComponent = cell.findComponent(MarkComponent);
        if (markComponent) {
            markComponent.addMark();
        }
    }

    return cell;
};

ComboComponent.resetComboTargets = function () {
    ComboComponent.targets = {};
};

ComboComponent.comboTargetId = function (x, y, color) {
    return x + "_" + y + "_" + color;
};

ComboComponent.checkComboTarget = function (x, y, color) {
    var uid = ComboComponent.comboTargetId(x, y, color);
    return ComboComponent.targets[uid] && ComboComponent.targets[uid] > 0;
};

ComboComponent.addComboTarget = function (x, y, color) {
    var uid = ComboComponent.comboTargetId(x, y, color);
    if (!ComboComponent.targets[uid]) {
        ComboComponent.targets[uid] = 0;
    }
    ComboComponent.targets[uid]++;
};

ComboComponent.removeComboTarget = function (x, y, color) {
    var uid = ComboComponent.comboTargetId(x, y, color);
    if (ComboComponent.checkComboTarget(x, y, color)) {
        ComboComponent.targets[uid]--;
    }
};