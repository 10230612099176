/**
 * Created by anatoly on 06.09.2024
 */

var DangerComponentView = cc.Node.extend({
    ctor: function (component) {
        this._super();

        var styles = cleverapps.styles.DangerComponentView;

        this.component = component;

        this.addTimer();
        this.addAnimation();

        this.setContentSize(styles);

        this.setPositionRound(cleverapps.styles.DangerComponentView);
    },

    addAnimation: function () {
        var styles = cleverapps.styles.DangerComponentView.animation;

        var animationBg = new cc.Sprite(bundles.danger_component.frames.timer_bg);
        animationBg.setPositionRound(styles);

        this.addChild(animationBg);

        var animation = new cleverapps.Spine(bundles.danger_component.jsons.timer);

        animation.setAnimation(0, "animation");
        animation.setPosition(animationBg.width / 2, animationBg.height / 2);

        var realDuration = this.component.duration / 1000;
        var animationDuration = animation.getAnimationDuration("animation");

        var timeScale = animationDuration / realDuration;

        animation.setTimeScale(0);
        this.component.on("onStart", function () {
            animation.setTimeScale(timeScale);
        });

        animationBg.addChild(animation);
    },

    addTimer: function () {
        var styles = cleverapps.styles.DangerComponentView.timer;

        var timerBg = cleverapps.UI.createScale9Sprite(bundles.danger_component.frames.progress_bg);
        timerBg.setCapInsets(cc.rect(timerBg.width / 2 - 1, timerBg.height / 3, 2, timerBg.height / 3));

        timerBg.setContentSize(styles.background.width, timerBg.height);
        timerBg.setPositionRound(styles);

        this.addChild(timerBg, -1);

        this.timerView = cleverapps.UI.generateImageText("00:00", cleverapps.styles.FONTS.DANGER_COMPONENT);
        this.updateTime();

        this.component.on("update", this.updateTime.bind(this));

        this.timerView.setPositionRound(styles);

        this.addChild(this.timerView);
    },

    updateTime: function () {
        var timeLeft = this.component.getTimeLeft();
        this.timerView.setString(cleverapps.intervalToString(timeLeft, false));
    }
});

cleverapps.styles.DangerComponentView = {
    width: 300,
    height: 100,

    x: { align: "left", dx: 20 },
    y: { align: "center" },

    timer: {
        background: {
            width: 200
        },

        x: { align: "center", dx: 35 },
        y: { align: "center" }
    },

    animation: {
        x: { align: "left" },
        y: { align: "center" }
    }
};

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    DANGER_COMPONENT: {
        size: 40,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    }
});