/**
 * Created by Andrey Popov on 13.04.2022
 */

cleverapps.YandexPushesMap = {
    mergecraft: {
        BONUS_ROUND: undefined,
        LIVES: undefined,
        MINI_GAME: undefined,
        LETS_PLAY_1: undefined,
        FREE_CHEST: undefined,
        ENERGY_LOTTERY: undefined,
        FRUIT_RIPE: 1,
        CASTLE_PRIZE: undefined,
        ORDER_READY: 3,
        TREE_CHOPPED: undefined,
        BUILDING_BUILT: 5,
        MAGIC_PLANT_READY: undefined,
        MINI_GAME_PRIZE: undefined,
        DAILY_TASKS_UNCOMPLETED: undefined,
        PASS_UNCOMPLETED: undefined,
        PUMPKIN_RIPE: undefined,
        UNITLOCK_OPEN: undefined,
        EXPEDITION: undefined,
        EXPEDITION_TREE_CHOPPED: undefined,
        EXPEDITION_PERIODIC: undefined
    },
    wondermerge_debug: {
        BONUS_ROUND: undefined,
        LIVES: undefined,
        MINI_GAME: undefined,
        LETS_PLAY_1: 8,
        FREE_CHEST: 9,
        ENERGY_LOTTERY: undefined,
        FRUIT_RIPE: 1,
        CASTLE_PRIZE: 2,
        ORDER_READY: 3,
        TREE_CHOPPED: 4,
        BUILDING_BUILT: 5,
        MAGIC_PLANT_READY: 6,
        MINI_GAME_PRIZE: 7,
        DAILY_TASKS_UNCOMPLETED: 10,
        PASS_UNCOMPLETED: 11,
        PUMPKIN_RIPE: 12,
        UNITLOCK_OPEN: 13,
        EXPEDITION: 14,
        EXPEDITION_TREE_CHOPPED: 15,
        EXPEDITION_PERIODIC: 16
    },
    riddles_debug: {
        BONUS_ROUND: undefined,
        LIVES: 1,
        MINI_GAME: 2,
        LETS_PLAY_1: 3,
        FREE_CHEST: undefined,
        ENERGY_LOTTERY: undefined,
        FRUIT_RIPE: undefined,
        CASTLE_PRIZE: undefined,
        ORDER_READY: undefined,
        TREE_CHOPPED: undefined,
        BUILDING_BUILT: undefined,
        MAGIC_PLANT_READY: undefined,
        MINI_GAME_PRIZE: undefined,
        DAILY_TASKS_UNCOMPLETED: 4,
        PASS_UNCOMPLETED: 5,
        PUMPKIN_RIPE: undefined,
        UNITLOCK_OPEN: undefined,
        EXPEDITION: undefined,
        EXPEDITION_TREE_CHOPPED: undefined,
        EXPEDITION_PERIODIC: undefined
    },
    heroes_debug: {
        BONUS_ROUND: undefined,
        LIVES: 1,
        MINI_GAME: 2,
        LETS_PLAY_1: 3,
        FREE_CHEST: undefined,
        ENERGY_LOTTERY: undefined,
        FRUIT_RIPE: undefined,
        CASTLE_PRIZE: undefined,
        ORDER_READY: undefined,
        TREE_CHOPPED: undefined,
        BUILDING_BUILT: undefined,
        MAGIC_PLANT_READY: undefined,
        MINI_GAME_PRIZE: undefined,
        DAILY_TASKS_UNCOMPLETED: 4,
        PASS_UNCOMPLETED: 5,
        PUMPKIN_RIPE: undefined,
        UNITLOCK_OPEN: undefined,
        EXPEDITION: undefined,
        EXPEDITION_TREE_CHOPPED: undefined,
        EXPEDITION_PERIODIC: undefined
    },
    scramble_debug: {
        BONUS_ROUND: 1,
        LIVES: undefined,
        MINI_GAME: 2,
        LETS_PLAY_1: 3,
        FREE_CHEST: undefined,
        ENERGY_LOTTERY: undefined,
        FRUIT_RIPE: undefined,
        CASTLE_PRIZE: undefined,
        ORDER_READY: undefined,
        TREE_CHOPPED: undefined,
        BUILDING_BUILT: undefined,
        MAGIC_PLANT_READY: undefined,
        MINI_GAME_PRIZE: undefined,
        DAILY_TASKS_UNCOMPLETED: 4,
        PASS_UNCOMPLETED: undefined,
        PUMPKIN_RIPE: undefined,
        UNITLOCK_OPEN: undefined,
        EXPEDITION: undefined,
        EXPEDITION_TREE_CHOPPED: undefined,
        EXPEDITION_PERIODIC: undefined
    },
    tripeaks_debug: {
        BONUS_ROUND: undefined,
        LIVES: 1,
        MINI_GAME: 2,
        LETS_PLAY_1: 3,
        FREE_CHEST: undefined,
        ENERGY_LOTTERY: undefined,
        FRUIT_RIPE: undefined,
        CASTLE_PRIZE: undefined,
        ORDER_READY: undefined,
        TREE_CHOPPED: undefined,
        BUILDING_BUILT: undefined,
        MAGIC_PLANT_READY: undefined,
        MINI_GAME_PRIZE: undefined,
        DAILY_TASKS_UNCOMPLETED: 4,
        PASS_UNCOMPLETED: undefined,
        PUMPKIN_RIPE: undefined,
        UNITLOCK_OPEN: undefined,
        EXPEDITION: undefined,
        EXPEDITION_TREE_CHOPPED: undefined,
        EXPEDITION_PERIODIC: undefined
    }
};

if (typeof cc === "undefined") {
    module.exports = cleverapps.YandexPushesMap;
}
