/**
 * Created by slava on 02.02.17.
 */

var IceBlockDecoratorCellView = BaseCellView.extend({
    ctor: function (decoratedCell) {
        this._super();

        this.setAnchorPoint(0.5, 0.5);

        this.setCascadeOpacityEnabled(true);

        var image = this.image = new cc.Sprite(this.getImage(decoratedCell));
        this.setContentSize2(image.getContentSize());
        image.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(image);

        this.initialize(decoratedCell);
    },

    getImage: function (decoratedCell) {
        return bundles.ice_block.frames["ice_block_" + decoratedCell.lives + "_stable"];
    },

    initialize: function (decoratedCell) {
        this._super(decoratedCell);

        this.cell.onChangeLivesListener = this.createListener(this.changeLives.bind(this));
        this.cell.onUpdate = this.createListener(this.updateImage.bind(this));

        this.updateImage();
        this.image.visible = true;
    },

    unuse: function () {
        this.cell.onChangeLivesListener = function () {};
        this.cell.onUpdate = function () {};

        this._super();
    },

    animate: function (name, callback, spriteAction) {
        var animation = IceBlockAnimation.factory();
        animation.setPosition(this.getPosition());
        this.parent.addChild(animation);

        animation.runAnimate(name, callback, spriteAction, this.image);
    },

    animateExplode: function (callback) {
        callback = callback || function () {};
        this.animate("0", callback, new cc.FadeOut(0.4));
        cleverapps.audio.playSound(cleverapps.styles.IceBlockDecoratorCellView.dieSound);
    },

    changeLives: function () {
        if (this.cell.lives <= 0) {
            return;
        }

        this.animate("1", function () {
            if (this.cell.lives > 0) {
                this.image.setSpriteFrame(this.getImage(this.cell));
                this.image.setVisible(true);
            }
        }.bind(this));

        cleverapps.audio.playSound(cleverapps.styles.IceBlockDecoratorCellView.stageDecreaseSound);
    },

    updateImage: function () {
        this.image.setSpriteFrame(this.getImage(this.cell));
    }
});

cleverapps.styles.IceBlockDecoratorCellView = {
    stageDecreaseSound: bundles.ice_block.urls.ice_block_live_effect,
    dieSound: bundles.ice_block.urls.ice_block_explode_effect
};