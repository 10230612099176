/**
 * Created by Ivan on 04.07.2023
 */

var PreviewSlider = function (wysiwyg) {
    this.hierarchy = wysiwyg.hierarchy;
    this.preview = wysiwyg.wysiwygPreview;

    cleverapps.EventEmitter.call(this);

    this.hierarchy.on("selectionChanged", this.selectionChanged.bind(this));
};

PreviewSlider.prototype = Object.create(cleverapps.EventEmitter.prototype);
PreviewSlider.prototype.constructor = PreviewSlider;

PreviewSlider.prototype.selectionChanged = function (item) {
    if (item.type === HierarchyItem.TYPE.DIRECTORY) {
        this.preview.showComponents(item);
        return;
    }

    this.clip = this.hierarchy.getSelectedClip();
    this.countOfScenes = this.clip.children.length;

    this.preview.showComponents(item, this.clip.id !== this.selectedClipId || this.current !== this.getIndexOfScene(item));
    this.selectedClipId = this.clip && this.clip.id ? this.clip.id : undefined;

    this.current = this.getIndexOfScene(item);
    this.trigger("changeScene", this.current);
};

PreviewSlider.prototype.setScene = function (sceneIndex) {
    this.hierarchy.selectItem(this.getSceneByIndex(sceneIndex));
};

PreviewSlider.prototype.getSceneByIndex = function (sceneIndex) {
    return sceneIndex === 0 ? this.clip : this.clip.children[sceneIndex - 1];
};

PreviewSlider.prototype.getIndexOfScene = function (item) {
    while (item.parentItem && item.type !== HierarchyItem.TYPE.SCENE) {
        item = item.parentItem;
    }

    var clip = item.parentItem || item;
    var currentScene = 0;
    clip.children.forEach(function (scene, index) {
        if (scene === item) {
            currentScene = index + 1;
        }
    });

    return currentScene;
};

PreviewSlider.prototype.getNamesOfScenes = function () {
    var sceneNames = ["C"];

    this.clip.children.forEach(function (scene) {
        var name = scene.properties.caption;
        sceneNames.push(name.slice(name.indexOf("_") + 1));
    });

    return sceneNames;
};