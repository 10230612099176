/**
 * Created by slava on 02.02.17.
 */

var EaterCellView = BaseCellView.extend({
    ctor: function (eaterCell) {
        this._super();

        var bigComponent = eaterCell.findComponent(BigComponent);
        if (!bigComponent.isHead) {
            return;
        }

        var styles = this.getStyles();
        if (!eaterCell.isForGoal) {
            this.setContentSize2(styles.width, styles.height);
        } else {
            this.setContentSize2(styles.goal.width, styles.goal.height);
        }

        this.setAnchorPoint(0.5, 0.5);

        this.initialize(eaterCell);

        this.setPosition(BaseCellView.alignInTheGrid(eaterCell.x + 0.5, eaterCell.y + 0.5));
    },

    getStyles: function () {
        return cleverapps.styles.EaterCellView;
    }
});

cleverapps.styles.EaterCellView = {
    width: 240,
    height: 240,
    idleAnimation: true,

    goal: {
        width: 120,
        height: 120,
        animation: {
            scale: 0.5
        }
    },

    hover: {
        y: 10
    },

    collect: {
        y: 300,
        up: 30,
        scale: 0.5,
        target: {
            y: 0
        }
    }
};