/**
 * Created by slava on 02.02.17.
 */

var BombByLeftMoves = function (x, y) {
    this.x = x;
    this.y = y;
    this.onExplodeListener = function () {};
    this.onAnimateListener = function () {};
};

BombByLeftMoves.prototype.go = function () {
    this.onAnimateListener();

    Game.currentGame.counter.setTimeout(function () {
        this.explode();
    }.bind(this), 1400);
};

BombByLeftMoves.prototype.explode = function () {
    for (var dx = -1; dx <= 1; dx++) {
        for (var dy = -1; dy <= 1; dy++) {
            var col = this.x + dx;
            var row = this.y + dy;
            if (Game.currentGame.field.isCellInField(row, col)) {
                Game.currentGame.field.cells[row][col].hurt({
                    id: 0,
                    groupSize: 1
                });
            }
        }
    }
    this.onExplodeListener();
};

BombByLeftMoves.ChooseLocations = function (field, amount) {
    var i, j;
    var usedInExplode = [];
    for (i = 0; i < Field.SIZE; i++) {
        usedInExplode.push([]);
        for (j = 0; j < Field.SIZE; j++) {
            usedInExplode[i].push(false);
        }
    }

    var calcScoreByLeftMovesExplodeCell = function (i, j) {
        if (!field.cells[i][j] || field.cells[i][j].lives < 0) {
            return 0;
        }
        var score = 0;
        for (var di = -1; di <= 1; di++) {
            for (var dj = -1; dj <= 1; dj++) {
                var row = i + di;
                var col = j + dj;
                if (field.isCellInField(row, col)) {
                    if (field.cells[row][col].lives > 0 && !usedInExplode[row][col]) {
                        if (Game.currentGame.goals.hasType(field.cells[row][col].getGoalId())) {
                            var amountCells = 1;
                            var goalCoefComponent = field.cells[row][col].findComponent(GoalCoefComponent);
                            if (goalCoefComponent && goalCoefComponent.coef) {
                                amountCells += goalCoefComponent.coef;
                            }
                            score += 10 * amountCells;
                        } else {
                            score += 3;
                        }
                    }
                }
            }
        }
        return score;
    };

    var useCellInLeftMovesExplode = function (i, j) {
        for (var di = -1; di <= 1; di++) {
            for (var dj = -1; dj <= 1; dj++) {
                var row = i + di;
                var col = j + dj;
                if (field.isCellInField(row, col)) {
                    usedInExplode[row][col] = true;
                }
            }
        }
    };

    var res = [];
    for (var t = 0; t < amount; t++) {
        var bestPosition = false, bestScore = 0;
        for (i = 0; i < Field.SIZE; i++) {
            for (j = 0; j < Field.SIZE; j++) {
                var currentScore = calcScoreByLeftMovesExplodeCell(i, j);
                if (currentScore > bestScore) {
                    bestScore = currentScore;
                    bestPosition = { row: i, col: j };
                }
            }
        }
        if (!bestPosition) {
            break;
        }
        useCellInLeftMovesExplode(bestPosition.row, bestPosition.col);
        res.push(bestPosition);
    }

    cleverapps.shuffle(res);

    return res;
};