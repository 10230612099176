/**
 * Created by Andrey Popov on 1/22/21.
 */

var Hierarchy = function (wysiwyg) {
    this.wysiwyg = wysiwyg;

    cleverapps.EventEmitter.call(this);

    this.items = [];
    this.selectedItem = undefined;
    this.directories = [];
    this.readyDirectory = "ads";

    this.multiSelection = false;
    this.selectedItems = [];
};

Hierarchy.prototype = Object.create(cleverapps.EventEmitter.prototype);
Hierarchy.prototype.constructor = Hierarchy;

Hierarchy.prototype.createDirectory = function (fromRoot) {
    var newName = (prompt("enter name for the new directory:") || "new_directory");
    if (fromRoot) {
        newName = "wysiwyg/" + newName;
    } else {
        newName = this.wysiwyg.directory + "/" + newName;
    }

    cleverapps.RestClient.post(
        "/wysiwyg/createDirectory",
        {
            directory: newName
        },
        function () {
            this.selectDirectory(newName);
        }.bind(this)
    );
};

Hierarchy.prototype.loadDirectory = function (directory, callback) {
    this.blockSelection(true);
    directory = directory || "wysiwyg/tmp";

    cleverapps.RestClient.get(
        "/wysiwyg/directories?directory=" + directory,
        {},
        function (data) {
            cleverapps.git.listLocalChanges(GitManager.FOLDERS.WYSIWYG_SCENARIOS).forEach(function (file) {
                var clipFileName = file.split("/").pop().replace(".json", "");

                if (!GitManager.match(file, this.getClipPath(directory, clipFileName))) {
                    return;
                }

                var clipData = cleverapps.git.getContent(file);
                var clipId = clipData ? clipData[0].id : clipFileName;

                for (var i = 0; i < data.content.length; ++i) {
                    if (data.content[i].id === clipId) {
                        if (clipData === null) {
                            data.content.splice(i, 1);
                        } else {
                            data.content[i] = {
                                id: clipData[0].id,
                                caption: clipData[0].properties.caption,
                                frameCapture: clipData[0].properties.frameCapture,
                                mtime: clipData[0].properties.mtime
                            };
                        }
                        return;
                    }
                }

                if (clipData) {
                    data.content.push({
                        id: clipData[0].id,
                        caption: clipData[0].properties.caption,
                        frameCapture: clipData[0].properties.frameCapture,
                        mtime: clipData[0].properties.mtime
                    });
                }
            }.bind(this));

            this.directories = data.directories;
            this.wysiwyg.googleDriveProvider.setAssets(data.assets);

            var directoryItem = HierarchyItem.createDirectory(directory);

            var items = [directoryItem].concat(data.content.map(function (clipData) {
                var hierarchyItem = new HierarchyItem({
                    type: HierarchyItem.TYPE.CLIP,
                    id: clipData.id,
                    properties: {
                        caption: clipData.caption,
                        mtime: clipData.mtime,
                        frameCapture: clipData.frameCapture
                    }
                });
                hierarchyItem.parentItem = directoryItem;

                return hierarchyItem;
            }));

            this.items = [];

            items.forEach(function (item) {
                this.addItem(item, true);
            }.bind(this));

            this.blockSelection(false);

            callback && callback();
        }.bind(this),
        function (err) {
            console.log(err);
            this.blockSelection(false);
        }.bind(this),
        {
            ignoreNoRest: true
        }
    );
};

Hierarchy.prototype.selectDirectory = function (directory) {
    this.wysiwyg.directory = directory;

    cleverapps.setUrlHash({
        wysiwyg: true,
        directory: this.wysiwyg.directory,
        clipId: undefined
    });

    this.loadDirectory(this.wysiwyg.directory, function () {
        this.selectItem(this.items[0]);
    }.bind(this));
};

Hierarchy.prototype.loadClip = function (clipId, callback) {
    this.blockSelection(true);

    if (this.directories.length === 0) {
        this.loadDirectory(this.wysiwyg.directory, function () {
            this.loadClip(clipId, callback);
        }.bind(this));
        return;
    }

    var file = this.getClipPath(this.wysiwyg.directory, clipId);

    var onFailure = function (err) {
        console.log(err);
        this.blockSelection(false);
    }.bind(this);

    var clipData = cleverapps.git.getContent(file);
    if (clipData) {
        this.processClipData(clipData, callback);
        return;
    }

    cleverapps.RestClient.get(
        "/wysiwyg/clip?file=" + file,
        {},
        function (clipData) {
            if (clipData) {
                this.processClipData(clipData, callback);
            } else {
                onFailure("no such file " + file);
            }
        }.bind(this),
        onFailure,
        {
            ignoreNoRest: true
        }
    );
};

Hierarchy.prototype.processClipData = function (clipData, callback) {
    var itemsByIds = {};
    var directoryItem = HierarchyItem.createDirectory(this.wysiwyg.directory);

    var items = [directoryItem].concat(clipData.map(function (item) {
        var hierarchyItem = new HierarchyItem(item);
        itemsByIds[item.id] = hierarchyItem;

        return hierarchyItem;
    })).filter(function (item) {
        return item.type !== HierarchyItem.TYPE.COMPONENT || Assets.COMPONENTS[item.assetName] !== undefined;
    });

    var clipItem = undefined;
    items.forEach(function (item) {
        if (item.parent) {
            item.parentItem = itemsByIds[item.parent];
            delete item.parent;
        }

        if (item.type === HierarchyItem.TYPE.CLIP) {
            clipItem = item;
        } else if (item.type === HierarchyItem.TYPE.COMPONENT) {
            var assetComponent = this.wysiwyg.assets.components.filter(function (comp) {
                return comp.name === item.assetName;
            })[0];

            if (assetComponent) {
                var sceneComponent = new SceneComponent({
                    id: item.id,
                    ctor: assetComponent.ctor,
                    properties: item.properties
                });
                this.wysiwyg.wysiwygPreview.registerComponent(sceneComponent);
            }
        }
    }.bind(this));

    this.setItems(items);

    cleverapps.setUrlHash({
        directory: this.wysiwyg.directory,
        clipId: clipItem.id
    });

    this.blockSelection(false);
    this.selectItem(clipItem);

    callback && callback();
};

Hierarchy.prototype.getClipContent = function (clip) {
    var content = [clip.toJSON()];
    var parents = [clip.id];
    this.items.map(function (item) {
        return item.toJSON();
    }).forEach(function (item) {
        if (parents.indexOf(item.parent) !== -1) {
            content.push(item);
            parents.push(item.id);
        }
    });

    return cleverapps.clone(content, true);
};

Hierarchy.prototype.save = function (clip, f) {
    if (clip.type === HierarchyItem.TYPE.DIRECTORY) {
        return;
    }

    if (resolutionScale !== 1) {
        alert("check resolutionScale! Current value: " + resolutionScale);
        return;
    }

    cleverapps.git.edit("scenario", {
        file: this.getClipPath(this.wysiwyg.directory, clip.id),
        content: this.getClipContent(clip)
    });

    if (f) {
        f();
    }
};

Hierarchy.prototype.makeReady = function () {
    if (!this.selectedItem) {
        return;
    }
    var itemToMove = this.selectedItem;
    var newDirectory = this.readyDirectory ? "wysiwyg/" + this.readyDirectory : "wysiwyg/ads";
    var content = this.getClipContent(itemToMove);

    cleverapps.git.remove("scenario", {
        file: this.getClipPath(this.wysiwyg.directory, itemToMove.id)
    });
    cleverapps.git.edit("scenario", {
        file: this.getClipPath(newDirectory, itemToMove.id),
        content: content
    });

    this.selectDirectory(newDirectory);
};

Hierarchy.prototype.reorder = function (itemToMove, direction) {
    var siblings = itemToMove.parentItem.children.slice().sort(function (a, b) {
        return this.items.indexOf(a) - this.items.indexOf(b);
    }.bind(this));
    var sibling = siblings[siblings.indexOf(itemToMove) + direction];

    var deleteCount = this.getCountOfChildren(itemToMove) + 1;
    var itemsToMove = this.items.splice(this.items.indexOf(itemToMove), deleteCount);

    var insertIndex = this.items.indexOf(sibling);
    if (direction > 0) {
        insertIndex += deleteCount;
    }
    this.items.splice.apply(this.items, [insertIndex, 0].concat(itemsToMove));

    itemToMove.parentItem.children = [];
    this.items.forEach(function (item) {
        if (item.parentItem === itemToMove.parentItem) {
            itemToMove.parentItem.children.push(item);
        }
    });

    this.updateItemsZOrder();

    this.save(this.getSelectedClip());

    this.trigger("rebuildItems");
    this.wysiwyg.wysiwygPreview.forceUpdate = true;

    this.selectItem(itemToMove);
};

Hierarchy.prototype.addItem = function (item, silent) {
    if (item.parentItem) {
        var indexToAddAfter = this.items.indexOf(item.parentItem) + this.getCountOfChildren(item.parentItem);
        this.items.splice(indexToAddAfter + 1, 0, item);
        item.parentItem.children.push(item);
    } else {
        this.items.push(item);
    }

    this.updateItemsZOrder();

    if (!silent) {
        this.trigger("rebuildItems");

        this.wysiwyg.wysiwygPreview.forceUpdate = true;
        this.selectItem(item);

        this.save(this.getSelectedClip());
    }
};

Hierarchy.prototype.updateItemsZOrder = function () {
    this.items.forEach(function (item, index) {
        item.properties.zOrder = this.items.length - index - 1;
    }.bind(this));
};

Hierarchy.prototype.setItems = function (items, silent) {
    this.items = [];
    items.forEach(function (item) {
        this.addItem(item, true);
    }.bind(this));

    if (!silent) {
        this.trigger("rebuildItems");
    }
};

Hierarchy.prototype.removeItem = function (item) {
    var clip = this.getItemClip(item);
    this.items.filter(function (child) {
        return child.parentItem === item;
    }).forEach(function (child) {
        this.removeItem(child);
    }.bind(this));

    if (item.parentItem) {
        var index = item.parentItem.children.indexOf(item);
        if (index > -1) {
            item.parentItem.children.splice(index, 1);
        }
    }

    var toDelete = this.items.indexOf(item);
    if (toDelete !== -1) {
        this.items.splice(toDelete, 1);
        this.trigger("rebuildItems");
    }

    this.wysiwyg.wysiwygPreview.forceUpdate = true;
    if (item.parentItem) {
        this.selectItem(item.parentItem);
    } else if (this.items.length > 0) {
        this.selectItem(this.items[0]);
    }

    if (HierarchyItem.TYPE.CLIP === item.type) {
        cleverapps.git.remove("scenario", {
            file: this.getClipPath(this.wysiwyg.directory, item.id)
        });

        cleverapps.setUrlHash({
            clipId: undefined
        });
    } else {
        this.save(clip);
    }
};

Hierarchy.prototype.selectItem = function (item) {
    if (this.selectionBlocked) {
        console.log("skip selection due loading");
        return;
    }

    console.log("selectItem:", item);
    this.selectedItem = item;
    this.trigger("selectionChanged", item);

    if (this.multiSelection) {
        this.selectedItems.push(item);
        this.trigger("multipleSelectionChanged", this.selectedItems);
    } else {
        this.selectedItems = [];
    }

    if (connector.info.source !== "playable") {
        cleverapps.setUrlHash({ clipId: this.getItemClip(item) ? this.getItemClip(item).id : undefined });
    }
};

Hierarchy.prototype.changeItemProperty = function (item, property, value) {
    console.log("PROPERTY VALUE CHANGED: " + property + " : " + JSON.stringify(item.properties[property]) + " -> " + JSON.stringify(value));
    var falseAlarm = JSON.stringify(item.properties[property]) === JSON.stringify(value);

    item.properties[property] = cleverapps.clone(value, true);
    this.save(this.getItemClip(item));
    HierarchyItem.processProperties(item.properties);

    if (["caption", "visible", "spine", "image", "sound", "LocalizedText", "gameSpine", "gameImage"].indexOf(property) !== -1) {
        this.trigger("rebuildItems");
    }

    if (["scale", "position", "flags", "snapshot", "height", "width"].indexOf(property) !== -1 && item.assetName === "AdsGame") {
        this.wysiwyg.wysiwygPreview.forceUpdate = true;
    }

    if (["zOrder", "bgPosition"].indexOf(property) !== -1) {
        this.wysiwyg.wysiwygPreview.forceUpdate = true;
    }

    if (["flags", "snapshot", "height", "width"].indexOf(property) !== -1 && item.assetName === "AdsGame") {
        this.wysiwyg.wysiwygPreview.hardReload = true;
    }

    if (property === "adHoc") {
        this.items.filter(function (gameItem) {
            return gameItem.assetName === "AdsGame";
        }).forEach(function (gameItem) {
            this.wysiwyg.wysiwygPreview.forceUpdate = true;
            this.wysiwyg.wysiwygPreview.hardReload = true;
            item = gameItem;
        }.bind(this));
    }

    if (falseAlarm) {
        this.wysiwyg.wysiwygPreview.forceUpdate = false;
        this.wysiwyg.wysiwygPreview.hardReload = false;
    }

    this.trigger("itemPropertyChanged", item);
};

Hierarchy.prototype.getCountOfChildren = function (item) {
    var result = 0;
    item.children.forEach(function (child) {
        result++;
        result += this.getCountOfChildren(child);
    }.bind(this));
    return result;
};

Hierarchy.prototype.getSelectedClip = function () {
    var selected = this.selectedItem;
    if (!selected) {
        return undefined;
    }
    while (selected.parentItem) {
        selected = selected.parentItem;
    }

    return selected.type === HierarchyItem.TYPE.CLIP ? selected : undefined;
};

Hierarchy.prototype.getItemClip = function (item) {
    while (item && item.type !== HierarchyItem.TYPE.CLIP) {
        item = item.parentItem;
    }
    return item;
};

Hierarchy.prototype.getItemScene = function (item) {
    while (item && item.type !== HierarchyItem.TYPE.SCENE) {
        item = item.parentItem;
    }
    return item;
};

Hierarchy.prototype.getSceneSnapshot = function (scene) {
    var componentWithSnaphot = scene.children.filter(function (component) {
        return component.properties && component.properties.snapshot;
    })[0];

    return componentWithSnaphot && componentWithSnaphot.properties.snapshot;
};

Hierarchy.prototype.getSceneFlags = function (scene) {
    var componentWithFlags = scene.children.filter(function (component) {
        return component.properties && component.properties.flags;
    })[0];

    return componentWithFlags && componentWithFlags.properties.flags;
};

Hierarchy.prototype.getChildrenByFilter = function (item, filter) {
    var result = [];
    if (filter(item)) {
        result.push(item);
    }

    item.children.forEach(function (child) {
        result = result.concat(this.getChildrenByFilter(child, filter));
    }.bind(this));
    return result;
};

Hierarchy.prototype.getNewClipIndex = function () {
    var clipIndex = 0;
    var nameNoneUnique = true;
    do {
        clipIndex++;
        nameNoneUnique = this.items.filter(function (itemToCheck) {
            return itemToCheck.id === "clip_" + clipIndex;
        }).length > 0;
    } while (nameNoneUnique);
    return clipIndex;
};

Hierarchy.prototype.pasteItem = function (itemToPaste) {
    var newClip = undefined;
    var pasteItemInt = function (item, parent) {
        var newItem = item.clone(parent);
        if (item.type === HierarchyItem.TYPE.CLIP) {
            newClip = newItem;
        }
        this.addItem(newItem, true);
        item.children.forEach(function (child) {
            pasteItemInt(child, newItem);
        });
    }.bind(this);

    if (this.selectedItems.length) {
        this.selectedItems.forEach(function (item) {
            pasteItemInt(itemToPaste, item);
        });
    } else {
        var target = this.selectedItem;
        if (itemToPaste.type === HierarchyItem.TYPE.COMPONENT && itemToPaste.id === target.id) {
            target = target.parentItem;
        }
        pasteItemInt(itemToPaste, target);
    }

    var selectionId = this.selectedItem.id;
    this.save(newClip || this.getSelectedClip(), function () {
        if (newClip) {
            this.selectDirectory(selectionId);
        } else {
            this.loadClip(this.getSelectedClip().id);
        }
    }.bind(this));
};

Hierarchy.prototype.toggleMultiSelection = function () {
    this.multiSelection = !this.multiSelection;
};

Hierarchy.prototype.blockSelection = function (block) {
    this.selectionBlocked = block;
    this.trigger("showLoading", block);
};

Hierarchy.prototype.getClipPath = function (directory, name) {
    directory = directory.split("/").filter(Boolean).join("/");
    return GitManager.FOLDERS.WYSIWYG_SCENARIOS.replace("wysiwyg/", "") + directory + "/" + name + ".json";
};

Hierarchy.prototype.createFromTemplate = function () {
    var snapshot = cleverapps.loadedSnapshot.snapshotId;
    var actions = cleverapps.loadedSnapshot.actions;
    var flags = cleverapps.loadedSnapshot.flags;

    var directory = "wysiwyg/tmp/";

    this.loadDirectory(this.wysiwyg.directory, function () {
        var index = this.getNewClipIndex();
        var clipId = "clip_" + index;

        var randomString = "";
        ["d", "l", "l", "d", "d"].forEach(function (type) {
            var charset = type === "l" ? "abcdefghiklmnopqrstuvwxyz" : "0123456789";
            randomString += charset.charAt(Math.floor(Math.random() * charset.length));
        });

        var template = cc.loader.getRes(actions ? bundles.wysiwyg.jsons.clip_template : bundles.wysiwyg.jsons.static_clip_template);
        var content = JSON.stringify(template)
            .replaceAll("%%clipId%%", clipId)
            .replaceAll("%%randomId%%", randomString)
            .replaceAll("\"%%gameComponentId%%\"", "" + (index + 10000))
            .replaceAll("\"%%mapComponentId%%\"", "" + (index + 10001))
            .replaceAll("\"%%logoComponentId%%\"", "" + (index + 10002))
            .replaceAll("\"%%buttonComponentId%%\"", "" + (index + 10003))
            .replaceAll("\"%%flags%%\"", JSON.stringify(flags, undefined, 2))
            .replaceAll("%%snapshot%%", snapshot);

        if (actions) {
            content = content.replaceAll("\"%%actions%%\"", JSON.stringify(actions, undefined, 2));
        }

        cleverapps.git.edit("scenario", {
            file: this.getClipPath(directory, clipId),
            content: JSON.parse(content)
        }, function () {
            connector.platform.openUrl(cleverapps.config.staticUrl + "#wysiwyg=true&directory=wysiwyg/tmp&clipId=" + clipId);
        });
    }.bind(this));
};
