/**
 * Created by vladislav on 1/23/19
 */

var SharkCellView = BaseCellView.extend({
    ctor: function (sharkCell) {
        this._super();

        this.setContentSize(cleverapps.styles.BaseCellView.CELL_SIZE_PX, cleverapps.styles.BaseCellView.CELL_SIZE_PX);
        this.setAnchorPoint(0.5, 0.5);

        this.animation = new cleverapps.Spine(bundles.shark.jsons.shark_json);
        this.animation.setPosition(this.width / 2, this.height / 2);
        this.addChild(this.animation);
        this.initialize(sharkCell);
    },

    initialize: function (sharkCell) {
        this._super(sharkCell);

        sharkCell.onChangeLivesListener = this.changeLives.bind(this);
        sharkCell.onCreateNew = this.createNew.bind(this);
        sharkCell.onTakeMove = this.takeMove.bind(this);
        sharkCell.onDisappear = this.disappear.bind(this);

        this.animation.setAnimation(0, "idle_" + sharkCell.lives, true);
    },

    unuse: function () {
        this.cell.onChangeLivesListener = function () {};
        this.cell.onCreateNew = function () {};
        this.cell.onTakeMove = function () {};
        this.cell.onDisappear = function () {};

        this._super();
    },

    changeLives: function () {
        this.animation.setAnimation(0, "transition_" + this.cell.lives, false);
        this.animation.setCompleteListener(function () {
            if (this.cell.lives > 0) {
                this.animation.setAnimation(0, "idle_" + this.cell.lives, true);
            }
        }.bind(this));

        cleverapps.audio.playSound(cleverapps.styles.SharkCellView.stageDecreaseSound);
    },

    createNew: function () {
        this.setScale(0);
        this.runAction(
            new cc.Sequence(
                new cc.ScaleTo(0.1, 0.25).easing(cc.easeBackOut()),
                new cc.ScaleTo(0.1, 0.15).easing(cc.easeBackOut()),
                new cc.ScaleTo(0.3, 1.05).easing(cc.easeBackOut()),
                new cc.ScaleTo(0.1, 1).easing(cc.easeBackOut())
            )
        );
        cleverapps.audio.playSound(cleverapps.styles.SharkCellView.growUpSound);
    },

    takeMove: function (callback) {
        var callback2 = function () {
            this.animation.setAnimation(0, "explode", false);

            if (Game.currentGame) {
                Game.currentGame.shakeField({
                    delay: 200,
                    target: cleverapps.scenes.getRunningScene().movesView
                });
            }

            this.animation.setCompleteListener(function () {
                this.delete();
                callback();
                this.animation.setCompleteListener();
            }.bind(this));
        }.bind(this);

        this.setLocalZOrder(100);
        this.animation.setAnimation(0, "fly", true);

        this.runAction(new cc.Sequence(
            AnimationsLibrary.animateCollect(this, "moves", {
                scale: 1,
                collectEffect: true,
                sound: bundles.shark.urls.shark_poof_effect
            }),
            new cc.CallFunc(callback2)
        ));
    },

    disappear: function () {
        var animation = this.cell.lives > 1 ? "explode_idle_" + this.cell.lives : "explode";
        this.setLocalZOrder(11);
        this.animation.setAnimation(0, animation, false);
        cleverapps.audio.playSound(cleverapps.styles.SharkCellView.dieSound);
        if (Game.currentGame) {
            Game.currentGame.shakeField({ delay: 200 });
        }

        this.animation.setCompleteListener(function () {
            this.setLocalZOrder(this.baseZOrder);
            this.animation.setCompleteListener();
        }.bind(this));
    }
});

cleverapps.styles.SharkCellView = {
    stageDecreaseSound: bundles.shark.urls.shark_effect,
    growUpSound: bundles.game.urls.special_element_grow_up,
    dieSound: bundles.shark.urls.shark_effect
};