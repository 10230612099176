/**
 * Created by slava on 15/8/19
 */

var CrazyCombosLibrary = {
    multicolorSimple: function (multicolor, other) {
        multicolor.crazyActionSimple(other);
    },

    multicolorCombo: function (multicolor, other) {
        multicolor.crazyActionDuplicateCombos(other);
    },

    multicolorTwo: function (start, finish) {
        CrazyCombosHelper.merge([start, finish], function (center) {
            return new SuperMultiColorCell(center.x, center.y);
        }, function (superCell) {
            superCell.explode();
        });
    },

    planeTwo: function (start, finish) {
        CrazyCombosHelper.merge([start, finish], function (center) {
            var key = CombosLibrary.TYPE_PLANE + "_" + CombosLibrary.TYPE_PLANE;
            return new ComboCell(center.x, center.y, {
                algo: Object.assign({}, CombosLibrary.ByShape[Shape.PLANE], CombosLibrary.powerUp[key])
            });
        }, function (superCell) {
            start.skipAlgoEffect = true;
            finish.skipAlgoEffect = true;

            superCell.explode();
        });
    },

    planeLiner: function (start, finish) {
        var cargo;

        [start, finish].forEach(function (cell) {
            if (cell.algo.name === CombosLibrary.TYPE_LINER) {
                cargo = cell.algo;
            }
        });

        CrazyCombosHelper.merge([start, finish], function (center) {
            var key = CombosLibrary.TYPE_PLANE + "_" + CombosLibrary.TYPE_LINER;
            return new ComboCell(center.x, center.y, {
                algo: Object.assign({ cargo: cargo }, CombosLibrary.ByShape[Shape.PLANE], CombosLibrary.powerUp[key])
            });
        }, function (superCell) {
            start.skipAlgoEffect = true;
            finish.skipAlgoEffect = true;

            superCell.explode();
        });
    },

    linerTwo: function (start, finish) {
        CrazyCombosHelper.merge([start, finish], function (center) {
            return new SuperMultiColorCell(center.x, center.y);
        }, function (superCell) {
            superCell.explode();
        });
    },

    comboCombo: function (start, finish) {
        if (Match3Rules.Heroes) {
            if (Game.currentGame && !Game.currentGame.field.isThreeInRow(start.y, start.x)) {
                start.mustHurtNextBurn = true;
            }

            if (Game.currentGame && !Game.currentGame.field.isThreeInRow(finish.y, finish.x)) {
                finish.mustHurtNextBurn = true;
            }

            return;
        }

        if (start.algo.name === CombosLibrary.TYPE_PLANE && finish.algo.name === CombosLibrary.TYPE_PLANE) {
            CrazyCombosLibrary.planeTwo(start, finish);
        } else if ([start.algo.name, finish.algo.name].indexOf(CombosLibrary.TYPE_PLANE) !== -1) {
            CrazyCombosLibrary.planeLiner(start, finish);
        } else {
            CrazyCombosLibrary.linerTwo(start, finish);
        }
    },

    colorCombo: function (color, combo) {
        if (Match3Rules.Heroes) {
            return;
        }

        combo.mustHurtNextBurn = true;
    }
};

var CrazyCombosHelper = {
    merge: function (cells, mergeConstructor, callback) {
        var shape = new Shape(cells);

        var duration = this.getMergeDuration();

        var center = shape.getCenter();
        shape.merge({
            target: center,
            duration: duration
        });

        var superCell = mergeConstructor(center);

        Game.currentGame.counter.setTimeout(function () {
            cells.forEach(function (cell) {
                cell.delete();
            });

            Game.currentGame.field.addCell(superCell);
            superCell.animate(BaseCell.ANIMATION_CREATE);

            Game.currentGame.counter.setTimeout(function () {
                callback(superCell);
            }, superCell.beforeExplodeTimeout() * 1000);
        }, duration * 1000);
    },

    getMergeDuration: function () {
        return 0.2;
    }
};

var CrazyCombos = function (start, finish) {
    if (start instanceof MultiColorCell || finish instanceof MultiColorCell) {
        if (start instanceof MultiColorCell && finish instanceof MultiColorCell) {
            CrazyCombosLibrary.multicolorTwo(start, finish);
        } else {
            var multicolor = start;
            var other = finish;

            if (finish instanceof MultiColorCell) {
                multicolor = finish;
                other = start;
            }

            if (other.findComponent(ComboComponent)) {
                CrazyCombosLibrary.multicolorCombo(multicolor, other);
            } else {
                CrazyCombosLibrary.multicolorSimple(multicolor, other);
            }
        }
    } else if (start.findComponent(ComboComponent) && finish.findComponent(ComboComponent)) {
        CrazyCombosLibrary.comboCombo(start, finish);
    } else if (start.findComponent(ComboComponent) && finish.findComponent(ColorComponent)) {
        CrazyCombosLibrary.colorCombo(finish, start);
    } else if (finish.findComponent(ComboComponent) && start.findComponent(ColorComponent)) {
        CrazyCombosLibrary.colorCombo(start, finish);
    }
};