/**
 * Created by mac on 9/25/20
 */

var LevelVersions = function () {

};

LevelVersions.prototype.reset = function () {

};