/**
 * Created by vladislav on 25.02.2021
 */

var AdsCounter = cc.Node.extend({
    ctor: function (properties, eventBus) {
        this._super();

        this.setAnchorPoint(0.5, 0.5);

        this.properties = properties;

        var initialValue = properties.initialValue || 0;
        this.text = cleverapps.UI.generateImageText(
            initialValue,
            properties.font ? Wysiwyg.getValueForResolution(properties.font) : cleverapps.styles.FONTS.BUTTON_TEXT
        );
        this.addChild(this.text);
        this.setContentSize2(this.text.getContentSize());
        this.text.setPositionRound(this.width / 2, this.height / 2);

        if ((properties.listensEvents || []).indexOf(Wysiwyg.EVENTS.PROGRESS_CHANGED) !== -1) {
            eventBus.on(Wysiwyg.EVENTS.PROGRESS_CHANGED, this.incProgress.bind(this), this);
        }
    },

    incProgress: function () {
        this.text.countToAnimation(0.4, +this.text.getString() + this.properties.step);

        this.text.runAction(
            new cc.Sequence(
                new cc.ScaleTo(0.3, 1.2),
                new cc.ScaleTo(0.1, 1.0)
            )
        );
    }
});

AdsCounter.PROPERTIES = [
    {
        name: "font", type: "font", value: cleverapps.styles.FONTS.WHITE_TEXT
    },
    {
        name: "initialValue", type: "number", value: 0
    },
    {
        name: "step", type: "number", value: 50
    },
    {
        name: "listensEvents", type: "string", items: Object.values(Wysiwyg.EVENTS), multiselect: true, value: [Wysiwyg.EVENTS.PROGRESS_CHANGED]
    }
];