/**
 * Created by andrey on 15.05.17.
 */

levels.LevelAttempts = function () {
    var save = cleverapps.dataLoader.load(DataLoader.TYPES.LEVEL_ATTEMPTS);
    this.attempts = save || {};
};

levels.LevelAttempts.prototype.addAttempt = function (level) {
    if (!level.isCurrentLevel()) {
        return;
    }
    var levelId = level.getHumanReadableNumber();
    if (this.attempts[levelId] === undefined) {
        this.attempts[levelId] = 0;
    }
    this.attempts[levelId]++;
    this.save();
};

levels.LevelAttempts.prototype.amountAttemptsOnLastLevels = function (amountLevels) {
    var data = [];
    for (var levelId in this.attempts) {
        data.push({
            levelId: levelId,
            attempts: this.attempts[levelId]
        });
    }

    if (amountLevels > data.length) {
        return false;
    }

    data.sort(function (a, b) {
        return b.levelId - a.levelId;
    });
    var res = 0;
    for (var i = 0; i < amountLevels; i++) {
        res += data[i].attempts;
    }
    return res;
};

levels.LevelAttempts.prototype.getAttempts = function () {
    var levelId = levels.user.getProgress() + 1;
    return this.attempts[levelId];
};

levels.LevelAttempts.prototype.save = function () {
    var minLevelId = 1000000000, amountAttempts = 0;
    for (var levelId in this.attempts) {
        if (parseInt(levelId) < minLevelId) {
            minLevelId = parseInt(levelId);
        }
        amountAttempts++;
    }
    if (amountAttempts > levels.LevelAttempts.MAX_HISTORY) {
        delete this.attempts[minLevelId];
    }
    cleverapps.dataLoader.save(DataLoader.TYPES.LEVEL_ATTEMPTS, this.attempts);
};

levels.LevelAttempts.MAX_HISTORY = 10;