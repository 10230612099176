/**
 * Created by slava on 02.02.17.
 */

var ColorCellView = BaseCellView.extend({
    ctor: function (colorCell) {
        this._super();

        this.setContentSize(cleverapps.styles.BaseCellView.CELL_SIZE_PX, cleverapps.styles.BaseCellView.CELL_SIZE_PX);
        this.setAnchorPoint(0.5, 0.5);

        this.initialize(colorCell);
    },

    initialize: function (colorCell) {
        this._super(colorCell);

        colorCell.onBeforeExplodeMouseListener = this.startExplodingViaMouse.bind(this);
        colorCell.onGameFinishListener = this.onGameFinish.bind(this);
    },

    unuse: function () {
        this.cell.onBeforeExplodeMouseListener = function () {};
        this.cell.onGameFinishListener = function () {};

        this._super();
    }
});

// one color - one pool
// eslint-disable-next-line camelcase
var ColorCellView_a = ColorCellView.extend({});
// eslint-disable-next-line camelcase
var ColorCellView_b = ColorCellView.extend({});
// eslint-disable-next-line camelcase
var ColorCellView_c = ColorCellView.extend({});
// eslint-disable-next-line camelcase
var ColorCellView_d = ColorCellView.extend({});
// eslint-disable-next-line camelcase
var ColorCellView_e = ColorCellView.extend({});
// eslint-disable-next-line camelcase
var ColorCellView_w = ColorCellView.extend({});