/**
 * Created by andrey on 24.08.17.
 */

var NutCellView = BaseCellView.extend({
    ctor: function (nutCell) {
        this._super();

        this.setContentSize(cleverapps.styles.BaseCellView.CELL_SIZE_PX, cleverapps.styles.BaseCellView.CELL_SIZE_PX);
        this.setAnchorPoint(0.5, 0.5);

        var image = new cc.Sprite(this.getImage(nutCell));
        this.image = image;
        this.image.setPosition(this.width / 2, this.height / 2);
        this.addChild(image);

        this.initialize(nutCell);
    },

    initialize: function (nutCell) {
        this._super(nutCell);

        nutCell.onChangeLivesListener = this.changeLives.bind(this);
    },

    getImage: function (nutCell) {
        var bundle = nutCell.bundleId();
        return bundles[bundle].frames[bundle + nutCell.lives];
    },

    changeLives: function () {
        this.image.setSpriteFrame(this.getImage(this.cell));
    },

    unuse: function () {
        this.cell.onChangeLivesListener = function () {};

        this._super();
    },

    smile: function () {
        if (this.smiling) {
            return;
        }

        var bundle = this.cell.bundleId();
        this.smiling = BaseAnimation.factoryLimited(bundles[bundle].jsons[bundle + "_spine_json"]);
        this.smiling.setPosition(this.width / 2, this.height / 2);
        this.addChild(this.smiling);
        this.smiling.runAnimate("smile", this.finishSmile.bind(this), this.image);
    },

    finishSmile: function () {
        if (this.smiling) {
            this.image.visible = true;
            this.smiling.finishAnimation();

            this.smiling = false;
        }
    },

    animateExploding: function () {
        this.setLocalZOrder(3);
        this.stopAllActions();

        this.finishSmile();

        var name = NutCellView.animations[this.cell.lives];
        var bundle = this.cell.bundleId();
        var animation = BaseAnimation.factoryLimited(bundles[bundle].jsons[bundle + "_spine_json"]);
        animation.setPosition(this.width / 2, this.height / 2);
        this.addChild(animation);
        animation.runAnimate(name, function () {
            this.delete();
        }.bind(this), this.image);

        cleverapps.audio.playSound(bundles.game.urls.nut_effect);
    },

    startExploding: function () {
        if (Game.currentGame.goals.hasType(this.cell.getGoalId())) {
            BaseCellView.prototype.startExplodingViaCollect.apply(this, arguments);
        } else {
            this.animateExploding.apply(this, arguments);
        }
    }
});

NutCellView.animations = {
    1: "animation_2_1",
    0: "animation_1_0"
};