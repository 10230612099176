/**
 * Created by vladislav on 3/18/19
 */

var CannonCellView = BaseCellView.extend({
    ctor: function (cannonCell) {
        this._super();

        this.setContentSize(cleverapps.styles.BaseCellView.CELL_SIZE_PX, cleverapps.styles.BaseCellView.CELL_SIZE_PX);
        this.setAnchorPoint(0.5, 0.5);

        this.initialize(cannonCell);
    },

    initialize: function (cannonCell) {
        this._super(cannonCell);

        var styles = this.styles().text; 
    
        this.static = new cleverapps.Spine(bundles.cannon.jsons.json);
        this.static.setAnimation(0, "gun_static", false);
        this.animationsNode = new cc.Node();
        this.animationsNode.setContentSize2(cleverapps.styles.BaseCellView.CELL_SIZE_PX, cleverapps.styles.BaseCellView.CELL_SIZE_PX);
        this.animationsNode.addChild(this.static);
        this.animationsNode.setLocalZOrder(0);
        this.animationsNode.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(this.animationsNode);
        
        this.shot = new cleverapps.Spine(bundles.cannon.jsons.json);
        this.animationsNode.addChild(this.shot);

        this.shot.setStartVisibleListener(function () {
            this.static.visible = false;
        }.bind(this));
    
        this.angle = CannonCellView.ROTATION_BY_DIR[cannonCell.currDir];
        this.animationsNode.setRotation(-this.angle);
  
        this.livesText = new cleverapps.UI.ImageFont("" + cannonCell.lives, cleverapps.styles.FONTS.INGAME_COMPACT_TEXT);
        this.livesText.setScale(styles.scale);
        this.livesText.setLocalZOrder(1);
        this.addChild(this.livesText);
        this.livesText.setPositionRound(this.width / 2 + styles.x, this.height / 2 + styles.y);

        this.cell.onChangeLivesListener = this.changeLives.bind(this);
        this.cell.onTurnListener = this.turn.bind(this);
        this.cell.onFireListener = this.fire.bind(this);
        this.cell.view = this;
    },

    changeLives: function () {
        this.updateLivesText();
    },
    
    fire: function (cellsToFlyCount) {
        this.shot.setAnimation(0, "gun_action", false);
        this.animationsNode.runAction(new cc.Sequence(
            new cc.DelayTime(0.12),
            new cc.CallFunc(function () {
                SmallPetardCellView.explosionWavesAnimation.call(this, {
                    image: bundles.cannon.frames.cannonBullet,
                    directions: [BaseCell.DIRECTIONS[CannonCellView.ROTATION_BY_DIR.indexOf(this.angle)]],
                    bulletFly: true,
                    timeFlyOneCellSec: CannonCell.timeFlyOneCellSec,
                    cellsToFlyCount: cellsToFlyCount === 0 ? cellsToFlyCount : cellsToFlyCount - 1
                });
            }.bind(this))
        ));
        this.shot.setCompleteListener(function () {
            this.shot.setVisible(false);
            this.static.setVisible(true);
        }.bind(this));
    },
    
    turn: function () {
        var curr = this.angle;
        var target = CannonCellView.ROTATION_BY_DIR[this.cell.currDir];

        var rot = -((target - curr + 360) % 360);
        if (!this.cell.clockwise) { // counter-clockwise
            rot = (360 + rot) % 360;
        }
        
        if (this.animationsNode) {
            this.animationsNode.runAction(new cc.RotateBy(0.5, rot));
        }
    
        this.angle = target;
    },
    
    updateLivesText: function () {
        this.livesText.setString("" + this.cell.lives);
    },

    styles: function () {
        return cleverapps.styles.CannonCellView;
    }
});

// Must be corresponded to BaseCell.DIRECTIONS order
CannonCellView.ROTATION_BY_DIR = [
    0, 45, 90, 135, 180, -135, -90, -45
];

cleverapps.styles.CannonCellView = {
    text: {
        scale: 1.0,
        x: 0,
        y: 0
    }
};