/**
 * Created by Vladislav on 04.09.2024.
 */

RewardTypes.stickers = {
    flyingAnimation: Reward.FULL_SCREEN_CUSTOM_ANIMATION,
    viewClass: StickersCollectComponent,

    getIcon: function () {
        return bundles.reward_icons.frames.stickers_png;
    },

    getCustomRewardText: function (value) {
        return "x" + value;
    },

    getCustomRewardIcon: function () {
        return bundles.reward_icons.frames.stickers_png;
    },

    bundle: function () {
        return cleverapps.unique(["stickers_reward_window"].concat(
            this.stickers.map(function (sticker) {
                return "collection_" + sticker.collection.index;
            })
        ));
    },

    handler: function (value, options) {
        options = options || {};
        var source;

        if (options.event === cleverapps.EVENTS.EARN.LEVEL_REWARD) {
            source = Sticker.SOURCES.level;
        } else if (options.event && options.event.indexOf("stickerspack") !== -1) {
            source = Sticker.SOURCES.buy;
        }

        this.stickers = cleverapps.stickersBook.givePrize(value, source);

        return function () {};
    }
};