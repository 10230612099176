/**
 * Created by Ivan on 13.04.2024
 */

var ConversionManager = function () {
    this.load();
};

ConversionManager.prototype.load = function () {
    this.data = cleverapps.dataLoader.load(DataLoader.TYPES.MULTIPLE_CONVERSIONS_INFO) || {};
};

ConversionManager.prototype.save = function () {
    cleverapps.dataLoader.save(DataLoader.TYPES.MULTIPLE_CONVERSIONS_INFO, this.data);
};

ConversionManager.prototype.processConversion = function (conversion, amount) {
    if (Array.isArray(conversion)) {
        conversion.forEach(function (one) {
            this.processConversion(one, amount);
        }, this);
        return;
    }

    if (cleverapps.user.getDaysSinceRegistration() >= conversion.conversion_window) {
        return;
    }

    var day = "d" + conversion.conversion_window;

    if (conversion.reach && conversion.reach.includes(amount)) {
        this.logReachEvent(conversion, day + "r_" + conversion.name + "_" + amount);

        if (conversion.old_name) {
            this.logReachEvent(conversion, conversion.old_name + "_" + amount);
        }
    }

    if (conversion.accumulate) {
        this.logReachEvent(conversion, day + "a_" + conversion.name + "#");
        this.logAccumulateEvent(conversion, day + "a_" + conversion.name, conversion.accumulate === "count" ? 1 : amount);

        if (conversion.old_name) {
            this.logAccumulateEvent(conversion, conversion.old_name, conversion.accumulate === "count" ? 1 : amount);
        }
    }
};

ConversionManager.prototype.logReachEvent = function (conversion, event) {
    if (!this.data[event]) {
        this.data[event] = 1;
        this.save();

        var isOld = conversion.old_name && event.startsWith(conversion.old_name);

        cleverapps.eventLogger.logEvent(event, {
            event: cleverapps.user.registered,
            channel: cleverapps.user.channel,
            value: 1,
            conversion: !isOld
        });
    }
};

ConversionManager.prototype.logAccumulateEvent = function (conversion, event, amount) {
    var isOld = conversion.old_name && event.startsWith(conversion.old_name);

    cleverapps.eventLogger.logEvent(event, {
        event: cleverapps.user.registered,
        channel: cleverapps.user.channel,
        value: amount,
        conversion: !isOld
    });
};

ConversionManager.EVENTS = Object.values(cleverapps.EVENTS.CONVERSIONS).reduce(function (events, conversions) {
    cleverapps.toArray(conversions).forEach(function (conversion) {
        var day = "d" + conversion.conversion_window;

        if (conversion.reach) {
            conversion.reach.forEach(function (amount) {
                events[day + "r_" + conversion.name + "_" + amount] = true;

                if (conversion.old_name && conversion.old_name !== "retention") {
                    events[conversion.old_name + "_" + amount] = true;
                }
            });
        }

        if (conversion.accumulate) {
            events[day + "a_" + conversion.name] = true;

            if (conversion.old_name && conversion.old_name !== "retention") {
                events[conversion.old_name] = true;
            }
        }
    });

    return events;
}, {});
