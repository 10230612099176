/**
 * Created by Reda on 19.08.2024
 */
var MenuBarTextView = cc.Node.extend({
    ctor: function (item, options) {
        this.options = options || {};
        this._super();
        this.item = item;
        this.baseScale = 1;
        this.lastValue = item.getCurrentValue();
        this.withTotal = this.lastValue.total !== undefined;

        this.amountText = cleverapps.UI.generateImageText("", cleverapps.styles.FONTS.MENUBAR_TEXT);

        if (this.withTotal) {
            this.totalText = cleverapps.UI.generateImageText("", cleverapps.styles.FONTS.MENUBAR_TEXT);

            this.totalLayout = this.createTotalLayout();
            this.addChild(this.totalLayout);
        } else {
            this.addChild(this.amountText);
        }

        this.setString(this.getString());
    },

    createTotalLayout: function () {
        var separateText = cleverapps.UI.generateImageText(" / ", cleverapps.styles.FONTS.MENUBAR_TEXT);

        return new cleverapps.Layout([this.amountText, separateText, this.totalText], {
            direction: cleverapps.UI.HORIZONTAL
        });
    },

    setString: function (string) {
        string = String(string);

        this.updateTotalIcon();

        if (this.item.totalIcon) {
            return;
        }

        if (this.withTotal) {
            var parts = string.split("/");

            var amount = +parts[0].trim();
            var total = +parts[1].trim();

            this.updateAmountText(amount, total);
            this.updateTotalText(total);

            this.totalLayout.reshape();
            this.totalLayout.setPositionRound(this.width / 2, this.height / 2);
        } else {
            this.updateAmountText(string);
            this.amountText.setPositionRound(this.width / 2, this.height / 2);
        }

        this._applyFitTo();
    },

    updateTotalIcon: function () {
        var styles = cleverapps.styles.MenuBarTextView;

        var visible = !!this.item.totalIcon;

        if (visible) {
            if (!this.totalIcon) {
                this.totalIcon = new cc.Sprite(this.item.totalIcon);
                this.totalIcon.setPositionRound(styles.totalIcon);
                this.addChild(this.totalIcon);
            }
        } else if (this.totalIcon) {
            this.totalIcon.removeFromParent();
            delete this.totalIcon;
        }

        if (this.withTotal) {
            this.totalLayout.visible = !visible;
        } else {
            this.amountText.visible = !visible;
        }
    },

    updateAmountText: function (amount, total) {
        this.amountText.setString(amount);

        if (this.withTotal && !cleverapps.styles.MenuBarTextView.noColoredText) {
            if (amount >= total) {
                this.amountText.setColor(cleverapps.styles.COLORS.GREEN);
            } else if (amount < total * 0.1) {
                this.amountText.setColor(cleverapps.styles.COLORS.COLOR_RED);
            } else {
                this.amountText.setColor(this.amountText.font.color || cleverapps.styles.COLORS.WHITE);
            }
        }
    },

    updateTotalText: function (total) {
        this.totalText.setString(total);

        if (this.item.highlightTotal && this.item.highlightTotal()) {
            this.totalText.setColor(cleverapps.styles.COLORS.COLOR_BONUS);
        } else {
            this.totalText.setColor(this.amountText.font.color || cleverapps.styles.COLORS.WHITE);
        }
    },

    valueToString: function (value) {
        if (this.withTotal) {
            return value.amount + " / " + value.total;
        }
        return "" + value.amount;
    },

    getString: function () {
        return this.valueToString(this.lastValue);
    },

    animateAmountChange: function (amount, duration) {
        if (this.changeTextAction && !this.changeTextAction.isDone()) {
            this.stopAction(this.changeTextAction);
        }

        if (this.item.deltaSound) {
            cleverapps.audio.playSound(this.item.deltaSound);
        }

        duration = amount - this.lastValue.amount < 0 ? 0.3 : duration;
        this.changeTextAction = this.runAction(
            new cc.Spawn(
                AnimationsLibrary.countTo(this, amount, { duration: duration }),
                AnimationsLibrary.pulse(this, { duration: duration })
            )
        );
    },

    updateValue: function (duration) {
        var currentValue = this.item.getCurrentValue();
        var amountDelta = currentValue.amount - this.lastValue.amount;
        var totalChanged = this.lastValue.total !== undefined && this.lastValue.total !== currentValue.total;

        var time = currentValue.time - (this.lastValue.time === undefined ? Date.now() : this.lastValue.time);
        if (time) {
            time = time > 0 ? "+" + Product.FormatTimePeriod(time).title : Product.FormatTimePeriod(time).title;
        }
        var delta = time || amountDelta;

        if (amountDelta && !totalChanged) {
            this.animateAmountChange(currentValue.amount, duration);
        } else {
            this.setString(this.valueToString(currentValue));
        }
        this.lastValue = currentValue;
        return delta;
    },

    _applyFitTo: function () {
        if (!this.maxWidth) {
            return;
        }

        if (this.withTotal) {
            this.totalLayout.scale = Math.min(this.maxWidth / this.totalLayout.width, 1);
        } else {
            this.amountText.fitTo(this.maxWidth);
        }
    },

    fitTo: function (maxWidth) {
        this.maxWidth = maxWidth;

        this._applyFitTo();
    }
});

cleverapps.styles.MenuBarTextView = {
    totalIcon: {
        x: { align: "center", dx: -15 },
        y: { align: "center" }
    }
};