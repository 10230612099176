/**
 * Created by andrey on 16.03.18.
 */

var MuffinGoalCell = function (x, y) {
    BaseCell.call(this, x, y);

    this.addComponent(MuffinComponent);
};

MuffinGoalCell.prototype = Object.create(BaseCell.prototype);
MuffinGoalCell.prototype.constructor = MuffinGoalCell;

MuffinGoalCell.prototype.save = function () {
    return MuffinGoalCell.CODES.concat([this.lives]);
};

MuffinGoalCell.prototype.getViewClass = function () {
    return MuffinGoalCellView;
};

MuffinGoalCell.prototype.getGoalId = function () {
    return MuffinGoalCell.GOAL_ID;
};

MuffinGoalCell.prototype.explode = function () {
    BaseCell.prototype.explodeViaCollect.call(this);
};

MuffinGoalCell.prototype.smile = function () {
    var muffinComponent = this.findComponent(MuffinComponent);
    muffinComponent.smile();
};

MuffinGoalCell.GOAL_ID = "muffin_goal_cell";