/**
 * https://developers.facebook.com/docs/meta-pixel/implementation/conversion-tracking/
 *
 * Created by Andrey Popov on 1/17/22.
 */

var FacebookPixelLogger = function () {
    !(function (f, b, e, v, n, t, s) {
        if (f.fbq) {
            return;
        }
        n = f.fbq = function () {
            n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };
        if (!f._fbq) {
            f._fbq = n;
        }
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
    }(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js"));

    fbq("init", cleverapps.config.facebookPixel.id);
    fbq("track", "PageView");
};

FacebookPixelLogger.prototype.logEvent = function (name, params) {
    if (!ConversionManager.EVENTS[name]) {
        return;
    }

    console.log("facebook pixel", name, params);

    fbq("trackCustom", name, params);
};

FacebookPixelLogger.prototype.logPurchase = function (product, purchase, params) {
    fbq("track", "Purchase", {
        currency: params.currency,
        value: params.price
    });
};

FacebookPixelLogger.isApplicable = function () {
    return connector.platform.oneOf(connector.CLEVERAPPS) && cleverapps.config.facebookPixel;
};
