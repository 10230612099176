/**
 * Created by andrey on 28.03.2024.
 */

var AdOceanLogger = function () {
    this.queue = [];

    this.clueToken = cleverapps.dataLoader.load(DataLoader.TYPES.AD_OCEAN_TOKEN);
};

AdOceanLogger.prototype.requestClueToken = function () {
    if (this.clueToken) {
        return;
    }

    var clientToken = wx.getLaunchOptionsSync().query.clue_token || -1;

    var path = "/adocean/cluetoken/" + encodeURIComponent(connector.platform.getUserID()) + "/" + encodeURIComponent(clientToken);

    cleverapps.RestClient.get(path, {}, function (response) {
        if (response.errmsg || response.errcode) {
            this._logError("AdOcean.requestClueToken error: " + (response.errmsg || response.errcode));

            return;
        }

        var clueToken = response.clueToken;

        console.log("AdOcean.requestClueToken success token: " + clueToken + ", status: " + AdOceanLogger.STATUS[response.status]);

        if (clueToken) {
            this.setClueToken(clueToken);
        }
    }.bind(this), function (response) {
        this._logError("AdOcean.requestClueToken fail clientToken: " + clientToken + ", response: " + JSON.stringify(response));
    }.bind(this));
};

AdOceanLogger.prototype.setClueToken = function (clueToken) {
    this.clueToken = clueToken;

    cleverapps.dataLoader.save(DataLoader.TYPES.AD_OCEAN_TOKEN, clueToken);

    for (var i = 0; i < this.queue.length; i++) {
        this.sendEvent(this.queue[i].type, this.queue[i].data);
    }
    this.queue = [];
};

AdOceanLogger.prototype.onUpdateUserId = function () {
    this.requestClueToken();
};

AdOceanLogger.prototype.logEvent = function (name, params) {
    var type = AdOceanLogger.EVENTS_MAP[name];

    if (!type) {
        return;
    }

    this.sendEvent(type, params);
};

AdOceanLogger.prototype.sendEvent = function (type, data) {
    if (!this.clueToken) {
        if (this.queue.length < AdOceanLogger.QUEUE_LIMIT) {
            this.queue.push({
                type: type,
                data: data
            });
        }

        return;
    }

    var path = "/adocean/event/" + encodeURIComponent(connector.platform.getUserID()) + "/" + encodeURIComponent(this.clueToken) + "/" + type;

    var props = {};
    if (type === AdOceanLogger.EVENT_ACTIVE_PAY) {
        props.pay_amount = Math.floor(data.price * 100);
    }

    cleverapps.RestClient.post(path, { props: props }, function (response) {
        if (response.errmsg || response.errcode) {
            this._logError("AdOcean.reportEvent error: " + (response.errmsg || response.errcode) + ", clueToken: " + this.clueToken);

            return;
        }

        console.log("AdOcean.reportEvent success, type: " + type + ", data: " + JSON.stringify(data) + ", clueToken: " + this.clueToken);
    }.bind(this), function (response) {
        this._logError("AdOcean.reportEvent fail clueToken: " + this.clueToken + ", response: " + JSON.stringify(response));
    }.bind(this));
};

AdOceanLogger.prototype.logPurchase = function (product, purchase, params) {
    this.sendEvent(AdOceanLogger.EVENT_ACTIVE_PAY, {
        price: params.price
    });
};

AdOceanLogger.isApplicable = function () {
    return false;

    // return connector.platform.oneOf(connector.WECHAT);
};

AdOceanLogger.prototype._logError = function (message) {
    console.log(message);

    if (cleverapps.config.debugMode) {
        cleverapps.notification.create(message);
    }
};

AdOceanLogger.STATUS = {};
AdOceanLogger.STATUS[0] = "no clue token";
AdOceanLogger.STATUS[1] = "found saved token";
AdOceanLogger.STATUS[2] = "token activated";

AdOceanLogger.EVENT_REGISTRATION = 1;
AdOceanLogger.EVENT_ACTIVE_PAY = 2;
AdOceanLogger.EVENT_NEXT_DAY_OPEN = 2;

AdOceanLogger.EVENTS_MAP = {};
AdOceanLogger.EVENTS_MAP[cleverapps.EVENTS.GENERAL.TUTORIAL_COMPLETE] = AdOceanLogger.EVENT_REGISTRATION;
AdOceanLogger.EVENTS_MAP[cleverapps.EVENTS.STATS.PAYMENTS] = AdOceanLogger.EVENT_ACTIVE_PAY;
AdOceanLogger.EVENTS_MAP[cleverapps.EVENTS.STATS.RETENTION_1] = AdOceanLogger.EVENT_NEXT_DAY_OPEN;

AdOceanLogger.QUEUE_LIMIT = 100;
