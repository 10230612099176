/**
 * Created by Andrey Popov on 27.01.2021
 */

var AdsScale9Sprite = cc.Node.extend({
    ctor: function (properties) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);

        properties = properties || {};

        if (cleverapps.wysiwyg.googleDriveProvider.hasAsset(properties.image)) {
            var imagePath = cleverapps.wysiwyg.googleDriveProvider.getLink(properties.image);
            cc.loader.load(imagePath, function () {
                var sprite = cleverapps.UI.createScale9Sprite(new cc.Sprite(imagePath).getSpriteFrame(), cleverapps.UI.Scale9Rect.TwoPixelXY);
                sprite.setContentSize(properties.width || sprite.width, properties.height || sprite.height);
                this.addChild(sprite);

                this.setContentSize(sprite.getContentSize());
                sprite.setPositionRound(this.width / 2, this.height / 2);

                if (properties.flipX) {
                    sprite.setScaleX(-1);
                }
                if (properties.flipY) {
                    sprite.setScaleY(-1);
                }
                properties.rotation && sprite.setRotation(properties.rotation);

                this.parent && this.parent.onComponentSizeChanged();
            }.bind(this));
        }
    }
});

AdsScale9Sprite.PROPERTIES = [{
    name: "image", type: "image", folders: ["scale9"], bubbleUp: true, value: { folder: "scale9", name: "autumn_title.png" }
}, {
    name: "height", type: "number", value: 10, allowOverride: true
},
{
    name: "width", type: "number", value: 20, allowOverride: true
}, {
    name: "flipX", type: "checkBox"
}, {
    name: "flipY", type: "checkBox"
}];