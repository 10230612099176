/**
 * Created by vlad on 26.12.18.
 */

var ColorContainerCell = function (x, y) {
    BaseCell.call(this, x, y);
    this.lives = 2;
    this.movable = true;
    
    this.putInPoolAvailable = true;

    this.onSetColor = function () {};
};

ColorContainerCell.prototype = Object.create(BaseCell.prototype);
ColorContainerCell.prototype.constructor = ColorContainerCell;
Skinify(ColorContainerCell, "color_container");

ColorContainerCell.prototype.boom = function (coef, cell) {
    var color = cell && cell.getColor() || this.getRandomColor();

    if (!this.boomColor) {
        this.boomColor = color;
        this.onSetColor(this.boomColor);
        this.hurt();
    } else if (this.boomColor === color) {
        this.hurt();
    }
};

ColorContainerCell.prototype.hurt = function (explodeParams) {
    if (!this.boomColor) {
        this.boomColor = this.getRandomColor();
        this.onSetColor(this.boomColor);
    }
    BaseCell.prototype.hurt.call(this, explodeParams);
};

ColorContainerCell.prototype.explode = function (params) {
    this.explodeViaCollect(params);
};

ColorContainerCell.prototype.getViewClass = function () {
    return ColorContainerCellView;
};

ColorContainerCell.prototype.getGoalId = function () {
    return ColorContainerCell.GOAL_ID;
};

ColorContainerCell.prototype.hurtDuration = function () {
    return 0.7;
};

ColorContainerCell.prototype.load = function (data) {
    if (data.length === 3) {
        this.boomColor = data[1];
        this.lives = parseInt(data[2]);
    }
};

ColorContainerCell.prototype.save = function () {
    if (this.boomColor) {
        return ColorContainerCell.CODES.concat([this.boomColor, this.lives]);
    }
    return ColorContainerCell.CODES;
};

ColorContainerCell.GOAL_ID = "color_container";