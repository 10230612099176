/**
 * Created by vladislav on 3/4/19
 */

var ClockCell = function (x, y) {
    BaseCell.call(this, x, y);

    var bigComponent = this.bigComponent = this.addComponent(BigComponent, { cellClass: ClockCell });

    this.hurtable = true;

    if (!bigComponent.isHead) {
        return;
    }

    this.colors = ["a", "b", "c", "d", "e", "w"];
    this.lives = this.colors.length;
};

ClockCell.prototype = Object.create(BaseCell.prototype);
ClockCell.prototype.constructor = ClockCell;

ClockCell.prototype.bundleId = function () {
    return "clock";
};

ClockCell.prototype.getViewClass = function () {
    return ClockCellView;
};

ClockCell.prototype.boom = function (coef, cell) {
    this.bigComponent.head.hurt(undefined, cell);
};

ClockCell.prototype.hurt = function (explodeParams, cell) {
    if (!this.bigComponent.isHead) {
        this.bigComponent.head.hurt(explodeParams, cell);
        return;
    }

    if (this.lives > 0) {
        var color = cell && cell.getColor();

        if (color && this.colors[0] !== color) {
            return;
        }

        this.colors.shift();

        this.lives--;

        this.onChangeLivesListener();
        Game.currentGame.counter.setTimeout(function () {
            if (this.lives < 1) {
                this.bigComponent.head.explode();
            }
        }.bind(this), this.hurtDuration() * 1000);
    }
};

ClockCell.prototype.onHeadExplode = function () {
    BaseCell.prototype._explode.call(this);
};

ClockCell.prototype.hurtDuration = function () {
    return 1.0;
};

ClockCell.prototype.getMaxLives = function () {
    return 8;
};

ClockCell.prototype.editorComponents = function () {
    if (this.bigComponent.isHead) {
        return [BaseCellComponent, BigCellComponent, ClockCellComponentCreator];
    }
    return [BaseCellComponent];
};

ClockCell.prototype.load = function (data) {
    if (!this.bigComponent.isHead) {
        return;
    }
    this.colors = data.slice(1, data.length);
    this.lives = this.maxLives = this.colors.length;
};

ClockCell.prototype.save = function () {
    if (this.bigComponent.isHead) {
        return ClockCell.CODES.concat(Object.values(this.colors));
    }
    return ClockCell.CODES;
};

ClockCell.prototype.getGoalId = function () {
    return ClockCell.GOAL_ID;
};

ClockCell.GOAL_ID = "clock";