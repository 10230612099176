/**
 * Created by Denis Kuzin on 06 june 2024
 */

var MuffinComponent = function (cell) {
    this.cell = cell;
    this.cell.movable = false;

    this.cell.isForGoal = this.cell.x === undefined;
    this.cell.isForEditor = this.cell.x < 0;
};

MuffinComponent.prototype.bundleId = function () {
    return "muffin";
};

MuffinComponent.prototype.load = function (data) {
    this.cell.lives = parseInt(data[1]);
};

MuffinComponent.prototype.save = function () {
    return MuffinComponent.CODES.concat([this.cell.lives]);
};

MuffinComponent.prototype.getMaxLives = function () {
    return 2;
};

MuffinComponent.prototype.getViewClass = function () {
    return MuffinCellView;
};

MuffinComponent.prototype.boom = function () {
    this.cell.hurt();
};

MuffinComponent.prototype.smile = function () {
    if (!this.cell.alive) {
        return;
    }

    this.cell.animate(BaseCell.ANIMATION_SMILE);
};
