/**
 * Created by denis on 6 august 2020
 */

var DiceBooster = function () {
    BaseBoosterBefore.call(this, cleverapps.Boosters.TYPE_DICE);
};

DiceBooster.prototype = Object.create(BaseBoosterBefore.prototype);
DiceBooster.prototype.constructor = DiceBooster;

DiceBooster.prototype.getActionBefore = function () {
    return {
        type: "dice",
        source: this,
        getDuration: function () {
            return 2.6 + (Game.currentGame.dice.number || 6) * 0.2;
        }
    };
};