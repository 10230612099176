/**
 * Created by slava on 01.08.17.
 */

var CrabDecoratorCell = function (x, y, options) {
    BaseCell.call(this, x, y, options);

    this.isForGoal = x === undefined;
    this.isForEditor = x === -1;
    this.putInPoolAvailable = true;

    this.lives = 1;
    this.onCrabMove = function () {};

    if (Game.currentGame) {
        Game.currentGame.counter.registerStage(203, CrabDecoratorCell.process);
    }
};

CrabDecoratorCell.prototype = Object.create(BaseCell.prototype);
CrabDecoratorCell.prototype.constructor = CrabDecoratorCell;
Skinify(CrabDecoratorCell, "crab_decorator");

CrabDecoratorCell.prototype.getViewClass = function () {
    return CrabDecoratorCellView;
};

CrabDecoratorCell.prototype.load = function (data, loader) {
    data.shift();
    if (data[0] === "2") {
        data.shift();
        this.lives = 2;
    }
    this.setInnerCell(loader.loadNext(this.y, this.x, data));
};

CrabDecoratorCell.prototype.save = function () {
    var saveData = CrabDecoratorCell.CODES;
    if (this.lives === 2) {
        saveData = saveData.concat("2");
    }
    return saveData.concat(this.innerCell.save());
};

CrabDecoratorCell.prototype.getColor = function () {
    if (this.isForGoal) {
        return undefined;
    }
    return this.innerCell.getColor();
};

CrabDecoratorCell.prototype.smile = function () {
    if (!this.alive) {
        return;
    }
    this.animate(BaseCell.ANIMATION_SMILE);
};

CrabDecoratorCell.prototype.canMoveTo = function (x, y) {
    if (!Game.currentGame.field.isCellInField(y, x)) {
        return false;
    }
    if (CrabDecoratorCell.blockedByCrab[y][x]) {
        return false;
    }
    var cell = Game.currentGame.field.cells[y][x];
    var type = cell.constructor;
    var targets = [WaffleCell, ColorBombCell, FireworkCell, TruffleCell,
        ColorCookieManCell, LollipopCell];
    return targets.indexOf(type) !== -1 || type === BaseCell && (cell.findComponent(ColorComponent) || cell.findComponent(GoalCoefComponent));
};

CrabDecoratorCell.prototype.process = function () {
    var canMove = [];
    
    var DX = [-1, 1, 0, 0];
    var DY = [0, 0, -1, 1];
    for (var dir = 0; dir < 4; dir++) {
        if (this.canMoveTo(this.x + DX[dir], this.y + DY[dir])
            && (Game.currentGame.field.barriers.canMove(this.y, this.x, this.y + DY[dir], this.x + DX[dir]) === true)) {
            canMove.push(Game.currentGame.field.cells[this.y + DY[dir]][this.x + DX[dir]]);
        }
    }
        
    if (canMove.length) {
        Game.currentGame.crabDecoratorCellCanProcess = false;
        var moveCell = cleverapps.Random.choose(canMove);
        CrabDecoratorCell.blockedByCrab[moveCell.y][moveCell.x] = true;

        this.innerCell.viewExists = true;
        this.innerCell.x = this.x;
        this.innerCell.y = this.y;
        Game.currentGame.field.addCell(this.innerCell);

        Game.currentGame.counter.setTimeout(function () {
            var moveTime = 0.3;
            this.onCrabMove(moveCell, moveTime, moveCell.x - this.x);
            Game.currentGame.counter.setTimeout(function () {
                this.viewExists = true;
                this.x = moveCell.x;
                this.y = moveCell.y;
                this.setInnerCell(moveCell);
                Game.currentGame.field.addCell(this);
                this.onCrabFinishMove(moveCell);
            }.bind(this), moveTime * 1000);
        }.bind(this), CrabDecoratorCell.processedCrabs * 50);
        CrabDecoratorCell.processedCrabs++;
        if (CrabDecoratorCell.processedCrabs > 8) {
            CrabDecoratorCell.processedCrabs = 0;
        }
    }
};

CrabDecoratorCell.process = function () {
    if (!Game.currentGame || !Game.currentGame.crabDecoratorCellCanProcess) {
        return;
    }

    var i, j;
    CrabDecoratorCell.blockedByCrab = [];
    CrabDecoratorCell.processedCrabs = 0;
    for (i = 0; i < Field.SIZE; i++) {
        CrabDecoratorCell.blockedByCrab.push([]);
        for (j = 0; j < Field.SIZE; j++) {
            CrabDecoratorCell.blockedByCrab[i].push(false);
        }
    }

    for (i = 0; i < Field.SIZE; i++) {
        for (j = 0; j < Field.SIZE; j++) {
            if (Game.currentGame.field.cells[i][j] && Game.currentGame.field.cells[i][j].constructor === CrabDecoratorCell) {
                Game.currentGame.field.cells[i][j].process();
            }
        }
    }
};

CrabDecoratorCell.prototype.getGoalId = function () {
    return CrabDecoratorCell.GOAL_ID;
};

CrabDecoratorCell.GOAL_ID = "crab_decorator";