/**
 * Created by Andrey Popov on 2/19/21.
 */

var StaticPuzzleLogic = function (width, height, game) {
    this.fieldWidth = width || 9;
    this.fieldWidth = Math.max(this.fieldWidth, 5);
    this.fieldWidth = Math.min(this.fieldWidth, 10);

    this.fieldHeight = height || 13;
    this.fieldHeight = Math.max(this.fieldHeight, 5);
    this.fieldHeight = Math.min(this.fieldHeight, 15);

    this.game = game;
    this.goalsCount = Math.floor(this.fieldWidth * this.fieldHeight / 3);
};

StaticPuzzleLogic.prototype.createUnits = function () {
    this.actions = [];
    var map = this.game.map;
    var iterationsLimit = 10000;
    for (var i = 0; i < this.goalsCount; i++) {
        var unitType = cleverapps.Random.random(20);

        var action = { units: []};
        [0, 1, 2].forEach(function () {
            var x, y;
            var isAvailable = false;
            do {
                x = cleverapps.Random.random(map.width);
                y = cleverapps.Random.random(map.height);

                var bfs = map.bfs(x, y, map.compareMergeable.bind(map, new Puzzle15Unit(undefined, {type: unitType}))).length;
                isAvailable = map.getUnit(x, y) === undefined && bfs < 3;

                if (iterationsLimit-- === 0) {
                    map.listAvailableGrounds().forEach(function (cell) {
                        map.remove(Map2d.LAYER_UNITS, cell.x, cell.y);
                    }, this);
                    this.createUnits(map);
                    break;
                }
            } while (!isAvailable);

            var unit = new Puzzle15Unit(map, {type: unitType, x: x, y: y, static: true});
            map.add(Map2d.LAYER_UNITS, x, y, unit);
            action.units.push(unit);
        }.bind(this));
        this.actions.push(action);
    }
};

StaticPuzzleLogic.prototype.getPlayActions = function () {
    return this.actions.slice(0, 7);
};