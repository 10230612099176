/**
 * Created by Andrey Popov on 20.01.2021.
 */
var WysiwygScene = cleverapps.FixedWidthScene.extend({
    ctor: function (wysiwyg, clipIdToLoad, generate) {
        this.wysiwyg = wysiwyg;
        this.clipIdToLoad = clipIdToLoad;
        this.generate = generate;

        this._super();
    },

    onSceneLoaded: function () {
        this._super(cleverapps.Environment.SCENE_WYSIWYG);

        var size = cleverapps.resolution.getSceneSize();

        var sceneViewHeight = size.height - cleverapps.styles.AssetsView.height - cleverapps.styles.WysiwygMenubarView.height - 2 * cleverapps.styles.WysiwygScene.margin;
        var sceneViewWidth = size.width - cleverapps.styles.HierarchyView.width - cleverapps.styles.InspectorView.width;

        var hierarchyView = this.hierarchyView = new HierarchyView(this.wysiwyg.hierarchy, { height: sceneViewHeight });
        var wysiwygPreviewView = this.wysiwygPreviewView = new WysiwygPreviewView(this.wysiwyg.wysiwygPreview, {
            height: sceneViewHeight,
            width: sceneViewWidth
        });
        var inspectorView = new InspectorView(this.wysiwyg.inspector);
        var menubarView = new WysiwygMenubarView(this.wysiwyg.wysiwygMenubar);
        var assetsView = new AssetsView(cleverapps.wysiwyg.assets, { width: size.width - inspectorView.width });

        this.addChild(hierarchyView);
        this.addChild(wysiwygPreviewView);
        this.addChild(inspectorView);
        this.addChild(menubarView);
        this.addChild(assetsView);

        cleverapps.config.nologin = true;

        if (this.clipIdToLoad) {
            this.wysiwyg.hierarchy.loadClip(this.clipIdToLoad);
        } else {
            this.wysiwyg.hierarchy.selectDirectory(this.wysiwyg.directory);
        }

        // to create transparent bg
        cc.director.setClearColor(new cc.Color(0, 0, 0, 0));
        cleverapps.resolution.setSafePaddingDebug(cc.padding());

        setTimeout(function () {
            cleverapps.resolution.setupDesignResolution();
        }, 0);

        cleverapps.git.changeFolders([GitManager.FOLDERS.WYSIWYG_SCENARIOS]);
        cleverapps.git.on("changed", this.wysiwyg.handleGitStatusChanged.bind(this.wysiwyg));
    },

    setupChildren: function () {
        if (!this.isAllLoaded) {
            return;
        }

        this.setBackground();
    },

    setBackground: function () {
        if (this.background) {
            this.background.removeFromParent();
        }
    },

    _closeAction: function () {
        cleverapps.audio.unmute();
        cleverapps.config.wysiwygMode = false;

        this._super();
    },

    getAudioStyles: function () {
        return {
            res: undefined,
            delay: 0
        };
    },

    listBundles: function () {
        return ["wysiwyg", "admin"];
    }
});

WysiwygScene.open = function (wysiwyg, clipId) {
    cleverapps.audio.mute();

    cleverapps.meta.display({
        focus: "wysiwyg",
        action: function (f) {
            cleverapps.config.wysiwygMode = true;
            wysiwyg.wysiwygPreview.forceUpdate = true;
            cleverapps.scenes.replaceScene(new WysiwygScene(wysiwyg, clipId), f);
        }
    });
};

cleverapps.styles.WysiwygScene = {
    margin: 4
};

(function () {
    var params = cleverapps.getRequestParameters(location.hash);
    if (!cleverapps.config.debugMode || !params.wysiwyg) {
        return;
    }

    cleverapps.config.nologin = true;

    DataLoader.prototype.resetClientSession = function () {
        this.setAlerted(true);
        this.setEnabled(false);
        this.setCorrupted(false);
    };

    cleverapps.Synchronizer.prototype._sync = function () {
        cleverapps.Synchronizer.prototype._sync = function () {};
        cleverapps.flags.nologin = true;
    };

    cleverapps.Plot.onStartup = function (f, returnClassOnly) {
        if (returnClassOnly) {
            return WysiwygScene;
        }

        cleverapps.wysiwyg = new Wysiwyg(params.directory);

        cleverapps.config.wysiwygMode = true;
        var scene = new WysiwygScene(cleverapps.wysiwyg, params.clipId, params.generate);
        cleverapps.scenes.replaceScene(scene, f);
    };
}());
