/**
 * Created by Andrey Popov on 18.07.2024
 */

var PagingScroll = function (totalPagesCount) {
    cleverapps.EventEmitter.call(this);

    this.pages = {};
    this.maxCountOfPages = totalPagesCount;
    this.currentPosition = 0;

    this.updateVisiblePages();
};

PagingScroll.prototype = Object.create(cleverapps.EventEmitter.prototype);
PagingScroll.prototype.constructor = PagingScroll;

PagingScroll.prototype.addPage = function (pageNo) {
    this.pages[pageNo] = new Page(pageNo, pageNo >= this.maxCountOfPages, pageNo - this.currentPosition);
    this.trigger("addPage", this.pages[pageNo]);
};

PagingScroll.prototype.removePage = function (pageNo) {
    delete this.pages[pageNo];
    this.trigger("removePage", pageNo);
};

PagingScroll.prototype.reset = function () {
    Object.keys(this.pages).forEach(function (pageNo) {
        this.removePage(parseInt(pageNo));
    }.bind(this));
    this.pages = {};
    this.currentPosition = 0;
};

PagingScroll.prototype.getCurrentPosition = function () {
    return this.currentPosition;
};

PagingScroll.prototype.listVisiblePages = function () {
    return Object.values(this.pages);
};

PagingScroll.prototype.isPageVisible = function (pageNo) {
    return Boolean(this.pages[pageNo]);
};

PagingScroll.prototype.updateVisiblePages = function () {
    var currentPage = Math.floor(this.currentPosition + 0.5);

    var oldPages = Object.keys(this.pages).map(function (pageNo) {
        return parseInt(pageNo);
    });
    var newPages = this.range(currentPage, -1, PagingScroll.VISIBLE_PAGES + 1);

    cleverapps.substract(oldPages, newPages).forEach(function (pageNo) {
        this.removePage(pageNo);
    }, this);

    cleverapps.substract(newPages, oldPages).forEach(function (pageNo) {
        this.addPage(pageNo);
    }, this);
};

PagingScroll.prototype.range = function (base, start, count) {
    var pages = [];

    for (var i = Math.max(base + start, 0), total = Math.min(base + start + count, this.maxCountOfPages); i <= total; ++i) {
        pages.push(i);
    }

    return pages;
};

PagingScroll.prototype.gotoNextPage = function (callback, floatPage) {
    cleverapps.audio.playSound(bundles.main.urls.window_effect);

    this.scrollToLevel(1, floatPage, callback);
};

PagingScroll.prototype.scrollToLevel = function (duration, floatPage, callback) {
    if (cleverapps.config.name === "riddles") {
        floatPage -= 0.35;
    }

    if (cleverapps.config.name === "crocword") {
        floatPage = Math.floor(floatPage);
    }

    this.scrollToPosition(duration, floatPage, callback);
};

PagingScroll.prototype.scrollToPosition = function (duration, floatPage, callback) {
    callback = callback || function () {};
    
    if (floatPage < 0) {
        floatPage = 0;
    }

    if (floatPage > this.maxCountOfPages) {
        floatPage = this.maxCountOfPages;
    }

    if (this.currentPosition === floatPage) {
        callback();
        return;
    }

    var currentPage = Math.floor(this.currentPosition + 0.5);
    var nextPage = Math.floor(floatPage + 0.5);

    this.currentPosition = floatPage;

    if (currentPage !== nextPage) {
        this.updateVisiblePages();
    }

    this.updatePagesOffset();

    this.trigger("scroll", duration, floatPage, function () {
        this.updatePagesOffset();
        callback();
    }.bind(this));
};

PagingScroll.prototype.animateOpen = function (f, pageNo) {
    var page = this.pages[pageNo];
    if (!page || !page.onAnimateOpen(f)) {
        f();
    }
};

PagingScroll.prototype.animateClose = function (f, pageNo) {
    var page = this.pages[pageNo];
    if (!page || !page.onAnimateClose(f)) {
        f();
    }
};

PagingScroll.prototype.updatePagesOffset = function () {
    Object.keys(this.pages).forEach(function (pageNo) {
        this.pages[pageNo].setOffsetFromCenter(pageNo - this.currentPosition);
    }.bind(this));
};

PagingScroll.VISIBLE_PAGES = 1;
