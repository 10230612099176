/**
 * Created by andrey on 21.05.2020
 */

var ComboBooster = function () {
    BaseBoosterBefore.call(this, cleverapps.Boosters.TYPE_COMBO);
};

ComboBooster.prototype = Object.create(BaseBoosterBefore.prototype);
ComboBooster.prototype.constructor = ComboBooster;

ComboBooster.prototype.getActionBefore = function () {
    return {
        type: "combo",
        source: this,
        amount: 5
    };
};