/**
 * Created by vladislav on 1/26/2024
 */

var SamsungLogic = function () {
    PlatformLogic.call(this);
};

SamsungLogic.prototype = Object.create(PlatformLogic.prototype);
SamsungLogic.prototype.constructor = SamsungLogic;

SamsungLogic.prototype.reportScore = function (score, callback) {
    GSInstant.setScoreAsync(score).then(function () {
        callback(cleverapps.CODE_SUCCEED);
    }).catch(function () {
        callback(cleverapps.CODE_FAILED);
    });
};