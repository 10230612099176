/**
 * Created by vladislav on 1/23/19
 */

var MovesCell = function (x, y) {
    BaseCell.call(this, x, y);
    this.lives = 1;
    this.moves = 1;
    this.movable = true;
};

MovesCell.prototype = Object.create(BaseCell.prototype);
MovesCell.prototype.constructor = MovesCell;

MovesCell.prototype.bundleId = function () {
    return "moves_cell";
};

MovesCell.prototype.editorComponents = function () {
    return [BaseCellComponent, MovesCellComponent];
};

MovesCell.prototype.load = function (data) {
    var digits = data.splice(1).join("");
    this.moves = parseInt(digits);
};

MovesCell.prototype.save = function () {
    var digits = "" + this.moves;
    return MovesCell.CODES.concat(digits.split(""));
};

MovesCell.prototype.getViewClass = function () {
    return MovesCellView;
};

MovesCell.prototype.boom = function () {
    this.hurt();
};

MovesCell.prototype.explodeDuration = function () {
    return 0.8;
};