/**
 * Created by Aleksandr on 29.11.2022
 */

var Orangery = function (data) {
    this.data = data;

    this.maximized = this.data.maximized || false;

    this.selected = undefined;
    this.selectedTab = this.data.preSelectedTab;

    this.onChangeButton = function () {};
    this.onSelectionChanged = function () {};

    this.onToggleView = function () {};
    this.onToggleButton = function () {};
};

Orangery.prototype.init = function () {
    this.data.init();
};

Orangery.prototype.getControlName = function () {
    return "Orangery";
};

Orangery.prototype.toggleButton = function () {
    this.onToggleButton();
};

Orangery.prototype.toggleView = function (visible) {
    this.onToggleView(visible);
};

Orangery.prototype.select = function (item, tab) {
    if (this.selected !== item) {
        if (this.selected) {
            this.selected.onSelectionChanged(false);
        }

        this.selected = item;
        this.selectedTab = tab;

        if (item) {
            item.onSelectionChanged(true);
        }

        this.onSelectionChanged(item);
        this.onChangeButton();
    }
};

Orangery.prototype.unselect = function (item) {
    if (this.selected === item) {
        this.selected = undefined;
        this.selectedTab = undefined;

        if (item) {
            item.onSelectionChanged(false);
        }

        this.onSelectionChanged();
        this.onChangeButton();
    }
};

Orangery.prototype.listTabs = function () {
    return this.data.tabs.filter(function (tab) {
        return tab.rows.length;
    }).map(function (tab, i) {
        return Object.assign({ id: i }, tab);
    });
};

Orangery.prototype.getItemCode = function (item) {
    return this.data.getItemCode(item);
};

Orangery.prototype.destructor = function () {
    if (this.data.clear) {
        this.data.clear();
    }
};