/**
 * Created by mac on 4/7/20
 */

var GameMessageView = cc.Node.extend({
    ctor: function (message, options) {
        this.options = options || {};

        this._super();

        this.styles = cleverapps.styles.GameMessageView;

        var background = this.createBackground();
        background.setVisible(false);
        this.addChild(background);
        this.updateSize();

        this.content = this.options.content || this.createText(message);
        background.addChild(this.content);
        this.content.setupChildren = function () {
            this.content.setPositionRound(this.styles.content);
        }.bind(this);
        this.content.setupChildren();

        this.setLocalZOrder(17);
        this.setupChildren();
        this.show();
    },

    setupChildren: function () {
        var scene = cleverapps.scenes.getRunningScene();
        this.background.setPositionRound(scene.width / 2, this.background.y);

        this.start = cc.p(scene.width / 2, scene.height + this.background.height / 2);
        this.target = cc.p(scene.width / 2, scene.height / 2);
        this.finish = cc.p(scene.width / 2, -this.background.height / 2);

        if (this.pattern) {
            this.createPatternLayer(this.pattern, this.background);
        }
    },

    updateSize: function () {
        var sceneSize = cleverapps.resolution.getSceneSize();
        this.background.setContentSize2(sceneSize.width * 1.2, this.background.height);
    },

    show: function () {
        cleverapps.audio.playSound(bundles.gamemessage.urls.game_message_effect);
        this.background.setVisible(true);

        if (cleverapps.styles.GameMessageView.showAnimation === "fromTop") {
            this.showFromTop();
        } else {
            this.showFromCenter();
        }
    },

    showFromCenter: function () {
        this.background.setPositionRound(this.target);
        this.background.setScale(1, 0.6);

        this.background.runAction(new cc.Sequence(
            new cc.ScaleTo(0.3, 1, 1).easing(cc.easeBackOut()),
            new cc.DelayTime(0.5),
            new cc.Spawn(
                new cc.FadeOut(0.3),
                new cc.ScaleTo(0.3, 1, 0.6).easing(cc.easeBackIn())
            ),
            new cc.CallFunc(function () {
                this.removeFromParent();
            }.bind(this))
        ));
    },

    showFromTop: function () {
        this.background.setPositionRound(this.start);

        this.background.runAction(new cc.Sequence(
            new cc.MoveTo(0.3, this.target).easing(cc.easeBackOut(1.4)),
            new cc.DelayTime(0.8),
            new cc.MoveTo(0.3, this.finish).easing(cc.easeBackIn(1.4)),
            new cc.CallFunc(function () {
                this.removeFromParent();
            }.bind(this))
        ));
    },

    createText: function (message) {
        message = Messages.has(message) ? Messages.get(message) : message;
        var font = cleverapps.styles.FONTS.GAME_MESSAGE_TEXT;
        var text = cleverapps.UI.generateTTFText(message.toUpperCase(), font);

        text.setLocalZOrder(1);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        text.fitTo(cleverapps.resolution.getSceneSize().width * 0.9);
        return text;
    },

    createBackground: function () {
        var background = this.background = new cc.Scale9Sprite(bundles.gamemessage.frames.gamemessage_bg_png);
        background.setLocalZOrder(-1);
        var pattern = this.pattern = bundles.gamemessage.frames.gamemessage_pattern_png;
        if (pattern) {
            this.createPatternLayer(pattern, background);
        }
        return background;
    },

    createPatternLayer: function (pattern, parent) {
        if (this.patternLayout) {
            this.patternLayout.removeFromParent();
            delete this.patternLayout;
        }

        var patternWidth = new cc.Sprite(pattern).width;
        var patternAmount = Math.ceil(cleverapps.resolution.getSceneSize().width / patternWidth) + 1;
        var patterns = [];

        for (var i = 0; i <= patternAmount; i++) {
            var patternSprite = new cc.Sprite(pattern);
            patterns.push(patternSprite);
        }

        var patternLayer = this.patternLayout = new cleverapps.Layout(patterns, { direction: cleverapps.UI.HORIZONTAL });
        patternLayer.setLocalZOrder(0);
        patternLayer.setPositionRound(cleverapps.scenes.getRunningScene().width / 2, parent.height / 2);
        parent.addChild(patternLayer);
    }
});

cleverapps.styles.GameMessageView = {
    showAnimation: "fromTop",
    content: {
        x: { align: "center" },
        y: { align: "center" }
    }
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    GAME_MESSAGE_TEXT: {
        size: 86,
        color: cleverapps.styles.COLORS.WHITE
    }
});