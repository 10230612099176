/**
 * Created by slava on 22/8/19
 */

var SuperMultiColorCell = function (x, y) {
    BaseCell.call(this, x, y);

    this.movable = true;
    this.fallThroughBarrier = true;
};

SuperMultiColorCell.prototype = Object.create(MultiColorCell.prototype);
SuperMultiColorCell.prototype.constructor = SuperMultiColorCell;

SuperMultiColorCell.prototype.load = function () {
    // do nothing
};

SuperMultiColorCell.prototype.save = function () {
    return SuperMultiColorCell.CODES;
};

SuperMultiColorCell.prototype.getViewClass = function () {
    return SuperMultiColorCellView;
};

SuperMultiColorCell.prototype.explodeDuration = function () {
    return 1.4;
};

SuperMultiColorCell.prototype.beforeExplodeTimeout = function () {
    return 0.3;
};

SuperMultiColorCell.prototype.onExplode = function () {
    this.chosenColor = MultiColorCell.COLOR_ALL;
    MultiColorCell.prototype.onExplode.call(this);
};