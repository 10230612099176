/**
 * Created by slava on 02.02.17.
 */

var CookieManCellView = BaseCellView.extend({
    ctor: function (fishCell) {
        this._super();

        this.setContentSize(cleverapps.styles.BaseCellView.CELL_SIZE_PX, cleverapps.styles.BaseCellView.CELL_SIZE_PX);
        this.setAnchorPoint(0.5, 0.5);

        var image = new cc.Sprite(this.getImage());
        this.image = image;
        this.image.setPosition(this.width / 2, this.height / 2);
        this.addChild(image);

        this.animation = new cleverapps.Spine(bundles.cookieman.jsons.cookieman_spine_json);
        this.animation.setPosition(this.width / 2, this.height / 2);
        this.addChild(this.animation);

        this.setCascadeOpacityEnabled(true);

        this.initialize(fishCell);
    },

    getImage: function () {
        return bundles.cookieman.frames.cookieman_stable;
    },

    initialize: function (fishCell) {
        this._super(fishCell);

        fishCell.onBeforeExplodeListener = this.startExplodingViaCollect.bind(this);
        this.image.setVisible(true);
        if (this.animation) {
            this.animation.setVisible(false);
        }
        this.smiling = false;
    },

    swapImageToAnimation: function () {
        this.runAction(new cc.CallFunc(function () {
            this.image.visible = false;
            this.animation.visible = true;
        }.bind(this)));
    },

    finishSmile: function () {
        if (this.smiling) {
            this.smiling = false;
            this.image.visible = true;
            this.animation.visible = false;
        }
    },

    smile: function () {
        if (!this.animation) {
            return;
        }

        if (this.smiling) {
            return;
        }
        this.smiling = true;
        this.animation.setAnimation(0, "animation", false);
        this.animation.setCompleteListener(this.finishSmile.bind(this));
        this.swapImageToAnimation();
    },

    onclick: function () {
        if (!this.animation) {
            return;
        }

        this.smiling = true;
        this.animation.setAnimation(0, "smile", false);
        this.animation.setCompleteListener(this.finishSmile.bind(this));
        this.swapImageToAnimation();
    },

    cookieUp: function (callback) {
        var initialZOrder = this.getLocalZOrder();
        if (this.animation) {
            this.setLocalZOrder(3);
            this.smiling = true;
            this.animation.setAnimation(0, "up", false);
            this.animation.setCompleteListener(function () {
                callback();
                this.finishSmile();
                this.setLocalZOrder(initialZOrder);
            }.bind(this));
            this.swapImageToAnimation();
        } else {
            this.setLocalZOrder(3);
            var position = BaseCellView.alignInTheGrid(this.cell.x, this.cell.y);
            this.runAction(new cc.Sequence(
                new cc.DelayTime(0.3),
                new cc.MoveTo(0.5, position),
                new cc.CallFunc(function () {
                    this.setLocalZOrder(initialZOrder);
                    callback();
                }.bind(this))
            ));
        }
    }
});