/**
 * Created by vladislav on 1/23/19
 */

var MovesCellView = BaseCellView.extend({
    ctor: function (movesCell) {
        this._super();

        this.setContentSize(cleverapps.styles.BaseCellView.CELL_SIZE_PX, cleverapps.styles.BaseCellView.CELL_SIZE_PX);
        this.setAnchorPoint(0.5, 0.5);

        this.image = new cc.Sprite(bundles.moves_cell.frames.moves_cell);
        this.image.setPosition(this.width / 2, this.height / 2);
        this.addChild(this.image);

        var styles = cleverapps.styles.MovesCellView.text;

        var text = movesCell.moves;
        if (styles.plus) {
            text = "+" + text;
        }
        this.movesText = cleverapps.UI.generateImageText(text, cleverapps.styles.FONTS.MOVES_CELL_TEXT);
        this.addChild(this.movesText);
        this.movesText.setPositionRound(this.width / 2 + styles.x, this.height / 2 + styles.y);
        if (movesCell.moves >= 10 && styles.scale) {
            this.movesText.setScale(styles.scale);
        }

        this.initialize(movesCell);
    },

    startExploding: function () {
        var callback = function () {
            if (Game.currentGame) {
                Game.currentGame.setMoves(Game.currentGame.moves + this.cell.moves, {
                    delay: 300
                });
            }
            this.delete();
        }.bind(this);

        this.runAction(new cc.Sequence(
            AnimationsLibrary.animateCollect(this, "moves", {
                scale: 0.5,
                collectEffect: true
            }),
            new cc.CallFunc(callback)
        ));
    },

    updateMoves: function () {
        this.movesText.setString("+" + this.cell.moves);
    }
});

cleverapps.styles.MovesCellView = {
    text: {
        x: -5,
        y: 0,
        plus: true
    }
};