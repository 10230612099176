/**
 * Created by r4zi4l on 05.04.2024
 */

GameBase.REWARD_PIGGY_BANK = "piggyBank";

// GameBase.REWARD_PIGGY_BANK
RewardTypes.piggyBank = {
    flyingAnimation: Reward.CUSTOM_COLLECT,

    getCustomRewardAnimation: function () {
        return bundles.piggy_bank_reward.jsons.coins_animation_json;
    },

    getCustomRewardIcon: function () {
        return bundles.custom_reward.jsons.piggybank_icon_json;
    },

    getCustomRewardText: function (value, prev) {
        var mission = cleverapps.missionManager.findByType(Mission.TYPE_PIGGY_BANK);
        if (!mission || !mission.isRunning()) {
            return;
        }

        if (mission.logic.isFull()) {
            return prev ? mission.result - value : "PiggyBank.Full";
        }

        return prev ? mission.result - value : mission.result;
    },

    getCustomRewardSound: function () {
        return bundles.custom_reward.urls.piggybank_coins_effect;
    },

    getCustomRewardOpenSound: function () {
        return bundles.custom_reward.urls.piggy_reward_open;
    },

    getCustomRewardCloseSound: function () {
        return bundles.custom_reward.urls.piggy_reward_close;
    },

    getCustomRewardAfterUpdateSound: function () {
        var mission = cleverapps.missionManager.findByType(Mission.TYPE_PIGGY_BANK);
        if (!mission || !mission.isRunning() || !mission.logic.isFull()) {
            return;
        }

        return bundles.custom_reward.urls.piggybank_coins_full_effect;
    },

    parse: function (value, options) {
        var mission = cleverapps.missionManager.findRunningMission(Mission.TYPE_PIGGY_BANK);
        if (mission && !mission.logic.isFull()) {
            return [new Reward("piggyBank", Math.min(mission.logic.getLevelReward(), mission.logic.getMaxAmount() - mission.result), options)];
        }
        return [];
    },

    handler: function (value) {
        var mission = cleverapps.missionManager.findByType(Mission.TYPE_PIGGY_BANK);
        if (mission && mission.isRunning()) {
            mission.logic.add(value);
        }

        return function () {};
    }
};
