/**
 * Created by slava on 01.08.17.
 */

var DragonCell = function (x, y) {
    BaseCell.call(this, x, y);

    this.bigComponent = this.addComponent(BigComponent, { cellClass: DragonCell });
    this.addComponent(LiveLineComponent);

    if (this.isForGoal) {
        this.hurtable = true;
        return;
    }

    this.hurtable = true;
    this.lives = 10;
};

DragonCell.prototype = Object.create(BaseCell.prototype);
DragonCell.prototype.constructor = DragonCell;

DragonCell.prototype.editorComponents = function () {
    if (this.bigComponent.isHead) {
        return [BaseCellComponent, BigCellComponent, LiveLineEditorComponent];
    }
};

DragonCell.prototype.bundleId = function () {
    return "dragon";
};

DragonCell.prototype.getViewClass = function () {
    return DragonCellView;
};

DragonCell.prototype.getGoalId = function () {
    return DragonCell.GOAL_ID;
};

DragonCell.prototype.smile = function () {
    if (this.alive) {
        this.animate(BaseCell.ANIMATION_SMILE);
    }
};

DragonCell.prototype.boom = function () {
    this.hurt();
};

DragonCell.prototype.onHeadExplode = function () {
    this.onOpenAnimation();
    Game.currentGame.counter.setTimeout(function () {
        this.explodeViaCollect();
    }.bind(this), DragonCell.EXPLODE_VIA_COLLECT_TIMEOUT);
};

DragonCell.GOAL_ID = "dragon_cell";

DragonCell.EXPLODE_VIA_COLLECT_TIMEOUT = 300;