/**
 * Created by vladislav on 19.11.2019
 */

cleverapps.styles.comicses = {
    begin: {
        stages: [
            {
                showAnimation: "trans_1",
                idleAnimation: "idle_1",
                dialogueStages: 1
            },
            {
                showAnimation: "trans_2",
                idleAnimation: "idle_2",
                dialogueStages: 1
            },
            {
                showAnimation: "trans_3",
                idleAnimation: "idle_3",
                dialogueStages: 5
            }
        ],

        dialogue: [
            {
                text: "Comics.Begin.Text1",
                person: false
            },
            {
                text: "Comics.Begin.Text2",
                person: false
            },
            {
                text: "Comics.Begin.Text3",
                person: {
                    role: "yellow",
                    orientation: "right"
                },
                person2: {
                    role: "purple",
                    orientation: "left"
                }
            },
            {
                text: "Comics.Begin.Text4",
                person: {
                    role: "purple",
                    orientation: "left"
                },
                person2: {
                    role: "yellow",
                    orientation: "right"
                }
            },
            {
                text: "Comics.Begin.Text5",
                person: {
                    role: "red",
                    orientation: "right"
                },
                person2: {
                    role: "purple",
                    orientation: "left"
                }
            },
            {
                text: "Comics.Begin.Text6",
                person: {
                    role: "green",
                    orientation: "right"
                },
                person2: {
                    role: "purple",
                    orientation: "left"
                }
            },
            {
                text: "Comics.Begin.Text7",
                person: {
                    role: "purple",
                    orientation: "left"
                },
                person2: {
                    role: "green",
                    orientation: "right"
                }
            }
        ],

        bundles: ["comicses"],
        json: bundles.comicses.jsons.begin_json,
        onFinish: "game"
    }
};
