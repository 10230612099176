/**
 * Created by andrey on 29.05.17.
 */

var MarksBlock = ElementsChooser.extend({
    ctor: function () {
        var table = {};

        var markView = new MarkView({ mark: new Mark() });
        table["!"] = this.createElementView(markView);

        this._super({
            type: "mark",
            table: table 
        });

        this.displayMarksAmount(0);
    },

    createElementView: function (markView) {
        var view = new cc.Node();
        view.element = markView;
        view.addChild(markView);
        view.setContentSize2(markView.getContentSize());
        view.setAnchorPoint(0.5, 0.5);
        markView.setPosition(view.width / 2, view.height / 2);
        view.setPosition(view.width / 2, view.height / 2);

        return view;
    },

    displayMarksAmount: function (count) {
        if (this.title) {
            this.title.removeFromParent(true);
        }

        var title = cleverapps.UI.generateTTFText("Marks:\n" + ((count === undefined) ? 0 : count.toString()) + " now", cleverapps.styles.FONTS.EDITOR_TEXT);
        title.setAnchorPoint(0, 0.5);
        title.setPosition(cleverapps.styles.MarksBlockView.title.x, 0);
        this.addChild(title);

        this.title = title;
    }
});

cleverapps.styles.MarksBlockView = {
    title: {
        x: 100
    }
};