/**
 * Created by andrey on 24.04.2024
 */

var TransitionLoading = cleverapps.Layout.extend({
    ctor: function () {
        var styles = cleverapps.styles.TransitionLoading;

        var animation = this.animation = new cleverapps.Spine(bundles.main.jsons.wait_transition_json);
        animation.setAnimation(0, "idle", true);

        var text = this.text = cleverapps.UI.generateOnlyText("Loading", cleverapps.styles.FONTS.TRANSITION_LOADING_TEXT);

        this._super([text, animation], {
            direction: cleverapps.UI.VERTICAL,
            padding: styles.padding
        });

        this.setPositionRound(styles);
        this.setVisible(false);
    },

    show: function (silent) {
        this.setVisible(true);
        if (silent) {
            this.animation.clearTrack(0);
            return;
        }

        this.text.setOpacity(0);
        this.text.setScale(0.9);

        this.runAction(new cc.TargetedAction(this.text, new cc.Spawn(
            new cc.FadeIn(0.3),
            new cc.ScaleTo(0.3, 1).easing(cc.easeIn(1))
        )));
    },

    hide: function () {
        this.stopAllActions();
        this.runAction(new cc.Sequence(
            new cc.TargetedAction(this.text, new cc.Spawn(
                new cc.FadeOut(0.2),
                new cc.ScaleTo(0.2, 0.9).easing(cc.easeIn(1))
            )),
            new cc.Hide()
        ));
    }
});

TransitionLoading.isAvailable = function () {
    return Boolean(bundles.main.jsons.wait_transition_json);
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    TRANSITION_LOADING_TEXT: {
        size: 50,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    }
});

cleverapps.styles.TransitionLoading = {
    x: { align: "center" },
    y: { align: "center", dy: -400 },

    padding: 30
};