/**
 * Created by slava on 27/10/17
 */

var unmifiyBundlesRec = function (object, id, bundleName) {
    var el = object[id];
    if (typeof el === "object") {
        for (var i in el) {
            if (i === "meta" && id === bundleName) {
                continue;
            }
            if (id === "jsons" && el[i] && el[i].inline) {
                continue;
            }
            unmifiyBundlesRec(el, i, bundleName);
        }
    } else if (typeof el === "string") {
        el = el.replace("$", "res/packed/sounds/");
        el = el.replace("@", "~#/%r/");
        el = el.replace("~", "res/packed/");
        el = el.replace(/#/g, bundleName);

        object[id] = el;
    }
};

var unminifyBundles = function (names) {
    names = names || Object.keys(bundles);
    var i, j, k, name, bundle, jsonId, depBundle;

    for (i in names) {
        name = names[i];
        bundle = bundles[name];

        if (!bundle.frames) {
            bundle.frames = {};
        }
        if (!bundle.jsons) {
            bundle.jsons = {};
        }
        if (!bundle.urls) {
            bundle.urls = {};
        }
        if (!bundle.meta) {
            bundle.meta = {};
        }

        unmifiyBundlesRec(bundles, name, name);
    }

    for (i in names) {
        name = names[i];
        bundle = bundles[name];

        if (bundle.injectTo && bundle.frameIds) {
            bundle.frameIds.forEach(function (frameId) {
                bundle.frames[frameId] = "";
            });
            delete bundle.frameIds;
        }
        if (bundle.jsonIds) {
            for (j = 0; j < bundle.jsonIds.length; j++) {
                jsonId = bundle.jsonIds[j];
                bundle.jsons[jsonId] = "";

                if (bundle.injectTo) {
                    for (k = 0; k < bundle.injectTo.length; k++) {
                        depBundle = bundles[bundle.injectTo[k]];
                        depBundle.jsons[jsonId] = depBundle.jsons[jsonId] || "";
                    }
                }
            }
            delete bundle.jsonIds;
        }
        if (bundle.injectTo && bundle.soundIds) {
            bundle.soundIds.forEach(function (soundId) {
                bundle.urls[soundId] = "";
            });
            delete bundle.soundIds;
        }
        if (bundle.injectTo && bundle.unpackedIds) {
            bundle.unpackedIds.forEach(function (unpackedId) {
                bundle.urls[unpackedId] = "";
            });
            delete bundle.unpackedIds;
        }
    }

    ResourceProcessor.addStaticUrl();

    for (i in names) {
        name = names[i];
        bundle = bundles[name];

        if (bundle.important) {
            bundle.important = cleverapps.createSet(bundle.important);
        }
        if (bundle.lazy) {
            bundle.lazy = cleverapps.createSet(bundle.lazy);
        }

        bundles[name] = new Bundle(name, bundle);
    }
};

unminifyBundles();
