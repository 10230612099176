/**
 * Created by slava on 02.02.17.
 */
var BaseRandomCell = function (wildcard) {
    this.wildcard = wildcard === undefined || wildcard === ".";
};

var EditorRandomCell = function (x, y) {
    BaseCell.call(this, x, y, { color: "" });
    this.addComponent(MarkComponent);
};

EditorRandomCell.prototype = Object.create(BaseCell.prototype);
EditorRandomCell.prototype.constructor = EditorRandomCell;

EditorRandomCell.prototype.getViewClass = function () {
    return EditorRandomCellView;
};

EditorRandomCell.CODES = [Loader.WILDCARD];

EditorRandomCell.prototype.save = function () {
    var markComponent = this.findComponent(MarkComponent);
    if (markComponent.mark) {
        return [Loader.WILDCARD, "!"];
    }
    return [Loader.WILDCARD];
};

var EditorBombRandomCell = function (x, y) {
    BombCell.call(this, x, y);
    BaseRandomCell.call(this);
};

EditorBombRandomCell.prototype = Object.create(BombCell.prototype);
EditorBombRandomCell.prototype.constructor = EditorBombRandomCell;

EditorBombRandomCell.prototype.getViewClass = function () {
    return EditorBombRandomCellView;
};

EditorBombRandomCell.prototype.save = function () {
    var digits = "" + this.basicUntilExplode;
    return BombCell.CODES.concat([this.createType, Loader.WILDCARD]).concat(digits.split(""));
};

var EditorColorBombRandomCell = function (x, y) {
    ColorBombCell.call(this, x, y);
    BaseRandomCell.call(this);
};

EditorColorBombRandomCell.prototype = Object.create(ColorBombCell.prototype);
EditorColorBombRandomCell.prototype.constructor = EditorColorBombRandomCell;

EditorColorBombRandomCell.prototype.getViewClass = function () {
    return EditorColorBombRandomCellView;
};

EditorColorBombRandomCell.prototype.save = function () {
    var codes = ColorBombCell.CODES.concat([this.lives, Loader.WILDCARD, this.createType]);
    var markComponent = this.findComponent(MarkComponent);
    if (markComponent.mark) {
        codes.push(markComponent.mark.code);
    }
    return codes;
};

var EditorSmallPetardRandomCell = function (x, y) {
    SmallPetardCell.call(this, x, y);
    BaseRandomCell.call(this);
};

EditorSmallPetardRandomCell.prototype = Object.create(SmallPetardCell.prototype);
EditorSmallPetardRandomCell.prototype.constructor = EditorSmallPetardRandomCell;

EditorSmallPetardRandomCell.prototype.getViewClass = function () {
    return EditorSmallPetardRandomCellView;
};

EditorSmallPetardRandomCell.prototype.save = function () {
    var codes = SmallPetardCell.CODES.concat([this.lives, Loader.WILDCARD]);
    return codes;
};

var EditorFireworkRandomCell = function (x, y) {
    FireworkCell.call(this, x, y);
    BaseRandomCell.call(this);
};

EditorFireworkRandomCell.prototype = Object.create(FireworkCell.prototype);
EditorFireworkRandomCell.prototype.constructor = EditorFireworkRandomCell;

EditorFireworkRandomCell.prototype.getViewClass = function () {
    return EditorFireworkRandomCellView;
};

EditorFireworkRandomCell.prototype.save = function () {
    return FireworkCell.CODES.concat([this.createType, Loader.WILDCARD]);
};

var EditorWaffleRandomCell = function (x, y) {
    WaffleCell.call(this, x, y);
    BaseRandomCell.call(this);
};

EditorWaffleRandomCell.prototype = Object.create(WaffleCell.prototype);
EditorWaffleRandomCell.prototype.constructor = EditorWaffleRandomCell;

EditorWaffleRandomCell.prototype.getViewClass = function () {
    return EditorWaffleRandomCellView;
};

EditorWaffleRandomCell.prototype.save = function () {
    var code = WaffleCell.CODES.concat([Loader.WILDCARD]);
    var markComponent = this.findComponent(MarkComponent);
    if (markComponent.mark) {
        code.push(markComponent.mark.code);
    }
    return code;
};

var EditorTruffleRandomCell = function (x, y, wildcard) {
    TruffleCell.call(this, x, y);
    BaseRandomCell.call(this, wildcard);
};

EditorTruffleRandomCell.prototype = Object.create(TruffleCell.prototype);
EditorTruffleRandomCell.prototype.constructor = EditorTruffleRandomCell;

EditorTruffleRandomCell.prototype.getViewClass = function () {
    return EditorTruffleRandomCellView;
};
EditorTruffleRandomCell.prototype.save = function () {
    var code = TruffleCell.CODES.slice();
    if (!this.wildcard) {
        code.push(this.colors[0]);
    }
    code.push(".");
    var markComponent = this.findComponent(MarkComponent);
    if (markComponent.mark) {
        code.push(markComponent.mark.code);
    }
    return code;
};

var EditorColorCookieManRandomCell = function (x, y) {
    ColorCookieManCell.call(this, x, y);
    BaseRandomCell.call(this);
};

EditorColorCookieManRandomCell.prototype = Object.create(ColorCookieManCell.prototype);
EditorColorCookieManRandomCell.prototype.constructor = EditorColorCookieManRandomCell;

EditorColorCookieManRandomCell.prototype.getViewClass = function () {
    return EditorColorCookieManRandomCellView;
};
EditorColorCookieManRandomCell.prototype.save = function () {
    var code = ColorCookieManCell.CODES.concat([Loader.WILDCARD]);
    var markComponent = this.findComponent(MarkComponent);
    if (markComponent && markComponent.mark) {
        code.push(markComponent.mark.code);
    }
    return code;
};

var EditorLollipopRandomCell = function (x, y) {
    LollipopCell.call(this, x, y);
    BaseRandomCell.call(this);
};

EditorLollipopRandomCell.prototype = Object.create(LollipopCell.prototype);
EditorLollipopRandomCell.prototype.constructor = EditorLollipopRandomCell;

EditorLollipopRandomCell.prototype.getViewClass = function () {
    return EditorLollipopRandomCellView;
};

EditorLollipopRandomCell.prototype.save = function () {
    var code = LollipopCell.CODES.concat([Loader.WILDCARD]);
    var markComponent = this.findComponent(MarkComponent);
    if (markComponent.mark) {
        code.push(markComponent.mark.code);
    }
    return code;
};

var EditorBagDecoratorRandomCell = function (x, y) {
    BagDecoratorCell.call(this, x, y);
};

EditorBagDecoratorRandomCell.prototype = Object.create(BagDecoratorCell.prototype);
EditorBagDecoratorRandomCell.prototype.constructor = EditorBagDecoratorRandomCell;

EditorBagDecoratorRandomCell.prototype.getViewClass = function () {
    return EditorBagDecoratorRandomCellView;
};

EditorBagDecoratorRandomCell.prototype.save = function () {
    var code = BagDecoratorCell.CODES.slice();
    code.push(Loader.WILDCARD);
    return code.concat(this.innerCell.save());
};
