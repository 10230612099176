/**
 * Created by mac on 1/30/20
 */

cleverapps.SkinManager.SKINS.halloween = {
    bundle: "halloween",
    localization: {},
    slots: {
        localization: function () {},
        windowDecors: function (options) {
            var decors = cleverapps.clone(options.decors || {}, true);

            decors["window_liana_left"] = false;
            decors["window_liana_right"] = false;
            decors["window_liana_bottom_left"] = false;
            decors["window_liana_top_left"] = false;
            decors["window_liana_bottom_right"] = false;
            decors["window_liana_top_right"] = false;

            if (options.window instanceof ConfirmExitWindow) {
                decors["halloween_window_pumpkin"] = true;
                decors["halloween_window_spider"] = true;
                return decors;
            }

            decors["halloween_window_candle_right"] = true;
            decors["halloween_window_candle_left"] = typeof UnitsLibraryWindow === "undefined" || cleverapps.config.ui !== "wooden" || !(options.window instanceof UnitsLibraryWindow);
            decors["halloween_window_lian_left"] = true;
            decors["halloween_window_lian_top"] = true;
            decors["halloween_window_lian_right"] = true;
            decors["halloween_window_lian_bottom"] = true;
            decors["halloween_window_pumpkin"] = true;
            decors["halloween_window_spider"] = true;
            decors["halloween_window_decor_bg_right"] = true;

            var noTopDecors = [SalePassWindow, BuildPassWindow, ExpeditionPassWindow];
            if (noTopDecors.some(function (type) {
                return options.window instanceof type;
            })) {
                decors["halloween_window_lian_top"] = false;
                decors["halloween_window_pumpkin"] = false;
            }

            return decors;
        },
        menuBarItem: function () {
            return cleverapps.styles.SceneDecors.halloween_menubar;
        },

        menuBarLevelItem: function () {
            return cleverapps.styles.SceneDecors.halloween_menubar_level;
        }
    }
};
