/**
 * Created by ivan on 25.05.18.
 */

var CandleCell = function (x, y) {
    BaseCell.call(this, x, y);
    this.isForGoal = x === undefined;
    this.state = CandleCell.STATE_OFF;
    this.movable = false;

    this.onChangeStateListener = function () {}; 

    if (Game.currentGame && Game.currentGame.callsOnFieldReady.indexOf(CandleCell.onGameStart) < 0) {
        Game.currentGame.callsOnFieldReady.push(CandleCell.onGameStart);
    }

    if (Game.currentGame) {
        Game.currentGame.counter.registerStage(150, CandleCell.applyIncGoal);
    }
};

CandleCell.prototype = Object.create(BaseCell.prototype);
CandleCell.prototype.constructor = CandleCell;

CandleCell.onGameStart = function () {
    CandleCell.incDelta = 0;
    if (cleverapps.loadedSnapshot && cleverapps.loadedSnapshot.games && cleverapps.loadedSnapshot.games.goals && cleverapps.loadedSnapshot.games.goals[CandleCell.GOAL_ID]) {
        return;
    }
    for (var i = 0; i < Field.SIZE; ++i) {
        for (var j = 0; j < Field.SIZE; ++j) {
            var cell = Game.currentGame.field.cells[i][j];
            if (cell && cell.constructor === CandleCell && cell.state === CandleCell.STATE_ON) {
                cell.updateGoal(+1);
            }
        }
    }
};

CandleCell.prototype.bundleId = function () {
    return "candle";
};

CandleCell.prototype.load = function (data) {
    this.state = data[1];
};

CandleCell.prototype.save = function () {
    return CandleCell.CODES.concat([this.state]);
};

CandleCell.prototype.getViewClass = function () {
    return CandleCellView;
};

CandleCell.prototype.getGoalId = function () {
    return CandleCell.GOAL_ID;
};

CandleCell.prototype.boom = function () {
    this.hurt();
};

CandleCell.applyIncGoal = function () {
    if (CandleCell.incDelta > 0) {
        Game.currentGame.goalCounter.setTimeout(function () {
            Game.currentGame.goals.incGoal(CandleCell.GOAL_ID, CandleCell.incDelta);
            CandleCell.incDelta = 0;
        }, 0);
    }
};

CandleCell.prototype.updateGoal = function (delta) {
    if (Game.currentGame && Game.currentGame.goals.hasType(CandleCell.GOAL_ID)) {
        if (delta < 0) {
            Game.currentGame.goalCounter.setTimeout(function () {
                Game.currentGame.goals.incGoal(CandleCell.GOAL_ID, delta);
            }, 0);
        } else {
            CandleCell.incDelta += delta;
        }
    }
};

CandleCell.prototype.hurt = function (params) {
    params = params || {};

    if (Game.currentGame.goals.isDone) {
        return;
    }

    // To prevent from turning candle off with a booster the same move it was turned on
    if (this.wasHurt && this.state === CandleCell.STATE_ON && params.boosterId !== undefined) {
        return;
    }
    this.wasHurt = true;

    if (this.state === CandleCell.STATE_ON) {
        this.state = CandleCell.STATE_OFF;
        this.updateGoal(-1);
    } else {
        this.state = CandleCell.STATE_ON;
        this.updateGoal(+1);
    }

    this.onChangeStateListener();
};

CandleCell.prepare = function () {
    if (!Game.currentGame) {
        return;
    }

    for (var i = 0; i < Field.SIZE; i++) {
        for (var j = 0; j < Field.SIZE; j++) {
            if (Game.currentGame.field.cells[i][j] && Game.currentGame.field.cells[i][j].constructor === CandleCell) {
                Game.currentGame.field.cells[i][j].wasHurt = false;
            }
        }
    }
};

CandleCell.STATE_ON = "+";
CandleCell.STATE_OFF = "-";

CandleCell.GOAL_ID = "candle_cell";
