/**
 * Created by vtbelo on 28.02.18.
 */
var BaseCellComponent = function (view, cell) {
    view.setPosition(EditorView.alignInTheGrid(cell.x, cell.y));
    view.setLocalZOrder(3);
    cell.currentView = view;
    this.field.addChild(view);
};

var BigCellComponent = function (view, cell) {
    view.setPosition(EditorView.alignInTheGrid(cell.x + 0.5, cell.y + 0.5));
};

var BlanketCellComponent = function (view, cell, saveCallback) {
    var bigComponent = cell.findComponent(BigComponent);
    view.setPosition(EditorView.alignInTheGrid(cell.x + bigComponent.cols / 2 - 0.5, cell.y + bigComponent.rows / 2 - 0.5));
    
    var styles = cleverapps.styles.EditorView.blanketCell.sizeButton;
    var sizeButton = new cleverapps.UI.Button({
        type: cleverapps.styles.UI.Button.Images.editor,
        text: "sz",
        width: styles.width,
        height: styles.height,
        onClicked: function () {
            var editor = cleverapps.scenes.getRunningScene().editor;
            var sizesCorrect = false;
            var rows = parseInt(window.prompt("Number of rows:"));
            if (!isNaN(rows)) {
                var cols = parseInt(window.prompt("Number of columns:"));
                if (!isNaN(cols)) {
                    if (rows >= 2 && rows <= editor.cells.length && cols >= 2 && cols <= editor.cells[0].length) {
                        sizesCorrect = true;
    
                        editor.clearBlanketCell(cell);
                        
                        var newCell = new BlanketCell(cell.x, cell.y, rows, cols);
                        newCell.setAcceptedColors(cell.getAcceptedColors());
                        newCell.lives = cell.lives;
                        
                        if (editor.cellCanBeSetUp(newCell.x, newCell.y, newCell)) {
                            if (editor.cells[newCell.y][newCell.x]) {
                                newCell.setInnerCell(editor.cells[newCell.y][newCell.x]);
                            } else {
                                newCell.setInnerCell(new EditorRandomCell(newCell.x, newCell.y));
                            }

                            editor.changeCell(newCell);
                            editor.changeBlanketCell(newCell);
                            cell = newCell;
                        } else {
                            editor.changeCell(cell); // return back old cell
                            editor.changeBlanketCell(cell);
                            console.log("BLANKET CELLS CAN'T BE PLACED THERE WITH SIZE ", rows, " x ", cols);
                        }
                        
                        saveCallback();
                    }
                }
            }
            if (!sizesCorrect) {
                console.log("INCORRECT SIZES (must be not less than 2 and not more than field size)");
            }
        }
    });
    view.addChild(sizeButton);
    sizeButton.setPositionRound(view.width - styles.width / 2, styles.height / 2);
    
    styles = cleverapps.styles.EditorView.blanketCell.colorButton;
    var colorButton = new cleverapps.UI.Button({
        type: cleverapps.styles.UI.Button.Images.editor,
        text: "c",
        width: styles.width,
        height: styles.height,
        onClicked: function () {
            if (view.colorChooseWindow) {
                view.colorChooseWindow.removeFromParent(true);
                view.colorChooseWindow = undefined;
                return;
            }
            var editor = cleverapps.scenes.getRunningScene().editor;
            var node = view.colorChooseWindow = new cc.Node();
            node.setAnchorPoint(0.5, 0.5);
            var bg = new cc.Scale9Sprite(new cc.Sprite(bundles.request_center.frames.friend_message_bg_png).getSpriteFrame());
            node.addChild(bg);
            node.setLocalZOrder(11);
    
            var colors = ["a", "b", "c", "d", "e", "w"];
            var height;
            colors.forEach(function (color) {
                var button = new cleverapps.UI.Button({
                    width: cleverapps.styles.EditorView.blanketCell.colorItem.width,
                    height: cleverapps.styles.EditorView.blanketCell.colorItem.height,
                    onClicked: function () {
                        cell.setAcceptedColors([color]);
                        editor.onDeleteCell(editor.cells[cell.y][cell.x]);
                        editor.onCellChanged(cell);
                        saveCallback();
    
                        view.colorChooseWindow.removeFromParent(true);
                        view.colorChooseWindow = undefined;
                    },
                    content: new cc.Sprite(bundles.color_cells.frames["color_cells_" + color])
                });
        
                height = button.height;
                button.setPosition(bg.width / 2, 0);
                bg.addChild(button);
            });
    
            bg.setContentSize2(bg.width, height * colors.length + (colors.length - 1) * cleverapps.styles.EditorView.blanketCell.colorItem.margin);
            node.setContentSize2(bg.getContentSize());
            bg.setPositionRound(node.width / 2, node.height / 2);
    
            cleverapps.UI.arrangeWithMargins(bg.children, {
                direction: cleverapps.UI.VERTICAL,
                margin: cleverapps.styles.EditorView.blanketCell.colorItem.margin
            });
    
            cleverapps.scenes.getRunningScene().addChild(node);
            var p = EditorView.alignInTheGrid(cell.x, cell.y);
            p.x += cleverapps.styles.BaseCellView.CELL_SIZE_PX * (bigComponent.cols - 1) / 2;
            p.y -= cleverapps.styles.BaseCellView.CELL_SIZE_PX * (bigComponent.rows - 1) / 2;
            p.x += view.width / 2 + node.width / 2 + cleverapps.styles.BaseCellView.CELL_SIZE_PX / 4;
            var globalViewPos = view.getParent().convertToWorldSpace(p);
            node.setPositionRound(globalViewPos.x, globalViewPos.y);
        }
    });
    view.addChild(colorButton);
    colorButton.setPositionRound(styles.width / 2, styles.height / 2);
};

var changeLives = function (view, cell, lives) {
    var min = cell.getMinLives ? cell.getMinLives() : 1;
    var max = Number.MAX_VALUE;

    var muffinComponent = cell.findComponent(MuffinComponent);
    if (cell.getMaxLives) {
        max = cell.getMaxLives();
    } else if (muffinComponent) {
        max = muffinComponent.getMaxLives();
    }

    if (lives >= min && lives <= max) {
        cell.lives = lives;

        var liveLineComponent = cell.findComponent(LiveLineComponent);
        if (view.changeLives) {
            view.changeLives(true);
        } else if (liveLineComponent) {
            liveLineComponent.view.changeLives(true);
        }

        this.needSave();
    }
};

var SpecialShapeCellComponent = function (view, cell) {
    var size = cell.findComponent(SpecialShapeComponent).getSize();
    view.setPosition(EditorView.alignInTheGrid(cell.topLeftX + size.cols / 2 - 0.5, cell.topLeftY + size.rows / 2 - 0.5));

    var styles = cleverapps.styles.EditorView.specialShapeCell.rotationButton;

    var button = new cleverapps.UI.Button({
        type: cleverapps.styles.UI.Button.Images.editor,
        text: "r",
        width: styles.width,
        height: styles.height,
        onClicked: function () {
            var editor = cleverapps.scenes.getRunningScene().editor;
            editor.deleteSpecialShapeCell(cell);
            cell.rotation = (cell.rotation + 1) % 4;
            var saveData = cell.findComponent(SpecialShapeComponent).getSaveData().join("");
            editor.clickField(cell.topLeftX, cell.topLeftY, saveData, "goal_cell");
            this.needSave();
        }.bind(this)
    });

    view.addChild(button);
    button.setPositionRound(view.width / 2 + styles.x, view.height / 2 + styles.y);
};

var MovesCellComponent = function (view, cell) {
    var incDecBlock = new IncDecBlock({
        value: cell.moves,
        range: [1, Infinity],
        onChange: function (value) {
            cell.moves = value;
            view.updateMoves();
            this.needSave();
        }.bind(this),
        radius: cleverapps.styles.EditorView.colorBombCell.radius.width
    });
    incDecBlock.setPosition(view.width / 2, cleverapps.styles.EditorView.colorBombCell.y);
    view.addChild(incDecBlock);
};

var CannonCellComponent = function (view, cell) {
    var styles = cleverapps.styles.EditorView.cannonCell;
    var window;
    var loader = new Loader();

    var itemsByDir = {};

    var selectCurrDir = function (dir) {
        var currItem = itemsByDir[cell.currDir];
        if (currItem && currItem.dirView.currDirCheck) {
            currItem.dirView.currDirCheck.removeFromParent(true);
            delete currItem.dirView.currDirCheck;
        }

        cell.currDir = dir;

        var currDirCheck = new cc.Sprite(bundles.checkbox.frames.check_mark_png);
        itemsByDir[dir].dirView.addChild(currDirCheck);
        itemsByDir[dir].dirView.currDirCheck = currDirCheck;
        currDirCheck.setPositionRound(styles.currDirCheckBox.x, view.height + styles.currDirCheckBox.y);
    
        view.angle = CannonCellView.ROTATION_BY_DIR[dir];
        view.animationsNode.setRotation(-view.angle);
        
        setSelected(dir);
    };

    var setSelected = function (dir) {
        if (cell.dirs.indexOf(dir) !== -1) {
            return;
        }

        cell.dirs.push(dir);
        cell.dirs.sort();
        itemsByDir[dir].checkBox.setSelected(true);
    };

    var createContent = function () {
        var dirs = Object.keys(BaseCell.DIRECTIONS).map(function (key) {
            return parseInt(key);
        });
        var amountInRow = 4;
        var rows = [];
        while (dirs.length > 0) {
            var itemsInRow = [];
            dirs.splice(0, amountInRow).forEach(function (dir) {
                var checkBox = new cleverapps.UI.CheckBox({
                    onChange: function (state) {
                        if (state) {
                            setSelected(dir);
                        } else {
                            if (cell.dirs.length === 1) {
                                checkBox.setSelected(true);
                                return;
                            }

                            var index = cell.dirs.indexOf(dir);
                            if (index !== -1) {
                                cell.dirs.splice(index, 1);
                            }

                            if (dir === cell.currDir) {
                                selectCurrDir(cell.dirs[0]);
                            }
                        }
                        this.needSave();
                    }.bind(this),
                    isSelected: cell.dirs.indexOf(dir) !== -1
                });

                var code = ("m3" + dir + "1" + (1 << dir)).split("");
                var dirCell = loader.loadNext(this.y, this.x, code);
                var dirView = new (dirCell.getViewClass())(dirCell);
                dirView.setLocalZOrder(10);
                dirView.setPosition(0, 0);

                cleverapps.UI.onClick(dirView, function () {
                    if (dir === cell.currDir) {
                        return;
                    }

                    selectCurrDir(dir);
                    this.needSave();
                }.bind(this));

                var item = new cleverapps.Layout([dirView, checkBox], {
                    direction: cleverapps.UI.VERTICAL,
                    margin: styles.itemsMargin
                });

                itemsByDir[dir] = {
                    checkBox: checkBox,
                    dirView: dirView
                };

                if (dir === cell.currDir) {
                    selectCurrDir(dir);
                }

                itemsInRow.push(item);
            }.bind(this));

            var row = new cleverapps.Layout(itemsInRow, {
                direction: cleverapps.UI.HORIZONTAL,
                margin: styles.rowsMargin
            });

            rows.push(row);
        }

        var dirsLayout = new cleverapps.Layout(rows, {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.rowsMargin
        });

        var clockwiseCheckBox = new cleverapps.UI.CheckBox({
            onChange: function (state) {
                cell.clockwise = state;
                this.needSave();
            }.bind(this),
            label: {
                text: "Clockwise"
            },
            isSelected: cell.clockwise
        });

        var layout = new cleverapps.Layout([dirsLayout, clockwiseCheckBox], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.clockwiseCheckBoxMargin
        });

        return layout;
    }.bind(this);

    var button = new cleverapps.UI.Button({
        type: cleverapps.styles.UI.Button.Images.editor,
        text: "r",
        width: styles.dirButton.width,
        height: styles.dirButton.height,
        onClicked: function () {
            if (window) {
                window.close();
                window = undefined;
                return;
            }
            window = EditorWindow.create(createContent(), cell, view, {
                onClose: function () {
                    window = undefined;
                }
            });
        }
    });

    view.addChild(button);
    button.setPositionRound(view.width / 2 + styles.dirButton.x, view.height / 2 + styles.dirButton.y);
};

var ChangeLivesOnClickComponent = function (view, cell, cb) {
    cell.changeLivesOnClick = function () {
        var lives = parseInt(window.prompt("Lives", cell.lives));
        if (!isNaN(lives)) {
            cb(view, cell, lives);
        }
    };
};

var LiveLineEditorComponent = function (view, cell) {
    var incDecBlock = new IncDecBlock({
        value: cell.lives,
        range: [1, Infinity],
        onChange: function (value) {
            changeLives.call(this, view, cell, value);
        }.bind(this)
    });
    ChangeLivesOnClickComponent.call(this, view, cell, function (view, cell, lives) {
        changeLives.call(this, view, cell, lives);
        incDecBlock._setValue(lives, true);
    }.bind(this));
    var x = view.width / 2;
    var y = view.height / 2;
    if (view instanceof FigureDragonCellView) {
        view.lives.setVisible(false);
    }
    incDecBlock.setPositionRound(x, y);
    view.addChild(incDecBlock);
};

var FireflyJarComponent = function (view, cell) {
    var incDecBlock = new IncDecBlock({
        value: cell.lives,
        range: [1, cell.getMaxLives ? cell.getMaxLives() : Number.MAX_VALUE],
        onChange: function (value) {
            changeLives.call(this, view, cell, value);
            livesText.setString(value);
        }.bind(this),

        radius: cleverapps.styles.EditorView.fireflyJarCell.radius.width
    });

    incDecBlock.setPosition(view.width / 2, cleverapps.styles.EditorView.fireflyJarCell.y);
    view.addChild(incDecBlock);

    var livesText = cleverapps.UI.generateImageText(cell.lives, cleverapps.styles.FONTS.EDITOR_BLACK_TEXT);
    view.addChild(livesText);
    livesText.setPositionRound(view.width / 2, view.height / 2);

    ChangeLivesOnClickComponent.call(this, view, cell, function (view, cell, lives) {
        changeLives.call(this, view, cell, lives);
        incDecBlock._setValue(lives, true);
        livesText.setString(cell.lives);
    }.bind(this));
};

var BlanketCellComponentCreator = function (view, cell) {
    new BlanketCellComponent(view, cell, this.needSave.bind(this));
};

var MultiColorBombCellComponentCreator = function (view, cell) {
    new MultiColorBombCellComponent(view, cell, this.needSave.bind(this));
};

var ClockCellComponentCreator = function (view, cell) {
    new ClockCellComponent(view, cell, this.needSave.bind(this));
};

var ColorBombCellComponent = function (view, cell) {
    var incDecBlock = new IncDecBlock({
        value: cell.lives,
        range: [1, cell.getMaxLives ? cell.getMaxLives() : Number.MAX_VALUE],
        onChange: function (value) {
            changeLives.call(this, view, cell, value);
        }.bind(this),

        radius: cleverapps.styles.EditorView.colorBombCell.radius.width
    });
    ChangeLivesOnClickComponent.call(this, view, cell, function (view, cell, lives) {
        changeLives.call(this, view, cell, lives);
        incDecBlock._setValue(lives, true);
    }.bind(this));

    incDecBlock.setPosition(view.width / 2, cleverapps.styles.EditorView.colorBombCell.y);
    view.addChild(incDecBlock);
};

var BombCellComponent = function (view, cell) {
    var changeUntilExplode = function (view, cell, basicUntilExplode) {
        if (basicUntilExplode >= 1) {
            cell.basicUntilExplode = basicUntilExplode;
            cell.untilExplode = cell.basicUntilExplode;
            view.onUntilExplodeChanged();
            this.needSave();
        }
    }.bind(this);

    var incDecBlock = new IncDecBlock({
        value: cell.basicUntilExplode,
        range: [1, Infinity],
        onChange: function (value) {
            changeUntilExplode(view, cell, value);
        },
        radius: cleverapps.styles.EditorView.bombCell.radius.width
    });

    ChangeLivesOnClickComponent.call(this, view, cell, function (view, cell, lives) {
        changeUntilExplode(view, cell, lives);
        incDecBlock._setValue(lives, true);
    });

    incDecBlock.setPosition(view.width / 2, cleverapps.styles.EditorView.bombCell.y);
    view.addChild(incDecBlock);
};

var SwitchModeComponent = function (view, cell) {
    var styles = cleverapps.styles.EditorView.switchMode;

    var createType = cleverapps.UI.generateOnlyText("SIMPLE", cleverapps.styles.FONTS.EDITOR_FIELD_TEXT);
    createType.setDimensions(styles.width, 0);
    createType.fitTo(undefined, styles.height);
    createType.setAnchorPoint(0, 0.5);
    var drawCreateType = function () {
        createType.setString(cell.createType === CreateTypes.NOT_CREATE_AGAIN_TYPE ? "SIMPLE" : "AUTO");
    };
    drawCreateType();
    createType.setPosition(0, view.height - createType.height / 2);
    view.addChild(createType);

    cleverapps.UI.onClick(createType, function () {
        if (cell.createType === CreateTypes.NOT_CREATE_AGAIN_TYPE) {
            cell.createType = CreateTypes.CREATE_AGAIN_TYPE;
        } else {
            cell.createType = CreateTypes.NOT_CREATE_AGAIN_TYPE;
        }
        drawCreateType();
        this.needSave();
    }.bind(this));

    cleverapps.UI.applyHover(createType);
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    EDITOR_FIELD_TEXT: {
        size: 20,
        color: cleverapps.styles.COLORS.BLACK
    }
});