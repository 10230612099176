/**
 * Created by andrey on 19.05.2022.
 */

var CenterHint = function () {
    this.onShowHint = function () {};
    this.onHideHint = function () {};
};

CenterHint.prototype.createTextHint = function (message, toReplace) {
    this.create({
        message: message,
        toReplace: toReplace
    });
};

CenterHint.prototype.listAvailableHints = function () {
    if (cleverapps.config.type === "merge") {
        return [MissionTreeHintContent, ProducerHintContent, UnmergeableUnitHintContent, MergeBonusHintContent, FeedableHintContent, TextHintContent];
    }
    return [TextHintContent];
};

CenterHint.prototype.create = function (options) {
    if (this.content && this.content.hasSameContent(options)) {
        this.hideWaitComplete = false;

        if (this.contentWasShown) {
            this.schedule(CenterHint.DURATION, this.finishCenterHint.bind(this));
        }
        return;
    }

    var availableHints = this.listAvailableHints();
    var HintContent = availableHints.filter(function (hintContents) {
        return hintContents.isApplicable(options);
    })[0];

    if (HintContent) {
        this.hideHint();
        this.hideWaitComplete = false;
        this.contentWasShown = false;

        this.content = new HintContent(options);

        var messages = this.content.getMessages();
        var position = Dialogue.POSITIONS.TOP;
        if (this.content.doNotBlock) {
            var centerCell = Map2d.currentMap.getScreenCenterCell();
            position = Map2dView.IsoToScreen(this.content.doNotBlock.x, this.content.doNotBlock.y).y > Map2dView.IsoToScreen(centerCell.x, centerCell.y).y ? Dialogue.POSITIONS.BOTTOM : Dialogue.POSITIONS.TOP;
        }

        if (options.wrongMerged) {
            this.schedule(CenterHint.WRONG_MERGED_DELAY, this.showHint.bind(this, messages, position));
        } else {
            this.showHint(messages, position);
        }
    }
};

CenterHint.prototype.finishCenterHint = function () {
    if (this.content) {
        this.hideWaitComplete = true;

        if (this.contentWasShown) {
            this.schedule(CenterHint.DELAY_BEFORE_HIDING, this.hideHint.bind(this));
        }
    }
};

CenterHint.prototype.onCompleteAnimation = function () {
    if (this.content && this.hideWaitComplete) {
        this.hideHint();
    }
};

CenterHint.prototype.showHint = function (messages, position) {
    if (cleverapps.gameModes.silentCenterHint) {
        return;
    }

    if (this.hideWaitComplete) {
        this.hideHint();
        return;
    }

    this.contentWasShown = true;
    this.onShowHint(messages, position);
    this.schedule(CenterHint.DURATION, this.finishCenterHint.bind(this));
};

CenterHint.prototype.hideHint = function () {
    this.content && this.content.onComplete && this.content.onComplete();
    this.clearTimeout();
    this.onHideHint();
    this.content = undefined;
};

CenterHint.prototype.destructor = function () {
    this.clearTimeout();
};

CenterHint.prototype.clearTimeout = function () {
    if (this.timeout !== undefined) {
        cleverapps.timeouts.clearTimeout(this.timeout);
        this.timeout = undefined;
    }
};

CenterHint.prototype.schedule = function (timeout, callback) {
    this.clearTimeout();
    this.timeout = cleverapps.timeouts.setTimeout(callback, timeout);
};

CenterHint.DURATION = 2500;
CenterHint.WRONG_MERGED_DELAY = 600;
CenterHint.DELAY_BEFORE_HIDING = 200;
