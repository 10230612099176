/**
 * Created by slava on 02.02.17.
 */

var BaseTileView = {};

BaseTileView.initialize = function (tile) {
    this.tile = tile;

    this.setContentSize(cleverapps.styles.BaseCellView.CELL_SIZE_PX, cleverapps.styles.BaseCellView.CELL_SIZE_PX);
    this.setLocalZOrder(2);

    if (!tile.isForGoal) {
        this.setPosition(BaseCellView.alignInTheGrid(tile.x, tile.y));
    }

    tile.onBeforeExplodeListener = this.startExploding.bind(this);
    tile.onAnimationListener = this.showAnimation.bind(this);
    tile.onGetView = function () {
        return this;
    }.bind(this);
};

BaseTileView.delete = function () {
    if (this.tile && this.tile.putInPoolAvailable && !cleverapps.gameModes.putInPoolDisabled) {
        cc.pool.putInPool(this);
    } else {
        this.removeFromParent();
    }
};

BaseTileView.startExploding = function (duration) {
    this.stopAllActions();

    this.runAction(new cc.Sequence(new cc.Spawn(new cc.RotateBy(duration, 720), new cc.ScaleTo(duration, 0)), new cc.CallFunc(function () {
        this.delete();
    }, this)));
};

BaseTileView.beforeShowUpAnimation = function () {
    if (!Game.currentGame.started) {
        this.setScale(0);
    }
};

BaseTileView.showUp = function (options) {
    options = options || {};
    if (options.silent) {
        this.setScale(1);
        if (options.callback) {
            options.callback();
        }
        return;
    }

    this.runAction(new cc.Sequence(
        new cc.DelayTime(0.1 + (this.tile.y * 1.2 + this.tile.x) * 0.4 / Field.SIZE),
        new cc.ScaleTo(0.4, 1.0).easing(cc.easeBackOut()),
        new cc.CallFunc(function () {
            if (options && options.callback) {
                options.callback();
            }
        })
    ));
};

BaseTileView.hide = function () {
    this.runAction(new cc.Sequence(
        new cc.DelayTime(0.1 + ((Field.SIZE - 1 - this.tile.y) * 1.2 + this.tile.x) * 0.4 / Field.SIZE),
        new cc.ScaleTo(0.3, 0.5).easing(cc.easeBackIn())
    )).setFinalize(function () {
        this.setVisible(false);
    }.bind(this));
};

BaseTileView.showAnimation = function (type, params) {
    switch (type) {
        case BaseCell.ANIMATION_SHOWUP:
            this.showUp(params);
            break;
        case BaseCell.ANIMATION_HIDE:
            this.hide();
            break;
    }
};

BaseTileView.reuse = function (tile) {
    this.initialize(tile);
};

BaseTileView.unuse = function () {
    this.removeFromParent();

    this.tile.onBeforeExplodeListener = function () {};
    this.tile.onAnimationListener = function () {};
    this.tile.onGetView = function () {};
    delete this.tile;
};