/**
 * Created by Denis Kuzin on 22 december 2022
 */

var PaginationView = cleverapps.Spine.extend({
    avoidNode: "PaginationView",

    ctor: function (pagination) {
        this.pagination = pagination;
        this._super(bundles.pagination.jsons[this.getJsonName()]);

        this.setAnimation(0, this.getIdleAnimationName());

        this.pagination.on("changePage", this.createListener(this.changePage.bind(this)));

        this.setPositionRound(cleverapps.styles.PaginationView.position);
    },

    changePage: function () {
        this.setAnimationAndIdleAfter("open_" + this.pagination.page, this.getIdleAnimationName());
    },

    getJsonName: function () {
        return "pagination_" + this.pagination.total + "_json";
    },

    getIdleAnimationName: function () {
        return "idle_" + this.pagination.page;
    }
});

cleverapps.styles.PaginationView = {
    position: [
        {
            x: { align: "center", dx: 0 },
            y: { align: "top", dy: -220 }
        },
        {
            x: { align: "center", dx: 0 },
            y: { align: "top", dy: -30 }
        },
        {
            x: { align: "center", dx: 0 },
            y: { align: "top", dy: -30 }
        }
    ]
};
