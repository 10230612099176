/**
 * Created by vladislav on 2/13/2024
 */

var Invites = function () {};

Invites.prototype.invite = function (callback) {
    var data = {
        text: Messages.get("InviteFriends.message"),
        title: Messages.get(cleverapps.config.title)
    };
    
    var image = Share.getData("default").image;
    if (connector.platform.oneOf(connector.WORTAL)) {
        image = Share.getData("wortal").image;
    }

    new ActionPlayer([
        function (f) {
            if (connector.social.isNeedsLoginForInvite) {
                cleverapps.SocialManager.enforceLogin(function (code) {
                    if (code === cleverapps.CODE_SUCCEED) {
                        f();
                    } else {
                        callback(false);
                    }
                });
            } else {
                f();
            }
        },
        function (f) {
            var needsBase64 = connector.platform.oneOf(connector.INSTANT, connector.WORTAL, connector.TEST);
            if (needsBase64) {
                cleverapps.loadBase64Image(image, function (base64Picture) {
                    if (base64Picture) {
                        data.image = base64Picture;

                        f();
                    } else {
                        callback(false);
                    }
                });
            } else {
                data.image = image;

                f();
            }
        },
        function () {
            connector.social.inviteCallback(data, callback);
        }
    ]).play();
};