/**
 * Created by Denis Kuzin on 04 july 2024
 */

var GoalCoefComponentView = cc.Node.extend({
    ctor: function (component, cellView) {
        this._super();

        this.component = component;
        this.cellView = cellView;

        this.coefView = CoefView.factory(this.component.coef);
        this.coefView.setPositionRound(cleverapps.styles.GoalCoefComponentView.position);
        this.addChild(this.coefView);

        this.setContentSize(this.coefView.getContentSize());
        this.setCascadeOpacityEnabled(true);

        this.component.onBeforeResetCoef = this.onWillResetCoef.bind(this);
        this.component.onBeforeIncCoef = this.onWillIncCoef.bind(this);
    },

    onWillResetCoef: function (duration) {
        this.coefView.hide(duration);
    },

    onWillIncCoef: function (duration) {
        this.coefView.popUp(duration);
    },

    onDestruction: function () {
        delete this.component.onBeforeResetCoef;
        delete this.component.onBeforeIncCoef;

        this.coefView.remove();
        delete this.coefView;
    }
});

cleverapps.styles.GoalCoefComponentView = {
    position: {
        x: { align: "center", dx: 38 },
        y: { align: "center", dy: -44 }
    }
};
