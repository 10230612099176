/**
 * Created by Ivan on 26.04.2024
 */

var Sticker = function (options) {
    cleverapps.EventEmitter.call(this);

    this.collection = options.collection;
    this.image = options.image;
    this.title = options.title;
    this.state = options.state;
    this.rarity = options.rarity;
    this.attention = options.attention && this.isOpen();
};

Sticker.prototype = Object.create(cleverapps.EventEmitter.prototype);
Sticker.prototype.constructor = Sticker;

Sticker.prototype.open = function (source) {
    if (this.getState() === Sticker.STATE_CLOSED) {
        this.state = Sticker.STATE_OPENED;
        this.setAttention(true);

        this.trigger("update");
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STICKER_OPEN + source);
    }
};

Sticker.prototype.setAttention = function (state) {
    this.attention = state;
    this.trigger("update");

    cleverapps.stickersBook.onChangeAttentionListener();
    cleverapps.stickersBook.save();
};

Sticker.prototype.getViewClass = function () {
    return StickersBook.isPuzzle() ? PuzzleStickerView : StickerView;
};

Sticker.prototype.getIndex = function () {
    return this.collection.stickers.indexOf(this);
};

Sticker.prototype.hasAttention = function () {
    return this.attention;
};

Sticker.prototype.getState = function () {
    return this.state;
};

Sticker.prototype.isOpen = function () {
    return this.state === Sticker.STATE_OPENED;
};

Sticker.prototype.isRare = function () {
    return this.rarity === Sticker.RARITY_RARE;
};

Sticker.STATE_CLOSED = 0;
Sticker.STATE_OPENED = 1;

Sticker.RARITY_REGULAR = "regular";
Sticker.RARITY_RARE = "rare";

Sticker.SOURCES = {
    joker: "joker",
    buy: "buy",
    level: "level"
};