/**
 * Created by Andrey Popov on 17.10.2022.
 */

var SelectorView = cc.Node.extend({
    ctor: function (items, selected, onSelect) {
        this._super();

        var styles = cleverapps.styles.SelectorView;

        var selectorItems = items || [];
        this.buttons = selectorItems.map(function (item) {
            var itemButton = new cleverapps.UI.Button({
                width: styles.button.width,
                height: styles.button.height,
                type: cleverapps.styles.UI.Button.Images.wysiwyg,
                text: item.split("_")[1] || item,
                disabled: selected === item,
                onClicked: function () {
                    this.buttons.forEach(function (button) {
                        button.enable();
                    });
                    itemButton.disable();
                    onSelect(item);
                }.bind(this)
            });
            return itemButton;
        }.bind(this));
        var itemsLayout = new cleverapps.Layout(this.buttons, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });
        itemsLayout.setPositionRound(itemsLayout.width / 2, 0);

        this.addChild(itemsLayout);
        this.setContentSize2(itemsLayout.getContentSize());
    }
});

cleverapps.styles.SelectorView = {
    height: 26,
    width: 400,
    margin: 10,

    button: {
        width: 90,
        height: 26
    }
};