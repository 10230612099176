/**
 * Created by andrey on 29.05.17.
 */
var EditorGoal = function (editor) {
    this.type = false;
    this.target = 10;
    this.auto = false;
    this.editor = editor;

    this.onChangeGoal = function () {};
    this.goalChanged = function () {};
};

EditorGoal.prototype.isProvidedGoal = function () {
    if (EditorGoal.FALLING_GOALS[this.type] || (this.type && GoalCoefComponent.CODES.indexOf(this.type.toUpperCase()) !== -1)
            || this.type === RugTile.GOAL_ID) {
        return true;
    }
    return false;
};

EditorGoal.prototype.countInField = function () {
    var getGoalCell = function (cell, goalType) {
        var goalCell = undefined;
        var markComponent = cell.findComponent && cell.findComponent(MarkComponent);

        if (cell.getGoalId && cell.getGoalId() === goalType) {
            goalCell = cell;
        }
        if (markComponent && markComponent.mark && goalType === markComponent.mark.getGoalId()) {
            goalCell = cell;
        }
        if (cell.innerCell) {
            var currCell = cell.innerCell;
            while (currCell) {
                var currMarkComponent = currCell.findComponent(MarkComponent);
                if ((currCell.getGoalId && currCell.getGoalId() === goalType) || (currMarkComponent && currMarkComponent.mark && goalType === currMarkComponent.mark.getGoalId())) {
                    goalCell = currCell;
                    break;
                }
                currCell = currCell.innerCell;
            }
        }
        return goalCell;
    };

    var bigCells = [DragonCell, MouseCell, DogCell, GingerHouseCell, CakeCell, ClockCell, BlanketCell, FigureDragonCell];
    var bigTiles = [RabbitTile];
    var multiValueGoals = [FireflyJarCell];

    var cellsRugWontGrowUnder = [IceCreamMakerCell, DonutBoxCell, CrabMakerCell, BurstCell, DirtMakerCell, CandleCell];
    var tilesRugWontGrowOn = [TransporterTile, GrassTile, IceBlockMakerTile, RugTile, SyrupTile];

    var iceMakerExists = false, iceExists = false;
    var tilesToGrowIntoAmount = 0;
    var amountInField = 0;

    for (var i = 0; i < Field.SIZE; i++) {
        for (var j = 0; j < Field.SIZE; j++) {
            if (this.editor.cells[i][j]) {
                var goalCell = getGoalCell(this.editor.cells[i][j], this.type);
                if (goalCell) {
                    if (bigCells.indexOf(goalCell.constructor) !== -1) {
                        var component = goalCell.findComponent([BigComponent, SpecialShapeComponent]);
                        if (component.isHead) {
                            if (goalCell instanceof ClockCell) {
                                amountInField += goalCell.lives;
                            } else {
                                amountInField++;
                            }
                        }
                    } else if (multiValueGoals.indexOf(goalCell.constructor) !== -1) {
                        amountInField += goalCell.lives;
                    } else {
                        amountInField++;
                    }
                }
            }

            if (this.editor.floor[i][j]) {
                var goalTile = getGoalCell(this.editor.floor[i][j], this.type);
                if (goalTile) {
                    if (bigTiles.indexOf(goalTile.constructor) !== -1) {
                        if (goalTile.isRoot) {
                            amountInField++;
                        }
                    } else {
                        amountInField++;
                    }
                }
            }

            if (this.editor.cells[i][j] && this.editor.cells[i][j] instanceof IceMakerCell) {
                iceMakerExists = true;
            }
            if (this.editor.floor[i][j] && this.editor.floor[i][j] instanceof IceTile) {
                iceExists = true;
            }

            if (this.editor.cells[i][j]) {
                if (cellsRugWontGrowUnder.indexOf(this.editor.cells[i][j].constructor) === -1) {
                    if (!this.editor.floor[i][j] || (this.editor.floor[i][j] && tilesRugWontGrowOn.indexOf(this.editor.floor[i][j].constructor)) === -1) {
                        tilesToGrowIntoAmount++;
                    }
                }
            }
        }
    }

    if (this.type === RugTile.GOAL_ID || this.type === SyrupTile.GOAL_ID) {
        this.tilesToGrowInto = tilesToGrowIntoAmount;
    }

    if (this.type === CakeCell.GOAL_ID) {
        amountInField *= 12;
    }

    if (this.type === IceTile.GOAL_ID && iceMakerExists && !iceExists) {
        amountInField = 5;
    }

    this.inFieldAmount = amountInField;
};

EditorGoal.prototype.setAutoTarget = function () {
    this.countInField();
    if (this.auto) {
        this.target = this.tilesToGrowInto || this.inFieldAmount;
    }

    if (this.inFieldAmount === this.target || this.tilesToGrowInto === this.target) {
        this.auto = true;
    } else {
        this.auto = false;
    }
    this.onChangeGoal();
};

EditorGoal.prototype.toJson = function () {
    var res = {
        type: this.type,
        target: this.target
    };

    if (EditorGoal.FALLING_GOALS[this.type]) {
        this.countInField();
        if (this.inFieldAmount < this.target) {
            res.createOnCollect = {
                type: EditorGoal.FALLING_GOALS[this.type],
                amount: this.target - this.inFieldAmount
            };
        }
    }

    return res;
};

EditorGoal.prototype.setTarget = function (target) {
    if (!this.editor.prepareEdit()) {
        return;
    }

    if (target >= 1000 || !this.type) {
        return;
    }

    this.auto = false;

    if (target <= 0 || (this.inFieldAmount === target || this.tilesToGrowInto === target) || (this.tilesToGrowInto && target > this.tilesToGrowInto)) {
        this.auto = true;
        target = this.tilesToGrowInto || this.inFieldAmount;
    }
    this.target = target;
    this.onChangeGoal();
    this.goalChanged();
};

EditorGoal.prototype.setType = function (type) {
    if (!this.editor.prepareEdit()) {
        return;
    }
    if (type) {
        this.auto = true;
    }
    this.tilesToGrowInto = undefined;
    this.type = type;
    this.setAutoTarget();
    this.onChangeGoal();
    this.goalChanged();
};

EditorGoal.prototype.load = function (data) {
    this.type = data.type;
    this.target = data.target;
    this.setAutoTarget();
    this.onChangeGoal();
};

EditorGoal.FALLING_GOALS = {
    "cell_fish": "F",
    "waffle": "S.",
    "truffle": "Q.",
    "lollipop": "O.",
    "cookie_man": "J",
    "color_cookie_man": "V.",
    "color_container": "g",
    "coin_cell": "+"
};
