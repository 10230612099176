/**
 * Created by vladislav on 22.07.2022
 */

var PersonsLibrary = function () {
    this.data = {};

    Object.keys(cleverapps.config.persons).forEach(function (group) {
        Object.keys(cleverapps.config.persons[group]).forEach(function (role) {
            var name = cleverapps.config.persons[group][role];

            this.add(role, {
                name: name,
                bundle: "persons_" + group,
                json: bundles["person_" + role].jsons[role + "_json"]
            });

            if (bundles["person_" + role] === undefined && cleverapps.config.debugMode) {
                throw "No person for role: " + role;
            }
        }, this);
    }, this);

    Object.keys(cleverapps.config.personsMinimal).forEach(function (role) {
        if (!bundles["person_minimal_" + role] && cleverapps.config.debugMode) {
            throw "No minimalPerson for role: " + role;
        }
    });
};

PersonsLibrary.prototype.add = function (role, person) {
    this.data[role] = person;
};

PersonsLibrary.prototype.choose = function (role1, role2) {
    if (this.data[role1] !== undefined) {
        return role1;
    }

    if (role2 && this.data[role2] !== undefined) {
        return role2;
    }

    return undefined;
};

PersonsLibrary.prototype.getRole = function (role) {
    return this.data[role];
};

PersonsLibrary.prototype.getMinimalJson = function (role) {
    return bundles["person_minimal_" + role] && bundles["person_minimal_" + role].jsons[role + "_minimal_json"];
};