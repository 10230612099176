/**
 * Created by andrey on 29.05.17.
 */
var EditorView = EditorViewBase.extend({
    ctor: function (editor) {
        this._super(editor);

        this.backgrounds = {};

        if (Game.currentGame.hasDanger()) {
            this.dangerComponent = new EditorDangerDurationView(editor);
            this.addChild(this.dangerComponent);

            var counter = new GoalsCounterView(editor);
            this.addChild(counter);
        } else {
            this.movesBlock = new EditorMovesView(editor);
            this.addChild(this.movesBlock);
        }

        this.field = new EditorField();
        this.addChild(this.field);

        this.fieldSizeBlock = new EditorFieldSizeView(this.field);
        this.addChild(this.fieldSizeBlock);

        this.goalsView = new EditorGoalsView(editor.goals);
        this.addChild(this.goalsView);

        this.colorsBlock = new ColorsBlock();
        this.cellsBlock = new CellsBlock();
        this.goalCellsBlock = new GoalCellsBlock();
        this.decoratorsBlock = new DecoratorsBlock();
        this.tilesBlock = new TilesBlock();
        this.marksBlock = new MarksBlock();

        this.tab = new TabMenu({
            color: this.colorsBlock,
            cells: this.cellsBlock,
            goal_cells: this.goalCellsBlock,
            decorators: this.decoratorsBlock,
            tiles: this.tilesBlock,
            marks: this.marksBlock
        });
        this.tab.setScale(0.7);
        this.addChild(this.tab);

        cc.eventManager.addListener({
            event: cc.EventListener.KEYBOARD,
        
            onKeyPressed: function (key) {
                if (key === cc.KEY.shift) {
                    var codesValue = this.tab.table[this.tab.active].savedSelected[this.tab.table[this.tab.active].selected];
                    if (codesValue === undefined) {
                        return;
                    }
                    var codesArray = this.tab.table[this.tab.active].subCodes;
                    if (codesArray === undefined) {
                        return;
                    }
                    var index = codesArray.indexOf(codesValue);
                    if (index < 0) {
                        return;
                    }
                    index++;
                    if (index >= codesArray.length) {
                        index = 0;
                    }
                    this.tab.table[this.tab.active].savedSelected[this.tab.table[this.tab.active].selected] = codesArray[index];
                    this.tab.table[this.tab.active].subChooser.onBeforeSelect(codesArray[index], this.tab.table[this.tab.active].subTable[codesArray[index]]);
                }
            }.bind(this)
        }, this);

        this.setupChildren();
    },

    addBackground: function (x, y) {
        var image = [bundles.game.frames.tile_background_1, bundles.game.frames.tile_background_2][(x + y) % 2];
        var background = cleverapps.UI.createScale9Sprite(image, cleverapps.UI.Scale9Rect.TwoPixelXY);
        background.setContentSize(cleverapps.styles.BaseCellView.CELL_SIZE_PX, cleverapps.styles.BaseCellView.CELL_SIZE_PX);
        background.setPosition(EditorView.alignInTheGrid(x, y));
        background.setLocalZOrder(1);
        this.backgrounds[x + "_" + y] = background;
        this.field.addChild(background);
    },

    deleteBackground: function (x, y) {
        this.backgrounds[x + "_" + y].removeFromParent();
    },

    cellChanged: function (cell) {
        var components = cell.editorComponents();
        if (components) {
            var ViewClass = cell.getViewClass();
            var view = new ViewClass(cell);
            view.beforeShowUpAnimation();
            cell.changeVisible(true); // need after "visible on model" changes for cells
            components.forEach(function (component) {
                component.call(this, view, cell);
            }.bind(this));
        }
    },

    cellDeleted: function (cell) {
        if (cell.currentView) {
            cell.currentView.removeFromParent();
        }
        if (cell.innerCell) {
            this.cellDeleted(cell.innerCell);
        }
    },

    tileChanged: function (tile) {
        var ViewClass = tile.getViewClass();
        var view = new ViewClass(tile);
        view.setPosition(EditorView.alignInTheGrid(tile.x, tile.y));
        view.beforeShowUpAnimation();
        view.setLocalZOrder(2);
        tile.currentView = view;
        this.field.addChild(view);
    },
    
    tileDeleted: function (tile) {
        tile.currentView.removeFromParent();
    },

    setupChildren: function () {
        this._super();
        if (this.tab) {
            this.tab.setPositionRound(cleverapps.styles.BaseEditorView.tabMenu);
        }
        if (this.barriersView) {
            this.barriersView.setPositionRound(this.field.getPosition());
        }
    }
});

EditorView.alignInTheGrid = function (x, y) {
    return cc.p((x + 0.5) * cleverapps.styles.BaseCellView.CELL_SIZE_PX, cleverapps.styles.BaseCellView.CELL_SIZE_PX * (9 - y - 0.5));
};

cleverapps.styles.BaseEditorView = {
    tabMenu: {
        x: { align: "center" },
        y: { align: "center", dy: -500 }
    }
};