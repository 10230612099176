/**
 * Created by mac on 4/21/20
 */

var EditorViewBase = cc.Node.extend({
    ctor: function (editor) {
        this._super();

        this.editor = editor;

        this.setAnchorPoint(0.5, 0.5);
        this.setupChildren();

        if (cleverapps.config.type !== "merge" && cleverapps.config.name !== "wordsoup") {
            var levelNoBlock = new EditorLevelView(editor);
            this.addChild(levelNoBlock);
        }

        if (cleverapps.config.type !== "merge" && cleverapps.config.name !== "wordsoup") {
            var controls = new EditorControlsView(editor);
            this.addChild(controls);
        }
    },

    setupChildren: function () {
        var winSize = cleverapps.resolution.getSceneSize();
        this.setContentSize(winSize.width, winSize.height);
        this.setPosition(winSize.width / 2, winSize.height / 2);
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    EDITOR_GREEN_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.GREEN
    },

    EDITOR_TEXT: {
        size: 30,
        color: cleverapps.styles.COLORS.WHITE,
        font: bundles.wysiwyg.urls.strict_font_ttf
    },

    INCDECBLOCK_AUTO_TEXT: {
        size: 32,
        color: cleverapps.styles.COLORS.MESSAGE_GREEN
    },

    EDITOR_BIG_TEXT: {
        size: 50,
        color: cleverapps.styles.COLORS.WHITE,
        font: bundles.wysiwyg.urls.strict_font_ttf
    },

    EDITOR_BLUE_TEXT: {
        size: 22,
        color: cleverapps.styles.COLOR_BLUE
    }
});