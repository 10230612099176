/**
 * Created by vladislav on 16.02.2022
 */

var TreasureSearchView = cc.Node.extend({
    ctor: function (treasureSearch) {
        this._super();

        this.treasureSearch = treasureSearch;

        this.setContentSize2(cleverapps.styles.TreasureSearchView.size);

        this.createFieldScroll();
        this.createHeader();
        this.createGoalBlock(true);
        this.createProgress();
        this.createMiningTool();
        this.createAds();
        this.createShadow();
        this.updateAds();

        this.treasureSearch.on("updateAds", this.createListener(this.updateAds.bind(this)));
        this.treasureSearch.on("open", this.createListener(this.open.bind(this)));
        this.treasureSearch.on("completeStage", this.createListener(this.completeStage.bind(this)));

        this.treasureSearch.mission.logic.onUpdateCurrency = this.createListener(this.updateCurrency.bind(this));
    },

    open: function (data) {
        var delay = 0;

        if (this.fishingRod) {
            var window = cleverapps.windows.currentWindow();
            if (window && window.window && window instanceof TreasureSearchWindow && !this.fishingRod.oldParent) {
                this.fishingRod.oldParent = this.fishingRod.getParent();
                this.fishingRod.replaceParentSamePlace(window.window, { keepScale: true });
            }

            this.fishingRod.setStartListener(function () {
                if (this.fishingRod.getCurrentAnimationName(0) === "fishing") {
                    this.fishingRod.runAction(new cc.Sequence(
                        new cc.DelayTime(2.1),
                        new cc.PlaySound(bundles.treasuresearch.urls.peck_effect)
                    ));
                }
            }.bind(this));

            cleverapps.audio.playSound(bundles.treasuresearch.urls.open_effect);
            this.fishingRod.setAnimation(0, "throw", false);
            if (this.treasureSearch.getGoalType(data.col, data.row)) {
                this.fishingRod.addAnimation(0, "fishing", false);
            }
            this.fishingRod.addAnimation(0, "moveback", false);

            this.fishingRod.setCompleteListener(function () {
                if (this.fishingRod.oldParent && this.fishingRod.getCurrentAnimationName(0) === "moveback") {
                    this.fishingRod.setCompleteListener();
                    this.fishingRod.replaceParentSamePlace(this.fishingRod.oldParent, { keepScale: true });
                    delete this.fishingRod.oldParent;
                }
            }.bind(this));

            delay = this.fishingRod.getAnimationDuration("throw");
        }

        var cell = this.cellViews[data.row][data.col];
        var goalCells = [];

        if (data.openedGoal) {
            this.treasureSearch.goals[data.openedGoal].forEach(function (goalCell) {
                goalCells.push(this.cellViews[goalCell.row][goalCell.col]);
            }.bind(this));
        }

        cell.animateOpen(delay, function () {
            if (data.openedGoal) {
                var waiter = cleverapps.wait(goalCells.length, this.collectGoal.bind(this, data.openedGoal));

                goalCells.forEach(function (goalCell) {
                    goalCell.animateFinish(waiter);
                });
            }
        }.bind(this));
    },

    completeStage: function (stage, rewardsList) {
        cleverapps.meta.display({
            stack: true,
            focus: "tSearchCompleteStage",
            actions: [
                function (f) {
                    this.treasureSearch.pause();
                    f();
                }.bind(this),

                function (f) {
                    this.beforeChangeStage(f, stage);
                }.bind(this),

                function (f) {
                    this.receiveRewards(f, stage, rewardsList);
                }.bind(this),

                function (f) {
                    this.treasureSearch.resume();
                    f();
                }.bind(this),

                function (f) {
                    if (!this.treasureSearch.isFinished()) {
                        this.changeStage(f, stage);
                    } else {
                        f();
                    }
                }.bind(this)
            ]
        });
    },

    receiveRewards: function (callback, stage, rewardsList) {
        var chest = this.chests[stage];

        cleverapps.tooltipManager.remove(chest);

        chest.textBg.runAction(new cc.FadeOut(0.2));
        chest.text.runAction(new cc.FadeOut(0.2));
        chest.setAnimation(0, "open", false);
        cleverapps.audio.playSound(bundles.treasuresearch.urls.open_lock_effect);

        var prize = new cleverapps.Spine(bundles.treasuresearch.jsons.prize_json);
        prize.setSkin("chest" + (stage + 1));
        prize.runAction(new cc.CallFunc(function () {
            prize.setAnimation(0, "open_chest", false);
        }));
        this.addChild(prize, 100);
        prize.setPosition(this.getRelativeCoordinates(this.field.fieldNode));
        cleverapps.audio.playSound(bundles.treasuresearch.urls.reward_effect);

        prize.setCompleteListener(function () {
            prize.setAnimation(0, "reward", false);
            prize.setCompleteListenerRemove(callback);

            rewardsList.collectRewardsAnimation(prize, {
                flyingAnimation: Reward.JUMP_COLLECT_ANIMATION,
                allAtOnce: true,
                small: false,
                beginScale: 1
            });
        });
    },

    beforeChangeStage: function (callback, stage) {
        if (this.treasureSearch.type !== TreasureSearch.TYPE_FISHING) {
            this.miningTool.runAction(new cc.Sequence(
                new cc.DelayTime(1.3),
                new cc.MoveBy(0.5, -cleverapps.styles.TreasureSearchView.miningTool.dx, 0).easing(cc.easeIn(1))
            ));
        }

        this.shadow.runAction(new cc.Sequence(
            new cc.DelayTime(this.treasureSearch.type === TreasureSearch.TYPE_FISHING ? 9.5 : 2.3),
            new cc.Show(),
            new cc.FadeTo(0.5, 100)
        ));

        this.updateGoalBlock(callback, stage);
    },

    changeStage: function (callback) {
        this.updateProgress();
        this.moveToNextField(function () {
            this.shadow.runAction(new cc.Sequence(
                new cc.FadeTo(0.5, 0),
                new cc.Hide()
            ));

            callback();
        }.bind(this));
        this.resetGoalBlock();
    },

    collectGoal: function (goalType) {
        var fieldItem = this.fieldItems[goalType];
        var holder = this.holders[goalType];

        if (fieldItem && holder) {
            var scale = cleverapps.styles.TreasureSearchView.goalBlock[goalType].scale;

            if (this.treasureSearch.type === TreasureSearch.TYPE_FISHING) {
                fieldItem.setVisible(false);
            }
            fieldItem.replaceParentSamePlace(holder, { keepScale: true, keepRotation: true });
            holder.item = fieldItem;

            var first = fieldItem.getPosition();
            var second = holder.convertToNodeSpace(this.field.fieldNode.getParent().convertToWorldSpace(this.field.fieldNode.getPosition()));
            var third = cc.p(holder.width / 2, holder.height / 2);

            fieldItem.runAction(new cc.Sequence(
                new cc.Spawn(
                    new cc.RotateTo(0.2, 0),
                    new cc.CallFunc(function () {
                        cleverapps.audio.playSound(bundles.treasuresearch.urls.found_effect);
                        fieldItem.setAnimation(0, "found", false);
                    })
                ),
                new cc.Spawn(
                    new cc.ScaleTo(0.7, scale).easing(cc.easeIn(1)),
                    new cc.Sequence(
                        new cc.BezierTo(0.7, [first, second, third]),
                        new cc.PlaySound(bundles.treasuresearch.urls.target_effect)
                    ),
                    new cc.Sequence(
                        new cc.DelayTime(0.5),
                        new cc.CallFunc(function () {
                            if (holder.glow) {
                                holder.glow.runAction(new cc.FadeOut(0.2));
                            }

                            delete this.fieldItems[goalType];
                        }.bind(this))
                    )
                )
            ));
        }
    },

    createFieldCells: function (size) {
        var cellViews = [];

        for (var i = 0; i < size.height; i++) {
            var row = [];
            for (var j = 0; j < size.width; j++) {
                row.push(new TreasureSearchCellView(this.treasureSearch, i, j, this));
            }
            cellViews.push(row);
        }

        return cellViews;
    },

    createFieldContent: function () {
        var styles = cleverapps.styles.TreasureSearchView.field;

        var stage = this.treasureSearch.getStage();
        var fieldSize = TreasureSearch.FIELD_SIZE_BY_STAGES[stage];

        this.cellViews = this.createFieldCells(fieldSize);

        var cells = Array.prototype.concat.apply([], this.cellViews);

        var content = new cc.Node();
        content.setAnchorPoint(0.5, 0.5);
        content.setContentSize2(styles.size);

        var size = content.getContentSize();

        var grid = new cleverapps.GridLayout(cells, {
            columns: this.cellViews[0].length,
            reverseRows: true,
            ascZOrder: true
        });

        var contentSize = grid.getContentSize();
        var scale = Math.min(size.width / contentSize.width, size.height / contentSize.height);
        grid.setScale(scale);
        grid.setPositionRound(content.width / 2, content.height / 2);
        content.addChild(grid);
        content.fieldNode = grid;

        var border = cleverapps.UI.createScale9Sprite(bundles.treasuresearch.frames.border_field, cleverapps.UI.Scale9Rect.TwoPixelXY);
        border.setContentSize2(size.width + styles.border.padding.x, size.height + styles.border.padding.y);
        border.setPositionRound(content.width / 2, content.height / 2);
        content.addChild(border, -1);

        if (bundles.treasuresearch.frames.field_cell_bg) {
            cells.forEach(function (cell) {
                var bg = new cc.Sprite(bundles.treasuresearch.frames.field_cell_bg);
                bg.setLocalZOrder(-1 - cell.row);
                bg.setPositionRound(cell.getPosition());
                grid.addChild(bg);
            });
        }

        this.placeItemsOnField(content.fieldNode);

        return content;
    },

    placeItemsOnField: function (fieldNode) {
        this.fieldItems = {};

        Object.keys(this.treasureSearch.goals).forEach(function (type) {
            var foundParts = 0, goalRect;

            (this.treasureSearch.goals[type] || []).forEach(function (goal) {
                foundParts += this.treasureSearch.isOpened(goal.col, goal.row) ? 1 : 0;

                fieldNode.items.forEach(function (cell) {
                    if (cell.row === goal.row && cell.col === goal.col) {
                        var rect = cc.rect(cell.x - cell.width / 2, cell.y - cell.height / 2, cell.width, cell.height);
                        if (!goalRect) {
                            goalRect = rect;
                        } else {
                            goalRect = cc.rectUnion(goalRect, rect);
                        }
                    }
                }, this);
            }, this);

            if (!goalRect) {
                return;
            }

            var scale;

            var imprint = this.imageByType(type, { imprint: true });
            if (imprint) {
                imprint.setLocalZOrder(-1);
                imprint.setAnchorPoint(0.5, 0.5);
                imprint.setPositionRound(goalRect.x + goalRect.width / 2, goalRect.y + goalRect.height / 2);

                if (this.treasureSearch.shapeRotation[type]) {
                    imprint.setRotation(this.treasureSearch.shapeRotation[type]);
                    scale = Math.min(goalRect.width / imprint.height, goalRect.height / imprint.width);
                } else {
                    scale = Math.min(goalRect.width / imprint.width, goalRect.height / imprint.height);
                }

                if (TreasureSearch.ITEMS_BY_STAGE[0][type] !== TreasureSearch.ITEMS_BY_STAGE[this.treasureSearch.getStage()][type]) {
                    imprint.setScale(scale);
                }

                fieldNode.addChild(imprint);
            }

            if (foundParts === this.treasureSearch.goals[type].length) {
                return;
            }

            var fieldItem = this.fieldItems[type] = this.imageByType(type, { item: true });
            fieldItem.setAnimation(0, "idle", true);
            fieldItem.setLocalZOrder(-1);
            fieldItem.setAnchorPoint(0.5, 0.5);
            fieldItem.setPositionRound(goalRect.x + goalRect.width / 2, goalRect.y + goalRect.height / 2);

            if (this.treasureSearch.shapeRotation[type]) {
                fieldItem.setRotation(this.treasureSearch.shapeRotation[type]);
                scale = scale || Math.min(goalRect.width / fieldItem.height, goalRect.height / fieldItem.width);
            } else {
                scale = scale || Math.min(goalRect.width / fieldItem.width, goalRect.height / fieldItem.height);
            }

            if (TreasureSearch.ITEMS_BY_STAGE[0][type] !== TreasureSearch.ITEMS_BY_STAGE[this.treasureSearch.getStage()][type]) {
                fieldItem.setScale(scale);
            }

            fieldNode.addChild(fieldItem);
        }, this);
    },

    imageByType: function (type, options) {
        var name = "gnome_";

        if (options.glow) {
            name = "glow_gnome_";
        } else if (options.imprint) {
            name = "bg_gnome_";
        }

        switch (type) {
            case "k":
                name += "3x2";
                break;
            case "j":
                name += "2x2";
                break;
            case "h":
                name += "2x1";
                break;
            case "g":
                name += "1x1";
                break;
        }

        if (options.item) {
            name += "_json";
            return bundles.treasuresearch.jsons[name] && new cleverapps.Spine(bundles.treasuresearch.jsons[name]);
        }

        name += "_png";
        return bundles.treasuresearch.frames[name] && new cc.Sprite(bundles.treasuresearch.frames[name]);
    },

    moveToNextField: function (callback) {
        var player = new ActionPlayer();

        player.add(function (f) {
            this.fieldScroll.innerContent.runAction(new cc.Sequence(
                new cc.MoveBy(0.35, 0, -this.fieldScroll.height).easing(cc.easeBackIn()),
                new cc.CallFunc(function () {
                    this.field.removeFromParent();

                    f();
                }.bind(this))
            ));
        }.bind(this));

        player.add(function (f) {
            this.field = this.createFieldContent();

            var styles = cleverapps.styles.TreasureSearchView;

            if (this.treasureSearch.type !== TreasureSearch.TYPE_FISHING) {
                this.createMiningTool();
                this.miningTool.setPositionX(this.miningTool.x - styles.miningTool.dx);
                this.miningTool.runAction(new cc.Sequence(
                    new cc.DelayTime(0.2),
                    new cc.MoveBy(0.5, styles.miningTool.dx, 0).easing(cc.easeOut(1))
                ));
            }

            this.fieldScroll.setInnerContent(this.field);
            this.fieldScroll.innerContent.setPositionRound(0, this.fieldScroll.height);

            this.fieldScroll.innerContent.runAction(new cc.Sequence(
                new cc.MoveBy(0.35, 0, -this.fieldScroll.height).easing(cc.easeBackOut()),
                new cc.CallFunc(function () {
                    callback && callback();
                    f();
                })
            ));
        }.bind(this));

        player.play();
    },

    createFieldScroll: function () {
        var styles = cleverapps.styles.TreasureSearchView.scroll;

        this.field = this.createFieldContent();

        var fieldScroll = this.fieldScroll = new cleverapps.UI.ScrollView(this.field, {
            childrenVisibility: cleverapps.UI.ScrollView.CHILDREN_VISIBILITY_NONE,
            scrollBarEnabled: false,
            mouseScroll: false
        });
        fieldScroll.touchScrollDisabled = true;
        fieldScroll.setContentSize2(styles.size);
        fieldScroll.setPositionRound(styles.positions);
        this.addChild(fieldScroll, -1);
    },

    createHeader: function () {
        var styles = cleverapps.styles.TreasureSearchView.header;

        if (styles.photo) {
            Object.keys(this.treasureSearch.goals).forEach(function (type) {
                var photo = new cc.Sprite(bundles.treasuresearch.frames["photo_" + type]);
                photo.setPositionRound(styles.photo[type].positions);
                photo.setRotation(styles.photo[type].rotation[cleverapps.resolution.mode]);
                photo.setLocalZOrder(styles.photo[type].zOrder[cleverapps.resolution.mode] || 0);
                this.addChild(photo);
            }.bind(this));
        } else if (bundles.treasuresearch.jsons.bg_json) {
            var headerAnimation = this.headerAnimation = new cleverapps.Spine(bundles.treasuresearch.jsons.bg_json);
            headerAnimation.setAnimation(0, "idle", true);
            headerAnimation.setPositionRound(styles);
            this.addChild(headerAnimation);
        } else {
            var bg = new cc.Sprite(bundles.treasuresearch.frames.bg);
            bg.setPositionRound(styles);
            this.addChild(bg);
        }
    },

    createGoalBlock: function (silent) {
        var styles = cleverapps.styles.TreasureSearchView.goalBlock;

        var types = Object.keys(this.treasureSearch.goals);
        var holders = {};

        types.forEach(function (type) {
            var holder = new cc.Node();
            holder.setAnchorPoint(0.5, 0.5);
            holder.setContentSize2(styles);
            holder.setPositionRound(styles[type].positions);
            holder.setRotation(styles[type].rotation || 0);
            this.addChild(holder, styles[type].zOrder || 1);

            if (this.fieldItems && !this.fieldItems[type]) {
                var item = this.imageByType(type, { item: true });
                item.setAnimation(0, "idle", true);
                item.setAnchorPoint(0.5, 0.5);
                item.setScale(styles[type].scale);
                item.setPositionRound(holder.width / 2, holder.height / 2);
                holder.addChild(item);
                holder.item = item;
            } else {
                var glow = this.imageByType(type, { glow: true });
                if (glow) {
                    glow.setAnchorPoint(0.5, 0.5);
                    glow.setPositionRound(holder.width / 2, holder.height / 2);
                    holder.addChild(glow);
                    holder.glow = glow;

                    if (!silent) {
                        glow.setOpacity(0);
                        glow.runAction(new cc.Sequence(
                            new cc.DelayTime(1.2),
                            new cc.FadeIn(0.3)
                        ));
                    }
                }
            }

            holders[type] = holder;
        }, this);

        this.holders = holders;
    },

    updateGoalBlock: function (callback, stage) {
        callback = callback || function () {};

        var styles = cleverapps.styles.TreasureSearchView.goalBlock;

        if (this.holders) {
            Object.keys(this.holders).forEach(function (key, index) {
                var holder = this.holders[key];
                var delay = styles[key].delay;

                holder.runAction(new cc.Sequence(
                    new cc.DelayTime(delay),
                    new cc.CallFunc(function () {
                        if (this.headerAnimation && this.headerAnimation.hasAnimation("animation")) {
                            holder.item.setVisible(false);

                            if (index === Object.keys(this.holders).length - 1) {
                                this.headerAnimation.setAnimationAndIdleAfter("animation", "idle");
                                cleverapps.audio.playSound(bundles.treasuresearch.urls.explosion_effect);

                                this.headerAnimation.runAction(new cc.Sequence(
                                    new cc.DelayTime(2.5),
                                    new cc.CallFunc(callback)
                                ));
                            }
                        } else {
                            holder.item.setAnimation(0, "glow", false);
                            holder.item.setCompleteListener(function () {
                                if (!bundles.treasuresearch.jsons.explosion_json) {
                                    callback();
                                    return;
                                }

                                var target = this.chests[stage];
                                var first = this.parent.convertToNodeSpace(holder.parent.convertToWorldSpace(holder.getPosition()));
                                var localPos = target.getPosition();
                                var third = this.parent.convertToNodeSpace(target.parent.convertToWorldSpace(cc.p(localPos.x + styles.explosion.x, localPos.y + styles.explosion.y)));
                                var second = cc.p(third.x, third.y + styles.explosion.dy);

                                var explosion = new cleverapps.Spine(bundles.treasuresearch.jsons.explosion_json);
                                explosion.setAnimation(0, "explosion", false);
                                explosion.setPositionRound(first);
                                this.parent.addChild(explosion, 100);
                                cleverapps.audio.playSound(bundles.treasuresearch.urls.explosion_effect);

                                explosion.runAction(new cc.Sequence(
                                    new cc.DelayTime(0.8),
                                    new cc.BezierTo(1.4, [first, second, third]).easing(cc.easeIn(1.5)),
                                    new cc.CallFunc(function () {
                                        if (index === Object.keys(this.holders).length - 1) {
                                            callback();
                                        }
                                    }.bind(this))
                                ));
                            }.bind(this));
                        }
                    }.bind(this))
                ));
            }, this);
        } else {
            callback();
        }
    },

    removeGoalBlock: function () {
        if (this.holders) {
            Object.keys(this.holders).forEach(function (key) {
                this.holders[key].removeFromParent();
                delete this.holders[key];
            }, this);
        }
    },

    resetGoalBlock: function () {
        this.removeGoalBlock();

        this.fieldItems = undefined;
        this.createGoalBlock();
    },

    updateProgress: function (silent) {
        var styles = cleverapps.styles.TreasureSearchView.progress;

        var stage = this.treasureSearch.getStage();
        var progress;
        if (this.treasureSearch.type === TreasureSearch.TYPE_FISHING) {
            progress = Math.max(1 + (stage - 1) * 2, 0);
            this.bar.setGoal(this.chests.length * 2 - 1);
        } else {
            progress = stage;
            this.bar.setGoal(this.chests.length - 1);
        }

        this.bar.setPercentage(progress, {
            animated: !silent,
            time: 0.5,
            easing: cc.easeInOut(2)
        });

        if (this.dividers) {
            for (var i = 0; i < stage; i++) {
                var newDivider = new cc.Sprite(bundles.treasuresearch.frames.divider_green_png);
                newDivider.setPosition(this.dividers[i].getPosition());
                this.dividers[i].getParent().addChild(newDivider);
                this.dividers[i].removeFromParent();
                this.dividers[i] = newDivider;
            }
        }

        if (this.currentStageBg) {
            var pos = this.currentStageBg.getRelativeCoordinates(this.chests[stage]);

            if (silent) {
                this.currentStageBg.setPosition(pos.x, pos.y + styles.currentStage.dy);
            } else {
                this.currentStageBg.runAction(new cc.MoveTo(0.5, pos.x, pos.y + styles.currentStage.dy).easing(cc.easeInOut(2)));
                cleverapps.audio.playSound(bundles.treasuresearch.urls.progress_effect);
            }
        }
    },

    createProgress: function () {
        var styles = cleverapps.styles.TreasureSearchView.progress;

        var content = new cc.Node();
        content.setContentSize2(styles.bg.size);
        content.setPositionRound(styles.bg.positions);
        content.setLocalZOrder(styles.zOrder || 0);
        this.addChild(content);

        var bg = cleverapps.UI.createScale9Sprite(bundles.treasuresearch.frames.stage_bg, cleverapps.UI.Scale9Rect.TwoPixelXY);
        bg.setContentSize(content.getContentSize());
        bg.setPositionRound(content.width / 2, content.height / 2);
        content.addChild(bg);

        var progressBar = this.bar = new ScaledProgressBar({
            progress: bundles.progress_bar.frames.bar_treasure_search,
            background: bundles.progress_bar.frames.bg_treasure_search
        });
        progressBar.setLength(styles.progressBar.width);
        progressBar.setPositionRound(styles.progressBar.positions);
        progressBar.setRotation(styles.chestBlock.direction[cleverapps.resolution.mode] === cleverapps.UI.VERTICAL ? -90 : 0);
        content.addChild(progressBar);

        this.chests = [];

        var itemNodes = [];
        var stage = this.treasureSearch.getStage();

        for (var i = 1; i < 6; i++) {
            var chest = new cleverapps.Spine(bundles.treasuresearch.jsons.chest_json);
            chest.setSkin("chest" + i);

            var node = new cc.Node();
            node.setContentSize(chest.getContentSize());
            chest.setPositionRound(node.width / 2, node.height / 2);
            node.addChild(chest);

            if (stage > i - 1) {
                chest.setAnimation(0, "idle_open", true);
            } else {
                chest.setAnimation(0, "idle_close", true);

                cleverapps.tooltipManager.create(chest, {
                    rewards: this.treasureSearch.getStageReward(i - 1),
                    text: "Rewards.list",
                    location: cleverapps.UI.Tooltip.LOCATION_BELOW,
                    size: cleverapps.styles.UI.Tooltip.SIZE.medium
                });

                var textBg;
                if (bundles.treasuresearch.frames.stage_text_bg) {
                    textBg = cleverapps.UI.createScale9Sprite(bundles.treasuresearch.frames.stage_text_bg, cleverapps.UI.Scale9Rect.TwoPixelXY);
                } else {
                    textBg = new cc.Node();
                }
                textBg.setContentSize(styles.chestBlock.text);
                textBg.setPositionRound(styles.chestBlock.text);
                node.addChild(textBg);
                chest.textBg = textBg;

                var text = cleverapps.UI.generateOnlyText("TreasureSearch.stage" + i, cleverapps.styles.FONTS.TREASURE_STAGE_TEXT);
                text.setPositionRound(textBg.width / 2, textBg.height / 2);
                text.fitTo(styles.chestBlock.text.width);
                textBg.addChild(text);
                chest.text = text;
            }

            itemNodes.push(node);

            this.chests.push(chest);
        }

        var chestBlock = new cleverapps.Layout(itemNodes, {
            direction: styles.chestBlock.direction[cleverapps.resolution.mode],
            margin: cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? styles.chestBlock.margins.vertical : styles.chestBlock.margins.horizontal,
            reversed: styles.chestBlock.direction[cleverapps.resolution.mode] === cleverapps.UI.VERTICAL
        });
        chestBlock.setPositionRound(styles.chestBlock.positions);
        content.addChild(chestBlock);

        if (bundles.treasuresearch.frames.current_stage_bg) {
            var currentStageBg = this.currentStageBg = cleverapps.UI.createScale9Sprite(bundles.treasuresearch.frames.current_stage_bg, cleverapps.UI.Scale9Rect.TwoPixelXY);
            currentStageBg.setContentSize(styles.currentStage);
            chestBlock.addChild(currentStageBg, -1);
        }

        if (this.treasureSearch.type === TreasureSearch.TYPE_FISHING && cleverapps.resolution.mode !== cleverapps.WideMode.VERTICAL) {
            this.dividers = [];
            for (var j = 0; j < this.chests.length - 1; j++) {
                var divider = new cc.Sprite(bundles.treasuresearch.frames.divider_brown_png);
                divider.setPositionRound(
                    { align: styles.dividers.position.x.align, dx: styles.dividers.position.x.dx + styles.dividers.offset.dx * j },
                    { align: styles.dividers.position.y.align, dy: styles.dividers.position.y.dy + styles.dividers.offset.dy * j }
                );
                content.addChild(divider);
                this.dividers[j] = divider;
            }
        }

        this.updateProgress(true);
    },

    createMiningTool: function () {
        var styles = cleverapps.styles.TreasureSearchView.miningTool;

        if (this.miningTool) {
            this.miningTool.removeFromParent();
        }

        var bg;
        if (bundles.treasuresearch.frames.mining_tool_bg) {
            bg = cleverapps.UI.createScale9Sprite(bundles.treasuresearch.frames.mining_tool_bg, cleverapps.UI.Scale9Rect.TwoPixelXY);
            this.field.addChild(bg, -1);
        } else {
            bg = new cc.Node();
            this.addChild(bg);
        }
        bg.setContentSize(styles.bg);
        bg.setPositionRound(styles.bg.positions);
        this.miningTool = bg;

        var icon;
        if (bundles.treasuresearch.jsons.fishing_rod_json) {
            icon = this.fishingRod = new cleverapps.Spine(bundles.treasuresearch.jsons.fishing_rod_json);
            icon.setAnimation(0, "idle", true);
            icon.setLocalZOrder(3);
        } else {
            icon = new cc.Sprite(bundles.treasuresearch.frames.mining_tool);
        }
        icon.setPositionRound(styles.icon);
        bg.addChild(icon);

        var amountBg = cleverapps.UI.createScale9Sprite(bundles.big_timer.frames.big_timer_bg_png, cleverapps.UI.Scale9Rect.TwoPixelXY);
        amountBg.setContentSize2(styles.amountBg);
        amountBg.setPositionRound(styles.amountBg);
        bg.addChild(amountBg);

        this.amountText = cleverapps.UI.generateImageText(this.treasureSearch.getCurrency(), cleverapps.styles.FONTS.TREASURE_SEARCH_TEXT);
        amountBg.addChild(this.amountText);
        this.amountText.setPositionRound(amountBg.width / 2, amountBg.height / 2);

        if (cleverapps.config.debugMode) {
            cleverapps.UI.onClick(this.fishingRod ? bg : icon, function () {
                Object.keys(this.treasureSearch.goals).forEach(function (type) {
                    this.treasureSearch.goals[type].forEach(function (goal) {
                        if (!this.treasureSearch.running || this.treasureSearch.paused) {
                            return;
                        }

                        if (!this.treasureSearch.canTakeCurrency()) {
                            this.treasureSearch.updateCurrency(1);
                        }

                        this.treasureSearch.open(goal.col, goal.row);
                    }, this);
                }, this);
            }.bind(this));
        }
    },

    createAds: function () {
        if (!cleverapps.rewardedAdsManager.isEnabled()) {
            return;
        }

        var ads = this.ads = new cc.Sprite(bundles.treasuresearch.frames.mining_ad);
        ads.setPositionRound(cleverapps.styles.TreasureSearchView.ads.positions);
        this.addChild(ads, -1);

        this.hoverHandler = cleverapps.UI.applyHover(ads);
        this.clickHandler = cleverapps.UI.onClick(ads, function () {
            if (!this.treasureSearch.running || this.treasureSearch.paused) {
                return;
            }

            this.treasureSearch.watchAd();
        }.bind(this));
    },

    updateAds: function () {
        if (this.ads) {
            var ready = this.treasureSearch.isAdsReady();

            cleverapps.UI.convertToGrayScale(this.ads, ready);

            this.hoverHandler.setEnabled(ready);
            this.clickHandler.setEnabled(ready);
        }
    },

    updateCurrency: function () {
        if (this.amountText) {
            this.amountText.setString(this.treasureSearch.getCurrency());
        }
    },

    createShadow: function () {
        var styles = cleverapps.styles.TreasureSearchView.shadow;

        var shadow = this.shadow = new cc.Scale9Sprite(bundles.treasuresearch.frames.win_bg);
        shadow.setColor(cleverapps.styles.COLORS.BLACK);
        shadow.setOpacity(0);
        shadow.setContentSize2(styles.size);
        shadow.setPositionRound(styles.positions);
        shadow.setVisible(false);
        shadow.setLocalZOrder(styles.zOrder);
        this.addChild(shadow);
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    TREASURE_SEARCH_TEXT: {
        name: "nostroke",
        color: cleverapps.styles.COLORS.WHITE,
        size: 35
    },

    TREASURE_STAGE_TEXT: {
        name: "nostroke",
        color: cleverapps.styles.COLORS.COLOR_WINDOW_TEXT,
        size: 20
    },

    TREASURE_PHOTO_TEXT: {
        color: cleverapps.styles.COLORS.WHITE,
        size: 45
    }
});

cleverapps.styles.TreasureSearchView = {
    size: [{
        width: 932, height: 920
    }, {
        width: 932, height: 920
    }, {
        width: 932, height: 920
    }],

    header: {
        x: { align: "center", dx: 0 },
        y: { align: "top", dy: 0 }
    },

    goalBlock: {
        width: 50,
        height: 50,

        "g": {
            positions: [{
                x: { align: "center", dx: 355 }, y: { align: "top", dy: -86 }
            }, {
                x: { align: "center", dx: 355 }, y: { align: "top", dy: -86 }
            }, {
                x: { align: "center", dx: 355 }, y: { align: "top", dy: -86 }
            }],
            scale: 0.89,
            delay: 3.3
        },
        "h": {
            positions: [{
                x: { align: "center", dx: -195 }, y: { align: "top", dy: -58 }
            }, {
                x: { align: "center", dx: -195 }, y: { align: "top", dy: -58 }
            }, {
                x: { align: "center", dx: -195 }, y: { align: "top", dy: -58 }
            }],
            scale: 0.78,
            delay: 3.1
        },
        "j": {
            positions: [{
                x: { align: "center", dx: 225 }, y: { align: "top", dy: -63 }
            }, {
                x: { align: "center", dx: 225 }, y: { align: "top", dy: -63 }
            }, {
                x: { align: "center", dx: 225 }, y: { align: "top", dy: -63 }
            }],
            scale: 0.62,
            delay: 3.2
        },
        "k": {
            positions: [{
                x: { align: "center", dx: -330 }, y: { align: "top", dy: -66 }
            }, {
                x: { align: "center", dx: -330 }, y: { align: "top", dy: -66 }
            }, {
                x: { align: "center", dx: -330 }, y: { align: "top", dy: -66 }
            }],
            scale: 0.43,
            delay: 3
        },

        explosion: {
            x: 35,
            y: 15,
            dy: 80
        }
    },

    progress: {
        bg: {
            size: [{
                width: 1016, height: 175
            }, {
                width: 1016, height: 175
            }, {
                width: 1016, height: 175
            }],

            positions: [{
                x: { align: "center", dx: 0 }, y: { align: "top", dy: -168 }
            }, {
                x: { align: "center", dx: 0 }, y: { align: "top", dy: -168 }
            }, {
                x: { align: "center", dx: 0 }, y: { align: "top", dy: -168 }
            }]
        },

        progressBar: {
            width: 660,
            positions: [{
                x: { align: "center", dx: 0 }, y: { align: "center", dy: 3 }
            }, {
                x: { align: "center", dx: 0 }, y: { align: "center", dy: 3 }
            }, {
                x: { align: "center", dx: 0 }, y: { align: "center", dy: 3 }
            }]
        },

        currentStage: {
            width: 140,
            height: 180,
            dy: -10
        },

        chestBlock: {
            direction: [cleverapps.UI.HORIZONTAL, cleverapps.UI.HORIZONTAL, cleverapps.UI.HORIZONTAL],
            margins: {
                vertical: 80,
                horizontal: 80
            },
            positions: [{
                x: { align: "center", dx: 0 }, y: { align: "center", dy: 4 }
            }, {
                x: { align: "center", dx: 0 }, y: { align: "center", dy: 4 }
            }, {
                x: { align: "center", dx: 0 }, y: { align: "center", dy: 4 }
            }],

            text: {
                width: 100,
                height: 32,
                x: { align: "center", dx: 0 },
                y: { align: "center", dy: -56 }
            }
        }
    },

    miningTool: {
        dx: 200,

        bg: {
            width: 112,
            height: 180,
            positions: [{
                x: { align: "right", dx: 112 }, y: { align: "center", dy: 0 }
            }, {
                x: { align: "right", dx: 112 }, y: { align: "center", dy: 0 }
            }, {
                x: { align: "right", dx: 112 }, y: { align: "center", dy: 0 }
            }]
        },

        icon: {
            x: { align: "center", dx: 5 },
            y: { align: "top", dy: 17 }
        },

        amountBg: {
            width: 85,
            height: 55,
            x: { align: "center", dx: -2 },
            y: { align: "bottom", dy: 17 }
        }
    },

    ads: {
        positions: [{
            x: { align: "left", dx: -13 }, y: { align: "bottom", dy: -55 }
        }, {
            x: { align: "left", dx: -13 }, y: { align: "bottom", dy: -55 }
        }, {
            x: { align: "left", dx: -13 }, y: { align: "bottom", dy: -55 }
        }]
    },

    field: {
        size: [{
            width: 560, height: 560
        }, {
            width: 560, height: 560
        }, {
            width: 560, height: 560
        }],

        border: {
            padding: {
                x: 25,
                y: 25
            }
        }
    },

    scroll: {
        size: [{
            width: 997, height: 657
        }, {
            width: 997, height: 657
        }, {
            width: 997, height: 657
        }],

        positions: [{
            x: { align: "center", dx: 0 }, y: { align: "top", dy: -330 }
        }, {
            x: { align: "center", dx: 0 }, y: { align: "top", dy: -330 }
        }, {
            x: { align: "center", dx: 0 }, y: { align: "top", dy: -330 }
        }]
    },

    shadow: {
        zOrder: -1,

        size: [{
            width: 995, height: 787
        }, {
            width: 995, height: 787
        }, {
            width: 995, height: 787
        }],

        positions: [{
            x: { align: "center", dx: 0 }, y: { align: "top", dy: -200 }
        }, {
            x: { align: "center", dx: 0 }, y: { align: "top", dy: -200 }
        }, {
            x: { align: "center", dx: 0 }, y: { align: "top", dy: -200 }
        }]
    }
};
