/**
 * Created by iamso on 25.02.19.
 */

var GumDecoratorCell = function (x, y, options) {
    BaseCell.call(this, x, y, options);

    this.onSetBorders = function () {};

    Game.currentGame.counter.registerStage(218, GumDecoratorCell.adjustBordersForAll);
};

GumDecoratorCell.prototype = Object.create(BaseCell.prototype);
GumDecoratorCell.prototype.constructor = GumDecoratorCell;

GumDecoratorCell.prototype.getViewClass = function () {
    return GumDecoratorCellView;
};

GumDecoratorCell.prototype.load = function (data, loader) {
    data.shift();
    if ("23".indexOf(data[0]) >= 0) {
        this.lives = parseInt(data[0]);
        data.shift();
    }
    this.setInnerCell(loader.loadNext(this.y, this.x, data));
};

GumDecoratorCell.prototype.save = function () {
    var saveData = GumDecoratorCell.CODES;
    if (this.lives > 1) {
        saveData = saveData.concat(this.lives);
    }
    return saveData.concat(this.innerCell.save());
};

GumDecoratorCell.prototype.bundleId = function () {
    return "gum_decorator";
};

GumDecoratorCell.prototype.boom = function () {
    var decoratorComponent = this.findComponent(DecoratorComponent);
    decoratorComponent.boomHurt();
};

GumDecoratorCell.prototype.onExplode = function () {
    if (Game.currentGame.goals.hasType(this.getGoalId())) {
        Game.currentGame.goalCounter.setTimeout(function () {}, 500);
    }

    var decoratorComponent = this.findComponent(DecoratorComponent);
    decoratorComponent.onExplode();
};

GumDecoratorCell.prototype.hurt = function (explodeParams) {
    BaseCell.prototype.hurt.call(this, explodeParams);
    Game.currentGame.counter.setTimeout(this.adjustNeighborsBorders.bind(this), this.hurtDuration() * 1000);
};

GumDecoratorCell.prototype.hurtDuration = function () {
    return this.lives > 0 ? 0.3 : 0;
};

GumDecoratorCell.prototype.explode = function () {
    if (Game.currentGame.goals.hasType(this.getGoalId())) {
        BaseCell.prototype.explodeViaCollect.call(this);
    } else {
        BaseCell.prototype._explode.call(this);
    }
};

GumDecoratorCell.prototype.getNeighbors = function () {
    if (!Game.currentGame || !Game.currentGame.field) {
        return [];
    }

    var cells = Game.currentGame.field.cells;

    var neighbors = {};
    for (var dir in GumDecoratorCell.DIRECTIONS) {
        var offset = GumDecoratorCell.DIRECTIONS[dir];
        var cell = cells[this.y + offset.y] ? cells[this.y + offset.y][this.x + offset.x] : undefined;
        if (cell && cell.constructor === GumDecoratorCell && cell.lives > 0) {
            neighbors[dir] = cell;
        }
    }
    return neighbors;
};

GumDecoratorCell.prototype.getBordersDirections = function () {
    var neighbors = this.getNeighbors();

    return Object.keys(GumDecoratorCell.DIRECTIONS).filter(function (dir) {
        var neighbor = neighbors[dir];
        return !neighbor || neighbor.lives !== this.lives;
    }.bind(this));
};

GumDecoratorCell.prototype.adjustNeighborsBorders = function () {
    Object.values(this.getNeighbors()).forEach(function (cell) {
        cell.onSetBorders();
    });
};

GumDecoratorCell.adjustBordersForAll = function () {
    var cells = Game.currentGame.field.cells;

    for (var i = 0; i < cells.length; i++) {
        for (var j = 0; j < cells.length; j++) {
            var cell = cells[i][j];
            if (cell && cell.constructor === GumDecoratorCell) {
                cell.onSetBorders();
            }
        }
    }
};

GumDecoratorCell.prototype.getGoalId = function () {
    return GumDecoratorCell.GOAL_ID;
};

GumDecoratorCell.GOAL_ID = "gum";

GumDecoratorCell.UP = "U";
GumDecoratorCell.RIGHT = "R";
GumDecoratorCell.DOWN = "D";
GumDecoratorCell.LEFT = "L";

GumDecoratorCell.DIRECTIONS = {};
GumDecoratorCell.DIRECTIONS[GumDecoratorCell.UP] = { x: 0, y: -1 };
GumDecoratorCell.DIRECTIONS[GumDecoratorCell.DOWN] = { x: 0, y: 1 };
GumDecoratorCell.DIRECTIONS[GumDecoratorCell.LEFT] = { x: -1, y: 0 };
GumDecoratorCell.DIRECTIONS[GumDecoratorCell.RIGHT] = { x: 1, y: 0 };
