/**
 * Created by Oleg on 05.02.19.
 */

var BarriersView = cc.Node.extend({
    ctor: function (field, alignerGrid) { // fieldView & alignerGrid are necessary because of game & editor usage
        this.field = field;
        this.barriers = field.barriers;
        this.alignerGrid = alignerGrid;

        this._super();

        this.load();

        field.onGetBarriersViews = this.createListener(this.getViews.bind(this));
    },

    load: function () {
        var y, x, background;
        this.downSprites = [];
        if (this.barriers.isDownBarrier) {
            for (y = 0; y < this.barriers.isDownBarrier.length; y++) {
                this.downSprites[y] = [];
                for (x = 0; x < this.barriers.isDownBarrier[y].length; x++) {
                    if (this.barriers.isDownBarrier[y][x]) {
                        background = new cc.Sprite(bundles.game.frames.barrier);
                        background.setPosition(this.alignerGrid(x, y)); // can't use align before Field.SIZE_Y is known
                        // (column, row) - such order of cooordinates
                        this.addChild(background);
                        this.downSprites[y].push(background); // need for tutorial "change parent" only
                    } else {
                        this.downSprites[y].push(null);
                    }
                }
            }
        }

        this.rightSprites = [];
        if (this.barriers.isRightBarrier) {
            for (y = 0; y < this.barriers.isRightBarrier.length; y++) {
                this.rightSprites[y] = [];
                for (x = 0; x < this.barriers.isRightBarrier[y].length; x++) {
                    if (this.barriers.isRightBarrier[y][x]) {
                        background = new cc.Sprite(bundles.game.frames.barrier);
                        background.setPosition(this.alignerGrid(x, y)); // can't use align before Field.SIZE_Y is known
                        // (column, row) - such order of cooordinates
                        background.setRotation(-90);
                        this.addChild(background);
                        this.rightSprites[y].push(background); // need for tutorial "change parent" only
                    } else {
                        this.rightSprites[y].push(null);
                    }
                }
            }
        }
    },

    getViews: function (x, y) {
        return [
            this.downSprites[y] && this.downSprites[y][x],
            this.downSprites[y - 1] && this.downSprites[y - 1][x],
            this.rightSprites[y] && this.rightSprites[y][x],
            this.rightSprites[y] && this.rightSprites[y][x - 1]
        ].filter(Boolean);
    }
});
