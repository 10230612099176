/**
 * Created by mac on 9/25/20
 */

var AdminLevels = function (administrator) {
    cleverapps.EventEmitter.call(this);

    this.levels = [];

    this.episodeStatistics = new EpisodeStatistics();
    this.versions = new LevelVersions();

    this.onReadyListeners = [];

    this.ready = false;

    this.episodeStatistics.whenReady(this.onStatisticsLoaded.bind(this));

    administrator.adminEpisodes.onUpdateHoverEpisodeListener = this.onUpdateEpisodesHover.bind(this);
};

AdminLevels.prototype = Object.create(cleverapps.EventEmitter.prototype);
AdminLevels.prototype.constructor = AdminLevels;

AdminLevels.prototype.onUpdateEpisodesHover = function () {
    if (this.draggingLevel) {
        this.draggingLevel.setDragEpisode(cleverapps.administrator.adminEpisodes.hoverEpisode);
    }
};

AdminLevels.prototype.addLevel = function (adminLevel) {
    this.levels.splice(adminLevel.levelNo, 0, adminLevel);

    for (var i = 0; i < this.levels.length; i++) {
        if (!this.levels[i].isAddMoreLevel()) {
            this.levels[i].levelNo = i;
        }
    }

    this.trigger("addLevel", adminLevel);
};

AdminLevels.prototype.makeCurrent = function (adminLevel) {
    cleverapps.user.level = parseInt(adminLevel.levelNo);
    cleverapps.user.episode = parseInt(adminLevel.episodeNo);
    cleverapps.user.save();
};

AdminLevels.prototype.update = function () {
    this.levels.forEach(function (level) {
        level.onUpdate();
    });
};

AdminLevels.prototype.copyLevel = function (adminLevel) {
    var adminEpisode = cleverapps.administrator.adminEpisodes.getActive();
    var episodeNo = adminEpisode.episodeNo;
    var fromLevelNo = adminLevel.levelNo;

    this.processEpisodeContent(episodeNo, function (content) {
        content.levels.push(JSON.parse(JSON.stringify(content.levels[fromLevelNo])));
    });
};

AdminLevels.prototype.copyLevelToClipboard = function (adminLevel) {
    var level = cleverapps.clone(adminLevel.getContent(), true);

    Object.assign(level, {
        clipboardInfo: {
            gameName: cleverapps.config.name
        }
    });

    cleverapps.copyToClipboard(JSON.stringify(level), function () {
        this.trigger("copyLevelToClipboard");

        cleverapps.notification.create("Copy level to clipboard");
    }.bind(this));
};

AdminLevels.prototype.levelFromClipboard = function (callback) {
    window.navigator.clipboard.readText().then(function (data) {
        var levelContent;
        try {
            levelContent = JSON.parse(data);
            if (!levelContent.clipboardInfo) {
                levelContent = undefined;
            }
            // eslint-disable-next-line no-empty
        } catch (e) {

        }
        callback(levelContent);
    }).catch(function () {
        callback();
    });
};

AdminLevels.prototype.pasteLevelFromClipboard = function (levelContent, callback) {
    if (["merge", "tile3"].indexOf(cleverapps.config.type) === -1) {
        return;
    }

    if (levelContent.clipboardInfo.gameName !== cleverapps.config.name) {
        if (levelContent.field) {
            levelContent.field.forEach(function (unit) {
                if (!Families[unit.code]) {
                    unit.code = "coins";
                    unit.stage = Families.coins.units.length - 1;
                } else if (Families[unit.code].units.length - 1 < unit.stage) {
                    unit.stage = Families[unit.code].units.length - 1;
                }
            });
        }

        if (levelContent.decorators) {
            levelContent.decorators = levelContent.decorators.filter(function (decorator) {
                return Map2dDecoratorView.DecoratorImageExists(decorator);
            });
        }
    }

    delete levelContent.clipboardInfo;

    var adminEpisode = cleverapps.administrator.adminEpisodes.getActive();
    var episodeNo = adminEpisode.episodeNo;

    this.processEpisodeContent(episodeNo, function (content) {
        content.levels.push(levelContent);
    }, function () {
        cleverapps.notification.create("Paste level from clipboard");

        callback();
    });
};

AdminLevels.prototype.moveLevel = function (options) {
    options = options || {};

    var episodeNo = options.episodeNo;
    var fromLevelNo = options.fromLevelNo;
    var toLevelNo = options.toLevelNo;
    var callback = options.callback;

    this.processEpisodeContent(episodeNo, function (content) {
        var contentToMove = content.levels[fromLevelNo];
        content.levels.splice(fromLevelNo, 1);
        content.levels.splice(toLevelNo, 0, contentToMove);
    }, callback);
};

AdminLevels.prototype.updateLevels = function () {
    this.levels.forEach(function (level) {
        level.onUpdate();
    });
};

AdminLevels.prototype.dragLevel = function (adminLevel, levelNo) {
    this.levels.splice(adminLevel.levelNo, 1);
    this.levels.splice(levelNo, 0, adminLevel);

    this.levels.forEach(function (level, levelNo) {
        level.setLevelNo(levelNo);
    });
};

AdminLevels.prototype.getStatistics = function () {

};

AdminLevels.prototype.createNew = function (blankContent, callback) {
    var adminEpisode = cleverapps.administrator.adminEpisodes.getActive();
    var episodeNo = adminEpisode.episodeNo;
    var levelNo = this.levels.length - 1;

    blankContent.levelNo = levelNo;
    blankContent.episodeNo = episodeNo;

    this.processEpisodeContent(episodeNo, function (content) {
        content.levels.push(blankContent);
    }, callback);
};

AdminLevels.prototype.removeLevel = function (adminLevel, f) {
    var episodeNo = adminLevel.episodeNo;
    var levelNo = adminLevel.levelNo;

    this.processEpisodeContent(episodeNo, function (content) {
        content.levels.splice(levelNo, 1);
    }, f);
};

AdminLevels.prototype.processEpisodeContent = function (episodeNo, processor, f) {
    f = cleverapps.once(f);

    var language = cleverapps.config.type === "board" ? cleverapps.settings.language : undefined;

    var adminEpisode = cleverapps.administrator.adminEpisodes.getEpisode(episodeNo);
    var bundleName = AdminEpisode.BundleId(episodeNo, language);

    cleverapps.bundleLoader.loadBundle(bundleName, {
        onSuccess: function () {
            var content = JSON.parse(JSON.stringify(cc.loader.getRes(bundles[bundleName].jsons.levels)));
            if (!content.levels) {
                content = { levels: content };
            }

            processor(content);

            content.levels.forEach(function (level, index) {
                cleverapps.toArray(level).forEach(function (levelContent) {
                    levelContent.levelNo = index;
                    levelContent.episodeNo = episodeNo;
                });
            });

            cleverapps.administrator.adminEpisodes.saveToStorage(episodeNo, content);

            if (cleverapps.administrator.adminEpisodes.getActive() === adminEpisode) {
                this.reset(f);
            } else {
                f();
            }

            cleverapps.bundleLoader.deleteBundle(bundleName);
        }.bind(this),

        onError: function () {
            cleverapps.notification.create("Can't load bundle " + bundleName);
            f();
        }
    });
};

AdminLevels.FIRST_LEVEL = 0;
AdminLevels.LAST_LEVEL = 1;

AdminLevels.prototype.onStatisticsLoaded = function (data) {
    this.whenReady(function () {
        data.forEach(function (levelData) {
            if (this.levels[levelData.levelNo]) {
                this.levels[levelData.levelNo].setDifficulty(levelData.difficulty);
            }
        }, this);
    }.bind(this));

    this.trigger("statisticsLoaded", data);
};

AdminLevels.prototype.reset = function (callback, suggestedLevel) {
    var adminEpisode = cleverapps.administrator.adminEpisodes.getActive();

    if (typeof adminEpisode.episodeNo === "undefined") {
        return;
    }

    this.setActive(undefined);

    this.ready = false;

    this.loadData(function (adminEpisode) {
        this.updateData(adminEpisode);

        var selectedLevel = this.levels[0];
        if (suggestedLevel) {
            if (suggestedLevel === AdminLevels.LAST_LEVEL) {
                selectedLevel = this.levels[this.levels.length - 2];
            } else {
                selectedLevel = this.levels[suggestedLevel];
            }
        }

        if (selectedLevel && !selectedLevel.addMoreLevel) {
            this.setActive(selectedLevel);
        }

        if (callback) {
            callback();
        }

        this.runOnReadyListeners();

        this.ready = true;

        this.episodeStatistics.reset(adminEpisode);
        this.trigger("statisticsReset");
    }.bind(this));
};

AdminLevels.prototype.runOnReadyListeners = function () {
    var listeners = this.onReadyListeners;
    this.onReadyListeners = [];
    listeners.forEach(function (listener) {
        listener(this.data);
    }, this);
};

AdminLevels.prototype.whenReady = function (callback) {
    if (this.ready) {
        callback();
    } else {
        this.onReadyListeners.push(callback);
    }
};

AdminLevels.prototype.next = function (sign) {
    var pos = this.levels.indexOf(this.active) + sign;

    if (this.levels[pos] === undefined || this.levels[pos].isAddMoreLevel()) {
        return undefined;
    }

    return this.levels[pos];
};

AdminLevels.prototype.createLevel = function (adminEpisode, levelNo) {
    return AdminLevel.createFromData({
        levelNo: levelNo,
        episodeNo: adminEpisode.episodeNo,
        hashes: true,
        version: true
    }, adminEpisode.getEpisodeLevels()[levelNo]);
};

AdminLevels.prototype.updateData = function (adminEpisode) {
    var levelsAndVersions = adminEpisode.getEpisodeLevels();
    this.levels = [];
    for (var levelNo = 0; levelNo < 100 && levelsAndVersions[levelNo]; levelNo++) {
        this.levels.push(this.createLevel(adminEpisode, levelNo));
    }

    this.levels.push(AdminLevel.getAddMoreLevel());

    this.versions.reset();
    this.trigger("reset");
};

AdminLevels.prototype.getActive = function () {
    return this.active;
};

AdminLevels.prototype.setActive = function (level) {
    if (this.active !== level) {
        if (this.active !== undefined) {
            this.active.unselect();
        }

        this.active = level;

        if (cleverapps.config.adminMode) {
            cleverapps.setUrlHash({
                episode: this.active ? this.active.episodeNo : undefined,
                level: this.active ? this.active.levelNo : undefined
            });
        }

        if (level !== undefined) {
            level.select();
            this.trigger("chartUpdate", level);
            this.trigger("changeActive");
        }

        cleverapps.administrator.adminDetails.reset();
    }
};

AdminLevels.prototype.loadData = function (callback) {
    var adminEpisode = cleverapps.administrator.adminEpisodes.getActive();
    if (!adminEpisode) {
        return;
    }

    adminEpisode.loadData(function () {
        if (adminEpisode !== cleverapps.administrator.adminEpisodes.getActive()) {
            return;
        }

        callback(adminEpisode);
    });
};

AdminLevels.prototype.download = function (callback) {
    this.loadData(function (adminEpisode) {
        var data = JSON.stringify(adminEpisode.getEpisodeLevelsContent(), undefined, 4);
        var fileName = AdminEpisode.BundleId(cleverapps.administrator.adminEpisodes.getActive().episodeNo) + ".json";

        this.trigger("downloadLevels", {
            fileName: fileName,
            data: data
        });
    }.bind(this));

    callback();
};

AdminLevels.prototype.upload = function (event, callback) {
    var files = event.target.files;
    var file = files[0];
    var end = file.name.indexOf(".json");
    if (end < 0) {
        cleverapps.notification.create("Error - File must be json format");
        callback();
        return;
    }

    var reader = new FileReader();

    reader.onload = function (event) {
        var content = event.target.result;

        cleverapps.administrator.adminEpisodes.upload(content, callback);
    };

    reader.readAsText(file);
};
