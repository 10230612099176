var ColorCookieManCell = function (x, y, color) {
    if (color === "random") {
        color = this.getRandomColor();
    }
    BaseCell.call(this, x, y);

    this.addComponent(ColorComponent, { color: color });
    this.addComponent(SpecialColorComponent);

    this.lives = 1;
    this.movable = true;
    this.hurtable = true;
    this.putInPoolAvailable = true;

    Game.currentGame.counter.registerStage(209, ColorCookieManCell.processExit);
    Game.currentGame.counter.registerStage(210, ColorCookieManCell.process);
};

ColorCookieManCell.prototype = Object.create(BaseCell.prototype);
ColorCookieManCell.prototype.constructor = ColorCookieManCell;

ColorCookieManCell.prepareToUp = function () {
    if (!Game.currentGame) {
        return;
    }

    var i, j;
    ColorCookieManCell.amountToExit += ColorCookieManCell.exitToNextMove;
    ColorCookieManCell.exitToNextMove = 0;

    for (i = 0; i < Field.SIZE; i++) {
        for (j = 0; j < Field.SIZE; j++) {
            if (Game.currentGame.field.cells[i][j] && Game.currentGame.field.cells[i][j].constructor === ColorCookieManCell) {
                Game.currentGame.field.cells[i][j].canUp = true;
            }
        }
    }
};

ColorCookieManCell.process = function () {
    if (!Game.currentGame) {
        return;
    }

    for (var i = 0; i < Field.SIZE; i++) {
        for (var j = 0; j < Field.SIZE; j++) {
            if (Game.currentGame.field.cells[i][j] && Game.currentGame.field.cells[i][j].constructor === ColorCookieManCell) {
                Game.currentGame.field.cells[i][j].process();
            }
        }
    }
};

ColorCookieManCell.processExit = function () {
    if (!Game.currentGame || !ColorCookieManCell.amountToExit) {
        return;
    }

    var availableCells = [];
    for (var col = 0; col < Field.SIZE; col++) {
        for (var row = Field.SIZE - 1; row >= 0; row--) {
            if (Game.currentGame.field.isCellInField(row, col)) {
                var currentCell = Game.currentGame.field.cells[row][col];
                if (currentCell.canDelete()) {
                    availableCells.push(currentCell);
                }
                break;
            }
        }
    }

    while (ColorCookieManCell.amountToExit > 0 && availableCells.length > 0) {
        var ind = cleverapps.Random.random(availableCells.length);
        var cellToExit = availableCells[ind];
        availableCells.splice(ind, 1);
        ColorCookieManCell.amountToExit--;

        cellToExit.delete();
        var newCookieMan = new ColorCookieManCell(cellToExit.x, cellToExit.y, "random");
        newCookieMan.processExit();
    }
};

ColorCookieManCell.prototype.processExit = function () {
    Game.currentGame.field.addCell(this);

    this.canUp = false;

    this.animate(BaseCell.ANIMATION_COOKIE_IN, function () {
    });

    Game.currentGame.counter.setTimeout(function () {
    }, 1300);
};

ColorCookieManCell.prototype.move = function () {
    CookieManCell.prototype.move.apply(this, arguments);
};

ColorCookieManCell.prototype.beforeMoveByTransporter = function () {
    CookieManCell.prototype.beforeMoveByTransporter.apply(this, arguments);
};

ColorCookieManCell.prototype.process = function () {
    if (!this.canUp || !Game.currentGame) {
        return;
    }

    var cx = this.x, cy = this.y;

    var existUpperCell = false;
    for (var i = cy - 1; i >= 0; i--) {
        if (Game.currentGame.field.cells[i][cx]) {
            existUpperCell = true;
            break;
        }
    }

    if (existUpperCell) {
        if (!Game.currentGame.field.cells[cy - 1][cx]) {
            return;
        }

        if (!Game.currentGame.field.cells[cy - 1][cx].movable) {
            return;
        }

        if (Game.currentGame.field.cells[cy - 1][cx].constructor === ColorCookieManCell || Game.currentGame.field.cells[cy - 1][cx].constructor === CookieManCell) {
            return;
        }
    
        if (Game.currentGame.field.barriers.canMove(cy, cx, cy - 1, cx) === false) {
            return;
        }

        var buddy = Game.currentGame.field.cells[cy - 1][cx];
        this.canUp = false;
        this.y = cy - 1;

        var wasFinishMove = false;
        this.animate(BaseCell.ANIMATION_COOKIE_UP, function () {
            if (!wasFinishMove) {
                this.onFinishMoveListener({ x: this.x, y: this.y }, {});
                wasFinishMove = true;
            }
        }.bind(this));

        Game.currentGame.counter.setTimeout(function () {
            buddy.move({ x: cx, y: cy }, { moveInterval: 0.1 });
        }, 500);

        Game.currentGame.counter.setTimeout(function () {
            if (!wasFinishMove) {
                this.onFinishMoveListener({ x: this.x, y: this.y }, {});
                wasFinishMove = true;
            }
            Game.currentGame.field.cells[cy][cx] = buddy;
            Game.currentGame.field.cells[cy - 1][cx] = this;
        }.bind(this), 1400);
    } else {
        if (Game.currentGame.field.barriers.canMove(cy, cx, cy - 1, cx) === false) {
            return;
        }
        
        this.canUp = false;

        if (Game.currentGame.moves > 0) {
            Game.currentGame.setMoves(Game.currentGame.moves - 1, {
                delay: 300
            });
        }

        this.animate(BaseCell.ANIMATION_COOKIE_OUT, function () {
        });

        Game.currentGame.counter.setTimeout(function () {
            if (Game.currentGame) {
                this.delete();
                Game.currentGame.field.cells[cy][cx] = undefined;
                ColorCookieManCell.exitToNextMove++;
            }
        }.bind(this), 1300);
    }
};

ColorCookieManCell.prototype.getViewClass = function () {
    return ColorCookieManCellView;
};

ColorCookieManCell.prototype.bundleId = function () {
    return "colorcookieman";
};

ColorCookieManCell.prototype.load = function (data) {
    var color = data[1];
    var colorComponent = this.findComponent(ColorComponent);
    colorComponent.setColor(color === "." ? this.getRandomColor() : color);
};

ColorCookieManCell.prototype.smile = function () {
    if (this.alive) {
        this.animate(BaseCell.ANIMATION_SMILE);
    }
};

ColorCookieManCell.prototype.save = function () {
    return ColorCookieManCell.CODES.concat([this.findComponent(ColorComponent).color]);
};

ColorCookieManCell.prototype.explode = function (params) {
    this.explodeViaCollect(params);
};

ColorCookieManCell.prototype.getGoalId = function () {
    return ColorCookieManCell.GOAL_ID;
};

ColorCookieManCell.GOAL_ID = "color_cookie_man";