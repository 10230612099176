/**
 * Created by olga on 30.01.2023
 */

ToolModel.prototype._iterate = function (iterator, item) {
    iterator(item);

    var children = item.children();
    if (children) {
        children.forEach(function (childItem) {
            this._iterate(iterator, childItem);
        }, this);
    }
};

ToolModel.prototype.find = function (input) {
    input = input.toLowerCase().trim();

    var priorities = [];
    this._iterate(function (item) {
        var priority = this.getPriority(input, item);
        if (priority) {
            priorities.push(priority);
        }
    }.bind(this), this.root);

    priorities.sort(function (a, b) {
        return b - a;
    });

    var MAX_RESULT_LENGTH = 20;

    priorities = priorities.slice(0, MAX_RESULT_LENGTH);
    var threshold = priorities[priorities.length - 1];
    if (!threshold) {
        return [];
    }
    var items = [];
    var used = new Set();
    used.add(this.root);
    this._iterate(function (item) {
        var priority = this.getPriority(input, item);

        if (priority < threshold || items.length > MAX_RESULT_LENGTH) {
            return;
        }

        if (!used.has(item.parent) && item.parent) {
            used.add(item.parent);
            items.push(item.parent);
        }

        if (!used.has(item)) {
            used.add(item);
            items.push(item);
        }
    }.bind(this), this.root);

    return items;
};

ToolModel.prototype.getPriority = function (input, item) {
    var key = item.key.toLowerCase();

    var res = 0;
    if (input === key) {
        res = 30;
    } else if (key.startsWith(input)) {
        res = 20;
    } else if (key.includes(input)) {
        res = 10;
    }

    if (item.parent && item.parent !== this.root) {
        res += this.getPriority(input, item.parent) / 10;
    }

    if (res >= 1) {
        return res;
    }
    return 0;
};