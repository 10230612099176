/**
 * Created by Ivan on 20.07.2023
 */

var AdsVideo = cc.Node.extend({
    ctor: function () {
        this._super();
        new VideoNode("cleverapps/res/bundles/ads/videos/video1.mp4");
    }
});

AdsVideo.BUNDLES = ["ads_video"];

AdsVideo.PROPERTIES = [{
    name: "video", type: "video", folders: ["videos"]
}, {
    name: "listensEvent", type: "string", items: Object.values(Wysiwyg.EVENTS).concat([Wysiwyg.NOT_SELECTED]), value: Wysiwyg.NOT_SELECTED
}, {
    name: "delayAfterListenedEvent", type: "number"
}];