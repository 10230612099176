/**
 * Created by vladislav on 1/26/2024
 */

var YandexLogic = function () {
    PlatformLogic.call(this);

    connector.ads.on("error:details:yandex:showRewarded", this.onRewardedError.bind(this));
    connector.ads.on("error:details:yandex:showInterstitial", this.onInterstitialError.bind(this));
};

YandexLogic.prototype = Object.create(PlatformLogic.prototype);
YandexLogic.prototype.constructor = YandexLogic;

YandexLogic.prototype.onRewardedError = function (error) {
    cleverapps.throwAsync({ group: "ads_play_unknown_yandex", message: "rewarded " + connector.utils.stringify(error) });
};

YandexLogic.prototype.onInterstitialError = function (error) {
    cleverapps.throwAsync({ group: "ads_play_unknown_yandex", message: "interstitial " + connector.utils.stringify(error) });
};

YandexLogic.prototype.logPushes = function () {
    var payload = ysdk.environment.payload && JSON.parse(ysdk.environment.payload);
    if (payload && payload.push_code) {
        cleverapps.localPushes.logClickEvent(payload.push_code);
    }
};
