/**
 * Created by Andrey Popov on 9/12/23.
 *
 * Sync to drive using https://github.com/astrada/google-drive-ocamlfuse/tree/beta
 *
 * link to drive https://drive.google.com/drive/folders/1TxCMoaNO7ttThSpbTXWVVkKMHdjAlZ15?usp=sharing
 * wysiwygassets@gmail.com
 * wysiwyg_@ssets
 *
 * mount command (use new label every time - the old one becomes deprecated)
 * sudo google-drive-ocamlfuse -o allow_other -label wysiwyg29jan /var/www/html/publish/wysiwyg/assets -headless -id 763921670680-o8sue6ps1khr99j024o92847nj7pvjvv.apps.googleusercontent.com -secret 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
 *
 * verification code can expire. Use another label to reconnect and
 *
 * sudo umount -l /var/www/html/publish/wysiwyg/assets
 *
 * For local testing user:
 * sudo google-drive-ocamlfuse -o allow_other -label wysiwyg15jan /opt/workspaces/mergecraft/publish/wysiwyg/assets -headless -id 763921670680-o8sue6ps1khr99j024o92847nj7pvjvv.apps.googleusercontent.com -secret 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
 */

var GoogleDriveProvider = function () {
    this.path = (cleverapps.isLocalhost() ? "/mergecraft" : "https://stands.labsystech.ru") + "/publish/wysiwyg/assets/public/";
    this.assets = {};
};

GoogleDriveProvider.prototype.getLink = function (asset) {
    if (!asset.folder || !asset.name) {
        return undefined;
    }
    return this.path + asset.folder + "/" + asset.name;
};

GoogleDriveProvider.prototype.getSoundLink = function (asset) {
    if (!asset.folder || !asset.name) {
        return undefined;
    }
    var url = this.path + asset.folder + "/" + asset.name;
    var packedSound = {
        resolve: function () {
            return packedSound;
        },

        getUrl: function () {
            return url;
        },

        isLazy: function () {
            return false;
        },

        isSound: function () {
            return true;
        }
    };
    return packedSound;
};

GoogleDriveProvider.prototype.getSpinePath = function (asset) {
    if (!asset.folder || !asset.name) {
        return undefined;
    }

    return this.path + asset.folder + "/" + asset.name + "/" + asset.name;
};

GoogleDriveProvider.prototype.setAssets = function (assets) {
    this.assets = assets;
};

GoogleDriveProvider.prototype.getAssetsInFolders = function (folders) {
    var result = {};
    var hasAssets = false;

    folders.forEach(function (folderString) {
        if (this.assets[folderString]) {
            this.assets[folderString].forEach(function (assetName) {
                result[assetName] = folderString;
                hasAssets = true;
            });
        }
    }.bind(this));

    if (!hasAssets) {
        cleverapps.notification.create("Please auth to Google Drive using instruction in cleverapps/wysiwyg/googledriveprovider.js");
    }
    return result;
};

GoogleDriveProvider.prototype.hasAsset = function (asset) {
    if (!asset || !asset.name || !asset.folder) {
        return false;
    }
    return this.assets[asset.folder] && this.assets[asset.folder].indexOf(asset.name) !== -1;
};
