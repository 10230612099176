/**
 * Created by vtbel on 03.11.2020.
 */

var AdminUpdateGooglesheetsWindow = CleverappsWindow.extend({
    onWindowLoaded: function () {
        this._super({
            noBackground: true,
            closeButton: false,
            name: "adminupdategooglesheetswindow",
            content: this.getContent()
        });

        cleverapps.administrator.updateGooglesheets(this.createListener(this.generateInfo.bind(this)));
    },

    generateTextRow: function (text) {
        var styles = cleverapps.styles.AdminUpdateGooglesheetsWindow;

        var elems = [];
        var ttf = cleverapps.UI.generateTTFText(text, cleverapps.styles.FONTS.ADMIN_GOOGLESHEETS_TEXT);
        ttf.setDimensions(styles.scroll.width - styles.viewButton.width - styles.viewButton.margin, 0);
        ttf.setHorizontalAlignment(cc.TEXT_ALIGNMENT_LEFT);
        elems.push(ttf);

        if (text.indexOf("row:") >= 0) {
            var row = parseInt(text.substr(text.indexOf("row:") + 4));
            var viewButton = new cleverapps.UI.Button({
                type: cleverapps.styles.UI.Button.Images.editor,
                text: "view",
                width: styles.viewButton.width,
                height: styles.viewButton.height,
                onClicked: function () {
                    cleverapps.administrator.scrambleGotoRow(row);
                }
            });

            elems.push(viewButton);
        } else {
            var el = new cc.Node();
            el.setContentSize2(styles.viewButton.width, 0);
            elems.push(el);
        }

        return new cleverapps.Layout(elems, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.viewButton.margin
        });
    },

    generateInfo: function (result) {
        var styles = cleverapps.styles.AdminUpdateGooglesheetsWindow;
        this.loading.removeFromParent();

        var err = "Aborting writing jsons";
        var copy = result;

        if (result.indexOf(err) >= 0) {
            var beg = "Writing jsons...\n";
            copy = copy.substr(copy.indexOf(beg) + beg.length);
            copy = copy.substr(0, copy.indexOf(err));
        }

        this.inputDiv = document.createElement("textarea");
        this.inputDiv.type = "text";
        this.inputDiv.value = copy;
        document.body.appendChild(this.inputDiv);

        var texts = result.split("\n");
        var rows = [];
        for (var i = texts.length - 1; i >= 0; i--) {
            rows.unshift(this.generateTextRow(texts[i]));
        }

        var scrollContent = new cleverapps.Layout(rows, {
            direction: cleverapps.UI.VERTICAL,
            margin: 0
        });

        var scrollContainer = new cleverapps.UI.ScrollView(scrollContent);
        scrollContainer.setContentSize2(styles.scroll);
        scrollContainer.setPositionRound(styles.scroll);
        this.content.addChild(scrollContainer);

        var copyButton = new cleverapps.UI.Button({
            type: cleverapps.styles.UI.Button.Images.editor,
            text: "copy to clipboard",
            width: styles.copyButton.width,
            height: styles.copyButton.height,
            onClicked: function () {
                this.inputDiv.select();
                document.execCommand("copy");
            }.bind(this)
        });
        copyButton.setPositionRound(styles.copyButton);

        this.content.addChild(copyButton);
    },

    onClose: function () {
        if (this.inputDiv) {
            this.inputDiv.remove();
            this.inputDiv = undefined;
        }
    },

    getContent: function () {
        var styles = cleverapps.styles.AdminUpdateGooglesheetsWindow;

        var content = this.content = cleverapps.UI.createScale9Sprite(bundles.admin.frames.lvl_bg);
        content.setContentSize2(styles.content);

        this.loading = new cc.Sprite(bundles.admin.frames.loading_png);
        this.loading.runAction(new cc.RepeatForever(new cc.RotateBy(5, 360)));
        this.loading.setPositionRound(content.width / 2, content.height / 2);
        content.addChild(this.loading);

        var closeButton = new cleverapps.UI.Button({
            type: cleverapps.styles.UI.Button.Images.editor,
            onClicked: this.close.bind(this)
        });
        closeButton.setPositionRound(styles.closeButton);
        content.addChild(closeButton);

        return content;
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    ADMIN_GOOGLESHEETS_TEXT: {
        size: 22,
        color: cleverapps.styles.COLORS.ADMIN_TEXT_COLOR
    }
});

cleverapps.styles.AdminUpdateGooglesheetsWindow = {
    content: {
        width: 750,
        height: 800
    },

    viewButton: {
        width: 68,
        height: 36,
        margin: 15
    },

    copyButton: {
        width: 200,
        height: 60,
        margin: 50,
        x: { align: "center" },
        y: { align: "bottom", dy: 50 }
    },

    scroll: {
        width: 700,
        height: 620,
        x: { align: "center" },
        y: { align: "top", dy: -30 }
    },

    closeButton: {
        x: { align: "right", dx: 20 },
        y: { align: "top", dy: 20 }
    }
};