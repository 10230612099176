/**
 * Created by decipaliz on 23.05.2024
 */

var PrizeCalendarMissionLogic = function (mission) {
    this.mission = mission;

    this.checkCompletion();
};

PrizeCalendarMissionLogic.prototype.getTilesAmount = function () {
    return RewardsConfig.PrizeCalendar.stages.length;
};

PrizeCalendarMissionLogic.prototype.checkCompletion = function () {
    if (this.mission.result === this.getTilesAmount()) {
        this.mission.complete();
    }
};

PrizeCalendarMissionLogic.prototype.isAttentionRequired = function () {
    return this.mission.result === 0;
};

PrizeCalendarMissionLogic.prototype.onComplete = function () {
    if (this.mission.result === this.getTilesAmount()) {
        cleverapps.abTest.allLogEvent(cleverapps.EVENTS.PRIZECAL_COMPLETE);
    }
};