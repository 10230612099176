/**
 * Created by iamso on 23.11.2020.
 */

var AdminLog = function () {
    cleverapps.EventEmitter.call(this);
    this.entries = [];
    this.lastId = 0;
    this.inUpdate = false;
    this.targetEpisode = undefined;

    this.setUpdates();
};

AdminLog.prototype = Object.create(cleverapps.EventEmitter.prototype);
AdminLog.prototype.constructor = AdminLog;

AdminLog.prototype.setUpdates = function () {
    cleverapps.timeouts.setInterval(function () {
        if (!cleverapps.environment.isAdministratorScene() || this.lastId === 0) {
            return;
        }

        var updated = this.getUpdatedEntries().map(function (entry) {
            return entry.id;
        });

        var paramsArray = [];
        if (updated.length) {
            paramsArray.push("toupdate=" + updated);
        }
        if (this.targetEpisode !== undefined) {
            paramsArray.push("episode=" + this.targetEpisode);
        }

        this.requestServerLogs("/admin/log/getnew/" + this.lastId + "?" + paramsArray.join("&"));
    }.bind(this), AdminLog.UPDATE_INTERVAL);
};

AdminLog.prototype.getUpdatedEntries = function () {
    var candidates = this.entries.filter(function (entry) {
        return entry.updatedEntry && ((Date.now() - entry.date) / (1000 * 60 * 60 * 24)) < 1;
    });

    candidates.sort(function (a, b) {
        return b.id - a.id;
    });

    var res = [];
    candidates.forEach(function (a) {
        if (!res.some(function (b) {
            return a.type === b.type && a.userName === b.userName && JSON.stringify(a.params) === JSON.stringify(b.params);
        })) {
            res.push(a);
        }
    });

    return res;
};

AdminLog.prototype.createEntry = function (data) {
    var entry = new AdminLogEntry(data);
    if (entry.filter && !entry.filter()) {
        return;
    }

    entry.save(function () {
        this.pushEntry(entry);
    }.bind(this));

    return entry;
};

AdminLog.prototype.checkAmount = function () {
    while (this.entries.length > AdminLog.MAX_AMOUNT) {
        var entry = this.entries.shift();
        entry.remove();
    }
};

AdminLog.prototype.pushEntry = function (newEntry) {
    if (this.findEntryById(newEntry.id)) {
        return;
    }

    this.entries.push(newEntry);
    this.trigger("addLogEntry", newEntry);
    this.checkAmount();

    this.lastId = newEntry.id;
};

AdminLog.prototype.findEntryById = function (id) {
    for (var i = 0; i < this.entries.length; i++) {
        if (this.entries[i].id === id) {
            return this.entries[i];
        }
    }
};

AdminLog.prototype.parseServeData = function (serverDat) {
    serverDat.forEach(function (entryData) {
        var data = {
            id: entryData.id,
            type: entryData.type,
            userName: entryData.username,
            date: new Date(entryData.event)
        };

        var existing = this.findEntryById(data.id);
        if (existing) {
            existing.updateData(data, true);
            return;
        }

        Object.assign(data, JSON.parse(entryData.data));
        if (Object.keys(AdminLog.ACTIONS).indexOf("" + data.type) === -1) {
            console.log("Unknown adminlog type received, please refresh the app!");
            this.lastId = data.id;
            return;
        }

        var entry = new AdminLogEntry(data);
        this.pushEntry(entry);
    }, this);
};

AdminLog.prototype.loadLastEntries = function () {
    this.requestServerLogs("/admin/log/getlast/" + AdminLog.MAX_AMOUNT + (this.targetEpisode !== undefined ? ("/" + this.targetEpisode) : ""));
};

AdminLog.prototype.requestServerLogs = function (path) {
    if (this.inUpdate) {
        return;
    }

    this.inUpdate = true;
    cleverapps.RestClient.get(path, {}, function (data) {
        this.inUpdate = false;
        this.parseServeData(data);
    }.bind(this), function (err) {
        this.inUpdate = false;
        console.log("Error loading admin logs", err);
    }.bind(this));
};

AdminLog.prototype.toggleMode = function () {
    if (this.inUpdate) {
        return;
    }

    if (this.targetEpisode !== undefined) {
        this.targetEpisode = undefined;
    } else {
        this.targetEpisode = cleverapps.administrator.adminEpisodes.getActive().episodeNo;
    }

    this.clearEntries();
    this.loadLastEntries();
};

AdminLog.prototype.clearEntries = function () {
    while (this.entries.length > 0) {
        var entry = this.entries.shift();
        entry.remove();
    }
};

AdminLog.prototype.onChangeEpisode = function (episode) {
    if (this.targetEpisode !== undefined && this.targetEpisode !== episode.episodeNo) {
        this.targetEpisode = episode.episodeNo;
        this.clearEntries();
        this.loadLastEntries();
    }
};

AdminLog.MAX_AMOUNT = 25;
AdminLog.UPDATE_INTERVAL = 10000;