/**
 * Created by evgenijsenkevic on 27.08.2024
 * */

var AdminLocations = function () {
    cleverapps.EventEmitter.call(this);
    this.adminLocations = [];

    var bundleNames = AdminLocations.GetLocationBundleNames();

    for (var i = 0; i < bundleNames.length; i++) {
        this.adminLocations.push(new AdminLocation(bundleNames[i]));
    }

    this.setActive(this.adminLocations[0]);
};

AdminLocations.prototype = Object.create(cleverapps.EventEmitter.prototype);
AdminLocations.prototype.constructor = AdminLocations;

AdminLocations.prototype.setActive = function (location) {
    if (this.activeLocation === location) {
        return;
    }

    if (this.activeLocation) {
        this.activeLocation.unselect();
    }

    this.activeLocation = location;
    location.select();

    this.trigger("updateActive", location);
};

AdminLocations.prototype.play = function () {
    if (!this.activeLocation) {
        return;
    }

    cleverapps.meta.display({
        focus: "AdminPlayLocation",
        action: function (f) {
            cleverapps.scenes.replaceScene(cleverapps.config.type === "merge"
                ? new MergeScene({ level: new Level(this.activeLocation.getLocationName(), 0) }) : new HomefixScene(), f);
        }.bind(this)
    });
};

AdminLocations.prototype.edit = function () {
    if (!this.activeLocation) {
        return;
    }

    cleverapps.setUrlHash({
        mode: "editor_location",
        location: this.activeLocation.getLocationName()
    });

    cleverapps.meta.display({
        focus: "AdminEditLocation",
        action: function (f) {
            EditorLocationScene.open(f);
        }
    });
};

AdminLocations.prototype.save = function (data) {
    cleverapps.git.edit("location", {
        content: data
    });
};

AdminLocations.GetLocationBundleNames = function () {
    return Object.values(bundles).filter(function (bundle) {
        return bundle.meta.location;
    }).map(function (bundle) {
        return bundle.name;
    });
};