/**
 * Created by Andrey Popov on 13.12.2022
 */

var AdsGame = cc.Scene.extend({
    ctor: function (properties, eventBus) {
        this._super();

        this.properties = properties || {};
        this.eventBus = eventBus;
        this.generatesEvents = this.properties.generatesEvents || [];
        this.listensEvents = this.properties.listensEvents || [];

        if (this.listensEvents.indexOf(Wysiwyg.EVENTS.SCENE_STARTED) !== -1) {
            eventBus.on(Wysiwyg.EVENTS.SCENE_STARTED, this.play.bind(this), this);
        }

        if (cleverapps.config.type === "board" && this.properties.current) {
            this.updateCurrent();
        }

        if (cleverapps.config.type === "board") {
            this.updateGridColumns();
        }
    },

    updateCurrent: function () {
        var current = this.properties.current[cleverapps.settings.language] || this.properties.current.general && this.properties.current.general[cleverapps.settings.language];
        if (!Game.currentGame) {
            return;
        }

        if (!current) {
            Game.currentGame.board.clearHighlight();
            return;
        }

        var game = Game.currentGame;
        game.keypad.reset();
        game.board.clearHighlight();

        var currentLetters = current.toLowerCase().split("");
        game.keypad.letters.forEach(function (letter) {
            var index = currentLetters.indexOf(letter.symbol);
            if (index !== -1) {
                currentLetters[index] = letter.index;
            }
        });
        game.keypad.setControlType(Keypad.CONTROL_TYPES.SNAPSHOT_CLICK, true);
        currentLetters.forEach(function (index) {
            if (this.letters[index]) {
                this.push(this.letters[index], true);
            }
        }.bind(game.keypad));

        game.board.highlightWord(current);
    },

    updateGridColumns: function () {
        if (!Game.currentGame) {
            return;
        }

        var columnsCount = this.properties.columns;
        if (columnsCount) {
            Game.currentGame.board.setColumnsAmount(columnsCount.general);
        }
    },

    init: function () {
        var scene = cleverapps.scenes.getRunningScene();
        scene.setPosition(scene.width / 2 + this.properties.position.x.dx, scene.height / 2 + this.properties.position.y.dy);

        if (this.properties.scale) {
            scene.setScale(this.properties.scale || 1);
        }

        var w = this.properties.width || 100;
        var h = this.properties.height || 100;
        ResolutionManager.prototype.getSceneSize = function () {
            return cc.size(this.sceneRect.width * w / 100, this.sceneRect.height * h / 100);
        };

        setTimeout(function () {
            scene.setPosition(scene.width / 2 + this.properties.position.x.dx, scene.height / 2 + this.properties.position.y.dy);

            if (this.properties.scale) {
                scene.setScale(this.properties.scale);
            }

            this.parent && this.parent.onComponentSizeChanged();
        }.bind(this), 0);
    },

    play: function () {
        var actions = this.properties.actions || cleverapps.loadedSnapshot.actions || [];

        var clipProps = cleverapps.wysiwyg.hierarchy.getSelectedClip().properties;

        this.player = new GamePlayer({
            finger: {
                image: clipProps.finger ? cleverapps.wysiwyg.googleDriveProvider.getLink(clipProps.finger) : undefined,
                noCircle: this.properties.fingerNoCircle,
                name: clipProps.finger ? clipProps.finger.image : undefined
            },
            progressChanged: function (progress) {
                if (this.generatesEvents.indexOf(Wysiwyg.EVENTS.PROGRESS_CHANGED) !== -1) {
                    this.eventBus.trigger(Wysiwyg.EVENTS.PROGRESS_CHANGED, progress);
                }
            }.bind(this)
        });

        this.player.play({
            actions: actions,
            delay: this.properties.actionsDelay || 0,
            initialDelay: this.properties.playDelay || 0,
            fingerSpeedMultiplier: this.properties.fingerSpeedMultiplier && this.properties.fingerSpeedMultiplier > 0.1 ? this.properties.fingerSpeedMultiplier : 1,
            onStartPlay: function () {
                if (this.generatesEvents.indexOf(Wysiwyg.EVENTS.GAME_STARTED) !== -1) {
                    this.eventBus.trigger(Wysiwyg.EVENTS.GAME_STARTED);
                }
            }.bind(this)
        }, this.onGameFinished.bind(this));
    },

    onGameFinished: function () {
        if (this.generatesEvents.indexOf(Wysiwyg.EVENTS.GAME_FINISHED) !== -1) {
            this.eventBus.trigger(Wysiwyg.EVENTS.GAME_FINISHED);
        }

        setTimeout(this.runFinishAnimation.bind(this), this.properties.finishAnimationDelay || 0);

        setTimeout(function () {
            if (this.generatesEvents.indexOf(Wysiwyg.EVENTS.SCENE_COMPLETED) !== -1) {
                this.eventBus.trigger(Wysiwyg.EVENTS.SCENE_COMPLETED);
            }
        }.bind(this), this.properties.clipCompletedDelay || 1200);
    },

    runFinishAnimation: function () {
        if (this.properties.finishAnimation === AdsGame.FINISH_ANIMATIONS.FADE_OUT) {
            this.getParent().getParent().children.forEach(function (child) {
                if (child.tag !== "clipBg") {
                    child.setCascadeOpacityEnabledRecursively(true);

                    child.runAction(new cc.FadeOut(1.2));
                }
            });
        }
    }
});

AdsGame.FINISH_ANIMATIONS = {};
AdsGame.FINISH_ANIMATIONS.FADE_OUT = "fade out";

AdsGame.PROPERTIES = [{
    name: "isGame", value: true
}, {
    name: "snapshot", type: "string", link: true, bubbleUp: true, dirtyOnChange: true
}, {
    name: "flags",
    type: "string",
    multiselect: true,
    items: function () {
        return Object.keys(cleverapps.gameModes); 
    },
    bubbleUp: true
}, {
    name: "actions", type: "actions", bubbleUp: true
}, {
    name: "generatesEvents",
    type: "string",
    items: Object.values(Wysiwyg.EVENTS),
    multiselect: true,
    value: [Wysiwyg.EVENTS.GAME_STARTED, Wysiwyg.EVENTS.GAME_FINISHED, Wysiwyg.EVENTS.PROGRESS_CHANGED, Wysiwyg.EVENTS.SCENE_COMPLETED]
}, {
    name: "listensEvents", type: "string", items: Object.values(Wysiwyg.EVENTS), multiselect: true, value: [Wysiwyg.EVENTS.SCENE_STARTED]
}, {
    name: "playDelay", type: "number", value: 0
}, {
    name: "actionsDelay", type: "number", value: 0
}, {
    name: "clipCompletedDelay", type: "number", value: 1200
}, {
    name: "finishAnimation", type: "string", value: Wysiwyg.NOT_SELECTED, items: Object.values(AdsGame.FINISH_ANIMATIONS).concat([Wysiwyg.NOT_SELECTED])
}, {
    name: "finishAnimationDelay", type: "number", value: 0
}, {
    name: "fingerNoCircle", type: "checkBox", value: false
}, {
    name: "fingerSpeedMultiplier", type: "number", value: 1
}, {
    name: "position",
    type: "position",
    value: {
        x: { align: "center", dx: "-50%" },
        y: { align: "center", dy: "-50%" }
    }
}, {
    name: "height", type: "number", allowOverride: true, value: 100
}, {
    name: "width", type: "number", allowOverride: true, value: 100
},
{ name: "delay", type: "number", disabled: true },
{ name: "rotation", type: "number", disabled: true },
{ name: "anchorX", type: "number", disabled: true },
{ name: "anchorY", type: "number", disabled: true },
{ name: "adHoc", type: "adHoc" }
];

if (cleverapps.config.type === "board") {
    AdsGame.PROPERTIES.push({
        name: "columns", type: "number", allowOverride: true, value: 2
    });
    AdsGame.PROPERTIES.push({ name: "current", type: "localizedText" });
}