/**
 * Created by Vladislav on 25.09.2024.
 */

Placements.ENTRIES["animatePassLevel"] = {
    type: Placements.FREE_FOCUS_MAIN,
    priority: -2,

    filter: function () {
        return cleverapps.playButton.passedLevel && cleverapps.isLevels();
    },

    action: function () {
        cleverapps.playButton.animatePassLevel();
    }
};