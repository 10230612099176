/**
 * Created by mac on 10/18/17.
 */

var WindowGoalView = cc.Node.extend({
    ctor: function (goal, icon) {
        this._super();

        var styles = cleverapps.styles.WindowGoalView;

        icon.stopAllActions();

        this.icon = icon;
        this.addChild(icon);

        this.setContentSize(styles);
        this.setAnchorPoint(0.5, 0.5);

        icon.setPositionRound(styles.icon);

        if (icon.life) {
            icon.life.updateAmount(goal.target - goal.amount, { silent: true });
            icon.life.setScale(icon.life.scale * 0.8);
        } else {
            icon.amount.setVisible(false);
        }

        var isComplete = goal.amount >= goal.target;

        if (isComplete) {
            icon.setCompleted();
            if (icon.checkMark) {
                icon.checkMark.setPositionRound(styles.check);
            }
        } else if (!cleverapps.config.rpg) {
            var amount = cleverapps.UI.generateImageText(goal.amount, cleverapps.styles.FONTS.WINDOW_GOAL_AMOUNT_TEXT);

            goal = cleverapps.UI.generateImageText("/" + goal.target, cleverapps.styles.FONTS.WINDOW_GOAL_TEXT);

            var amountNode = new cleverapps.Layout([amount, goal], {
                direction: cleverapps.UI.HORIZONTAL,
                margin: styles.goal.margin
            });
            this.addChild(amountNode);
            amountNode.setPositionRound(styles.amount);
        }

        this.setupChildren();
    },

    setupChildren: function () {
        if (this.icon.actor && cleverapps.config.name === "adventure") {
            this.icon.setScale(cleverapps.styles.WindowGoalView.icon.scale);
            this.icon.actor.setPosition(this.icon.width / 2, 0);
        }
    },

    getPreferredBundles: function () {
        return ["game"];
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    WINDOW_GOAL_AMOUNT_TEXT: {
        size: 30,
        color: new cc.Color(255, 92, 92, 255)
    },
    WINDOW_GOAL_TEXT: {
        size: 30,
        color: cleverapps.styles.COLORS.WHITE
    }
});

cleverapps.styles.WindowGoalView = {
    width: 110,
    height: 160,
    check: {
        x: { align: "center" },
        y: { align: "bottom", dy: -40 }
    },
    goal: {
        margin: 0
    },
    amount: {
        x: { align: "center" },
        y: { align: "bottom", dy: 10 }
    },
    icon: {
        x: { align: "center" },
        y: { align: "bottom", dy: 53 }
    }
};