/**
 * Created by iamso on 05.03.19.
 */

var SideBarView = cc.Node.extend({
    ctor: function () {
        this._super();

        this.updateSize();

        this.slots = SideBar.SLOTS.map(function (slot) {
            var slotView = new SideBarSlotView(slot, this);
            this.addChild(slotView);
            return slotView;
        }, this);

        cleverapps.sideBar.refreshAllIcons();

        cleverapps.sideBar.onAddIcon = this.createListener(this.insert.bind(this));
        cleverapps.sideBar.onMoveIcon = this.createListener(this.move.bind(this));
        cleverapps.sideBar.onRemoveIcon = this.createListener(this.eject.bind(this));
        cleverapps.sideBar.onForceIcon = this.createListener(this.onForce.bind(this));

        for (var i = 0; i < cleverapps.sideBar.icons.length; i++) {
            this.insert(i);
        }

        this.updateZOrder();
    },

    updateSize: function () {
        var sceneSize = cleverapps.resolution.getSceneSize();
        var height = sceneSize.height;
        this.setAnchorPoint(0.5, 0.5);

        this.setContentSize2(sceneSize.width, height);
        this.setPositionRound(sceneSize.width / 2, height / 2);
    },

    onForce: function (index) {
        this.slots[index].showForce();
    },

    insert: function (index) {
        if (this.slots[index]) {
            var IconView = cleverapps.sideBar.icons[index].getView();
            this.slots[index].insert(new IconView(cleverapps.sideBar.icons[index]));
        }
    },

    eject: function (index) {
        if (this.slots[index]) {
            this.slots[index].eject();
        }
    },

    move: function (dest, source) {
        if (!this.slots[dest]) {
            if (this.slots[source]) {
                this.slots[source].eject();
            }
            return;
        }

        var view;
        if (this.slots[source]) {
            view = this.slots[source].release();
        }

        if (!view) {
            var icon = cleverapps.sideBar.icons[dest];
            var IconView = icon.getView();
            view = new IconView(icon);
        }

        this.slots[dest].insert(view);
    },

    updateZOrder: function () {
        this.setLocalZOrder(cleverapps.meta.isFocused() ? BaseWindow.WINDOWS_ZORDER + 1 : SideBarView.ZORDER);
    }
});

SideBarView.ZORDER = 1;
