/**
 * Created by Ivan on 30.07.2024
 */

var StickersComponent = cc.Node.extend({
    ctor: function (collection) {
        this._super();

        var styles = cleverapps.styles.StickersComponent;

        this.collection = collection;
        var stickers = this.stickers = this.createStickers(collection.stickers);

        var layout = new cleverapps.GridLayout(stickers, {
            columns: cleverapps.resolution.mode !== cleverapps.WideMode.HORIZONTAL ? 3 : 5,
            margin: styles.layout.margin[cleverapps.resolution.mode]
        });
        layout.setPositionRound(styles.layout);

        if (styles.fg) {
            var fg = new cc.Scale9Sprite(bundles.stickers_collection_window.frames.foreground_png);
            fg.setContentSize2(styles.fg.size);

            fg.addChild(layout);
            fg.setPositionRound(styles.position);

            this.addChild(fg);
            this.setPositionRound(styles.position);
            this.setContentSize2(fg.getContentSize());
            fg.setPositionRound(this.width / 2, this.height / 2);
        } else {
            this.addChild(layout);
            this.setContentSize2(layout.getContentSize());
            this.setPositionRound(styles.layout);
            layout.setPositionRound(this.width / 2, this.height / 2);
        }
    },

    createStickers: function (stickers) {
        return stickers.map(function (sticker) {
            var ViewClass = sticker.getViewClass();
            var stickerView = new ViewClass(sticker, {
                withUpdate: true,
                badge: true,
                tape: cleverapps.styles.StickerView.tape
            });

            return stickerView;
        });
    },

    onClose: function () {
        this.collection.resetAttention();
    }
});

cleverapps.styles.StickersComponent = {
    position: [
        { x: { align: "center" }, y: { align: "center", dy: -98 } },
        { x: { align: "center" }, y: { align: "center", dy: -98 } },
        { x: { align: "center" }, y: { align: "center", dy: -111 } }
    ],

    fg: {
        size: [
            { width: 900, height: 1070 },
            { width: 900, height: 1070 },
            { width: 1500, height: 750 }
        ]
    },

    layout: {
        margin: [{ x: 30, y: 10 }, { x: 30, y: 10 }, { x: 40, y: 20 }],
        x: { align: "center" },
        y: { align: "center" }
    }
};