/**
 * Created by Oleg on 26/02/18
 */

var BandButton = cc.Node.extend({
    avoidNode: "BandButton",

    ctor: function (options) {
        this._super();

        this.options = options;

        this.setAnchorPoint(0.5, 0.5);
        this.setLocalZOrder(3);

        var bg = this.bg = cleverapps.UI.createScale9Sprite(bundles.buttons_main.frames.scene_exit_button, cleverapps.UI.Scale9Rect.TwoPixelXY);
        this.addChild(bg);

        var icon = this.icon = new cc.Sprite(bundles.buttons_main.frames.close_band_icon);
        this.addChild(icon);

        this.setupChildren();

        cleverapps.UI.applyHover(this);
        cleverapps.UI.onClick(this, function () {
            this.action();
        }.bind(this));
    },

    setupChildren: function () {
        var styles = cleverapps.styles.BandButton;
        var safePadding = cleverapps.resolution.getSafePadding();

        var contentSize = styles.size[cleverapps.resolution.mode] || styles.size;
        contentSize = {
            width: contentSize.width,
            height: contentSize.height + safePadding.top
        };

        this.setContentSize2(contentSize);
        this.updatePosition();

        this.bg.setContentSize2(contentSize);
        this.bg.setPositionRound(this.width / 2, this.height / 2);

        this.icon.setPositionRound(styles.icon);
    },

    completeAnimationOnResize: function () {
        this.stopAllActions();
        this.setVisible(true);
    },

    updatePosition: function () {
        var styles = cleverapps.styles.BandButton;

        var safePadding = cleverapps.resolution.getSafePadding();

        var position = styles.position[cleverapps.resolution.mode] || styles.position;
        position = cleverapps.clone(position, true);
        position.x.dx = (position.x.dx || 0);
        position.y.dy = (position.y.dy || 0) + safePadding.top;

        this.setPositionRound(position);
    },

    action: function () {
        cleverapps.audio.playSound(bundles.main.urls.click_effect);
        this.options.onClicked();
    },

    hide: function (silent) {
        this.stopAllActions();

        if (silent) {
            this.setVisible(false);
            return;
        }

        this.updatePosition();
        this.runAction(new cc.Sequence(
            new cc.MoveBy(0.3, 0, this.height).easing(cc.easeBackIn()),
            new cc.Hide()
        ));
    },

    show: function () {
        this.stopAllActions();
        this.updatePosition();
        this.runAction(new cc.Sequence(
            new cc.MoveBy(0, 0, this.height),
            new cc.Show(),
            new cc.MoveBy(0.3, 0, -this.height).easing(cc.easeBackOut())
        ));
    }
});

cleverapps.styles.BandButton = {
    size: {
        width: 85,
        height: 190
    },

    position: {
        x: { align: "right", dx: -40 },
        y: { align: "top", dy: 10 }
    },

    icon: {
        x: { align: "center" },
        y: { align: "bottom", dy: 25 }
    }
};
