/**
 * Created by vladislav on 3/4/2024
 */

var GameManager = function () {
    connector.platform.on("pause", this.pause.bind(this));
    connector.platform.on("resume", this.resume.bind(this));
};

GameManager.prototype.pause = function () {
    if (cc.game.isPaused() || cleverapps.gameModes && cleverapps.gameModes.noGamePause) {
        return;
    }

    if (connector.platform.oneOf(connector.INSTANT) && connector.info.os === connector.OS_IOS && connector.info.isInApp) {
        return;
    }

    console.log("pause game");

    cc.game.pause();

    if (cleverapps.audio) {
        cleverapps.audio.onChangeMusic();
    }

    if (cc.eventManager) {
        cc.eventManager.dispatchEvent(new cc.EventCustom(cc.game.EVENT_HIDE));
    }
};

GameManager.prototype.resume = function () {
    cleverapps.Audio.resumeAudioContext();

    if (!cc.game.isPaused() || cleverapps.gameModes && cleverapps.gameModes.noGamePause) {
        return;
    }

    console.log("resume game");

    cc.game.resume();

    if (cleverapps.audio) {
        cleverapps.audio.onChangeMusic();
    }

    if (cleverapps.allInitialized) {
        cleverapps.rewardedAdsManager.showInterstitial();
    }

    if (cc.eventManager) {
        cc.eventManager.dispatchEvent(new cc.EventCustom(cc.game.EVENT_SHOW));
    }
};