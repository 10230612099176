/**
 * Created by andrey on 23.05.2023
 */

var BoosterInfoView = cc.Node.extend({
    ctor: function (booster) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);
        this.setLocalZOrder(30);

        this.fieldView = Game.currentGame.field.onGetView();
        this.background = cleverapps.UI.createScale9Sprite(bundles.game.frames.booster_bg_png);
        this.addChild(this.background);

        var icon = this.icon = new cc.Sprite(booster.icon_active);
        var text = this.text = cleverapps.UI.generateTTFText("Boosters." + booster.id + ".Hint", cleverapps.styles.FONTS.BUSTER_NOTIFICATION_TEXT);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);

        var info = this.info = new cleverapps.Layout([icon, text], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: cleverapps.styles.BoosterInfoView.margin
        });
        this.addChild(info);

        this.updateSize();
        this.setupChildren();
    },

    updateSize: function () {
        var winSize = cleverapps.resolution.getSceneSize();
        var styles = cleverapps.styles.BoosterInfoView;

        var fieldSize = { width: this.fieldView.width * this.fieldView.scale, height: this.fieldView.height * this.fieldView.scale };

        var height = styles.sizes[cleverapps.resolution.mode].height;
        var width = styles.sizes[cleverapps.resolution.mode].width;

        this.setContentSize2(width === "field" ? fieldSize.width : winSize.width + styles.sizes[cleverapps.resolution.mode].addx, height);

        this.background.setContentSize2(this.width, 2 * height);
        this.icon.setScale(styles.icon.scales[cleverapps.resolution.mode].scale);

        var infoWidth = width === "field" ? this.width : winSize.width;

        var textWidth = infoWidth - this.icon.width * this.icon.scale - 3 * styles.margin;
        this.text.setDimensions(textWidth, 0);
        this.text.fitTo(undefined, this.info.height);

        this.info.reshape();
    },

    setupChildren: function () {
        this.background.setPositionRound(this.width / 2, this.height);
        this.info.setPositionRound(this.width / 2, this.height / 2);

        this.setPositionRound(this.fieldView.x, { align: "top" });
    },

    hide: function (silent) {
        var hidePosition = this.calculatePositionRound(this.fieldView.x, { align: "top", dy: 1.1 * this.height });

        if (silent) {
            this.setPosition(hidePosition);
            this.setVisible(false);
            return;
        }

        this.runAction(
            new cc.Sequence(
                new cc.MoveTo(0.3, hidePosition),
                new cc.Hide()
            )
        );
    },

    show: function () {
        this.runAction(
            new cc.Sequence(
                new cc.Show(),
                new cc.MoveTo(0.3, this.calculatePositionRound(this.fieldView.x, { align: "top" }))
            )
        );
    }
});

cleverapps.styles.BoosterInfoView = {
    sizes: [
        {
            width: "full",
            addx: 90,
            height: 220
        },
        {
            width: "field",
            height: 180
        },
        {
            width: "field",
            height: 110
        }
    ],

    icon: {
        scales: [
            {
                scale: 1.2
            },
            {
                scale: 1
            },
            {
                scale: 0.7
            }
        ]
    },

    margin: 20
};