/**
 * Created by vladislav on 1/26/2024
 */

var InstantLogic = function () {
    PlatformLogic.call(this);

    SelectFriendWindow.MAX_SELECTED = 1;

    connector.payments.on("error:details:instant:getSubscriptionStatus", this.onGetSubscriptionStatusError.bind(this));
    connector.payments.on("error:details:instant:loadProducts", this.onLoadProductsError.bind(this));
    connector.payments.on("error:details:instant:openSubscription", this.onOpenSubscriptionError.bind(this));
    connector.payments.on("error:details:instant:purchase", this.onPurchaseError.bind(this));
    connector.payments.on("error:details:instant:loadSubscriptions", this.onLoadSubscriptionsError.bind(this));
    connector.payments.on("error:details:instant:consume", this.onConsumeError.bind(this));
    connector.payments.on("error:details:instant:restore", this.onRestoreError.bind(this));
    connector.ads.on("error:details:instant:showRewarded", this.onRewardedError.bind(this));
    connector.ads.on("error:details:instant:showInterstitial", this.onInterstitialError.bind(this));
    connector.ads.on("error:details:instant:cacheAd:rewarded", this.onRewardedCacheError.bind(this));
    connector.ads.on("error:details:instant:cacheAd:interstitial", this.onInterstitialCacheError.bind(this));
};

InstantLogic.prototype = Object.create(PlatformLogic.prototype);
InstantLogic.prototype.constructor = InstantLogic;

InstantLogic.prototype.onRewardedCacheError = function (error) {
    if (this.needToThrowCacheError(error)) {
        cleverapps.throwAsync({ group: "ads_loading_unknown_instant", message: "rewarded " + connector.utils.stringify(error) });
    }
};

InstantLogic.prototype.onInterstitialCacheError = function (error) {
    if (this.needToThrowCacheError(error)) {
        cleverapps.throwAsync({ group: "ads_loading_unknown_instant", message: "interstitial " + connector.utils.stringify(error) });
    }
};

InstantLogic.prototype.onRewardedError = function (error) {
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.ADS_ERROR_REWARDED + "-" + (error && error.code));

    if (this.needToThrowPlayError(error)) {
        cleverapps.throwAsync({ group: "ads_play_unknown_instant", message: "rewarded " + connector.utils.stringify(error) });
    }
};

InstantLogic.prototype.onInterstitialError = function (error) {
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.ADS_ERROR_INTERSTITIAL + "-" + (error && error.code));

    if (this.needToThrowPlayError(error)) {
        cleverapps.throwAsync({ group: "ads_play_unknown_instant", message: "interstitial " + connector.utils.stringify(error) });
    }
};

InstantLogic.prototype.needToThrowPlayError = function (error) {
    switch (error.code) {
        case "USER_INPUT":
        case "PENDING_REQUEST":
        case "ADS_NOT_LOADED":
        case "INVALID_PARAM":
        case "NETWORK_FAILURE":
        case "INVALID_OPERATION":
        case "RATE_LIMITED":
        case "PAYMENTS_NOT_INITIALIZED": // wierd huh? but occurs in jserror.log
        case "ADS_EXPIRED":
        case "UNKNOWN":
            return false;
    }

    return true;
};

InstantLogic.prototype.needToThrowCacheError = function (error) {
    switch (error.code) {
        case "ADS_NO_FILL":
        case "INVALID_OPERATION":
        case "ADS_FREQUENT_LOAD":
        case "ADS_TOO_MANY_INSTANCES":
        case "NETWORK_FAILURE":
        case "UNKNOWN":
        case "CLIENT_UNSUPPORTED_OPERATION":
        case "INVALID_PARAM":
            return false;
    }

    return true;
};

InstantLogic.prototype.onGetSubscriptionStatusError = function (e) {
    switch (e.code) {
        case "CLIENT_UNSUPPORTED_OPERATION":
        case "NETWORK_FAILURE":
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.SUBSCRIPTION_LIST + e.code);
            break;
        default:
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.SUBSCRIPTION_LIST + "OTHER");
            cleverapps.throwAsync({ group: "DebugMessage", message: cleverapps.EVENTS.STATS.INSTANT.SUBSCRIPTION_LIST + e.code + " message: " + e.message });
    }
};

InstantLogic.prototype.onLoadProductsError = function (e) {
    if (!this.loadProductsErrorReported) {
        switch (e.code) {
            case "PENDING_REQUEST":
            case "CLIENT_UNSUPPORTED_OPERATION":
            case "PAYMENTS_NOT_INITIALIZED":
            case "NETWORK_FAILURE":
                cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.CATALOG + e.code);
                break;
            default:
                cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.CATALOG + "OTHER");
                cleverapps.throwAsync({ group: "DebugMessage", message: cleverapps.EVENTS.STATS.INSTANT.CATALOG + e.code + " message: " + e.message });
        }

        this.loadProductsErrorReported = true;
    }
};

InstantLogic.prototype.onOpenSubscriptionError = function (e) {
    switch (e.code) {
        case "CLIENT_UNSUPPORTED_OPERATION":
        case "PAYMENTS_NOT_INITIALIZED":
        case "INVALID_PARAM":
        case "NETWORK_FAILURE":
        case "INVALID_OPERATION":
        case "USER_INPUT":
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.SUBSCRIPTION + e.code);
            break;
        default:
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.SUBSCRIPTION + "OTHER");
            cleverapps.throwAsync({ group: "DebugMessage", message: cleverapps.EVENTS.STATS.INSTANT.SUBSCRIPTION + e.code + " message: " + e.message });
    }
};

InstantLogic.prototype.onPurchaseError = function (e) {
    switch (e.code) {
        case "CLIENT_UNSUPPORTED_OPERATION":
        case "PAYMENTS_NOT_INITIALIZED":
        case "INVALID_PARAM":
        case "NETWORK_FAILURE":
        case "INVALID_OPERATION":
        case "USER_INPUT":
        case "PAYMENTS_PURCHASE_CREATION_FAILED":
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.PAYMENT + e.code);
            break;
        default:
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.PAYMENT + "OTHER");
            cleverapps.throwAsync({ group: "DebugMessage", message: cleverapps.EVENTS.STATS.INSTANT.PAYMENT + e.code + " message: " + e.message });
    }
};

InstantLogic.prototype.onLoadSubscriptionsError = function (e) {
    if (!this.loadSubscriptionsErrorReported) {
        switch (e.code) {
            case "CLIENT_UNSUPPORTED_OPERATION":
            case "NETWORK_FAILURE":
            case "PENDING_REQUEST":
            case "PAYMENTS_NOT_INITIALIZED":
                cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.SUBSCRIPTION_CATALOG + e.code);
                break;
            default:
                cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.SUBSCRIPTION_CATALOG + "OTHER");
                cleverapps.throwAsync({ group: "DebugMessage", message: cleverapps.EVENTS.STATS.INSTANT.SUBSCRIPTION_CATALOG + e.code + " message: " + e.message });
        }

        this.loadSubscriptionsErrorReported = true;
    }
};

InstantLogic.prototype.onConsumeError = function (e, purchase) {
    switch (e.code) {
        case "CLIENT_UNSUPPORTED_OPERATION":
        case "PAYMENTS_NOT_INITIALIZED":
        case "NETWORK_FAILURE":
        case "UNKNOWN":
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.CONSUME + e.code);
            break;
        case "INVALID_PARAM":
            cleverapps.throwAsync("Instant consume INVALID_PARAM productId: " + purchase.productId
                + " paymentId: " + purchase.paymentId
                + " purchaseToken: " + purchase.payload.purchaseToken);
            break;
        default:
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.CONSUME + "OTHER");
            cleverapps.throwAsync({ group: "DebugMessage", message: cleverapps.EVENTS.STATS.INSTANT.CONSUME + e.code + " message: " + e.message });
    }
};

InstantLogic.prototype.onRestoreError = function (e) {
    switch (e.code) {
        case "CLIENT_UNSUPPORTED_OPERATION":
        case "PAYMENTS_NOT_INITIALIZED":
        case "NETWORK_FAILURE":
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.CONSUME + e.code);
            break;
        case "INVALID_PARAM":
            cleverapps.throwAsync("Instant consume INVALID_PARAM");
            break;
        default:
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.CONSUME + "OTHER");
            cleverapps.throwAsync({ group: "DebugMessage", message: cleverapps.EVENTS.STATS.INSTANT.CONSUME + e.code + " message: " + e.message });
    }
};

InstantLogic.prototype.reportScore = function (score, callback) {
    FBInstant.postSessionScoreAsync(score).then(function () {
        callback(cleverapps.CODE_SUCCEED);
    }).catch(function () {
        callback(cleverapps.CODE_FAILED);
    });
};

InstantLogic.prototype.getCurrentTournamentId = function (callback) {
    FBInstant.getTournamentAsync().then(function (tournament) {
        var expired = new Date(tournament.getEndTime() * 1000) < new Date();
        callback(cleverapps.CODE_SUCCEED, expired ? undefined : String(tournament.getID()));
    }).catch(function () {
        callback(cleverapps.CODE_FAILED);
    });
};

InstantLogic.prototype.loadOldUserId = function (callback) {
    var canvasAppId = cleverapps.config.facebook && cleverapps.config.facebook.appId;

    if (canvasAppId) {
        FBInstant.graphApi.requestAsync("/me/ids_for_business")
            .then(function (response) {
                var canvasUser = response.data.find(function (user) {
                    return user.app && user.app.id === canvasAppId;
                });

                callback(canvasUser && canvasUser.id);
            })
            .catch(function (error) {
                console.log("my ids error", error);
            });
    }
};

InstantLogic.prototype.onConnected = function () {
    console.log("Request entrypoint");
    FBInstant.getEntryPointAsync().then(function (entrypoint) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.ENTRY_POINT + (entrypoint || "empty"));

        if (cleverapps.config.debugMode) {
            console.log("Instant entrypoint: " + entrypoint);
            setTimeout(function () {
                cleverapps.notification.create("Instant entrypoint: " + entrypoint);
            }, 2000);
        }
    });

    FBInstant.player.getASIDAsync().then(function (asid) {
        console.log("Instant ASID: " + asid);

        if (asid) {
            cleverapps.info.setKeyValue("asid", asid, true);
        }
    });

    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.LOCALE + (FBInstant.getLocale() || "empty"));
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.PLATFORM + (FBInstant.getPlatform() || "empty"));

    var entryPointData = FBInstant.getEntryPointData() || {};
    if (entryPointData.push_code) {
        cleverapps.localPushes.logClickEvent(entryPointData.push_code);
    }
};

InstantLogic.prototype.subscribeBot = function () {
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.BOT_WANT);
    FBInstant.player.canSubscribeBotAsync().then(function (canSubscribe) {
        if (canSubscribe) {
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.BOT_SHOW);

            FBInstant.player.subscribeBotAsync().then(function () {
                cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.BOT);
            }).catch(function (e) {
                // e.message === "Player closed the subscribe bot async dialog"
                console.log(e.code + " " + e.message);
            });
        }
    }).catch(function (e) {
        console.log("SubsBot " + e.code + " " + e.message);
    });
};
