/**
 * Created by slava on 01.08.17.
 */

var CoefView = cc.Node.extend({
    ctor: function (coef) {
        this._super();

        this.number = new cc.Sprite(bundles.coef.frames["coef_" + (coef || 1)]);
        this.addChild(this.number);

        this.setContentSize(this.number.getContentSize());
        this.number.setPositionRound(this.width / 2, this.height / 2);

        this.reuse(coef);
        this.setCascadeOpacityEnabled(true);
    },

    createAnimation: function () {
        this.finishAnimation();

        var animation = this.coefAnimation = CoefAnimation.factory();
        if (animation) {
            animation.setPosition(this.width / 2, this.height / 2);
            this.addChild(animation);
        }
        return animation;
    },

    finishAnimation: function () {
        if (this.coefAnimation) {
            this.coefAnimation.finishAnimation();
            delete this.coefAnimation;
        }
    },

    hide: function () {
        this.number.visible = false;

        var animation = this.createAnimation();
        if (animation) {
            animation.runAnimate("coef_off", this.finishAnimation.bind(this));
        }
    },

    popUp: function (duration) {
        if (this.popUpAnimation) {
            return;
        }
        this.popUpAnimation = true;

        var animation = this.createAnimation();

        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.3),
            new cc.CallFunc(function () {
                cleverapps.audio.playSound(bundles.coef.urls.inc_coef_effect);
            })
        ));

        var popUpAnimationCallback = function () {
            var goalCoefComponent = this.parent.component;
            if (goalCoefComponent && goalCoefComponent.coef) {
                this.updateImage(goalCoefComponent.coef);
            } else {
                this.number.visible = false;
            }
            this.popUpAnimation = false;
        }.bind(this);

        if (!animation || !animation.animation) {
            this.number.runAction(new cc.Sequence(
                new cc.DelayTime(duration),
                new cc.CallFunc(popUpAnimationCallback)
            ));
            return;
        }

        animation.runAnimate("coef_on", function () {
            popUpAnimationCallback();
            this.finishAnimation();
        }.bind(this));
    },

    updateImage: function (coef) {
        this.number.visible = true;
        this.number.setSpriteFrame(bundles.coef.frames["coef_" + coef]);
    },

    remove: function () {
        cc.pool.putInPool(this);
    },

    reuse: function (coef) {
        if (coef === 0) {
            this.number.visible = false;
        } else {
            this.updateImage(coef);
            this.number.visible = true;
        }

        this.popUpAnimation = false;
    },

    unuse: function () {
        this.finishAnimation();
        this.removeFromParent(false);
    }
});

CoefView.factory = function (coef) {
    // if (cc.pool.hasObject(CoefView)) {
    //     console.log("FROM POOL CoefView");
    // }
    if (coef === undefined) {
        coef = 0;
    }
    return cc.pool.hasObject(CoefView) ? cc.pool.getFromPool(CoefView, coef) : new CoefView(coef);
};