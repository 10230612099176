/**
 * Created by olga on 04.07.2023
 */

var ToolConfirmWindow = CleverappsWindow.extend({
    onWindowLoaded: function (onConfirm, message) {
        this.onConfirm = onConfirm;
        this.message = message;
        this._super({
            content: cleverapps.UI.generateOnlyText(this.message, cleverapps.styles.FONTS.WINDOW_TEXT),
            closeButton: true,
            button: {
                text: "OK",
                onPressed: function () {
                    this.onConfirm();
                    this.close();
                    cleverapps.notification.create(this.message);
                }.bind(this)
            }
        });
    }
});