/**
 * Created by vlad on 10.02.18.
 */

var PromptWindow = CleverappsWindow.extend({
    onWindowLoaded: function (options) {
        var content = this.getContent(options);

        this._super({
            name: "promptwindow",
            content: content,
            closeButton: true,
            button: {
                width: 150,
                text: "OK",
                onPressed: function () {
                    var level = this.editBox.getString();
                    options.callback(level);
                    this.close();
                }.bind(this)
            }
        });
    },
    getContent: function (options) {
        var editBoxBg = new cc.Scale9Sprite(bundles.windows.frames.editbox_bg_png);
        var editBox = this.editBox = new cc.EditBox(cleverapps.styles.PromptWindow.editBox, editBoxBg, true);

        editBox.setDelegate(this);
        editBox.setMaxLength(100);
        editBox.stayOnTop(true);
        editBox.setTouchEnabled();
        editBox.setFontColor(new cc.Color(255, 255, 255, 255));
        editBox.setFont(cleverapps.UI.getFontName(), 60);

        if (options.defaultVal !== undefined) {
            editBox.setString(options.defaultVal);
        }

        var label = cleverapps.UI.generateOnlyText(options.text || "", cleverapps.styles.FONTS.WINDOW_TEXT);

        return new cleverapps.Layout([label, editBox], {
            direction: cleverapps.UI.VERTICAL,
            margin: 50 * resolutionScale,
            padding: cc.multPadding(cc.padding(100, 150), resolutionScale)
        });
    }
});

cleverapps.styles.PromptWindow = {
    editBox: {
        width: 550,
        height: 80
    }
};