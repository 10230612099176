/**
 * Created by Denis Kuzin on 14 june 2024
 */

var ColorComponentView = cc.Node.extend({
    ctor: function (component, cellView) {
        this._super();

        this.component = component;
        this.cellView = cellView;

        this.setContentSize(cellView.getContentSize());
        this.setLocalZOrder(-1);

        var image = this.image = new cc.Sprite(this.getImage(this.component.cell));
        image.setPosition(this.width / 2, this.height / 2);
        this.addChild(image);
    },

    getImage: function (colorCell) {
        if (cleverapps.environment.isEditorScene() && this.cellView.getImage) {
            return this.cellView.getImage(colorCell);
        }

        var cellBundle = colorCell.bundleId() || "color_cells";

        if (colorCell.findComponent(ColorComponent).color === undefined) {
            return bundles[cellBundle].frames[cellBundle + "_goal"];
        }

        var color = colorCell.findComponent(ColorComponent).color;

        if (this.cellView instanceof GoalColorCellView && cleverapps.config.name !== "adventure") {
            color = color.toUpperCase();
        }

        return bundles[cellBundle].frames[cellBundle + "_" + color];
    },

    updateImage: function (cell) {
        this.image.setSpriteFrame(this.getImage(cell));
    },

    hide: function () {
        this.image.setVisible(false);
    },

    show: function () {
        this.image.setVisible(true);
    }
});
