/**
 * Created by Denis Kuzin on 05 october 2023
 */

var CoinCellView = BaseCellView.extend({
    ctor: function (coinCell) {
        this._super();

        this.setContentSize(cleverapps.styles.BaseCellView.CELL_SIZE_PX, cleverapps.styles.BaseCellView.CELL_SIZE_PX);
        this.setAnchorPoint(0.5, 0.5);

        var bundle = coinCell.bundleId();
        this.animation = new cleverapps.Spine(bundles[bundle].jsons.coin_json);
        this.animation.setPosition(this.width / 2, this.height / 2);
        this.addChild(this.animation);

        this.initialize(coinCell);
    },

    initialize: function (coinCell) {
        this._super(coinCell);

        this.animation.setAnimation(0, "idle", true);
    },

    unuse: function () {
        this.cell.onChangeLivesListener = function () {};

        this._super();
    },

    animateExplodingViaCollect: function (goal) {
        this.runAction(new cc.Sequence(
            new cc.DelayTime(Goals.UP_DURATION + Goals.WAIT_DURATION),
            new cc.CallFunc(function () {
                var duration = cc.CollectAnimation.calcDuration({ targetNode: this, to: goal.getView() });
                this.animation.setAnimation(0, "animation", false);
                var timeScale = this.animation.getTimeLeft() / duration;
                this.animation.setTimeScale(timeScale);
            }.bind(this))
        ));

        BaseCellView.prototype.startExplodingViaCollect.apply(this, arguments);
    },

    startExploding: function () {
        if (Game.currentGame.goals.hasType(this.cell.getGoalId())) {
            this.animateExplodingViaCollect.apply(this, arguments);
        } else {
            this._super.apply(this, arguments);
        }
    }
});