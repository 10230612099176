/**
 * Created by ivan on 11.01.18.
 */

var GingerHousePartCellView = BaseCellView.extend({
    ctor: function (gingerHousePartCell) {
        this._super();

        var styles = cleverapps.styles.GingerHousePartCellView;

        this.setContentSize2(styles.width, styles.height);
        this.setAnchorPoint(0.5, 0.5);

        this.image = new cc.Sprite(this.getImage(gingerHousePartCell));

        this.addChild(this.image);
        this.image.setPosition(this.width / 2, this.height / 2);

        this.initialize(gingerHousePartCell);
        this.setPosition(BaseCellView.alignInTheGrid(gingerHousePartCell.x, gingerHousePartCell.y));
    },

    getImage: function (gingerHousePartCell) {
        switch (gingerHousePartCell.type) {
            case GingerHousePartCell.TYPES.ROOF:
                return bundles.gingerbread_house.frames.ginger_house_part_roof_png;
            case GingerHousePartCell.TYPES.DOOR:
                return bundles.gingerbread_house.frames.ginger_house_part_door_png;
            case GingerHousePartCell.TYPES.WINDOW:
                return bundles.gingerbread_house.frames.ginger_house_part_window_png;
            case GingerHousePartCell.TYPES.WALL:
                return bundles.gingerbread_house.frames.ginger_house_part_wall_png;
        }
    },

    initialize: function (gingerHousePartCell) {
        this._super(gingerHousePartCell);

        this.image.setSpriteFrame(this.getImage(gingerHousePartCell));

        gingerHousePartCell.view = this;
        gingerHousePartCell.onBeforeExplodeListener = this.startExploding.bind(this);
    },

    unuse: function () {
        this.cell.view = undefined;
        this.cell.onBeforeExplodeListener = function () {};

        this._super();
    },

    startExploding: function () {
    }
});

cleverapps.styles.GingerHousePartCellView = {
    width: 120,
    height: 120
};