/**
 * Created by Denis Kuzin on 19 may 2021
 */

var AdsShadowLayer = cc.Node.extend({
    ctor: function (properties) {
        this._super();

        this.properties = properties;

        var size = cleverapps.resolution.getSceneSize();
        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize2(size);

        var shadow = this.shadow = new cc.LayerColor(new cc.Color(0, 0, 0, 255), size.width, size.height);
        shadow.setOpacity(properties.reverse ? 255 : 0);
        this.addChild(shadow);

        this.show();
    },

    hide: function () {
        this.setVisible(false);
    },

    show: function () {
        this.setVisible(true);

        this.shadow.setOpacity(this.properties.reverse ? 255 : 0);

        this.shadow.stopAllActions();

        this.shadow.runAction(new cc.FadeTo(this.properties.riseTime / 1000, this.properties.opacity));
    }
});

AdsShadowLayer.PROPERTIES = [{
    name: "riseTime", type: "number", value: 300
}, {
    name: "opacity", type: "number", value: 110
}, {
    name: "reverse", type: "checkBox"
}];