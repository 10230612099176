/**
 * Created by Denis Kuzin on 23 may 2024
 */

var CellsComponentsFactory = function (cell, options) {
    options = options || {};
    var components = [];

    if (options.coef || options.components && options.components.indexOf(GoalCoefComponent) !== -1) {
        components.push(new GoalCoefComponent(cell));
    }

    if (options.color !== undefined || options.components && options.components.indexOf(ColorComponent) !== -1) {
        cell.movable = true;
        cell.putInPoolAvailable = true;

        components.push(new ColorComponent(cell, options.color));
    }

    if (options.components && options.components.indexOf(SpecialColorComponent) !== -1) {
        components.push(new SpecialColorComponent(cell));
    }

    if (options.components && options.components.indexOf(DecoratorComponent) !== -1) {
        components.push(new DecoratorComponent(cell));
    }

    if (options.components && options.components.indexOf(BigComponent) !== -1) {
        cell.movable = false;
        cell.hurtable = true;

        components.push(new BigComponent(cell, options.cellClass, options.rows, options.cols));
    }

    if (options.components && options.components.indexOf(EaterComponent) !== -1) {
        components.push(new EaterComponent(cell, options.cellClass, options.rows, options.cols));

        cell.hurtable = false;
    }

    if (options.components && options.components.indexOf(LiveLineComponent) !== -1) {
        components.push(new LiveLineComponent(cell));
    }

    if (options.components && options.components.indexOf(MuffinComponent) !== -1) {
        components.push(new MuffinComponent(cell));
    }

    if (options.components && options.components.indexOf(SpecialShapeComponent) !== -1) {
        cell.movable = false;
        cell.hurtable = false;

        components.push(new SpecialShapeComponent(cell, options.cellClass));
    }

    if (options.components && options.components.indexOf(MarkComponent) !== -1) {
        components.push(new MarkComponent(cell));
    }

    if (options.components && options.components.indexOf(ComboComponent) !== -1) {
        components.push(new ComboComponent(cell));
    }

    return components;
};