/**
 * Created by r4zi4l on 13.05.2021
 */

var PassRewardIcon = cc.Node.extend({
    ctor: function (options) {
        this._super();
        this.options = options;
        this.passLogic = options.passLogic;
        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize2(cleverapps.styles.PassRewardIcon.icon);

        var rewardData = options.premium ? this.passLogic.levels[options.level].premiumReward : this.passLogic.levels[options.level].reward;
        this.rewardList = new RewardsList(rewardData, { toMainWorld: this.passLogic.mission.mainWorldReward });

        var reward = this.rewardList.listRewards()[0];
        if (reward.type === "units") {
            this.toolTipMsg = cleverapps.unitsLibrary.getUnitName(reward.getValue(), true);
        }
        this.createContent(reward);
    },

    createContent: function (reward) {
        var styles = cleverapps.styles.PassRewardIcon;

        var icon = this.icon = reward.getIcon();
        icon.setAnchorPoint(0.5, 0.5);
        cleverapps.UI.fitToBox(icon, {
            width: styles.icon.width,
            height: styles.icon.height,
            maxScale: this.options.premium ? styles.icon.prem.scale : styles.icon.free.scale
        });
        icon.setPositionRound(styles.icon);
        this.addChild(icon);

        var msg = reward.getText("x");
        var font = this.options.font || cleverapps.styles.FONTS.PASS_REWARD_TEXT;
        if (cleverapps.UI.ImageFont.IsApplicable(font, msg)) {
            this.text = cleverapps.UI.generateImageText(msg, font);
        } else {
            this.text = cleverapps.UI.generateOnlyText(msg, font);
        }
        if (this.options.amountBg) {
            var amountBgSprite = new cc.Sprite(this.options.amountBg);
            amountBgSprite.setPositionRound(styles.text);
            this.addChild(amountBgSprite);
            cleverapps.UI.fitToBox(this.text, amountBgSprite);
            this.text.setPositionRound(amountBgSprite.width / 2, amountBgSprite.height / 2);
            amountBgSprite.addChild(this.text);
        } else {
            this.text.setPositionRound(styles.text);
            this.addChild(this.text);
        }
    },

    setDiscolor: function (toggle) {
        if (toggle) {
            this.icon.setOpacity(120);
            this.icon.setColor(new cc.Color(0, 150, 255, 255));
            this.text.setOpacity(120);
            this.text.setScale(0.8);
        } else {
            this.icon.setOpacity(255);
            this.icon.setColor(new cc.Color(255, 255, 255, 255));
            this.text.setScale(1);
            this.text.setOpacity(255);
        }
    },

    setAttention: function (image) {
        var styles = cleverapps.styles.PassRewardIcon.attention;

        if (this.attention) {
            this.attention.removeFromParent();
            delete this.attention;
        }

        if (image) {
            var attention = this.attention = new cc.Sprite(image);
            attention.setPositionRound(styles);
            this.addChild(attention);
        }
    },

    setShining: function (toggle) {
        var styles = cleverapps.styles.PassRewardIcon.shining;

        if (this.shining) {
            this.shining.removeFromParent();
            delete this.shining;
        }

        if (toggle) {
            this.shining = AnimationsLibrary.rays(this, { skin: "white", size: styles.size });
            this.shining.setPositionRound(styles);
        }
    },

    setFlying: function (toggle) {
        var styles = cleverapps.styles.PassRewardIcon.flying;

        if (this.flyingAction) {
            this.stopAction(this.flyingAction);
            delete this.flyingAction;
        }

        if (toggle) {
            this.flyingAction = this.runAction(new cc.Sequence(
                new cc.DelayTime(Math.random() * 1.5),
                new cc.CallFunc(function () {
                    this.flyingAction = this.runAction(new cc.RepeatForever(new cc.Sequence(
                        new cc.CallFunc(function () {
                            this.icon.runAction(new cc.Sequence(
                                new cc.MoveBy(3, 0, styles.offsetY),
                                new cc.CallFunc(function () {
                                    if (Math.random() < 0.7 / this.passLogic.countAvailableRewards()) {
                                        this.icon.runAction(new cc.Sequence(
                                            new cc.RotateBy(0.25, 10),
                                            new cc.RotateBy(0.25, -10),
                                            new cc.RotateBy(0.25, 10),
                                            new cc.RotateBy(0.25, -10)
                                        ));
                                    }
                                }, this),
                                new cc.MoveBy(1.5, 0, -styles.offsetY)
                            ));
                        }, this),
                        new cc.DelayTime(4.5)
                    )));
                }, this)
            ));
        }
    },

    setClaimable: function (toggle, disable) {
        this.setClaimButton(toggle, disable);

        if (toggle && !disable) {
            cleverapps.UI.onClick(this, this.claimReward.bind(this));
        }
    },

    setClaimButton: function (toggle, disable) {
        if (this.claimButton) {
            this.claimButton.removeFromParent();
            this.claimButton = undefined;
        }

        if (toggle) {
            var styles = cleverapps.styles.PassRewardIcon.claimButton;

            var claimButton = this.claimButton = new cleverapps.UI.Button({
                type: cleverapps.styles.UI.Button.Images.small_button_green,
                width: styles.width,
                height: styles.height,
                text: "Claim",
                onClicked: this.claimReward.bind(this)
            });
            claimButton.setAnchorPoint(0.5, 0.5);
            claimButton.setPositionRound(styles);
            this.addChild(claimButton);

            if (disable) {
                claimButton.disable();
            }
        }
    },

    updateState: function (level) {
        var shownLevel = level !== undefined ? level : this.passLogic.shownProgress.level;
        var state;

        if (this.options.level < shownLevel) {
            if (this.passLogic.hasReward(this.options.level, this.options.premium)) {
                if (this.options.premium && !this.passLogic.hasPremium()) {
                    state = "premium_required";
                } else {
                    state = "fresh";
                }
            } else {
                state = "gained";
            }
        }

        cc.eventManager.removeListeners(this);

        this.setDiscolor(false);
        this.setAttention();
        this.setShining(false);
        this.setFlying(false);
        this.setClaimable(false);

        switch (state) {
            case "premium_required":
                this.setClaimable(true, false);
                break;

            case "fresh":
                this.setShining(true);
                this.setFlying(true);
                this.setClaimable(true, false);
                break;

            case "gained":
                this.setAttention(bundles.reward_icons_pass.frames.prize_checkmark_png);
                break;

            default:
                this.setDiscolor(true);
                cleverapps.tooltipManager.create(this, {
                    text: "PassWindow.moreItemsToolTip." + this.passLogic.mission.collectItemName
                });
        }
    },

    openAnimation: function () {
        this.runAction(new cc.Sequence(
            new cc.ScaleTo(0.2, 1.2),
            new cc.ScaleTo(0.2, 1)
        ));

        if (this.claimButton) {
            this.claimButton.setVisible(false);
            this.claimButton.runAction(new cc.Sequence(
                new cc.DelayTime(0.4),
                new cc.Show()
            ));
        }
    },

    claimReward: function () {
        if (this.passLogic.shownProgress.level < this.options.level) {
            return;
        }

        if (this.options.premium && !this.passLogic.hasPremium()) {
            new PassBuyTicketWindow(this.passLogic);
            return;
        }

        var prize = this.passLogic.takeLevelReward(this.options.level, this.options.premium);
        if (!prize) {
            return;
        }

        var prizeImage = this.rewardList.listRewards()[0].getIcon();
        this.addChild(prizeImage);
        prizeImage.setPosition(this.icon.getPosition());
        prizeImage.setScale(this.icon.getScale());
        prizeImage.setAnchorPoint(this.icon.anchorX, this.icon.anchorY);

        this.rewardList.receiveRewards();
        var delay = Math.max(this.rewardList.collectRewardsAnimation(prizeImage) / 1000, 0.9);

        if (!prize.unit || this.passLogic.mission.mainWorldReward) {
            this.updateState();
            this.passLogic.onHideFinger();
            this.passLogic.onShowFinger();
        } else {
            var currentWindow = cleverapps.windows.currentWindow();
            currentWindow.hideSelf();
            currentWindow.runAction(new cc.Sequence(
                new cc.DelayTime(delay),
                new cc.CallFunc(function () {
                    if (this.passLogic.checkComplete()) {
                        currentWindow.close();
                    } else {
                        currentWindow.displaySelf();
                    }
                }.bind(this))
            ));
        }
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    PASS_REWARD_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    }
});

cleverapps.styles.PassRewardIcon = {
    icon: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 0 },
        width: 180,
        height: 180,
        free: { scale: 1.1 },
        prem: { scale: 1.2 }
    },

    text: {
        x: { align: "right", dx: 0 },
        y: { align: "bottom", dy: -2 },

        bgScale: 3
    },

    attention: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: -60 }
    },

    shining: {
        x: { align: "center", dx: -8 },
        y: { align: "center", dy: 0 },
        size: 100
    },

    flying: {
        offsetY: 8
    },

    claimButton: {
        width: 170,
        height: 60,
        x: { align: "center" },
        y: { align: "bottom", dy: -75 }
    }
};
