/**
 * Created by andrey on 21.05.2020
 */

var BoostersBeforeComponent = function (boosters, lanternStart) {
    var styles = cleverapps.styles.BoostersBeforeComponent;

    var font = cleverapps.styles.FONTS.WINDOW_BIG_TEXT || cleverapps.styles.FONTS.WINDOW_TEXT;
    if (cleverapps.config.features.includes("lantern_start")) {
        font = cleverapps.styles.FONTS.BOOSTERS_BEFORE_TEXT;
    }
    var title = cleverapps.UI.generateOnlyText(
        "BoostersBeforeComponent.Title",
        font
    );

    var views = boosters.list.map(function (booster) {
        return new BoosterBeforeView(booster, boosters.level);
    });

    var boostersViews = new cleverapps.Layout(views, {
        padding: styles.boostersPadding,
        margin: lanternStart ? styles.lantern.boostersMargin : styles.boostersMargin,
        direction: cleverapps.UI.HORIZONTAL
    });

    if (cleverapps.config.features.includes("lantern_start")) {
        boostersViews.runAction(new cc.Sequence(
            new cc.DelayTime(0.2),
            new cc.PlaySound(bundles.boosters_before.urls.appear_sfx)
        ));
    }
    views.forEach(function (view, index) {
        if (cleverapps.config.features.includes("lantern_start")) {
            view.showUp(0.2, true);
        } else {
            view.showUp(0.2 + index * 0.2);
        }
    });

    var content = new cleverapps.Layout([title, boostersViews], {
        margin: lanternStart ? styles.lantern.contentMargin : styles.contentMargin,
        direction: cleverapps.UI.VERTICAL
    });

    var hasLantern = boosters.list.some(function (booster) {
        return booster.isLantern();
    });

    var lantern = Lantern.Get();

    if (hasLantern && lantern && !lanternStart) {
        var view = new LanternView(lantern, content);
        content.runAction(new cc.Sequence(
            new cc.DelayTime(0.3),
            new cc.CallFunc(function () {
                view.show();
            }),
            new cc.DelayTime(0.2),
            new cc.CallFunc(function () {
                view.replaceParentSamePlace(cleverapps.windows.currentWindow());
                view.setLocalZOrder(20);
            }),
            new cc.DelayTime(0.6),
            new cc.CallFunc(function () {
                view.hide();
            })
        ));
    }

    return content;
};

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    BOOSTERS_BEFORE_TEXT: {
        size: 40,
        color: new cc.Color(220, 110, 70, 255)
    }
});

cleverapps.styles.BoostersBeforeComponent = {
    boostersPadding: {
        bottom: 20
    },
    contentMargin: 50,
    lantern: {
        boostersMargin: 25,
        contentMargin: 30
    },
    boostersMargin: 25
};