/**
 * Created by vladislav on 2/19/2024
 */

cleverapps.config.testers = {
    draugiem: {
        ids: [
            "5531190", // Romanov Viacheslav
            "5531196", // Spepa
            "5533303", // Andrey Kargapolov
            "5536860", // Bogdan
            "5720568" // Vladislav Rakovskii
        ]
    },
    fotostrana: {
        ids: [
            "FS_113127743", // CleverApps
            "FS_113139202", // Anna Diduk
            "FS_113154744" // Andrey Kargapolov
        ]
    },
    microsoft: {
        deviceIds: [
            "MS_d1005317-92e5-4a83-11a9-d927877edd08", // spepa
            "MS_af64328e-3371-73c1-367c-92bb6d560ed5",
            "MS_3c940659-7a68-99e0-0ef2-0e4369a5372f",
            "MS_bafafe21-499a-26aa-44dc-f74662bff405",
            "MS_9c82c0be-a17d-a981-11b7-4a85ec0661a4", // Dmitriy
            "MS_089e6329-8b28-c5e4-7b60-1f56b5b4b126",
            "MS_34390e45-0500-febc-5829-27c92a3481b5", // Anna Keda
            "MS_428f0900-1986-514e-b40e-3c8b70235374", // Alexandrina Read
            "MS_459d1c26-a293-f56b-b56c-84f410e3830b", // Bogdan
            "MS_3ff4887f-2eaa-81d3-e99e-d6495ee64e85", // Artem Vinokurov
            "MS_13c53147-883c-b6e0-6c56-2aae159ed6d1", // Sergei Bogdanov,
            "MS_c8d2d30b-2a32-573b-219f-eaa7cbbd6f35", // Petr Isakov
            "MS_3a4afd8a-dd2d-0825-80cd-f934c2532139", // Vlad
            "MS_23c2dc13-9079-0550-596d-4eed01b6b286" // Vlad PC
        ]
    },
    mygames: {
        ids: [
            "223914816",
            "224977482",
            // "224974534",
            "224977211",
            "224977565"
        ]
    },
    mm: {
        ids: [
            "12485194034381634037",
            "14225752454745236203"
        ]
    },
    mbga: {
        ids: [
            "mbga.jp:155776208", // spepa
            "mbga.jp:157478302" // Andrey Kargapolov
        ]
    },
    sp_mbga: {
        ids: [
            "mbga.jp:155776208", // spepa
            "mbga.jp:157478302" // Andrey Kargapolov
        ]
    },
    msstart: {
        ids: [
            "e913873c-3848-4155-a881-edb315e30692", // andrey k tripeaks,
            "6e83dcbf-29fd-40f9-abed-83cf80d6194a", // andrey k mergecraft
            "54de395d-d6e0-4f83-8ed9-7b68e4de19e0", // andrey k riddles
            "c869c9f1-1729-4247-882e-fc1e0c37fbc6", // andrey k mergecraft staging
            "5fb5e261-5781-4730-ac0e-b5984c8e89d3", // alexandrina mergecraft staging
            "ddaa100f-c0e3-4159-ad84-5f24bd0859b1" // vlad staging
        ]
    },
    android: {
        deviceIds: [
            "45f0caa5-d1ab-4b03-8e09-a0b63b17c181", // andrey k m31s
            "839d10f8-0da0-40fa-a4e9-02885ff0973a", // andrey k s21
            "17ef0ac8-71fa-47d1-941b-70924e8a031e", // anna
            "217ac5d3-6b67-48e8-8fe8-0fff1108c808", // alex_read
            "75f21832-7a24-47cc-9e88-8c4f461fc06d", // dmitriy
            "aca92164-3f00-4115-84c3-2ab54ed7ba7b", // samsung tab a android 9
            "1572cd26-1b38-4b85-8346-258e171b388c", // spepa
            "b1ae8117-ff55-4159-b9ad-18e4d05a9da8", // michail
            "cb3ed72f-c604-4c51-84fa-2ffaa9acb849", // vlad
            "85c137ac-0a55-45cd-bd8a-b8be552beea8", // bogdan
            "d2d82613-36c4-4d5c-aef5-43e62e938a48", // artem
            "f3cac7c8-962e-4ea2-9d08-2ec8a3950f58", // artem 2
            "2105ba80-4607-4ae3-a3a8-866aa7fb63b9", // denis
            "24280e2d-6603-415e-a064-d794cbda6437", // anatoly
            "c07beae6-3277-4c72-83ec-26cba72eeb36", // denis kuzin
            "9107a111-7da9-4f18-903b-fd7a19d1de0e", // ivan m
            "57ef186f-eb6c-470a-a8ba-d9575c623c26", // denis alexandrov
            "d2f4787d-fd18-4414-abec-d01f9a1c72c9", // vlad
            "b1b7e593-9557-451f-b8d4-05888173de9c" // sergei bogdanov
        ]
    },
    vk: {
        ids: [
            "8593874", // Vitaly
            "519644122", // Petr
            "559432346", // Clever Apps
            "120601236", // Michael Timkin
            "13506358", // Nail
            "662073098", // Andrey Popov
            "4252816", // Andrey Kargapolov
            "575473277", // Denis Kuzin
            "512342080", // Stepa
            "201054870", // Vlad
            "9938687", // Vsevolod
            "241941018", // Evgenia Morozova
            "16681526", // Olga Eremina
            "9933151", // Bogdan
            "122220961", // Eugenia Galkina
            "99189882", // Sergei Bogdanov
            "451315686", // Natalia Sadovaya
            "244780021", // Evgeny Senckewitch
            "62287676", // Stepan Puchik
            "858300556", // Denis Alexandrov
            "493261263", // Anatoly Mezenov
            "870476180", // Arseniy Morozov
            "394324092", // Reda Abou-Hamdi
            "3284030" // Dmitry Eremin
        ]
    },

    samsung: {
        ids: [
            "sI+Uq1MmA2LUsgKD9grmbg3TMi1Pe0mqNdDPc1oSTX4=" // scramble Andrey Kargapolov
        ]
    }
};

["rustore"].forEach(function (source) {
    cleverapps.config.testers[source] = cleverapps.config.testers.android;
});