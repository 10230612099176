/**
 * Created by Ivan on 29.12.2023
 */

var HorizontalBlockerNode = cc.Node.extend({
    ctor: function (node) {
        this._super();

        this.node = node;

        var alignment = cleverapps.clone(this.node.alignment, true);
        cleverapps.toArray(alignment).forEach(function (position) {
            position.x = cleverapps.styles.HorizontalBlockerNode.x;
        });

        this.updateSize();
        this.setPositionRound(alignment);
    },

    updateSize: function () {
        var scene = cleverapps.resolution.getSceneSize();
        this.setContentSize(scene.width, this.node.height);
    }
});

cleverapps.styles.HorizontalBlockerNode = {
    x: { align: "left" }
};