/**
 * https://github.com/InteractiveAdvertisingBureau/GDPR-Transparency-and-Consent-Framework/blob/master/TCFv2/IAB%20Tech%20Lab%20-%20CMP%20API%20v2.md#using-postmessage
 * https://developers.google.com/funding-choices/fc-api-docs
 *
 * Created by andrey on 29.02.2024
 */

var ConsentTcfApi = {
    callbacks: {},
    cmpFrame: undefined,
    queue: [],

    init: function () {
        var isApplicable = cleverapps.isIFrame() && connector.platform.oneOf(connector.CLEVERAPPS) && !window.__tcfapi;

        if (!isApplicable) {
            return;
        }

        window.__tcfapi = this.__tcfapi.bind(this);
        window.addEventListener("message", this.postMessageHandler.bind(this), false);

        this.connect();
    },

    __tcfapi: function (command, version, callback, parameter) {
        cleverapps.config.debugMode && console.log("tcfapi call", command, version, parameter);

        // debug on release
        // __tcfapi("addEventListener", 2, function (returnValue, success) { console.log("tcfevent", returnValue, success); });

        this.cmpFrame = this.cmpFrame || this.getCmpFrame();

        if (!this.cmpFrame) {
            this.queue.push({
                command: command, version: version, callback: callback, parameter: parameter 
            });

            if (this.queue.length > ConsentTcfApi.QUEUE_LIMIT) {
                var first = this.queue.shift();
                first.callback({ msg: "CMP not found" }, false);
            }

            return;
        }

        var callId = command + Math.floor(Math.random() * 1000000000000000);
        var msg = {
            __tcfapiCall: {
                command: command,
                parameter: parameter,
                version: version,
                callId: callId
            }
        };

        this.callbacks[callId] = callback;
        this.cmpFrame.postMessage(msg, "*");
    },

    postMessageHandler: function (event) {
        var json = {};

        try {
            json = typeof event.data === "string" ? JSON.parse(event.data) : event.data;
            // eslint-disable-next-line no-empty
        } catch (ignore) {}

        var payload = json.__tcfapiReturn;
        var callback = payload && this.callbacks[payload.callId];

        cleverapps.config.debugMode && payload && console.log("tcfapi event data", payload);

        if (callback) {
            // delete this.callbacks[payload.callId];
            callback(payload.returnValue, payload.success);
        }
    },

    connect: function (attempt) {
        this.cmpFrame = this.cmpFrame || this.getCmpFrame();

        if (!this.cmpFrame) {
            attempt = attempt || 0;
            cleverapps.timeouts.setTimeout(
                this.connect.bind(this, attempt + 1),
                Math.min(Math.pow(2, attempt), cleverapps.parseInterval("1 minute"))
            );
            return;
        }

        var queue = this.queue;
        this.queue = [];

        for (var i = 0; i < queue.length; i++) {
            this.__tcfapi(queue[i].command, queue[i].version, queue[i].callback, queue[i].parameter);
        }
    },

    getCmpFrame: function () {
        var self = window;

        while (self) {
            try {
                // throws a reference error if no frames exist or no __tcfapiLocator
                if (self.frames.__tcfapiLocator) {
                    return self;
                }
                // eslint-disable-next-line no-empty
            } catch (ignore) {
            }

            if (self === window.top) {
                break;
            }

            self = self.parent;
        }
    }
};

ConsentTcfApi.QUEUE_LIMIT = 20;