/**
 * Created by andrey on 22.08.17.
 */

var AddBombMovesWindow = CleverappsWindow.extend({
    onWindowLoaded: function (price, bombColor, onSuccess, onCancel) {
        this.onCancel = onCancel;
        this.price = price;
        this.onSuccess = onSuccess;
        this.priceTxt = price === 0 ? "FREE" : price;
        this.bombColor = bombColor;

        var styles = cleverapps.styles.AddBombMovesWindow;

        var animation = this.createAnimation();

        var text = this.createText();

        var breaks = this.price ? new BreakViews() : new cc.Node();

        this.content = new cleverapps.Layout([animation, breaks, text], {
            margin: styles.margin,
            direction: cleverapps.UI.VERTICAL
        });

        this._super({
            title: Messages.get("AddBombMovesWindow.title"),
            name: "addbombmoveswindow",
            content: this.content,
            button: this.getButton()
        });

        cleverapps.audio.playSound(bundles.prolongation_window.urls.continue_effect);
    },

    createText: function () {
        var styles = cleverapps.styles.AddBombMovesWindow;

        var text = this.text = cleverapps.UI.generateOnlyText("AddBombMovesWindow.addMoves", cleverapps.styles.FONTS.WINDOW_TEXT, {
            moves: AddBombMovesWindow.MOVES_TO_ADD
        });
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        text.setDimensions(styles.text.width, 0);

        return text;
    },

    createAnimation: function () {
        var styles = cleverapps.styles.AddBombMovesWindow;

        var node = new cc.Node();
        node.setAnchorPoint(0.5, 0.5);
        node.setContentSize(styles.animation);

        var bombIconAnimation = this.bombIconAnimation = new cleverapps.Spine(bundles.bomb.jsons.bomb_spine_json);
        bombIconAnimation.setScale(1.5);
        node.addChild(bombIconAnimation);
        bombIconAnimation.setPositionRound(styles.animation);
        bombIconAnimation.setSkin("skin_" + this.bombColor);
        bombIconAnimation.setAnimation(0, "animation", true);

        var sadIconAnimation = this.sadIconAnimation = new cleverapps.Spine(bundles.bomb.jsons.bomb_window_json);
        sadIconAnimation.setScale(1.5);
        node.addChild(sadIconAnimation);
        sadIconAnimation.setPositionRound(styles.animation);
        sadIconAnimation.setAnimation(0, "windowsbomb", true);

        this.timerTxt = cleverapps.UI.generateImageText("0", cleverapps.styles.FONTS.BOMB_TIMER_DANGER_TEXT);
        node.addChild(this.timerTxt);
        this.timerTxt.setPositionRound(styles.animation.text);

        return node;
    },

    takeMoves: function () {
        if (this.taken) {
            return;
        }

        if (!levels.user.spendHard(cleverapps.EVENTS.SPENT.BOMB_MOVES, this.price)) {
            return;
        }

        this.taken = true;

        cleverapps.audio.playSound(bundles.main.urls.shop_buy_effect);

        this.close();
        this.onSuccess();
    },

    onShow: function () {
        if (!Game.currentGame || Game.currentGame.outcome !== GameBase.OUTCOME_UNKNOWN) {
            this.close();
            return;
        }

        this._super();

        if (!this.price) {
            cleverapps.forces.create(this.buttons, Forces.FREE_BOMB_FORCE, {
                highlights: [this],
                importantNodes: [this.window]
            });
        } else {
            this.goldInfo = new ShopUserGoldView();
        }

        if (this.goldInfo) {
            this.goldInfo.showUp();
        }

        this.buttons.disabled = true;
        this.buttons.scale = 0;
        this.buttons.runAction(new cc.Sequence(
            new cc.DelayTime(0.4),
            new cc.ScaleTo(0.5, 1).easing(cc.easeBackOut()),
            new cc.CallFunc(function () {
                this.buttons.disabled = false;
            }.bind(this))
        ));
    },

    onHide: function () {
        this._super();

        if (this.buttons) {
            this.buttons.stopAllActions();
            this.buttons.setScale(1);
            this.buttons.disabled = false;
        }

        if (this.goldInfo) {
            this.goldInfo.hide(undefined, true);
        }
    },

    getButton: function () {
        var styles = cleverapps.styles.AddBombMovesWindow;

        return {
            text: "$$" + this.priceTxt,
            width: styles.button.width,
            height: styles.button.height,
            onPressed: function () {
                if (cleverapps.forces.isRunningForce()) {
                    cleverapps.forces.closeRunningForce();
                }

                this.takeMoves();
            }.bind(this)
        };
    },

    onClose: function () {
        if (this.hint) {
            this.hint.close();
        }
        if (!this.taken) {
            this.onCancel.call(this);
        }
    },

    beforeCloseAnimation: function (callback) {
        if (this.goldInfo) {
            this.goldInfo.remove(callback);
        } else {
            callback();
        }
    },

    listBundles: function () {
        return ["bomb_moves_window", "bomb"];
    }
});

AddBombMovesWindow.MOVES_TO_ADD = 5;

cleverapps.styles.AddBombMovesWindow = {
    margin: 30,

    animation: {
        width: 600,
        height: 250,

        x: { align: "center" },
        y: { align: "center" },

        text: {
            x: { align: "center" },
            y: { align: "center", dy: -35 }
        }
    },

    text: {
        width: 600
    },

    button: {
        width: 300,
        height: 100
    }
};
