/**
 * Created by vlad on 19.10.18.
 */

var MultiColorBombCell = function (x, y) {
    BaseCell.call(this, x, y);

    var bigComponent = this.bigComponent = this.addComponent(BigComponent, { cellClass: MultiColorBombCell });

    this.hurtable = true;

    if (!bigComponent.isHead) {
        return;
    }

    this.colors = ["a", "b", "c", "d", "e", "w"];
    this.lives = this.colors.length;
};

MultiColorBombCell.prototype = Object.create(BaseCell.prototype);
MultiColorBombCell.prototype.constructor = MultiColorBombCell;

MultiColorBombCell.prototype.bundleId = function () {
    return "petards";
};

MultiColorBombCell.prototype.getViewClass = function () {
    return MultiColorBombCellView;
};

MultiColorBombCell.prototype.boom = function (coef, cell) {
    this.bigComponent.head.hurt(undefined, cell);
};

MultiColorBombCell.prototype.hurt = function (explodeParams, cell, byBooster) {
    if (!cell) {
        cell = this;
        byBooster = true;
    }

    if (!this.bigComponent.isHead) {
        this.bigComponent.head.hurt(explodeParams, cell, byBooster);
        return;
    }

    if (this.lives > 0) {
        var color = !byBooster && cell.getColor();
        var callback = function (index) {
            this.lives--;
            this.colors[index] = this.colors[index].toUpperCase();
        }.bind(this);
        Game.currentGame.counter.setTimeout(function () {
            this.onChangeLivesListener(color, cell, callback);
            if (this.lives < 1) {
                this.bigComponent.head.explode();
            }
        }.bind(this), this.hurtDuration() * 1000);
    }
};

MultiColorBombCell.prototype.isSelf = function (x, y) {
    return x >= this.bigComponent.head.x && x <= this.bigComponent.head.x + 1 && y >= this.bigComponent.head.y && y <= this.bigComponent.head.y + 1;
};

MultiColorBombCell.prototype.processRugOnExplode = function () {
    if (!Game.currentGame) {
        return;
    }
    
    var rugGrowDirs = [[[1, 3], [0, 3]], [[1, 2], [0, 2]]];
    var rugCellsToHurt = [];
    var anyCellWithRug;

    // move on all petard cells  (dx - columns, dy - rows)
    for (var dx = 0; dx <= 1; dx++) {
        for (var dy = 0; dy <= 1; dy++) {
            var tile = Game.currentGame.field.floor[this.bigComponent.head.y + dy][this.bigComponent.head.x + dx];
            if (tile && tile.constructor === RugTile) {
                for (var dirs = 0; dirs < 2; dirs++) {
                    this.iterateLineOfFire(this.bigComponent.head.x + dx, this.bigComponent.head.y + dy, BaseCell.neighbors[rugGrowDirs[dx][dy][dirs]], function (cell) {
                        rugCellsToHurt.push(cell);
                    }, true);
                }
                anyCellWithRug = new BaseCell(this.bigComponent.head.x + dx, this.bigComponent.head.y + dy);
            }
        }
    }
    
    rugCellsToHurt.forEach(function (cell) {
        RugTile.hurtViaCombo(anyCellWithRug, cell);
    });
};

MultiColorBombCell.prototype.onHeadExplode = function () {
    Game.currentGame.counter.setTimeout(function () {
        var cellsToHurt = [];

        BaseCell.neighbors.forEach(function (dir) {
            this.iterateLineOfFire(this.bigComponent.head.x, this.bigComponent.head.y, dir, function (cell) {
                if (!this.isSelf(cell.x, cell.y)) {
                    cellsToHurt.push(cell);
                }
            }.bind(this));
        }.bind(this));

        BaseCell.neighbors.forEach(function (dir) {
            this.iterateLineOfFire(this.bigComponent.head.x + 1, this.bigComponent.head.y + 1, dir, function (cell) {
                if (!this.isSelf(cell.x, cell.y)) {
                    cellsToHurt.push(cell);
                }
            }.bind(this));
        }.bind(this));

        cellsToHurt.forEach(function (cell, index) {
            cell.hover(false);
            cell.hurt({ id: index, groupSize: cellsToHurt.length });
        });

        this.processRugOnExplode();
    }.bind(this), this.explodeDuration() * 1000);

    Game.currentGame.counter.setTimeout(function () {
        BaseCell.prototype._explode.call(this);
    }.bind(this), this.explodeDelay() * 1000);
};

MultiColorBombCell.prototype.explodeDuration = function () {
    return 0.7;
};

MultiColorBombCell.prototype.explodeDelay = function () {
    return 0.3;
};

MultiColorBombCell.prototype.hurtDuration = function () {
    return 0.2;
};

MultiColorBombCell.prototype.getMaxLives = function () {
    return 6;
};

MultiColorBombCell.prototype.editorComponents = function () {
    if (this.bigComponent.isHead) {
        return [BaseCellComponent, BigCellComponent, MultiColorBombCellComponentCreator];
    }
    return [BaseCellComponent];
};

MultiColorBombCell.prototype.load = function (data) {
    if (!this.bigComponent.isHead) {
        return;
    }
    this.colors = data.slice(1, data.length);
    this.lives = this.colors.filter(function (color) {
        return color === color.toLowerCase();
    }).length;
};

MultiColorBombCell.prototype.save = function () {
    if (this.bigComponent.isHead) {
        return MultiColorBombCell.CODES.concat(Object.values(this.colors));
    }
    return MultiColorBombCell.CODES;
};