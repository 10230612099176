/**
 * Created by Denis Kuzin on 05 june 2024
 */

var BigComponent = function (cell, cellClass, rows, cols) {
    this.cell = cell;

    this.cellClass = cellClass;

    this.isHead = true;
    this.head = this.cell;
    this.headExploded = false;

    this.rows = 2;
    if (rows && rows > 2) {
        this.rows = rows;
    }

    this.cols = 2;
    if (cols && cols > 2) {
        this.cols = cols;
    }

    if (this.cell.x >= 0) {
        this.checkHeadFlag();
    }
};

BigComponent.prototype.checkHeadFlag = function () {
    for (var dy = 0; dy < this.rows; dy++) {
        for (var dx = 0; dx < this.cols; dx++) {
            if (dx + dy === 0) {
                continue;
            }
            if (this.cell.y - dy >= 0
                && Game.currentGame.field.cells[this.cell.y - dy] && this.cell.x - dx >= 0
                && Game.currentGame.field.cells[this.cell.y - dy][this.cell.x - dx]
                && Game.currentGame.field.cells[this.cell.y - dy][this.cell.x - dx] instanceof this.cellClass
                && Game.currentGame.field.cells[this.cell.y - dy][this.cell.x - dx].findComponent(BigComponent).isHead) {
                this.isHead = false;
                this.head = Game.currentGame.field.cells[this.cell.y - dy][this.cell.x - dx];
            }
        }
    }
};

BigComponent.prototype.editorComponents = function () {
    if (this.isHead) {
        return [BaseCellComponent, BigCellComponent];
    }
};

BigComponent.prototype.load = function (data) {
    if (data.length > 1) {
        var digits = data.splice(1).join("");
        this.cell.lives = parseInt(digits);
    }
};

BigComponent.prototype.save = function () {
    if (this.isHead) {
        var digits = "" + this.cell.lives;
        return this.cellClass.CODES.concat(digits.split(""));
    }

    return this.cellClass.CODES;
};

BigComponent.prototype.animate = function (type, params) {
    if (!this.isHead) {
        var bigComponent = this.head.findComponent(BigComponent);
        if (bigComponent.isHead) {
            this.head.animate(type, params);
        }
        return;
    }

    return true;
};

BigComponent.prototype.getLives = function () {
    if (!this.isHead) {
        return this.head.getLives();
    }
    return this.cell.lives;
};

BigComponent.prototype.hurt = function () {
    if (!this.isHead) {
        this.head.hurt();
        return;
    }

    return true;
};

BigComponent.prototype.hover = function (state) {
    if (!this.isHead) {
        this.head.hover(state);
        return;
    }

    return true;
};

BigComponent.prototype.onHeadExplode = function () {
    if (this.cell.onHeadExplode) {
        this.cell.onHeadExplode();
        return;
    }

    this.cell.explodeViaCollect();
};

BigComponent.prototype.explode = function () {
    if (this.isHead) {
        if (this.headExploded || !Game.currentGame) {
            return;
        }
        this.headExploded = true;

        var cells = Game.currentGame.field.cells;
        for (var dy = 0; dy < this.rows; dy++) {
            for (var dx = 0; dx < this.cols; dx++) {
                if (dy + dx > 0) {
                    cells[this.cell.y + dy][this.cell.x + dx].explode();
                }
            }
        }

        this.onHeadExplode();

        return;
    }

    return true;
};