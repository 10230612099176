/**
 * Created by vlad on 13.08.18.
 */

var TruffleCellView = BaseCellView.extend({
    ctor: function (trufflecell) {
        this._super();

        this.setContentSize(cleverapps.styles.BaseCellView.CELL_SIZE_PX, cleverapps.styles.BaseCellView.CELL_SIZE_PX);
        this.setAnchorPoint(0.5, 0.5);

        if (trufflecell.findComponent(ColorComponent).color === undefined) {
            var goalImage = new cc.Sprite(bundles.truffle.frames.truffle_goal);
            goalImage.setPosition(this.width / 2, this.height / 2);
            this.addChild(goalImage);
        } else {
            this.images = [];
            for (var i = 0; i < TruffleCell.LIVES; i++) {
                var image = new cc.Sprite();
                image.setLocalZOrder(-i);
                this.addChild(image);
                this.images.push(image);
            }
        }

        if (cleverapps.styles.TruffleCellView.rose && !trufflecell.isForGoal) {
            this.rose = new cc.Sprite(bundles.truffle.frames.truffle_rose);
            this.rose.setLocalZOrder(10);
            this.addChild(this.rose);
        }

        this.initialize(trufflecell);
        this.setCascadeOpacityEnabled(true);
    },

    changeLives: function () {
        if (!this.images) {
            return;
        }

        var spentLives = TruffleCell.LIVES - this.cell.lives;
        this.images.forEach(function (image, index) {
            var order = index - spentLives;
            if (order < 0) {
                image.visible = false;
            } else {
                this.positionImage(image, order);
                image.visible = true;
            }
        }, this);
    },

    updateRose: function () {
        if (!this.rose) {
            return;
        }
        var styles = cleverapps.styles.TruffleCellView.rose;
        var index = this.images.length - this.cell.lives;
        if (index < 0 || index > this.images.length - 1) {
            index = 0;
        }
        var pos = this.images[index].getPosition();
        this.rose.setPositionRound(pos.x + styles.x, pos.y + styles.y);
    },

    positionImage: function (sprite, position) {
        var styles = cleverapps.styles.TruffleCellView;

        sprite.setPosition(this.width / 2, this.height / 2 + (position) * styles.images[position].offsetY);
        sprite.setScale(styles.images[position].scale);
    },

    updateImages: function () {
        if (!this.images) {
            return;
        }

        this.images.forEach(function (image, index) {
            var color = this.cell.colors[index];
            var frame = index < this.images.length - 1 || !bundles.truffle.frames["truffle_cell_last_" + color] ? bundles.truffle.frames["truffle_cell_" + color] : bundles.truffle.frames["truffle_cell_last_" + color];
            image.setSpriteFrame(frame);
        }, this);
    },

    getSkin: function (truffleCell) {
        var colors = {
            "a": "blue",
            "b": "green",
            "c": "purple",
            "d": "red",
            "e": "yellow",
            "w": "white"
        };

        return colors[truffleCell.findComponent(ColorComponent).color];
    },

    initialize: function (trufflecell) {
        this._super(trufflecell);

        this.updateImages();
        this.changeLives();
        this.updateRose();

        trufflecell.onChangeLivesListener = this.changeLives.bind(this);
    },

    unuse: function () {
        this.cell.onChangeLivesListener = function () {};

        this._super();
    },

    startExploding: function (goal, delta) {
        this.startExplodingViaCollect(goal, delta);
    },

    positionAnimation: function () {
        this.animation.setPosition(this.width / 2, this.height / 2);
        return undefined;
    },

    hurtAnimation: function () {
        this.setLocalZOrder(11);

        this.animation = BaseAnimation.factoryLimited(bundles.truffle.jsons.truffles_spine_json);
        var imageToHide = this.positionAnimation();
        
        this.animation.setSkin(this.getSkin(this.cell));
        this.addChild(this.animation);
        this.animation.setLocalZOrder(1);
        
        this.animation.runAnimate("animation", function () {
            if (this.cell.alive) {
                this.setLocalZOrder(this.baseZOrder);
            }
        }.bind(this), imageToHide);

        this.roseAnimation();

        cleverapps.audio.playSound(bundles.game.urls.flower_open_effect);
    },

    roseAnimation: function () {
        if (!this.rose) {
            return;
        }

        var styles = cleverapps.styles.TruffleCellView;
        var posY = this.height / 2 + (this.cell.lives > 0 ? styles.rose.y + styles.topOffsetY[this.cell.lives] : 0);
        this.rose.stopAllActions();
        this.rose.runAction(new cc.Sequence(
            new cc.ScaleTo(0.1, 1.2, 0.8),
            new cc.Spawn(
                new cc.ScaleTo(0.15, 1, 1),
                new cc.MoveBy(0.15, 0, styles.rose.jumpHeight)
            ),
            new cc.MoveTo(0.15, this.rose.x, posY)
        ));
    }
});

cleverapps.styles.TruffleCellView = {
    images: {
        "0": {
            offsetY: 0,
            scale: 0.8
        },
        "1": {
            offsetY: 20,
            scale: 0.6
        },
        "2": {
            offsetY: 18,
            scale: 0.4
        }
    }
};