/**
 * Created by slava on 03.08.17.
 */

var BaseTile = function (x, y) {
    this.x = x;
    this.y = y;
    this.alive = true;

    if (x < 0) {
        this.isForGoal = true;
    }

    this.onBeforeExplodeListener = function () {};
    this.onAfterExplodeListener = function () {};
    this.onGetView = function () {};
    // eslint-disable-next-line no-unused-vars
    this.onAnimationListener = function (type, params) {};
};

// eslint-disable-next-line no-unused-vars
BaseTile.prototype.load = function (data) {
    // do nothing
};

BaseTile.prototype.save = function () {
    throw "Not implemented";
};

BaseTile.prototype.bundleId = function () {
    return false;
};

BaseTile.prototype.animate = function (type, params) {
    this.onAnimationListener(type, params);
};

BaseTile.prototype.hurt = function () {
    this.explode();
};

// eslint-disable-next-line no-unused-vars
BaseTile.prototype.hurtViaBooster = function (boosterId) {

};

BaseTile.prototype.getExplodeInterval = function () {
    return 0.1;
};

BaseTile.prototype.getMyCell = function () {
    return Game.currentGame.field.cells[this.y][this.x];
};

BaseTile.prototype.explode = function () {
    if (!this.alive) {
        return;
    }

    this.alive = false;

    var EXPLODE_INTERVAL = this.getExplodeInterval();

    this.onBeforeExplodeListener();

    Game.currentGame.counter.setTimeout(function () {
        this.onAfterExplodeListener(this.x, this.y);
    }.bind(this), EXPLODE_INTERVAL * 1000);
};
