/**
 * Created by andrey on 01.08.2022
 */

var VictoryMessageAction = function (f) {
    if (Game.currentGame.outcome !== GameBase.OUTCOME_VICTORY) {
        f();
        return;
    }

    cleverapps.audio.playSound(bundles.main.urls.congratulate_sfx);

    var game = Game.currentGame;
    
    if (["heroes", "runes", "adventure"].includes(cleverapps.config.name)) {
        cleverapps.exclamation.show("message.YouWin", Exclamation.Types.Congrats);
        game.counter.setTimeout(f, 1200);
        return;
    }

    if (cleverapps.config.type === "board") {
        game.counter.setTimeout(f, 650);
        return;
    }

    game.counter.inc();
    game.showMessage("GameMessage.Victory", function () {
        console.log("GameMessage.Victory callback");
        game.counter.dec();
        f();
    });
};