/**
 * Created by vladislav on 1/26/2024
 */

var FotostranaLogic = function () {
    PlatformLogic.call(this);

    cleverapps.SETUP_RESOLUTION_MIN_FRAME_SIZE = cleverapps.SETUP_RESOLUTION_MIN_FRAME_SIZE_FS;
};

FotostranaLogic.prototype = Object.create(PlatformLogic.prototype);
FotostranaLogic.prototype.constructor = FotostranaLogic;
