/**
 * Created by Denis Kuzin on 20 december 2023
 */

var BonusWorldWindow = CleverappsWindow.extend({
    onWindowLoaded: function (mission) {
        var styles = cleverapps.styles.BonusWorldWindow;

        var config = this.config = BonusWorldConfig[mission.bonusWorld.type];

        this._super({
            name: "BonusWorldWindow",
            title: config.localizationPrefix + ".BonusWorldWindow.Title",
            content: this.getContent(),
            button: {
                width: styles.button.width,
                height: styles.button.height,
                text: "Play",
                onPressed: this.close.bind(this)
            }
        });
    },

    getContent: function () {
        var styles = cleverapps.styles.BonusWorldWindow;

        var banner = new cc.Sprite(bundles.bonus_world_window.frames.banner);

        var text = cleverapps.UI.generateOnlyText(this.config.localizationPrefix + ".BonusWorldWindow.Text", cleverapps.styles.FONTS.BONUS_WORLD_WINDOW_TEXT);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        text.setDimensions(banner.width - 2 * styles.textPadding, 0);

        return new cleverapps.Layout([banner, text], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });
    },

    listBundles: function () {
        return ["bonus_world_window"];
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    BONUS_WORLD_WINDOW_TEXT: {
        size: 37,
        name: "nostroke",
        color: cleverapps.styles.COLORS.COLOR_WINDOW_TEXT
    }
});

cleverapps.styles.BonusWorldWindow = {
    margin: 30,

    textPadding: 30,

    button: {
        width: 200,
        height: 100
    }
};