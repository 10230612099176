/**
 * Created by andrey on 22.04.2024
 */

var LoaderSceneBackground = cc.Node.extend({
    ctor: function (options) {
        this._super();

        this.onShowAgeRestriction = options.onShowAgeRestriction;

        var bgSize = cleverapps.resolution.getBgSize();
        this.horizontalMode = bgSize.width / bgSize.height > 1 / cleverapps.ASPECT_RATIO;

        // OK can start with invalid width and height, use horizontal background in this case as it look better
        if (bgSize.width < cleverapps.SETUP_RESOLUTION_MIN_FRAME_SIZE || bgSize.height < cleverapps.SETUP_RESOLUTION_MIN_FRAME_SIZE) {
            this.horizontalMode = true;
        }

        this.setVisible(false);
        this.updateSize();

        if (connector.platform.oneOf(connector.WECHAT)) {
            this.addDescription();
        }

        if (connector.platform.oneOf(connector.SAMSUNG, connector.WECHAT)) {
            this.addAgeRestriction();
        }

        this.transitionBundles = [this.getBackgroundBundle(), this.getAnimationsBundle()];
    },

    getBackgroundBundle: function () {
        var backgroundBundle = "loading_scene_images_vertical";
        if (this.horizontalMode) {
            backgroundBundle = "loading_scene_images_horizontal";
        }
        return bundles[backgroundBundle] ? backgroundBundle : "loading_scene_images_" + cleverapps.config.orientation;
    },

    getAnimationsBundle: function () {
        return "loading_scene";
    },

    updateSize: function () {
        var bgSize = cleverapps.resolution.getBgSize();
        this.setContentSize2(bgSize);
    },

    addBackground: function () {
        var styles = cleverapps.styles.LoaderSceneBackground;
        var image = bundles[this.getBackgroundBundle()].urls.background;

        var background = this.background = styles.pattern
            ? cleverapps.UI.createPatternSprite(image, cleverapps.resolution.getBgSize())
            : new cc.Sprite(image);

        this.addChild(background, -1);

        this.setVisible(true);
        cleverapps.resolution.showCanvas();

        this.addAnimations();
        this.setupChildren();

        this.triggerShowAgeRestriction();

        if (cc.game.isPaused()) {
            cc.game.step();
        }
    },

    addDescription: function () {
        var msg = "健康游戏忠告\n"
            + "抵制不良游戏,拒绝盗版游戏,注意自我保护,谨防受骗上当.\n"
            + "适度游戏益脑,沉迷游戏伤身,合理安排时间,享受健康生活.\n"
            + "批准文号：新广出审[2016]4509号 软著登记号：2016SR194719\n"
            + "著作权人：广州道泉网络科技有限公司 出版物号：ISBN 978-7-7979-3066-6\n"
            + "出版单位名称：广州盈正信息技术有限公司 运营单位：深圳彩苻科技有限公司";

        var styles = cleverapps.styles.LoaderSceneBackground;

        var description = this.description = cleverapps.UI.generateOnlyText(msg, cleverapps.styles.FONTS.LOADER_SCENE_DESCRIPTION);
        description.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        description.setDimensions(styles.description.width, 0);
        description.setPositionRound(styles.description);
        this.addChild(description);
    },

    addAgeRestriction: function () {
        if (cleverapps.isLocalhost()) {
            ageRestrictionSrc = "cleverapps/res/samsung/age_restriction.png";
        }

        cc.loader.loadImg(ageRestrictionSrc, function (err, img) {
            var styles = cleverapps.styles.LoaderSceneBackground;
            var isVertical = cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL;

            var ageRestriction = this.ageRestriction = new cc.Sprite(img);

            ageRestriction.setScale(isVertical ? styles.ageRestriction.vert.scale : 1);
            ageRestriction.setPositionRound(styles.ageRestriction);

            this.addChild(ageRestriction);

            this.triggerShowAgeRestriction();
        }.bind(this));
    },

    triggerShowAgeRestriction: function () {
        if (this.visible && this.ageRestriction && this.onShowAgeRestriction) {
            this.onShowAgeRestriction();
            delete this.onShowAgeRestriction;
        }
    },

    addAnimations: function () {
        if (!this.background || !cleverapps.bundleLoader.isLoaded(this.getAnimationsBundle())) {
            return;
        }

        var logo = this.logo = new cleverapps.Spine(bundles.loading_scene.jsons.logo_json);

        var language = logo.hasAnimation("animation_" + cleverapps.settings.language)
            ? cleverapps.settings.language
            : cc.sys.LANGUAGE_ENGLISH;

        var source = logo.hasAnimation("animation_" + language + "_" + connector.info.source)
            ? "_" + connector.info.source
            : "";

        logo.setAnimationAndIdleAfter("animation_" + language + source, "animation_" + language + "_idle" + source);
        logo.setLocalZOrder(10);

        this.addChild(logo);

        if (bundles.loading_scene.jsons.bg_json) {
            var styles = cleverapps.styles.LoaderSceneBackground;

            var customAnimation = this.customAnimation = new cleverapps.Spine(bundles.loading_scene.jsons.bg_json);
            customAnimation.setAnimation(0, "animation", false);
            customAnimation.setPositionRound(!this.horizontalMode ? styles.customAnimation.posVert : styles.customAnimation.pos);
            this.background.addChild(customAnimation);
        }

        this.setupChildren();
    },

    setupChildren: function () {
        if (!this.background) {
            return;
        }

        var styles = cleverapps.styles.LoaderSceneBackground;

        var bgSize = cleverapps.resolution.getBgSize();
        var logo = this.logo;
        var background = this.background;

        background.setScale(Math.max(bgSize.height / background.height, bgSize.width / background.width));
        background.setPosition(this.width / 2, this.height / 2);

        if (logo) {
            var scale = Math.max(background.scale, 1);
            var logoWidth = scale * logo.width;
            var maxWidth = bgSize.width * 0.9;
            if (styles.logoAnimation.canScale && (logoWidth > maxWidth)) {
                scale *= maxWidth / logoWidth;
            }
            logo.setScale(scale);

            var factor = !this.horizontalMode && styles.logoAnimation.posVert || styles.logoAnimation.pos;
            logo.setPosition(
                bgSize.width * factor.factorX,
                bgSize.height * factor.factorY
            );
        }
    }
});

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    LOADER_SCENE_DESCRIPTION: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: {
            size: 4,
            color: new cc.Color(116, 49, 161, 200)
        }
    }
});

cleverapps.styles.LoaderSceneBackground = {
    ageRestriction: {
        x: { align: "right", dx: -30 },
        y: { align: "top", dy: -30 },

        vert: {
            scale: 0.5
        }
    },
    description: {
        x: { align: "center" },
        y: { align: "bottom", dy: 215 },

        width: 1450
    },
    logoAnimation: {
        canScale: true,
        pos: {
            factorX: 0.5,
            factorY: 0.5
        }
    }
};