/**
 * Created by vlad on 19.10.18.
 */

var MultiColorBombCellView = BaseCellView.extend({
    ctor: function (allColorsCell) {
        this._super();
        var styles = cleverapps.styles.MultiColorBombCellView;

        if (allColorsCell.isForGoal) {
            this.setLocalZOrder(11);
            this.setAnchorPoint(0.5, 0.5);
            this.setContentSize2(styles.goal.width, styles.goal.height);
            var goalImage = new cc.Sprite(bundles.petards.frames.mclrbmb_a);
            goalImage.setScale(styles.goal.scale);
            goalImage.setPositionRound(this.width / 2, this.height / 2);
            this.addChild(goalImage);
            return;
        }

        var bigComponent = allColorsCell.findComponent(BigComponent);
        if (!bigComponent.isHead) {
            return;
        }

        this.createItems(allColorsCell);

        this.initialize(allColorsCell);
    },

    createItems: function (allColorsCell) {
        var styles = cleverapps.styles.MultiColorBombCellView;
        if (this.items) {
            this.items.forEach(function (item) {
                item.view.removeFromParent(true);
            });
        }
        this.items = [];

        var itemsStyles = cleverapps.styles.MultiColorBombCellView.items[allColorsCell.colors.length];
        allColorsCell.colors.forEach(function (color, index) {
            var activated = false;

            if (color === color.toUpperCase()) {
                color = color.toLowerCase();
                activated = true;
            }

            var scale = itemsStyles.scale || 1.0;
            var animation = new cleverapps.Spine(bundles.petards.jsons.petards_json);
            animation.setScale(scale || 1.0);
            animation.setContentSize2(styles.item.width, styles.item.height);
            animation.setSkin(this.getSkin(color));
            animation.setAnimation(0, activated ? "on" : "off", true);
            this.addChild(animation);
            animation.setPositionRound(itemsStyles.positions[index]);
            animation.setLocalZOrder(itemsStyles.positions[index].zOrder);
            this.items.push({ view: animation, color: color, activated: activated });
        }.bind(this));
    },

    createItem: function (color) {
        return new cc.Sprite(bundles.petards.frames["mclrbmb_" + color]);
    },

    getSkin: function (color) {
        var colors = {
            "a": "blue",
            "b": "green",
            "c": "purple",
            "d": "red",
            "e": "yellow",
            "w": "white"
        };

        return colors[color];
    },

    getClosestItemIndex: function (cell, color) {
        var minDist = Number.MAX_SAFE_INTEGER || Number.MAX_VALUE, minIndex;

        this.items.forEach(function (item, index) {
            if (item.activated) {
                return;
            }

            if (color && item.color !== color) {
                return;
            }

            var itemPos = this.getParent().convertToNodeSpace(item.view.getParent().convertToWorldSpace(item.view.getPosition()));
            var pos = BaseCellView.alignInTheGrid(cell.x, cell.y);
            var dx = Math.abs(itemPos.x - pos.x), dy = Math.abs(itemPos.y - pos.y);
            var dist = Math.sqrt(dx * dx + dy * dy);
            if (dist < minDist) {
                minDist = dist;
                minIndex = index;
            }
        }.bind(this));
        return minIndex;
    },

    initialize: function (allColorsCell) {
        this._super(allColorsCell);

        allColorsCell.onChangeLivesListener = this.changeLives.bind(this);

        this.setPositionRound(BaseCellView.alignInTheGrid(allColorsCell.x + 0.5, allColorsCell.y + 0.5));
    },

    changeLives: function (color, cell, callback) {
        var index = this.getClosestItemIndex(cell, color);
        if (index === undefined) {
            return;
        }
        var item = this.items[index];
        item.activated = true;
        item.view.setAnimation(0, "on", true);
        callback(index);
        if (this.cell && this.cell.lives < 1) {
            cleverapps.audio.playSound(bundles.petards.urls.petards_explosion_effect);
        }
    },

    boomAnimation: function () {
        var animation = new cleverapps.Spine(bundles.petards.jsons.petards_explosion_json);
        animation.setAnimation(0, "boom", false);
        animation.setCompleteListener(function () {
            this.runAction(new cc.RemoveSelf());
        }.bind(this));
        animation.setLocalZOrder(20);
        this.addChild(animation);
        animation.setPositionRound(0, 0);
    
        var flyShotDirs = [[[BaseCell.UP, BaseCell.LEFT], [BaseCell.LEFT, BaseCell.DOWN]], [[BaseCell.UP, BaseCell.RIGHT], [BaseCell.DOWN, BaseCell.RIGHT]]];
        // move on all petard cells  (dx - columns, dy - rows)
        for (var dx = 0; dx <= 1; dx++) {
            for (var dy = 0; dy <= 1; dy++) {
                for (var dirs = 0; dirs < 2; dirs++) {
                    var cellsToHurt = [];
                    this.cell.iterateLineOfFire(this.cell.x + dx, this.cell.y + dy, BaseCell.DIRECTIONS[flyShotDirs[dx][dy][dirs]], function (cell) {
                        cellsToHurt.push(cell);
                    }, true);
                    SmallPetardCellView.explosionWavesAnimation.call(this, {
                        image: bundles.petards.frames.wave_png,
                        scale: 0.9,
                        directions: [BaseCell.DIRECTIONS[flyShotDirs[dx][dy][dirs]]],
                        fieldDX: dx,
                        fieldDY: dy,
                        cellsToFlyCount: cellsToHurt.length
                    });
                }
            }
        }
    },

    startExploding: function () {
        this.setLocalZOrder(11);
        this.stopAllActions();
        this.runAction(new cc.Sequence(
            new cc.ScaleTo(0.3, 1.5),
            new cc.CallFunc(function () {
                this.items.forEach(function (item) {
                    item.view.setVisible(false);
                });
                this.boomAnimation();
            }.bind(this))
        ));
    },

    unuse: function () {
        this.cell.onChangeLivesListener = function () {};

        this._super();
    },

    getStyles: function () {
        return cleverapps.styles.MultiColorBombCellView;
    }
});

cleverapps.styles.MultiColorBombCellView = {
    item: {
        width: 70,
        height: 130
    },

    items: {
        "6": {
            scale: 0.7,
            positions: [
                { x: -28, y: 28, zOrder: 0 },
                { x: 28, y: 28, zOrder: 0 },
                { x: 45, y: -10, zOrder: 1 },
                { x: 28, y: -48, zOrder: 2 },
                { x: -28, y: -48, zOrder: 2 },
                { x: -45, y: -10, zOrder: 1 }
            ]
        },
        "5": {
            scale: 0.8,
            positions: [
                { x: 0, y: 32, zOrder: 0 },
                { x: 44, y: 4, zOrder: 1 },
                { x: 32, y: -36, zOrder: 2 },
                { x: -32, y: -36, zOrder: 2 },
                { x: -44, y: 4, zOrder: 1 }
            ]
        },
        "4": {
            scale: 0.8,
            positions: [
                { x: 0, y: 32, zOrder: 0 },
                { x: 40, y: 0, zOrder: 1 },
                { x: 0, y: -36, zOrder: 2 },
                { x: -40, y: 0, zOrder: 1 }
            ]
        },
        "3": {
            positions: [
                { x: -40, y: 10, zOrder: 1 },
                { x: 40, y: 10, zOrder: 1 },
                { x: 0, y: -35, zOrder: 2 }
            ]
        },
        "2": {
            scale: 0.9,
            positions: [
                { x: -23, y: 23, zOrder: 0 },
                { x: 23, y: -23, zOrder: 1 }
            ]
        },
        "1": {
            positions: [
                { x: 0, y: 0, zOrder: 0 }
            ]
        }
    },

    hover: {
        y: 10
    },

    goal: {
        scale: 0.7,
        width: 120,
        height: 120
    }
};