/**
 * Created by Denis Kuzin on 15 may 2024
 */

var ColorComponent = function (cell, color) {
    this.cell = cell;
    this.color = color === "" ? undefined : color;
};

ColorComponent.prototype.useCustomView = function () {
    return false;
};

ColorComponent.prototype.onDoubleTouch = function () {
    if (cleverapps.config.debugMode) {
        var combo = ComboComponent.Create(this.cell, {
            random: true
        });

        if (combo && !this.cell.moving) {
            this.cell.delete();
            Game.currentGame.field.addCell(combo);
            combo.animate(BaseCell.ANIMATION_CREATE);
        }

        if (Game.currentGame.tutorial && !Game.currentGame.tutorial.finished) {
            Game.levelTutorialView.moveUpNodes();
        }

        var move = Game.currentGame.field.findRandomMove();
        if (move) {
            Game.currentGame.field.availableMove.setMove(move);
        }
    }
};

ColorComponent.prototype.setColor = function (color) {
    this.color = color;
};

ColorComponent.prototype.canRepaint = function () {
    return true;
};

ColorComponent.prototype.canDelete = function () {
    return this.cell.constructor === BaseCell && !this.cell.toDelete;
};

ColorComponent.prototype.repaint = function () {
    return Game.currentGame.field.loader.loadNext(this.cell.y, this.cell.x, [Loader.WILDCARD]);
};

ColorComponent.prototype.boomNeighbors = function (coef) {
    if (!this.cell.alive) {
        return;
    }

    this.cell.forNeighbor(function (neighbor) {
        neighbor.boom(coef, this.cell);
    }.bind(this));
};

ColorComponent.prototype.explodeViaEater = function (eater, params) {
    if (!this.cell.alive) {
        return;
    }
    this.cell.alive = false;

    params = params || {};
    if (!params.groupSize) {
        params.groupSize = 1;
    }
    if (!params.id) {
        params.id = 0;
    }

    var goal = eater.findComponent(EaterComponent).check(this.cell, params.id, params.groupSize);
    this.cell.onBeforeExplodeMouseListener(eater, goal, params.id);

    // different time, because we don't want to wait for collect animation to finish
    var EXPLODE_INTERVAL = this.cell.explodeDuration();
    Game.currentGame.counter.setTimeout(this.cell.afterExplode.bind(this.cell, params.callback), EXPLODE_INTERVAL * 1000);
};

ColorComponent.prototype.simpleExplode = function (params) {
    var eater = EaterComponent.find(params, this.color);
    if (eater) {
        this.explodeViaEater(eater, params);
        return;
    }

    return true;
};

ColorComponent.prototype.explode = function (params) {
    cleverapps.eventBus.trigger("taskEvent", DailyTasks.COLLECT_COLOR_CELLS, { color: this.color.toLowerCase() });

    this.cell.simpleExplode(params);
};

ColorComponent.prototype.load = function (data) {
    this.color = data.shift();

    var markComponent = this.cell.findComponent(MarkComponent);
    if (markComponent) {
        markComponent.load(data);
        markComponent.addMissionMark();
    }
};

ColorComponent.prototype.save = function () {
    var markCode = this.cell.findComponent(MarkComponent).save();
    if (markCode) {
        return [this.color, markCode];
    }

    return [this.color];
};

ColorComponent.prototype.getViewClass = function () {
    if (this.color === "a") {
        // eslint-disable-next-line camelcase
        return ColorCellView_a;
    }
    if (this.color === "b") {
        // eslint-disable-next-line camelcase
        return ColorCellView_b;
    }
    if (this.color === "c") {
        // eslint-disable-next-line camelcase
        return ColorCellView_c;
    }
    if (this.color === "d") {
        // eslint-disable-next-line camelcase
        return ColorCellView_d;
    }
    if (this.color === "e") {
        // eslint-disable-next-line camelcase
        return ColorCellView_e;
    }
    if (this.color === "w") {
        // eslint-disable-next-line camelcase
        return ColorCellView_w;
    }
    throw "unknown color: " + this.color;
};

ColorComponent.COLOR_A = "a";
ColorComponent.COLOR_B = "b";
ColorComponent.COLOR_C = "c";
ColorComponent.COLOR_D = "d";
ColorComponent.COLOR_E = "e";
ColorComponent.COLOR_W = "w";

ColorComponent.CODE_COLORS = {};
ColorComponent.CODE_COLORS[ColorComponent.COLOR_A] = "blue";
ColorComponent.CODE_COLORS[ColorComponent.COLOR_B] = "green";
ColorComponent.CODE_COLORS[ColorComponent.COLOR_C] = "purple";
ColorComponent.CODE_COLORS[ColorComponent.COLOR_D] = "red";
ColorComponent.CODE_COLORS[ColorComponent.COLOR_E] = "yellow";
ColorComponent.CODE_COLORS[ColorComponent.COLOR_W] = "white";
