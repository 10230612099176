/**
 * Created by slava on 02.02.17.
 */

var FlowerCellView = BaseCellView.extend({
    ctor: function (flowerCell) {
        this._super();

        this.setContentSize(cleverapps.styles.BaseCellView.CELL_SIZE_PX, cleverapps.styles.BaseCellView.CELL_SIZE_PX);
        this.setAnchorPoint(0.5, 0.5);

        var image = new cc.Sprite(this.getImage(flowerCell));
        this.image = image;
        this.image.setPosition(this.width / 2, this.height / 2);
        this.addChild(image);

        this.initialize(flowerCell);
    },

    initialize: function (flowerCell) {
        this._super(flowerCell);

        flowerCell.onChangeLivesListener = this.changeLives.bind(this);

        this.changeLives();
    },

    getImage: function (cell) {
        var bundle = cell.bundleId();
        return bundles[bundle].frames[bundle + "_" + cell.lives];
    },

    changeLives: function () {
        this.image.setSpriteFrame(this.getImage(this.cell));
    },

    unuse: function () {
        this.cell.onChangeLivesListener = function () {};

        this._super();
    },

    finishAnimation: function () {
        if (this.animation) {
            this.animation.finishAnimation();
            delete this.animation;
        }
        this.isAnimationRunning = false;
        this.image.visible = true;
    },

    runAnimate: function (name) {
        this.isAnimationRunning = true;
        
        this.animation = BaseAnimation.factoryLimited(bundles[this.cell.bundleId()].jsons.flower_spine_json);
        this.animation.setPosition(this.width / 2, this.height / 2);
        this.addChild(this.animation);

        this.animation.runAnimate(name, this.finishAnimation.bind(this), this.image);
    },

    hurtAnimation: function () {
        if (this.isAnimationRunning) {
            return; // to prevent visual bugs with several animations on each other when flowerCell is beaten multiple times simultaneously (for example, green hero)
        }
        this.runAnimate(FlowerAnimationView.animations[this.cell.lives]);

        cleverapps.audio.playSound(bundles.game.urls.flower_open_effect);
    },

    startExploding: function () {
        var callback = Game.currentGame.goals.hasType(this.cell.getGoalId())
            ? BaseCellView.prototype.startExplodingViaCollect : BaseCellView.prototype.startExploding;
        callback.apply(this, arguments);
    },

    smile: function () {
        if (cleverapps.Spine.hasAnimation("animation", bundles[this.cell.bundleId()].jsons.flower_spine_json)) {
            this.runAnimate("animation");
        }
    }
});