/**
 * Created by Andrey Popov on 1/21/21.
 */

var AssetComponent = function (name, options) {
    this.name = name;
    Object.assign(this, options);

    cleverapps.EventEmitter.call(this);
};

AssetComponent.prototype = Object.create(cleverapps.EventEmitter.prototype);
AssetComponent.prototype.constructor = AssetComponent;

AssetComponent.prototype.moveToScene = function (parentId, position) {
    this.trigger("componentMovedToScene", parentId, position);
};