/**
 * Created by slava on 02.02.17.
 */

var SuperMultiColorCellView = MultiColorCellView.extend({
    ctor: function (cell) {
        this._super(cell);
        this.setScale(1.2);
    },

    createAnimation: function () {
        cleverapps.audio.playSound(bundles.game.urls.create_multicolor_effect);

        var blast = new cleverapps.Spine(bundles.game.jsons.blast_spine_json);
        blast.setLocalZOrder(100);
        blast.setPosition(this.width / 2, this.height / 2);
        this.addChild(blast);

        blast.setAnimation(0, "animation", false);
        blast.setCompleteListenerRemove();
    }
});