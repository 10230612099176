/**
 * Created by olga on 10.06.2024
 */

var Goal = function (options, savedGoal) {
    cleverapps.EventEmitter.call(this);

    this.type = options.type;
    this.target = options.target;

    this.amount = savedGoal || 0;

    this.getView = function () {
    };
};

Goal.prototype = Object.create(cleverapps.EventEmitter.prototype);
Goal.prototype.constructor = Goal;

Goal.prototype.getInfo = function () {
    return this.amount;
};

Goal.prototype.inc = function (delta, silent) {
    delta = delta || 1;
    this.setAmount(this.amount + delta, silent);
};

Goal.prototype.setAmount = function (amount, silent) {
    amount = Math.max(0, amount);
    if (this.withTarget()) {
        amount = Math.min(this.target, amount);
    }

    if (this.amount === amount) {
        return;
    }

    if (!silent) {
        this.trigger("update", amount - this.amount);
    }
    this.amount = amount;
    this.checkCompleted();
    this.checkIncompleted();
};

Goal.prototype.collect = function (amount, flyIcon, options) {
    this.inc(amount, true);

    options = options || {};
    var callback = options.callback || function () {};
    this.onCollectFinished = function () {};
    this.trigger("collect", amount, flyIcon, Object.assign(options, {
        callback: function () {
            if (this.onCollectFinished) {
                this.onCollectFinished();
                delete this.onCollectFinished;
            }
            callback();
        }.bind(this)
    }));
};

Goal.prototype.onCollect = function (delta) {
    this.trigger("onCollect", delta);
};

Goal.prototype.getRemaining = function () {
    return Math.max(0, this.target - this.amount);
};

Goal.prototype.getAmount = function () {
    return this.amount;
};

Goal.prototype.getTarget = function () {
    return this.target;
};

Goal.prototype.isCompleted = function () {
    return this.completed;
};

Goal.prototype.checkCompleted = function () {
    if (this.withTarget() && this.amount >= this.target && !this.isCompleted()) {
        this.completed = true;
        this.trigger("complete");
    }
};

Goal.prototype.checkIncompleted = function () {
    if (this.amount < this.target && this.isCompleted()) {
        this.completed = false;
        this.trigger("incomplete");
    }
};

Goal.prototype.incTarget = function (target) {
    this.target += target;

    this.trigger("update");

    this.checkCompleted();
    this.checkIncompleted();
};

Goal.prototype.withTarget = function () {
    return this.target !== Goal.NO_TARGET;
};

Goal.prototype.showView = function () {
    this.trigger("show");
};

Goal.NO_TARGET = -1;
