/**
 * Created by evgeny on 15.05.2024
 */

var PiggyBankProgressBar = ScaledProgressBar.extend({
    ctor: function (mission) {
        var styles = cleverapps.styles.PiggyBankProgressBar;

        this._super({
            progress: bundles.progress_bar.frames.bar_dailytask,
            background: bundles.progress_bar.frames.bg_dailytask,
            to: mission.logic.getMaxAmount()
        });

        mission.logic.updateProgress = this.createListener(this.updateProgress.bind(this));
        this.mission = mission;
        this.setLength(styles.length);
        this.goalViews = this.addGoalViews();

        var lastShownAmount = mission.logic.getLastShowAmountProgress();
        this.setPercentage(lastShownAmount);

        var target = this.target = new cc.Node();
        this.addChild(target);
        this.updateTargetPosition();
    },

    addGoalViews: function () {
        var goals = this.mission.logic.getProgressGoals();
        var styles = cleverapps.styles.PiggyBankProgressBar;
        return goals.map(function (goal) {
            var goalView = new PiggyBankGoalView(this.mission.logic.getGoal(goal));
            this.addChild(goalView);
            var position = this.width * this.mission.logic.calculateProgress(goal) + (styles.goalsOffset || 0);
            goalView.setPosition(position, this.height / 2);

            return goalView;
        }.bind(this));
    },

    updateTargetPosition: function () {
        var position = this.width * this.mission.result / this.mission.logic.getMaxAmount();
        this.target.setPosition(position, this.height / 2);
    },

    updateProgress: function () {
        if (Math.abs(this.mission.logic.getLastShowAmountProgress() - this.mission.result) < 0.01) {
            return;
        }

        var goals = this.goalViews;
        var amountProgress = this.mission.result;

        var stages = goals.filter(function (goal) {
            return goal.amount <= amountProgress && goal.amount > this.mission.logic.getLastShowAmountProgress();
        }.bind(this));

        cleverapps.audio.playSound(bundles.piggy_bank_window.urls.piggy_progress_update);

        var actions = [];

        if (stages.length) {
            actions = stages.map(function (stage) {
                return new cc.Sequence(
                    new cc.CallFunc(
                        function () {
                            this.setPercentage(stage.amount, {
                                animated: true,
                                delta: true,
                                callback: function () {
                                    stage.showLight();
                                    this.mission.logic.showBottomButtonPriceWithAnimation();

                                    if (this.mission.result === stage.amount) {
                                        this.mission.logic.setBalance(this.mission.result);
                                    }
                                }.bind(this)
                            });
                        }.bind(this)
                    ),
                    new cc.DelayTime(2)
                );
            }.bind(this));
        }

        actions.push(
            new cc.CallFunc(function () {
                this.setPercentage(this.mission.result, {
                    animated: true,
                    delta: true,
                    callback: function () {
                        this.mission.logic.setBalance(this.mission.result);
                    }.bind(this)
                });
            }.bind(this))
        );

        actions.push(new cc.CallFunc(function () {
            this.updateTargetPosition();
        }.bind(this)));

        this.runAction(new cc.Sequence(
            actions
        ));
    }
});

cleverapps.styles.PiggyBankProgressBar = {
    length: 550
};

if (cleverapps.config.ui === "heroes") {
    cleverapps.overrideStyles(cleverapps.styles.PiggyBankProgressBar, {
        goalsOffset: -10
    });
}