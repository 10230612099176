/**
 * Created by slava on 24.03.17.
 */

var CanUpgradeHeroWindow = CleverappsWindow.extend({
    onWindowLoaded: function () { 
        var styles = cleverapps.styles.CanUpgradeHeroWindow;

        var heroes = new cc.Node();
        heroes.setAnchorPoint(0.5, 0.5);
        heroes.setContentSize2(styles.heroesNode.width, styles.heroesNode.height);
        cleverapps.styles.HeroesScene.heroBuildings.forEach(function (building, order) {
            var color = building.color.toUpperCase();

            var animation = new cleverapps.Spine(cleverapps.skins.getSlot(color + "_hero_spine_json") || bundles.heroes.jsons[color + "_hero_spine_json"]);
            animation.setScale(styles.heroes[order].scale || 1.0);
            animation.setSkin(match3.heroes.getCurrentSkin());
            animation.setPosition(heroes.width / 2 + styles.heroes[order].x, heroes.height / 2 + styles.heroes[order].y);
            animation.setAnimation(0, "idle", true);
            heroes.addChild(animation);
        }, this);

        var text = cleverapps.UI.generateTTFText("CanUpgradeHeroes.Text", cleverapps.styles.FONTS.WINDOW_TEXT);
        text.setDimensions(styles.heroesNode.width, 0);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);

        var content = new cleverapps.Layout([heroes, text], {
            margin: styles.margin,
            direction: cleverapps.UI.VERTICAL
        });

        this._super({
            title: "CanUpgradeHeroes.Title",
            name: "canupgradeherowindow",
            content: content,
            button: {
                text: "CanUpgradeHeroes.Button",
                onPressed: function () {
                    cleverapps.meta.distract({
                        focus: "CanUpgradeHeroesScene",
                        action: function (f) {
                            cleverapps.scenes.replaceScene(new HeroesScene(), f);
                        }
                    });
                },
                width: styles.button.width,
                height: styles.button.height
            }
        });
    },

    listBundles: function () {
        return ["can_upgrade_hero_window"];
    }
});

cleverapps.styles.CanUpgradeHeroWindow = {
    margin: 0,

    button: {
        width: 340,
        height: 90
    },

    heroesNode: {
        width: 550,
        height: 400
    },

    heroes: [{
        x: -56,
        y: 150,
        scale: 1
    }, {
        x: 56,
        y: 150,
        scale: 1
    }, {
        x: -150,
        y: 30,
        scale: 1.25
    }, {
        x: 150,
        y: 30,
        scale: 1.25
    }, {
        x: -80,
        y: -90,
        scale: 1.4
    }, {
        x: 80,
        y: -90,
        scale: 1.4
    }]
};