/**
 * Created by vlad on 13.08.18
 */

var TruffleCell = function (x, y, color) {
    BaseCell.call(this, x, y);

    this.addComponent(ColorComponent, { color: color });
    this.addComponent(SpecialColorComponent);
    this.addComponent(MarkComponent);

    this.lives = TruffleCell.LIVES;
    this.putInPoolAvailable = true;

    this.onChangeLivesListener = function () {};
};

TruffleCell.prototype = Object.create(BaseCell.prototype);
TruffleCell.prototype.constructor = TruffleCell;

TruffleCell.prototype.useCustomView = function () {
    return true;
};

TruffleCell.prototype.getViewClass = function () {
    return TruffleCellView;
};

TruffleCell.prototype.getGoalId = function () {
    return TruffleCell.GOAL_ID;
};

TruffleCell.prototype.bundleId = function () {
    return "truffle";
};

TruffleCell.prototype.repaint = function () {
    var colorComponent = this.findComponent(ColorComponent);
    this.colors = this.getRandomColors(this.lives);
    colorComponent.setColor(this.colors[0]);
    return this;
};

TruffleCell.prototype.getRandomColors = function (amount, topColor) {
    var colors = Game.currentGame.levelContent.colors.slice().filter(function (color) {
        return (!topColor) || (topColor && color.toLowerCase() !== topColor.toLowerCase());
    });
    for (var i = 0; i < colors.length; i++) {
        colors[i] = colors[i].toLowerCase();
    }
    colors = cleverapps.Random.shuffle(colors);
    if (!topColor || topColor === ".") {
        colors = colors.splice(0, amount);
    } else {
        colors = colors.splice(0, amount - 1);
        colors.unshift(topColor);
    }

    return colors;
};

TruffleCell.prototype.hurt = function (explodeParams) {
    if (this.lives < 1 || !this.alive) {
        return;
    }

    this.lives--;

    if (this.lives > 0) {
        this.onChangeLivesListener();
        this.animate(BaseCell.ANIMATION_HURT);

        var colorComponent = this.findComponent(ColorComponent);
        colorComponent.setColor(this.colors[this.colors.length - this.lives]);

        Game.currentGame.counter.setTimeout(function () {}, this.hurtDuration() * 1000);
    } else {
        this.explode(explodeParams);
    }
};

TruffleCell.prototype.hurtDuration = function () {
    return 0.5;
};

TruffleCell.prototype.explode = function (params) {
    var markComponent = this.findComponent(MarkComponent);
    if (markComponent) {
        markComponent.explode();
    }

    this.explodeViaCollect(params);
};

TruffleCell.prototype.load = function (data) {
    var colorComponent = this.findComponent(ColorComponent);

    if (data[1] === "l") {
        this.lives = parseInt(data[2]);
        this.colors = [data[3], data[4], data[5]];
        colorComponent.setColor(this.colors[this.colors.length - this.lives]);
    } else {
        this.colors = this.getRandomColors(this.lives, data[1]);
        colorComponent.setColor(this.colors[0]);
    }

    this.findComponent(MarkComponent).load(data);
};

TruffleCell.prototype.save = function () {
    var code = TruffleCell.CODES.slice();
    if (cleverapps.environment.isGameScene()) {
        code = code.concat(["l", "" + this.lives]).concat(this.colors);
    } else {
        code.push(this.colors[0]);
        code.push(".");
    }

    var markCode = this.findComponent(MarkComponent).save();
    if (markCode) {
        code.push(markCode);
    }

    return code;
};

TruffleCell.GOAL_ID = "truffle";

TruffleCell.LIVES = 3;