/**
 * Created by Andrey Popov on 08.10.2021.
 */
var AtlasAnalyzerScene = cleverapps.FixedWidthScene.extend({
    ctor: function (atlasAnalyzer, options) {
        this._super();

        this.atlasAnalyzer = atlasAnalyzer;
        this.options = options;
    },

    onSceneLoaded: function () {
        this._super(cleverapps.Environment.SCENE_ATLAS_ANALYZER);

        var options = this.options;

        cleverapps.Synchronizer.prototype.showClientSessionExpiredWindow = function () {};

        var bundlesExplorerView = new BundlesExplorerView(this.atlasAnalyzer.bundlesExplorer);
        var textureImageView = new TextureImageView(this.atlasAnalyzer.textureImage);
        var imageSpyView = new ImageSpyView(this.atlasAnalyzer.imageSpy);

        var horizontalLayout = this.horizontalLayout = new cleverapps.Layout([bundlesExplorerView, textureImageView, imageSpyView], {
            direction: cleverapps.UI.HORIZONTAL
        });

        horizontalLayout.setPositionRound(0, 0);
        horizontalLayout.setAnchorPoint(0, 0);
        this.addChild(horizontalLayout);

        this.atlasAnalyzer.showUp(options.tab || "Main", options.bundleName, options.frame);

        cleverapps.debugStats.setEnabled(false);
    },

    setupChildren: function () {
        if (!this.isAllLoaded) {
            return;
        }

        this._super();

        this.horizontalLayout.reshape();
    },

    _closeAction: function () {
        cleverapps.debugStats.setEnabled(true);

        cleverapps.setUrlHash({
            atlasAnalyzer: undefined, tab: undefined, bundleName: undefined, frame: undefined 
        });
        cleverapps.FixedWidthScene.prototype._closeAction.call(this);
    },

    setBackground: function () {
        if (this.background) {
            this.background.removeFromParent();
        }
        var background = this.background = new cc.Scale9Sprite(bundles.pixel.frames.pixel_png);
        background.setColor(new cc.Color(255, 255, 255, 255));
        background.setAnchorPoint(0, 0);
        background.setLocalZOrder(-10);
        this.updateBackgroundScale();
        this.updateBackgroundPosition();
        this.screen.addChild(background);
    },

    updateBackgroundScale: function () {
        this.background.setContentSize2(cleverapps.resolution.getBgSize());
    },

    updateBackgroundPosition: function () {
    },

    listBundles: function () {
        return ["wysiwyg"];
    }
});

AtlasAnalyzerScene.open = function (atlasAnalyzer, bundleName) {
    (cleverapps.meta.isFocused() ? cleverapps.meta.distract : cleverapps.meta.display).call(cleverapps.meta, {
        focus: "atlasAnalyzer",
        control: ["ControlButtonsView"],
        action: function (f) {
            cleverapps.scenes.replaceScene(new AtlasAnalyzerScene(atlasAnalyzer, {
                bundleName: bundleName
            }), f);
        }
    });
};

cleverapps.styles.AtlasAnalyzerScene = {
    offsetTop: 90
};

(function () {
    var params = cleverapps.getRequestParameters(location.hash);
    if (!params.atlasAnalyzer || !cleverapps.config.debugMode) {
        return;
    }

    cleverapps.Plot.onStartup = function (f, returnClassOnly) {
        if (returnClassOnly) {
            return AtlasAnalyzerScene;
        }

        if (!cleverapps.atlasAnalyzer) {
            cleverapps.atlasAnalyzer = new AtlasAnalyzer(params.tab);
        }
        var atlasAnalyzer = cleverapps.atlasAnalyzer;

        var scene = new AtlasAnalyzerScene(atlasAnalyzer, {
            tab: params.tab,
            bundleName: params.bundleName,
            frame: params.frame
        });
        cleverapps.scenes.replaceScene(scene, f);
    };
}());