/**
 * Created by spepa on 24.02.2020.
 */

var AdminFBProductsWindow = CleverappsWindow.extend({
    ctor: function () {
        this._super.apply(this, arguments);
    },

    onWindowLoaded: function (adminFBProducts) {
        this.adminFBProducts = adminFBProducts;

        this._super({
            noBackground: true,
            closeButton: false,
            name: "GitInfoWindow",
            content: this.getContent(),
            buttons: this.getButtons()
        });
    },

    getContent: function () {
        var styles = cleverapps.styles.AdminFBProductsWindow;

        var items = [this.createProducts(), this.createLangs(), this.createStandToggle()];
        var content = new cleverapps.Layout(items, {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.contentMargin,
            offset: styles.contentOffsetY
        });

        var bg = cleverapps.UI.createScale9Sprite(bundles.admin.frames.lvl_bg);
        bg.setContentSize2(content.width + styles.bg.padding.x, content.height + styles.bg.padding.y);
        bg.addChild(content);
        content.setPositionRound(bg.width / 2, bg.height / 2);

        return bg;
    },

    createProducts: function () {
        var productItems = this.productItems = [];
        connector.payments.products.forEach(function (product) {
            productItems.push(this.createOneProduct(product));
        }, this);

        var half = Math.round(productItems.length / 2);
        var columns = [
            new cleverapps.Layout(productItems.slice(0, half), {
                direction: cleverapps.UI.VERTICAL,
                reversed: true
            }),
            new cleverapps.Layout(productItems.slice(half), {
                direction: cleverapps.UI.VERTICAL,
                reversed: true
            })
        ];
        columns.forEach(function (column) {
            column.setAnchorPoint(0.5, 1);
        });

        var styles = cleverapps.styles.AdminFBProductsWindow.products;
        var productsNode = new cleverapps.Layout(columns, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.columnsMargin
        });

        var scroll = new cleverapps.UI.ScrollView(productsNode);
        scroll.setContentSize2(styles.width, Math.min(productsNode.height, styles.height));
        scroll.scrollToDefault();

        var frame = new cc.Node();
        frame.setAnchorPoint(0.5, 0.5);
        frame.setContentSize2(scroll.width + styles.framePadding, scroll.height + styles.framePadding);
        frame.addChild(scroll);
        scroll.setPositionRound(frame.width / 2, frame.height / 2);

        this.addGroupToggle(frame, this.productItems, false);

        return frame;
    },

    createOneProduct: function (product) {
        var styles = cleverapps.styles.AdminFBProductsWindow.products.product;
        var items = [];

        if (product.image) {
            var imgNode = new cleverapps.Layout.AsyncChild();
            imgNode.setContentSize2(styles.img);
            items.push(imgNode);

            var img = new cc.Sprite(product.image);
            img.addEventListener("load", function () {
                cleverapps.UI.fitToBox(img, styles.img);
                imgNode.reshape(img);
            }, img);
        }

        var title = cleverapps.UI.generateOnlyText(Messages.getLocalized(product.title), cleverapps.styles.FONTS.ADMIN_PRODUCTS);
        title.setDimensions(styles.titleWidth, 0);
        title.fitTo(undefined, styles.height);
        items.push(title);

        var id = cleverapps.UI.generateOnlyText(product.itemId, cleverapps.styles.FONTS.ADMIN_PRODUCTS_ID);
        id.setDimensions(styles.idWidth, 0);
        id.setOpacity(160);
        items.push(id);

        var content = new cleverapps.Layout(items, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });

        var node = new cc.Node();
        node.setAnchorPoint(0.5, 0.5);
        node.setContentSize2(styles);
        content.setPositionRound(styles);
        node.addChild(content);
        node.id = product.productId;

        node.selected = false;
        this.makeItemTogglable(node);
        return node;
    },

    createLangs: function () {
        var langItems = cleverapps.config.languages.map(function (lang) {
            return this.createOneLang(lang);
        }, this);
        this.langs = langItems.slice(0);

        var rows = [];
        while (langItems.length > 0) {
            rows.push(new cleverapps.Layout(langItems.splice(0, 6), {
                direction: cleverapps.UI.HORIZONTAL
            }));
            rows.at(-1).setAnchorPoint(0, 0.5);
        }

        var langsNode = new cleverapps.Layout(rows, {
            direction: cleverapps.UI.VERTICAL
        });

        this.addGroupToggle(langsNode, this.langs, true);

        return langsNode;
    },

    createOneLang: function (lang) {
        var styles = cleverapps.styles.AdminFBProductsWindow.langs.lang;
        var text = cleverapps.UI.generateOnlyText(lang, cleverapps.styles.FONTS.ADMIN_PRODUCTS);
        text.fitTo(styles.width);

        var frame = new cc.Node();
        frame.setAnchorPoint(0.5, 0.5);
        frame.id = lang;
        frame.setContentSize2(styles);
        frame.addChild(text);
        text.setPositionRound(frame.width / 2, frame.height / 2);

        frame.selected = true;
        this.makeItemTogglable(frame);

        return frame;
    },

    createStandToggle: function () {
        var text = this.standUpdate = cleverapps.UI.generateOnlyText("Update stand products", cleverapps.styles.FONTS.ADMIN_PRODUCTS);
        text.selected = false;
        this.makeItemTogglable(text);
        return text;
    },

    makeItemTogglable: function (node) {
        var selectedBg = cleverapps.UI.createScale9Sprite(bundles.admin.frames.lvl_selected_bg);
        selectedBg.setOpacity(180);
        selectedBg.setContentSize2(node.width * 1.02, node.height);
        selectedBg.setPositionRound(node.width / 2, node.height / 2);
        node.addChild(selectedBg, -1);
        selectedBg.setVisible(node.selected);

        node.toggle = function (state) {
            if (typeof state === "boolean") {
                node.selected = state;
            } else {
                node.selected = !node.selected;
            }

            selectedBg.setVisible(node.selected);
        };

        cleverapps.UI.onClick(node, node.toggle);
    },

    addGroupToggle: function (node, group, defaultVal) {
        var toggle = cleverapps.UI.generateOnlyText("Toggle all", cleverapps.styles.FONTS.GIT_INFO_TEXT);
        toggle.selected = defaultVal;
        node.addChild(toggle);
        toggle.setAnchorPoint(0.5, 0.5);
        toggle.setPositionRound(cleverapps.styles.AdminFBProductsWindow.toggle);

        cleverapps.UI.applyHover(toggle);
        cleverapps.UI.onClick(toggle, function () {
            toggle.selected = !toggle.selected;

            group.forEach(function (product) {
                product.toggle(toggle.selected);
            }, this);
        }.bind(this));
    },

    getButtons: function () {
        var styles = cleverapps.styles.AdminFBProductsWindow.buttons;
        return [
            new cleverapps.UI.Button({
                type: cleverapps.styles.UI.Button.Images.editor,
                text: "confirm",
                width: styles.width,
                height: styles.height,
                onClicked: function () {
                    var gather = function (group) {
                        return group.filter(function (item) {
                            return item.selected;
                        }).map(function (item) {
                            return item.id;
                        });
                    };

                    var activeLangs = gather(this.langs);
                    var activeProducts = gather(this.productItems);
                    this.adminFBProducts.setUpdateData(activeProducts, activeLangs, this.standUpdate.selected);

                    this.close();
                }.bind(this)
            }),
            new cleverapps.UI.Button({
                type: cleverapps.styles.UI.Button.Images.editor,
                text: "cancel",
                width: styles.width,
                height: styles.height,
                onClicked: this.close.bind(this)
            })
        ];
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    ADMIN_PRODUCTS: {
        size: 20,
        color: cleverapps.styles.COLORS.BLACK
    },

    ADMIN_PRODUCTS_ID: {
        size: 14,
        color: cleverapps.styles.COLORS.BLACK
    }
});

cleverapps.styles.AdminFBProductsWindow = {
    contentMargin: 45,
    contentOffsetY: 100,

    bg: {
        padding: {
            x: 60,
            y: 100
        }
    },

    toggle: {
        x: { align: "center" },
        y: { align: "top", anchor: false, dy: 15 }
    },

    products: {
        columnsMargin: 20,
        height: 650,
        width: 600,
        framePadding: 10,

        product: {
            width: 285,
            height: 40,
            titleWidth: 140,
            idWidth: 100,
            margin: 5,
            x: { align: "left" },
            y: { align: "center" },

            img: {
                width: 36,
                height: 36
            }
        }
    },

    langs: {

        lang: {
            width: 100,
            height: 30
        }
    },

    buttons: {
        width: 150,
        height: 70
    }
};