/**
 * Created by Denis Kuzin on 17 may 2024
 */

var GoalCoefComponent = function (cell) {
    this.cell = cell;
    this.coef = 0;

    this.onBeforeIncCoef = function () {};
    this.onBeforeResetCoef = function () {};
};

GoalCoefComponent.prototype.getGoalId = function () {
    var colorComponent = this.cell.findComponent(ColorComponent);
    return colorComponent && colorComponent.color;
};

GoalCoefComponent.prototype.smile = function () {
    var markComponent = this.cell.findComponent(MarkComponent);
    if (markComponent && Math.random() < 0.5) {
        markComponent.smile();
        return;
    }
    this.cell.animate(BaseCell.ANIMATION_SMILE);
};

GoalCoefComponent.prototype.resetCoef = function () {
    if (!this.cell.alive) {
        return;
    }

    if (this.coef === 0) {
        return;
    }

    if (this.frozenForBurn) {
        return;
    }

    if (!this.needResetCoef) {
        return;
    }

    var CHANGE_INTERVAL = 0.2;

    this.onBeforeResetCoef(CHANGE_INTERVAL);
    this.coef = 0;
    this.needResetCoef = false;

    Game.currentGame.counter.setTimeout(function () {}, CHANGE_INTERVAL * 1000);
};

GoalCoefComponent.prototype.incCoef = function (inc) {
    if (!Match3Rules.Plus || !this.cell.alive || this.frozenForBurn) {
        return;
    }

    if (this.needResetCoef) {
        this.coef = 0;
        this.needResetCoef = false;
    }

    if (this.coef === 9) {
        return;
    }

    var CHANGE_INTERVAL = 0.2;

    this.onBeforeIncCoef(CHANGE_INTERVAL);
    this.coef += inc;
    if (this.coef > 9) {
        this.coef = 9;
    }

    Game.currentGame.counter.setTimeout(function () {}, CHANGE_INTERVAL * 1000);
};

GoalCoefComponent.prototype.canDelete = function () {
    var colorComponent = this.cell.findComponent(ColorComponent);
    return this.cell.constructor === BaseCell && colorComponent && !this.cell.toDelete;
};

GoalCoefComponent.prototype.prepareForBurn = function () {
    this.frozenForBurn = true;
};

GoalCoefComponent.prototype.prepareResetCoef = function () {
    if (!(Game.currentGame.field.floor[this.cell.y][this.cell.x] instanceof GrassTile)) {
        this.needResetCoef = true;
    }
};

GoalCoefComponent.prototype.boom = function (coef) {
    if (!this.cell.alive) {
        return;
    }

    if (this.frozenForBurn) {
        return;
    }

    this.incCoef(coef);
    this.cell.animate(BaseCell.ANIMATION_BOOM_NEIGHBOUR);
};

GoalCoefComponent.prototype.getViewClass = function () {
    var colorComponent = this.cell.findComponent(ColorComponent);
    if (!colorComponent) {
        throw "color component not found";
    }

    if (colorComponent.color === "a") {
        // eslint-disable-next-line camelcase
        return GoalColorCellView_A;
    }
    if (colorComponent.color === "b") {
        // eslint-disable-next-line camelcase
        return GoalColorCellView_B;
    }
    if (colorComponent.color === "c") {
        // eslint-disable-next-line camelcase
        return GoalColorCellView_C;
    }
    if (colorComponent.color === "d") {
        // eslint-disable-next-line camelcase
        return GoalColorCellView_D;
    }
    if (colorComponent.color === "e") {
        // eslint-disable-next-line camelcase
        return GoalColorCellView_E;
    }
    if (colorComponent.color === "w") {
        // eslint-disable-next-line camelcase
        return GoalColorCellView_W;
    }

    throw "unknown color: " + colorComponent.color;
};

GoalCoefComponent.prototype.explode = function (params) {
    var colorComponent = this.cell.findComponent(ColorComponent);
    if (!colorComponent) {
        return;
    }

    var eater = EaterComponent.find(params, colorComponent.color); // should process corresponding eaters firstly
    if (eater) {
        this.cell.simpleExplode(params);
        return;
    }

    var amount = (this.coef || 0) + 1;
    cleverapps.eventBus.trigger("taskEvent", DailyTasks.COLLECT_COLOR_CELLS, amount, { color: colorComponent.color.toLowerCase() });

    this.cell.explodeViaCollect(params);
};

GoalCoefComponent.prototype.load = function (data) {
    var colorComponent = this.cell.findComponent(ColorComponent);
    if (colorComponent) {
        var color = data.shift();
        colorComponent.setColor(color.toLowerCase());
    }

    var markComponent = this.cell.findComponent(MarkComponent);

    if (data.length > 0) {
        var values = data.join("").split(" ");
        if (values[0] !== "") {
            if (markComponent) {
                markComponent.load(data);
            }
        }
        if (values[1]) {
            this.coef = parseInt(values[1]);
        }
    }

    if (markComponent) {
        markComponent.addMissionMark();
    }
};

GoalCoefComponent.prototype.save = function () {
    var data = [];

    var colorComponent = this.cell.findComponent(ColorComponent);
    if (colorComponent) {
        data.push(colorComponent.color.toUpperCase());
    }

    var markComponent = this.cell.findComponent(MarkComponent);
    if (markComponent) {
        var markCode = markComponent.save();
        if (markCode) {
            data.push(markCode);
        }
    }

    if (this.coef > 0) {
        var coef = " " + this.coef;
        data = data.concat(coef.split(""));
    }

    return data;
};