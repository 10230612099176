/**
 * Created by Andrey Popov on 20.04.2021
 */

var AdsSound = cc.Node.extend({
    ctor: function (properties, eventBus) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);

        properties = properties || {};

        if (properties.isPreview) {
            var icon = cleverapps.UI.createSprite(bundles.editor_controls.frames.sound_icon);
            this.setContentSize(cleverapps.styles.AdsSound);
            icon.setPositionRound(this.width / 2, this.height / 2);
            this.addChild(icon);
        }

        var playSound = function () {
            var soundUrl;
            if (properties.sound.name) {
                soundUrl = cleverapps.wysiwyg.googleDriveProvider.getSoundLink(properties.sound);
            }
            if (properties.isDisk === "true") {
                cleverapps.audio.insertDisk(soundUrl);
            } else {
                this.audio = cleverapps.audio.playSound(soundUrl, {
                    loop: properties.loop === "true"
                });
            }
        }.bind(this);

        if (properties.sound && !properties.isPreview) {
            if (properties.delay) {
                playSound();
            } else if (properties.startOnEvent && properties.startOnEvent !== Wysiwyg.NOT_SELECTED) {
                eventBus.on(properties.startOnEvent, playSound, this);
            }

            if (properties.stopOnEvent && properties.stopOnEvent !== Wysiwyg.NOT_SELECTED) {
                eventBus.on(properties.stopOnEvent, function () {
                    if (properties.isDisk === "true") {
                        if (properties.fadeOut === "true") {
                            cleverapps.audio.fadeOut(2);
                        } else {
                            cleverapps.audio.eject();
                        }
                    } else {
                        cleverapps.audio.stopSound(this.audio);
                    }
                }.bind(this), this);
            }
        }

        var provider = cleverapps.wysiwyg.googleDriveProvider;

        if (properties.music) {
            if (properties.music === SoundPropertyEditor.WITHOUT_MUSIC || properties.music.name === SoundPropertyEditor.WITHOUT_MUSIC) {
                GameSceneBase.prototype.getAudioStyles = function () {
                    return {
                        res: undefined
                    };
                };
            } else {
                Object.keys(bundles).filter(function (bundleName) {
                    return bundles[bundleName].urls.background_music_game;
                }).forEach(function (bundleName) {
                    if (provider.getSoundLink(properties.music)) {
                        bundles[bundleName].urls.background_music_game = provider.getSoundLink(properties.music);
                    }
                });
                cleverapps.styles.GameScene.STYLING.regular.forEach(function (style) {
                    style.music = bundles.game.urls.background_music_game;
                });
            }
        }

        if (properties.mergeSound) {
            Object.keys(bundles.merge.urls).filter(function (soundName) {
                return soundName.startsWith("merge_effect_");
            }).forEach(function (soundName) {
                bundles.merge.urls[soundName] = provider.getSoundLink(properties.mergeSound);
            });
        }
        if (properties.spawnSound) {
            bundles.merge.urls.spawn_start_effect = provider.getSoundLink(properties.spawnSound);
        }
        if (properties.collectionSound) {
            bundles.game.urls.collection_effect = provider.getSoundLink(properties.collectionSound);
        }
        if (properties.tileSound) {
            bundles.game.urls.play_tile_effect = provider.getSoundLink(properties.tileSound);
        }
        if (properties.blackoutSound) {
            bundles.game.urls.blackout_effect = provider.getSoundLink(properties.blackoutSound);
        }
    }
});

cleverapps.styles.AdsSound = {
    width: 68,
    height: 60
};

AdsSound.PROPERTIES = [{
    name: "sound", type: "sound", folders: ["background_music", "sounds"], bubbleUp: true
}, {
    name: "startOnEvent", type: "string", items: [Wysiwyg.NOT_SELECTED].concat(Object.values(Wysiwyg.EVENTS)), value: Wysiwyg.NOT_SELECTED
}, {
    name: "stopOnEvent", type: "string", items: [Wysiwyg.NOT_SELECTED].concat(Object.values(Wysiwyg.EVENTS)), value: Wysiwyg.NOT_SELECTED
}, {
    name: "fadeOut", type: "string", items: ["true", "false"], value: "false"
}, {
    name: "isDisk", type: "string", items: ["true", "false"], value: "false"
}, {
    name: "loop", type: "string", items: ["true", "false"], value: "false"
}, {
    name: "music", type: "sound", folders: ["background_music", "sounds"]
}];

if (cleverapps.config.type === "merge") {
    AdsSound.PROPERTIES = AdsSound.PROPERTIES.concat([{
        name: "mergeSound", type: "sound", folders: ["sounds"]
    }, {
        name: "spawnSound", type: "sound", folders: ["sounds"]
    }]);
}

if (cleverapps.config.type === "tile3") {
    AdsSound.PROPERTIES = AdsSound.PROPERTIES.concat([{
        name: "collectionSound", type: "sound", folders: ["sounds"]
    }, {
        name: "tileSound", type: "sound", folders: ["sounds"]
    }, {
        name: "blackoutSound", type: "sound", folders: ["sounds"]
    }]);
}