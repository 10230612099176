/**
 * Created by slava on 02.02.17.
 */

var BaseCellView = cc.Node.extend({
    ctor: function () {
        this._super();
    },

    initialize: function (cell) {
        this.cell = cell;

        if (cell.y < 0 && cell.x >= 0) {
            this.opacity = 0;
            this.visible = false;
        }

        this.inShakeNow = false;

        this.initializeZOrder();

        if (cell.x !== undefined && cell.y !== undefined) {
            this.setPosition(BaseCellView.alignInTheGrid(cell.x, cell.y));
        }

        if (cleverapps.config.debugMode) {
            cell.onDebugListener = function () {
                console.log(this.cell);
                var tile = Game.currentGame.field.floor[this.cell.y][this.cell.x];
                tile && console.log(tile);
            }.bind(this);
        }

        cell.onHoverListener = this.showHover.bind(this);

        cell.onAnimationListener = this.showAnimation.bind(this);

        cell.onBeforeMoveListener = this.startMoving.bind(this);
        cell.onFinishMoveListener = this.finishMoving.bind(this);
        cell.onWrongMoveListener = this.wrongMove.bind(this);
        cell.onBeforeExplodeListener = this.startExploding.bind(this);
        cell.onDeleteListener = this.delete.bind(this);
        cell.onAppear = this.changeZOrder.bind(this);
        cell.onFinishAppear = this.initializeZOrder.bind(this);
        cell.onBeforeBoosterExecute = this.beforeBoosterExecute.bind(this);
        cell.onAfterBoosterExecute = this.afterBoosterExecute.bind(this);
        cell.onAvailableMoveListener = this.animateAvailableMove.bind(this);
        cell.onDepthChanged = this.onDepthChanged.bind(this);
        cell.onGetView = function () {
            return this;
        }.bind(this);

        cell.onVisibleChanged = function (visible) {
            this.setVisible(visible);
        }.bind(this);
        this.setVisible(cell.visible);

        this.setOpacity(255);

        this.cell.components.forEach(function (component) {
            ViewFactory(component, this);
        }, this);
    },

    beforeBoosterExecute: function () {
        this.setLocalZOrder(100);
    },

    afterBoosterExecute: function () {
        this.setLocalZOrder(this.baseZOrder);
    },

    delete: function () {
        if (this.cell && this.cell.putInPoolAvailable && !cleverapps.gameModes.putInPoolDisabled) {
            cc.pool.putInPool(this);
        } else {
            this.removeFromParent();
        }
    },

    removeMissionMarkView: function () {
        var markComponent = this.cell.findComponent(MarkComponent);
        if (markComponent) {
            markComponent.view.removeMissionMark();
        }
    },

    changeZOrder: function () {
        this.setLocalZOrder(-this.cell.getTypeId() + 1000);
    },

    onDepthChanged: function () {
        this.initializeZOrder();
    },

    initializeZOrder: function () {
        if (this.cell.x >= 0) {
            if (this.cell.movable) {
                this.setLocalZOrder(-this.cell.getTypeId() - BaseCellView.ZORDER_GAP_FOR_ONE_LAYER * this.cell.depth);
            } else {
                this.setLocalZOrder(-this.cell.getTypeId() - BaseCellView.ZORDER_GAP_FOR_ONE_LAYER * this.cell.depth - BaseCellView.ZORDER_GAP_FOR_NON_MOVABLE_CELL);
            }
        }
        this.baseZOrder = this.getLocalZOrder();
    },

    beforeShowUpAnimation: function () {
        if (!Game.currentGame.started) {
            this.setScale(0);
        }
    },

    showUp: function (params) {
        if (params.silent) {
            this.setScale(1);
            this.setVisible(true);
            return;
        }

        if (["heroes"].indexOf(cleverapps.config.name) !== -1) {
            this.setScale(0);

            this.rotation = -480;
            this.runAction(
                new cc.Spawn(
                    new cc.RotateBy(0.4, 480),
                    new cc.ScaleTo(0.4, 1, 1)
                )
            );
        } else {
            this.setScale(0.5);
            this.setVisible(false);

            this.runAction(new cc.Sequence(
                new cc.DelayTime(0.1 + (this.cell.y * 1.2 + this.cell.x) * 0.4 / Field.SIZE),
                new cc.Show(),
                new cc.ScaleTo(0.4, 1, 1).easing(cc.easeBackOut())
            ));
        }
    },

    hide: function () {
        this.runAction(new cc.Sequence(
            new cc.DelayTime(Math.random() * 1.2 + ((Field.SIZE_Y - 1 - this.cell.y) * 1.2 + this.cell.x) * 0.4 / Field.SIZE),
            new cc.ScaleTo(0.4, 0.5, 0.5).easing(cc.easeBackIn()),
            new cc.Hide()
        )).setFinalize(function () {
            this.setVisible(false);
        }.bind(this));
    },

    startMoving: function (destination, options) {
        BaseCellViewStartMoving.call(this, destination, options);
    },

    finishMoving: function (destination, options, onFinishCallback) {
        var pxDestination = BaseCellView.alignInTheGrid(destination.x, destination.y);
        this.stopAllActions();
        this.setPosition(pxDestination);
        if (options && options.scale !== undefined) {
            this.scale = options.scale;
        } else {
            this.scale = 1;
        }
        this.setRotation(0);
        this.setLocalZOrder(this.baseZOrder);

        var goalCoefComponent = this.cell.findComponent(GoalCoefComponent);
        var coefView = goalCoefComponent && goalCoefComponent.view;
        if (coefView) {
            coefView.opacity = 255;
        }

        if (onFinishCallback && typeof onFinishCallback === "function") {
            onFinishCallback();
        }
    },

    wrongMove: function (destination) {
        var t = 0.1;
        var move = cleverapps.styles.BaseCellView.wrongMove.move;

        var sign = function (value) {
            if (value > 0) {
                return 1;
            }
            return value < 0 ? -1 : 0;
        };
        var moveX = move * sign(destination.x - this.cell.x);
        var moveY = move * sign(this.cell.y - destination.y);

        var scaleX = 0.9, scaleY = 1.1;
        if (this.cell.x === destination.x) {
            scaleX = 1.1;
            scaleY = 0.9;
        }

        Game.currentGame.counter.setTimeout(function () {}, 100);
        this.runAction(new cc.Spawn(
            new cc.Sequence(
                new cc.Spawn(
                    new cc.ScaleTo(t, scaleX, scaleY),
                    new cc.MoveBy(t, cc.p(moveX, moveY))
                ),
                new cc.Spawn(
                    new cc.ScaleTo(t, 1, 1),
                    new cc.MoveBy(t, cc.p(-moveX, -moveY))
                )
            ),
            new cc.CallFunc(this.squeeze.bind(this, 1))
        ));
    },

    showHover: function (state) {
        if (this.cell.findComponent([BigComponent, SpecialShapeComponent])) {
            BigComponentView.showHover.call(this, state);
            return;
        }

        if (state) {
            var styles = cleverapps.styles.BaseCellView;
            if (this.cell.parentCell) { // innerCell case
                this.runAction(new cc.Sequence(
                    new cc.DelayTime(0.1),
                    new cc.MoveBy(0.2, 0, styles.hover.y).easing(cc.easeIn(0.5))
                ));
            } else {
                this.shine = new cc.Node();
                this.addChild(this.shine);
                this.shine.runAction(new cc.Sequence(
                    new cc.DelayTime(0.1),
                    new cc.CallFunc(function () {
                        this.shine.shine = ShineView.factory();
                        this.shine.addChild(this.shine.shine);

                        this.runAction(new cc.MoveBy(0.2, 0, styles.hover.y).easing(cc.easeIn(0.5)));
                    }.bind(this))
                ));
            }
        } else {
            this.stopAllActions();
            this.runAction(new cc.MoveTo(0.2, BaseCellView.alignInTheGrid(this.cell.x, this.cell.y)).easing(cc.easeIn(0.5)));

            if (this.shine) {
                var shine = this.shine;
                delete this.shine;

                shine.stopAllActions();
                shine.runAction(
                    new cc.Sequence(
                        new cc.DelayTime(0.1),
                        new cc.CallFunc(function () {
                            if (shine.shine) {
                                cc.pool.putInPool(shine.shine);
                            }
                            shine.removeFromParent();
                        })
                    )
                );
            }
            this.initializeZOrder();
        }
    },

    refuse: function () {
        var component = this.cell.findComponent([EaterComponent, BigComponent]);
        if (component) {
            component.view.refuse();
            return;
        }

        if (this.getRotation() !== 0) {
            return;
        }
        var NOD_DURATION = 0.05;
        var ANGLE = 15;
        this.runAction(new cc.Sequence(
            (new cc.RotateBy(NOD_DURATION, -ANGLE)).easing(cc.easeIn(1)),
            (new cc.RotateBy(2 * NOD_DURATION, 2 * ANGLE)).easing(cc.easeIn(1)),
            (new cc.RotateTo(NOD_DURATION, 0)).easing(cc.easeIn(1))
        ));
    },

    showAnimation: function (type, params) {
        switch (type) {
            case BaseCell.ANIMATION_SHOWUP:
                this.showUp(params);
                break;
            case BaseCell.ANIMATION_HIDE:
                this.hide();
                break;
            case BaseCell.ANIMATION_MISTAKE:
                this.refuse();
                break;
            case BaseCell.ANIMATION_DOWN:
                this.squeeze(1);
                break;
            case BaseCell.ANIMATION_UP:
                this.squeeze(-1);
                break;
            case BaseCell.ANIMATION_RIGHT:
                this.squeezeLeftRight(1);
                break;
            case BaseCell.ANIMATION_LEFT:
                this.squeezeLeftRight(-1);
                break;
            case BaseCell.ANIMATION_HURT:
                this.hurtAnimation();
                break;
            case BaseCell.ANIMATION_MULTI_COLOR_SELECT:
                this.multiColorSelectAnimation(params);
                break;
            case BaseCell.ANIMATION_CREATE:
                this.createAnimation(params);
                break;
            case BaseCell.ANIMATION_SMILE:
                this.smile();
                break;
            case BaseCell.ANIMATION_GOTO_POSITION:
                this.gotoPositionAnimation();
                break;
            case BaseCell.ANIMATION_CREATE_FROM_CELL:
                this.createFromCellAnimation(params);
                break;
            case BaseCell.ANIMATION_CREATE_FROM_CENTER:
                this.createFromCenterAnimation(params);
                break;
            case BaseCell.ANIMATION_OPEN:
                this.openAnimation();
                break;
            case BaseCell.ANIMATION_ONCLICK:
                this.onclick();
                break;
            case BaseCell.ANIMATION_COOKIE_UP:
                this.cookieUp(params);
                break;
            case BaseCell.ANIMATION_COOKIE_IN:
                this.cookieIn(params);
                break;
            case BaseCell.ANIMATION_COOKIE_OUT:
                this.cookieOut(params);
                break;
            case BaseCell.ANIMATION_SELECT:
                this.selectAnimation();
                break;
            case BaseCell.ANIMATION_BOOM_NEIGHBOUR:
                this.shakeAnimation();
                break;
            case BaseCell.ANIMATION_TRANSPORTER_LOOP_START:
                this.transporterLoopStart();
                break;
            case BaseCell.ANIMATION_TRANSPORTER_LOOP_FINISH:
                this.transporterLoopFinish();
                break;
            case BaseCell.ANIMATION_ICE_MAKER_MOVE:
                this.moveAnimation(params);
                break;
            case BaseCell.ANIMATION_MOVE_TO:
                this.moveToAnimation(params);
                break;
            case BaseCell.ANIMATION_CREATE_FROM_BOOSTER_BEFORE:
                this.createFromBoosterBeforeAnimation(params);
                break;
            case BaseCell.ANIMATION_CREATE_FROM_LANTERN:
                this.createFromLanternAnimation(params);
                break;
        }
    },

    transporterLoopStart: function () {
        this.runAction(new cc.ScaleTo(0.2, 0));
    },

    transporterLoopFinish: function () {
        this.runAction(new cc.ScaleTo(0.2, 1));
    },

    shakeAnimation: function () {
        if (this.cell.findComponent([BigComponent, SpecialShapeComponent])) {
            return;
        }

        if (this.inShakeNow || this.getScaleX() !== 1 || this.getScaleY() !== 1) {
            return;
        }
        this.inShakeNow = true;
        this.runAction(new cc.Sequence(
            new cc.ScaleTo(0.2, 0.95),
            new cc.ScaleTo(0.1, 1),
            new cc.CallFunc(function () {
                this.inShakeNow = false;
            }.bind(this))
        ));
    },

    moveToAnimation: function (params) {
        this.stopAllActions();

        if (params.target.x === this.cell.x && params.target.y === this.cell.y) {
            this.setLocalZOrder(100);
        }

        var targetPosition = BaseCellView.alignInTheGrid(params.target.x, params.target.y);

        this.runAction(new cc.MoveTo(params.duration, targetPosition).easing(cc.easeIn(2)));
    },

    createFromAnimation: function (beginPosition, delay, scale, duration, callback) {
        this.setScale(scale);
        this.setOpacity(255);
        this.setVisible(false);
        this.setPosition(beginPosition);
        this.setLocalZOrder(3);
        this.stopAllActions();

        var styles = cleverapps.styles.BaseCellView;

        var targetPosition = BaseCellView.alignInTheGrid(this.cell.x, this.cell.y);
        var bezierSecondPoint = cc.p(targetPosition.x, targetPosition.y + styles.bezier.y);
        var bezierFirstPoint = cc.p(beginPosition.x, beginPosition.y + styles.bezier.y);

        this.runAction(new cc.Sequence(
            new cc.DelayTime(delay),
            new cc.CallFunc(function () {
                this.setVisible(true);
            }.bind(this)),
            new cc.Spawn(
                new cc.ScaleTo(duration, 1),
                new cc.BezierTo(duration, [bezierFirstPoint, bezierSecondPoint, targetPosition]).easing(cc.easeIn(1))
            ),
            new cc.CallFunc(function () {
                this.setLocalZOrder(1);
                if (callback) {
                    callback();
                }
            }.bind(this))
        ));
    },

    gotoPositionAnimation: function () {
        var targetPosition = BaseCellView.alignInTheGrid(this.cell.x, this.cell.y);
        this.setLocalZOrder(3);

        var blastAnimation = BaseAnimation.factoryLimited(bundles.heroes.jsons.C_hero_spine_effect_json);
        blastAnimation.setLocalZOrder(100);
        blastAnimation.setPosition(this.width / 2, this.height / 2);
        this.addChild(blastAnimation);

        blastAnimation.runAnimate("animation", function () {
            blastAnimation.runAction(new cc.RemoveSelf());
        });

        if (this.gotoAction) {
            this.stopAction(this.gotoAction);
        }

        this.gotoAction = this.runAction(new cc.Sequence(
            new cc.MoveTo(0.5, targetPosition),
            new cc.CallFunc(function () {
                this.setLocalZOrder(this.baseZOrder);
                this.gotoAction = undefined;
            }.bind(this))
        ));
    },

    createFromCenterAnimation: function (options) {
        options = options || {};
        var delay = 0;
        var beginPosition = { x: cleverapps.styles.BaseCellView.CELL_SIZE_PX * Field.SIZE_X / 2, y: cleverapps.styles.BaseCellView.CELL_SIZE_PX * Field.SIZE_Y / 2 };
        this.createFromAnimation(beginPosition, delay, options.scale || 3, BaseCellView.CREATE_FROM_CENTER_DURATION / 1000 - delay, function () {
            cleverapps.audio.playSound(bundles.game.urls.create_multicolor_effect);
        });
    },

    createFromLanternAnimation: function (options) {
        options = options || {};

        var styles = cleverapps.styles.BaseCellView;

        var lanternPosition = {
            x: styles.CELL_SIZE_PX * Field.SIZE_X / 2 + styles.lantern.dx,
            y: styles.CELL_SIZE_PX * Field.SIZE_Y / 2 + styles.lantern.dy
        };
        this.createFromAnimation(lanternPosition, 0, options.scale || 1, 0.5, function () {
            cleverapps.audio.playSound(bundles.game.urls.create_multicolor_effect);
            BoostersBeforeHelper.animateStars(this);
        }.bind(this));
    },

    createFromBoosterBeforeAnimation: function () {
        var position = BaseCellView.alignInTheGrid(this.cell.x, this.cell.y);

        this.setOpacity(50);
        this.setVisible(true);
        this.setPosition(position);
        this.setLocalZOrder(3);
        this.stopAllActions();

        this.setScale(2.5);
        this.runAction(new cc.Sequence(
            new cc.Spawn(
                new cc.ScaleTo(0.5, 1).easing(cc.easeBackOut()),
                new cc.FadeIn(0.5)
            ),
            new cc.CallFunc(function () {
                cleverapps.audio.playSound(bundles.game.urls.create_multicolor_effect);
            }),
            new cc.DelayTime(0.3),
            new cc.CallFunc(function () {
                this.setLocalZOrder(this.baseZOrder);
            }.bind(this))
        ));

        BoostersBeforeHelper.animateStars(this);
    },

    createFromCellAnimation: function (from) {
        var delay = 0.4;
        if (from.delay !== undefined) {
            delay = from.delay;
        }
        this.createFromAnimation(BaseCellView.alignInTheGrid(from.x, from.y), delay, 0.5, 0.5);
    },

    openAnimation: function () {
    },

    hurtAnimation: function () {
        var component = this.cell.findComponent(EaterComponent);
        if (component) {
            component.view.hurtAnimation();
        }
    },

    squeeze: function (upOrDown) {
        var SQUEEZE_DURATION = 0.1;

        this.runAction(new cc.Sequence(
            new cc.Spawn(
                (new cc.ScaleTo(SQUEEZE_DURATION, 1.1, 0.9)).easing(cc.easeIn(0.5)),
                (new cc.MoveBy(SQUEEZE_DURATION, cc.p(0, upOrDown * cleverapps.styles.BaseCellView.squeeze))).easing(cc.easeIn(0.5))
            ),

            new cc.Spawn(
                (new cc.ScaleTo(SQUEEZE_DURATION, 1, 1)).easing(cc.easeIn(0.5)),
                (new cc.MoveBy(SQUEEZE_DURATION, cc.p(0, -upOrDown * cleverapps.styles.BaseCellView.squeeze))).easing(cc.easeIn(0.5))
            )
        ));
    },

    squeezeLeftRight: function (leftOrRight) {
        var SQUEEZE_DURATION = 0.1;
        this.runAction(new cc.Sequence(
            new cc.Spawn(
                (new cc.ScaleTo(SQUEEZE_DURATION, 0.9, 1.1)).easing(cc.easeIn(0.5)),
                (new cc.MoveBy(SQUEEZE_DURATION, cc.p(leftOrRight * cleverapps.styles.BaseCellView.squeeze, 0))).easing(cc.easeIn(0.5))
            ),

            new cc.Spawn(
                (new cc.ScaleTo(SQUEEZE_DURATION, 1, 1)).easing(cc.easeIn(0.5)),
                (new cc.MoveBy(SQUEEZE_DURATION, cc.p(-leftOrRight * cleverapps.styles.BaseCellView.squeeze, 0))).easing(cc.easeIn(0.5))
            )
        ));
    },

    getBoomColor: function () {
        var muffinComponent = this.cell && this.cell.findComponent(MuffinComponent);
        if (muffinComponent) {
            return muffinComponent.view.getBoomColor();
        }

        var colorComponent = this.cell && this.cell.findComponent(ColorComponent);
        return colorComponent && colorComponent.color && cleverapps.styles.COLORS.CELL_COLORS[colorComponent.color.toLowerCase()];
    },

    boomAnimation: function (duration) {
        if (cleverapps.config.wysiwygMode && BaseCellView.__boomAnimation) {
            BaseCellView.__boomAnimation(this, duration);
            return;
        }

        var color = this.getBoomColor();
        if (!color) {
            return;
        }

        var boom = ExplosionView.factory();
        if (!boom) {
            return;
        }

        boom.setPosition(this.getPosition());

        if (!boom.parent) {
            this.parent.addChild(boom);
        }

        boom.animate(color, duration);

        cleverapps.audio.playSound(bundles.game.urls.boom_effect);

        return boom;
    },

    startExploding: function (duration, delta) {
        var exploded = false;

        [DecoratorComponent, EaterComponent, LiveLineComponent].map(function (Component) {
            return this.cell.findComponent(Component);
        }.bind(this)).forEach(function (component) {
            if (component && component.view.startExploding(duration, delta)) {
                exploded = true;
            }
        });

        if (exploded) {
            return;
        }

        this.removeMissionMarkView();

        if (this.finishSmile) {
            this.finishSmile();
        }

        this.setLocalZOrder(BaseAnimation.ZORDER + 1);
        this.stopAllActions();
        this.runAction(new cc.Sequence(
            new cc.ScaleTo(0.3, 1.5),
            new cc.CallFunc(function () {
                this.boomAnimation(duration);
                this.delete();
            }.bind(this))
        ));
    },

    startExplodingViaCollect: function (goal, delta) {
        this.removeMissionMarkView();

        if (this.finishSmile) {
            this.finishSmile();
        }

        if (!goal.getView()) {
            if (cleverapps.gameModes.silentGoals) {
                this.delete();
                return;
            }

            this.startExploding();
            return;
        }

        cleverapps.audio.playSound(bundles.game.urls.cell_collect_effect, {
            throttle: 200
        });

        this.runAction(
            new cc.Sequence(
                new cc.DelayTime(Goals.WAIT_DURATION),
                new cc.MoveBy(Goals.UP_DURATION, 0, 30),
                new cc.CallFunc(function () {
                    goal.collect(delta, this, {
                        callback: function () {
                            this.delete();
                        }.bind(this)
                    });
                }.bind(this))
            )

        );
    },

    reuse: function (cell) {
        this.scale = 1;
        this.initialize(cell);
    },

    unuse: function () {
        for (var i = 0; i < this.cell.components.length; i++) {
            var component = this.cell.components[i];
            if (component.view) {
                if (component.view.onDestruction) {
                    component.view.onDestruction();
                }

                component.view.removeFromParent();
                delete component.view;
            }
        }

        this.removeFromParent(false);

        this.cell.onAnimationListener = function () {
        };
        this.cell.onHoverListener = function () {
        };

        this.cell.onBeforeMoveListener = function () {
        };
        this.cell.onFinishMoveListener = function () {
        };
        this.cell.onWrongMoveListener = function () {
        };
        this.cell.onBeforeExplodeListener = function () {
        };
        this.cell.onGetView = function () {};
        this.cell.onDeleteListener = function () {
        };

        this.cell.onAvailableMoveListener = function () {
        };

        this.cell.onDebugListener = function () {
        };

        this.cell.onVisibleChanged = function () {
        };

        delete this.gotoAction;
        delete this.cell;
    },

    alignInTheGrid: function (x, y) {
        return BaseCellView.alignInTheGrid(x, y);
    },

    multiColorSelectAnimation: function () {
        if (this.multicolorSelectAnimation) {
            return;
        }

        var animation = ColorFulAnimationView.factory();
        if (!animation) {
            return;
        }
        this.multicolorSelectAnimation = true;

        animation.setPosition(this.getPosition());
        this.getParent().addChild(animation);

        animation.animate(function () {
            this.multicolorSelectAnimation = false;
        }.bind(this));
    },

    createAnimation: function () {
    },

    cookieUp: function () {
    },

    cookieIn: function () {
    },

    cookieOut: function () {
    },

    selectAnimation: function () {
        this.squeeze(0);
        cleverapps.audio.playSound(bundles.game.urls.cell_select_effect);
    },

    onGameFinish: function () {
        this.removeMissionMarkView();
    },

    startExplodingViaMouse: function (mouse, goal, idInGroup) {
        this.removeMissionMarkView();

        if (this.finishSmile) {
            this.finishSmile();
        }

        var target = mouse.findComponent(EaterComponent).view;

        this.stopAllActions();

        target.collect(this, goal, idInGroup, function () {
            this.delete();
        }.bind(this));
    },

    animateAvailableMove: function (dir, move) {
        cleverapps.styles.AvailableMoveView.animation.call(this, dir, move);
    }
});

BaseCellView.alignInTheGrid = function (x, y) {
    return cc.p((x + 0.5) * cleverapps.styles.BaseCellView.CELL_SIZE_PX, cleverapps.styles.BaseCellView.CELL_SIZE_PX * (Field.SIZE_Y - y - 0.5));
};

BaseCellViewStartMoving = function (destination, options) {
    if (this.finishSmile) {
        this.finishSmile();
    }

    var pxDestination;
    if (options && options.alignInCenter) {
        pxDestination = cc.p(this.parent.width / 2, this.parent.height / 2);
    } else {
        pxDestination = BaseCellView.alignInTheGrid(destination.x, destination.y);
    }
    this.stopAllActions();
    if (options && options.scale !== undefined) {
        this.scale = options.scale;
    } else {
        this.scale = 1;
    }
    this.opacity = 255;
    this.visible = true;
    this.setRotation(0);

    if (Match3Rules.SlideFallDown) {
        this.runAction(new cc.MoveTo(options.moveInterval, pxDestination));
    } else {
        this.runAction(new cc.MoveTo(options.moveInterval, pxDestination).easing(cc.easeIn(1.7)));
    }
};

BaseCellView.CREATE_FROM_CENTER_DURATION = 500;

BaseCellView.ZORDER_GAP_FOR_ONE_LAYER = 2000;
BaseCellView.ZORDER_GAP_FOR_NON_MOVABLE_CELL = 1000;

cleverapps.styles.BaseCellView = {
    squeeze: 10,
    wrongMoveAnimationName: "default",

    CELL_SIZE_PX: 120,

    bezier: {
        y: 200
    },

    wrongMove: {
        move: 30
    },

    hover: {
        y: 10
    },

    lantern: {
        dx: 0,
        dy: 0
    }
};
