/**
 * Created by Andrey Popov on 12.03.24
 */

var FakeSideBarIcon = function (parentIcon) {
    this.parentIcon = parentIcon;

    SideBarIcon.call(this);

    this.available = true;
    this.isFake = true;
};

FakeSideBarIcon.prototype = Object.create(SideBarIcon.prototype);
FakeSideBarIcon.prototype.constructor = FakeSideBarIcon;

FakeSideBarIcon.prototype.resetState = function () {
    this.available = this.parentIcon.available;
};
