/**
 * Created by andrey on 21.06.18.
 */

var LollipopAnimation = BaseAnimation.extend({
    ctor: function (skin, bundle) {
        this._super(bundles[bundle].jsons[bundle + "_json"]);
        if (this.animation) {
            this.animation.setSkin(skin);
        }
    }
});

LollipopAnimation.factory = function (skin, bundle) {
    return cc.pool.hasObject(LollipopAnimations[skin]) ? cc.pool.getFromPool(LollipopAnimations[skin]) : new LollipopAnimations[skin](bundle);
};

var LollipopColorsMap = {
    "a": "blue",
    "b": "green",
    "c": "purple",
    "d": "red",
    "e": "yellow",
    "w": "white"
};

var LollipopAnimations = {
    "a": LollipopAnimation.extend({
        ctor: function (bundle) {
            this._super("blue", bundle); 
        } 
    }),
    "b": LollipopAnimation.extend({
        ctor: function (bundle) {
            this._super("green", bundle); 
        } 
    }),
    "c": LollipopAnimation.extend({
        ctor: function (bundle) {
            this._super("purple", bundle); 
        } 
    }),
    "d": LollipopAnimation.extend({
        ctor: function (bundle) {
            this._super("red", bundle); 
        } 
    }),
    "e": LollipopAnimation.extend({
        ctor: function (bundle) {
            this._super("yellow", bundle); 
        } 
    }),
    "w": LollipopAnimation.extend({
        ctor: function (bundle) {
            this._super("white", bundle); 
        } 
    })
};