/**
 * Created by mac on 10/17/20
 */

var AdminNavigation = {
    left: function (f) {
        AdminNavigation.nextLevel(AdminNavigation.LEFT, f);
    },

    left_double: function (f) {
        AdminNavigation.nextEpisode(AdminNavigation.LEFT, f);
    },

    right: function (f) {
        AdminNavigation.nextLevel(AdminNavigation.RIGHT, f);
    },

    right_double: function (f) {
        AdminNavigation.nextEpisode(AdminNavigation.RIGHT, f);
    },

    nextLevel: function (dir, f) {
        var level = cleverapps.administrator.adminLevels.getActive();
        if (!level) {
            f();
            return;
        }

        var next = cleverapps.administrator.adminLevels.next(dir);

        if (next !== undefined) {
            cleverapps.administrator.adminLevels.setActive(next);
            f();
        } else {
            AdminNavigation.nextEpisode(dir, f, AdminNavigation.suggestedLevelAfterScroll(dir));
        }
    },

    nextEpisode: function (dir, f, suggestedLevel) {
        var next = cleverapps.administrator.adminEpisodes.next(dir);
        if (next !== undefined) {
            cleverapps.administrator.adminEpisodes.setActive(next, f, suggestedLevel);
        } else {
            f();
        }
    },

    suggestedLevelAfterScroll: function (dir) {
        if (dir === AdminNavigation.RIGHT) {
            return AdminLevels.FIRST_LEVEL;
        }

        return AdminLevels.LAST_LEVEL;
    }
};

AdminNavigation.RIGHT = 1;
AdminNavigation.LEFT = -1;