/**
 * Created by ivan on 25.05.18.
 */

var CandleCellView = BaseCellView.extend({
    ctor: function (candleCell) {
        this._super();

        this.setContentSize(cleverapps.styles.BaseCellView.CELL_SIZE_PX, cleverapps.styles.BaseCellView.CELL_SIZE_PX);
        this.setAnchorPoint(0.5, 0.5);

        var animation = new cleverapps.Spine(bundles.candle.jsons.candle_json);
        animation.setPosition(this.width / 2, this.height / 2);
        if (candleCell.state === CandleCell.STATE_ON || candleCell.isForGoal) {
            animation.setAnimation(0, "burn", true);
        } else {
            animation.setAnimation(0, "put_out", false);
        }
        this.addChild(animation);
        this.animation = animation;

        this.initialize(candleCell);
    },

    initialize: function (candleCell) {
        this._super(candleCell);

        candleCell.onChangeStateListener = this.update.bind(this);
    },

    update: function () {
        if (this.cell.state === CandleCell.STATE_ON) {
            this.animation.setAnimation(0, "lights_up", false);
            this.animation.setCompleteListener(function () {
                this.animation.setAnimation(0, "burn", true);
            }.bind(this));
            cleverapps.audio.playSound(bundles.candle.urls.turn_on_effect);
        } else {
            this.animation.setAnimation(0, "put_out", false);
            this.animation.setCompleteListener();
            cleverapps.audio.playSound(bundles.candle.urls.turn_off_effect);
        }
    }
});