/**
 * Created by andrey on 28.03.2024.
 */

var GoogleAnalyticsLogger = function () {
    var trackingId = cleverapps.config.googleAnalytics.measurementId;

    this.sendMessage({
        type: "config",
        trackingId: trackingId
    });

    this.onUpdateUserId();
};

GoogleAnalyticsLogger.prototype.logEvent = function (name, params) {
    if (!GoogleAnalyticsLogger.EVENTS[name]) {
        return;
    }

    this.sendMessage({
        type: "event",
        name: name,
        params: params
    });
};

GoogleAnalyticsLogger.prototype.onUpdateUserId = function () {
    this.sendMessage({
        type: "set",
        params: { "user_id": connector.platform.getUserID() }
    });
};

GoogleAnalyticsLogger.prototype.logPurchase = function (product, purchase, params) {
    this.sendMessage({
        type: "event",
        name: "purchase",
        params: {
            currency: params.currency,
            value: params.price,
            transaction_id: params.transactionId,
            items: [{
                item_id: product.itemId,
                item_name: product.title
            }]
        }
    });
};

GoogleAnalyticsLogger.prototype.logAdRevenue = function (name, impressionCost) {
    this.sendMessage({
        type: "event",
        name: "ad_" + name,
        params: {
            value: impressionCost,
            currency: "USD"
        }
    });
};

GoogleAnalyticsLogger.prototype.sendMessage = function (message) {
    window.parent[GoogleAnalyticsLogger.POST_MESSAGE]({
        googleAnalytics: true,
        message: message
    }, "*");
};

GoogleAnalyticsLogger.isApplicable = function () {
    return connector.platform.oneOf(connector.CLEVERAPPS) && cleverapps.config.googleAnalytics;
};

GoogleAnalyticsLogger.EVENTS = ConversionManager.EVENTS;

GoogleAnalyticsLogger.POST_MESSAGE = "postMessage";
