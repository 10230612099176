/**
 * Created by andrey on 29.05.17.
 */
var EditorFieldSizeView = cc.Node.extend({
    ctor: function (field) {
        this._super();
        this.field = field;

        var title = cleverapps.UI.generateTTFText("Field size:", cleverapps.styles.FONTS.EDITOR_TEXT);
        this.addChild(title);

        this.size = new IncDecBlock({
            range: [3, 9],
            value: LevelManager.getInstance().level.content.field.length,

            onChange: function (value, diff) {
                this.onSetFieldSize(diff);
            }.bind(this),

            radius: cleverapps.styles.EditorFieldSizeView.radius
        });

        this.size.setPosition(0, cleverapps.styles.EditorFieldSizeView.size.y);
        this.addChild(this.size);

        this.onSetFieldSize = function () {};
        this.setupChildren();
    },

    setupChildren: function () {
        this.setPositionRound(cleverapps.styles.EditorFieldSizeView);
    }
});

cleverapps.styles.EditorFieldSizeView = {
    x: { align: "right", dx: -100 },
    y: { align: "top", dy: -220 },
    radius: 50,
    size: {
        y: -50
    }
};