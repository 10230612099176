/**
 * Created by vladislav on 11/10/2023
 */

/* eslint-disable max-len */
// eslint-disable-next-line no-unused-vars
var termsText = {
    wechat: "道泉网络科技用户协议\n"
        + "\n"
        + "欢迎您选择由广州道泉网络科技有限公司（以下简称“道泉网络科技”或“我们”）提供的游戏！\n"
        + "本《道泉网络科技用户协议》（以下简称“本协议”）由您与道泉网络科技共同缔结，本协议具有合同效力。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制道泉网络科技的条款（以下称“免责条款”）、对用户权利进行限制的条款（以下称“限制条款”）、约定争议解决方式和司法管辖的条款，以及开通或使用某项服务的单独协议。前述免责、限制及争议解决方式和管辖条款可能以黑体加粗、颜色标记或其他合理方式提示您注意，请您在确认同意本协议之前或在使用道泉网络科技游戏服务之前再次阅读前述条款。\n"
        + "请您详细阅读本协议所有条款，如您同意本协议，您可继续使用道泉网络科技游戏服务。如果您对本协议或道泉网络科技游戏服务有任何疑问或投诉、建议，可通过客服、道泉网络科技客服微信小程序（小程序版本）、道泉网络科技客服公众号（小程序版本）等方式联系我们，我们将尽快跟进处理，并在法律法规规定的期限内回复您的请求。您点击同意、接受或下一步，或您注册、使用道泉网络科技游戏服务均视为您已阅读并同意签署本协议。\n"
        + "如果您未满18周岁，请在法定监护人的陪同下阅读本协议，并在取得法定监护人同意后才能使用道泉网络科技游戏服务，同时应特别注意未成年人使用条款。如您为未成年人法定监护人，希望合理设定孩子娱乐时间，培养孩子健康游戏习惯。\n"
        + "\n"
        + "一、定义\n"
        + "如无特别说明，下列术语在本协议中的含义为：\n"
        + "1.1本协议：指本协议正文、《隐私协议》、游戏规则及其修订版本。上述内容一经正式发布，即成为本协议不可分割的组成部分。本协议同时还包括《网络游戏服务格式化协议必备条款》。\n"
        + "1.2游戏规则：指道泉网络科技不时发布并修订的关于道泉网络科技游戏的用户守则、玩家条例、游戏公告、提示及通知等内容。\n"
        + "1.3道泉网络科技：指向您提供道泉网络科技游戏及其他服务的深圳市道泉网络科技科技股份有限公司及其关联公司，在本协议中简称为“道泉网络科技”或“我们”。\n"
        + "1.4道泉网络科技游戏：指由道泉网络科技负责运营的游戏的统称，包括计算机客户端游戏、网页游戏、HTML5游戏（H5游戏）、移动终端游戏、电视端游戏以及其他形式的游戏；道泉网络科技游戏可能以软件形式提供，这种情况下，道泉网络科技游戏还包括该相关软件及相关文档。\n"
        + "1.5您：又称“ 玩家 ”或“用户”，指被授权使用道泉网络科技产品及其服务的自然人。\n"
        + "1.6游戏数据：指您在使用道泉网络科技游戏过程中产生的被服务器记录的各种数据，包括但不限于角色数据、虚拟物品数据、行为日志、购买日志等数据。\n"
        + "1.7游戏虚拟道具：指提供的以电磁记录方式存储于游戏程序运行的服务器内，可实现游戏程序设定的特定功能或体现游戏程序运行的特定结果的一种服务，其可以以文字、图形化或其他数字形式进行表现。游戏虚拟道具是游戏服务的一部分。常见的游戏虚拟道具包括但不限于：\n"
        + "（1）游戏代币及其他各种游戏币。其中游戏代币，指用户使用法定货币按一定比例直接或者间接购买的，存储于该特定游戏服务器内，并仅能用于换取该特定游戏中指定的其他虚拟道具等增值服务的一种游戏虚拟道具；\n"
        + "（2）游戏装备，如武器等；\n"
        + "（3）英雄、皮肤及其他各种游戏虚拟道具。\n"
        + "\n"
        + "二、协议的变更和生效\n"
        + "2.1 道泉网络科技有权在必要时变更本协议条款，您可以在道泉网络科技游戏的相关页面查阅最新版本的协议条款。\n"
        + "2.2 本协议条款变更后，如果您继续使用道泉网络科技游戏服务，即视为您已接受变更后的协议。如果您不接受变更后的协议，应当停止使用道泉网络科技游戏服务。\n"
        + "\n"
        + "三、游戏账号\n"
        + "3.1 使用道泉网络科技游戏服务前，您应当进行实名注册。进行实名注册时，您应提供有关您本人真实、合法、准确、有效的身份信息及其他相关信息，且不得以他人身份资料进行实名注册。同时，为了避免个人信息被滥用及防止绕过防沉迷措施，同一身份信息可认证的游戏账号数量可能受到限制，不得超过合理数量。否则，我们有权采取相应处理措施，并可公布处理结果，因此造成的一切后果由您自行承担，且我们有权要求您赔偿因此给我们造成的所有损失。\n"
        + "3.2 为满足相关法律法规政策及相关主管部门的要求，您应提供真实身份信息进行账号注册和登录使用，我们将通过实名认证系统（包括但不限于腾讯健康游戏系统）验证您的真实身份信息。为实现前述目的，您同意我们向实名认证系统核实您当前注册和登录所使用的游戏账号、微信或QQ账号的实名认证情况，若该账号已在实名认证系统完成实名认证，则您进一步同意向我们披露年龄信息，以确认是否将该账号纳入防沉迷系统以及采取何种防沉迷措施，同时您同意我们通实名认证系统将您的姓名和身份证号提交至国家统一未成年人网络游戏电子身份认证系统进行真实身份验证。\n"
        + "3.3 若您的账号未在实名认证系统完成实名认证，则我们无法为您提供服务，若您仍希望使用我们的产品和服务，则您应在实名认证系统对您当前注册和登录所使用的游戏账号、微信或QQ账号完成实名认证，实名认证过程中实名认证系统会收集您的实名身份信息（包括姓名和身份证号）。同时，为验证真实性之目的，实名认证系统可能会根据未成年人保护策略，在您对当前注册和登录所使用的游戏账号、微信或QQ账号进行实名认证之时或您使用已完成实名认证的微信或QQ账号登录游戏并使用游戏服务过程中，不时要求您进一步提供其他敏感个人信息（包括手机号码、照片或人脸识别信息等）并将其与您在道泉网络科技健康系统的实名信息记录、电信运营商处实名信息记录或公安权威数据平台数据源进行比对。若您拒绝提供前述信息或未通过比对，则您可能无法继续使用我们的产品和服务或受到限制。人脸识别相关验证数据，在加密后仅用于与公安权威数据平台数据源进行比对，我们不会对其留存。\n"
        + "3.5 在您进行账号注册或使用游戏服务时，如发现您账号可能存在涉诈异常情形或风险的，我们有权根据相关法律法规规定重新核验您的账号，并可根据风险情况，采取限期改正、限制功能、暂停使用、关闭账号、禁止重新注册以及本协议规定的其他处置措施。\n"
        + "3.6 您进一步知悉并同意，您在游客模式下可能无法进行游戏充值或消费，也无法使用跟帖评论服务（包括局内交流、群聊、聊天室等功能）。且一旦您卸载或重装道泉网络科技游戏，或您更换手机、电脑等终端设备或该等终端设备损坏的，您在该游客模式下所有游戏相关数据可能都将会被清空，且无法查询和恢复。如因此造成您任何损失的，均由您自行承担。\n"
        + "3.7 如您使用第三方账号作为游戏账号使用道泉网络科技游戏服务的，您还应遵守有关该第三方账号的协议、规则，且因该第三方账号产生的相关问题包括但不限于被盗等，您应自行联系该第三方进行解决，道泉网络科技可视情况提供相应的协助。\n"
        + "3.8 您充分理解并同意，我们会按照国家相关要求将您的实名注册信息（包括相关实名认证及年龄情况等）运用于防沉迷系统之中，即道泉网络科技会根据您的实名注册信息判断您是否年满18周岁、您提交的实名身份信息是否规范或实名验证是否通过等，从而决定是否对您的游戏账号予以防沉迷限制。\n"
        + "3.9 您充分理解并同意，道泉网络科技有权根据法律法规要求校验用户注册所提供的身份信息是否真实、有效，并应积极地采取技术与管理等合理措施保障用户账号的安全、有效；用户有义务妥善保管其账号及密码，并正确、安全地使用其账号及密码。如发现您的账号可能存在异常情形或风险的，道泉网络科技有权根据相关法律法规规定重新核验您的账号。任何一方未尽上述义务导致账号密码遗失、账号被盗等情形而给用户和他人的民事权利造成损害的，应当承担由此产生的法律责任。\n"
        + "若您发现有他人冒用或盗用您的游戏账号及密码、或任何其他未经您合法授权使用的情形时，应立即以有效方式通知道泉网络科技（可通过道泉网络科技客服与我们联系）。您通知泉网络科技时，应提供与您注册身份信息相一致的个人有效身份信息，道泉网络科技收到您的有效请求并核实身份后，会结合具体情况采取相应措施（包括但不限于暂停该账号的登录和使用等），道泉网络科技因根据您的请求采取相应措施而造成您及其他用户损失的，由您自行承担。若您没有提供有效身份信息或您提供的个人有效身份信息与所注册的身份信息不一致的，道泉网络科技有权拒绝您的请求，因此造成您损失的，由您自行承担。\n"
        + "3.10 您充分理解并同意，为高效利用服务器资源，如果您1年内未使用游戏账号登录道泉网络科技游戏，道泉网络科技有权在提前通知的情况下，对该账号及其账号下的游戏数据及相关信息采取删除等处置措施。\n"
        + "3.11 游戏账号是道泉网络科技按照本协议授权您用于登录、使用道泉网络科技游戏及相关服务的标识和凭证，其所有权属于道泉网络科技。您仅根据本协议以及道泉网络科技为此发布的专项规则享有游戏账号的使用权。您不得将游戏账号以任何方式提供给他人使用，包括但不限于不得以转让、出租、借用等方式提供给他人作包括但不限于直播、录制、代打代练等商业性使用。否则，因此产生任何法律后果及责任均由您自行承担，且道泉网络科技有权对您的游戏账号采取包括但不限于警告、限制或禁止使用游戏账号全部或部分功能、删除游戏账号及游戏数据及其他相关信息、封号直至注销的处理措施，因此造成的一切后果由您自行承担。\n"
        + "3.11 在道泉网络科技游戏提供游戏账号注销功能情形下，如您需要注销游戏账号的，可按照该游戏官方提供的账号注销指引进行操作，并应保证满足游戏官方公布的有关游戏账号注销的相关条件，同时同意游戏官方公布的游戏账号注销协议和其他有关规则。\n"
        + "同时也特别提示您， 如您注销游戏账号的，该游戏账号下的游戏虚拟道具及其他增值服务（为描述方便，以下也统称“游戏收益”）也将会被清除。据此，在您注销本游戏账号前请您确保已妥善处理该游戏账号下的游戏收益或相关的游戏收益已结清。一旦您注销本游戏账号，如届时该游戏账号下还存在游戏收益的（包括但不限于在本游戏使用期间已产生但尚未失效或未使用的游戏虚拟道具及其他游戏增值服务，及未来可能产生的游戏收益），视为您自愿放弃该等游戏收益，道泉网络科技有权对该游戏账号下的全部游戏收益做清除处理，因此产生的后果由您自行承担。前述游戏收益包括但不限于：游戏会员权益、等级；与游戏角色成长升级相关的所有数据（包括但不限于经验值、荣誉值、声望值、称号等）；尚未使用的游戏虚拟道具（如卡券、金币、钻石及其他等）；已经购买但未到期或未使用完的其他增值服务；已产生但未消耗完毕的其他游戏收益或未来预期的游戏收益等。\n"
        + "\n"
        + "四、用户信息收集、使用及保护\n"
        + "4.1您同意并授权道泉网络科技为履行本协议之目的收集您的用户信息，这些信息包括您在实名注册系统中注册的信息、您游戏账号下的游戏数据以及其他您在使用道泉网络科技游戏服务的过程中向道泉网络科技提供或道泉网络科技基于安全、用户体验优化等考虑而需收集的信息，道泉网络科技对您的用户信息的收集将遵循本协议及相关法律的规定。\n"
        + "4.2您充分理解并同意：道泉网络科技或其合作的第三方可以根据您提供的用户信息，通过短信、电话、邮件等各种方式向您提供关于道泉网络科技游戏的活动信息、推广信息等各类信息。\n"
        + "4.3您理解并同意：为了更好地向您提供游戏服务，改善游戏体验，道泉网络科技可对您游戏账号中的昵称、头像以及在道泉网络科技游戏中的相关操作信息、游戏信息等信息（以下称“该等信息”。该等信息具体包括但不限于您的登录状态、对战信息/状态、成就信息等）进行使用，并可向您本人或其他用户或好友展示该等信息。\n"
        + "4.4您应对通过道泉网络科技游戏及相关服务了解、接收或可接触到的包括但不限于其他用户在内的任何人的个人信息予以充分尊重，您不应以搜集、复制、存储、传播或以其他任何方式使用其他用户的个人信息，否则，由此产生的后果由您自行承担。\n"
        + "4.5保护用户信息及隐私是道泉网络科技的一项基本原则。除本协议另有规定外，道泉网络科技游戏服务对用户信息收集、使用及保护等将遵循道泉网络科技统一公布的相关隐私政策，该等隐私政策系本协议的补充，构成本协议的一部分。\n"
        + "\n"
        + "五、道泉网络科技游戏服务\n"
        + "5.1 在您遵守本协议及相关法律法规的前提下，道泉网络科技给予您一项个人的、不可转让及非排他性的许可，以使用道泉网络科技游戏服务。您仅可为非商业目的使用道泉网络科技游戏服务，包括：\n"
        + "（1）接收、下载、安装、启动、升级、登录、显示、运行和/或截屏道泉网络科技游戏；\n"
        + "（2）创建游戏角色，设置网名，查阅游戏规则、用户个人资料、游戏对局结果，开设游戏房间、设置游戏参数，在游戏中购买、使用游戏虚拟道具等，使用聊天功能、社交分享功能；\n"
        + "（3）使用道泉网络科技游戏支持并允许的其他某一项或几项功能。\n"
        + "5.2 您在使用道泉网络科技游戏服务过程中不得未经道泉网络科技许可以任何方式录制、直播或向他人传播道泉网络科技游戏内容，包括但不限于不得利用任何第三方软件进行网络直播、传播等。\n"
        + "5.3 在道泉网络科技游戏以软件形式提供的情况下，您在使用道泉网络科技游戏及道泉网络科技游戏服务时还应符合本协议第五条关于软件许可的规定。\n"
        + "5.4 本条及本协议其他条款未明示授权的其他一切权利仍由道泉网络科技保留，您在行使这些权利时须另外取得道泉网络科技的书面许可。\n"
        + "5.5 如果您违反本协议任何约定的，道泉网络科技有权采取相应处理措施，并可公布处理结果，且（/或）有权要求您赔偿因您违约行为而给道泉网络科技造成的所有损失。\n"
        + "5.6 您充分理解并同意，道泉网络科技有权依合理判断对违反有关法律法规或本协议规定的行为进行处理，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，用户应独自承担由此而产生的一切法律责任。在向您提供道泉网络科技游戏服务过程中，如发现涉诈违法犯罪线索、风险信息的，道泉网络科技有权依照国家有关规定，根据涉诈风险类型、程度情况移送公安、金融、电信、网信等有权部门。\n"
        + "5.7 您充分理解并同意，因您违反本协议或相关服务条款的规定，导致或产生第三方主张的任何索赔、要求或损失，您应当独立承担责任；道泉网络科技因此遭受损失的，您也应当一并赔偿。\n"
        + "5.8 您充分理解并同意：游戏虚拟道具及其他游戏增值服务等均是道泉网络科技游戏服务的一部分，道泉网络科技在此许可您依本协议而获得其使用权。您购买、使用游戏虚拟道具及其他游戏增值服务等应遵循本协议、游戏具体规则的要求。同时，游戏虚拟道具及其他游戏增值服务等可能受到一定有效期限的限制，即使您在规定的有效期内未使用，除不可抗力或可归责于道泉网络科技的原因外，一旦有效期届满，将会自动失效。如相关游戏虚拟道具及其他游戏增值服务没有标明使用期限，或者标明的使用期限为“永久”或其他具有与“永久”相同或类似意思的内容的（如“无限期”、“无限制”等），则其使用期限为自您获得该游戏虚拟道具或其他游戏增值服务之日起至该游戏终止运营之日止。\n"
        + "您充分理解并同意：为更好地向用户提供道泉网络科技游戏服务，道泉网络科技有权对游戏相关内容（包括但不限于游戏虚拟道具的设计、性能及相关数值设置等）作出调整、更新或优化。\n"
        + "5.9 您充分理解并同意：为保障您游戏账号安全，为营造公平、健康及安全的游戏环境，在您使用道泉网络科技游戏服务的过程中，在不违反相关法律规定情况下，道泉网络科技可以通过技术手段了解您终端设备的随机存储内存以及与道泉网络科技游戏同时运行的相关程序。一经发现有任何未经授权的、危害道泉网络科技游戏服务正常运营的相关程序，道泉网络科技可以采取合理措施予以打击。\n"
        + "5.10 道泉网络科技将按照相关法律法规和本协议的规定，采取切实有效的措施保护未成年人在使用道泉网络科技游戏服务过程中的合法权益，包括可能采取技术措施、禁止未成年人接触不适宜的游戏或者游戏功能、限制未成年人的游戏时间、预防未成年人沉迷网络。作为游戏规则的一部分，道泉网络科技还将在适当位置发布道泉网络科技游戏用户指引和警示说明，包括游戏内容介绍、正确使用游戏的方法以及防止危害发生的方法。所有未成年人用户都应在法定监护人的指导下仔细阅读并遵照执行这些指引和说明；其他玩家在使用道泉网络科技游戏服务的过程中应避免发布、产生任何有损未成年人身心健康的内容，共同营造健康游戏环境。\n"
        + "5.11 您知悉并同意，出现以下情形之一的，道泉网络科技有权将您的游戏账号纳入相应的防沉迷系统，采取相应的防沉迷措施：\n"
        + "（1）系统判断您未满18周岁的；或\n"
        + "（2）您提交的实名身份信息不规范的；或\n"
        + "（3）您实名验证未通过的；或\n"
        + "（4）游客模式登录的；或\n"
        + "（5）其他国家法律法规政策要求或道泉网络科技有合理理由认为需要纳入防沉迷系统的情形的。\n"
        + "另外，为了进一步提高实名认证的精准度，最大限度防止未成年人冒用他人身份信息，我们可能在部分游戏或针对部分用户启用人脸识别验证，或者从第三方平台获取您的游戏账号信息，并基于未成年人保护策略识别记录您的游戏行为，并判断游戏行为是否符合未成年人游戏行为特征。如道泉网络科技游戏要求您进行人脸识别验证，而您未通过或拒绝的，我们也会将您的游戏账号纳入相应的防沉迷系统，采取相应的防沉迷措施。\n"
        + "对纳入相应防沉迷系统的游戏账号，我们有权依据国家有关法律法规及政策规定、本协议其他条款规定、道泉网络科技游戏运营策略或根据您法定监护人的合理要求采取以下一种或多种措施：\n"
        + "（1）将与您游戏相关的信息（包括但不限于您游戏账号的登录信息、充值流水信息等）提供给您的法定监护人，使得您法定监护人可及时或同步了解您游戏情况；\n"
        + "（2）限制您游戏账号的消费额度；\n"
        + "（3）采取技术措施屏蔽某些游戏或游戏的某些功能，或限定您游戏时间或游戏时长；\n"
        + "（4）注销或删除您游戏账号及游戏数据等相关信息；\n"
        + "（5）您法定监护人要求采取的，或道泉网络科技认为可采取的其他合理措施，以限制或禁止您使用道泉网络科技游戏；\n"
        + "（6）国家法律法规或政策要求的相关措施。\n"
        + "5.12 道泉网络科技向用户提供游戏服务本身属于商业行为，用户有权自主决定是否根据道泉网络科技自行确定的收费项目（包括但不限于购买游戏内的游戏虚拟道具的使用权以及接受其他增值服务等各类收费项目）及收费标准支付相应的费用，以获得相应的游戏服务。如您不按相应标准支付相应费用的，您将无法获得相应的游戏服务。\n"
        + "您知悉并同意：收费项目或收费标准的改变、调整是一种正常的商业行为，您不得因为收费项目或收费标准的改变、调整而要求道泉网络科技进行赔偿或补偿。\n"
        + "您知悉并同意，您使用法定货币进行游戏充值购买或兑换游戏虚拟道具及其他增值服务的，您所购买或兑换的游戏虚拟道具及其他增值服务将不能退还或兑换成法定货币，但法律另有强制性规定的除外。\n"
        + "您知悉并同意，如您进行游戏充值购买或兑换游戏虚拟道具及其他增值服务的交易因退款或构成无效民事行为等原因被取消或恢复原状的，道泉网络科技有权就该被取消或恢复原状的交易对应下发到您游戏账号中的游戏虚拟道具及其他增值服务进行扣除。如前述被取消或恢复原状的交易涉及的游戏虚拟道具或其他增值服务已赠送其他用户的，您或当您为受赠用户时，均同意道泉网络科技可从受赠游戏账号中扣除该游戏虚拟道具或其他增值服务。如您或当您为受赠用户时游戏账号中的游戏虚拟道具及其他增值服务不足以扣除的，就不足部分，道泉网络科技可在您或受赠用户游戏账号中的游戏虚拟道具及其他增值服务后续增加时优先扣除。\n"
        + "5.13 在任何情况下，道泉网络科技不对因不可抗力导致的您在使用道泉网络科技游戏服务过程中遭受的损失承担责任。该等不可抗力事件包括但不限于国家法律、法规、政策及国家机关的命令及其他政府行为或者其它的诸如地震、水灾、雪灾、火灾、海啸、台风、罢工、战争等不可预测、不可避免且不可克服的事件。\n"
        + "5.14 道泉网络科技游戏可能因游戏软件BUG、版本更新缺陷、第三方病毒攻击或其他任何因素导致您的游戏虚拟道具等账号数据或游戏数据发生异常。在数据异常的原因未得到查明前，道泉网络科技有权暂时冻结该游戏账号；若查明数据异常为非正常游戏行为所致，道泉网络科技有权恢复游戏账号数据至异常发生前的原始状态（包括向第三方追回被转移数据），而无须向您承担任何责任。若数据异常是由于您实施违法违规行为或违反本协议约定的行为所致，道泉网络科技有权采取相应处理措施。\n"
        + "您理解并同意，如游戏部署了多个服务器，在游戏运营过程中，为了提高游戏的可玩性和互动性，创造更好的游戏环境，道泉网络科技可结合游戏服务器内活跃用户人数等情况，适时调整服务器数量，将其中多个服务器之间的用户临时或永久地合并或迁移到同一个服务器中（即“合服”或“合区”）。\n"
        + "如游戏合服或合区的，道泉网络科技会在合服或合区前通过游戏内公告或其他方式向您通知，并会公布有关合服或合区具体规则。合服或合区可能会导致游戏相关设置、体验及有关榜单等用户相关游戏数据发生变化，但不会对您游戏虚拟道具的功能进行调整，具体见游戏官方公示的有关合服或合区具体规则。如合服或合区导致角色名、战队名（如有）及其他相关内容等重名的，游戏官方可随机对重名内容设置新名字以作区分。\n"
        + "5.15 未经道泉网络科技许可，您不得擅自与其他用户进行游戏虚拟道具及其他增值服务的交易，或从第三方通过购买、接受赠与或者其他式获得游戏虚拟道具及其他增值服务。道泉网络科技不对第三方交易行为（第三方交易，是指您从第三方通过购买、接受赠与或者其他的方式获得游戏虚拟道具及其他增值服务的行为）负责，并且不受理因任何第三方交易发生纠纷而带来的申诉。\n"
        + "5.16 您充分理解到：不同操作系统之间存在不互通的客观情况，该客观情况并非道泉网络科技造成，由此可能导致您在某一操作系统中的充值和游戏数据不能顺利转移到另一操作系统中。由于您在不同系统进行切换造成的充值损失和游戏数据丢失风险应由您自行承担，道泉网络科技对此不承担任何责任。\n"
        + "5.17 道泉网络科技自行决定终止运营道泉网络科技游戏时或道泉网络科技游戏因其他任何原因终止运营时，道泉网络科技会按照国家有关网络游戏终止运营的相关法律法规规定处理游戏终止运营相关事宜，以保障用户合法权益。\n"
        + "\n"
        + "六、用户行为规范\n"
        + "6.1 您充分了解并同意，您为自己游戏账号下的一切行为负责，包括您所发表的任何内容以及由此产生的任何后果。\n"
        + "6.2 您除了可以按照本协议的约定使用道泉网络科技游戏服务之外，不得进行任何侵犯道泉网络科技游戏的知识产权的行为，或者进行其他的有损于道泉网络科技或其他第三方合法权益的行为。\n"
        + "6.3 您在使用道泉网络科技游戏或道泉网络科技游戏服务时须遵守法律法规，不得利用道泉网络科技游戏或道泉网络科技游戏服务从事违法违规行为，包括但不限于以下行为：\n"
        + "（一）违反宪法所确定的基本原则的；\n"
        + "（二）危害国家安全、泄露国家秘密，颠覆国家政策，破坏国家统一的；\n"
        + "（三）损害国家荣誉和利益的；\n"
        + "（四）歪曲、丑化、亵渎、否定英雄烈士事迹和精神，以侮辱、诽谤或者其他方式侵害英雄烈士的姓名、肖像、名誉、荣誉的；\n"
        + "（五）宣扬恐怖主义、极端主义或者煽动实施恐怖活动、极端主义活动的；\n"
        + "（六）煽动民族仇恨、民族歧视，破坏民族团结的；\n"
        + "（七）破坏国家宗教政策，宣扬邪教和封建迷信的；\n"
        + "（八）散布谣言，扰乱社会秩序和社会秩序的；\n"
        + "（九）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；\n"
        + "（十）侮辱或者诽谤他人，侵害他人名誉、隐私和其他合法权益的；\n"
        + "（十一）违背社会公德的；\n"
        + "（十二）法律、行政法规和国家规定禁止的其他内容的。\n"
        + "您不得利用道泉网络科技游戏或道泉网络科技游戏服务制作、复制、发布含有下列内容的不良信息：\n"
        + "（一）使用夸张标题，内容与标题严重不符的；\n"
        + "（二）炒作绯闻、丑闻、劣迹等的；\n"
        + "（三）不当评述自然灾害、重大事故等灾难的；\n"
        + "（四）带有性暗示、性挑逗等易使人产生性联想的；\n"
        + "（五）展现血腥、惊悚、残忍等致人身心不适的；\n"
        + "（六）煽动人群歧视、地域歧视等的；\n"
        + "（七）宣扬低俗、庸俗、媚俗内容的；\n"
        + "（八）可能引发未成年人模仿不安全行为和违反社会公德行为、诱导未成年人不良嗜好等的；\n"
        + "（九）其他对网络生态造成不良影响的内容。\n"
        + "6.4 除非法律允许或道泉网络科技书面许可，您不得从事下列行为：\n"
        + "（1）删除游戏软件及其副本上关于著作权的信息；\n"
        + "（2）对游戏软件进行反向工程、反向汇编、反向编译或者以其他方式尝试发现软件的源代码或其他保密内容，包括但不限于道泉网络科技暂未主动公开呈现但已封存在游戏安装包中的游戏元素等；\n"
        + "（3）对游戏软件进行扫描、探查、测试，以检测、发现、查找其中可能存在的BUG或弱点；\n"
        + "（4）对游戏软件或者软件运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及软件运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经合法授权的第三方工具/服务接入软件和相关系统；\n"
        + "（5）修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论上述行为是否为商业目的；\n"
        + "（6）通过非道泉网络科技开发、授权的第三方软件、插件、外挂、系统，使用道泉网络科技游戏及道泉网络科技游戏服务，或制作、发布、传播非道泉网络科技开发、授权的第三方软件、插件、外挂、系统；\n"
        + "（7）对游戏中道泉网络科技拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；\n"
        + "（8）建立有关道泉网络科技游戏的镜像站点，或者进行网页（络）快照，或者利用架设服务器等方式，为他人提供与道泉网络科技游戏服务完全相同或者类似的服务；\n"
        + "（9）将道泉网络科技游戏的任意部分分离出来单独使用，或者进行其他的不符合本协议的使用；\n"
        + "（10）使用、修改或遮盖道泉网络科技游戏的名称、商标或其它知识产权；\n"
        + "（11）其他未经道泉网络科技明示授权的行为。\n"
        + "如道泉网络科技游戏存在可供多个用户在线交流游戏信息的网络平台的，用户应遵守法律法规、本协议以及道泉网络科技基于该平台制定的相关规则（“平台规则”），不得利用该平台制作、复制、发布、传播法律法规和国家有关规定禁止的信息内容。\n"
        + "如用户（“创建者”）可以根据平台规则自行创建相应的游戏交流板块，并允许其他用户进入该板块制作、复制、发布或传播信息内容的，创建者以及管理者（即创建者指定的其他用户作为管理者）应当履行该板块的管理责任，并依据法律法规、本协议及平台规则规范该板块内的网络行为和信息发布。创建者及管理者应规范板块内其他用户的行为，及时制止板块内容违法违规行为，并对利用该板块管理权限所实施的行为负责。对于未尽到管理义务导致游戏交流板块出现违法和不良信息内容的创建者及管理者，道泉网络科技将根据具体情形采取警示提醒、删除信息、限制或暂停其使用游戏交流板块功能、永久关闭游戏交流板块以及有权采取相应处理措施。\n"
        + "6.5 您在使用道泉网络科技游戏服务过程中如有以下任何行为（以下也称“违法违规行为”）之一的，道泉网络科技有权视情节严重程度，依据本协议及相关游戏规则的规定，对您采取本条第二款规定的一种或多种处理措施，并可公告处理结果：\n"
        + "（1）在道泉网络科技游戏中使用、上传违法或不当词语、字符及其他内容的行为，包括用于角色命名；\n"
        + "（2）以任何方式破坏道泉网络科技游戏或影响道泉网络科技游戏服务的正常进行，包括但不限于：\n"
        + "1）违规及（或）恶意注册、登录游戏的行为，包括但不限于恶意批量注册游戏账号，以及以破坏服务器鉴权、恶意挤服等方式登录游戏行为；\n"
        + "2）恶意实施可能导致游戏服务器宕机、卡顿，或导致其他游戏玩家掉线、延迟等无法正常游戏的行为，如利用DDOS对游戏服务器或其他玩家进行网络流量攻击的行为等；\n"
        + "3)利用游戏漏洞实施获益、破坏游戏环境或给其他玩家造成不良影响的行为；\n"
        + "4）消极游戏破坏其他用户游戏体验的行为。包括但不限于恶意伤害或者击杀本方队友，恶意组队，恶意送分、降分，消极挂机、逃跑、故意送人头，以及其他消极游戏破坏用户游戏体验的行为；\n"
        + "5）游戏数据表现异常，影响道泉网络科技游戏公平的其他行为。\n"
        + "（3）使用各种私服、外挂行为；\n"
        + "（4）使用游戏同步器（具体包括但不限于使用键盘、鼠标等硬件同步器，以便可以使用一套键盘、鼠标，同时、同步控制多台电脑进行游戏的行为），以及使用其他各种可以让您在游戏效率或收益数据上表现异常的硬件（包括但不限于“连点器”等，以下统称“作弊硬件”）；\n"
        + "（5）传播非法言论或不当信息；\n"
        + "（6）盗取他人游戏账号、游戏物品；\n"
        + "（7）进行游戏账号交易、账号共享等影响账号安全的行为；\n"
        + "（8）未经道泉网络科技许可，擅自与其他用户进行游戏虚拟道具及其他游戏增值服务等交易，或从第三方通过购买、接受赠与或者其他式获得游戏虚拟道具及其他游戏增值服务；\n"
        + "（9）在未经道泉网络科技授权或认可的折扣渠道进行游戏充值、进行折扣游戏道具交易（例如在非道泉网络科技官方渠道低价购买折扣皮肤）或委托未获道泉网络科技授权或认可的第三方代为充值，或者使用未经道泉网络科技授权或认可的第三方软件充值；\n"
        + "（10）以某种方式暗示或伪称道泉网络科技内部员工或某种特殊身份，企图得到不正当利益或影响其他用户权益的行为；\n"
        + "（11）违反本协议任何约定，或违反国家法律法规任何规定的行为；\n"
        + "（12）其他在行业内被广泛认可的不当行为，或通过游戏规则（包括但不限于用户守则、玩家条例、游戏公告、提示及通知）公布的其他违规行为。\n"
        + "前款规定的处理措施包括但不限于：\n"
        + "（1）警告；及/或\n"
        + "（2）禁言，包括但不限于游戏全区禁言、单区禁言以及公共频道禁言；及/或\n"
        + "（3）内容重置，包括但不限于重置或强制修改您提供或上传的非法昵称、房间名、图片等非法信息或内容等；及/或\n"
        + "（4）减少或限制您的游戏收益，该游戏收益包括但不限于游戏中与游戏角色成长升级相关的所有数据（如经验值、荣誉值、声望值、称号等）的提升以及游戏中的游戏虚拟道具及其他游戏增值服务的获取；及/或\n"
        + "（5）删除或注销游戏账号、游戏数据或其他游戏相关信息；及/或\n"
        + "（6）限制或禁止您进入游戏中的特定场景或使用游戏特定功能；及/或\n"
        + "（7）强制退出游戏（即“踢出游戏”），包括但不限于强制退出整个游戏，或游戏中的单局对战，或您正在进行中的其他游戏行为；及/或\n"
        + "（8）暂时或永久性地禁止您游戏账号登录道泉网络科技游戏（以下称“封号”）；及/或\n"
        + "（9）暂时或永久性地禁止您违法违规行为涉及的特定终端（包括但不限于PC终端、移动终端等）登录道泉网络科技游戏；及/或\n"
        + "（10）暂停或终止您使用道泉网络科技游戏及相关服务，或单方通知您终止本协议；及/或\n"
        + "（11）就您的违法违规行为提起相应民事诉讼，追究您的侵权、违约或其他民事责任，并要求您赔偿道泉网络科技公司因您违法违规行为所受到的损失（包括道泉网络科技公司所受到的直接经济损失、名誉或商誉损失以及道泉网络科技公司对外支付的赔偿金、和解费用、律师费用、诉讼费用及其他间接损失），或移交有关行政管理机关给予行政处罚，或者移交司法机关追究您的刑事责任；及/或\n"
        + "（12）道泉网络科技通过游戏规则（包括但不限于用户守则、玩家条例、游戏公告、提示及通知）公布的其他处理措施。\n"
        + "您知悉并同意，由于外挂及游戏同步器等作弊硬件具有隐蔽性或用完后即消失等特点，道泉网络科技有权根据您的游戏数据和表现异常判断您有无使用非法外挂或游戏同步器等作弊硬件行为。\n"
        + "6.6 您知悉并同意，如道泉网络科技依据本协议对您的游戏账号采取封号处理措施的，具体封号期间由道泉网络科技根据您违规行为情节而定。\n"
        + "您知悉并同意：（1）在封号期间，您游戏账号中的游戏虚拟道具及其他增值服务都将无法使用；（2）如前述游戏虚拟道具及其他增值服务存在一定有效期，该有效期可能会在封号期间过期，您游戏账号解封后，您将无法使用该等已过期的游戏虚拟道具及其他增值服务。\n"
        + "\n"
        + "七、知识产权\n"
        + "7.1 道泉网络科技是道泉网络科技游戏的知识产权权利人。道泉网络科技游戏（包括但不限于道泉网络科技游戏整体、游戏内所包含的所有美术、音乐、文字作品和其他游戏构成要素、组成部分，以及道泉网络科技游戏运行呈现的连续动态画面）的一切著作权、商标权、专利权、商业秘密等知识产权及其他合法权益，以及与道泉网络科技游戏相关的所有信息内容（包括文字、图片、音频、视频、图表、界面设计、版面框架、有关数据或电子文档等）均受中华人民共和国法律法规和相应的国际条约保护，道泉网络科技享有上述知识产权和合法权益，但相关权利人依照法律规定应享有的权利除外。未经道泉网络科技事先书面同意，您不得以任何方式将道泉网络科技游戏（包括但不限于道泉网络科技游戏整体、游戏内所包含的所有美术、音乐、文字作品和其他游戏构成要素、组成部分，以及道泉网络科技游戏运行呈现的连续动态画面 ）进行商业性使用或通过信息网络传播道泉网络科技游戏内容。\n"
        + "7.2 如道泉网络科技游戏存在可供您自行制作、创作或上传相关内容的平台功能或服务的（以下称”UGC平台”），针对您使用UGC平台制作、创作、上传或发布的内容（以下称“UGC内容”），您应确保其合法且不侵犯他人合法权益并应遵守道泉网络科技在UGC平台上发布的相关规则（以下称“UGC平台规则”），且您同意：\n"
        + "（1）如UGC内容含有道泉网络科技游戏内容（包括但不限于道泉网络科技游戏整体、游戏内所包含的所有美术、音乐、文字作品和其他游戏构成要素、组成部分，以及道泉网络科技游戏运行呈现的连续动态画面）及（或）道泉网络科技公司享有知识产权的其他内容的，则您对UGC内容进行商业目的使用需要经道泉网络科技公司书面授权同意，并遵守相应的UGC平台规则。\n"
        + "（2）针对UGC内容， 您同意不可撤销地授权道泉网络科技以任何方式使用该UGC内容（前述“使用”包括但不限于复制、发行、出租、展览、表演、广播、修改、改编、信息网络传播及其他方式使用，同时包括商业目的和非商业目的使用，本游戏内和本游戏外使用，也包括在UGC平台内和UGC平台外使用。为方便描述，以下将前述使用的权利统称“使用权”），同时授权道泉网络科技可以将前述使用权转授权或分许可第三方使用。您承诺道泉网络科技的该等使用不会侵害任何第三方知识产权及其他合法权益。\n"
        + "（3） 针对UGC内容，您仅可在道泉网络科技游戏及道泉网络科技认可的其他平台对该UGC内容作出展示或按道泉网络科技认可方式使用，不得在未经道泉网络科技许可情况下擅自或授权第三方在其他平台进行传播，也不得擅自或授权第三方进行任何出版、商业盈利、二次衍生及其他使用的行为。如违反，道泉网络科技将保留法律上的追诉权利。\n"
        + "（4）针对UGC内容，您不可撤销地授权道泉网络科技以自己的名义或委托专业第三方对侵犯UGC内容合法权益的行为进行维权，维权形式包括但不限于：监测侵权行为、发送维权函、提起诉讼或仲裁、调解、和解等，道泉网络科技有权对维权事宜做出决策并独立实施。\n"
        + "（5）如根据著作权法等法律规定，您对UGC内容享有发表权、署名权、修改权和保护作品完整权等著作人身权的，您同意道泉网络科技可自行根据UGC的具体使用情况或使用场景是否为您进行署名以及以什么方式进行署名，且您不会因道泉网络科技按本协议约定使用UGC内容而向道泉网络科技主张发表权、署名权、修改权和保护作品完整权等著作人身权。\n"
        + "7.3 您在使用道泉网络科技游戏服务中产生的游戏数据的所有权和知识产权归道泉网络科技所有，道泉网络科技有权保存、处置该游戏数据，但国家法律法规另有规定的从其规定。\n"
        + "道泉网络科技对其合法处理游戏数据形成的相关数据产品和服务享有法律法规规定的财产权益。\n"
        + "7.4 道泉网络科技游戏可能涉及第三方知识产权，而该等第三方对您基于本协议在道泉网络科技游戏中使用该等知识产权有要求的，道泉网络科技将以适当方式向您告知该要求，您应当一并遵守。\n"
        + "\n"
        + "八、遵守当地法律监管\n"
        + "8.1 您在使用道泉网络科技游戏服务过程中应当遵守当地相关的法律法规，并尊重当地的道德和风俗习惯。如果您的行为违反了当地法律法规或道德风俗，您应当为此独立承担责任。\n"
        + "8.2 您应避免因使用道泉网络科技游戏服务而使道泉网络科技卷入政治和公共事件，否则道泉网络科技有权暂停或终止对您的服务。\n"
        + "\n"
        + "九、管辖与法律适用\n"
        + "9.1 本协议签订地为中华人民共和国广东省深圳市南山区。\n"
        + "9.2 本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。\n"
        + "9.3 您和道泉网络科技之间因本协议发生的（和/或与本协议有关的）任何纠纷或争议，首先友好协商解决；协商不成的，您同意将纠纷或争议提交至本协议签订地有管辖权的人民法院管辖。\n"
        + "9.4 本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。\n"
        + "9.5 本协议条款无论因何种原因部分无效，其余条款仍有效，对各方具有约束力。\n"
        + "\n"
        + "十、 其他\n"
        + "10.1 道泉网络科技有权在必要时变更本协议条款，您可以在道泉网络科技游戏的相关页面查阅最新版本的协议条款。本协议条款变更后，如果您继续使用道泉网络科技游戏服务，即视为您已接受变更后的协议。\n"
        + "10.2 道泉网络科技提醒您：抵制不良游戏，拒绝盗版游戏；注意自我保护，谨防受骗上当；适度游戏益脑，沉迷游戏伤身；合理安排时间，享受健康生活。\n"
        + "\n"
        + "广州道泉网络科技有限公司",
    default: "TERMS OF SERVICE\n"
        + "1. GENERAL\n"
        + "1.1. These terms and conditions including the privacy policy (altogether, the \"Terms\") constitute a legally binding agreement between you, as a user of the Service (as defined below) (\"you\") and Clever Apps Pte. Ltd., the provider of the Service (\"we\" or \"us\" or \"Clever Apps\").\n"
        + "1.2. By loading, installing, accessing and using our games, applications, content, activities, and services (the \"Service\") which are made available through third party social networking websites, such as Facebook, and/or by application stores, such as Google Play or Apple AppStore, and/or directly by us, you confirm that you have read, understand and agree to be bound by these Terms. If you do not agree with these Terms, you are prohibited from accessing and using the Service.\n"
        + "1.3. You must be 13 years or older in order to use the Service. If you are between 13 and 18 you must seek permission of a parent or legal guardian before using the Service. Parents and legal guardians warrant and represent that they have reviewed and agreed to these Terms.\n"
        + "\n"
        + "2. INFORMATION ABOUT US\n"
        + "2.1. We are Clever Apps Pte. Ltd., a company registered in Singapore under registration number 201706967K. Our registered address is 8 Marina Boulevard #11-00 Marina Bay Financial Centre Singapore 018981.\n"
        + "2.2. If you have any questions, complaints or comments on this Service then please contact us at vtbelo+support@gmail.com.\n"
        + "\n"
        + "3. PAYMENT AND CREDITS\n"
        + "3.1. Depending on  the Service you use you may be given tokens to play (without charge) in order to be able to participate (\"free credits\") or you may otherwise be permitted to play for free. Where applicable, a certain number of free credits may be made available to each user (depending on the Service to be used) on a daily basis. We may also award free credits or permit you to use a Service without charge in connection with promotions and as part of any game. We will clarify to you where in certain games it is not possible to roll over free credits from one day to the next (although this may be subject to change, at our discretion), but you may roll over paid credits in this way (see below).\n"
        + "3.2. The Service is free to use until you (i) use up your allocation of free credits; (ii) wish to use additional features or functionality or access different levels of the Service, in which case you may be required to purchase additional credits; or (iii) you are otherwise notified that payment is required in order to participate or access different levels of a Service.  Credits which you purchase are referred to as \"paid credits\" in these Terms and free credits and paid credits are together referred to as \"credits\".\n"
        + "3.3. You may be able to purchase or otherwise acquire a single form of credit for use across all of our Services (\"coins\", \"gold\" etc). Coins may be changed into Service specific (or \"in-game\") credits. However, once a user changes coins for Service specific credits, those credits cannot be changed back into coins for use in other Services.\n"
        + "3.4. When you purchase paid credits or otherwise pay to participate you are responsible for paying all fees and charges including any applicable taxes associated with such purchases and payments. Unless otherwise stated, you may only purchase paid credits or pay to participate using a valid PayPal account, or any other payment method approved by us. By paying to participate, purchasing or otherwise acquiring credits as set out above, you obtain a limited license to access and select from content that we expressly make available as part of the Service.\n"
        + "3.5. Credits, coins and any prizes, awards and achievements (\"Prizes\") made available in using the Service are notional credits and Prizes and have no monetary value. Prizes cannot be redeemed or exchanged for cash, property or other items. Credits, coins and payments to participate may only be redeemed for service content or Services provided by us. This is the case whether or not you have paid to participate or purchased paid credits in order to use the Service. Credits cannot be withdrawn, sold or transferred. Payments made in order to participate, coins and paid credits are non-refundable. You are not entitled to a refund for any unused payments, coins or credits, including in the event of closed and cancelled accounts. \n"
        + "\n"
        + "4. USER CONTENT RULE\n"
        + "4.1. We may invite you to participate in blogs, message boards, online forums and other functionality through which you will be able to communicate with other users of the Service (\"Communication Services\") by submitting various material for public display, in particular (without limitation) comments and messages (\"User Content\").\n"
        + "4.2. We reserve the right to review and keep a record of all User Content. We are not under any obligation to display User Content. As a condition of your use of any Communication Services, you agree to adhere to the following rules:\n"
        + "4.2.1. You agree that you will not submit any User Content, email, transmit or otherwise disseminate any material which is:\n"
        + "4.2.1.1. defamatory, obscene, vulgar or indecent; or\n"
        + "4.2.1.2. may have the effect of being harassing, threatening, abusive or hateful; or\n"
        + "4.2.1.3. that otherwise discriminates against, degrades or intimidates an individual or group of individuals on the basis of religion, gender, sexual orientation, race, ethnicity, age or disability;\n"
        + "4.2.1.4. which breaches the rights of others (including copyright and other intellectual property rights); or\n"
        + "4.2.1.5. would otherwise cause you to breach of these Terms.\n"
        + "4.2.2. You shall not submit User Content containing statements that advertise, promote or otherwise relate to any other online sites or ventures, discuss illegal activities or post personal details.\n"
        + "4.2.3. You shall not submit User Content containing or in order to make statements about Us or any internet site connected to the Clever Apps Pte. Ltd. (as defined in our Privacy Policy) that are untrue and/or malicious and/or damaging to the Us or any member of the Clever Apps Pte. Ltd..\n"
        + "4.3. We reserve the right to monitor or moderate User Content to check whether it complies with these Terms. You may report a user's behaviour using the reporting function provided as part of the Service (if any) or any reporting function provided on the social network website you are using to access the Service. However, we are under no obligation to oversee, monitor or moderate User Content or any Communication Service we provide. We recommend that you use the \"mute\" feature of a Communication Service or turn off any Communication Service (where these options are made available to you) to stop any unwanted User Content.\n"
        + "4.4. We may remove or refuse to display your User Content if it causes you to breach these Terms including any of your promises as set out in this paragraph 4 or for any other reason in our reasonable discretion.\n"
        + "4.5. We expressly exclude our liability for any loss or damage arising from the publication of any User Content or the use of any Communication Service by you in contravention of these Terms, whether the Service is moderated or not.\n"
        + "\n"
        + "5. LICENCE OF USER CONTENT\n"
        + "5.1. You grant us a non-exclusive, worldwide, royalty-free, perpetual licence (with rights to sublicense on identical terms and in multiple tiers) to use, exploit, adapt, perform, display, reproduce, communicate to the public and distribute the User Content through any media now or in future known, which includes the right to display the User Content on and in connection with the Service, the right to display the User Content on any other website or other domain accessible via any internet-enabled device, the right to display the User Content in marketing material in relation to the Service and the right to include the User Content in a book or other publication.\n"
        + "5.2. You waive in perpetuity in respect of the User Content the benefits of any provision of law known as moral rights or any similar law in any country (moral rights are certain rights that the owner of a copyright work has in relation to how that work is used).\n"
        + "5.3. You promise to us that you own the User Content and have the right to grant the licence set out in paragraph 5.1 above and that the User Content does not infringe the rights of any third party.\n"
        + "5.4. You promise that any User Content submitted by you does not cause you to breach these Terms including, in particular (without limitation) the promise made by you at paragraph 5.3.\n"
        + "\n"
        + "6. RESTRICTIONS ON USE OF SERVICE\n"
        + "6.1. By using the Service (including the Communication Services), you agree that:\n"
        + "6.1.1 you will comply with all applicable local laws, regulations and rules in the country in which you reside when using the Service;\n"
        + "6.1.2. you will not use the Service for any purpose other than your personal use;\n"
        + "6.1.3. you will not use the Service in any way that is unlawful or fraudulent or may lead to the encouragement, procurement or carrying out of any criminal activity;\n"
        + "6.1.4. you will not use the Service in order to distribute unsolicited communications including 'spam' email or other material in non compliance with these Terms;\n"
        + "6.1.5. You will not transfer to, or use the Service for the purpose of transferring, files that contain viruses, trojans or other harmful programs or otherwise use the Service in any way that may damage or disrupt another's computer; or access or attempt to access the accounts of other users or to penetrate or attempt to penetrate the Service's security measures.\n"
        + "6.2. If we determine, in our sole and absolute discretion, that you have breached, or reasonably believe that you will breach these Terms, we may:\n"
        + "6.2.1. immediately withdraw your right (on a temporary or permanent basis) to use the Service;\n"
        + "6.2.2. immediately (on a temporary or permanent basis) remove any User Content posted or uploaded by you;\n"
        + "6.2.3. issue of a warning to you;\n"
        + "6.2.4. issue legal proceedings against you for reimbursement of all costs on an indemnity basis (including, but not limited to, reasonable administrative and legal costs) resulting from the breach;\n"
        + "6.2.5. take further legal action against you; and/or\n"
        + "6.2.6. disclose such information to law enforcement authorities as we reasonably feel is necessary.\n"
        + "\n"
        + "7. TRADE MARKS AND OTHER INTELLECTUAL PROPERTY\n"
        + "7.1. Your use of the Service and its contents grants no rights to you in relation to our intellectual property rights (including, amongst other things, any copyright, trade marks and other rights in relation to the code, software, logos, designs, images, photographs, animations, videos and text in or on the Service) (\"Intellectual Property Rights\") or the rights of third parties in the Service or its contents.\n"
        + "7.2. You may not copy, reproduce, republish, download, post, broadcast, record, transmit, commercially exploit, edit, communicate to the public or distribute in any way the services, web pages or materials on the Service or the computer code of elements comprising the Service, other than for your own personal use. Subject to the foregoing, you may download insubstantial excerpts of this content to your hard disk for the purpose of viewing it provided that no more than one copy of any information is made.\n"
        + "7.3. Any use other than that permitted under this paragraph 7.2may only be undertaken with our prior express authorisation in writing.\n"
        + "\n"
        + "8. LINKS TO AND FROM OTHER WEBSITES\n"
        + "8.1. We may provide links to third party websites via the Service or permit third party advertisers to display advertisements as part of the Service. Links are provided for your ease of reference and convenience only.\n"
        + "8.2. We do not control third party websites or third party advertisements and are not responsible for their contents. Our inclusion of links and advertisements does not imply any endorsement of the material contained in such websites or advertisements or any association with their operators.\n"
        + "8.3. You should read the terms and conditions and privacy policies of any third party websites or applications accessed via links or advertisements displayed via the Service.\n"
        + "\n"
        + "9. OUR LEGAL OBLIGATIONS AND LIMITS ON LIABILITY\n"
        + "9.1. Nothing in these Terms shall exclude or limit our liability for:\n"
        + "9.1.1. fraud or fraudulent misrepresentation; or\n"
        + "9.1.2. death or personal injury resulting from our negligence or the negligence by us, our employees or agents;\n"
        + "9.1.3. or any other matter which cannot be excluded or limited by law.\n"
        + "9.2. Subject to paragraph 9.1 and to the extent permitted by law, we, other members of our group of companies and third parties connected to us hereby expressly exclude:\n"
        + "9.2.1. all conditions, warranties and other terms which might otherwise be implied by statute, common law or the law of equity.\n"
        + "9.2.2. any liability for any direct, indirect or consequential loss or damage incurred by you in connection with the Service (including any materials posted on it) including: \n"
        + "9.2.2.1. any loss or damage to your computer system;\n"
        + "9.2.2.2. loss of data;\n"
        + "9.2.2.3. loss of income or revenue;\n"
        + "9.2.2.4. loss of business; or\n"
        + "9.2.2.5. loss of profits or contracts\n"
        + "whether caused by tort (including negligence), breach of contract or otherwise, even if foreseeable, provided that this condition shall not prevent claims for loss of or damage to your tangible property or any other claims for direct financial loss that are not excluded by any of the categories set out above.\n"
        + "9.3. We cannot guarantee that any files that you download are free from viruses, contamination or destructive features.\n"
        + "9.4. Whilst we use all reasonable endeavours to correct any errors or omissions as soon as practicable once they have been brought to our attention, we do not promise that any information provided as part of the Service itself will be free from errors or omissions.\n"
        + "9.5. While every effort is made to reliably provide the Service, outages and unexpected downtime will occur. We do not make any guarantee of uninterrupted service. In particular, lost credits, coins and tickets may occur from time to time. Access to the Service may be suspended temporarily and without notice in the case of system failure, maintenance or repair or for reasons reasonably beyond our control (including outages or software errors that result from actions taken by social networking websites).\n"
        + "9.6. The material displayed through our Service is provided without any guarantees, conditions or warranties as to its accuracy. Commentary and other materials posted as part of the Service are not intended to amount to advice on which reliance should be placed. We therefore disclaim all liability and responsibility arising from any reliance placed on such materials by any user of the Service, or by anyone who may be informed of any of its contents.\n"
        + "9.7. We reserve the right to discontinue, withdraw, terminate or modify the Service or any part of the Service at any time with or without notice or further liability to you.\n"
        + "\n"
        + "10. INDEMNITY\n"
        + "10.1. You agree to indemnify and hold us and any member of the Clever Apps Pte. Ltd. harmless from any claims, proceedings, actions or demands including reasonable legal fees made by any third party due to or arising out of your breach of these Terms or your violation of the rights of another. You agree that the provisions in this paragraph will survive any termination of your access to the Service.\n"
        + "\n"
        + "11. GOVERNING LAW AND JURISDICTION\n"
        + "11.1. The Singapore courts will have exclusive jurisdiction over any claim arising from, or related to, your use of the Service.\n"
        + "11.2. These Terms and any dispute or claim arising out of or in connection with them or their subject matter or formation (including non-contractual disputes or claims) shall be governed by and construed in accordance with the law of Singapore.\n"
        + "\n"
        + "12. SEVERANCE\n"
        + "12.1. If any court or competent authority finds that any provision of these Terms (or part of any provision) is invalid, illegal or unenforceable, that provision or part-provision shall, to the extent required, be deemed to be deleted, and the validity and enforceability of the other provisions of this agreement shall not be affected.\n"
        + "12.2. If any invalid, unenforceable or illegal provision of this agreement would be valid, enforceable and legal if some part of it were deleted, the provision shall apply with the minimum modification necessary to make it legal, valid and enforceable.\n"
        + "\n"
        + "13. TERMS AND AMENDMENT\n"
        + "13.1. These Terms shall be valid from the first time that you become a user of the Service until such time as you cease being a user or until such time as we may amend them at which time the amended Terms shall apply. We reserve the right to change these Terms at any time. Such changes shall have effect immediately upon publication on the Service and you agree to be bound by them and to regularly review these terms for the purposes of monitoring such changes. Your continued use of the Services shall be deemed irrevocable acceptance of those revisions. If you don't agree to the changes, you should stop using the Service. These Terms were last updated on 11 April 2017, (Version 1.0). We recommend that you print out a copy of these Terms.\n"
        + "\n"
        + "14. APPLE APPLICATION STORE ADDITIONAL TERMS AND CONDITIONS\n"
        + "14.1. The following additional terms and conditions apply to you if you are using an App from the Apple Application Store. To the extent the other terms and conditions of this Agreement are less restrictive than, or otherwise conflict with, the terms and conditions of this Section 10, the more restrictive or conflicting terms and conditions in this Section 10 apply, but solely with respect to Apps from the Apple Application Store. \n"
        + "14.1.1. Acknowledgement: Clever Apps and you acknowledge that this Agreement is concluded between Clever Apps and you only, and not with Apple, and Clever Apps, not Apple, is solely responsible for App and the content thereof. To the extent this Agreement provides for usage rules for App that are less restrictive than the Usage Rules set forth for App in, or otherwise is in conflict with, the Application Store Terms of Service, the more restrictive or conflicting Apple term applies. \n"
        + "14.1.2. Scope of License: The license granted to you for App is limited to a non-transferable license to use App on an iOS Product that you own or control and as permitted by the Usage Rules set forth in the Application Store Terms of Service. \n"
        + "14.1.3. Maintenance and Support: Clever Apps is solely responsible for providing any maintenance and support services with respect to App, as specified in this Agreement (if any), or as required under applicable law. Clever Apps and you acknowledge that Apple has no obligation whatsoever to furnish any maintenance and support services with respect to App. \n"
        + "14.1.4. Warranty: Clever Apps is solely responsible for any product warranties, whether express or implied by law, to the extent not effectively disclaimed. In the event of any failure of App to conform to any applicable warranty, you may notify Apple, and Apple may refund the purchase price for App to you; and to the maximum extent permitted by applicable law, Apple will have no other warranty obligation whatsoever with respect to App, and any other claims, losses, liabilities, damages, costs or expenses attributable to any failure to conform to any warranty will be Clever Apps's sole responsibility. \n"
        + "14.1.5. Product Claims: Clever Apps and you acknowledge that Clever Apps, not Apple, is responsible for addressing any claims of you or any third party relating to App or your possession and/or use of App, including, but not limited to: (i) product liability claims; (ii) any claim that App fails to conform to any applicable legal or regulatory requirement; and (iii) claims arising under consumer protection or similar legislation. This Agreement does not limit Clever Apps's liability to you beyond what is permitted by applicable law. \n"
        + "14.1.6. Intellectual Property Rights: Clever Apps and you acknowledge that, in the event of any third party claim that App or your possession and use of App infringes that third party's intellectual property rights, Clever Apps, not Apple, will be solely responsible for the investigation, defense, settlement and discharge of any such intellectual property infringement claim. \n"
        + "14.1.7. Legal Compliance: You represent and warrant that (i) you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a \"terrorist supporting\" country; and (ii) you are not listed on any U.S. Government list of prohibited or restricted parties. \n"
        + "14.1.8. Developer Name and Address: Clever Apps's contact information for any end-user questions, complaints or claims with respect to App is provided above. \n"
        + "14.1.9. Third Party Terms of Agreement: You must comply with applicable third party terms of agreement when using App. \n"
        + "14.1.10. Third Party Beneficiary: Clever Apps and you acknowledge and agree that Apple, and Apple's subsidiaries, are third party beneficiaries of this Agreement, and that, upon your acceptance of the terms and conditions of this Agreement, Apple will have the right (and will be deemed to have accepted the right) to enforce this Agreement against you as a third party beneficiary thereof. \n"
        + "\n"
        + "15. APPLE SUBSCRIPTIONS TERMS\n"
        + "15.1. A subscription is valid for 1 week or 1 month, depending on the subscription type selected.\n"
        + "15.2. Payment will be charged to your iTunes Account upon confirmation of purchase.\n"
        + "15.3. Your subscription automatically renews unless auto-renew is turned off at least 24-hours before the end of the current period.\n"
        + "15.4. Your account will be charged for renewal at the price listed within 24-hours prior to the end of the current period.\n"
        + "15.5. Subscriptions may be managed and auto-renewal my be turned off via Settings > iTunes & App Stores > Apple ID > View Apple ID > Subscriptions."
};