/**
 * Created by vladislav on 03/02/2023
 */

var BattlefieldOrangery = {
    init: function () {
        var eraser = [{
            eraser: true,
            id: 0,
            itemIcon: new cc.Sprite(bundles.orangery.frames.eraser_icon),
            minimizedIcon: new cc.Sprite(bundles.orangery.frames.eraser_icon)
        }];

        this.tabs = [{
            icon: bundles.pixel.frames.pixel_png,
            rows: [eraser].concat(this.listByFamily())
        }];
    },

    listByFamily: function () {
        return Object.values(WarriorLines.CODES).map(function (code) {
            var warriorLine = WarriorLines[code];

            return warriorLine.line.map(function (warrior, stage) {
                return {
                    code: code,
                    stage: stage,
                    itemIcon: WarriorView.getUnitImage(warriorLine, stage),
                    minimizedIcon: WarriorView.getUnitImage(warriorLine, stage)
                };
            });
        });
    },

    getItemCode: function (item) {
        return Messages.get("Units." + item.code + ".name") + "_" + item.stage;
    }
};
