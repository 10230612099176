/**
 * Created by Andrey Popov on 1/26/21.
 */

var ImagePropertyEditor = function (property, initialValue, onChangeValueCallback) {
    this.property = property;
    this.onChangeValueCallback = onChangeValueCallback;
    var resolution = cleverapps.wysiwyg.wysiwygPreview.selectedResolution;

    if (this.property.allowOverride) {
        this.value = cleverapps.override({
            general: {
                folder: "",
                name: ""
            }
        }, initialValue);

        if (!this.value[resolution]) {
            this.value[resolution] = {};
        }
    } else {
        this.value = cleverapps.override({
            folder: "",
            name: ""
        }, initialValue);
    }

    var nameWithFolders = cleverapps.wysiwyg.googleDriveProvider.getAssetsInFolders(this.property.folders);
    nameWithFolders[Wysiwyg.NOT_SELECTED] = true;

    this.propertyEditors = [{
        name: property.name,
        items: Object.keys(nameWithFolders),
        direction: cleverapps.UI.VERTICAL,
        allowOverride: property.allowOverride,
        getter: function () {
            return this.value.name;
        }.bind(this),
        setter: function (value) {
            if (value === Wysiwyg.NOT_SELECTED) {
                this.value = undefined;
            } else {
                this.value = {
                    name: value,
                    folder: nameWithFolders[value]
                };
            }
        }.bind(this)
    }];

    if (this.property.allowOverride) {
        delete this.propertyEditors[0].setter;
        delete this.propertyEditors[0].getter;
        this.propertyEditors[0].setters = [function (value) {
            if (value === Wysiwyg.NOT_SELECTED) {
                this.value.general = undefined;
            } else {
                this.value.general = {
                    name: value,
                    folder: nameWithFolders[value]
                };
            }
        }.bind(this), function (value) {
            if (value === Wysiwyg.NOT_SELECTED) {
                this.value[resolution] = undefined;
            } else {
                this.value[resolution] = {
                    name: value,
                    folder: nameWithFolders[value]
                };
            }
        }.bind(this)];

        this.propertyEditors[0].getters = [function () {
            return this.value.general && this.value.general.name;
        }.bind(this),
        function () {
            return this.value[resolution].name;
        }.bind(this)];
    }

    this.propertyEditors = this.propertyEditors.map(function (propertyDescription) {
        return new PropertyEditor(propertyDescription, this);
    }.bind(this));
};

ImagePropertyEditor.prototype.editBoxEditingDidEnd = function (sender) {
    sender.customSetter(sender.getString());
    this.onChangeValueCallback(this.property.name, this.value);
    this.propertyEditors.forEach(function (propertyEditor) {
        propertyEditor.updateOverrides();
    });
};