/**
 * Created by Andrey Popov on 5/15/24.
 */

var NoAds = function () {
    this.state = NoAds.STATE_DISABLED;
    this.isWindowShown = false;

    this.load();

    connector.ads.on("changeStatus", this.updateSideBarIcon.bind(this));

    this.updateSideBarIcon();
};

NoAds.prototype.load = function () {
    this.state = cleverapps.dataLoader.load(DataLoader.TYPES.NO_ADS) || NoAds.STATE_DISABLED;
};

NoAds.prototype.getState = function () {
    return this.state;
};

NoAds.prototype.updateSideBarIcon = function () {
    var icon = cleverapps.sideBar.findIconByClassName(NoAdsIcon);

    if (icon && !this.needToNudge()) {
        cleverapps.sideBar.removeTemporaryIcon(icon);
    }

    if (!icon && this.needToNudge()) {
        cleverapps.sideBar.addTemporaryIcon(new NoAdsIcon());
        cleverapps.sideBar.refreshAllIcons();
    }
};

NoAds.prototype.enable = function (fromServer) {
    this.state = NoAds.STATE_ENABLED;

    if (cleverapps.bannerAd) {
        cleverapps.bannerAd.check(true);
    }

    this.updateSideBarIcon();

    this.save(fromServer);
};

NoAds.prototype.reset = function () {
    this.state = NoAds.STATE_DISABLED;

    cleverapps.bannerAd.check(true);

    this.updateSideBarIcon();

    this.save();
};

NoAds.prototype.save = function (fromServer) {
    cleverapps.dataLoader.save(DataLoader.TYPES.NO_ADS, this.state);

    if (!fromServer) {
        cleverapps.synchronizer.addUpdateTask("noAds");
    }
};

NoAds.prototype.needToNudge = function (forWindow) {
    if (forWindow) {
        if (this.isWindowShown) {
            return false;
        }

        var lastNudgeTime = cleverapps.dataLoader.localStorage.getItem(DataLoader.TYPES.NO_ADS_NUDGE_TIME);
        if (lastNudgeTime && parseInt(lastNudgeTime) + NoAds.NUDGE_TIMEOUT > Date.now()) {
            return false;
        }
    }

    var adsLimit = cleverapps.adsLimits.getLimit(AdsLimits.TYPES.INTERSTITIAL);
    var watchCount = cleverapps.adsLimits.getWatchCount(AdsLimits.TYPES.INTERSTITIAL);
    return adsLimit.limit && watchCount > 0
        && connector.platform.oneOf(connector.WECHAT, connector.INSTANT, connector.MSSTART, connector.CRAZY, connector.OK, connector.VK, connector.YANDEX, connector.CLEVERAPPS, connector.TEST)
        && connector.payments.getProduct("noAds")
        && this.state === NoAds.STATE_DISABLED
        && connector.ads.isInterstitialSupported;
};

NoAds.prototype.updateNudgeTime = function () {
    cleverapps.dataLoader.save(DataLoader.TYPES.NO_ADS_NUDGE_TIME, Date.now());
    this.isWindowShown = true;
};

NoAds.STATE_DISABLED = 0;
NoAds.STATE_ENABLED = 1;

NoAds.NUDGE_TIMEOUT = cleverapps.parseInterval("6 hour");
if (cleverapps.config.debugMode) {
    NoAds.NUDGE_TIMEOUT = cleverapps.parseInterval("1 minute");
}