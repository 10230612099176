/**
 * Created by Aleksandr on 02.05.2024
 */

var PiggyBankMissionLogic = function (mission, isNewMission) {
    this.mission = mission;

    if (isNewMission) {
        this.init();
    }
    this.onShowBottomButtonPriceWithAnimation = function () {};
    this.onSetBalance = function () {};
    this.updateProgress = function () {};
    this.lastShownAmount = this.mission.result;
    this.load(this.mission.details);
};

PiggyBankMissionLogic.prototype.init = function () {
    this.level = cleverapps.paymentsHistory.classify();
    if (this.level === cleverapps.PaymentsHistory.BRACKET_UNDECIDED) {
        this.level = cleverapps.PaymentsHistory.BRACKET_NONE;
    }
    if (this.level === cleverapps.PaymentsHistory.BRACKET_BIGWHALE) {
        this.level = cleverapps.PaymentsHistory.BRACKET_WHALE;
    }

    var product = this.getProduct();

    if (!product) {
        var paymentsName = connector.payments.getName();
        var productKey = PiggyBankMissionLogic.PRODUCTS[this.level] || PiggyBankMissionLogic.VIRTUAL_PRODUCTS[this.level];

        cleverapps.throwAsync("no product for PiggyBank level:" + this.level
            + " plugin:" + paymentsName
            + " productKey:" + productKey);

        return;
    }

    this.mission.result = this.getStartAmount();

    this.saveProgress();
};

PiggyBankMissionLogic.prototype.getInfo = function () {
    return {
        level: this.level,
        amount: this.mission.result
    };
};

PiggyBankMissionLogic.prototype.saveProgress = function () {
    this.mission.update(0, this.getInfo());
};

PiggyBankMissionLogic.prototype.load = function (data) {
    this.level = data.level || 0;
    this.mission.result = data.amount || 0;
};

PiggyBankMissionLogic.prototype.addGameReward = function () {
    Game.currentGame.rewards[GameBase.REWARD_PIGGY_BANK] = this.getLevelReward();
};

PiggyBankMissionLogic.prototype.add = function (amount) {
    this.filled = undefined;
    this.unlocked = undefined;

    if (this.isFull()) {
        return;
    }

    if (this.mission.result < this.getBaseAmount() && this.mission.result + amount >= this.getBaseAmount()) {
        this.unlocked = true;
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.PIGGY_BANK + "_unlocked");
    }

    this.mission.result += amount;

    if (this.isFull()) {
        this.filled = true;
        this.mission.result = Math.min(this.mission.result, this.getMaxAmount());
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.PIGGY_BANK + "_full");
    }

    if (this.unlocked || this.filled) {
        this.mission.setNeedReview(true);
    }

    this.saveProgress();
};

PiggyBankMissionLogic.prototype.getStartAmount = function () {
    return Math.round(this.getBaseAmount() - PiggyBankMissionLogic.WINS_TO_MINIMAL * this.getLevelReward());
};

PiggyBankMissionLogic.prototype.getBaseAmount = function () {
    return PiggyBankMissionLogic.BASE_AMOUNTS[this.level];
};

PiggyBankMissionLogic.prototype.getLevelReward = function () {
    return Math.round((this.getMaxAmount() - this.getBaseAmount()) / PiggyBankMissionLogic.WINS_TO_FULFILL);
};

PiggyBankMissionLogic.prototype.getMaxAmount = function () {
    return Math.round(this.getBaseAmount() * PiggyBankMissionLogic.MAX_COEF / PiggyBankMissionLogic.WINS_TO_FULFILL) * PiggyBankMissionLogic.WINS_TO_FULFILL;
};

PiggyBankMissionLogic.prototype.getCurrentPrice = function () {
    return this.getProduct().getCurrentPrice();
};

PiggyBankMissionLogic.prototype.wasShown = function () {
    return this.isFull() && Math.abs(this.getLastShowAmountProgress() - this.mission.result) >= 0.01;
};

PiggyBankMissionLogic.prototype.showBottomButtonPriceWithAnimation = function () {
    this.onShowBottomButtonPriceWithAnimation();
};

PiggyBankMissionLogic.prototype.setBalance = function (value) {
    this.onSetBalance(value);
};

PiggyBankMissionLogic.prototype.getProgressGoals = function () {
    return [this.getBaseAmount(), this.getMaxAmount()];
};

PiggyBankMissionLogic.prototype.calculateProgress = function (amount) {
    return amount / this.getMaxAmount();
};

PiggyBankMissionLogic.prototype.getLastShowAmountProgress = function () {
    return this.lastShownAmount;
};

PiggyBankMissionLogic.prototype.setLastShowAmountProgress = function (amount) {
    this.lastShownAmount = amount;
};

PiggyBankMissionLogic.prototype.isFull = function () {
    return this.mission.result >= this.getMaxAmount();
};

PiggyBankMissionLogic.prototype.isReadyToFinish = function () {
    var state = this.getState();
    return state === PiggyBankMissionLogic.STATE_BASE || state === PiggyBankMissionLogic.STATE_FULL;
};

PiggyBankMissionLogic.prototype.wantToShowNewState = function () {
    return this.mission.needReview && this.isReadyToFinish();
};

PiggyBankMissionLogic.prototype.processEvent = function (options) {
    this.add(options.amount);
};

PiggyBankMissionLogic.prototype.getProduct = function () {
    return Product.CreateById(PiggyBankMissionLogic.PRODUCTS[this.level]) || Product.CreateById(PiggyBankMissionLogic.VIRTUAL_PRODUCTS[this.level]);
};

PiggyBankMissionLogic.prototype.getRewardCurrency = function () {
    return cleverapps.config.soft !== false ? "soft" : "hard";
};

PiggyBankMissionLogic.prototype.getGoal = function (goal) {
    return this.getRewardCurrency() === "soft" ? "@@" + goal : "$$" + goal;
};

PiggyBankMissionLogic.prototype.getCurrentAmount = function () {
    var amount = cleverapps.formatAmount(this.mission.result);
    return this.getGoal(amount);
};

PiggyBankMissionLogic.prototype.getReward = function () {
    var reward = {};
    reward[this.getRewardCurrency()] = this.mission.result;
    return reward;
};

PiggyBankMissionLogic.prototype.buy = function (closeShopCallback) {
    var state = this.getState();
    if (state === PiggyBankMissionLogic.STATE_UNACTIVE) {
        closeShopCallback();
        return;
    }

    var product = this.getProduct();
    product.reward = this.getReward();
    product.context = {
        type: "piggy_bank",
        mission: Mission.TYPE_PIGGY_BANK
    };

    product.buy(function (success) {
        if (success) {
            this.mission.complete();

            closeShopCallback();
        }
    }.bind(this));
};

PiggyBankMissionLogic.prototype.getControls = function () {
    return cleverapps.config.soft !== false ? "MenuBarCoinsItem" : "MenuBarGoldItem";
};

PiggyBankMissionLogic.prototype.getState = function () {
    var state = PiggyBankMissionLogic.STATE_FULL;

    if (this.mission.result < this.getBaseAmount()) {
        state = PiggyBankMissionLogic.STATE_UNACTIVE;
    } else if (this.mission.result >= this.getBaseAmount() && this.mission.result < this.getMaxAmount()) {
        state = PiggyBankMissionLogic.STATE_BASE;
    }

    return state;
};

PiggyBankMissionLogic.prototype.checkAvailableBottomButton = function () {
    return this.lastShownAmount >= this.getBaseAmount();
};

PiggyBankMissionLogic.prototype.getDescriptionText = function () {
    var state = this.getState();

    var text = "PiggyBankWindow.text1.Full";
    if (state === PiggyBankMissionLogic.STATE_UNACTIVE) {
        text = "PiggyBankWindow.desc1";
    } else if (state === PiggyBankMissionLogic.STATE_BASE) {
        text = "PiggyBankWindow.desc2";
    }

    return text;
};

PiggyBankMissionLogic.RestoreExpeditionId = function () {
    return "main";
};

PiggyBankMissionLogic.RestoreActions = function (context, consume) {
    var mission = cleverapps.missionManager.findByType(context.mission);
    var reward = mission && mission.logic && mission.logic.getReward();
    if (!mission || !mission.isRunning() || !reward) {
        return;
    }

    return [
        function (f) {
            if (mission.isRunning()) {
                mission.complete();
            }

            consume();
            new RewardWindow(reward, { event: cleverapps.EVENTS.EARN.PURCHASE });
            cleverapps.meta.onceNoWindowsListener = f;
        }
    ];
};

PiggyBankMissionLogic.MAX_COEF = 1.5;

PiggyBankMissionLogic.BASE_AMOUNTS = [500, 1800, 6000, 25000];

PiggyBankMissionLogic.PRODUCTS = ["piggybank500", "piggybank1800", "piggybank6000", "piggybank25000"];
PiggyBankMissionLogic.VIRTUAL_PRODUCTS = ["piggybankSmall", "piggybankMedium", "piggybankLarge", "piggybankHuge"];

PiggyBankMissionLogic.WINS_TO_FULFILL = 15;
PiggyBankMissionLogic.WINS_TO_MINIMAL = 10;

PiggyBankMissionLogic.STATE_UNACTIVE = 0;
PiggyBankMissionLogic.STATE_BASE = 1;
PiggyBankMissionLogic.STATE_FULL = 2;

Product.RESTORE_LOGIC["piggy_bank"] = PiggyBankMissionLogic;