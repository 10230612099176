/**
 * Created by Ivan on 23.04.2023
 */

var PrizeBarComponent = cc.Node.extend({
    ctor: function (options) {
        this._super();
        this.options = options || {};
        this.reward = this.options.reward;
        this.canClick = this.options.canClick !== undefined ? this.options.canClick : true;

        this.addProgressBar();
        this.addPrize();

        this.setAnchorPoint(0.5, 0.5);
        cleverapps.UI.wrap(this);

        this.state = PrizeBarComponent.STATE_ACTIVE;
    },

    updateProgress: function (progress, goal, options) {
        this.progressBar.setGoal(goal);
        if (progress >= goal) {
            options = options || {};
            var callback = options.callback;

            options.callback = function () {
                this.setFull();
                if (callback) {
                    callback();
                }
            }.bind(this);
        }
        this.progressBar.setPercentage(progress, options);
    },

    addProgressBar: function () {
        var styles = cleverapps.styles.PrizeBarComponent.progressBar;
        var options = this.options.progressBar || {};

        var progressBar = this.progressBar = new ScaledProgressBar({
            progress: options.progressImage || bundles.progress_bar.frames.bar_dailytask,
            background: options.progressBgImage || bundles.progress_bar.frames.bg_dailytask,
            barText: options.removeBarText ? undefined : Object.assign({
                font: cleverapps.styles.FONTS.PRIZE_BAR_TEXT,
                dy: styles.barText.dy
            }, options.barText)
        });
        progressBar.setLength(options.width || styles.length);
        progressBar.setPositionRound(progressBar.width / 2, progressBar.height / 2);
        this.addChild(progressBar);
    },

    addPrize: function () {
        if (this.prize || this.options.prize === false) {
            return;
        }

        var prize = this.prize = new cleverapps.Spine(this.options.prize || bundles.prize.jsons.prize_json);
        prize.setAnimation(0, "idle", true);

        cleverapps.tooltipManager.create(prize, {
            text: this.options.tooltipText || "Reward",
            rewards: this.reward,
            location: cleverapps.UI.Tooltip.LOCATION_BELOW,
            size: cleverapps.styles.UI.Tooltip.SIZE.short
        });
        prize.setPositionRound(this.options.prizePosition || cleverapps.styles.PrizeBarComponent.prize);
        this.progressBar.addChild(prize);
        prize.replaceParentSamePlace(this);
    },

    setFull: function () {
        if (!this.prize || this.state === PrizeBarComponent.STATE_COLLECTED) {
            return;
        }
        this.state = PrizeBarComponent.STATE_FULL;

        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.1),
            new cc.CallFunc(function () {
                cleverapps.tooltipManager.remove(this.prize);
                cleverapps.audio.playSound(bundles.prize.urls.prize_view_created_effect);

                this.addLighting();
                this.addShine();
                this.prize.setAnimation(0, "animation", true);

                if (this.canClick) {
                    this.addFinger();
                    cleverapps.UI.applyHover(this.prize);
                    cleverapps.UI.onClick(this.prize, this.onClick.bind(this));
                }
            }.bind(this))
        ));
    },

    onClick: function () {
        this.animateCollected();
    },

    animateCollected: function () {
        this.stopAllActions();
        this.prize.runAction(AnimationsLibrary.deSpawn(this.prize, {
            callback: function () {
                this.setCollected();
            }.bind(this) 
        }));
    },

    setCollected: function () {
        this.state = PrizeBarComponent.STATE_COLLECTED;
        this.removeLighting();
        this.removePrize();
        this.addMark();
        this.options.onCollect && this.options.onCollect();
    },

    addMark: function () {
        if (this.options.noMark) {
            return;
        }
        var mark = this.mark = new cc.Sprite(bundles.prize.frames.mark_png);
        this.addChild(mark);
        mark.setPositionRound(cleverapps.styles.PrizeBarComponent.mark);
    },

    addLighting: function () {
        var lightning = this.lightning = new cleverapps.Spine(bundles.progress_bar.jsons.flash_json);
        lightning.setAnimation(0, "animation");
        this.progressBar.addChild(lightning);

        lightning.runAction(new cc.RepeatForever(
            new cc.Sequence(
                new cc.CallFunc(function () {
                    lightning.setVisible(true);
                    lightning.setPositionRound(lightning.width / 2, this.progressBar.height / 2);
                    lightning.setAnimation(0, "animation");
                }.bind(this)),
                new cc.MoveTo(0.4, this.progressBar.width + this.prize.width / 2 - lightning.width / 2, this.progressBar.height / 2),
                new cc.CallFunc(function () {
                    lightning.setVisible(false);
                }),
                new cc.DelayTime(2)
            )
        ));

        return lightning;
    },

    removeLighting: function () {
        if (this.lightning) {
            this.lightning.removeFromParent();
            delete this.lightning;
        }
    },

    removePrize: function () {
        if (this.prize) {
            this.stopAllActions();
            this.prize.removeFromParent();
            delete this.prize;
        }
    },

    addFinger: function () {
        var styles = cleverapps.styles.PrizeBarComponent.prize;
        FingerView.hintClick(this.prize, {
            delay: 0.8,
            scale: styles.finger.scale,
            rotation: styles.finger.rotation
        });
    },

    addShine: function () {
        var shining = this.shine = AnimationsLibrary.rays(this.prize, { skin: "white" });
        shining.setTimeScale(2);
    },

    showUp: function (callback, delay) {
        this.progressBar.barText.setScale(0);
        delay = delay || 0.2;

        this.progressBar.barText.runAction(new cc.Sequence(
            new cc.DelayTime(delay),
            new cc.ScaleTo(0.4, 1).easing(cc.easeBackOut())
        ));

        this.prize.setScale(0);
        this.prize.runAction(new cc.Sequence(
            new cc.ScaleTo(0, 0),
            new cc.Show(),
            new cc.DelayTime(delay),
            new cc.ScaleTo(0.4, 1).easing(cc.easeBackOut()),
            new cc.CallFunc(callback || function () {})
        ));
    },

    receivePrize: function () {
        Object.keys(this.reward).forEach(function (type) {
            var reward = new Reward(type, this.reward[type], { event: this.options.rewardEvent || cleverapps.EVENTS.EARN.OTHER });
            reward.receiveReward();
            reward.collectRewardsAnimation(this.prize, {
                flyingAnimation: Reward.JUMP_COLLECT_ANIMATION
            });
        }.bind(this));
    }
});

PrizeBarComponent.STATE_ACTIVE = 0;
PrizeBarComponent.STATE_FULL = 1;
PrizeBarComponent.STATE_COLLECTED = 2;

cleverapps.styles.PrizeBarComponent = {
    progressBar: {
        length: 400,
        barText: {
            dy: 1
        }
    },
    prize: {
        x: { align: "right", dx: 50 },
        y: { align: "center", dy: 0 },

        flyY: 10,

        finger: {
            scale: 0.7,
            rotation: 20
        }
    },
    mark: {
        x: { align: "right", dx: 80 },
        y: { align: "center", dy: 0 }
    }
};
