/**
 * Created by andrey on 29.05.17.
 */

var TilesBlock = ElementsChooser.extend({
    ctor: function () {
        this._super({
            elements: EditorView.Tiles,
            type: "tile"
        });
    }
}); 

EditorView.Tiles = [GrassTile, IceTile, RabbitTile, TransporterTile, RugTile, DirtyHoneyTile, DirtyHoneyDecoratorCell, Barriers, IceBlockMakerTile, SyrupTile];
EditorView.RiddlesTiles = [];

if (cleverapps.config.name === "riddles") {
    EditorView.Tiles = EditorView.Tiles.concat(EditorView.RiddlesTiles);
}

RabbitTile.EDITOR_CODES = ["T1", "T2", "T", "T2l2", "T1l2", "Tl2"];
TransporterTile.EDITOR_CODES = [">0", ">1", ">2", ">3"];
SyrupTile.EDITOR_CODES = ["ё0", "ё2", "ё4", "ё6"];
Barriers.EDITOR_CODES = ["j0", "j1"];
