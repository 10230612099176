/**
 * Created by vlad on 14.11.18.
 */

var FireflyJarCell = function (x, y) {
    BaseCell.call(this, x, y);
    this.movable = false;

    this.onFlyToGoalListener = function () {};
};

FireflyJarCell.prototype = Object.create(BaseCell.prototype);
FireflyJarCell.prototype.constructor = FireflyJarCell;

FireflyJarCell.prototype.load = function (data) {
    this.lives = parseInt(data[1]);
};

FireflyJarCell.prototype.save = function () {
    return FireflyJarCell.CODES.concat([this.lives]);
};

FireflyJarCell.prototype.getViewClass = function () {
    return FireflyJarCellView;
};

FireflyJarCell.prototype.editorComponents = function () {
    return [BaseCellComponent, FireflyJarComponent];
};

FireflyJarCell.prototype.getGoalId = function () {
    return FireflyJarCell.GOAL_ID;
};

FireflyJarCell.prototype.boom = function () {
    this.hurt();
};

FireflyJarCell.prototype.hurt = function (explodeParams) {
    if (this.lives < 1 || !this.alive) {
        return;
    }

    this.lives--;
    this.animate(BaseCell.ANIMATION_HURT);

    if (Game.currentGame && Game.currentGame.goals.hasType(FireflyJarCell.GOAL_ID)) {
        this.onFlyToGoalListener();
    }

    Game.currentGame.counter.setTimeout(function () {
        if (this.lives <= 0) {
            this.explode(explodeParams);
        }
    }.bind(this), this.hurtDuration() * 1000);
};

FireflyJarCell.prototype.hurtDuration = function () {
    return 1.2;
};

FireflyJarCell.prototype.getMaxLives = function () {
    return 9;
};

FireflyJarCell.prototype.bundleId = function () {
    return "firefly_jar";
};

FireflyJarCell.GOAL_ID = "firefly_jar_cell";