/**
 * Created by Andrey Popov on 20.01.2021.
 */

var InspectorView = WysiwygWindowBase.extend({
    ctor: function (inspector) {
        var styles = cleverapps.styles.InspectorView;
        this._super("Inspector", styles.width, styles.height);

        this.inspector = inspector;

        this.inspector.on("fillProperties", this.fillProperties.bind(this), this);

        this.editorCreators = [];

        this.setLocalZOrder(2);
        this.setPositionRound(styles);
    },

    fillProperties: function (propertyGroups) {
        var styles = cleverapps.styles.InspectorView;
        this.editorCreators = [];

        var groupsView = propertyGroups.map(function (propertyGroup, index) {
            var editors = index > 0 ? [this.createGroupName(propertyGroup.name, propertyGroup.hierarchyItem)] : [];
            if (this.inspector.collapsedGroups.indexOf(propertyGroup.name) === -1) {
                propertyGroup.properties.filter(function (property) {
                    return property.type;
                }).forEach(function (property) {
                    var editor = new InspectorView.EDITORS[property.type](property, propertyGroup.values[property.name], propertyGroup.vch);
                    this.editorCreators.push(editor);
                    editors = editors.concat(editor.propertyEditors);
                }.bind(this));
            }

            var groupView = new cleverapps.Layout(editors, {
                direction: cleverapps.UI.VERTICAL,
                margin: styles.margin
            });

            editors.forEach(function (editor, index) {
                editor.setLocalZOrder(editors.length - index);
            });

            groupView.setLocalZOrder(propertyGroups.length - index);
            return groupView;
        }.bind(this));

        this.createScroll(new cleverapps.Layout(groupsView, {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        }));
    },
    
    createGroupName: function (propertyGroupName, item) {
        var styles = cleverapps.styles.InspectorView.groupName;

        var groupNameNode = new cc.Node();
        groupNameNode.setAnchorPoint(0.5, 0.5);
        groupNameNode.setContentSize2(styles);

        var groupIcon = new cc.Sprite(bundles.wysiwyg.frames.scenario_item);
        groupIcon.setAnchorPoint(0.5, 0.5);
        groupIcon.setPositionRound(styles.icon);
        if (this.inspector.collapsedGroups.indexOf(propertyGroupName) === -1) {
            groupIcon.setRotation(90);
        }
        groupNameNode.addChild(groupIcon);

        var groupNameText = cleverapps.UI.generateOnlyText(propertyGroupName, cleverapps.styles.FONTS.PROPERTY_EDITOR_GREY_TEXT);
        groupNameText.setPositionRound(styles.text);
        groupNameNode.addChild(groupNameText);

        cleverapps.UI.onClick(groupIcon, this.inspector.onGroupIconClick.bind(this.inspector, propertyGroupName));
        cleverapps.UI.onClick(groupNameText, this.inspector.onGroupNameClick.bind(this.inspector, item, propertyGroupName));

        return groupNameNode;
    }
});

InspectorView.EDITORS = {
    "string": EditBoxPropertyEditor,
    "number": EditBoxPropertyEditor,
    "font": FontPropertyEditor,
    "localizedText": LocalizedTextPropertyEditor,
    "position": PositionPropertyEditor,
    "image": ImagePropertyEditor,
    "textBg": TextBgPropertyEditor,
    "spine": SpinePropertyEditor,
    "sound": SoundPropertyEditor,
    "color": ColorPropertyEditor,
    "actions": ActionsPropertyEditor,
    "adHoc": AdHocPropertyEditor,
    "frameCapture": ScreenshotsPropertyEditor,
    "checkBox": CheckBoxPropertyEditor,
    "video": VideoPropertyEditor
};

cleverapps.styles.InspectorView = {
    width: 500,
    height: 1230,

    x: { align: "right" },
    y: { align: "bottom" },

    margin: 5,

    groupName: {
        width: 450,
        height: 30,

        icon: {
            x: { align: "left", dx: 4 },
            y: { align: "center" }
        },

        text: {
            x: { align: "left", dx: 20 },
            y: { align: "center" }
        }
    }
};