/**
 * Created by andrey on 21.08.17.
 */

var BaseAnimation = cc.Node.extend({
    ctor: function (json, lowModeSprite) {
        this._super();

        var jsonLink = json instanceof VirtualJson ? json.resolve() : "";

        if (jsonLink && cc.loader.getRes(jsonLink)) {
            this.animation = new cleverapps.Spine(json);
            this.addChild(this.animation);
        } else if (lowModeSprite) {
            this.sprite = new cc.Sprite(lowModeSprite);
            this.addChild(this.sprite);
        }

        this.inUse = true;
        this.visible = false;
        this.setLocalZOrder(BaseAnimation.ZORDER);

        this.startVisibleListener = function () {};
    },

    setSkin: function (skin) {
        if (this.animation) {
            this.animation.setSkin(skin);
        }
    },

    setStartVisibleListener: function (listener) {
        this.startVisibleListener = listener;
    },

    runAnimate: function (name, onFinish, spriteAction, staticImage) {
        if (spriteAction instanceof cc.Node) {
            staticImage = spriteAction;
        }
        if (onFinish instanceof cc.Node) {
            staticImage = onFinish;
        }
        if (staticImage) {
            this.setStartVisibleListener(function () {
                staticImage.visible = false;
            });
        }

        if (this.animation) {
            this.animation.setStartVisibleListener(this.startVisibleListener);
            this.animation.setAnimation(0, name, false);
            this.animation.setCompleteListener(function () {
                this.finishAnimation(onFinish);
            }.bind(this));
        } else {
            if (spriteAction && this.sprite) {
                this.sprite.runAction(new cc.Sequence(spriteAction, new cc.CallFunc(function () {
                    this.finishAnimation(onFinish);
                }.bind(this))));
            } else {
                this.runAction(new cc.CallFunc(function () {
                    this.finishAnimation(onFinish);
                }.bind(this)));
            }

            this.startVisibleListener();
        }
        this.visible = true;
    },

    finishAnimation: function (onFinish) {
        if (this.inUse) {
            this.inUse = false;

            if (typeof onFinish === "function") {
                onFinish();
            }

            cc.pool.putInPool(this);
        }
    },

    unuse: function () {
        this.removeFromParent(false);
        this.visible = false;
        if (this.animation) {
            this.animation.visible = false;
            this.animation.setStartVisibleListener(function () {});
            this.animation.setCompleteListener();
        }
        this.startVisibleListener = function () {};
    },

    reuse: function () {
        this.inUse = true;
        if (this.sprite) {
            this.sprite.setOpacity(255);
            this.sprite.setScale(1);
        }
    }
});

BaseAnimation.runAnimate = function (parent, json, name, onFinish, staticImage) {
    var animation = BaseAnimation.factory(json);
    animation.setPositionRound(parent.width / 2, parent.height / 2);
    parent.addChild(animation);

    animation.runAnimate(name, onFinish, staticImage);
};

var DummyAnimation = cc.Node.extend({
    ctor: function (defaultSprite) {
        this._super();
        this.inUse = true;
        if (defaultSprite) {
            this.image = new cc.Sprite(defaultSprite);
            this.addChild(this.image);
        }
    },
    
    runAnimate: function (name, onFinish, spriteAction, staticImage) {
        if (spriteAction instanceof cc.Node) {
            staticImage = spriteAction;
        }
        if (onFinish instanceof cc.Node) {
            staticImage = onFinish;
        }
        this.runAction(new cc.Sequence(
            new cc.DelayTime(DummyAnimation.DURATION),
            new cc.CallFunc(function () {
                if (staticImage && staticImage.isRunning()) {
                    staticImage.setVisible(false);
                }
                this.finishAnimation(onFinish);
            }.bind(this))
        ));
    },
    
    finishAnimation: function (onFinish) {
        if (this.inUse) {
            this.inUse = false;

            if (typeof onFinish === "function") {
                onFinish();
            }

            this.removeFromParent();
        }
    },
    
    // eslint-disable-next-line no-unused-vars
    setSkin: function (skin) {
    }
});

DummyAnimation.DURATION = 0.2;
BaseAnimation.DEFAULT_LIMIT = 9;
BaseAnimation.BOX_DECORATOR_LIMIT = 50;

var BaseAnimationClasses = {
};

var BaseAnimationFactories = {
};

BaseAnimation.getClass = function (json) {
    var key = json;
    var BaseAnimationClass = BaseAnimationClasses[key];
    if (!BaseAnimationClass) {
        BaseAnimationClasses[key] = BaseAnimationClass = BaseAnimation.extend({
            ctor: function () {
                this._super(json); 
            } 
        });
    }
    return BaseAnimationClass;
};

var BaseAnimationSemaphore = function (amount) {
    this.amount = amount;
};

BaseAnimationSemaphore.prototype.lock = function (delay) {
    if (this.amount > 0) {
        this.amount--;
        delay = delay || 0.3;
        setTimeout(function () {
            this.amount++;
        }.bind(this), delay * 1000);

        return true;
    }
};

BaseAnimation.semaphore = function (key, amount) {
    var code = "semaphore_" + key;
    var semaphore = BaseAnimationFactories[code];
    if (!semaphore) {
        semaphore = BaseAnimationFactories[code] = new BaseAnimationSemaphore(amount);
    }
    return semaphore;
};

BaseAnimation.factoryLimited = function (json, limit, defaultSprite) {
    limit = limit || BaseAnimation.DEFAULT_LIMIT;
    return BaseAnimation.factory(json, limit, defaultSprite);
};

BaseAnimation.factory = function (json, limit, defaultSprite) {
    var key = json + "_" + limit; // defaultSprite can be undefined, better not include it to key
    limit = limit || 1 << 16;
    var factory = BaseAnimationFactories[key];
    if (!factory) {
        factory = BaseAnimationFactories[key] = function () {
            var BaseAnimationClass = BaseAnimation.getClass(json);
            if (cc.pool.hasObject(BaseAnimationClass)) {
                return cc.pool.getFromPool(BaseAnimationClass);
            }

            if (limit > 0) {
                limit--;
                return new BaseAnimationClass();
            } 
            return new DummyAnimation(defaultSprite);
        };
    }
    return factory();
};

BaseAnimation.ZORDER = 10;
