/**
 * Created by Ivan on 31.07.2024
 */

var RewardStickerView = cc.Node.extend({
    ctor: function (sticker, stickerCollectView) {
        this._super();
        this.stickerCollectView = stickerCollectView;
        var ViewClass = sticker.getViewClass();
        var stickerView = this.stickerView = new ViewClass(sticker, {
            badge: true
        });
        stickerView.setAnchorPoint(0.5, 0.5);

        this.setAnchorPoint(0.5, 0);
        this.setVisible(false);
        this.setCascadeOpacityEnabledRecursively(true);
        this.setContentSize2(stickerView.getContentSize());

        this.addChild(stickerView);
        stickerView.setPositionRound(this.width / 2, this.height / 2);
    },

    receiveStickerAnimation: function (delay) {
        this.setOpacity(0);

        var actions = new cc.Sequence(
            new cc.Hide(),
            new cc.DelayTime(delay || 0),
            AnimationsLibrary.spawn(this),
            new cc.CallFunc(function () {
                this.stickerView.ray = AnimationsLibrary.rays(this.stickerView, { skin: "blue" });
            }.bind(this))
        );

        var badges = this.stickerView.sticker.duplicate ? this.addDuplicate() : this.addBadge();
        var hoveringOptions = {
            animationTime: cleverapps.Random.random(4, 8),
            leftAngle: cleverapps.Random.random(2, 12),
            rightAngle: cleverapps.Random.random(2, 12),
            delayTime: Math.random() * 0.5
        };
        actions = new cc.Sequence(
            actions,
            badges,
            AnimationsLibrary.hovering(this, hoveringOptions)
        );

        return new cc.TargetedAction(this, actions);
    },

    collectStickerAction: function () {
        return new cc.TargetedAction(this.stickerView, new cc.Sequence(
            new cc.ScaleTo(0.1, 1.1),
            new cc.ScaleTo(0.1, 0.7),
            new cc.CallFunc(function () {
                this.stickerView.ray && this.stickerView.ray.removeFromParent();
            }.bind(this)),
            new cc.RemoveSelf()
        ));
    },

    addDuplicate: function () {
        var duplicateLabel = this.stickerView.duplicateLabel = cleverapps.UI.generateOnlyText("Duplicate.Text", cleverapps.styles.FONTS.DUPLICATE_TEXT);
        this.stickerView.addChild(duplicateLabel);
        duplicateLabel.setPositionRound(cleverapps.styles.StickerView.text);
        duplicateLabel.setVisible(false);

        return new cc.Sequence(
            new cc.CallFunc(function () {
                duplicateLabel.setScale(2);
                duplicateLabel.setVisible(true);
            }),
            new cc.Spawn(
                new cc.TargetedAction(duplicateLabel, new cc.ScaleTo(0.4, 1).easing(cc.easeBackOut()))
            )
        );
    },

    addBadge: function () {
        this.stickerView.createBadge("stickers_reward_window");
        this.stickerView.badge.setVisible(false);
        this.stickerView.badge.setScale(3);
        return new cc.TargetedAction(this.stickerView.badge, new cc.Sequence(
            new cc.Show(),
            new cc.CallFunc(function () {
                cleverapps.audio.playSound(bundles.stickers_reward_window.urls.sticker_new_effect);
            }),
            new cc.ScaleTo(0.2, 1).easing(cc.easeBackOut()),
            new cc.DelayTime(0.2)
        ));
    }
});

cleverapps.styles.RewardStickerView = {

    collectAnimation: {
        scale: 0.7
    }

};