/**
 * Created by andrey on 01.08.2022
 */

var SaveFinalResultsAction = function (f) {
    var outcome = this.game.outcome;

    if (outcome === GameBase.OUTCOME_VICTORY && this.level.isDailyCup()) {
        cleverapps.dailyCup.addAmount(this.game.getDailyCupStars());
        cleverapps.dailyCup.reload(true);
        cleverapps.playSession.set(cleverapps.EVENTS.CUP_PLAYERS + cleverapps.dailyCup.type, true);
        cleverapps.eventBus.trigger("taskEvent", DailyTasks.PASS_DAILY_CUP_LEVEL);
    }

    if (outcome === GameBase.OUTCOME_VICTORY && cleverapps.config.type === "match3") {
        if (!cleverapps.user.isPassedAll()) {
            var levelNo = this.level.getHumanReadableNumber();
            cleverapps.activities.send(Activities.TYPES.LVL_PASSED, {
                userName: cleverapps.user.getPlayerInfo().name,
                score: this.game.score.getPoints(),
                levelNo: levelNo
            });
        }

        if (this.level.isRegular() && this.level.isNew() && cleverapps.exp.isAvailable() && !this.game.rewards[GameBase.REWARD_EXP]) {
            this.game.rewards[GameBase.REWARD_EXP] = this.level.isHard() ? GameBase.HARD_LEVEL_EXP_PRIZE : GameBase.EXP_PRIZE;
        }

        if (this.level.isRegular() && this.level.isNew() || this.level.isDailyCup()) {
            this.game.rewards.stars = this.game.score.stars;
        }

        if (this.level.isRegular()) {
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.LEVEL_STARS, {
                hash: this.level.hash,
                stars: this.game.score.stars
            });
        }
    }

    if (this.game.rewards[GameBase.REWARD_BOOSTERS]) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.BOOSTER_CELL_RECEIVED);
    }

    if (this.level.isCurrentLevel()) {
        if (outcome === GameBase.OUTCOME_VICTORY) {
            cleverapps.eventBus.trigger("taskEvent", DailyTasks.PASS_LEVEL, { level: this.level.getHumanReadableNumber() });

            if (this.level.isHard()) {
                cleverapps.eventBus.trigger("taskEvent", DailyTasks.PASS_LEVEL_HARD);
            }
        }

        if (cleverapps.isKnockoutGame()) {
            if (cleverapps.meta.getRumble().outcome === Rumble.OUTCOME_WIN) {
                cleverapps.user.incLevel();
            }
        } else if (outcome === GameBase.OUTCOME_VICTORY && cleverapps.meta.getType() !== Metha.FARM) {
            cleverapps.user.incLevel();
        }
    }

    if (cleverapps.config.type === "tile3") {
        cleverapps.eventBus.trigger("taskEvent", DailyTasks.COLLECT_TILES, this.game.collectedTiles);
    }

    if (cleverapps.config.type === "blocks") {
        if (this.game.getMode() === GameBase.MODE_HIGHSCORE) {
            cleverapps.eventBus.trigger("taskEvent", DailyTasks.SCORE_POINTS, this.game.score.points);
        }
        cleverapps.eventBus.trigger("taskEvent", DailyTasks.EXPLODE_LINES, this.game.explodedLines);
    }

    cleverapps.restoreProgress.update();

    f();
};