/**
 * Created by slava on 02.08.17.
 */

var IceBlockDecoratorCell = function (x, y, options) {
    BaseCell.call(this, x, y, options);

    this.controlDisabled = true;
    this.movable = true;
    this.putInPoolAvailable = true;
};

IceBlockDecoratorCell.prototype = Object.create(BaseCell.prototype);
IceBlockDecoratorCell.prototype.constructor = IceBlockDecoratorCell;

IceBlockDecoratorCell.prototype.getViewClass = function () {
    return IceBlockDecoratorCellView;
};

IceBlockDecoratorCell.prototype.load = function (data, loader) {
    data.shift();
    this.lives = parseInt(data[0]);
    data.shift();
    this.setInnerCell(loader.loadNext(this.y, this.x, data));
};

IceBlockDecoratorCell.prototype.bundleId = function () {
    return "ice_block";
};

IceBlockDecoratorCell.prototype.save = function () {
    var saveData = IceBlockDecoratorCell.CODES;
    saveData = saveData.concat(this.lives);
    return saveData.concat(this.innerCell.save());
};

IceBlockDecoratorCell.prototype.getColor = function () {
    return this.innerCell.getColor();
};