/**
 * Created by razial on 09.04.2024.
 */

cleverapps.EventsQueue = function (interval) {
    this.data = [];
    this.interval = cleverapps.parseInterval(interval);
};

cleverapps.EventsQueue.prototype.add = function (event) {
    this.data.push(event);
    this.data.sort();

    this.shrink();
};

cleverapps.EventsQueue.prototype.first = function () {
    this.shrink();
    if (this.data.length > 0) {
        return this.data[0];
    }
};

cleverapps.EventsQueue.prototype.shrink = function () {
    while (this.data.length > 0 && this.data[0] + this.interval <= Date.now()) {
        this.data.shift();
    }
};

cleverapps.EventsQueue.prototype.getShrinkLeftTime = function () {
    if (this.data.length > 0) {
        return this.data[0] + this.interval - Date.now();
    }
};

cleverapps.EventsQueue.prototype.size = function () {
    this.shrink();

    return this.data.length;
};
