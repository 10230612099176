/**
 * Created by mac on 7/24/17.
 */

var ScoreView = cc.Node.extend({
    ctor: function (score) {
        this._super();

        this.score = score;

        this.setAnchorPoint(0.5, 0.5);

        this.bar = new ScaledProgressBar({
            background: bundles.progress_bar.frames.bg_score,
            progress: bundles.progress_bar.frames.bar_score
        });
        this.bar.setAnchorPoint(0, 0);
        this.addChild(this.bar);
        this.updateSize();
        this.stars = [];
        this.stars.push(new StarView());
        this.stars.push(new StarView());
        this.stars.push(new StarView());

        for (var i = 0; i < 3; i++) {
            this.addChild(this.stars[i]);
        }

        score.on("onChange", this.change.bind(this), this);

        if (cleverapps.config.debugMode && !cleverapps.config.editorMode && !cleverapps.config.wysiwygMode) {
            this.percent = cleverapps.UI.generateImageText(0, cleverapps.styles.FONTS.MOVES_TEXT);
            this.percent.setPosition(cleverapps.styles.UI.ScoreView.percent);
            this.percent.setScale(cleverapps.styles.UI.ScoreView.percent.scale);
            this.addChild(this.percent);
        }
        this.setupChildren();
    },

    updateSize: function () {
        var styles = cleverapps.styles.UI.ScoreView;
        var modeStyles = styles.modes[cleverapps.resolution.mode];
        this.setRotation(modeStyles.rotation || 0);
        this.setContentSize(modeStyles.width, this.bar.height);
        this.bar.setLength(this.width);
    },

    setupChildren: function () {
        var styles = cleverapps.styles.UI.ScoreView;
        var modeStyles = styles.modes[cleverapps.resolution.mode];
        modeStyles.x = modeStyles.x || 0;
        this.setPositionRound(modeStyles);
        this.bar.setPositionRound(styles.progressBar);
        this.stars.forEach(function (star, i) {
            var x = Score.COEFS[i] * this.width;
            this.stars[i].setPositionRound(x, this.height / 2 + styles.stars.offsetY);
            if (modeStyles.rotation) {
                this.stars[i].setRotation(-modeStyles.rotation);
            }
        }.bind(this));
    },

    change: function (data) {
        var percent = data.percent;
        var star = data.star;

        if (percent !== undefined) {
            if (percent > 100) {
                percent = 100;
            }

            this.bar.setPercentage(percent, {
                animated: true
            });

            if (cleverapps.config.debugMode && !cleverapps.config.editorMode && this.percent) {
                this.percent.setString(Math.round(percent));
            }
        }

        if (star !== undefined) {
            this.stars[star - 1].on();
            cleverapps.audio.playSound(bundles.main.urls.score_star_effect);
        }
    }
});

var StarView = cleverapps.Spine.extend({
    ctor: function () {
        this._super(bundles.game.jsons.stars_json);

        this.setAnimation(0, "idle_off", true);

        this.setLocalZOrder(11);
    },

    on: function () {
        var scene = cleverapps.scenes.getRunningScene();

        var parent = this.getParent();

        this.replaceParentSamePlace(scene, { keepRotation: true });

        this.setAnimation(0, "animation", false);

        this.setCompleteListener(function () {
            this.setAnimation(0, "idle_on", true);

            this.replaceParentSamePlace(parent, { keepRotation: true });

            this.setCompleteListener();
        }.bind(this));
    }
});

cleverapps.styles.UI.ScoreView = {
    modes: [{
        x: { align: "center", dx: 75 },
        y: { align: "bottom", dy: 12 },
        width: 440
    }, {
        x: { align: "center", dx: 75 },
        y: { align: "bottom", dy: 12 },
        width: 440
    }, {
        x: { align: "center", dx: 80 },
        y: { align: "center", dy: 31 },
        width: 420,
        rotation: -90,
        scaleX: -1
    }],

    progressBar: {
        x: { align: "center" },
        y: { align: "center", dy: 3 }
    },

    percent: {
        x: 650,
        y: 12,
        scale: 0.7
    },

    stars: {
        offsetY: 3
    }
};