/**
 * Created by Denis Kuzin on 05 july 2024
 */

var DecoratorComponentView = cc.Node.extend({
    ctor: function (component, cellView) {
        this._super();

        this.component = component;
        this.cellView = cellView;

        this.createSubView();
    },

    createSubView: function () {
        if (this.component.cell.innerCell) {
            Game.currentGame.field.cellEvents(this.component.cell.innerCell);
        }
    },

    startExploding: function (goal, delta) {
        if (this.cellView instanceof GumDecoratorCellView) {
            return false;
        }

        if (Game.currentGame.goals.hasType(this.component.cell.getGoalId())) {
            this.cellView.startExplodingViaCollect(goal, delta);
        } else {
            this.cellView.animateExplode(function () {
                this.cellView.delete();
            }.bind(this));
        }

        return true;
    }
});