/**
 * Created by spepa on 28.02.2024
 */

var PromoCodes = function () {};

PromoCodes.prototype.hasAvailableCodes = function () {
    for (var code in PromoCodes.CODES) {
        if (this.canUseCode(code)) {
            return true;
        }
    }
    return false;
};

PromoCodes.prototype.isUsedCode = function (code) {
    var used = cleverapps.dataLoader.load(DataLoader.TYPES.PROMOCODES) || [];
    return used.includes(code);
};

PromoCodes.prototype.canUseCode = function (code) {
    if (!code || this.isUsedCode(code)) {
        return false;
    }

    var config = PromoCodes.CODES[code];
    if (!config || (config.available && !levels.user.checkAvailable(config.available))) {
        return false;
    }
    return true;
};

PromoCodes.prototype.save = function (code) {
    var used = cleverapps.dataLoader.load(DataLoader.TYPES.PROMOCODES) || [];
    cleverapps.dataLoader.save(DataLoader.TYPES.PROMOCODES, used.concat(code));
};

PromoCodes.prototype.useCode = function (code) {
    if (!this.canUseCode(code)) {
        return false;
    }
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.PROMOCODE_REWARD + code);
    this.save(code);
    return PromoCodes.CODES[code].reward;
};

PromoCodes.CODES = {
    KING: {
        available: {
            disabled: true,
            mergecraft: {
                source: ["mygames", "test", "rustore"]
            },
            wondermerge: {
                source: ["test", "rustore"]
            }
        },
        reward: {
            energy: 100,
            hard: 5,
            soft: 100,
            worker: "6 hour"
        }
    }
};