/**
 * Created by slava on 02.08.17.
 */

var ChainDecoratorCell = function (x, y, options) {
    BaseCell.call(this, x, y, options);
};

ChainDecoratorCell.prototype = Object.create(BaseCell.prototype);
ChainDecoratorCell.prototype.constructor = ChainDecoratorCell;

ChainDecoratorCell.prototype.getViewClass = function () {
    return ChainDecoratorCellView;
};

ChainDecoratorCell.prototype.load = function (data, loader) {
    data.shift();
    if (data[0] === "2") {
        data.shift();
        this.lives = 2;
    }
    
    this.setInnerCell(loader.loadNext(this.y, this.x, data));
};

ChainDecoratorCell.prototype.save = function () {
    var saveData = ChainDecoratorCell.CODES;
    if (this.lives === 2) {
        saveData = saveData.concat("2");
    }
    return saveData.concat(this.innerCell.save());
};

ChainDecoratorCell.prototype.getColor = function () {
    return this.innerCell.getColor();
};

ChainDecoratorCell.prototype.bundleId = function () {
    return "chain_decorator";
};