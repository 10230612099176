/**
 * Created by olga on 27.02.2023
 */

var VirtualProduct = function (data) {
    Product.call(this, data);
};

VirtualProduct.prototype = Object.create(Product.prototype);
VirtualProduct.prototype.constructor = VirtualProduct;

VirtualProduct.prototype.getCurrentPrice = function () {
    return this.currency === "hard" ? "$$" + this.price : "@@" + this.price;
};

VirtualProduct.prototype.getUsdPrice = function () {
    return 0;
};

VirtualProduct.prototype.buy = function (closeShopCallback) {
    var spendCurrency = this.currency === "hard" ? levels.user.spendHard : levels.user.spendSoft;
    var spentEventName = this.spentEvent || this.getSpentEventFromContext();

    if (spendCurrency.call(levels.user, spentEventName, this.price)) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.BUY_VIRTUAL_ITEM + "-" + spentEventName);

        if (this.reward) {
            new RewardWindow(this.reward, { event: spentEventName });
        }

        cleverapps.audio.playSound(bundles.main.urls.shop_buy_effect);
        closeShopCallback(true);

        return true;
    }

    return false;
};

VirtualProduct.prototype.getSpentEventFromContext = function () {
    if (!this.context) {
        return;
    }

    switch (this.context.type) {
        case "mission_offer":
        case "chain_sale":
        case "supplies_sale":
        case "promotion_sale":
            return cleverapps.EVENTS.SPENT.OFFER + Offers[this.context.offer].name;
        case "special_offer": return cleverapps.EVENTS.SPENT.GENIE_ENERGY;
        case "third_element": return cleverapps.EVENTS.SPENT.THIRD_ELEMENT;
        case "offers": return cleverapps.EVENTS.SPENT.PIXEL_OFFER;
        case "fogs": return cleverapps.EVENTS.SPENT.PAY_ISLAND;
        case "worker": return cleverapps.EVENTS.SPENT.WORKER;
        case "last_chance": return cleverapps.EVENTS.SPENT.LAST_CHANCE;
        case "pass_ticket": return cleverapps.EVENTS.SPENT.BATTLE_PASS;
    }
};
