/**
 * Created by Denis Kuzin on 08 august 2024
 */

var MarkComponent = function (cell) {
    this.cell = cell;

    this.mark = undefined;
    this.missionMark = undefined;
};

MarkComponent.prototype.addMark = function (mark) {
    this.mark = mark || new Mark(this.cell);
};

MarkComponent.prototype.removeMark = function () {
    this.mark = undefined;
};

MarkComponent.prototype.explode = function () {
    if (this.mark) {
        this.mark.explode();
        this.mark = undefined;
    }
};

MarkComponent.prototype.smile = function () {
    if (this.mark) {
        this.mark.smile();
    }
};

MarkComponent.prototype.repaint = function (cell) {
    var markComponent = cell.findComponent(MarkComponent);

    if (markComponent) {
        if (markComponent.mark) {
            this.addMark();
        }

        this.setMissionMark(markComponent.missionMark);
    }

    return this.cell;
};

MarkComponent.prototype.addMissionMark = function () {
    if (!this.mark && Game.currentGame && Game.currentGame.outcome === GameBase.OUTCOME_UNKNOWN) {
        if (Game.currentGame.getMissionType() === Mission.TYPE_COLLECT_MARK) {
            if (cleverapps.config.debugMode) {
                if (cleverapps.config.editorMode) {
                    return;
                }
            }

            if (Math.random() < 0.1) {
                this.missionMark = true;
            }
        }
    }
};

MarkComponent.prototype.setMissionMark = function (missionMark) {
    this.missionMark = missionMark;
};

MarkComponent.prototype.load = function (data) {
    if (data.includes(Mark.CODES[0])) {
        this.addMark();
    }
};

MarkComponent.prototype.save = function () {
    if (this.mark) {
        return this.mark.code;
    }
};
