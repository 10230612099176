/**
 * Created by ivan on 24.04.18.
 */

var DirtMakerCellView = BaseCellView.extend({
    ctor: function (dirtMakerCell) {
        this._super();

        this.setContentSize(cleverapps.styles.BaseCellView.CELL_SIZE_PX, cleverapps.styles.BaseCellView.CELL_SIZE_PX);
        this.setAnchorPoint(0.5, 0.5);

        var image = new cc.Sprite(this.getImage(dirtMakerCell.stage));
        image.setAnchorPoint(0.5, 0.5);
        image.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(image);
        this.image = image;

        this.initialize(dirtMakerCell);
        this.addAnimation();
    },

    initialize: function (dirtMakerCell) {
        this._super(dirtMakerCell);

        dirtMakerCell.onChangeStage = this.changeStage.bind(this);
        dirtMakerCell.onShoot = this.shoot.bind(this);
    },

    unuse: function () {
        this.cell.onChangeStage = function () {};

        this._super();
    },

    addAnimation: function () {
        var animation = new cleverapps.Spine(bundles.dirt_maker.jsons.dirt_maker_spine_json);
        animation.setPosition(this.width / 2, this.height / 2);
        this.addChild(animation);
        this.animation = animation;
        if (cleverapps.styles.DirtMakerCellView.idleAnimation && this.cell.stage > 0) {
            animation.visible = true;
            this.animation.setAnimation(0, this.cell.stage + cleverapps.styles.DirtMakerCellView.idleAnimation, true);
        }
    },

    getImage: function (stage) {
        return bundles.dirt_maker.frames["dirt_maker_stable_" + stage];
    },

    changeStage: function (callback) {
        if (!this.animation) {
            this.addAnimation();
        }
        this.animation.setStartVisibleListener(function () {
            this.image.visible = false;
        }.bind(this));
        this.animation.setAnimation(0, "" + this.cell.stage, false);
        this.animation.setCompleteListener(function () {
            this.image.setSpriteFrame(this.getImage(this.cell.stage));
            if (cleverapps.styles.DirtMakerCellView.idleAnimation) {
                this.animation.setCompleteListener();
                this.animation.setAnimation(0, this.cell.stage + cleverapps.styles.DirtMakerCellView.idleAnimation, true);
            } else {
                this.image.visible = true;
                this.animation.visible = false;
            }
            if (callback) {
                callback();
            }
        }.bind(this));
        cleverapps.audio.playSound(bundles.game.urls.dirt_maker_fill);
    },

    shoot: function (x, y) {
        if (!this.animation) {
            this.addAnimation();
        }
        var zorder = this.getLocalZOrder();
        this.setLocalZOrder(10);
        this.animation.setStartVisibleListener(function () {
            this.image.visible = false;
        }.bind(this));
        this.animation.setAnimation(0, "" + DirtMakerCell.STAGES_COUNT, false);
        this.animation.setCompleteListener(function () {
            this.image.setSpriteFrame(this.getImage(this.cell.stage));
            this.image.visible = true;
            this.setLocalZOrder(zorder);
        }.bind(this));

        var dirt = new cc.Sprite(bundles.dirt_maker.frames.dirt_blob);
        this.parent.addChild(dirt);
        dirt.setPosition(this.getPosition());
        dirt.setScale(0);
        var targetPosition = BaseCellView.alignInTheGrid(x, y);
        var maxY = Math.max(this.y, targetPosition.y) + cleverapps.styles.DirtMakerCellView.fly.y;
        var p1 = cc.p(this.x, maxY);
        var p2 = cc.p(targetPosition.x, maxY);
        var p3 = cc.p(targetPosition.x, targetPosition.y + cleverapps.styles.BaseCellView.CELL_SIZE_PX / 2);
        dirt.runAction(new cc.Sequence(
            new cc.DelayTime(cleverapps.styles.DirtMakerCellView.shot.delay),
            new cc.CallFunc(function () {
                cleverapps.audio.playSound(bundles.game.urls.dirt_maker_explode);
            }),
            new cc.Spawn(
                new cc.ScaleTo(0.2, 2),
                new cc.BezierTo(1, [p1, p2, p3])
            ),
            new cc.RemoveSelf()
        ));
    },

    hurtAnimation: function () {

    }
});

cleverapps.styles.DirtMakerCellView = {
    fly: {
        y: 300
    },

    shot: {
        delay: 1
    }
};