/**
 * Created by slava on 01.08.17.
 */

var MultiColorCell = function (x, y) {
    // if (!MultiColorCell.IsAvailable()) {
    //     return;
    // }

    BaseCell.call(this, x, y);

    this.movable = true;
    this.explodeOnGameFinish = true;
    
    this.putInPoolAvailable = true;
    this.fallThroughBarrier = true;
};

MultiColorCell.prototype = Object.create(BaseCell.prototype);
MultiColorCell.prototype.constructor = MultiColorCell;

MultiColorCell.prototype.load = function () {
    // do nothing
};

MultiColorCell.prototype.save = function () {
    return MultiColorCell.CODES;
};

MultiColorCell.prototype.canMoveWith = function (neighbor) {
    return neighbor.findComponent(ColorComponent) || (neighbor instanceof MultiColorCell);
};

MultiColorCell.prototype.updateMoveHover = function (value) {
    var cells = Game.currentGame.field.cells;
    for (var i = 0; i < Field.SIZE; i++) {
        for (var j = 0; j < Field.SIZE; j++) {
            if (cells[i][j] && cells[i][j] !== this) {
                if ((this.currentHoverColor === -1 && cells[i][j].hurtable) || cells[i][j].getColor() === this.currentHoverColor) {
                    cells[i][j].hover(value);
                }
            }
        }
    }
};

MultiColorCell.prototype.removeMoveHover = function () {
    if (this.currentHoverColor) {
        this.updateMoveHover(false);
        this.currentHoverColor = undefined;
    }
};

MultiColorCell.prototype.addMoveHover = function () {
    if (this.currentHoverColor) {
        this.updateMoveHover(true);
    }
};

MultiColorCell.prototype.onRemoveSelection = function () {
    this.removeMoveHover();
};

MultiColorCell.prototype.onMouseMove = function (cell) {
    var cellColor = undefined;
    if (cell && cell.hurtable && Math.abs(cell.x - this.x) + Math.abs(cell.y - this.y) === 1 && cell.movable) {
        if (cell instanceof MultiColorCell) {
            cellColor = -1;
        } else {
            var colorComponent = cell.findComponent(ColorComponent);
            cellColor = colorComponent && colorComponent.color;
        }
    }

    if (this.currentHoverColor === cellColor) {
        return;
    }

    this.removeMoveHover();
    this.currentHoverColor = cellColor;
    this.addMoveHover();
};

MultiColorCell.prototype.getViewClass = function () {
    return MultiColorCellView;
};

MultiColorCell.prototype.explodeDuration = function () {
    return 0.5;
};

MultiColorCell.prototype.chooseRandomColor = function () {
    var cells = Game.currentGame.field.cells;

    var colors = [];
    for (var i = 0; i < Field.SIZE; i++) {
        for (var j = 0; j < Field.SIZE; j++) {
            var colorComponent = cells[i][j] && cells[i][j].findComponent(ColorComponent);
            if (colorComponent && colorComponent.color) {
                colors.push(colorComponent.color);
            }
        }
    }

    if (colors.length === 0) {
        return undefined;
    }

    return cleverapps.Random.choose(colors);
};

MultiColorCell.prototype.onExplode = function () {
    if (this.hurtByMulticolor) {
        return;
    }
    
    BaseCell.prototype.onExplode.call(this);

    if (this.chosenColor === undefined) {
        this.chosenColor = this.chooseRandomColor();
    }

    var toExplode = [];
    for (var i = 0; i < Field.SIZE; i++) {
        for (var j = 0; j < Field.SIZE; j++) {
            var cell = Game.currentGame.field.cells[i][j];
            if (cell && cell.alive && cell.lives > 0 && (cell.getColor() === this.chosenColor || this.chosenColor === MultiColorCell.COLOR_ALL)) {
                toExplode.push(cell);
            }
        }
    }

    toExplode.forEach(function (cell) {
        cell.prepareForBurn();
    });

    cleverapps.eventBus.trigger("taskEvent", DailyTasks.USE_MULTI_COLOR_CELL);

    var toAnimate = [];
    Array.prototype.push.apply(toAnimate, toExplode);
    cleverapps.shuffle(toAnimate).forEach(function (cell) {
        cell.animate(BaseCell.ANIMATION_MULTI_COLOR_SELECT, { multiColorCell: this });
    }.bind(this));

    var isTwoPiesCase = (this.chosenColor === MultiColorCell.COLOR_ALL);

    toExplode.forEach(function (cell, id) {
        if (!isTwoPiesCase) {
            cell.boomNeighbors(1);
        } else if (cell instanceof MultiColorCell) {
            cell.hurtByMulticolor = true; // other pies should not explode and hurt cells
        }
        cell.hurt({
            id: id,
            groupSize: toExplode.length
        });
        RugTile.hurtViaCombo(this, cell);
    }.bind(this));
};

MultiColorCell.COLOR_ALL = -1;
MultiColorCell.MAX_SPECIAL_CREATE = 5;

MultiColorCell.prototype.crazyActionDuplicateCombos = function (neighbor) {
    this.chosenColor = neighbor.getColor();
    var variants = [];
    for (var i = 0; i < Field.SIZE; i++) {
        for (var j = 0; j < Field.SIZE; j++) {
            var cell = Game.currentGame.field.cells[i][j];
            if (cell && cell.canDelete() && cell.getColor() === this.chosenColor) {
                variants.push(cell);
            }
        }
    }
    if (variants.length > MultiColorCell.MAX_SPECIAL_CREATE) {
        variants = cleverapps.shuffle(variants).slice(0, MultiColorCell.MAX_SPECIAL_CREATE);
    }
    variants.forEach(function (cell) {
        var specialCell = ComboComponent.Create(cell, {
            neighbor: neighbor
        });
        cell.delete();
        Game.currentGame.field.addCell(specialCell);
        specialCell.animate(BaseCell.ANIMATION_CREATE);
    });

    this.explode();
};

MultiColorCell.prototype.crazyActionSimple = function (neighbor) {
    this.chosenColor = neighbor.getColor();
    this.explode();
};

MultiColorCell.IsAvailable = function () {
    if (cleverapps.gameModes.multiColorCellDisabled) {
        return false;
    }

    return cleverapps.user.checkAvailable(MultiColorCell.AVAILABLE);
};

MultiColorCell.AVAILABLE = {
    level: 0.26
};