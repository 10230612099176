/**
 * Created by ivan on 04.06.18.
 */

var RabbitTileView = cc.Node.extend(Object.assign({}, BaseTileView, {
    ctor: function (tile) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);

        this.initialize(tile);
        this.setLocalZOrder(3);

        var bundle = this.tile.bundleId();

        if (tile.isForGoal) {
            this.image = new cc.Sprite(bundles[bundle].frames[bundle + "_goal"]);
            this.image.setPosition(this.width / 2, this.height / 2);
            this.addChild(this.image);
        } else if (tile.isForEditor) {
            if (tile.size) {
                this.image = new cc.Sprite(bundles.editor.frames["rabbitTile" + tile.getSkinSuffix() + tile.size + "_" + tile.lives]);
            } else if (tile.lives > 1) {
                this.image = new cc.Sprite(bundles.editor.frames["rabbitTileCover_" + tile.lives]);
            } else {
                this.image = new cc.Sprite(bundles[bundle].frames[bundle + "_tile_cover_2"]);
            }
            this.image.setPosition(this.width / 2, this.height / 2);
            this.addChild(this.image);
        } else {
            if (tile.isRoot) {
                this.image = new cc.Sprite(bundles[bundle].frames[bundle + "_tile_" + tile.size]);
                this.addChild(this.image);
                this.image.setPositionRound({ align: "left" }, { align: "top" });
                this.image.setVisible(false);
            }
            this.cover = this.createImage(tile);
            this.cover.setPosition(this.width / 2, this.height / 2);
            this.addChild(this.cover);
            if (this.tile.lives < 1) {
                this.cover.visible = false;
            }
            if (cleverapps.environment.isEditorScene()) {
                this.cover.setOpacity(150);
                var livesText = cleverapps.UI.generateTTFText("" + tile.lives, cleverapps.styles.FONTS.EDITOR_RED_TEXT);
                this.addChild(livesText);
                livesText.setPositionRound({ align: "left" }, { align: "top" });
            }
        }
    },

    initialize: function (tile) {
        BaseTileView.initialize.call(this, tile);
        tile.onHurtListener = this.hurtAnimation.bind(this);
    },

    createImage: function (tile) {
        var bundle = this.tile.bundleId();
        var image = new cc.Sprite(bundles[bundle].frames[bundle + "_tile_cover_2"]);
        if (tile.lives === 1) {
            image.setOpacity(150);
        }
        return image;
    },

    hurtAnimation: function () {
        var bundle = this.tile.bundleId();
        this.coverAnimation = BaseAnimation.factoryLimited(bundles[bundle].jsons[bundle + "_wrapper_spine1_json"]);
        this.coverAnimation.setPosition(this.width / 2, this.height / 2);
        this.addChild(this.coverAnimation);
    
        if (this.tile.lives < 1) {
            this.coverAnimation.runAnimate("animation", this.cover);
        } else {
            this.coverAnimation.runAnimate("animation");
            this.cover.setOpacity(150);
        }

        cleverapps.audio.playSound(bundles.game.urls.nut_effect);
    },

    startExploding: function () {
        if (!this.tile.isRoot) {
            return;
        }

        this.stopAllActions();
        var fieldView = Game.currentGame.field.onGetView();
        var pos = this.getPosition();
        if (this.tile.size === 2) {
            pos.x += cleverapps.styles.BaseCellView.CELL_SIZE_PX / 2;
            pos.y -= cleverapps.styles.BaseCellView.CELL_SIZE_PX / 2;
        }
        var object = this.tile;

        if (Game.currentGame) {
            this.runAction(new cc.Sequence(
                new cc.DelayTime(0.5),
                new cc.CallFunc(function () {
                    if (Game.currentGame) {
                        var bundle = this.tile.bundleId();
                        var icon = new cc.Sprite(bundles[bundle].frames[bundle + "_tile_" + object.size]);
                        icon.setLocalZOrder(5);
                        icon.setPosition(pos.x, pos.y);
                        fieldView.addChild(icon);
                        icon.tileSize = object.size;

                        var goal = Game.currentGame.goals.findTargetFor(object.getGoalId());
                        goal.collect(1, icon);
                    }
                    if (this.coverAnimation) {
                        this.coverAnimation.finishAnimation(); // new cc.RemoveSelf() can be called before coverAnimation
                        // is finished from hurtAnimation call and we won't put it in pool otherwise
                    }
                }.bind(this)),
                new cc.RemoveSelf()
            ));
        }
    },

    showUp: function (options) {
        options = options || {};
        if (this.image) {
            options.callback = function () {
                if (options.silent) {
                    this.image.setScale(1);
                    this.image.setVisible(true);
                    return;
                }

                this.image.setScale(0);
                this.image.setVisible(true);
                this.image.runAction(new cc.Sequence(
                    new cc.DelayTime(0.1 + (this.tile.y * 1.2 + this.tile.x) * 0.4 / Field.SIZE),
                    new cc.ScaleTo(0.4, 1.0).easing(cc.easeBackOut())
                ));
            }.bind(this);
        }

        BaseTileView.showUp.call(this, options);
    }
}));

cleverapps.styles.RabbitTileView = {};
