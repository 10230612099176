/**
 * Created by Denis Kuzin on 06 august 2024
 */

var MuffinComponentView = cc.Node.extend({
    ctor: function (component, cellView) {
        this._super();

        this.component = component;
        this.cellView = cellView;

        this.setContentSize(cellView.getContentSize());

        component.cell.onChangeLivesListener = this.changeLives.bind(this);

        this.createAnimation();
    },

    createAnimation: function () {
        if (this.component.cell.isForGoal) {
            return;
        }

        if (this.animation) {
            return;
        }
        this.animation = new cleverapps.Spine(bundles.muffin.jsons.muffin_json);
        this.addChild(this.animation);
        this.animation.setPosition(this.width / 2, this.height / 2);
        this.updateAnimation();
    },

    hide: function () {
        this.animation.setVisible(false);
    },

    getIdleAnimationName: function () {
        if (this.cellView.getIdleAnimationName) {
            return this.cellView.getIdleAnimationName();
        }

        return "muffin_" + this.component.cell.lives;
    },

    getTransitionAnimationName: function () {
        if (this.cellView.getTransitionAnimationName) {
            return this.cellView.getTransitionAnimationName();
        }

        return "muffin_" + this.component.cell.lives + "_transition";
    },

    getSmileAnimationName: function () {
        if (this.cellView.getSmileAnimationName) {
            return this.cellView.getSmileAnimationName();
        }

        return "muffin_" + this.component.cell.lives + "_smile";
    },

    updateAnimation: function () {
        if (this.component.cell.lives > 0) {
            this.animation.setAnimation(0, this.getIdleAnimationName(), true);
        }
    },

    changeLives: function (fromEditor) {
        if (fromEditor) {
            this.updateAnimation();
            return;
        }

        if (this.component.cell.lives > 0) {
            this.animation.setAnimation(0, this.getTransitionAnimationName(), false);
            this.animation.setCompleteListener(function () {
                this.animation.setCompleteListener(function () { });
                this.updateAnimation();
            }.bind(this));
        }

        if (this.getStyles().hurtEffect) {
            cleverapps.audio.playSound(this.getStyles().hurtEffect);
        }
    },

    finishSmile: function () {
        if (this.smiling) {
            this.smiling = false;
            this.updateAnimation();
        }
    },

    smile: function () {
        if (this.smiling || !this.getStyles().smile || this.component.cell.isForGoal || this.component.cell.lives < 1) {
            return;
        }

        this.smiling = true;
        this.animation.setAnimation(0, this.getSmileAnimationName(), false);
        this.animation.setCompleteListener(this.finishSmile.bind(this));
    },

    getStyles: function () {
        return cleverapps.styles.MuffinComponentView;
    },

    onDestruction: function () {
        this.component.cell.onChangeLivesListener = function () {};
    },

    getBoomColor: function () {
        return cleverapps.styles.MuffinComponentView.boomColor;
    }
});

cleverapps.styles.MuffinComponentView = {
    smile: false,
    hurtEffect: false,
    boomColor: new cc.Color(156, 51, 0, 255)
};