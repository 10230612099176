/**
 * Created by slava on 23.03.17.
 */

var PagingScrollView = cc.Node.extend({ 
    ctor: function (pagingScroll) {
        this._super();

        var styles = cleverapps.styles.PagingScrollView;

        this.pagingScroll = pagingScroll;
        this.pages = {};

        this.direction = styles.direction !== undefined ? styles.direction : cleverapps.UI.HORIZONTAL;
        this.firstOffset = 0;

        this.container = new cc.Node();
        this.addChild(this.container);

        this.parallax = new PagesViewParallax(styles.parallax, this.container, styles.direction);

        pagingScroll.on("addPage", this.createListener(this.addPage.bind(this)));
        pagingScroll.on("scroll", this.createListener(this.scrollTo.bind(this)));

        this.updateSize();
        this.addControls();

        pagingScroll.listVisiblePages().forEach(function (page) {
            this.addPage(page);
        }.bind(this));
    },

    updateSize: function () {
        var rect = cleverapps.resolution.getBgRect();

        var styles = cleverapps.styles.PagingScrollView;

        this.pageWidth = styles.pageWidth === "scene" ? rect.width : styles.pageWidth;
        this.pageHeight = styles.pageHeight === "scene" ? rect.height : styles.pageHeight;

        var width = styles.width === "scene" ? rect.width : styles.width;
        var height = styles.height === "scene" ? rect.height : styles.height;
        this.setContentSize(width, height);
        this.setScale(this.calcScale());

        var position = styles.position || cc.p(rect.width / 2, rect.y);

        this.setAnchorPoint(0.5, 0);
        this.setPositionRound(position);

        this.parallax.updateScale();

        this.calcFirstOffset();

        this.scrollTo(0, this.pagingScroll.getCurrentPosition());
    },

    calcFirstOffset: function () {
        this.firstOffset = 0;

        if (cleverapps.styles.PagingScrollView.firstOffset) {
            this.firstOffset = cleverapps.styles.PagingScrollView.firstOffset;
        } else if (PagingScroll.VISIBLE_PAGES > 1) {
            if (this.isHorizontal()) {
                this.firstOffset = this.pageWidth / 2;
            } else {
                this.firstOffset = this.pageHeight / 2;
            }
        }
    },

    calcScale: function () {
        var styles = cleverapps.styles.PagingScrollView;

        var size = cleverapps.resolution.getSceneSize();
        var bgSize = cleverapps.resolution.getBgSize();

        var width = styles.width === "scene" ? bgSize.width : styles.width;

        return Math.max(
            1,
            this.isHorizontal() ? 1 : bgSize.height / size.height,
            styles.maxWidth ? width / styles.maxWidth : 1
        );
    },

    addControls: function () {
        if (cleverapps.config.name === "crocword") {
            return;
        }

        cleverapps.UI.onDrag(this, {
            onDragStart: this.onTouchBegan.bind(this),
            followPointer: this.onTouchMoved.bind(this),
            onDragEnd: this.onTouchEnded.bind(this)
        });

        cc.eventManager.addListener({
            event: cc.EventListener.MOUSE,
            onMouseScroll: this.onMouseScroll.bind(this)
        }, this);
    },

    onTouchBegan: function (touch) {
        if (cleverapps.meta.isFocused()) {
            return false;
        }

        this.touchBeganId = touch.getID();

        this.container.stopAllActions();

        var point = this.convertTouchToNodeSpace(touch);
        this.horizontalLookDirection = undefined;
        this.basePoint = point;
        this.prevPoint = point;
        this.prevPointTime = 0;
        this.prevPointCalcTimes = 0;
        this.deltaPoint = cc.p(0, 0);
        this.basePosition = this.container.getPosition();

        return cc.rectContainsPoint(cc.rect(0, 0, this.width, this.height), point);
    },

    onTouchMoved: function (touch) {
        if (this.touchBeganId !== touch.getID()) {
            return false;
        }

        var point = this.convertTouchToNodeSpace(touch);
        var d = [point.x - this.basePoint.x, point.y - this.basePoint.y];

        var maxWidth = cleverapps.styles.PagingScrollView.maxWidth || cleverapps.styles.SCENE_MAX_WIDTH;
        if (!this.isHorizontal() && this.width < maxWidth && !cleverapps.styles.horizontalScrollDisabled) {
            if (this.horizontalLookDirection === undefined && (Math.abs(d[0]) > PagingScrollView.MOVE_TRESHOLD * this.pageWidth || Math.abs(d[1]) > PagingScrollView.MOVE_TRESHOLD * this.pageHeight)) {
                this.horizontalLookDirection = Math.abs(d[0]) > 2 * Math.abs(d[1]);
            }

            if (this.horizontalLookDirection) {
                var dx = d[0];
                dx = Math.min(dx, (maxWidth - this.width) / 2 - this.basePosition.x);
                dx = Math.max(dx, -(maxWidth - this.width) / 2 - this.basePosition.x);
                this.container.setPosition(this.basePosition.x + dx, this.basePosition.y);
                return;
            }
        }

        this.calcDeltaPoint(point);
        this.deltaMove(0, d);
    },

    onTouchEnded: function (touch) {
        if (this.touchBeganId !== touch.getID()) {
            return false;
        }

        if (this.horizontalLookDirection) {
            this.container.runAction(new cc.MoveTo(0.5, { x: 0, y: this.basePosition.y }).easing(cc.easeOut(3)));
            return;
        }

        var deltaPoint = cc.p(Math.max(Math.min(Math.round(this.deltaPoint.x), 5), -5), Math.max(Math.min(Math.round(this.deltaPoint.y), 5), -5));
        var duration = Math.max(0.5, Math.sqrt(Math.abs(this.isHorizontal() ? this.deltaPoint.x : this.deltaPoint.y)) / 2);

        this.basePosition = this.container.getPosition();
        this.deltaMove(duration, [deltaPoint.x * cleverapps.styles.PagingScrollView.scrollInertia, deltaPoint.y * cleverapps.styles.PagingScrollView.scrollInertia]);
    },

    onMouseScroll: function (r) {
        if (!cleverapps.meta.checkEventNode(this)) {
            return;
        }

        var styles = cleverapps.styles.PagingScrollView;
        var d = r.getScrollY();
        if (d !== 0) {
            d = d > 0 ? styles.wheelScrollSpeed : -styles.wheelScrollSpeed;
            this.basePosition = this.container.getPosition();
            this.deltaMove(0.3, [0, -d]);
        }
    },

    calcDeltaPoint: function (point) {
        var dt = Date.now();
        if (this.prevPointTime === 0) {
            this.prevPointTime = dt;
            this.prevPoint = point;
            return;
        }

        if (this.prevPointTime < dt - PagingScrollView.SCROLL_SPEED_PERIODS[this.prevPointCalcTimes]) {
            if (this.prevPointTime < dt - 200) {
                this.prevPoint = point;
            }
            this.deltaPoint = cc.p(point.x - this.prevPoint.x, point.y - this.prevPoint.y);
            this.deltaPoint = cc.pMult(this.deltaPoint, 1 / (dt - this.prevPointTime) * 2);

            this.prevPointTime = dt;
            if (this.prevPointCalcTimes < PagingScrollView.SCROLL_SPEED_PERIODS.length - 1) {
                this.prevPointCalcTimes++;
            }
            this.prevPoint = point;
        }
    },

    deltaMove: function (duration, d) {
        cleverapps.audio.playSound(bundles.main.urls.pagingscroll);

        var page = this.convertToPage(cc.p(this.basePosition.x + d[0], this.basePosition.y + d[1]));
        this.pagingScroll.scrollToPosition(duration, page);
    },

    getPages: function () {
        return Object.values(this.pages);
    },

    isHorizontal: function () {
        return this.direction === cleverapps.UI.HORIZONTAL;
    },

    scrollTo: function (duration, page, callback) {
        this.container.stopAllActions();

        var pos = this.convertToPosition(page);

        if (!duration) {
            this.container.setPosition(pos);
            this.cleanupPages();

            if (this.onFinishScrolling) {
                this.onFinishScrolling();
                this.onFinishScrolling = undefined;
            }

            return;
        }

        this.onFinishScrolling = callback;

        this.container.runAction(new cc.Sequence(
            new cc.MoveTo(duration, pos.x, pos.y).easing(cc.easeQuadraticActionOut()),
            new cc.CallFunc(this.cleanupPages.bind(this)),
            new cc.CallFunc(function () {
                this.onFinishScrolling = undefined;
                callback();
            }.bind(this))
        ));
    },

    convertToPage: function (position) {
        var page;

        if (this.isHorizontal()) {
            page = (-position.x + this.firstOffset) / this.pageWidth;
        } else {
            page = (-position.y + this.firstOffset) / this.pageHeight;
        }

        return page;
    },

    convertToPosition: function (page) {
        var position;

        if (this.isHorizontal()) {
            position = cc.p(-page * this.pageWidth + this.firstOffset, 0);
        } else {
            position = cc.p(0, -page * this.pageHeight + this.firstOffset);
        }

        return position;
    },

    addPage: function (page) {
        this.removePage(page.pageNo);

        var pageView = this.pages[page.pageNo] = page.isComingSoon() ? new ComingSoonLocationView(page) : new LocationView(page);
        pageView.onShow();
        this.container.addChild(pageView);
    },

    removePage: function (pageNo) {
        var pageView = this.pages[pageNo];
        if (pageView) {
            delete this.pages[pageNo];
            pageView.onRemove();
        }
    },

    cleanupPages: function () {
        Object.keys(this.pages).forEach(function (page) {
            if (!this.pagingScroll.isPageVisible(page)) {
                this.removePage(page);
            }
        }.bind(this));
    }
});

PagingScrollView.MOVE_TRESHOLD = 0.01;
PagingScrollView.SCROLL_SPEED_PERIODS = [25, 50, 75, 100];

cleverapps.styles.PagingScrollView = {
    wheelScrollSpeed: 700,
    scrollInertia: 200,
    comingSoonExtraLength: [0, 0, 0],

    direction: cleverapps.UI.HORIZONTAL,
    height: 1200,
    width: "scene",
    pageHeight: 1200,
    pageWidth: "scene",

    parallax: {
        speed: [0, 0.15],
        zIndex: [-1000, -999],
        bundle: "episodes_spines",
        width: "scene",
        height: "scene"
    }
};
