/**
 * Created by slava on 23/10/18
 */

var Heroes = function () {
    this.load();

    // eslint-disable-next-line no-unused-vars
    this.onHeroChangeLevel = function (color) {};

    // eslint-disable-next-line no-unused-vars
    this.onDisplayHeroForce = function (color, force) {};

    this.checkAvailableToOpen();
};

Heroes.prototype.load = function () {
    var data = cleverapps.dataLoader.load(DataLoader.TYPES.HEROES);

    if (data === undefined) {
        this.data = {};
        this.save();
    } else {
        this.data = data;
    }
};

Heroes.prototype.getInfo = function () {
    return this.data;
};

Heroes.prototype.updateInfo = function (data, fromServer) {
    this.data = data;
    this.save(fromServer);
};

Heroes.prototype.save = function (fromServer) {
    cleverapps.dataLoader.save(DataLoader.TYPES.HEROES, this.getInfo());

    if (!fromServer) {
        cleverapps.synchronizer.addUpdateTask("heroes");
    }
};

Heroes.prototype.available = function (options) {
    return levels.user.checkAvailable(Heroes.AVAILABLE, options);
};

Heroes.prototype.checkAvailableToOpen = function () {
    if (!this.available()) {
        return;
    }

    for (var color in Heroes.OPEN_HERO) {
        if (!this.getLevel(color)) {
            this.setLevel(color, 1);
        }
    }
};

Heroes.prototype.isHeroAvailable = function (color) {
    return this.data[color] || Heroes.AVAILABLE_HEROES.indexOf(color) !== -1 && levels.user.checkAvailable(Heroes.OPEN_HERO[color] || Heroes.AVAILABLE);
};

Heroes.prototype.canUpgradeSomebody = function () {
    for (var i = 0; i < Heroes.AVAILABLE_HEROES.length; i++) {
        if (this.canUpgradeHero(Heroes.AVAILABLE_HEROES[i])) {
            return true;
        }
    }
    return false;
};

Heroes.prototype.getListOfAvailableColors = function () {
    return Object.keys(this.data);
};

Heroes.prototype.getLevel = function (color) {
    if (Heroes.AVAILABLE_HEROES.indexOf(color) < 0) {
        return 0;
    }
    return this.data[color] || 0;
};

Heroes.prototype.getHero = function (color, options) {
    var level = this.getLevel(color);

    if (options && options.nextLevel) {
        level++;
    }

    var data = { level: level };

    Object.assign(data, HeroesLibrary.byColors[color], HeroesLibrary.levels[color][level]);

    return data;
};

Heroes.prototype.isMaxLevel = function (color) {
    var level = this.getLevel(color);
    return level >= Heroes.HEROES_MAX_LEVEL;
};

Heroes.prototype.expUpgradePrice = function (color) {
    if (this.getHeroForce(color)) {
        return 0;
    }

    var level = this.getLevel(color);
    if (level < Heroes.UPGRADE_EXP_PRICE.length) {
        return Heroes.UPGRADE_EXP_PRICE[level];
    }
};

Heroes.prototype.getHeroForce = function (color) {
    if (color === this.getUnlockTutorialHero()) {
        return Forces.UNLOCK_HEROES;
    }

    if (color === this.getUpgradeTutorialHero()) {
        return Forces.UPGRADE_HEROES;
    }
};

Heroes.prototype.displayHeroForce = function (color) {
    cleverapps.meta.display({
        focus: "HEROES_TUTORIAL",
        actions: [
            function (f) {
                this.onDisplayHeroForce(color, this.getHeroForce(color));
                cleverapps.forces.onceForceClosed = f;
            }.bind(this)
        ]
    });
};

Heroes.prototype.getUnlockTutorialHero = function () {
    var color = Heroes.UNLOCK_TUTORIAL_HERO;
    if (this.getLevel(color) === 0 && this.isHeroAvailable(color)) {
        return color;
    }
};

Heroes.prototype.getUpgradeTutorialHero = function () {
    var color = Heroes.UPGRADE_TUTORIAL_HERO;
    if (this.getLevel(color) === 1 && this.isHeroAvailable(color)) {
        return color;
    }
};

Heroes.prototype.readyToOpen = function () {
    for (var color in Heroes.OPEN_HERO) {
        if (this.getLevel(color) === 0 && levels.user.checkAvailable(Heroes.OPEN_HERO[color])) {
            return color;
        }
    }
};

Heroes.prototype.coinUpgradePrice = function (color) {
    if (this.getHeroForce(color)) {
        return 0;
    }

    var level = this.getLevel(color);
    if (level < Heroes.UPGRADE_GOLD_PRICE.length) {
        return Heroes.UPGRADE_GOLD_PRICE[level];
    }
};

Heroes.prototype.canUpgradeHero = function (color) {
    if (this.isMaxLevel(color)) {
        return false;
    }
    var expPrice = this.expUpgradePrice(color);
    return expPrice !== undefined && cleverapps.exp.canTakeExp(expPrice);
};

Heroes.prototype.getCurrentSkin = function () {
    return Heroes.REGULAR_SKIN;
};

Heroes.prototype.listStats = function (color, nextLevel) {
    var hero = this.getHero(color, { nextLevel: nextLevel });
    var stats = {
        level: hero.level,
        numTargets: hero.numTargets,
        power: hero.power
    };
    if (hero.makeHeroProb) {
        stats.makeHeroProb = Math.round(hero.makeHeroProb * 100);
    }
    return stats;
};

Heroes.prototype.incLevel = function (color) {
    this.setLevel(color, this.getLevel(color) + 1);
};

Heroes.prototype.setLevel = function (color, level, silent) {
    this.data[color] = level;
    this.save();
    cleverapps.eventBus.trigger("taskEvent", DailyTasks.UPGRADE_HERO);

    if (!silent) {
        this.onHeroChangeLevel(color);
    }
};

Heroes.prototype.getComics = function (color) {
    return cleverapps.styles.comicses && cleverapps.styles.comicses[color];
};

Heroes.UPGRADE_GOLD_PRICE = [0, 0, 30, 100, 200, 300, 300];
Heroes.UPGRADE_EXP_PRICE = [3, 5, 15, 150, 300, 400, 500];
Heroes.HEROES_MAX_LEVEL = 7;

Heroes.REGULAR_SKIN = "regular";

Heroes.AVAILABLE_HEROES = ["e", "d", "a", "b", "c", "w"];
Heroes.UPGRADE_TUTORIAL_HERO = "e";
Heroes.UNLOCK_TUTORIAL_HERO = "w";

Heroes.OPEN_HERO = {
    "a": {
        level: 0.07
    },

    "d": {
        level: 0.27
    },

    "e": {
        level: 0.4
    },

    "b": {
        level: 0.53
    }
};

Heroes.AVAILABLE = {
    level: 2
};