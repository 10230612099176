/**
 * Created by Andrey Popov on 7/25/23.
 */

Wysiwyg.VIDEO_SIZES = {
    portrait_720x800: {
        width: 720,
        height: 800
    },
    portrait_240x400: {
        width: 240,
        height: 400
    },
    portrait_300x380: {
        width: 300,
        height: 380
    },
    portrait_800x1200: {
        width: 800,
        height: 1200
    },
    portrait_768x1024: {
        width: 768,
        height: 1024
    },
    portrait_1080x1350: {
        width: 1080,
        height: 1350
    },
    portrait_1080x1920: {
        width: 1080,
        height: 1920
    },
    portrait_1242x2208: {
        width: 1242,
        height: 2208
    },
    portrait_1284x2778: {
        width: 1284,
        height: 2778
    },
    portrait_1600x2560: {
        width: 1600,
        height: 2560
    },
    portrait_2048x2732: {
        width: 2048,
        height: 2732
    },
    portrait_1290x2796: {
        width: 1290,
        height: 2796
    },
    square_600x600: {
        width: 600,
        height: 600
    },
    square_800x800: {
        width: 800,
        height: 800
    },
    square_1024x1024: {
        width: 1024,
        height: 1024
    },
    square_1080x1080: {
        width: 1080,
        height: 1080
    },
    landscape_696x340: {
        width: 696,
        height: 340
    },
    landscape_500x200: {
        width: 500,
        height: 200
    },
    landscape_800x40: {
        width: 800,
        height: 40
    },
    landscape_617x500: {
        width: 617,
        height: 500
    },
    landscape_800x470: {
        width: 800,
        height: 470
    },
    landscape_900x720: {
        width: 900,
        height: 720
    },
    landscape_1000x250: {
        width: 1000,
        height: 250
    },
    landscape_1024x768: {
        width: 1024,
        height: 768
    },
    landscape_1024x500: {
        width: 1024,
        height: 500
    },
    landscape_1080x607: {
        width: 1080,
        height: 607
    },
    landscape_1120x630: {
        width: 1120,
        height: 630
    },
    landscape_1200x627: {
        width: 1200,
        height: 627
    },
    landscape_1200x628: {
        width: 1200,
        height: 628
    },
    landscape_1440x900: {
        width: 1440,
        height: 900
    },
    landscape_1600x300: {
        width: 1600,
        height: 300
    },
    landscape_1600x1200: {
        width: 1600,
        height: 1200
    },
    landscape_1848x682: {
        width: 1848,
        height: 682
    },
    landscape_1920x886: {
        width: 1920,
        height: 886
    },
    landscape_2880x650: {
        width: 2880,
        height: 650
    },
    landscape_2560x1600: {
        width: 2560,
        height: 1600
    },
    landscape_2796x1290: {
        width: 2796,
        height: 1290
    },
    landscape_2732x2048: {
        width: 2732,
        height: 2048
    },
    landscape_2208x1242: {
        width: 2208,
        height: 1242
    },
    landscape_2778x1284: {
        width: 2778,
        height: 1284
    },
    landscape_640x128: {
        width: 640,
        height: 128
    },
    landscape_680x358: {
        width: 680,
        height: 358
    },
    landscape_1920x1080: {
        width: 1920,
        height: 1080
    }
};

Wysiwyg.setValueForResolution = function (property, value) {
    var resolution = cleverapps.wysiwyg.wysiwygPreview.selectedResolution;
    if (property === null || property === undefined || property.general === undefined) {
        property = {
            general: value
        };
    }

    if (typeof property[resolution] === "object" && (property[resolution] === undefined || Object.keys(property[resolution]).length === 0)) {
        property.general = value;
    } else {
        property[resolution] = value;
    }

    return property;
};

Wysiwyg.getValueForResolution = function (property) {
    if (!property) {
        return;
    }

    var resolution = cleverapps.wysiwyg.wysiwygPreview.selectedResolution;
    var generalValue = property.general !== undefined ? property.general : property;

    if (typeof generalValue !== "object") {
        return property[resolution] === undefined || property[resolution] === "" ? generalValue : property[resolution];
    }

    var resultValue = cleverapps.override(cleverapps.clone(generalValue, true), property[resolution] || {});
    return Object.keys(resultValue).length > 0 ? resultValue : undefined;
};

Wysiwyg.toPercents = function (value, total) {
    return value * 100 / total;
};
