/**
 * Created by Vladislav on 25.09.2024.
 */

Placements.ENTRIES["pastAchievements"] = {
    type: Placements.FREE_FOCUS_MAIN,
    filter: function () {
        return cleverapps.achievements.wantsPastCheck;
    },
    action: function () {
        cleverapps.achievements.checkPastFeats();
    }
};