/**
 * Created by mac on 10/9/20
 */

var DataSource = function () {
    cleverapps.EventEmitter.call(this);

    this.data = {};
    this.ready = false;

    this.onReadyListeners = [];
};

DataSource.prototype = Object.create(cleverapps.EventEmitter.prototype);
DataSource.prototype.constructor = DataSource;

DataSource.prototype.purge = function () {
    cleverapps.EventEmitter.prototype.purge.apply(this, arguments);
    this.onReadyListeners = [];
};

DataSource.prototype.calculateData = function (options, callback) {
    this.data = options;
    callback();
};

DataSource.prototype.reset = function (options) {
    this.ready = false;
    this.data = {};
    this.options = options;
    this.trigger("reset");

    if (options) {
        this.calculateData(options, function () {
            if (this.options !== options) {
                console.log("received old result");
                return;
            }

            this.ready = true;
            this.runOnReadyListeners();
        }.bind(this));
    }
};

DataSource.prototype.runOnReadyListeners = function () {
    this.onReadyListeners.forEach(function (listener) {
        listener(this.data);
    }, this);
};

DataSource.prototype.getData = function () {
    return this.data;
};

DataSource.prototype.whenReady = function (callback, target) {
    this.onReadyListeners.push(callback);

    if (this.ready) {
        callback(this.data);
    }

    var handler = {
        clear: function () {
            var index = this.onReadyListeners.indexOf(callback);
            if (index >= 0) {
                this.onReadyListeners.splice(index, 1);
            }
        }.bind(this)
    };

    if (target instanceof cc.Node) {
        target.addCleaner(handler.clear);
    }

    return handler;
};
