/**
 * Created by spepa on 09.09.2024
 */

var ReportLevelOutcomeAction = function (f) {
    if (cleverapps.platformLogic.reportDefeat && this.game.outcome === GameBase.OUTCOME_LOST) {
        cleverapps.platformLogic.reportDefeat(f);
    } else if (cleverapps.platformLogic.reportLevelUp && this.game.outcome === GameBase.OUTCOME_VICTORY) {
        cleverapps.platformLogic.reportLevelUp(f);
    } else {
        f();
    }
};