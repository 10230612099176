/**
 * Created by Ivan on 18.04.2024
 */

var StickersCollectionIcon = cc.Node.extend({
    ctor: function (collection) {
        this.collection = collection;
        this.isAvailable = this.collection.isAvailable();
        this._super();

        var styles = cleverapps.styles.StickersCollectionIcon;

        this.icon = this.createIcon(collection.index);

        if (this.isAvailable) {
            this.progress = this.createProgress();
        } else {
            this.availableText = this.createAvailableText();
        }

        var footNode = new cc.Node();
        footNode.setAnchorPoint(0.5, 0.5);
        footNode.setContentSize(styles.footnote.width, styles.footnote.height);
        footNode.addChild(this.progress || this.availableText);

        var underline;
        if (styles.underline) {
            underline = new cc.Sprite(bundles.stickers_book_window.frames.underline_collection_png);
            footNode.addChild(underline);
            underline.setPositionRound(styles.underline);
        }

        this.content = new cleverapps.Layout([this.icon, footNode].filter(Boolean), {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });

        if (collection.hasAttention()) {
            this.createBadge();
        }

        this.addChild(this.content);
        this.setContentSize(this.content.getContentSize());
        this.content.setPositionRound(this.width / 2, this.height / 2);

        if (this.isAvailable) {
            cleverapps.UI.onClick(this, function () {
                new StickersCollectionWindow(collection);
            });
        }

        this.collection.on("hideAttention", this.createListener(this.removeBadge.bind(this)));
        this.collection.on("update", this.createListener(this.updateProgress.bind(this)));
    },

    createIcon: function () {
        var styles = cleverapps.styles.StickersCollectionIcon;

        var icon = new cc.Node();
        icon.setAnchorPoint(0.5, 0.5);
        
        var bg = this.bg = new cleverapps.Spine(bundles.stickers_book_window.jsons.icon_border_json);
        bg.setAnimation(0, this.isAvailable ? "idle" : "close", false);
        icon.addChild(bg);
        icon.setContentSize(bg.getContentSize());
        bg.setPositionRound(icon.width / 2, icon.height / 2);

        if (this.isAvailable) {
            var image = new cc.Sprite(this.collection.icon);
            icon.addChild(image);
            image.setPositionRound(styles.icon);
        }

        if (styles.textBg) {
            var textBg = new cc.Scale9Sprite(bundles.stickers_book_window.frames.text_bg_png);
            textBg.setContentSize2(styles.textBg);
            icon.addChild(textBg);
            textBg.setPositionRound(styles.textBg);
        }

        var titleFont = cleverapps.styles.FONTS.STICKERS_COLLECTION_TEXT;
        var opacity = 255;
        if (!this.isAvailable) {
            titleFont = cleverapps.styles.FONTS.STICKERS_COLLECTION_UNAVAILABLE_TEXT || titleFont;
            opacity = styles.title.unavailableOpacity || opacity;
        }

        var title = cleverapps.UI.generateOnlyText(this.collection.title, titleFont);
        title.setOpacity(opacity);

        icon.addChild(title);
        title.setPositionRound(cleverapps.styles.StickersCollectionIcon.title);
        title.fitTo(styles.title.maxWidth, styles.title.maxHeight);

        return icon;
    },

    createAvailableText: function () {
        var styles = cleverapps.styles.StickersCollectionIcon.availableText;
        var availableLevel = this.collection.getAvailableLevel();
        var text = cleverapps.UI.generateOnlyText("StickersCollectionWindow.AvailableText", cleverapps.styles.FONTS.STICKERS_AVAILABLE_TEXT, { level: availableLevel });
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        text.setDimensions(styles.width, 0);
        text.fitTo(0, styles.height);
        text.setAnchorPoint(0.5, 0.5);
        text.setPositionRound(styles);

        return text;
    },

    createProgress: function () {
        var styles = cleverapps.styles.StickersCollectionIcon.prizebar;

        if (this.collection.isPrizeCollected) {
            var checkmark = new cc.Sprite(bundles.stickers_book_window.frames.checkmarks_png);
            checkmark.setPositionRound(styles.checkmark);
            return checkmark;
        }

        var currentValue = this.collection.getOpenedStickersAmount();
        var goalValue = StickersCollection.STICKERS_IN_COLLECTION;

        if (currentValue === goalValue) {
            var button = new cleverapps.UI.Button({
                type: cleverapps.styles.UI.Button.Images.small_button_green,
                width: styles.button.width,
                height: styles.button.height,
                text: "Claim.Button",
                onClicked: function () {
                    this.collection.onPrizeCollect();
                    cleverapps.windows.currentWindow().close();
                    new StickersCompletedWindow(this.collection.index);
                }.bind(this)
            });
            button.setPositionRound(styles.button);
            return button;
        }

        var progress = this.progress = new PrizeBarComponent({
            reward: StickersBook.getCollectionReward(this.collection.index),
            progressBar: {
                progressImage: bundles.stickers_book_window.frames.collection_progress_bar_png,
                progressBgImage: bundles.stickers_book_window.frames.collection_progress_bar_bg_png,
                width: styles.width,
                barText: {
                    dy: styles.text.dy
                },
                removeBarText: styles.removeBarText
            }
        });
        progress.updateProgress(currentValue, goalValue);
        if (this.collection.isPrizeCollected) {
            this.progress.setCollected();
        }
        progress.setPositionRound(styles);
        return progress;
    },

    updateProgress: function (animated) {
        if (this.collection.jokerAnimationActive) {
            return;
        }

        var currentProgress = this.collection.getOpenedStickersAmount();
        var goalProgress = StickersCollection.STICKERS_IN_COLLECTION;

        if (currentProgress < goalProgress) {
            this.progress.updateProgress(currentProgress, goalProgress, {
                animated: animated
            });
            return;
        }

        var parent = this.progress.parent;
        this.progress.removeFromParent();
        this.progress = this.createProgress();
        parent.addChild(this.progress);
    },

    collect: function (callback) {
        if (!this.collection.isCollected()) {
            this.progress.updateProgress(this.collection.getOpenedStickersAmount() - 1, StickersCollection.STICKERS_IN_COLLECTION);
        }

        this.runAction(new cc.Sequence(
            new cc.DelayTime(cleverapps.styles.StickersCollectionIcon.collectAnimation.duration),
            new cc.CallFunc(function () {
                this.bg.setAnimation(0, "open", false);
            }.bind(this)),
            new cc.ScaleTo(0.25, 1.2).easing(cc.easeOut(2)),
            new cc.ScaleTo(0.25, 1).easing(cc.easeBounceOut()),
            new cc.CallFunc(function () {
                this.collection.jokerAnimationActive = false;
                this.updateProgress(true);
                callback();
            }.bind(this))
        ));
    },

    createBadge: function () {
        var styles = cleverapps.styles.StickersCollectionIcon.badge;

        var badge = this.badge = new cc.Sprite(bundles.stickers_book_window.frames.collection_badge_png);
        badge.setLocalZOrder(10);

        if (styles.text) {
            var text = cleverapps.UI.generateOnlyText("Attention.Text", cleverapps.styles.FONTS.COLLECTION_BADGE_TEXT);
            badge.addChild(text);
            text.setPositionRound(styles.text);
            text.fitTo(styles.text.maxWidth);
            text.setRotation(styles.text.rotation);
        }

        this.addChild(badge);
        badge.setPositionRound(styles);
    },

    removeBadge: function () {
        if (!this.badge) {
            return;
        }
        this.badge.removeFromParent();
    }
});

cleverapps.styles.StickersCollectionIcon = {
    footnote: {
        width: 200,
        height: 100
    },
    icon: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 10 }
    },

    textBg: {
        width: 220,
        height: 60,
        x: { align: "center" },
        y: { align: "bottom" }
    },

    availableText: {
        x: { align: "center" },
        y: { align: "center" },
        width: 220,
        height: 90
    },

    title: {
        maxWidth: 190,
        maxHeight: 50,
        x: { align: "center" },
        y: { align: "bottom", dy: 11 },
        unavailableOpacity: 170
    },

    prizebar: {
        x: { align: "center" },
        y: { align: "center" },
        width: 160,
        text: {
            dy: 0
        },
        checkmark: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: -8 }
        },
        button: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: -8 },
            width: 200,
            height: 70
        }
    },
    margin: 0,

    badge: {
        x: { align: "right", dx: 30 },
        y: { align: "top", dy: 20 },
        text: {
            x: { align: "center" },
            y: { align: "center" },
            maxWidth: 70,
            rotation: 20
        }
    },

    collectAnimation: {
        duration: 3.5
    },

    underline: undefined
};