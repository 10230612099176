/**
 * Created by vladislav on 3/25/19
 */

var FigureDragonCellView = BaseCellView.extend({
    ctor: function (cell) {
        this._super();
        var image;

        if (cell.isForGoal) {
            this.setContentSize2(this.getStyles().goal);
            this.setAnchorPoint(0.5, 0.5);
            image = new cc.Sprite(bundles.figure_dragon.frames.figure_dragon_goal);
            this.addChild(image);
            image.setPositionRound(this.width / 2, this.height / 2);
            return;
        }

        var specialShapeComponent = cell.findComponent(SpecialShapeComponent);
        if (!specialShapeComponent.isHead) {
            return;
        }

        this.initialize(cell);

        image = this.image = new cc.Sprite(this.getImage());
        this.addChild(image);
        image.setPositionRound(this.width / 2, this.height / 2);
        image.setRotation(SpecialShapeComponentView.ROTATION_BY_ID[cell.rotation]);

        if (bundles.figure_dragon.jsons["dragon_" + this.cell.configId]) {
            this.animation = new cleverapps.Spine(bundles.figure_dragon.jsons["dragon_" + this.cell.configId]);
            this.addChild(this.animation);
            this.animation.setPositionRound(this.width / 2, this.height / 2);
            this.animation.spine._skeleton.getRootBone().rotation = -SpecialShapeComponentView.ROTATION_BY_ID[cell.rotation];
        }

        if (!this.cell.isForEditor) {
            this.createLives();
            this.createGrass();
        }
    },

    refuse: function () {},

    initialize: function (cell) {
        this._super(cell);

        cell.onChangeLivesListener = this.changeLives.bind(this);
    },

    createLives: function () {
        var pos = cleverapps.styles.FigureDragonCellView.livesText[this.cell.configId];

        var livesBg = this.lives = new cc.Sprite(bundles.figure_dragon.frames.livesBg);
        this.addChild(livesBg, 1);
        livesBg.setPositionRound(pos);

        switch (this.cell.rotation) {
            case 1:
                livesBg.setPosition(pos.y, this.height - pos.x);
                break;
            case 2:
                livesBg.setPosition(this.width - pos.x, this.height - pos.y);
                break;
            case 3:
                livesBg.setPosition(this.width - pos.y, pos.x);
        }

        var text = this.lives.text = cleverapps.UI.generateTTFText(this.cell.lives, cleverapps.styles.FONTS.FIGURE_DRAGON_LIVES_TEXT || cleverapps.styles.FONTS.WINDOW_SMALL_TEXT);
        livesBg.addChild(text);
        text.setPositionRound(livesBg.width / 2, livesBg.height / 2);
    },

    createGrass: function () {
        var specialShapeComponent = this.cell.findComponent(SpecialShapeComponent);

        var size = specialShapeComponent.getSize();
        var cellSize = cleverapps.styles.BaseCellView.CELL_SIZE_PX;
        this.grass = [];

        for (var row = 0; row < size.rows; row++) {
            for (var col = 0; col < size.cols; col++) {
                if (specialShapeComponent.config[row][col] === 1) {
                    var grass = new cc.Sprite(bundles.figure_dragon.frames.grass);
                    this.grass.push(grass);
                    this.addChild(grass, -1);
                    grass.setPositionRound(cellSize * col + cellSize / 2, this.height - (cellSize * row + cellSize / 2));
                }
            }
        }
    },

    startExploding: function (goal, delta) {
        if (this.finishSmile) {
            this.finishSmile();
        }

        this.stopAllActions();
        this.setLocalZOrder(3);

        this.lives.runAction(new cc.Sequence(
            new cc.ScaleTo(0.25, 0.1).easing(cc.easeBackIn()),
            new cc.Hide()
        ));

        this.grass.forEach(function (grass) {
            var animation = BaseAnimation.factory(bundles.figure_dragon.jsons.grass_json);
            this.addChild(animation, -1);
            animation.setPositionRound(grass.getPosition());
            animation.runAnimate("two");
            grass.setVisible(false);
        }.bind(this));

        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.35),
            new cc.CallFunc(function () {
                this.startExplodingViaCollect(goal, delta);
            }.bind(this))
        ));
    },

    changeLives: function () {
        this.lives.text.setString(this.cell.lives);
    },

    getImage: function () {
        return bundles.figure_dragon.frames["figure_dragon_" + this.cell.configId];
    },

    getStyles: function () {
        return cleverapps.styles.FigureDragonCellView;
    },

    smile: function () {
        if (this.smiling || this.onHurtAnimating || !this.animation) {
            return;
        }

        this.smiling = true;
        this.image.visible = false;
        this.animation.setAnimation(0, "smile", false);
        this.animation.setCompleteListener(this.finishSmile.bind(this));
    },

    finishSmile: function () {
        if (this.smiling && this.animation) {
            this.smiling = false;
            this.image.visible = true;
            this.animation.visible = false;
        }
    },

    hurtAnimation: function () {
        var specialShapeComponent = this.cell.findComponent(SpecialShapeComponent);
        if (!specialShapeComponent.isHead || this.onHurtAnimating || this.cell.lives <= 1) {
            return;
        }

        this.onHurtAnimating = true;
        this.image.visible = false;
        this.animation.setAnimation(0, "animation", false);
        this.animation.setCompleteListener(function () {
            this.smiling = false;
            this.onHurtAnimating = false;
            this.image.visible = true;
            this.animation.visible = false;
        }.bind(this));
    }
});

cleverapps.styles.FigureDragonCellView = {
    hover: {
        y: 10
    },

    goal: {
        width: 120,
        height: 120
    },

    livesText: {}
};

cleverapps.styles.FigureDragonCellView.livesText[FigureDragonCell.TYPE_0] = {
    x: 120,
    y: 120
};

cleverapps.styles.FigureDragonCellView.livesText[FigureDragonCell.TYPE_1] = {
    x: 130,
    y: 195
};

cleverapps.styles.FigureDragonCellView.livesText[FigureDragonCell.TYPE_2] = {
    x: 175,
    y: 170
};

cleverapps.styles.FigureDragonCellView.livesText[FigureDragonCell.TYPE_3] = {
    x: 55,
    y: 260
};