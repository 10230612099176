/**
 * Created by Andrey Popov on 10.11.2021
 */

var BundlesExplorerView = AnalyzerWindowBase.extend({
    ctor: function (bundlesExplorer) {
        this._super(["Main", "Episodes", "Debug"]);

        this.bundlesExplorer = bundlesExplorer;
        this.itemViews = [];

        this.bundlesExplorer.on("selectionChanged", this.selectItem.bind(this), this);
        this.bundlesExplorer.on("selectedTabChanged", this.selectedTabChanged.bind(this), this);
        this.bundlesExplorer.on("rebuildItems", this.rebuildItems.bind(this), this);

        this.addKeyboardNavigation();

        this.updateSize();
        this.setupChildren();
    },

    updateSize: function () {
        var sceneSize = cleverapps.resolution.getSceneSize();

        var width = cleverapps.styles.BundlesExplorerView.width;
        var height = sceneSize.height - cleverapps.styles.AtlasAnalyzerScene.offsetTop;

        this.setContentSize(width, height);
    },

    rebuildItems: function () {
        var styles = cleverapps.styles.BundlesExplorerView;

        this.itemViews = [];
        var itemViews = this.bundlesExplorer.items.map(function (explorerItem) {
            var itemView = new ExplorerItemView(explorerItem, this.bundlesExplorer.loadedBundles.indexOf(explorerItem) !== -1);
            this.itemViews.push(itemView);
            return itemView;
        }.bind(this));

        this.createScroll(new cleverapps.Layout(itemViews, {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        }));

        this.scroll.scrollToPercent(100);
    },

    selectItem: function (tab, texture) {
        this.itemViews.forEach(function (itemView) {
            itemView.setSelected(itemView.explorerItem === texture);
        });
    },

    selectedTabChanged: function (tab) {
        this.onTabClick(tab, true);
    },

    onTabClick: function (tab, fromModel) {
        this._super(tab);
        if (fromModel !== true) {
            this.bundlesExplorer.onTabChanged(tab);
        }
    },

    addKeyboardNavigation: function () {
        cc.eventManager.addListener({
            event: cc.EventListener.KEYBOARD,

            onKeyPressed: function (key) {
                if (key === cc.KEY.up) {
                    this.bundlesExplorer.moveUp();
                } else if (key === cc.KEY.down) {
                    this.bundlesExplorer.moveDown();
                }
            }.bind(this)
        }, this);
    }
});

cleverapps.styles.BundlesExplorerView = {
    margin: 6,

    width: 400
};