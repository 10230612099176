/**
 * Created by slava on 02.02.17.
 */

var CookieManCell = function (x, y) {
    BaseCell.call(this, x, y);
    this.movable = true;
    this.hurtable = false;
    
    this.putInPoolAvailable = true;

    Game.currentGame.counter.registerStage(204, CookieManCell.process);
};

CookieManCell.prototype = Object.create(BaseCell.prototype);
CookieManCell.prototype.constructor = CookieManCell;

CookieManCell.prototype.onTouchEnd = function () {
    if (this.movable) {
        this.animate(BaseCell.ANIMATION_ONCLICK);
        cleverapps.notification.create("CookieMan.MoveToDown");
    }
};

CookieManCell.prepareToUp = function () {
    if (!Game.currentGame) {
        return;
    }

    for (var i = 0; i < Field.SIZE; i++) {
        for (var j = 0; j < Field.SIZE; j++) {
            if (Game.currentGame.field.cells[i][j] && Game.currentGame.field.cells[i][j].constructor === CookieManCell) {
                Game.currentGame.field.cells[i][j].canUp = true;
                if (Game.currentGame.field.cells[i][j].movedDownToTransporterOnPreviousMove) {
                    Game.currentGame.field.cells[i][j].movedDownToTransporterOnPreviousMove = false;
                }
            }
        }
    }
};

CookieManCell.process = function () {
    if (!Game.currentGame) {
        return;
    }

    for (var i = 0; i < Field.SIZE; i++) {
        for (var j = 0; j < Field.SIZE; j++) {
            if (Game.currentGame.field.cells[i][j] && Game.currentGame.field.cells[i][j].constructor === CookieManCell) {
                Game.currentGame.field.cells[i][j].process();
            }
        }
    }
};

CookieManCell.prototype.move = function (destination, options) {
    if (destination.y > this.y) {
        if (!(options && options.source && options.source instanceof TransporterTile)) {
            this.canUp = false;
        }

        if (Game.currentGame && Game.currentGame.field.floor[destination.y][destination.x] instanceof TransporterTile) {
            this.movedDownToTransporterOnPreviousMove = true;
            this.canUp = false;
        }
    }

    BaseCell.prototype.move.apply(this, arguments);
};

CookieManCell.prototype.beforeMoveByTransporter = function (destination) {
    if (destination.x - this.x !== 0) {
        if (!this.movedDownToTransporterOnPreviousMove) {
            this.canUp = true;
        }
    }
    if (destination.y - this.y !== 0) {
        this.canUp = false;
    }
};

CookieManCell.prototype.process = function () {
    if (!this.canUp) {
        return;
    }

    var cx = this.x, cy = this.y;
    
    if (Game.currentGame.field.barriers.canMove(cy, cx, cy - 1, cx) === false) {
        return;
    }
        
    if (Game.currentGame.field.isCellInField(cy - 1, cx)) {
        if (Game.currentGame.field.cells[cy - 1][cx].movable && Game.currentGame.field.cells[cy - 1][cx].constructor !== CookieManCell && Game.currentGame.field.cells[cy - 1][cx].constructor !== ColorCookieManCell) {
            var buddy = Game.currentGame.field.cells[cy - 1][cx];

            this.y = cy - 1;
            this.animate(BaseCell.ANIMATION_COOKIE_UP, function () {
                this.onFinishMoveListener({ x: this.x, y: this.y }, {});
            }.bind(this));

            Game.currentGame.counter.setTimeout(function () {
                buddy.move({ x: cx, y: cy }, { moveInterval: 0.1 });
            }, 500);

            Game.currentGame.counter.setTimeout(function () {
                Game.currentGame.field.cells[cy][cx] = buddy;
                Game.currentGame.field.cells[cy - 1][cx] = this;
            }.bind(this), this.upDuration());
            this.canUp = false;
        }
    }
};

CookieManCell.prototype.upDuration = function () {
    return 1300;
};

CookieManCell.prototype.bundleId = function () {
    return "cookieman";
};

CookieManCell.prototype.smile = function () {
    if (this.alive) {
        this.animate(BaseCell.ANIMATION_SMILE);
    }
};

CookieManCell.prototype.load = function () {
    // do nothing
};

CookieManCell.prototype.save = function () {
    return CookieManCell.CODES;
};

CookieManCell.prototype.getViewClass = function () {
    return CookieManCellView;
};

CookieManCell.prototype.onBurnStage = function () {
    for (var i = this.y + 1; i < Field.SIZE; i++) {
        if (Game.currentGame.field.inField(i, this.x)) {
            return;
        }
    }
    this.explodeViaCollect();
};

CookieManCell.prototype.getGoalId = function () {
    return CookieManCell.GOAL_ID;
};

CookieManCell.GOAL_ID = "cookie_man";