/**
 * Created by mac on 8/03/17.
 */

var FieldScoreView = cc.Node.extend({
    ctor: function () {
        this._super();

        this.text = cleverapps.UI.generateImageText("+", cleverapps.styles.FONTS.FIELD_SCORE);
        this.text.setScale(1.2);

        this.addChild(this.text);

        this.setLocalZOrder(-1000000);
        this.setCascadeOpacityEnabled(true);
    },

    onEnterTransitionDidFinish: function () {
        this._super();

        this.text.setString("+" + this.score);
        if (cleverapps.styles.COLORS.CELL_COLORS[this.cellColor]) {
            this.text.setColor(cleverapps.styles.COLORS.CELL_COLORS[this.cellColor]);
        }

        this.setPosition(this.targetPosition);
        this.setOpacity(255);
        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.8),
            new cc.Spawn(new cc.MoveBy(1, cleverapps.styles.FieldScoreView.animation.moveBy), new cc.FadeOut(1)),
            new cc.CallFunc(function () {
                cc.pool.putInPool(this);
            }.bind(this))
        ));
    },

    reuse: function (score, color, position) {
        this.score = score;
        this.cellColor = color;
        this.targetPosition = position;
    },

    unuse: function () {
        this.removeFromParent();
    }
});

FieldScoreView.factory = function (score, color, position) {
    return cc.pool.hasObject(FieldScoreView) ? cc.pool.getFromPool(FieldScoreView, score, color, position) : undefined;
};

FieldScoreView.fillPool = function () {
    for (var i = 0; i < 3; i++) {
        cc.pool.putInPool(new FieldScoreView());
    }
    cc.pool.hasObject(FieldScoreView);
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    FIELD_SCORE: {
        size: 40,
        name: "default"
    }
});

cleverapps.styles.FieldScoreView = {
    animation: {
        moveBy: {
            x: 0,
            y: 50
        }
    }
};