/**
 * Created by slava on 02.02.17.
 */

var MultiColorCellView = BaseCellView.extend({
    ctor: function (multicolorCell) {
        this._super();

        this.setContentSize(cleverapps.styles.BaseCellView.CELL_SIZE_PX, cleverapps.styles.BaseCellView.CELL_SIZE_PX);
        this.setAnchorPoint(0.5, 0.5);

        this.animation = new cleverapps.Spine(bundles.game.jsons.multicolor_animation_json);
        this.animation.setPosition(this.width / 2, this.height / 2);
        this.addChild(this.animation);

        this.animation.setAnimation(0, "idle", true);

        this.initialize(multicolorCell);
    },
    
    initialize: function (multicolorCell) {
        this._super(multicolorCell);

        if (this.animation) {
            this.stopAllActions();
            this.animation.visible = true;
            this.animation.setAnimation(0, "idle", true);
            this.animation.setCompleteListener(); // wrong listener remains, need to clear
        }
    },
    
    createAnimation: function () {
        this.animation.visible = false;
        this.animation.setAnimation(0, "enter", false);
        this.animation.setCompleteListener(function () {
            this.animation.setAnimation(0, "idle", true);
        }.bind(this));
        cleverapps.audio.playSound(bundles.game.urls.create_multicolor_effect);
    },

    startExploding: function () {
        if (!this.getParent()) {
            return;
        }
        this.stopAllActions();
        this.setLocalZOrder(100);
        var styles = cleverapps.styles.MultiColorCellView;

        var action = new cc.CallFunc(function () {
            this.animation.setAnimation(0, "exit", false);
            this.animation.setCompleteListener(function () {
                this.animation.setVisible(false);
                this.delete();
            }.bind(this));
        }.bind(this));

        if (cleverapps.config.wysiwygMode && this.adsCustomizeExplosion) {
            action = this.adsCustomizeExplosion();
        }

        if (styles.centerAnimation) {
            var gotoCenterAction = new cc.Spawn(
                new cc.MoveTo(styles.centerAnimation.duration, this.getParent().width / 2, this.getParent().height / 2),
                new cc.ScaleTo(styles.centerAnimation.duration, styles.centerAnimation.scale)
            );

            action = new cc.Sequence(gotoCenterAction, action);
        }

        this.runAction(action);

        if (Game.currentGame) {
            Game.currentGame.shakeField(styles.shakeField);
        }

        cleverapps.audio.playSound(bundles.game.urls.multicolor_use_effect);
    }
});

cleverapps.styles.MultiColorCellView = {
    shakeField: {
        delay: 400
    },

    centerAnimation: {
        duration: 0.25,
        scale: 2
    }
};