/**
 * Created by slava on 23.03.17.
 */

var Match3Scene = GameSceneBase.extend({
    addScoreView: function () {
        var scoreView = new ScoreView(this.game.score);
        scoreView.setLocalZOrder(1);
        if (this.infoPanel) {
            this.infoPanel.addChild(scoreView);
        } else {
            this.addChild(scoreView);
        }
    },

    addMovesView: function () {
        var movesView = new MovesView(this.game);
        if (movesView) {
            this.movesView = movesView;
            if (this.infoPanel) {
                this.infoPanel.addChild(movesView);
            } else {
                this.addChild(movesView);

                this.game.on("outcome", function () {
                    movesView.hide();
                }, this);
            }
        }
    },

    addGoals: function () {
        this.goalsView = new GoalsView(this.game);
        if (this.infoPanel) {
            this.infoPanel.addChild(this.goalsView);
        } else {
            this.addChild(this.goalsView);
        }
    },

    addLevelRewards: function () {
        if (cleverapps.styles.PassLevelReward.enabled) {
            this.passLevelRewards = new PassLevelReward(this.infoPanel || this, this);
        }
    },

    addActor: function () {
        var styles = cleverapps.styles.GameScene.actor;
        if (styles) {
            this.actor = new Actor(styles);
            this.addChild(this.actor);
            this.game.heroAnimation = this.actor.createListener(this.actor.animate.bind(this.actor));
            this.actor.setVisible(false);

            this.game.on("outcome", function () {
                this.actor.hide();
            }.bind(this), this);
        }
    },

    addBoosters: function () {
        this.boostersView = new FieldBoostersView(this.game.boosters);

        if (this.controlsPanel) {
            this.controlsPanel.addChild(this.boostersView);
        } else {
            this.addChild(this.boostersView);

            this.game.on("outcome", function () {
                this.boostersView.hideAnimation();
            }.bind(this), this);
        }
    },

    addDanger: function () {
        if (this.infoPanel) {
            var dangerComponentView = this.dangerComponentView = new DangerComponentView(this.game.dangerComponent);

            this.infoPanel.addChild(dangerComponentView);
        }

        var fieldBg = this.fieldBg = new cc.Sprite(bundles.danger_component.frames.field_bg);
        this.addChild(fieldBg);

        var danger = this.dangerAnimationView = new DangerAnimationView(this.game.dangerComponent);
        this.addChild(danger);
    },

    onSceneLoaded: function () {
        this._super();

        var game = this.game;
        var level = this.level;

        this.addActor();
        this.addGoals();

        var fieldView = this.fieldView = new FieldView(game.field);
        this.addChild(fieldView);
        fieldView.adjustSize();

        if (level.isTricky()) {
            this.addDanger();
        } else {
            this.addScoreView();
            this.addMovesView();
            this.addBoosters();
        }

        if (game.field.boosterAdvice) {
            var boosterAdviceView = new BoosterAdviceView(game.field.boosterAdvice, fieldView);
            this.addChild(boosterAdviceView);
        }

        game.onBoosterCellCollected = this.createListener(this.showBoosterCellReward.bind(this));

        game.on("tutorial:nextStep", function (stepData) {
            this.addChild(new LevelTutorialView(stepData));
        }.bind(this), this);

        game.on("lowMoves", function (moves) {
            if (moves === 1 || moves === 2) {
                this.buyMoves();
            } else if (moves === 3 && this.level.isCurrentLevel()) {
                HelpByFiveAttemptsWindow.showIfCan();
            } else if (moves === 5) {
                cleverapps.exclamation.show("message.MovesLeft", Exclamation.Types.Warning);
            }
        }.bind(this), this);

        this.addLevelRewards();

        levels.levelAttempts.addAttempt(this.level);
        cleverapps.playSession.inc(cleverapps.EVENTS.PLAY_TIMES);

        if (level.isHard() && cleverapps.styles.HardLevelIcon.enabled) {
            var hardLevelIconParent = cleverapps.styles.HardLevelIcon.parentSceneScene ? this : this.infoPanel;
            this.hardLevelIcon = new HardLevelIcon(hardLevelIconParent);
        }

        if (["adventure", "heroes", "runes"].indexOf(cleverapps.config.name) !== -1) {
            fieldView.setVisible(false);
            if (cleverapps.styles.FieldView.slideAnimation.movesView && this.movesView) {
                this.movesView.setVisible(false);
            }
        }

        this.goalsView.views.forEach(function (target) {
            target.setVisible(false);
        });

        game.on("showPanels", this.onShowPanels.bind(this), this);
        game.on("showActor", this.onShowActor.bind(this), this);
        game.on("moveActor", this.onMoveActor.bind(this), this);
        game.on("startFilmEffect", this.onStartFilmEffect.bind(this), this);
        game.on("stopFilmEffect", this.onStopFilmEffect.bind(this), this);

        game.onSetTutorialControls = this.createListener(this.setTutorialControls.bind(this));

        this.setupChildren();
    },

    onShowPanels: function (silent) {
        if (this.infoPanel) {
            this.infoPanel.show(silent);
        }
        if (this.controlsPanel) {
            this.controlsPanel.show(silent);
        }
    },

    onShowActor: function (silent) {
        this.actor.showUpAnimation(silent);
    },

    onMoveActor: function (silent) {
        if (!silent) {
            this.actor.moveAnimation();
        }
    },

    setupChildren: function () {
        if (!this.isAllLoaded) {
            return;
        }

        this._super();

        var styles;

        if (this.actor) {
            styles = cleverapps.styles.GameScene.actor;
            var scaleX = 1, scaleY = 1;
            if (styles.mobileScale && cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
                scaleX = scaleY = styles.mobileScale;
            }
            if (styles.scaleX) {
                scaleX *= styles.scaleX;
            }
            if (styles.scaleY) {
                scaleX *= styles.scaleX;
            }
            cleverapps.timeouts.setTimeout(function () {
                this.actor.setScale(scaleX, scaleY);
            }.bind(this), 0);
        }
        if (this.game.hasDanger()) {
            styles = cleverapps.styles.FieldView.filedBg;
            var bgRect = cleverapps.resolution.getBgRect();
            var fieldBg = this.fieldBg;
            var position = styles.positions[cleverapps.resolution.mode];

            if (cleverapps.resolution.mode === cleverapps.WideMode.HORIZONTAL) {
                var widthLimit = bgRect.width - this.dangerAnimationView.width;

                fieldBg.setScale(widthLimit / fieldBg.width, bgRect.height / fieldBg.height);

                this.infoPanel.setPositionX(this.width - (this.width - this.dangerAnimationView.width) / 2);
            } else {
                var heightLimit = bgRect.height - this.dangerAnimationView.height;

                fieldBg.setScale(bgRect.width / fieldBg.width, heightLimit / fieldBg.height);

                this.infoPanel.setPositionY(this.height - this.dangerAnimationView.height);
            }

            fieldBg.setPositionRound(position.x, position.y, { box: bgRect });
        }
    },

    getOverlappingNodes: function () {
        var overlappingNodes = [this.upMenuContainer, this.controlsPanel, this.boostersView, this.goalsView, this.infoPanel, this.dangerAnimationView];

        return overlappingNodes.filter(Boolean);
    },

    scaleGameField: function () {
        var styles = cleverapps.styles.FieldView;

        var centerPoint = cc.p(this.width / 2, this.height / 2);
        if (this.game.hasDanger()) {
            if (cleverapps.resolution.mode === cleverapps.WideMode.HORIZONTAL) {
                centerPoint.x = this.width - (this.width - this.dangerAnimationView.width) / 2;
            } else {
                centerPoint.y = (this.height - this.dangerAnimationView.height) / 2;
            }
        }

        cleverapps.UI.inflateToBoundaries(this.fieldView, this.getOverlappingNodes(), {
            centerPoint: this.convertToWorldSpace(centerPoint),
            maxScale: 1.5,
            padding: styles.padding
        });
    },

    showBoosterCellReward: function () {
        if (this.passLevelRewards) {
            this.passLevelRewards.addPrize(PassLevelReward.BOOSTER);
        }
    },

    buyMoves: function () {
        if (TacticalExtendWindow.canShow()) {
            cleverapps.meta.display({
                focus: "TacticalExtendWindow",
                action: function (f) {
                    TacticalExtendWindow.show();

                    cleverapps.meta.onceNoWindowsListener = f;
                }
            });
        }
    },

    outOfMoves: function () {
        cleverapps.audio.playSound(bundles.game.urls.no_moves_effect);

        cleverapps.meta.displayWhenFreeFocus({
            focus: "AddMovesWindow",
            control: "panel_info",
            filter: function () {
                return this.game && this.game === Game.currentGame && Game.currentGame.outcome === GameBase.OUTCOME_UNKNOWN;
            }.bind(this),
            actions: [
                function (f) {
                    Game.currentGame.counter.setTimeout(f, 500);
                },
                function (f) {
                    new ProlongationWindow({
                        onGiveUp: function () {
                            Game.currentGame.lose();
                        }
                    });

                    cleverapps.meta.onceNoWindowsListener = f;
                }
            ]
        });
    },

    onStartFilmEffect: function (options) {
        this.createFilmEffect(options);
    },

    onStopFilmEffect: function () {
        this.removeFilmEffect(0.4);
    },

    setTutorialControls: function () {
        cleverapps.meta.showControlsWhileFocused(["field", "panel_controls", "panel_info"]);
        cleverapps.meta.setEventNodes([this.fieldView]);
    },

    completeAnimationOnResize: function () {
        GameSceneBase.prototype.completeAnimationOnResize.call(this);
    },

    getBackgroundStyles: function () {
        var options = this._super();

        if (this.game.hasDanger() && cleverapps.resolution.mode !== cleverapps.WideMode.HORIZONTAL) {
            options.backgroundId += "_vertical";
        }

        return options;
    }
});

GameScene = Match3Scene;
