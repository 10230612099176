/**
 * Created by Vladislav on 05.09.2024.
 */

if (StickersBook.isStickersShopAvailable()) {
    Object.assign(VirtualProducts, {
        stickers0: {
            title: "StickersBook.products.specialPack",
            currency: "hard",
            spentEvent: cleverapps.EVENTS.SPENT.STICKERS_3,
            price: 1000,
            icon: bundles.stickers_shop.frames.stickers_chest_0,
            availableAfterCollection: 2,
            reward: {}
        },

        stickers1: {
            title: "StickersBook.products.mediumPack",
            currency: "hard",
            spentEvent: cleverapps.EVENTS.SPENT.STICKERS_2,
            price: 500,
            icon: bundles.stickers_shop.frames.stickers_chest_1,
            reward: {}
        },

        stickers2: {
            title: "StickersBook.products.smallPack",
            currency: "hard",
            spentEvent: cleverapps.EVENTS.SPENT.STICKERS_1,
            price: 200,
            icon: bundles.stickers_shop.frames.stickers_chest_2,
            reward: {}
        }
    });
    switch (cleverapps.config.type) {
        case "tile3":
            Object.assign(VirtualProducts.stickers2, {
                reward: {
                    boosters: {
                        17: 2,
                        18: 1
                    }
                },
                price: 100
            });
            Object.assign(VirtualProducts.stickers1, {
                reward: {
                    boosters: {
                        18: 1
                    },
                    lives: 0,
                    soft: 300
                },
                price: 250
            });
            Object.assign(VirtualProducts.stickers0, {
                reward: {
                    boosters: {
                        23: 1
                    },
                    unlimitedLives: "6 hours"
                },
                price: 500
            });
            break;
        case "solitaire":
            Object.assign(VirtualProducts.stickers2, {
                reward: {
                    boosters: {
                        11: 1
                    },
                    lives: 2
                }
            });
            Object.assign(VirtualProducts.stickers1, {
                reward: {
                    boosters: {
                        11: 1
                    },
                    lives: 3
                }
            });
            Object.assign(VirtualProducts.stickers0, {
                reward: {
                    boosters: {
                        12: 2
                    },
                    unlimitedLives: "6 hours"
                }
            });
            break;
        case "board":
            Object.assign(VirtualProducts.stickers2, {
                reward: {
                    boosters: {
                        13: 1,
                        14: 1
                    }
                }
            });
            Object.assign(VirtualProducts.stickers1, {
                reward: {
                    boosters: {
                        13: 1,
                        15: 1
                    }
                }
            });
            Object.assign(VirtualProducts.stickers0, {
                reward: {
                    boosters: {
                        14: 1,
                        15: 1
                    }
                }
            });
            break;
        case "match3":
            Object.assign(VirtualProducts.stickers2, {
                reward: {
                    boosters: {
                        6: 1
                    },
                    lives: 2
                }
            });
            Object.assign(VirtualProducts.stickers1, {
                reward: {
                    boosters: {
                        6: 1
                    },
                    lives: 3
                }
            });
            Object.assign(VirtualProducts.stickers0, {
                reward: {
                    boosters: {
                        7: 2
                    },
                    unlimitedLives: "6 hours"
                }
            });
            break;
        case "blocks":
            Object.assign(VirtualProducts.stickers2, {
                reward: {
                    boosters: {
                        22: 2,
                        20: 1
                    }
                },
                price: 100
            });
            Object.assign(VirtualProducts.stickers1, {
                reward: {
                    boosters: {
                        20: 1
                    },
                    soft: 300
                },
                price: 250
            });
            Object.assign(VirtualProducts.stickers0, {
                reward: {
                    boosters: {
                        21: 1
                    },
                    unlimitedLives: "6 hours"
                },
                price: 500
            });
            break;
    }

    if (cleverapps.config.name === "crocword") {
        Object.assign(VirtualProducts.stickers2, {
            reward: {
                boosters: {
                    3: 2
                }
            }
        });
        Object.assign(VirtualProducts.stickers1, {
            reward: {
                boosters: {
                    3: 3
                }
            }
        });
        Object.assign(VirtualProducts.stickers0, {
            reward: {
                boosters: {
                    3: 5
                }
            }
        });
    }

    Object.assign(VirtualProducts.stickers2.reward, {
        stickers: 2
    });
    Object.assign(VirtualProducts.stickers1.reward, {
        stickers: 6
    });
    Object.assign(VirtualProducts.stickers0.reward, {
        stickers: 15
    });
}